import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { appFont, RESOLUTION } from "../../config/defaults";
import { GREEN_BTN, PURPLE_BTN } from "../../config/images";
import {
	openPoolOverview,
	openTournamentDetails,
	registerForTournament,
	unRegisterFromTournament,
	subscribeToTournament,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { GameButton } from "../common";
import { handleError } from "../ErrorHandler";

class TournamentLobbyButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnViewHovered: false,
			btnCreateHovered: false,
			btnRegisterHovered: false,
		};
	}

	//#region events
	onMouseEnterViewButton() {
		this.setState({ btnViewHovered: true });
	}

	onMouseLeaveViewButton() {
		this.setState({ btnViewHovered: false });
	}

	onMouseEnterRegisterButton() {
		this.setState({ btnRegisterHovered: true });
	}

	onMouseLeaveRegisterButton() {
		this.setState({ btnRegisterHovered: false });
	}

	async onViewTournament() {
		const { tournamentID } = this.props.selectedTournamentDetails;
		try {
			await Analytics.logEvent("viewTournamentDetails");
			this.props.subscribeToTournament(tournamentID);
			this.props.openTournamentDetails();
		} catch (error) {
			handleError(error);
		}
	}

	async onRegisterForTournament() {
		const { tournamentID, state, isRegistered } = this.props.selectedTournamentDetails;
		try {
			await Analytics.logEvent("registerTournament");
			if (tournamentID === this.props.selectedTournamentID && state === "OPEN" && !isRegistered) {
				this.props.registerForTournament(tournamentID);
				// this.props.openPoolOverview();
			}
		} catch (error) {
			handleError(error);
		}
	}

	async onUnRegisterFromTournament() {
		const { tournamentID, isRegistered } = this.props.selectedTournamentDetails;
		try {
			await Analytics.logEvent("unRegisterTournament");
			if (tournamentID === this.props.selectedTournamentID && isRegistered) {
				this.props.unRegisterFromTournament(tournamentID);
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderRegisterButton() {
		const disabled = this.props.selectedTournamentID == -1;
		const opacity = disabled ? 0.5 : this.state.btnRegisterHovered ? 0.8 : 1;
		const enabledBackImage = {
			uri: PURPLE_BTN,
			style: [
				styles.enabledBackStyle,
				{ opacity },
				this.props.resolution == RESOLUTION.HIGH && {
					maxWidth: 200,
					height: 60,
				},
			],
		};
		let functionToReturn = null;
		let buttonText = "";

		if (
			!this.props.selectedTournamentDetails ||
			(this.props.selectedTournamentDetails && !this.props.selectedTournamentDetails.isRegistered)
		) {
			functionToReturn = this.onRegisterForTournament.bind(this);
			buttonText = this.props.lang.tournamentList.buttons.register;
		} else {
			functionToReturn = this.onUnRegisterFromTournament.bind(this);
			buttonText = this.props.lang.tournamentList.buttons.unregister;
		}

		return (
			<GameButton
				onPress={functionToReturn}
				onMouseEnter={this.onMouseEnterRegisterButton.bind(this)}
				onMouseLeave={this.onMouseLeaveRegisterButton.bind(this)}
				// TODO: Readd this when server side is fixed, at this time tournament teams do not exist
				// so user can't register
				disabled={disabled}
				myStyle={[
					styles.defButtonStyle,
					{
						opacity,
						height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
					},
				]}
				textContent={buttonText}
				textContentStyle={[
					styles.btnTextStyle,
					{ opacity, textShadowColor: "#5825A7" },
					this.props.resolution == RESOLUTION.HIGH && {
						fontSize: 22,
						paddingTop: 13,
						paddingBottom: 13,
					},
				]}
				backgroundImage={enabledBackImage}
			/>
		);
	}

	renderViewButton() {
		const disabled = this.props.selectedTournamentID == -1;
		const opacity = disabled ? 0.5 : this.state.btnViewHovered ? 0.8 : 1;
		const enabledBackImage = {
			uri: GREEN_BTN,
			style: [
				styles.enabledBackStyle,
				{ opacity },
				this.props.resolution == RESOLUTION.HIGH && {
					maxWidth: 200,
					height: 60,
				},
			],
		};
		return (
			<GameButton
				onPress={this.onViewTournament.bind(this)}
				onMouseEnter={this.onMouseEnterViewButton.bind(this)}
				onMouseLeave={this.onMouseLeaveViewButton.bind(this)}
				disabled={disabled}
				myStyle={[
					styles.defButtonStyle,
					{
						opacity,
						height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
					},
				]}
				textContent={this.props.lang.tournamentList.buttons.view}
				textContentStyle={[
					styles.btnTextStyle,
					{ opacity },
					this.props.resolution == RESOLUTION.HIGH && {
						fontSize: 22,
						paddingTop: 13,
						paddingBottom: 13,
					},
				]}
				backgroundImage={enabledBackImage}
			/>
		);
	}

	render() {
		return (
			<View style={styles.containerStyle}>
				{this.renderViewButton()}
				{this.renderRegisterButton()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	containerStyle: {
		width: "100%",
		flexDirection: "row",
		justifyContent: "space-around",
		marginTop: 3,
		padding: 5,
	},
	defButtonStyle: {
		flex: 2,
		height: 40,
		backgroundColor: "transparent",
		borderWidth: 0,
	},
	enabledBackStyle: {
		flex: 2,
		maxWidth: 150,
		height: 40,
	},
	btnTextStyle: {
		width: "100%",
		fontFamily: appFont,
		fontSize: 14,
		paddingTop: 10,
		paddingBottom: 10,
		textAlign: "center",
		color: "#FFFFFF",
		textShadowColor: "#397423",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: Platform.OS !== "web" ? 0.001 : 0,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedTournamentID: state.tournaments.selectedTournamentID,
		selectedTournamentDetails: state.tournaments.selectedTournamentDetails,
	};
};

const mapDispatchToProps = {
	openPoolOverview,
	openTournamentDetails,
	registerForTournament,
	unRegisterFromTournament,
	subscribeToTournament,
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentLobbyButtons);
