import React, { Component } from "react";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import { AppTouchableOpacity, Hoverable } from "../../../components/common";
import { handleError } from "../../../components/ErrorHandler";
import { LAUREL_URL, SQUARE_FLAG_URL } from "../../../config/connection";
import { f_sourcesansprosemibold } from "../../../config/defaults";
import { panelMenuEnabled } from "../../../config/permissions";
import { decideAvatarURL, getRankingImageURL } from "../../../helpers/commonHelpers";
import { store } from "../../../redux/store";
import { panelIcons, panelImage } from "../../config/images";
import MiniCardsContainer from "../MiniCardsContainer";
import PanelToAct from "../PanelToAct";
import StartPositionsContainer from "../StartPositionsContainer";

class ClassicPlayerPanel extends Component {
	constructor(props) {
		super(props);
		const { player, avatarURL, botAvatarURL } = this.props;
		const bot = this.props.availableBots.find((bot) => bot.name == player.username);
		this.state = {
			menuHeight: 0,
			userID: player.userID,
			avatarURL: decideAvatarURL(player, avatarURL, bot, botAvatarURL),
			/* avatarURL: player.isHuman
				? "https://" + this.props.avatarURL + player.userID + "/" + Date.now()
				: player.botImage &&
				  player.botImage != "" &&
				  player.botImage.endsWith(".png")
				? "https://" + this.props.botAvatarURL + player.botImage
				: bot && bot.image
				? "https://" + this.props.botAvatarURL + bot.image
				: "https://" + this.props.botAvatarURL + "bot1.png", */
		};
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.player !== this.props.player &&
			(this.state.userID != this.props.player.userID || this.props.player.botImage != prevProps.player.botImage)
		) {
			const { player, avatarURL, botAvatarURL } = this.props;
			const bot = this.props.availableBots.find((bot) => bot.name == player.username);
			this.setState({
				userID: player.userID,
				avatarURL: decideAvatarURL(player, avatarURL, bot, botAvatarURL),
				/* avatarURL: player.isHuman
					? "https://" + this.props.avatarURL + player.userID + "/" + Date.now()
					: player.botImage &&
					  player.botImage != "" &&
					  player.botImage.endsWith(".png")
					? "https://" + this.props.botAvatarURL + player.botImage
					: bot && bot.image
					? "https://" + this.props.botAvatarURL + bot.image
					: "https://" + this.props.botAvatarURL + "bot1.png", */
			});
		}
	}

	//#region events
	toggleMenu() {
		try {
			if (this.props.expandedPanelID != this.props.playerPanel.slotID) {
				this.props.openMenu(this.props.playerPanel.slotID);
			} else {
				this.props.openMenu(-1);
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		//#region calculations
		const { playerPanel, player, color, panelScale } = this.props;

		const countryCode = typeof player.country === "string" ? player.country.toLowerCase() : undefined;
		const countryURL = countryCode ? SQUARE_FLAG_URL + countryCode + ".png" : SQUARE_FLAG_URL + "_unknown.png";
		const countryTooltip =
			countryCode && typeof this.props.lang.countryList[countryCode] === "string"
				? this.props.lang.countryList[countryCode]
				: undefined;

		// const isVIP =
		//   typeof player !== "undefined" &&
		//   typeof player.laurelID !== "undefined" &&
		//   player.laurelID > 0;
		// const laurelNr = isVIP
		//   ? player.laurelID < 100
		//     ? player.laurelID < 10
		//       ? "00" + player.laurelID
		//       : "0" + player.laurelID
		//     : player.laurelID.toString()
		//   : "";
		const isVIP = player.isVip == true;
		const laurelNr = isVIP ? "009" : "";
		const laurelImgSrc = isVIP ? { uri: LAUREL_URL + laurelNr + ".png" } : {}; //{ uri: LAUREL_URL + "009" + ".png" }

		const rankCircleImg = isVIP ? panelIcons.ranking_vip[color] : panelIcons.ranking[color];
		const rankingImgURL = player.isHuman
			? getRankingImageURL(player.ranking, 50)
			: getRankingImageURL(player.strength, 50);
		const _level = player.isHuman ? player.ranking : player.strength;
		const strengthText =
			!player.isHuman &&
			typeof player.strength === "string" &&
			typeof this.props.lang[player.strength.toLowerCase()] === "string"
				? this.props.lang[player.strength.toLowerCase()]
				: _level;
		const _score = player.isHuman ? player.score : "-";
		var levelText = isVIP ? this.props.lang.tooltips.vipRank : this.props.lang.tooltips.nonVipRank;
		levelText = levelText.replace("#1#", strengthText);
		levelText = levelText.replace("#2#", _score);

		const panelImg = panelImage[playerPanel.type][color];
		const teamMateImg = panelIcons.team_mate[this.props.teamMateColor];
		var teamMateName, teamMateText;
		if (this.props.isTeamEnabled == true) {
			const { startedGame } = store.getState();
			const teamMateSlot = (this.props.playerPanel.slotID + startedGame.playerCnt / 2) % startedGame.playerCnt;
			const teamMate = startedGame.players["slot_" + teamMateSlot];

			if (teamMate != undefined && typeof teamMate.username === "string") {
				teamMateName = teamMate.username;
				teamMateText = this.props.lang.tooltips.teamMate.replace("#1#", teamMateName);
			}
		}

		const bottomImageStyle = [
			styles.bottomImage,
			{
				width: Math.floor(7 * panelScale),
				height: Math.floor(7 * panelScale),
			},
		];
		var avatarPos = {
				top: playerPanel.top,
				left: playerPanel.left,
			},
			flagPos = {
				top: playerPanel.top,
				left: playerPanel.left,
			},
			countryHover = {
				top: playerPanel.top,
				left: playerPanel.left,
			},
			laurelPos = {
				top: playerPanel.top,
				left: playerPanel.left,
			},
			levelHover = {
				top: playerPanel.top,
				left: playerPanel.left,
			},
			rankPos = {
				top: playerPanel.top,
				left: playerPanel.left,
			},
			circlePos = {
				top: playerPanel.top,
				left: playerPanel.left,
			},
			miniCardsPos = { top: playerPanel.top, left: playerPanel.left },
			cardsHover = { top: playerPanel.top, left: playerPanel.left },
			cardsHoverContainer = { top: playerPanel.top, left: playerPanel.left },
			usernamePos = {
				top: playerPanel.top,
				left: playerPanel.left,
			},
			statePos = { top: playerPanel.top, left: playerPanel.left },
			slotStateHover = { top: playerPanel.top, left: playerPanel.left },
			resPos = { top: playerPanel.top, left: playerPanel.left },
			chatHover = { top: playerPanel.top, left: playerPanel.left },
			dealerPos = { top: playerPanel.top, left: playerPanel.left },
			dealerHover = { top: playerPanel.top, left: playerPanel.left },
			toActPos = { top: playerPanel.top, left: playerPanel.left },
			thinkingHover = { top: playerPanel.top, left: playerPanel.left },
			teamMatePos = {
				top: playerPanel.top,
				left: playerPanel.left,
				transform: [{ rotate: "0deg" }],
			},
			teamMateHover = { top: playerPanel.top, left: playerPanel.left },
			_fontSize = Math.floor(5 * panelScale);

		switch (playerPanel.type) {
			case "TL":
				avatarPos = {
					top: Math.round((11 * panelScale) / 1.6),
					left: Math.round((5 * panelScale) / 1.6),
				};
				laurelPos = {
					top: Math.round((21 * panelScale) / 1.6),
					right: Math.round((11 * panelScale) / 1.6),
				};
				rankPos = {
					top: Math.round((29 * panelScale) / 1.6),
					left: Math.round((50 * panelScale) / 1.6),
				};
				circlePos = {
					top: Math.round((24 * panelScale) / 1.6),
					right: Math.round((14 * panelScale) / 1.6),
				};
				levelHover = { top: circlePos.top, left: 3 };
				flagPos = {
					top: Math.round((12 * panelScale) / 1.6),
					right: Math.round((17 * panelScale) / 1.6),
				};
				miniCardsPos = {
					bottom: Math.round((5 * (panelScale - 0.1)) / 1.5),
					left: Math.round((8 * (panelScale - 0.1)) / 1.5),
				};
				cardsHover = { top: 15, left: 3 };
				usernamePos = {
					top: Math.round((0 * panelScale) / 1.6),
					left: Math.round((6 * panelScale) / 1.6),
				};
				statePos = {
					top: Math.round((14 * panelScale) / 1.6),
					right: Math.round((4 * panelScale) / 1.6),
				};
				slotStateHover = { top: statePos.top, left: 3 };
				resPos = {
					top: Math.round((27 * panelScale) / 1.6),
					right: Math.round((4 * panelScale) / 1.6),
				};
				chatHover = { top: resPos.top, left: 3 };
				dealerPos = {
					bottom: Math.round((16 * panelScale) / 1.6),
					right: Math.round((6 * panelScale) / 1.6),
				};
				dealerHover = { top: dealerPos.bottom, left: 3 };
				toActPos = {
					bottom: Math.round((6 * panelScale) / 1.6),
					right: Math.round((16 * panelScale) / 1.6),
				};
				thinkingHover = { top: toActPos.bottom, left: 3 };
				teamMatePos = {
					top: Math.round((3 * panelScale) / 1.6),
					right: Math.round((5 * panelScale) / 1.6),
					// transform: [{ rotateY: "-180deg" }],
				};
				teamMateHover = { top: teamMatePos.top, left: 3 };
				switch (this.props.playerCnt) {
					case 2:
						usernamePos = { top: 0, left: 7 };
						rankPos = { top: 36, left: 62 };
						miniCardsPos = { bottom: 8, left: 16 };
						cardsHoverContainer = { bottom: 6, left: 6 };
						break;
					case 3:
						flagPos = { top: 12, right: 17 };
						miniCardsPos = { bottom: 5, left: 9 };
						cardsHoverContainer = { bottom: 5, left: 5 };
						break;
					case 4:
						usernamePos = { top: 0, left: 6 };
						rankPos = { top: 29, left: 50 };
						flagPos = { top: 12, right: 17 };
						miniCardsPos = Platform.OS === "web" ? miniCardsPos : { bottom: 4.5, left: 10 };
						cardsHoverContainer = { bottom: 5, left: 5 };
						break;
					case 5:
						flagPos = { top: 13, right: 18 };
						rankPos = { top: 33, left: 56 };
						miniCardsPos = { bottom: 6, left: 12 };
						cardsHoverContainer = { bottom: 5, left: 5 };
						usernamePos = { top: 0, left: 6 };
						break;
					case 7:
						miniCardsPos = { bottom: 6, left: 12 };
						cardsHoverContainer = { bottom: 5, left: 5 };
						usernamePos = { top: 0, left: 7 };
						flagPos = { top: 13, right: 18 };
						rankPos = { top: 33, left: 56 };
						break;
					case 8:
						miniCardsPos = { bottom: 7, left: 13 };
						cardsHoverContainer = { bottom: 6, left: 5 };
						usernamePos = { top: 0, left: 7 };
						flagPos = { top: 14, right: 20 };
						rankPos = { top: 34, left: 59 };
						break;
					default:
						break;
				}
				countryHover = { top: flagPos.top, left: 3 };
				break;
			case "TR":
				avatarPos = {
					top: Math.round((11 * panelScale) / 1.6),
					right: Math.round((5 * panelScale) / 1.6),
				};
				laurelPos = {
					top: Math.round((21 * panelScale) / 1.6),
					left: Math.round((11 * panelScale) / 1.6),
				};
				rankPos = {
					top: Math.round((29 * panelScale) / 1.6),
					right: Math.round((50 * panelScale) / 1.6),
				};
				circlePos = {
					top: Math.round((24 * panelScale) / 1.6),
					left: Math.round((14 * panelScale) / 1.6),
				};
				levelHover = { top: circlePos.top, right: 3 };
				flagPos = {
					top: Math.round((12 * panelScale) / 1.6),
					left: Math.round((16 * panelScale) / 1.6),
				};
				miniCardsPos = {
					bottom: Math.round((5 * (panelScale - 0.1)) / 1.5),
					right: Math.round((10 * (panelScale - 0.1)) / 1.5),
				};
				cardsHover = { top: 15, right: 3 };
				usernamePos = {
					top: Math.round((0 * panelScale) / 1.6),
					right: Math.round((4 * panelScale) / 1.6),
				};
				statePos = {
					top: Math.round((14 * panelScale) / 1.6),
					left: Math.round((4 * panelScale) / 1.6),
				};
				slotStateHover = { top: statePos.top, right: 3 };
				resPos = {
					top: Math.round((26 * panelScale) / 1.6),
					left: Math.round((4 * panelScale) / 1.6),
				};
				chatHover = { top: resPos.top, right: 3 };
				dealerPos = {
					bottom: Math.round((16 * panelScale) / 1.6),
					left: Math.round((6 * panelScale) / 1.6),
				};
				dealerHover = { top: dealerPos.bottom, right: 3 };
				toActPos = {
					bottom: Math.round((6 * panelScale) / 1.6),
					left: Math.round((16 * panelScale) / 1.6),
				};
				thinkingHover = { top: toActPos.bottom, right: 3 };
				teamMatePos = {
					top: Math.round((3 * panelScale) / 1.6),
					left: Math.round((5 * panelScale) / 1.6),
					// transform: [{ rotateY: "0deg" }],
				};
				teamMateHover = { top: teamMatePos.top, right: 3 };
				switch (this.props.playerCnt) {
					case 2:
						usernamePos = { top: 0, right: 4 };
						rankPos = { top: 36, right: 62 };
						miniCardsPos = { bottom: 8, right: 18 };
						cardsHoverContainer = { bottom: 6, right: 6 };
						break;
					case 3:
						flagPos = { top: 12, left: 17 };
						miniCardsPos = { bottom: 5, right: 12 };
						cardsHoverContainer = { bottom: 5, right: 6 };
						break;
					case 4:
						usernamePos = { top: 0, right: 4 };
						rankPos = { top: 29, right: 50 };
						flagPos = { top: 12, left: 16 };
						miniCardsPos = Platform.OS === "web" ? miniCardsPos : { bottom: 4.5, right: 9 };
						cardsHoverContainer = { bottom: 5, right: 5 };
						break;
					case 5:
						flagPos = { top: 13, left: 18 };
						rankPos = { top: 33, right: 56 };
						miniCardsPos = { bottom: 6, right: 14 };
						cardsHoverContainer = { bottom: 5, right: 6 };
						usernamePos = { top: 0, right: 4 };
						break;
					case 6:
						usernamePos = { top: 0, right: 3 };
						miniCardsPos = { bottom: 6, right: 14 };
						cardsHoverContainer = { bottom: 5, right: 6 };
						flagPos = { top: 13, left: 18 };
						rankPos = { top: 33, right: 56 };
						break;
					case 7:
						miniCardsPos = { bottom: 6, right: 14 };
						cardsHoverContainer = { bottom: 5, right: 6 };
						usernamePos = { top: 0, right: 3 };
						flagPos = { top: 13, left: 18 };
						rankPos = { top: 33, right: 56 };
						break;
					case 8:
						miniCardsPos = { bottom: 7, right: 16 };
						cardsHoverContainer = { bottom: 6, right: 6 };
						usernamePos = { top: 0, right: 3 };
						flagPos = { top: 14, left: 19 };
						rankPos = { top: 34, right: 59 };
						break;
					default:
						break;
				}
				countryHover = { top: flagPos.top, right: 3 };
				break;
			case "BL":
				avatarPos = {
					bottom: Math.round((11 * panelScale) / 1.6),
					left: Math.round((5 * panelScale) / 1.6),
				};
				laurelPos = {
					bottom: Math.round((23 * panelScale) / 1.6),
					right: Math.round((11 * panelScale) / 1.6),
				};
				rankPos = {
					bottom: Math.round((31 * panelScale) / 1.6),
					left: Math.round((50 * panelScale) / 1.6),
				};
				circlePos = {
					bottom: Math.round((26 * panelScale) / 1.6),
					right: Math.round((14 * panelScale) / 1.6),
				};
				levelHover = { bottom: -13, left: 3 };
				flagPos = {
					bottom: Math.round((13 * panelScale) / 1.6),
					right: Math.round((17 * panelScale) / 1.6),
				};
				miniCardsPos = {
					top: Math.round((2 * (panelScale - 0.1)) / 1.5),
					left: Math.round((8 * (panelScale - 0.1)) / 1.5),
				};
				cardsHover = { bottom: -18, left: 3 };
				usernamePos = {
					bottom: Math.round((3 * panelScale) / 1.6),
					left: Math.round((6 * panelScale) / 1.6),
				};
				statePos = {
					bottom: Math.round((15 * panelScale) / 1.6),
					right: Math.round((4 * panelScale) / 1.6),
				};
				slotStateHover = { bottom: -15, left: 3 };
				resPos = {
					bottom: Math.round((27 * panelScale) / 1.6),
					right: Math.round((4 * panelScale) / 1.6),
				};
				chatHover = { bottom: -15, left: 3 };
				dealerPos = {
					top: Math.round((16 * panelScale) / 1.6),
					right: Math.round((6 * panelScale) / 1.6),
				};
				dealerHover = { bottom: -15, left: 3 };
				toActPos = {
					top: Math.round((5 * panelScale) / 1.6),
					right: Math.round((16 * panelScale) / 1.6),
				};
				thinkingHover = { bottom: -15, left: 3 };
				teamMatePos = {
					bottom: Math.round((4 * panelScale) / 1.6),
					right: Math.round((5 * panelScale) / 1.6),
					// transform: [{ rotateX: "-180deg" }, { rotateY: "180deg" }],
				};
				teamMateHover = { bottom: -15, left: 3 };
				switch (this.props.playerCnt) {
					case 3:
						flagPos = { bottom: 13, right: 17 };
						miniCardsPos = { top: 2, left: 9 };
						cardsHoverContainer = { top: 3, left: 6 };
						usernamePos = { bottom: 2, left: 6 };
						break;
					case 4:
						usernamePos = { bottom: 3, left: 6 };
						rankPos = { bottom: 31, left: 50 };
						flagPos = { bottom: 13, right: 17 };
						miniCardsPos = Platform.OS === "web" ? miniCardsPos : { top: 2.5, left: 10 };
						cardsHoverContainer = { top: 2, left: 5 };
						break;
					case 5:
						flagPos = { bottom: 14, right: 18 };
						rankPos = { bottom: 35, left: 56 };
						miniCardsPos = { top: 3, left: 12 };
						cardsHoverContainer = { top: 2, left: 5 };
						usernamePos = { bottom: 3, left: 7 };
						break;
					case 7:
						miniCardsPos = { top: 3, left: 12 };
						cardsHoverContainer = { top: 2, left: 5 };
						usernamePos = { bottom: 2, left: 7 };
						flagPos = { bottom: 14, right: 18 };
						rankPos = { bottom: 35, left: 56 };
						break;
					case 8:
						miniCardsPos = { top: 4, left: 13 };
						cardsHoverContainer = { top: 3, left: 5 };
						usernamePos = { bottom: 2, left: 7 };
						flagPos = { bottom: 16, right: 20 };
						rankPos = { bottom: 37, left: 59 };
						break;
					default:
						break;
				}
				countryHover = { bottom: -15, left: 3 };
				break;
			case "BR":
				avatarPos = {
					bottom: Math.round((11 * panelScale) / 1.6),
					right: Math.round((5 * panelScale) / 1.6),
				};
				laurelPos = {
					bottom: Math.round((23 * panelScale) / 1.6),
					left: Math.round((11 * panelScale) / 1.6),
				};
				rankPos = {
					bottom: Math.round((31 * panelScale) / 1.6),
					right: Math.round((50 * panelScale) / 1.6),
				};
				circlePos = {
					bottom: Math.round((26 * panelScale) / 1.6),
					left: Math.round((14 * panelScale) / 1.6),
				};
				levelHover = { bottom: -13, right: 3 };
				flagPos = {
					bottom: Math.round((13 * panelScale) / 1.6),
					left: Math.round((16 * panelScale) / 1.6),
				};
				miniCardsPos = {
					top: Math.round((2 * (panelScale - 0.1)) / 1.5),
					right: Math.round((10 * (panelScale - 0.1)) / 1.5),
				};
				cardsHover = { bottom: -18, right: 3 };
				usernamePos = {
					bottom: Math.round((3 * panelScale) / 1.6),
					right: Math.round((4 * panelScale) / 1.6),
				};
				statePos = {
					bottom: Math.round((15 * panelScale) / 1.6),
					left: Math.round((4 * panelScale) / 1.6),
				};
				slotStateHover = { bottom: -15, right: 3 };
				resPos = {
					bottom: Math.round((27 * panelScale) / 1.6),
					left: Math.round((4 * panelScale) / 1.6),
				};
				chatHover = { bottom: -15, right: 3 };
				dealerPos = {
					top: Math.round((16 * panelScale) / 1.6),
					left: Math.round((6 * panelScale) / 1.6),
				};
				dealerHover = { bottom: -15, right: 3 };
				toActPos = {
					top: Math.round((5 * panelScale) / 1.6),
					left: Math.round((16 * panelScale) / 1.6),
				};
				thinkingHover = { bottom: -15, right: 3 };
				teamMatePos = {
					bottom: Math.round((4 * panelScale) / 1.6),
					left: Math.round((5 * panelScale) / 1.6),
					// transform: [{ rotateX: "180deg" }],
				};
				teamMateHover = { bottom: -15, right: 3 };
				switch (this.props.playerCnt) {
					case 4:
						usernamePos = { bottom: 3, right: 4 };
						rankPos = { bottom: 31, right: 50 };
						flagPos = { bottom: 13, left: 16 };
						miniCardsPos = Platform.OS === "web" ? miniCardsPos : { top: 2.5, right: 9 };
						cardsHoverContainer = { top: 2, right: 5 };
						break;
					case 6:
						usernamePos = { bottom: 3, right: 3 };
						miniCardsPos = { top: 3, right: 14 };
						cardsHoverContainer = { top: 2, right: 6 };
						flagPos = { bottom: 15, left: 18 };
						rankPos = { bottom: 35, right: 56 };
						break;
					case 8:
						miniCardsPos = { top: 4, right: 16 };
						cardsHoverContainer = { top: 3, right: 6 };
						usernamePos = { bottom: 2, right: 3 };
						flagPos = { bottom: 16, left: 19 };
						rankPos = { bottom: 37, right: 59 };
						break;
					default:
						break;
				}
				countryHover = { bottom: -15, right: 3 };
				break;
		}

		const plColor =
			this.props.playerPanel.color && this.props.playerPanel.color.normal
				? this.props.playerPanel.color
				: { normal: "000000", strong: "000000", text: "FFFFFF" };
		const miniCardURL = panelIcons.mini_card[color];
		// DYNAMIC_IMAGES_URL +
		// "pp-icon-card.php?c=" +
		// plColor.normal +
		// "&s=" +
		// plColor.strong;

		avatarPos.width = Math.floor(25 * panelScale);
		avatarPos.height = Math.floor(24 * panelScale);
		flagPos.width = Math.round(11 * panelScale);
		flagPos.height = Math.round(7.5 * panelScale);
		laurelPos.width = Math.floor(18 * panelScale);
		laurelPos.height = Math.floor(18 * panelScale);
		rankPos.width = Math.floor(8 * panelScale);
		rankPos.height = Math.floor(8 * panelScale);
		circlePos.width = Math.floor(14 * panelScale);
		circlePos.height = Math.floor(14 * panelScale);
		circlePos.zIndex = isVIP == true ? -1 : 1;
		if (levelHover.top) levelHover.top = circlePos.height + 3;
		usernamePos.width = Math.floor(38 * panelScale);
		usernamePos.height = Math.floor(8 * panelScale);
		teamMatePos.width = Math.floor(7 * panelScale);
		teamMatePos.height = Math.floor(6 * panelScale);
		if (teamMateHover.top) teamMateHover.top = teamMatePos.height + 3;

		switch (this.props.playerCnt) {
			case 2:
				flagPos.width = 24;
				break;
			case 3:
				flagPos.width = 19;
				flagPos.height = 13;
				break;
			case 5:
			case 6:
			case 7:
				rankPos.width = 13;
				rankPos.height = 13;
				flagPos.width = 21;
				flagPos.height = 14;
				break;
			case 8:
				rankPos.width = 14;
				rankPos.height = 14;
				break;
			default:
				break;
		}
		if (countryHover.top) countryHover.top = flagPos.height + 3;
		if (slotStateHover.top) slotStateHover.top = bottomImageStyle[1].height;
		if (chatHover.top) chatHover.top = bottomImageStyle[1].height;
		if (dealerHover.top) dealerHover.top = bottomImageStyle[1].height;
		if (thinkingHover.top) thinkingHover.top = bottomImageStyle[1].height;
		//#endregion

		var _cardCounts = store.getState().startedGame.cardCounts.find((cc) => cc.slotID == playerPanel.slotID);
		_cardCounts = _cardCounts != undefined ? _cardCounts.count : 0;
		return (
			<View key={playerPanel.slotID}>
				<StartPositionsContainer
					positions={this.props.positions}
					gameTheme={this.props.gameTheme}
					verticalStartPositions={this.props.verticalStartPositions}
					playerPanel={this.props.playerPanel}
					panelScale={this.props.panelScale}
					playerCnt={this.props.playerCnt}
					onFinishedPawnMoveAction={this.props.onFinishedPawnMoveAction}
				/>
				<AppTouchableOpacity
					silentPress={true}
					activeOpacity={1}
					onPress={this.toggleMenu.bind(this)}
					disabled={panelMenuEnabled == false}
					style={{
						position: "absolute",
						top: Math.floor(playerPanel.top * panelScale),
						left: Math.floor(playerPanel.left * panelScale),
						width: Math.floor(51 * panelScale),
						height: Math.floor(42 * panelScale),
						// transform: [{ scale: panelScale }]
					}}
				>
					<Image
						key={playerPanel.slotID}
						source={panelImg}
						resizeMode="cover"
						style={[styles.panelImg, { width: "100%", height: "100%" }, { top: 0, left: 0 }]}
					/>
					<View key="avatarBg" style={[styles.avatarImgBg, avatarPos]} />
					<Image
						key="avatar"
						resizeMode="cover"
						source={{ uri: this.state.avatarURL }}
						style={[styles.avatarImg, avatarPos]}
					/>
					<Image
						key="rankCircle"
						resizeMode={Platform.OS === "ios" ? "cover" : "center"}
						source={rankCircleImg}
						style={[styles.circleImg, circlePos]}
					/>
					<Hoverable
						key="levelHover"
						tooltipText={levelText}
						tooltipTextPos={levelHover}
						style={[styles.laurelImg, laurelPos, { zIndex: playerPanel.type[0] == "T" ? 40 : 100 }]}
					>
						<Image
							key="laurel"
							resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
							source={laurelImgSrc}
							style={[styles.laurelImg, laurelPos, { top: 0, left: 0, bottom: 0, right: 0 }]}
						/>
					</Hoverable>
					<Image
						key="rankImage"
						resizeMode={Platform.OS === "web" ? "center" : "cover"}
						source={rankingImgURL}
						style={[styles.rankingImg, rankPos]}
					/>
					<Hoverable
						key="countryHover"
						tooltipText={countryTooltip}
						tooltipTextPos={countryHover}
						style={[styles.flagImg, flagPos, { zIndex: 100 }]}
					>
						<View style={{ width: flagPos.width, height: flagPos.height }} />
					</Hoverable>
					<Image key="country" resizeMode="cover" source={{ uri: countryURL }} style={[styles.flagImg, flagPos]} />
					<Hoverable
						key="slotStateHover"
						tooltipText={
							this.props.isLeft
								? this.props.lang.tooltips.player_left
								: this.props.isConnected
								? this.props.lang.tooltips.connected
								: this.props.lang.tooltips.disconnected
						}
						tooltipTextPos={slotStateHover}
						style={[bottomImageStyle, statePos, { zIndex: playerPanel.type[0] == "T" ? 45 : 80 }]}
					>
						<Image
							key="slotState"
							resizeMode="contain"
							source={
								this.props.isLeft
									? panelIcons.player_left
									: this.props.isConnected
									? panelIcons.connected
									: panelIcons.disconnected
							}
							style={[bottomImageStyle]}
						/>
					</Hoverable>
					<Hoverable
						key="chatHover"
						tooltipText={
							this.props.isDealer ? this.props.lang.tooltips.gameChatOn : this.props.lang.tooltips.gameChatOff
						}
						tooltipTextPos={chatHover}
						style={[
							bottomImageStyle,
							resPos,
							{
								opacity: 0,
								zIndex: -1 /*TODO: put this back playerPanel.type[0] == "T" ? 40 : 85*/,
							},
						]}
					>
						<Image key="reserved" resizeMode="contain" source={panelIcons.chat_enabled} style={[bottomImageStyle]} />
					</Hoverable>
					<Hoverable
						key="dealerHover"
						tooltipText={this.props.isDealer ? this.props.lang.tooltips.dealerOn : this.props.lang.tooltips.dealerOff}
						tooltipTextPos={dealerHover}
						style={[bottomImageStyle, dealerPos, { zIndex: playerPanel.type[0] == "T" ? 45 : 105 }]}
					>
						<Image
							key="dealerIcon"
							resizeMode="contain"
							source={this.props.isDealer ? panelIcons.dealer : panelIcons.dealer_disabled}
							style={[bottomImageStyle]}
						/>
					</Hoverable>
					<PanelToAct
						slotID={playerPanel.slotID}
						toActPos={toActPos}
						tooltipTextPos={thinkingHover}
						style={[bottomImageStyle]}
						width={Math.floor(7 * panelScale)}
						height={Math.floor(7 * panelScale)}
						hoverIndex={playerPanel.type[0] == "T" ? 30 : 105}
					/>
					{this.props.isTeamEnabled && (
						<Hoverable
							key="teamMateHover"
							tooltipText={teamMateText}
							tooltipTextPos={teamMateHover}
							style={[
								styles.teamMate,
								teamMatePos,
								{
									transform: undefined,
									zIndex: playerPanel.type[0] == "T" ? 100 : 75,
								},
							]}
						>
							<Image
								key="teamMate"
								resizeMode="cover"
								source={teamMateImg}
								style={[styles.teamMate, teamMatePos, { top: 0, left: 0, bottom: 0, right: 0 }]}
							/>
						</Hoverable>
					)}
					<Hoverable
						key="cardsHover"
						tooltipText={_cardCounts > 0 ? this.props.lang.tooltips.cardsInHand : this.props.lang.tooltips.emptyHand}
						tooltipTextPos={cardsHover}
						style={[cardsHoverContainer, { position: "absolute", zIndex: 105 }]}
					>
						<View
							style={{
								width: Math.round((47 * panelScale) / 1.6),
								height: Math.round((12 * panelScale) / 1.6),
							}}
						/>
					</Hoverable>
					<MiniCardsContainer
						slotID={playerPanel.slotID}
						miniCardURL={miniCardURL}
						miniCardsPos={miniCardsPos}
						panelScale={panelScale - 0.1}
						gameTheme={this.props.gameTheme}
					/>
					<View style={[styles.usernameContainer, usernamePos]}>
						<View style={{ flex: 1, justifyContent: "center" }}>
							{this.props.player && (
								<Text
									allowFontScaling={false}
									numberOfLines={1}
									style={[
										styles.usernameText,
										{
											fontSize: _fontSize,
											lineHeight: usernamePos.height,
											color: plColor.text ? "#" + plColor.text : "#fff",
										},
									]}
								>
									{this.props.player.username}
								</Text>
							)}
						</View>
					</View>
				</AppTouchableOpacity>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	panelImg: {
		position: "absolute",
		width: 51,
		height: 42,
	},
	avatarImgBg: {
		position: "absolute",
		width: 25,
		height: 24,
		zIndex: -2,
		backgroundColor: "#DBDBDB",
	},
	avatarImg: {
		position: "absolute",
		width: 25,
		height: 24,
		borderRadius: 2,
		zIndex: -1,
	},
	circleImg: {
		position: "absolute",
		width: 14,
		height: 14,
		zIndex: 1,
	},
	rankingImg: {
		position: "absolute",
		width: 8,
		height: 8,
		zIndex: 0,
	},
	laurelImg: {
		position: "absolute",
		width: 17,
		height: 17,
	},
	flagImg: {
		position: "absolute",
		width: 12,
		height: 8,
		borderRadius: 1,
		zIndex: -1,
	},
	usernameContainer: {
		position: "absolute",
		width: 38,
		height: 8,
	},
	usernameText: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: 8,
		color: "#fff",
		paddingLeft: 0,
		paddingRight: 0,
		lineHeight: 12,
	},
	teamMate: {
		position: "absolute",
		width: 7,
		height: 6,
	},
	bottomImage: {
		position: "absolute",
		width: 7,
		height: 7,
		zIndex: 5,
	},
});

export { ClassicPlayerPanel };
