import { tabType } from "../../config/defaults";
import { MyWebSocket } from "../../connection";
import { EventQueue } from "../../controller";
import DebugLogger from "../../controller/DebugLogger";
import { store } from "../../redux/store";
import {
	CLOSE_SHOP_TAB,
	HIDE_BANNER,
	OPEN_CHAT_TAB,
	OPEN_CREATE_NEW_GAME,
	OPEN_GAMES_TAB,
	OPEN_GAME_END,
	OPEN_GAME_LOBBY,
	OPEN_GAME_ROOM,
	OPEN_SHOP_TAB,
	OPEN_STARTED_GAME,
	SHOW_BANNER,
} from "./actionTypes";
import { subscribeToGameList } from "./GameListDataActions";
import { clearInShopLateMoveTimeout, getInShopLateMoveTimeout } from "./GameShopActions";
import { selectChatRoomTab } from "./GlobalChatActions";

export const openTab = (type, reason, linkID) => {
	return (dispatch) => {
		dispatch(_openTab(type, reason, linkID));
	};
};

export const openGameLobby = () => {
	return (dispatch) => {
		dispatch({ type: OPEN_GAME_LOBBY });
		const { isSubscribeInProgress } = store.getState().gameListData;
		if (!isSubscribeInProgress) dispatch(subscribeToGameList());
	};
};

export const openCreateNewGame = () => {
	return (dispatch) => {
		dispatch(_openCreateNewGame());
	};
};

export const openGameRoom = (gameID, gameName, cardset) => {
	return { type: OPEN_GAME_ROOM, payload: { gameID, gameName, cardset } };
};

export const openStartedGame = () => {
	return { type: OPEN_STARTED_GAME };
};

export const openGameEnd = () => {
	return { type: OPEN_GAME_END };
};

export const openGlobalChat = () => {
	return { type: OPEN_CHAT_TAB };
};

export const openShop = () => {
	return (dispatch) => {
		MyWebSocket.shared.requestShopDetails();
		dispatch({ type: OPEN_SHOP_TAB });
		if (EventQueue.shared.openShopInProgress) {
			setTimeout(async () => {
				await DebugLogger.shared.saveLog({ OS_message: "openShop finished" }, true);
			}, 50);
			const { currentEvent, requestNextEventExecution } = EventQueue.shared;
			EventQueue.shared.openShopInProgress = false;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const closeShop = () => {
	return (dispatch) => {
		const curState = store.getState();
		const { isLobbyOpen, isCreateNewGameOpen, isGameRoomOpen, isGameStarted, isTabGamesButtonActive } = curState.tab;
		const { authenticated } = curState.currentUser;
		const { isSubscribeInProgress } = curState.gameListData;
		/* if (
      authenticated &&
      isLobbyOpen &&
      isTabGamesButtonActive &&
      !isCreateNewGameOpen &&
      !isGameRoomOpen &&
      !isGameStarted &&
      !isSubscribeInProgress
    ) {
      dispatch(subscribeToGameList());
    } */
		dispatch({ type: CLOSE_SHOP_TAB });

		const { tab } = store.getState();
		if (tab.isGameStarted && !tab.isGameEndOpened && getInShopLateMoveTimeout() != null) {
			clearInShopLateMoveTimeout();
		}
	};
};
export const openGamesTab = () => {
	return (dispatch) => {
		dispatch({ type: OPEN_GAMES_TAB });
		const curState = store.getState();
		const { isLobbyOpen, isCreateNewGameOpen, isGameRoomOpen, isGameStarted, isShopOpen } = curState.tab;
		const { authenticated } = curState.currentUser;
		const { isSubscribeInProgress } = curState.gameListData;
		if (
			authenticated &&
			isLobbyOpen &&
			!isCreateNewGameOpen &&
			!isGameRoomOpen &&
			!isGameStarted &&
			!isShopOpen &&
			!isSubscribeInProgress
		) {
			dispatch(subscribeToGameList());
		}
	};
};

export const showBanner = (showBanner) => {
	if (showBanner) {
		return { type: SHOW_BANNER };
	} else {
		return { type: HIDE_BANNER };
	}
};

const _openTab = (type, reason, linkID) => {
	return (dispatch) => {
		switch (type) {
			case tabType.CHAT:
				// dispatch(unsubscribeFromGameList());
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "getFriendList",
				});
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "getBanList",
				});
				dispatch(openGlobalChat());
				const globalChat = store.getState().globalChat;
				const activeRoom = globalChat.activeRoom;
				if (activeRoom != null) {
					dispatch(selectChatRoomTab(activeRoom));
				} else {
					const rooms = globalChat.joinedChatRooms;
					if (rooms.length > 0) dispatch(selectChatRoomTab(rooms[0]));
				}
				/* const { isSubscribeInProgress } = store.getState().gameListData;
        if (!isSubscribeInProgress) dispatch(subscribeToGameList()); */
				break;
			case tabType.SHOP:
				const { startedGame, tab, app } = store.getState();
				// dispatch(unsubscribeFromGameList());
				dispatch(openShop());

				//if there is a game started
				/* if (
          tab.isGameStarted &&
          !tab.isGameEndOpened &&
          typeof startedGame.gamePlayerToAct !== "undefined" &&
          startedGame.gamePlayerToAct !== null &&
          !startedGame.isPlayerWarned
        ) {
          dispatch(startShopLateMoveTimeout());
        } */
				break;
			case tabType.GAMES:
			default:
				dispatch(openGamesTab());
				break;
		}
	};
};

const _openCreateNewGame = () => {
	return { type: OPEN_CREATE_NEW_GAME };
};
