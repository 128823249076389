import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { appFont, RESOLUTION } from "../../config/defaults";
import { GREEN_BTN } from "../../config/images";
import { MyWebSocket } from "../../connection";
import { publishGame } from "../../redux/actions";
//removed because of crashlytics import GameRewardedAd from "../Admob/RewardedAd";
import Analytics from "../Analytics/Analytics";
import { GameButton } from "../common";
import { handleError } from "../ErrorHandler";

class CreateGameButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isStartGameHovered: false,
			isPublishGameHovered: false,
		};
	}

	//#region events
	async publishGame() {
		try {
			if (typeof this.props.publishGame === "function") {
				this.props.publishGame(
					this.props.publishGameJSON.gameID,
					this.props.publishGameJSON.name,
					this.props.players,
					this.props.publishGameJSON.slots,
					this.props.publishGameJSON.cardset
				);
			}
			MyWebSocket.shared.sendMsg(this.props.publishGameJSON);
			await Analytics.logEvent("publishGame");
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderLeftButton() {
		const { defButtonStyle, btnTextStyle } = styles;
		const disabled = this.props.loadingNewGame || !this.props.isPublishEnabled;
		const opacity = this.props.canStartGame
			? !this.props.loadingNewGame
				? this.state.isStartGameHovered || disabled
					? 0.8
					: 1
				: 0
			: !this.props.loadingNewGame
			? this.state.isPublishGameHovered || disabled
				? 0.8
				: 1
			: 0;
		const enabledBackImage = {
			uri: GREEN_BTN,
			style: [
				{
					flex: 2,
					maxWidth: 200,
					height: 40,
					opacity,
				},
				this.props.resolution == RESOLUTION.HIGH && {
					maxWidth: 250,
					height: 60,
				},
			],
		};

		const _content =
			this.props.canStartGame == true ? this.props.lang.startGame : this.props.lang.createNewGame.btnPublishGame;
		return (
			<GameButton
				onPress={this.publishGame.bind(this)}
				onMouseEnter={() => this.setState({ isPublishGameHovered: true })}
				onMouseLeave={() => this.setState({ isPublishGameHovered: false })}
				disabled={disabled}
				myStyle={[
					defButtonStyle,
					{
						opacity,
						height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
					},
				]}
				textContent={_content}
				textContentStyle={[
					btnTextStyle,
					{ opacity },
					this.props.resolution == RESOLUTION.HIGH && {
						fontSize: 22,
						paddingTop: 13,
						paddingBottom: 13,
					},
				]}
				backgroundImage={enabledBackImage}
			/>
		);
	}

	render() {
		return (
			<View onLayout={this.props.onLayout} style={[styles.containerStyle, this.props.style || {}]}>
				{/*removed because of crashlytics {Platform.OS !== "web" &&
          this.props.adUnlock.adUnlockOptionEnabled &&
          !this.props.loadingNewGame && (
            <GameRewardedAd
              adRequestLocation="gameCreate"
              isUnlock={true}
              adIDRemote={this.props.adUnlock.adIDRemote}
              adID={this.props.adUnlock.adID}
              adKey={this.props.adUnlock.adKey}
            />
          )} */}
				{this.renderLeftButton()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	containerStyle: {
		flexDirection: "row",
		justifyContent: "flex-end",
		paddingTop: 15,
		paddingBottom: 5,
	},
	defButtonStyle: {
		flex: 2,
		height: 40,
		backgroundColor: "transparent",
		borderWidth: 0,
	},
	btnTextStyle: {
		width: "100%",
		fontFamily: appFont,
		fontSize: 14,
		paddingTop: 10,
		paddingBottom: 10,
		textAlign: "center",
		color: "#FFFFFF",
		textShadowColor: "#397423",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: Platform.OS !== "web" ? 0.001 : 0,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		createNewGameButtonsWidth: state.views.createNewGameButtonsWidth,
		publishGameJSON: state.createNewGame.publishGameJSON,
		players: state.createNewGame.players,
		canStartGame: state.createNewGame.canStartGame,
		loadingNewGame: state.createNewGame.loadingNewGame,
		// canUnlock: state.currentUser.features.generic.canUnlock,
		adUnlock: state.createNewGame.adUnlock,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = { publishGame };

export default connect(mapStateToProps, mapDispatchToProps)(CreateGameButtons);
