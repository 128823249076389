import { cloneDeep, orderBy } from "lodash";
import { defaultCardsetID, gameButton } from "../../config/defaults";
import * as helpers from "../../helpers/commonHelpers";
import { gameBoardTheme } from "../../Keezers/config/defaults";
import {
	APPLY_FILTER,
	CLEAR_GAME_LIST_MODULE,
	CLEAR_GAME_SELECTION,
	ENABLE_JOIN_GAME_BUTTON,
	GAME_LIST_HEADER_CELL_PRESSED,
	GAME_LIST_SUBSCRIBED_RECEIVED,
	GAME_LIST_UNSUBSCRIBED_RECEIVED,
	INSERT_NEW_GAME,
	MOUSEENTER_GAME_IN_LOBBY,
	MOUSELEAVE_GAME_IN_LOBBY,
	MOUSE_ENTERED_GAME_BUTTON,
	MOUSE_LEAVED_GAME_BUTTON,
	RECEIVE_GAME_PLAYER_INFO,
	REMOVE_GAME,
	RESET_CLIENT,
	RESET_FILTER,
	SELECT_GAME_IN_LOBBY,
	SET_FILTER,
	SET_FILTER_LABELS,
	SUBSCRIBE_TO_GAME_LIST,
	UPDATE_PLAYER_AMOUNT_FILTER,
} from "../actions/actionTypes";

const initialState = {
	gameListOrig: [],
	gameListFiltered: [],
	subscribeID: -1,
	isSubscribeInProgress: false,
	selectedGameID: -1,
	filter: {
		playerCnt_3: {
			default: {
				labels: [2, 3, 4],
				checks: { 0: true, 1: true, 2: true },
			},
			active: {
				0: true,
				1: true,
				2: true,
			},
		},
		playerCnt_4: {
			default: {
				labels: [5, 6, 7, 8],
				checks: { 0: true, 1: true, 2: true, 3: true },
			},
			active: {
				0: true,
				1: true,
				2: true,
				3: true,
			},
		},
		chipsRequired: {
			default: {
				labels: ["", ""],
				checks: { 0: true, 1: true },
			},
			active: { 0: true, 1: true },
		},
		onlyLocalLanguage: {
			default: {
				labels: ["", ""],
				checks: { 0: true, 1: true },
			},
			active: { 0: true, 1: true },
		},
		onlyFriends: {
			default: {
				labels: ["", ""],
				checks: { 0: true, 1: true },
			},
			active: { 0: true, 1: true },
		},
	},
	gameListTableHeaderColumns: {
		creatorCountry: {
			isSelected: false,
			isAscending: false,
		},
		onlyFriends: {
			isSelected: false,
			isAscending: false,
		},
		name: {
			isSelected: false,
			isAscending: false,
		},
		minLevel: {
			isSelected: false,
			isAscending: false,
		},
		maxLevel: {
			isSelected: false,
			isAscending: false,
		},
		playerCnt: {
			isSelected: false,
			isAscending: false,
		},
		freeSlots: {
			isSelected: false,
			isAscending: false,
		},
		chipsRequired: {
			isSelected: false,
			isAscending: false,
		},
	},
	joinGameButton: {
		enabled: false,
		hovered: false,
	},
	createNewGameButton: {
		enabled: true,
		hovered: false,
	},
	selectedGamesRulesList: [],
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SUBSCRIBE_TO_GAME_LIST:
			return {
				...state,
				gameListOrig: [],
				gameListFiltered: [],
				selectedGameID: -1,
				isSubscribeInProgress: true,
				selectedGamesRulesList: [],
			};
		case GAME_LIST_SUBSCRIBED_RECEIVED:
			if (typeof payload === "undefined" || typeof payload.subscribeID === "undefined") return state;
			return {
				...state,
				subscribeID: payload.subscribeID,
				isSubscribeInProgress: false,
			};
		case GAME_LIST_UNSUBSCRIBED_RECEIVED:
			if (
				typeof payload === "undefined" ||
				typeof payload.subscribeID === "undefined" ||
				payload.subscribeID != state.subscribeID
			)
				return state;
			return {
				...state,
				gameListOrig: [],
				gameListFiltered: [],
				subscribeID: initialState.subscribeID,
				isSubscribeInProgress: false,
			};
		case INSERT_NEW_GAME:
			//TODO: put this back if subscribeID will be received if (state.subscribeID != payload.subscribeID) return state;
			var newOrigList = [...state.gameListOrig];
			var newFilteredList = [...state.gameListFiltered];
			payload.games.forEach((game) => {
				game.subscribeID = payload.subscribeID || state.subscribeID;
				game.cardset = typeof game.cardset === "number" ? game.cardset : defaultCardsetID;
				var modif_OrigGameIndex = newOrigList.findIndex((obj) => obj.gameID == game.gameID);
				if (/* payload.games.length == 1 && */ modif_OrigGameIndex != -1) {
					newOrigList[modif_OrigGameIndex].freeSlots = game.freeSlots;
					newOrigList[modif_OrigGameIndex].gameState = game.gameState;
					newOrigList[modif_OrigGameIndex].progressInPercent = game.progressInPercent;
					newOrigList[modif_OrigGameIndex].name = game.name;
					newOrigList[modif_OrigGameIndex].subscribeID = game.subscribeID || state.subscribeID;
					newOrigList[modif_OrigGameIndex].isUpdatedInGameList = true;
					game.isUpdatedInGameList = true;
					newOrigList[modif_OrigGameIndex].isNewlyInserted = false;
					game.isNewlyInserted = false;
					var modif_FilteredGameIndex = newFilteredList.findIndex((obj) => obj.gameID == game.gameID);
					if (modif_FilteredGameIndex != -1) {
						newFilteredList[modif_FilteredGameIndex].freeSlots = game.freeSlots;
						newOrigList[modif_FilteredGameIndex].gameState = game.gameState;
						newOrigList[modif_FilteredGameIndex].progressInPercent = game.progressInPercent;
						newFilteredList[modif_FilteredGameIndex].name = game.name;
						newFilteredList[modif_FilteredGameIndex].subscribeID = game.subscribeID || state.subscribeID;
					}
				} else {
					game.isSelected = false;
					game.isHovered = false;
					game.isUpdatedInGameList = false;
					game.isNewlyInserted = true;
					newOrigList.push(game); // = [...newOrigList, ...payload.games];
					newFilteredList.push(game); // = [...newFilteredList, ...payload.games];
				}
			});
			newOrigList = orderBy(newOrigList, ["gameState", "gameID"], ["asc", "asc"]);
			newFilteredList = orderBy(newFilteredList, ["gameState", "gameID"], ["asc", "asc"]);
			return {
				...state,
				gameListOrig: newOrigList,
				gameListFiltered: newFilteredList,
			};
		case REMOVE_GAME:
			//TODO: put this back if subscribeID will be received if (state.subscribeID != payload.subscribeID) return state;
			var gameList = [...state.gameListOrig];
			var filteredList = [...state.gameListFiltered];
			var removableOrigGameIndex = gameList.findIndex((obj) => obj.gameID == payload.gameID);
			var removableFilteredGameIndex = filteredList.findIndex((obj) => obj.gameID == payload.gameID);
			if (removableOrigGameIndex != -1) gameList.splice(removableOrigGameIndex, 1);
			if (removableFilteredGameIndex != -1) filteredList.splice(removableFilteredGameIndex, 1);
			if (payload.gameID == state.selectedGameID) {
				state.selectedGameID = initialState.selectedGameID;
				state.selectedGamesRulesList = initialState.selectedGamesRulesList;
			}
			return {
				...state,
				gameListOrig: gameList,
				gameListFiltered: filteredList,
			};
		case GAME_LIST_HEADER_CELL_PRESSED:
			var tmp = { ...state.gameListTableHeaderColumns };
			var resultOrig = [...state.gameListOrig];
			var resultFiltered = [...state.gameListFiltered];

			helpers.mapObject(tmp, (key, value) => {
				if (key === payload) {
					value.isSelected = true;
					value.isAscending = !value.isAscending;
				} else {
					value.isSelected = false;
					value.isAscending = false;
				}
			});
			resultOrig = helpers.sortObjectArray(resultOrig, payload, tmp[payload].isAscending);
			resultFiltered = helpers.sortObjectArray(resultFiltered, payload, tmp[payload].isAscending);
			return {
				...state,
				gameListTableHeaderColumns: tmp,
				gameListOrig: resultOrig,
				gameListFiltered: resultFiltered,
			};
		case SELECT_GAME_IN_LOBBY:
			var newSelGameID = initialState.selectedGameID;
			var newSelectedGamesRulesList = initialState.selectedGamesRulesList;
			var selList = [...state.gameListFiltered];
			selList.map((obj) => (obj.isSelected = false));
			var selectedGame = selList.find((obj) => obj.gameID == payload.selectedGameId);
			selectedGame.isSelected = true;
			newSelGameID = selectedGame.gameID;
			var cardsetContent = helpers.mapObject(payload.lang.cardsets, (key, value) => {
				if (value.cardsetID == selectedGame.cardset) return value;
			});
			cardsetContent = cardsetContent.find((obj) => obj !== undefined).name;
			var gameThemeContent = "";
			gameThemeContent = helpers.mapObject(gameBoardTheme, (key, val) => {
				if (key === selectedGame.gameTheme) return val[0].toUpperCase() + val.slice(1).toLowerCase();
			});
			newSelectedGamesRulesList = [
				{
					ruleID: 1,
					ruleName: "onlyFriends",
					displayHelpText: false,
					label: payload.lang.gameOptions.onlyFriends,
					content: selectedGame.localLanguagesOnly,
					helpText: payload.lang.miniHelp.onlyFriends,
				},
				{
					ruleID: 2,
					ruleName: "localLanguagesOnly",
					displayHelpText: false,
					label: payload.lang.gameOptions.localLanguagesOnly,
					content: selectedGame.localLanguagesOnly,
					helpText: payload.lang.miniHelp.localLanguagesOnly,
				},
				{
					ruleID: 3,
					ruleName: "localCountryOnly",
					displayHelpText: false,
					label: payload.lang.gameOptions.localCountryOnly,
					content: selectedGame.localCountryOnly,
					helpText: payload.lang.miniHelp.localCountryOnly,
				},
				{
					ruleID: 4,
					ruleName: "chipsRequired",
					displayHelpText: false,
					label: payload.lang.gameOptions.chipsRequired,
					content: selectedGame.chipsRequired.toString(),
					helpText: payload.lang.miniHelp.chipsRequired,
				},
				{
					ruleID: 5,
					ruleName: "privateGame",
					displayHelpText: false,
					label: payload.lang.gameOptions.privateGame,
					content: selectedGame.isPrivateGame,
					helpText: payload.lang.miniHelp.privateGame,
				},
				{
					ruleID: 6,
					ruleName: "enableTeams",
					displayHelpText: false,
					label: payload.lang.gameOptions.enableTeams,
					content: selectedGame.teams,
					helpText: payload.lang.miniHelp.enableTeams,
				},
				{
					ruleID: 7,
					ruleName: "playerLevel",
					displayHelpText: false,
					label: payload.lang.gameOptions.playerLevel,
					helpText: payload.lang.miniHelp.playerLevel,
					content:
						// payload.lang.min +
						// ": " +
						selectedGame.minLevel +
						" - " +
						// payload.lang.max +
						// ": " +
						selectedGame.maxLevel,
					minLang: payload.lang.min,
					maxLang: payload.lang.max,
					selectedMinValue: selectedGame.minLevel,
					selectedMaxValue: selectedGame.maxLevel,
				},
				{
					ruleID: 8,
					ruleName: "cardset",
					displayHelpText: false,
					label: payload.lang.gameOptions.cardset,
					content: cardsetContent,
					helpText: payload.lang.miniHelp.cardset,
				},
				{
					ruleID: 9,
					ruleName: "fairDeal",
					displayHelpText: false,
					label: payload.lang.gameOptions.fairDeal,
					content: selectedGame.enableFairDealing,
					helpText: payload.lang.miniHelp.fairDeal,
				},
				{
					ruleID: 10,
					ruleName: "gameTheme",
					displayHelpText: false,
					label: payload.lang.gameOptions.gameTheme,
					content: gameThemeContent,
					helpText: payload.lang.miniHelp.gameTheme,
				},
			];
			return {
				...state,
				gameListFiltered: selList,
				selectedGameID: newSelGameID,
				selectedGamesRulesList: newSelectedGamesRulesList,
			};
		case MOUSEENTER_GAME_IN_LOBBY:
			var selList = [...state.gameListFiltered];
			selList.map((obj) => (obj.isHovered = false));
			var hoveredGame = selList.find((obj) => obj.gameID == payload);
			hoveredGame.isHovered = true;
			return { ...state, gameListFiltered: selList };
		case MOUSELEAVE_GAME_IN_LOBBY:
			var selList = [...state.gameListFiltered];
			var hoveredGame = selList.find((obj) => obj.gameID == payload);
			hoveredGame.isHovered = false;
			return { ...state, gameListFiltered: selList };
		case SET_FILTER_LABELS:
			var newState = { ...state };
			newState.filter.chipsRequired.default.labels = payload.yesNo;
			newState.filter.onlyLocalLanguage.default.labels = payload.yesNo;
			newState.filter.onlyFriends.default.labels = payload.yesNo;
			return newState;
		case UPDATE_PLAYER_AMOUNT_FILTER:
			const { enableFiveOrMorePlayers } = payload;
			var newState = {
				...state,
				filter: {
					...state.filter,
					playerCnt_4: {
						...state.filter.playerCnt_4,
						default: {
							...state.filter.playerCnt_4.default,
							checks: {
								0: enableFiveOrMorePlayers,
								1: enableFiveOrMorePlayers,
								2: enableFiveOrMorePlayers,
								3: enableFiveOrMorePlayers,
							},
						},
						active: {
							0: enableFiveOrMorePlayers,
							1: enableFiveOrMorePlayers,
							2: enableFiveOrMorePlayers,
							3: enableFiveOrMorePlayers,
						},
					},
				},
			};
			return newState;
		case SET_FILTER:
			return helpers.getStateAfterSetFilter(state, payload);
		case RESET_FILTER:
			const defFilter = helpers.getDefaultFilters(state.filter);
			return { ...state, filter: defFilter };
		case APPLY_FILTER:
			state.gameListOrig.map((game) => (game.isHiddenByFilter = false));
			var filteredGames = helpers.getFilteredGameList([...state.gameListOrig], state.filter);
			//filteredGames = [...filteredGames];
			var newFilteredGames = [];
			filteredGames.forEach((g) => {
				if (g.subscribeID == state.subscribeID) newFilteredGames.push(g);
			});
			var newSelGameID = state.selectedGameID;
			var newSelGamesRulesList = state.selectedGamesRulesList;
			var selGameIndex = newFilteredGames.findIndex((obj) => obj.gameID == newSelGameID);
			if (selGameIndex == -1) {
				newSelGameID = initialState.selectedGameID;
				newSelGamesRulesList = initialState.selectedGamesRulesList;
			}
			return {
				...state,
				selectedGameID: newSelGameID,
				selectedGamesRulesList: newSelGamesRulesList,
				gameListFiltered: newFilteredGames,
			};
		case ENABLE_JOIN_GAME_BUTTON:
			var selectedGame = state.gameListFiltered.find((obj) => obj.gameID == state.selectedGameID);
			var isEnabled = typeof selectedGame !== "undefined" && selectedGame.freeSlots > 0;
			return {
				...state,
				joinGameButton: { ...state.joinGameButton, enabled: isEnabled },
			};
		case MOUSE_ENTERED_GAME_BUTTON:
			switch (payload) {
				case gameButton.JOIN_GAME:
					return {
						...state,
						joinGameButton: { ...state.joinGameButton, hovered: true },
					};
				case gameButton.CREATE_GAME:
					return {
						...state,
						createNewGameButton: {
							...state.createNewGameButton,
							hovered: true,
						},
					};
				default:
					return state;
			}
		case MOUSE_LEAVED_GAME_BUTTON:
			switch (payload) {
				case gameButton.JOIN_GAME:
					return {
						...state,
						joinGameButton: { ...state.joinGameButton, hovered: false },
					};
				case gameButton.CREATE_GAME:
					return {
						...state,
						createNewGameButton: {
							...state.createNewGameButton,
							hovered: false,
						},
					};
				default:
					return state;
			}
		case CLEAR_GAME_SELECTION:
			var newSelGameID = initialState.selectedGameID;
			var newSelectedGamesRulesList = initialState.selectedGamesRulesList;
			var selList = [...state.gameListFiltered];
			selList.map((obj) => (obj.isSelected = false));
			return {
				...state,
				gameListFiltered: selList,
				selectedGameID: newSelGameID,
				selectedGamesRulesList: newSelectedGamesRulesList,
			};
		case RECEIVE_GAME_PLAYER_INFO:
			if (
				typeof payload.isLobbyOpen !== "boolean" ||
				!payload.isLobbyOpen ||
				typeof payload.selLobbyGameID === "undefined" ||
				typeof payload.msg.data === "undefined" ||
				payload.msg.data === null ||
				typeof payload.msg.data.generic === "undefined" ||
				payload.msg.data.generic === null ||
				typeof payload.msg.data.generic.gameID === "undefined" ||
				payload.msg.data.generic.gameID != payload.selLobbyGameID
			)
				return state;
			var newOrigGameList = [...state.gameListOrig];
			var origGame = newOrigGameList.find((g) => g.gameID == payload.selLobbyGameID);
			if (origGame == undefined) return state;

			var newSelectedGamesRulesList = cloneDeep(state.selectedGamesRulesList);
			newSelectedGamesRulesList.forEach((rule) => {
				switch (rule.ruleName) {
					case "onlyFriends":
						rule.content = payload.msg.data.generic.friendsOnly || false;
						break;
					case "localLanguagesOnly":
						rule.content = payload.msg.data.generic.localLanguageOnly || false;
						break;
					case "localCountryOnly":
						rule.content = payload.msg.data.generic.localCountryOnly || false;
						break;
					case "chipsRequired":
						rule.content = payload.msg.data.generic.chipAmount ? payload.msg.data.generic.chipAmount.toString() : "0";
						break;
					case "enableTeams":
						rule.content =
							payload.msg.data.specific && payload.msg.data.specific.teamsEnabled
								? payload.msg.data.specific.teamsEnabled
								: false;
						break;
					case "playerLevel":
						var _minRate = payload.msg.data.generic.minRating,
							_maxRate = payload.msg.data.generic.maxRating,
							_minRateDefined = typeof _minRate !== "undefined",
							_maxRateDefined = typeof _maxRate !== "undefined";

						rule.content = _minRateDefined && _maxRateDefined ? _minRate + " - " + _maxRate : "";
						rule.selectedMinValue = _minRateDefined ? _minRate : 0;
						rule.selectedMaxValue = _maxRateDefined ? _maxRate : 0;
						break;
					case "cardset":
						rule.content =
							payload.msg.data.specific &&
							payload.msg.data.specific.cardset &&
							payload.msg.data.specific.cardset != null
								? payload.msg.data.specific.cardset
								: "";
						break;
					case "gameTheme":
						rule.content =
							payload.msg.data.specific &&
							payload.msg.data.specific.gameTheme &&
							payload.msg.data.specific.gameTheme != null
								? payload.msg.data.specific.gameTheme
								: "";
						break;
					case "fairDeal":
						rule.content =
							payload.msg.data.specific && payload.msg.data.specific.enableFairDealing
								? payload.msg.data.specific.enableFairDealing
								: false;
						break;
					case "privateGame":
						rule.content = payload.msg.data.generic.isPrivateGame || false;
						break;
				}
			});
			return { ...state, selectedGamesRulesList: newSelectedGamesRulesList };
		case CLEAR_GAME_LIST_MODULE:
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
