//#region FetchData actions
export const GET_BOARD_COORDINATES = "get_board_coordinates";
export const GET_BOARD_COORDINATES_SUCCESS = "get_board_coordinates_success";
export const CHECK_FETCH_IN_PROGRESS = "check_fetch_in_progress";
//#endregion

//#region StartedGame actions
export const SPECTATED_GAME_CLOSED = "spectated_game_closed";
export const SPECTATE_GAME_STARTED = "spectate_game_started";
export const SPECTATE_GAME_STOPPED = "spectate_game_stopped";
export const UPDATE_MESSAGES_COLORS = "update_messages_colors";
export const SWAP_PLAYER_PANELS = "swap_player_panels";
export const MOVE_BOARD_PAWNS = "move_board_pawns";
export const NEW_GAME_STARTED = "new_game_started";
export const GAME_PLAYER_LEFT_GAME = "game_player_left_game";
export const GAME_PLAYER_CONNECTED = "game_player_connected";
export const GAME_PLAYER_DISCONNECTED = "game_player_disconnected";
export const FOCUS_IN_GAME_CHAT_INPUT = "focus_in_game_chat_input";
export const FOCUS_OUT_GAME_CHAT_INPUT = "focus_out_game_chat_input";
export const GAME_BOARD_RENDERED = "game_board_rendered";
export const SET_BOARD_COORDINATES = "set_board_coordinates";
export const SET_PLAYER_PANELS_COLOR = "set_player_panels_color";
export const SET_BOARD_POSITIONS_BASE_COLOR = "set_board_positions_base_color";
export const GAME_STARTED_RECEIVED = "game_started_received";
export const GAME_INFO_RECEIVED = "game_info_received";
export const GAME_PLAYER_TO_ACT_RECEIVED = "game_player_to_act_received";
export const RESET_GAME_PLAYER_TO_ACT = "reset_game_player_to_act";
export const GAME_PLAYER_ACTED_RECEIVED = "game_player_acted_received";
export const RESET_GAME_PLAYER_ACTED = "reset_game_player_acted";
export const GAME_END_RECEIVED = "game_end_received";
export const RESET_GAME_END = "reset_game_end";
export const SAVE_START_POSITIONS_DIRECTION = "save_start_positions_directions";
export const CLEAR_START_POSITIONS_DIRECTION = "clear_start_positions_directions";
export const RESET_STARTED_GAME = "reset_started_game";
export const RESET_LAST_MOVE_EFFECT = "reset_last_move_effect";
export const RESET_PLAYABLE_POSITIONS = "reset_playable_positions";
export const RESET_PLAYABLE_CARDS = "reset_playable_cards";
export const ENABLE_THROW_AWAY = "enable_throw_away";
export const DISABLE_THROW_AWAY = "disable_throw_away";
export const SET_PLAYABLE_CARDS = "set_playable_cards";
export const SET_PLAYABLE_PAWNS = "set_playable_pawns";
export const SELECT_CARD = "select_card";
export const SELECT_PAWN = "select_pawn";
export const SELECT_TARGETED_POSITION = "select_targeted_position";
export const PLAY_CARD = "play_card";
export const THROW_AWAY = "throw_away";
export const UPDATE_PAWNS = "update_pawns";
export const DEAL_CARD = "deal_card";
export const FILTER_AVAILABLE_MOVES = "filter_available_moves";
export const START_COUNTDOWN = "start_countdown";
export const FINISH_COUNTDOWN = "finish_countdown";
export const SET_TO_LATE_TIME = "set_to_late_time";
export const CLEAR_TO_LATE_TIME = "clear_to_late_time";
export const SET_WARNING_TIMER = "set_warning_timer";
export const RESET_WARNING_TIMER = "reset_warning_timer";
export const MOVE_CARD_TO_PLAYED_CARDS = "move_cards_to_played_cards";
export const MOVE_THROWN_CARD_TO_PLAYED_CARDS = "move_thrown_cards_to_played_cards";
export const MOVE_PAWNS = "move_pawns";
export const SET_MOVE_COUNT = "set_move_count";
export const RESET_MOVE_COUNT = "reset_move_count";
export const INCREASE_MOVE_COUNT = "increase_move_count";
export const CLEAR_PLAYED_CARDS = "clear_played_cards";
export const SHOW_DID_YOU_KNOW = "show_did_you_know";
export const START_LATE_MOVE_IN_SHOP = "start_late_move_in_shop";
export const FINISH_LATE_MOVE_IN_SHOP = "finish_late_move_in_shop";
export const ROLLBACK_MOVE = "rollback_move";
export const SET_NEXT_SLOT_TO_ACT = "set_next_slot_to_act";
export const REQUEST_REOPEN_GAME = "request_reopen_game";
export const GAME_REOPENED = "game_reopened";
export const OPEN_PANEL_MENU = "open_panel_menu";
export const CLOSE_PANEL_MENU = "close_panel_menu";
export const STORE_CREATED_SCREENSHOT = "store_created_screenshot";
export const PROCESS_IMAGE_STORED = "process_image_stored";
//#endregion
