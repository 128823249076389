import React from "react";
import { StyleSheet, View } from "react-native";

const TableHeader = (props) => {
	const { onLayout, style, children } = props;
	return (
		<View style={[styles.containerStyle, style || {}]} onLayout={onLayout}>
			{children}
		</View>
	);
};

const styles = StyleSheet.create({
	containerStyle: {
		flexDirection: "row",
		backgroundColor: /* Platform.OS === "web" ? "#318689" : */ "#23D1DB",
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		overflow: "hidden",
	},
});

export { TableHeader };
