import { cloneDeep } from "lodash";
import { Linking, Platform } from "react-native";
import { handleError } from "../components/ErrorHandler";
import { DEBUG_ENABLED, LOGGING_ENABLED } from "../config/permissions";
import { MyWebSocket } from "../connection";
import { store } from "../redux/store";
import DebugLogger from "./DebugLogger";
import { queueWaitTimeLimit } from "../config/defaults";

class EventQueue {
	static shared = EventQueue.shared ? EventQueue.shared : new EventQueue();
	constructor() {
		this.eventsInQueueList = [];
		this.isEventInProgress = false;
		this.isDealInProgress = false;
		this.currentEvent = null;
		this.swapPlayerPanels = () => {};
		this.isSwapPlayerPanelEventInProgress = false;
		this.openShop = () => {};
		this.openShopInProgress = false;
		this.closeGameHelp = () => {};
		this.handlingGameHang = false;
		this.processPurchaseStatus = () => {};
		this.processStoredImage = () => {};
		this.saveMessageList = () => {};
		this.receiveNewMessage = () => {};
		this.updateMessageDetails = () => {};
	}

	addEventToQueue = (_event) => {
		if (DEBUG_ENABLED) {
			console.log(`addEventToQueue called with _event = ${JSON.stringify(_event)}`);
		}

		try {
			if (_event && typeof _event.sMessageID !== "undefined") {
				if (
					(_event.type == "gamePlayerActed" && _event.otherPlayersSecondMove == true) ||
					(_event.type == "gamePlayerToAct" && _event.waitForSecond_7 == true)
				) {
					this.eventsInQueueList.unshift(_event);
				} else if (_event.type == "localMove") {
					this.eventsInQueueList.unshift(_event);
				} else {
					this.eventsInQueueList.push(_event);
				}

				if (DEBUG_ENABLED) {
					console.log("!!! QUEUE LIST AFTER CALLING addEventToQueue: !!!", cloneDeep(this.eventsInQueueList));
				}

				DebugLogger.shared.saveLog(
					{ eventQueueListLog: `Saving the current eventQueueList ${cloneDeep(this.eventsInQueueList)}` },
					true
				);
				DebugLogger.shared.saveLog({ addEventToQueueLog: `Added event to queue ${JSON.stringify(_event)}` }, true);
			}
		} catch (error) {
			handleError(error);
		}
	};

	startQueuedEvent = (_event) => {
		try {
			if (_event) {
				this.isEventInProgress = true;
				this.currentEvent = _event;
			}
		} catch (error) {
			handleError(error);
		}
	};

	finishQueuedEvent = () => {
		try {
			this.isEventInProgress = false;
			this.currentEvent = null;
		} catch (error) {
			handleError(error);
		}
	};

	requestNextEventExecution = (sMessageID, isProcessedEventRequested, delay) => {
		if (DEBUG_ENABLED) {
			console.log(
				`requestNextEventExecution called with sMessageID = ${sMessageID}, isProcessedEventRequested = ${isProcessedEventRequested}, delay = ${delay}`
			);
		}

		try {
			const { app, tab } = store.getState();
			const { isBackground, isSettingsOpened, isHelpOpened } = app;
			const { isShopOpen } = tab;
			isProcessedEventRequested =
				typeof isProcessedEventRequested !== "boolean" || sMessageID == 0 || sMessageID == undefined
					? false
					: isProcessedEventRequested;
			delay = typeof delay !== "undefined" ? delay : 0;
			var nextEventExecutionTimeout = null;
			clearTimeout(nextEventExecutionTimeout);
			const nextEventToProcess = cloneDeep(this.eventsInQueueList.shift(this.eventsInQueueList[0]));
			this.finishQueuedEvent();
			if (isBackground || isSettingsOpened || isHelpOpened || isShopOpen || this.eventsInQueueList.length == 0) {
				if (nextEventToProcess != undefined) {
					this.processServerEvent(nextEventToProcess);
				}
				if (isProcessedEventRequested) {
					MyWebSocket.shared.sendProcessedMessage(sMessageID);
				}
			} else {
				nextEventExecutionTimeout = setTimeout(() => {
					if (nextEventToProcess != undefined) {
						this.processServerEvent(nextEventToProcess);
					}
					if (isProcessedEventRequested) {
						MyWebSocket.shared.sendProcessedMessage(sMessageID);
					}
				}, delay);
			}

			DebugLogger.shared.saveLog(
				{ requestNextEventExecutionLog: `requestNextEventExecution called with sMessageID = ${sMessageID}` },
				true
			);
		} catch (error) {
			handleError(error);
		}
	};

	resetEventsQueue = () => {
		try {
			this.isDealInProgress = false;
			this.isEventInProgress = false;
			this.isSwapPlayerPanelEventInProgress = false;
			this.openShopInProgress = false;
			this.currentEvent = null;
			this.eventsInQueueList = [];
		} catch (error) {
			handleError(error);
		}
	};

	receiveEvent = (msg) => {
		if (DEBUG_ENABLED) {
			console.log(`receiveEvent called with msg = ${JSON.stringify(msg)}`);
		}

		try {
			if (msg && typeof msg.type !== "undefined") {
				if (msg.type == "gameDetails") {
					// this.isDealInProgress = true;
					if (msg.reason === "RESET") this.resetEventsQueue();
				}
				// if (msg.type == "gamePlayerToAct" && msg.isPlayerToAct == true) {
				msg.receivedAt = Date.now();
				// }
				if (typeof msg.processInQueue !== "undefined") {
					if (msg.processInQueue == true) {
						//check if game is hanging
						if (this.eventsInQueueList.length > 0) {
							const currentTime = Date.now();
							const firstWaitingTime =
								typeof this.eventsInQueueList[0].receivedAt === "number"
									? currentTime - this.eventsInQueueList[0].receivedAt
									: 0;
							if (firstWaitingTime >= queueWaitTimeLimit) {
								var _waitingEvents = [];
								this.eventsInQueueList.forEach((ev) => {
									if (ev && ev.sMessageID)
										_waitingEvents.push({
											sMessageID: ev.sMessageID,
											receivedAt: ev.receivedAt || null,
										});
								});
								var _hangLog = {
									gameHang: {
										firstWaitingEvent: {
											sMessageID: this.eventsInQueueList[0]
												? this.eventsInQueueList[0].sMessageID
												: this.eventsInQueueList[0],
											gameID: this.eventsInQueueList[0] ? this.eventsInQueueList[0].gameID : this.eventsInQueueList[0],
											receivedAt: this.eventsInQueueList[0]
												? this.eventsInQueueList[0].receivedAt
												: this.eventsInQueueList[0],
										},
										waitingEvents: _waitingEvents,
										currentTime: currentTime,
										waitingTime: firstWaitingTime,
										waitTimeLimitInMillisec: queueWaitTimeLimit,
										currentlyExecuting: this.currentEvent ? cloneDeep(this.currentEvent).sMessageID : this.currentEvent,
										isDealInProgress: cloneDeep(this.isDealInProgress),
										isEventInProgress: cloneDeep(this.isEventInProgress),
									},
								};
								MyWebSocket.shared.sendMsg({
									type: "clientException",
									sMessageID: 0,
									inThisSession: true,
									exception: "POSSIBLE_GAME_HANG",
									stackTrace: [],
									details: _hangLog,
								});
							} else {
								//eslint-disable-next-line
								if (DEBUG_ENABLED && __DEV__ && this.handlingGameHang == true) {
									console.log("game hang handler FINISHED");
								}
								this.handlingGameHang = false;
							}
						} else {
							//eslint-disable-next-line
							if (DEBUG_ENABLED && __DEV__ && this.handlingGameHang == true) {
								console.log("game hang handler FINISHED; list is empty");
							}
							this.handlingGameHang = false;
						}

						if (
							(msg.type != "gameDetails" && this.isDealInProgress) ||
							this.currentEvent !== null ||
							this.eventsInQueueList.length > 0
							// this.isEventInProgress ||
							// (msg.type != "gameDetails" && this.isDealInProgress) ||
							// this.currentEvent !== null
						) {
							this.addEventToQueue(msg);
						} else {
							this.addEventToQueue(msg);
							if (this.currentEvent !== null) {
								const { sMessageID, delay } = this.currentEvent;
								this.requestNextEventExecution(sMessageID, true, delay);
							} else {
								this.requestNextEventExecution(0, true, 0);
							}
							// this.processServerEvent(msg);
						}
					} else {
						this.processServerEvent(msg);
					}
				} else {
					this.processServerEvent(msg);
				}

				DebugLogger.shared.saveLog({ receiveEventLog: `receiveEvent called with msg = ${JSON.stringify(msg)}` }, true);
			}
		} catch (error) {
			handleError(error);
		}
	};

	processServerEvent = (msg) => {
		try {
			switch (msg.type) {
				case "openInGameShop":
					if (!this.openShopInProgress) {
						if (this.isDealInProgress || this.currentEvent !== null || this.isEventInProgress) {
							const exists = this.eventsInQueueList.findIndex((ev) => ev.type == "openInGameShop");
							if (exists == -1) {
								this.addEventToQueue(msg);
							} else {
								this.eventsInQueueList.splice(exists, 1, msg);
							}
						} else {
							DebugLogger.shared.saveLog({ OSB_message: "openShop started" }, true);
							this.openShopInProgress = true;
							this.startQueuedEvent(msg);
							this.closeGameHelp();
							this.openShop();
						}
					}
					break;
				case "swapPlayerPanels":
					if (!this.isSwapPlayerPanelEventInProgress) {
						if (this.isDealInProgress || this.currentEvent !== null || this.isEventInProgress) {
							const exists = this.eventsInQueueList.findIndex((ev) => ev.type == "swapPlayerPanels");
							if (exists == -1) {
								this.addEventToQueue(msg);
							} else {
								this.eventsInQueueList.splice(exists, 1, msg);
							}
						} else {
							if (typeof msg.curSlotID != "undefined" && typeof msg.newSlotID != "undefined") {
								this.isSwapPlayerPanelEventInProgress = true;
								this.startQueuedEvent(msg);
								this.swapPlayerPanels(msg.curSlotID, msg.newSlotID, true);
							}
						}
					}
					break;
				case "gameDetails":
					if (
						typeof msg.data === "object" &&
						msg.data !== null &&
						typeof msg.data.generic === "object" &&
						msg.data.generic != null &&
						msg.data.generic.state == "RUNNING"
					) {
						switch (msg.reason) {
							case "GAME_STARTED":
							case "RESET":
								if (this.currentEvent !== null || this.isEventInProgress) {
									this.addEventToQueue(msg);
								} else {
									this.isDealInProgress = true;
									MyWebSocket.shared.sendAckMessage(msg.sMessageID);
									MyWebSocket.shared.gameDetailsStartedRecevied(msg);
								}
								break;
							case "DEAL":
							case "NEW_ROUND":
							case "RECALCULATIONS":
								if (this.currentEvent !== null || this.isEventInProgress) {
									this.addEventToQueue(msg);
								} else {
									this.isDealInProgress = true;
									MyWebSocket.shared.sendAckMessage(msg.sMessageID);
									MyWebSocket.shared.processGameInfo(msg);
								}
								break;
						}
						MyWebSocket.shared.receiveGamePlayerInfo(msg);
					} else {
						this.processNonGameEvent(msg);
					}
					break;
				case "localMove":
					if (this.isDealInProgress || this.currentEvent !== null || this.isEventInProgress) {
						this.addEventToQueue(msg);
					} else {
						MyWebSocket.shared.processLocalMove(msg);
					}
					break;
				case "gamePlayerActed":
					if (DEBUG_ENABLED) console.log("!!! gamePlayerActed case entered inside EventQueue.js !!!");
					if (this.isDealInProgress || this.currentEvent !== null || this.isEventInProgress) {
						if (DEBUG_ENABLED) console.log("!!! adding msg to the event queue !!!", JSON.stringify(msg));
						this.addEventToQueue(msg);
					} else {
						if (DEBUG_ENABLED)
							console.log("!!! calling sendAckMessage and processGamePlayerActed from EventQueue.js !!!");
						MyWebSocket.shared.sendAckMessage(msg.sMessageID);
						MyWebSocket.shared.processGamePlayerActed(msg);
					}
					DebugLogger.shared.saveLog(
						{ gamePlayerActedLog: `gamePlayerActed called with msg = ${JSON.stringify(msg)}` },
						true
					);
					break;
				case "gamePlayerToAct":
					if (DEBUG_ENABLED) console.log("!!! gamePlayerToAct case entered inside EventQueue.js !!!");
					if (this.isDealInProgress || this.currentEvent !== null || this.isEventInProgress) {
						if (DEBUG_ENABLED) console.log("!!! adding msg to the event queue !!!", JSON.stringify(msg));
						this.addEventToQueue(msg);
					} else {
						if (DEBUG_ENABLED)
							console.log("!!! calling sendAckMessage and processGamePlayerToAct from EventQueue.js !!!");
						MyWebSocket.shared.sendAckMessage(msg.sMessageID);
						MyWebSocket.shared.processGamePlayerToAct(msg);
					}
					DebugLogger.shared.saveLog(
						{ gamePlayerToActLog: `gamePlayerToAct called with msg = ${JSON.stringify(msg)}` },
						true
					);
					break;
				case "gameEnd":
					if (this.isDealInProgress || this.currentEvent !== null || this.isEventInProgress) {
						this.addEventToQueue(msg);
					} else {
						MyWebSocket.shared.sendAckMessage(msg.sMessageID);
						MyWebSocket.shared.processGameEnd(msg);
					}
					break;
				default:
					this.processNonGameEvent(msg);
					break;
			}
		} catch (error) {
			handleError(error);
		}
	};

	processNonGameEvent = (msg) => {
		try {
			switch (msg.type) {
				case "messageList":
					this.saveMessageList(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "newMessage":
					this.receiveNewMessage(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "message":
					this.updateMessageDetails(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "imageStored":
					this.processStoredImage(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "success":
					MyWebSocket.shared.handleSuccessEvent(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "welcome":
					MyWebSocket.shared.handleWelcome(msg);
					break;
				case "upgradeRequired":
					MyWebSocket.shared.handleUpgradeRequired(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "ack":
					MyWebSocket.shared.processAckEvent(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "userDetails":
					if (
						typeof MyWebSocket.shared.authenticateRefreshProcess !== "undefined" ||
						MyWebSocket.shared.authenticateRefreshProcess !== null
					) {
						clearInterval(MyWebSocket.shared.authenticateRefreshProcess);
						MyWebSocket.shared.authenticateRefreshProcess = null;
					}
					MyWebSocket.shared.authenticateSucceeded(msg, MyWebSocket.shared.isAuthenticateRefreshing);
					if (typeof msg.usersOnline !== "undefined") MyWebSocket.shared.setOnlinePlayersAmount(msg.usersOnline);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "userOverview":
					MyWebSocket.shared.updateUserOverview(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "stackUpdate":
					MyWebSocket.shared.updateStack(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "ping":
					const pongMsg = {
						type: "pong",
						sMessageID: msg.sMessageID,
						localTimestamp: Date.now(),
					};
					MyWebSocket.shared.sendMsg(pongMsg);
					break;
				case "error":
					MyWebSocket.shared.processErrorEvent(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "disconnect":
					if (msg.reason != "MAINTENANCE_MODE") MyWebSocket.shared.startReconnect();
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "stats":
					if (typeof msg.usersOnline !== "undefined") MyWebSocket.shared.setOnlinePlayersAmount(msg.usersOnline);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "announcement":
					MyWebSocket.shared.processAnnouncement(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gameListSubscribed":
					MyWebSocket.shared.processGameListSubscribed(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gameListUnsubscribed":
					MyWebSocket.shared.processGameListUnsubscribed(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gameListUpdate":
					MyWebSocket.shared.insertNewGame(msg.games, msg.sMessageID, msg.subscribeID);
					if (typeof msg.usersOnline !== "undefined") MyWebSocket.shared.setOnlinePlayersAmount(msg.usersOnline);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gameListRemove":
					if (Array.isArray(msg.games)) {
						msg.games.forEach((gameID) => {
							MyWebSocket.shared.removeGame(gameID, msg.sMessageID, msg.subscribeID);
						});
					}
					if (typeof msg.usersOnline !== "undefined") MyWebSocket.shared.setOnlinePlayersAmount(msg.usersOnline);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gameClosed":
					MyWebSocket.shared.processGameClosedEvent(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gamePlayerInfo":
					MyWebSocket.shared.receiveGamePlayerInfo(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				// case "gameCreated":
				case "gameDetails":
					if (
						typeof msg.data === "object" &&
						msg.data !== null &&
						typeof msg.data.generic === "object" &&
						msg.data.generic != null &&
						msg.data.generic.state
					) {
						switch (msg.data.generic.state) {
							case "CREATED":
								MyWebSocket.shared.processGameCreated(msg);
								MyWebSocket.shared.sendAckMessage(msg.sMessageID);
								break;
							case "PUBLISHED":
								switch (msg.reason) {
									case "GAME_PUBLISHED":
										MyWebSocket.shared.processGamePublished(msg);
										break;
									case "GAME_JOINED":
										MyWebSocket.shared.processGameJoined(msg);
										break;
									case "GAME_LEFT":
										MyWebSocket.shared.updateGameRoomWithPlayerLeft(msg);
										break;
								}
								MyWebSocket.shared.sendAckMessage(msg.sMessageID);
								break;
						}
						MyWebSocket.shared.receiveGamePlayerInfo(msg);
					}
					break;
				case "gameStarted":
					MyWebSocket.shared.processGameStarted(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gameJoined":
					MyWebSocket.shared.processGameJoined(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gameNameUpdated":
					MyWebSocket.shared.processGameNameUpdated(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gameSlotUpdated":
					MyWebSocket.shared.processGameSlotUpdated(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gameChat":
				case "systemGameChat":
				case "spectatorGameChat":
					MyWebSocket.shared.processGameChat(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gamePlayerJoined":
					MyWebSocket.shared.processGamePlayerJoined(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gamePlayerLeft":
					const { isGameStared } = store.getState().tab;
					if (isGameStared == true) {
						MyWebSocket.shared.updateGameRoomWithPlayerLeft(msg);
						MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					} else {
						MyWebSocket.shared.gamePlayerLeftStartedGame(msg);
					}
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "chatListUpdate":
					if (typeof msg.usersOnline !== "undefined") MyWebSocket.shared.setOnlinePlayersAmount(msg.usersOnline);
					MyWebSocket.shared.processChatRoomListUpdate(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "chatRoomListRemove":
					if (typeof msg.usersOnline !== "undefined") MyWebSocket.shared.setOnlinePlayersAmount(msg.usersOnline);
					MyWebSocket.shared.processChatRoomListRemove(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "chatRoomJoined":
					if (typeof msg.userCntInRoom !== "undefined") MyWebSocket.shared.setOnlinePlayersAmount(msg.userCntInRoom);
					MyWebSocket.shared.processChatRoomJoined(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "chatRoomLeft":
					if (typeof msg.userCntInRoom !== "undefined") MyWebSocket.shared.setOnlinePlayersAmount(msg.userCntInRoom);
					MyWebSocket.shared.processChatRoomLeft(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "chatRoomCreated":
					MyWebSocket.shared.processChatRoomCreated(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "chatRoomUserUpdated":
					MyWebSocket.shared.chatRoomUserUpdated(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "chat":
					MyWebSocket.shared.receiveGlobalChatMessage(msg);
					MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "shopPay":
					if (typeof msg.action !== "undefined")
						switch (msg.action) {
							case "openNewWindowAndRedirect":
								if (typeof msg.actionData === "string" && msg.actionData != "") {
									if (Platform.OS !== "web") {
										Linking.canOpenURL(msg.actionData)
											.then((supported) => {
												if (!supported) {
													if (DEBUG_ENABLED) {
														console.log("Can't handle url: " + msg.actionData);
														if (LOGGING_ENABLED) {
															MyWebSocket.shared.addLog({
																title: "Can't handle url:",
																content: msg.actionData,
															});
														}
													}
												} else {
													Linking.openURL(msg.actionData);
												}
											})
											.catch((err) => {
												if (DEBUG_ENABLED) {
													console.error("An error occurred opening URL:", err);
													if (LOGGING_ENABLED) {
														MyWebSocket.shared.addLog({
															title: "An error occurred opening URL:",
															content: err,
														});
													}
												}
											});
									} else {
										MyWebSocket.shared.openNewWindowAndRedirect(msg.actionData);
									}
								}
						}
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "paymentPrepared":
					if (typeof msg.action !== "undefined")
						switch (msg.action) {
							case "OPEN_URL":
								if (typeof msg.actionData === "string" && msg.actionData != "") {
									if (Platform.OS !== "web") {
										Linking.canOpenURL(msg.actionData)
											.then((supported) => {
												if (!supported) {
													if (DEBUG_ENABLED) {
														console.log("Can't handle url: " + msg.actionData);
														if (LOGGING_ENABLED) {
															MyWebSocket.shared.addLog({
																title: "Can't handle url:",
																content: msg.actionData,
															});
														}
													}
												} else {
													Linking.openURL(msg.actionData);
												}
											})
											.catch((err) => {
												if (DEBUG_ENABLED) {
													console.log("An error occurred opening URL:", err);
													if (LOGGING_ENABLED) {
														MyWebSocket.shared.addLog({
															title: "An error occurred opening URL:",
															content: err,
														});
													}
												}
											});
									} else {
										MyWebSocket.shared.openNewWindowAndRedirect(msg.actionData);
									}
								}
						}
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "purchaseSuccessful":
					MyWebSocket.shared.buyItemSucceeded(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "addCreditSuccessful":
					MyWebSocket.shared.addCreditSucceeded(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "purchaseStatus":
					this.processPurchaseStatus(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "leaderBoardResults":
					MyWebSocket.shared.saveLeaderBoardsData(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "profileSettings":
					MyWebSocket.shared.handleProfileSettings(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "profileSettingsUpdated":
					MyWebSocket.shared.handleProfileSettingsUpdated(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "settings":
					MyWebSocket.shared.handleSettings(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "friendList":
					MyWebSocket.shared.updateFriendList(msg, "ALL");
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "banList":
					MyWebSocket.shared.updateBanList(msg, "ALL");
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "userList":
					MyWebSocket.shared.updateSearchedUsersList(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "loggedOut":
					MyWebSocket.shared.logOff();
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "shopInventory":
					MyWebSocket.shared.receiveShopInventory(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gamePlayerDisonnected":
					MyWebSocket.shared.gamePlayerDisconnected(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "gamePlayerConnected":
					MyWebSocket.shared.gamePlayerConnected(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "tournamentListUpdate":
					MyWebSocket.shared.insertTournaments(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "tournamentListRemoved":
					MyWebSocket.shared.removeTournaments(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "tournamentDetails":
					MyWebSocket.shared.saveTournamentDetails(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				case "registeredTournamentPlayer":
					MyWebSocket.shared.updateTournamentDetails(msg);
					MyWebSocket.shared.sendAckMessage(msg.sMessageID);
					break;
				default:
					break;
			}
		} catch (error) {
			handleError(error);
		}
	};
}

export { EventQueue };
