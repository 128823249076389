import { cloneDeep } from "lodash";
import { Image } from "react-native";
import { defaultClientLanguage, defaultClientResolution } from "../../config/connection";
import { RESOLUTION } from "../../config/defaults";
import { enableAllFeatures } from "../../config/permissions";
import * as helpers from "../../helpers/commonHelpers";
import {
	APP_LANGUAGE_UPDATED,
	APP_NOTIFICATIONS_UPDATED,
	AUTHENTICATE_SUCCEEDED,
	CARDS_ORDER_UPDATED,
	CHAT_TRANSLATION_UPDATED,
	CLEAR_SEARCHED_USERS_LIST,
	CLOSE_PLAYERS_LIST,
	DEFAULT_CARDSET_UPDATED,
	EMAIL_NOTIFICATIONS_UPDATED,
	GO_BACK_TO_MAIN,
	HANDLE_PROFILE_SETTINGS,
	HANDLE_SETTINGS,
	HANDLE_WELCOME,
	OVERRIDE_CARDSET_UPDATED,
	PROCESS_PLAYER_MOD,
	PROFILE_SETTINGS_UPDATED,
	REMOVE_EMAIL_CREDENTIALS,
	REMOVE_FACEBOOK_CREDENTIALS,
	RESET_CLIENT,
	SAVE_EMAIL_PASSWORD,
	SAVE_EMAIL_USERNAME,
	SAVE_FACEBOOK_USER_DETAILS,
	SAVE_FB_TOKEN,
	SAVE_FB_UID,
	SAVE_USER_DETAILS,
	SCREEN_RESOLUTION_UPDATED,
	SELECT_DEFAULT_CARDSET,
	SELECT_DEFAULT_CARD_SORT_ORDER,
	SELECT_DEFAULT_CARD_SUIT_ORDER,
	SELECT_DEFAULT_CARD_VALUE_ORDER,
	SET_FEATURES,
	SET_LOGGED_IN_STATE,
	SET_USER_AS_VIP,
	SHOW_RUNNING_GAMES_IN_LIST_UPDATED,
	SOUNDS_PREFERENCES_UPDATED,
	UNLOCK_CREATE_GAME_FEATURES,
	UPDATE_BAN_LIST,
	UPDATE_FRIEND_LIST,
	UPDATE_SEARCHED_USERS_LIST,
	UPDATE_STACK,
	UPDATE_USERNAME_SUCCEEDED,
	UPDATE_USER_OVERVIEW,
} from "../actions/actionTypes";

const initialState = {
	authenticated: false,
	isGuestUser: false,
	credentials: {
		email: {
			email: "",
			username: "",
			pwd: "",
		},
		facebook: {
			UID: "",
			token: "",
		},
	},
	fb_userDetails: null,
	userDetails: {
		username: "",
		userID: 0,
		firstName: "",
		lastName: "",
		country: "",
		gender: "",
		born: 0,
		city: "",
		wins: 0,
		losses: 0,
		disconnects: 0,
		ranking: 0,
		points: 0,
		score: 0,
		gamesPlayed: 0,
		languages: "",
		friendList: [],
		banList: [],
		colors: [],
		emojis: {},
		levelProgress: 0,
		minScore: 0,
		maxScore: 0,
		email: "",
		publicData: {},
		vipUntilTimestamp: 0,
		displayVipRed: false,
		streamingMode: false,
		stack: {
			gold: 0,
			silver: 0,
			chip: 0,
		},
		extraMultipliers: [],
		isVip: false,
		// openTournamentID: "",
	},
	features: {
		specific: {
			canSetTeams: true,
			canSetCardsets: true,
			canSetFairDealing: true,
			canSetPrivateGame: true,
		},
		generic: {
			game: "ENABLED",
			tournaments: "ENABLED",
			shop: "ENABLED",
			messaging: "ENABLED",
			testingOptions: "ENABLED",
			forum: "ENABLED",
			canSendMessage: true,
			leaderBoard: "ENABLED",
			adminOptions: "ENABLED",
			chat: "ENABLED",
			canCreateGame: true,
			deleteAccount: "ENABLED",
			canSetName: true,
			canSetFriendsMode: true,
			canSetChips: true,
			canSetPlayerLevel: true,
			canUnlock: false,
			canSetLanguageMode: true,
			canSetCountryMode: true,
			canPlayWith2Players: true,
			canPlayWith3Players: true,
			canPlayWith4Players: true,
			canPlayWith5Players: true,
			canPlayWith6Players: true,
			canPlayWith7Players: true,
			canPlayWith8Players: true,
		},
		disabledFeatureText: {
			canSetName: "",
			canSetFriendsMode: "",
			canSetChips: "",
			canSetPlayerLevel: "",
			canUnlock: "",
			canSetLanguageMode: "",
			canSetCountryMode: "",
			canPlayWith2Players: "",
			canPlayWith3Players: "",
			canPlayWith4Players: "",
			canPlayWith5Players: "",
			canPlayWith6Players: "",
			canPlayWith7Players: "",
			canPlayWith8Players: "",
		},
		//   GAME_CREATE_CAN_SET_CUSTOM_BOTS: true,
		//   GAME_CREATE_CAN_SET_NAME: true,
		//   GAME_CREATE_CAN_SET_TEAMS: true,
		//   GAME_CREATE_CAN_SET_PLAYER_LEVEL: true,
		//   GAME_CREATE_CAN_SET_LANGUAGE: true,
		//   GAME_CREATE_CAN_SET_COUNTRY: true,
		//   GAME_CREATE_CAN_SET_ONLY_FRIENDS: true,
		//   GAME_CREATE_CAN_SET_CARD_MODE: true,
		//   GAME_PLAY_CAN_PLAY_WITH_2_PLAYERS: true,
		//   GAME_PLAY_CAN_PLAY_WITH_3_PLAYERS: true,
		//   GAME_PLAY_CAN_PLAY_WITH_4_PLAYERS: true,
		//   GAME_PLAY_CAN_PLAY_WITH_5_PLAYERS: true,
		//   GAME_PLAY_CAN_PLAY_WITH_6_PLAYERS: true,
		//   GAME_PLAY_CAN_PLAY_WITH_7_PLAYERS: true,
		//   GAME_PLAY_CAN_PLAY_WITH_8_PLAYERS: true,
		//   USER_IS_VIP: true,
		//   USER_IS_ADMIN: true,
		//   USER_IS_CHILD: false,
		//   USER_IS_OPERATOR: true,
		//   GLOBAL_CHAT_CAN_CHAT: true,
		//   GLOBAL_CHAT_TRANSLATE: true,
		//   GLOBAL_CHAT_CAN_CREATE_NORMAL_ROOM: true,
		//   GLOBAL_CHAT_CAN_CREATE_PRIVATE_ROOM: true,
		//   GAME_LIST_CAN_FILTER_ON_CARD_MODE: true,
		//   GAME_LIST_CAN_FILTER_ON_LANGUAGE: true,
		//   GAME_LIST_CAN_FILTER_ON_PLAYERS: true,
		//   GAME_LIST_CAN_FILTER_ON_CHIPS: true,
		//   GAME_LIST_CAN_FILTER_ON_FRIENDS: true,
		//   GAME_PLAY_CAN_SWITCH_PLACES: true,
	},
	profileSettings: {
		username: "",
		country: "",
		isCountryVisible: true,
		city: "",
		isCityVisible: false,
		language: "EN",
		firstName: "",
		lastName: "",
		dateOfBirth: 0,
		gender: "",
		isFirstNameVisible: false,
		isLastNameVisible: false,
		isAgeVisible: false,
		isGenderVisible: false,
	},
	preferences: {
		clientLanguage: defaultClientLanguage,
		resolution: defaultClientResolution,
		overrideCardset: false,
		defaultCardset: "",
		defaultCardSuitOrder: "",
		defaultCardValueOrder: "",
		defaultCardSortOrder: "",
		availableCardsets: [],
		sortOrders: [],
		suitRankings: [],
		valueRankings: [],
		useLastMoveTracking: false,
		useFixedStartPosition: false,
		position2PlayerGame: 0,
		position3PlayerGame: 0,
		position4PlayerGame: 0,
		position5PlayerGame: 0,
		position6PlayerGame: 0,
		position7PlayerGame: 0,
		position8PlayerGame: 0,
		availableAppLanguages: { EN: "English", NL: "Dutch" },
		useChatTranslation: false,
		emailOnNewsLetter: true,
		emailOnMessage: true,
		showRunningGamesInList: true,
		playMenuButtonSound: true,
		playMiscButtonSound: true,
		playGameChatSound: true,
		playGlobalChatSound: true,
		useLoudToActSound: true,
		appNotifyOnNewFriendGame: true,
		appNotifyOnFriendOnline: true,
		appNotifyOnTournaments: true,
		appNotifyOnPrivateChat: true,
		appNotifyOnChat: true,
		appNotifyOnGroupChat: true,
		appNotifyOnGroupOnline: true,
		appNotifyOnMessage: true,
		useColoredGameChat: true,
	},
	friends: {},
	maxFriendListSize: 0,
	bannedUsers: {},
	maxBanListSize: 0,
	searchedUsers: {},
	isDebuggingUser: true,
	userOverview: {
		currScore: 0,
		currLevel: 0,
		startScore: 0,
		nextLevel: 0,
		nextScore: 0,
		gamesWon: 0,
		gamesLost: 0,
		globalPos: 0,
		yearlyPos: 0,
		monthlyPos: 0,
		weeklyPos: 0,
		isVip: false,
		isVipUntil: 0,
		multiplier: 1,
		timeJoined: 0,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_USER_OVERVIEW:
			if (!payload || payload.type != "userOverview") return state;
			return {
				...state,
				userOverview: {
					...state.userOverview,
					currScore: typeof payload.currScore === "number" ? payload.currScore : initialState.userOverview.currScore,
					currLevel: typeof payload.currLevel === "number" ? payload.currLevel : initialState.userOverview.currLevel,
					startScore:
						typeof payload.startScore === "number" ? payload.startScore : initialState.userOverview.startScore,
					nextLevel: typeof payload.nextLevel === "number" ? payload.nextLevel : initialState.userOverview.nextLevel,
					nextScore: typeof payload.nextScore === "number" ? payload.nextScore : initialState.userOverview.nextScore,
					gamesWon: typeof payload.gamesWon === "number" ? payload.gamesWon : initialState.userOverview.gamesWon,
					gamesLost: typeof payload.gamesLost === "number" ? payload.gamesLost : initialState.userOverview.gamesLost,
					globalPos:
						typeof payload.globalPosition === "number" ? payload.globalPosition : initialState.userOverview.globalPos,
					yearlyPos:
						typeof payload.yearlyPosition === "number" ? payload.yearlyPosition : initialState.userOverview.yearlyPos,
					monthlyPos:
						typeof payload.monthlyPosition === "number"
							? payload.monthlyPosition
							: initialState.userOverview.monthlyPos,
					weeklyPos:
						typeof payload.weeklyPosition === "number" ? payload.weeklyPosition : initialState.userOverview.weeklyPos,
					isVip: typeof payload.isVip === "boolean" ? payload.isVip : initialState.userOverview.isVip,
					isVipUntil:
						typeof payload.isVipUntil === "number" ? payload.isVipUntil : initialState.userOverview.isVipUntil,
					multiplier:
						payload.specific &&
						typeof payload.specific.multiplier !== "undefined" &&
						!isNaN(Number(payload.specific.multiplier))
							? Math.round(payload.specific.multiplier)
							: initialState.userOverview.multiplier,
					timeJoined:
						typeof payload.timeJoined === "number" ? payload.timeJoined : initialState.userOverview.timeJoined,
				},
			};
		case AUTHENTICATE_SUCCEEDED:
			return {
				...state,
				authenticated: true,
				isGuestUser:
					typeof payload !== "undefined" && typeof payload.isGuestUser === "boolean"
						? payload.isGuestUser
						: state.isGuestUser,
				/* isDebuggingUser:
          payload != undefined &&
          typeof payload.username === "string" &&
          payload.username.startsWith("test_"), */
			};
		case SET_LOGGED_IN_STATE:
			if (typeof payload !== "boolean" || payload == true) return state;
			return {
				...state,
				authenticated: false,
				userDetails: initialState.userDetails,
				fb_userDetails: null,
				isDebuggingUser: initialState.isDebuggingUser,
			};
		case SAVE_EMAIL_USERNAME:
			if (typeof payload !== "string") return state;
			var newCredentials = cloneDeep(state.credentials);
			newCredentials.email.username = payload;
			return { ...state, credentials: newCredentials };
		case SAVE_EMAIL_PASSWORD:
			if (typeof payload !== "string") return state;
			var newCredentials = cloneDeep(state.credentials);
			newCredentials.email.pwd = payload;
			return { ...state, credentials: newCredentials };
		case REMOVE_EMAIL_CREDENTIALS:
			var newCredentials = cloneDeep(state.credentials);
			newCredentials.email = initialState.credentials.email;
			return { ...state, credentials: newCredentials };
		case SAVE_FB_UID:
			if (typeof payload === "undefined") return state;
			var newCredentials = cloneDeep(state.credentials);
			newCredentials.facebook.UID = payload;
			return { ...state, credentials: newCredentials };
		case SAVE_FB_TOKEN:
			if (typeof payload === "undefined") return state;
			var newCredentials = cloneDeep(state.credentials);
			newCredentials.facebook.token = payload;
			return { ...state, credentials: newCredentials };
		case SAVE_FACEBOOK_USER_DETAILS:
			if (typeof payload === undefined) return state;
			return { ...state, fb_userDetails: payload };
		case REMOVE_FACEBOOK_CREDENTIALS:
			var newCredentials = cloneDeep(state.credentials);
			newCredentials.facebook = initialState.credentials.facebook;
			return { ...state, credentials: newCredentials, fb_userDetails: null };
		case SAVE_USER_DETAILS:
			var userDetails = helpers.getUserDetailsFromAuthenticatedMessage(payload);
			return { ...state, userDetails: userDetails };
		case SET_USER_AS_VIP:
			return { ...state, userDetails: { ...state.userDetails, isVip: true } };
		case UPDATE_USERNAME_SUCCEEDED:
			if (typeof payload === "undefined" || typeof payload.newUName !== "string") return state;
			return {
				...state,
				userDetails: { ...state.userDetails, username: payload.newUName },
			};
		case PROCESS_PLAYER_MOD:
			if (
				typeof payload === "undefined" ||
				(payload.type != "playerMod" && payload.type != "gamePlayerKick") ||
				typeof payload.userID === "undefined"
			)
				return state;

			const responseTo = payload;
			var newBanList = cloneDeep(state.userDetails.banList);
			var newFriendList = cloneDeep(state.userDetails.friendList);

			if (payload.type == "gamePlayerKick" && typeof payload.ban === "boolean" && payload.ban)
				responseTo.action = "banAdd";

			switch (responseTo.action) {
				case "banAdd":
					if (newBanList.indexOf(responseTo.userID) == -1) newBanList.push(responseTo.userID);
					var friendIndex = newFriendList.indexOf(responseTo.userID);
					if (friendIndex != -1) newFriendList.splice(friendIndex, 1);
					break;
				case "banDelete":
					var banIndex = newBanList.indexOf(responseTo.userID);
					if (banIndex != -1) newBanList.splice(banIndex, 1);
					break;
				case "friendAdd":
					var friendIndex = newFriendList.indexOf(responseTo.userID);
					if (friendIndex == -1) newFriendList.push(responseTo.userID);

					break;
				case "friendDelete":
					var friendIndex = newFriendList.indexOf(responseTo.userID);
					if (friendIndex != -1) newFriendList.splice(friendIndex, 1);
					break;
				default:
					break;
			}
			return {
				...state,
				userDetails: {
					...state.userDetails,
					banList: newBanList,
					friendList: newFriendList,
				},
			};
		case UPDATE_STACK:
			if (typeof payload === "undefined") return state;
			var newUserDetails = cloneDeep(state.userDetails);
			newUserDetails.score =
				typeof payload.score === "number" ? state.userDetails.score + payload.score : state.userDetails.score;
			newUserDetails.ranking = typeof payload.finalLevel === "number" ? payload.finalLevel : state.userDetails.ranking;
			newUserDetails.stack = {
				gold:
					typeof payload.gold === "number"
						? payload.gold
						: typeof payload.goldStack === "number"
						? payload.goldStack
						: state.userDetails.stack.gold,
				silver:
					typeof payload.silver === "number"
						? payload.silver
						: typeof payload.silverStack === "number"
						? payload.silverStack
						: state.userDetails.stack.silver,
				chip:
					typeof payload.chips === "number"
						? payload.chips
						: typeof payload.chipStack === "number"
						? payload.chipStack
						: state.userDetails.stack.chip,
			};
			return { ...state, userDetails: newUserDetails };
		case SET_FEATURES:
			if (typeof payload === "undefined" || typeof payload.features === "undefined" || payload.features === null)
				return state;
			var newFeatures = cloneDeep(state.features);
			helpers.mapObject(payload.features, (key, val) => {
				if (key == "specific") {
					helpers.mapObject(payload.features.specific, (k, v) => {
						newFeatures.specific[k] = enableAllFeatures || v;
					});
				} else if (key == "generic") {
					helpers.mapObject(payload.features.generic, (k, v) => {
						newFeatures.generic[k] = enableAllFeatures || v;
					});
				} else if (key == "disabledFeatureText") {
					helpers.mapObject(payload.features.disabledFeatureText, (k, v) => {
						if (newFeatures.disabledFeatureText && newFeatures.disabledFeatureText[k] != undefined)
							newFeatures.disabledFeatureText[k] = v;
					});
				}
			});
			return { ...state, features: newFeatures };
		case UNLOCK_CREATE_GAME_FEATURES:
			return {
				...state,
				features: {
					specific: {
						...state.features.specific,
						canSetTeams: true,
						canSetCardsets: true,
						canSetFairDealing: true,
						canSetPrivateGame: true,
					},
					generic: {
						...state.features.generic,
						canSetName: true,
						canSetFriendsMode: true,
						canSetChips: true,
						canSetPlayerLevel: true,
						canSetLanguageMode: true,
						canSetCountryMode: true,
						canPlayWith2Players: true,
						canPlayWith3Players: true,
						canPlayWith4Players: true,
						canPlayWith5Players: true,
						canPlayWith6Players: true,
						canPlayWith7Players: true,
						canPlayWith8Players: true,
					},
				},
			};
		case HANDLE_PROFILE_SETTINGS:
			if (payload == undefined) return state;
			return {
				...state,
				userDetails: {
					...state.userDetails,
					username: payload.username || state.userDetails.username,
					country: payload.country || state.userDetails.country,
					city: payload.city || state.userDetails.city,
					language: payload.language || state.userDetails.language,
					firstName: payload.firstName || state.userDetails.firstName,
					lastName: payload.lastName || state.userDetails.lastName,
					born: payload.dateOfBirth || state.userDetails.born,
					gender: payload.gender || state.userDetails.gender,
				},
				profileSettings: {
					...state.profileSettings,
					username: payload.username || state.profileSettings.username,
					country: payload.country || state.profileSettings.country,
					isCountryVisible:
						typeof payload.isCountryVisible === "boolean"
							? payload.isCountryVisible
							: state.profileSettings.isCountryVisible,
					city: payload.city || state.profileSettings.city,
					isCityVisible:
						typeof payload.isCityVisible === "boolean" ? payload.isCityVisible : state.profileSettings.isCityVisible,
					language: payload.language || state.profileSettings.language,
					firstName: payload.firstName || state.profileSettings.firstName,
					lastName: payload.lastName || state.profileSettings.lastName,
					dateOfBirth: payload.dateOfBirth || state.profileSettings.dateOfBirth,
					gender: payload.gender || state.profileSettings.gender,
					isFirstNameVisible:
						typeof payload.isFirstNameVisible === "boolean"
							? payload.isFirstNameVisible
							: state.profileSettings.isFirstNameVisible,
					isLastNameVisible:
						typeof payload.isLastNameVisible === "boolean"
							? payload.isLastNameVisible
							: state.profileSettings.isLastNameVisible,
					isAgeVisible:
						typeof payload.isAgeVisible === "boolean" ? payload.isAgeVisible : state.profileSettings.isAgeVisible,
					isGenderVisible:
						typeof payload.isGenderVisible === "boolean"
							? payload.isGenderVisible
							: state.profileSettings.isGenderVisible,
				},
			};
		case PROFILE_SETTINGS_UPDATED:
			if (payload == undefined) return state;
			return {
				...state,
				userDetails: {
					...state.userDetails,
					username: payload.username || state.userDetails.username,
					country: payload.country || state.userDetails.country,
					city: payload.city || state.userDetails.city,
					language: payload.language || state.userDetails.language,
					firstName: payload.firstName || state.userDetails.firstName,
					lastName: payload.lastName || state.userDetails.lastName,
					born: payload.dateOfBirth || state.userDetails.born,
					gender: payload.gender || state.userDetails.gender,
				},
				profileSettings: {
					...state.profileSettings,
					username: payload.username || state.profileSettings.username,
					country: payload.country || state.profileSettings.country,
					isCountryVisible:
						typeof payload.isCountryVisible === "boolean"
							? payload.isCountryVisible
							: state.profileSettings.isCountryVisible,
					city: payload.city || state.profileSettings.city,
					isCityVisible:
						typeof payload.isCityVisible === "boolean" ? payload.isCityVisible : state.profileSettings.isCityVisible,
					language: payload.language || state.profileSettings.language,
					firstName: payload.firstName || state.profileSettings.firstName,
					lastName: payload.lastName || state.profileSettings.lastName,
					dateOfBirth: payload.dateOfBirth || state.profileSettings.dateOfBirth,
					gender: payload.gender || state.profileSettings.gender,
					isFirstNameVisible:
						typeof payload.isFirstNameVisible === "boolean"
							? payload.isFirstNameVisible
							: state.profileSettings.isFirstNameVisible,
					isLastNameVisible:
						typeof payload.isLastNameVisible === "boolean"
							? payload.isLastNameVisible
							: state.profileSettings.isLastNameVisible,
					isAgeVisible:
						typeof payload.isAgeVisible === "boolean" ? payload.isAgeVisible : state.profileSettings.isAgeVisible,
					isGenderVisible:
						typeof payload.isGenderVisible === "boolean"
							? payload.isGenderVisible
							: state.profileSettings.isGenderVisible,
				},
			};
		case APP_LANGUAGE_UPDATED:
			if (payload == undefined || payload.newClientLanguage == undefined) return state;
			return {
				...state,
				preferences: {
					...state.preferences,
					clientLanguage: payload.newClientLanguage,
				},
			};
		case CHAT_TRANSLATION_UPDATED:
			if (payload == undefined || typeof payload.useChatTranslation !== "boolean") return state;
			return {
				...state,
				preferences: {
					...state.preferences,
					useChatTranslation: payload.useChatTranslation,
				},
			};
		case EMAIL_NOTIFICATIONS_UPDATED:
			if (payload == undefined) return state;
			return {
				...state,
				preferences: {
					...state.preferences,
					emailOnNewsLetter:
						typeof payload.emailOnNewsLetter === "boolean" ? payload.emailOnNewsLetter : preferences.emailOnNewsLetter,
					emailOnMessage:
						typeof payload.emailOnMessage === "boolean" ? payload.emailOnMessage : preferences.emailOnMessage,
				},
			};
		case SOUNDS_PREFERENCES_UPDATED:
			if (payload == undefined) return state;
			return {
				...state,
				preferences: {
					...state.preferences,
					playMiscButtonSound:
						typeof payload.playMiscButtonSound === "boolean"
							? payload.playMiscButtonSound
							: preferences.playMiscButtonSound,
					playMenuButtonSound:
						typeof payload.playMenuButtonSound === "boolean"
							? payload.playMenuButtonSound
							: preferences.playMenuButtonSound,
					playGameChatSound:
						typeof payload.playGameChatSound === "boolean" ? payload.playGameChatSound : preferences.playGameChatSound,
					playGlobalChatSound:
						typeof payload.playGlobalChatSound === "boolean"
							? payload.playGlobalChatSound
							: preferences.playGlobalChatSound,
					useLoudToActSound:
						typeof payload.useLoudToActSound === "boolean" ? payload.useLoudToActSound : preferences.useLoudToActSound,
				},
			};
		case APP_NOTIFICATIONS_UPDATED:
			if (payload == undefined) return state;
			return {
				...state,
				preferences: {
					...state.preferences,
					appNotifyOnNewFriendGame:
						typeof payload.appNotifyOnNewFriendGame === "boolean"
							? payload.appNotifyOnNewFriendGame
							: preferences.appNotifyOnNewFriendGame,
					appNotifyOnFriendOnline:
						typeof payload.appNotifyOnFriendOnline === "boolean"
							? payload.appNotifyOnFriendOnline
							: preferences.appNotifyOnFriendOnline,
					appNotifyOnTournaments:
						typeof payload.appNotifyOnTournaments === "boolean"
							? payload.appNotifyOnTournaments
							: preferences.appNotifyOnTournaments,
					appNotifyOnPrivateChat:
						typeof payload.appNotifyOnPrivateChat === "boolean"
							? payload.appNotifyOnPrivateChat
							: preferences.appNotifyOnPrivateChat,
					appNotifyOnChat:
						typeof payload.appNotifyOnChat === "boolean" ? payload.appNotifyOnChat : preferences.appNotifyOnChat,
					appNotifyOnGroupChat:
						typeof payload.appNotifyOnGroupChat === "boolean"
							? payload.appNotifyOnGroupChat
							: preferences.appNotifyOnGroupChat,
					appNotifyOnGroupOnline:
						typeof payload.appNotifyOnGroupOnline === "boolean"
							? payload.appNotifyOnGroupOnline
							: preferences.appNotifyOnGroupOnline,
					appNotifyOnMessage:
						typeof payload.appNotifyOnMessage === "boolean"
							? payload.appNotifyOnMessage
							: preferences.appNotifyOnMessage,
				},
			};
		case CARDS_ORDER_UPDATED:
			if (payload == undefined) return state;
			var newSortOrders = cloneDeep(state.preferences.sortOrders),
				newSuitRankings = cloneDeep(state.preferences.suitRankings),
				newValueRankings = cloneDeep(state.preferences.valueRankings);
			if (payload.selectedSort) {
				newSortOrders.forEach((el) => (el.selected = payload.selectedSort == el.name));
			}
			if (payload.selectedSuit) {
				newSuitRankings.forEach((el) => (el.selected = payload.selectedSuit == el.name));
			}
			if (payload.selectedValue) {
				newValueRankings.forEach((el) => (el.selected = payload.selectedValue == el.name));
			}
			return {
				...state,
				preferences: {
					...state.preferences,
					defaultCardSortOrder: payload.selectedSort || state.preferences.defaultCardSortOrder,
					defaultCardSuitOrder: payload.selectedSuit || state.preferences.defaultCardSuitOrder,
					defaultCardValueOrder: payload.selectedValue || state.preferences.defaultCardValueOrder,
					sortOrders: newSortOrders,
					suitRankings: newSuitRankings,
					valueRankings: newValueRankings,
				},
			};
		case HANDLE_WELCOME:
			if (payload == undefined || payload.appSettings == undefined) return state;
			var _resolution;
			switch (payload.appSettings.resolution) {
				case RESOLUTION.LOW:
				case RESOLUTION.MEDIUM:
				case RESOLUTION.HIGH:
					_resolution = payload.appSettings.resolution;
					break;
			}
			return {
				...state,
				preferences: {
					...state.preferences,
					showRunningGamesInList:
						typeof payload.appSettings.showRunningGamesInList === "boolean"
							? payload.appSettings.showRunningGamesInList
							: state.preferences.showRunningGamesInList,
					playMiscButtonSound:
						typeof payload.appSettings.playMiscButtonSound === "boolean"
							? payload.appSettings.playMiscButtonSound
							: state.preferences.playMiscButtonSound,
					playMenuButtonSound:
						typeof payload.appSettings.playMenuButtonSound === "boolean"
							? payload.appSettings.playMenuButtonSound
							: state.preferences.playMenuButtonSound,
					playGameChatSound:
						typeof payload.appSettings.playGameChatSound === "boolean"
							? payload.appSettings.playGameChatSound
							: state.preferences.playGameChatSound,
					playGlobalChatSound:
						typeof payload.appSettings.playGlobalChatSound === "boolean"
							? payload.appSettings.playGlobalChatSound
							: state.preferences.playGlobalChatSound,
					resolution: _resolution || state.preferences.resolution,
					clientLanguage: payload.appSettings.clientLanguage || state.preferences.clientLanguage,
				},
			};
		case SCREEN_RESOLUTION_UPDATED:
			if (payload == undefined || payload == null) return state;
			var _resolution;
			switch (payload.resolution) {
				case RESOLUTION.LOW:
				case RESOLUTION.MEDIUM:
				case RESOLUTION.HIGH:
					_resolution = payload.resolution;
					break;
			}
			return {
				...state,
				preferences: {
					...state.preferences,
					resolution: _resolution || state.preferences.resolution,
				},
			};
		case DEFAULT_CARDSET_UPDATED:
			if (payload == undefined || payload == null) return state;
			var _cardsets = cloneDeep(state.preferences.availableCardsets);
			if (payload.defaultCardset) {
				_cardsets.forEach((el) => (el.selected = el.name == payload.defaultCardset));
			}
			return {
				...state,
				preferences: {
					...state.preferences,
					defaultCardset: payload.defaultCardset || state.preferences.defaultCardset,
					availableCardsets: _cardsets,
				},
			};
		case OVERRIDE_CARDSET_UPDATED:
			if (payload == undefined || payload == null) return state;
			return {
				...state,
				preferences: {
					...state.preferences,
					overrideCardset:
						typeof payload.overrideCardset === "boolean" ? payload.overrideCardset : state.preferences.overrideCardset,
				},
			};
		case SHOW_RUNNING_GAMES_IN_LIST_UPDATED:
			if (payload == undefined || payload == null) return state;
			return {
				...state,
				preferences: {
					...state.preferences,
					showRunningGamesInList:
						typeof payload.showRunningGamesInList === "boolean"
							? payload.showRunningGamesInList
							: preferences.showRunningGamesInList,
				},
			};
		case HANDLE_SETTINGS:
			if (payload == undefined) return state;
			const { preferences } = state;

			var _clientLanguages = {},
				_cardsets = [],
				_sortOrders = [],
				_suitRankings = [],
				_valueRankings = [];
			if (payload.validValues) {
				if (payload.validValues.clientLanguage) {
					payload.validValues.clientLanguage.forEach((el) => (_clientLanguages[el.name] = el.text));
				}
				if (payload.validValues.defaultCardset) {
					payload.validValues.defaultCardset.forEach((item, index) => {
						item.cardsetImgURL = "https://" + payload.cardsetURL + item.name + "/preview.png";
						item.cardsetImgBackURL = "https://" + payload.cardsetURL + "/background.png";
						item.cardsetID = index;
						item.selected = payload.specific && item.name == payload.specific.defaultCardset;
						var newCardset = getPreloadedCardsetItem(item);
						_cardsets.push(newCardset);
					});
				}
				if (payload.validValues.cardSortOrder) {
					payload.validValues.cardSortOrder.forEach((item) => {
						item.selected = payload.specific && payload.specific.cardSortOrder == item.name;
						_sortOrders.push(item);
					});
				}
				if (payload.validValues.cardSuitOrder) {
					payload.validValues.cardSuitOrder.forEach((item) => {
						item.selected = payload.specific && payload.specific.cardSuitOrder == item.name;
						_suitRankings.push(item);
					});
				}
				if (payload.validValues.cardValueOrder) {
					payload.validValues.cardValueOrder.forEach((item) => {
						item.selected = payload.specific && payload.specific.cardValueOrder == item.name;
						_valueRankings.push(item);
					});
				}
			}
			return {
				...state,
				preferences: {
					...preferences,
					clientLanguage: payload.app.clientLanguage,
					resolution: payload.app.resolution,
					overrideCardset:
						typeof payload.specific.overrideCardset === "boolean"
							? payload.specific.overrideCardset
							: preferences.overrideCardset,
					defaultCardset: payload.specific.defaultCardset || preferences.defaultCardset,
					defaultCardSuitOrder: payload.specific.defaultCardSuitOrder || preferences.defaultCardSuitOrder,
					defaultCardValueOrder: payload.specific.defaultCardValueOrder || preferences.defaultCardValueOrder,
					defaultCardSortOrder: payload.specific.defaultCardSortOrder || preferences.defaultCardSortOrder,
					availableCardsets: _cardsets,
					sortOrders: _sortOrders,
					suitRankings: _suitRankings,
					valueRankings: _valueRankings,
					useLastMoveTracking:
						typeof payload.specific.useLastMoveTracking === "boolean"
							? payload.specific.useLastMoveTracking
							: preferences.useLastMoveTracking,
					useFixedStartPosition:
						typeof payload.specific.useFixedStartPosition === "boolean"
							? payload.specific.useFixedStartPosition
							: preferences.useFixedStartPosition,
					position2PlayerGame:
						typeof payload.specific.position2Players !== "undefined"
							? payload.specific.position2Players
							: preferences.position2Players,
					position3PlayerGame:
						typeof payload.specific.position3Players !== "undefined"
							? payload.specific.position3Players
							: preferences.position3Players,
					position4PlayerGame:
						typeof payload.specific.position4Players !== "undefined"
							? payload.specific.position4Players
							: preferences.position4Players,
					position5PlayerGame:
						typeof payload.specific.position5Players !== "undefined"
							? payload.specific.position5Players
							: preferences.position5Players,
					position6PlayerGame:
						typeof payload.specific.position6Players !== "undefined"
							? payload.specific.position6Players
							: preferences.position6Players,
					position7PlayerGame:
						typeof payload.specific.position7Players !== "undefined"
							? payload.specific.position7Players
							: preferences.position7Players,
					position8PlayerGame:
						typeof payload.specific.position8Players !== "undefined"
							? payload.specific.position8Players
							: preferences.position8Players,
					availableAppLanguages: _clientLanguages,
					useChatTranslation:
						typeof payload.generic.enableChatTranslation === "boolean"
							? payload.generic.enableChatTranslation
							: preferences.useChatTranslation,
					emailOnNewsLetter:
						typeof payload.generic.emailOnNewsLetter === "boolean"
							? payload.generic.emailOnNewsLetter
							: preferences.emailOnNewsLetter,
					emailOnMessage:
						typeof payload.generic.emailOnMessage === "boolean"
							? payload.generic.emailOnMessage
							: preferences.emailOnMessage,
					showRunningGamesInList:
						typeof payload.app.showRunningGamesInList === "boolean"
							? payload.app.showRunningGamesInList
							: preferences.showRunningGamesInList,
					playMiscButtonSound:
						typeof payload.app.playMiscButtonSound === "boolean"
							? payload.app.playMiscButtonSound
							: preferences.playMiscButtonSound,
					playMenuButtonSound:
						typeof payload.app.playMenuButtonSound === "boolean"
							? payload.app.playMenuButtonSound
							: preferences.playMenuButtonSound,
					playGameChatSound:
						typeof payload.app.playGameChatSound === "boolean"
							? payload.app.playGameChatSound
							: preferences.playGameChatSound,
					playGlobalChatSound:
						typeof payload.app.playGlobalChatSound === "boolean"
							? payload.app.playGlobalChatSound
							: preferences.playGlobalChatSound,
					useLoudToActSound:
						typeof payload.specific.useLoudToActSound === "boolean"
							? payload.specific.useLoudToActSound
							: preferences.useLoudToActSound,
					appNotifyOnNewFriendGame:
						typeof payload.generic.appNotifyOnNewFriendGame === "boolean"
							? payload.generic.appNotifyOnNewFriendGame
							: preferences.appNotifyOnNewFriendGame,
					appNotifyOnFriendOnline:
						typeof payload.generic.appNotifyOnFriendOnline === "boolean"
							? payload.generic.appNotifyOnFriendOnline
							: preferences.appNotifyOnFriendOnline,
					appNotifyOnTournaments:
						typeof payload.generic.appNotifyOnTournaments === "boolean"
							? payload.generic.appNotifyOnTournaments
							: preferences.appNotifyOnTournaments,
					appNotifyOnPrivateChat:
						typeof payload.generic.appNotifyOnPrivateChat === "boolean"
							? payload.generic.appNotifyOnPrivateChat
							: preferences.appNotifyOnPrivateChat,
					appNotifyOnChat:
						typeof payload.generic.appNotifyOnChat === "boolean"
							? payload.generic.appNotifyOnChat
							: preferences.appNotifyOnChat,
					appNotifyOnGroupChat:
						typeof payload.generic.appNotifyOnGroupChat === "boolean"
							? payload.generic.appNotifyOnGroupChat
							: preferences.appNotifyOnGroupChat,
					appNotifyOnGroupOnline:
						typeof payload.generic.appNotifyOnGroupOnline === "boolean"
							? payload.generic.appNotifyOnGroupOnline
							: preferences.appNotifyOnGroupOnline,
					appNotifyOnMessage:
						typeof payload.generic.appNotifyOnMessage === "boolean"
							? payload.generic.appNotifyOnMessage
							: preferences.appNotifyOnMessage,
				},
			};
		case UPDATE_FRIEND_LIST:
			if (payload === undefined || payload.action === undefined) return state;
			var newFriendList = state.userDetails.friendList;
			var flIndex = newFriendList.indexOf(payload.userID);
			switch (payload.action) {
				case "ADD":
					if (payload.userID === undefined) return state;
					var foundUser,
						newFriends = cloneDeep(state.friends);
					helpers.mapObject(state.searchedUsers, (k, v) => {
						if (k == payload.userID) {
							foundUser = { userID: payload.userID, name: v };
						}
					});
					if (foundUser !== undefined) newFriends[foundUser.userID] = foundUser.name;
					if (flIndex == -1) {
						newFriendList = [...state.userDetails.friendList];
						newFriendList.push(payload.userID);
					}
					return {
						...state,
						friends: newFriends,
						userDetails: { ...state.userDetails, friendList: newFriendList },
					};
				case "REMOVE":
					if (payload.userID === undefined) return state;
					var newFriends = cloneDeep(state.friends);
					delete newFriends[payload.userID];
					if (flIndex !== -1) {
						newFriendList = [...state.userDetails.friendList];
						newFriendList.splice(flIndex, 1);
					}
					return {
						...state,
						friends: newFriends,
						userDetails: { ...state.userDetails, friendList: newFriendList },
					};
				case "ALL":
				default:
					if (
						payload.response === undefined ||
						payload.response.friends === undefined ||
						payload.response.friends === null
					)
						return state;
					const friendUserIDs = Object.keys(payload.response.friends);
					if (friendUserIDs.length > 0) {
						newFriendList = [...state.userDetails.friendList];
						friendUserIDs.forEach((id) => {
							if (newFriendList.indexOf(id) == -1) newFriendList.push(id);
						});
					}
					return {
						...state,
						friends: payload.response.friends,
						maxFriendListSize: payload.response.maxFriendListSize || state.maxFriendListSize,
						userDetails: { ...state.userDetails, friendList: newFriendList },
					};
			}
		case UPDATE_BAN_LIST:
			if (payload === undefined || payload.action === undefined) return state;
			var newBanList = state.userDetails.banList;
			var blIndex = newBanList.indexOf(payload.userID);
			switch (payload.action) {
				case "ADD":
					if (payload.userID === undefined) return state;
					var foundUser,
						newBannedUsers = cloneDeep(state.bannedUsers);
					helpers.mapObject(state.searchedUsers, (k, v) => {
						if (k == payload.userID) {
							foundUser = { userID: payload.userID, name: v };
						}
					});
					if (foundUser !== undefined) newBannedUsers[foundUser.userID] = foundUser.name;
					if (blIndex == -1) {
						newBanList = [...state.userDetails.banList];
						newBanList.push(payload.userID);
					}
					return {
						...state,
						bannedUsers: newBannedUsers,
						userDetails: { ...state.userDetails, banList: newBanList },
					};
				case "REMOVE":
					if (payload.userID === undefined) return state;
					var newBannedUsers = cloneDeep(state.bannedUsers);
					delete newBannedUsers[payload.userID];
					if (blIndex !== -1) {
						newBanList = [...state.userDetails.banList];
						newBanList.splice(blIndex, 1);
					}
					return {
						...state,
						bannedUsers: newBannedUsers,
						userDetails: { ...state.userDetails, banList: newBanList },
					};
				case "ALL":
				default:
					if (
						payload.response === undefined ||
						payload.response.bannedUsers === undefined ||
						payload.response.bannedUsers === null
					)
						return state;
					const bannedUserIDs = Object.keys(payload.response.bannedUsers);
					if (bannedUserIDs.length > 0) {
						newBanList = [...state.userDetails.banList];
						bannedUserIDs.forEach((id) => {
							if (newBanList.indexOf(id) == -1) newBanList.push(id);
						});
					}
					return {
						...state,
						bannedUsers: payload.response.bannedUsers,
						maxBanListSize: payload.response.maxBanListSize || state.maxBanListSize,
						userDetails: { ...state.userDetails, banList: newBanList },
					};
			}
		case UPDATE_SEARCHED_USERS_LIST:
			if (payload === undefined || payload.users === undefined) return state;
			return { ...state, searchedUsers: payload.users };
		case SELECT_DEFAULT_CARDSET:
			if (isNaN(payload) || payload < 0) return state;
			var newAvailableCardsets = cloneDeep(state.preferences.availableCardsets);
			newAvailableCardsets.forEach((el) => (el.selected = false));
			newAvailableCardsets[payload].selected = true;
			return {
				...state,
				preferences: {
					...state.preferences,
					availableCardsets: newAvailableCardsets,
				},
			};
		case SELECT_DEFAULT_CARD_SORT_ORDER:
			if (isNaN(payload) || payload < 0) return state;
			var newSortOrders = cloneDeep(state.preferences.sortOrders);
			newSortOrders.forEach((el) => (el.selected = false));
			newSortOrders[payload].selected = true;
			return {
				...state,
				preferences: { ...state.preferences, sortOrders: newSortOrders },
			};
		case SELECT_DEFAULT_CARD_SUIT_ORDER:
			if (isNaN(payload) || payload < 0) return state;
			var newSuitRankings = cloneDeep(state.preferences.suitRankings);
			newSuitRankings.forEach((el) => (el.selected = false));
			newSuitRankings[payload].selected = true;
			return {
				...state,
				preferences: { ...state.preferences, suitRankings: newSuitRankings },
			};
		case SELECT_DEFAULT_CARD_VALUE_ORDER:
			if (isNaN(payload) || payload < 0) return state;
			var newValueRankings = cloneDeep(state.preferences.valueRankings);
			newValueRankings.forEach((el) => (el.selected = false));
			newValueRankings[payload].selected = true;
			return {
				...state,
				preferences: { ...state.preferences, valueRankings: newValueRankings },
			};
		case CLEAR_SEARCHED_USERS_LIST:
		case GO_BACK_TO_MAIN:
		case CLOSE_PLAYERS_LIST:
			return { ...state, searchedUsers: initialState.searchedUsers };
		case RESET_CLIENT:
			return { ...initialState, isGuestUser: state.isGuestUser };
		default:
			return state;
	}
};

const getPreloadedCardsetItem = (item) => {
	Image.getSize(
		item.cardsetImgURL,
		(width, height) => {},
		(error) => {
			// handleError(new Error("Error loading image: " + item.cardsetImgURL));
		}
	);
	Image.getSize(
		item.cardsetImgBackURL,
		(width, height) => {},
		(error) => {
			// handleError(new Error("Error loading image: " + item.cardsetImgBackURL));
		}
	);
	return item;
};
