import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor, RESOLUTION } from "../../../../config/defaults";
import { gameDimensions } from "../../../../config/dimensions";
import {
	closeMyProfilePage,
	goBackToMain,
	openUpdateAvatar,
	openUpdateLanguage,
	openUpdateLocation,
	openUpdatePersonalData,
	openUpdateUsername,
} from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader } from "../../../common";
import UpdateAvatar from "./UpdateAvatar";
import UpdateLanguage from "./UpdateLanguage";
import UpdateLocation from "./UpdateLocation";
import UpdatePersonalData from "./UpdatePersonalData";
import UpdateUsername from "./UpdateUsername";

class MyProfile extends Component {
	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("MyProfileMenu");
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeMyProfilePage}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "username":
				textContent = this.props.lang.myProfilePage.username;
				onPressFunc = () => {
					this.props.openUpdateUsername();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "location":
				textContent = this.props.lang.myProfilePage.location;
				onPressFunc = () => {
					this.props.openUpdateLocation();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
			case "language":
				textContent = this.props.lang.myProfilePage.language;
				onPressFunc = () => {
					this.props.openUpdateLanguage();
				};
				_buttonColor = buttonColor.LIGHT_BLUE;
				break;
			case "personal":
				textContent = this.props.lang.myProfilePage.personal;
				onPressFunc = () => {
					this.props.openUpdatePersonalData();
				};
				_buttonColor = buttonColor.GREEN;
				break;
			case "avatar":
				textContent = this.props.lang.myProfilePage.avatar;
				onPressFunc = () => {
					this.props.openUpdateAvatar();
				};
				_buttonColor = buttonColor.RED;
				break;
		}

		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				additionalTextStyle={_buttonTextStyle}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderButton("username")}
					{this.renderButton("location")}
					{this.renderButton("language")}
					{this.renderButton("personal")}
					{this.renderButton("avatar")}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.updateUsernameOpened) {
			return <UpdateUsername />;
		} else if (this.props.updateLocationOpened) {
			return <UpdateLocation />;
		} else if (this.props.updateLanguageOpened) {
			return <UpdateLanguage />;
		} else if (this.props.updatePersonalDataOpened) {
			return <UpdatePersonalData />;
		} else if (this.props.updateAvatarOpened) {
			return <UpdateAvatar />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		paddingHorizontal: 55,
	},
	containerContent: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		updateUsernameOpened: state.app.updateUsernameOpened,
		updateLocationOpened: state.app.updateLocationOpened,
		updateLanguageOpened: state.app.updateLanguageOpened,
		updatePersonalDataOpened: state.app.updatePersonalDataOpened,
		updateAvatarOpened: state.app.updateAvatarOpened,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeMyProfilePage,
	goBackToMain,
	openUpdateAvatar,
	openUpdateLanguage,
	openUpdateLocation,
	openUpdatePersonalData,
	openUpdateUsername,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
