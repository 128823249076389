import AsyncStorage from "@react-native-async-storage/async-storage";
import { handleError } from "../../components/ErrorHandler";
import { DEBUG_ENABLED } from "../../config/permissions";

export default AsyncStorage;

export const storeData = async (key, value) => {
	try {
		await AsyncStorage.setItem(key, value);
	} catch (e) {
		if (DEBUG_ENABLED) console.log(e);
		handleError(e);
	}
};

export const mergeObjects = async (key, value) => {
	try {
		if (typeof value === "object") {
			await AsyncStorage.mergeItem(key, JSON.stringify(value));
		} else {
			throw new Error("Error merging objects; value is not an object; key = " + key + "; value = " + value);
		}
	} catch (e) {
		if (DEBUG_ENABLED) console.log(e);
		handleError(e);
	}
};

export const getData = async (key) => {
	try {
		const value = await AsyncStorage.getItem(key);
		return value;
	} catch (e) {
		handleError(e);
	}
};

export const getAllData = async () => {
	AsyncStorage.getAllKeys().then((keys) => {
		return AsyncStorage.multiGet(keys)
			.then((result) => {
				if (DEBUG_ENABLED) console.log(result);
			})
			.catch((e) => {
				if (DEBUG_ENABLED) console.log(e);
				handleError(e);
			});
	});
};

export const clearItem = async (key) => {
	await AsyncStorage.removeItem(key);
};

export const clearAllData = async () => {
	AsyncStorage.getAllKeys().then((keys) => {
		AsyncStorage.multiGet(keys).then((result) => {
			result.map((r) => {
				clearItem(r[0]);
			});
		});
	});
};
