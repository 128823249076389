import React from "react";
import { ActivityIndicator, Image, Platform, StyleSheet, View } from "react-native";
import { AJAX_BUSY } from "../../config/images";

const Spinner = ({ size, color }) => {
	if (Platform.OS === "web")
		return (
			<View style={styles.spinnerStyle}>
				<Image source={AJAX_BUSY} style={{ width: 75, height: 75 }} />
			</View>
		);

	return (
		<View style={styles.spinnerStyle}>
			<ActivityIndicator size={size || "large"} color={color || "gray"} />
		</View>
	);
};

const styles = StyleSheet.create({
	spinnerStyle: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
});

export { Spinner };
