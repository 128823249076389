import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { appFont, RESOLUTION } from "../../config/defaults";
import { GREEN_BTN, RED_BTN } from "../../config/images";
import { MyWebSocket } from "../../connection";
import { newGameStarted } from "../../Keezers/redux/actions/KeezersActions";
import { clearSlotSelection, disableChangeSlot, leaveGame, openModalDialog } from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { GameButton } from "../common";
import { handleError } from "../ErrorHandler";

class GameRoomButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isStartGameHovered: false,
			isChangeSlotHovered: false,
			isLeaveGameHovered: false,
		};
	}

	//#region events
	async startGamePressed() {
		try {
			if (this.props.gameRoomsGameId == -1) return;
			const msgStartGame = {
				type: "startGame",
				sMessageID: 0,
				gameID: this.props.gameRoomsGameId,
			};
			this.props.newGameStarted();
			MyWebSocket.shared.sendMsg(msgStartGame);
			await Analytics.logEvent("startGame");
		} catch (error) {
			handleError(error);
		}
	}
	onChangeSlotPress() {
		try {
			if (!this.props.grIsGameCreator && this.props.grSelectedSlotID != -1 && this.props.gameRoomsGameId != -1) {
				this.props.disableChangeSlot();
				var msgGameJoin = {
					type: "joinGame",
					sMessageID: 0,
					gameID: this.props.gameRoomsGameId,
					slotID: this.props.grSelectedSlotID,
					swap: true,
				};
				MyWebSocket.shared.sendMsg(msgGameJoin);
				this.props.clearSlotSelection();
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderLeftButton() {
		const { defButtonStyle, btnTextStyle, disabledButtonBck } = styles;
		const opacity = !this.props.loadingGameRoom
			? this.props.grIsGameCreator
				? this.props.canStartGame
					? this.state.isStartGameHovered
						? 0.8
						: 1
					: 0
				: this.state.isChangeSlotHovered
				? 0.8
				: 1
			: 0;
		const enabledBackImage = {
			uri: GREEN_BTN,
			style: [
				{
					flex: 2,
					maxWidth: 200,
					height: 40,
					opacity,
				},
				this.props.resolution == RESOLUTION.HIGH && {
					maxWidth: 250,
					height: 60,
				},
			],
		};
		const disabledBackImage = {
			...enabledBackImage,
			style: [...enabledBackImage.style, disabledButtonBck, { opacity: 0 }],
		};
		const enabledButtonStyle = [
			defButtonStyle,
			{
				cursor: "pointer",
				height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
			},
		];
		const disabledButtonStyle = [
			defButtonStyle,
			{
				cursor: "default",
				height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
			},
		];
		if (this.props.grIsGameCreator == true)
			return (
				<GameButton
					onPress={this.startGamePressed.bind(this)}
					onMouseEnter={() => this.setState({ isStartGameHovered: true })}
					onMouseLeave={() => this.setState({ isStartGameHovered: false })}
					disabled={!this.props.canStartGame}
					myStyle={defButtonStyle}
					textContent={this.props.lang.startGame}
					textContentStyle={[
						btnTextStyle,
						{ opacity },
						this.props.resolution == RESOLUTION.HIGH && {
							fontSize: 22,
							paddingTop: 13,
							paddingBottom: 13,
						},
					]}
					backgroundImage={this.props.canStartGame == true ? enabledBackImage : disabledBackImage}
				/>
			);
		return (
			<GameButton
				onPress={this.onChangeSlotPress.bind(this)}
				onMouseEnter={() => this.setState({ isChangeSlotHovered: true })}
				onMouseLeave={() => this.setState({ isChangeSlotHovered: false })}
				// disabled={!(this.props.canChangeSlot == true)}
				disabled={true}
				myStyle={
					this.props.canChangeSlot == true
						? [enabledButtonStyle, { opacity }]
						: // [enabledButtonStyle, { opacity: 0 }]
						  [disabledButtonStyle, { opacity: 0 }]
				}
				textContent={this.props.lang.gameRoom.btnChangeSlot}
				textContentStyle={[
					btnTextStyle,
					{
						opacity,
						textShadowColor: "#5f580b",
					},
					this.props.resolution == RESOLUTION.HIGH && {
						fontSize: 22,
						paddingTop: 13,
						paddingBottom: 13,
					},
				]}
				backgroundImage={
					this.props.canChangeSlot == true
						? disabledBackImage
						: //  enabledBackImage
						  disabledBackImage
				}
			/>
		);
	}

	render() {
		const { defButtonStyle, btnTextStyle, containerStyle } = styles;
		const { onLayout, style, gameRoomButtonsWidth, msgGamePlayerLeave } = this.props;
		const opacity = this.state.isLeaveGameHovered ? 0.8 : 1;
		const enabledBackImage = {
			uri: RED_BTN,
			style: [
				{
					flex: 2,
					maxWidth: 200,
					height: 40,
					opacity,
				},
				this.props.resolution == RESOLUTION.HIGH && {
					maxWidth: 250,
					height: 60,
				},
			],
		};
		const enabledButtonStyle = [
			defButtonStyle,
			{
				cursor: "pointer",
				height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
			},
		];
		return (
			<View onLayout={onLayout} style={[containerStyle, style || {}]}>
				{this.renderLeftButton()}
				<GameButton
					onPress={async () => {
						// this.props.openModalDialog(
						//   modalType.DIALOG,
						//   this.props.lang.messages.gamePlayerLeaveWarningMessage,
						//   this.props.lang.yes,
						//   () => {
						try {
							if (msgGamePlayerLeave.gameID && msgGamePlayerLeave.gameID > 0) {
								MyWebSocket.shared.sendMsg(msgGamePlayerLeave);
								this.props.leaveGame();
								await Analytics.logEvent("finishedGame");
							}
						} catch (error) {
							handleError(error);
						}
						//   },
						//   this.props.lang.no,
						//   null
						// );
					}}
					onMouseEnter={() => this.setState({ isLeaveGameHovered: true })}
					onMouseLeave={() => this.setState({ isLeaveGameHovered: false })}
					disabled={false}
					myStyle={[enabledButtonStyle, { opacity }]}
					textContent={this.props.lang.gameRoom.btnLeaveGame}
					textContentStyle={[
						btnTextStyle,
						{ opacity, textShadowColor: "#6C2D0C" },
						this.props.resolution == RESOLUTION.HIGH && {
							fontSize: 22,
							paddingTop: 13,
							paddingBottom: 13,
						},
					]}
					backgroundImage={enabledBackImage}
				/>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	containerStyle: {
		flexDirection: "row",
		justifyContent: "space-around",
		paddingTop: 13,
		paddingBottom: 5,
	},
	defButtonStyle: {
		flex: 2,
		height: 40,
		backgroundColor: "transparent",
		borderWidth: 0,
	},
	btnTextStyle: {
		width: "100%",
		fontFamily: appFont,
		fontSize: 14,
		paddingTop: 10,
		paddingBottom: 10,
		textAlign: "center",
		color: "#FFFFFF",
		textShadowColor: "#397423",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: Platform.OS !== "web" ? 0.001 : 0,
	},
	disabledButtonBck: { opacity: 0 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		gameRoomButtonsWidth: state.views.gameRoomButtonsWidth,
		canStartGame: state.gameRoom.canStartGame,
		grIsGameCreator: state.gameRoom.grIsGameCreator,
		canChangeSlot: state.gameRoom.canChangeSlot,
		grSelectedSlotID: state.gameRoom.grSelectedSlotID,
		gameRoomsGameId: state.gameRoom.gameRoomsGameId,
		msgGamePlayerLeave: state.gameRoom.msgGamePlayerLeave,
		loadingGameRoom: state.gameRoom.loadingGameRoom,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {
	clearSlotSelection,
	disableChangeSlot,
	leaveGame,
	newGameStarted,
	openModalDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameRoomButtons);
