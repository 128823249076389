import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { Dimensions, Image, ImageBackground, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprosemibold,
	isWebOrTablet,
	processState,
	RESOLUTION,
	tabType,
} from "../../../../../../config/defaults";
import { fixBoardPositionsDimensions, gameDimensions } from "../../../../../../config/dimensions";
import { TOGGLE_ICON } from "../../../../../../config/images";
import CheckBox from "../../../../../../libraries/CheckBox";
import {
	closeBoardPreferences,
	closeFixBoardPositions,
	goBackToMain,
	openTab,
	openVIPShop,
	startPlay,
	updateFixedPositions,
} from "../../../../../../redux/actions";
import Analytics from "../../../../../Analytics/Analytics";
import { AppTouchableOpacity, ColoredButton, NavigationHeader, Spinner } from "../../../../../common";
import { handleError } from "../../../../../ErrorHandler";
import VIPShop from "../../../../../GameShop/VIPShop";

const positionToColor = Object.freeze({
	_1: "#333333",
	_2: "#7DC248",
	_3: "#C4202C",
	_4: "#00CDD8",
	_5: "#FCD407",
	_6: "#F6AECD",
	_7: "#FF9743",
	_8: "purple",
});

class FixBoardPositions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			isFixPositionsChecked: this.props.useFixedStartPosition,
			positions: this.getPositionsList(this.props.useFixedStartPosition),
			enableLastMove: this.props.useLastMoveTracking,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("BoardPositionsPreferences");
	}
	//#endregion

	//#region events
	onUpdatePress() {
		try {
			const data = {
				useLastMoveTracking: this.state.enableLastMove,
				useFixedStartPosition: this.state.isFixPositionsChecked,
				position2PlayerGame: this.state.positions[0].selPos,
				position3PlayerGame: this.state.positions[1].selPos,
				position4PlayerGame: this.state.positions[2].selPos,
				position5PlayerGame: this.state.positions[3].selPos,
				position6PlayerGame: this.state.positions[4].selPos,
				position7PlayerGame: this.state.positions[5].selPos,
				position8PlayerGame: this.state.positions[6].selPos,
			};
			this.props.updateFixedPositions(data);
		} catch (error) {
			handleError(error);
		}
	}

	onLinkPress() {
		this.props.goBackToMain();
		this.props.startPlay();
		this.props.openTab(tabType.SHOP);
		this.props.openVIPShop();
	}

	togglePosition(index, prev = false) {
		try {
			if (index >= 0) {
				var newPositions = cloneDeep(this.state.positions);
				var board = newPositions[index];
				if (prev) {
					board.selPos--;
					if (board.selPos <= 0) board.selPos = board.playerCnt;
				} else {
					board.selPos++;
					if (board.selPos == board.playerCnt + 1) board.selPos = 1;
				}
				this.setState({ positions: newPositions });
			}
		} catch (error) {
			handleError(error);
		}
	}

	onFixPositionsCheckChange() {
		this.setState({
			isFixPositionsChecked: !this.state.isFixPositionsChecked,
			positions: this.getPositionsList(!this.state.isFixPositionsChecked),
		});
	}

	onEnableLastMoveChange() {
		this.setState({ enableLastMove: !this.state.enableLastMove });
	}

	onBackPress() {
		this.props.closeFixBoardPositions();
		this.props.closeBoardPreferences();
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateFixedPositionsState != processState.STARTED}
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderInfo() {
		try {
			const regex = /_/gi;
			var main_text = this.props.lang.fixBoardPositions.info;
			var main_text_i = main_text.indexOf("_");
			var main_text_j = main_text.lastIndexOf("_");
			if (main_text_i != -1 && main_text_j != -1) var linkMainText = main_text.substring(main_text_i, ++main_text_j);
			if (linkMainText != undefined) var mainTextLink = linkMainText.replace(regex, "");

			var _infoStyle = [styles.dataText, styles.infoText],
				_innerContainerStyle = [styles.dataInnerContainer, { flexDirection: "row" }],
				_inputLabel = [styles.dataTitle, styles.dataText],
				_checkBoxStyle = [
					Platform.OS === "web"
						? styles.checkBox
						: {
								transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }],
								marginTop: 10,
						  },
				];
			if (this.props.resolution === RESOLUTION.HIGH) {
				_infoStyle = [styles.dataText, styles.infoText, { fontSize: 24, lineHeight: 30 }];
				_innerContainerStyle = [styles.dataInnerContainer, { flexDirection: "row", marginTop: 5, marginBottom: 20 }];
				_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
				_checkBoxStyle = [
					Platform.OS === "web"
						? [styles.checkBox, { width: 30, height: 30 }]
						: {
								transform: [{ scale: Platform.OS === "ios" ? 1 : 1.75 }],
								marginTop: 10,
						  },
				];
			}
			return (
				<View style={styles.columnContainer}>
					<View style={_innerContainerStyle}>
						<Text allowFontScaling={false} style={_inputLabel}>
							{this.props.lang.fixBoardPositions.lastMoveCheckTitle}
						</Text>
						<CheckBox
							disabled={false}
							value={this.state.enableLastMove}
							onValueChange={this.onEnableLastMoveChange.bind(this)}
							style={_checkBoxStyle}
						/>
					</View>
					<View
						style={[
							_innerContainerStyle,
							{
								marginBottom: this.props.resolution === RESOLUTION.HIGH ? 10 : -10,
							},
						]}
					>
						<Text allowFontScaling={false} style={_inputLabel}>
							{this.props.lang.fixBoardPositions.checkBoxTitle}
						</Text>
						<CheckBox
							disabled={false}
							value={this.state.isFixPositionsChecked}
							onValueChange={this.onFixPositionsCheckChange.bind(this)}
							style={_checkBoxStyle}
						/>
					</View>
					<View style={styles.detailsContainer}>
						{mainTextLink != "" && (
							<ParsedText
								style={_infoStyle}
								parse={[
									{
										pattern: new RegExp("_" + mainTextLink + "_"),
										style: [_infoStyle, styles.linkDecor],
										onPress: this.onLinkPress.bind(this),
										renderText: () => mainTextLink,
									},
								]}
							>
								{main_text}
							</ParsedText>
						)}
						{mainTextLink == "" && (
							<Text allowFontScaling={false} style={_infoStyle}>
								{main_text}
							</Text>
						)}
					</View>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderRow(item, index) {
		var _playerCntText = styles.playerCntText,
			_selectedPositionText = styles.selectedPositionText,
			_rowContainer = styles.rowContainer,
			_toggleButton = styles.toggleButton,
			_toggleIcon = styles.toggleIcon;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_playerCntText = [styles.playerCntText, { fontSize: 24, lineHeight: 60 }];
			_selectedPositionText = [styles.selectedPositionText, { fontSize: 24, lineHeight: 60, marginRight: 7 }];
			_rowContainer = [styles.rowContainer, { height: 60 }];
			_toggleButton = [styles.toggleButton, { width: 60, height: 60 }];
			_toggleIcon = [styles.toggleIcon, { width: 45, height: 45 }];
		}
		return (
			<View style={_rowContainer} key={"playerCnt_" + item.playerCnt}>
				<Text allowFontScaling={false} style={_playerCntText}>
					{item.playerCnt}
				</Text>
				<AppTouchableOpacity
					style={_toggleButton}
					onPress={() => this.togglePosition(index, true)}
					disabled={!this.state.isFixPositionsChecked}
				>
					<Image source={TOGGLE_ICON} style={_toggleIcon} resizeMode="stretch" />
				</AppTouchableOpacity>
				<Text allowFontScaling={false} style={[_selectedPositionText, { color: positionToColor["_" + item.selPos] }]}>
					{item.selPos}
				</Text>
				<AppTouchableOpacity
					style={_toggleButton}
					onPress={() => this.togglePosition(index, false)}
					disabled={!this.state.isFixPositionsChecked}
				>
					<Image
						source={TOGGLE_ICON}
						style={[_toggleIcon, { transform: [{ rotateY: "-180deg" }] }]}
						resizeMode="stretch"
					/>
				</AppTouchableOpacity>
			</View>
		);
	}

	renderLeftColumn() {
		var rows = [];

		for (let i = 0; i < 4; i++) {
			rows.push(this.renderRow(this.state.positions[i], i));
		}

		return rows;
	}

	renderRightColumn() {
		var rows = [];

		for (let i = 4; i < this.state.positions.length; i++) {
			rows.push(this.renderRow(this.state.positions[i], i));
		}

		return rows;
	}

	renderDetails() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_innerContainerStyle = [styles.dataInnerContainer];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { marginTop: 5, marginBottom: 20 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
		}
		return (
			<View style={[styles.columnContainer, { paddingLeft: 40 }]}>
				<View style={_innerContainerStyle}>
					<Text allowFontScaling={false} style={_inputLabel}>
						{this.props.lang.fixBoardPositions.detailsTitle}
					</Text>
				</View>
				<View style={{ flex: 1, flexDirection: "row" }}>
					<View style={{ flex: 2 }}>{this.renderLeftColumn()}</View>
					<View style={{ flex: 2 }}>{this.renderRightColumn()}</View>
				</View>
			</View>
		);
	}

	renderContent() {
		return (
			<View
				style={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				<ScrollView style={{ flex: 1 }} contentContainerStyle={styles.positionsContainer}>
					{this.renderInfo()}
					{this.renderDetails()}
					{this.renderUpdateButton()}
				</ScrollView>
			</View>
		);
	}

	render() {
		if (this.props.updateFixedPositionsState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);

		if (this.props.vipShopOpened) {
			return <VIPShop />;
		} else {
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<View style={styles.container}>{this.renderContent()}</View>
					{this.renderHeader()}
				</ImageBackground>
			);
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (fixBoardPositionsDimensions.width > availableWidth || fixBoardPositionsDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / fixBoardPositionsDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / fixBoardPositionsDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}

	getPositionsList(enabled) {
		try {
			return [
				{
					playerCnt: 2,
					selPos: enabled ? this.props.position2PlayerGame : 1,
				},
				{
					playerCnt: 3,
					selPos: enabled ? this.props.position3PlayerGame : 1,
				},
				{
					playerCnt: 4,
					selPos: enabled ? this.props.position4PlayerGame : 1,
				},
				{
					playerCnt: 5,
					selPos: enabled ? this.props.position5PlayerGame : 1,
				},
				{
					playerCnt: 6,
					selPos: enabled ? this.props.position6PlayerGame : 1,
				},
				{
					playerCnt: 7,
					selPos: enabled ? this.props.position7PlayerGame : 1,
				},
				{
					playerCnt: 8,
					selPos: enabled ? this.props.position8PlayerGame : 1,
				},
			];
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	contentContainer: { flex: 1, paddingHorizontal: 20 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		marginBottom: 15,
		justifyContent: "center",
	},
	dataTitle: {
		flex: 1,
		textAlign: "left",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		paddingTop: 2,
		paddingRight: 10,
		lineHeight: 40,
	},
	dataText: { fontFamily: f_sourcesansprosemibold, fontSize: 20 },
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: isWebOrTablet ? 26 : 22,
		marginTop: 20,
		marginBottom: 10,
		fontSize: 18,
	},
	linkDecor: { color: "#00CDD8" },
	positionsContainer: { minHeight: "100%", flexDirection: "row" },
	columnContainer: { flex: 2 },
	detailsContainer: { flex: 1 },
	rowContainer: { height: 40, flexDirection: "row", marginBottom: 10 },
	toggleButton: { width: 40, height: 40, justifyContent: "center" },
	toggleIcon: { width: 30, height: 30 },
	selectedPositionText: {
		width: 20,
		lineHeight: 40,
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		color: "#00CDD8",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
	},
	playerCntText: {
		width: 30,
		lineHeight: 40,
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
	},
	checkBox: { width: 20, height: 20, marginTop: 10, marginRight: 20 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		vipShopOpened: state.gameShop.vipShopOpened,
		updateFixedPositionsState: state.app.updateFixedPositionsState,
		useFixedStartPosition: state.currentUser.preferences.useFixedStartPosition,
		position2PlayerGame: state.currentUser.preferences.position2PlayerGame,
		position3PlayerGame: state.currentUser.preferences.position3PlayerGame,
		position4PlayerGame: state.currentUser.preferences.position4PlayerGame,
		position5PlayerGame: state.currentUser.preferences.position5PlayerGame,
		position6PlayerGame: state.currentUser.preferences.position6PlayerGame,
		position7PlayerGame: state.currentUser.preferences.position7PlayerGame,
		position8PlayerGame: state.currentUser.preferences.position8PlayerGame,
		useLastMoveTracking: state.currentUser.preferences.useLastMoveTracking,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeBoardPreferences,
	closeFixBoardPositions,
	goBackToMain,
	openVIPShop,
	openTab,
	startPlay,
	updateFixedPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(FixBoardPositions);
