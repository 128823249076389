import React, { Component } from "react";
import { Platform, StyleSheet, Text, TextInput, View } from "react-native";
import { appFont } from "../../config/defaults";
import { SMALL_BUTTON } from "../../config/images";
import { Box, GameButton } from "../common";
import { handleError } from "../ErrorHandler";

class CreateChatRoom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boxTop: 0,
			boxLeft: 0,
			inputWidth: 0,
			newName: "",
			isCreateEnabled: false,
			isCreateHovered: false,
			isCancelHovered: false,
		};
	}

	//#region events
	onBoxLayout(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const { width, height } = this.props;
			const { layout } = event.nativeEvent;
			const newTop = (height - layout.height) / 2;
			const newLeft = (width - layout.width) / 2;
			const newInputWidth =
				/* Platform.OS !== "web" || this.props.isSmallResolution
        ? width - 20
        : */ this.props.boxWidth - 280;
			this.setState({
				boxTop: newTop,
				boxLeft: newLeft,
				inputWidth: newInputWidth,
			});
		} catch (error) {
			handleError(error);
		}
	}

	onButtonPress(button) {
		try {
			const { onCreatePress, onCancelPress, isPrivateRoom } = this.props;
			const { isCreateEnabled, newName } = this.state;
			if (button == "create" && isCreateEnabled && typeof onCreatePress === "function")
				onCreatePress(newName, isPrivateRoom);
			if (button == "cancel" && typeof onCancelPress === "function") onCancelPress();
		} catch (error) {
			handleError(error);
		}
	}

	onMouseEnterButton(button) {
		if (button == "create") this.setState({ isCreateHovered: true });
		if (button == "cancel") this.setState({ isCancelHovered: true });
	}

	onMouseLeaveButton(button) {
		if (button == "create") this.setState({ isCreateHovered: false });
		if (button == "cancel") this.setState({ isCancelHovered: false });
	}

	onChangeName(nName) {
		this.setState({ newName: nName });
		if (nName.length >= 6) {
			this.setState({ isCreateEnabled: true });
		} else {
			this.setState({ isCreateEnabled: false });
		}
	}
	//#endregion

	//#region render methods
	renderButtons() {
		const cancelOpacity = this.state.isCancelHovered ? 0.8 : 1;
		const createHoveredOpacity = this.state.isCreateEnabled ? (this.state.isCreateHovered ? 0.8 : 1) : 0.5;
		const createCursor =
			Platform.OS === "web" ? (this.state.isCreateEnabled ? { cursor: "pointer" } : { cursor: "default" }) : {};
		return (
			<View style={{ flexDirection: "row", justifyContent: "space-between" }}>
				<GameButton
					textContent={this.props.lang.create}
					textContentStyle={[styles.textStyle, styles.textOutline]}
					onPress={() => this.onButtonPress("create")}
					onMouseEnter={() => this.onMouseEnterButton("create")}
					onMouseLeave={() => this.onMouseLeaveButton("create")}
					myStyle={[styles.buttonStyle, { opacity: createHoveredOpacity }, createCursor]}
					backgroundImage={{
						uri: SMALL_BUTTON,
						resizeMode: "stretch",
						style: [styles.buttonBackImgStyle, { opacity: createHoveredOpacity }],
					}}
				/>
				<GameButton
					textContent={this.props.lang.cancel}
					textContentStyle={[styles.textStyle, styles.textOutline]}
					onPress={() => this.onButtonPress("cancel")}
					onMouseEnter={() => this.onMouseEnterButton("cancel")}
					onMouseLeave={() => this.onMouseLeaveButton("cancel")}
					myStyle={[styles.buttonStyle, { opacity: cancelOpacity }]}
					backgroundImage={{
						uri: SMALL_BUTTON,
						resizeMode: "stretch",
						style: [styles.buttonBackImgStyle, { opacity: cancelOpacity }],
					}}
				/>
			</View>
		);
	}

	renderInput() {
		return (
			<TextInput
				allowFontScaling={false}
				autoFocus={Platform.OS === "web"}
				disableFullscreenUI={true}
				onSubmitEditing={() => {}}
				value={this.state.newName}
				onChangeText={(text) => this.onChangeName(text)}
				editable={true}
				selectTextOnFocus={false}
				maxLength={16}
				numberOfLines={1}
				underlineColorAndroid="transparent"
				style={[
					styles.textInputStyle,
					{ width: this.state.inputWidth, backgroundColor: "#fff" },
					Platform.OS === "web" ? { outlineWidth: 0 } : {},
				]}
			/>
		);
	}

	renderBoxContent() {
		return (
			<View>
				<Text allowFontScaling={false} selectable={false} style={styles.titleStyle}>
					{this.props.lang.globalChat.createChatRoomTitle}
				</Text>
				<View style={{ flexDirection: "row" }}>
					{this.renderInput()}
					{this.renderButtons()}
				</View>
			</View>
		);
	}

	renderContent() {
		const { boxTop, boxLeft } = this.state;
		return (
			<Box
				style={[styles.boxStyle, { top: boxTop, left: boxLeft, width: this.props.boxWidth }]}
				onLayout={(event) => this.onBoxLayout(event)}
			>
				{this.renderBoxContent()}
			</Box>
		);
	}

	render() {
		const { width, height, marginLeft, isSmallResolution, style } = this.props;
		return (
			<View style={[{ width, height, marginLeft }, styles.mainContainer, style]}>
				<View style={[styles.background]} />
				{this.renderContent()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	background: {
		width: "100%",
		height: "100%",
		position: "absolute",
		zIndex: -1,
		borderRadius: 10,
		backgroundColor: "black",
		opacity: 0.5,
	},
	mainContainer: {
		position: "absolute",
		zIndex: 900,
	},
	boxStyle: {
		width: null,
		height: null,
		position: "absolute",
		paddingTop: 10,
		paddingBottom: 10,
	},
	titleStyle: {
		fontFamily: appFont,
		fontSize: 18,
		marginLeft: 25,
		marginRight: 25,
	},
	textOutline: {
		position: "absolute",
		zIndex: 0,
	},
	textStyle: {
		width: "100%",
		height: 40,
		fontFamily: appFont,
		fontSize: 14,
		paddingTop: 13,
		paddingBottom: 13,
		textAlign: "center",
		color: "#FFFFFF",
		textShadowColor: "#397423",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: Platform.OS !== "web" ? 0.001 : 0,
	},
	textInputStyle: {
		width: "100%",
		height: 40,
		margin: 10,
		paddingTop: 5,
		paddingBottom: 4,
		paddingLeft: 8,
		paddingRight: 8,
		textAlign: "left",
		fontFamily: appFont,
		fontSize: 16,
		overflow: "hidden",
		borderWidth: 1,
		borderColor: "#ecedee",
		borderRadius: 10,
		shadowColor: "#383838",
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 2,
	},
	buttonStyle: {
		width: 100,
		height: 40,
		backgroundColor: "transparent",
		borderWidth: 0,
	},
	buttonBackImgStyle: {
		width: 100,
		height: 40,
		margin: 10,
	},
});

export default CreateChatRoom;
