import React from "react";
import { StyleSheet, View } from "react-native";

const Box = (props) => {
	return (
		<View style={[styles.container, props.style || {}]} onLayout={props.onLayout}>
			{props.children}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#FFFFFF",
		borderRadius: 10,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
	},
});

export { Box };
