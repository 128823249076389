import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, processState, RESOLUTION } from "../../../../config/defaults";
import { emailNotifSettingsDimensions, gameDimensions } from "../../../../config/dimensions";
import CheckBox from "../../../../libraries/CheckBox";
import { closeSoundsPreferences, goBackToMain, updateSoundsPreferences } from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../../../common";
import { handleError } from "../../../ErrorHandler";

class SoundPreferences extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			menuButtonSoundEnabled: this.props.playMenuButtonSound,
			miscButtonSoundEnabled: this.props.playMiscButtonSound,
			gameChatSoundEnabled: this.props.playGameChatSound,
			globalChatSoundEnabled: this.props.playGlobalChatSound,
			useLoudToActSound: this.props.useLoudToActSound,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("SoundPreferencesPreferences");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.playMenuButtonSound !== this.props.playMenuButtonSound) {
			this.setState({
				menuButtonSoundEnabled: this.props.playMenuButtonSound,
			});
		}
		if (prevProps.playMiscButtonSound !== this.props.playMiscButtonSound) {
			this.setState({
				miscButtonSoundEnabled: this.props.playMiscButtonSound,
			});
		}
		if (prevProps.playGameChatSound !== this.props.playGameChatSound) {
			this.setState({ gameChatSoundEnabled: this.props.playGameChatSound });
		}
		if (prevProps.playGlobalChatSound !== this.props.playGlobalChatSound) {
			this.setState({ globalChatSoundEnabled: this.props.playGlobalChatSound });
		}
		if (prevProps.useLoudToActSound !== this.props.useLoudToActSound) {
			this.setState({
				useLoudToActSound: this.props.useLoudToActSound,
			});
		}
	}
	//#endregion

	//#region events
	onSavePress() {
		try {
			const data = {
				playMenuButtonSound: this.state.menuButtonSoundEnabled,
				playMiscButtonSound: this.state.miscButtonSoundEnabled,
				playGameChatSound: this.state.gameChatSoundEnabled,
				playGlobalChatSound: this.state.globalChatSoundEnabled,
				useLoudToActSound: this.state.useLoudToActSound,
			};
			this.props.updateSoundsPreferences(data);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateSoundsPreferencesState != processState.STARTED}
				backAction={this.props.closeSoundsPreferences}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderSaveButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onSavePress.bind(this)}
					textContent={this.props.lang.save}
					accessibilityLabel={this.props.lang.save}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderCheck(type) {
		var title = "",
			changeFunc = () => {},
			value;

		switch (type) {
			case "playMenuButtonSound":
				title = this.props.lang.soundPreferences.menuButtonSound;
				value = this.state.menuButtonSoundEnabled;
				changeFunc = () => {
					this.setState({
						menuButtonSoundEnabled: !this.state.menuButtonSoundEnabled,
					});
				};
				break;
			case "playMiscButtonSound":
				title = this.props.lang.soundPreferences.miscButtonSound;
				value = this.state.miscButtonSoundEnabled;
				changeFunc = () => {
					this.setState({
						miscButtonSoundEnabled: !this.state.miscButtonSoundEnabled,
					});
				};
				break;
			case "playGlobalChatSound":
				title = this.props.lang.soundPreferences.globalChatSound;
				value = this.state.globalChatSoundEnabled;
				changeFunc = () => {
					this.setState({
						globalChatSoundEnabled: !this.state.globalChatSoundEnabled,
					});
				};
				break;
			case "playGameChatSound":
				title = this.props.lang.soundPreferences.gameChatSound;
				value = this.state.gameChatSoundEnabled;
				changeFunc = () => {
					this.setState({
						gameChatSoundEnabled: !this.state.gameChatSoundEnabled,
					});
				};
				break;
			case "useLoudToActSound":
				title = this.props.lang.soundPreferences.useLoudToActSound;
				value = this.state.useLoudToActSound;
				changeFunc = () => {
					this.setState({ useLoudToActSound: !this.state.useLoudToActSound });
				};
				break;
		}
		var _innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center" }],
			_inputLabel = [styles.dataTitle, styles.dataText],
			_checkBoxStyle = [
				Platform.OS === "web" ? styles.checkBox : { transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }] },
			];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center", height: 70 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_checkBoxStyle = [
				Platform.OS === "web"
					? [styles.checkBox, { width: 30, height: 30 }]
					: { transform: [{ scale: Platform.OS === "ios" ? 1 : 1.75 }] },
			];
		}
		return (
			<View style={_innerContainerStyle}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{title}
				</Text>
				<View style={styles.checkboxContainer}>
					<CheckBox value={value} onValueChange={changeFunc} style={_checkBoxStyle} />
				</View>
			</View>
		);
	}

	renderContent() {
		return (
			<View
				style={{
					flex: 1,
					paddingHorizontal: 20,
					paddingBottom: 5,
					// transform: [{ scale: this.state.contentScale }],
				}}
			>
				<ScrollView
					style={{ flex: 1, marginBottom: 70 }}
					contentContainerStyle={styles.containerContent}
					centerContent={true}
				>
					{this.renderCheck("playMenuButtonSound")}
					{this.renderCheck("playMiscButtonSound")}
					{this.renderCheck("playGlobalChatSound")}
					{this.renderCheck("playGameChatSound")}
					{this.renderCheck("useLoudToActSound")}
				</ScrollView>
				{this.renderSaveButton()}
			</View>
		);
	}

	render() {
		if (this.props.updateSoundsPreferencesState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);

		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("screen");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height; //- 50;
			if (
				emailNotifSettingsDimensions.width > availableWidth ||
				emailNotifSettingsDimensions.height > availableHeight
			) {
				const _scaleX = Math.round((availableWidth / emailNotifSettingsDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / emailNotifSettingsDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	containerContent: {
		justifyContent: "center",
	},
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 2,
		marginBottom: 2,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 400,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	checkboxContainer: {
		width: 100,
		height: 40,
		flexDirection: "row",
		paddingTop: 5,
	},
	checkBox: { width: 20, height: 20, alignSelf: "center" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		playMenuButtonSound: state.currentUser.preferences.playMenuButtonSound,
		playMiscButtonSound: state.currentUser.preferences.playMiscButtonSound,
		playGameChatSound: state.currentUser.preferences.playGameChatSound,
		playGlobalChatSound: state.currentUser.preferences.playGlobalChatSound,
		updateSoundsPreferencesState: state.app.updateSoundsPreferencesState,
		useLoudToActSound: state.currentUser.preferences.useLoudToActSound,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeSoundsPreferences,
	goBackToMain,
	updateSoundsPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(SoundPreferences);
