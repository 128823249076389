import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprobolditalic,
	f_sourcesansprolight,
	processState,
	RESOLUTION,
} from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { TEXT_INPUT, TEXT_INPUT_ERROR } from "../../config/images";
import { MyWebSocket } from "../../connection";
import {
	cancelEmailLogin,
	emailLoginSucceeded,
	openLogInTrouble,
	saveEmailPassword,
	saveEmailUsername,
	startEmailLogin,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { ColoredButton, Link, NavigationHeader, Spinner } from "../common";
import { handleError } from "../ErrorHandler";
import LogInTrouble from "./LogInTrouble/LogInTrouble";

class EmailLoginForm extends Component {
	emailRef = React.createRef();
	pwdRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			isFormValid: true,
			username: "",
			usernameValid: true,
			usernameActive: false,
			pwd: "",
			pwdValid: true,
			pwdActive: false,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("LoginForm");
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.emailLoginState == processState.STARTED &&
			this.props.emailLoginState == processState.FINISHED &&
			!prevProps.isEmailLoggedIn &&
			!this.props.isEmailLoggedIn
		) {
			const validUName = this.props.emailLoginError.username === "";
			const validPwd = this.props.emailLoginError.pwd === "";
			const validForm = validUName && validPwd;
			this.setState({
				usernameValid: validUName,
				pwdValid: validPwd,
				isFormValid: validForm,
			});
		}
	}

	//#region events
	onLoginPress() {
		// save credentials in AsyncStorage
		try {
			this.props.saveEmailPassword(this.state.pwd);
			this.props.saveEmailUsername(this.state.username);
			this.props.startEmailLogin();
			MyWebSocket.shared.sendLogin("email", true);
		} catch (error) {
			handleError(error);
		}
	}

	onEmailSubmit() {
		this.setState({ usernameActive: false });
		this.pwdRef.current.focus();
	}

	onEmailChange(text) {
		this.setState({ username: text });
	}

	onPwdSubmit() {
		this.setState({ pwdActive: false });
		this.onLoginPress();
	}

	onPwdChange(text) {
		this.setState({ pwd: text });
	}
	//#endregion

	//#region render methods
	renderLoginButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onLoginPress.bind(this)}
					textContent={this.props.lang.login}
					accessibilityLabel={this.props.lang.login}
					color={buttonColor.GREEN}
				/>
			</View>
		);
	}

	renderUsernameField() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = styles.inputStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26, width: 250 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataInnerContainer}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.emailLoginForm.username.title}
				</Text>
				<ImageBackground
					source={this.state.usernameValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					<TextInput
						allowFontScaling={false}
						ref={this.emailRef}
						disableFullscreenUI={true}
						onBlur={this.onEmailSubmit.bind(this)}
						onFocus={() => this.setState({ usernameActive: true })}
						onSubmitEditing={this.onEmailSubmit.bind(this)}
						onChangeText={(text) => this.onEmailChange(text)}
						value={this.state.username}
						editable={true}
						selectTextOnFocus={true}
						autoCapitalize="none"
						autoCorrect={false}
						spellCheck={false}
						textContentType="oneTimeCode"
						underlineColorAndroid="transparent"
						style={_inputStyle}
						placeholder={this.props.lang.emailLoginForm.username.placeHolder}
						placeholderTextColor="#8a8a8a"
					/>
				</ImageBackground>
			</View>
		);
	}

	renderUsernameError() {
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26, width: 550 }];
		}
		return (
			<View style={styles.dataErrorContainer}>
				{!this.state.usernameValid && (
					<Text allowFontScaling={false} style={_errorStyle}>
						{this.props.emailLoginError.username}
					</Text>
				)}
			</View>
		);
	}

	renderPwdField() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = styles.inputStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26, width: 250 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataInnerContainer}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.emailLoginForm.pwd.title}
				</Text>
				<ImageBackground
					source={this.state.pwdValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					<TextInput
						allowFontScaling={false}
						ref={this.pwdRef}
						disableFullscreenUI={true}
						onBlur={() => this.setState({ pwdActive: true })}
						onFocus={() => this.setState({ pwdActive: true })}
						onSubmitEditing={this.onPwdSubmit.bind(this)}
						onChangeText={(text) => this.onPwdChange(text)}
						value={this.state.pwd}
						editable={true}
						selectTextOnFocus={true}
						autoCapitalize="none"
						underlineColorAndroid="transparent"
						style={_inputStyle}
						placeholder={this.props.lang.emailLoginForm.pwd.placeHolder}
						secureTextEntry={true}
						placeholderTextColor="#8a8a8a"
					/>
				</ImageBackground>
			</View>
		);
	}

	renderPwdError() {
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26, width: 550 }];
		}
		return (
			<View style={styles.dataErrorContainer}>
				{!this.state.pwdValid && (
					<Text allowFontScaling={false} style={_errorStyle}>
						{this.props.emailLoginError.pwd}
					</Text>
				)}
			</View>
		);
	}

	renderLink() {
		var _linkText = styles.linkText;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_linkText = [styles.linkText, { fontSize: 26, marginLeft: 50 }];
		}
		return (
			<View style={[styles.dataInnerContainer]}>
				<Link
					onPress={() => this.props.openLogInTrouble()}
					text={this.props.lang.emailLoginForm.troubleLink}
					textStyle={_linkText}
				/>
				<Text allowFontScaling={false} style={{ width: 300 }}></Text>
			</View>
		);
	}

	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.emailLoginState != processState.STARTED}
				backAction={this.props.cancelEmailLogin}
				closeAction={this.props.cancelEmailLogin}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderContent() {
		return (
			<ImageBackground source={this.props.DEFAULT_BACK.url} style={styles.backgroundContainer}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderUsernameField()}
					{this.renderUsernameError()}
					{this.renderPwdField()}
					{this.renderPwdError()}
					{this.renderLink()}
					{this.renderLoginButton()}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.emailLoginState == processState.STARTED)
			return (
				<ImageBackground source={this.props.DEFAULT_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		if (this.props.troubleLoginStarted) {
			return <LogInTrouble />;
		} else {
			return this.renderContent();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1 },
	container: { flex: 1 },
	containerContent: {
		minHeight: "100%",
		padding: 20,
		paddingTop: 80,
		justifyContent: "center",
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	buttonContainer: {
		marginTop: 10,
		justifyContent: "flex-end",
		alignItems: "flex-end",
		paddingRight: 150,
	},
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 300,
		height: 50,
		marginRight: 2,
		borderRadius: 5,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		outlineWidth: 0,
	},
	linkText: {
		width: 300,
		marginLeft: 100,
		paddingTop: 2,
		paddingRight: 10,
		fontFamily: f_sourcesansprobolditalic,
		fontSize: 22,
		color: "#00CED9",
		lineHeight: 40,
		textAlign: "left",
		textDecorationLine: "none",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 10,
	},
	dataErrorContainer: {
		width: "100%",
		minHeight: 40,
		marginTop: 0,
		marginBottom: 10,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	fieldError: {
		width: 500,
		height: "100%",
		color: "#FE1F01",
		textAlign: "right",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		troubleLoginStarted: state.app.troubleLoginStarted,
		emailLoginState: state.app.emailLoginState,
		emailLoginError: state.app.emailLoginError,
		isEmailLoggedIn: state.app.isEmailLoggedIn,
		resolution: state.currentUser.preferences.resolution,
		DEFAULT_BACK: state.images.DEFAULT_BACK,
	};
};

const mapDispatchToProps = {
	cancelEmailLogin,
	emailLoginSucceeded,
	openLogInTrouble,
	saveEmailPassword,
	saveEmailUsername,
	startEmailLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailLoginForm);
