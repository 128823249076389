import { cloneDeep } from "lodash";
import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { Dimensions, ImageBackground, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, f_sourcesansproregular, isWebOrTablet } from "../../../config/defaults";
import { gameDimensions } from "../../../config/dimensions";
import parse from "../../../helpers/emoji_parse";
import { closeShopHelp, goBackToMain } from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { AppTouchableOpacity, ColoredButton, NavigationHeader } from "../../common";
import { handleError } from "../../ErrorHandler";

class ShopHelp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openedIndex: -1,
			questions: cloneDeep(this.props.lang.shopHelp),
			scrollViewHeight: 15,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("ShopHelp");
	}
	//#endregion

	//#region events
	measureScrollView(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const { height } = event.nativeEvent.layout;
			this.setState({ scrollViewHeight: height });
		} catch (error) {
			handleError(error);
		}
	}

	onGotItPress() {
		this.setState({ openedIndex: -1 });
	}

	openQuestion(index) {
		this.setState({ openedIndex: index });
	}

	onBackPress() {
		if (this.state.openedIndex == -1) {
			this.props.closeShopHelp();
		} else {
			this.onGotItPress();
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButtons() {
		var buttons = [];
		this.state.questions.forEach((item, index) => {
			var _buttonColor = "";
			const _mod = (index % 5) + 1;
			switch (_mod) {
				case 1:
					_buttonColor = buttonColor.YELLOW;
					break;
				case 2:
					_buttonColor = buttonColor.PURPLE;
					break;
				case 3:
					_buttonColor = buttonColor.BLUE;
					break;
				case 5:
					_buttonColor = buttonColor.RED;
					break;
				case 4:
				default:
					_buttonColor = buttonColor.GREEN;
					break;
			}
			buttons.push(
				<ColoredButton
					key={index}
					width={500}
					height={60}
					onPressFunc={() => this.openQuestion(index)}
					textContent={item.title}
					accessibilityLabel={item.title}
					color={_buttonColor}
					disabled={false}
					isLong={true}
					isInMenu={true}
				/>
			);
		});
		return buttons;
	}

	renderQuestionInnerDetails(question) {
		const msgContent = parse(question.content);
		if (Platform.OS === "web") {
			const _height = Dimensions.get("window").height - 80;
			return (
				<ScrollView
					nestedScrollEnabled={true}
					showsVerticalScrollIndicator={false}
					style={[styles.detailsContent, { height: _height }]}
					onLayout={(event) => this.measureScrollView(event)}
				>
					<CustomScroll
						allowOuterScroll={true}
						heightRelativeToParent={this.state.scrollViewHeight - 5 + "px"}
						minScrollHandleHeight={15}
					>
						<View style={styles.contentContainer}>
							<Text allowFontScaling={false} style={styles.contentText}>
								{msgContent}
							</Text>
						</View>
					</CustomScroll>
				</ScrollView>
			);
		}

		const _height = Dimensions.get("screen").height - 80;
		return (
			<ScrollView style={[styles.detailsContent, { height: _height }]}>
				<Text allowFontScaling={false} style={styles.contentText}>
					{msgContent}
				</Text>
			</ScrollView>
		);
	}

	renderQuestionDetails() {
		const question = this.state.questions[this.state.openedIndex];
		var nextIndex = 0;
		if (this.state.openedIndex >= this.state.questions.length - 1) {
			nextIndex = 0;
		} else {
			nextIndex = this.state.openedIndex + 1;
		}
		const continueContent = this.props.lang.helpMenu.continue.replace("#1#", this.state.questions[nextIndex].title);
		return (
			<View style={styles.detailsContainer}>
				<View style={styles.titleContainer}>
					<Text allowFontScaling={false} style={styles.titleStyle}>
						{question.title}
					</Text>
				</View>
				{this.renderQuestionInnerDetails(question)}
				<View style={styles.detailsButtonContainer}>
					<AppTouchableOpacity style={styles.continueButton} onPress={() => this.openQuestion(nextIndex)}>
						<Text allowFontScaling={false} style={styles.continueText} numberOfLines={1}>
							{continueContent}
						</Text>
					</AppTouchableOpacity>
					<ColoredButton
						width={gameDimensions.gameButtonsWidth}
						height={60}
						onPressFunc={() => this.onGotItPress()}
						textContent={this.props.lang.helpMenu.gotIt}
						accessibilityLabel={this.props.lang.helpMenu.gotIt}
						color={buttonColor.GREEN}
						disabled={false}
					/>
				</View>
			</View>
		);
	}

	renderMain() {
		return (
			<ImageBackground
				source={this.props.openedFrom != "shop" || !isWebOrTablet ? this.props.HELP_BACK.url : { uri: "https://" }}
				style={{ flex: 1 }}
			>
				{this.state.openedIndex == -1 && (
					<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
						{this.renderButtons()}
					</ScrollView>
				)}
				{this.state.openedIndex != -1 && this.renderQuestionDetails()}
				{(this.props.openedFrom != "shop" || !isWebOrTablet) && this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		return this.renderMain();
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		paddingHorizontal: 55,
	},
	containerContent: { flex: 1, justifyContent: "center", alignItems: "center" },
	detailsContent: {
		paddingLeft: 35,
		paddingRight: Platform.OS === "web" ? 15 : 35,
		marginTop: 20,
		marginBottom: 5,
	},
	detailsContainer: {
		flex: 1,
		margin: gameDimensions.fullWidthMargin,
		padding: gameDimensions.fullWidthMargin,
	},
	titleContainer: {
		width: "100%",
		height: 50,
		paddingHorizontal: 55,
		justifyContent: "center",
	},
	titleStyle: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansprobold,
		fontSize: 26,
		textAlign: "center",
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
	},
	contentContainer: { flex: 1, paddingRight: Platform.OS === "web" ? 20 : 0 },
	contentText: {
		width: "100%",
		lineHeight: 28,
		fontFamily: f_sourcesansproregular,
		fontSize: 18,
		color: "#FFFFFF",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
	},
	detailsButtonContainer: {
		flexDirection: "row",
		width: "100%",
		paddingHorizontal: 10,
		justifyContent: "space-between",
		marginTop: 10,
	},
	continueButton: { flex: 1, justifyContent: "center" },
	continueText: {
		width: "100%",
		fontFamily: f_sourcesansprobold,
		fontSize: 18,
		lineHeight: 50,
		color: "#00ced9",
		textShadowColor: "#333",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		HELP_BACK: state.images.HELP_BACK,
	};
};

const mapDispatchToProps = { closeShopHelp, goBackToMain };

export default connect(mapStateToProps, mapDispatchToProps)(ShopHelp);
