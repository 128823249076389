import React, { Component } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansproitalic, isTablet, isWebOrTablet, modalType, RESOLUTION } from "../../config/defaults";
import { gameListColumns, gameListMobileColumns } from "../../config/dimensions";
import { HEADER_CHIP_ICON_RED, HEADER_CHIP_ICON_DISABLED } from "../../config/images";
import { MyWebSocket } from "../../connection";
import * as helpers from "../../helpers/commonHelpers";
import {
	clearGameSelection,
	displayPermissionDenied,
	gameListTableContentSizeChanged,
	gameListTableHeaderSize,
	openGameRoom,
	openModalDialog,
	pressGameListHeaderCell,
	selectGameInLobby,
} from "../../redux/actions";
import { AppTouchableOpacity, TableHeader, TableHeaderCell, TableRow, TableRowCell } from "../common";
import { handleError } from "../ErrorHandler";

class GameListTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columnNames: this.getColumNames(),
			maxVisibleRows: 1,
		};
	}

	//#region events
	tableRendered(event) {
		try {
			const tableHeight = event.nativeEvent.layout.height;
			const maxVisibleRows = isWebOrTablet ? Math.floor(tableHeight / 31) : Math.floor(tableHeight / 20);
			this.setState({ maxVisibleRows });
		} catch (error) {
			handleError(error);
		}
	}

	onHeaderColumnPress(columnKey) {
		this.props.pressGameListHeaderCell(columnKey);
	}

	onHeaderLayout(event) {
		this.props.gameListTableHeaderSize(event.nativeEvent.layout);
	}

	onRowSelect(rowId) {
		try {
			let selGameID = rowId.split("row_").pop();
			if (this.props.selectedGameID.toString() != selGameID) {
				this.props.selectGameInLobby(selGameID);
				var msg = {
					sMessageID: 0,
					type: "getGameDetails",
					gameID: selGameID,
				};
				MyWebSocket.shared.sendMsg(msg);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onRowDoubleTap(rowId) {
		this.onRowSelect(rowId);
		setTimeout(() => {
			try {
				const { loading, gameID, state } = this.props.selectedGame;
				if (!loading && gameID != -1 && state !== "RUNNING") {
					const { gamePlayersList, selectedSlotID } = this.props.selectedGame;
					const { selectedGameID, gameListOrig } = this.props;
					const selGameIndex = gameListOrig.findIndex((obj) => obj.gameID == selectedGameID);
					var permission = this.checkPermission(gameListOrig[selGameIndex].playerCnt);
					if (!permission.enabled) {
						this.props.displayPermissionDenied(permission.rightID);
					} else {
						const gameName = selGameIndex == -1 ? "" : gameListOrig[selGameIndex].name;
						const cardset = selGameIndex == -1 ? "" : gameListOrig[selGameIndex].cardset;

						var firstOpenSlotID = gamePlayersList.findIndex((obj) => obj.username == this.props.lang.openSlot);
						var slotIDToJoin = selectedSlotID;
						if (slotIDToJoin > -1) {
							var selSlot = gamePlayersList.find((obj) => obj.slotID == slotIDToJoin);
							if (selSlot.username != this.props.lang.openSlot) slotIDToJoin = firstOpenSlotID;
						} else {
							slotIDToJoin = firstOpenSlotID;
						}

						if (slotIDToJoin != -1) {
							var msgGameJoin = {
								type: "joinGame",
								sMessageID: 0,
								gameID: selectedGameID,
								slotID: slotIDToJoin,
								swap: false,
							};
							MyWebSocket.shared.sendMsg(msgGameJoin);
							this.props.openGameRoom(selectedGameID, gameName, cardset);
							this.props.clearGameSelection();
						} else {
							const nrFreeSlots =
								selGameIndex != -1 && typeof gameListOrig[selGameIndex].freeSlots === "number"
									? gameListOrig[selGameIndex].freeSlots
									: -1;
							if (nrFreeSlots == 0) {
								this.props.openModalDialog(
									modalType.ERROR,
									this.props.lang.messages.noOpenSlotToPlayMessage,
									this.props.lang.ok || "",
									null
								);
							}
						}
					}
				}
			} catch (error) {
				handleError(error);
			}
		}, 500);
	}

	onTableBackPress() {
		this.props.clearGameSelection();
	}
	//#endregion

	//#region render methods
	renderRow(item) {
		const rowId = "row_" + item.gameID;
		const { gameListTableColumnsDimensions } = this.props;
		let cells = [];
		helpers.mapObject(this.state.columnNames, (key) => {
			if (typeof item[key] !== "undefined") {
				let cellId = key + "_" + item.gameID;
				let cellKey = "cell_" + cellId;
				const textStyle =
					gameListColumns[key].cellType !== "img"
						? {
								textAlign: "center",
								color: "#FFFFFF",
								lineHeight: 40,
								height: 40,
								fontSize: 20,
								paddingLeft: key == "playerCnt" ? (item.gameState === "RUNNING" ? 1 : 3) : undefined,
								paddingRight: key == "playerCnt" ? (item.gameState === "RUNNING" ? 1 : 3) : undefined,
						  }
						: {};
				var countryURL = "",
					chipURL = "",
					imgDim = {},
					_content = item[key],
					_cellWidth = "100%",
					showToolTip = false,
					tooltipText = "";
				switch (key) {
					case "playerCnt":
						_content =
							item.gameState === "RUNNING" ? item.progressInPercent + " %" : item.freeSlots + " / " + item.playerCnt;
						_cellWidth = 55;
						break;
					case "creatorCountry":
						countryURL = { uri: helpers.getCountryFlagImageURL(item[key], 40) };
						imgDim = { width: 30, height: 30 };
						_cellWidth = 40;
						showToolTip = true;
						const countryCode = typeof item[key] === "string" ? item[key].toLowerCase() : undefined;
						tooltipText =
							countryCode && typeof this.props.lang.countryList[countryCode] === "string"
								? this.props.lang.countryList[countryCode]
								: undefined;
						break;
					case "chipsRequired":
						chipURL = item.chipsRequired > 0 ? HEADER_CHIP_ICON_RED : HEADER_CHIP_ICON_DISABLED;
						imgDim = { width: 35, height: 35, marginTop: 8 };
						_cellWidth = 40;
						break;
				}
				cells.push(
					<TableRowCell
						lang={this.props.lang}
						isSelected={item.isSelected}
						backgroundColor={item.gameState !== "RUNNING" ? "transparent" : "rgba(10, 95, 110,0.5)"}
						selectedBackgroundColor={"#F6A022"}
						style={[
							textStyle,
							item.gameState === "RUNNING" && {
								fontFamily: f_sourcesansproitalic,
							},
						]}
						// cellWidth={gameListTableColumnsDimensions[key]}
						cellWidth={_cellWidth}
						key={cellKey}
						cellId={cellId}
						content={_content}
						cellType={gameListColumns[key].cellType || "text"}
						imgURL={countryURL || chipURL || { uri: "https://" }}
						imgDim={imgDim}
						cellContainerStyle={[{ height: 40 }, key == "name" && { flex: 1 }]}
						showToolTip={showToolTip}
						tooltipText={tooltipText}
					/>
				);
			}
		});

		const rowSelectFunc = () => this.onRowSelect(rowId);
		const onDoubleTapFunc = () => this.onRowDoubleTap(rowId);
		return (
			<TableRow rowId={rowId} onPress={rowSelectFunc} onDoubleTap={onDoubleTapFunc}>
				{cells}
			</TableRow>
		);
	}

	renderHeader() {
		const columnDimensions = this.getColumnDimensions();
		const headerCells = [];

		helpers.mapObject(this.state.columnNames, (key, value) => {
			const sortFunction = gameListColumns[key].sortable ? () => this.onHeaderColumnPress(key) : null;
			let cWidth = 0;
			if (typeof columnDimensions[key] === "number") cWidth = columnDimensions[key];
			const textStyle = {
				textAlign: "center",
				backgroundColor: "transparent",
				alignSelf: isTablet && this.props.resolution == RESOLUTION.LOW ? "flex-end" : "center",
			};
			const containerStyle = {
				width: cWidth,
				justifyContent: "center",
				backgroundColor: "transparent",
			};

			headerCells.push(
				<TableHeaderCell
					key={key + "_glHeader"}
					cellContent={value}
					containerStyle={[
						containerStyle,
						this.props.resolution == RESOLUTION.LOW && {
							minHeight: isTablet ? 22 : 16,
							height: isTablet ? 22 : 16,
						},
					]}
					textStyle={textStyle}
					// onPress={sortFunction}
					// isSortable={gameListColumns[key].sortable || false}
					// isSelected={gameListTableHeaderColumns[key].isSelected || false}
					// isAscending={gameListTableHeaderColumns[key].isAscending || false}
				/>
			);
		});

		return <TableHeader onLayout={(event) => this.onHeaderLayout(event)}>{headerCells}</TableHeader>;
	}

	renderTable() {
		return (
			<AppTouchableOpacity
				activeOpacity={1}
				style={{ height: "100%", paddingBottom: 10 }}
				onPress={this.onTableBackPress.bind(this)}
				disabled={this.props.selectedGame.loadingGamePlayersList}
				accessibilityState={{
					disabled: this.props.selectedGame.loadingGamePlayersList,
				}}
				accessibilityLabel={"deselect game"}
			>
				<FlatList
					keyExtractor={(game, index) => game.gameID.toString() + "_" + index}
					data={this.props.gameListFiltered || []}
					extraData={this.props.gameListFiltered}
					renderItem={({ item }) => this.renderRow(item)}
					getItemLayout={(data, index) => ({
						length: 42,
						offset: 42 * index,
						index,
					})}
					onContentSizeChange={(width, height) => {
						this.props.gameListTableContentSizeChanged({ width, height });
					}}
					onLayout={(event) => this.tableRendered(event)}
					accessibilityLabel={"Games list"}
				/>
			</AppTouchableOpacity>
		);
	}

	render() {
		const _tableStyle = {
			height: this.props.gameListTable.contentHeight || null,
		};
		return (
			<View style={[styles.tableStyle, _tableStyle]}>
				{/* {this.renderHeader()} */}
				{this.renderTable()}
			</View>
		);
	}
	//#endregion

	//#region helpers
	getColumNames() {
		try {
			let columnNames = {};
			const { gameListTableColumns } = this.props.lang.gameLobby;
			helpers.mapObject(gameListMobileColumns, (key) => {
				columnNames[key] = gameListTableColumns[key] || "";
			});
			return columnNames;
		} catch (error) {
			handleError(error);
		}
	}

	getColumnDimensions() {
		try {
			const { gameListTableColumnsDimensions } = this.props;
			let dims = {};
			helpers.mapObject(gameListTableColumnsDimensions, (key) => {
				dims[key] = gameListTableColumnsDimensions[key];
			});
			return dims;
		} catch (error) {
			handleError(error);
		}
	}

	checkPermission(playerAmount) {
		try {
			var result = { rightID: "", enabled: true };
			var permissionID = "GAME_PLAY_CAN_PLAY_WITH_" + playerAmount + "_PLAYERS";
			if (typeof this.props[permissionID] === "boolean") {
				result.rightID = permissionID;
				result.enabled = this.props[permissionID];
			}
			return result;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	tableStyle: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
	},
});

const mapStateToProps = (state) => {
	const { features } = state.currentUser;
	return {
		lang: state.language.currentLanguage,
		gameListFiltered: state.gameListData.gameListFiltered,
		gameListTableHeaderColumns: state.gameListData.gameListTableHeaderColumns,
		selectedGameID: state.gameListData.selectedGameID,
		gameListOrig: state.gameListData.gameListOrig,
		gameListTableColumnsDimensions: state.views.gameListTableColumnsDimensions,
		gameListTable: state.views.gameListTable,
		selectedGame: state.gameDetailsData.selectedGame,
		resolution: state.currentUser.preferences.resolution,
		GAME_PLAY_CAN_PLAY_WITH_2_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_2_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_3_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_3_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_4_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_4_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_5_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_5_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_6_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_6_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_7_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_7_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_8_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_8_PLAYERS,
	};
};

const mapDispatchToProps = {
	clearGameSelection,
	displayPermissionDenied,
	gameListTableContentSizeChanged,
	gameListTableHeaderSize,
	openGameRoom,
	openModalDialog,
	pressGameListHeaderCell,
	selectGameInLobby,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameListTable);
