//#region GameMessage actions
export const OPEN_MESSAGE_INBOX = "open_message_inbox";
export const OPEN_MESSAGE_DETAILS = "open_message_details";
export const CLOSE_MESSAGE_DETAILS = "close_message_details";
export const OPEN_COMPOSE_MESSAGE = "open_compose_message";
export const CLOSE_COMPOSE_MESSAGE = "close_compose_message";
export const SAVE_MESSAGE_LIST = "save_message_list";
export const CLEAR_QUERIED_USERS = "clear_queried_users";
export const RECEIVE_NEW_MESSAGE = "receive_new_message";
export const UPDATE_MESSAGE_DETAILS = "update_message_details";
export const TOGGLE_CHECK_MESSAGE = "toggle_check_message";
//#endregion

//#region SharedInput actions
export const CHANGE_STARTED_GAME_CHAT_TEXT = "change_started_game_chat_text";
export const CLEAR_STARTED_GAME_CHAT_TEXT = "clear_started_game_chat_text";
export const UPDATE_IN_GAME_ACTIVE_CHATROOM = "update_in_game_active_chatroom";
export const CHANGE_GLOBAL_CHAT_TEXT = "change_global_chat_text";
export const CLEAR_GLOBAL_CHAT_TEXT = "clear_global_chat_text";
export const CHANGE_GAME_ROOM_CHAT_TEXT = "change_game_room_chat_text";
export const CLEAR_GAME_ROOM_TEXT = "clear_game_room_chat_text";
export const UPDATE_CREATED_GAME_ACTIVE_CHATROOM = "update_created_game_active_chatroom";
//#endregion

//#region sounds
export const REGISTER_SOUNDS = "register_sounds";
export const TOGGLE_SYSTEM_SOUND = "toggle_system_sound";
//#endregion

//#region StartedGame actions
export const GAME_STARTED_RECEIVED = "game_started_received";
export const GAME_INFO_RECEIVED = "game_info_received";
export const GAME_END_RECEIVED = "game_end_received";
export const RESET_GAME_END = "reset_game_end";
export const RESET_STARTED_GAME = "reset_started_game";
export const SHOW_DID_YOU_KNOW = "show_did_you_know";
//#endregion

//#region NotificationBar actions
export const DISPLAY_NOTIFICATION = "display_notification";
export const ROTATE_NOTIFICATIONS = "rotate_notifications";
export const PROCESS_ANNOUNCEMENT = "process_announcement";
export const RESET_CURRENT_MESSAGE_INDEX = "reset_current_message_index";
export const INCREASE_CURRENT_MESSAGE_INDEX = "increase_current_message_index";
export const INCREASE_CURRENT_ANNOUNCEMENTS_DISPLAYED_NUMBER = "increase_current_announcements_displayed_number";
export const REMOVE_CURRENT_DISPLAYED_MESSAGE = "remove_current_displayed_message";
export const REMOVE_CURRENT_ANNOUNCEMENT_FROM_QUEUE = "remove_current_announcement_from_queue";
//#endregion

//#region Questionnaire actions
export const OPEN_QUIESTIONNAIRE = "open_questionnaire";
export const PREPARE_SURVEY = "prepare_survey";
export const SAVE_ANSWER = "save_answer";
export const CLOSE_QUIESTIONNAIRE = "close_questionnaire";
//#endregion

//#region Leaderboard actions
export const SELECT_LB_CATEGORY = "select_lb_category";
export const SELECT_LB_GROUP = "select_lb_group";
export const SAVE_LB_DATA = "save_lb_data";
//#endregion

//#region DEBUG actions
export const ADD_LOG = "add_log";
export const OPEN_LOGGER = "open_logger";
export const CLOSE_LOGGER = "close_logger";
export const OPEN_DEBUG_MENU = "open_debug_menu";
export const CLOSE_DEBUG_MENU = "close_debug_menu";
//#endregion

//#region TOURNAMENT actions
export const TOURNAMENT_ACTIONS = Object.freeze({
	SET_TOURNAMENT_SCREEN_ACTIVE: "set_tournament_screen_active",
	CLEAR_TOURNAMENT_LIST: "clear_tournament_list",
	OPEN_TOURNAMENT_TAB: "open_tournament_tab",
	CLOSE_TOURNAMENT_TAB: "close_tournament_tab",
	INSERT_TOURNAMENT: "insert_tournament",
	REMOVE_TOURNAMENT: "remove_tournament",
	SELECT_TOURNAMENT: "select_tournament",
	DESELECT_TOURNAMENT: "deselect_tournament",
	SAVE_TOURNAMENT_DETAILS: "save_tournament_details",
	OPEN_TOURNAMENT_DETAILS: "open_tournament_details",
	CLOSE_TOURNAMENT_DETAILS: "close_tournament_details",
	SELECT_TEAM: "select_team",
	DESELECT_TEAM: "deselect_team",
	OPEN_CREATE_TEAM: "open_create_team",
	CLOSE_CREATE_TEAM: "close_create_team",
	SELECT_NEW_TEAM_TYPE: "select_new_team_type",
	SAVE_NEW_TEAM: "save_new_team",
	SAVE_NEW_TEAM_NAME: "save_new_team_name",
	SAVE_NEW_TEAM_PIN_CODE: "save_new_team_pin_code",
	SAVE_NEW_TEAM_MIN_LEVEL: "save_new_team_min_level",
	SAVE_NEW_TEAM_MAX_LEVEL: "save_new_team_max_level",
	OPEN_TOURNAMENT_SCORE_STAT: "open_tournament_score_stat",
	CLOSE_TOURNAMENT_SCORE_STAT: "close_tournament_score_stat",
	OPEN_TOURNAMENT_PAYOUT: "open_tournament_payout",
	CLOSE_TOURNAMENT_PAYOUT: "close_tournament_payout",
	OPEN_TOURNAMENT_LADDER_STAT: "open_tournament_ladder_stat",
	CLOSE_TOURNAMENT_LADDER_STAT: "close_tournament_ladder_stat",
	UPDATE_TOURNAMENT_LADDER_STAT: "update_tournament_ladder_stat",
	OPEN_POOL_OVERVIEW: "open_pool_overview",
	CLOSE_POOL_OVERVIEW: "close_pool_overview",
	SAVE_NEW_TOURNAMENT_NAME: "save_new_tournament_name",
	SELECT_NEW_TOURNAMENT_TYPE: "select_new_tournament_type",
	SELECT_NEW_TOURNAMENT_TEAMCOUNT: "select_new_tournament_teamcount",
	SELECT_NEW_TOURNAMENT_BUYIN: "select_new_tournament_buyin",
	SELECT_NEW_TOURNAMENT_STARTS_AT: "select_new_tournament_starts_at",
});
//#endregion

//#region IMAGE actions
export const IMAGE_ACTIONS = Object.freeze({
	PRELOAD_SCREEN_BACKGROUNDS: "preload_screen_backgrounds",
});
//#endregion
