import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import Position from "./Position";

class BoardPositions extends Component {
	renderPositions() {
		var _boardPositions = [];
		this.props.boardPositions.map((bpos) => {
			const isBaseColored = bpos.id[0] === "f" || (typeof bpos.isBasePos === "boolean" && bpos.isBasePos);

			_boardPositions.push(
				<Position
					key={"pos_" + bpos.id}
					id={bpos.id}
					gameTheme={this.props.gameTheme}
					animatedMove={bpos.animatedMove}
					requestHint={bpos.requestHint}
					playable={bpos.playable}
					filled={bpos.filled}
					selected={bpos.selected}
					smashable={bpos.filled && bpos.targeted}
					targeted={bpos.targeted}
					filtered={bpos.filtered}
					lastSwithed={bpos.lastSwithed}
					moveEnd={bpos.moveEnd}
					moveStarter={bpos.moveStarter}
					implicatedInMove={bpos.implicatedInMove}
					baseColor={bpos.baseColor ? bpos.baseColor.normal : "#"}
					isBaseColored={isBaseColored}
					pawnColor={bpos.color ? bpos.color.normal : "#"}
					top={bpos.top}
					left={bpos.left}
					mainObj={bpos}
					onFinishedPawnMoveAction={this.props.onFinishedPawnMoveAction}
				/>
			);
		});

		return _boardPositions;
	}

	render() {
		return <View key="boardPositions">{this.renderPositions()}</View>;
	}
}

const mapStateToProps = (state) => {
	return {
		boardPositions: state.startedGame.gameBoard.boardPositions,
		gameTheme: state.startedGame.gameTheme,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BoardPositions);
