import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { FlatList, Image, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansprobolditalic } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { lobbyChat } from "../../config/images";
import { mapObject } from "../../helpers/commonHelpers";
import { selectChatRoomTab } from "../../redux/actions";
import { AppTouchableOpacity, Box, HeaderText } from "../common";
import { handleError } from "../ErrorHandler";
import GameRoomChatMessageBox from "./GameRoomChatMessageBox";

class GameRoomChat extends Component {
	constructor(props) {
		super(props);
		var _chatRooms = [{ chatRoomID: -1, name: this.props.gameChatName }];
		mapObject(this.props.joinedChatRooms, (k, v) => _chatRooms.push(v));
		_chatRooms.forEach((room) => (room.selected = room.chatRoomID == -1));

		this.state = {
			chatRooms: _chatRooms,
			activeRoom: _chatRooms[0],
			firstViewableIndex: 0,
			lastViewableIndex: 1,
			mainWidth: 0,
			mainHeight: 0,
		};

		this.handleViewableItemsChanged = this.onViewableItemsChanged.bind(this);
		this.viewabilityConfig = { itemVisiblePercentThreshold: 90 };
		this.viewPosition = 0;
	}

	//#region lifecycle methods
	componentDidUpdate(prevProps) {
		if (prevProps.gameChatName != this.props.gameChatName) {
			var newRooms = this.state.chatRooms;
			var newActiveRoom = this.state.activeRoom;
			var gameChatRoom = newRooms.find((room) => room.chatRoomID == -1);
			if (gameChatRoom != undefined) {
				gameChatRoom.name = this.props.gameChatName;
				newRooms = [...this.state.chatRooms];
			}
			if (this.state.activeRoom != null && this.state.activeRoom.chatRoomID == -1) {
				newActiveRoom = {
					...this.state.activeRoom,
					name: this.props.gameChatName,
				};
			}
			this.setState({ chatRooms: newRooms, activeRoom: newActiveRoom });
		}
	}
	//#endregion

	//#region events
	measureMainContainer(event) {
		if (Platform.OS !== "web") event.persist();
		const { layout } = event.nativeEvent;
		this.setState({ mainWidth: layout.width, mainHeight: layout.height });
	}

	onChatTabPress(roomID) {
		try {
			if (typeof roomID === "undefined") return false;
			var chRooms = cloneDeep(this.state.chatRooms);
			var _roomIndex = chRooms.findIndex((r) => r.chatRoomID == roomID);
			if (_roomIndex == -1) return false;
			var _room = chRooms[_roomIndex];
			if (this.state.activeRoom === null || _room.chatRoomID !== this.state.activeRoom.chatRoomID) {
				chRooms.forEach((room) => (room.selected = false));
				_room.selected = true;
				this.props.setActiveRoom(_room.chatRoomID);
				this.setState({ chatRooms: chRooms, activeRoom: _room });
				if (_room.chatRoomID != -1) this.props.selectChatRoomTab(_room);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onViewableItemsChanged = ({ viewableItems, changed }) => {
		try {
			if (viewableItems.length > 0) {
				this.setState({
					firstViewableIndex: viewableItems[0].index,
					lastViewableIndex: viewableItems[viewableItems.length - 1].index,
				});
			}
		} catch (error) {
			handleError(error);
		}
	};

	onPreviousPress() {
		try {
			const nextIndex = this.state.firstViewableIndex - 1;
			if (nextIndex >= 0) {
				this.refs.chatTabsList.scrollToIndex({
					index: nextIndex,
					viewPosition: this.viewPosition,
				});
			} else {
				this.refs.chatTabsList.scrollToIndex({
					index: this.state.firstViewableIndex,
					viewPosition: this.viewPosition,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onNextPress() {
		try {
			const nextIndex = this.state.lastViewableIndex + 1;
			if (nextIndex < this.state.chatRooms.length) {
				this.refs.chatTabsList.scrollToIndex({
					index: nextIndex,
					viewPosition: this.viewPosition,
				});
			} else {
				this.refs.chatTabsList.scrollToIndex({
					index: this.state.lastViewableIndex,
					viewPosition: this.viewPosition,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onScrollToIndexFailed(error) {
		if (error != undefined) {
			this.refs.chatTabsList.scrollToOffset({
				offset: error.averageItemLength * error.index,
				animated: false,
			});
			setTimeout(() => {
				if (this.state.chatRooms.length !== 0 && this.refs.chatTabsList !== null) {
					this.refs.chatTabsList.scrollToIndex({
						index: error.index,
						viewPosition: this.viewPosition,
						animated: false,
					});
				}
			}, 50);
		}
	}
	//#endregion

	//#region render methods
	renderItemBack(isSelected, id) {
		const { activeTab, inactiveTab } = lobbyChat.tabArea.normal;
		const border = isSelected ? activeTab.border : inactiveTab.border;
		const corner = isSelected ? activeTab.corner : inactiveTab.corner;
		const center = isSelected ? activeTab.center : inactiveTab.center;
		return (
			<View key={"back_" + id} style={[styles.itemBackContainer, { width: "100%", height: isSelected ? 29 : 29 }]}>
				<Image
					key="tabTopLeftCorner"
					source={corner.top_left}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: 8,
						height: 8,
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabTopBorder"
					source={border.top}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: "94%",
						height: 8,
						position: "absolute",
						top: 0,
						left: 4,
						zIndex: -2,
					}}
				/>
				<Image
					key="tabTopRightCorner"
					source={corner.top_right}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: 8,
						height: 8,
						position: "absolute",
						top: 0,
						right: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabLeftBorder"
					source={border.left}
					resizeMode={Platform.OS !== "web" || !isSelected ? "stretch" : "repeat"}
					style={{
						width: 23,
						height: isSelected ? 23 : 23,
						position: "absolute",
						top: isSelected ? 7 : 7,
						left: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabCenter"
					source={center}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: "94%",
						height: isSelected ? 13 : 17,
						position: "absolute",
						top: isSelected ? 7 : 7,
						left: 4,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabRightBorder"
					source={border.right}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: 23,
						height: isSelected ? 23 : 23,
						position: "absolute",
						top: isSelected ? 7 : 7,
						right: 0,
						zIndex: -2,
					}}
				/>
				<Image
					key="tabBottomLeftCorner"
					source={corner.bottom_left}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: isSelected ? 21 : 21,
						height: isSelected ? 7 : 7,
						position: "absolute",
						bottom: isSelected ? -1 : -1,
						left: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabBottomBorder"
					source={border.bottom}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: "94%",
						height: isSelected ? 11 : 7,
						position: "absolute",
						bottom: isSelected ? -1 : -1,
						left: 4,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabBottomRightCorner"
					source={corner.bottom_right}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: 21,
						height: isSelected ? 7 : 7,
						position: "absolute",
						bottom: isSelected ? 0 : -1,
						right: 0,
						zIndex: -2,
					}}
				/>
			</View>
		);
	}

	renderItems(room, index) {
		const content = room.name ? room.name.toString() : " ";
		return (
			<AppTouchableOpacity
				activeOpacity={1}
				key={"room_" + room.chatRoomID}
				style={[styles.chatTabButton, { width: null, marginLeft: index == 0 ? 0 : -1.5 }]}
				onPress={() => this.onChatTabPress(room.chatRoomID)}
				silentPress={true}
				accessibilityLabel={content}
			>
				{this.renderItemBack(room.selected, room.chatRoomID)}
				<HeaderText
					style={{ width: undefined }}
					textContent={content}
					textContentStyle={{
						paddingLeft: 9,
						paddingRight: 9,
						fontFamily: f_sourcesansprobolditalic,
						fontSize: 18,
						color: room.selected ? "#084955" : "#f3f8fa",
						textShadowColor: room.selected ? "transparent" : "#1993a9",
						textShadowOffset: { width: 2, height: 2 },
						textShadowRadius: Platform.OS === "web" ? 0 : 4,
					}}
				/>
			</AppTouchableOpacity>
		);
	}

	renderPreviousBack() {
		try {
			const { border, corner, center } = lobbyChat.tabArea.normal.previous;
			return (
				<View key="prevBack" style={[styles.toggleButtonBack, { left: -2 }]}>
					<Image
						key="topLeftCorner"
						source={corner.top_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="topBorder"
						source={border.top}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 8,
							position: "absolute",
							top: 0,
							left: 7,
							zIndex: -2,
						}}
					/>
					<Image
						key="topRightCorner"
						source={corner.top_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							right: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="leftBorder"
						source={border.left}
						resizeMode={Platform.OS !== "web" ? "stretch" : "repeat"}
						style={{
							width: 8,
							height: 17,
							position: "absolute",
							top: 7,
							left: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="center"
						source={center}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 17,
							position: "absolute",
							top: 7,
							left: 7,
							zIndex: -3,
						}}
					/>
					<Image
						key="rightBorder"
						source={border.right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 17,
							position: "absolute",
							top: 7,
							right: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="bottomLeftCorner"
						source={corner.bottom_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							bottom: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="bottomBorder"
						source={border.bottom}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 8,
							position: "absolute",
							bottom: 0,
							left: 7,
							zIndex: -1,
						}}
					/>
					<Image
						key="bottomRightCorner"
						source={corner.bottom_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: -2,
						}}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderPreviousButton() {
		try {
			const { arrow } = lobbyChat.tabArea.normal.previous;
			return (
				<AppTouchableOpacity
					activeOpacity={0.7}
					disabled={false}
					style={styles.toggleButton}
					onPress={this.onPreviousPress.bind(this)}
					silentPress={true}
					accessibilityLabel={"scroll to previous chatroom"}
				>
					{this.renderPreviousBack()}
					<Image
						source={arrow}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={styles.toggleButtonImage}
					/>
				</AppTouchableOpacity>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderNextBack() {
		try {
			const { border, corner, center } = lobbyChat.tabArea.normal.next;
			return (
				<View key="nextBack" style={[styles.toggleButtonBack, { right: -2 }]}>
					<Image
						key="topLeftCorner"
						source={corner.top_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="topBorder"
						source={border.top}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 8,
							position: "absolute",
							top: 0,
							left: 7,
							zIndex: -2,
						}}
					/>
					<Image
						key="topRightCorner"
						source={corner.top_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							right: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="leftBorder"
						source={border.left}
						resizeMode={Platform.OS !== "web" ? "stretch" : "repeat"}
						style={{
							width: 8,
							height: 17,
							position: "absolute",
							top: 7,
							left: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="center"
						source={center}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 17,
							position: "absolute",
							top: 7,
							left: 7,
							zIndex: -3,
						}}
					/>
					<Image
						key="rightBorder"
						source={border.right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 17,
							position: "absolute",
							top: 7,
							right: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="bottomLeftCorner"
						source={corner.bottom_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							bottom: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="bottomBorder"
						source={border.bottom}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 8,
							position: "absolute",
							bottom: 0,
							left: 7,
							zIndex: -1,
						}}
					/>
					<Image
						key="bottomRightCorner"
						source={corner.bottom_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: -2,
						}}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderNextButton() {
		try {
			const { arrow } = lobbyChat.tabArea.normal.next;
			return (
				<AppTouchableOpacity
					activeOpacity={0.7}
					disabled={false}
					style={styles.toggleButton}
					onPress={this.onNextPress.bind(this)}
					silentPress={true}
					accessibilityLabel={"scroll to next chatroom"}
				>
					{this.renderNextBack()}
					<Image
						source={arrow}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={styles.toggleButtonImage}
					/>
				</AppTouchableOpacity>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderChatTabs() {
		try {
			return (
				<View key="chatTabsContainer" style={styles.chatTabsContainer}>
					{this.renderPreviousButton()}
					<FlatList
						viewabilityConfig={this.viewabilityConfig}
						onViewableItemsChanged={this.handleViewableItemsChanged}
						ref={"chatTabsList"}
						horizontal={true}
						directionalLockEnabled={true}
						showsHorizontalScrollIndicator={false}
						nestedScrollEnabled={true}
						keyExtractor={(item) => "room_" + item.chatRoomID.toString()}
						data={this.state.chatRooms}
						extraData={this.state.chatRooms}
						renderItem={({ item, index }) => this.renderItems(item, index)}
						onScrollToIndexFailed={(error) => this.onScrollToIndexFailed(error)}
						style={{
							flex: 1,
							height: "100%",
							paddingLeft: 2,
							top: -1,
							zIndex: -1,
						}}
						contentContainerStyle={{ paddingRight: 3 }}
					/>
					{this.renderNextButton()}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderTabBack() {
		try {
			const { border, corner, center } = lobbyChat.tabArea.normal.back;
			return (
				<View key="highResTabBack" style={[styles.chatTabsBackContainer, { width: this.state.mainWidth + 3 }]}>
					<Image
						key="tabLeftCorner"
						source={corner.top_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="tabTopBorder"
						source={border.top}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: this.state.mainWidth - 11,
							height: 7,
							position: "absolute",
							top: 0,
							left: 7,
							zIndex: -2,
						}}
					/>
					<Image
						key="tabRightCorner"
						source={corner.top_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 6,
							position: "absolute",
							top: 0,
							right: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="tabMiddleLeftBorder"
						source={border.left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 20,
							position: "absolute",
							top: 6,
							left: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="tabMiddleRightBorder"
						source={border.right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 6,
							height: 21,
							position: "absolute",
							top: 6,
							right: 0,
							zIndex: -4,
						}}
					/>
					<Image
						key="tabMiddle"
						source={center}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: this.state.mainWidth - 6,
							height: 20,
							position: "absolute",
							top: 6,
							left: 6,
							zIndex: -3,
						}}
					/>
					<Image
						key="tabLeftBorder"
						source={corner.bottom_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 7,
							height: 6,
							position: "absolute",
							bottom: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="tabRightBorder"
						source={corner.bottom_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 6,
							height: Platform.OS === "web" ? 5 : 6,
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: -2,
						}}
					/>
					<Image
						key="tabCenter"
						source={border.bottom}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: this.state.mainWidth - 8,
							height: 6,
							position: "absolute",
							bottom: 0,
							left: 6,
							zIndex: -1,
						}}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderChatTop() {
		try {
			return (
				<View style={[styles.chatTopContainer, { width: this.state.mainWidth + 3 }]}>
					{this.renderTabBack()}
					{this.renderChatTabs()}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderChatBox() {
		try {
			const { border, corner, center } = lobbyChat.background;
			const _width = this.state.mainWidth + 6;
			const _height = this.state.mainHeight + 6;
			return (
				<Box
					style={{
						position: "absolute",
						top: -3,
						left: -3,
						zIndex: -1,
						width: _width,
						height: _height,
						backgroundColor: "transparent",
						borderRadius: 3,
					}}
				>
					<Image
						key="backTLC"
						source={corner.top_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 15,
							height: 12,
							position: "absolute",
							top: 0,
							left: 0,
						}}
					/>
					<Image
						key="backTB"
						source={border.top}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: _width - 20,
							height: 12,
							position: "absolute",
							top: 0,
							left: 14,
							zIndex: -1,
						}}
					/>
					<Image
						key="backTRC"
						source={corner.top_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 15,
							height: 12,
							position: "absolute",
							top: 0,
							right: 0,
						}}
					/>
					<Image
						key="backLB"
						source={border.left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 13,
							height: _height - 20,
							position: "absolute",
							top: 11,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="backCenter"
						source={center}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: _width - 24,
							height: _height - 18,
							position: "absolute",
							top: 11,
							left: 12,
							zIndex: -1,
						}}
					/>
					<Image
						key="backRB"
						source={border.right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 13,
							height: _height - 20,
							position: "absolute",
							top: 11,
							right: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="backBLC"
						source={corner.bottom_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 13,
							height: 11,
							position: "absolute",
							bottom: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="backBB"
						source={border.bottom}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: _width - 24,
							height: 11,
							position: "absolute",
							bottom: 0,
							left: 12,
							zIndex: -1,
						}}
					/>
					<Image
						source={corner.bottom_right}
						key="backBRC"
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 13,
							height: 11,
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: -1,
						}}
					/>
					{this.renderChatTop()}
					<GameRoomChatMessageBox
						activeRoomID={this.state.activeRoom != undefined ? this.state.activeRoom.chatRoomID : undefined}
					/>
				</Box>
			);
		} catch (error) {
			handleError(error);
		}
	}

	render() {
		const isContainerRendered = this.state.mainWidth > 0 && this.state.mainHeight > 0;
		return (
			<View
				style={[
					styles.mainContainer,
					{
						height: this.props.grSlotSettingsHeight - gameDimensions.boxHeaderHeight,
					},
				]}
				onLayout={(event) => this.measureMainContainer(event)}
			>
				{isContainerRendered && this.renderChatBox()}
				{/* {isContainerRendered && this.renderChatTop()} */}
				{/* {isContainerRendered && (
          <GameRoomChatMessageBox
            activeRoomID={
              this.state.activeRoom != undefined
                ? this.state.activeRoom.chatRoomID
                : undefined
            }
          />
        )} */}
			</View>
		);
	}
	//#endregion
}
const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
		backgroundColor: "transparent",
		zIndex: 1,
		margin: 3,
	},
	chatTopContainer: {
		position: "absolute",
		height: 30,
		zIndex: 5,
		top: 1,
		left: 1,
	},
	chatTabsBackContainer: {
		position: "absolute",
		top: -2,
		left: -1,
		height: 30,
		zIndex: -1,
	},
	chatTabsContainer: {
		width: "100%",
		height: "100%",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "flex-end",
		top: -1,
	},
	chatTabButton: {
		justifyContent: "center",
		alignItems: "center",
	},
	toggleButton: {
		width: 25,
		height: 30,
		top: -1,
		justifyContent: "center",
	},
	toggleButtonImage: { width: 9, height: 12, alignSelf: "center" },
	toggleButtonBack: {
		position: "absolute",
		top: 0,
		zIndex: 0,
		width: 30,
		height: 30,
	},
	itemBackContainer: {
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: -1,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		gameID: state.startedGame.gameID,
		gameChatName: state.gameRoom.gameChatName,
		joinedChatRooms: state.globalChat.joinedChatRooms,
		grSlotSettingsHeight: state.views.grSlotSettingsHeight,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = { selectChatRoomTab };

export default connect(mapStateToProps, mapDispatchToProps)(GameRoomChat);
