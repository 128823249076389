import React, { Component } from "react";
import { FlatList, Image, ImageBackground, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansprosemibold, RESOLUTION } from "../../../config/defaults";
import { ACTIVE_GROUP, INACTIVE_GROUP, TOGGLE_ICON } from "../../../config/images";
import { selectLBGroup } from "../../../redux/actions";
import { AppTouchableOpacity } from "../../common";
import { handleError } from "../../ErrorHandler";

class GroupList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			viewableItemsNr: 0,
			firstViewableIndex: 0,
			lastViewableIndex: 0,
		};

		this.handleViewableItemsChanged = this.onViewableItemsChanged.bind(this);
		this.viewabilityConfig = { itemVisiblePercentThreshold: 90 };
	}

	//#region events
	onGroupSelect(group, index) {
		try {
			index = index == -1 ? 0 : index;
			this.refs.groupList.scrollToIndex({ index: index, viewPosition: 0.5 });
			this.props.selectLBGroup(group);
		} catch (error) {
			handleError(error);
		}
	}

	onPreviousPress() {
		try {
			const nextIndex = this.state.firstViewableIndex - 1;
			if (nextIndex >= 0) {
				this.refs.groupList.scrollToIndex({
					index: nextIndex,
					viewPosition: 0,
				});
			} else {
				this.refs.groupList.scrollToIndex({
					index: this.state.firstViewableIndex,
					viewPosition: 0,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onNextPress() {
		try {
			const nextIndex = this.state.lastViewableIndex + 1;
			if (nextIndex < this.props.groups.length) {
				this.refs.groupList.scrollToIndex({
					index: nextIndex,
					viewPosition: 1,
				});
			} else {
				this.refs.groupList.scrollToIndex({
					index: this.state.lastViewableIndex,
					viewPosition: 1,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onViewableItemsChanged = ({ viewableItems, changed }) => {
		try {
			if (viewableItems.length > 0) {
				this.setState({
					viewableItemsNr: viewableItems.length,
					firstViewableIndex: viewableItems[0].index,
					lastViewableIndex: viewableItems[viewableItems.length - 1].index,
				});
			}
		} catch (error) {
			handleError(error);
		}
	};
	//#endregion

	//#region render methods
	renderTab(group, index) {
		var _groupImage = styles.groupImage,
			_groupButton = styles.groupButton,
			_groupText = styles.groupText;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_groupImage = [styles.groupImage, { height: 58 }];
			_groupButton = [styles.groupButton, { height: 45 }];
			_groupText = [styles.groupText, { fontSize: 28, paddingHorizontal: 15 }];
		}
		return (
			<ImageBackground
				key={group.groupID}
				source={group.selected ? ACTIVE_GROUP : INACTIVE_GROUP}
				resizeMode="stretch"
				style={_groupImage}
			>
				<AppTouchableOpacity activeOpacity={1} onPress={() => this.onGroupSelect(group, index)} style={_groupButton}>
					<Text allowFontScaling={false} numberOfLines={1} style={_groupText}>
						{group.groupName}
					</Text>
				</AppTouchableOpacity>
			</ImageBackground>
		);
	}

	render() {
		var _toggleButton = styles.toggleButton,
			_toggleIcon = styles.toggleIcon,
			_container = styles.container;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_toggleButton = [styles.toggleButton, { width: 45, height: 45 }];
			_toggleIcon = [styles.toggleIcon, { width: 45, height: 45 }];
			_container = [styles.container, { height: 60 }];
		}
		return (
			<View style={_container}>
				<AppTouchableOpacity
					disabled={this.state.viewableItemsNr == this.props.groups.length}
					onPress={this.onPreviousPress.bind(this)}
					style={_toggleButton}
					accessibilityState={{
						disabled: this.state.viewableItemsNr == this.props.groups.length,
					}}
					accessibilityLabel={"scroll to previous item"}
				>
					<Image source={TOGGLE_ICON} style={_toggleIcon} resizeMode="stretch" />
				</AppTouchableOpacity>
				<FlatList
					ref="groupList"
					horizontal={true}
					showsHorizontalScrollIndicator={false}
					keyExtractor={(item, index) => item.groupID.toString()}
					data={this.props.groups}
					extraData={this.props.groups}
					renderItem={({ item, index }) => this.renderTab(item, index)}
					viewabilityConfig={this.viewabilityConfig}
					onViewableItemsChanged={this.handleViewableItemsChanged}
					style={{ flex: 1 }}
					contentContainerStyle={styles.contentContainer}
				/>
				<AppTouchableOpacity
					disabled={this.state.viewableItemsNr == this.props.groups.length}
					onPress={this.onNextPress.bind(this)}
					style={_toggleButton}
					accessibilityState={{
						disabled: this.state.viewableItemsNr == this.props.groups.length,
					}}
					accessibilityLabel={"scroll to next item"}
				>
					<Image
						source={TOGGLE_ICON}
						style={[_toggleIcon, { transform: [{ rotateY: "-180deg" }] }]}
						resizeMode="stretch"
					/>
				</AppTouchableOpacity>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		width: "100%",
		height: 50,
		flexDirection: "row",
	},
	toggleButton: {
		width: 35,
		height: 35,
		justifyContent: "center",
	},
	toggleIcon: { width: 30, height: 30, alignSelf: "center" },
	contentContainer: { justifyContent: "center" },
	groupImage: { height: 40, marginRight: 5 },
	groupButton: {
		height: 30,
		marginLeft: 3,
		marginRight: 4,
		justifyContent: "center",
	},
	groupText: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		color: "#085968",
		textAlign: "center",
		paddingHorizontal: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		groups: state.leaderboard.groups,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = { selectLBGroup };

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
