import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { appFont } from "../../config/defaults";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

class RadioButton extends Component {
	//#region render methods
	render() {
		const data = this.props.data;
		const display = !data.visible ? "none" : "flex";
		const opacity = data.enabled ? 1 : 0.5;
		let layout = { flexDirection: "row" };
		let margin = { marginLeft: 3, marginRight: 10 };
		if (data.layout === "column") {
			layout = { alignItems: "center" };
			margin = { marginTop: 10 };
		}
		return (
			<AppTouchableOpacity
				activeOpacity={opacity}
				style={[layout, { display }, { opacity }, data.buttonLayout]}
				onPress={() => {
					this.props.onPress(data.label);
				}}
			>
				<View
					style={[
						styles.border,
						{
							borderColor: "#ccc",
							width: data.size,
							height: data.size,
							borderRadius: data.size / 2,
							alignSelf: "center",
							marginRight: data.layout === "column" ? 10 : 0,
						},
						data.optionLayout,
					]}
				>
					{data.selected && (
						<View
							style={{
								backgroundColor: data.color,
								width: data.size / 2,
								height: data.size / 2,
								borderRadius: data.size / 4,
							}}
						/>
					)}
				</View>
				<Text allowFontScaling={false} style={[styles.textStyle, margin, data.textLayout]}>
					{data.label}
				</Text>
			</AppTouchableOpacity>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	border: {
		borderWidth: 0.5,
		borderColor: "#aaa",
		backgroundColor: "#eee",
		justifyContent: "center",
		alignItems: "center",
	},
	textStyle: {
		fontFamily: appFont,
		fontSize: 16,
		alignSelf: "center",
	},
});

export { RadioButton };
