import { cloneDeep } from "lodash";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { Component } from "react";
import { Dimensions, FlatList, Image, ImageBackground, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprosemibold } from "../../../../config/defaults";
import { gameDimensions, silverShopDimensions } from "../../../../config/dimensions";
import { ITEM_DEFAULT_IMAGE, NEXT_ICON, PREVIOUS_ICON } from "../../../../config/images";
import { openRateDone } from "../../../../redux/actions";
import { AppTouchableOpacity, ColoredButton } from "../../../common";
import { handleError } from "../../../ErrorHandler";
import RateDone from "./RateDone";

class SliderRate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			cardsets: [
				// {
				//   det_title: "Russian cards",
				//   imgBack: {
				//     uri: "https://webs-a.keezers.net/shop/background/cardsetBG.png",
				//   },
				//   itemID: "CARDSET_RUSSIAN",
				//   itemImgDim: {
				//     height: 110,
				//     width: 160,
				//   },
				//   itemImgSrc: {
				//     uri: "https://webs-a.keezers.net/shop/item/cardset_russian.png",
				//   },
				//   name: "russian",
				//   rateValue: 50,
				// },
				// {
				//   det_title: "Linear cards",
				//   imgBack: {
				//     uri: "https://webs-a.keezers.net/shop/background/cardsetBG.png",
				//   },
				//   itemID: "CARDSET_LINEAR",
				//   itemImgDim: { height: 110, width: 160 },
				//   itemImgSrc: {
				//     uri: "https://webs-a.keezers.net/shop/item/cardset_linear.png",
				//   },
				//   name: "linear",
				//   rateValue: 50,
				// },
				// {
				//   det_title: "Wild west cards",
				//   imgBack: {
				//     uri: "https://webs-a.keezers.net/shop/background/cardsetBG.png",
				//   },
				//   itemID: "CARDSET_WILD_WEST",
				//   itemImgDim: { height: 110, width: 160 },
				//   itemImgSrc: {
				//     uri: "https://webs-a.keezers.net/shop/item/cardset_wild_west.png",
				//   },
				//   name: "wild west",
				//   rateValue: 50,
				// },
				// {
				//   det_title: "Modern cards",
				//   imgBack: {
				//     uri: "https://webs-a.keezers.net/shop/background/cardsetBG.png",
				//   },
				//   itemID: "CARDSET_MODERN",
				//   itemImgDim: { height: 110, width: 160 },
				//   itemImgSrc: {
				//     uri: "https://webs-a.keezers.net/shop/item/cardset_modern.png",
				//   },
				//   name: "modern",
				//   rateValue: 50,
				// },
				// {
				//   det_title: "Sideways cards",
				//   imgBack: {
				//     uri: "https://webs-a.keezers.net/shop/background/cardsetBG.png",
				//   },
				//   itemID: "CARDSET_SIDEWAYS",
				//   itemImgDim: { height: 110, width: 160 },
				//   itemImgSrc: {
				//     uri: "https://webs-a.keezers.net/shop/item/cardset_sideways.png",
				//   },
				//   name: "sideways",
				//   rateValue: 50,
				// },
			],
			firstViewableIndex: 0,
			lastViewableIndex: 0,
		};

		this.handleViewableItemsChanged = this.onViewableItemsChanged.bind(this);
		this.viewabilityConfig = { itemVisiblePercentThreshold: 90 };
	}

	//#region events
	onLevelChange(index, value) {
		try {
			if (index >= 0) {
				var found = cloneDeep(this.state.cardsets[index]);
				found.rateValue = value;
				const curState = this.state.cardsets;
				curState.splice(index, 1, found);
				this.setState({ cardsets: curState });
			}
		} catch (error) {
			handleError(error);
		}
	}

	onViewableItemsChanged = ({ viewableItems, changed }) => {
		try {
			if (viewableItems.length > 0) {
				this.setState({
					firstViewableIndex: viewableItems[0].index,
					lastViewableIndex: viewableItems[viewableItems.length - 1].index,
				});
			}
		} catch (error) {
			handleError(error);
		}
	};

	onPreviousPress() {
		try {
			const nextIndex = this.state.firstViewableIndex - 1;
			if (nextIndex >= 0) {
				this.refs.itemsList.scrollToIndex({
					index: nextIndex,
					viewPosition: 0,
				});
			} else {
				this.refs.itemsList.scrollToIndex({
					index: this.state.firstViewableIndex,
					viewPosition: 0,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onNextPress() {
		try {
			const nextIndex = this.state.lastViewableIndex + 1;
			if (nextIndex < this.state.cardsets.length) {
				this.refs.itemsList.scrollToIndex({
					index: nextIndex,
					viewPosition: 1,
				});
			} else {
				this.refs.itemsList.scrollToIndex({
					index: this.state.lastViewableIndex,
					viewPosition: 1,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onScrollToIndexFailed(error) {
		try {
			if (error != undefined) {
				this.refs.itemsList.scrollToOffset({
					offset: error.averageItemLength * error.index,
					animated: false,
				});
				setTimeout(() => {
					if (this.state.cardsets.length !== 0 && this.refs.itemsList !== null) {
						this.refs.itemsList.scrollToIndex({
							index: error.index,
							viewPosition: 0.5,
							animated: false,
						});
					}
				}, 50);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onContinuePress() {
		try {
			this.props.openRateDone();
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderTopContent() {
		return (
			<View style={styles.topContainer}>
				<Text allowFontScaling={false} style={[styles.titleText]} numberOfLines={1}>
					{this.props.lang.gameShop.rateSomething.cardsetsSlider.title}
				</Text>
				<Text allowFontScaling={false} style={[styles.titleText, styles.subTitle]} numberOfLines={1}>
					{this.props.lang.gameShop.rateSomething.cardsetsSlider.subTitle}
				</Text>
			</View>
		);
	}

	renderItems(item, index) {
		try {
			return (
				<View key={"item_" + index} style={styles.itemContainer}>
					<ImageBackground source={item.imgBack} resizeMode="stretch" style={styles.itemBackground}>
						<Image
							source={item.itemImgSrc}
							defaultSource={ITEM_DEFAULT_IMAGE}
							resizeMode="stretch"
							style={{
								width: item.itemImgDim.width == 0 ? 120 : item.itemImgDim.width,
								height: item.itemImgDim.height == 0 ? 120 : item.itemImgDim.height,
								alignSelf: "center",
							}}
						/>
					</ImageBackground>
					<Slider
						className="rate-slider"
						min={0}
						max={100}
						step={1}
						value={item.rateValue}
						trackStyle={[{ backgroundColor: "#0C697A" }]}
						railStyle={{ backgroundColor: "#009688" }}
						handleStyle={[{ backgroundColor: "#0B5969", borderColor: "#0B5969" }]}
						onChange={(val) => this.onLevelChange(index, val)}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderBottomContent() {
		const { lastViewableIndex, firstViewableIndex } = this.state;
		const viewableItemsNr = lastViewableIndex - firstViewableIndex;
		return (
			<View style={styles.bottomContainer}>
				<AppTouchableOpacity
					disabled={firstViewableIndex == 0}
					style={[styles.toggleButton, { marginRight: 5 }]}
					onPress={this.onPreviousPress.bind(this)}
					accessibilityState={{ disabled: firstViewableIndex == 0 }}
					accessibilityLabel={"scroll to previous item"}
				>
					<Image source={PREVIOUS_ICON} style={[styles.toggleButton, { opacity: firstViewableIndex == 0 ? 0 : 1 }]} />
				</AppTouchableOpacity>
				<FlatList
					viewabilityConfig={this.viewabilityConfig}
					onViewableItemsChanged={this.handleViewableItemsChanged}
					ref={"itemsList"}
					horizontal={true}
					showsHorizontalScrollIndicator={false}
					keyExtractor={(item) => "item_" + item.itemID.toString()}
					data={this.state.cardsets}
					extraData={this.state.cardsets}
					renderItem={({ item, index }) => this.renderItems(item, index)}
					onScrollToIndexFailed={(error) => this.onScrollToIndexFailed(error)}
					style={styles.fullFlex}
					contentContainerStyle={[
						styles.listContainer,
						viewableItemsNr == this.state.cardsets.length - 1 ? styles.fullFlex : {},
					]}
				/>
				<AppTouchableOpacity
					disabled={lastViewableIndex == this.state.cardsets.length - 1 || this.state.cardsets.length == 0}
					style={[styles.toggleButton, { marginLeft: 5 }]}
					onPress={this.onNextPress.bind(this)}
					accessibilityState={{
						disabled: lastViewableIndex == this.state.cardsets.length - 1 || this.state.cardsets.length == 0,
					}}
					accessibilityLabel={"scroll to next item"}
				>
					<Image
						source={NEXT_ICON}
						style={[
							styles.toggleButton,
							{
								opacity: lastViewableIndex == this.state.cardsets.length - 1 || this.state.cardsets.length == 0 ? 0 : 1,
							},
						]}
					/>
				</AppTouchableOpacity>
			</View>
		);
	}

	renderContinueButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.onContinuePress.bind(this)}
					textContent={this.props.lang.continue}
					accessibilityLabel={this.props.lang.continue}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderContent() {
		return (
			<View style={[styles.container, { transform: [{ scale: this.state.contentScale }] }]}>
				{this.renderTopContent()}
				{this.renderBottomContent()}
				{this.renderContinueButton()}
			</View>
		);
	}

	renderMain() {
		return <View style={styles.fullFlex}>{this.renderContent()}</View>;
	}

	render() {
		if (this.props.rateDoneOpened) {
			return <RateDone />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("screen");
			const availableWidth = windowDim.width;
			const availableHeight = windowDim.height - Math.round(50 - 50 / windowDim.scale);
			if (silverShopDimensions.width > availableWidth || silverShopDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / silverShopDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / silverShopDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	fullFlex: { flex: 1 },
	container: {
		flex: 1,
		marginTop: 10,
		justifyContent: "center",
	},
	topContainer: {
		height: 50,
		marginBottom: 10,
		marginHorizontal: 50,
	},
	bottomContainer: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-evenly",
		minHeight: 200,
		marginHorizontal: 10,
		marginBottom: 10,
	},
	titleText: {
		alignSelf: "center",
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		color: "#FFFFFF",
	},
	subTitle: { fontSize: 16, marginTop: 10 },
	itemBackground: {
		width: 140,
		height: 170,
		justifyContent: "center",
		alignSelf: "center",
	},
	slider: { width: 150, height: 30 },
	toggleButton: {
		width: 45,
		height: 45,
		alignSelf: "center",
	},
	listContainer: {
		alignItems: "center",
		justifyContent: "space-evenly",
	},
	itemContainer: {
		width: 160,
		height: 200,
		alignContent: "center",
		marginHorizontal: 5,
	},
	buttonContainer: { marginRight: 20, alignSelf: "flex-end" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		rateDoneOpened: state.app.rateDoneOpened,
	};
};

const mapDispatchToProps = { openRateDone };

export default connect(mapStateToProps, mapDispatchToProps)(SliderRate);
