import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { AppState, Image, ImageBackground, Platform, StatusBar, StyleSheet, Text, View } from "react-native";
import * as RNLocalize from "react-native-localize";
import { MenuProvider } from "react-native-popup-menu";
import SplashScreen from "react-native-splash-screen";
import { Provider as StoreProvider } from "react-redux";
import { ColoredButton } from "./components/common";
import ErrorHandler, { handleError } from "./components/ErrorHandler";
import Main from "./components/Main/Main";
import { BUILD_VERSION, CONNECT_MSG, defaultClientLanguage } from "./config/connection";
import { APP_STATE, buttonColor, f_sourcesansprobold, START_REASON, storeKeys } from "./config/defaults";
import { DEFAULT_BACK } from "./config/images";
import { DEBUG_ENABLED, LOGGING_ENABLED } from "./config/permissions";
import { BUTTON_PRESS_SOUND, BUY_SUCCEEDED_SOUND, mySounds, SAVE_SOUND } from "./config/sounds";
import { MyWebSocket } from "./connection";
import DebugLogger from "./controller/DebugLogger";
import { getScreenSizeInInch, mapObject } from "./helpers/commonHelpers";
import { setAttribute } from "./libraries//Crashlytics/Crashlytics";
import { getData, storeData } from "./libraries/AsyncStorage";
import DeviceInfo from "./libraries/DeviceInfo";
import { FullScreenAndroid } from "./libraries/FullScreenAndroid/FullScreenAndroid";
import { InstallReferrer } from "./libraries/InstallReferrer/InstallReferrer";
import UUIDGenerator from "./libraries/UUIDGenerator";
import { ACCEPT_TERMS_AND_CONDITIONS, SET_APP_VISIBILITY_STATE } from "./redux/actions/actionTypes";
import { store } from "./redux/store";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appStartTime: Date.now(),
			deviceId: "",
			appId: "",
			appState: null,
			loggable: [],
			selectedLanguage: "",
		};
		// clearAllData();
		// clearItem(storeKeys.APP_LANGUAGE);
		// clearItem(storeKeys.REFERRER_SENT);
		// getAllData();
		// const images = [
		//   BUY_BOOSTERS_BUTTON,
		//   EXIT_BUTTON_BACK,
		//   EXIT_BUTTON_TEXT,
		//   LOSE_BACKGROUND,
		//   RESULTS_TABLE,
		//   SILVER_ICON,
		//   WIN_BACKGROUND,
		// ];
		// if (Platform.OS === "web") this.preloadImages(images);
	}

	async preloadImages(urlOfImages) {
		try {
			// an array of urls of images
			let preFetchTasks = [];
			urlOfImages.forEach((url) => {
				preFetchTasks.push(Image.prefetch(url));
			});

			Promise.all(preFetchTasks).then((results) => {
				try {
					let downloadedAll = true;
					results.forEach((result) => {
						if (!result) {
							//error occurred downloading a pic
							downloadedAll = false;
						}
					});
				} catch (error) {
					handleError(error);
				}
			});
		} catch (error) {
			handleError(error);
		}
	}

	//#region lifecycle methods
	componentDidMount() {
		if (Platform.OS !== "web") SplashScreen.hide();
		try {
			if (Platform.OS === "android" && Platform.Version >= 30) FullScreenAndroid.enable();
		} catch (err) {
			if (DEBUG_ENABLED) console.log("ERROR IN App/FullScreenAndroid: ", err);
		}

		var prefLang = RNLocalize.getLocales();
		prefLang =
			Array.isArray(prefLang) && prefLang.length > 0 && prefLang[0].languageCode
				? prefLang[0].languageCode
				: defaultClientLanguage;
		CONNECT_MSG.clientLanguage = prefLang.toUpperCase();
		//TODO: clear the following line if bug is resolved server-side
		CONNECT_MSG.clientLanguage = defaultClientLanguage.toUpperCase();

		CONNECT_MSG.clientPlatform = Platform.OS === "web" ? "WEBSITE" : "MOBILE";
		CONNECT_MSG.screenSizeInInch = getScreenSizeInInch();

		if (Platform.OS === "web") {
			//#region ONLY WEB
			this._handleTabStateChange();
			document.addEventListener("visibilitychange", this._handleTabStateChange);
			window.addEventListener("focus blur", this._handleTabStateChange);

			getData(storeKeys.REFERRER_SENT).then((data) => {
				if (!data) {
					//set the CONNECT_MSG.referrer field
					if (typeof document.referrer === "string" && document.referrer != "") {
						CONNECT_MSG.referrer = document.referrer;
					}
				}
			});

			getData(storeKeys.APP_TERMS_ACCEPTED).then((termsAccepted) => {
				if (termsAccepted == "true") {
					if (store) store.dispatch({ type: ACCEPT_TERMS_AND_CONDITIONS });
				}
			});
			//#endregion
		} else {
			//#region ONLY MOBILE
			CONNECT_MSG.clientState = APP_STATE.STARTED;
			if (CONNECT_MSG.resumeReason) delete CONNECT_MSG.resumeReason;

			getData(storeKeys.REFERRER_SENT).then((data) => {
				if (!data) {
					InstallReferrer.getInstallReferrerInfo((installReferrerInfo, error) => {
						if (!error) {
							//set the CONNECT_MSG.referrer field
							if (installReferrerInfo) CONNECT_MSG.referrer = installReferrerInfo;
						} else {
							if (DEBUG_ENABLED) {
								console.log("Failed to get install referrer info!");
								console.log("Response code: " + error.responseCode);
								console.log("Message: " + error.message);
							}
						}
					});
				}
			});

			getData(storeKeys.APP_STATE).then((data) => {
				if (data === null) {
					storeData(storeKeys.APP_VERSION, BUILD_VERSION.toString());
					CONNECT_MSG.startReason = START_REASON.INSTALLED;
					CONNECT_MSG.recoverFromCrash = false;
				} else {
					getData(storeKeys.APP_VERSION).then((versionData) => {
						if (versionData !== BUILD_VERSION.toString()) {
							CONNECT_MSG.startReason = START_REASON.UPDATED;
							CONNECT_MSG.recoverFromCrash = false;
							storeData(storeKeys.APP_VERSION, BUILD_VERSION.toString());
						} else {
							CONNECT_MSG.startReason = START_REASON.NORMAL;
							CONNECT_MSG.recoverFromCrash = false;
							if (data === APP_STATE.OPENED) {
								CONNECT_MSG.startReason = START_REASON.RECOVERED_FROM_CRASH;
								CONNECT_MSG.recoverFromCrash = true;
							} else if (data === APP_STATE.MINIMIZED) {
								if (this.state.appState === null) {
									CONNECT_MSG.startReason = START_REASON.RECOVERED_FROM_CRASH;
									CONNECT_MSG.recoverFromCrash = true;
								} else {
									if (this.state.appState !== "active") {
										if (CONNECT_MSG.startReason) delete CONNECT_MSG.startReason;
										CONNECT_MSG.clientState = APP_STATE.RESUMED;
										CONNECT_MSG.resumeReason = APP_STATE.MAXIMIZED;
										CONNECT_MSG.recoverFromCrash = false;
									}
								}
							}
						}
						getData(storeKeys.APP_TERMS_ACCEPTED).then((termsAccepted) => {
							if (termsAccepted == "true") {
								if (store) store.dispatch({ type: ACCEPT_TERMS_AND_CONDITIONS });
							}
						});
					});
				}

				storeData(storeKeys.APP_STATE, APP_STATE.OPENED);
			});

			this.appStateSubscription = AppState.addEventListener("change", this._handleAppStateChange.bind(this));

			CONNECT_MSG.deviceDetails = {
				OS: Platform.OS,
				OS_Version: Platform.Version,
				systemName: DeviceInfo.getSystemName(),
				systemVersion: DeviceInfo.getSystemVersion(),
				deviceBrand: DeviceInfo.getBrand(),
				deviceModel: DeviceInfo.getModel(),
			};
			DeviceInfo.getUserAgent()
				.then((ua) => (CONNECT_MSG.deviceDetails.userAgent = ua))
				.catch((error) => {
					handleError(error);
				});
			if (Platform.OS === "android") {
				DeviceInfo.getBaseOs()
					.then((baseOS) => {
						CONNECT_MSG.deviceDetails.androidBaseOS = baseOS;
					})
					.catch((error) => {
						handleError(error);
					});
				DeviceInfo.getAndroidId()
					.then((androidId) => {
						CONNECT_MSG.deviceDetails.androidId = androidId;
					})
					.catch((error) => {
						handleError(error);
					});
			}

			var devId = DeviceInfo.getUniqueId();
			CONNECT_MSG.deviceIdentifier = devId;
			// save deviceIdentifier to crashlytics
			setAttribute("deviceIdentifier", devId.toString());
			getData(storeKeys.DEVICE_ID).then((data) => {
				if (data === null) storeData(storeKeys.DEVICE_ID, devId);
			});

			getData(storeKeys.UUID)
				.then((data) => {
					if (data === null || data === "") {
						UUIDGenerator.getRandomUUID()
							.then((uuid) => {
								storeData(storeKeys.UUID, uuid);
								CONNECT_MSG.appIdentifier = uuid;
								this.setState({
									deviceId: devId,
									appId: CONNECT_MSG.appIdentifier,
								});
							})
							.catch((error) => {
								handleError(new Error("Failed to generate the appIdentifier"));
							});
					} else {
						CONNECT_MSG.appIdentifier = data;
						this.setState({
							deviceId: devId,
							appId: CONNECT_MSG.appIdentifier,
						});
					}
				})
				.catch((error) => {
					handleError(new Error("Failed to read the appIdentifier from AsyncStorage"));
				});
			//#endregion
		}

		getData(storeKeys.APP_LANGUAGE)
			.then((data) => {
				if (data !== null && data !== "") {
					CONNECT_MSG.clientLanguage = data.toUpperCase();
					this.setState({ selectedLanguage: data });
				}
			})
			.catch((error) => {
				handleError(new Error("Failed to read the selected client language from AsyncStorage"));
			});
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			Platform.OS !== "web" &&
			prevState.appState !== null &&
			prevState.appState !== this.state.appState &&
			this.state.appState === "active" &&
			(MyWebSocket.shared.ws === null || MyWebSocket.shared.ws.readyState !== 1) &&
			DebugLogger.shared.sessionID !== -1
		) {
			if (CONNECT_MSG.startReason) delete CONNECT_MSG.startReason;
			CONNECT_MSG.clientState = APP_STATE.RESUMED;
			CONNECT_MSG.resumeReason = APP_STATE.MAXIMIZED;
			CONNECT_MSG.recoverFromCrash = false;

			if (MyWebSocket.shared.connectToNextURL !== null) {
				// MyWebSocket.shared.connectToNextURL();
			}
		}
	}

	componentWillUnmount() {
		this.setState({ loggable: [] });
		if (Platform.OS !== "web") {
			storeData(storeKeys.APP_STATE, APP_STATE.CLOSED);
			this.appStateSubscription?.remove();
		} else {
			document.removeEventListener("visibilitychange", this._handleTabStateChange);
			window.removeEventListener("focus blur", this._handleTabStateChange);
		}
	}
	//#endregion

	//#region events
	selectLanguage(newLang) {
		CONNECT_MSG.clientLanguage = newLang.toUpperCase();
		storeData(storeKeys.APP_LANGUAGE, newLang.toUpperCase()).then(() => {
			this.setState({ selectedLanguage: newLang });
		});
	}
	//#endregion

	//#region render methods
	renderLanguageButton(lang) {
		var onPressFunc = () => {
				this.selectLanguage(lang);
			},
			textContent = "";
		switch (lang) {
			case "EN":
				textContent = "English";
				break;
			case "NL":
				textContent = "Dutch";
				break;
			default:
				return null;
		}
		return (
			<ColoredButton
				width={200}
				height={60}
				onPressFunc={onPressFunc}
				accessibilityLabel={textContent}
				textContent={textContent}
				color={buttonColor.GREY}
				disabled={false}
				additionalTextStyle={{ paddingTop: 13 }}
				isInMenu={true}
				silentPress={true}
			/>
		);
	}

	renderLanguageSelector() {
		return (
			<ImageBackground source={DEFAULT_BACK} style={{ flex: 1 }}>
				<View style={styles.container}>
					<View style={styles.titleContainer}>
						<Text allowFontScaling={false} accessibilityLabel={"Please select language"} style={styles.titleStyle}>
							{"Please select language"}
						</Text>
					</View>
					<View style={styles.buttonContainer}>
						{this.renderLanguageButton("NL")}
						{this.renderLanguageButton("EN")}
					</View>
				</View>
			</ImageBackground>
		);
	}

	renderMain() {
		return <Main appStartTime={this.state.appStartTime} loggableMessages={this.state.loggable} />;
	}

	renderContent() {
		if (this.state.selectedLanguage === "") return this.renderLanguageSelector();
		return this.renderMain();
	}

	render() {
		StatusBar.setHidden(true);
		return (
			<ErrorHandler>
				<StoreProvider store={store}>
					<MenuProvider>{this.renderContent()}</MenuProvider>
				</StoreProvider>
			</ErrorHandler>
		);
	}
	//#endregion

	//#region helpers
	_handleTabStateChange = () => {
		if (Platform.OS === "web" && store) {
			store.dispatch({
				type: SET_APP_VISIBILITY_STATE,
				payload: document.hidden,
			});
			if (store.getState().tab.isGameStarted) {
				if (document.hidden) {
					DebugLogger.shared.saveLog({ INACTIVE_message: "Browser tab is inactive" }, true);
				} else {
					DebugLogger.shared.saveLog({ ACTIVE_message: "Browser tab is active" }, true);
				}
			}
		}
	};

	_shouldntPlaySounds = async () => {
		try {
			mapObject(mySounds, async (key, val) => {
				await val.shouldntPlay();
			});
			mapObject(BUY_SUCCEEDED_SOUND, async (key, val) => {
				await val.shouldntPlay();
			});
			await BUTTON_PRESS_SOUND.shouldntPlay();
			await SAVE_SOUND.shouldntPlay();
		} catch (err) {
			if (DEBUG_ENABLED) console.log("ERROR in _shouldntPlaySounds(): ", err);
		}
	};

	_unloadSounds = async () => {
		try {
			mapObject(mySounds, async (key, val) => {
				await val.unload();
			});
			mapObject(BUY_SUCCEEDED_SOUND, async (key, val) => {
				await val.unload();
			});
			await BUTTON_PRESS_SOUND.unload();
			await SAVE_SOUND.unload();
		} catch (err) {
			if (DEBUG_ENABLED) console.log("ERROR UNLOADING SOUNDS: ", err);
		}
	};

	_reloadSounds = () => {
		try {
			mapObject(mySounds, (key, val) => {
				val.reload();
			});
			mapObject(BUY_SUCCEEDED_SOUND, (key, val) => {
				val.reload();
			});
			BUTTON_PRESS_SOUND.reload();
			SAVE_SOUND.reload();
		} catch (err) {
			if (DEBUG_ENABLED) console.log("ERROR RELOADING SOUNDS: ", err);
		}
	};

	_handleAppStateChange(nextAppState) {
		if (this.state.appState !== null && this.state.appState != "unknown") {
			if (
				this.state.appState !== nextAppState &&
				this.state.appState.match(/inactive|background/) &&
				nextAppState === "active"
			) {
				// this._reloadSounds();
				if (DEBUG_ENABLED) {
					//logging data
					console.log("App has come to the foreground!");
					if (LOGGING_ENABLED) {
						var _loggable = cloneDeep(this.state.loggable);
						_loggable.push({
							title: "AppStateChange:",
							content: "App has come to the foreground!",
						});
						this.setState({ loggable: _loggable });
					}
				}
				storeData(storeKeys.APP_STATE, APP_STATE.MAXIMIZED);
				//TODO: put this back when server will be ready
				// MyWebSocket.shared.sendMsg({ type: "appMaximized", sMessageID: 0 });
				if (store) {
					store.dispatch({
						type: SET_APP_VISIBILITY_STATE,
						payload: false,
					});
					if (store.getState().tab.isGameStarted) {
						DebugLogger.shared.saveLog({ ACTIVE_message: "App has come to the foreground!" }, true);
					}
				}
			} else {
				// this._unloadSounds();
				this._shouldntPlaySounds();
				if (DEBUG_ENABLED) {
					//logging data
					console.log("App has gone to the background!");
					if (LOGGING_ENABLED) {
						var _loggable = cloneDeep(this.state.loggable);
						_loggable.push({
							title: "AppStateChange:",
							content: "App has gone to the background!",
						});
						this.setState({ loggable: _loggable });
					}
				}
				storeData(storeKeys.APP_STATE, APP_STATE.MINIMIZED);
				//TODO: put this back when server will be ready
				// MyWebSocket.shared.sendMsg({ type: "appMinimized", sMessageID: 0 });
				if (store) {
					store.dispatch({
						type: SET_APP_VISIBILITY_STATE,
						payload: true,
					});
					if (store.getState().tab.isGameStarted) {
						DebugLogger.shared.saveLog({ INACTIVE_message: "App has gone to the background!" }, true);
					}
				}
			}
		}
		this.setState({ appState: nextAppState });
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { flex: 1, height: "100%", justifyContent: "center" },
	titleContainer: { width: "100%", height: 50 },
	titleStyle: {
		width: "100%",
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 30,
		textAlign: "center",
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
	},
	buttonContainer: {
		alignItems: "center",
		marginVertical: 20,
	},
});

export default App;
