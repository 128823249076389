import { Platform } from "react-native";
import { defaultCardsetID, gameChatMessageLimit, maxExistingUserRating } from "../../config/defaults";
import * as helpers from "../../helpers/commonHelpers";
import { gameBoardTheme, invertedColor } from "../../Keezers/config/defaults";
import {
	GAME_STARTED_RECEIVED,
	SET_PLAYER_PANELS_COLOR,
	UPDATE_MESSAGES_COLORS,
} from "../../Keezers/redux/actions/actionTypes";
import {
	CHANGE_GAME_NAME,
	CLEAR_SLOT_SELECTION,
	DISABLE_CHANGE_SLOT,
	GAME_JOINED_RECEIVED,
	GAME_NAME_UPDATED,
	GAME_PLAYER_JOINED,
	GAME_PLAYER_LEFT,
	GAME_PUBLISHED_RECEIVED,
	INIT_GAME_ROOMS_PLAYERS,
	MOUSE_ENTER_GAME_ROOMS_SLOT,
	MOUSE_LEAVE_GAME_ROOMS_SLOT,
	OPEN_GAME_ROOM,
	PREPARE_GAME_ROOMS_RULES_LIST,
	RECEIVE_GAME_CHAT_MESSAGE,
	RESET_CLIENT,
	RESET_GAME_ROOM,
	SELECT_GAME_ROOMS_SLOT,
	UPDATE_GAME_ROOMS_SLOT,
} from "../actions/actionTypes";

const initialState = {
	loadingGameRoom: true,
	grIsGameCreator: false,
	grSelectedSlotID: -1,
	gameRoomsGameId: -1,
	gameName: "",
	canStartGame: false,
	canChangeSlot: false,
	gameRoomsRulesList: [],
	gameRoomPlayers: [],
	gameChatMessages: [],
	gameChatName: "",
	msgGamePlayerLeave: {
		type: "leaveGame",
		sMessageID: 0,
	},
	minLevel: 0,
	maxLevel: maxExistingUserRating,
	playerCnt: 2,
	teams: false,
	fourRule: true,
	sevenRule: false,
	jackRule: true,
	onlyLocalCountry: false,
	onlyLocalLanguage: false,
	onlyFriends: false,
	chipsRequired: 0,
	cardset: defaultCardsetID,
	colors: null,
	enableFairDealing: true,
	isPrivateGame: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case OPEN_GAME_ROOM:
			const { gameID, gameName, cardset } = payload;
			if (
				typeof payload === "undefined" ||
				typeof gameID === "undefined" ||
				typeof gameName === "undefined" ||
				typeof cardset === "undefined"
			)
				return state;
			return {
				...state,
				gameRoomsGameId: gameID,
				gameName: gameName,
				cardset: cardset,
				msgGamePlayerLeave: { ...state.msgGamePlayerLeave, gameID: gameID },
			};
		case INIT_GAME_ROOMS_PLAYERS:
			if (!Array.isArray(payload.players) || !Array.isArray(payload.slots)) return state;
			const { players, slots } = payload;
			slots.map((obj) => {
				if (obj.type == "COMPUTER") players[obj.slotID].ranking = obj.strength;
				players[obj.slotID].state = obj.state;
				players[obj.slotID].curDate = Date.now();
			});
			// players.find((obj) => typeof obj.state === "undefined").state =
			//   "OCCUPIED";
			var openSlot = slots.findIndex((obj) => obj.state == "OPEN");
			var canChangeSlot =
				!state.grIsGameCreator && state.grSelectedSlotID != -1 && players[state.grSelectedSlotID].state == "OPEN";
			return {
				...state,
				canStartGame: openSlot == -1,
				canChangeSlot: canChangeSlot,
				gameRoomPlayers: players,
			};
		case GAME_PUBLISHED_RECEIVED:
		case GAME_PLAYER_LEFT:
		case GAME_JOINED_RECEIVED:
		case GAME_PLAYER_JOINED:
			if (
				typeof payload === "undefined" ||
				typeof payload.msg === "undefined" ||
				typeof payload.msg.data === "undefined" ||
				payload.msg.data === null ||
				typeof payload.msg.data.generic === "undefined" ||
				payload.msg.data.generic === null ||
				typeof payload.msg.data.specific === "undefined" ||
				payload.msg.data.specific === null ||
				payload.msg.data.generic.gameID != state.gameRoomsGameId
			)
				return state;

			var playersList = helpers.getSelectedGamesPlayersList(
				payload.msg,
				payload.msg,
				payload.lang.openSlot,
				payload.lang.teamPrefix
			);
			playersList = playersList == undefined ? state.gameRoomPlayers : playersList;
			var openSlot = playersList.findIndex((obj) => obj.state == "OPEN");
			state.gameRoomPlayers.forEach((grpl) => {
				if (grpl.userID && grpl.curDate) {
					const find = playersList.find((pl) => pl.userID == grpl.userID);
					if (find) find.curDate = grpl.curDate;
				}
			});
			return {
				...state,
				gameChatName: payload.msg.data.generic.gameChatName || "",
				loadingGameRoom: false,
				grIsGameCreator: payload.isJoined == false,
				gameRoomPlayers: playersList,
				canStartGame: openSlot == -1,
				minLevel:
					typeof payload.msg.data.generic.minRating !== "undefined"
						? payload.msg.data.generic.minRating
						: state.minLevel, //payload.minRating || state.minRating,
				maxLevel:
					typeof payload.msg.data.generic.maxRating !== "undefined"
						? payload.msg.data.generic.maxRating
						: state.maxLevel, //payload.maxRating || state.maxRating,
				playerCnt: payload.msg.data.generic.playerCnt || state.playerCnt,
				teams:
					typeof payload.msg.data.specific.teamsEnabled === "boolean"
						? payload.msg.data.specific.teamsEnabled
						: state.teams,
				fourRule: typeof payload.msg.fourRule === "boolean" ? payload.msg.fourRule : state.fourRule,
				sevenRule: typeof payload.msg.sevenRule === "boolean" ? payload.msg.sevenRule : state.sevenRule,
				jackRule: typeof payload.msg.jackRule === "boolean" ? payload.msg.jackRule : state.jackRule,
				onlyLocalCountry:
					typeof payload.msg.data.generic.localCountryOnly === "boolean"
						? payload.msg.data.generic.localCountryOnly
						: state.onlyLocalCountry,
				onlyLocalLanguage:
					typeof payload.msg.data.generic.localLanguageOnly === "boolean"
						? payload.msg.data.generic.localLanguageOnly
						: state.onlyLocalLanguage,
				onlyFriends:
					typeof payload.msg.data.generic.friendsOnly === "boolean"
						? payload.msg.data.generic.friendsOnly
						: state.onlyFriends,
				chipsRequired:
					typeof payload.msg.data.generic.chipAmount !== "undefined"
						? payload.msg.data.generic.chipAmount
						: state.chipsRequired,
				enableFairDealing:
					typeof payload.msg.data.specific.enableFairDealing === "boolean"
						? payload.msg.data.specific.enableFairDealing
						: state.enableFairDealing,
				isPrivateGame:
					typeof payload.msg.data.generic.isPrivateGame === "boolean"
						? payload.msg.data.generic.isPrivateGame
						: state.isPrivateGame,
			};
		/* case GAME_JOINED_RECEIVED:
      if (
        typeof payload === "undefined" ||
        typeof payload.msgGJ === "undefined"
      )
        return state;
      var playersList = helpers.getSelectedGamesPlayersList(
        payload.msgGJ,
        payload.msgGJ,
        payload.lng.openSlot,
        payload.lng.teamPrefix
      );
      playersList.forEach((obj) => {
        obj.state =
          typeof obj.userID == "string" && obj.userID.startsWith("generated")
            ? "OPEN"
            : "OCCUPIED";
      });
      return {
        ...state,
        loadingGameRoom: false,
        grIsGameCreator: false,
        gameRoomPlayers: playersList,
        minLevel: payload.msgGJ.data.generic.minRating || state.minLevel, //game.minRating || state.minRating,
        maxLevel: payload.msgGJ.data.generic.maxRating || state.maxLevel, //game.maxRating || state.maxRating,
        playerCnt: payload.msgGJ.data.generic.playerCnt || state.playerCnt,
        teams: payload.msgGJ.data.specific.teamsEnabled || state.teams,
        fourRule: payload.msgGJ.fourRule || state.fourRule,
        sevenRule: payload.msgGJ.sevenRule || state.sevenRule,
        jackRule: payload.msgGJ.jackRule || state.jackRule,
        onlyLocalCountry:
          payload.msgGJ.data.generic.localCountryOnly || state.onlyLocalCountry,
        onlyLocalLanguage:
          payload.msgGJ.data.generic.localLanguageOnly ||
          state.onlyLocalLanguage,
        onlyFriends:
          payload.msgGJ.data.generic.friendsOnly || state.onlyFriends,
        chipsRequired:
          payload.msgGJ.data.generic.chipAmount || state.chipsRequired,
        enableFairDealing:
          payload.msgGJ.data.specific.enableFairDealing ||
          state.enableFairDealing,
      }; */
		case PREPARE_GAME_ROOMS_RULES_LIST:
			if (typeof payload === "undefined") return state;
			const { cardsets } = payload.lang;
			var fCardset = helpers.mapObject(cardsets, (key, value) => {
				if (key == state.cardset) return value;
			});
			fCardset = fCardset.find((obj) => obj !== undefined);
			var cardsetContent = "";
			if (typeof fCardset !== "undefined") {
				cardsetContent = fCardset.name;
			} else {
				var defCardset = helpers.mapObject(cardsets, (key, value) => {
					if (value.cardsetID == defaultCardsetID) return value;
				});
				if (typeof defCardset !== "undefined") cardsetContent = defCardset.name;
			}
			if (
				payload.msg &&
				payload.msg.data &&
				payload.msg.data.specific &&
				payload.msg.data.specific.cardset &&
				payload.msg.data.specific.cardset != null
			) {
				cardsetContent = payload.msg.data.specific.cardset;
			}
			var gameThemeContent = "";
			if (
				payload.msg &&
				payload.msg.data &&
				payload.msg.data.specific &&
				payload.msg.data.specific.gameTheme &&
				payload.msg.data.specific.gameTheme != null
			) {
				gameThemeContent = payload.msg.data.specific.gameTheme;
			}
			var rulesList = [
				{
					ruleID: 1,
					ruleName: "onlyFriends",
					displayHelpText: false,
					label: payload.lang.gameOptions.onlyFriends,
					content: state.onlyFriends,
					helpText: payload.lang.miniHelp.onlyFriends,
				},
				{
					ruleID: 2,
					ruleName: "localLanguagesOnly",
					displayHelpText: false,
					label: payload.lang.gameOptions.localLanguagesOnly,
					content: state.onlyLocalLanguage,
					helpText: payload.lang.miniHelp.localLanguagesOnly,
				},
				{
					ruleID: 3,
					ruleName: "localCountryOnly",
					displayHelpText: false,
					label: payload.lang.gameOptions.localCountryOnly,
					content: state.onlyLocalCountry,
					helpText: payload.lang.miniHelp.localCountryOnly,
				},
				{
					ruleID: 4,
					ruleName: "chipsRequired",
					displayHelpText: false,
					label: payload.lang.gameOptions.chipsRequired,
					content: state.chipsRequired.toString(),
					helpText: payload.lang.miniHelp.chipsRequired,
				},
				{
					ruleID: 5,
					ruleName: "privateGame",
					displayHelpText: false,
					label: payload.lang.gameOptions.privateGame,
					content: state.isPrivateGame,
					helpText: payload.lang.miniHelp.privateGame,
				},
				{
					ruleID: 6,
					ruleName: "enableTeams",
					displayHelpText: false,
					label: payload.lang.gameOptions.enableTeams,
					content: state.teams,
					helpText: payload.lang.miniHelp.enableTeams,
				},
				{
					ruleID: 7,
					ruleName: "playerLevel",
					displayHelpText: false,
					label: payload.lang.gameOptions.playerLevel,
					helpText: payload.lang.miniHelp.playerLevel,
					content:
						// payload.lang.min +
						// ": " +
						state.minLevel +
						" - " +
						// payload.lang.max +
						// ": " +
						state.maxLevel,
					minLang: payload.lang.min,
					maxLang: payload.lang.max,
					selectedMinValue: state.minLevel,
					selectedMaxValue: state.maxLevel,
				},
				{
					ruleID: 8,
					ruleName: "cardset",
					displayHelpText: false,
					label: payload.lang.gameOptions.cardset,
					content: cardsetContent,
					helpText: payload.lang.miniHelp.cardset,
				},
				{
					ruleID: 9,
					ruleName: "fairDeal",
					displayHelpText: false,
					label: payload.lang.gameOptions.fairDeal,
					content: state.enableFairDealing,
					helpText: payload.lang.miniHelp.fairDeal,
				},
				{
					ruleID: 10,
					ruleName: "gameTheme",
					displayHelpText: false,
					label: payload.lang.gameOptions.gameTheme,
					content: gameThemeContent,
					helpText: payload.lang.miniHelp.gameTheme,
				},
				{
					ruleID: 11,
					ruleName: "gameName",
					displayHelpText: false,
					label: payload.lang.gameOptions.gameName,
					content: state.gameName,
					helpText: payload.lang.miniHelp.gameName,
				},
			];
			return { ...state, gameRoomsRulesList: rulesList };
		case CHANGE_GAME_NAME:
			return { ...state, gameName: payload || state.gameName };
		case GAME_NAME_UPDATED:
			if (
				typeof payload === "undefined" ||
				typeof payload.gameID === "undefined" ||
				typeof payload.name !== "string" ||
				typeof payload.cMessageID !== "number"
			)
				return state;
			if (payload.cMessageID != 0 || payload.gameID != state.gameRoomsGameId) return state;
			if (state.gameName == payload.name) return state;
			return { ...state, gameName: payload.name };
		case SELECT_GAME_ROOMS_SLOT:
			var newSelSlotID = initialState.grSelectedSlotID;
			var playerList = [...state.gameRoomPlayers];
			playerList.map((obj) => (obj.isSelected = false));
			var selectedPlayer = playerList.find((obj) => obj.slotID == payload);
			selectedPlayer.isSelected = true;
			newSelSlotID = selectedPlayer.slotID;
			var canChangeSlot = !state.grIsGameCreator && newSelSlotID != -1 && playerList[newSelSlotID].state == "OPEN";
			return {
				...state,
				canChangeSlot: canChangeSlot,
				grSelectedSlotID: newSelSlotID,
				gameRoomPlayers: playerList,
			};
		case MOUSE_ENTER_GAME_ROOMS_SLOT:
			var playerList = [...state.gameRoomPlayers];
			playerList.map((obj) => (obj.isHovered = false));
			var hoveredSlot = playerList.find((obj) => obj.slotID == payload);
			hoveredSlot.isHovered = true;
			return { ...state, gameRoomPlayers: playerList };
		case MOUSE_LEAVE_GAME_ROOMS_SLOT:
			var playerList = [...state.gameRoomPlayers];
			var hoveredGame = playerList.find((obj) => obj.slotID == payload);
			hoveredGame.isHovered = false;
			return { ...state, gameRoomPlayers: playerList };
		case UPDATE_GAME_ROOMS_SLOT:
			const { msg, lang } = payload;
			if (
				typeof msg === "undefined" ||
				typeof msg.gameID === "undefined" ||
				msg.gameID != state.gameRoomsGameId ||
				typeof msg.isComputerPlayer !== "boolean"
			)
				return state;
			var newPlayers = [...state.gameRoomPlayers];
			var selSlot = newPlayers.find((obj) => obj.slotID == msg.slotID);
			if (selSlot == undefined) return state;

			if (msg.isComputerPlayer) {
				if (state.grIsGameCreator) {
					selSlot.username = lang.computerLabel + " - " + lang[msg.computerPlayer.strength.toLowerCase()];
				} else {
					selSlot.username = msg.computerPlayer.name;
				}
				selSlot.botID = msg.computerPlayer.computerID;
				selSlot.state = msg.state === "OCCUPIED" ? "OCCUPIED" : "OPEN";
				selSlot.strength = msg.computerPlayer.strength;
				selSlot.ranking = msg.computerPlayer.strength;
				selSlot.type = "COMPUTER";
			} else {
				selSlot.username = lang.openSlot;
				selSlot.botID = 0;
				selSlot.state = "OPEN";
				selSlot.strength = "";
				selSlot.ranking = "";
				selSlot.type = "HUMAN";
			}
			var openSlot = newPlayers.findIndex((obj) => obj.state == "OPEN");
			var canChangeSlot =
				!state.grIsGameCreator && state.grSelectedSlotID != -1 && newPlayers[state.grSelectedSlotID].state == "OPEN";
			return {
				...state,
				canStartGame: openSlot == -1,
				canChangeSlot: canChangeSlot,
				gameRoomPlayers: newPlayers,
			};
		/* case GAME_PLAYER_JOINED:
      if (typeof payload === "undefined") return state;
      const { msgGPJ, language } = payload;
      if (
        typeof msgGPJ === "undefined" ||
        typeof language === "undefined" ||
        msgGPJ.gameID != state.gameRoomsGameId
      )
        return state;
      var newPlayers = [...state.gameRoomPlayers];
      if (!msgGPJ.swap) {
        if (msgGPJ.isHuman && msgGPJ.computerPlayer == null) {
          var oldSlot = state.gameRoomPlayers[msgGPJ.slotID];
          newPlayers.splice(msgGPJ.slotID, 1, msgGPJ.humanPlayer);
          newPlayers[msgGPJ.slotID].state = "OCCUPIED";
          newPlayers[msgGPJ.slotID].teamName = oldSlot.teamName;
        }
      } else {
        if (msgGPJ.isHuman && msgGPJ.computerPlayer == null) {
          var oldSlot = state.gameRoomPlayers[msgGPJ.oldSlotID];
          var oldSlotTeamName = oldSlot.teamName;
          var curSlot = state.gameRoomPlayers[msgGPJ.humanPlayer.slotID];
          var curSlotTeamName = curSlot.teamName;
          newPlayers.splice(msgGPJ.oldSlotID, 1, curSlot);
          newPlayers.splice(msgGPJ.humanPlayer.slotID, 1, oldSlot);
          newPlayers[msgGPJ.oldSlotID].slotID = oldSlot.slotID;
          newPlayers[msgGPJ.oldSlotID].teamName = oldSlotTeamName;
          newPlayers[msgGPJ.humanPlayer.slotID].slotID =
            msgGPJ.humanPlayer.slotID;
          newPlayers[msgGPJ.humanPlayer.slotID].teamName = curSlotTeamName;
        }
      }
      var openSlot = newPlayers.findIndex((obj) => obj.state == "OPEN");
      var canChangeSlot =
        !state.grIsGameCreator &&
        state.grSelectedSlotID != -1 &&
        newPlayers[state.grSelectedSlotID].state == "OPEN";
      return {
        ...state,
        canStartGame: openSlot == -1,
        canChangeSlot: canChangeSlot,
        gameRoomPlayers: newPlayers,
      }; */
		case RECEIVE_GAME_CHAT_MESSAGE:
			if (typeof payload === "undefined") return state;
			switch (payload.type) {
				case "systemGameChat":
					payload.name = payload.username || "";
					payload.color = payload.color || "000";
					payload.slotID = -1;
					payload.userID = -1;
					payload.isTranslated = false;
					payload.hadTranslationError = false;
					payload.originalText = payload.text || "";
					payload.translatedText = payload.text || "";
					break;
				case "gameChat":
					var _user = state.gameRoomPlayers.find((u) => u.userID == payload.userID);
					if (payload.startedGamePlayers && payload.startedGamePlayers !== null) {
						helpers.mapObject(payload.startedGamePlayers, (k, val) => {
							if (val.userID == payload.userID) _user = val;
						});
					}
					payload.name = _user != undefined ? _user.username : "unknown";
					payload.slotID = _user != undefined ? _user.slotID : -1;
					payload.color = "000";
					if (state.colors !== null) {
						const slot = state.colors[payload.slotID];
						if (slot && slot.normal != undefined) payload.color = slot.normal;
						if (slot && slot.chat != undefined) payload.color = slot.chat;
						if (
							Platform.OS !== "web" &&
							payload.gameTheme === gameBoardTheme.MODERN &&
							slot &&
							slot.normal != undefined &&
							invertedColor["_" + slot.normal] != undefined
						)
							payload.color = invertedColor["_" + slot.normal];
					}
					break;
				case "spectatorGameChat":
					payload.name = payload.spectatorName || "";
					// payload.color = "a9a9a9";
					payload.color = "999";
					payload.slotID = -1;
					payload.isSpectatorMessage = true;
					break;
			}
			const newGameChatMessages = [...state.gameChatMessages];
			newGameChatMessages.push(payload);
			if (newGameChatMessages.length > gameChatMessageLimit) newGameChatMessages.shift();
			return { ...state, gameChatMessages: newGameChatMessages };
		case UPDATE_MESSAGES_COLORS:
			if (
				typeof payload == undefined ||
				typeof payload.players === undefined ||
				payload.players == null ||
				!Array.isArray(payload.panels) ||
				state.gameChatMessages.length <= 0
			)
				return state;

			var panels = payload.panels;
			var _players = payload.players;
			var newMessages = [...state.gameChatMessages];
			newMessages.forEach((message) => {
				var slotID;
				helpers.mapObject(_players, (k, v) => {
					if (v.userID == message.userID) {
						slotID = v.slotID;
					}
				});
				if (typeof slotID !== "undefined") {
					const slot = panels.find((pp) => pp.slotID == slotID);
					if (slot && slot.color) {
						message.color = "000";
						if (slot.color.normal != undefined) message.color = slot.color.normal;
						if (slot.color.chat != undefined) message.color = slot.color.chat;
						if (
							Platform.OS !== "web" &&
							payload.gameTheme === gameBoardTheme.MODERN &&
							slot.color.normal != undefined &&
							invertedColor["_" + slot.color.normal] != undefined
						)
							message.color = invertedColor["_" + slot.color.normal];
					}
				}
			});

			return { ...state, gameChatMessages: newMessages };
		case SET_PLAYER_PANELS_COLOR:
			if (typeof payload === "undefined") return state;
			var newMessages = [...state.gameChatMessages];
			newMessages.forEach((message) => {
				if (message.slotID != -1) {
					const slot = payload[message.slotID.toString()];
					message.color = "000";
					if (slot && slot.normal != undefined) message.color = slot.normal;
					if (slot && slot.chat != undefined) message.color = slot.chat;
				}
			});
			return {
				...state,
				gameChatMessages: newMessages,
				colors: payload,
			};
		case DISABLE_CHANGE_SLOT:
			return { ...state, canChangeSlot: false };
		case CLEAR_SLOT_SELECTION:
			return { ...state, grSelectedSlotID: initialState.grSelectedSlotID };
		case GAME_STARTED_RECEIVED:
			if (payload && payload.reason === "RESET") return state;
			return { ...state, gameChatMessages: [] };
		case RESET_GAME_ROOM:
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
