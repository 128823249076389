import React, { Component } from "react";
import { ImageBackground, Platform, SafeAreaView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { RESOLUTION } from "../../config/defaults";
import {
	closeComposeMessage,
	closeGameMessage,
	closeMessageDetails,
	goBackToMain,
	subscribeToGameList,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { NavigationHeader } from "../common";
import GameShopStack from "../GameShop/GameShopStack";
import ComposeMessage from "./ComposeMessage";
import MessageDetails from "./MessageDetails";
import MessageInbox from "./MessageInbox";

class GameMessage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			safeAreaWidth: 100,
		};
	}

	componentDidMount() {
		Analytics.logScreenView("GameMessages");
	}

	//#region events
	onBackPress() {
		if (this.props.isComposeOpened) {
			this.props.closeComposeMessage();
			if (this.props.isReadMessageOpened) this.props.closeMessageDetails();
		} else if (this.props.isReadMessageOpened) {
			this.props.closeMessageDetails();
		} else {
			this.props.closeGameMessage();
			this.props.subscribeToGameList();
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			>
				{!this.props.isComposeOpened && !this.props.isReadMessageOpened && <GameShopStack />}
			</NavigationHeader>
		);
	}

	renderContent() {
		if (this.props.isComposeOpened) {
			return <ComposeMessage safeAreaWidth={this.state.safeAreaWidth} />;
		} else if (this.props.isReadMessageOpened) {
			return <MessageDetails />;
		} else {
			return <MessageInbox />;
		}
	}

	render() {
		if (this.props.isPlayerOverviewOpened || this.props.isShopOpen) return null;
		return (
			<ImageBackground source={this.props.MENU_PLAY_BACK.url} style={styles.fullFlex}>
				{Platform.OS !== "android" && this.props.isComposeOpened && this.renderHeader()}
				<SafeAreaView
					onLayout={(ev) => {
						this.setState({ safeAreaWidth: ev.nativeEvent.layout.width });
					}}
					style={{ flex: 1 }}
				>
					<View
						style={{
							flex: 1,
							marginHorizontal: this.props.resolution === RESOLUTION.HIGH ? 65 : 45,
						}}
					>
						{this.renderContent()}
					</View>
				</SafeAreaView>
				{Platform.OS !== "android" && !this.props.isComposeOpened && this.renderHeader()}
				{Platform.OS === "android" && this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	fullFlex: { flex: 1 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		isComposeOpened: state.gameMessage.isComposeOpened,
		isReadMessageOpened: state.gameMessage.isReadMessageOpened,
		isShopOpen: state.tab.isShopOpen,
		isPlayerOverviewOpened: state.app.isPlayerOverviewOpened,
		MENU_PLAY_BACK: state.images.MENU_PLAY_BACK,
	};
};

const mapDispatchToProps = {
	closeComposeMessage,
	closeGameMessage,
	closeMessageDetails,
	goBackToMain,
	subscribeToGameList,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameMessage);
