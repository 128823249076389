import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { Dimensions, Image, ImageBackground, Platform, StyleSheet, Text, View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprosemibold, tabType } from "../../../../../../config/defaults";
import { fixBoardColorsDimensions, gameDimensions } from "../../../../../../config/dimensions";
import { TOGGLE_ICON } from "../../../../../../config/images";
import CheckBox from "../../../../../../libraries/CheckBox";
import { closeFixBoardColors, goBackToMain, openTab, openVIPShop, startPlay } from "../../../../../../redux/actions";
import Analytics from "../../../../../Analytics/Analytics";
import { AppTouchableOpacity, ColoredButton, NavigationHeader } from "../../../../../common";
import { handleError } from "../../../../../ErrorHandler";
import VIPShop from "../../../../../GameShop/VIPShop";

class FixBoardColors extends Component {
	constructor(props) {
		super(props);
		//TODO: do this with redux
		this.state = {
			contentScale: this.getContentScale(),
			isFixColorsChecked: false,
			positions: [
				{ playerCnt: 2, selColorIndex: 7 },
				{ playerCnt: 3, selColorIndex: 1 },
				{ playerCnt: 4, selColorIndex: 2 },
				{ playerCnt: 5, selColorIndex: 3 },
				{ playerCnt: 6, selColorIndex: 4 },
				{ playerCnt: 7, selColorIndex: 5 },
				{ playerCnt: 8, selColorIndex: 6 },
			],
		};

		this.colors = [
			{
				colorID: 0,
				colorName: this.props.lang.colors.black,
				colorCode: "black",
			},
			{
				colorID: 1,
				colorName: this.props.lang.colors.blue,
				colorCode: "blue",
			},
			{
				colorID: 2,
				colorName: this.props.lang.colors.brown,
				colorCode: "brown",
			},
			{
				colorID: 3,
				colorName: this.props.lang.colors.green,
				colorCode: "green",
			},
			{
				colorID: 4,
				colorName: this.props.lang.colors.pink,
				colorCode: "pink",
			},
			{
				colorID: 5,
				colorName: this.props.lang.colors.purple,
				colorCode: "purple",
			},
			{
				colorID: 6,
				colorName: this.props.lang.colors.red,
				colorCode: "red",
			},
			{
				colorID: 7,
				colorName: this.props.lang.colors.yellow,
				colorCode: "yellow",
			},
		];
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("BoardColorsPreferences");
	}
	//#endregion

	//#region events
	onUpdatePress() {
		this.props.closeFixBoardColors();
	}

	onLinkPress() {
		this.props.goBackToMain();
		this.props.startPlay();
		this.props.openTab(tabType.SHOP);
		this.props.openVIPShop();
	}

	toggleColors(index, prev = false) {
		try {
			if (index >= 0) {
				var newPositions = cloneDeep(this.state.positions);
				var board = newPositions[index];
				if (prev) {
					board.selColorIndex--;
					if (board.selColorIndex < 0) board.selColorIndex = this.colors.length - 1;
				} else {
					board.selColorIndex++;
					if (board.selColorIndex == this.colors.length) board.selColorIndex = 0;
				}
				this.setState({ positions: newPositions });
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeFixBoardColors}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={!this.props.isVip}
				/>
			</View>
		);
	}

	renderInfo() {
		try {
			const regex = /_/gi;
			var main_text = this.props.lang.fixBoardColors.info;
			var main_text_i = main_text.indexOf("_");
			var main_text_j = main_text.lastIndexOf("_");
			if (main_text_i != -1 && main_text_j != -1) var linkMainText = main_text.substring(main_text_i, ++main_text_j);
			if (linkMainText != undefined) var mainTextLink = linkMainText.replace(regex, "");
			return (
				<View style={styles.columnContainer}>
					<View style={[styles.dataInnerContainer, { flexDirection: "row" }]}>
						<Text allowFontScaling={false} style={[styles.dataTitle, styles.dataText]}>
							{this.props.lang.fixBoardColors.checkBoxTitle}
						</Text>
						<CheckBox
							disabled={!this.props.isVip}
							value={this.state.isFixColorsChecked}
							onValueChange={() =>
								this.setState({
									isFixColorsChecked: !this.state.isFixColorsChecked,
								})
							}
							style={[
								{ alignSelf: "flex-end", marginRight: 20 },
								Platform.OS === "web"
									? styles.checkBox
									: {
											transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }],
									  },
							]}
						/>
					</View>
					<View style={styles.detailsContainer}>
						{mainTextLink != "" && (
							<ParsedText
								style={[styles.dataText, styles.infoText]}
								parse={[
									{
										pattern: new RegExp("_" + mainTextLink + "_"),
										style: [styles.dataText, styles.infoText, styles.linkDecor],
										onPress: this.onLinkPress.bind(this),
										renderText: () => mainTextLink,
									},
								]}
							>
								{main_text}
							</ParsedText>
						)}
						{mainTextLink == "" && (
							<Text allowFontScaling={false} style={[styles.dataText, styles.infoText]}>
								{main_text}
							</Text>
						)}
					</View>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderRow(item, index) {
		const selectedColor = this.colors[item.selColorIndex];
		return (
			<View style={styles.rowContainer} key={"playerCnt_" + item.playerCnt}>
				<Text allowFontScaling={false} style={styles.playerCntText}>
					{item.playerCnt}
				</Text>
				<AppTouchableOpacity style={styles.toggleButton} onPress={() => this.toggleColors(index, true)}>
					<Image source={TOGGLE_ICON} style={styles.toggleIcon} resizeMode="stretch" />
				</AppTouchableOpacity>
				<Text allowFontScaling={false} style={[styles.selectedColorText, { color: selectedColor.colorCode }]}>
					{selectedColor.colorName}
				</Text>
				<AppTouchableOpacity style={styles.toggleButton} onPress={() => this.toggleColors(index, false)}>
					<Image
						source={TOGGLE_ICON}
						style={[styles.toggleIcon, { transform: [{ rotateY: "-180deg" }] }]}
						resizeMode="stretch"
					/>
				</AppTouchableOpacity>
			</View>
		);
	}

	renderLeftColumn() {
		var rows = [];

		for (let i = 0; i < 4; i++) {
			rows.push(this.renderRow(this.state.positions[i], i));
		}

		return rows;
	}

	renderRightColumn() {
		var rows = [];

		for (let i = 4; i < this.state.positions.length; i++) {
			rows.push(this.renderRow(this.state.positions[i], i));
		}

		return rows;
	}

	renderDetails() {
		return (
			<View style={[styles.columnContainer, { paddingLeft: 40 }]}>
				<View style={styles.dataInnerContainer}>
					<Text allowFontScaling={false} style={[styles.dataTitle, styles.dataText]}>
						{this.props.lang.fixBoardColors.detailsTitle}
					</Text>
				</View>
				<View style={{ flex: 1, flexDirection: "row" }}>
					<View style={{ flex: 2 }}>{this.renderLeftColumn()}</View>
					<View style={{ flex: 2 }}>{this.renderRightColumn()}</View>
				</View>
			</View>
		);
	}

	renderContent() {
		return (
			<View style={[styles.contentContainer, { transform: [{ scale: this.state.contentScale }] }]}>
				<View style={styles.positionsContainer}>
					{this.renderInfo()}
					{this.renderDetails()}
				</View>
				{this.renderUpdateButton()}
			</View>
		);
	}

	render() {
		if (this.props.vipShopOpened) {
			return <VIPShop />;
		} else {
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<View style={styles.container}>{this.renderContent()}</View>
					{this.renderHeader()}
				</ImageBackground>
			);
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 80;
			if (fixBoardColorsDimensions.width > availableWidth || fixBoardColorsDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / fixBoardColorsDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / fixBoardColorsDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	contentContainer: { flex: 1, paddingHorizontal: 20 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		marginBottom: 15,
		justifyContent: "center",
	},
	dataTitle: {
		flex: 1,
		textAlign: "left",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		paddingTop: 2,
		paddingRight: 10,
		lineHeight: 40,
	},
	dataText: { fontFamily: f_sourcesansprosemibold, fontSize: 20 },
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: 26,
		marginTop: 20,
		marginBottom: 20,
		fontSize: 18,
	},
	linkDecor: { color: "#00CDD8" },
	positionsContainer: { flex: 1, height: 280, flexDirection: "row" },
	columnContainer: { flex: 2, height: 280 },
	detailsContainer: { flex: 1, marginBottom: 5, justifyContent: "center" },
	rowContainer: {
		height: 40,
		flexDirection: "row",
		marginBottom: 10,
	},
	toggleButton: { width: 40, height: 40, justifyContent: "center" },
	toggleIcon: { width: 30, height: 30, alignSelf: "center" },
	selectedColorText: {
		width: 60,
		lineHeight: 40,
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		textAlign: "center",
		alignSelf: "center",
	},
	playerCntText: {
		width: 15,
		lineHeight: 40,
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		textAlign: "center",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	checkBox: { width: 20, height: 20, alignSelf: "center" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isVip: state.currentUser.userDetails.isVip,
		vipShopOpened: state.gameShop.vipShopOpened,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeFixBoardColors,
	goBackToMain,
	openVIPShop,
	openTab,
	startPlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(FixBoardColors);
