import { RESET_CLIENT, TOGGLE_NAV_MENU } from "../actions/actionTypes";

const initialState = {
	isMenuOpen: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case TOGGLE_NAV_MENU:
			return { ...state, isMenuOpen: !state.isMenuOpen };
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
