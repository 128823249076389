import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { gameDimensions } from "../../config/dimensions";
import { mapObject } from "../../helpers/commonHelpers";
import { store } from "../../redux/store";
import { ChatMessages, Spinner } from "../common";

class GameRoomChatMessageBox extends Component {
	constructor(props) {
		super(props);
		var players = [];
		const { startedGame } = store.getState();
		mapObject(startedGame.players, (k, v) => players.push(v));
		this.state = {
			players: players,
		};
	}

	//#region render methods
	renderSpinner() {
		if (this.props.loadingGameRoom) {
			return <Spinner size="large" />;
		}
		return null;
	}

	renderChatBody() {
		if (!this.props.loadingGameRoom) {
			if (typeof this.props.activeRoomID === "undefined")
				return <View style={{ height: this.props.grSlotSettingsHeight - 30 }}></View>;

			var messages = [],
				players = [];
			if (this.props.activeRoomID == -1) {
				messages = this.props.gameChatMessages;
				players = this.state.players;
			} else {
				const room = this.props.joinedChatRooms.find((r) => r.chatRoomID == this.props.activeRoomID);
				if (room == undefined || !Array.isArray(room.chatMessages) || !Array.isArray(room.users)) return null;

				messages = room.chatMessages;
				players = room.users;
				// var _msg = {
				//   content: "/getPositions",
				//   type: "gameChat",
				//   sMessageID: 0,
				//   gameID: this.props.gameID,
				// };
				// MyWebSocket.shared.sendMsg(_msg);
			}
			return (
				<ChatMessages
					players={players}
					renderLoc={"gameRoom"}
					messages={messages}
					isGlobalMessage={this.props.activeRoomID !== -1}
					contentHeight={this.props.grSlotSettingsHeight - 30}
				/>
			);
		}
		return <View style={{ height: this.props.grSlotSettingsHeight - 30 }}>{this.renderSpinner()}</View>;
	}

	render() {
		const isGameChatNameDefined = typeof this.props.gameChatName === "string" && this.props.gameChatName != "";
		// const _height = isGameChatNameDefined
		//   ? isTablet
		//     ? this.props.resolution !== RESOLUTION.LOW
		//       ? this.props.grSlotSettingsHeight -
		//         gameDimensions.boxHeaderHeight -
		//         30
		//       : this.props.grSlotSettingsHeight -
		//         gameDimensions.boxHeaderHeight -
		//         22
		//     : this.props.grSlotSettingsHeight - gameDimensions.boxHeaderHeight - 20
		//   : isTablet
		//   ? this.props.resolution !== RESOLUTION.LOW
		//     ? this.props.grSlotSettingsHeight - 30
		//     : this.props.grSlotSettingsHeight - 22
		//   : this.props.grSlotSettingsHeight - 20;
		// const _marginTop = isTablet
		//   ? this.props.resolution !== RESOLUTION.LOW
		//     ? 30
		//     : 22
		//   : 20;
		const _height = isGameChatNameDefined
			? this.props.grSlotSettingsHeight - gameDimensions.boxHeaderHeight - 30
			: this.props.grSlotSettingsHeight - 30;
		const _marginTop = 30;
		return <View style={[styles.container, { height: _height, marginTop: _marginTop }]}>{this.renderChatBody()}</View>;
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { flex: 1, padding: 3 },
});

const mapStateToProps = (state) => {
	return {
		gameChatName: state.gameRoom.gameChatName,
		grSlotSettingsHeight: state.views.grSlotSettingsHeight,
		loadingGameRoom: state.gameRoom.loadingGameRoom,
		gameRoomPlayers: state.gameRoom.gameRoomPlayers,
		gameChatMessages: state.gameRoom.gameChatMessages,
		resolution: state.currentUser.preferences.resolution,
		joinedChatRooms: state.globalChat.joinedChatRooms,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GameRoomChatMessageBox);
