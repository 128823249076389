import React, { Component } from "react";
import { Dimensions, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, f_sourcesansproregular } from "../../../config/defaults";
import { emailTroubleDimensions, gameDimensions } from "../../../config/dimensions";
import { closeEarnFreeSilver } from "../../../redux/actions";
import { ColoredButton } from "../../common";
import { handleError } from "../../ErrorHandler";

class InviteEmailSent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
		};
	}

	//#region render methods
	renderInviteMoreButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.props.onInviteMorePress}
					textContent={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.emailSent.inviteMore}
					accessibilityLabel={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.emailSent.inviteMore}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderBackToShopButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.props.closeEarnFreeSilver}
					textContent={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.emailSent.backToShop}
					accessibilityLabel={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.emailSent.backToShop}
					color={buttonColor.YELLOW}
					disabled={false}
				/>
			</View>
		);
	}

	renderContent() {
		const { emailFriend } = this.props.lang.gameShop.inviteFriendsMenu;
		var text = emailFriend.emailSent.content.replace("#emailAddress#", this.props.emailAddress);
		text = text.replace("#silverAmount#", 500); //TODO:
		return (
			<View style={{ flex: 1 }}>
				<View style={styles.titleContainer}>
					<Text allowFontScaling={false} style={styles.titleStyle}>
						{this.props.lang.gameShop.inviteFriendsMenu.emailFriend.emailSent.title}
					</Text>
				</View>
				<ScrollView
					style={[
						styles.sentContentContainer,
						{
							height: Dimensions.get("window").height - 40,
						},
					]}
				>
					<Text allowFontScaling={false} style={styles.contentText}>
						{text}
					</Text>
				</ScrollView>
				<View style={styles.mainButtonContainer}>
					{this.renderBackToShopButton()}
					{this.renderInviteMoreButton()}
				</View>
			</View>
		);
	}

	render() {
		return <View style={styles.container}>{this.renderContent()}</View>;
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (emailTroubleDimensions.width > availableWidth || emailTroubleDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / emailTroubleDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / emailTroubleDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: { flex: 1, marginTop: 55, marginHorizontal: 30 },
	mainButtonContainer: { flexDirection: "row", justifyContent: "space-evenly" },
	buttonContainer: { marginHorizontal: 10 },
	titleContainer: { width: "85%", height: 50, alignSelf: "center" },
	titleStyle: {
		width: "100%",
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 28,
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
		textAlign: "center",
	},
	sentContentContainer: { paddingHorizontal: 50, marginVertical: 5 },
	contentText: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansproregular,
		fontSize: 18,
		color: "#FFFFFF",
		marginTop: 20,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
	};
};

const mapDispatchToProps = { closeEarnFreeSilver };

export default connect(mapStateToProps, mapDispatchToProps)(InviteEmailSent);
