import React, { Component } from "react";
import { FlatList, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansproregular,
	isWebOrTablet,
	RESOLUTION,
} from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { openComposeMessage, openMessageDetails, toggleCheckMessage } from "../../redux/actions";
import { ColoredButton, TableHeader, TableHeaderCell, TableRow, TableRowCell } from "../common";
import { handleError } from "../ErrorHandler";

class MessageInbox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			titleColumnWidth: 435,
			maxVisibleRows: 1,
		};
	}

	//#region events
	tableRendered(event) {
		try {
			const tableHeight = event.nativeEvent.layout.height;
			const maxVisibleRows = isWebOrTablet
				? this.props.resolution === RESOLUTION.HIGH
					? Math.floor(tableHeight / 41)
					: Math.floor(tableHeight / 31)
				: Math.floor(tableHeight / 41);
			this.setState({ maxVisibleRows });
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderTableHeader() {
		return (
			<TableHeader textStyle={isWebOrTablet ? {} : { fontSize: 20 }}>
				<TableHeaderCell
					key={"fromHeader"}
					cellContent={this.props.lang.gameMessage.fromHeader}
					containerStyle={{
						height: this.props.resolution === RESOLUTION.HIGH ? 40 : 30,
						width: this.props.resolution === RESOLUTION.HIGH ? 220 : 200,
					}}
					textStyle={[
						{
							flex: 1,
							textAlign: "center",
							paddingHorizontal: 3,
							fontSize: 20,
						},
						this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
					]}
				/>
				<TableHeaderCell
					key={"titleHeader"}
					cellContent={this.props.lang.gameMessage.titleHeader}
					containerStyle={{
						flex: 1,
						height: this.props.resolution === RESOLUTION.HIGH ? 40 : 30,
					}}
					textStyle={[
						{
							flex: 1,
							textAlign: "center",
							paddingHorizontal: 3,
							fontSize: 20,
						},
						this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
					]}
					onLayout={(event) => {
						this.setState({
							titleColumnWidth: Math.round(event.nativeEvent.layout.width),
						});
					}}
				/>
				<TableHeaderCell
					key={"dateHeader"}
					cellContent={this.props.lang.gameMessage.dateHeader}
					containerStyle={{
						height: this.props.resolution === RESOLUTION.HIGH ? 40 : 30,
						width: this.props.resolution === RESOLUTION.HIGH ? 210 : 170,
					}}
					textStyle={[
						{
							flex: 1,
							paddingLeft: 3,
							paddingRight: 5,
							textAlign: "center",
							fontSize: 20,
						},
						this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
					]}
				/>
				{this.props.resolution === RESOLUTION.HIGH && (
					<TableHeaderCell
						key={"checkHeader"}
						cellContent={this.props.lang.gameMessage.checkHeader}
						containerStyle={{ width: 100, height: 40 }}
						textStyle={[
							{
								flex: 1,
								textAlign: "center",
								paddingHorizontal: 3,
								fontSize: 26,
							},
						]}
					/>
				)}

				{Platform.OS === "web" && this.state.maxVisibleRows < this.props.messages.length && (
					<TableHeaderCell
						key={"scrollerHeader"}
						cellContent={""}
						containerStyle={{
							width: 17,
							height: this.props.resolution === RESOLUTION.HIGH ? 40 : 30,
						}}
						textStyle={{ flex: 1, textAlign: "center" }}
					/>
				)}
			</TableHeader>
		);
	}

	renderTableRow(item, ind) {
		//seconds
		var dateFromSeconds = new Date(item.recieveTimestamp * 1000);
		const year = dateFromSeconds.getFullYear();
		var month = dateFromSeconds.getMonth() + 1;
		if (month < 10) month = "0" + month;
		const day = dateFromSeconds.getDate();
		var _day = day;
		if (day < 10) _day = "0" + day;
		const date = _day + "-" + month + "-" + year;
		const time = dateFromSeconds.toLocaleTimeString("nl-NL").substr(0, 5);
		const sentTime = "" + date + " " + time;
		return (
			<TableRow
				rowId={"row_" + ind}
				onPress={() => {
					this.props.openMessageDetails(item.messageID);
				}}
				style={{
					height: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 30 : 40) : 40,
				}}
			>
				<TableRowCell
					key={"from_" + ind}
					lang={this.props.lang}
					backgroundColor={!item.isUnread ? "transparent" : "rgba(10, 95, 110,0.5)"}
					cellWidth={this.props.resolution === RESOLUTION.HIGH ? 220 : 200}
					cellId={"from_" + ind}
					cellType={"text"}
					content={item.fromUsername}
					style={[
						{
							textAlign: "left",
							paddingHorizontal: 3,
							fontFamily: item.isUnread ? f_sourcesansprobold : f_sourcesansproregular,
							fontSize: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 20 : 26) : 20,
							height: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 30 : 40) : 40,
							lineHeight: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 30 : 40) : 40,
						},
					]}
				/>
				<TableRowCell
					key={"title_" + ind}
					lang={this.props.lang}
					backgroundColor={!item.isUnread ? "transparent" : "rgba(10, 95, 110,0.5)"}
					cellWidth={this.state.titleColumnWidth}
					cellId={"title_" + ind}
					cellType={"text"}
					content={item.subject}
					style={[
						{
							textAlign: "left",
							paddingHorizontal: 3,
							fontFamily: item.isUnread ? f_sourcesansprobold : f_sourcesansproregular,
							fontSize: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 20 : 26) : 20,
							height: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 30 : 40) : 40,
							lineHeight: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 30 : 40) : 40,
						},
					]}
				/>
				<TableRowCell
					key={"date_" + ind}
					lang={this.props.lang}
					backgroundColor={!item.isUnread ? "transparent" : "rgba(10, 95, 110,0.5)"}
					cellWidth={this.props.resolution === RESOLUTION.HIGH ? 210 : 170}
					cellId={"date_" + ind}
					cellType={"text"}
					content={sentTime}
					style={[
						!item.isUnread && {
							letterSpacing: Platform.OS === "web" ? (this.props.resolution === RESOLUTION.HIGH ? 0.7 : 0.6) : 0.5,
						},
						{
							textAlign: "right",
							paddingLeft: 3,
							paddingRight: Platform.OS === "web" ? 5 : 8,
							fontFamily: item.isUnread ? f_sourcesansprobold : f_sourcesansproregular,
							fontSize: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 20 : 26) : 20,
							height: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 30 : 40) : 40,
							lineHeight: isWebOrTablet ? (this.props.resolution !== RESOLUTION.HIGH ? 30 : 40) : 40,
						},
					]}
				/>
				{this.props.resolution === RESOLUTION.HIGH && (
					<TableRowCell
						key={"check_" + ind}
						lang={this.props.lang}
						backgroundColor={!item.isUnread ? "transparent" : "rgba(10, 95, 110,0.5)"}
						cellWidth={100}
						cellId={"check_" + ind}
						cellType={"checkbox"}
						isChecked={item.isSelected}
						onValueChange={() => {
							this.props.toggleCheckMessage(item.messageID);
						}}
						checkBoxStyle={{ width: 24, height: 24, alignSelf: "center" }}
					/>
				)}
				{Platform.OS === "web" && this.state.maxVisibleRows < this.props.messages.length && (
					<TableRowCell
						key={"scroller_" + ind}
						lang={this.props.lang}
						backgroundColor={!item.isUnread ? "transparent" : "rgba(10, 95, 110,0.5)"}
						cellWidth={25}
						cellId={"scroller_" + ind}
						cellType={"text"}
						content={" "}
						style={{ paddingLeft: 0, paddingRight: 0 }}
					/>
				)}
			</TableRow>
		);
	}

	renderTable() {
		return (
			<FlatList
				keyExtractor={(msg, ind) => "message_" + ind}
				data={this.props.messages}
				renderItem={({ item, index }) => this.renderTableRow(item, index)}
				getItemLayout={(data, index) => ({
					length: 40,
					offset: 40 * index,
					index,
				})}
				style={{ flex: 1 }}
				onLayout={(event) => this.tableRendered(event)}
				accessibilityLabel={"Messages list"}
			/>
		);
	}

	renderCreateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.props.openComposeMessage}
					textContent={this.props.lang.gameMessage.btnCompose}
					accessibilityLabel={this.props.lang.gameMessage.btnCompose}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderMessageTable() {
		return (
			<View style={{ flex: 1 }}>
				{this.renderTableHeader()}
				{this.renderTable()}
			</View>
		);
	}

	render() {
		return (
			<View style={[styles.container, this.props.resolution === RESOLUTION.HIGH && styles.containerHR]}>
				<View style={styles.tableContainer}>{this.renderMessageTable()}</View>
				{this.renderCreateButton()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "flex-end",
		marginHorizontal: 10,
		marginTop: 60,
		marginBottom: 3,
	},
	containerHR: {
		marginTop: 75,
	},
	buttonContainer: {
		alignSelf: "flex-end",
		alignItems: "flex-end",
		marginTop: 10,
	},
	tableContainer: {
		flex: 1,
		paddingBottom: 1,
		paddingRight: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		messages: state.gameMessage.messages,
		clientLanguage: state.currentUser.preferences.clientLanguage,
	};
};

const mapDispatchToProps = {
	openComposeMessage,
	openMessageDetails,
	toggleCheckMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageInbox);
