import { Component } from "react";
import { DEBUG_ENABLED, LOGGING_ENABLED } from "../config/permissions";
import { MyWebSocket } from "../connection";
import { store } from "../redux/store";

export const handleError = (error) => {
	if (DEBUG_ENABLED) {
		//logging data
		console.log("ERROR DETECTED: ", error);
		if (LOGGING_ENABLED) {
			MyWebSocket.shared.logData({ title: "ERROR DETECTED:", content: error });
		}
	}
	if (error !== undefined) {
		// if (
		//   EventQueue.shared.currentEvent !== null &&
		//   !error.message.includes("Failed to load resource") &&
		//   !error.message.includes("Not Found, url")
		// ) {
		//   const { sMessageID, delay } = EventQueue.shared.currentEvent;
		//   EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
		// }
		if (store) {
			const { app, currentUser } = store.getState();
			if ((app.isLoggedIn || currentUser.isGuestUser) && currentUser.authenticated) {
				MyWebSocket.shared.sendMsg({
					type: "clientException",
					sMessageID: 0,
					inThisSession: true,
					exception: "internalException",
					stackTrace: [],
					details: {
						name: error.name,
						message: error.message,
						info: error.componentStack ? error.componentStack.toString() : error.stack ? error.stack.toString() : "",
						fullError: error,
					},
				});
			}
		}
	}
};

class ErrorHandler extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error) {
		this.setState({ hasError: true });
		handleError(error);
	}

	render() {
		return /* !this.state.hasError &&  */ this.props.children;
	}
}

export default ErrorHandler;
