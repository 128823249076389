import React, { Component } from "react";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import { f_sourcesansprobolditalic, isTablet, isWebOrTablet } from "../../config/defaults";
import { HEADER_CHIP_ICON_RED } from "../../config/images";

class HeaderText extends Component {
	renderText() {
		return (
			<View style={this.props.style} accessibilityLabel={this.props.textContent}>
				<Text
					allowFontScaling={false}
					style={[styles.textStyle, this.props.textContentStyle]}
					selectable={false}
					numberOfLines={1}
				>
					{this.props.textContent}
				</Text>
				<Text
					allowFontScaling={false}
					style={[
						styles.textStyle,
						this.props.textContentStyle,
						styles.textOutline,
						{ textShadowOffset: { width: 2, height: -2 } },
					]}
					selectable={false}
					numberOfLines={1}
				>
					{this.props.textContent}
				</Text>
				<Text
					allowFontScaling={false}
					style={[
						styles.textStyle,
						this.props.textContentStyle,
						styles.textOutline,
						{ textShadowOffset: { width: -2, height: 2 } },
					]}
					selectable={false}
					numberOfLines={1}
				>
					{this.props.textContent}
				</Text>
				<Text
					allowFontScaling={false}
					style={[
						styles.textStyle,
						this.props.textContentStyle,
						styles.textOutline,
						{ textShadowOffset: { width: -2, height: -2 } },
					]}
					selectable={false}
					numberOfLines={1}
				>
					{this.props.textContent}
				</Text>
				<Text
					allowFontScaling={false}
					style={[
						styles.textStyle,
						this.props.textContentStyle,
						styles.textOutline,
						{ textShadowOffset: { width: 2, height: 0 } },
					]}
					selectable={false}
					numberOfLines={1}
				>
					{this.props.textContent}
				</Text>
				<Text
					allowFontScaling={false}
					style={[
						styles.textStyle,
						this.props.textContentStyle,
						styles.textOutline,
						{ textShadowOffset: { width: 0, height: 2 } },
					]}
					selectable={false}
					numberOfLines={1}
				>
					{this.props.textContent}
				</Text>
				<Text
					allowFontScaling={false}
					style={[
						styles.textStyle,
						this.props.textContentStyle,
						styles.textOutline,
						{ textShadowOffset: { width: -2, height: 0 } },
					]}
					selectable={false}
					numberOfLines={1}
				>
					{this.props.textContent}
				</Text>
				<Text
					allowFontScaling={false}
					style={[
						styles.textStyle,
						this.props.textContentStyle,
						styles.textOutline,
						{ textShadowOffset: { width: 0, height: -2 } },
					]}
					selectable={false}
					numberOfLines={1}
				>
					{this.props.textContent}
				</Text>
			</View>
		);
	}

	render() {
		if (!this.props.withChipsIcon) return this.renderText();
		return (
			<View style={[{ flexDirection: "row", alignSelf: "center" }, this.props.style]}>
				{this.renderText()}
				<View style={this.props.iconContainer}>
					<Image
						resizeMode="stretch"
						source={HEADER_CHIP_ICON_RED}
						style={{
							width: isWebOrTablet ? 45 : 35,
							height: isWebOrTablet ? 45 : 35,
							marginTop: isWebOrTablet ? 6 : 3,
						}}
					/>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	textStyle: {
		width: "100%",
		paddingHorizontal: 5,
		textAlign: "center",
		color: "#084955",
		fontFamily: f_sourcesansprobolditalic,
		fontSize: isTablet ? 36 : 24,
		textShadowColor: "#92F4FF",
		textShadowOffset: { width: -2, height: -2 },
		textShadowRadius: Platform.OS !== "web" ? 1 : 0,
	},
	textOutline: {
		position: "absolute",
		zIndex: 0,
	},
});

export { HeaderText };
