import { cloneDeep, orderBy, sortBy } from "lodash";
import React, { Component } from "react";
import { FlatList, Image, SectionList, StyleSheet, Switch, Text, View } from "react-native";
import { connect } from "react-redux";
import { LAUREL_URL } from "../../config/connection";
import { appFont, RESOLUTION, tabType, viewSide } from "../../config/defaults";
import { gameDimensions, globalChatDimensions } from "../../config/dimensions";
import { allowPlayerInfo } from "../../config/permissions";
import { MyWebSocket } from "../../connection";
import { getCountryFlagImageURL, getRankingImageURL, mapObject } from "../../helpers/commonHelpers";
import {
	displayNotification,
	displayPermissionDenied,
	goBackToMain,
	openJoinedRoom,
	openTab,
	sendGlobalChatMessage,
	startChatRoomJoin,
	subcribeToGlobalChat,
	unsubcribeFromGlobalChat,
	unsubscribeFromGameList,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import {
	Box,
	ChatMessageSender,
	DoubleClick,
	Header,
	NavigationHeader,
	PlayerContextMenu,
	PlayerInfo,
} from "../common";
import { handleError } from "../ErrorHandler";
import MainNavigation from "../MainNavigation";
import CreateChatRoom from "./CreateChatRoom";
import GlobalChatMain from "./GlobalChatMain";
import JoinedChatRooms from "./JoinedChatRooms";

class GlobalChat extends Component {
	constructor(props) {
		super(props);
		const _onlinePlayers = this.prepOnlinePlayersList();
		this.state = {
			showChatMain: true,
			showLists: true,
			showChatRooms: false,
			showOnlinePlayers: true,
			chatRoomList: this.props.chatRoomList,
			onlinePlayers: _onlinePlayers,
			showCreateChatRoom: false,
			isPrivateRoom: false,
			selectedPlayer: null,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		this.checkState();
		this.props.subcribeToGlobalChat();
		await Analytics.logScreenView("GlobalChat");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.chatRoomList !== this.props.chatRoomList) {
			this.setState({ chatRoomList: this.props.chatRoomList });
		}

		if (prevProps.activeRoomUsers !== this.props.activeRoomUsers || prevProps.userDetails !== this.props.userDetails) {
			const _onlinePlayers = this.prepOnlinePlayersList();
			this.setState({ onlinePlayers: _onlinePlayers });
		}

		if (prevProps.joinedChatRooms.length !== this.props.joinedChatRooms.length) {
			if (this.props.joinedChatRooms.length == 0 && this.state.showLists == true) {
				this.setState({ showChatRooms: true, showOnlinePlayers: false });
			}
		}

		if (prevProps.activeChatRoomID != this.props.activeChatRoomID) {
			this.checkState();
		}
	}

	componentWillUnmount() {
		this.props.unsubcribeFromGlobalChat();
	}
	//#endregion

	//#region events
	onHeaderBackPress() {
		try {
			if (this.state.selectedPlayer !== null) {
				var newPlList = cloneDeep(this.state.onlinePlayers);
				mapObject(newPlList, (key, val) => {
					val.forEach((pl) => {
						pl.showContext = false;
					});
				});
				this.setState({ onlinePlayers: newPlList, selectedPlayer: null });
			} else {
				this.props.openTab(tabType.GAMES);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onHeaderClosePress() {
		this.props.unsubscribeFromGameList();
		this.props.goBackToMain();
	}

	onSwitchPress() {
		if (this.state.showChatRooms) {
			this.onChatRoomsListSwitchIconPress();
		} else {
			this.onPlayersListsSwitchIconPress();
		}
	}

	onPlayersListsSwitchIconPress() {
		this.setState({ showOnlinePlayers: false, showChatRooms: true });
	}

	onChatRoomsListSwitchIconPress() {
		this.setState({ showOnlinePlayers: true, showChatRooms: false });
	}

	onBackIconPress() {
		this.setState({
			showChatMain: true,
			showLists: false,
			showOnlinePlayers: false,
			showChatRooms: false,
		});
	}

	onPlayerDoublePress(item) {
		if (this.props.GLOBAL_CHAT_CAN_CREATE_PRIVATE_ROOM) {
			if (item.userID != this.props.userDetails.userID) this.createNewRoom("", true, item.userID);
		} else {
			this.props.displayPermissionDenied("GLOBAL_CHAT_CAN_CREATE_PRIVATE_ROOM");
		}
	}

	onPlayerSinglePress(item, section) {
		try {
			if (item.userID == this.props.userDetails.userID) return false;
			var newList = cloneDeep(this.state.onlinePlayers);
			var _item;
			switch (section.title) {
				case this.props.lang.globalChat.otherPlayers:
					_item = newList.normalPlayers.find((n) => n.userID == item.userID);
					_item.isBanned = false;
					_item.isFriend = false;
					break;
				case this.props.lang.globalChat.friends:
					_item = newList.friends.find((f) => f.userID == item.userID);
					_item.isBanned = false;
					_item.isFriend = true;
					break;
				case this.props.lang.globalChat.bannedPlayers:
					_item = newList.bannedPlayers.find((b) => b.userID == item.userID);
					_item.isBanned = true;
					_item.isFriend = false;
					break;
				default:
					return false;
			}
			//TODO: put this later back if (typeof _item !== "undefined") _item.showContext = true;
			this.setState({
				onlinePlayers: newList,
				selectedPlayer: allowPlayerInfo == true ? _item : null,
			});
		} catch (error) {
			handleError(error);
		}
	}

	onContextOptionSelect(item, section, option) {
		try {
			var newList = cloneDeep(this.state.onlinePlayers);
			var _item;
			switch (section.title) {
				case this.props.lang.globalChat.otherPlayers:
					_item = newList.normalPlayers.find((n) => n.userID == item.userID);
					break;
				case this.props.lang.globalChat.friends:
					_item = newList.friends.find((f) => f.userID == item.userID);
					break;
				case this.props.lang.globalChat.bannedPlayers:
					_item = newList.bannedPlayers.find((b) => b.userID == item.userID);
					break;
				default:
					return false;
			}
			if (typeof _item === "undefined") return false;
			var msgPlayerMod = {
				type: "playerMod",
				userID: _item.userID,
			};
			switch (option) {
				case "banAdd":
					msgPlayerMod.action = "banAdd";
					break;
				case "banRemove":
					msgPlayerMod.action = "banDelete";
					break;
				case "friendAdd":
					msgPlayerMod.action = "friendAdd";
					break;
				case "friendRemove":
					msgPlayerMod.action = "friendDelete";
					break;
				case "mute":
					var notificationText = "";
					if (
						typeof this.props.lang.messages !== "undefined" &&
						typeof this.props.lang.messages.notImplementedYetMessage !== "undefined"
					) {
						var notificationObj = this.props.lang.messages.notImplementedYetMessage;
						if (typeof notificationObj !== "undefined") notificationText = notificationObj.main_text;
					}
					var notification = {
						displayTime: 5000,
						deleteHistory: false,
						rotate: false,
						clickable: false,
						playSound: true,
						url: "#",
						priority: "high",
						announcement: notificationText,
						isNotification: true,
					};
					this.props.displayNotification(notification);
					return false;
				case "privateRoom":
					this.onPlayerDoublePress(item);
					return true;
				default:
					return false;
			}
			MyWebSocket.shared.sendMsg(msgPlayerMod);
		} catch (error) {
			handleError(error);
		}
	}

	onCloseContextMenu(item, section) {
		try {
			var newList = cloneDeep(this.state.onlinePlayers);
			var _item;
			switch (section.title) {
				case this.props.lang.globalChat.otherPlayers:
					_item = newList.normalPlayers.find((n) => n.userID == item.userID);
					break;
				case this.props.lang.globalChat.friends:
					_item = newList.friends.find((f) => f.userID == item.userID);
					break;
				case this.props.lang.globalChat.bannedPlayers:
					_item = newList.bannedPlayers.find((b) => b.userID == item.userID);
					break;
				default:
					return false;
			}
			if (typeof _item !== "undefined") _item.showContext = false;
			this.setState({ onlinePlayers: newList });
		} catch (error) {
			handleError(error);
		}
	}

	openOrJoinChatRoom(room) {
		try {
			if (typeof room === "undefined" || typeof room.chatRoomID === "undefined") return false;
			const joined = this.props.joinedChatRooms.find((r) => r.chatRoomID == room.chatRoomID);
			if (joined != undefined && room.chatRoomID == joined.chatRoomID) {
				this.props.openJoinedRoom(joined);
				this.setState({
					showChatRooms: false,
					showOnlinePlayers: true,
					showChatMain: true,
					showLists: true,
				});
			} else {
				this.props.startChatRoomJoin(room.chatRoomID);
			}
		} catch (error) {
			handleError(error);
		}
	}

	createNewRoom(name, isPrivate, userID) {
		try {
			if (typeof name !== "string" || typeof isPrivate !== "boolean") return;
			if (!isPrivate && name.length < 6) return;

			const msgCreateNewChatRoom = {
				type: "chatRoomCreate",
				friendsOnly: isPrivate ? true : false,
				roomType: isPrivate ? "private" : "normal",
				userIDtoJoin: isPrivate ? userID : "0",
				name: isPrivate ? "" : name,
			};
			MyWebSocket.shared.sendMsg(msgCreateNewChatRoom);
			this.setState({
				showCreateChatRoom: false,
				showChatRooms: false,
				showOnlinePlayers: true,
				showChatMain: true,
				showLists: true,
			});
		} catch (error) {
			handleError(error);
		}
	}

	sendGameChatMessage(msgText) {
		this.props.sendGlobalChatMessage(msgText, this.props.activeChatRoomID);
	}

	onFriendAction(item) {
		try {
			if (item.isFriend == true) {
				//remove from friendlist
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "removeFromFriendList",
						userID: item.userID,
					});
				}
			} else {
				//add to friendlist
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "addToFriendList",
						userID: item.userID,
					});
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onBanAction(item) {
		try {
			if (item.isBanned == true) {
				//remove from banList
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "removeFromBanList",
						userID: item.userID,
					});
				}
			} else {
				//add to banList
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "addToBanList",
						userID: item.userID,
					});
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onHeaderBackPress.bind(this)}
				closeAction={this.onHeaderClosePress.bind(this)}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			>
				{this.state.selectedPlayer == null && <JoinedChatRooms show={this.state.showChatMain} />}
			</NavigationHeader>
		);
	}

	renderChatMain() {
		if (!this.state.showChatMain) return null;
		return <GlobalChatMain />;
	}

	renderCreateChatRoom() {
		if (!this.state.showCreateChatRoom) return null;
		const fullWidth = this.props.chatMainWidth + this.props.chatListsLeftMargin + this.props.chatListsWidth;
		const maxHeight =
			this.props.chatMainHeight >= this.onPlayersListsSwitchIconPress.chatListsHeight
				? this.props.chatMainHeight + globalChatDimensions.chatInputHeight + 5
				: this.props.chatListsHeight + globalChatDimensions.chatInputHeight + 5;
		return (
			<CreateChatRoom
				lang={this.props.lang}
				isPrivateRoom={this.state.isPrivateRoom}
				width={fullWidth}
				height={maxHeight}
				boxWidth={this.props.chatRoomNameWidth}
				marginLeft={this.props.chatMainLeftMargin}
				isSmallResolution={this.props.isSmallResolution}
				onCreatePress={(name, isPrivate) => this.createNewRoom(name, isPrivate)}
				onCancelPress={() => this.setState({ showCreateChatRoom: false, isPrivateRoom: false })}
				style={{ opacity: this.state.showCreateChatRoom ? 1 : 0 }}
			/>
		);
	}

	renderChatRoomRow(item) {
		return (
			<DoubleClick
				activeOpacity={1}
				singleTap={() => this.openOrJoinChatRoom(item)}
				//doubleTap={() => this.openOrJoinChatRoom(item)}
				style={styles.chatRoomsRowContainer}
			>
				<Text allowFontScaling={false} style={styles.chatRoomsLeftCol}>
					{item.name}
				</Text>
				<Text allowFontScaling={false} style={styles.chatRoomsRightCol}>
					{item.userCnt}
				</Text>
			</DoubleClick>
		);
	}

	renderChatRoomsListBody() {
		return (
			<FlatList
				keyExtractor={(item) => "room_" + item.chatRoomID}
				data={this.state.chatRoomList}
				extraData={this.props}
				renderItem={({ item }) => this.renderChatRoomRow(item)}
				getItemLayout={(data, index) => ({
					length: 16,
					offset: 16 * index,
					index,
				})}
				style={{
					// padding: 10,
					height: this.props.chatListsContentHeight - 20,
				}}
			/>
		);
	}

	renderPlayerInfo() {
		if (this.state.selectedPlayer == null) return null;
		return (
			<View style={styles.playerInfoContainer}>
				<View style={styles.playerInfoContentContainer}>
					<PlayerInfo
						lang={this.props.lang}
						player={this.state.selectedPlayer}
						chatAction={() => {}}
						messageAction={() => {}}
						friendAction={() => this.onFriendAction(this.state.selectedPlayer)}
						banAction={() => this.onBanAction(this.state.selectedPlayer)}
						userDetails={this.props.userDetails}
						backAction={this.onHeaderBackPress.bind(this)}
					/>
				</View>
			</View>
		);
	}

	renderContextMenu(item, section) {
		if (!item.showContext) return null;
		return (
			<PlayerContextMenu
				ref="playerContext"
				refItem={item}
				refSection={section}
				lang={this.props.lang}
				onCloseMenu={(item, section) => this.onCloseContextMenu(item, section)}
				onOptionSelect={(item, section, option) => this.onContextOptionSelect(item, section, option)}
			/>
		);
	}

	renderOnlinePlayerRow(item, section) {
		const rankingImgURL = getRankingImageURL(item.ranking, 14);
		const laurelImgSrc = item.isVip ? { uri: LAUREL_URL + "009.png" } : {};
		const countryImgURL =
			typeof item.country !== "undefined" && item.country != ""
				? getCountryFlagImageURL(item.country.toUpperCase())
				: "https://";
		const uNameWidth = this.props.chatListsWidth - 75;
		var relation = "";
		switch (section.title) {
			case this.props.lang.globalChat.friends:
				relation = "FRIEND";
				break;
			case this.props.lang.globalChat.otherPlayers:
				relation = "NORMAL";
				break;
			case this.props.lang.globalChat.bannedPlayers:
				relation = "BANNED";
				break;
			default:
				break;
		}
		const rowPressFunc = () => this.onPlayerSinglePress(item, section);
		const doubleClickFunc = () => this.onPlayerDoublePress(item);

		return (
			<DoubleClick
				activeOpacity={1}
				style={{ flexDirection: "row", marginBottom: 2 }}
				singleTap={rowPressFunc.bind(this)}
				doubleTap={doubleClickFunc.bind(this)}
				disabled={!allowPlayerInfo}
			>
				<Image key="laurel" resizeMode={"stretch"} source={laurelImgSrc} style={styles.opLaurelImg} />
				<Image source={rankingImgURL} style={styles.opRankingImg} />
				<Image source={{ uri: countryImgURL }} style={styles.flagImg} />
				{this.renderContextMenu(item, section)}
				<Text
					allowFontScaling={false}
					selectable={false}
					numberOfLines={1}
					ellipsizeMode="tail"
					style={[styles.opUserName, { width: uNameWidth }]}
				>
					{item.username}
				</Text>
			</DoubleClick>
		);
	}

	renderOnlinePlayersListBody() {
		const { friends, normalPlayers, bannedPlayers } = this.state.onlinePlayers;
		const sections = [
			{ title: this.props.lang.globalChat.friends, data: friends },
			{ title: this.props.lang.globalChat.otherPlayers, data: normalPlayers },
			{ title: this.props.lang.globalChat.bannedPlayers, data: bannedPlayers },
		];
		return (
			<SectionList
				keyExtractor={(item) => "user_" + item.userID}
				sections={sections}
				renderSectionHeader={({ section }) => (
					<Text
						allowFontScaling={false}
						selectable={false}
						numberOfLines={1}
						style={[
							styles.sectionHeader,
							{
								marginTop: section.title == this.props.lang.globalChat.friends ? 0 : 3,
							},
						]}
					>
						{section.title}
					</Text>
				)}
				renderItem={({ item, section }) => this.renderOnlinePlayerRow(item, section)}
				extraData={this.props}
				getItemLayout={(data, index) => ({
					length: 18,
					offset: 18 * index,
					index,
				})}
				style={{
					padding: 10,
					height: this.props.chatListsContentHeight,
				}}
			/>
		);
	}

	renderListsContent() {
		if (this.state.showOnlinePlayers) {
			const playersNr = this.props.activeRoomsUserCnt;
			const plHeaderText = playersNr + " " + this.props.lang.globalChat.onlinePlayersHeader;
			return (
				<View style={{ paddingBottom: 10 }}>
					<Header
						textContent={plHeaderText}
						position={viewSide.RIGHT}
						style={{ height: gameDimensions.boxHeaderHeight }}
					/>
					{this.renderOnlinePlayersListBody()}
				</View>
			);
		} else {
			return (
				<View>
					<Header
						textContent={this.props.lang.globalChat.chatRoomsListHeader}
						position={viewSide.RIGHT}
						style={{ height: gameDimensions.boxHeaderHeight }}
					/>
					<View style={styles.chatRoomsHeaderContainer}>
						<Text allowFontScaling={false} style={styles.chatRoomsLeftHeader}>
							{this.props.lang.globalChat.chatRoomsLeftHeader}
						</Text>
						<Text allowFontScaling={false} style={styles.chatRoomsRightHeader}>
							{this.props.lang.globalChat.chatRoomsRightHeader}
						</Text>
					</View>
					{this.renderChatRoomsListBody()}
				</View>
			);
		}
	}

	renderLists() {
		if (this.state.showLists) {
			let boxStyle =
				this.props.resolution !== RESOLUTION.LOW
					? {
							flex: 1,
							maxWidth: globalChatDimensions.chatListsDefaultWidth,
							height: this.props.chatListsHeight,
							marginLeft: this.props.chatListsLeftMargin,
					  }
					: {
							width: this.props.chatListsWidth,
							height: this.props.chatListsHeight,
							marginLeft: this.props.chatListsLeftMargin,
					  };

			return <Box style={boxStyle}>{this.renderListsContent()}</Box>;
		}
		return null;
	}

	renderChatInput() {
		const containerStyle =
			this.props.resolution !== RESOLUTION.LOW
				? {
						flex: 2,
						maxWidth: globalChatDimensions.chatMainDefaultWidth,
						marginTop: 5,
				  }
				: {
						width: this.props.chatMainWidth,
						marginTop: 5,
						marginLeft: this.props.chatMainLeftMargin,
				  };
		const senderStyle = [
			{ backgroundColor: "transparent" },
			this.props.resolution === RESOLUTION.LOW && {
				maxWidth: this.props.chatMainWidth,
			},
		];
		return (
			<View style={containerStyle}>
				<ChatMessageSender
					lang={this.props.lang}
					isEnabled={this.props.activeChatRoomID != -1}
					chatButtonPress={this.sendGameChatMessage.bind(this)}
					chatRoomID={this.props.activeChatRoomID}
					style={senderStyle}
					silentPress={true}
				/>
			</View>
		);
	}

	renderChatBoxSwitch() {
		const containerStyle = [
			styles.switchContainer,
			this.props.resolution !== RESOLUTION.LOW && {
				flex: 1,
				maxWidth: globalChatDimensions.chatListsDefaultWidth,
				marginLeft: this.props.chatListsLeftMargin,
			},
			this.props.resolution === RESOLUTION.LOW && {
				width: this.props.chatListsWidth,
				marginLeft: this.props.chatListsLeftMargin,
			},
		];
		return (
			<View style={containerStyle}>
				<Text allowFontScaling={false} style={styles.switchTitle}>
					{this.props.lang.globalChat.switchTitle}
				</Text>
				<Switch
					disabled={this.props.joinedChatRooms.length <= 0}
					value={this.state.showChatRooms}
					activeTrackColor={this.state.showChatRooms ? "#00e600" : "grey"}
					onValueChange={this.onSwitchPress.bind(this)}
					style={styles.switch}
				/>
			</View>
		);
	}

	render() {
		const { isGlobalChatOpen, isTabChatButtonActive, isTabShopButtonActive } = this.props;
		if (!isTabChatButtonActive || !isGlobalChatOpen || isTabShopButtonActive) return null;
		return (
			<View style={{ flex: 1 }}>
				{this.renderHeader()}
				{this.state.selectedPlayer == null && (
					<View
						style={{
							marginTop: this.props.resolution === RESOLUTION.HIGH ? 70 : 60,
							flex: 1,
							flexDirection: "row",
						}}
					>
						{this.props.resolution !== RESOLUTION.LOW && <MainNavigation />}
						<View
							style={{
								flex: 1,
								marginBottom: gameDimensions.pageContentBottomMargin,
								height: this.props.chatMainHeight,
								position: "relative",
								zIndex: 0,
							}}
						>
							<View
								style={[
									{ flexDirection: "row" },
									this.props.resolution !== RESOLUTION.LOW && {
										justifyContent: "center",
										marginRight: 3,
									},
								]}
							>
								{this.renderChatMain()}
								{this.renderLists()}
							</View>
							<View
								style={[
									{ flex: 1, flexDirection: "row" },
									this.props.resolution !== RESOLUTION.LOW && {
										justifyContent: "center",
									},
								]}
							>
								{this.renderChatInput()}
								{this.renderChatBoxSwitch()}
							</View>
						</View>
						{this.renderCreateChatRoom()}
					</View>
				)}
				{this.state.selectedPlayer !== null && this.renderPlayerInfo()}
			</View>
		);
	}
	//#endregion

	//#region helpers
	checkState() {
		this.setState({
			showChatMain: true,
			showLists: true,
			showChatRooms: this.props.joinedChatRooms.length == 0,
			showOnlinePlayers: this.props.joinedChatRooms.length != 0,
		});
	}

	prepOnlinePlayersList() {
		try {
			var players = [...this.props.activeRoomUsers];
			players = sortBy(players, (pl) => pl.userID);
			players = orderBy(players, ["isVip", "score"], ["desc", "desc"]);

			const { banList, friendList } = this.props.userDetails;
			var friends = [],
				normalPlayers = [],
				bannedPlayers = [];
			players.forEach((pl) => {
				var isPlayerBanned = typeof banList !== "undefined" && Array.isArray(banList) && banList.includes(pl.userID);
				var isPlayerFriend =
					typeof friendList !== "undefined" && Array.isArray(friendList) && friendList.includes(pl.userID);
				if (isPlayerBanned && bannedPlayers.findIndex((b) => b.userID == pl.userID) == -1) bannedPlayers.push(pl);
				if (isPlayerFriend && friends.findIndex((f) => f.userID == pl.userID) == -1) friends.push(pl);
				if (!isPlayerBanned && !isPlayerFriend && normalPlayers.findIndex((n) => n.userID == pl.userID))
					normalPlayers.push(pl);
			});

			/* friends = orderBy(friends, ["isVip", "score"], ["desc", "desc"]);

      normalPlayers = orderBy(
        normalPlayers,
        ["isVip", "score"],
        ["desc", "desc"]
      );

      bannedPlayers = orderBy(
        bannedPlayers,
        ["isVip", "score"],
        ["desc", "desc"]
      ); */
			return { friends, normalPlayers, bannedPlayers };
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	sectionHeader: {
		fontFamily: appFont,
		fontSize: 14,
		textDecorationLine: "underline",
		marginBottom: 10,
	},
	opUserName: {
		fontFamily: appFont,
		fontSize: 12,
		height: 18,
		paddingTop: 2,
		paddingBottom: 2,
	},
	switchContainer: {
		flexDirection: "row",
		height: 60,
		justifyContent: "flex-end",
	},
	switchTitle: {
		fontFamily: appFont,
		fontSize: 14,
		alignSelf: "center",
		height: 30,
		lineHeight: 30,
	},
	switch: {
		transform: [{ scale: 1.5 }],
		marginLeft: 30,
		marginRight: 20,
		alignSelf: "center",
	},
	opRankingImg: {
		width: 14,
		height: 14,
		marginTop: 2,
		marginBottom: 2,
		marginLeft: 5,
		marginRight: 5,
	},
	opLaurelImg: {
		width: 26,
		height: 26,
		position: "absolute",
		top: -4,
		left: -1,
	},
	flagImg: {
		width: 14,
		height: 14,
		marginTop: 2,
		marginBottom: 2,
		marginRight: 10,
	},
	playerInfoContainer: {
		flex: 1,
		marginTop: 55,
		paddingBottom: 0,
		paddingHorizontal: 40,
		justifyContent: "center",
	},
	playerInfoContentContainer: {
		width: "100%",
		flexDirection: "row",
		height: 375,
	},
	chatRoomsLeftCol: { fontFamily: appFont, fontSize: 16, flex: 1 },
	chatRoomsRightCol: {
		fontFamily: appFont,
		fontSize: 16,
		width: 50,
		textAlign: "right",
		borderLeftWidth: 1,
		borderColor: "#ddd",
	},
	chatRoomsRowContainer: {
		flexDirection: "row",
		borderBottomWidth: 1,
		borderColor: "#ddd",
		paddingHorizontal: 3,
	},
	chatRoomsLeftHeader: {
		flex: 1,
		fontFamily: appFont,
		color: "#fff",
		fontSize: 16,
		textAlign: "center",
	},
	chatRoomsRightHeader: {
		width: 50,
		fontFamily: appFont,
		color: "#fff",
		fontSize: 16,
		textAlign: "center",
		borderLeftWidth: 1,
		borderColor: "#ddd",
	},
	chatRoomsHeaderContainer: {
		height: 20,
		flexDirection: "row",
		paddingHorizontal: 3,
		backgroundColor: "#ccc",
		borderBottomWidth: 1,
		borderColor: "#ddd",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isGlobalChatOpen: state.tab.isGlobalChatOpen,
		isTabChatButtonActive: state.tab.isTabChatButtonActive,
		isTabShopButtonActive: state.tab.isTabShopButtonActive,
		isSmallResolution: state.views.isSmallResolution,
		chatMainWidth: state.views.chatMainWidth,
		chatMainHeight: state.views.chatMainHeight,
		chatMainLeftMargin: state.views.chatMainLeftMargin,
		chatListsWidth: state.views.chatListsWidth,
		chatListsHeight: state.views.chatListsHeight,
		chatListsLeftMargin: state.views.chatListsLeftMargin,
		chatListsContentHeight: state.views.chatListsContentHeight,
		chatRoomNameWidth: state.views.chatRoomNameWidth,
		onlinePlayersAmount: state.globalChat.onlinePlayersAmount,
		chatRoomList: state.globalChat.chatRoomList,
		joinedChatRooms: state.globalChat.joinedChatRooms,
		activeChatRoomID: state.globalChat.activeRoom ? state.globalChat.activeRoom.chatRoomID : -1,
		activeRoomUsers:
			state.globalChat.activeRoom && Array.isArray(state.globalChat.activeRoom.users)
				? state.globalChat.activeRoom.users
				: [],
		activeRoomsUserCnt:
			state.globalChat.activeRoom && state.globalChat.activeRoom.userCnt != undefined
				? state.globalChat.activeRoom.userCnt
				: "0",
		userDetails: state.currentUser.userDetails,
		GLOBAL_CHAT_CAN_CREATE_PRIVATE_ROOM: state.currentUser.features.GLOBAL_CHAT_CAN_CREATE_PRIVATE_ROOM,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {
	displayNotification,
	displayPermissionDenied,
	goBackToMain,
	openJoinedRoom,
	openTab,
	sendGlobalChatMessage,
	startChatRoomJoin,
	subcribeToGlobalChat,
	unsubscribeFromGameList,
	unsubcribeFromGlobalChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalChat);
