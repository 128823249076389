import { cloneDeep } from "lodash";
import { Platform } from "react-native";
import { SOUND_SET } from "../../config/connection";
import {
	ADD_URL_TO_SOCKET_LIST,
	AUTHENTICATE_START,
	AUTHENTICATE_SUCCEEDED,
	CLEAR_WEBSOCKET,
	CONNECTION_FAILED,
	CONNECTION_SUCCESSFULL,
	CONNECT_TO_NEXT_URL,
	CONNECT_TO_WEBSOCKET,
	INCREASE_CLIENT_MESSAGES_COUNTER,
	INCREASE_CONNECTION_TRIES,
	MESSAGE_SENT,
	PROCESS_ACK_EVENT,
	RECONNECT_FINISHED,
	RECONNECT_START,
	REMOVE_SAVED_EVENT,
	REMOVE_URL_FROM_SOCKET_LIST,
	RESET_CLIENT_MESSAGES_COUNTER,
	RESET_CONNECTION_TRIES,
	SOCKET_CONNECTION_CLOSED,
	SOCKET_CONNECTION_ESTABLISHED,
} from "../actions/actionTypes";

const initialState = {
	websocket: null,
	connected: false,
	sentMsg: [],
	loading: true,
	cMessageID: 0,
	internSoundSetURL: "",
	wsURLs: [],
	ipList: [],
	isReconnecting: false,
	connectionID: 0,
	connectionTriesCounter: 0,
	socketConnected: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SOCKET_CONNECTION_ESTABLISHED:
			return { ...state, socketConnected: true };
		case SOCKET_CONNECTION_CLOSED:
			return { ...state, socketConnected: false };
		case CONNECTION_SUCCESSFULL:
			return { ...state, connected: true, isReconnecting: false };
		case CONNECTION_FAILED:
			return { ...state, connected: false, isReconnecting: false };
		case ADD_URL_TO_SOCKET_LIST:
			if (typeof payload === "undefined" || typeof payload.url !== "string") return state;

			var curList = cloneDeep(state.wsURLs);
			if (payload.url.indexOf("ws://") == 0 || payload.url.indexOf("wss://") == 0) {
				if (curList.indexOf(payload.url) == -1) curList.push(payload.url);
			} else {
				if (Platform.OS === "web") {
					if (window.location.protocol === "https://") {
						curList.push("wss://" + payload.url + "/keezers/");
					} else {
						curList.push("ws://" + payload.url + "/keezers/");
					}
				} else {
					curList.push("wss://" + payload.url + "/keezers/");
				}
			}
			return {
				...state,
				wsURLs: curList,
				// next only if debugUser want to swap between sockets
				connectionID: isNaN(payload._connectionID) ? state.connectionID : payload._connectionID - 1,
			};
		case REMOVE_URL_FROM_SOCKET_LIST:
			if (typeof payload === "undefined" || typeof payload.url !== "string") return state;
			var curList = cloneDeep(state.wsURLs);
			const removableIndex = curList.indexOf(payload.url);
			if (removableIndex == -1) return state;
			curList.splice(removableIndex, 1);
			return {
				...state,
				wsURLs: curList,
				connectionID: curList.length <= 1 ? 0 : state.connectionID,
			};
		case CONNECT_TO_WEBSOCKET:
			if (
				typeof payload === "undefined" ||
				typeof payload.url !== "string" ||
				typeof payload.eventHandlers === "undefined"
			)
				return state;
			const { url, eventHandlers } = payload;
			var newWSConn = new WebSocket(url);
			newWSConn.onopen = (event) => eventHandlers.onopen(event);
			newWSConn.onmessage = (event) => eventHandlers.onmessage(event);
			newWSConn.onerror = (event) => eventHandlers.onerror(event);
			newWSConn.onclose = (event) => eventHandlers.close(event);
			return { ...state, websocket: newWSConn };
		case INCREASE_CONNECTION_TRIES:
			return {
				...state,
				connectionTriesCounter: state.connectionTriesCounter + 1,
			};
		case RESET_CONNECTION_TRIES:
			return {
				...state,
				connectionTriesCounter: initialState.connectionTriesCounter,
			};
		case AUTHENTICATE_START:
			return { ...state, loading: true };
		case AUTHENTICATE_SUCCEEDED:
			if (typeof payload === "undefined" || typeof payload.soundSet === "undefined")
				return { ...state, loading: false };
			const strSoundSet = payload.soundSet.toString();
			var newSoundSetURL = SOUND_SET;
			if (strSoundSet.length > 0)
				if (strSoundSet.length > 1) {
					newSoundSetURL = newSoundSetURL + strSoundSet + "/";
				} else {
					newSoundSetURL = newSoundSetURL + "0" + strSoundSet + "/";
				}
			return { ...state, loading: false, internSoundSetURL: newSoundSetURL };
		case INCREASE_CLIENT_MESSAGES_COUNTER:
			var newID = state.cMessageID + 1;
			return { ...state, cMessageID: newID };
		case RESET_CLIENT_MESSAGES_COUNTER:
			return { ...state, cMessageID: 0 };
		case MESSAGE_SENT:
			if (typeof payload === "undefined" || typeof payload.type === "undefined") return state;
			switch (payload.type) {
				case "guestLogin":
				case "registerGuest":
				case "login":
				case "facebookLogin":
				case "register":
				case "recoverUsername":
				case "recoverPassword":
				case "changePassword":
				case "getBanList":
				case "getFriendList":
				case "addToBanList":
				case "addToFriendList":
				case "removeFromBanList":
				case "removeFromFriendList":
				case "updateUsername":
				case "updateLanguage":
				case "updateLocation":
				case "updateAvatar":
				case "deleteAvatar":
				case "updatePersonalData":
				case "playerMod":
				case "publishGame":
				case "joinGame":
				case "shopBuyItem":
				case "purchaseShopItemWithSilver":
				case "purchaseShopItemWithGold":
				case "addCredit":
				case "updateSettings":
				case "getGameDetails":
				case "leaveGame":
				case "rejoinGame":
				case "spectateGame":
				case "startGame":
					var newSentList = cloneDeep(state.sentMsg);
					newSentList.push(payload);
					return { ...state, sentMsg: newSentList };
				case "gamePlayerKick":
					if (!payload.ban) return state;
					var newSentList = cloneDeep(state.sentMsg);
					newSentList.push(payload);
					return { ...state, sentMsg: newSentList };
				case "playerAct":
					if (payload.moveToSave == undefined || payload.moveToSave.saveMove == false) return state;

					var newSentList = cloneDeep(state.sentMsg);
					newSentList.push(payload);
					return { ...state, sentMsg: newSentList };
				default:
					return state;
			}
		case PROCESS_ACK_EVENT:
			if (typeof payload === "undefined" || typeof payload.cMessageID === "undefined") return state;
			var newSentList = cloneDeep(state.sentMsg);
			var responseToIndex = newSentList.findIndex((m) => m.cMessageID == payload.cMessageID);
			if (responseToIndex == -1 || newSentList[responseToIndex].type != "playerMod") return state;
			newSentList.splice(responseToIndex, 1);
			return { ...state, sentMsg: newSentList };
		case REMOVE_SAVED_EVENT:
			if (typeof payload === "undefined" || typeof payload.cMessageID === "undefined") return state;

			var newSentList = cloneDeep(state.sentMsg);
			var responseToIndex = newSentList.findIndex((m) => m.cMessageID == payload.cMessageID);
			if (responseToIndex == -1) {
				responseToIndex = newSentList.findIndex((m) => m.type == payload.mType);
			}
			if (responseToIndex == -1) return state;

			newSentList.splice(responseToIndex, 1);
			return { ...state, sentMsg: newSentList };
		case CONNECT_TO_NEXT_URL:
			var connID = state.connectionID + 1;
			if (connID >= state.wsURLs.length) connID = 0;
			return { ...state, connectionID: connID };
		case CLEAR_WEBSOCKET:
			return { ...state, websocket: null };
		case RECONNECT_START:
			return { ...state, isReconnecting: true, connected: false };
		case RECONNECT_FINISHED:
			return { ...state, isReconnecting: false };
		default:
			return state;
	}
};
