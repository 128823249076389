import { Platform } from "react-native";
import { handleError } from "../components/ErrorHandler";
import Sound from "../libraries/Sound/sound";

const msgSend = Platform.select({
	android: "message_send",
	ios: require("../assets/sounds/message_send.mp3"),
	web: require("../assets/sounds/message_send.mp3").default,
});
const msgReceived = Platform.select({
	android: "message_received",
	ios: require("../assets/sounds/message_received.mp3"),
	web: require("../assets/sounds/message_received.mp3").default,
});
const buttonSound = Platform.select({
	android: "button",
	ios: require("../assets/sounds/button.mp3"),
	web: require("../assets/sounds/button.mp3").default,
});
const cashSound = Platform.select({
	android: "cash",
	ios: require("../assets/sounds/cash.mp3"),
	web: require("../assets/sounds/cash.mp3").default,
});
const goldSound = Platform.select({
	android: "gold",
	ios: require("../assets/sounds/gold.mp3"),
	web: require("../assets/sounds/gold.mp3").default,
});
const silverSound = Platform.select({
	android: "silver",
	ios: require("../assets/sounds/silver.mp3"),
	web: require("../assets/sounds/silver.mp3").default,
});
const saveSound = Platform.select({
	android: "save",
	ios: require("../assets/sounds/save.mp3"),
	web: require("../assets/sounds/save.mp3").default,
});
const add_credit = Platform.select({
	android: "add_credit",
	ios: require("../assets/sounds/add_credit.mp3"),
	web: require("../assets/sounds/add_credit.mp3").default,
});
const reconnect = Platform.select({
	android: "reconnect",
	ios: require("../assets/sounds/reconnect.mp3"),
	web: require("../assets/sounds/reconnect.mp3").default,
});
const player_leaves = Platform.select({
	android: "player_leaves",
	ios: require("../assets/sounds/player_leaves.mp3"),
	web: require("../assets/sounds/player_leaves.mp3").default,
});
const player_joins = Platform.select({
	android: "player_joins",
	ios: require("../assets/sounds/player_joins.mp3"),
	web: require("../assets/sounds/player_joins.mp3").default,
});
const notification = Platform.select({
	android: "notification",
	ios: require("../assets/sounds/notification.mp3"),
	web: require("../assets/sounds/notification.mp3").default,
});
const my_turn_inactive = Platform.select({
	android: "my_turn_inactive",
	ios: require("../assets/sounds/my_turn_inactive.mp3"),
	web: require("../assets/sounds/my_turn_inactive.mp3").default,
});
const my_turn = Platform.select({
	android: "my_turn",
	ios: require("../assets/sounds/my_turn.mp3"),
	web: require("../assets/sounds/my_turn.mp3").default,
});
const move_normal = Platform.select({
	android: "move_normal",
	ios: require("../assets/sounds/move_normal.mp3"),
	web: require("../assets/sounds/move_normal.mp3").default,
});
const move_start = Platform.select({
	android: "move_start",
	ios: require("../assets/sounds/move_start.mp3"),
	web: require("../assets/sounds/move_start.mp3").default,
});
const move_smash = Platform.select({
	android: "move_smash",
	ios: require("../assets/sounds/move_smash.mp3"),
	web: require("../assets/sounds/move_smash.mp3").default,
});
const move_jack = Platform.select({
	android: "move_jack",
	ios: require("../assets/sounds/move_jack.mp3"),
	web: require("../assets/sounds/move_jack.mp3").default,
});
const move_backwards = Platform.select({
	android: "move_backwards",
	ios: require("../assets/sounds/move_backwards.mp3"),
	web: require("../assets/sounds/move_backwards.mp3").default,
});
const level_up = Platform.select({
	android: "level_up",
	ios: require("../assets/sounds/level_up.mp3"),
	web: require("../assets/sounds/level_up.mp3").default,
});
const kick = Platform.select({
	android: "kick",
	ios: require("../assets/sounds/kick.mp3"),
	web: require("../assets/sounds/kick.mp3").default,
});
const game_start = Platform.select({
	android: "game_start",
	ios: require("../assets/sounds/game_start.mp3"),
	web: require("../assets/sounds/game_start.mp3").default,
});
const game_won = Platform.select({
	android: "game_won",
	ios: require("../assets/sounds/game_won.mp3"),
	web: require("../assets/sounds/game_won.mp3").default,
});
const game_lost = Platform.select({
	android: "game_lost",
	ios: require("../assets/sounds/game_lost.mp3"),
	web: require("../assets/sounds/game_lost.mp3").default,
});
const game_chat = Platform.select({
	android: "game_chat",
	ios: require("../assets/sounds/game_chat.mp3"),
	web: require("../assets/sounds/game_chat.mp3").default,
});
const error_sound = Platform.select({
	android: "error",
	ios: require("../assets/sounds/error.mp3"),
	web: require("../assets/sounds/error.mp3").default,
});
const disconnect = Platform.select({
	android: "disconnect",
	ios: require("../assets/sounds/disconnect.mp3"),
	web: require("../assets/sounds/disconnect.mp3").default,
});
const countdown = Platform.select({
	android: "countdown",
	ios: require("../assets/sounds/countdown.mp3"),
	web: require("../assets/sounds/countdown.mp3").default,
});
const complete = Platform.select({
	android: "complete",
	ios: require("../assets/sounds/complete.mp3"),
	web: require("../assets/sounds/complete.mp3").default,
});
const chat_join_friend = Platform.select({
	android: "chat_join_friend",
	ios: require("../assets/sounds/chat_join_friend.mp3"),
	web: require("../assets/sounds/chat_join_friend.mp3").default,
});
const chat_global = Platform.select({
	android: "chat_global",
	ios: require("../assets/sounds/chat_global.mp3"),
	web: require("../assets/sounds/chat_global.mp3").default,
});
const chat_game = Platform.select({
	android: "chat_game",
	ios: require("../assets/sounds/chat_game.mp3"),
	web: require("../assets/sounds/chat_game.mp3").default,
});
const cards_throw_away = Platform.select({
	android: "cards_throw_away",
	ios: require("../assets/sounds/cards_throw_away.mp3"),
	web: require("../assets/sounds/cards_throw_away.mp3").default,
});
const cards_shuffle = Platform.select({
	android: "cards_shuffle",
	ios: require("../assets/sounds/cards_shuffle.mp3"),
	web: require("../assets/sounds/cards_shuffle.mp3").default,
});
const cards_throw = Platform.select({
	android: "cards_throw",
	ios: require("../assets/sounds/cards_throw.mp3"),
	web: require("../assets/sounds/cards_throw.mp3").default,
});
const cards_deal5 = Platform.select({
	android: "cards_deal5",
	ios: require("../assets/sounds/cards_deal5.mp3"),
	web: require("../assets/sounds/cards_deal5.mp3").default,
});
const cards_deal4 = Platform.select({
	android: "cards_deal4",
	ios: require("../assets/sounds/cards_deal4.mp3"),
	web: require("../assets/sounds/cards_deal4.mp3").default,
});
const anounce_medium = Platform.select({
	android: "anounce_medium",
	ios: require("../assets/sounds/anounce_medium.mp3"),
	web: require("../assets/sounds/anounce_medium.mp3").default,
});
const anounce_low = Platform.select({
	android: "anounce_low",
	ios: require("../assets/sounds/anounce_low.mp3"),
	web: require("../assets/sounds/anounce_low.mp3").default,
});
const score_increase = Platform.select({
	android: "score_increase",
	ios: require("../assets/sounds/score_increase.mp3"),
	web: require("../assets/sounds/score_increase.mp3").default,
});
const anounce_high = Platform.select({
	android: "anounce_high",
	ios: require("../assets/sounds/anounce_high.mp3"),
	web: require("../assets/sounds/anounce_high.mp3").default,
});

const soundError = (error) => {
	if (error) handleError(new Error("ERROR PLAYING SOUND: " + error));
};

export const BUTTON_PRESS_SOUND = new Sound(buttonSound, soundError, "button");
export const BUY_SUCCEEDED_SOUND = {
	cash: new Sound(cashSound, soundError, "cash"),
	gold: new Sound(goldSound, soundError, "gold"),
	silver: new Sound(silverSound, soundError, "silver"),
};
export const SAVE_SOUND = new Sound(saveSound, soundError, "save");

export const mySounds = {
	messageSend: new Sound(msgSend, soundError, "message_send"),
	messageReceived: new Sound(msgReceived, soundError, "message_received"),
	addCreditSound: new Sound(add_credit, soundError, "add_credit"),
	reconnectSound: new Sound(reconnect, soundError, "reconnect"),
	playerLeavesSound: new Sound(player_leaves, soundError, "player_leaves"),
	playerJoinsSound: new Sound(player_joins, soundError, "player_joins"),
	notificationSound: new Sound(notification, soundError, "notification"),
	myTurnInactiveSound: new Sound(my_turn_inactive, soundError, "my_turn_inactive"),
	myTurnSound: new Sound(my_turn, soundError, "my_turn"),
	moveNormalSound: new Sound(move_normal, soundError, "move_normal"),
	moveStartSound: new Sound(move_start, soundError, "move_start"),
	moveSmashSound: new Sound(move_smash, soundError, "move_smash"),
	moveJackSound: new Sound(move_jack, soundError, "move_jack"),
	moveBackwardsSound: new Sound(move_backwards, soundError, "move_backwards"),
	levelUpSound: new Sound(level_up, soundError, "level_up"),
	kickSound: new Sound(kick, soundError, "kick"),
	gameStartSound: new Sound(game_start, soundError, "game_start"),
	gameWonSound: new Sound(game_won, soundError, "game_won"),
	gameLostSound: new Sound(game_lost, soundError, "game_lost"),
	gameChatSound: new Sound(game_chat, soundError, "game_chat"),
	errorSound: new Sound(error_sound, soundError, "error_sound"),
	disconnectSound: new Sound(disconnect, soundError, "disconnect"),
	countdownSound: new Sound(countdown, soundError, "countdown"),
	completeSound: new Sound(complete, soundError, "complete"),
	chatJoinFriendSound: new Sound(chat_join_friend, soundError, "chat_join_friend"),
	chatGlobalSound: new Sound(chat_global, soundError, "chat_global"),
	chatGameSound: new Sound(chat_game, soundError, "chat_game"),
	cardsThrowAwaySound: new Sound(cards_throw_away, soundError, "cards_throw_away"),
	cardsShuffleSound: new Sound(cards_shuffle, soundError, "cards_shuffle"),
	cardsThrowSound: new Sound(cards_throw, soundError, "cards_throw"),
	cardsDeal5Sound: new Sound(cards_deal5, soundError, "cards_deal5"),
	cardsDeal4Sound: new Sound(cards_deal4, soundError, "cards_deal4"),
	announceMediumSound: new Sound(anounce_medium, soundError, "anounce_medium"),
	announceLowSound: new Sound(anounce_low, soundError, "anounce_low"),
	announceHighSound: new Sound(anounce_high, soundError, "anounce_high"),
	scoreIncreaseSound: new Sound(score_increase, soundError, "score_increase"),
};
