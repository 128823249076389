import React from "react";
import { StyleSheet } from "react-native";
import { DoubleClick } from "./DoubleClick";

const TableRow = (props) => {
	const { children, rowId, onPress, onMouseEnter, onMouseLeave, onDoubleTap, onLayout, style } = props;
	return (
		<DoubleClick
			activeOpacity={0.8}
			style={[styles.rowContainerStyle, style]}
			key={rowId}
			singleTap={onPress}
			doubleTap={onDoubleTap}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onLayout={onLayout}
			disabled={props.disabled}
			isPressable={props.isPressable}
		>
			{children}
		</DoubleClick>
	);
};

const styles = StyleSheet.create({
	rowContainerStyle: {
		flexDirection: "row",
		borderBottomWidth: /* Platform.OS === "web" ? 0 : */ 1,
		borderBottomColor: /*  Platform.OS === "web" ? "transparent" : */ "#22C1E4",
	},
});

export { TableRow };
