import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { FlatList, Image, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { AppTouchableOpacity, HeaderText } from "../../components/common";
import { handleError } from "../../components/ErrorHandler";
import GameChatMessages from "../../components/Game/GameChatMessages";
import GameChatSender from "../../components/Game/GameChatSender";
import { appFont, f_sourcesansprosemibold, RESOLUTION } from "../../config/defaults";
import { panelMenuEnabled } from "../../config/permissions";
import { mapObject } from "../../helpers/commonHelpers";
import { selectChatRoomTab } from "../../redux/actions";
import { updateInGameActiveChatRoom } from "../../redux/actions/SharedInputActions";
import { gameChat } from "../config/images";
import KeezChatMessageBox from "./KeezChatMessageBox";
import PlayerPanelMenu from "./PlayerPanelMenu";

class ClassicKeezChat extends Component {
	constructor(props) {
		super(props);
		var _chatRooms = [{ chatRoomID: -1, name: this.props.gameChatName }];
		mapObject(this.props.joinedChatRooms, (k, v) => _chatRooms.push(v));
		_chatRooms.forEach((room) => (room.selected = room.chatRoomID == -1));

		this.state = {
			chatRooms: _chatRooms,
			activeRoom: _chatRooms[0],
			firstViewableIndex: 0,
			lastViewableIndex: 1,
		};

		this.handleViewableItemsChanged = this.onViewableItemsChanged.bind(this);
		this.viewabilityConfig = { itemVisiblePercentThreshold: 90 };
		this.viewPosition = 0;
	}

	//#region lifecycle methods
	componentDidUpdate(prevProps) {
		if (prevProps.gameChatName != this.props.gameChatName) {
			var newRooms = this.state.chatRooms;
			var newActiveRoom = this.state.activeRoom;
			var gameChatRoom = newRooms.chatRooms.find((room) => room.chatRoomID == -1);
			if (gameChatRoom != undefined) {
				newRooms = [...this.state.chatRooms];
				gameChatRoom = { ...gameChatRoom, name: this.props.gameChatName };
			}
			if (this.state.activeRoom != null && this.state.activeRoom.chatRoomID == -1) {
				newActiveRoom = {
					...this.state.activeRoom,
					name: this.props.gameChatName,
				};
			}
			this.setState({ chatRooms: newRooms, activeRoom: newActiveRoom });
			if (newActiveRoom != null && typeof newActiveRoom.chatRoomID !== "undefined") {
				this.props.updateInGameActiveChatRoom(newActiveRoom.chatRoomID);
			}
		}
	}
	//#endregion

	//#region events
	onChatTabPress(roomID) {
		try {
			if (typeof roomID === "undefined") return false;
			var chRooms = cloneDeep(this.state.chatRooms);
			var _roomIndex = chRooms.findIndex((r) => r.chatRoomID == roomID);
			if (_roomIndex == -1) return false;
			var _room = chRooms[_roomIndex];
			if (this.state.activeRoom === null || _room.chatRoomID !== this.state.activeRoom.chatRoomID) {
				chRooms.forEach((room) => (room.selected = false));
				_room.selected = true;
				this.setState({ chatRooms: chRooms, activeRoom: _room });
				if (_room.chatRoomID != -1) this.props.selectChatRoomTab(_room);
				if (_room != null && typeof _room.chatRoomID !== "undefined") {
					this.props.updateInGameActiveChatRoom(_room.chatRoomID);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onViewableItemsChanged = ({ viewableItems, changed }) => {
		try {
			if (viewableItems.length > 0) {
				this.setState({
					firstViewableIndex: viewableItems[0].index,
					lastViewableIndex: viewableItems[viewableItems.length - 1].index,
				});
			}
		} catch (error) {
			handleError(error);
		}
	};

	onPreviousPress() {
		try {
			const nextIndex = this.state.firstViewableIndex - 1;
			if (nextIndex >= 0) {
				this.refs.chatTabsList.scrollToIndex({
					index: nextIndex,
					viewPosition: this.viewPosition,
				});
			} else {
				this.refs.chatTabsList.scrollToIndex({
					index: this.state.firstViewableIndex,
					viewPosition: this.viewPosition,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onNextPress() {
		try {
			const nextIndex = this.state.lastViewableIndex + 1;
			if (nextIndex < this.state.chatRooms.length) {
				this.refs.chatTabsList.scrollToIndex({
					index: nextIndex,
					viewPosition: this.viewPosition,
				});
			} else {
				this.refs.chatTabsList.scrollToIndex({
					index: this.state.lastViewableIndex,
					viewPosition: this.viewPosition,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onScrollToIndexFailed(error) {
		if (error != undefined) {
			this.refs.chatTabsList.scrollToOffset({
				offset: error.averageItemLength * error.index,
				animated: false,
			});
			setTimeout(() => {
				if (this.state.chatRooms.length !== 0 && this.refs.chatTabsList !== null) {
					this.refs.chatTabsList.scrollToIndex({
						index: error.index,
						viewPosition: this.viewPosition,
						animated: false,
					});
				}
			}, 50);
		}
	}
	//#endregion

	//#region render methods
	renderItemBack(isSelected, id) {
		const { activeTab, inactiveTab } = gameChat.tabArea.normal;
		const border = isSelected ? activeTab.border : inactiveTab.border;
		const corner = isSelected ? activeTab.corner : inactiveTab.corner;
		const center = isSelected ? activeTab.center : inactiveTab.center;
		return (
			<View key={"back_" + id} style={[styles.itemBackContainer, { width: "100%", height: isSelected ? 26 : 25 }]}>
				<Image
					key="tabLeftCorner"
					source={corner.left}
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: 8,
						height: 8,
						position: "absolute",
						top: 0,
						left: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabTopBorder"
					source={border.top}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: "91%",
						height: 8,
						position: "absolute",
						top: 0,
						left: 4,
						zIndex: -2,
					}}
				/>
				<Image
					key="tabRightCorner"
					source={corner.right}
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: 8,
						height: 9,
						position: "absolute",
						top: 0,
						right: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabLeftBorder1"
					source={border.left}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: 8,
						height: isSelected ? 10 : 9,
						position: "absolute",
						bottom: isSelected ? 0 : 0,
						left: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabLeftBorder2"
					source={border.left}
					resizeMode={Platform.OS !== "web" || !isSelected ? "stretch" : "repeat"}
					style={{
						width: 8,
						height: isSelected ? 10 : 11,
						position: "absolute",
						bottom: isSelected ? 8 : 7,
						left: 0,
						zIndex: -3,
					}}
				/>
				<Image
					key="tabCenter1"
					source={center}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: "91%",
						height: isSelected ? 10 : 9,
						position: "absolute",
						bottom: isSelected ? -1 : 0,
						left: 4,
						zIndex: -1,
					}}
				/>
				<Image
					key="tabCenter2"
					source={center}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: "91%",
						height: isSelected ? 10 : 11,
						position: "absolute",
						bottom: isSelected ? 9 : 6,
						left: 4,
						zIndex: -3,
					}}
				/>
				<Image
					key="tabRightBorder1"
					source={border.right}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: 8,
						height: isSelected ? 10 : 9,
						position: "absolute",
						bottom: isSelected ? 0 : 0,
						right: 0,
						zIndex: -2,
					}}
				/>
				<Image
					key="tabRightBorder2"
					source={border.right}
					resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
					style={{
						width: 8,
						height: isSelected ? 10 : 10,
						position: "absolute",
						bottom: isSelected ? 8 : 7,
						right: 0,
						zIndex: -3,
					}}
				/>
			</View>
		);
	}

	renderItems(room, index) {
		const content = room.name ? room.name.toString() : " ";
		return (
			<AppTouchableOpacity
				activeOpacity={1}
				key={"room_" + room.chatRoomID}
				style={[styles.chatTabButton, { width: null, marginLeft: index == 0 ? 0 : -1.5 }]}
				onPress={() => this.onChatTabPress(room.chatRoomID)}
				silentPress={true}
			>
				{this.renderItemBack(room.selected, room.chatRoomID)}
				<HeaderText
					style={{ width: undefined }}
					textContent={content}
					textContentStyle={{
						paddingLeft: 9,
						paddingRight: 9,
						fontFamily: f_sourcesansprosemibold,
						fontSize: 12,
						color: room.selected ? "#a17d4d" : "#fcfbfa",
						textShadowColor: room.selected ? "transparent" : "#a17d4d",
						textShadowOffset: { width: 2, height: 2 },
						textShadowRadius: Platform.OS === "web" ? 2 : 4,
					}}
				/>
			</AppTouchableOpacity>
		);
	}

	renderPreviousBack() {
		try {
			const { border, corner, center } = gameChat.tabArea.normal.previous;
			return (
				<View key="prevBack" style={[styles.toggleButtonBack, { left: -2 }]}>
					<Image
						key="topLeftCorner"
						source={corner.top_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="topBorder"
						source={border.top}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 8,
							position: "absolute",
							top: 0,
							left: 7,
							zIndex: -2,
						}}
					/>
					<Image
						key="topRightCorner"
						source={corner.top_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							right: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="leftBorder"
						source={border.left}
						resizeMode={Platform.OS !== "web" ? "stretch" : "repeat"}
						style={{
							width: 8,
							height: 12,
							position: "absolute",
							top: 7,
							left: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="center"
						source={center}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 12,
							position: "absolute",
							top: 7,
							left: 7,
							zIndex: -3,
						}}
					/>
					<Image
						key="rightBorder"
						source={border.right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 12,
							position: "absolute",
							top: 7,
							right: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="bottomLeftCorner"
						source={corner.bottom_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							bottom: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="bottomBorder"
						source={border.bottom}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 8,
							position: "absolute",
							bottom: 0,
							left: 7,
							zIndex: -1,
						}}
					/>
					<Image
						key="bottomRightCorner"
						source={corner.bottom_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: -2,
						}}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderPreviousButton() {
		try {
			const { arrow } = gameChat.tabArea.normal.previous;
			return (
				<AppTouchableOpacity
					activeOpacity={0.7}
					disabled={false}
					style={styles.toggleButton}
					onPress={this.onPreviousPress.bind(this)}
					silentPress={true}
					accessibilityLabel={"scroll to previous chatroom"}
				>
					{this.renderPreviousBack()}
					<Image
						source={arrow}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={styles.toggleButtonImage}
					/>
				</AppTouchableOpacity>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderNextBack() {
		try {
			const { border, corner, center } = gameChat.tabArea.normal.next;
			return (
				<View key="nextBack" style={[styles.toggleButtonBack, { right: -2 }]}>
					<Image
						key="topLeftCorner"
						source={corner.top_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="topBorder"
						source={border.top}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 8,
							position: "absolute",
							top: 0,
							left: 7,
							zIndex: -2,
						}}
					/>
					<Image
						key="topRightCorner"
						source={corner.top_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							right: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="leftBorder"
						source={border.left}
						resizeMode={Platform.OS !== "web" ? "stretch" : "repeat"}
						style={{
							width: 8,
							height: 12,
							position: "absolute",
							top: 7,
							left: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="center"
						source={center}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 12,
							position: "absolute",
							top: 7,
							left: 7,
							zIndex: -3,
						}}
					/>
					<Image
						key="rightBorder"
						source={border.right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 12,
							position: "absolute",
							top: 7,
							right: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="bottomLeftCorner"
						source={corner.bottom_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							bottom: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="bottomBorder"
						source={border.bottom}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 16,
							height: 8,
							position: "absolute",
							bottom: 0,
							left: 7,
							zIndex: -1,
						}}
					/>
					<Image
						key="bottomRightCorner"
						source={corner.bottom_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: -2,
						}}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderNextButton() {
		try {
			const { arrow } = gameChat.tabArea.normal.next;
			return (
				<AppTouchableOpacity
					activeOpacity={0.7}
					disabled={false}
					style={styles.toggleButton}
					onPress={this.onNextPress.bind(this)}
					silentPress={true}
					accessibilityLabel={"scroll to next chatroom"}
				>
					{this.renderNextBack()}
					<Image
						source={arrow}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={styles.toggleButtonImage}
					/>
				</AppTouchableOpacity>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderChatTabs() {
		try {
			return (
				<View key="chatTabsContainer" style={styles.chatTabsContainer}>
					{this.renderPreviousButton()}
					<FlatList
						viewabilityConfig={this.viewabilityConfig}
						onViewableItemsChanged={this.handleViewableItemsChanged}
						ref={"chatTabsList"}
						horizontal={true}
						directionalLockEnabled={true}
						showsHorizontalScrollIndicator={false}
						nestedScrollEnabled={true}
						keyExtractor={(item) => "room_" + item.chatRoomID.toString()}
						data={this.state.chatRooms}
						extraData={this.state.chatRooms}
						renderItem={({ item, index }) => this.renderItems(item, index)}
						onScrollToIndexFailed={(error) => this.onScrollToIndexFailed(error)}
						style={{
							flex: 1,
							height: "100%",
							paddingLeft: 2,
							top: -1,
						}}
						contentContainerStyle={{ paddingRight: 3 }}
					/>
					{this.renderNextButton()}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderLowResTabBack(width) {
		try {
			const { border, corner, center } = gameChat.tabArea.low;
			const _width = width + 2;
			return (
				<View
					key="lowResTabBack"
					style={[styles.chatTabsBackContainer, { width: typeof _width === "number" ? _width : 330 }]}
				>
					<Image
						key="tabLeftCorner"
						source={corner.top_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="tabTopBorder"
						source={border.top}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: typeof _width === "number" ? _width - 14 : 316,
							height: 7,
							position: "absolute",
							top: 0,
							left: 7,
							zIndex: -2,
						}}
					/>
					<Image
						key="tabRightCorner"
						source={corner.top_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: 8,
							height: 6,
							position: "absolute",
							top: 0,
							right: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="tabMiddleLeftBorder"
						source={border.left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 16,
							position: "absolute",
							top: 6,
							left: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="tabMiddleRightBorder"
						source={border.right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 6,
							height: 17,
							position: "absolute",
							top: 6,
							right: 0,
							zIndex: -4,
						}}
					/>
					<Image
						key="tabMiddle"
						source={center}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: typeof _width === "number" ? _width - 9 : 319,
							height: 16,
							position: "absolute",
							top: 6,
							left: 6,
							zIndex: -3,
						}}
					/>
					<Image
						key="tabLeftBorder"
						source={corner.bottom_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 7,
							height: 6,
							position: "absolute",
							bottom: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="tabRightBorder"
						source={corner.bottom_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 6,
							height: Platform.OS === "web" ? 5 : 6,
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: -2,
						}}
					/>
					<Image
						key="tabCenter"
						source={border.bottom}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: typeof _width === "number" ? _width - 11 : 319,
							height: 6,
							position: "absolute",
							bottom: 0,
							left: 6,
							zIndex: -1,
						}}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderHighResTabBack(width) {
		try {
			const { border, corner, center } = gameChat.tabArea.normal.back;
			const _width = width + 2;
			return (
				<View
					key="highResTabBack"
					style={[styles.chatTabsBackContainer, { width: typeof _width === "number" ? _width : 330 }]}
				>
					<Image
						key="tabLeftCorner"
						source={corner.top_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: 8,
							height: 8,
							position: "absolute",
							top: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="tabTopBorder"
						source={border.top}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: typeof _width === "number" ? _width - 14 : 316,
							height: 7,
							position: "absolute",
							top: 0,
							left: 7,
							zIndex: -2,
						}}
					/>
					<Image
						key="tabRightCorner"
						source={corner.top_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
						style={{
							width: 8,
							height: 6,
							position: "absolute",
							top: 0,
							right: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="tabMiddleLeftBorder"
						source={border.left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 8,
							height: 16,
							position: "absolute",
							top: 6,
							left: 0,
							zIndex: -3,
						}}
					/>
					<Image
						key="tabMiddleRightBorder"
						source={border.right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 6,
							height: 17,
							position: "absolute",
							top: 6,
							right: 0,
							zIndex: -4,
						}}
					/>
					<Image
						key="tabMiddle"
						source={center}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: typeof _width === "number" ? _width - 9 : 319,
							height: 16,
							position: "absolute",
							top: 6,
							left: 6,
							zIndex: -3,
						}}
					/>
					<Image
						key="tabLeftBorder"
						source={corner.bottom_left}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 7,
							height: 6,
							position: "absolute",
							bottom: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="tabRightBorder"
						source={corner.bottom_right}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: 6,
							height: Platform.OS === "web" ? 5 : 6,
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: -2,
						}}
					/>
					<Image
						key="tabCenter"
						source={border.bottom}
						resizeMode={Platform.OS === "web" ? "repeat" : "stretch"}
						style={{
							width: typeof _width === "number" ? _width - 11 : 319,
							height: 6,
							position: "absolute",
							bottom: 0,
							left: 6,
							zIndex: -1,
						}}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderChatTop() {
		try {
			const _width = this.props.width;
			return (
				<View style={[styles.chatTopContainer, { width: typeof _width === "number" ? _width : 330 }]}>
					{this.props.resolution != RESOLUTION.LOW && this.renderHighResTabBack(_width)}
					{this.props.resolution != RESOLUTION.LOW && this.renderChatTabs()}
					{this.props.resolution == RESOLUTION.LOW && this.renderLowResTabBack(_width)}
					{this.props.resolution == RESOLUTION.LOW && (
						<HeaderText
							style={{
								width: "100%",
								height: "85%",
								justifyContent: "center",
							}}
							textContent="CHAT"
							textContentStyle={{
								fontFamily: appFont,
								fontSize: 14,
								color: "#fcfbfa",
								textShadowColor: "#a17d4d",
								textShadowOffset: { width: 2, height: 2 },
								textShadowRadius: 2,
								paddingTop: 2,
							}}
						/>
					)}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	render() {
		const { top, left, width, height } = this.props;
		const _height = typeof this.props.height === "number" ? this.props.height + 6 : 206;
		const containerHeight =
			Platform.OS === "web"
				? this.props.resolution == RESOLUTION.LOW
					? _height - 27 - 40
					: _height - 29 - 40
				: _height - 40;
		const _scale = typeof this.props.scale === "number" ? this.props.scale : 1;
		return (
			<View style={[styles.mainContainer, { top, left, transform: [{ scale: _scale }], width, height }]}>
				{this.renderChatTop()}
				<KeezChatMessageBox width={this.props.width} height={this.props.height} gameTheme={this.props.gameTheme} />
				<GameChatSender width={this.props.width} activeRoom={this.state.activeRoom} />
				<GameChatMessages
					containerHeight={containerHeight}
					activeRoomID={this.state.activeRoom != undefined ? this.state.activeRoom.chatRoomID : undefined}
				/>
				{panelMenuEnabled && <PlayerPanelMenu containerHeight={containerHeight} />}
			</View>
		);
	}
	//#endregion
}
const styles = StyleSheet.create({
	mainContainer: {
		position: "absolute",
		top: 0,
		left: 0,
		height: 170,
		width: 330,
		backgroundColor: "transparent",
		zIndex: 1,
	},
	chatTopContainer: {
		width: 330,
		position: "absolute",
		height: 26,
		zIndex: 1,
	},
	chatTabsBackContainer: {
		width: 330,
		position: "absolute",
		top: -2,
		left: -1,
		height: 26,
		zIndex: -1,
	},
	chatTabsContainer: {
		width: "100%",
		height: "100%",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "flex-end",
		top: -1,
	},
	chatTabButton: {
		justifyContent: "center",
		alignItems: "center",
	},
	toggleButton: {
		width: 25,
		height: 26,
		top: -1,
		justifyContent: "center",
	},
	toggleButtonImage: { width: 9, height: 12, alignSelf: "center" },
	toggleButtonBack: {
		position: "absolute",
		top: 0,
		zIndex: 0,
		width: 30,
		height: 25,
	},
	itemBackContainer: {
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: -1,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		gameID: state.startedGame.gameID,
		gameChatName: state.startedGame.gameChatName,
		pageTopLeftMargin: state.views.pageTopLeftMargin,
		joinedChatRooms: state.globalChat.joinedChatRooms,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = { selectChatRoomTab, updateInGameActiveChatRoom };

export default connect(mapStateToProps, mapDispatchToProps)(ClassicKeezChat);
