import Modal from "modal-enhanced-react-native-web";
import React, { Component } from "react";
import { Dimensions, Image, Platform, StyleSheet, Text, View } from "react-native";
import RNExitApp from "react-native-exit-app";
import ReactNativeModal from "react-native-modal";
import ParsedText from "react-native-parsed-text";
import { connect } from "react-redux";
import { appFont, modalType, RESOLUTION, tabType } from "../config/defaults";
import { MAINTENANCE_ICON, SMALL_BUTTON } from "../config/images";
import { MyWebSocket } from "../connection";
import NetworkUtils from "../connection/NetworkUtils";
import {
	closeModalDialog,
	connectToNextURL,
	// connectToWebsocket,
	disableReconnect,
	openTab,
	startReconnect,
} from "../redux/actions";
import { Box, GameButton, NavigationHeader } from "./common";
import { handleError } from "./ErrorHandler";

class CustomModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPosButtonHovered: false,
			isNegButtonHovered: false,
			isReconnectButtonVisible: false, // this.props.connectionTriesCounter == 0,
			mainTextLink: "",
			extraTextLink: "",
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		if (this.props.linkInText) {
			const { main_text, extra_text } = this.props.message;
			var main_text_i = main_text.indexOf("_");
			var main_text_j = main_text.lastIndexOf("_");
			var extra_text_i = extra_text.indexOf("_");
			var extra_text_j = extra_text.lastIndexOf("_");
			if (main_text_i != -1 && main_text_j != -1) var linkMainText = main_text.substring(main_text_i, ++main_text_j);
			if (extra_text_i != -1 && extra_text_j != -1)
				var linkExtraText = extra_text.substring(extra_text_i, ++extra_text_j);

			const regex = /_/gi;
			if (linkMainText != undefined) this.setState({ mainTextLink: linkMainText.replace(regex, "") });

			if (linkExtraText != undefined) this.setState({ extraTextLink: linkExtraText.replace(regex, "") });
		}
	}

	componentDidUpdate(prevProps) {
		// if (
		//   prevProps.socketConnected == true &&
		//   this.props.socketConnected == false
		// ) {
		//   this.setState({ isReconnectButtonVisible: false });
		//   // this.onReconnectPress();
		//   // this.onPosButtonPress();
		// }
		// if (
		//   prevProps.connectionTriesCounter != this.props.connectionTriesCounter &&
		//   this.props.connectionTriesCounter != 0
		// ) {
		//   this.setState({ isReconnectButtonVisible: false });
		// }
	}
	//#endregion

	//#region events
	onReconnectPress() {
		this.props.disableReconnect();
		setTimeout(() => {
			MyWebSocket.shared.connectToNextURL();
			// this.props.startReconnect();
			// if (this.props.websocket !== null) this.props.websocket.close();
			// this.props.connectToNextURL();
		}, 1000);
	}

	onPosButtonPress() {
		const { posButAction, closeModalDialog } = this.props;
		if (!this.props.isUpgradable) closeModalDialog();
		setTimeout(() => {
			if (typeof posButAction == "function") posButAction();
		}, 500);
	}

	onNegButtonPress() {
		const { negButAction, closeModalDialog } = this.props;
		closeModalDialog();
		setTimeout(() => {
			if (typeof negButAction == "function") negButAction();
		}, 500);
	}

	onLinkPress() {
		try {
			if (!this.props.isFullscreenLink) return false;

			if (!this.props.isFullscreenLink) {
				if (!this.props.isShopOpen) {
					var linkID = "dialogAnchor_";
					this.props.openTab(tabType.SHOP, "directLink", linkID);
				}
				this.props.closeModalDialog();
			} else {
				//TODO: go to fullscreen on web Platform
				return;
			}
		} catch (error) {
			handleError(error);
		}
	}

	async closeApp() {
		try {
			if (MyWebSocket.shared.ws && MyWebSocket.shared.ws.readyState == 1) {
				//send exit event
				var _isInternetReachable = await NetworkUtils.isInternetReachable(),
					_isConnected = await NetworkUtils.isNetworkAvailable(),
					_connectionType = await NetworkUtils.getConnectionType();
				var disconnectMsg = {
					sMessageID: 0,
					type: "exit",
					reason: "EXIT_APP",
					debugData: {
						isInternetReachable: _isInternetReachable,
						isConnected: _isConnected,
						connectionType: _connectionType,
						socketData: MyWebSocket.shared.ws,
					},
				};
				MyWebSocket.shared.sendMsg(disconnectMsg);
				setTimeout(() => {
					MyWebSocket.shared.ws.close();
					RNExitApp.exitApp();
				}, 250);
			} else {
				RNExitApp.exitApp();
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={false}
				backAction={() => {}}
				closeAction={this.closeApp.bind(this)}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.quit}
				resolution={this.props.resolution}
			/>
		);
	}

	renderReconnectExtra() {
		if (!this.props.isConnectionError) return null;
		return (
			<View style={[styles.extraText, styles.reconnectExtra]}>
				<Text allowFontScaling={false} style={[styles.fontFamily, styles.reconnectText]}>
					{this.props.lang.reconnect_disabled || ""}
				</Text>
			</View>
		);
	}

	renderReconnectMain() {
		if (this.props.resolution === RESOLUTION.HIGH) {
			styles.reconnectText = [styles.reconnectText, { fontSize: 30 }];
		}
		if (this.props.isMaintenance) styles.reconnectText = [styles.reconnectText, styles.maintenanceText];
		return (
			<Text allowFontScaling={false} style={[styles.fontFamily, styles.reconnectText]}>
				{this.props.message.main_text}
			</Text>
		);
	}

	renderExtraText() {
		if (this.props.resolution === RESOLUTION.HIGH) {
			styles.linkText = [styles.linkText, { fontSize: 26 }];
		}
		if (this.state.extraTextLink != "") {
			return (
				<ParsedText
					style={[styles.extraText, styles.fontFamily, styles.linkText]}
					parse={[
						{
							pattern: new RegExp("_" + this.state.extraTextLink + "_"),
							style: [styles.fontFamily, styles.linkText, styles.linkDecor],
							onPress: this.onLinkPress.bind(this),
							renderText: () => this.state.extraTextLink,
						},
					]}
				>
					{this.props.message.extra_text}
				</ParsedText>
			);
		}
		return (
			<View style={styles.extraText}>
				<Text allowFontScaling={false} style={[styles.fontFamily, styles.linkText]}>
					{this.props.message.extra_text || ""}
				</Text>
			</View>
		);
	}

	renderMainText() {
		if (this.props.resolution === RESOLUTION.HIGH) {
			styles.messageStyle = [styles.messageStyle, { fontSize: 30 }];
		}
		if (this.state.mainTextLink != "") {
			return (
				<ParsedText
					style={[styles.fontFamily, styles.messageStyle]}
					parse={[
						{
							pattern: new RegExp("_" + this.state.mainTextLink + "_"),
							style: [styles.fontFamily, styles.messageStyle, styles.linkDecor],
							onPress: this.onLinkPress.bind(this),
							renderText: () => this.state.mainTextLink,
						},
					]}
				>
					{this.props.message.main_text}
				</ParsedText>
			);
		}
		return (
			<Text allowFontScaling={false} style={[styles.fontFamily, styles.messageStyle]}>
				{this.props.message.main_text}
			</Text>
		);
	}

	renderPosButton() {
		const { posButText, modType, enableReconnect } = this.props;
		if (typeof posButText !== "string" || posButText == "") return null;
		const onPressFunc = modType === modalType.RECONNECT ? () => this.onReconnectPress() : () => this.onPosButtonPress();
		const _disabled =
			modType === modalType.RECONNECT
				? enableReconnect != true //|| isConnectionError == true
				: false;
		var _buttonTextStyle = {},
			_buttonStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			_buttonTextStyle = {
				fontSize: 28,
				paddingTop: Platform.OS === "ios" ? 26 : 20,
			};
			_buttonStyle = { width: 150, height: 75 };
		}
		return (
			<GameButton
				disabled={_disabled}
				textContent={posButText}
				textContentStyle={[styles.fontFamily, styles.buttonTextStyle, styles.textOutline, _buttonTextStyle]}
				onPress={onPressFunc}
				onMouseEnter={() => {
					if (!_disabled) this.setState({ isPosButtonHovered: true });
				}}
				onMouseLeave={() => {
					if (!_disabled) this.setState({ isPosButtonHovered: false });
				}}
				myStyle={[styles.buttonStyle, { opacity: _disabled ? 0.5 : 1 }, _buttonStyle]}
				backgroundImage={{
					uri: SMALL_BUTTON,
					resizeMode: "stretch",
					style: [
						styles.buttonBackImgStyle,
						{
							opacity: _disabled ? 0.5 : this.state.isPosButtonHovered ? 0.8 : 1,
						},
						_buttonStyle,
					],
				}}
			/>
		);
	}

	renderNegButton() {
		const { negButText } = this.props;
		if (typeof negButText !== "string" || negButText == "") return null;
		var _buttonTextStyle = {},
			_buttonStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			_buttonTextStyle = {
				fontSize: 28,
				paddingTop: Platform.OS === "ios" ? 26 : 20,
			};
			_buttonStyle = { width: 150, height: 75 };
		}
		return (
			<GameButton
				textContent={negButText}
				textContentStyle={[styles.fontFamily, styles.buttonTextStyle, styles.textOutline, _buttonTextStyle]}
				onPress={this.onNegButtonPress.bind(this)}
				onMouseEnter={() => this.setState({ isNegButtonHovered: true })}
				onMouseLeave={() => this.setState({ isNegButtonHovered: false })}
				myStyle={[styles.buttonStyle, _buttonStyle]}
				backgroundImage={{
					uri: SMALL_BUTTON,
					resizeMode: "stretch",
					style: [styles.buttonBackImgStyle, { opacity: this.state.isNegButtonHovered ? 0.8 : 1 }, _buttonStyle],
				}}
			/>
		);
	}

	renderButton() {
		const { negButText } = this.props;
		var butContStyle = [styles.buttonContainerStyle];
		if (negButText == "") butContStyle = [styles.buttonContainerStyle, { justifyContent: "center" }];
		return (
			<View style={butContStyle}>
				{this.renderPosButton()}
				{this.renderNegButton()}
			</View>
		);
	}

	renderModalContent() {
		var _boxStyle =
			Platform.OS === "web" && !this.props.isSmallResolution
				? [styles.boxStyle, { minWidth: 400 }]
				: [styles.boxStyle, { width: "100%" }];
		if (this.props.modType === modalType.RECONNECT) {
			if (this.props.isMaintenance) _boxStyle = [_boxStyle, styles.maintenanceBox];
			return (
				<Box style={_boxStyle}>
					{this.props.isMaintenance && (
						<Image source={MAINTENANCE_ICON} style={{ marginRight: 10, width: 80, height: 80 }} />
					)}
					{this.renderReconnectMain()}
					{this.state.isReconnectButtonVisible && !this.props.socketConnected && this.renderButton()}
					{/* {this.renderReconnectExtra()} */}
				</Box>
			);
		}
		return (
			<Box style={_boxStyle}>
				{this.renderMainText()}
				{this.renderExtraText()}
				{this.renderButton()}
			</Box>
		);
	}

	renderModal() {
		const dimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		const deviceWidth = dimensions.width;
		const deviceHeight = dimensions.height;
		var accLabel = "popup";
		switch (this.props.modType) {
			case modalType.DIALOG:
				accLabel = "Dialog Popup";
				break;
			case modalType.ERROR:
				accLabel = "ERROR Popup";
				break;
			case modalType.RECONNECT:
				accLabel = "Reconnecting Popup";
				break;
		}
		if (Platform.OS === "web") {
			return (
				<Modal
					animationIn={"zoomIn"}
					animationInTiming={500}
					animationOut={"zoomOut"}
					animationOutTiming={500}
					isVisible={this.props.visibleModal}
					deviceHeight={deviceHeight}
					deviceWidth={deviceWidth}
					accessibilityLabel={accLabel}
				>
					{this.renderModalContent()}
				</Modal>
			);
		}
		return (
			<ReactNativeModal
				animationIn={"zoomIn"}
				animationInTiming={500}
				animationOut={"zoomOut"}
				animationOutTiming={500}
				coverScreen={true}
				isVisible={this.props.visibleModal}
				deviceHeight={deviceHeight}
				deviceWidth={deviceWidth}
				useNativeDriver={true}
				hasBackdrop={false}
				accessibilityLabel={accLabel}
				style={{ marginTop: 0, marginBottom: 0, marginRight: 3 }}
			>
				{this.renderModalContent()}
				{Platform.OS !== "web" &&
					(this.props.modType === modalType.RECONNECT || this.props.isUpgradable) &&
					this.renderHeader()}
			</ReactNativeModal>
		);
	}

	render() {
		if (this.props.message == "") return null;

		return (
			<View
				style={{
					width: "100%",
					height: "100%",
					position: "absolute",
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					backgroundColor: "rgba(0,0,0,0.7)",
				}}
			>
				{this.renderModal()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	boxStyle: {
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 50,
		paddingRight: 50,
		minHeight: 50,
		alignSelf: "center",
		alignItems: "center",
		borderWidth: 2,
		borderColor: "red",
	},
	fontFamily: { fontFamily: appFont },
	messageStyle: { textAlign: "center", fontSize: 18, color: "#484a49" },
	buttonContainerStyle: {
		width: "100%",
		flexDirection: "row",
		marginTop: 20,
		justifyContent: "space-between",
	},
	buttonStyle: {
		width: 100,
		height: 50,
		backgroundColor: "transparent",
		borderWidth: 0,
	},
	buttonBackImgStyle: { width: 100, height: 50 },
	buttonTextStyle: {
		width: "100%",
		fontSize: 16,
		textAlign: "center",
		paddingTop: Platform.OS === "ios" ? 19 : 15,
		paddingBottom: 15,
		color: "#FFFFFF",
		textShadowColor: "#397423",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: Platform.OS !== "web" ? 0.001 : 0,
	},
	textOutline: { position: "absolute", zIndex: 0 },
	maintenanceBox: {
		flexDirection: "row",
		justifyContent: "center",
		paddingLeft: 20,
		paddingRight: 20,
	},
	maintenanceText: { flex: 1, paddingHorizontal: 0 },
	extraText: { marginTop: 10 },
	reconnectText: {
		color: "#000",
		fontSize: 20,
		textAlign: "center",
		lineHeight: 40,
	},
	reconnectExtra: { marginTop: 20, marginBottom: 20, color: "#555" },
	linkButton: { backgroundColor: "transparent", borderWidth: 0 },
	linkText: { fontSize: 14, color: "#696C6B" },
	linkDecor: {
		textDecorationLine: "underline",
		textDecorationColor: "rgb(0,0,238)",
		color: "rgb(85,26,139)",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isShopOpen: state.tab.isShopOpen,
		visibleModal: state.modal.visibleModal,
		modType: state.modal.modType,
		message: state.modal.message,
		isConnectionError: state.modal.isConnectionError,
		socketConnected: state.socket.socketConnected,
		// connectionTriesCounter: state.socket.connectionTriesCounter,
		enableReconnect: state.modal.enableReconnect,
		posButText: state.modal.posButText,
		posButAction: state.modal.posButAction,
		negButText: state.modal.negButText,
		negButAction: state.modal.negButAction,
		linkInText: state.modal.linkInText,
		isFullscreenLink: state.modal.isFullscreenLink,
		isMaintenance: state.modal.isMaintenance,
		isSmallResolution: state.views.isSmallResolution,
		websocket: state.socket.websocket,
		resolution: state.currentUser.preferences.resolution,
		isUpgradable: state.app.isUpgradable,
	};
};

const mapDispatchToProps = {
	closeModalDialog,
	connectToNextURL,
	// connectToWebsocket,
	disableReconnect,
	openTab,
	startReconnect,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomModal);
