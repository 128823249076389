import React, { Component } from "react";
import { FlatList, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { isTablet, isWebOrTablet } from "../../config/defaults";
import { HEADER_CHIP_ICON_RED, HEADER_CHIP_ICON_DISABLED } from "../../config/images";
import { MyWebSocket } from "../../connection";
import { deselectTournament, openTournamentPayout, selectTournament } from "../../redux/actions";
import { AppTouchableOpacity, TableRow, TableRowCell } from "../common";
import { handleError } from "../ErrorHandler";

class TournamentListTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			maxVisibleRows: 1,
			tableWidth: 0,
		};
		this.columns = ["buyIn", "tournamentName", "teamCount", "free/total"];
	}

	//#region events
	tableRendered(event) {
		try {
			const tableHeight = event.nativeEvent.layout.height;
			const maxVisibleRows = isWebOrTablet ? Math.floor(tableHeight / 31) : Math.floor(tableHeight / 20);
			this.setState({
				maxVisibleRows,
				tableWidth: event.nativeEvent.layout.width,
			});
		} catch (error) {
			handleError(error);
		}
	}

	onRowSelect(tourID) {
		try {
			if (this.props.selectedTournamentID.toString() != tourID) {
				this.props.selectTournament(tourID);
				let msg = {
					sMessageID: 0,
					type: "getTournamentDetails",
					tournamentID: tourID,
				};
				MyWebSocket.shared.sendMsg(msg);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onRowDoubleTap(tourID) {
		try {
			this.onRowSelect(tourID);
			this.props.openTournamentPayout();
		} catch (err) {
			handleError(err);
		}
	}

	onTableBackPress() {
		this.props.deselectTournament();
	}
	//#endregion

	//#region render methods
	renderRow(item, index) {
		const rowId = "row_" + item.tournamentID;
		let cells = [];
		let _isSelected = item.tournamentID == this.props.selectedTournamentID;

		this.columns.forEach((key) => {
			let cellId = key + "_" + item.tournamentID;
			let cellKey = "cell_" + cellId;

			var _chipURL,
				_imgDim,
				_content,
				_cellWidth = "100%",
				_textStyle = styles.cellTextStyle,
				_cellContainerStyle = { height: 40 };
			switch (key) {
				case "buyIn":
					if (item.chipsRequired <= 0) _content = this.props.lang.tournamentList.freeBuyIn;
					_chipURL = item.chipsRequired <= 0 ? HEADER_CHIP_ICON_DISABLED : HEADER_CHIP_ICON_RED;
					_imgDim = { width: 30, height: 30, marginTop: 8 };
					_cellWidth = Platform.OS === "web" ? 50 : 30;
					_textStyle = [styles.cellTextStyle, index == 0 && styles.firstRowFirstItem];
					_cellContainerStyle = [{ height: 40 }, index == 0 && styles.firstRowFirstItem];
					break;
				case "tournamentName":
					_content = item.name;
					_cellContainerStyle = { height: 40, flex: 1 };
					break;
				case "teamCount":
					_content = item.chipsRequired;
					_cellWidth = Platform.OS === "web" ? 100 : 40;
					_textStyle = [styles.cellTextStyle, { textAlign: "right", paddingRight: 5 }];
					break;
				case "free/total":
					_cellWidth = Platform.OS === "web" ? 120 : 95;
					_content =
						(item.maxPlayerCnt - item.registeredPlayerCnt).toLocaleString() +
						" / " +
						item.maxPlayerCnt.toLocaleString();
					_textStyle = [styles.cellTextStyle, index == 0 && styles.firstRowLastItem];
					_cellContainerStyle = [{ height: 40, borderRightWidth: 0 }, index == 0 && styles.firstRowLastItem];
					break;
			}
			cells.push(
				<TableRowCell
					lang={this.props.lang}
					isSelected={_isSelected}
					backgroundColor={"transparent"}
					selectedBackgroundColor={"#F6A022"}
					style={_textStyle}
					cellWidth={_cellWidth}
					key={cellKey}
					cellId={cellId}
					content={_content}
					cellType={key == "buyIn" ? "img" : "text"}
					imgURL={_chipURL}
					imgDim={_imgDim}
					cellContainerStyle={_cellContainerStyle}
				/>
			);
		});

		const rowSelectFunc = () => this.onRowSelect(item.tournamentID);
		const rowDoubleTapFunc = () => this.onRowDoubleTap(item.tournamentID);
		return (
			<TableRow
				rowId={rowId}
				onPress={rowSelectFunc}
				onDoubleTap={rowDoubleTapFunc} //TODO: modify/remove this if it will be specified
			>
				{cells}
			</TableRow>
		);
	}

	sortTournaments() {
		return this.props.tournamentsList.sort((a, b) => parseInt(a.tournamentID) - parseInt(b.tournamentID));
	}

	renderTable() {
		return (
			<AppTouchableOpacity
				activeOpacity={1}
				style={{ flex: 1, paddingBottom: 2 }}
				onPress={this.onTableBackPress.bind(this)}
				disabled={this.props.selectedTournamentID == -1}
				accessibilityState={{
					disabled: this.props.selectedTournamentID == -1,
				}}
				accessibilityLabel={"deselect tournament"}
			>
				<FlatList
					keyExtractor={(tour, index) => tour.tournamentID.toString() + "_" + index}
					data={this.sortTournaments()}
					extraData={this.sortTournaments()}
					renderItem={({ item, index }) => this.renderRow(item, index)}
					getItemLayout={(data, index) => ({
						length: isTablet ? 62 : 42,
						offset: isTablet ? 62 * index : 42 * index,
						index,
					})}
					onLayout={this.tableRendered.bind(this)}
					accessibilityLabel={"Tournaments list"}
				/>
			</AppTouchableOpacity>
		);
	}

	render() {
		return <View style={styles.tableStyle}>{this.renderTable()}</View>;
	}
	//#endregion
}

const styles = StyleSheet.create({
	tableStyle: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
	},
	cellTextStyle: {
		textAlign: "center",
		color: "#FFFFFF",
		lineHeight: 40,
		height: 40,
		fontSize: Platform.OS === "web" ? 20 : 16,
	},
	firstRowFirstItem: { borderTopLeftRadius: 6 },
	firstRowLastItem: { borderTopRightRadius: 6 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedTournamentID: state.tournaments.selectedTournamentID,
		tournamentsList: state.tournaments.tournamentsList,
	};
};

const mapDispatchToProps = {
	deselectTournament,
	openTournamentPayout, //TODO: modify/remove this if it will be specified
	selectTournament,
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentListTable);
