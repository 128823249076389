import React, { Component } from "react";
import {
	BackHandler,
	Dimensions,
	ImageBackground,
	InputAccessoryView,
	Keyboard,
	Platform,
	StyleSheet,
	Text,
	TextInput,
	TouchableOpacity,
	TouchableWithoutFeedback,
	View,
} from "react-native";
import { connect } from "react-redux";
import {
	appFont,
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprolight,
	isTablet,
	RESOLUTION,
} from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { TEXT_INPUT } from "../../config/images";
import { DEBUG_ENABLED } from "../../config/permissions";
import { MyWebSocket } from "../../connection";
import AutocompleteInput from "../../libraries/AutoFillInput";
import DeviceInfo from "../../libraries/DeviceInfo";
import { FullScreenAndroid } from "../../libraries/FullScreenAndroid/FullScreenAndroid";
import { KeyboardAccessoryView } from "../../libraries/KeyboardAccessoryView/KeyboardAccessoryView";
import { clearQueriedUsers, closeComposeMessage, closeMessageDetails } from "../../redux/actions";
import { AppTouchableOpacity, ColoredButton } from "../common";
import { handleError } from "../ErrorHandler";

class ComposeMessage extends Component {
	queriedListRef = React.createRef();
	constructor(props) {
		super(props);
		var initUName = "",
			initSubject = "",
			initMessage = "";
		if (this.props.forwardMessage && this.props.selectedMessage) {
			if (typeof this.props.selectedMessage.subject === "string") {
				const isOnceForwarded = this.props.selectedMessage.subject.indexOf("Fwd:") == 0;
				initSubject = !isOnceForwarded
					? "Fwd: " + this.props.selectedMessage.subject
					: this.props.selectedMessage.subject;
			}

			var sentTime = "";
			if (typeof this.props.selectedMessage.recieveTimestamp === "number") {
				var dateFromSeconds = new Date(this.props.selectedMessage.recieveTimestamp * 1000);
				const year = dateFromSeconds.getFullYear();
				var month = dateFromSeconds.getMonth() + 1;
				if (month < 10) month = "0" + month;
				const day = dateFromSeconds.getDate();
				var _day = day;
				if (day < 10) _day = "0" + day;
				const date = _day + "-" + month + "-" + year;
				const time = dateFromSeconds.toLocaleTimeString("nl-NL").substr(0, 5);
				sentTime = "" + date + " " + time;
			}
			var oldContent = "";
			if (typeof this.props.selectedMessage.content === "string")
				oldContent = this.props.selectedMessage.content + "\n";

			initMessage =
				"\n\n---------------------------------------\n" +
				sentTime +
				" " +
				this.props.lang.gameMessage.reply.from +
				" " +
				this.props.selectedMessage.fromUsername +
				"\n" +
				oldContent;
		}
		if (this.props.openedForReply && this.props.selectedMessage) {
			if (typeof this.props.selectedMessage.subject === "string") {
				const isOnceReplied = this.props.selectedMessage.subject.indexOf("RE:") == 0;
				initSubject = !isOnceReplied ? "RE: " + this.props.selectedMessage.subject : this.props.selectedMessage.subject;
			}

			if (typeof this.props.selectedMessage.fromUsername === "string")
				initUName = this.props.selectedMessage.fromUsername;

			var sentTime = "";
			if (typeof this.props.selectedMessage.recieveTimestamp === "number") {
				var dateFromSeconds = new Date(this.props.selectedMessage.recieveTimestamp * 1000);
				const year = dateFromSeconds.getFullYear();
				var month = dateFromSeconds.getMonth() + 1;
				if (month < 10) month = "0" + month;
				const day = dateFromSeconds.getDate();
				var _day = day;
				if (day < 10) _day = "0" + day;
				const date = _day + "-" + month + "-" + year;
				const time = dateFromSeconds.toLocaleTimeString("nl-NL").substr(0, 5);
				sentTime = "" + date + " " + time;
			}
			var oldContent = "";
			if (typeof this.props.selectedMessage.content === "string")
				oldContent = this.props.selectedMessage.content + "\n";

			initMessage =
				"\n\n---------------------------------------\n" +
				sentTime +
				" " +
				this.props.lang.gameMessage.reply.from +
				" " +
				initUName +
				"\n" +
				oldContent;
		}
		if (this.props.isDirectMessage && this.props.queriedUsers.length == 1) {
			if (typeof this.props.queriedUsers[0].uName === "string") initUName = this.props.queriedUsers[0].uName;
		}

		this.state = {
			username: initUName,
			usernameValid: true,
			subject: initSubject,
			subjectValid: true,
			message: initMessage,
			messageValid: true,
			maxRightHeight: 0,
			isUserFocused: false,
			isMessageFocused: false,
			keyboardOpen: false,
			hoveredUName: null,
			hoveredUNameIndex: -1,
			selection: null,
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		if (Platform.OS !== "ios") {
			BackHandler.addEventListener("hardwareBackPress", this.handleBackPress);
			this.keyboardDidShowSubscription = Keyboard.addListener("keyboardDidShow", () => {
				this.setState({ keyboardOpen: true });
			});
		} else {
			this.keyboardWillShowSubscription = Keyboard.addListener("keyboardWillShow", () => {
				this.setState({ keyboardOpen: true });
			});
		}
		this.keyboardDidHideSubscription = Keyboard.addListener("keyboardDidHide", () => {
			this.setState({ keyboardOpen: false });
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.hoveredUNameIndex != prevState.hoveredUNameIndex &&
			this.state.hoveredUNameIndex >= 0 &&
			this.props.queriedUsers.length > 1 &&
			this.state.isUserFocused &&
			this.queriedListRef &&
			this.queriedListRef.current
		) {
			this.queriedListRef.current.scrollToIndex({
				index: this.state.hoveredUNameIndex,
				viewPosition: 1,
			});
		}
	}

	componentWillUnmount() {
		if (Platform.OS !== "ios") BackHandler.removeEventListener("hardwareBackPress", this.handleBackPress);
		this.keyboardDidShowSubscription?.remove();
		this.keyboardWillShowSubscription?.remove();
		this.keyboardDidHideSubscription?.remove();
	}
	//#endregion

	//#region events
	handleBackPress = () => {
		if (this.refs.msgInput) this.refs.msgInput.blur();
		return true;
	};

	queryUsers(uName) {
		try {
			if (uName.length >= 3) {
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "searchUsers",
					username: uName,
					maxResults: 10,
					includeAliases: true,
				});
			} else {
				this.props.clearQueriedUsers();
			}
			this.setState({
				username: uName,
				usernameValid: true,
				hoveredUName: null,
				hoveredUNameIndex: -1,
			});
		} catch (error) {
			handleError(error);
		}
	}

	focusOutUsername() {
		this.setState({ isUserFocused: false });
	}

	onSendPress() {
		try {
			var foundUser = this.props.queriedUsers.find((us) => us.uName == this.state.username);
			if (this.props.openedForReply) {
				foundUser = {
					username: this.props.selectedMessage.fromUsername,
					userID: this.props.selectedMessage.fromUserID,
				};
			}
			const _disabled =
				this.state.username == "" ||
				this.state.subject.length < 1 ||
				this.state.message.length < 1 ||
				!foundUser ||
				typeof foundUser.userID === "undefined";
			if (!_disabled) {
				MyWebSocket.shared.sendMsg({
					type: "sendMessage",
					sMessageID: 0,
					subject: this.state.subject,
					userID: foundUser.userID,
					content: this.state.message,
				});
				this.props.closeComposeMessage();
				this.props.closeMessageDetails();
			} else {
				this.setState({
					usernameValid: this.state.username != "" && foundUser && typeof foundUser.userID !== "undefined",
					subjectValid: this.state.subject.length >= 1,
					messageValid: this.state.message.length >= 1,
				});
			}
		} catch (err) {
			handleError(err);
		}
	}

	onUsernameKeyPress(ev) {
		try {
			if (this.props.queriedUsers.length > 0) {
				if (ev.nativeEvent.keyCode == 40) {
					//down arrow
					ev.preventDefault();
					var newInd = 0;
					if (this.state.hoveredUNameIndex == -1) {
						newInd = 0;
					} else {
						newInd = this.state.hoveredUNameIndex + 1;
						newInd = newInd > this.props.queriedUsers.length - 1 ? 0 : newInd;
					}
					this.setState({
						hoveredUNameIndex: newInd,
						hoveredUName: this.props.queriedUsers[newInd],
					});
				} else if (ev.nativeEvent.keyCode == 38) {
					//up arrow
					ev.preventDefault();
					var newInd = 0;
					if (this.state.hoveredUNameIndex == -1) {
						newInd = this.props.queriedUsers.length - 1;
					} else {
						newInd = this.state.hoveredUNameIndex - 1;
						newInd = newInd < 0 ? this.props.queriedUsers.length - 1 : newInd;
					}
					this.setState({
						hoveredUNameIndex: newInd,
						hoveredUName: this.props.queriedUsers[newInd],
					});
				} else if (ev.nativeEvent.keyCode == 13) {
					//ENTER key
					ev.preventDefault();
					if (this.state.hoveredUName && typeof this.state.hoveredUName.uName === "string") {
						this.queryUsers(this.state.hoveredUName.uName);
						Keyboard.dismiss();
					}
				}
			}
		} catch (err) {
			handleError(err);
		}
	}
	//#endregion

	//#region render methods
	renderDoneButton() {
		var _content = "✓";
		var _onPress = () => {
			this.setState({ isUserFocused: false, isMessageFocused: false });
			Keyboard.dismiss();
		};
		return (
			<AppTouchableOpacity
				onPress={_onPress.bind(this)}
				silentPress={true}
				activeOpacity={1}
				style={styles.doneButton}
				accessibilityLabel={this.props.lang.gameMessage.compose.btnDone}
			>
				<Text style={styles.doneText}>{_content}</Text>
			</AppTouchableOpacity>
		);
	}

	renderButton(type) {
		var _content = "",
			_color = buttonColor.GREEN,
			_onPress = () => {},
			buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		switch (type) {
			case "cancel":
				_content = this.props.lang.gameMessage.compose.btnDiscard;
				_color = buttonColor.RED;
				_onPress = () => {
					this.props.closeComposeMessage();
					this.props.closeMessageDetails();
				};
				break;
			case "send":
				_content = this.props.lang.gameMessage.compose.btnSend;
				_onPress = () => {
					this.onSendPress();
				};
				break;
			default:
				return null;
		}

		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				additionalTextStyle={_buttonTextStyle}
				onPressFunc={_onPress.bind(this)}
				textContent={_content}
				accessibilityLabel={_content}
				color={_color}
				disabled={false}
			/>
		);
	}

	renderAutofillInput() {
		return (
			<AutocompleteInput
				renderTextInput={() => this.renderInputField("to")}
				inputContainerStyle={{ borderWidth: 0 }}
				data={
					(this.props.queriedUsers?.length === 1 && this.props.queriedUsers[0].uName === this.state.username) ||
					!this.state.isUserFocused
						? []
						: this.props.queriedUsers
				}
				flatListProps={{
					ref: this.queriedListRef,
					keyboardShouldPersistTaps: "always",
					keyExtractor: (item, index) => "username_" + index,
					style: [styles.filteredList, { maxHeight: this.state.maxRightHeight - 175 }],
					getItemLayout: (data, index) => ({
						length: 40,
						offset: 40 * index,
						index,
					}),
					renderItem: ({ item, index }) => {
						var _textStyle = [styles.normalText, { paddingLeft: 6, paddingRight: 6 }];
						if (this.props.resolution === RESOLUTION.HIGH) {
							_textStyle = [styles.normalText, { fontSize: 26, paddingLeft: 6, paddingRight: 6 }];
						}
						return (
							<TouchableOpacity
								activeOpacity={1}
								onPress={() => {
									this.queryUsers(item.uName);
									Keyboard.dismiss();
								}}
								style={
									this.state.hoveredUName && this.state.hoveredUName === item
										? { backgroundColor: "#aaa", paddingHorizontal: 5 }
										: { paddingHorizontal: 5 }
								}
							>
								<Text allowFontScaling={false} style={_textStyle}>
									{item.uName}
								</Text>
							</TouchableOpacity>
						);
					},
				}}
			/>
		);
	}

	renderInputField(type) {
		const constInput = [Platform.OS === "android" && { paddingTop: 13 }, Platform.OS === "web" && { outlineWidth: 0 }];
		var _inputStyle = [styles.inputStyle, constInput],
			_inputBackImage = { flex: 1, borderRadius: 9 },
			_inputBackStyle = styles.rowContainer;

		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputStyle = [styles.inputStyle, { fontSize: 26 }, constInput];
			_inputBackImage = { flex: 1, borderRadius: 9 };
		}

		var _multiline = false,
			_placeHolder = "",
			maxCharNr,
			_defVal = "",
			onChangeFunc = (text) => {},
			selectionChangeFunc = () => {},
			onFocusFunc = () => {},
			onBlurFunc = () => {},
			onKeyPressFunc = () => {},
			_editable = true,
			_isValid = true;
		switch (type) {
			case "to":
				_inputStyle = [..._inputStyle, { height: this.props.resolution === RESOLUTION.HIGH ? 60 : 50 }];
				_inputBackImage = {
					..._inputBackImage,
					height: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
				};
				_placeHolder = this.props.lang.gameMessage.compose.phTo;
				onChangeFunc = (text) => {
					this.queryUsers(text);
				};
				onFocusFunc = () => this.setState({ isUserFocused: true, isMessageFocused: false });
				onKeyPressFunc = (ev) => {
					this.onUsernameKeyPress(ev);
				};
				_defVal = this.state.username;
				_editable = !this.props.openedForReply && !this.props.isDirectMessage;
				_isValid = this.state.usernameValid;
				break;
			case "title":
				_inputStyle = [..._inputStyle, { height: this.props.resolution === RESOLUTION.HIGH ? 60 : 50 }];
				_inputBackImage = {
					..._inputBackImage,
					height: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
				};
				_placeHolder = this.props.lang.gameMessage.compose.phTitle;
				maxCharNr = 32;
				onChangeFunc = (text) => {
					this.setState({ subject: text, subjectValid: true });
				};
				onFocusFunc = () => {
					this.setState({ isUserFocused: false, isMessageFocused: false });
				};
				_defVal = this.state.subject;
				_isValid = this.state.subjectValid;
				break;
			case "message":
				_multiline = true;
				_inputBackStyle = [styles.rowContainer, { flex: 1, marginBottom: 10 }];
				_inputStyle = [..._inputStyle, { flex: 1, paddingTop: 16, textAlignVertical: "top" }];
				_placeHolder = this.props.lang.gameMessage.compose.phMessage;
				maxCharNr = 8096;
				onChangeFunc = (text) => {
					this.setState({ message: text, messageValid: true, selection: null });
				};
				onFocusFunc = () => {
					this.setState({
						isMessageFocused: true,
						isUserFocused: false,
						selection: { start: 0, end: 0 },
					});
				};
				onBlurFunc = () => {
					this.setState({ /*isMessageFocused: false,*/ selection: null });
				};
				selectionChangeFunc = () => this.setState({ selection: null });
				_defVal = this.state.message;
				_isValid = this.state.messageValid;
				break;
		}
		return (
			<ImageBackground
				source={TEXT_INPUT}
				resizeMode="cover"
				imageStyle={[
					_inputBackImage,
					{
						borderWidth: _isValid ? 0 : 2,
						borderColor: _isValid ? "transparent" : "red",
					},
				]}
				style={[_inputBackStyle, { opacity: _editable ? 1 : 0.8 }]}
			>
				<TextInput
					inputAccessoryViewID={type == "message" ? "iOS_message_input" : "iOS_" + type + "_input"}
					ref={type == "message" ? "msgInput" : undefined}
					editable={_editable}
					disabled={!_editable}
					multiline={_multiline}
					numberOfLines={_multiline == true ? 50 : 1}
					allowFontScaling={false}
					disableFullscreenUI={true}
					selection={this.state.selection}
					onChangeText={(text) => onChangeFunc(text)}
					onFocus={() => {
						try {
							if (Platform.OS === "android" && Platform.Version >= 30) FullScreenAndroid.disable();
						} catch (err) {
							if (DEBUG_ENABLED) console.log("ERROR IN ComposeMessage/FullScreenAndroid.disable(): ", err);
						}
						onFocusFunc();
					}}
					onSelectionChange={selectionChangeFunc}
					onBlur={() => {
						try {
							if (Platform.OS === "android" && Platform.Version >= 30) FullScreenAndroid.enable();
						} catch (err) {
							if (DEBUG_ENABLED) console.log("ERROR IN ComposeMessage/FullScreenAndroid.enable(): ", err);
						}
						onBlurFunc();
					}}
					onKeyPress={(event) => onKeyPressFunc(event)}
					value={_defVal}
					placeholder={_placeHolder}
					placeholderTextColor="#B2B2B2"
					autoCapitalize="none"
					autoCompleteType="off"
					autoCorrect={false}
					spellCheck={false}
					textContentType="oneTimeCode"
					keyboardType={"default"}
					underlineColorAndroid="transparent"
					blurOnSubmit={type != "message"}
					returnKeyType={type != "message" ? "done" : "default"}
					style={_inputStyle}
					maxLength={maxCharNr}
				/>
			</ImageBackground>
		);
	}

	renderRightColumn() {
		return (
			<View
				style={styles.rightColumn}
				onLayout={(event) => {
					this.setState({ maxRightHeight: event.nativeEvent.layout.height });
				}}
			>
				<View
					style={{
						height: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
						marginVertical: 5,
					}}
				/>
				<View style={[styles.autocompleteContainer]}>{this.renderAutofillInput()}</View>
				{this.renderInputField("title")}
				{this.renderInputField("message")}
				<TouchableWithoutFeedback onPress={this.focusOutUsername.bind(this)}>
					<View
						style={[
							styles.buttonContainer,
							{
								height: this.props.resolution === RESOLUTION.HIGH ? 90 : 60,
								justifyContent: this.state.keyboardOpen && this.state.isMessageFocused ? "flex-end" : "space-evenly",
							},
						]}
					>
						{!this.state.keyboardOpen && this.renderButton("cancel")}
						{!this.state.keyboardOpen && this.renderButton("send")}
					</View>
				</TouchableWithoutFeedback>
			</View>
		);
	}

	renderLeftColumn() {
		var _labelStyle = styles.labelStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_labelStyle = [styles.labelStyle, { fontSize: 26, lineHeight: 60 }];
		}
		return (
			<TouchableWithoutFeedback onPress={this.focusOutUsername.bind(this)}>
				<View style={styles.leftColumn}>
					<View style={styles.rowContainer}>
						<Text allowFontScaling={false} style={_labelStyle}>
							{this.props.lang.gameMessage.compose.lblTo}
						</Text>
					</View>
					<View style={styles.rowContainer}>
						<Text allowFontScaling={false} style={_labelStyle}>
							{this.props.lang.gameMessage.compose.lblTitle}
						</Text>
					</View>
					<View style={styles.rowContainer}>
						<Text allowFontScaling={false} style={_labelStyle}>
							{this.props.lang.gameMessage.compose.lblMessage}
						</Text>
					</View>
				</View>
			</TouchableWithoutFeedback>
		);
	}

	renderAccessoryView() {
		const onChangeFunc = (text) => {
			this.setState({ message: text, messageValid: true, selection: null });
		};
		const selectionChangeFunc = () => this.setState({ selection: null });

		return (
			<KeyboardAccessoryView
				inSafeAreaView={true}
				hideBorder={true}
				// animateOn="none"
				androidAdjustResize={false}
				style={{
					flex: 1,
					alignSelf: "flex-end",
					backgroundColor: "transparent",
					marginBottom: isTablet ? 55 : 0,
					alignItems:
						this.props.appOrientation === "LANDSCAPE-RIGHT" && Platform.Version >= 30 ? "flex-end" : "flex-start",
				}}
			>
				<View
					style={[
						{
							flexDirection: "row",
							borderRadius: 6,
							backgroundColor: "#ccc",
						},
						Platform.Version < 30 && {
							marginRight:
								this.props.appOrientation === "LANDSCAPE-RIGHT"
									? this.props.resolution === RESOLUTION.HIGH
										? -75
										: -50
									: 0,
							marginLeft:
								this.props.appOrientation === "LANDSCAPE-LEFT"
									? this.props.resolution === RESOLUTION.HIGH
										? -75
										: -50
									: 0,
						},
						// this.props.appOrientation === "LANDSCAPE-RIGHT" &&
						// Platform.Version >= 30
						//   ? {
						//       marginRight:
						//         this.props.resolution === RESOLUTION.HIGH ? -75 : -50,
						//     }
						//   : {
						//       marginLeft:
						//         this.props.resolution === RESOLUTION.HIGH ? -75 : -50,
						//     },
					]}
				>
					<TextInput
						multiline={true}
						allowFontScaling={false}
						disableFullscreenUI={true}
						selection={this.state.selection}
						onChangeText={(text) => onChangeFunc(text)}
						onSelectionChange={selectionChangeFunc}
						value={this.state.message}
						placeholder={this.props.lang.gameMessage.compose.phMessage}
						placeholderTextColor="#B2B2B2"
						autoCapitalize="none"
						autoCompleteType="off"
						autoCorrect={false}
						spellCheck={false}
						textContentType="oneTimeCode"
						underlineColorAndroid="transparent"
						blurOnSubmit={false}
						// blurOnSubmit={true}
						// returnKeyType={"default"}
						style={{
							padding: 5,
							width: Math.abs(Dimensions.get("window").width - 50),
							// Platform.Version >= 30 || isTablet
							//   ? Math.abs(Dimensions.get("window").width - 50)
							//   : Dimensions.get("window").width,
							minHeight: 30,
							maxHeight: 90,
							backgroundColor: "#fff",
							fontFamily: appFont,
							fontSize: 16,
							textAlignVertical: "top",
							borderRadius: 6,
							marginRight: 5,
							color: "#555",
						}}
						maxLength={8096}
						keyboardType={"default"}
					/>
					<View style={{ alignSelf: "center" }}>{this.renderDoneButton()}</View>
				</View>
			</KeyboardAccessoryView>
		);
	}

	renderIOSAccessoryView() {
		const onChangeFunc = (text) => {
			this.setState({ message: text, messageValid: true, selection: null });
		};
		const selectionChangeFunc = () => this.setState({ selection: null });
		const hasNotch = DeviceInfo.hasNotch();
		return (
			<InputAccessoryView nativeID="iOS_message_input">
				<View
					style={[
						{
							width: this.props.safeAreaWidth,
							flexDirection: "row",
							borderRadius: 6,
							backgroundColor: "#ccc",
						},
						!hasNotch || this.props.appOrientation !== "LANDSCAPE-RIGHT"
							? {
									marginRight: this.props.resolution === RESOLUTION.HIGH ? -70 : -45,
							  }
							: {
									marginLeft: this.props.resolution === RESOLUTION.HIGH ? -70 : -45,
							  },
					]}
				>
					<TextInput
						multiline={true}
						allowFontScaling={false}
						disableFullscreenUI={true}
						selection={this.state.selection}
						onChangeText={(text) => onChangeFunc(text)}
						onSelectionChange={selectionChangeFunc}
						value={this.state.message}
						placeholder={this.props.lang.gameMessage.compose.phMessage}
						placeholderTextColor="#B2B2B2"
						autoCapitalize="none"
						autoCompleteType="off"
						autoCorrect={false}
						spellCheck={false}
						textContentType="oneTimeCode"
						underlineColorAndroid="transparent"
						blurOnSubmit={false}
						// blurOnSubmit={true}
						// returnKeyType={"default"}
						style={{
							padding: 5,
							width: hasNotch ? Math.abs(this.props.safeAreaWidth - 50 - 75) : Math.abs(this.props.safeAreaWidth - 50),
							minHeight: 30,
							maxHeight: 90,
							backgroundColor: "#fff",
							fontFamily: appFont,
							fontSize: 16,
							textAlignVertical: "top",
							borderRadius: 6,
							marginRight: 5,
							color: "#555",
						}}
						maxLength={8096}
						keyboardType={"default"}
					/>
					<View style={{ alignSelf: "center" }}>{this.renderDoneButton()}</View>
				</View>
			</InputAccessoryView>
		);
	}

	render() {
		return (
			<View style={[styles.container, this.props.resolution === RESOLUTION.HIGH && styles.containerHR]}>
				<View style={styles.contentContainer}>
					{this.renderLeftColumn()}
					{this.renderRightColumn()}
				</View>
				{this.state.isMessageFocused && Platform.OS === "android" && this.renderAccessoryView()}
				{Platform.OS === "ios" && this.renderIOSAccessoryView()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// justifyContent: "flex-end",
		marginHorizontal: 5,
		marginTop: 10,
	},
	containerHR: { marginHorizontal: 10 },
	buttonContainer: {
		width: "100%",
		flexDirection: "row",
		marginBottom: 5,
	},
	contentContainer: { flex: 1, flexDirection: "row" },
	leftColumn: { marginLeft: 10 },
	rightColumn: {
		flex: 1,
		paddingLeft: 20,
		paddingRight: 10,
	},
	rowContainer: { marginVertical: 5 },
	labelStyle: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		color: "white",
		lineHeight: 50,
		textAlign: "right",
	},
	inputStyle: {
		width: "100%",
		height: 50,
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		marginRight: 2,
		borderRadius: 6,
		paddingHorizontal: 8,
		textAlign: "left",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	autocompleteContainer: {
		paddingLeft: 20,
		paddingRight: 10,
		position: "absolute",
		left: 0,
		right: 0,
		top: 0,
		zIndex: 1,
		borderWidth: 1,
		borderColor: "transparent",
	},
	filteredList: {
		flex: 1,
		backgroundColor: "#FFFFFF",
		borderRadius: 10,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
	},
	normalText: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		lineHeight: 40,
		marginTop: Platform.OS === "web" ? 0 : 2,
		color: "#000",
	},
	doneText: {
		fontSize: 26,
		fontFamily: appFont,
		color: "#fff",
		alignSelf: "center",
	},
	doneButton: {
		width: 40,
		height: 40,
		margin: 3,
		borderRadius: 20,
		backgroundColor: "green",
		justifyContent: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		appOrientation: state.app.appOrientation,
		resolution: state.currentUser.preferences.resolution,
		selectedMessage: state.gameMessage.selectedMessage,
		forwardMessage: state.gameMessage.forwardMessage,
		openedForReply: state.gameMessage.openedForReply,
		isDirectMessage: state.gameMessage.isDirectMessage,
		queriedUsers: state.gameMessage.queriedUsers,
	};
};

const mapDispatchToProps = {
	clearQueriedUsers,
	closeComposeMessage,
	closeMessageDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeMessage);
