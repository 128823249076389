import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { Platform, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { handleError } from "../../components/ErrorHandler";
import { ANDROID_VERSION, BUILD_VERSION, IOS_VERSION } from "../../config/connection";
import { appFont, f_sourcesansprosemibold, modalType, RESOLUTION } from "../../config/defaults";
import { enablePanelSwap, panelMenuEnabled } from "../../config/permissions";
import { MyWebSocket } from "../../connection";
import { EventQueue } from "../../controller";
import { openModalDialog } from "../../redux/actions";
import { gameBoardTheme } from "../config/defaults";
import { openPanelMenu, swapPlayerPanels } from "../redux/actions";

class PlayerPanelMenu extends Component {
	//#region events
	addToFriends(selectedPlayer) {
		try {
			if (selectedPlayer && typeof selectedPlayer.userID === "string") {
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "addToFriendList",
					userID: selectedPlayer.userID,
				});
				this.props.openPanelMenu(selectedPlayer.slotID);
			}
		} catch (error) {
			handleError(error);
		}
	}

	removeFromFriends(selectedPlayer) {
		try {
			if (selectedPlayer && typeof selectedPlayer.userID === "string") {
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "removeFromFriendList",
					userID: selectedPlayer.userID,
				});
				this.props.openPanelMenu(selectedPlayer.slotID);
			}
		} catch (error) {
			handleError(error);
		}
	}

	addToBanList(selectedPlayer) {
		try {
			if (selectedPlayer && typeof selectedPlayer.userID === "string") {
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "addToBanList",
					userID: selectedPlayer.userID,
				});
				this.props.openPanelMenu(selectedPlayer.slotID);
			}
		} catch (error) {
			handleError(error);
		}
	}

	removeFromBanList(selectedPlayer) {
		try {
			if (selectedPlayer && typeof selectedPlayer.userID === "string") {
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "removeFromBanList",
					userID: selectedPlayer.userID,
				});
				this.props.openPanelMenu(selectedPlayer.slotID);
			}
		} catch (error) {
			handleError(error);
		}
	}

	sitHere(selectedPlayer) {
		try {
			if (selectedPlayer && this.props.userDetails.isVip) {
				const { sitSlotID } = this.props;
				// this.props.swapPlayerPanels(sitSlotID, selectedPlayer.slotID, true);
				var newMsg = {
					sMessageID: -1,
					type: "swapPlayerPanels",
					curSlotID: sitSlotID,
					newSlotID: selectedPlayer.slotID,
				};
				EventQueue.shared.processServerEvent(newMsg);
			} else {
				this.props.openModalDialog(
					modalType.ERROR,
					this.props.lang.playerPanelMenu.notVIPError,
					this.props.lang.ok || "",
					null
				);
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#render methods
	renderDetails() {
		var details = [];
		if (this.props.expandedPanelID >= 0 && this.props.players) {
			const selectedPlayer = this.props.players["slot_" + this.props.expandedPanelID];
			if (selectedPlayer && selectedPlayer.isHuman) {
				const _level = selectedPlayer.isHuman ? selectedPlayer.ranking : selectedPlayer.strength;
				const _score = selectedPlayer.isHuman ? selectedPlayer.score : "-";
				var resStyle = { fontSize: 12, lineHeight: 15 };
				switch (this.props.resolution) {
					case RESOLUTION.LOW:
						resStyle = { fontSize: 18, lineHeight: 22, width: 105 };
						break;
					case RESOLUTION.MEDIUM:
						resStyle = { fontSize: 16, lineHeight: 18, width: 95 };
						break;
				}
				resStyle = {
					...resStyle,
					color: this.props.gameTheme === gameBoardTheme.MODERN ? "#FFF" : "#000",
				};
				details.push(
					<View key="details_user" style={styles.detailsContainer}>
						<Text
							allowFontScaling={false}
							numberOfLines={1}
							style={[styles.detailsTitle, styles.detailsText, resStyle]}
						>
							{this.props.lang.playerPanelMenu.username}
						</Text>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.detailsDet, styles.detailsText, resStyle]}>
							{selectedPlayer.username}
						</Text>
					</View>
				);
				details.push(
					<View key="details_level" style={styles.detailsContainer}>
						<Text
							allowFontScaling={false}
							numberOfLines={1}
							style={[styles.detailsTitle, styles.detailsText, resStyle]}
						>
							{this.props.lang.playerPanelMenu.level}
						</Text>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.detailsDet, styles.detailsText, resStyle]}>
							{_level}
						</Text>
					</View>
				);
				details.push(
					<View key="details_score" style={styles.detailsContainer}>
						<Text
							allowFontScaling={false}
							numberOfLines={1}
							style={[styles.detailsTitle, styles.detailsText, resStyle]}
						>
							{this.props.lang.playerPanelMenu.score}
						</Text>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.detailsDet, styles.detailsText, resStyle]}>
							{_score}
						</Text>
					</View>
				);
				if (selectedPlayer.firstName) {
					details.push(
						<View key="details_fName" style={styles.detailsContainer}>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsTitle, styles.detailsText, resStyle]}
							>
								{this.props.lang.playerPanelMenu.firstName}
							</Text>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsDet, styles.detailsText, resStyle]}
							>
								{selectedPlayer.firstName}
							</Text>
						</View>
					);
				}
				if (selectedPlayer.lastName) {
					details.push(
						<View key="details_lName" style={styles.detailsContainer}>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsTitle, styles.detailsText, resStyle]}
							>
								{this.props.lang.playerPanelMenu.lastName}
							</Text>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsDet, styles.detailsText, resStyle]}
							>
								{selectedPlayer.lastName}
							</Text>
						</View>
					);
				}
				if (selectedPlayer.age) {
					details.push(
						<View key="details_age" style={styles.detailsContainer}>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsTitle, styles.detailsText, resStyle]}
							>
								{this.props.lang.playerPanelMenu.age}
							</Text>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsDet, styles.detailsText, resStyle]}
							>
								{selectedPlayer.age}
							</Text>
						</View>
					);
				}
				if (selectedPlayer.city) {
					details.push(
						<View key="details_city" style={styles.detailsContainer}>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsTitle, styles.detailsText, resStyle]}
							>
								{this.props.lang.playerPanelMenu.city}
							</Text>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsDet, styles.detailsText, resStyle]}
							>
								{selectedPlayer.city}
							</Text>
						</View>
					);
				}
				if (selectedPlayer.gender && selectedPlayer.gender !== "NOT_SET") {
					const _genderText = this.props.lang.playerPanelMenu.genders[selectedPlayer.gender.toLowerCase()];
					if (_genderText) {
						details.push(
							<View key="details_gender" style={styles.detailsContainer}>
								<Text
									allowFontScaling={false}
									numberOfLines={1}
									style={[styles.detailsTitle, styles.detailsText, resStyle]}
								>
									{this.props.lang.playerPanelMenu.gender}
								</Text>
								<Text
									allowFontScaling={false}
									numberOfLines={1}
									style={[styles.detailsDet, styles.detailsText, resStyle]}
								>
									{_genderText}
								</Text>
							</View>
						);
					}
				}
				if (selectedPlayer.slotID === this.props.sitSlotID && !this.props.isSpectator) {
					const _clientVersion = Platform.select({
						android: ANDROID_VERSION,
						ios: IOS_VERSION,
						web: BUILD_VERSION.toLocaleString(),
					});
					details.push(
						<View key="details_version" style={styles.detailsContainer}>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsTitle, styles.detailsText, resStyle]}
							>
								{this.props.lang.playerPanelMenu.clientVersion}
							</Text>
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.detailsDet, styles.detailsText, resStyle]}
							>
								{_clientVersion}
							</Text>
						</View>
					);
				}
			}
		}
		return details;
	}

	renderWebcontent() {
		return (
			<CustomScroll
				allowOuterScroll={true}
				heightRelativeToParent={this.props.containerHeight - 30 + "px"}
				minScrollHandleHeight={15}
			>
				{this.renderDetails()}
			</CustomScroll>
		);
	}

	renderNativeContent() {
		return this.renderDetails();
	}

	renderContent() {
		if (Platform.OS === "web") return this.renderWebcontent();
		return this.renderNativeContent();
	}

	renderButton(type) {
		if (this.props.expandedPanelID >= 0 && this.props.players) {
			const selectedPlayer = this.props.players["slot_" + this.props.expandedPanelID];
			if (selectedPlayer) {
				var resStyle = { fontSize: 12, lineHeight: 15 };
				switch (this.props.resolution) {
					case RESOLUTION.LOW:
						resStyle = { fontSize: 18, lineHeight: 22 };
						break;
					case RESOLUTION.MEDIUM:
						resStyle = { fontSize: 16, lineHeight: 18 };
						break;
				}
				var content = "",
					onPressFunc = () => {},
					buttonStyle = styles.button,
					//is disabled if player's slot is selected
					_disabled = selectedPlayer.slotID === this.props.sitSlotID,
					//is visible if not player's slot is selected
					visible = selectedPlayer.slotID !== this.props.sitSlotID;
				switch (type) {
					case "friendAdd":
						content = !selectedPlayer.isOnMyFriendList
							? this.props.lang.playerPanelMenu.addFriend
							: this.props.lang.playerPanelMenu.delFriend;
						onPressFunc = !selectedPlayer.isOnMyFriendList
							? () => this.addToFriends(selectedPlayer)
							: () => this.removeFromFriends(selectedPlayer);
						//if player a computer cannot be added as friend
						visible = (visible || this.props.isSpectator) && selectedPlayer.isHuman;
						_disabled = (_disabled && !this.props.isSpectator) || !selectedPlayer.isHuman;
						break;
					case "banAdd":
						content = !selectedPlayer.isOnMyBanList
							? this.props.lang.playerPanelMenu.addBan
							: this.props.lang.playerPanelMenu.delBan;
						onPressFunc = !selectedPlayer.isOnMyBanList
							? () => this.addToBanList(selectedPlayer)
							: () => this.removeFromBanList(selectedPlayer);
						//if player is a computer cannot be banned
						visible = (visible || this.props.isSpectator) && selectedPlayer.isHuman;
						_disabled = (_disabled && !this.props.isSpectator) || !selectedPlayer.isHuman;
						break;
					case "sitHere":
						content = this.props.lang.playerPanelMenu.sitHere;
						onPressFunc = () => this.sitHere(selectedPlayer);
						visible = visible && enablePanelSwap && !this.props.isSpectator;
						_disabled = _disabled || !enablePanelSwap;
						break;
					default:
						return null;
				}
				const textStyle = [[styles.linkDecor, resStyle], { opacity: visible ? (_disabled ? 0.5 : 1) : 0 }];

				return (
					<TouchableOpacity activeOpacity={1} onPress={onPressFunc} disabled={_disabled} style={buttonStyle}>
						<Text allowFontScaling={false} numberOfLines={1} style={textStyle}>
							{content}
						</Text>
					</TouchableOpacity>
				);
			}
		}
		return null;
	}

	render() {
		const isMenuExpanded =
			panelMenuEnabled == true && this.props.expandPanelMenu == true && this.props.expandedPanelID >= 0;
		const additionalStyle = [
			{
				height: this.props.containerHeight,
				zIndex: isMenuExpanded ? 10 : -5,
			},
			Platform.OS === "web" && { opacity: isMenuExpanded ? 1 : 0 },
		];
		return (
			<View style={[styles.container, additionalStyle]}>
				<ScrollView
					showsVerticalScrollIndicator={Platform.OS !== "web"}
					style={styles.scrollViewContainer}
					nestedScrollEnabled={true}
				>
					{this.renderContent()}
				</ScrollView>
				<View style={[styles.buttonsContainer]}>
					{this.renderButton("friendAdd")}
					{this.renderButton("banAdd")}
					{this.renderButton("sitHere")}
				</View>
			</View>
		);
	}
	//#endregion
}

const styles = {
	container: {
		position: "absolute",
		width: "100%",
		bottom: Platform.OS === "web" ? 37 : 3,
		zIndex: 10,
	},
	scrollViewContainer: { flex: 1 },
	detailsContainer: {
		flexDirection: "row",
		justifyContent: "center",
		paddingHorizontal: 3,
	},
	detailsTitle: { width: 75, marginRight: 3 },
	detailsDet: { flex: 1 },
	detailsText: { textAlign: "left", fontFamily: appFont, color: "#000" },
	buttonsContainer: {
		flexDirection: "row",
		justifyContent: "center",
		height: 20,
	},
	button: {
		flex: 3,
		height: "100%",
	},
	linkDecor: {
		alignSelf: "center",
		fontFamily: f_sourcesansprosemibold,
		textAlign: "center",
		color: "#00CDD8",
	},
};

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		userDetails: state.currentUser.userDetails,
		expandPanelMenu: state.startedGame.expandPanelMenu,
		expandedPanelID: state.startedGame.expandedPanelID,
		players: state.startedGame.players,
		sitSlotID: state.startedGame.sitSlotID,
		isSpectator: state.startedGame.isSpectator,
		gameTheme: state.startedGame.gameTheme,
	};
};

const mapDispatchToProps = { openModalDialog, openPanelMenu, swapPlayerPanels };

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPanelMenu);
