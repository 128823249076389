import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansprosemibold, isWebOrTablet, maxExistingUserRating } from "../../../config/defaults";
import { saveNewTeamMaxLevel, saveNewTeamMinLevel } from "../../../redux/actions";
import { handleError } from "../../ErrorHandler";

class RankingSlider extends Component {
	//#region events
	onChangeMinLevel(minLevel) {
		try {
			this.props.saveNewTeamMinLevel(minLevel);
		} catch (err) {
			handleError(err);
		}
	}

	onChangeMaxLevel(maxLevel) {
		try {
			this.props.saveNewTeamMaxLevel(maxLevel);
		} catch (err) {
			handleError(err);
		}
	}
	//#endregion

	//#region render methods
	renderMinLevel() {
		const _minVal = 0;
		const _maxVal = this.props.userLevel == undefined ? 1 : this.props.userLevel;
		return (
			<View style={styles.mainContainer}>
				<View style={styles.labelContainer}>
					<Text allowFontScaling={false} selectable={false} style={styles.labelText}>
						{this.props.lang.gameOptions.minRating + ":"}
					</Text>
				</View>
				<View style={styles.sliderContainer}>
					<View style={styles.sliderInnerContainer}>
						<Text allowFontScaling={false} style={[styles.sliderLabel, { marginRight: 20, color: "#000000" }]}>
							{this.props.minLevel.toString()}
						</Text>
						<Text allowFontScaling={false} style={styles.sliderLabel}>
							{_minVal.toString()}
						</Text>
						<Slider
							className="game-create"
							min={_minVal}
							max={_maxVal}
							step={1}
							value={this.props.minLevel}
							disabled={false}
							trackStyle={[{ backgroundColor: "#0C697A" }]}
							railStyle={{ backgroundColor: "rgb(45, 212, 228)" }}
							handleStyle={[{ backgroundColor: "#0B5969", borderColor: "#0B5969" }]}
							onChange={this.onChangeMinLevel.bind(this)}
						/>
						<Text allowFontScaling={false} style={styles.sliderLabel}>
							{_maxVal.toString()}
						</Text>
					</View>
				</View>
			</View>
		);
	}

	renderMaxLevel() {
		const _minVal = this.props.userLevel == undefined ? 1 : this.props.userLevel;
		const _maxVal = maxExistingUserRating;
		return (
			<View style={styles.mainContainer}>
				<View style={styles.labelContainer}>
					<Text allowFontScaling={false} selectable={false} style={styles.labelText}>
						{this.props.lang.gameOptions.maxRating + ":"}
					</Text>
				</View>
				<View style={styles.sliderContainer}>
					<View style={styles.sliderInnerContainer}>
						<Text allowFontScaling={false} style={[styles.sliderLabel, { marginRight: 20, color: "#000000" }]}>
							{this.props.maxLevel.toString()}
						</Text>
						<Text allowFontScaling={false} style={styles.sliderLabel}>
							{_minVal.toString()}
						</Text>
						<Slider
							className="game-create"
							min={_minVal}
							max={_maxVal}
							step={1}
							value={this.props.maxLevel}
							disabled={false}
							trackStyle={[{ backgroundColor: "#0C697A" }]}
							railStyle={{ backgroundColor: "rgb(45, 212, 228)" }}
							handleStyle={[{ backgroundColor: "#0B5969", borderColor: "#0B5969" }]}
							onChange={this.onChangeMaxLevel.bind(this)}
						/>
						<Text allowFontScaling={false} style={styles.sliderLabel}>
							{_maxVal.toString()}
						</Text>
					</View>
				</View>
			</View>
		);
	}

	render() {
		return (
			<>
				{this.renderMinLevel()}
				{this.renderMaxLevel()}
			</>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: "row",
		height: 60,
		paddingTop: 5,
		paddingBottom: 5,
	},
	labelContainer: { flex: 2, height: 50 },
	labelText: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: isWebOrTablet ? 20 : 18,
		textAlignVertical: "center",
		alignSelf: "flex-end",
		height: 50,
		lineHeight: 50,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	sliderContainer: {
		flex: 2,
		height: 50,
		alignSelf: "flex-start",
	},
	sliderInnerContainer: {
		flexDirection: "row",
		width: "75%",
		height: 50,
		paddingLeft: 5,
		paddingRight: 25,
	},
	sliderLabel: {
		width: 25,
		height: 50,
		lineHeight: 50,
		fontFamily: f_sourcesansprosemibold,
		fontSize: isWebOrTablet ? 20 : 18,
		textAlign: "right",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
});

const mapStateToProps = (state) => {
	const { newTeam } = state.tournaments;
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		userLevel: state.currentUser.userDetails.ranking,
		minLevel: newTeam && !isNaN(newTeam.minLevel) ? newTeam.minLevel : 0,
		maxLevel: newTeam && !isNaN(newTeam.maxLevel) ? newTeam.maxLevel : 1,
	};
};

const mapDispatchToProps = { saveNewTeamMinLevel, saveNewTeamMaxLevel };

export default connect(mapStateToProps, mapDispatchToProps)(RankingSlider);
