import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { LAUREL_URL } from "../../config/connection";
import { f_sourcesansprosemibold, isTablet } from "../../config/defaults";
import { ARROW } from "../../config/images";
import CheckBox from "../../libraries/CheckBox";
import { Hoverable } from "./";
import { AppTouchableOpacity } from "./AppTouchableOpacity";
import { KickBanPlayer } from "./KickBanPlayer";
import { PlayerDropDownButton } from "./PlayerDropDownButton";
import { PlayerTypeToggle } from "./PlayerTypeToggle";

const TableRowCell = (props) => {
	const {
		style,
		cellWidth,
		cellContainerStyle,
		cellId,
		content,
		backgroundColor,
		selectedBackgroundColor,
		isSelected,
		isHovered,
		cellType,
		imgURL,
		isVip,
		imgDim,
		laurelDim,
		isPlayerDropDown,
		isTogglePlayer,
		tableKey,
		enableKickBan,
		kickAction,
		banAction,
		contentTextColor,
		onDropButtonPress,
		onDropButtonBlur,
		onButtonImagePress,
		isButtonDisabled,
		parsedStyle,
		containerLayout,
		showToolTip,
		tooltipText,
		withDropDown,
	} = props;
	let bck = { backgroundColor: backgroundColor };

	if (isSelected || isHovered) bck = { backgroundColor: selectedBackgroundColor };

	const renderTextImage = () => {
		if (imgURL) {
			return <Image style={[styles.cellImageStyle, styles.cellTextImageStyle, imgDim || {}]} source={imgURL} />;
		}
		return null;
	};

	switch (cellType) {
		case "parsed_text":
			return (
				<View style={[styles.cellContainerStyle, cellContainerStyle]}>
					<ParsedText
						key={cellId}
						style={[styles.cellStyle, { width: cellWidth }, style || {}, bck]}
						parse={[
							{
								pattern: new RegExp(props.parsedText, "i"),
								style: [styles.cellStyle, { width: cellWidth }, style || {}, bck, parsedStyle],
								renderText: () => props.parsedText,
							},
						]}
					>
						{content}
					</ParsedText>
				</View>
			);
		case "img_button":
			return (
				<View style={[styles.cellContainerStyle, cellContainerStyle, { width: cellWidth }, bck, style]}>
					<AppTouchableOpacity onPress={onButtonImagePress} disabled={isButtonDisabled}>
						<Image
							key={cellId}
							style={[styles.cellImageStyle, imgDim || {}, { opacity: isButtonDisabled ? 0.5 : 1 }]}
							source={imgURL}
						/>
					</AppTouchableOpacity>
				</View>
			);
		case "img":
			if (showToolTip === true && tooltipText && tooltipText != "") {
				return (
					<Hoverable
						tooltipText={tooltipText}
						noAbsPos={true}
						style={[styles.cellContainerStyle, cellContainerStyle, { width: cellWidth }, bck, style]}
					>
						<View onLayout={containerLayout}>
							<Image key={cellId} style={[styles.cellImageStyle, imgDim || {}]} source={imgURL} />
							{isVip && (
								<Image
									source={{ uri: LAUREL_URL + "009.png" }}
									style={[
										{
											width: 30,
											height: 30,
											alignSelf: "center",
											position: "absolute",
										},
										laurelDim,
									]}
								/>
							)}
						</View>
					</Hoverable>
				);
			}
			return (
				<View
					style={[styles.cellContainerStyle, cellContainerStyle, { width: cellWidth }, bck, style]}
					onLayout={containerLayout}
				>
					<Image key={cellId} style={[styles.cellImageStyle, imgDim || {}]} source={imgURL} />
					{isVip && (
						<Image
							source={{ uri: LAUREL_URL + "009.png" }}
							style={[
								{
									width: 30,
									height: 30,
									alignSelf: "center",
									position: "absolute",
								},
								laurelDim,
							]}
						/>
					)}
				</View>
			);
		case "text_image":
			return (
				<View
					style={[
						styles.cellContainerStyle,
						styles.cellTextImageContainer,
						cellContainerStyle,
						{ width: cellWidth },
						bck,
					]}
				>
					{renderTextImage()}
					<Text
						allowFontScaling={false}
						key={cellId}
						style={[styles.cellStyle, { marginTop: "auto", marginBottom: "auto" }, style]}
					>
						{content}
					</Text>
				</View>
			);
		case "mixed":
			if (typeof enableKickBan === "boolean" && enableKickBan) {
				return (
					<View style={[styles.cellContainerStyle, cellContainerStyle]}>
						<KickBanPlayer
							lang={this.props.lang}
							key={cellId}
							username={content}
							containerStyle={[{ width: cellWidth }, bck]}
							usernameStyle={[styles.cellStyle, bck]}
							kickAction={kickAction}
							banAction={banAction}
						/>
					</View>
				);
			} else {
				if (isPlayerDropDown === true && !isTogglePlayer) {
					return (
						<View style={[styles.cellContainerStyle, cellContainerStyle]}>
							<PlayerDropDownButton
								key={cellId}
								style={[
									{
										width: cellWidth,
										height: 40,
										// paddingTop: isWebOrTablet ? 5 : 3,
										// paddingBottom: isWebOrTablet ? 2 : 0,
									},
									bck,
								]}
								cellId={cellId}
								contentText={content}
								textColor={contentTextColor}
								onButtonPress={onDropButtonPress}
								onDropButtonBlur={onDropButtonBlur}
								slotID={props.slotID}
								show={props.show}
							/>
						</View>
					);
				}

				if (isTogglePlayer === true && !isPlayerDropDown) {
					return (
						<View style={[styles.cellContainerStyle, cellContainerStyle]}>
							<PlayerTypeToggle
								key={cellId}
								style={[{ width: cellWidth, height: 40 }, bck]}
								textColor={contentTextColor}
								slotID={props.slotID}
								tableKey={tableKey}
							/>
						</View>
					);
				}
			}

			return (
				<View style={[styles.cellContainerStyle, cellContainerStyle]}>
					<Text
						allowFontScaling={false}
						key={cellId}
						style={[styles.cellStyle, { width: cellWidth }, style || {}, bck]}
					>
						{content}
					</Text>
					{withDropDown === true && (
						<View style={[props.arrowContainer, bck]}>
							<Image style={props.arrowStyle} source={ARROW} />
						</View>
					)}
				</View>
			);
		case "checkbox":
			return (
				<TouchableOpacity
					activeOpacity={1}
					onPress={props.onValueChange}
					touchSoundDisabled={true}
					style={[styles.cellContainerStyle, cellContainerStyle, { width: cellWidth }, bck, style]}
				>
					<CheckBox
						value={props.isChecked}
						// onValueChange={props.onValueChange}
						color={props.checkBoxColor}
						style={props.checkBoxStyle}
					/>
				</TouchableOpacity>
			);
		case "text":
		default:
			return (
				<View style={[styles.cellContainerStyle, cellContainerStyle]} onLayout={containerLayout}>
					<Text
						allowFontScaling={false}
						numberOfLines={1}
						key={cellId}
						style={[styles.cellStyle, { width: cellWidth }, style || {}, bck]}
					>
						{content}
					</Text>
				</View>
			);
	}
};

const styles = StyleSheet.create({
	cellContainerStyle: {
		justifyContent: "center",
		borderRightWidth: /* Platform.OS === "web" ? 0 : */ 1,
		borderRightColor: /* Platform.OS === "web" ? "transparent" : */ "#22C1E4",
	},
	cellStyle: {
		paddingLeft: 5,
		paddingRight: 1,
		fontFamily: f_sourcesansprosemibold,
		height: isTablet ? 30 : 20,
		lineHeight: isTablet ? 30 : 20,
		fontSize: isTablet ? 18 : 12,
		marginTop: "auto",
		marginBottom: "auto",
	},
	cellImageStyle: {
		width: 16,
		height: 16,
		alignSelf: "center",
	},
	cellTextImageContainer: {
		// flex: 1,
		flexDirection: "row",
		justifyContent: "center",
	},
	cellTextImageStyle: {
		// marginLeft: 3,
		// marginRight: 3,
	},
});

export { TableRowCell };
