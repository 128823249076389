import React, { Component } from "react";
import { Animated, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { EventQueue } from "../../controller";

class ClassicMiniCardsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			miniCard_1_Anim: new Animated.Value(0),
			miniCard_2_Anim: new Animated.Value(0),
			miniCard_3_Anim: new Animated.Value(0),
			miniCard_4_Anim: new Animated.Value(0),
			miniCard_5_Anim: new Animated.Value(0),
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		this.animateMiniCards();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.cards !== this.props.cards || prevProps.cardCounts != this.props.cardCounts) {
			this.animateMiniCards();
		}
	}
	//#endregion

	//#region events
	animateMiniCards = () => {
		var _cardCounts = this.props.cardCounts.find((cc) => cc.slotID == this.props.slotID);
		_cardCounts = _cardCounts != undefined ? _cardCounts.count : 0;

		if (this.props.cards.length > 0) {
			this.props.cards.forEach((card, index) => {
				const animState = "miniCard_" + (index + 1) + "_Anim";
				if (this.state[animState] != undefined) {
					if (card.dealed == true) {
						if (!this.props.isBackground) {
							Animated.timing(this.state[animState], {
								toValue: 1,
								duration: 5,
								useNativeDriver: Platform.OS !== "web",
							}).start();
						} else {
							this.state[animState].setValue(1);
						}
					} else {
						if (!this.props.isBackground) {
							Animated.timing(this.state[animState], {
								toValue: 0,
								duration: 5,
								useNativeDriver: Platform.OS !== "web",
							}).start();
						} else {
							this.state[animState].setValue(0);
						}
					}
				}
			});
			switch (_cardCounts) {
				case 0:
					this.state.miniCard_1_Anim.setValue(0);
					this.state.miniCard_2_Anim.setValue(0);
					this.state.miniCard_3_Anim.setValue(0);
					this.state.miniCard_4_Anim.setValue(0);
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 1:
					if (!EventQueue.shared.isDealInProgress) {
						this.state.miniCard_1_Anim.setValue(1);
					}
					this.state.miniCard_2_Anim.setValue(0);
					this.state.miniCard_3_Anim.setValue(0);
					this.state.miniCard_4_Anim.setValue(0);
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 2:
					if (!EventQueue.shared.isDealInProgress) {
						this.state.miniCard_1_Anim.setValue(1);
						this.state.miniCard_2_Anim.setValue(1);
					}
					this.state.miniCard_3_Anim.setValue(0);
					this.state.miniCard_4_Anim.setValue(0);
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 3:
					if (!EventQueue.shared.isDealInProgress) {
						this.state.miniCard_1_Anim.setValue(1);
						this.state.miniCard_2_Anim.setValue(1);
						this.state.miniCard_3_Anim.setValue(1);
					}
					this.state.miniCard_4_Anim.setValue(0);
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 4:
					if (!EventQueue.shared.isDealInProgress) {
						this.state.miniCard_1_Anim.setValue(1);
						this.state.miniCard_2_Anim.setValue(1);
						this.state.miniCard_3_Anim.setValue(1);
						this.state.miniCard_4_Anim.setValue(1);
					}
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 5:
					if (!EventQueue.shared.isDealInProgress) {
						this.state.miniCard_1_Anim.setValue(1);
						this.state.miniCard_2_Anim.setValue(1);
						this.state.miniCard_3_Anim.setValue(1);
						this.state.miniCard_4_Anim.setValue(1);
						this.state.miniCard_5_Anim.setValue(1);
					}
					break;
			}
		} else {
			switch (_cardCounts) {
				case 0:
					this.state.miniCard_1_Anim.setValue(0);
					this.state.miniCard_2_Anim.setValue(0);
					this.state.miniCard_3_Anim.setValue(0);
					this.state.miniCard_4_Anim.setValue(0);
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 1:
					this.state.miniCard_1_Anim.setValue(1);
					this.state.miniCard_2_Anim.setValue(0);
					this.state.miniCard_3_Anim.setValue(0);
					this.state.miniCard_4_Anim.setValue(0);
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 2:
					this.state.miniCard_1_Anim.setValue(1);
					this.state.miniCard_2_Anim.setValue(1);
					this.state.miniCard_3_Anim.setValue(0);
					this.state.miniCard_4_Anim.setValue(0);
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 3:
					this.state.miniCard_1_Anim.setValue(1);
					this.state.miniCard_2_Anim.setValue(1);
					this.state.miniCard_3_Anim.setValue(1);
					this.state.miniCard_4_Anim.setValue(0);
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 4:
					this.state.miniCard_1_Anim.setValue(1);
					this.state.miniCard_2_Anim.setValue(1);
					this.state.miniCard_3_Anim.setValue(1);
					this.state.miniCard_4_Anim.setValue(1);
					this.state.miniCard_5_Anim.setValue(0);
					break;
				case 5:
					this.state.miniCard_1_Anim.setValue(1);
					this.state.miniCard_2_Anim.setValue(1);
					this.state.miniCard_3_Anim.setValue(1);
					this.state.miniCard_4_Anim.setValue(1);
					this.state.miniCard_5_Anim.setValue(1);
					break;
			}
		}
	};
	//#endregion

	//#region render methods
	render() {
		var _cardCounts = this.props.cardCounts.find((cc) => cc.slotID == this.props.slotID);
		_cardCounts = _cardCounts != undefined ? _cardCounts.count : 0;

		return (
			<View
				key={"miniCardsContainer_" + this.props.slotID}
				style={[
					styles.miniCardsContainer,
					this.props.miniCardsPos,
					{ transform: [{ scale: this.props.panelScale || 1 }] },
				]}
			>
				<Animated.Image
					source={this.props.miniCardURL}
					style={[styles.miniCardImg, { opacity: _cardCounts >= 1 ? this.state.miniCard_1_Anim : 0 }]}
					resizeMode="cover"
				/>
				<Animated.Image
					source={this.props.miniCardURL}
					style={[styles.miniCardImg, { opacity: _cardCounts >= 2 ? this.state.miniCard_2_Anim : 0 }]}
					resizeMode="cover"
				/>
				<Animated.Image
					source={this.props.miniCardURL}
					style={[styles.miniCardImg, { opacity: _cardCounts >= 3 ? this.state.miniCard_3_Anim : 0 }]}
					resizeMode="cover"
				/>
				<Animated.Image
					source={this.props.miniCardURL}
					style={[styles.miniCardImg, { opacity: _cardCounts >= 4 ? this.state.miniCard_4_Anim : 0 }]}
					resizeMode="cover"
				/>
				<Animated.Image
					source={this.props.miniCardURL}
					style={[styles.miniCardImg, { opacity: _cardCounts >= 5 ? this.state.miniCard_5_Anim : 0 }]}
					resizeMode="cover"
				/>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	miniCardsContainer: {
		flexDirection: "row",
		position: "absolute",
		width: 40,
		paddingLeft: Platform.OS === "web" ? 6 : 4,
		paddingRight: Platform.OS === "web" ? 0 : 1,
		paddingTop: 1,
		paddingBottom: 1,
	},
	miniCardImg: {
		width: Platform.OS === "web" ? 5 : 6,
		height: 11,
		marginRight: Platform.OS === "web" ? 1 : 0,
		marginTop: 1,
	},
});

const mapStateToProps = (state) => {
	return {
		cards: state.startedGame.cards,
		cardCounts: state.startedGame.cardCounts,
		isBackground: state.app.isBackground,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClassicMiniCardsContainer);
