import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { Dimensions, ImageBackground, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, f_sourcesansproregular } from "../../../config/defaults";
import { gameDimensions } from "../../../config/dimensions";
import parse from "../../../helpers/emoji_parse";
import { closeVIPHelp, goBackToMain, openVIPBenefitsHelp } from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { ColoredButton, NavigationHeader } from "../../common";
import { handleError } from "../../ErrorHandler";
import VIPBenefitsHelp from "./VIPBenefitsHelp";

class VIPHelp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showNormalHelp: false,
			scrollViewHeight: 15,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("VIPHelp");
	}
	//#endregion

	//#region events
	measureScrollView(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const { height } = event.nativeEvent.layout;
			this.setState({ scrollViewHeight: height });
		} catch (error) {
			handleError(error);
		}
	}

	onBackPress() {
		if (this.state.showNormalHelp) {
			this.closeNormalHelp();
		} else {
			this.props.closeVIPHelp();
		}
	}

	openNormalHelp() {
		this.setState({ showNormalHelp: true });
	}

	closeNormalHelp() {
		this.setState({ showNormalHelp: false });
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "benefits":
				textContent = this.props.lang.vipHelp.benefits;
				onPressFunc = () => {
					this.props.openVIPBenefitsHelp();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "normal":
				textContent = this.props.lang.vipHelp.normal;
				onPressFunc = () => {
					this.openNormalHelp();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
		}
		return (
			<ColoredButton
				width={gameDimensions.gameButtonsWidth}
				height={60}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				isInMenu={true}
			/>
		);
	}

	renderNormalHelpDetails() {
		const msgContent = parse(this.props.lang.vipHelp.becoming.content);
		if (Platform.OS === "web") {
			const _height = Dimensions.get("window").height - 80;
			return (
				<ScrollView
					nestedScrollEnabled={true}
					showsVerticalScrollIndicator={false}
					style={[styles.detailsContent, { height: _height }]}
					onLayout={(event) => this.measureScrollView(event)}
				>
					<CustomScroll
						allowOuterScroll={true}
						heightRelativeToParent={this.state.scrollViewHeight - 5 + "px"}
						minScrollHandleHeight={15}
					>
						<View style={styles.contentContainer}>
							<Text allowFontScaling={false} style={styles.contentText}>
								{msgContent}
							</Text>
						</View>
					</CustomScroll>
				</ScrollView>
			);
		}

		const _height = Dimensions.get("screen").height - 80;
		return (
			<ScrollView style={[styles.detailsContent, { height: _height }]}>
				<Text allowFontScaling={false} style={styles.contentText}>
					{msgContent}
				</Text>
			</ScrollView>
		);
	}

	renderNormalHelp() {
		return (
			<View style={styles.detailsContainer}>
				<View style={styles.titleContainer}>
					<Text allowFontScaling={false} style={styles.titleStyle}>
						{this.props.lang.vipHelp.becoming.title}
					</Text>
				</View>
				{this.renderNormalHelpDetails()}
				<View style={styles.gotItButtonContainer}>
					<ColoredButton
						width={gameDimensions.gameButtonsWidth}
						height={60}
						onPressFunc={this.closeNormalHelp.bind(this)}
						textContent={this.props.lang.helpMenu.gotIt}
						accessibilityLabel={this.props.lang.helpMenu.gotIt}
						color={buttonColor.GREEN}
						disabled={false}
					/>
				</View>
			</View>
		);
	}

	renderMainMenu() {
		return (
			<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
				{this.renderButton("benefits")}
				{this.renderButton("normal")}
			</ScrollView>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.HELP_BACK.url} style={{ flex: 1 }}>
				{this.state.showNormalHelp && this.renderNormalHelp()}
				{!this.state.showNormalHelp && this.renderMainMenu()}
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.vipBenefitsHelpOpened) {
			return <VIPBenefitsHelp />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		paddingHorizontal: 55,
	},
	containerContent: { flex: 1, justifyContent: "center", alignItems: "center" },
	buttonStyle: { backgroundColor: "transparent", borderWidth: 0 },
	detailsContainer: {
		flex: 1,
		margin: gameDimensions.fullWidthMargin,
		padding: gameDimensions.fullWidthMargin,
	},
	titleContainer: {
		width: "100%",
		height: 50,
		paddingHorizontal: 55,
		justifyContent: "center",
	},
	titleStyle: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansprobold,
		fontSize: 26,
		textAlign: "center",
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
	},
	detailsContent: {
		paddingLeft: 35,
		paddingRight: Platform.OS === "web" ? 15 : 35,
		marginTop: 20,
		marginBottom: 5,
	},
	contentContainer: { flex: 1, paddingRight: Platform.OS === "web" ? 20 : 0 },
	contentText: {
		width: "100%",
		lineHeight: 28,
		fontFamily: f_sourcesansproregular,
		fontSize: 18,
		color: "#FFFFFF",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
	},
	gotItButtonContainer: {
		flexDirection: "row",
		width: "100%",
		paddingHorizontal: 30,
		justifyContent: "flex-end",
		marginTop: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		vipBenefitsHelpOpened: state.app.vipBenefitsHelpOpened,
		resolution: state.currentUser.preferences.resolution,
		HELP_BACK: state.images.HELP_BACK,
	};
};

const mapDispatchToProps = { closeVIPHelp, goBackToMain, openVIPBenefitsHelp };

export default connect(mapStateToProps, mapDispatchToProps)(VIPHelp);
