import React, { Component } from "react";
import { Image, Picker, StyleSheet, View } from "react-native";
import { TEXT_INPUT } from "../../config/images";
import { mapObject } from "../../helpers/commonHelpers";
import { handleError } from "../ErrorHandler";

class CountryListPicker extends Component {
	constructor(props) {
		super(props);
		const { initialSelected } = this.props;
		const _dataSource = this.getDataSource();
		var item = _dataSource.find((obj) => obj.countryID == initialSelected);
		item = item != undefined ? item : { countryID: "", name: "" };
		this.state = {
			dataSource: _dataSource,
			show: false,
			hoveredItem: null,
			selectedItem: item,
		};
	}

	//#region render methods
	render() {
		try {
			return (
				<View style={styles.boxStyle}>
					<Image source={TEXT_INPUT} resizeMode="stretch" style={styles.backImageStyle} />
					<Picker
						style={styles.pickerStyle}
						selectedValue={this.state.selectedItem.name}
						onValueChange={(itemValue, itemPosition) => {
							//TODO:
						}}
					>
						{this.state.dataSource.length > 0 &&
							this.state.dataSource.map((item, index) => {
								return (
									<Picker.Item key={index} label={item.name} value={item.countryID} style={styles.pickerItemStyle} />
								);
							})}
					</Picker>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region helpers
	getDataSource() {
		const { countryList } = this.props.lang;
		var dataSource = [];
		mapObject(countryList, (key, value) => {
			var newItem = {};
			newItem.countryID = key;
			newItem.name = value;
			dataSource.push(newItem);
		});
		return dataSource;
	}
	//#endregion
}

const styles = StyleSheet.create({
	boxStyle: {
		width: 300,
		height: 50,
		backgroundColor: "transparent",
	},
	pickerStyle: {
		width: 300,
		height: 50,
		color: "#fff",
	},
	backImageStyle: { width: 300, height: 50, position: "absolute", zIndex: -1 },
});

export { CountryListPicker };
