import { ADD_LOG, CLOSE_DEBUG_MENU, CLOSE_LOGGER, OPEN_DEBUG_MENU, OPEN_LOGGER } from "../actionTypes";

export const addLog = ({ title, content }) => {
	return { type: ADD_LOG, payload: { title, content } };
};

export const openLogger = () => {
	return { type: OPEN_LOGGER };
};

export const closeLogger = () => {
	return { type: CLOSE_LOGGER };
};

export const openDebugMenu = () => {
	return { type: OPEN_DEBUG_MENU };
};

export const closeDebugMenu = () => {
	return { type: CLOSE_DEBUG_MENU };
};
