import { gameLobbyDimensions } from "../../config/dimensions";
import { store } from "../store";
import {
	CREATE_GAME_BUTTONS_RENDERED,
	DETECT_ORIENTATION,
	DETECT_SMALL_RESOLUTION,
	GAME_DETAILS_TABLE_CONTENT_SIZE_CHANGED,
	GAME_DETAILS_TABLE_HEADER_SIZE,
	GAME_DETAILS_TABLE_RENDERED,
	GAME_LIST_FILTER_RENDERED,
	GAME_LIST_FILTER_SECTION_UNMOUNT,
	GAME_LIST_FILTER_TOGGLE,
	GAME_LIST_FILTER_UNMOUNT,
	GAME_LIST_TABLE_CONTENT_SIZE_CHANGED,
	GAME_LIST_TABLE_HEADER_SIZE,
	GAME_LOBBY_BUTTONS_RENDERED_IN_GAME_LIST,
	GAME_LOBBY_BUTTONS_UNMOUNTED_FROM_GAME_LIST,
	GAME_ROOM_BUTTONS_RENDERED,
	IS_FIVE_OR_MORE_PLAYERS_GAME_ENABLED,
	REQUEST_GAME_DETAILS_TABLE_HEIGHT,
	RESIZE_CREATE_GAME_BUTTONS,
	RESIZE_CREATE_NEW_GAME,
	RESIZE_GAME_DETAILS_TABLE,
	RESIZE_GAME_LIST_TABLE,
	RESIZE_GAME_LOBBY,
	RESIZE_GAME_LOBBY_BUTTONS,
	RESIZE_GAME_ROOM,
	RESIZE_GAME_ROOMS_RULE_DETAILS,
	RESIZE_GAME_ROOM_BUTTONS,
	RESIZE_GAME_SHOP,
	RESIZE_GLOBAL_CHAT,
	RESIZE_MAIN_CONTENT,
	RESIZE_NEW_GAMES_RULE_DETAILS,
	RESIZE_PAGE_TOP,
} from "./actionTypes";
import { updatePlayerAmountOptions } from "./CreateNewGameActions";
import { updatePlayerAmountFilter } from "./GameListDataActions";

export const resizeAll = (eventLayout) => {
	return (dispatch) => {
		dispatch(detectSmallResolution());
		dispatch(detectOrientation());
		dispatch(checkMoreThenFivePlayers());
		dispatch(_resizeMainContent(eventLayout));
		dispatch(_resizePageTop());
		dispatch(_resizeGlobalChat());
		dispatch(_resizeLobby());
		dispatch(_resizeGameShop());
		dispatch(_resizeGameListTable());
		dispatch(updatePlayerAmountFilter());
		dispatch(_resizeCreateNewGame());
		if (!store.getState().tab.isCreateNewGameOpen) {
			dispatch(_resizeGameDetailsTable());
		}
		dispatch(resizeNewGameRuleDetails());
		dispatch(_resizeGameRoom());
		dispatch(resizeGameRoomsRuleDetails());
	};
};

export const detectSmallResolution = () => {
	return { type: DETECT_SMALL_RESOLUTION };
};

export const detectOrientation = () => {
	return { type: DETECT_ORIENTATION };
};

export const gameListTableContentSizeChanged = (contentView) => {
	return (dispatch) => {
		dispatch(_gameListTableContentSizeChanged(contentView));
		dispatch(_resizeGameListTable());
	};
};

export const gameListTableHeaderSize = (eventLayout) => {
	return { type: GAME_LIST_TABLE_HEADER_SIZE, payload: eventLayout };
};

export const resizeGameListFilter = (newHeight) => {
	return (dispatch) => {
		dispatch(_resizeGameListFilter(newHeight));
	};
};

export const renderedGameLobbyButtonsInGameList = (myHeight) => {
	return (dispatch) => {
		dispatch(_renderedGameLobbyButtonsInGameList(myHeight));
		dispatch(_resizeGameListTable());
	};
};

export const unmountedGameLobbyButtonsFromGameList = () => {
	return (dispatch) => {
		dispatch(_unmountedGameLobbyButtonsFromGameList());
		dispatch(_resizeGameListTable());
	};
};

export const gameListFilterRendered = (newHeight) => {
	return (dispatch) => {
		dispatch(_resizeGameListTable());
		dispatch(_resizeGameListFilter(newHeight));
	};
};

export const toggleGameListFilter = () => {
	return { type: GAME_LIST_FILTER_TOGGLE };
};

export const unmountGameListFilter = () => {
	const newHeight = gameLobbyDimensions.gameListFilterContainerMinHeight;
	return (dispatch) => {
		dispatch(_unmountGameListFilter());
		dispatch(_unmountGameListFilterSection());
		dispatch(_resizeGameListTable());
		dispatch(_resizeGameListFilter(newHeight));
	};
};

export const resizeGameLobbyButtons = (layout) => {
	return { type: RESIZE_GAME_LOBBY_BUTTONS, payload: layout };
};

export const gameDetailsTableRendered = (tableKey) => {
	return { type: GAME_DETAILS_TABLE_RENDERED, payload: tableKey };
};

export const gameDetailsTableHeaderSize = (eventLayout) => {
	return { type: GAME_DETAILS_TABLE_HEADER_SIZE, payload: eventLayout };
};

export const gameDetailsTableContentSizeChanged = (contentView) => {
	return (dispatch) => {
		dispatch(_gameDetailsTableContentSizeChanged(contentView));
		dispatch(_resizeGameDetailsTable());
		dispatch(resizeNewGameRuleDetails());
		dispatch(resizeGameRoomsRuleDetails());
	};
};

export const requestAdditionalGameDetailsTableHeight = (additionalHeight) => {
	return { type: REQUEST_GAME_DETAILS_TABLE_HEIGHT, payload: additionalHeight };
};

export const renderedCreateGameButtons = (myHeight) => {
	return (dispatch) => {
		dispatch(_renderedCreateGameButtons(myHeight));
		// dispatch(_resizeGameDetailsTable());
		dispatch(resizeNewGameRuleDetails());
	};
};

export const resizeCreateGameButtons = (layout) => {
	return { type: RESIZE_CREATE_GAME_BUTTONS, payload: layout };
};

export const resizeNewGameRuleDetails = () => {
	return { type: RESIZE_NEW_GAMES_RULE_DETAILS };
};

export const checkMoreThenFivePlayers = () => {
	return (dispatch) => {
		dispatch(_checkMoreThenFivePlayers());
		dispatch(updatePlayerAmountOptions(store.getState().views.enableFiveOrMorePlayersGame));
	};
};

export const renderedGameRoomButtons = (myHeight) => {
	return (dispatch) => {
		dispatch(_renderedGameRoomButtons(myHeight));
		dispatch(_resizeGameDetailsTable());
		dispatch(resizeGameRoomsRuleDetails());
	};
};

export const resizeGameRoomButtons = (layout) => {
	return { type: RESIZE_GAME_ROOM_BUTTONS, payload: layout };
};
export const resizeGameRoomsRuleDetails = () => {
	return { type: RESIZE_GAME_ROOMS_RULE_DETAILS };
};

const _checkMoreThenFivePlayers = () => {
	return { type: IS_FIVE_OR_MORE_PLAYERS_GAME_ENABLED };
};

const _resizePageTop = () => {
	return { type: RESIZE_PAGE_TOP };
};

const _gameListTableContentSizeChanged = (contentView) => {
	return {
		type: GAME_LIST_TABLE_CONTENT_SIZE_CHANGED,
		payload: { contentView },
	};
};

const _resizeMainContent = (eventLayout) => {
	return { type: RESIZE_MAIN_CONTENT, payload: eventLayout };
};

const _resizeGlobalChat = () => {
	return { type: RESIZE_GLOBAL_CHAT };
};

const _resizeLobby = () => {
	const { resolution } = store.getState().currentUser.preferences;
	return { type: RESIZE_GAME_LOBBY, payload: { resolution } };
};

const _resizeGameShop = () => {
	return { type: RESIZE_GAME_SHOP };
};

const _resizeCreateNewGame = () => {
	const { resolution } = store.getState().currentUser.preferences;
	return { type: RESIZE_CREATE_NEW_GAME, payload: { resolution } };
};

const _resizeGameListTable = () => {
	return { type: RESIZE_GAME_LIST_TABLE };
};

const _resizeGameListFilter = (newHeight) => {
	return { type: GAME_LIST_FILTER_RENDERED, payload: newHeight };
};

const _unmountGameListFilter = () => {
	return { type: GAME_LIST_FILTER_UNMOUNT };
};

const _unmountGameListFilterSection = () => {
	return { type: GAME_LIST_FILTER_SECTION_UNMOUNT };
};

const _renderedGameLobbyButtonsInGameList = (myHeight) => {
	return { type: GAME_LOBBY_BUTTONS_RENDERED_IN_GAME_LIST, payload: myHeight };
};

const _unmountedGameLobbyButtonsFromGameList = () => {
	return { type: GAME_LOBBY_BUTTONS_UNMOUNTED_FROM_GAME_LIST };
};

const _resizeGameDetailsTable = () => {
	const { resolution } = store.getState().currentUser.preferences;
	return { type: RESIZE_GAME_DETAILS_TABLE, payload: { resolution } };
};

const _gameDetailsTableContentSizeChanged = (contentView) => {
	return {
		type: GAME_DETAILS_TABLE_CONTENT_SIZE_CHANGED,
		payload: { contentView },
	};
};

const _renderedCreateGameButtons = (myHeight) => {
	return {
		type: CREATE_GAME_BUTTONS_RENDERED,
		payload: myHeight,
	};
};

const _resizeGameRoom = () => {
	const { resolution } = store.getState().currentUser.preferences;
	return { type: RESIZE_GAME_ROOM, payload: { resolution } };
};

const _renderedGameRoomButtons = (myHeight) => {
	return { type: GAME_ROOM_BUTTONS_RENDERED, payload: myHeight };
};
