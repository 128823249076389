import { defaultClientLanguage } from "../../config/connection";
import { languages } from "../../languages";
import { CHANGE_LANGUAGE_DATA } from "../actions/actionTypes";

const INITIAL_STATE = {
	currentLanguage: languages[defaultClientLanguage.toLowerCase()],
};

export default (state = INITIAL_STATE, { type, payload }) => {
	switch (type) {
		case CHANGE_LANGUAGE_DATA:
			if (typeof payload === "undefined" || languages[payload] == undefined) return state;
			return { ...state, currentLanguage: languages[payload] };
		default:
			return state;
	}
};
