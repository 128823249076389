import { cloneDeep, sortBy } from "lodash";
import { TOURNAMENT_TEAM_TYPE } from "../../config/defaults";
import { GO_BACK_TO_MAIN, RESET_CLIENT } from "../actions/actionTypes";
import { TOURNAMENT_ACTIONS } from "../actionTypes";

const initialState = {
	tournamentScreenIsActive: false,
	tournamentsList: [],
	selectedTournamentID: -1,
	selectedTeamID: -1,
	createTeamOpened: false,
	newTeam: null,
	isTourStatsOpen: false,
	isTourLadderStatsOpen: false,
	isTourPayoutOpen: false,
	isTournamentDetailsOpen: false,
	selectedTournamentDetails: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case TOURNAMENT_ACTIONS.SET_TOURNAMENT_SCREEN_ACTIVE:
			return {
				...state,
				tournamentScreenIsActive: payload,
			};
		case TOURNAMENT_ACTIONS.CLEAR_TOURNAMENT_LIST:
			return {
				...initialState,
				tournamentScreenIsActive: state.tournamentScreenIsActive,
			};
		case TOURNAMENT_ACTIONS.INSERT_TOURNAMENT:
			if (typeof payload === "undefined" || !Array.isArray(payload.tournaments)) return state;
			var _tournaments = cloneDeep(state.tournamentsList);
			var insertedCnt = 0;
			payload.tournaments.forEach((tour) => {
				let foundIndex = state.tournamentsList.findIndex((t) => t.tournamentID == tour.tournamentID);

				if (foundIndex === -1) {
					_tournaments.push(tour);
					insertedCnt++;
				} else {
					_tournaments[foundIndex].tournamentID = tour.tournamentID;
					_tournaments[foundIndex].name = tour.name;
					_tournaments[foundIndex].state = tour.state;
					_tournaments[foundIndex].chipsRequired = tour.chipsRequired;
					_tournaments[foundIndex].registeredPlayerCnt = tour.registeredPlayerCnt;
					_tournaments[foundIndex].maxPlayerCnt = tour.maxPlayerCnt;
					_tournaments[foundIndex].isRegistered = tour.isRegistered;
					insertedCnt++;
				}
			});
			if (insertedCnt < 1) return state;
			return { ...state, tournamentsList: _tournaments };
		case TOURNAMENT_ACTIONS.REMOVE_TOURNAMENT:
			if (typeof payload === "undefined" || !Array.isArray(payload.tournaments)) return state;
			var _tournaments = cloneDeep(state.tournamentsList);
			var removedCnt = 0;
			var _selectedTournamentID = state.selectedTournamentID;
			var _selectedTeamID = state.selectedTeamID;
			let selectedTournamentDetails = state.selectedTournamentDetails;
			payload.tournaments.forEach((tour) => {
				var foundIndex = state.tournamentsList.findIndex((t) => t.tournamentID == tour);
				if (foundIndex != -1) {
					if (_tournaments[foundIndex].tournamentID == _selectedTournamentID) {
						_selectedTournamentID = -1;
						_selectedTeamID = -1;
						selectedTournamentDetails = null;
					}
					_tournaments.splice(foundIndex, 1);
					removedCnt++;
				}
			});
			if (removedCnt < 1) return state;
			return {
				...state,
				tournamentsList: _tournaments,
				selectedTournamentID: _selectedTournamentID,
				selectedTeamID: _selectedTeamID,
				selectedTournamentDetails: selectedTournamentDetails,
			};
		case TOURNAMENT_ACTIONS.SELECT_TOURNAMENT:
			if (
				typeof payload === "undefined" ||
				typeof payload.tournamentID !== "string" ||
				payload.tournamentID == state.selectedTournamentID
			)
				return state;
			return {
				...state,
				selectedTournamentID: payload.tournamentID,
				selectedTeamID: -1,
			};
		case TOURNAMENT_ACTIONS.DESELECT_TOURNAMENT:
			return {
				...state,
				selectedTournamentID: -1,
				selectedTeamID: -1,
				selectedTournamentDetails: null,
			};
		case TOURNAMENT_ACTIONS.SAVE_TOURNAMENT_DETAILS:
			if (typeof payload === "undefined" || state.selectedTournamentID === -1) {
				return state;
			}
			let tournamentDetails = {};
			tournamentDetails.state = payload.state;
			tournamentDetails.tournamentID = payload.tournamentID;
			tournamentDetails.name = payload.name;
			tournamentDetails.chipsRequired = payload.chipsRequired;
			tournamentDetails.registeredPlayerCnt = payload.registeredPlayerCnt;
			tournamentDetails.maxPlayerCnt = payload.maxPlayerCnt;
			tournamentDetails.isRegistered = payload.isRegistered;
			tournamentDetails.totalBuyIn = payload.totalBuyIn;
			tournamentDetails.totalRounds = payload.totalRounds;
			tournamentDetails.payoutMode = payload.payoutMode;
			tournamentDetails.payoutTable = payload.payoutTable;
			tournamentDetails.rounds = payload.rounds;
			tournamentDetails.specificDetails = payload.specificDetails;
			tournamentDetails.teams = payload.teams;
			return { ...state, selectedTournamentDetails: tournamentDetails };
		case TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_DETAILS:
			if (state.selectedTournamentID == -1) return state;
			return {
				...state,
				tournamentsList: initialState.tournamentsList,
				isTournamentDetailsOpen: true,
			};
		/* var _selTeamID = state.selectedTeamID;
			var foundTour = state.tournamentsList.find(
				(tour) => tour.tournamentID === state.selectedTournamentID
			);
			if (typeof payload !== "undefined" && payload.currentUserID) {
				if (foundTour && Array.isArray(foundTour.teams)) {
					const findUsersTeam = foundTour.teams.find((team) =>
						team.teamMembers.find((us) => us.userID == payload.currentUserID)
					);
					if (findUsersTeam && findUsersTeam.teamID) {
						_selTeamID = findUsersTeam.teamID;
						foundTour.canJoinTeam = false;
						//TODO: this should be added I think foundTour.canCreateTeam = false;
					}
				}
			}
			return {
				...state,
				isTournamentDetailsOpen: true,
				selectedTeamID: _selTeamID,
			}; */
		case TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_DETAILS:
			return { ...state, isTournamentDetailsOpen: false };
		// return { ...state, isTournamentDetailsOpen: false, selectedTeamID: -1 };
		case TOURNAMENT_ACTIONS.SELECT_TEAM:
			if (typeof payload === "undefined" || typeof payload.teamID !== "string" || payload.teamID == state.teamID)
				return state;
			return { ...state, selectedTeamID: payload.teamID };
		case TOURNAMENT_ACTIONS.DESELECT_TEAM:
			return { ...state, selectedTeamID: -1 };
		case TOURNAMENT_ACTIONS.OPEN_CREATE_TEAM:
			if (state.selectedTournamentID == -1 || state.tournamentsList.length == 0 || state.createTeamOpened === true)
				return state;
			var found = state.tournamentsList.find((tour) => tour.tournamentID === state.selectedTournamentID);
			if (!found || !found.withTeams) return state;
			const newTeamID = Math.floor(1000 + Math.random() * 9000);
			return {
				...state,
				createTeamOpened: true,
				newTeam: {
					teamID: newTeamID.toString(),
					teamType: TOURNAMENT_TEAM_TYPE.OPEN,
					teamName: "",
					teamMembers: [],
					teamPosition: 0,
					teamPointsFor: 15000,
					teamPointsAgainst: 28000,
					teamWins: 1500,
					teamLosses: 2000,
				},
			};
		case TOURNAMENT_ACTIONS.CLOSE_CREATE_TEAM:
			if (state.createTeamOpened === false) return state;
			return { ...state, createTeamOpened: false, newTeam: null };
		case TOURNAMENT_ACTIONS.SELECT_NEW_TEAM_TYPE:
			if (typeof payload === "undefined" || typeof payload.newType !== "string") return state;
			var _newTeam = { ...state.newTeam, teamType: payload.newType };
			if (_newTeam.minLevel !== "undefined") delete _newTeam.minLevel;
			if (_newTeam.maxLevel !== "undefined") delete _newTeam.maxLevel;
			if (_newTeam.pinCode !== "undefined") delete _newTeam.pinCode;
			switch (payload.newType) {
				case TOURNAMENT_TEAM_TYPE.PRIVATE:
					_newTeam.pinCode = "";
					break;
				case TOURNAMENT_TEAM_TYPE.LEVEL:
					_newTeam.minLevel = 0;
					_newTeam.maxLevel = 1;
					break;
			}
			return { ...state, newTeam: _newTeam };
		case TOURNAMENT_ACTIONS.SAVE_NEW_TEAM:
			if (!state.newTeam || typeof payload === "undefined" || !payload.userDetails) return state;

			var _tournaments = state.tournamentsList;
			var insertedCnt = 0;
			var found = _tournaments.find((t) => state.selectedTournamentID == t.tournamentID);
			var newSelTeamID = state.selectedTeamID;
			if (found && Array.isArray(found.teams)) {
				found.canCreateTeam = false;
				state.newTeam.teamMembers = [payload.userDetails];
				state.newTeam.maxMembersCount = 5;
				found.teams.push(state.newTeam);
				newSelTeamID = state.newTeam.teamID;
				insertedCnt++;
			}
			if (insertedCnt < 1) return state;
			return {
				...state,
				tournamentsList: _tournaments,
				selectedTeamID: newSelTeamID,
			};
		case TOURNAMENT_ACTIONS.SAVE_NEW_TEAM_NAME:
			if (typeof payload === "undefined" || typeof payload.newName !== "string") return state;
			return {
				...state,
				newTeam: { ...state.newTeam, teamName: payload.newName },
			};
		case TOURNAMENT_ACTIONS.SAVE_NEW_TEAM_PIN_CODE:
			if (typeof payload === "undefined" || typeof payload.newPinCode !== "string") return state;
			var _newPinCode = Number(payload.newPinCode);
			if (isNaN(_newPinCode)) return state;
			return {
				...state,
				newTeam: { ...state.newTeam, pinCode: _newPinCode },
			};
		case TOURNAMENT_ACTIONS.SAVE_NEW_TEAM_MIN_LEVEL:
			if (typeof payload === "undefined" || typeof payload.newMinLevel !== "number") return state;
			return {
				...state,
				newTeam: { ...state.newTeam, minLevel: payload.newMinLevel },
			};
		case TOURNAMENT_ACTIONS.SAVE_NEW_TEAM_MAX_LEVEL:
			if (typeof payload === "undefined" || typeof payload.newMaxLevel !== "number") return state;
			return {
				...state,
				newTeam: { ...state.newTeam, maxLevel: payload.newMaxLevel },
			};
		case TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_SCORE_STAT:
			if (state.selectedTournamentID == -1) return state;
			return { ...state, isTourStatsOpen: true };
		case TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_SCORE_STAT:
			return { ...state, isTourStatsOpen: false };
		case TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_PAYOUT:
			if (state.selectedTournamentID == -1) return state;
			return { ...state, isTourPayoutOpen: true };
		case TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_PAYOUT:
			return { ...state, isTourPayoutOpen: false };
		case TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_LADDER_STAT:
			if (state.selectedTournamentID == -1) return state;
			return { ...state, isTourLadderStatsOpen: true };
		case TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_LADDER_STAT:
			return { ...state, isTourLadderStatsOpen: false };
		case TOURNAMENT_ACTIONS.UPDATE_TOURNAMENT_LADDER_STAT:
			if (state.selectedTournamentID == -1) return state;

			var _tournamentsList = state.tournamentsList;
			var foundTour = _tournamentsList.find((tour) => tour.tournamentID == state.selectedTournamentID);
			if (!foundTour || !Array.isArray(foundTour.teams)) return state;

			foundTour.teams.forEach((team) => {
				const filterDropped = team.teamMembers.filter((pl) => pl.isDroppedOut);
				const indexInDropped = foundTour.teamsDropped.findIndex((id) => id == team.teamID);
				const indexInPlaying = foundTour.teamsInPlay.findIndex((id) => id == team.teamID);

				// define teams with players dropped out from tournament;
				if (filterDropped.length > 0 && indexInDropped == -1) foundTour.teamsDropped.push(team.teamID);

				// define teams with players still in tournament
				if (filterDropped.length != team.teamMembers.length) {
					if (indexInPlaying == -1) foundTour.teamsInPlay.push(team.teamID);
				} else {
					// if team is not in the playing list, it is a finished team
					if (indexInPlaying >= 0) foundTour.teamsInPlay.splice(indexInPlaying, 1);
					team.isFinished = true;
				}
			});

			foundTour.teams = sortBy(foundTour.teams, (team) => team.teamPosition);
			if (Array.isArray(foundTour.teamsInPlay)) {
				foundTour.teamsInPlay = sortBy(foundTour.teamsInPlay, (teamID) => {
					const team = foundTour.teams.find((team) => team.teamID == teamID);
					return team.teamPosition;
				});
			}
			if (Array.isArray(foundTour.teamsDropped)) {
				//sorting: not finished teams appear first and sorted by their position
				foundTour.teamsDropped = sortBy(foundTour.teamsDropped, (teamID) => {
					const team = foundTour.teams.find((team) => team.teamID == teamID);
					return [team.isFinished, team.teamPosition];
				});
			}
			return { ...state, tournamentsList: _tournamentsList };
		case TOURNAMENT_ACTIONS.OPEN_POOL_OVERVIEW:
			if (state.selectedTournamentID == -1) return state;
			return { ...state, isPoolOverviewOpen: true };
		case TOURNAMENT_ACTIONS.CLOSE_POOL_OVERVIEW:
			return { ...state, isPoolOverviewOpen: false };
		case GO_BACK_TO_MAIN:
		case TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_TAB:
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
