import { CLOSE_QUIESTIONNAIRE, OPEN_QUIESTIONNAIRE, PREPARE_SURVEY, SAVE_ANSWER } from "../actionTypes";
import { store } from "../store";

export const openQuestionnaire = (surveyID) => {
	return (dispatch) => {
		dispatch(_prepareSurvey(surveyID));
		dispatch(_openQuestionnaire());
	};
};

export const closeQuestionnaire = () => {
	return { type: CLOSE_QUIESTIONNAIRE };
};

export const saveAnswer = (questionID, answerID) => {
	return { type: SAVE_ANSWER, payload: { questionID, answerID } };
};

const _openQuestionnaire = () => {
	return { type: OPEN_QUIESTIONNAIRE };
};

const _prepareSurvey = (surveyID) => {
	const lang = store.getState().language.currentLanguage;
	return { type: PREPARE_SURVEY, payload: { surveyID, lang } };
};
