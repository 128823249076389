import React from "react";
import { ImageBackground, /* Platform, */ StyleSheet, Text } from "react-native";
import { handleError } from "../ErrorHandler";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

const Button = (props) => {
	const {
		onPress,
		onMouseEnter,
		onMouseLeave,
		disabled,
		myStyle,
		textContent,
		textContentStyle,
		backgroundImage,
		children,
	} = props;
	const defaultButton = (
		<AppTouchableOpacity
			onPress={onPress}
			disabled={disabled || false}
			onMouseEnter={onMouseEnter || null}
			onMouseLeave={onMouseLeave || null}
			style={[styles.buttonGenericStyle, myStyle]}
			activeOpacity={0.8}
			isInMenu={props.isInMenu}
		>
			{children}
			<Text
				allowFontScaling={false}
				selectable={false}
				numberOfLines={1}
				ellipsizeMode="tail"
				style={[styles.textStyle, textContentStyle]}
			>
				{textContent ? textContent : ""}
			</Text>
		</AppTouchableOpacity>
	);

	const renderButton = () => {
		try {
			if (typeof backgroundImage !== "undefined") {
				return (
					<ImageBackground
						source={backgroundImage.uri}
						resizeMode={backgroundImage.resizeMode || "stretch"}
						style={backgroundImage.style || { display: "none" }}
					>
						{defaultButton}
					</ImageBackground>
				);
			}
			return defaultButton;
		} catch (error) {
			handleError(error);
		}
	};

	return renderButton();
};

const styles = StyleSheet.create({
	textStyle: {
		alignSelf: "center",
		color: "#007aff",
		fontSize: 16,
		paddingTop: 10,
		paddingBottom: 10,
	},
	buttonGenericStyle: {
		backgroundColor: "#fff",
		borderRadius: 5,
		borderWidth: 1,
		borderColor: "#007aff",
	},
});

export { Button };
