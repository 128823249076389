import { handleError } from "../components/ErrorHandler";

export function validateTextField(text) {
	try {
		if (typeof text !== "string") throw new Error("'text' parameter is not string at validateTextField() function");
		if (text.length >= 6 && text.length <= 31) return true;
		return false;
	} catch (error) {
		handleError(error);
	}
}

export function validateBornYear(text) {
	try {
		if (typeof text === "undefined") throw new Error("Born year is not defined at validateBornYear() function");
		const numText = isNaN(Number(text)) ? 0 : Number(text);
		const currentYear = new Date().getFullYear();
		if (text.length <= 4 && numText >= 1900 && numText <= currentYear) return true;
		return false;
	} catch (error) {
		handleError(error);
	}
}

export function validateEmail(email) {
	try {
		const pattern =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (typeof email !== "string") throw new Error("'email' parameter is not string!");
		return pattern.test(email);
	} catch (error) {
		handleError(error);
	}
}

export function validatePwd(pwd) {
	try {
		if (typeof pwd !== "string") throw Error("'pwd' parameter is not string!");
		return typeof pwd === "string" && pwd.length >= 5;
	} catch (error) {
		handleError(error);
	}
}

export function validateConfirmPwd(pwd, confirmPwd) {
	try {
		if (typeof pwd !== "string") throw new Error("'pwd' is not defined at validateConfirmPwd() function");
		if (typeof confirmPwd !== "string") throw new Error("'confirmPwd' is not defined at validateConfirmPwd() function");
		return validatePwd(pwd) && pwd === confirmPwd;
	} catch (error) {
		handleError(error);
	}
}
