import { cloneDeep } from "lodash";
import { Platform } from "react-native";
import Analytics from "../../components/Analytics/Analytics";
import { handleError } from "../../components/ErrorHandler";
import { SHOP_CATEGORY, waitForPaymentTime } from "../../config/defaults";
import { DEBUG_ENABLED } from "../../config/permissions";
import { BUY_SUCCEEDED_SOUND } from "../../config/sounds";
import { MyWebSocket } from "../../connection";
import { inShopLateMoveTime } from "../../Keezers/config/defaults";
import { sendGameMoveExpired, startLateMoveInShop } from "../../Keezers/redux/actions";
import { store } from "../../redux/store";
import {
	ADD_CREDIT_SUCCEEDED,
	BUY_ITEM,
	BUY_ITEM_FAILED,
	BUY_ITEM_SUCCEEDED,
	CLEAR_STARTED_PURCHASE,
	CLOSE_CARDSETS_SHOP,
	CLOSE_CHIPS_SHOP,
	CLOSE_GOLD_SHOP,
	CLOSE_MISC_SHOP,
	CLOSE_MULTIPLIERS_SHOP,
	CLOSE_SILVER_SHOP,
	CLOSE_VIP_SHOP,
	OPEN_CARDSETS_SHOP,
	OPEN_CHIPS_SHOP,
	OPEN_GOLD_SHOP,
	OPEN_MISC_SHOP,
	OPEN_MULTIPLIERS_SHOP,
	OPEN_SILVER_SHOP,
	OPEN_VIP_SHOP,
	PURCHASE_NOT_VALIDATED,
	PURCHASE_STARTED,
	PURCHASE_VALIDATED,
	RECEIVE_SHOP_INVENTORY,
	SAVE_AVAILABLE_PURCHASE,
	START_VALIDATE_PURCHASE,
	UPDATE_GOLD_ITEMS_PRICES,
} from "./actionTypes";
import { removeSavedEvent } from "./SocketActions";
import { setUserAsVIP, updateStack } from "./UserActions";

var inShopLateMoveTimeout = null;

export function getInShopLateMoveTimeout() {
	return inShopLateMoveTimeout;
}

export function clearInShopLateMoveTimeout() {
	clearTimeout(inShopLateMoveTimeout);
}

export const startShopLateMoveTimeout = () => {
	return (dispatch) => {
		const { startedGame, tab } = store.getState();
		inShopLateMoveTimeout = null;
		if (inShopLateMoveTimeout) clearTimeout(inShopLateMoveTimeout);
		inShopLateMoveTimeout = setTimeout(() => {
			dispatch(startLateMoveInShop());
			if (
				!tab.isGameEndOpened &&
				typeof startedGame.gamePlayerToAct !== "undefined" &&
				startedGame.gamePlayerToAct !== null
			) {
				dispatch(sendGameMoveExpired());
			}
			inShopLateMoveTimeout = null;
		}, inShopLateMoveTime);
	};
};

export const openSilverShop = () => {
	if (!store.getState().tab.isShopOpen) {
		MyWebSocket.shared.requestShopDetails();
	}
	return { type: OPEN_SILVER_SHOP };
};

export const closeSilverShop = () => {
	return { type: CLOSE_SILVER_SHOP };
};

export const openGoldShop = () => {
	// if (Platform.OS === "ios") return null;
	if (!store.getState().tab.isShopOpen) {
		MyWebSocket.shared.requestShopDetails();
	}
	return { type: OPEN_GOLD_SHOP };
};

export const updateGoldItemsPrices = (data) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_GOLD_ITEMS_PRICES, payload: { data } });
	};
};

export const closeGoldShop = () => {
	return { type: CLOSE_GOLD_SHOP };
};

export const openVIPShop = () => {
	if (!store.getState().tab.isShopOpen) {
		MyWebSocket.shared.requestShopDetails();
	}
	return { type: OPEN_VIP_SHOP };
};

export const closeVIPShop = () => {
	return { type: CLOSE_VIP_SHOP };
};

export const openChipsShop = () => {
	if (!store.getState().tab.isShopOpen) {
		MyWebSocket.shared.requestShopDetails();
	}
	return { type: OPEN_CHIPS_SHOP };
};

export const closeChipsShop = () => {
	return { type: CLOSE_CHIPS_SHOP };
};

export const openMultipliersShop = () => {
	if (!store.getState().tab.isShopOpen) {
		MyWebSocket.shared.requestShopDetails();
	}
	return { type: OPEN_MULTIPLIERS_SHOP };
};

export const closeMultipliersShop = () => {
	return { type: CLOSE_MULTIPLIERS_SHOP };
};

export const openCardsetsShop = () => {
	if (!store.getState().tab.isShopOpen) {
		MyWebSocket.shared.requestShopDetails();
	}
	return { type: OPEN_CARDSETS_SHOP };
};

export const closeCardsetsShop = () => {
	return { type: CLOSE_CARDSETS_SHOP };
};

export const openMiscShop = (miscShopType) => {
	if (!store.getState().tab.isShopOpen) {
		MyWebSocket.shared.requestShopDetails();
	}
	return { type: OPEN_MISC_SHOP, payload: miscShopType };
};

export const closeMiscShop = () => {
	return { type: CLOSE_MISC_SHOP };
};

export const buyItem = (item, selectedPrice, mobileData) => {
	return async (dispatch) => {
		var buyMsg = {
			itemID: item.itemID,
			sMessageID: 0,
		};
		switch (selectedPrice) {
			case "gold":
				buyMsg.type = "purchaseShopItemWithGold";
				await Analytics.logEvent("spendGold");
				break;
			case "silver":
				buyMsg.type = "purchaseShopItemWithSilver";
				await Analytics.logEvent("spendSilver");
				break;
			case "currency":
				buyMsg.type = "addCredit";
				if (mobileData && mobileData.purchaseToken) buyMsg.purchaseToken = mobileData.purchaseToken;
				if (Platform.OS === "web") {
					setTimeout(() => {
						dispatch({ type: BUY_ITEM_SUCCEEDED, payload: { item } });
					}, waitForPaymentTime);
				}
				break;
		}
		const shopData = { item, selectedPrice };
		MyWebSocket.shared.sendMsg(buyMsg, "", undefined, shopData);
		dispatch({ type: BUY_ITEM, payload: { item } });
	};
};

export const purchasePlayStore = (item, selectedPrice, mobileData) => {
	return (dispatch) => {
		if (selectedPrice == "currency" && Platform.OS === "android") {
			var buyMsg = {
				type: "addCredit",
				itemID: item.itemID,
				currency: item.currency,
				sMessageID: 0,
			};
			if (mobileData && mobileData.purchaseToken) {
				if (DEBUG_ENABLED)
					console.log(
						`data in purchasePlayStore item = ${JSON.stringify(item)} mobileData = ${JSON.stringify(mobileData)}`
					);
				buyMsg.token = mobileData.purchaseToken;
				const shopData = { item, selectedPrice };
				MyWebSocket.shared.sendMsg(buyMsg, "", undefined, shopData);
				dispatch({ type: BUY_ITEM, payload: { item } });
				// setTimeout(() => {
				//   dispatch({type: BUY_ITEM_SUCCEEDED, payload: {item}});
				// }, 3000);
			}
		}
	};
};

export const purchaseAppStore = (item, selectedPrice, mobileData) => {
	return (dispatch) => {
		if (selectedPrice == "currency" && Platform.OS === "ios") {
			var buyMsg = {
				type: "addCredit",
				itemID: item.itemID,
				currency: item.currency,
				sMessageID: 0,
			};
			if (mobileData && mobileData.transactionReceipt) {
				buyMsg.token = mobileData.transactionReceipt;
				if (mobileData.transactionId) buyMsg.transactionId = mobileData.transactionId;
				const shopData = { item, selectedPrice };
				MyWebSocket.shared.sendMsg(buyMsg, "", undefined, shopData);
				dispatch({ type: BUY_ITEM, payload: { item } });
			} else {
				dispatch({ type: BUY_ITEM, payload: { item } });
				setTimeout(() => {
					dispatch(addCreditSucceeded({ itemID: item.itemID }));
				}, 3000);
			}
		}
	};
};

export const startPurchase = (item) => {
	return (dispatch) => {
		dispatch({ type: PURCHASE_STARTED, payload: item });
	};
};

export const clearStartedPurchase = (error) => {
	return async (dispatch) => {
		if (error) {
			const { itemInPurchase } = store.getState().gameShop;
			if (itemInPurchase && itemInPurchase.details) {
				var _error = "";
				switch (error) {
					case error.E_USER_CANCELLED:
						_error = "E_USER_CANCELLED";
						break;
					case error.E_ALREADY_OWNED:
						_error = "E_ALREADY_OWNED";
						break;
					case error.E_UNKNOWN:
						_error = "E_UNKNOWN";
						break;
				}
				await Analytics.logEvent("buyGoldFailed", {
					productID:
						Platform.OS === "android" ? itemInPurchase.details.androidProductID : itemInPurchase.details.iosProductID,
					error: _error,
					details: error,
				});
			}
		}
		dispatch({ type: CLEAR_STARTED_PURCHASE });
	};
};

export const saveAvailablePurchase = (purchase) => {
	return (dispatch) => {
		dispatch({ type: SAVE_AVAILABLE_PURCHASE, payload: purchase });
	};
};

export const startPurchaseValidation = (purchase) => {
	return (dispatch) => {
		dispatch({ type: START_VALIDATE_PURCHASE, payload: purchase });
	};
};

export const processPurchaseStatus = (msg) => {
	return async (dispatch) => {
		if (msg && msg.status) {
			switch (msg.status) {
				case "VALID":
					dispatch({
						type: PURCHASE_VALIDATED,
						payload: { purchaseToken: msg.purchaseToken },
					});
					const RNIap = Platform.OS !== "web" ? require("react-native-iap") : null;
					if (RNIap) {
						if (Platform.OS === "android") {
							const _purchases = store.getState().gameShop.availablePurchases;
							let toAcknowledge;
							if (_purchases.length > 0) {
								toAcknowledge = _purchases.find((p) => p.purchaseToken == msg.purchaseToken);
								toAcknowledge = cloneDeep(toAcknowledge);
								if (typeof toAcknowledge.validated != "undefined") delete toAcknowledge.validated;
								if (typeof toAcknowledge.validationStarted != "undefined") delete toAcknowledge.validationStarted;
							} else {
								RNIap.initConnection().then(() => {
									RNIap.getAvailablePurchases().then(async (purchases) => {
										if (DEBUG_ENABLED) console.log("getAvailablePurchases() inside processPurchaseStatus: ", purchases);
										if (purchases.length > 0) {
											toAcknowledge = purchases.find((p) => p.purchaseToken == msg.purchaseToken);
										}
									});
								});
							}

							if (toAcknowledge) {
								await RNIap.finishTransaction(toAcknowledge, true)
									.then(async (dat) => {
										if (DEBUG_ENABLED) {
											console.log("FINISHED TRANSACTION purchase: ", toAcknowledge);
											console.log("FINISHED TRANSACTION dat: ", dat);
										}
										await Analytics.logEvent("buyGoldComplete", {
											purchaseToken: toAcknowledge.purchaseToken,
											productID: toAcknowledge.productId,
										});
									})
									.catch((error) => {
										handleError(
											new Error(
												"ERROR ACKNOWLEDGING TRANSACTION: " + JSON.stringify(toAcknowledge) + JSON.stringify(error)
											)
										);
									});
							}
						} else {
							const _purchases = store.getState().gameShop.availablePurchases;
							let toAcknowledge;
							if (_purchases.length > 0) {
								toAcknowledge = _purchases.find((p) => p.purchaseToken == msg.purchaseToken);
								toAcknowledge = cloneDeep(toAcknowledge);
								if (typeof toAcknowledge.validated != "undefined") delete toAcknowledge.validated;
								if (typeof toAcknowledge.validationStarted != "undefined") delete toAcknowledge.validationStarted;
							} else {
								RNIap.initConnection().then(() => {
									RNIap.getAvailablePurchases().then(async (purchases) => {
										if (DEBUG_ENABLED) console.log("getAvailablePurchases() inside processPurchaseStatus: ", purchases);
										if (purchases.length > 0) {
											toAcknowledge = purchases.find((p) => p.purchaseToken == msg.purchaseToken);
										}
									});
								});
							}

							if (toAcknowledge) {
								await RNIap.finishTransaction(toAcknowledge, true)
									.then(async (dat) => {
										if (DEBUG_ENABLED) {
											console.log("FINISHED TRANSACTION purchase: ", toAcknowledge);
											console.log("FINISHED TRANSACTION dat: ", dat);
										}
										await Analytics.logEvent("buyGoldComplete", {
											purchaseToken: toAcknowledge.purchaseToken,
											productID: toAcknowledge.productId,
										});
									})
									.catch((error) => {
										handleError(
											new Error(
												"ERROR ACKNOWLEDGING TRANSACTION: " + JSON.stringify(toAcknowledge) + JSON.stringify(error)
											)
										);
									});
							}
						}
					}
					break;
				case "INVALID":
				case "UNKNOWN_PAYMENT":
					dispatch({
						type: PURCHASE_NOT_VALIDATED,
						payload: { purchaseToken: msg.purchaseToken },
					});
					if (DEBUG_ENABLED) console.log("PURCHASE_NOT_VALIDATED; purchaseToken: " + msg.purchaseToken);
					break;
			}
		}
	};
};

export const buyItemSucceeded = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (
			responseTo !== undefined &&
			responseTo.shopData &&
			responseTo.shopData.item &&
			responseTo.shopData.selectedPrice
		) {
			const { item, selectedPrice } = responseTo.shopData;
			var soundDuration = 0;
			switch (selectedPrice) {
				case "currency":
					BUY_SUCCEEDED_SOUND.cash.play();
					soundDuration = BUY_SUCCEEDED_SOUND.cash.getDuration() * 1000;
					break;
				case "gold":
					BUY_SUCCEEDED_SOUND.gold.play();
					soundDuration = BUY_SUCCEEDED_SOUND.gold.getDuration() * 1000;
					break;
				case "silver":
					BUY_SUCCEEDED_SOUND.silver.play();
					soundDuration = BUY_SUCCEEDED_SOUND.silver.getDuration() * 1000;
					break;
			}
			dispatch(updateStack(msg));
			if (Platform.OS === "web") {
				dispatch({ type: BUY_ITEM_SUCCEEDED, payload: { item } });
				var parent = store.getState().gameShop.categories.find((cat) => cat.categoryID == item.categoryID);
				var parentType = parent && parent != null ? parent.type : "";
				if (parentType == SHOP_CATEGORY.VIP) {
					dispatch(setUserAsVIP());
				}
				dispatch(removeSavedEvent(msg.cMessageID));
			} else {
				setTimeout(() => {
					dispatch({ type: BUY_ITEM_SUCCEEDED, payload: { item } });
					var parent = store.getState().gameShop.categories.find((cat) => cat.categoryID == item.categoryID);
					var parentType = parent && parent != null ? parent.type : "";
					if (parentType == SHOP_CATEGORY.VIP) {
						dispatch(setUserAsVIP());
					}
					dispatch(removeSavedEvent(msg.cMessageID));
				}, soundDuration);
			}
		}
	};
};

export const addCreditSucceeded = (msg) => {
	return async (dispatch) => {
		if (msg && msg.itemID) {
			BUY_SUCCEEDED_SOUND.cash.play();
			dispatch(updateStack(msg));
			dispatch({ type: ADD_CREDIT_SUCCEEDED, payload: { itemID: msg.itemID } });
			await Analytics.logEvent("buyGoldComplete", { itemID: msg.itemID });
			dispatch(clearStartedPurchase());
		}
	};
};

export const buyItemFailed = (data) => {
	return (dispatch) => {
		if (data && data.item) dispatch({ type: BUY_ITEM_FAILED, payload: { item: data.item } });
	};
};

export const receiveShopInventory = (msg) => {
	const { shop } = store.getState().app.welcome.webResources;
	return {
		type: RECEIVE_SHOP_INVENTORY,
		payload: { ...msg, shopURL: shop },
	};
};
