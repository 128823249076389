import { storeKeys } from "../../config/defaults";
import { getData, storeData } from "../../libraries/AsyncStorage";
import { CHANGE_LANGUAGE_DATA } from "./actionTypes";

export const changeAppLanguage = (val) => {
	return (dispatch) => {
		var newVal = val;
		if (val === null || val === undefined) newVal = "en";
		storeData(storeKeys.APP_LANGUAGE, newVal);
		dispatch({ type: CHANGE_LANGUAGE_DATA, payload: newVal.toLowerCase() });
	};
};

export const getLanguage = () => {
	return (dispatch) => {
		getData(storeKeys.APP_LANGUAGE).then((data) => {
			dispatch(changeAppLanguage(data));
		});
	};
};

export const updateTexts = () => {
	return (dispatch) => {
		//TODO: overwrite texts in redux and in the corresponding file as well
	};
};
