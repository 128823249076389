import { Linking, Platform } from "react-native";
import Analytics from "../../components/Analytics/Analytics";
import { handleError } from "../../components/ErrorHandler";
import { APP_STORE_URL, BUILD_VERSION, CONNECT_MSG } from "../../config/connection";
import {
	isWebOrTablet,
	leaderboard_Type,
	modalType,
	RESOLUTION,
	SETTINGS_SCREEN,
	storeKeys,
	tabType,
	tutorialMode,
} from "../../config/defaults";
import { DEBUG_ENABLED } from "../../config/permissions";
import { mySounds, SAVE_SOUND } from "../../config/sounds";
import { MyWebSocket } from "../../connection";
import NetworkUtils from "../../connection/NetworkUtils";
import { EventQueue } from "../../controller";
import DebugLogger from "../../controller/DebugLogger";
import { mapObject } from "../../helpers/commonHelpers";
import {
	disableThrowAway,
	finishCountdown,
	finishLateMoveInShop,
	resetPlayableCards,
	timeout,
} from "../../Keezers/redux/actions";
import { clearItem, getData, storeData } from "../../libraries/AsyncStorage";
import SecureStorage from "../../libraries/ReactSecureStorage";
import EncryptedStorage from "../../libraries/ReactNativeEncryptedStorage";
import { setAttribute } from "../../libraries/Crashlytics/Crashlytics";
import { SAVE_LB_DATA, TOURNAMENT_ACTIONS } from "../actionTypes";
import { store } from "../store";
import {
	ACCEPT_TERMS_AND_CONDITIONS,
	ADMOB_INITED,
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_FAILED,
	CLEAR_RESET_PASSWORD_ERROR,
	CLEAR_SEND_USERNAME_ERROR,
	CLEAR_UPDATE_AVATAR_ERROR,
	CLEAR_UPDATE_LANGUAGE_ERROR,
	CLEAR_UPDATE_USERNAME_ERROR,
	CLOSE_APP_LANGUAGE_SETTINGS,
	CLOSE_APP_NOTIFICATIONS_SETTINGS,
	CLOSE_BOARD_PREFERENCES,
	CLOSE_CARDS_PREFERENCES,
	CLOSE_CARD_SORT_ORDER,
	CLOSE_CHAT_TRANSLATION_SETTINGS,
	CLOSE_DEFAULT_CARDSET,
	CLOSE_DELETE_ACCOUNT,
	CLOSE_DELETE_ACCOUNT_FINAL,
	CLOSE_DELETE_ACCOUNT_REASON,
	CLOSE_EARN_FREE_SILVER,
	CLOSE_EMAIL_FRIEND,
	CLOSE_EMAIL_FRIEND_PREVIEW,
	CLOSE_EMAIL_NOTIFICATIONS_SETTINGS,
	CLOSE_FIX_BOARD_COLORS,
	CLOSE_FIX_BOARD_POSITIONS,
	CLOSE_GAME_ITEMS_MENU,
	CLOSE_GAME_MESSAGE,
	CLOSE_GOLD_HELP,
	CLOSE_GRAPHICAL_PREFERENCES,
	CLOSE_HELP,
	CLOSE_INVITE_FRIENDS_MENU,
	CLOSE_LANGUAGE_PREFERENCES,
	CLOSE_LEADER_BOARDS,
	CLOSE_LEADER_BOARDS_MENU,
	CLOSE_LOG_IN_TROUBLE,
	CLOSE_MY_PROFILE,
	CLOSE_NOTIFICATIONS_PREFERENCES,
	CLOSE_OVERRIDE_CARDSET,
	CLOSE_PLAYERS_LIST,
	CLOSE_PLAYER_OVERVIEW,
	CLOSE_PREFERENCES,
	CLOSE_RATE_DONE,
	CLOSE_RATE_FOR_SILVER,
	CLOSE_RESET_PASSWORD,
	CLOSE_SCREEN_PREFERENCES,
	CLOSE_SEARCH_PLAYERS,
	CLOSE_SEND_EMAIL_TROUBLE,
	CLOSE_SETTINGS,
	CLOSE_SHOP_HELP,
	CLOSE_SHOW_RUNNING_GAMES_IN_LIST,
	CLOSE_SILVER_HELP,
	CLOSE_SOUNDS_PREFERENCES,
	CLOSE_TUTORIALS,
	CLOSE_UPDATE_AVATAR,
	CLOSE_UPDATE_LANGUAGE,
	CLOSE_UPDATE_LOCATION,
	CLOSE_UPDATE_PERSONAL_DATA,
	CLOSE_UPDATE_USERNAME,
	CLOSE_VIP_BENEFITS_HELP,
	CLOSE_VIP_HELP,
	CONTINUE_EMAIL_SIGNUP,
	DELETE_AVATAR,
	DELETE_AVATAR_FAILED,
	DELETE_AVATAR_SUCCEEDED,
	EMAIL_LOGIN_CANCELED,
	EMAIL_LOGIN_FAILED,
	EMAIL_LOGIN_SUCCEEDED,
	EMAIL_SIGNUP_CANCELED,
	EMAIL_SIGNUP_FAILED,
	EMAIL_SIGNUP_SUCCEEDED,
	FACEBOOK_LOGIN_CANCELED,
	FACEBOOK_LOGIN_FAILED,
	FACEBOOK_LOGIN_SUCCEEDED,
	FINISH_APP_LOAD,
	FINISH_CHOOSE_USERNAME,
	FINISH_PROFILE_UPDATE,
	GO_BACK_TO_MAIN,
	HANDLE_WELCOME,
	LOGOUT,
	NETWORK_RECOVERED,
	OPEN_APP_LANGUAGE_SETTINGS,
	OPEN_APP_NOTIFICATIONS_SETTINGS,
	OPEN_BOARD_PREFERENCES,
	OPEN_CARDS_PREFERENCES,
	OPEN_CARD_SORT_ORDER,
	OPEN_CHAT_TRANSLATION_SETTINGS,
	OPEN_DEFAULT_CARDSET,
	OPEN_DELETE_ACCOUNT,
	OPEN_DELETE_ACCOUNT_FINAL,
	OPEN_DELETE_ACCOUNT_REASON,
	OPEN_EARN_FREE_SILVER,
	OPEN_EMAIL_FRIEND,
	OPEN_EMAIL_LOGIN,
	OPEN_EMAIL_NOTIFICATIONS_SETTINGS,
	OPEN_FIX_BOARD_COLORS,
	OPEN_FIX_BOARD_POSITIONS,
	OPEN_GAME_ITEMS_MENU,
	OPEN_GAME_MESSAGE,
	OPEN_GOLD_HELP,
	OPEN_GRAPHICAL_PREFERENCES,
	OPEN_HELP,
	OPEN_INVITE_FRIENDS_MENU,
	OPEN_LANGUAGE_PREFERENCES,
	OPEN_LEADER_BOARDS,
	OPEN_LEADER_BOARDS_MENU,
	OPEN_LOG_IN_TROUBLE,
	OPEN_MY_PROFILE,
	OPEN_NOTIFICATIONS_PREFERENCES,
	OPEN_OVERRIDE_CARDSET,
	OPEN_PLAYERS_LIST,
	OPEN_PLAYER_OVERVIEW,
	OPEN_PREFERENCES,
	OPEN_RATE_DONE,
	OPEN_RATE_FOR_SILVER,
	OPEN_RESET_PASSWORD,
	OPEN_SCREEN_PREFERENCES,
	OPEN_SEARCH_PLAYERS,
	OPEN_SEND_EMAIL_TROUBLE,
	OPEN_SETTINGS,
	OPEN_SHOP_HELP,
	OPEN_SHOW_RUNNING_GAMES_IN_LIST,
	OPEN_SILVER_HELP,
	OPEN_SOUNDS_PREFERENCES,
	OPEN_TUTORIALS,
	OPEN_UPDATE_AVATAR,
	OPEN_UPDATE_LANGUAGE,
	OPEN_UPDATE_LOCATION,
	OPEN_UPDATE_PERSONAL_DATA,
	OPEN_UPDATE_USERNAME,
	OPEN_UPGRADE_ACCOUNT,
	OPEN_VIP_BENEFITS_HELP,
	OPEN_VIP_HELP,
	RESET_CLIENT,
	RESET_PASSWORD,
	RESET_PASSWORD_FAILED,
	RESET_PASSWORD_SUCCEEDED,
	SEND_MY_USERNAME,
	SEND_MY_USERNAME_FAILED,
	SEND_MY_USERNAME_SUCCEEDED,
	SET_INTERNET_REACHABLE,
	SET_INTERNET_UNREACHABLE,
	SET_LOAD_TIME,
	SET_LOGGED_IN_STATE,
	SHOW_EMAIL_FRIEND_PREVIEW,
	START_CHAT,
	START_EMAIL_LOGIN,
	START_EMAIL_SIGNUP,
	START_FACEBOOK_LOGIN,
	START_LOG_IN,
	START_PLAY,
	START_PROFILE_UPDATE,
	START_SHOP,
	START_UPGRADE_ACCOUNT,
	UPDATE_APP_LANGUAGE,
	UPDATE_APP_LANGUAGE_FAILED,
	UPDATE_APP_LANGUAGE_SUCCEEDED,
	UPDATE_APP_NOTIFICATIONS,
	UPDATE_APP_NOTIFICATIONS_FAILED,
	UPDATE_APP_NOTIFICATIONS_SUCCEEDED,
	UPDATE_APP_ORIENTATION,
	UPDATE_AVATAR,
	UPDATE_AVATAR_FAILED,
	UPDATE_AVATAR_SUCCEEDED,
	UPDATE_CARD_SORT_ORDER,
	UPDATE_CARD_SORT_ORDER_FAILED,
	UPDATE_CARD_SORT_ORDER_SUCCEEDED,
	UPDATE_CHAT_TRANSLATION,
	UPDATE_CHAT_TRANSLATION_FAILED,
	UPDATE_CHAT_TRANSLATION_SUCCEEDED,
	UPDATE_DEFAULT_CARDSET,
	UPDATE_DEFAULT_CARDSET_FAILED,
	UPDATE_DEFAULT_CARDSET_SUCCEEDED,
	UPDATE_EMAIL_NOTIFICATIONS,
	UPDATE_EMAIL_NOTIFICATIONS_FAILED,
	UPDATE_EMAIL_NOTIFICATIONS_SUCCEEDED,
	UPDATE_FIXED_POSITIONS,
	UPDATE_FIXED_POSITIONS_FAILED,
	UPDATE_FIXED_POSITIONS_SUCCEEDED,
	UPDATE_LANGUAGE,
	UPDATE_LANGUAGE_FAILED,
	UPDATE_LANGUAGE_SUCCEEDED,
	UPDATE_LOCATION,
	UPDATE_LOCATION_FAILED,
	UPDATE_LOCATION_SUCCEEDED,
	UPDATE_OVERRIDE_CARDSET,
	UPDATE_OVERRIDE_CARDSET_FAILED,
	UPDATE_OVERRIDE_CARDSET_SUCCEEDED,
	UPDATE_PERSONAL_DATA,
	UPDATE_PERSONAL_DATA_FAILED,
	UPDATE_PERSONAL_DATA_SUCCEEDED,
	UPDATE_RESOLUTION,
	UPDATE_RESOLUTION_FAILED,
	UPDATE_RESOLUTION_SUCCEEDED,
	UPDATE_SHOW_RUNNING_GAMES_IN_LIST,
	UPDATE_SHOW_RUNNING_GAMES_IN_LIST_FAILED,
	UPDATE_SHOW_RUNNING_GAMES_IN_LIST_SUCCEEDED,
	UPDATE_SOUNDS_PREFERENCES,
	UPDATE_SOUNDS_PREFERENCES_FAILED,
	UPDATE_SOUNDS_PREFERENCES_SUCCEEDED,
	UPDATE_USERNAME,
	UPDATE_USERNAME_FAILED,
	UPDATE_USERNAME_SUCCEEDED,
	UPGRADE_ACCOUNT_FAILED,
	UPGRADE_ACCOUNT_SUCCEEDED,
	UPGRADE_REQUIRED,
} from "./actionTypes";
import { clearInShopLateMoveTimeout } from "./GameShopActions";
import { changeAppLanguage } from "./LanguageActions";
import { openModalDialog } from "./ModalActions";
import { closeShop, openGamesTab, openTab } from "./NavigationActions";
import { connectionFailed, connectionSuccessfull, removeSavedEvent } from "./SocketActions";
import { requestPersonalData, saveEmailPassword, saveEmailUsername } from "./UserActions";

export const updateAppOrientation = (orientation) => {
	return { type: UPDATE_APP_ORIENTATION, payload: { orientation } };
};

export const setAdmobAsInited = () => {
	return { type: ADMOB_INITED };
};

export const openPlayerOverview = () => {
	MyWebSocket.shared.sendMsg({ type: "getUserOverview", sMessageID: 0 });
	return { type: OPEN_PLAYER_OVERVIEW };
};

export const closePlayerOverview = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_PLAYER_OVERVIEW });
	};
};

export const acceptTermsAndConditions = () => {
	return (dispatch) => {
		storeData(storeKeys.APP_TERMS_ACCEPTED, "true").then(async (data) => {
			dispatch({ type: ACCEPT_TERMS_AND_CONDITIONS });
			MyWebSocket.shared.sendMsg({ type: "termsAccepted", sMessageID: 0 });
			await Analytics.logEvent("acceptTerms");
		});
	};
};

export const setInternetReachable = () => {
	return (dispatch) => {
		dispatch({ type: SET_INTERNET_REACHABLE });
	};
};

export const setInternetUnreachable = () => {
	return (dispatch) => {
		const { tab, app } = store.getState();
		if (app.isInternetUnreachable == false) {
			if (tab.isGameStarted) {
				dispatch(resetPlayableCards());
				dispatch(disableThrowAway());
				dispatch(finishCountdown());
				if (timeout) clearTimeout(timeout);
				dispatch(finishLateMoveInShop());
				clearInShopLateMoveTimeout();
				const { countdownSound } = mySounds;
				if (typeof countdownSound !== "undefined" && countdownSound != null && countdownSound.isLoaded() == true) {
					countdownSound.stop();
				}
				const { currentEvent } = EventQueue.shared;
				if (currentEvent !== null) {
					const { sMessageID, delay } = currentEvent;
					EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
				}
			}
			const { websocket } = store.getState().socket;
			if (websocket) websocket.close();
			dispatch({ type: SET_INTERNET_UNREACHABLE });
		}
	};
};

export const finishAppLoad = () => {
	return async (dispatch) => {
		const _isOnline = Platform.OS === "web" ? navigator.onLine : await NetworkUtils.isOnline();
		dispatch({ type: FINISH_APP_LOAD });
		if (_isOnline) {
			if (Platform.OS !== "web") {
				const connectionType = await NetworkUtils.getConnectionType();
				CONNECT_MSG.connectionType = connectionType;
			}
			// dispatch(setInternetReachable());
		} else {
			// dispatch(setInternetUnreachable());
			// const { isCreateNewGameOpen, isGameRoomOpen } = store.getState().tab;
			// if (isCreateNewGameOpen || isGameRoomOpen) {
			//   dispatch(leaveGame());
			//   dispatch(goBackToMain());
			// }
			// const lang = store.getState().language.currentLanguage;
			// const notifObj = {
			//   main_text:
			//     Platform.OS === "web"
			//       ? lang.reconnect_disabled
			//       : lang.reconnect_disabled,
			// };
			// dispatch(openModalDialog(modalType.RECONNECT, notifObj, ""));
		}
	};
};

export const networkRecovered = () => {
	return { type: NETWORK_RECOVERED };
};

export const setLoadTime = (startTime) => {
	return { type: SET_LOAD_TIME, payload: startTime };
};

export const handleWelcome = (msg) => {
	return async (dispatch) => {
		if (msg && msg.appSettings && msg.appSettings.clientLanguage)
			dispatch(changeAppLanguage(msg.appSettings.clientLanguage));
		if (msg && typeof msg.sessionID === "string") {
			//before storing the sessionID check if there is a previously logged session
			const storedSessionID = await getData(storeKeys.LOGGED_SESSION_ID);
			const storedLog = await getData(storeKeys.STORED_LOG);
			// get old connectivity log
			const storedConnLog = await getData(storeKeys.STORED_CONN_LOG);
			if (storedSessionID !== msg.sessionID && (storedLog || storedConnLog)) {
				//if there is a previously logged session the "sessionLog" event should be sent with the logged data
				if (DEBUG_ENABLED) console.log("PREVIOUS SESSION'S LOG SHOULD BE SENT");
				const jsonLog = JSON.parse(storedLog);
				if (jsonLog && typeof jsonLog.sessionID === "string") {
					var logMsg = {
						type: "sessionLog",
						sMessageID: 0,
						sessionID: storedSessionID,
						data: storedLog,
					};

					if (storedConnLog) {
						if (DEBUG_ENABLED) console.log("STORED CONN LOG: ", storedConnLog);
						const connectivityLog = {
							connectivityLog: JSON.stringify(storedConnLog),
						};
						logMsg.data += JSON.stringify(connectivityLog);
					}
					MyWebSocket.shared.sendMsg(logMsg);
					//when the logged data is successfully sent the saved log should be cleared and the loggerID should be resetted
					await clearItem(storeKeys.STORED_LOG);
					DebugLogger.shared.resetLoggerID();
					await clearItem(storeKeys.STORED_CONN_LOG);
					DebugLogger.shared.resetConnectivityID();
				} else {
					var logMsg = {
						type: "sessionLog",
						sMessageID: 0,
						data: "",
					};
					if (typeof jsonLog === "object" && jsonLog && Object.keys(jsonLog).length > 0) {
						mapObject(jsonLog, async (key, val, ind, len) => {
							logMsg.sessionID = val.sessionID;
							logMsg.data += JSON.stringify(val.log);
							if (ind == len - 1) {
								//when the logged data is successfully sent the saved log should be cleared and the loggerID should be resetted
								await clearItem(storeKeys.STORED_LOG);
								DebugLogger.shared.resetLoggerID();
							}
						});
					}
					if (storedConnLog) {
						if (DEBUG_ENABLED) console.log("STORED CONN LOG: ", storedConnLog);

						const jsonConnLog = JSON.parse(storedConnLog);
						mapObject(jsonConnLog, async (key, val) => {
							logMsg.sessionID = val.sessionID;
							const connectivityLog = {
								connectivityLog: JSON.stringify(val.log),
							};
							logMsg.data += JSON.stringify(connectivityLog);
						});
					}
					if (logMsg.sessionID) MyWebSocket.shared.sendMsg(logMsg);
					//when the logged data is successfully sent the saved log should be cleared and the loggerID should be resetted
					await clearItem(storeKeys.STORED_CONN_LOG);
					DebugLogger.shared.resetConnectivityID();
				}

				//store the current sessionID
				await storeData(storeKeys.LOGGED_SESSION_ID, msg.sessionID);

				//store that the connection has been opened
				const { wsURLs, connectionID, isReconnecting } = store.getState().socket;
				const connOpen_log = {
					connOpen_message: "Websocket connection opened",
					wsURL: wsURLs[connectionID],
					isReconnecting,
				};
				DebugLogger.shared.saveConnectivityLog(connOpen_log);
			} else {
				//if there is no previously logged session store the current sessionID
				if (DEBUG_ENABLED) console.log("THERE IS NO LOGGED SESSION");
				await storeData(storeKeys.LOGGED_SESSION_ID, msg.sessionID);

				//store that the connection has been opened
				const { wsURLs, connectionID, isReconnecting } = store.getState().socket;
				const connOpen_log = {
					connOpen_message: "Websocket connection opened",
					wsURL: wsURLs[connectionID],
					isReconnecting,
				};
				DebugLogger.shared.saveConnectivityLog(connOpen_log);
			}
		}

		dispatch(_handleWelcome(msg));
		//save sessionID to crashlytics
		if (typeof msg.sessionID === "string" && Platform.OS !== "web") setAttribute("sessionID", msg.sessionID);
		if (
			Platform.OS !== "web" &&
			typeof msg !== "undefined" &&
			typeof msg.versioning !== "undefined" &&
			typeof msg.versioning.warn === "number" &&
			typeof msg.versioning.minimal === "number"
		) {
			const { warn, minimal } = msg.versioning;
			const lang = store.getState().language.currentLanguage;
			if (BUILD_VERSION < minimal) {
				dispatch({ type: UPGRADE_REQUIRED });
				dispatch(handleUpgradeRequired());
			} else if (BUILD_VERSION <= warn) {
				dispatch(
					openModalDialog(
						modalType.DIALOG,
						lang.messages.updateAppWarningMessage,
						lang.updNow,
						() => {
							dispatch(connectionSuccessfull());
							startUpgrade();
						},
						lang.cancel,
						() => {
							dispatch(connectionSuccessfull());
						}
					)
				);
			} else {
				dispatch(connectionSuccessfull());
			}
		} else {
			dispatch(connectionSuccessfull());
		}
	};
};

export const handleUpgradeRequired = () => {
	return (dispatch) => {
		if (Platform.OS !== "web") {
			const lang = store.getState().language.currentLanguage;
			dispatch(connectionFailed());
			dispatch(openModalDialog(modalType.ERROR, lang.messages.updateAppWarningMessage, lang.updNow, startUpgrade));
		}
	};
};

export const setLoggedInState = (isLoggedIn) => {
	return (dispatch) => {
		dispatch(_setLoggedInState(isLoggedIn));
		/* if (isLoggedIn === true) {
      MyWebSocket.shared.startAuthenticate();
      MyWebSocket.shared.sendMsg(AUTHENTICATE_BASE_MSG);
    } */
	};
};

export const startPlay = () => {
	return (dispatch) => {
		if (!store.getState().currentUser.authenticated) MyWebSocket.shared.sendLogin();
		dispatch({ type: START_PLAY });
		dispatch(openTab(tabType.GAMES));
	};
};

export const startChat = () => {
	return (dispatch) => {
		dispatch({ type: START_CHAT });
		dispatch(openTab(tabType.CHAT));
	};
};

export const startShop = (linkLocation) => {
	return (dispatch) => {
		dispatch({ type: START_SHOP });
		dispatch(openTab(tabType.GAMES));
		dispatch(openTab(tabType.SHOP, "directLink", linkLocation));
	};
};

export const startLogIn = () => {
	return { type: START_LOG_IN };
};

export const logout = () => {
	return { type: LOGOUT };
};

export const goBackToMain = () => {
	return (dispatch) => {
		const { isShopOpen, isGameStarted } = store.getState().tab;
		if (isShopOpen) {
			dispatch(openGamesTab());
			dispatch(closeShop());
		}
		dispatch({ type: GO_BACK_TO_MAIN });
		if (isGameStarted) dispatch(startPlay());
	};
};

export const openUpgradeAccount = () => {
	return { type: OPEN_UPGRADE_ACCOUNT };
};

export const startUpgradeAccount = () => {
	return { type: START_UPGRADE_ACCOUNT };
};

export const upgradeAccountFailed = (response) => {
	return { type: UPGRADE_ACCOUNT_FAILED, payload: response };
};

export const upgradeAccountSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: UPGRADE_ACCOUNT_SUCCEEDED });
	};
};

export const openEmailLogin = () => {
	return { type: OPEN_EMAIL_LOGIN };
};

export const startEmailLogin = () => {
	return { type: START_EMAIL_LOGIN };
};

export const emailLoginSucceeded = (responseTo) => {
	return async (dispatch) => {
		if (typeof responseTo !== "undefined") {
			storeData(storeKeys.EMAIL_IS_LOGGED_IN, "true");
			if (typeof responseTo.username === "string") {
				storeData(storeKeys.EMAIL_USERNAME, responseTo.username);
				dispatch(saveEmailUsername(responseTo.username));
			}
			if (typeof responseTo.email === "string") {
				storeData(storeKeys.EMAIL_USERNAME, responseTo.email);
				dispatch(saveEmailUsername(responseTo.email));
			}
			if (typeof responseTo.password === "string") {
				if (Platform.OS === "web") {
					SecureStorage.setItem(storeKeys.EMAIL_PWD, responseTo.password);
				} else {
					await EncryptedStorage.setItem(storeKeys.EMAIL_PWD, responseTo.password);
				}
				// storeData(storeKeys.EMAIL_PWD, responseTo.password);
				dispatch(saveEmailPassword(responseTo.password));
			}
		}
		dispatch({ type: EMAIL_LOGIN_SUCCEEDED });
	};
};

export const emailLoginFailed = (response) => {
	return async (dispatch) => {
		clearItem(storeKeys.EMAIL_IS_LOGGED_IN);
		clearItem(storeKeys.EMAIL_USERNAME);
		try {
			if (Platform.OS === "web") {
				const pswd = SecureStorage.getItem(storeKeys.EMAIL_PWD);
				if (typeof pswd !== "undefined" && pswd !== "") {
					SecureStorage.removeItem(storeKeys.EMAIL_PWD);
				}
			} else {
				const pswd = await EncryptedStorage.getItem(storeKeys.EMAIL_PWD);
				if (typeof pswd !== "undefined" && pswd !== "") {
					await EncryptedStorage.removeItem(storeKeys.EMAIL_PWD);
				}
			}
		} catch (error) {
			if (DEBUG_ENABLED) {
				console.log(error);
				console.log(error.message);
			}
		}
		// clearItem(storeKeys.EMAIL_PWD);
		dispatch({ type: EMAIL_LOGIN_FAILED, payload: response });
	};
};

export const cancelEmailLogin = () => {
	return { type: EMAIL_LOGIN_CANCELED };
};

export const startEmailSignup = () => {
	return { type: START_EMAIL_SIGNUP };
};

export const continueEmailSignup = () => {
	return { type: CONTINUE_EMAIL_SIGNUP };
};

export const emailSignupSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: EMAIL_SIGNUP_SUCCEEDED });
	};
};

export const emailSignupFailed = (response) => {
	return { type: EMAIL_SIGNUP_FAILED, payload: response };
};

export const cancelEmailSignup = () => {
	return { type: EMAIL_SIGNUP_CANCELED };
};

export const startFacebookLogin = () => {
	return { type: START_FACEBOOK_LOGIN };
};

export const facebookLoginSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: FACEBOOK_LOGIN_SUCCEEDED });
	};
};

export const finishChooseUserName = () => {
	return { type: FINISH_CHOOSE_USERNAME };
};

export const facebookLoginFailed = () => {
	return { type: FACEBOOK_LOGIN_FAILED };
};

export const cancelFacebookLogin = () => {
	return { type: FACEBOOK_LOGIN_CANCELED };
};

export const openMyProfilePage = () => {
	return { type: OPEN_MY_PROFILE };
};

export const closeMyProfilePage = () => {
	return { type: CLOSE_MY_PROFILE };
};

export const startProfileUpdate = () => {
	return { type: START_PROFILE_UPDATE };
};

export const finishProfileUpdate = () => {
	return { type: FINISH_PROFILE_UPDATE };
};

export const openLogInTrouble = () => {
	return { type: OPEN_LOG_IN_TROUBLE };
};

export const closeLogInTrouble = () => {
	return { type: CLOSE_LOG_IN_TROUBLE };
};

export const openSendEmailTrouble = () => {
	return { type: OPEN_SEND_EMAIL_TROUBLE };
};

export const closeSendEmailTrouble = () => {
	return { type: CLOSE_SEND_EMAIL_TROUBLE };
};

export const sendMyUsername = (emailAddress) => {
	return (dispatch) => {
		dispatch({ type: SEND_MY_USERNAME });
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "recoverUsername",
			email: emailAddress,
		});
	};
};

export const sendMyUsernameSucceeded = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "recoverUsername") dispatch(removeSavedEvent(msg.cMessageID));
		dispatch({ type: SEND_MY_USERNAME_SUCCEEDED });
	};
};

export const sendMyUsernameFailed = (errorMsg) => {
	return { type: SEND_MY_USERNAME_FAILED, payload: errorMsg };
};

export const clearSendUsernameError = () => {
	return { type: CLEAR_SEND_USERNAME_ERROR };
};

export const openResetPassword = () => {
	return { type: OPEN_RESET_PASSWORD };
};

export const closeResetPassword = () => {
	return { type: CLOSE_RESET_PASSWORD };
};

export const resetPassword = (username) => {
	return (dispatch) => {
		dispatch({ type: RESET_PASSWORD });
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "recoverPassword",
			username: username,
		});
	};
};

export const resetPasswordSucceeded = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "recoverPassword") dispatch(removeSavedEvent(msg.cMessageID));
		dispatch({ type: RESET_PASSWORD_SUCCEEDED });
	};
};

export const resetPasswordFailed = (errorMsg) => {
	return { type: RESET_PASSWORD_FAILED, payload: errorMsg };
};

export const clearResetPasswordError = () => {
	return { type: CLEAR_RESET_PASSWORD_ERROR };
};

export const changePassword = (username, code, password) => {
	return async (dispatch) => {
		dispatch({ type: CHANGE_PASSWORD });
		const changePwdMsg = {
			sMessageID: 0,
			type: "changePassword",
			code: code,
			password: password,
			username: username,
		};
		if (Platform.OS === "web") {
			SecureStorage.setItem(storeKeys.EMAIL_PWD, password);
		} else {
			await EncryptedStorage.setItem(storeKeys.EMAIL_PWD, password);
		}
		// storeData(storeKeys.EMAIL_PWD, password);
		dispatch(saveEmailUsername(username));
		MyWebSocket.shared.sendMsg(changePwdMsg);
	};
};

export const changePasswordFailed = (msg) => {
	return async (dispatch) => {
		var responseTo = store
			.getState()
			.socket.sentMsg.find((m) => m.type == "changePassword" || m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "changePassword") {
			try {
				if (Platform.OS === "web") {
					const pswd = SecureStorage.getItem(storeKeys.EMAIL_PWD);
					if (typeof pswd !== "undefined" && pswd !== "") {
						SecureStorage.removeItem(storeKeys.EMAIL_PWD);
					}
				} else {
					const pswd = await EncryptedStorage.getItem(storeKeys.EMAIL_PWD);
					if (typeof pswd !== "undefined" && pswd !== "") {
						await EncryptedStorage.removeItem(storeKeys.EMAIL_PWD);
					}
				}
			} catch (error) {
				if (DEBUG_ENABLED) {
					console.log(error);
					console.log(error.message);
				}
			}
			// clearItem(storeKeys.EMAIL_PWD);
			dispatch({
				type: CHANGE_PASSWORD_FAILED,
				payload: { field: msg.field, text: msg.text },
			});
			dispatch(removeSavedEvent(msg.cMessageID, responseTo.type));
		}
	};
};

export const openSettings = () => {
	return { type: OPEN_SETTINGS };
};

export const closeSettings = () => {
	return { type: CLOSE_SETTINGS };
};

export const openUpdateUsername = () => {
	return { type: OPEN_UPDATE_USERNAME };
};

export const closeUpdateUsername = () => {
	return { type: CLOSE_UPDATE_USERNAME };
};

export const updateUsername = (uName) => {
	return (dispatch) => {
		if (typeof uName === "string") {
			dispatch({ type: UPDATE_USERNAME });
			MyWebSocket.shared.sendMsg({
				sMessageID: 0,
				type: "updateUsername",
				username: uName,
			});
		}
	};
};

export const usernameUpdatedSuccessFully = (msg) => {
	return (dispatch) => {
		var responseTo = store
			.getState()
			.socket.sentMsg.find((m) => m.type == "updateUsername" || m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "updateUsername") {
			if (typeof responseTo.username === "string") {
				dispatch(updateUsernameSucceeded(responseTo.username));
				dispatch(saveEmailUsername(responseTo.username));
				storeData(storeKeys.EMAIL_USERNAME, responseTo.username);
			}
			dispatch(removeSavedEvent(msg.cMessageID, responseTo.type));
		}
	};
};

export const updateUsernameSucceeded = (newUName) => {
	return { type: UPDATE_USERNAME_SUCCEEDED, payload: { newUName } };
};

export const updateUsernameFailed = (errorMsg) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_USERNAME_FAILED, payload: errorMsg });
	};
};

export const clearUpdateUsernameError = () => {
	return { type: CLEAR_UPDATE_USERNAME_ERROR };
};

export const openUpdateLocation = () => {
	return { type: OPEN_UPDATE_LOCATION };
};

export const closeUpdateLocation = () => {
	return { type: CLOSE_UPDATE_LOCATION };
};

export const updateLocation = (data) => {
	return (dispatch) => {
		if (
			data != undefined &&
			data.country != undefined &&
			data.city != undefined &&
			data.isCountryVisible != undefined &&
			data.isCityVisible != undefined
		) {
			dispatch({ type: UPDATE_LOCATION });
			const { profileSettings } = store.getState().currentUser;
			MyWebSocket.shared.sendMsg({
				sMessageID: 0,
				type: "updateLocation",
				country: data.country.toUpperCase(),
				city: data.city,
			});
			if (profileSettings.isCityVisible != data.isCityVisible) {
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "updateSettings",
					isCityVisible: data.isCityVisible,
				});
			}
		}
	};
};

export const locationUpdatedSuccessfully = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "updateLocation") {
			dispatch(updateLocationSucceeded());
			dispatch(closeUpdateLocation());
			SAVE_SOUND.play();
			dispatch(removeSavedEvent(msg.cMessageID));
			dispatch(requestPersonalData());
		}
	};
};

export const updateLocationSucceeded = () => {
	return { type: UPDATE_LOCATION_SUCCEEDED };
};

export const updateLocationFailed = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_LOCATION_FAILED });
	};
};

export const openUpdateLanguage = () => {
	return { type: OPEN_UPDATE_LANGUAGE };
};

export const closeUpdateLanguage = () => {
	return { type: CLOSE_UPDATE_LANGUAGE };
};

export const updateLanguage = (newLang) => {
	return (dispatch) => {
		if (typeof newLang === "string") {
			dispatch({ type: UPDATE_LANGUAGE });
			MyWebSocket.shared.sendMsg({
				sMessageID: 0,
				type: "updateLanguage",
				language: newLang.toUpperCase(),
			});
		}
	};
};

export const languageUpdatedSuccessfully = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "updateLanguage") {
			dispatch(updateLanguageSucceeded());
			SAVE_SOUND.play();
			dispatch(removeSavedEvent(msg.cMessageID));
			dispatch(requestPersonalData());
		}
	};
};

export const updateLanguageSucceeded = () => {
	return { type: UPDATE_LANGUAGE_SUCCEEDED };
};

export const updateLanguageFailed = (errorMsg) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_LANGUAGE_FAILED, payload: errorMsg });
	};
};

export const clearUpdateLanguageError = () => {
	return { type: CLEAR_UPDATE_LANGUAGE_ERROR };
};

export const openUpdatePersonalData = () => {
	return { type: OPEN_UPDATE_PERSONAL_DATA };
};

export const closeUpdatePersonalData = () => {
	return { type: CLOSE_UPDATE_PERSONAL_DATA };
};

export const updatePersonalData = (data) => {
	return (dispatch) => {
		if (
			data != undefined &&
			data.birthday != undefined &&
			data.firstName != undefined &&
			data.lastName != undefined &&
			data.gender != undefined &&
			data.isFirstNameVisible != undefined &&
			data.isLastNameVisible != undefined &&
			data.isAgeVisible != undefined &&
			data.isGenderVisible != undefined
		) {
			dispatch({ type: UPDATE_PERSONAL_DATA });
			MyWebSocket.shared.sendMsg({
				sMessageID: 0,
				type: "updatePersonalData",
				birthday: data.birthday,
				firstName: data.firstName,
				lastName: data.lastName,
				gender: data.gender,
			});
			const { profileSettings } = store.getState().currentUser;
			if (
				profileSettings.isFirstNameVisible != data.isFirstNameVisible ||
				profileSettings.isLastNameVisible != data.isLastNameVisible ||
				profileSettings.isAgeVisible != data.isAgeVisible ||
				profileSettings.isGenderVisible != data.isGenderVisible
			) {
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "updateSettings",
					isFirstNameVisible:
						profileSettings.isFirstNameVisible != data.isFirstNameVisible ? data.isFirstNameVisible : undefined,
					isLastNameVisible:
						profileSettings.isLastNameVisible != data.isLastNameVisible ? data.isLastNameVisible : undefined,
					isAgeVisible: profileSettings.isAgeVisible != data.isAgeVisible ? data.isAgeVisible : undefined,
					isGenderVisible: profileSettings.isGenderVisible != data.isGenderVisible ? data.isGenderVisible : undefined,
				});
			}
		}
	};
};

export const personalDataUpdatedSuccessFully = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "updatePersonalData") {
			dispatch(updatePersonalDataSucceeded());
			dispatch(closeUpdatePersonalData());
			SAVE_SOUND.play();
			dispatch(removeSavedEvent(msg.cMessageID));
			dispatch(requestPersonalData());
		}
	};
};

export const updatePersonalDataSucceeded = () => {
	return { type: UPDATE_PERSONAL_DATA_SUCCEEDED };
};

export const updatePersonalDataFailed = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PERSONAL_DATA_FAILED });
	};
};

export const openUpdateAvatar = () => {
	return { type: OPEN_UPDATE_AVATAR };
};

export const closeUpdateAvatar = () => {
	return { type: CLOSE_UPDATE_AVATAR };
};

export const updateAvatar = (avatar) => {
	return (dispatch) => {
		if (typeof avatar === "string") {
			dispatch({ type: UPDATE_AVATAR });
			MyWebSocket.shared.sendMsg({
				sMessageID: 0,
				type: "updateAvatar",
				image: avatar,
			});
		}
	};
};

export const avatarUpdatedSuccessfully = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "updateAvatar") {
			dispatch(updateAvatarSucceeded());
			if (Platform.OS !== "web") dispatch(closeUpdateAvatar());
			SAVE_SOUND.play();
			dispatch(removeSavedEvent(msg.cMessageID));
			dispatch(requestPersonalData());
		}
	};
};

export const updateAvatarSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_AVATAR_SUCCEEDED });
	};
};

export const updateAvatarFailed = (errorMsg) => {
	return { type: UPDATE_AVATAR_FAILED, payload: errorMsg };
};

export const deleteAvatar = () => {
	return (dispatch) => {
		dispatch({ type: DELETE_AVATAR });
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "deleteAvatar",
		});
	};
};

export const avatarDeletedSuccessfully = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "deleteAvatar") {
			dispatch(deleteAvatarSucceeded());
			if (Platform.OS !== "web") dispatch(closeUpdateAvatar());
			SAVE_SOUND.play();
			dispatch(removeSavedEvent(msg.cMessageID));
			dispatch(requestPersonalData());
		}
	};
};

export const deleteAvatarSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: DELETE_AVATAR_SUCCEEDED });
	};
};

export const deleteAvatarFailed = (errorMsg) => {
	return { type: DELETE_AVATAR_FAILED, payload: errorMsg };
};

export const clearUpdateAvatarError = () => {
	return { type: CLEAR_UPDATE_AVATAR_ERROR };
};

export const openPreferences = () => {
	return { type: OPEN_PREFERENCES };
};

export const closePreferences = () => {
	return { type: CLOSE_PREFERENCES };
};

export const openSoundsPreferences = () => {
	return { type: OPEN_SOUNDS_PREFERENCES };
};

export const closeSoundsPreferences = () => {
	return { type: CLOSE_SOUNDS_PREFERENCES };
};

export const updateSoundsPreferences = (data) => {
	return (dispatch) => {
		if (typeof data !== undefined) {
			dispatch({ type: UPDATE_SOUNDS_PREFERENCES });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				playMenuButtonSound: data.playMenuButtonSound,
				playMiscButtonSound: data.playMiscButtonSound,
				playGameChatSound: data.playGameChatSound,
				playGlobalChatSound: data.playGlobalChatSound,
				useLoudToActSound: data.useLoudToActSound,
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_SOUNDS_PREFERENCES);
		}
	};
};

export const updateSoundsPreferencesSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_SOUNDS_PREFERENCES_SUCCEEDED });
	};
};

export const updateSoundsPreferencesFailed = () => {
	return { type: UPDATE_SOUNDS_PREFERENCES_FAILED };
};

export const openGraphicalPreferences = () => {
	return { type: OPEN_GRAPHICAL_PREFERENCES };
};

export const closeGraphicalPreferences = () => {
	return { type: CLOSE_GRAPHICAL_PREFERENCES };
};

export const openCardsPreferences = () => {
	return { type: OPEN_CARDS_PREFERENCES };
};

export const closeCardsPreferences = () => {
	return { type: CLOSE_CARDS_PREFERENCES };
};

export const openShowRunningGamesInList = () => {
	return { type: OPEN_SHOW_RUNNING_GAMES_IN_LIST };
};

export const closeShowRunningGamesInList = () => {
	return { type: CLOSE_SHOW_RUNNING_GAMES_IN_LIST };
};

export const updateShowRunningGamesInList = (showRunningGames) => {
	return (dispatch) => {
		if (typeof showRunningGames === "boolean") {
			dispatch({ type: UPDATE_SHOW_RUNNING_GAMES_IN_LIST });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				showRunningGamesInList: showRunningGames,
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_SHOW_RUNNING_GAMES_IN_LIST);
		}
	};
};

export const updateShowRunningGamesInListSucceeded = () => {
	return { type: UPDATE_SHOW_RUNNING_GAMES_IN_LIST_SUCCEEDED };
};

export const updateShowRunningGamesInListFailed = () => {
	return { type: UPDATE_SHOW_RUNNING_GAMES_IN_LIST_FAILED };
};

export const openOverrideCardset = () => {
	return { type: OPEN_OVERRIDE_CARDSET };
};

export const closeOverrideCardset = () => {
	return { type: CLOSE_OVERRIDE_CARDSET };
};

export const updateOverrideCardset = (override) => {
	return (dispatch) => {
		if (typeof override === "boolean") {
			dispatch({ type: UPDATE_OVERRIDE_CARDSET });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				overrideCardset: override,
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_OVERRIDE_CARDSET);
		}
	};
};

export const updateOverrideCardsetSucceeded = () => {
	return { type: UPDATE_OVERRIDE_CARDSET_SUCCEEDED };
};

export const updateOverrideCardsetFailed = () => {
	return { type: UPDATE_OVERRIDE_CARDSET_FAILED };
};

export const openDefaultCardset = () => {
	return { type: OPEN_DEFAULT_CARDSET };
};

export const closeDefaultCardset = () => {
	return { type: CLOSE_DEFAULT_CARDSET };
};

export const updateDefaultCardset = (cardset) => {
	return (dispatch) => {
		if (typeof cardset === "string") {
			dispatch({ type: UPDATE_DEFAULT_CARDSET });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				defaultCardset: cardset,
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_DEFAULT_CARDSET);
		}
	};
};

export const updateDefaultCardsetSucceeded = () => {
	return { type: UPDATE_DEFAULT_CARDSET_SUCCEEDED };
};

export const updateDefaultCardsetFailed = () => {
	return { type: UPDATE_DEFAULT_CARDSET_FAILED };
};

export const openCardSortOrder = () => {
	return { type: OPEN_CARD_SORT_ORDER };
};

export const closeCardSortOrder = () => {
	return { type: CLOSE_CARD_SORT_ORDER };
};

export const updateCardSortOrder = (selectedSort, selectedSuit, selectedValue) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_CARD_SORT_ORDER });
		var updateMsg = {
			sMessageID: 0,
			type: "updateSettings",
		};
		if (selectedSort != undefined && selectedSort.name != undefined) updateMsg.cardSortOrder = selectedSort.name;
		if (selectedSuit != undefined && selectedSuit.name != undefined) updateMsg.cardSuitOrder = selectedSuit.name;
		if (selectedValue != undefined && selectedValue.name != undefined) updateMsg.cardValueOrder = selectedValue.name;
		MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_CARDS_ORDER);
	};
};

export const updateCardSortOrderSucceeded = () => {
	return { type: UPDATE_CARD_SORT_ORDER_SUCCEEDED };
};

export const updateCardSortOrderFailed = () => {
	return { type: UPDATE_CARD_SORT_ORDER_FAILED };
};

export const openBoardPreferences = () => {
	return { type: OPEN_BOARD_PREFERENCES };
};

export const closeBoardPreferences = () => {
	return { type: CLOSE_BOARD_PREFERENCES };
};

export const openFixBoardPositions = () => {
	return { type: OPEN_FIX_BOARD_POSITIONS };
};

export const closeFixBoardPositions = () => {
	return { type: CLOSE_FIX_BOARD_POSITIONS };
};

export const updateFixedPositions = (data) => {
	return (dispatch) => {
		if (typeof data !== undefined) {
			dispatch({ type: UPDATE_FIXED_POSITIONS });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				useLastMoveTracking: data.useLastMoveTracking,
				useFixedStartPosition: data.useFixedStartPosition,
				position2Players: data.position2PlayerGame,
				position3Players: data.position3PlayerGame,
				position4Players: data.position4PlayerGame,
				position5Players: data.position5PlayerGame,
				position6Players: data.position6PlayerGame,
				position7Players: data.position7PlayerGame,
				position8Players: data.position8PlayerGame,
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_FIXED_POSITIONS);
		}
	};
};

export const updateFixedPositionsSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_FIXED_POSITIONS_SUCCEEDED });
	};
};

export const updateFixedPositionsFailed = () => {
	return { type: UPDATE_FIXED_POSITIONS_FAILED };
};

export const openFixBoardColors = () => {
	return { type: OPEN_FIX_BOARD_COLORS };
};

export const closeFixBoardColors = () => {
	return { type: CLOSE_FIX_BOARD_COLORS };
};

export const openLanguagePreferences = () => {
	return { type: OPEN_LANGUAGE_PREFERENCES };
};

export const closeLanguagePreferences = () => {
	return { type: CLOSE_LANGUAGE_PREFERENCES };
};

export const openAppLanguageSettings = () => {
	return { type: OPEN_APP_LANGUAGE_SETTINGS };
};

export const closeAppLanguageSettings = () => {
	return { type: CLOSE_APP_LANGUAGE_SETTINGS };
};

export const updateAppLanguage = (language) => {
	return (dispatch) => {
		if (typeof language === "string") {
			dispatch({ type: UPDATE_APP_LANGUAGE });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				clientLanguage: language.toUpperCase(),
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_APP_LANGUAGE);
		}
	};
};

export const updateAppLanguageSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_APP_LANGUAGE_SUCCEEDED });
	};
};

export const updateAppLanguageFailed = () => {
	return { type: UPDATE_APP_LANGUAGE_FAILED };
};

export const openChatTranslationSettings = () => {
	return { type: OPEN_CHAT_TRANSLATION_SETTINGS };
};

export const closeChatTranslationSettings = () => {
	return { type: CLOSE_CHAT_TRANSLATION_SETTINGS };
};

export const updateChatTranslation = (option) => {
	return (dispatch) => {
		if (typeof option === "boolean") {
			dispatch({ type: UPDATE_CHAT_TRANSLATION });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				useChatTranslation: option,
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_CHAT_TRANSLATION);
		}
	};
};

export const updateChatTranslationSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_CHAT_TRANSLATION_SUCCEEDED });
	};
};

export const updateChatTranslationFailed = () => {
	return { type: UPDATE_CHAT_TRANSLATION_FAILED };
};

export const openNotificationsPreferences = () => {
	return { type: OPEN_NOTIFICATIONS_PREFERENCES };
};

export const closeNotificationsPreferences = () => {
	return { type: CLOSE_NOTIFICATIONS_PREFERENCES };
};

export const openEmailNotificationsSettings = () => {
	return { type: OPEN_EMAIL_NOTIFICATIONS_SETTINGS };
};

export const closeEmailNotificationsSettings = () => {
	return { type: CLOSE_EMAIL_NOTIFICATIONS_SETTINGS };
};

export const updateEmailNotifications = (data) => {
	return (dispatch) => {
		if (typeof data !== undefined) {
			dispatch({ type: UPDATE_EMAIL_NOTIFICATIONS });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				emailOnNewsLetter: data.newsletter,
				emailOnMessage: data.inGameMessages,
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_EMAIL_NOTIFICATIONS);
		}
	};
};

export const updateEmailNotificationsSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_EMAIL_NOTIFICATIONS_SUCCEEDED });
	};
};

export const updateEmailNotificationsFailed = () => {
	return { type: UPDATE_EMAIL_NOTIFICATIONS_FAILED };
};

export const openAppNotificationsSettings = () => {
	return { type: OPEN_APP_NOTIFICATIONS_SETTINGS };
};

export const closeAppNotificationsSettings = () => {
	return { type: CLOSE_APP_NOTIFICATIONS_SETTINGS };
};

export const updateAppNotifications = (data) => {
	return (dispatch) => {
		if (typeof data !== undefined) {
			dispatch({ type: UPDATE_APP_NOTIFICATIONS });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				appNotifyOnNewFriendGame: data.appNotifyOnNewFriendGame,
				appNotifyOnFriendOnline: data.appNotifyOnFriendOnline,
				appNotifyOnTournaments: data.appNotifyOnTournaments,
				appNotifyOnPrivateChat: data.appNotifyOnPrivateChat,
				appNotifyOnChat: data.appNotifyOnChat,
				appNotifyOnGroupChat: data.appNotifyOnGroupChat,
				appNotifyOnGroupOnline: data.appNotifyOnGroupOnline,
				appNotifyOnMessage: data.appNotifyOnMessage,
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_APP_NOTIFICATIONS);
		}
	};
};

export const updateAppNotificationsSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_APP_NOTIFICATIONS_SUCCEEDED });
	};
};

export const updateAppNotificationsFailed = () => {
	return { type: UPDATE_APP_NOTIFICATIONS_FAILED };
};

export const openScreenPreferences = () => {
	return { type: OPEN_SCREEN_PREFERENCES };
};

export const closeScreenPreferences = () => {
	return { type: CLOSE_SCREEN_PREFERENCES };
};

export const updateResolution = (resolution) => {
	return (dispatch) => {
		if (typeof resolution === "string") {
			dispatch({ type: UPDATE_RESOLUTION });
			const updateMsg = {
				sMessageID: 0,
				type: "updateSettings",
				resolution: resolution,
			};
			MyWebSocket.shared.sendMsg(updateMsg, SETTINGS_SCREEN.UPDATE_SCREEN_RESOLUTION);
		}
	};
};

export const updateResolutionSucceeded = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_RESOLUTION_SUCCEEDED });
	};
};

export const updateResolutionFailed = () => {
	return { type: UPDATE_RESOLUTION_FAILED };
};

export const openDeleteAccount = () => {
	return { type: OPEN_DELETE_ACCOUNT };
};

export const closeDeleteAccount = () => {
	return { type: CLOSE_DELETE_ACCOUNT };
};

export const openDeleteAccountReason = () => {
	return { type: OPEN_DELETE_ACCOUNT_REASON };
};

export const closeDeleteAccountReason = () => {
	return { type: CLOSE_DELETE_ACCOUNT_REASON };
};

export const openDeleteAccountFinal = () => {
	return { type: OPEN_DELETE_ACCOUNT_FINAL };
};

export const closeDeleteAccountFinal = () => {
	return { type: CLOSE_DELETE_ACCOUNT_FINAL };
};

export const openPlayersList = () => {
	return { type: OPEN_PLAYERS_LIST };
};

export const closePlayersList = () => {
	return { type: CLOSE_PLAYERS_LIST };
};

export const openSearchPlayers = () => {
	return { type: OPEN_SEARCH_PLAYERS };
};

export const closeSearchPlayers = () => {
	return { type: CLOSE_SEARCH_PLAYERS };
};

export const openHelp = () => {
	return { type: OPEN_HELP };
};

export const closeHelp = () => {
	return { type: CLOSE_HELP };
};

export const openTutorials = () => {
	return { type: OPEN_TUTORIALS };
};

export const closeTutorials = () => {
	return { type: CLOSE_TUTORIALS };
};

export const offerTutorial = () => {
	return (dispatch) => {
		const _tutMode = store.getState().app.welcome.tutorialMode;
		if (_tutMode != tutorialMode.DISABLE) {
			getData(storeKeys.TUTORIAL_OFFERED).then(async (offered) => {
				if (!offered || offered == "false") {
					switch (_tutMode) {
						case tutorialMode.ASK:
							const { currentLanguage } = store.getState().language;
							dispatch(
								openModalDialog(
									modalType.DIALOG,
									currentLanguage.messages.offerTutorialMessage,
									currentLanguage.no,
									() => {
										storeData(storeKeys.TUTORIAL_OFFERED, "true");
									},
									currentLanguage.yes,
									async () => {
										dispatch(goBackToMain());
										dispatch(openHelp());
										dispatch(openTutorials());
										storeData(storeKeys.TUTORIAL_OFFERED, "true");
										await Analytics.logEvent("viewGeneralTutorial");
									}
								)
							);
							break;
						case tutorialMode.FORCE:
							dispatch(goBackToMain());
							dispatch(openHelp());
							dispatch(openTutorials());
							storeData(storeKeys.TUTORIAL_OFFERED, "true");
							await Analytics.logEvent("viewGeneralTutorial");
							break;
					}
				}
			});
		}
	};
};

export const openSilverHelp = () => {
	return { type: OPEN_SILVER_HELP };
};

export const closeSilverHelp = () => {
	return { type: CLOSE_SILVER_HELP };
};

export const openGoldHelp = () => {
	return { type: OPEN_GOLD_HELP };
};

export const closeGoldHelp = () => {
	return { type: CLOSE_GOLD_HELP };
};

export const openShopHelp = () => {
	return { type: OPEN_SHOP_HELP };
};

export const closeShopHelp = () => {
	return { type: CLOSE_SHOP_HELP };
};

export const openVIPHelp = () => {
	return { type: OPEN_VIP_HELP };
};

export const closeVIPHelp = () => {
	return { type: CLOSE_VIP_HELP };
};

export const openVIPBenefitsHelp = () => {
	return { type: OPEN_VIP_BENEFITS_HELP };
};

export const closeVIPBenefitsHelp = () => {
	return { type: CLOSE_VIP_BENEFITS_HELP };
};

export const openGameItemsMenu = () => {
	return { type: OPEN_GAME_ITEMS_MENU };
};

export const closeGameItemsMenu = () => {
	return { type: CLOSE_GAME_ITEMS_MENU };
};

export const openEarnFreeSilver = () => {
	return { type: OPEN_EARN_FREE_SILVER };
};

export const closeEarnFreeSilver = () => {
	return { type: CLOSE_EARN_FREE_SILVER };
};

export const openInviteFriendsMenu = () => {
	return { type: OPEN_INVITE_FRIENDS_MENU };
};

export const closeInviteFriendsMenu = () => {
	return { type: CLOSE_INVITE_FRIENDS_MENU };
};

export const openEmailFriend = () => {
	return { type: OPEN_EMAIL_FRIEND };
};

export const closeEmailFriend = () => {
	return { type: CLOSE_EMAIL_FRIEND };
};

export const showEmailFriendPreview = () => {
	return { type: SHOW_EMAIL_FRIEND_PREVIEW };
};

export const closeEmailFriendPreview = () => {
	return { type: CLOSE_EMAIL_FRIEND_PREVIEW };
};

export const openRateForSilver = () => {
	return { type: OPEN_RATE_FOR_SILVER };
};

export const closeRateForSilver = () => {
	return { type: CLOSE_RATE_FOR_SILVER };
};

export const openRateDone = () => {
	return { type: OPEN_RATE_DONE };
};

export const closeRateDone = () => {
	return { type: CLOSE_RATE_DONE };
};

export const openGameMessage = () => {
	return { type: OPEN_GAME_MESSAGE };
};

export const closeGameMessage = () => {
	return { type: CLOSE_GAME_MESSAGE };
};

export const openTournamentTab = () => {
	return { type: TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_TAB };
};

export const closeTournamentTab = () => {
	return { type: TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_TAB };
};

export const openLeaderBoardsMenu = () => {
	return (dispatch) => {
		dispatch({ type: OPEN_LEADER_BOARDS_MENU });
		const { resolution } = store.getState().currentUser.preferences;
		if (resolution !== RESOLUTION.LOW) {
			if (isWebOrTablet) dispatch(openLeaderBoards(leaderboard_Type.GROUPS));
		}
	};
};

export const closeLeaderBoardsMenu = () => {
	return { type: CLOSE_LEADER_BOARDS_MENU };
};

export const openLeaderBoards = (type) => {
	return {
		type: OPEN_LEADER_BOARDS,
		payload: {
			type,
			lang: store.getState().language.currentLanguage.leaderBoardsMenu,
		},
	};
};

export const closeLeaderBoards = () => {
	return { type: CLOSE_LEADER_BOARDS };
};

export const saveLeaderBoardsData = (msg) => {
	return { type: SAVE_LB_DATA, payload: msg };
};

export const resetClient = () => {
	EventQueue.shared.resetEventsQueue();
	return { type: RESET_CLIENT };
};

const _setLoggedInState = (isLoggedIn) => {
	return { type: SET_LOGGED_IN_STATE, payload: isLoggedIn };
};

export const startUpgrade = () => {
	const urlApp = Platform.OS === "android" ? APP_STORE_URL.android : APP_STORE_URL.ios;
	Linking.canOpenURL(urlApp)
		.then((supported) => {
			if (!supported) {
				if (DEBUG_ENABLED) {
					console.log("Can't handle url: " + urlApp);
					// if (LOGGING_ENABLED) {
					//   dispatch(addLog({ title: "Can't handle url:", content: urlApp }));
					// }
				}
			} else {
				Linking.openURL(urlApp);
			}
		})
		.catch((err) => {
			if (DEBUG_ENABLED) {
				console.error("An error occurred", err);
				// if (LOGGING_ENABLED) {
				//   dispatch(addLog({ title: "An error occurred", content: err }));
				// }
			}
			handleError(err);
		});
};

const _handleWelcome = (msg) => {
	return { type: HANDLE_WELCOME, payload: msg };
};
