import { Platform } from "react-native";
import { BUTTON_PRESS_SOUND, BUY_SUCCEEDED_SOUND, mySounds, SAVE_SOUND } from "../../config/sounds";
import { mapObject } from "../../helpers/commonHelpers";
import Sound from "../../libraries/Sound/sound";
import { store } from "../../redux/store";
import { REGISTER_SOUNDS, TOGGLE_SYSTEM_SOUND } from "../actionTypes";

export const registerSounds = () => {
	const { internSoundSetURL } = store.getState().socket;
	return (dispatch) => {
		dispatch(_registerSounds(internSoundSetURL));
	};
};

export const toggleSystemSound = () => {
	return (dispatch) => {
		const { sounds } = store.getState();

		if (Platform.OS === "web") {
			const newVolume = sounds.isMuted ? 1 : 0;
			Sound.setSystemVolume(newVolume);
		} else {
			if (sounds.isMuted) {
				//unmute
				BUTTON_PRESS_SOUND.unmute();
				SAVE_SOUND.unmute();
				mapObject(BUY_SUCCEEDED_SOUND, (k, v) => v.unmute());
				mapObject(mySounds, (k, v) => {
					if (v instanceof Sound) v.unmute();
				});
			} else {
				//mute
				BUTTON_PRESS_SOUND.mute();
				SAVE_SOUND.mute();
				mapObject(BUY_SUCCEEDED_SOUND, (k, v) => v.mute());
				mapObject(mySounds, (k, v) => {
					if (v instanceof Sound) v.mute();
				});
			}
		}
		dispatch({ type: TOGGLE_SYSTEM_SOUND });
	};
};

const _registerSounds = (internSoundSetURL) => {
	return { type: REGISTER_SOUNDS, payload: { internSoundSetURL } };
};
