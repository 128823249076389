import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor, RESOLUTION } from "../../../../../../config/defaults";
import { gameDimensions } from "../../../../../../config/dimensions";
import {
	closeCardsPreferences,
	goBackToMain,
	openCardSortOrder,
	openDefaultCardset,
	openOverrideCardset,
} from "../../../../../../redux/actions";
import { ColoredButton, NavigationHeader } from "../../../../../common";
import CardSortOrder from "./CardSortOrder";
import DefaultCardset from "./DefaultCardset";
import OverrideCardset from "./OverrideCardset";

class CardsPreferences extends Component {
	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeCardsPreferences}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "sortOrder":
				textContent = this.props.lang.cardsPreferences.sortOrder;
				onPressFunc = () => {
					this.props.openCardSortOrder();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "default":
				textContent = this.props.lang.cardsPreferences.default;
				onPressFunc = () => {
					this.props.openDefaultCardset();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
			case "override":
				textContent = this.props.lang.cardsPreferences.override;
				onPressFunc = () => {
					this.props.openOverrideCardset();
				};
				_buttonColor = buttonColor.LIGHT_BLUE;
				break;
		}
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				additionalTextStyle={_buttonTextStyle}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderButton("sortOrder")}
					{this.renderButton("default")}
					{this.renderButton("override")}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.overrideCardsetOpened) {
			return <OverrideCardset />;
		} else if (this.props.defaultCardsetOpened) {
			return <DefaultCardset />;
		} else if (this.props.cardSortOrderOpened) {
			return <CardSortOrder />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		minHeight: "100%",
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		overrideCardsetOpened: state.app.overrideCardsetOpened,
		defaultCardsetOpened: state.app.defaultCardsetOpened,
		cardSortOrderOpened: state.app.cardSortOrderOpened,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeCardsPreferences,
	goBackToMain,
	openCardSortOrder,
	openOverrideCardset,
	openDefaultCardset,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsPreferences);
