import {
	CLOSE_SHOP_TAB,
	HIDE_BANNER,
	OPEN_CHAT_TAB,
	OPEN_CREATE_NEW_GAME,
	OPEN_GAMES_TAB,
	OPEN_GAME_END,
	OPEN_GAME_LOBBY,
	OPEN_GAME_ROOM,
	OPEN_SHOP_TAB,
	OPEN_STARTED_GAME,
	RESET_CLIENT,
	SHOW_BANNER,
} from "../actions/actionTypes";

const initialState = {
	isLobbyOpen: false,
	isCreateNewGameOpen: false,
	isGameRoomOpen: false,
	isGameStarted: false,
	isGameEndOpened: false,
	isGlobalChatOpen: false,
	isShopOpen: false,
	isTabGamesButtonActive: false,
	isTabChatButtonActive: false,
	isTabShopButtonActive: false,
	enableBanner: false,
	banner: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case OPEN_GAMES_TAB:
			var _isLobbyOpen = false,
				_isCreateNewGameOpen = false,
				_isGameRoomOpen = false,
				_isGameStarted = false;
			if (state.isLobbyOpen || (!state.isCreateNewGameOpen && !state.isGameRoomOpen && !state.isGameStarted))
				_isLobbyOpen = true;
			if (state.isCreateNewGameOpen) _isCreateNewGameOpen = true;
			if (state.isGameRoomOpen) _isGameRoomOpen = true;
			if (state.isGameStarted) _isGameStarted = true;
			return {
				...state,
				isLobbyOpen: _isLobbyOpen,
				isCreateNewGameOpen: _isCreateNewGameOpen,
				isGameRoomOpen: _isGameRoomOpen,
				isGameStarted: _isGameStarted,
				isTabGamesButtonActive: true,
				isTabChatButtonActive: false,
			};
		case OPEN_CHAT_TAB:
			return {
				...state,
				isGlobalChatOpen: true,
				isTabGamesButtonActive: false,
				isTabChatButtonActive: true,
			};
		case OPEN_SHOP_TAB:
			return {
				...state,
				isShopOpen: true,
				isTabShopButtonActive: true,
			};
		case CLOSE_SHOP_TAB:
			return {
				...state,
				isShopOpen: false,
				isTabShopButtonActive: false,
			};
		case OPEN_GAME_LOBBY:
			return {
				...state,
				isLobbyOpen: true,
				isCreateNewGameOpen: false,
				isGameRoomOpen: false,
				isGameStarted: false,
				isGameEndOpened: false,
			};
		case OPEN_CREATE_NEW_GAME:
			return {
				...state,
				isLobbyOpen: false,
				isCreateNewGameOpen: true,
				isGameRoomOpen: false,
				isGameStarted: false,
			};
		case OPEN_GAME_ROOM:
			return {
				...state,
				isLobbyOpen: false,
				isCreateNewGameOpen: false,
				isGameRoomOpen: true,
				isGameStarted: false,
			};
		case OPEN_STARTED_GAME:
			return {
				...state,
				isLobbyOpen: false,
				isCreateNewGameOpen: false,
				isGameRoomOpen: false,
				isGameStarted: true,
				isGameEndOpened: false,
			};
		case OPEN_GAME_END: {
			return { ...state, isGameEndOpened: true, isGameStarted: false };
		}
		case SHOW_BANNER:
			return { ...state, enableBanner: true };
		case HIDE_BANNER:
			return { ...state, enableBanner: false };
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
