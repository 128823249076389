import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { hexToColor } from "../config/defaults";
import { closePanelMenu, openPanelMenu, selectPawn } from "../redux/actions";
import { PlayerPanel } from "./common";

class PlayerPanelsContainer extends Component {
	//#region render methods
	render() {
		var plPanels = [];
		this.props.playerPanels.forEach((pp) => {
			var _startPositions = this.props.startPositions.filter((sp) => sp.slotID == pp.slotID);
			_startPositions.forEach((sp) => (sp.color = pp.color));
			var panelImgColor = pp.color && pp.color.normal ? hexToColor["#" + pp.color.normal] : "black";
			panelImgColor = panelImgColor == undefined ? "black" : panelImgColor;
			const _player = this.props.players["slot_" + pp.slotID];

			var teamMateColor = "yellow";
			if (this.props.teams == true) {
				const teamMateSlot = (pp.slotID + this.props.playerCnt / 2) % this.props.playerCnt;
				const teamMatePanel = this.props.playerPanels.find((p) => p.slotID == teamMateSlot);

				if (teamMatePanel != undefined && teamMatePanel.color && teamMatePanel.color.normal) {
					teamMateColor = hexToColor["#" + teamMatePanel.color.normal];
				}
			}
			plPanels.push(
				<PlayerPanel
					key={"pp_" + pp.slotID}
					gameTheme={this.props.gameTheme}
					playerCnt={Object.keys(this.props.players).length}
					playerPanel={pp}
					player={_player}
					isDealer={Number(pp.slotID) === Number(this.props.dealer)}
					isConnected={_player && _player.connectedState === "CONNECTED"}
					isLeft={_player && _player.connectedState === "LEFT"}
					isTeamEnabled={this.props.teams}
					teamMateColor={teamMateColor}
					color={panelImgColor}
					panelScale={this.props.playerPanelScale}
					positions={_startPositions}
					verticalStartPositions={this.props.verticalStartPositions}
					onFinishedPawnMoveAction={this.props.onFinishedPawnMoveAction}
					avatarURL={this.props.avatars}
					botAvatarURL={this.props.botAvatars}
					availableBots={this.props.availableBots}
					isMenuExpanded={this.props.expandPanelMenu}
					expandedPanelID={this.props.expandedPanelID}
					playerSlot={this.props.sitSlotID}
					closeMenu={this.props.closePanelMenu}
					openMenu={this.props.openPanelMenu}
					lang={this.props.lang}
				/>
			);
		});
		return <View key="playerPanelsContainer">{plPanels}</View>;
	}
	//#endregion
}

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		playerPanels: state.startedGame.gameBoard.playerPanels,
		dealer: state.startedGame.dealer,
		// teamMate: state.startedGame.teamMate,
		teams: state.startedGame.teams,
		playerCnt: state.startedGame.playerCnt,
		playerPanelScale: state.startedGame.gameBoard.playerPanelScale,
		startPositions: state.startedGame.gameBoard.startPositions,
		players: state.startedGame.players,
		avatars: state.app.welcome.webResources.avatars,
		botAvatars: state.app.welcome.webResources.botAvatars,
		availableBots: state.createNewGame.availableBots,
		expandPanelMenu: state.startedGame.expandPanelMenu,
		expandedPanelID: state.startedGame.expandedPanelID,
		sitSlotID: state.startedGame.sitSlotID,
		gameTheme: state.startedGame.gameTheme,
	};
};

const mapDispatchToProps = { closePanelMenu, openPanelMenu, selectPawn };

export default connect(mapStateToProps, mapDispatchToProps)(PlayerPanelsContainer);
