import React, { Component } from "react";
import { Animated, Image, Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { handleError } from "../../components/ErrorHandler";
import { mySounds } from "../../config/sounds";
import DebugLogger from "../../controller/DebugLogger";
import { store } from "../../redux/store";
import { hexToColor } from "../config/defaults";
import { baseEmptyPos, pawn_img } from "../config/images";
import { increaseMoveCount, resetLastMoveEffect, selectPawn } from "../redux/actions";

class ClassicPosition extends Component {
	constructor(props) {
		super(props);
		const btnScale = this.props.scale || 1;
		const movedVal =
			this.props.implicatedInMove && !this.props.moveStarter && !this.props.lastSwithed && !this.props.moveEnd ? 1 : 0;
		const starterVal =
			this.props.moveStarter && !this.props.implicatedInMove && !this.props.lastSwithed && !this.props.moveEnd ? 1 : 0;
		const switchedVal =
			(this.props.lastSwithed || this.props.moveEnd) && !this.props.moveStarter && !this.props.implicatedInMove ? 1 : 0;
		this.state = {
			pawnAnimation: this.props.filled ? new Animated.Value(1) : new Animated.Value(0.01),
			pawnHintAnimation: new Animated.Value(btnScale),
			movedEffect: new Animated.Value(movedVal),
			starterEffect: new Animated.Value(starterVal),
			switchedEffect: new Animated.Value(switchedVal),
		};
		this.positionHintInterval = null;
	}

	//#region lifecycle methods
	componentDidMount() {
		if (this.props.requestHint) this.hintPosition();
	}

	shouldComponentUpdate(nextProps) {
		//hint animation
		if (this.props.requestHint != undefined && this.props.requestHint != nextProps.requestHint) {
			if (nextProps.requestHint == true) {
				this.hintPosition();
			} else {
				this.resetPawnHintAnimation();
			}
		}
		//last move effect animations
		if (
			this.props.implicatedInMove !== nextProps.implicatedInMove ||
			this.props.lastSwithed !== nextProps.lastSwithed ||
			this.props.moveEnd !== nextProps.moveEnd ||
			this.props.moveStarter !== nextProps.moveStarter
		) {
			if (nextProps.implicatedInMove == true) {
				Animated.timing(this.state.movedEffect, {
					toValue: 1,
					duration: 5,
					delay: this.props.isPlayerToAct ? 900 : 1700,
					useNativeDriver: Platform.OS !== "web",
				}).start();
			} else {
				Animated.timing(this.state.movedEffect, {
					toValue: 0,
					duration: 750,
					useNativeDriver: Platform.OS !== "web",
				}).start();
				// this.state.movedEffect.setValue(0);
			}
			if (nextProps.moveStarter == true) {
				Animated.timing(this.state.starterEffect, {
					toValue: 1,
					duration: 5,
					delay: this.props.isPlayerToAct ? 900 : 1700,
					useNativeDriver: Platform.OS !== "web",
				}).start();
			} else {
				Animated.timing(this.state.starterEffect, {
					toValue: 0,
					duration: 750,
					useNativeDriver: Platform.OS !== "web",
				}).start();
				// this.state.starterEffect.setValue(0);
			}
			if (nextProps.lastSwithed == true || nextProps.moveEnd == true) {
				Animated.timing(this.state.switchedEffect, {
					toValue: 1,
					duration: 5,
					delay: nextProps.isPlayerToAct ? 900 : 1300,
					useNativeDriver: Platform.OS !== "web",
				}).start();
			} else {
				Animated.timing(this.state.switchedEffect, {
					toValue: 0,
					duration: 750,
					useNativeDriver: Platform.OS !== "web",
				}).start();
				// this.state.switchedEffect.setValue(0);
			}
		}

		/* if (this.props.moveStarter !== nextProps.moveStarter) {
      if (nextProps.moveStarter == true) {
        Animated.timing(this.state.starterEffect, {
          toValue: 1,
          duration: 5,
          delay: this.props.isPlayerToAct ? 900 : 1700,
          useNativeDriver: Platform.OS !== "web",
        }).start();
      } else {
        Animated.timing(this.state.starterEffect, {
          toValue: 0,
          duration: 500,
          useNativeDriver: Platform.OS !== "web",
        }).start();
        // this.state.starterEffect.setValue(0);
      }
    }

    if (
      this.props.lastSwithed !== nextProps.lastSwithed ||
      this.props.moveEnd !== nextProps.moveEnd
    ) {
      if (nextProps.lastSwithed == true || nextProps.moveEnd == true) {
        Animated.timing(this.state.switchedEffect, {
          toValue: 1,
          duration: 5,
          delay: nextProps.isPlayerToAct ? 900 : 1300,
          useNativeDriver: Platform.OS !== "web",
        }).start();
      } else {
        Animated.timing(this.state.switchedEffect, {
          toValue: 0,
          duration: 500,
          useNativeDriver: Platform.OS !== "web",
        }).start();
        // this.state.switchedEffect.setValue(0);
      }
    } */
		return true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filled == undefined && this.props.filled != undefined) {
			var _newVal = this.props.filled ? 1 : 0.01;
			this.state.pawnAnimation.setValue(_newVal);
		}

		if (
			this.props.moveToRollback !== null &&
			(prevProps.filled !== this.props.filled ||
				prevProps.pawnColor !== this.props.pawnColor ||
				(this.props.moveToRollback && this.props.moveToRollback.isSmash == true))
		) {
			var _newVal = this.props.filled ? 1 : 0.01;
			this.state.pawnAnimation.setValue(_newVal);
		}

		if (
			prevProps.filled !== this.props.filled &&
			prevProps.animatedMove == this.props.animatedMove &&
			this.props.moveToRollback === null
		) {
			var _newVal = this.props.filled ? 1 : 0.01;
			this.state.pawnAnimation.setValue(_newVal);
		}

		if (
			this.props.animatedMove != undefined &&
			prevProps.animatedMove != this.props.animatedMove &&
			(prevProps.filled !== this.props.filled || prevProps.pawnColor !== this.props.pawnColor)
		) {
			if (this.props.mainObj.requestSwapAnimation === true) {
				this.animateSwapMove(this.props.animatedMove);
			} else {
				this.animateMove(this.props.animatedMove);
			}
		}
	}

	componentWillUnmount() {
		if (this.positionHintInterval) clearInterval(this.positionHintInterval);
	}
	//#endregion

	//#region events
	finishMove = async () => {
		await DebugLogger.shared.saveLog(
			{
				finishMove_message: "Finished move in Position component, requesting next event's execution",
			},
			true
		);
		this.props.onFinishedPawnMoveAction();
	};

	animateMove() {
		const move = this.props.animatedMove;
		const { moveBackwardsSound, moveStartSound, moveNormalSound, completeSound, moveSmashSound } = mySounds;
		const { app, tab } = store.getState();
		const { isBackground, isSettingsOpened, isHelpOpened } = app;
		const { isShopOpen } = tab;
		if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
			if (move.playSound === true && move.isSmashMove === true) {
				const delay = move.isSevenMove === true ? (move.isLocalPlayer === true ? 400 : 1000) : 400;
				setTimeout(() => {
					moveSmashSound.play();
				}, delay + 300);
			}
			Animated.timing(this.state.pawnAnimation, {
				toValue: this.props.filled == true ? 1 : 0.01,
				duration: move.pawnsTransitionTime,
				delay: move.pawnsTransitionDelay + 300,
				useNativeDriver: Platform.OS !== "web",
			}).start((data) => {
				if (data.finished === true) {
					//play sound if not smash
					var soundDuration = 0,
						eventDuration = 0;
					if (move.playSound == true) {
						if (!move.isSmashMove) {
							if (move.isBackMove == true) {
								moveBackwardsSound.play();
								soundDuration = moveBackwardsSound.getDuration() * 1000;
								eventDuration =
									typeof move.pawnsTransitionTime === "number"
										? soundDuration + move.pawnsTransitionTime
										: soundDuration;
							} else {
								if (move.isStartMove == true) {
									moveStartSound.play();
									soundDuration = moveStartSound.getDuration() * 1000;
									eventDuration =
										typeof move.pawnsTransitionTime === "number"
											? soundDuration + move.pawnsTransitionTime
											: soundDuration;
								} else {
									moveNormalSound.play();
									soundDuration = moveNormalSound.getDuration() * 1000;
									eventDuration =
										typeof move.pawnsTransitionTime === "number"
											? soundDuration + move.pawnsTransitionTime + 400
											: soundDuration + 400;
								}
							}
							if (move.isCompletedMove == true) {
								const completeSD = completeSound.getDuration() * 1000;
								eventDuration = eventDuration + 100 + completeSD;
								const { app, tab } = store.getState();
								const { isBackground, isSettingsOpened, isHelpOpened } = app;
								const { isShopOpen } = tab;
								if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
									setTimeout(() => completeSound.play(), soundDuration + 100);
								} else {
									completeSound.play();
								}
							}
						} else {
							soundDuration = moveSmashSound.getDuration() * 1000;
							eventDuration =
								typeof move.pawnsTransitionTime === "number" ? soundDuration + move.pawnsTransitionTime : soundDuration;
						}
					}
					//finishing move
					if (move.requestFinish == true) {
						if (!move.isLocalPlayer && move.isDoubleMove == true) {
							this.props.increaseMoveCount();
						}
						const { app, tab } = store.getState();
						const { isBackground, isSettingsOpened, isHelpOpened } = app;
						const { isShopOpen } = tab;
						if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
							setTimeout(() => {
								this.finishMove();
							}, eventDuration);
						} else {
							this.finishMove();
						}
					}
				}
			});
		} else {
			var _newVal = this.props.filled ? 1 : 0.01;
			this.state.pawnAnimation.setValue(_newVal);
			//play sound if not smash
			if (move.playSound === true && move.isSmashMove === true) {
				moveSmashSound.play();
			}
			if (move.playSound == true) {
				if (!move.isSmashMove) {
					if (move.isBackMove == true) {
						moveBackwardsSound.play();
					} else {
						if (move.isStartMove == true) {
							moveStartSound.play();
						} else {
							moveNormalSound.play();
						}
					}
					if (move.isCompletedMove == true) completeSound.play();
				}
			}
			//finishing move
			if (move.requestFinish == true) {
				if (!move.isLocalPlayer && move.isDoubleMove == true) {
					this.props.increaseMoveCount();
				}
				this.finishMove();
			}
		}
	}

	animateSwapMove() {
		const move = this.props.animatedMove;
		const { moveJackSound } = mySounds;
		const { app, tab } = store.getState();
		const { isBackground, isSettingsOpened, isHelpOpened } = app;
		const { isShopOpen } = tab;
		if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
			Animated.timing(this.state.pawnAnimation, {
				toValue: 0.5,
				duration: move.pawnsTransitionTime || 0,
				delay: move.pawnsTransitionDelay || 0,
				useNativeDriver: Platform.OS !== "web",
			}).start((data) => {
				if (data.finished === true) {
					const { app, tab } = store.getState();
					const { isBackground, isSettingsOpened, isHelpOpened } = app;
					const { isShopOpen } = tab;
					if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
						Animated.timing(this.state.pawnAnimation, {
							toValue: 1,
							duration: move.pawnsTransitionTime || 0,
							useNativeDriver: Platform.OS !== "web",
						}).start((data) => {
							if (data.finished === true && move.requestFinish) {
								this.finishMove();
							}
						});
					} else {
						this.state.pawnAnimation.setValue(1);
						if (move.requestFinish == true) this.finishMove();
					}
				}
				if (move.playSound == true) moveJackSound.play();
			});
		} else {
			this.state.pawnAnimation.setValue(1);
			if (move.requestFinish == true) this.finishMove();
			if (move.playSound == true) moveJackSound.play();
		}
	}

	resetPawnHintAnimation(willUnmount = false) {
		try {
			if (this.positionHintInterval !== null) {
				clearInterval(this.positionHintInterval);
				this.positionHintInterval = null;
				if (!willUnmount) {
					const btnScale = this.props.scale || 1;
					this.state.pawnHintAnimation.setValue(btnScale);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	hintPosition() {
		try {
			if (this.positionHintInterval == null) {
				const btnScale = this.props.scale || 1;
				this.positionHintInterval = setInterval(() => {
					Animated.loop(
						Animated.sequence([
							Animated.timing(this.state.pawnHintAnimation, {
								toValue: btnScale * 1.15,
								duration: 300,
								useNativeDriver: Platform.OS !== "web",
							}),
							Animated.timing(this.state.pawnHintAnimation, {
								toValue: btnScale,
								duration: 300,
								useNativeDriver: Platform.OS !== "web",
							}),
						]),
						{
							iterations: 3,
							useNativeDriver: Platform.OS !== "web",
						}
					).start();
				}, 5000);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onPositionSelect() {
		this.props.resetLastMoveEffect();
		this.props.selectPawn(this.props.mainObj);
	}
	//#endregion

	//#region render methods
	render() {
		const _position = {
			top: this.props.top,
			left: this.props.left,
		};
		const isDisabled = this.props.playable !== true;
		const opacity = this.props.playable !== true && this.props.isPlayerToAct ? 0.5 : 1;

		var bColor = this.props.isBaseColored
			? this.props.baseColor != "#"
				? hexToColor["#" + this.props.baseColor]
				: "norm"
			: "norm";
		var pColor = this.props.pawnColor != "#" ? hexToColor["#" + this.props.pawnColor] : null;
		var isActive, isSelectable, lastSwithed, isStarter, isMoved;
		if (this.props.filled == true) {
			isActive = this.props.selected == true;
			isSelectable = !isDisabled && this.props.filtered && !this.props.selected;
			lastSwithed = this.props.lastSwithed == true || this.props.moveEnd == true;
		} else {
			isMoved = this.props.implicatedInMove == true;
		}
		isStarter = this.props.moveStarter == true;
		const onlyImplicated =
			isMoved && isDisabled && !lastSwithed && !isSelectable && !isActive && !lastSwithed && !isStarter;
		const onlyStarter =
			!isMoved && !lastSwithed && !isSelectable && !isActive && !lastSwithed && isDisabled && isStarter;

		return (
			<Animated.View
				data-pos-id={this.props.id}
				data-pos-top={this.props.top}
				data-pos-left={this.props.left}
				key={this.props.id || this.props.pos}
				style={[styles.posButton, _position, styles.round, { transform: [{ scale: this.state.pawnHintAnimation }] }]}
			>
				<TouchableOpacity
					key={this.props.id || this.props.pos}
					activeOpacity={1}
					disabled={isDisabled}
					onPress={this.onPositionSelect.bind(this)}
					style={{
						width: 25,
						height: 25,
						justifyContent: "center",
						borderRadius: 50,
					}}
					touchSoundDisabled={true}
				>
					{/* Position's base image */}
					<Image
						key="normal_base"
						source={baseEmptyPos.normal[bColor]}
						style={[
							styles.backImage,
							styles.round,
							{
								opacity:
									(isDisabled && !this.props.isPlayerToAct) ||
									(!isDisabled && !this.props.targeted) ||
									(!isDisabled && this.props.targeted)
										? 1
										: 0,
							},
						]}
						resizeMode="contain"
					/>
					<Image
						key="selectable_base"
						source={baseEmptyPos.selectable[bColor]}
						style={[
							styles.backImage,
							styles.round,
							{
								width: 31,
								height: 31,
								opacity: !isDisabled && this.props.targeted ? 1 : 0,
							},
						]}
						resizeMode="contain"
					/>
					<Image
						key="disabled_base"
						source={baseEmptyPos.disabled[bColor]}
						style={[
							styles.backImage,
							styles.round,
							{
								opacity:
									(isDisabled && this.props.isPlayerToAct && !isMoved && !isStarter) ||
									(this.props.isBaseColored && isDisabled && isMoved && this.props.isPlayerToAct) ||
									onlyImplicated ||
									onlyStarter
										? 1
										: 0,
							},
						]}
						resizeMode="contain"
					/>
					<Animated.Image
						key="moved_base"
						source={baseEmptyPos.moved[bColor]}
						style={[styles.backImage, styles.round, { opacity: this.state.movedEffect }]}
						resizeMode="contain"
					/>
					<Animated.Image
						key="starter_base"
						source={baseEmptyPos.starter[bColor]}
						style={[styles.backImage, styles.round, { opacity: this.state.starterEffect }]}
						resizeMode="contain"
					/>
					{/* Position's pawn */}
					<View
						key="v_normal_pawn"
						style={[styles.pawnImage, styles.round, { opacity: !isActive && !isSelectable ? opacity : 0 }]}
					>
						<Animated.Image
							key="normal_pawn"
							source={pawn_img.normal[bColor + "_b"][pColor]}
							style={[
								styles.round,
								{
									width: "100%",
									height: "100%",
									opacity: this.state.pawnAnimation,
								},
							]}
							resizeMode="contain"
						/>
					</View>
					<View
						key="v_selectable_pawn"
						style={[
							styles.pawnImage,
							styles.round,
							{
								opacity: isSelectable ? 1 : 0,
								width: isSelectable ? 23 : 19,
								height: isSelectable ? 23 : 19,
							},
						]}
					>
						<Animated.Image
							key="selectable_pawn"
							source={pawn_img.selectable[bColor + "_b"][pColor]}
							style={[
								styles.round,
								{
									width: "100%",
									height: "100%",
									opacity: this.state.pawnAnimation,
								},
							]}
							resizeMode="contain"
						/>
					</View>
					<View
						key="v_active_pawn"
						style={[
							styles.pawnImage,
							styles.round,
							{
								opacity: isActive ? 1 : 0,
								width: isActive ? 31 : 19,
								height: isActive ? 31 : 19,
							},
						]}
					>
						<Animated.Image
							key="active_pawn"
							source={pawn_img.active[bColor + "_b"][pColor]}
							style={[
								styles.round,
								{
									width: "100%",
									height: "100%",
									opacity: this.state.pawnAnimation,
								},
							]}
							resizeMode="contain"
						/>
					</View>
					<Animated.View
						key="v_switched_pawn"
						style={[
							styles.pawnImage,
							styles.round,
							{
								opacity: this.state.switchedEffect,
								width: lastSwithed ? 23 : 19,
								height: lastSwithed ? 23 : 19,
							},
						]}
					>
						<Animated.Image
							key="switched_pawn"
							source={pawn_img.switched[bColor + "_b"][pColor]}
							style={[
								styles.round,
								{
									width: "100%",
									height: "100%",
									opacity: this.state.pawnAnimation,
								},
							]}
							resizeMode="contain"
						/>
					</Animated.View>
				</TouchableOpacity>
			</Animated.View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	round: {
		borderRadius: 50,
	},
	posButton: {
		position: "absolute",
		width: 25,
		height: 25,
	},
	backImage: {
		alignSelf: "center",
		width: 19,
		height: 19,
		position: "absolute",
		zIndex: -1,
	},
	pawnImage: {
		alignSelf: "center",
		width: 19,
		height: 19,
		position: "absolute",
		zIndex: 1,
	},
});

const mapStateToProps = (state) => {
	return {
		moveToRollback: state.startedGame.moveToRollback,
		isPlayerToAct: state.startedGame.isPlayerToAct,
	};
};

const mapDispatchToProps = {
	increaseMoveCount,
	resetLastMoveEffect,
	selectPawn,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassicPosition);
