import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, processState, RESOLUTION } from "../../../../../config/defaults";
import { appNotifSettingsDimensions, gameDimensions } from "../../../../../config/dimensions";
import CheckBox from "../../../../../libraries/CheckBox";
import { closeEmailNotificationsSettings, goBackToMain, updateEmailNotifications } from "../../../../../redux/actions";
import Analytics from "../../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../../../../common";
import { handleError } from "../../../../ErrorHandler";

class EmailNotifications extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			newsletterEnabled: this.props.emailOnNewsLetter,
			messagesEnabled: this.props.emailOnMessage,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("EmailNotificationsPreferences");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.emailOnNewsLetter !== this.props.emailOnNewsLetter) {
			this.setState({ newsletterEnabled: this.props.emailOnNewsLetter });
		}
		if (prevProps.emailOnMessage !== this.props.emailOnMessage) {
			this.setState({ messagesEnabled: this.props.emailOnMessage });
		}
	}
	//#endregion

	//#region events
	onSavePress() {
		try {
			const data = {
				newsletter: this.state.newsletterEnabled,
				inGameMessages: this.state.messagesEnabled,
			};
			this.props.updateEmailNotifications(data);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateEmailNotificationsState != processState.STARTED}
				backAction={this.props.closeEmailNotificationsSettings}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderSaveButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onSavePress.bind(this)}
					textContent={this.props.lang.save}
					accessibilityLabel={this.props.lang.save}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderCheck(type) {
		var title = "",
			changeFunc = () => {},
			value;
		switch (type) {
			case "newsletter":
				title = this.props.lang.emailNotifications.newsletter;
				value = this.state.newsletterEnabled;
				changeFunc = () => {
					this.setState({ newsletterEnabled: !this.state.newsletterEnabled });
				};
				break;
			case "messages":
				title = this.props.lang.emailNotifications.messages;
				value = this.state.messagesEnabled;
				changeFunc = () => {
					this.setState({ messagesEnabled: !this.state.messagesEnabled });
				};
				break;
		}
		var _innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center" }],
			_inputLabel = [styles.dataTitle, styles.dataText],
			_checkBoxStyle = [
				Platform.OS === "web" ? styles.checkBox : { transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }] },
			];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center", height: 70 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_checkBoxStyle = [
				Platform.OS === "web"
					? [styles.checkBox, { width: 30, height: 30 }]
					: { transform: [{ scale: Platform.OS === "ios" ? 1 : 1.75 }] },
			];
		}
		return (
			<View style={_innerContainerStyle}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{title}
				</Text>
				<View style={styles.checkboxContainer}>
					<CheckBox value={value} onValueChange={changeFunc} style={_checkBoxStyle} />
				</View>
			</View>
		);
	}

	renderContent() {
		return (
			<View
				style={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				{this.renderCheck("newsletter")}
				{this.renderCheck("messages")}
				{this.renderSaveButton()}
			</View>
		);
	}

	render() {
		if (this.props.updateEmailNotificationsState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);

		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("screen");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height; // - 80;
			if (appNotifSettingsDimensions.width > availableWidth || appNotifSettingsDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / appNotifSettingsDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / appNotifSettingsDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	contentContainer: { flex: 1, paddingHorizontal: 20 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 2,
		marginBottom: 2,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 400,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	checkboxContainer: {
		width: 100,
		height: 40,
		flexDirection: "row",
		paddingTop: 5,
	},
	checkBox: { width: 20, height: 20, alignSelf: "center" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		emailOnNewsLetter: state.currentUser.preferences.emailOnNewsLetter,
		emailOnMessage: state.currentUser.preferences.emailOnMessage,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeEmailNotificationsSettings,
	goBackToMain,
	updateEmailNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailNotifications);
