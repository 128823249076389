import React, { Component } from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { appFont, f_sourcesansprobold } from "../../config/defaults";
import { MyWebSocket } from "../../connection";
import stringify from "../../helpers/emoji_stringify";
import { gameChat } from "../../Keezers/config/images";
import { focusOutGameChatInput } from "../../Keezers/redux/actions";
import { sendGlobalChatMessage } from "../../redux/actions";
import { changeStartedGameChatText, clearStartedGameChatText } from "../../redux/actions/SharedInputActions";
import { GameButton } from "../common";
import { handleError } from "../ErrorHandler";
import GameChatInput from "./GameChatInput";

class GameChatSender extends Component {
	focusInTimeout = null;

	//#region lifecycle methods
	shouldComponentUpdate(nextProps) {
		if (nextProps.focusChatInput == true && this.props.focusChatInput == false) {
			this.closeKeyboard();
			this.refs.gameChatInput.refs.gameChatInput.blur();
			if (this.focusInTimeout) {
				clearTimeout(this.focusInTimeout);
				this.focusInTimeout = null;
			}
			this.focusInTimeout = setTimeout(() => {
				this.refs.gameChatInput.refs.gameChatInput.focus();
			}, 100);
			return false;
		}
		return true;
	}

	componentWillUnmount() {
		if (this.focusInTimeout) {
			clearTimeout(this.focusInTimeout);
			this.focusInTimeout = null;
		}
	}
	//#endregion

	//#region events
	onSendPress() {
		try {
			const _disableSpectatorChat =
				this.props.isSpectator && this.props.isSpectatedGameClosed && this.props.activeRoom.chatRoomID == -1;
			if (!_disableSpectatorChat) {
				if (Platform.OS === "web") {
					this.refs.gameChatInput.refs.gameChatInput.submit();
					this.refs.gameChatInput.refs.gameChatInput.focus();
				} else {
					this.onSendMessage();
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onSendMessage() {
		try {
			if (this.refs.gameChatInput) {
				const msg = Platform.OS === "web" ? this.refs.gameChatInput.refs.gameChatInput.value : this.props.msgChat;
				const { activeRoom } = this.props;
				if (typeof msg === "string" && msg != "") {
					if (activeRoom != null) {
						const _msg = Platform.OS === "web" ? stringify(msg) : msg;
						if (activeRoom.chatRoomID == -1) {
							if (!this.props.isSpectator) {
								var msgGameChat = {
									content: _msg,
									type: "gameChat",
									sMessageID: 0,
									gameID: this.props.gameID,
								};
								MyWebSocket.shared.sendMsg(msgGameChat);
							} else {
								var msgSpectatorGameChat = {
									content: _msg,
									type: "spectatorGameChat",
									sMessageID: 0,
									gameID: this.props.gameID,
								};
								MyWebSocket.shared.sendMsg(msgSpectatorGameChat);
							}
						} else {
							this.props.sendGlobalChatMessage(_msg, activeRoom.chatRoomID);
						}
						this.closeKeyboard();
						this.props.clearStartedGameChatText();
					}
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	closeKeyboard() {
		if (Platform.OS !== "web") this.props.focusOutGameChatInput();
	}
	//#endregion

	//#region render methods
	renderBackImage() {
		const { border, corner, center } = gameChat.input;
		const _width = typeof this.props.width === "number" ? this.props.width + 2 : 332;
		const _height = 38;
		return (
			<View
				style={{
					width: _width,
					height: _height,
					position: "absolute",
					zIndex: -1,
				}}
			>
				<Image
					key="backTLC"
					source={corner.top_left}
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: 8,
						height: 7,
						position: "absolute",
						top: 0,
						left: 0,
					}}
				/>
				<Image
					key="backTB"
					source={border.top}
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: typeof _width === "number" ? _width - 13 : 56,
						height: 7,
						position: "absolute",
						top: 0,
						left: 7,
						zIndex: -1,
					}}
				/>
				<Image
					source={corner.top_right}
					key="backTRC"
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: 8,
						height: 7,
						position: "absolute",
						top: 0,
						right: 0,
					}}
				/>
				<Image
					key="backLB"
					source={border.left}
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: 8,
						height: _height - 12,
						position: "absolute",
						top: 6,
						left: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="backCenter"
					source={center}
					resizeMode={Platform.OS === "web" ? "stretch" : "stretch"}
					style={{
						width: typeof _width === "number" ? _width - 13 : 56,
						height: _height - 10,
						position: "absolute",
						top: 6,
						left: 7,
						zIndex: -1,
					}}
				/>
				<Image
					key="backRB"
					source={border.right}
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: 8,
						height: _height - 12,
						position: "absolute",
						top: 6,
						right: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="backBLC"
					source={corner.bottom_left}
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: 8,
						height: 8,
						position: "absolute",
						bottom: 0,
						left: 0,
						zIndex: -1,
					}}
				/>
				<Image
					key="backBB"
					source={border.bottom}
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: typeof _width === "number" ? _width - 13 : 56,
						height: 6,
						position: "absolute",
						bottom: 0,
						left: 7,
						zIndex: -1,
					}}
				/>
				<Image
					key="backBRC"
					source={corner.bottom_right}
					resizeMode={Platform.OS === "web" ? "repeat" : "cover"}
					style={{
						width: 8,
						height: 8,
						position: "absolute",
						bottom: 0,
						right: 0,
						zIndex: -1,
					}}
				/>
			</View>
		);
	}

	render() {
		const _disableSpectatorChat =
			this.props.isSpectator && this.props.isSpectatedGameClosed && this.props.activeRoom.chatRoomID == -1;
		return (
			<View
				style={[
					styles.chatInputContainer,
					{
						width: this.props.width + 2,
						height: Platform.OS === "web" ? 40 : 0,
						opacity: Platform.OS === "web" ? 1 : 0,
						left: -1,
					},
				]}
			>
				{Platform.OS === "web" && this.renderBackImage()}
				{(Platform.OS !== "web" || this.props.boardLoaded) && (
					<GameChatInput
						ref="gameChatInput"
						activeRoom={this.props.activeRoom}
						msgChat={this.props.msgChat}
						onSendMessage={this.onSendMessage.bind(this)}
						onKeyboardClose={this.closeKeyboard.bind(this)}
						onTextChange={this.props.changeStartedGameChatText}
						lang={this.props.lang}
						inputWidth={this.props.width - 66}
						disableChat={_disableSpectatorChat}
					/>
				)}
				{Platform.OS === "web" && !this.props.boardLoaded && <View style={{ width: this.props.width - 66 }} />}
				{Platform.OS === "web" && (
					<View style={styles.sendButton}>
						<GameButton
							onPress={this.onSendPress.bind(this)}
							silentPress={true}
							disabled={_disableSpectatorChat}
							myStyle={styles.buttonStyle}
							textContent={this.props.lang.chat.toUpperCase()}
							textContentStyle={styles.buttonText}
							backgroundImage={{
								uri: gameChat.send_back,
								resizeMode: "stretch",
								style: styles.buttonBackImage,
							}}
						/>
					</View>
				)}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	chatInputContainer: {
		flexDirection: "row",
		width: "100%",
		position: "absolute",
		bottom: -3,
		left: 0,
		zIndex: 5,
	},
	inputTextImage: {
		position: "absolute",
		zIndex: -1,
		width: "100%",
		height: "100%",
		marginTop: Platform.OS === "web" ? -1 : 0,
	},
	font: {
		fontFamily: appFont,
		fontSize: 12,
		color: "#ddd",
	},
	sendButton: {
		width: 70,
		height: 40,
		paddingTop: 1,
	},
	buttonStyle: {
		backgroundColor: "transparent",
		borderWidth: 0,
		width: 64,
		height: 30,
	},
	buttonText: {
		width: 64,
		height: 30,
		fontFamily: f_sourcesansprobold,
		fontSize: 12,
		textAlign: "center",
		color: "#fff",
		textShadowColor: "#297422",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 2,
		paddingTop: 7,
		paddingBottom: 7,
	},
	buttonBackImage: {
		width: 64,
		height: 30,
		marginTop: 3,
		marginBottom: 3,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		gameID: state.startedGame.gameID,
		isSpectator: state.startedGame.isSpectator,
		isSpectatedGameClosed: state.startedGame.isSpectatedGameClosed,
		focusChatInput: state.startedGame.focusChatInput,
		msgChat: state.sharedInput.msgStartedGameChat,
		boardLoaded: state.startedGame.boardLoaded,
	};
};

const mapDispatchToProps = {
	changeStartedGameChatText,
	clearStartedGameChatText,
	focusOutGameChatInput,
	sendGlobalChatMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameChatSender);
