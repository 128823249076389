import React, { Component } from "react";
import { Dimensions, FlatList, Image, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { connect } from "react-redux";
import { f_sourcesansprobold, isTablet, isWebOrTablet, RESOLUTION } from "../../config/defaults";
import { silverShopDimensions } from "../../config/dimensions";
import { NEXT_ICON, PREVIOUS_ICON } from "../../config/images";
import ScrollContainer from "../../libraries/ScrollContainer";
import {
	buyItem,
	closeCardsetsShop,
	closeGameItemsMenu,
	goBackToMain,
	openGoldHelp,
	openGoldShop,
	openSilverHelp,
	openSilverShop,
} from "../../redux/actions";
import { AppTouchableOpacity, NavigationHeader } from "../common";
import { handleError } from "../ErrorHandler";
import GoldHelp from "../Main/Help/GoldHelp";
import SilverHelp from "../Main/Help/SilverHelp";
import GameShopItem from "./GameShopItem";
import GameShopItemDetails from "./GameShopItemDetails";
import GameShopStack from "./GameShopStack";

class CardsetsShop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			firstViewableIndex: 0,
			lastViewableIndex: 0,
			selectedItem: null,
			showItemDetails: false,
			bottomWidth: 0,
		};

		this.handleViewableItemsChanged = this.onViewableItemsChanged.bind(this);
		this.viewabilityConfig = { itemVisiblePercentThreshold: 90 };
		this.lastOffset = 0;
	}

	//#region lifecycle methods
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.itemDetailsOpened && !this.props.itemDetailsOpened) {
			this.setState({ showItemDetails: this.props.itemDetailsOpened });
		}
		if (prevState.showItemDetails != this.state.showItemDetails) {
			if (typeof this.props.toggleItemDetails === "function") this.props.toggleItemDetails(this.state.showItemDetails);
		}
	}
	//#endregion

	//#region events
	onViewableItemsChanged = ({ viewableItems, changed }) => {
		try {
			if (viewableItems.length > 0) {
				this.setState({
					firstViewableIndex: viewableItems[0].index,
					lastViewableIndex: viewableItems[viewableItems.length - 1].index,
				});
			}
		} catch (error) {
			handleError(error);
		}
	};

	onPreviousPress() {
		try {
			const nextIndex = this.state.firstViewableIndex - 1;
			if (nextIndex >= 0) {
				this.refs.itemsList.scrollToIndex({
					index: nextIndex,
					viewPosition: 0,
				});
			} else {
				this.refs.itemsList.scrollToIndex({
					index: this.state.firstViewableIndex,
					viewPosition: 0,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onNextPress() {
		try {
			const nextIndex = this.state.lastViewableIndex + 1;
			if (nextIndex < this.props.cardsetsInventory.length) {
				this.refs.itemsList.scrollToIndex({
					index: nextIndex,
					viewPosition: 1,
				});
			} else {
				this.refs.itemsList.scrollToIndex({
					index: this.state.lastViewableIndex,
					viewPosition: 1,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onItemSelect(item) {
		try {
			this.setState({ selectedItem: item, showItemDetails: true });
		} catch (error) {
			handleError(error);
		}
	}

	onBuyItemPress(item, selectedPrice) {
		try {
			if (this.state.showItemDetails) this.setState({ selectedItem: null, showItemDetails: false });
			this.props.buyItem(item, selectedPrice);
		} catch (error) {
			handleError(error);
		}
	}

	onBackPress() {
		try {
			if (this.state.selectedItem == null) {
				this.props.closeCardsetsShop();
			} else {
				this.setState({ selectedItem: null, showItemDetails: false });
			}
		} catch (error) {
			handleError(error);
		}
	}

	onClosePress() {
		try {
			this.props.goBackToMain();
		} catch (error) {
			handleError(error);
		}
	}

	onGoldErrorButtonPress() {
		try {
			this.props.closeCardsetsShop();
			this.props.closeGameItemsMenu();
			this.props.openGoldShop();
		} catch (error) {
			handleError(error);
		}
	}

	onSilverErrorButtonPress() {
		try {
			this.props.closeCardsetsShop();
			this.props.closeGameItemsMenu();
			this.props.openSilverShop();
		} catch (error) {
			handleError(error);
		}
	}

	onScrollToIndexFailed(error) {
		if (error != undefined) {
			this.refs.itemsList.scrollToOffset({
				offset: error.averageItemLength * error.index,
				animated: false,
			});
			setTimeout(() => {
				if (this.props.cardsetsInventory.length !== 0 && this.refs.itemsList !== null) {
					this.refs.itemsList.scrollToIndex({
						index: error.index,
						viewPosition: 0.5,
						animated: false,
					});
				}
			}, 50);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.onClosePress.bind(this)}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			>
				<GameShopStack />
			</NavigationHeader>
		);
	}

	renderTopContent() {
		try {
			var title_text = "",
				main_text = this.props.lang.gameShop.cardsetsShop.main_title;
			if (this.props.mainCat) {
				title_text = this.props.mainCat.title || "";
				main_text = this.props.mainCat.explanation || "";
			}
			const regex = /_/gi;
			main_text = main_text.replace("#1#", "100");
			var main_text_i = main_text.indexOf("_");
			var main_text_j = main_text.lastIndexOf("_");
			if (main_text_i != -1 && main_text_j != -1) var linkMainText = main_text.substring(main_text_i, ++main_text_j);
			var mainTextLink = "";
			if (linkMainText != undefined) {
				mainTextLink = linkMainText.replace(regex, "");
				main_text = main_text.replace(linkMainText, mainTextLink);
			}

			var scrollViewHeight = isWebOrTablet
				? Dimensions.get("window").height - 325
				: Dimensions.get("screen").height - 275;
			scrollViewHeight = scrollViewHeight < 70 ? 70 : scrollViewHeight;
			return (
				<View
					style={[
						styles.topContainer,
						{ maxHeight: scrollViewHeight },
						Platform.OS === "web" && {
							flexShrink: 1,
							flexWrap: "nowrap",
							minHeight: 70,
						},
					]}
				>
					{title_text != "" && (
						<View style={styles.titleContainer}>
							<Text
								allowFontScaling={false}
								style={[styles.titleStyle, this.props.resolution === RESOLUTION.HIGH && { fontSize: 30 }]}
							>
								{title_text}
							</Text>
						</View>
					)}
					{isWebOrTablet && (
						<ScrollView style={[styles.contentContainer, Platform.OS === "web" && { flex: 1 }]}>
							{mainTextLink != "" && (
								<ParsedText
									style={[
										styles.detailText,
										this.props.resolution === RESOLUTION.HIGH && {
											fontSize: isWebOrTablet ? 22 : 20,
										},
									]}
									parse={[
										{
											pattern: new RegExp(mainTextLink),
											style: [
												styles.linkText,
												this.props.resolution === RESOLUTION.HIGH && {
													fontSize: isWebOrTablet ? 22 : 20,
												},
											],
											onPress: () => {},
											renderText: () => mainTextLink,
										},
									]}
								>
									{main_text}
								</ParsedText>
							)}
							{mainTextLink == "" && (
								<Text
									allowFontScaling={false}
									style={[
										styles.detailText,
										this.props.resolution === RESOLUTION.HIGH && {
											fontSize: isWebOrTablet ? 24 : 22,
										},
									]}
								>
									{main_text}
								</Text>
							)}
						</ScrollView>
					)}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderItems(item, index) {
		const isHidden = index < this.state.firstViewableIndex || index > this.state.lastViewableIndex;
		return (
			<GameShopItem
				key={"item_" + item.itemID.toString()}
				item={item}
				onItemSelect={() => this.onItemSelect(item)}
				onCurrencyPricePress={() => this.onBuyItemPress(item, "currency")}
				onSilverPricePress={() => this.onBuyItemPress(item, "silver")}
				onGoldPricePress={() => this.onBuyItemPress(item, "gold")}
				accessibilityElementsHidden={isHidden}
				resolution={this.props.resolution}
			/>
		);
	}

	renderMobileBottomContent() {
		const { lastViewableIndex, firstViewableIndex } = this.state;
		const viewableItemsNr = lastViewableIndex - firstViewableIndex;
		const _nextDis =
			lastViewableIndex == this.props.cardsetsInventory.length - 1 || this.props.cardsetsInventory.length == 0;
		return (
			<View style={[styles.bottomContainer, this.props.resolution === RESOLUTION.HIGH && { minHeight: 360 }]}>
				<AppTouchableOpacity
					disabled={firstViewableIndex == 0}
					style={[styles.toggleButton, { marginRight: 5 }]}
					onPress={this.onPreviousPress.bind(this)}
					accessibilityState={{ disabled: firstViewableIndex == 0 }}
					accessibilityLabel={"scroll to previous item"}
					accessibilityElementsHidden={firstViewableIndex !== 0}
					importantForAccessibility={firstViewableIndex !== 0 ? "yes" : "no-hide-descendants"}
					renderInView={true}
				>
					<Image source={PREVIOUS_ICON} style={[styles.toggleButton, { opacity: firstViewableIndex == 0 ? 0 : 1 }]} />
				</AppTouchableOpacity>
				<FlatList
					viewabilityConfig={this.viewabilityConfig}
					onViewableItemsChanged={this.handleViewableItemsChanged}
					ref={"itemsList"}
					horizontal={true}
					directionalLockEnabled={true}
					showsHorizontalScrollIndicator={false}
					keyExtractor={(item) => "item_" + item.itemID.toString()}
					data={this.props.cardsetsInventory}
					extraData={this.props.cardsetsInventory}
					renderItem={({ item, index }) => this.renderItems(item, index)}
					onScrollToIndexFailed={(error) => this.onScrollToIndexFailed(error)}
					style={styles.fullFlex}
					contentContainerStyle={[
						styles.listContainer,
						viewableItemsNr == this.props.cardsetsInventory.length - 1 ? styles.fullFlex : {},
					]}
				/>
				<AppTouchableOpacity
					disabled={_nextDis}
					style={[styles.toggleButton, { marginLeft: 5 }]}
					onPress={this.onNextPress.bind(this)}
					accessibilityState={{ disabled: _nextDis }}
					accessibilityLabel={"scroll to next item"}
					accessibilityElementsHidden={_nextDis}
					importantForAccessibility={!_nextDis ? "yes" : "no-hide-descendants"}
					renderInView={true}
				>
					<Image
						source={NEXT_ICON}
						style={[
							styles.toggleButton,
							{
								opacity:
									lastViewableIndex == this.props.cardsetsInventory.length - 1 ||
									this.props.cardsetsInventory.length == 0
										? 0
										: 1,
							},
						]}
					/>
				</AppTouchableOpacity>
			</View>
		);
	}

	renderScrollableItems() {
		const items = [];
		this.props.cardsetsInventory.forEach((item, index) => items.push(this.renderItems(item, index)));
		return items;
	}

	renderWebBottomContent() {
		const maxViewableItemsNr =
			this.props.resolution === RESOLUTION.HIGH
				? Math.round(this.state.bottomWidth / 235)
				: Math.round(this.state.bottomWidth / 180);
		const moreThanViewport = maxViewableItemsNr > this.props.cardsetsInventory.length;
		return (
			<View
				style={[
					styles.bottomContainer,
					{ paddingHorizontal: moreThanViewport ? 50 : 35 },
					this.props.resolution === RESOLUTION.HIGH && { minHeight: 340 },
				]}
				onLayout={(event) => this.setState({ bottomWidth: event.nativeEvent.layout.width - 120 })}
			>
				<ScrollContainer
					className="scroll-container"
					style={{
						display: "flex",
						width: "100%",
						flexDirection: "row",
						justifyContent: moreThanViewport ? "space-evenly" : "space-between",
						alignItems: "center",
						cursor: "grab",
						overflow: "scroll",
					}}
				>
					{this.renderScrollableItems()}
				</ScrollContainer>
			</View>
		);
	}

	renderBottomContent() {
		if (Platform.OS === "web") return this.renderWebBottomContent();
		return this.renderMobileBottomContent();
	}

	renderContent() {
		return (
			<View
				style={[
					styles.fullFlex,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				{!this.state.showItemDetails && this.renderTopContent()}
				{!this.state.showItemDetails && this.renderBottomContent()}
				{this.state.showItemDetails && (
					<GameShopItemDetails
						item={this.state.selectedItem}
						resolution={this.props.resolution}
						onSilverPricePress={() => this.onBuyItemPress(this.state.selectedItem, "silver")}
						onCurrencyPricePress={() => this.onBuyItemPress(this.state.selectedItem, "currency")}
						onGoldPricePress={() => this.onBuyItemPress(this.state.selectedItem, "gold")}
					/>
				)}
			</View>
		);
	}

	render() {
		if (this.props.silverHelpOpened) {
			return <SilverHelp />;
		} else if (this.props.goldHelpOpened) {
			return <GoldHelp />;
		} else {
			return <View style={styles.fullFlex}>{this.renderContent()}</View>;
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("screen");
			const availableWidth = windowDim.width;
			const availableHeight = windowDim.height - Math.round(50 - 50 / windowDim.scale);
			if (silverShopDimensions.width > availableWidth || silverShopDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / silverShopDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / silverShopDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	fullFlex: { flex: 1 },
	topContainer: {
		marginBottom: 5,
		marginHorizontal: 10,
		flex: isWebOrTablet ? 1 : undefined,
	},
	bottomContainer: {
		flex: !isWebOrTablet ? 1 : undefined,
		width: !isWebOrTablet ? "100%" : undefined,
		flexDirection: "row",
		justifyContent: "center",
		minHeight: Platform.OS === "web" ? 300 : 250,
		marginHorizontal: isWebOrTablet ? 10 : 0,
	},
	listContainer: {
		alignItems: "center",
		justifyContent: "space-evenly",
	},
	contentContainer: {
		marginTop: isWebOrTablet ? 5 : 3,
		marginBottom: isWebOrTablet ? 10 : 5,
		marginLeft: Platform.OS === "web" ? 80 : isTablet ? 70 : 10,
		marginRight: Platform.OS === "web" ? 80 : isTablet ? 60 : 10,
		paddingHorizontal: isWebOrTablet ? 20 : 10,
		borderWidth: isWebOrTablet ? 3 : 2,
		borderColor: "#278082",
		borderRadius: isWebOrTablet ? 25 : 15,
		paddingVertical: isWebOrTablet ? 10 : 5,
		backgroundColor: "rgba(24, 63, 63, 0.8)",
	},
	titleContainer: { width: "100%", height: 32 },
	titleStyle: {
		width: "100%",
		lineHeight: 32,
		fontFamily: f_sourcesansprobold,
		fontSize: 24,
		textAlign: "center",
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
	},
	detailText: {
		fontFamily: f_sourcesansprobold,
		fontSize: isWebOrTablet ? 18 : 16,
		color: "#ebf1f1",
	},
	linkText: {
		fontSize: 18,
		color: "#E6EB13",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
		fontStyle: "italic",
	},
	toggleButton: {
		width: 50,
		height: 50,
		alignSelf: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		silverHelpOpened: state.app.silverHelpOpened,
		goldHelpOpened: state.app.goldHelpOpened,
		cardsetsInventory: state.gameShop.cardsetsInventory,
		shopURL: state.app.welcome.webResources.shop,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {
	buyItem,
	closeCardsetsShop,
	closeGameItemsMenu,
	goBackToMain,
	openGoldHelp,
	openGoldShop,
	openSilverHelp,
	openSilverShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsetsShop);
