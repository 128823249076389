import { leaderboard_Type, LEADER_BOARD_SIZE } from "../../config/defaults";
import { MyWebSocket } from "../../connection";
import { SELECT_LB_CATEGORY, SELECT_LB_GROUP } from "../actionTypes";
import { store } from "../store";

export const selectLBCategory = (category) => {
	return { type: SELECT_LB_CATEGORY, payload: category };
};

export const selectLBGroup = (group) => {
	return { type: SELECT_LB_GROUP, payload: group };
};

export const requestLeaderBoard = (maxVisibleRows, isRelative) => {
	return (dispatch) => {
		const { lbCategorySelected, lbTypeSelected } = store.getState().leaderboard;
		var leaderBoardMsg = {
			sMessageID: 0,
			type: "getLeaderBoard",
			size: 10,
			friendsOnly: false,
			timeFrame: lbCategorySelected,
			maxVisibleRows: maxVisibleRows,
		};
		switch (lbTypeSelected) {
			case leaderboard_Type.GLOBAL:
				leaderBoardMsg.size = LEADER_BOARD_SIZE.GLOBAL;
				leaderBoardMsg.relative = isRelative == true ? true : false;
				MyWebSocket.shared.sendMsg(leaderBoardMsg);
				break;
			case leaderboard_Type.FRIENDS:
				leaderBoardMsg.size = LEADER_BOARD_SIZE.FRIENDS;
				leaderBoardMsg.type = "getLeaderBoardForFriends";
				leaderBoardMsg.friendsOnly = true;
				MyWebSocket.shared.sendMsg(leaderBoardMsg);
				break;
			case leaderboard_Type.GROUPS:
				const { leaderboard } = store.getState();
				const selGroupIndex = leaderboard.groups.findIndex((gr) => gr.selected == true);
				switch (selGroupIndex) {
					case 0:
						leaderBoardMsg.size = LEADER_BOARD_SIZE.GLOBAL;
						leaderBoardMsg.relative = isRelative == true ? true : false;
						MyWebSocket.shared.sendMsg(leaderBoardMsg);
						break;
					case 1:
						leaderBoardMsg.size = LEADER_BOARD_SIZE.FRIENDS;
						leaderBoardMsg.type = "getLeaderBoardForFriends";
						leaderBoardMsg.friendsOnly = true;
						MyWebSocket.shared.sendMsg(leaderBoardMsg);
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	};
};
