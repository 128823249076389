import React, { Component } from "react";
import { Animated, Platform, StyleSheet, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import Analytics from "../../components/Analytics/Analytics";
import { handleError } from "../../components/ErrorHandler";
import { appFont } from "../../config/defaults";
import { MyWebSocket } from "../../connection";
import { EventQueue } from "../../controller";
import DebugLogger from "../../controller/DebugLogger";
import { openGameEnd, openGameLobby, resetCreateNewGame, resetGameEnd, resetGameRoom } from "../../redux/actions";
import { store } from "../../redux/store";
import { finishCountdown, resetLastMoveEffect, throwAway } from "../redux/actions";

class ThrowAwayButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			throwAwayHintAnim: new Animated.Value(this.props.scale),
		};
		this.throwAwayHintInterval = null;
		this.openGameEndTimeout = null;
	}

	//#region lifecycle methods
	componentDidUpdate(prevProps) {
		if (prevProps.isThrowAwayAction == true && this.props.isThrowAwayAction == false) {
			this.stopHintingAnimation();
		}

		if (prevProps.isGameEnded == false && this.props.isGameEnded == true) {
			this.stopHintingAnimation();
			this.startAutomaticOpenGameEnd();
		}
	}

	componentWillUnmount() {
		this.stopHintingAnimation(true);
		this.stopAutomaticOpenGameEnd();
	}
	//#endregion

	//#region events
	startAutomaticOpenGameEnd() {
		if (this.props.secondsBeforeClose >= 0) {
			this.stopAutomaticOpenGameEnd();
			this.openGameEndTimeout = setTimeout(() => {
				this.onExitGamePress();
			}, this.props.secondsBeforeClose * 1000);
		}
	}

	stopAutomaticOpenGameEnd() {
		clearTimeout(this.openGameEndTimeout);
		this.openGameEndTimeout = null;
	}

	stopHintingAnimation(willUnmount = false) {
		try {
			if (this.throwAwayHintInterval !== null) {
				clearInterval(this.throwAwayHintInterval);
				this.throwAwayHintInterval = null;
				if (!willUnmount) {
					this.state.throwAwayHintAnim.setValue(this.props.scale);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	hintThrowAway() {
		try {
			this.stopHintingAnimation();
			if (this.props.isThrowAwayAction) {
				this.throwAwayHintInterval = setInterval(() => {
					Animated.loop(
						Animated.sequence([
							Animated.timing(this.state.throwAwayHintAnim, {
								toValue: this.props.scale * 1.15,
								duration: 300,
								useNativeDriver: Platform.OS !== "web",
							}),
							Animated.timing(this.state.throwAwayHintAnim, {
								toValue: this.props.scale,
								duration: 300,
								useNativeDriver: Platform.OS !== "web",
							}),
						]),
						{
							iterations: 3,
							useNativeDriver: Platform.OS !== "web",
						}
					).start();
				}, 5000);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onThrowAwayPress() {
		try {
			this.props.resetLastMoveEffect();
			const { startedGame } = store.getState();
			const { gamePlayerToAct, cards } = startedGame;
			var msgPlayerAct = {
				sMessageID: 0,
				type: "playerAct",
				gameID: this.props.gameID,
				moveID: 0,
				actID: startedGame.gamePlayerToAct.actID,
				card: "*",
				toLate: false,
			};
			var selectedMove = gamePlayerToAct.availableMoves[0];
			selectedMove.cards = [];
			cards.forEach((card) => selectedMove.cards.push(card.id));
			const moveToSave = {
				saveMove: true,
				selectedMove: selectedMove,
			};
			MyWebSocket.shared.sendMsg(msgPlayerAct, "", moveToSave);
			this.props.throwAway(msgPlayerAct, moveToSave);
		} catch (error) {
			handleError(error);
		}
	}

	async onExitGamePress() {
		try {
			this.stopAutomaticOpenGameEnd();
			if (!this.props.isSpectator) {
				this.props.openGameEnd();
				if (this.props.gameID != -1) {
					const msgGamePlayerLeave = {
						type: "leaveGame",
						sMessageID: 0,
						gameID: this.props.gameID,
					};
					MyWebSocket.shared.sendMsg(msgGamePlayerLeave);
					DebugLogger.shared.storedForReset = [];
					await Analytics.logEvent("finishedGame");
				}
			} else {
				if (this.props.gameID != -1) {
					if (!this.props.isSpectatedGameClosed) {
						const msgStopSpectatingGame = {
							type: "stopSpectatingGame",
							sMessageID: 0,
							gameID: this.props.gameID,
						};
						MyWebSocket.shared.sendMsg(msgStopSpectatingGame);
					}
					DebugLogger.shared.storedForReset = [];
					await Analytics.logEvent("stopSpectatingGame");
					EventQueue.shared.resetEventsQueue();
					this.props.finishCountdown();
					this.props.resetGameEnd();
					this.props.resetGameRoom();
					this.props.resetCreateNewGame();
					this.props.openGameLobby();
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		if (this.props.isSpectator) {
			if (!this.props.isGameEnded && !this.props.isSpectatedGameClosed) return null;
		} else {
			if (!this.props.isThrowAwayAction && !this.props.isGameEnded) return null;
		}
		const textContent =
			this.props.isGameEnded || (this.props.isSpectator && this.props.isSpectatedGameClosed)
				? this.props.lang.btnGameEnd
				: this.props.lang.btnThrowAway;
		const onPressFunc =
			this.props.isGameEnded || (this.props.isSpectator && this.props.isSpectatedGameClosed)
				? this.onExitGamePress.bind(this)
				: this.onThrowAwayPress.bind(this);
		return (
			<Animated.View
				style={[
					styles.throwAwayButton,
					{
						transform: [{ scale: this.state.throwAwayHintAnim }],
						top: this.props.btnThrowAway.top,
						left: this.props.btnThrowAway.left,
						backgroundColor: this.props.isGameEnded || this.props.isSpectatedGameClosed ? "green" : "red",
					},
				]}
				onLayout={() => {
					this.hintThrowAway();
				}}
			>
				<TouchableOpacity
					activeOpacity={0.7}
					onPress={onPressFunc}
					style={{ width: "100%", height: "100%" }}
					touchSoundDisabled={true}
				>
					<Text allowFontScaling={false} style={styles.throwAwayText}>
						{textContent}
					</Text>
				</TouchableOpacity>
			</Animated.View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	throwAwayButton: {
		width: 100,
		height: 30,
		alignSelf: "center",
		position: "absolute",
		zIndex: 5,
		opacity: 0.9,
		borderRadius: 5,
	},
	throwAwayText: {
		width: "100%",
		height: 30,
		fontFamily: appFont,
		fontSize: 16,
		textAlign: "center",
		lineHeight: 30,
		color: "#fff",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		scale: state.startedGame.gameBoard.playersCardsProp.scale,
		btnThrowAway: state.startedGame.gameBoard.playersCardsProp.btnThrowAway,
		isThrowAwayAction: state.startedGame.isThrowAwayAction,
		gameID: state.startedGame.gameID,
		isSpectator: state.startedGame.isSpectator,
		isSpectatedGameClosed: state.startedGame.isSpectatedGameClosed,
		isGameEnded: state.startedGame.gameEnd.isGameEnded,
		secondsBeforeClose: state.startedGame.gameEnd.secondsBeforeClose,
		username: state.currentUser.userDetails.username,
	};
};

const mapDispatchToProps = {
	finishCountdown,
	resetCreateNewGame,
	openGameEnd,
	openGameLobby,
	resetGameEnd,
	resetGameRoom,
	resetLastMoveEffect,
	throwAway,
};
export default connect(mapStateToProps, mapDispatchToProps)(ThrowAwayButton);
