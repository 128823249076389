import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansproregular } from "../../config/defaults";
import { BLUE_BTN } from "../../config/images";
import { MyWebSocket } from "../../connection";
import Analytics from "../Analytics/Analytics";
import { GameButton } from "../common";
import { handleError } from "../ErrorHandler";

class FBShareButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bHeight: 0,
			disabled: false,
		};
	}

	//#region events
	onButtonLayout(event) {
		try {
			const { height } = event.nativeEvent.layout;
			this.setState({ bHeight: height });
		} catch (error) {
			handleError(error);
		}
	}

	shareURL() {
		try {
			this.setState({ disabled: true });
			window.FB.ui(
				{
					appId: "337488124517727",
					method: "share",
					href: this.props.shareableFBImageURL,
					hashtag: this.props.shareableFBHashtag,
				},
				(response) => {
					try {
						var shareResult = {
							type: "shareResult",
							sMessageID: 0,
							platform: "FACEBOOK",
							hash: this.props.faceBookShareHash,
							shareID: this.props.faceBookShareID,
						};
						if (response) {
							if (response.error_message) {
								shareResult.result = "ERROR";
								shareResult.errorData = {
									error_message: response.error_message,
									error_code: response.error_code,
								};
								this.setState({ disabled: false });
							} else {
								shareResult.result = "SHARED";
								Analytics.logEvent("facebookShare_SHARED");
								this.setState({ disabled: true });
								MyWebSocket.shared.sendMsg(shareResult);
							}
						}
						if (!response) {
							shareResult.result = "USER_ABORT";
							this.setState({ disabled: false });
						}
						if (typeof this.props.onShareResult === "function") this.props.onShareResult(shareResult);
					} catch (err) {
						handleError(new Error("Error in web shareUrl() callback: " + JSON.stringify(err)));
						this.setState({ disabled: false });
					}
				}
			);
		} catch (err) {
			handleError(new Error("Error in FBShareButton.web shareUrl(): " + JSON.stringify(err)));
			this.setState({ disabled: false });
		}
	}

	sendScreenshotToStore() {
		try {
			// if (
			//   this.props.shareableFBImageURL == null ||
			//   typeof this.props.shareableFBImageURL !== "string"
			// ) {
			//   MyWebSocket.shared.sendMsg({
			//     type: "storeImage",
			//     sMessageID: 0,
			//     platform: "FACEBOOK",
			//     gameID: this.props.gameID,
			//     image: this.props.gameScreenshotURL,
			//   });
			// } else {
			//   this.shareURL();
			// }
			this.shareURL();
		} catch (err) {
			handleError(new Error("Error in FBShareButton.web sendScreenshotToStore(): " + JSON.stringify(err)));
		}
	}
	//#endregion

	//#region render methods
	render() {
		if (!this.props.allowFacebookSharing) return null;
		const fSize = Math.round(this.state.bHeight * 0.58);
		const fVPadding = Math.round(this.state.bHeight * 0.098);
		return (
			<GameButton
				onLayout={(event) => this.onButtonLayout(event)}
				onPress={this.shareURL.bind(this)}
				silentPress={false}
				disabled={this.state.disabled}
				myStyle={[styles.buttonStyle, this.props.additionalButtonStyle]}
				accessibilityLabel={this.props.lang.FBShareText}
				textContent={this.props.lang.FBShareText}
				textContentStyle={[
					styles.buttonTextStyle,
					this.props.additionalTextStyle,
					{ fontSize: fSize, paddingTop: fVPadding, paddingBottom: fVPadding },
				]}
				backgroundImage={{
					uri: BLUE_BTN,
					resizeMode: "stretch",
					style: [styles.buttonBackImage, this.props.backImageStyle],
					imageStyle: { width: "100%", height: "100%" },
				}}
			/>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	buttonStyle: { backgroundColor: "transparent", borderWidth: 0 },
	buttonBackImage: { marginTop: 2, marginBottom: 2 },
	buttonTextStyle: {
		flex: 1,
		fontSize: 11,
		fontFamily: f_sourcesansproregular,
		textAlign: "center",
		color: "#ffffff",
		paddingTop: 2,
		paddingBottom: 2,
		// textShadowColor: "#0A4458",
		// textShadowOffset: { width: 1, height: 1 },
		// textShadowRadius: 8,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		gameID: state.startedGame.gameID,
		gameScreenshotURL: state.startedGame.gameScreenshotURL,
		shareableFBImageURL: state.startedGame.shareableFBImageURL,
		// shareableFBQuote: state.startedGame.shareableFBQuote,
		shareableFBHashtag: state.startedGame.shareableFBHashtag,
		allowFacebookSharing: state.startedGame.allowFacebookSharing,
		faceBookShareID: state.startedGame.faceBookShareID,
		faceBookShareHash: state.startedGame.faceBookShareHash,
		username: state.currentUser.userDetails.username,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FBShareButton);
