import React, { Component } from "react";
import { Image, ImageBackground, StyleSheet, View } from "react-native";
import { f_sourcesansprobolditalic, f_sourcesansprosemibolditalic, RESOLUTION } from "../../config/defaults";
import { ITEM_DEFAULT_IMAGE, ITEM_PRICE_GOLD, ITEM_PRICE_SILVER, ITEM_TITLE_BACK } from "../../config/images";
import { AppTouchableOpacity, HeaderText } from "../common";
import { handleError } from "../ErrorHandler";

class GameShopItem extends Component {
	constructor(props) {
		super(props);
	}
	//#region render methods
	render() {
		try {
			const { item } = this.props;
			/* const nPriceCurrency = item.currency !== undefined ? item.currency : "";
      const currency_lang =
        nPriceCurrency != "" &&
        genLang.currencies[nPriceCurrency] != "undefined"
          ? genLang.currencies[nPriceCurrency]
          : nPriceCurrency;
      const currencyPrice = !isNaN(item.currencyPrice)
        ? item.currencyPrice.toLocaleString()
        : 0;
      const priceVal = currency_lang + " " + currencyPrice; */
			var priceVal = item.realPrice;
			if (typeof item.realPrice === "string") {
				const numberPrice = Number.parseFloat(item.realPrice);
				const splitted = item.realPrice.split(" ");
				priceVal = splitted.length == 2 ? numberPrice.toLocaleString() + " " + splitted[1] : priceVal;
			}
			const itemPriceButtonStyle = [
				styles.itemPriceButton,
				{ width: item.canBeBoughtWithRealMoney == true ? 160 : 80 },
			];
			const goldPrice = !isNaN(item.goldPrice) ? item.goldPrice.toLocaleString() : 0;
			const silverPrice = !isNaN(item.silverPrice) ? item.silverPrice.toLocaleString() : 0;
			const buyEnabled = item.canBeBought;
			const _accessible = !(this.props.accessibilityElementsHidden || !buyEnabled);
			return (
				<AppTouchableOpacity
					activeOpacity={0.8}
					onPress={this.props.onItemSelect}
					disabled={!buyEnabled}
					style={[
						styles.itemContainer,
						this.props.resolution === RESOLUTION.HIGH && {
							transform: [{ scale: 1.1 }],
							marginHorizontal: 25,
						},
					]}
					accessible={_accessible}
					accessibilityElementsHidden={this.props.accessibilityElementsHidden}
					importantForAccessibility={_accessible ? "yes" : "no-hide-descendants"}
					renderInView={true}
				>
					<ImageBackground
						source={ITEM_TITLE_BACK}
						style={[styles.itemTitleBack, { filter: "grayscale(1)" }]}
						resizeMode="stretch"
					>
						<HeaderText textContent={item.title} textContentStyle={styles.itemTitleText} />
					</ImageBackground>
					<View style={styles.itemImageContainer}>
						<ImageBackground
							source={item.imgBack}
							resizeMode="stretch"
							style={[styles.itemBackground, !buyEnabled && { filter: "grayscale(1)" }]}
						>
							<Image
								source={item.itemImgSrc}
								defaultSource={ITEM_DEFAULT_IMAGE}
								resizeMode="stretch"
								style={{
									width: item.itemImgDim.width == 0 ? 140 : item.itemImgDim.width,
									height: item.itemImgDim.height == 0 ? 110 : item.itemImgDim.height,
									alignSelf: "center",
									filter: !buyEnabled ? "grayscale(1)" : undefined,
								}}
							/>
						</ImageBackground>
					</View>
					{buyEnabled && item.popBestSrc && (
						<Image source={item.popBestSrc} style={styles.popBestImage} resizeMode="contain" />
					)}
					<View style={styles.itemPriceContainer}>
						<View style={styles.itemPriceInnerContainer}>
							{/* currency price button */}
							{item.canBeBoughtWithRealMoney == true && !item.canBeBoughtWithGold && !item.canBeBoughtWithSilver && (
								<AppTouchableOpacity
									activeOpacity={0.8}
									onPress={this.props.onCurrencyPricePress}
									disabled={!buyEnabled}
									style={[itemPriceButtonStyle, { opacity: buyEnabled ? 1 : 0 }]}
								>
									<ImageBackground
										source={ITEM_PRICE_SILVER}
										style={[itemPriceButtonStyle, !buyEnabled && { filter: "grayscale(1)" }]}
										resizeMode={"stretch"}
									>
										<HeaderText textContent={priceVal} textContentStyle={[styles.itemPriceText, styles.silverPrice]} />
									</ImageBackground>
								</AppTouchableOpacity>
							)}
							{/* silver price button */}
							{!item.canBeBoughtWithRealMoney && item.canBeBoughtWithSilver == true && (
								<AppTouchableOpacity
									activeOpacity={0.8}
									onPress={this.props.onSilverPricePress}
									disabled={!buyEnabled}
									style={[itemPriceButtonStyle, { opacity: buyEnabled ? 1 : 0, marginTop: 1 }]}
								>
									<ImageBackground
										source={ITEM_PRICE_SILVER}
										style={[itemPriceButtonStyle, !buyEnabled && { filter: "grayscale(1)" }]}
										resizeMode={"contain"}
									>
										<HeaderText
											textContent={silverPrice}
											textContentStyle={[styles.itemPriceText, styles.silverPrice]}
										/>
									</ImageBackground>
								</AppTouchableOpacity>
							)}
							{/* gold price button */}
							{!item.canBeBoughtWithRealMoney && item.canBeBoughtWithGold == true && (
								<AppTouchableOpacity
									activeOpacity={0.8}
									onPress={this.props.onGoldPricePress}
									disabled={!buyEnabled}
									style={[itemPriceButtonStyle, { opacity: buyEnabled ? 1 : 0 }]}
								>
									<ImageBackground
										source={ITEM_PRICE_GOLD}
										style={[itemPriceButtonStyle, !buyEnabled && { filter: "grayscale(1)" }]}
										resizeMode={"stretch"}
									>
										<HeaderText
											textContent={goldPrice}
											textContentStyle={[styles.itemPriceText, styles.goldPrice, styles.silverPrice]}
										/>
									</ImageBackground>
								</AppTouchableOpacity>
							)}
						</View>
					</View>
				</AppTouchableOpacity>
			);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	itemContainer: {
		// width: 190,
		// height: "100%",
		// minWidth: 190,
		height: 250,
		alignContent: "center",
		alignItems: "center",
		marginHorizontal: 8,
		// marginLeft: -15,
		// borderWidth: 1,
	},
	itemTitleBack: { width: 160, height: 60, position: "relative", zIndex: 1 },
	itemTitleText: {
		alignSelf: "center",
		lineHeight: 50,
		fontFamily: f_sourcesansprobolditalic,
		fontSize: 18,
		color: "#FFFFFF",
		textShadowColor: "#2F0000",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 5,
	},
	itemImageContainer: {
		height: 120,
		justifyContent: "center",
		position: "relative",
		zIndex: 0,
	},
	itemBackground: {
		width: 140,
		height: 170,
		marginTop: 10,
		justifyContent: "center",
		alignSelf: "center",
		position: "absolute",
		zIndex: -1,
	},
	popBestImage: {
		width: 210,
		height: 60,
		alignSelf: "center",
		position: "absolute",
		// bottom: 70,
		top: 50,
		zIndex: 3,
	},
	itemPriceContainer: {
		width: "100%",
		height: 60,
		position: "relative",
		zIndex: 2,
	},
	itemPriceInnerContainer: {
		width: 160,
		height: 60,
		marginTop: 25,
		flexDirection: "row",
		justifyContent: "center",
		alignSelf: "center",
	},
	itemPriceButton: { width: 160, height: 47 },
	itemPriceText: {
		flex: 1,
		textAlign: "center",
		alignSelf: "center",
		lineHeight: 40,
		fontFamily: f_sourcesansprosemibolditalic,
		fontSize: 16, //24
		color: "#FFFFFF",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 5,
	},
	silverPrice: { textShadowColor: "#6B6B6B" },
	goldPrice: { textShadowColor: "#826000" },
});

export default GameShopItem;
