import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { RadioButton } from "./RadioButton";
import { DEBUG_ENABLED } from "../../config/permissions";

class RadioGroup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			radioButtons: this.validate(this.props.radioButtons),
		};
	}

	validate(data) {
		let selected = false; // Variable to check if "selected: true" for more than one button.
		data.map((e) => {
			e.color = e.color ? e.color : "#666";
			e.textLayout = e.textLayout ? e.textLayout : {};
			e.optionLayout = e.optionLayout ? e.optionLayout : {};
			e.buttonLayout = e.buttonLayout ? e.buttonLayout : {};
			e.disabled = e.disabled ? e.disabled : false;
			e.label = e.label ? e.label : "You forgot to give label";
			e.layout = e.layout ? e.layout : "row";
			e.selected = this.props.selectedLabel == e.label;
			if (e.selected) {
				if (selected) {
					e.selected = false; // Making "selected: false", if "selected: true" is assigned for more than one button.
					// if (DEBUG_ENABLED) console.log("Found \"selected: true\" for more than one button");
				} else {
					selected = true;
				}
			}
			e.size = e.size ? e.size : 12.8;
			e.value = e.value ? e.value : e.label;
		});
		if (!selected && this.props.mother !== "questionnaire") {
			data[0].selected = true;
		}
		return data;
	}

	onPress(label, value) {
		const radioButtons = this.state.radioButtons;
		const selectedIndex = radioButtons.findIndex((e) => e.selected == true);
		const selectIndex = radioButtons.findIndex((e) => e.label == label);
		if (selectedIndex != selectIndex) {
			if (this.props.mother == "playerAmount" && !radioButtons[selectIndex].enabled) {
				var _permissionID = "";
				switch (radioButtons[selectIndex].value) {
					case "pa_2":
						_permissionID = "GAME_PLAY_CAN_PLAY_WITH_2_PLAYERS";
						break;
					case "pa_3":
						_permissionID = "GAME_PLAY_CAN_PLAY_WITH_3_PLAYERS";
						break;
					case "pa_4":
						_permissionID = "GAME_PLAY_CAN_PLAY_WITH_4_PLAYERS";
						break;
					case "pa_5":
						_permissionID = "GAME_PLAY_CAN_PLAY_WITH_5_PLAYERS";
						break;
					case "pa_6":
						_permissionID = "GAME_PLAY_CAN_PLAY_WITH_6_PLAYERS";
						break;
					case "pa_7":
						_permissionID = "GAME_PLAY_CAN_PLAY_WITH_7_PLAYERS";
						break;
					case "pa_8":
						_permissionID = "GAME_PLAY_CAN_PLAY_WITH_8 _PLAYERS";
						break;
				}
				this.props.onPermissionDenied(_permissionID);
			} else {
				if (radioButtons[selectedIndex]) radioButtons[selectedIndex].selected = false;
				radioButtons[selectIndex].selected = true;
				this.setState({ radioButtons });
				this.props.onPress(this.state.radioButtons);
			}
		}
	}

	render() {
		return (
			<View style={[styles.container, this.props.containerStyle]}>
				<View style={{ flexDirection: this.props.flexDirection }} onLayout={this.props.onInnerContainerLayout}>
					{this.state.radioButtons.map((data) => (
						<RadioButton key={data.label} data={data} onPress={() => this.onPress(data.label)} />
					))}
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		justifyContent: "center",
		alignItems: "center",
		marginLeft: 10,
		marginBottom: 5,
	},
});

export { RadioGroup };
