import React, { Component } from "react";
import { Dimensions, ImageBackground, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansprobold } from "../../config/defaults";
import { closeLogger, goBackToMain } from "../../redux/actions";
import { NavigationHeader } from "../common";

class Logger extends Component {
	//#region lifecycle methods
	componentDidMount() {
		setTimeout(() => {
			if (this.refs["messagesList"]) this.refs["messagesList"].scrollToEnd({ animated: false });
		}, 25);
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeLogger}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
			/>
		);
	}

	renderMessageLog() {
		var logs = [];
		this.props.messages.forEach((m, ind) => {
			logs.push(
				<View key={ind} style={styles.rowContainer}>
					<Text
						allowFontScaling={false}
						selectable={true}
						style={[
							styles.defaultText,
							styles.titleText,
							m.content == "" ? { width: "100%", maxWidth: undefined } : {},
						]}
					>
						{m.title}
					</Text>
					<Text
						allowFontScaling={false}
						selectable={true}
						style={[styles.defaultText, styles.contentText, { marginLeft: m.title == "" ? 0 : 5 }]}
					>
						{m.content}
					</Text>
				</View>
			);
		});
		return logs;
	}

	render() {
		return (
			<ImageBackground source={this.props.DEFAULT_BACK.url} style={styles.fullFlex}>
				<ScrollView ref="messagesList" nestedScrollEnabled={true} style={[styles.fullFlex, styles.container]}>
					{this.renderMessageLog()}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	fullFlex: { flex: 1 },
	container: {
		marginTop: 55,
		marginBottom: 5,
		marginHorizontal: 20,
	},
	rowContainer: {
		flex: 1,
		flexDirection: "row",
	},
	defaultText: {
		fontFamily: f_sourcesansprobold,
		fontSize: 14,
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		flexWrap: "wrap",
	},
	titleText: {
		color: "#00CED9",
		maxWidth: Math.round(Dimensions.get("screen").width / 3),
	},
	contentText: { flex: 1, color: "#FFFDF1" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isLoggerOpened: state.debug.isLoggerOpened,
		messages: state.debug.messages,
		DEFAULT_BACK: state.images.DEFAULT_BACK,
	};
};

const mapDispatchToProps = { closeLogger, goBackToMain };

export default connect(mapStateToProps, mapDispatchToProps)(Logger);
