import { Image } from "react-native";
import { DEFAULT_BACK, HELP_BACK, MENU_PLAY_BACK, SETTINGS_BACK, SHOP_BACK, SURVEY_BACK } from "../../config/images";
import { IMAGE_ACTIONS } from "../actionTypes";

const getPreloadedImage = (img, url) => {
	img.url = url.toString();
	Image.queryCache([url.toString()]).then((cached) => {
		if (
			cached[url.toString()] !== "disk" &&
			cached[url.toString()] != "disk/memory" &&
			cached[url.toString()] !== "memory"
		) {
			Image.getSize(
				url.toString(),
				(width, height) => {
					img.itemImgDim = { width, height };
					img.isLoaded = true;
				},
				(error) => {
					// handleError(new Error("Error loading image: " + iconImgSrc));
					img.isLoaded = false;
				}
			);
		}
	});
	return img;
};

const initialState = {
	DEFAULT_BACK: { url: DEFAULT_BACK },
	MENU_PLAY_BACK: { url: MENU_PLAY_BACK },
	SETTINGS_BACK: { url: SETTINGS_BACK },
	HELP_BACK: { url: HELP_BACK },
	SHOP_BACK: { url: SHOP_BACK },
	SURVEY_BACK: { url: SURVEY_BACK },
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case IMAGE_ACTIONS.PRELOAD_SCREEN_BACKGROUNDS:
			return {
				...state,
				DEFAULT_BACK: {
					...state.DEFAULT_BACK,
					DEFAULT_BACK: getPreloadedImage({ url: DEFAULT_BACK }, DEFAULT_BACK),
				},
				MENU_PLAY_BACK: {
					...state.MENU_PLAY_BACK,
					MENU_PLAY_BACK: getPreloadedImage({ url: MENU_PLAY_BACK }, MENU_PLAY_BACK),
				},
				SETTINGS_BACK: {
					...state.SETTINGS_BACK,
					SETTINGS_BACK: getPreloadedImage({ url: SETTINGS_BACK }, SETTINGS_BACK),
				},
				HELP_BACK: {
					...state.HELP_BACK,
					HELP_BACK: getPreloadedImage({ url: HELP_BACK }, HELP_BACK),
				},
				SHOP_BACK: {
					...state.SHOP_BACK,
					SHOP_BACK: getPreloadedImage({ url: SHOP_BACK }, SHOP_BACK),
				},
				SURVEY_BACK: {
					...state.SURVEY_BACK,
					SURVEY_BACK: getPreloadedImage({ url: SURVEY_BACK }, SURVEY_BACK),
				},
			};
		default:
			return state;
	}
};
