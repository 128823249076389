import { getAnalytics, logEvent, setCurrentScreen, setUserProperties } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { DEBUG_ENABLED } from "../../config/permissions";

//my firebase config
// const firebaseConfig = {
//   apiKey: "AIzaSyCIBITpiZZZ-4j8DWilrVZr5oOcm1MSzFY",
//   authDomain: "goatama-keezers-test.firebaseapp.com",
//   databaseURL: "https://goatama-keezers-test.firebaseio.com",
//   projectId: "goatama-keezers-test",
//   storageBucket: "goatama-keezers-test.appspot.com",
//   messagingSenderId: "899008004721",
//   appId: "1:899008004721:web:93e6abbd1a6a0a4dc3d1d0",
//   measurementId: "G-J7GJR8FVS2",
// };

//orig firbase config
const firebaseConfig = {
	apiKey: "AIzaSyBAG_0T5T0K2oAUwiI1xe2N7VaUorKh1FA",
	authDomain: "keezers-bcb15.firebaseapp.com",
	projectId: "keezers-bcb15",
	storageBucket: "keezers-bcb15.appspot.com",
	messagingSenderId: "728664800831",
	appId: "1:728664800831:web:61379fc027b642fce9c28a",
	measurementId: "G-03GELXR4RR",
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);

class Analytics {
	static init() {
		// if (!firebaseAnalytics) firebaseAnalytics = getAnalytics(firebaseApp);
	}

	static onSignIn = async (userObject, isGuest) => {
		if (DEBUG_ENABLED) console.log("ANALYTICS onSignIn: ", userObject, " isGuest: ", isGuest);
		const { uName } = userObject;
		if (isGuest) {
			await Promise.all([setUserProperties(firebaseAnalytics, { username: uName }), this.logEvent("loginAsGuest")]);
		} else {
			await Promise.all([setUserProperties(firebaseAnalytics, { username: uName }), this.logEvent("login")]);
		}
	};

	static onSignUp = async (userObject) => {
		//not implemented yet
	};

	static logScreenView = async (_screenName) => {
		if (DEBUG_ENABLED) console.log("ANALYTICS logScreenView: ", _screenName);
		await Promise.all([
			setCurrentScreen(firebaseAnalytics, _screenName),
			this.logEvent("screen_view", {
				screen_name: _screenName,
				screen_class: _screenName,
			}),
		]);
	};

	static logEvent = async (eventName, propertyObject = {}) => {
		if (DEBUG_ENABLED) console.log("ANALYTICS logEvent: ", eventName, " with properties: ", propertyObject);
		await Promise.all([logEvent(firebaseAnalytics, eventName, propertyObject)]);
	};

	static onSignOut = async () => {
		//not implemented yet
	};
}

export default Analytics;
