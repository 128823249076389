import React, { Component } from "react";
import { ImageBackground, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { TUTORIAL_URL } from "../../../config/connection";
import { isWebOrTablet } from "../../../config/defaults";
import Video from "../../../libraries/ReactNativeVideo/Video";
import { closeTutorials, goBackToMain } from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { NavigationHeader } from "../../common";
import { handleError } from "../../ErrorHandler";

class Tutorials extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTime: 0,
			duration: 0,
			isLoading: true,
			paused: false,
			screenType: "contain",
		};
		this.videoURL = TUTORIAL_URL + this.props.clientLanguage.toLowerCase() + "/" + this.props.tutorialFile;
		this.videoPlayer;
	}

	//#region lifecycle methods
	componentDidMount() {
		Analytics.logScreenView("Tutorial");
	}
	//#endregion

	//#region events
	onBackPress() {
		// this.props.closeTutorials();
		this.props.goBackToMain();
	}

	onProgress = (data) => {
		const { isLoading } = this.state;
		if (!isLoading) {
			this.setState({ currentTime: data.currentTime });
		}
	};

	onLoad = (data) => this.setState({ duration: data.duration, isLoading: false });

	onLoadStart = (data) => this.setState({ isLoading: true });

	onEnd = () => {};

	onFullScreen = () => {
		if (this.state.screenType == "contain") this.setState({ screenType: "cover" });
		else this.setState({ screenType: "contain" });
	};

	videoError = (error) => {
		handleError(new Error("Failed to load tutorial from: " + this.videoURL));
	};

	//#region web only events
	handleOnReady = () => {
		this.setState({ isLoading: false });
	};

	handlePlay = () => {
		if (!this.state.seeking) {
			this.setState({ paused: false });
		}
	};

	handlePause = () => {
		if (!this.state.seeking) {
			this.setState({ paused: true });
		}
	};

	handleProgress = (data) => {
		if (!this.state.seeking) {
			const { isLoading } = this.state;
			if (!isLoading) {
				this.setState({ currentTime: data.playedSeconds });
			}
		}
	};

	handleDuration = (duration) => {
		this.setState({ duration });
	};
	//#endregion
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderWebTutorial() {
		return (
			<View style={styles.container}>
				<Video
					url={this.videoURL}
					width="100%"
					height="100%"
					pip={false}
					playing={!this.state.paused}
					controls={true}
					onReady={this.handleOnReady}
					onPlay={this.handlePlay}
					onPause={this.handlePause}
					onEnded={this.onEnd}
					onError={this.videoError}
					onProgress={this.handleProgress}
					onDuration={this.handleDuration}
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						bottom: 0,
						right: 0,
						backgroundColor: "transparent",
					}}
				/>
			</View>
		);
	}

	renderNativeTutorial() {
		return (
			<View style={styles.container} accessibilityLabel="Tutorial Video">
				<Video
					importantForAccessibility={"no-hide-descendants"}
					ref={(videoPlayer) => (this.videoPlayer = videoPlayer)}
					source={{ uri: this.videoURL }}
					onEnd={this.onEnd}
					onLoad={this.onLoad}
					onLoadStart={this.onLoadStart}
					onProgress={this.onProgress}
					onError={this.videoError}
					paused={this.state.paused}
					progressUpdateInterval={500}
					resizeMode={this.state.screenType}
					style={styles.mediaPlayer}
					volume={10}
					controls={true}
					bufferConfig={{
						minBufferMs: 15000,
						maxBufferMs: 50000,
						bufferForPlaybackMs: 2500,
						bufferForPlaybackAfterRebufferMs: 5000,
					}}
				/>
			</View>
		);
	}

	renderMain() {
		return (
			<ImageBackground
				source={this.props.openedFrom != "shop" || !isWebOrTablet ? this.props.HELP_BACK.url : { uri: "https://" }}
				style={{ flex: 1 }}
			>
				{Platform.OS !== "web" && this.renderNativeTutorial()}
				{Platform.OS === "web" && this.renderWebTutorial()}
				{(this.props.openedFrom != "shop" || !isWebOrTablet) && this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		return this.renderMain();
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	toolbar: {
		marginTop: 30,
		backgroundColor: "white",
		padding: 10,
		borderRadius: 5,
	},
	mediaPlayer: {
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		backgroundColor: "transparent",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		clientLanguage: state.currentUser.preferences.clientLanguage,
		resolution: state.currentUser.preferences.resolution,
		tutorialFile: state.app.welcome.tutorialFile,
		HELP_BACK: state.images.HELP_BACK,
	};
};

const mapDispatchToProps = { closeTutorials, goBackToMain };

export default connect(mapStateToProps, mapDispatchToProps)(Tutorials);
