import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { appFont } from "../../config/defaults";

class TimerView extends Component {
	render() {
		if (
			!this.props.isCountDownStarted ||
			this.props.toLateTime == "" ||
			isNaN(Number(this.props.toLateTime)) ||
			Number(this.props.toLateTime) <= 0
		)
			return null;

		return (
			<View
				style={[
					styles.countdownContainer,
					{
						top: this.props.toLateView.top,
						left: this.props.toLateView.left,
					},
				]}
			>
				<Text
					allowFontScaling={false}
					style={[styles.countdownText, { color: this.props.isPlayerWarned ? "red" : "black" }]}
				>
					{this.props.toLateTime}
				</Text>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	countdownContainer: {
		alignSelf: "center",
		position: "absolute",
		zIndex: 5,
	},
	countdownText: {
		height: 20,
		fontFamily: appFont,
		fontSize: 16,
		textAlign: "center",
		lineHeight: 20,
	},
});

const mapStateToProps = (state) => {
	return {
		toLateView: state.startedGame.gameBoard.playersCardsProp.toLateView,
		isCountDownStarted: state.startedGame.isCountDownStarted,
		isPlayerWarned: state.startedGame.isPlayerWarned,
		toLateTime: state.startedGame.toLateTime,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TimerView);
