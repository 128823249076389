import React, { Component } from "react";
import { connect } from "react-redux";
import { gameBoardTheme } from "../config/defaults";
import ClassicKeezChat from "./ClassicKeezChat";
import ModernKeezChat from "./ModernKeezChat/ModernKeezChat";

class KeezChat extends Component {
	render() {
		switch (this.props.gameTheme) {
			case gameBoardTheme.MODERN:
				return <ModernKeezChat {...this.props} />;
			default:
				return <ClassicKeezChat {...this.props} />;
		}
	}
}

const mapStateToProps = (state) => {
	return { gameTheme: state.startedGame.gameTheme };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KeezChat);
