import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor } from "../../../config/defaults";
import { gameDimensions } from "../../../config/dimensions";
import {
	closeHelp,
	openGoldHelp,
	openShopHelp,
	openSilverHelp,
	openTutorials,
	openVIPHelp,
} from "../../../redux/actions";
import { ColoredButton, NavigationHeader } from "../../common";
import GoldHelp from "./GoldHelp";
import ShopHelp from "./ShopHelp";
import SilverHelp from "./SilverHelp";
import Tutorials from "./Tutorials";
import VIPHelp from "./VIPHelp";

class Help extends Component {
	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeHelp}
				closeAction={this.props.closeHelp}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "tutorial":
				textContent = this.props.lang.helpMenu.tutorial;
				onPressFunc = () => {
					this.props.openTutorials();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "game":
				textContent = this.props.lang.helpMenu.game;
				onPressFunc = () => {
					//TODO:
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "silver":
				textContent = this.props.lang.helpMenu.silver;
				onPressFunc = () => {
					this.props.openSilverHelp();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
			case "gold":
				textContent = this.props.lang.helpMenu.gold;
				onPressFunc = () => {
					this.props.openGoldHelp();
				};
				_buttonColor = buttonColor.LIGHT_BLUE;
				break;
			case "shop":
				textContent = this.props.lang.helpMenu.shop;
				onPressFunc = () => {
					this.props.openShopHelp();
				};
				_buttonColor = buttonColor.GREEN;
				break;
			case "vip":
				textContent = this.props.lang.helpMenu.vip;
				onPressFunc = () => {
					this.props.openVIPHelp();
				};
				_buttonColor = buttonColor.RED;
				break;
		}
		return (
			<ColoredButton
				width={gameDimensions.gameButtonsWidth}
				height={60}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.HELP_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{/* {this.renderButton("game")} */}
					{this.renderButton("tutorial")}
					{this.renderButton("silver")}
					{this.renderButton("gold")}
					{this.renderButton("shop")}
					{this.renderButton("vip")}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.tutorialsOpened) {
			return <Tutorials />;
		} else if (this.props.silverHelpOpened) {
			return <SilverHelp />;
		} else if (this.props.goldHelpOpened) {
			return <GoldHelp />;
		} else if (this.props.shopHelpOpened) {
			return <ShopHelp />;
		} else if (this.props.vipHelpOpened) {
			return <VIPHelp />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		paddingHorizontal: 55,
	},
	containerContent: { flex: 1, justifyContent: "center", alignItems: "center" },
	buttonStyle: { backgroundColor: "transparent", borderWidth: 0 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		tutorialsOpened: state.app.tutorialsOpened,
		silverHelpOpened: state.app.silverHelpOpened,
		goldHelpOpened: state.app.goldHelpOpened,
		shopHelpOpened: state.app.shopHelpOpened,
		vipHelpOpened: state.app.vipHelpOpened,
		resolution: state.currentUser.preferences.resolution,
		HELP_BACK: state.images.HELP_BACK,
	};
};

const mapDispatchToProps = {
	closeHelp,
	openGoldHelp,
	openShopHelp,
	openSilverHelp,
	openTutorials,
	openVIPHelp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
