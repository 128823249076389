import React, { Component } from "react";
import { Dimensions, ImageBackground, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold } from "../../../../config/defaults";
import { deleteAccountDimensions, gameDimensions } from "../../../../config/dimensions";
import { mapObject } from "../../../../helpers/commonHelpers";
// import Picker from "react-mobile-picker";
import Picker from "../../../../libraries/WheelPicker";
import { closeDeleteAccountReason, goBackToMain, openDeleteAccountFinal } from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader } from "../../../common";
import { handleError } from "../../../ErrorHandler";
import DeleteAccountFinal from "./DeleteAccountFinal";

class DeleteAccountReason extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			selected: false,
			selectedReason: this.props.lang.deleteAccount.reason.reasons[2].toString(),
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("DeleteAccountReason");
	}
	//#endregion

	//#region events
	onReasonSelect(name, selectedItem) {
		if (selectedItem != this.state.selectedReason) {
			this.setState({ selectedReason: selectedItem, selected: false });
		} else {
			this.setState({ selected: true });
			if (!this.state.selected) {
				this.props.openDeleteAccountFinal();
			}
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeDeleteAccountReason}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderWheelPicker() {
		try {
			var _options = { data: [] },
				_values = { data: "" };
			mapObject(this.props.lang.deleteAccount.reason.reasons, (key, val) => {
				_options.data.push(val);
			});
			const selectedIndex = _options.data.findIndex((i) => i == this.state.selectedReason);
			if (selectedIndex != -1) _values.data = _options.data[selectedIndex];

			return <Picker optionGroups={_options} valueGroups={_values} onChange={this.onReasonSelect.bind(this)} />;
		} catch (error) {
			handleError(error);
		}
	}

	renderProceedButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.props.openDeleteAccountFinal}
					textContent={this.props.lang.deleteAccount.main.button}
					accessibilityLabel={this.props.lang.deleteAccount.main.button}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderContent() {
		return (
			<View style={styles.container}>
				<View style={[styles.contentContainer, { transform: [{ scale: this.state.contentScale }] }]}>
					<View style={styles.titleContainer}>
						<Text allowFontScaling={false} style={styles.titleStyle}>
							{this.props.lang.deleteAccount.reason.title}
						</Text>
					</View>
					{this.renderWheelPicker()}
				</View>
				{this.renderProceedButton()}
			</View>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				{this.renderContent()}
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.deleteAccountFinalOpened) {
			return (
				<DeleteAccountFinal selectedReason={this.props.lang.deleteAccount.reason.reasons[this.state.selectedReason]} />
			);
		} else {
			return this.renderMain();
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120;
			const availableHeight = windowDim.height - 100;
			if (deleteAccountDimensions.width > availableWidth || deleteAccountDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / deleteAccountDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / deleteAccountDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: { flex: 1, padding: 40, paddingBottom: 0 },
	contentContainer: { flex: 1, paddingLeft: 20 },
	titleContainer: {
		width: "85%",
		height: 50,
		alignSelf: "center",
	},
	titleStyle: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansprobold,
		fontSize: 24,
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
		textAlign: "center",
	},
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		deleteAccountFinalOpened: state.app.deleteAccountFinalOpened,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeDeleteAccountReason,
	goBackToMain,
	openDeleteAccountFinal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountReason);
