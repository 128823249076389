import { Platform } from "react-native";
import { getURLParameter } from "../helpers/commonHelpers";
import DeviceInfo from "../libraries/DeviceInfo";

export const queueWaitTimeLimit = 10000; //milliseconds
export const tutorialMode = Object.freeze({
	ASK: "ASK",
	FORCE: "FORCE",
	DISABLE: "DISABLE",
});

export const reconnectWaitTime = 250;

export const debugLogLimit = 0;

export const isWebOrTablet = Platform.OS === "web" || DeviceInfo.isTablet();
export const isTablet = DeviceInfo == null ? true : DeviceInfo.isTablet();
export const DEFAULT_CURRENCY = "EUR";

export const RESOLUTION = Object.freeze({
	LOW: "LOW",
	MEDIUM: "MEDIUM",
	HIGH: "HIGH",
});
export const SETTINGS_SCREEN = Object.freeze({
	UPDATE_SHOW_RUNNING_GAMES_IN_LIST: "update_show_running_games_in_list",
	UPDATE_SOUNDS_PREFERENCES: "update_sounds_preferences",
	UPDATE_APP_LANGUAGE: "update_app_language",
	UPDATE_CHAT_TRANSLATION: "update_chat_translation",
	UPDATE_EMAIL_NOTIFICATIONS: "update_email_notifications",
	UPDATE_APP_NOTIFICATIONS: "update_app_notifications",
	UPDATE_DEFAULT_CARDSET: "update_default_cardset",
	UPDATE_CARDS_ORDER: "update_cards_order",
	UPDATE_SCREEN_RESOLUTION: "update_screen_resolution",
	UPDATE_OVERRIDE_CARDSET: "update_override_cardset",
	UPDATE_FIXED_POSITIONS: "update_fixed_positions",
});
export const FEATURE_PERMISSION = Object.freeze({
	ENABLED: "ENABLED",
	DISABLED: "DISABLED",
	HIDDEN: "HIDDEN",
});
export const CONNECTION_TYPE = Object.freeze({
	UNKNOWN: "UNKNOWN",
	WIFI: "WIFI",
	CELLULAR_2G: "CELLULAR_2G",
	CELLULAR_3G: "CELLULAR_3G",
	CELLULAR_4G: "CELLULAR_4G",
	CELLULAR_5G: "CELLULAR_5G",
});
export const SHOP_CATEGORY = Object.freeze({
	CARDPACK: "CARDSETS",
	BAN_LIST: "BAN_LIST",
	FRIEND_LIST: "FRIEND_LIST",
	CHIPS: "CHIPS",
	GOLD: "GOLD",
	MISC: "MISC",
	MULTIPLIERS: "MULTIPLIERS",
	SILVER: "SILVER",
	VIP: "VIP",
	EARN_FREE: "EARN_SILVER",
});
export const buttonColor = Object.freeze({
	BLUE: "blue",
	GREEN: "green",
	GREY: "grey",
	LIGHT_BLUE: "light_blue",
	PURPLE: "purple",
	RED: "red",
	YELLOW: "yellow",
});
export const playerList = Object.freeze({
	FRIEND_LIST: "friendList",
	BAN_LIST: "banList",
	PLAYERS_LIST: "playersList",
});
export const storeKeys = Object.freeze({
	APP_VERSION: "appVersion",
	APP_STATE: "clientState",
	APP_TERMS_ACCEPTED: "appTerms",
	APP_LANGUAGE: "appLanguage",
	DEVICE_ID: "devID",
	UUID: "UUID",
	FB_UID: "fb_uid",
	FB_TOKEN: "fb_token",
	FB_IS_LOGGED_IN: "fb_is_logged_in",
	FB_USER_DETAILS: "fb_user_details",
	EMAIL_USERNAME: "email_username",
	EMAIL_PWD: "email_pwd",
	EMAIL_IS_LOGGED_IN: "email_is_logged_in",
	TUTORIAL_OFFERED: "tutorial_offered",
	WEB_USER_ID: "web_user_ID",
	WEB_USER_TOKEN: "web_user_token",
	LOGGED_SESSION_ID: "logged_session_id",
	STORED_LOG: "stored_log",
	STORED_CONN_LOG: "stored_conn_log",
	REFERRER_SENT: "referrer_sent",
});
export const APP_STATE = Object.freeze({
	CLOSED: "CLOSED",
	MAXIMIZED: "MAXIMIZED",
	MINIMIZED: "MINIMIZED",
	OPENED: "OPENED",
	RESUMED: "RESUMED",
	STARTED: "STARTED",
	RECONNECT: "RECONNECTING",
});
export const START_REASON = Object.freeze({
	NORMAL: "NORMAL",
	RECOVERED_FROM_CRASH: "RECOVERED_FROM_CRASH",
	INSTALLED: "JUST_INSTALLED",
	UPDATED: "JUST_UPDATED",
});
export const AUTH_TYPE = Object.freeze({
	USER_PASS: "USER_PASS",
	EMAIL_PASS: "EMAIL_PASS",
	TOKEN: "TOKEN",
	ANONYMOUS: "ANONYMOUS",
	FACEBOOK: "FACEBOOK",
});
export const FBPermissions = [
	"public_profile",
	"email",
	"user_age_range",
	"user_birthday",
	"user_friends",
	"user_gender",
	"user_hometown",
];
export const appFont = Platform.OS === "android" ? "vagroundedstdbold" : "VAGRoundedStdBold";

export const f_sourcesansprobold = Platform.OS === "ios" ? "SourceSansPro-Bold" : "sourcesansprobold";
export const f_sourcesansprobolditalic = Platform.OS === "ios" ? "SourceSansPro-BoldItalic" : "sourcesansprobolditalic";
export const f_sourcesansproitalic = Platform.OS === "ios" ? "SourceSansPro-Italic" : "sourcesansproitalic";
export const f_sourcesansprolight = Platform.OS === "ios" ? "SourceSansPro-Light" : "sourcesansprolight";
export const f_sourcesansproregular = Platform.OS === "ios" ? "SourceSansPro-Regular" : "sourcesansproregular";
export const f_sourcesansprosemibold = Platform.OS === "ios" ? "SourceSansPro-SemiBold" : "sourcesansprosemibold";
export const f_sourcesansprosemibolditalic =
	Platform.OS === "ios" ? "SourceSansPro-SemiBoldItalic" : "sourcesansprosemibolditalic";

export const tabType = Object.freeze({ CHAT: 1, GAMES: 2, SHOP: 3 });
export const viewSide = Object.freeze({
	LEFT: 1,
	RIGHT: 2,
	BOTTOM: 3,
	MIDDLE: 4,
});
export const gameButton = Object.freeze({
	JOIN_GAME: 1,
	CREATE_GAME: 2,
	PUBLISH_GAME: 3,
	CANCEL_NEW_GAME: 4,
	START_GAME: 5,
	LEAVE_GAME: 6,
});
export const gameDetailsTableKey = Object.freeze({
	selectedGame: 1,
	newGame: 2,
	joinedGame: 3,
});
export const maxExistingUserRating = 18;
export const defaultCardsetID = 12;
export const gameNameMinLength = 4;
export const gameNameMaxLength = 16;
export const gameChatMessageLimit = 250;
export const globalChatMessageLimitPerRoom = 100;
export const modalType = Object.freeze({
	ERROR: 0,
	DIALOG: 1,
	RECONNECT: 2,
});
export const waitForPaymentTime = 2000; //milliseconds
export const authenticateRefreshInterval = 900; //seconds
export const createGameHangWaitTime = 2000; //milliseconds

var flickerRate = Platform.OS !== "web" ? 100 : getURLParameter("flicker");
flickerRate = flickerRate == false ? 100 : Number(flickerRate);
flickerRate = isNaN(flickerRate) ? 100 : flickerRate;
export const activeTurnEffectTime = flickerRate; //milliseconds

export const processState = Object.freeze({
	INITIALIZED: 0,
	STARTED: 1,
	FINISHED: 2,
});

export const leaderboard_Type = Object.freeze({
	GLOBAL: "global",
	FRIENDS: "friends",
	GROUPS: "groups",
});

export const leaderboardCategory = Object.freeze({
	ALWAYS: "ALWAYS",
	YEAR: "YEAR",
	MONTH: "MONTH",
	WEEK: "WEEK",
	DAY: "DAY",
});

export const LEADER_BOARD_SIZE = { GLOBAL: 50, FRIENDS: 10, GROUPS: 20 };

export const TOURNAMENT_TEAM_TYPE = Object.freeze({
	OPEN: "OPEN",
	PRIVATE: "PRIVATE",
	COUNTRY: "COUNTRY",
	LANGUAGE: "LANGUAGE",
	VIP: "VIP",
	LEVEL: "LEVEL",
	SINGLE: "SINGLE",
});

export const TOURNAMENT_TYPE = Object.freeze({
	LADDER: "LADDER",
	POOL_LADDER: "POOL_LADDER",
	KNOCKOUT: "KNOCKOUT",
	DOUBLE_KNOCKOUT: "DOUBLE_KNOCKOUT",
	ROUND_ROBIN: "ROUND_ROBIN",
});
