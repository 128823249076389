import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Image, View } from "react-native";
import { RESOLUTION } from "../../config/defaults";
import { TEXT_INPUT } from "../../config/images";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class DateInput extends Component {
	constructor(props) {
		super(props);
	}

	handleChange(date) {
		if (typeof this.props.onChange === "function") this.props.onChange(date);
	}

	render() {
		const minDate = this.props.minDate || new Date("01-01-1900");
		const _width =
			this.props.renderLoc == "newTournament" ? 275 : this.props.resolution === RESOLUTION.HIGH ? 400 : 300;
		return (
			<View style={{ width: _width }}>
				<Image
					source={TEXT_INPUT}
					resizeMode="stretch"
					style={{
						width: _width,
						height: 50,
						position: "absolute",
						zIndex: -1,
					}}
				/>
				<DatePicker
					selected={this.props.selectedDate}
					onChange={(date) => this.handleChange(date)}
					dateFormat={"MM-dd-yyyy"}
					className={
						this.props.resolution === RESOLUTION.HIGH
							? this.props.renderLoc === "newTournament"
								? "dateInput newTournament dateInputHighRes"
								: "dateInput dateInputHighRes"
							: this.props.renderLoc === "newTournament"
							? "dateInput newTournament"
							: "dateInput"
					}
					calendarClassName={this.props.resolution === RESOLUTION.HIGH ? "highResCalendar" : ""}
					maxDate={this.props.renderLoc == "newTournament" ? undefined : Date.now()}
					minDate={minDate}
				/>
			</View>
		);
	}
}

export { DateInput };
