import React, { Component } from "react";
import { ImageBackground, StyleSheet, View } from "react-native";
import { appFont } from "../../config/defaults";
import { SWITCH_BACKGROUND } from "../../config/images";
import { mapObject } from "../../helpers/commonHelpers";
import { handleError } from "../ErrorHandler";
import { SwitchCheckbox } from "./SwitchCheckbox";

class SwitchBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChecked: props.isChecked || false,
		};
	}

	//#region events
	onPressed() {
		try {
			const { group, textContent, onPress, isMultiChecked, isChecked } = this.props;
			if (!isMultiChecked) {
				if (!isChecked) {
					if (typeof onPress === "function") onPress(group, textContent, !isChecked);
				} else {
					if (typeof onPress === "function") onPress(group, textContent, isChecked);
				}
			} else {
				if (typeof onPress === "function") onPress(group, textContent, !isChecked);
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		if (!Array.isArray(this.props.labels) || this.props.labels.length < 3) return null;

		return (
			<View style={[styles.container, this.props.style, { height: this.props.containerHeight }]}>
				<ImageBackground
					source={SWITCH_BACKGROUND}
					style={[styles.backgroundImage, { width: this.props.width, height: this.props.height }]}
					resizeMode="stretch"
				>
					<View style={styles.backgroundContainer}>
						{mapObject(this.props.checks, (key, val) => {
							return (
								<SwitchCheckbox
									key={key}
									textContent={this.props.labels[key]}
									isChecked={val}
									isMultiChecked={this.props.isMultiChecked}
									disabled={this.props.disabled}
									onPress={this.props.onCheck}
									group={this.props.group}
									buttonStyle={this.props.buttonStyle}
									labelStyle={[styles.label, this.props.labelStyle]}
									labelBackgroundStyle={[styles.textContentBackground, this.props.labelBackground]}
								/>
							);
						})}
					</View>
				</ImageBackground>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		height: "100%",
	},
	buttonStyle: {
		overflow: "visible",
		width: 50,
		height: 27,
	},
	label: {
		marginTop: 6,
		marginBottom: 6,
		fontFamily: appFont,
		fontSize: 12,
		textAlign: "center",
		color: "#FFFFFF",
	},
	textContentBackground: {
		width: 80,
		height: 30,
		alignSelf: "center",
	},
	backgroundContainer: {
		width: 240,
		height: 30,
		flexDirection: "row",
	},
	backgroundImage: {
		width: 240,
		height: 30,
		alignSelf: "center",
	},
});

export { SwitchBox };
