import { Dimensions, Platform } from "react-native";
import { handleError } from "../components/ErrorHandler";
import { RESOLUTION } from "../config/defaults";
import {
	gameDetailsColumns,
	gameDimensions,
	gameListColumns,
	gameLobbyDimensions,
	gameRoomDimensions,
	globalChatDimensions,
	newGameDimensions,
} from "../config/dimensions";

export function isSmallResolution() {
	try {
		const { gameListMinWidth, gameDetailsMinWidth } = gameLobbyDimensions;
		const smallResolutionMaxWidth = 3 * gameDimensions.fullWidthMargin + gameListMinWidth + gameDetailsMinWidth;
		let windowWidth = Dimensions.get("window").width;
		return windowWidth < smallResolutionMaxWidth;
	} catch (error) {
		handleError(error);
	}
}

export function isLandscape() {
	try {
		let windowDimensions = Dimensions.get("window");
		return windowDimensions.width > windowDimensions.height;
	} catch (error) {
		handleError(error);
	}
}

export function getPageTopDimensions() {
	try {
		let res = { leftMargin: 0, width: 0 };
		res.width =
			gameLobbyDimensions.gameListDefaultWidth +
			gameLobbyDimensions.gameDetailsDefaultWidth +
			gameDimensions.rightBoxDefaultLeftMargin;
		let windowDimensions = Dimensions.get("window");
		if (windowDimensions.width >= res.width) {
			res.leftMargin = Math.floor((windowDimensions.width - res.width) / 2);
		} else {
			res.leftMargin = 0;
			res.width = windowDimensions.width - 2 * gameDimensions.fullWidthMargin;
		}
		return res;
	} catch (error) {
		handleError(error);
	}
}

export function getGlobalChatDimensions(isSmallResolution) {
	try {
		if (Platform.OS !== "web") {
			return getMobileGlobalChatDimensions();
		} else {
			if (isSmallResolution) {
				return getMobileGlobalChatDimensions();
			} else {
				return Object.assign({}, getDesktopGlobalChatWidthDimensions(), getDesktopGlobalChatHeightDimensions());
			}
		}
	} catch (error) {
		handleError(error);
	}
}

export function getGameLobbyDimensions(isSmallResolution, resolution) {
	try {
		if (Platform.OS !== "web") {
			return getMobileGameLobbyDimensions(resolution);
		} else {
			if (isSmallResolution) {
				return getMobileGameLobbyDimensions(resolution);
			} else {
				return Object.assign({}, getDesktopGameLobbyWidthDimensions(), getDesktopGameLobbyHeightDimensions(resolution));
			}
		}
	} catch (error) {
		handleError(error);
	}
}

export function getGameListTableColumnsDimensions(tableWidth, containerWidth) {
	try {
		if (Platform.OS === "web") {
			var res = {
				creatorCountry: gameListColumns.creatorCountry.maxWidth,
				chipsRequired: gameListColumns.chipsRequired.maxWidth,
				name: gameListColumns.name.secureWidth,
				playerCnt: gameListColumns.playerCnt.maxWidth,
				// freeSlots: gameListColumns.freeSlots.maxWidth,
			};

			if (tableWidth && tableWidth > 0) {
				var remainingWidthForGameName =
					tableWidth -
					res.creatorCountry -
					res.playerCnt -
					// res.freeSlots -
					res.chipsRequired;
				var isScrollVisible = containerWidth > tableWidth;
				var scrollBarWidth = isScrollVisible ? containerWidth - tableWidth : 0;
				remainingWidthForGameName = isScrollVisible
					? remainingWidthForGameName + scrollBarWidth
					: remainingWidthForGameName;

				if (remainingWidthForGameName < gameListColumns.name.secureWidth) {
					remainingWidthForGameName =
						tableWidth -
						gameListColumns.creatorCountry.minWidth -
						gameListColumns.chipsRequired.minWidth -
						gameListColumns.playerCnt.minWidth;
					// - gameListColumns.freeSlots.minWidth;

					res.creatorCountry = gameListColumns.creatorCountry.minWidth;
					res.chipsRequired = gameListColumns.chipsRequired.minWidth;
					res.playerCnt = gameListColumns.playerCnt.minWidth;
					// res.freeSlots = gameListColumns.freeSlots.minWidth;
				} else {
					if (isScrollVisible) {
						var desc = scrollBarWidth / 3;
						res.creatorCountry -= desc;
						res.chipsRequired -= desc;
						res.playerCnt -= desc;
						// res.freeSlots -= desc;
					}
				}
				res.name = remainingWidthForGameName;
			}
			return res;
		} else {
			var res = {
				creatorCountry: gameListColumns.creatorCountry.maxWidth,
				chipsRequired: gameListColumns.chipsRequired.maxWidth,
				name: gameListColumns.name.secureWidth,
				playerCnt: gameListColumns.playerCnt.maxWidth,
				// freeSlots: gameListColumns.freeSlots.maxWidth,
			};

			if (tableWidth && tableWidth > 0) {
				var remainingWidthForGameName =
					tableWidth -
					res.creatorCountry -
					res.playerCnt -
					// res.freeSlots -
					res.chipsRequired;
				var isScrollVisible = containerWidth > tableWidth;
				var scrollBarWidth = isScrollVisible ? containerWidth - tableWidth : 0;
				remainingWidthForGameName = isScrollVisible
					? remainingWidthForGameName + scrollBarWidth
					: remainingWidthForGameName;

				if (remainingWidthForGameName < gameListColumns.name.secureWidth) {
					remainingWidthForGameName =
						tableWidth -
						gameListColumns.creatorCountry.minWidth -
						gameListColumns.chipsRequired.minWidth -
						gameListColumns.playerCnt.minWidth;
					// - gameListColumns.freeSlots.minWidth;

					res.creatorCountry = gameListColumns.creatorCountry.minWidth;
					res.chipsRequired = gameListColumns.chipsRequired.minWidth;
					res.playerCnt = gameListColumns.playerCnt.minWidth;
					// res.freeSlots = gameListColumns.freeSlots.minWidth;
				} else {
					if (isScrollVisible) {
						var desc = scrollBarWidth / 7;
						res.creatorCountry -= desc;
						res.chipsRequired -= desc;
						res.playerCnt -= desc;
						// res.freeSlots -= desc;
					}
				}
				res.name = remainingWidthForGameName;
			}
			return res;
		}
	} catch (error) {
		handleError(error);
	}
}

export function getGameDetailsTableColDimensions(tableWidth, containerWidth, tableKey, resolution) {
	try {
		var res = {
			// slotID: gameDetailsColumns.slotID.maxWidth,
			country: gameDetailsColumns.country.maxWidth,
			ranking: gameDetailsColumns.ranking.maxWidth,
			username: gameDetailsColumns.username.secureWidth,
			avatar: gameDetailsColumns.avatar.maxWidth,
			// teamName: gameDetailsColumns.teamName.maxWidth,
		};

		if (tableWidth && tableWidth > 0) {
			var remainingWidthForUserName = tableWidth - res.country - res.ranking - res.avatar;
			var isScrollVisible = containerWidth > tableWidth; /* && Platform.OS === "web" */
			var scrollBarWidth = isScrollVisible ? containerWidth - tableWidth : 0;

			if (remainingWidthForUserName < gameDetailsColumns.username.secureWidth) {
				remainingWidthForUserName =
					tableWidth -
					gameDetailsColumns.country.minWidth -
					gameDetailsColumns.ranking.minWidth -
					gameDetailsColumns.avatar.minWidth;

				res.country = gameDetailsColumns.country.minWidth;
				res.ranking = gameDetailsColumns.ranking.minWidth;
				res.avatar = gameDetailsColumns.avatar.minWidth;
			} else {
				if (isScrollVisible) {
					// var desc = Math.floor(scrollBarWidth / 4);
					// res.slotID -= desc;
					// res.country -= desc;
					// res.ranking -= desc;
					// res.teamName -= desc;
					// remainingWidthForUserName -= scrollBarWidth;
				}
			}
			res.username = remainingWidthForUserName;
		}
		/* if ((resolution && resolution == RESOLUTION.LOW) || !isWebOrTablet) {
      if (tableKey !== gameDetailsTableKey.selectedGame) {
        res.username += res.teamName;
        res.teamName = 0;
      }
    } */
		return res;
	} catch (error) {
		handleError(error);
	}
}

export function getCreateNewGameDimensions(isSmallResolution, resolution) {
	try {
		if (Platform.OS !== "web") {
			return getMobileCreateNewGameDimensions(resolution);
		} else {
			if (isSmallResolution) {
				return getMobileCreateNewGameDimensions(resolution);
			} else {
				return Object.assign(
					{},
					getDesktopCreateNewGameWidthDimensions(),
					getDesktopCreateNewGameHeightDimensions(resolution)
				);
			}
		}
	} catch (error) {
		handleError(error);
	}
}

export function getGameRoomDimensions(isSmallResolution, grChatCurrentHeight, resolution) {
	try {
		if (Platform.OS !== "web") {
			return getMobileGameRoomDimensions(resolution);
		} else {
			if (isSmallResolution) {
				return getMobileGameRoomDimensions(resolution);
			} else {
				return Object.assign(
					{},
					getDesktopGameRoomWidthDimensions(),
					getDesktopGameRoomHeightDimensions(grChatCurrentHeight, resolution)
				);
			}
		}
	} catch (error) {
		handleError(error);
	}
}

function getDesktopGlobalChatWidthDimensions() {
	try {
		let res = {
			chatMainWidth: globalChatDimensions.chatMainDefaultWidth,
			chatMainLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
			chatListsWidth: globalChatDimensions.chatListsDefaultWidth,
			chatListsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
			chatRoomNameWidth: globalChatDimensions.chatRoomNameMainDefaultWidth,
			chatRoomsTabWidth:
				globalChatDimensions.chatMainDefaultWidth +
				gameDimensions.rightBoxDefaultLeftMargin +
				globalChatDimensions.chatListsDefaultWidth,
		};
		let windowDimensions = Dimensions.get("window");
		let requestedWidth_tabChat =
			globalChatDimensions.chatMainDefaultWidth +
			globalChatDimensions.chatListsDefaultWidth +
			gameDimensions.fullWidthMargin +
			gameDimensions.rightBoxDefaultLeftMargin;

		if (windowDimensions.width >= requestedWidth_tabChat) {
			res.chatMainLeftMargin = Math.floor((windowDimensions.width - requestedWidth_tabChat) / 2);
			res.chatMainWidth = globalChatDimensions.chatMainDefaultWidth;
			res.chatListsLeftMargin = gameDimensions.rightBoxDefaultLeftMargin;
			res.chatListsWidth = globalChatDimensions.chatListsDefaultWidth;
			res.chatRoomNameWidth = globalChatDimensions.chatRoomNameMainDefaultWidth;
			res.chatRoomsTabWidth =
				globalChatDimensions.chatMainDefaultWidth +
				gameDimensions.rightBoxDefaultLeftMargin +
				globalChatDimensions.chatListsDefaultWidth;
		} else {
			let chatMainFactor = globalChatDimensions.chatMainDefaultWidth / requestedWidth_tabChat;
			let chatListsFactor = globalChatDimensions.chatListsDefaultWidth / requestedWidth_tabChat;

			let requestedChatMainWidth = windowDimensions.width * chatMainFactor,
				requestedChatListsWidth = windowDimensions.width * chatListsFactor;

			if (requestedChatMainWidth >= globalChatDimensions.chatMainDefaultWidth) {
				requestedChatMainWidth = globalChatDimensions.chatMainDefaultWidth;
			}

			if (requestedChatListsWidth >= globalChatDimensions.chatListsDefaultWidth) {
				requestedChatListsWidth = globalChatDimensions.chatListsDefaultWidth;
			}

			res.chatMainWidth = Math.floor(requestedChatMainWidth);
			res.chatMainLeftMargin = gameDimensions.fullWidthMargin;
			res.chatListsWidth = Math.floor(requestedChatListsWidth);
			res.chatListsLeftMargin = gameDimensions.fullWidthMargin;
			res.chatRoomNameWidth =
				Math.floor(requestedChatMainWidth) + gameDimensions.fullWidthMargin + Math.floor(requestedChatListsWidth);
			res.chatRoomsTabWidth =
				Math.floor(requestedChatMainWidth) + gameDimensions.fullWidthMargin + Math.floor(requestedChatListsWidth);
		}
		return res;
	} catch (error) {
		handleError(error);
	}
}

function getDesktopGlobalChatHeightDimensions() {
	try {
		let res = {
			chatMainHeight: globalChatDimensions.chatMainDefaultHeight,
			chatListsHeight: globalChatDimensions.chatListsDefaultHeight,
			chatMainContentHeight: globalChatDimensions.chatMainDefaultHeight - gameDimensions.boxHeaderHeight - 80,
		};
		let windowDimensions = Dimensions.get("window");

		let top_bottom =
			// gameDimensions.tabAreaDefaultHeight +
			// gameDimensions.notificationBarDefaultHeight +
			gameDimensions.navHeaderHeight +
			globalChatDimensions.chatInputHeight +
			gameDimensions.pageContentTopMargin +
			gameDimensions.pageContentBottomMargin; /*  +
      22; */
		let requestedChatMainHeight = top_bottom + globalChatDimensions.chatMainDefaultHeight;
		let requestedChatListsHeight = top_bottom + globalChatDimensions.chatListsDefaultHeight;
		let requestedHeight_tabChat =
			requestedChatMainHeight >= requestedChatListsHeight ? requestedChatMainHeight : requestedChatListsHeight;

		if (windowDimensions.height >= requestedHeight_tabChat) {
			res.chatMainHeight = globalChatDimensions.chatMainDefaultHeight;
			res.chatListsHeight = globalChatDimensions.chatListsDefaultHeight;
			res.chatMainContentHeight = globalChatDimensions.chatMainDefaultHeight - gameDimensions.boxHeaderHeight - 80;
		} else {
			let availableHeight = Math.abs(windowDimensions.height - top_bottom),
				requestedChatMainHeight = availableHeight,
				requestedChatListsHeight = availableHeight;
			if (availableHeight >= globalChatDimensions.chatMainDefaultHeight) {
				requestedChatMainHeight = globalChatDimensions.chatMainDefaultHeight;
			} else {
				if (requestedChatMainHeight < globalChatDimensions.chatMainMinHeight) {
					requestedChatMainHeight = globalChatDimensions.chatMainMinHeight;
				}
			}
			if (availableHeight >= globalChatDimensions.chatListsDefaultHeight) {
				requestedChatListsHeight = globalChatDimensions.chatListsDefaultHeight;
			} else {
				if (requestedChatListsHeight < globalChatDimensions.chatListsMinHeight) {
					requestedChatListsHeight = globalChatDimensions.chatListsMinHeight;
				}
			}

			res.chatMainHeight = requestedChatMainHeight;
			res.chatListsHeight = requestedChatListsHeight;
			res.chatMainContentHeight = requestedChatMainHeight - gameDimensions.boxHeaderHeight - 80;
		}
		return res;
	} catch (error) {
		handleError(error);
	}
}

function getMobileGlobalChatDimensions() {
	try {
		let res = {
			chatMainWidth: globalChatDimensions.chatMainDefaultWidth,
			chatMainHeight: globalChatDimensions.chatMainDefaultHeight - globalChatDimensions.chatInputHeight - 5,
			chatMainLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
			chatMainContentHeight:
				globalChatDimensions.chatMainDefaultHeight - gameDimensions.boxHeaderHeight - gameDimensions.headerHeight - 20,
			chatListsWidth: globalChatDimensions.chatListsDefaultWidth,
			chatListsHeight: globalChatDimensions.chatListsDefaultHeight,
			chatListsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
			chatRoomNameWidth: globalChatDimensions.chatRoomNameMainDefaultWidth,
			chatRoomsTabWidth:
				globalChatDimensions.chatMainDefaultWidth +
				gameDimensions.rightBoxDefaultLeftMargin +
				globalChatDimensions.chatListsDefaultWidth,
		};
		let windowDimensions = Dimensions.get("screen");
		let newHeight =
			windowDimensions.height -
			// gameDimensions.headerHeight -
			gameDimensions.navHeaderHeight -
			globalChatDimensions.chatInputHeight -
			5;

		res.chatMainWidth = (windowDimensions.width - 2 * gameDimensions.fullWidthMargin) * 0.65;
		res.chatMainLeftMargin = 5;
		res.chatMainHeight = newHeight;
		res.chatMainContentHeight =
			newHeight -
			gameDimensions.boxHeaderHeight -
			// gameDimensions.headerHeight -
			gameDimensions.navHeaderHeight -
			5;
		res.chatListsWidth = (windowDimensions.width - 2 * gameDimensions.fullWidthMargin) * 0.33;
		res.chatListsHeight = newHeight;
		res.chatListsLeftMargin = 10;
		res.chatRoomNameWidth = (windowDimensions.width - 2 * gameDimensions.fullWidthMargin) * 0.65;
		res.chatRoomsTabWidth = windowDimensions.width - 2 * gameDimensions.fullWidthMargin;

		return res;
	} catch (error) {
		handleError(error);
	}
}

function getDesktopGameLobbyWidthDimensions() {
	try {
		let res = {
			gameListLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
			gameListWidth: gameLobbyDimensions.gameListDefaultWidth,
			gameDetailsLeftMargin: gameLobbyDimensions.gameDetailsLeftMargin,
			gameDetailsWidth: gameLobbyDimensions.gameDetailsDefaultWidth,
		};
		let windowDimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");

		res.gameListWidth = (windowDimensions.width - 2 * gameDimensions.fullWidthMargin - 80) / 2;
		res.gameListLeftMargin = 0;
		res.gameDetailsWidth = (windowDimensions.width - 2 * gameDimensions.fullWidthMargin - 80) / 2;
		res.gameDetailsLeftMargin = 5;

		return res;
		/* let res = {
      gameListLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
      gameListWidth: gameLobbyDimensions.gameListDefaultWidth,
      gameDetailsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
      gameDetailsWidth: gameLobbyDimensions.gameDetailsDefaultWidth,
    };
    let windowDimensions = Dimensions.get("window");
    let requestedWidth_tabGames =
      gameLobbyDimensions.gameListDefaultWidth +
      gameLobbyDimensions.gameDetailsDefaultWidth +
      gameDimensions.fullWidthMargin +
      gameDimensions.rightBoxDefaultLeftMargin;

    if (windowDimensions.width >= requestedWidth_tabGames) {
      res.gameListWidth = gameLobbyDimensions.gameListDefaultWidth;
      res.gameListLeftMargin = Math.floor(
        (windowDimensions.width - requestedWidth_tabGames) / 2
      );
      res.gameDetailsWidth = gameLobbyDimensions.gameDetailsDefaultWidth;
      res.gameDetailsLeftMargin = gameDimensions.rightBoxDefaultLeftMargin;
    } else {
      let gameListFactor =
        gameLobbyDimensions.gameListDefaultWidth / requestedWidth_tabGames;
      let gameDetailsFactor =
        gameLobbyDimensions.gameDetailsDefaultWidth / requestedWidth_tabGames;

      let requestedGameDetailsWidth =
          windowDimensions.width * gameDetailsFactor,
        requestedGameListWidth = windowDimensions.width * gameListFactor;
      if (
        requestedGameDetailsWidth >= gameLobbyDimensions.gameDetailsDefaultWidth
      ) {
        requestedGameDetailsWidth = gameLobbyDimensions.gameDetailsDefaultWidth;
      } else {
        if (
          requestedGameDetailsWidth < gameLobbyDimensions.gameDetailsMinWidth
        ) {
          requestedGameDetailsWidth = gameLobbyDimensions.gameDetailsMinWidth;
        }
      }

      if (requestedGameListWidth >= gameLobbyDimensions.gameListDefaultWidth) {
        requestedGameListWidth = gameLobbyDimensions.gameListDefaultWidth;
      } else {
        if (requestedGameListWidth < gameLobbyDimensions.gameListMinWidth) {
          requestedGameListWidth = gameLobbyDimensions.gameListMinWidth;
        }
      }

      res.gameListLeftMargin = gameDimensions.fullWidthMargin;
      res.gameListWidth = Math.floor(requestedGameListWidth);
      res.gameDetailsWidth = Math.floor(requestedGameDetailsWidth);
      res.gameDetailsLeftMargin = gameDimensions.fullWidthMargin;
    }
    return res; */
	} catch (error) {
		handleError(error);
	}
}

function getDesktopGameLobbyHeightDimensions(resolution) {
	try {
		const _gameButtonsHeight =
			resolution === RESOLUTION.HIGH ? gameDimensions.gameButtons_HR_Height : gameDimensions.gameButtonsHeight;
		let res = {
			gameListHeight: gameLobbyDimensions.gameListDefaultHeight,
			gameDetailsHeight: gameLobbyDimensions.gameDetailsDefaultHeight - _gameButtonsHeight - 5,
		};
		let windowDimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		let top_bottom = gameDimensions.tabAreaDefaultHeight + _gameButtonsHeight; //+
		// gameDimensions.notificationBarDefaultHeight +
		// gameDimensions.pageContentTopMargin +
		/* gameDimensions.pageContentBottomMargin */ let newHeight = windowDimensions.height - top_bottom;

		res.gameListHeight = newHeight;
		res.gameDetailsHeight = newHeight - 5 /* - _gameButtonsHeight - 5 */;

		return res;
		/* let res = {
      gameListHeight: gameLobbyDimensions.gameListDefaultHeight,
      gameDetailsHeight: gameLobbyDimensions.gameDetailsDefaultHeight,
    };
    let windowDimensions = Dimensions.get("window");

    let top_bottom =
      gameDimensions.tabAreaDefaultHeight +
      gameDimensions.notificationBarDefaultHeight +
      gameDimensions.pageContentTopMargin +
      gameDimensions.pageContentBottomMargin;
    let requestedGameListHeight =
      top_bottom + gameLobbyDimensions.gameListDefaultHeight;
    let requestedGameDetailsHeight =
      top_bottom + gameLobbyDimensions.gameDetailsDefaultHeight;
    let requestedHeight_tabGames =
      requestedGameListHeight >= requestedGameDetailsHeight
        ? requestedGameListHeight
        : requestedGameDetailsHeight;

    if (windowDimensions.height >= requestedHeight_tabGames) {
      res.gameListHeight = gameLobbyDimensions.gameListDefaultHeight;
      res.gameDetailsHeight = gameLobbyDimensions.gameDetailsDefaultHeight;
    } else {
      let availableHeight = Math.abs(windowDimensions.height - top_bottom),
        requestedGameListHeight = availableHeight,
        requestedGameDetailsHeight = availableHeight;
      if (availableHeight >= gameLobbyDimensions.gameListDefaultHeight) {
        requestedGameListHeight = gameLobbyDimensions.gameListDefaultHeight;
      } else {
        if (requestedGameListHeight < gameLobbyDimensions.gameListMinHeight) {
          requestedGameListHeight = gameLobbyDimensions.gameListMinHeight;
        }
      }
      if (availableHeight >= gameLobbyDimensions.gameDetailsDefaultHeight) {
        requestedGameDetailsHeight =
          gameLobbyDimensions.gameDetailsDefaultHeight;
      } else {
        if (
          requestedGameDetailsHeight < gameLobbyDimensions.gameDetailsMinHeight
        ) {
          requestedGameDetailsHeight = gameLobbyDimensions.gameDetailsMinHeight;
        }
      }

      res.gameListHeight = requestedGameListHeight;
      res.gameDetailsHeight = requestedGameDetailsHeight;
    }
    return res; */
	} catch (error) {
		handleError(error);
	}
}

function getMobileGameLobbyDimensions(resolution) {
	try {
		const _gameButtonsHeight =
			resolution === RESOLUTION.HIGH ? gameDimensions.gameButtons_HR_Height : gameDimensions.gameButtonsHeight;
		let res = {
			gameListLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
			gameListWidth: gameLobbyDimensions.gameListDefaultWidth,
			gameDetailsLeftMargin: gameLobbyDimensions.gameDetailsLeftMargin,
			gameDetailsWidth: gameLobbyDimensions.gameDetailsDefaultWidth,
			gameListHeight: gameLobbyDimensions.gameListDefaultHeight,
			gameDetailsHeight: gameLobbyDimensions.gameDetailsDefaultHeight - _gameButtonsHeight - 5,
		};
		let windowDimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		let top_bottom = gameDimensions.tabAreaDefaultHeight + _gameButtonsHeight; //+
		// gameDimensions.notificationBarDefaultHeight +
		// gameDimensions.pageContentTopMargin +
		/* gameDimensions.pageContentBottomMargin */ let newHeight = windowDimensions.height - top_bottom;

		res.gameListWidth = (windowDimensions.width - 2 * gameDimensions.fullWidthMargin - 80) / 2;
		res.gameListHeight = newHeight;
		res.gameListLeftMargin = 0;
		res.gameDetailsWidth = (windowDimensions.width - 2 * gameDimensions.fullWidthMargin - 80) / 2;
		res.gameDetailsHeight = newHeight /* - _gameButtonsHeight - 5 */;
		res.gameDetailsLeftMargin = 5;

		return res;
	} catch (error) {
		handleError(error);
	}
}

function getDesktopCreateNewGameWidthDimensions() {
	try {
		let res = {
			slotSettingsWidth: newGameDimensions.slotSettingsDefaultWidth,
			slotSettingsLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
			otherSettingsWidth: newGameDimensions.otherSettingsDefaultWidth,
			otherSettingsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
		};
		let windowDimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");

		res.slotSettingsWidth = Math.round((windowDimensions.width - 2 * gameDimensions.fullWidthMargin) / 2);
		/* res.slotSettingsWidth = Math.round(
      windowDimensions.width - 2 * gameDimensions.fullWidthMargin - 400
    ); */
		res.slotSettingsLeftMargin = 0;
		res.otherSettingsWidth = Math.round((windowDimensions.width - 2 * gameDimensions.fullWidthMargin) / 2);
		// res.otherSettingsWidth = 400;
		res.otherSettingsLeftMargin = 0;

		return res;
		/* let res = {
      slotSettingsLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
      slotSettingsWidth: newGameDimensions.slotSettingsDefaultWidth,
      otherSettingsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
      otherSettingsWidth: newGameDimensions.otherSettingsDefaultWidth,
    };
    let windowDimensions = Dimensions.get("window");
    let requestedWidth_createNewGame =
      newGameDimensions.slotSettingsDefaultWidth +
      newGameDimensions.otherSettingsDefaultWidth +
      gameDimensions.fullWidthMargin +
      gameDimensions.rightBoxDefaultLeftMargin;

    if (windowDimensions.width >= requestedWidth_createNewGame) {
      res.slotSettingsLeftMargin = Math.floor(
        (windowDimensions.width - requestedWidth_createNewGame) / 2
      );
      res.slotSettingsWidth = newGameDimensions.slotSettingsDefaultWidth;
      res.slotSettingsHeight = newGameDimensions.slotSettingsDefaultHeight;
      res.otherSettingsLeftMargin = gameDimensions.rightBoxDefaultLeftMargin;
      res.otherSettingsWidth = newGameDimensions.otherSettingsDefaultWidth;
      res.otherSettingsHeight = newGameDimensions.otherSettingsDefaultHeight;
    } else {
      let slotSettingsFactor =
        newGameDimensions.slotSettingsDefaultWidth /
        requestedWidth_createNewGame;
      let otherSettingsFactor =
        newGameDimensions.otherSettingsDefaultWidth /
        requestedWidth_createNewGame;

      let requestedOtherSettingsWidth =
          windowDimensions.width * otherSettingsFactor,
        requestedSlotSettingsWidth =
          windowDimensions.width * slotSettingsFactor;
      if (
        requestedOtherSettingsWidth >=
        newGameDimensions.otherSettingsDefaultWidth
      ) {
        requestedOtherSettingsWidth =
          newGameDimensions.otherSettingsDefaultWidth;
      } else {
        if (
          requestedOtherSettingsWidth < newGameDimensions.otherSettingsMinWidth
        ) {
          requestedOtherSettingsWidth = newGameDimensions.otherSettingsMinWidth;
        }
      }

      if (
        requestedSlotSettingsWidth >= newGameDimensions.slotSettingsDefaultWidth
      ) {
        requestedSlotSettingsWidth = newGameDimensions.slotSettingsDefaultWidth;
      } else {
        if (
          requestedSlotSettingsWidth < newGameDimensions.slotSettingsMinWidth
        ) {
          requestedSlotSettingsWidth = newGameDimensions.slotSettingsMinWidth;
        }
      }

      res.slotSettingsLeftMargin = gameDimensions.fullWidthMargin;
      res.slotSettingsWidth = Math.floor(requestedSlotSettingsWidth);
      res.otherSettingsWidth = Math.floor(requestedOtherSettingsWidth);
      res.otherSettingsLeftMargin = gameDimensions.fullWidthMargin;
    }
    return res; */
	} catch (error) {
		handleError(error);
	}
}

function getDesktopCreateNewGameHeightDimensions(resolution) {
	try {
		const _gameButtonsHeight =
			resolution === RESOLUTION.HIGH ? gameDimensions.gameButtons_HR_Height : gameDimensions.gameButtonsHeight;
		let res = {
			slotSettingsHeight: newGameDimensions.slotSettingsDefaultHeight,
			otherSettingsHeight: newGameDimensions.otherSettingsDefaultHeight - _gameButtonsHeight - 5,
		};
		let windowDimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		let top_bottom =
			gameDimensions.tabAreaDefaultHeight +
			gameDimensions.notificationBarDefaultHeight +
			gameDimensions.pageContentTopMargin +
			gameDimensions.pageContentBottomMargin;
		let newHeight = windowDimensions.height - top_bottom;

		res.slotSettingsHeight = newHeight;
		res.otherSettingsHeight = newHeight - _gameButtonsHeight - 5;

		return res;
		/* let res = {
      slotSettingsHeight: newGameDimensions.slotSettingsDefaultHeight,
      otherSettingsHeight: newGameDimensions.otherSettingsDefaultHeight,
    };
    let windowDimensions = Dimensions.get("window");

    let top_bottom =
      gameDimensions.tabAreaDefaultHeight +
      gameDimensions.notificationBarDefaultHeight +
      gameDimensions.pageContentTopMargin +
      gameDimensions.pageContentBottomMargin;
    let requestedSlotSettingsHeight =
      top_bottom + newGameDimensions.slotSettingsDefaultHeight;
    let requestedOtherSettingsHeight =
      top_bottom + newGameDimensions.otherSettingsDefaultHeight;
    let requestedHeight_createNewGames =
      requestedSlotSettingsHeight >= requestedOtherSettingsHeight
        ? requestedSlotSettingsHeight
        : requestedOtherSettingsHeight;

    if (windowDimensions.height >= requestedHeight_createNewGames) {
      res.slotSettingsHeight = newGameDimensions.slotSettingsDefaultHeight;
      res.otherSettingsHeight = newGameDimensions.otherSettingsDefaultHeight;
    } else {
      let availableHeight = Math.abs(windowDimensions.height - top_bottom),
        requestedSlotSettingsHeight = availableHeight,
        requestedOtherSettingsHeight = availableHeight;
      if (availableHeight >= newGameDimensions.slotSettingsDefaultHeight) {
        requestedSlotSettingsHeight =
          newGameDimensions.slotSettingsDefaultHeight;
      } else {
        if (
          requestedSlotSettingsHeight < newGameDimensions.slotSettingsMinHeight
        ) {
          requestedSlotSettingsHeight = newGameDimensions.slotSettingsMinHeight;
        }
      }
      if (availableHeight >= newGameDimensions.otherSettingsDefaultHeight) {
        requestedOtherSettingsHeight =
          newGameDimensions.otherSettingsDefaultHeight;
      } else {
        if (
          requestedOtherSettingsHeight <
          newGameDimensions.otherSettingsMinHeight
        ) {
          requestedOtherSettingsHeight =
            newGameDimensions.otherSettingsMinHeight;
        }
      }

      res.slotSettingsHeight = requestedSlotSettingsHeight;
      res.otherSettingsHeight = requestedOtherSettingsHeight;
    }
    return res; */
	} catch (error) {
		handleError(error);
	}
}

function getMobileCreateNewGameDimensions(resolution) {
	try {
		const _gameButtonsHeight =
			resolution === RESOLUTION.HIGH ? gameDimensions.gameButtons_HR_Height : gameDimensions.gameButtonsHeight;
		let res = {
			slotSettingsWidth: newGameDimensions.slotSettingsDefaultWidth,
			slotSettingsHeight: newGameDimensions.slotSettingsDefaultHeight,
			slotSettingsLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
			otherSettingsWidth: newGameDimensions.otherSettingsDefaultWidth,
			otherSettingsHeight: newGameDimensions.otherSettingsDefaultHeight - _gameButtonsHeight - 5,
			otherSettingsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
		};
		let windowDimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		let top_bottom =
			gameDimensions.tabAreaDefaultHeight +
			gameDimensions.notificationBarDefaultHeight +
			gameDimensions.pageContentTopMargin +
			gameDimensions.pageContentBottomMargin;
		let newHeight = windowDimensions.height - top_bottom;

		res.slotSettingsWidth = Math.round((windowDimensions.width - 2 * gameDimensions.fullWidthMargin) / 2);
		res.slotSettingsHeight = newHeight;
		res.slotSettingsLeftMargin = 0;
		res.otherSettingsWidth = Math.round((windowDimensions.width - 2 * gameDimensions.fullWidthMargin) / 2);
		res.otherSettingsHeight = newHeight - _gameButtonsHeight - 5;
		res.otherSettingsLeftMargin = 0;

		return res;
	} catch (error) {
		handleError(error);
	}
}

function getDesktopGameRoomWidthDimensions() {
	try {
		let res = {
			grSlotSettingsWidth: gameRoomDimensions.grSlotSettingsDefaultWidth,
			grSlotSettingsLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
			grOtherSettingsWidth: gameRoomDimensions.grOtherSettingsDefaultWidth,
			grOtherSettingsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
			grChatWidth: gameRoomDimensions.grChatDefaultWidth,
			grChatLeftMargin: gameRoomDimensions.grChatLeftMargin,
		};
		let windowDimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		/* gameDimensions.notificationBarDefaultHeight +
      gameDimensions.pageContentTopMargin +
      gameDimensions.pageContentBottomMargin */

		let calcWidth = Math.round((windowDimensions.width - 2 * gameDimensions.fullWidthMargin) / 2);
		res.grSlotSettingsWidth = calcWidth;

		res.grSlotSettingsLeftMargin = 0;
		res.grOtherSettingsWidth = calcWidth;
		res.grOtherSettingsLeftMargin = 0;
		res.grChatWidth = calcWidth;
		res.grChatLeftMargin = 0;

		return res;
		/* let res = {
      grSlotSettingsLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
      grSlotSettingsWidth: gameRoomDimensions.grSlotSettingsDefaultWidth,
      grOtherSettingsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
      grOtherSettingsWidth: gameRoomDimensions.grOtherSettingsDefaultWidth,
      grChatWidth: gameRoomDimensions.grChatDefaultWidth,
      grChatLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
    };
    let windowDimensions = Dimensions.get("window");
    let requestedWidth_gameRoom =
      gameRoomDimensions.grSlotSettingsDefaultWidth +
      gameRoomDimensions.grOtherSettingsDefaultWidth +
      gameDimensions.fullWidthMargin +
      gameDimensions.rightBoxDefaultLeftMargin;

    if (windowDimensions.width >= requestedWidth_gameRoom) {
      var calcLeftMargin = Math.floor(
        (windowDimensions.width - requestedWidth_gameRoom) / 2
      );
      res.grSlotSettingsLeftMargin = calcLeftMargin;
      res.grSlotSettingsWidth = gameRoomDimensions.grSlotSettingsDefaultWidth;
      res.grSlotSettingsHeight = gameRoomDimensions.grSlotSettingsDefaultHeight;
      res.grOtherSettingsLeftMargin = gameDimensions.rightBoxDefaultLeftMargin;
      res.grOtherSettingsWidth = gameRoomDimensions.grOtherSettingsDefaultWidth;
      res.grOtherSettingsHeight =
        gameRoomDimensions.grOtherSettingsDefaultHeight;
      res.grChatWidth = gameRoomDimensions.grChatDefaultWidth;
      res.grChatLeftMargin = calcLeftMargin;
    } else {
      let slotSettingsFactor =
        gameRoomDimensions.grSlotSettingsDefaultWidth / requestedWidth_gameRoom;
      let otherSettingsFactor =
        gameRoomDimensions.grOtherSettingsDefaultWidth /
        requestedWidth_gameRoom;

      let requestedOtherSettingsWidth =
          windowDimensions.width * otherSettingsFactor,
        requestedSlotSettingsWidth =
          windowDimensions.width * slotSettingsFactor;
      if (
        requestedOtherSettingsWidth >=
        gameRoomDimensions.grOtherSettingsDefaultWidth
      ) {
        requestedOtherSettingsWidth =
          gameRoomDimensions.grOtherSettingsDefaultWidth;
      } else {
        if (
          requestedOtherSettingsWidth <
          gameRoomDimensions.grOtherSettingsMinWidth
        ) {
          requestedOtherSettingsWidth =
            gameRoomDimensions.grOtherSettingsMinWidth;
        }
      }

      if (
        requestedSlotSettingsWidth >=
        gameRoomDimensions.grSlotSettingsDefaultWidth
      ) {
        requestedSlotSettingsWidth =
          gameRoomDimensions.grSlotSettingsDefaultWidth;
      } else {
        if (
          requestedSlotSettingsWidth < gameRoomDimensions.grSlotSettingsMinWidth
        ) {
          requestedSlotSettingsWidth =
            gameRoomDimensions.grSlotSettingsMinWidth;
        }
      }

      res.grSlotSettingsLeftMargin = gameDimensions.fullWidthMargin;
      res.grSlotSettingsWidth = Math.floor(requestedSlotSettingsWidth);
      res.grOtherSettingsWidth = Math.floor(requestedOtherSettingsWidth);
      res.grOtherSettingsLeftMargin = gameDimensions.fullWidthMargin;
      res.grChatWidth =
        windowDimensions.width - 5 * gameDimensions.fullWidthMargin;
      res.grChatLeftMargin = gameDimensions.fullWidthMargin;
    }
    return res; */
	} catch (error) {
		handleError(error);
	}
}

function getDesktopGameRoomHeightDimensions(grChatCurrentHeight, resolution) {
	try {
		const _gameButtonsHeight =
			resolution === RESOLUTION.HIGH ? gameDimensions.gameButtons_HR_Height : gameDimensions.gameButtonsHeight;
		let res = {
			grSlotSettingsHeight: gameRoomDimensions.grSlotSettingsDefaultHeight - gameRoomDimensions.chatInputHeight - 5,
			grOtherSettingsHeight: gameRoomDimensions.grOtherSettingsDefaultHeight - _gameButtonsHeight - 5,
			grChatHeight: gameRoomDimensions.grChatDefaultHeight,
			grChatBodyHeight: gameRoomDimensions.grChatDefaultWidth - 100,
		};
		let windowDimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		let top_bottom = gameDimensions.tabAreaDefaultHeight + gameRoomDimensions.chatInputHeight + 5;
		/* gameDimensions.notificationBarDefaultHeight +
      gameDimensions.pageContentTopMargin +
      gameDimensions.pageContentBottomMargin */

		let newHeight = windowDimensions.height - top_bottom;

		if (resolution === RESOLUTION.HIGH) {
			res.grSlotSettingsHeight = newHeight - _gameButtonsHeight + 40;
		} else {
			res.grSlotSettingsHeight = newHeight /* - gameRoomDimensions.chatInputHeight - 5 */;
		}
		res.grOtherSettingsHeight = newHeight - gameRoomDimensions.chatInputHeight - 5;
		res.grChatHeight = newHeight;
		res.grChatBodyHeight = newHeight - 100;

		return res;
		/* let res = {
      grSlotSettingsHeight: gameRoomDimensions.grSlotSettingsDefaultHeight,
      grOtherSettingsHeight: gameRoomDimensions.grOtherSettingsDefaultHeight,
      grChatHeight: gameRoomDimensions.grChatDefaultHeight,
      grChatBodyHeight: gameRoomDimensions.grChatDefaultHeight - 100,
    };
    let windowDimensions = Dimensions.get("window");

    let top_bottom =
      gameDimensions.tabAreaDefaultHeight +
      gameDimensions.notificationBarDefaultHeight +
      gameDimensions.pageContentTopMargin +
      gameDimensions.pageContentBottomMargin;
    let requestedSlotSettingsHeight =
      top_bottom +
      gameRoomDimensions.grSlotSettingsDefaultHeight +
      gameRoomDimensions.grChatDefaultHeight;
    let requestedOtherSettingsHeight =
      top_bottom +
      gameRoomDimensions.grOtherSettingsDefaultHeight +
      gameRoomDimensions.grChatDefaultHeight;
    let requestedHeight_gameRoom =
      requestedSlotSettingsHeight >= requestedOtherSettingsHeight
        ? requestedSlotSettingsHeight
        : requestedOtherSettingsHeight;

    if (windowDimensions.height >= requestedHeight_gameRoom) {
      res.grSlotSettingsHeight = gameRoomDimensions.grSlotSettingsDefaultHeight;
      res.grOtherSettingsHeight =
        gameRoomDimensions.grOtherSettingsDefaultHeight;

      let higherDivHeight =
          gameRoomDimensions.grSlotSettingsDefaultHeight >=
          gameRoomDimensions.grOtherSettingsDefaultHeight
            ? gameRoomDimensions.grSlotSettingsDefaultHeight
            : gameRoomDimensions.grOtherSettingsDefaultHeight,
        availableHeightForChat = Math.abs(
          windowDimensions.height - top_bottom - higherDivHeight
        ),
        requestedChatHeight = availableHeightForChat - 10;
      if (availableHeightForChat >= gameRoomDimensions.grChatMaxHeight) {
        requestedChatHeight = gameRoomDimensions.grChatMaxHeight;
      } else {
        if (requestedChatHeight < gameRoomDimensions.grChatMinHeight) {
          requestedChatHeight = gameRoomDimensions.grChatMinHeight;
        }
      }
      res.grChatHeight = requestedChatHeight;
      res.grChatBodyHeight = requestedChatHeight - 100;
    } else {
      let availableHeight = Math.abs(
          windowDimensions.height - top_bottom - grChatCurrentHeight
        ),
        requestedSlotSettingsHeight = availableHeight,
        requestedOtherSettingsHeight = availableHeight;
      if (availableHeight >= gameRoomDimensions.grSlotSettingsDefaultHeight) {
        requestedSlotSettingsHeight =
          gameRoomDimensions.grSlotSettingsDefaultHeight;
      } else {
        if (
          requestedSlotSettingsHeight <
          gameRoomDimensions.grSlotSettingsMinHeight
        ) {
          requestedSlotSettingsHeight =
            gameRoomDimensions.grSlotSettingsMinHeight;
        }
      }
      if (availableHeight >= gameRoomDimensions.grOtherSettingsDefaultHeight) {
        requestedOtherSettingsHeight =
          gameRoomDimensions.grOtherSettingsDefaultHeight;
      } else {
        if (
          requestedOtherSettingsHeight <
          gameRoomDimensions.grOtherSettingsMinHeight
        ) {
          requestedOtherSettingsHeight =
            gameRoomDimensions.grOtherSettingsMinHeight;
        }
      }
      res.grSlotSettingsHeight = requestedSlotSettingsHeight;
      res.grOtherSettingsHeight = requestedOtherSettingsHeight;

      let higherDivHeight =
          requestedSlotSettingsHeight >= requestedOtherSettingsHeight
            ? requestedSlotSettingsHeight
            : requestedOtherSettingsHeight,
        availableHeightForChat = Math.abs(
          windowDimensions.height - top_bottom - higherDivHeight
        ),
        requestedChatHeight = availableHeightForChat - 10;
      if (requestedChatHeight >= gameRoomDimensions.grChatDefaultHeight) {
        requestedChatHeight = gameRoomDimensions.grChatDefaultHeight;
      } else {
        if (requestedChatHeight < gameRoomDimensions.grChatMinHeight) {
          requestedChatHeight = gameRoomDimensions.grChatMinHeight;
        }
      }
      res.grChatHeight = requestedChatHeight;
      res.grChatBodyHeight = requestedChatHeight - 100;
    }
    return res; */
	} catch (error) {
		handleError(error);
	}
}

function getMobileGameRoomDimensions(resolution) {
	try {
		const _gameButtonsHeight =
			resolution === RESOLUTION.HIGH ? gameDimensions.gameButtons_HR_Height : gameDimensions.gameButtonsHeight;
		let res = {
			grSlotSettingsWidth: gameRoomDimensions.grSlotSettingsDefaultWidth,
			grSlotSettingsHeight: gameRoomDimensions.grSlotSettingsDefaultHeight - gameRoomDimensions.chatInputHeight - 5,
			grSlotSettingsLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
			grOtherSettingsWidth: gameRoomDimensions.grOtherSettingsDefaultWidth,
			grOtherSettingsHeight: gameRoomDimensions.grOtherSettingsDefaultHeight - _gameButtonsHeight - 5,
			grOtherSettingsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
			grChatWidth: gameRoomDimensions.grChatDefaultWidth,
			grChatHeight: gameRoomDimensions.grChatDefaultHeight,
			grChatLeftMargin: gameRoomDimensions.grChatLeftMargin,
			grChatBodyHeight: gameRoomDimensions.grChatDefaultWidth - 100,
		};
		let windowDimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		let top_bottom = gameDimensions.tabAreaDefaultHeight + gameRoomDimensions.chatInputHeight + 5;
		/* gameDimensions.notificationBarDefaultHeight +
      gameDimensions.pageContentTopMargin +
      gameDimensions.pageContentBottomMargin */

		let newHeight = windowDimensions.height - top_bottom;
		let calcWidth = Math.round((windowDimensions.width - 2 * gameDimensions.fullWidthMargin) / 2);
		res.grSlotSettingsWidth = calcWidth;

		if (resolution === RESOLUTION.HIGH) {
			res.grSlotSettingsHeight = newHeight - _gameButtonsHeight + 40;
		} else {
			res.grSlotSettingsHeight = newHeight /* - gameRoomDimensions.chatInputHeight - 5 */;
		}
		res.grSlotSettingsLeftMargin = 0;
		res.grOtherSettingsWidth = calcWidth;
		res.grOtherSettingsHeight = newHeight - gameRoomDimensions.chatInputHeight - 5;
		res.grOtherSettingsLeftMargin = 0;
		res.grChatWidth = calcWidth;
		res.grChatHeight = newHeight;
		res.grChatLeftMargin = 0;
		res.grChatBodyHeight = newHeight - 100;

		return res;
	} catch (error) {
		handleError(error);
	}
}
