import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import Position from "./Position";

class StartPositionsContainer extends Component {
	//#region render methods
	renderStartPositions() {
		const { playerPanel, panelScale } = this.props;
		var startPositions = [];
		switch (playerPanel.type) {
			case "TL":
				this.props.positions.forEach((sp, i) => {
					var _top = 0,
						_left = 0;
					if (this.props.playerCnt === 2) {
						_top = (playerPanel.top + 42) * panelScale + 7;
						_left = (playerPanel.left + i * 27) * panelScale + i * 27 * (1 - panelScale);
						_top = Math.floor(_top);
						_left = Math.floor(_left);
					} else {
						_top = (playerPanel.top - 25) * panelScale - 25 * (1 - panelScale) - 3;
						_left = (playerPanel.left + i * 27) * panelScale + i * 27 * (1 - panelScale);
						_top = Math.floor(_top);
						_left = Math.floor(_left);
					}
					startPositions.push(
						<Position
							key={"pos_" + sp.pos}
							id={sp.pos}
							gameTheme={this.props.gameTheme}
							animatedMove={sp.animatedMove}
							requestHint={sp.requestHint}
							playable={sp.playable}
							filled={sp.filled}
							selected={sp.selected}
							filtered={sp.filtered}
							lastSwithed={sp.lastSwithed}
							moveEnd={sp.moveEnd}
							moveStarter={sp.moveStarter}
							implicatedInMove={sp.implicatedInMove}
							baseColor={sp.color ? sp.color.normal : "#"}
							isBaseColored={true}
							pawnColor={sp.color ? sp.color.normal : "#"}
							top={_top}
							left={_left}
							mainObj={sp}
							onFinishedPawnMoveAction={this.props.onFinishedPawnMoveAction}
						/>
					);
				});
				break;
			case "TR":
				this.props.positions.forEach((sp, i) => {
					var _top = 0,
						_left = 0;
					if (this.props.playerCnt === 2) {
						_top = (playerPanel.top - 25) * panelScale - 25 * (1 - panelScale) - 7;
						_left =
							(playerPanel.left - 56 + i * 27) * panelScale - 27 * (1 - panelScale) * (this.props.positions.length - i);
					} else {
						_top = (playerPanel.top - 25) * panelScale - 25 * (1 - panelScale) - 3;
						_left =
							(playerPanel.left - 56 + i * 27) * panelScale - 27 * (1 - panelScale) * (this.props.positions.length - i);
					}
					_top = Math.floor(_top);
					_left = Math.floor(_left);
					startPositions.push(
						<Position
							key={"pos_" + sp.pos}
							id={sp.pos}
							gameTheme={this.props.gameTheme}
							animatedMove={sp.animatedMove}
							requestHint={sp.requestHint}
							playable={sp.playable}
							filled={sp.filled}
							selected={sp.selected}
							filtered={sp.filtered}
							lastSwithed={sp.lastSwithed}
							moveEnd={sp.moveEnd}
							moveStarter={sp.moveStarter}
							implicatedInMove={sp.implicatedInMove}
							baseColor={sp.color ? sp.color.normal : "#"}
							isBaseColored={true}
							pawnColor={sp.color ? sp.color.normal : "#"}
							top={_top}
							left={_left}
							mainObj={sp}
							onFinishedPawnMoveAction={this.props.onFinishedPawnMoveAction}
						/>
					);
				});
				break;
			case "BL":
				this.props.positions.forEach((sp, i) => {
					var _top = (playerPanel.top + 42) * panelScale + 2;
					var _left = (playerPanel.left + i * 27) * panelScale + i * 27 * (1 - panelScale);
					_top = Math.floor(_top);
					_left = Math.floor(_left);
					startPositions.push(
						<Position
							key={"pos_" + sp.pos}
							id={sp.pos}
							gameTheme={this.props.gameTheme}
							animatedMove={sp.animatedMove}
							requestHint={sp.requestHint}
							playable={sp.playable}
							filled={sp.filled}
							selected={sp.selected}
							filtered={sp.filtered}
							lastSwithed={sp.lastSwithed}
							moveEnd={sp.moveEnd}
							moveStarter={sp.moveStarter}
							implicatedInMove={sp.implicatedInMove}
							baseColor={sp.color ? sp.color.normal : "#"}
							isBaseColored={true}
							pawnColor={sp.color ? sp.color.normal : "#"}
							top={_top}
							left={_left}
							mainObj={sp}
							onFinishedPawnMoveAction={this.props.onFinishedPawnMoveAction}
						/>
					);
				});
				break;
			case "BR":
				this.props.positions.forEach((sp, i) => {
					var _top = (playerPanel.top + 42) * panelScale + 2;
					var _left =
						(playerPanel.left - 56 + i * 27) * panelScale - 27 * (1 - panelScale) * (this.props.positions.length - i);
					_top = Math.floor(_top);
					_left = Math.floor(_left);
					startPositions.push(
						<Position
							key={"pos_" + sp.pos}
							id={sp.pos}
							gameTheme={this.props.gameTheme}
							animatedMove={sp.animatedMove}
							requestHint={sp.requestHint}
							playable={sp.playable}
							filled={sp.filled}
							selected={sp.selected}
							filtered={sp.filtered}
							lastSwithed={sp.lastSwithed}
							moveEnd={sp.moveEnd}
							moveStarter={sp.moveStarter}
							implicatedInMove={sp.implicatedInMove}
							baseColor={sp.color ? sp.color.normal : "#"}
							isBaseColored={true}
							pawnColor={sp.color ? sp.color.normal : "#"}
							top={_top}
							left={_left}
							mainObj={sp}
							onFinishedPawnMoveAction={this.props.onFinishedPawnMoveAction}
						/>
					);
				});
				break;
		}
		return startPositions;
	}

	render() {
		if (!Array.isArray(this.props.positions)) return null;
		return <View>{this.renderStartPositions()}</View>;
	}
	//#endregion
}

const styles = StyleSheet.create({});

export default StartPositionsContainer;
