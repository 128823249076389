import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { appFont, f_sourcesansprosemibold, isWebOrTablet, TOURNAMENT_TEAM_TYPE } from "../../../config/defaults";
import { TOGGLE_ICON } from "../../../config/images";
import { selectNewTeamType } from "../../../redux/actions";
import { AppTouchableOpacity } from "../../common";
import { handleError } from "../../ErrorHandler";

class TeamTypeToggle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			teamTypes: this.initTeamTypes(),
			selectedIndex: 0,
		};
	}

	//#region events
	toggleDataSource(prev = true) {
		try {
			const curSelInd = this.state.teamTypes.findIndex((e) => e.selected);
			if (curSelInd >= 0) {
				var nextSelInd = curSelInd;
				if (prev) {
					nextSelInd = curSelInd - 1;
					nextSelInd = nextSelInd < 0 ? this.state.teamTypes.length - 1 : nextSelInd;
				} else {
					nextSelInd = curSelInd + 1;
					nextSelInd = nextSelInd == this.state.teamTypes.length ? 0 : nextSelInd;
				}
				var newDataSource = cloneDeep(this.state.teamTypes);
				newDataSource.forEach((el) => (el.selected = false));
				newDataSource[nextSelInd].selected = true;
				this.setState({ teamTypes: newDataSource, selectedIndex: nextSelInd });

				const selectedType = newDataSource.length > 0 ? newDataSource[nextSelInd] : undefined;
				if (selectedType && selectedType.typeName) {
					this.props.selectNewTeamType(selectedType.typeName);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		const selectedType = this.state.teamTypes.length > 0 ? this.state.teamTypes[this.state.selectedIndex] : undefined;
		return (
			<View style={styles.mainContainer}>
				<View style={styles.labelContainer}>
					<Text allowFontScaling={false} selectable={false} style={styles.labelText}>
						{"Type of team: "}
					</Text>
				</View>
				<View style={styles.toggleContainer}>
					<AppTouchableOpacity
						style={[styles.toggleButton, { opacity: 1 }]}
						onPress={() => this.toggleDataSource(true)}
						accessibilityLabel={"previous team type"}
					>
						<Image source={TOGGLE_ICON} style={styles.prevImage} resizeMode="stretch" />
					</AppTouchableOpacity>
					<Text allowFontScaling={false} style={styles.toggleTextStyle}>
						{selectedType == undefined ? "" : selectedType.typeName}
					</Text>
					<AppTouchableOpacity
						style={styles.toggleButton}
						onPress={() => this.toggleDataSource(false)}
						accessibilityLabel={"next team type"}
					>
						<Image source={TOGGLE_ICON} style={styles.nextImage} resizeMode="stretch" />
					</AppTouchableOpacity>
				</View>
			</View>
		);
	}
	//#endregion

	//#region helpers
	initTeamTypes() {
		try {
			var types = [];
			Object.keys(TOURNAMENT_TEAM_TYPE).forEach((type, index) => {
				types.push({
					typeID: index,
					typeName: type,
					selected: index == 0,
				});
			});
			return types;
		} catch (err) {
			handleError(err);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: "row",
		height: 60,
		paddingTop: 5,
		paddingBottom: 5,
	},
	labelContainer: { flex: 2, height: 50 },
	labelText: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: isWebOrTablet ? 20 : 18,
		textAlignVertical: "center",
		alignSelf: "flex-end",
		height: 50,
		lineHeight: 50,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	toggleContainer: {
		flex: 2,
		height: 50,
		flexDirection: "row",
		alignSelf: "flex-start",
	},
	toggleButton: {
		width: 50,
		height: 50,
		marginTop: "auto",
		marginBottom: "auto",
		justifyContent: "center",
	},
	toggleTextStyle: {
		minWidth: 200,
		fontFamily: appFont,
		fontSize: isWebOrTablet ? 20 : 18,
		lineHeight: 50,
		textAlign: "center",
		color: "#00fffd",
		marginRight: 5,
		paddingTop: 0,
		paddingLeft: 3,
		paddingRight: 3,
		overflow: "hidden",
		height: 50,
	},
	prevImage: { width: 40, height: 40 },
	nextImage: {
		width: 40,
		height: 40,
		transform: [{ rotateY: "-180deg" }],
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = { selectNewTeamType };

export default connect(mapStateToProps, mapDispatchToProps)(TeamTypeToggle);
