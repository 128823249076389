import React from "react";
import { ImageBackground, Platform, StyleSheet, Text } from "react-native";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

const GameButton = ({
	onPress,
	onMouseEnter,
	onMouseLeave,
	onLayout,
	disabled,
	myStyle,
	textContent,
	textContentStyle,
	backgroundImage,
	additionalBackImageStyle,
	silentPress = false,
	isInMenu = false,
	accessibilityLabel,
}) => {
	const defaultButton = (
		<AppTouchableOpacity
			onPress={onPress}
			onLayout={onLayout}
			disabled={disabled || false}
			onMouseEnter={onMouseEnter || null}
			onMouseLeave={onMouseLeave || null}
			style={[
				styles.buttonGenericStyle,
				myStyle,
				Platform.OS === "web" ? { cursor: disabled ? "default" : "pointer" } : {},
			]}
			activeOpacity={disabled ? 0.5 : 0.8}
			silentPress={silentPress}
			isInMenu={isInMenu}
			accessibilityState={{ disabled: disabled || false }}
			accessibilityLabel={accessibilityLabel || textContent}
		>
			<Text allowFontScaling={false} style={[styles.textStyle, textContentStyle]}>
				{textContent ? textContent : ""}
			</Text>
			<Text
				allowFontScaling={false}
				style={[styles.textStyle, textContentStyle, styles.textOutline, { textShadowOffset: { width: 2, height: -2 } }]}
			>
				{textContent ? textContent : ""}
			</Text>
			<Text
				allowFontScaling={false}
				style={[styles.textStyle, textContentStyle, styles.textOutline, { textShadowOffset: { width: -2, height: 2 } }]}
			>
				{textContent ? textContent : ""}
			</Text>
			<Text
				allowFontScaling={false}
				style={[
					styles.textStyle,
					textContentStyle,
					styles.textOutline,
					{ textShadowOffset: { width: -2, height: -2 } },
				]}
			>
				{textContent ? textContent : ""}
			</Text>
			<Text
				allowFontScaling={false}
				style={[styles.textStyle, textContentStyle, styles.textOutline, { textShadowOffset: { width: 2, height: 0 } }]}
			>
				{textContent ? textContent : ""}
			</Text>
			<Text
				allowFontScaling={false}
				style={[styles.textStyle, textContentStyle, styles.textOutline, { textShadowOffset: { width: 0, height: 2 } }]}
			>
				{textContent ? textContent : ""}
			</Text>
			<Text
				allowFontScaling={false}
				style={[styles.textStyle, textContentStyle, styles.textOutline, { textShadowOffset: { width: -2, height: 0 } }]}
			>
				{textContent ? textContent : ""}
			</Text>
			<Text
				allowFontScaling={false}
				style={[styles.textStyle, textContentStyle, styles.textOutline, { textShadowOffset: { width: 0, height: -2 } }]}
			>
				{textContent ? textContent : ""}
			</Text>
		</AppTouchableOpacity>
	);

	const renderButton = () => {
		if (typeof backgroundImage !== "undefined") {
			return (
				<ImageBackground
					//source={{ uri: backgroundImage.uri }}
					source={backgroundImage.uri}
					resizeMode={backgroundImage.resizeMode || "stretch"}
					style={[
						{ opacity: disabled ? 0.5 : 1 },
						backgroundImage.style || { display: "none" },
						additionalBackImageStyle || {},
					]}
					imageStyle={backgroundImage.imageStyle}
				>
					{defaultButton}
				</ImageBackground>
			);
		}
		return defaultButton;
	};

	return renderButton();
};

const styles = StyleSheet.create({
	textStyle: {
		alignSelf: "center",
		color: "#007aff",
		fontSize: 16,
		paddingTop: 10,
		paddingBottom: 10,
	},
	buttonGenericStyle: {
		backgroundColor: "#fff",
		borderRadius: 5,
		borderWidth: 1,
		borderColor: "#007aff",
	},
	textOutline: {
		position: "absolute",
		zIndex: 0,
	},
});

export { GameButton };
