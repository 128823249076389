import { cloneDeep, orderBy } from "lodash";
import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, SafeAreaView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	f_sourcesansprobold,
	f_sourcesansprolight,
	f_sourcesansprosemibold,
	playerList,
	RESOLUTION,
	SHOP_CATEGORY,
	tabType,
} from "../../../../config/defaults";
import { playersListDimensions } from "../../../../config/dimensions";
import { ACTIVE_CATEGORY, INACTIVE_CATEGORY, TEXT_INPUT } from "../../../../config/images";
import { allowPlayerInfo } from "../../../../config/permissions";
import { MyWebSocket } from "../../../../connection";
import { mapObject } from "../../../../helpers/commonHelpers";
import {
	clearSearchedUsersList,
	closeComposeMessage,
	closePlayersList,
	goBackToMain,
	openMiscShop,
	openTab,
	startPlay,
} from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { AppTouchableOpacity, NavigationHeader, PlayerInfo } from "../../../common";
import { handleError } from "../../../ErrorHandler";
import ComposeMessage from "../../../GameMessage/ComposeMessage";
import MiscShop from "../../../GameShop/MiscShop";
import FriendBanListTable from "./FriendBanListTable";
import PlayersListTable from "./PlayersListTable";

class PlayersList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			activeList: playerList.FRIEND_LIST,
			searchTerm: "",
			isSearchInProgress: false,
			friendList: this.getPlayersList(playerList.FRIEND_LIST),
			banList: this.getPlayersList(playerList.BAN_LIST),
			playersList: this.getPlayersList(playerList.PLAYERS_LIST),
			showPlayerInfo: false,
			maxVisibleRows: 1,
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		Analytics.logScreenView("FriendBanListSettings");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.friends != this.props.friends) {
			var searchedF = this.state.friendList.find((pl) => pl.selected == true),
				searchedPl = this.state.playersList.find((pl) => pl.selected == true);
			var newFriendL = this.getPlayersList(playerList.FRIEND_LIST),
				newPlList = this.getPlayersList(playerList.PLAYERS_LIST);
			if (searchedF && searchedF.userID) {
				const found = newFriendL.find((fr) => fr.userID == searchedF.userID);
				if (found && found.userID) found.selected = true;
			}
			if (searchedPl && searchedPl.userID) {
				const found = newPlList.find((fr) => fr.userID == searchedPl.userID);
				if (found && found.userID) found.selected = true;
			}
			this.setState({ friendList: newFriendL, playersList: newPlList });
		}
		if (prevProps.bannedUsers != this.props.bannedUsers) {
			var searchedB = this.state.banList.find((pl) => pl.selected == true),
				searchedPl = this.state.playersList.find((pl) => pl.selected == true);
			var newBanL = this.getPlayersList(playerList.BAN_LIST),
				newPlList = this.getPlayersList(playerList.PLAYERS_LIST);
			if (searchedB && searchedB.userID) {
				const found = newBanL.find((fr) => fr.userID == searchedB.userID);
				if (found && found.userID) found.selected = true;
			}
			if (searchedPl && searchedPl.userID) {
				const found = newPlList.find((fr) => fr.userID == searchedPl.userID);
				if (found && found.userID) found.selected = true;
			}
			this.setState({ banList: newBanL, playersList: newPlList });
		}
		if (prevProps.searchedUsers != this.props.searchedUsers) {
			var searchedPl = this.state.playersList.find((pl) => pl.selected == true);
			var newPlList = this.getPlayersList(playerList.PLAYERS_LIST);
			if (searchedPl && searchedPl.userID) {
				const found = newPlList.find((fr) => fr.userID == searchedPl.userID);
				if (found && found.userID) found.selected = true;
			}
			this.setState({ isSearchInProgress: false, playersList: newPlList });
		}
	}
	//#endregion

	//#region events
	onBackPress() {
		try {
			if (this.state.showPlayerInfo) {
				var newPlList = cloneDeep(this.state.playersList);
				newPlList.forEach((pl) => (pl.selected = false));
				this.setState({ showPlayerInfo: false, playersList: newPlList });
			} else if (this.props.isComposeOpened) {
				this.props.closeComposeMessage();
			} else {
				this.props.closePlayersList();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onSearchTermChange(text) {
		try {
			if (text != this.state.searchTerm) {
				this.setState({
					searchTerm: text,
					isSearchInProgress: true,
					playersList: [],
				});
				//if searchTerm contains at least 3 characters, search the
				this.searchPlayers(text);
			}
		} catch (error) {
			handleError(error);
		}
	}

	searchPlayers(text) {
		try {
			if (text.length >= 3) {
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "searchUsers",
					username: text,
					maxVisibleRows: this.state.maxVisibleRows,
				});
			} else {
				this.props.clearSearchedUsersList();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onUpgradePress() {
		this.props.goBackToMain();
		this.props.startPlay();
		this.props.openTab(tabType.SHOP);
		switch (this.state.activeList) {
			case playerList.FRIEND_LIST:
				this.props.openMiscShop(SHOP_CATEGORY.FRIEND_LIST);
				break;
			case playerList.BAN_LIST:
				this.props.openMiscShop(SHOP_CATEGORY.BAN_LIST);
				break;
		}
	}

	selectFilteredPlayer(player, listType) {
		try {
			if (allowPlayerInfo == true) {
				var newPlList = cloneDeep(this.state.playersList),
					newFrList = cloneDeep(this.state.friendList),
					newBanList = cloneDeep(this.state.banList);
				newPlList.forEach((pl) => (pl.selected = false));
				newFrList.forEach((pl) => (pl.selected = false));
				newBanList.forEach((pl) => (pl.selected = false));
				switch (listType) {
					case playerList.PLAYERS_LIST:
						const selPlayer = newPlList.find((pl) => pl.userID == player.userID);
						if (selPlayer != undefined) {
							selPlayer.selected = true;
							selPlayer.isFriend = false;
							selPlayer.isBanned = false;
						}
						break;
					case playerList.FRIEND_LIST:
						const selFriend = newFrList.find((pl) => pl.userID == player.userID);
						if (selFriend != undefined) {
							selFriend.selected = true;
							selFriend.isFriend = true;
							selFriend.isBanned = false;
						}
						break;
					case playerList.BAN_LIST:
						const selBanned = newBanList.find((pl) => pl.userID == player.userID);
						if (selBanned != undefined) {
							selBanned.selected = true;
							selBanned.isBanned = true;
							selBanned.isFriend = false;
						}
						break;
				}
				this.setState({
					showPlayerInfo: true,
					playersList: newPlList,
					friendList: newFrList,
					banList: newBanList,
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	onFriendAction(item) {
		try {
			if (item.isFriend == true) {
				//remove from friendlist
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "removeFromFriendList",
						userID: item.userID,
					});
				}
			} else {
				//add to friendlist
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "addToFriendList",
						userID: item.userID,
					});
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onBanAction(item) {
		try {
			if (item.isBanned == true) {
				//remove from banList
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "removeFromBanList",
						userID: item.userID,
					});
				}
			} else {
				//add to banList
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "addToBanList",
						userID: item.userID,
					});
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	changeTab(btnType) {
		this.setState({ activeList: btnType });
		if (btnType == playerList.PLAYERS_LIST) {
			this.searchPlayers(this.state.searchTerm);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var textContent = "";
		switch (btnType) {
			case playerList.FRIEND_LIST:
				textContent = this.props.lang.playersList.btnFriend;
				break;
			case playerList.BAN_LIST:
				textContent = this.props.lang.playersList.btnBan;
				break;
			case playerList.PLAYERS_LIST:
				textContent = this.props.lang.playersList.btnSearch;
				break;
		}

		var categoryImageStyle = styles.categoryImage,
			categoryTextStyle = styles.categoryText,
			categoryButtonStyle = styles.categoryButton;
		if (this.props.resolution === RESOLUTION.HIGH) {
			categoryImageStyle = styles.categoryImageHR;
			categoryTextStyle = [styles.categoryText, { fontSize: 28, paddingTop: 3 }];
			categoryButtonStyle = [styles.categoryButton, { width: 200, height: 60, paddingRight: 3 }];
		}
		return (
			<ImageBackground
				key={btnType}
				source={this.state.activeList == btnType ? ACTIVE_CATEGORY : INACTIVE_CATEGORY}
				resizeMode="stretch"
				style={categoryImageStyle}
			>
				<AppTouchableOpacity activeOpacity={1} onPress={() => this.changeTab(btnType)} style={categoryButtonStyle}>
					<Text allowFontScaling={false} numberOfLines={1} style={categoryTextStyle}>
						{textContent}
					</Text>
				</AppTouchableOpacity>
			</ImageBackground>
		);
	}

	renderTitle() {
		var _title = "";
		switch (this.state.activeList) {
			case playerList.FRIEND_LIST:
				_title = this.props.lang.playersList.friendsTitle;
				break;
			case playerList.BAN_LIST:
				_title = this.props.lang.playersList.banTitle;
				break;
			case playerList.PLAYERS_LIST:
				_title = this.props.lang.playersList.searchTitle;
				break;
		}
		var _titleStyle = styles.titleStyle,
			_titleContainer = styles.titleContainer;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_titleStyle = [styles.titleStyle, { fontSize: 30 }];
			_titleContainer = [styles.titleContainer, { height: 40 }];
		}
		return (
			<View style={_titleContainer}>
				<Text allowFontScaling={false} style={_titleStyle}>
					{_title}
				</Text>
			</View>
		);
	}

	renderListBottom(listType) {
		var summary = "";
		switch (listType) {
			case playerList.FRIEND_LIST:
				summary = this.props.lang.playersList.listSummary.replace("#1#", this.state.friendList.length.toString());
				summary = summary.replace("#2#", this.props.maxFriendListSize);
				break;
			case playerList.BAN_LIST:
				summary = this.props.lang.playersList.listSummary.replace("#1#", this.state.banList.length);
				summary = summary.replace("#2#", this.props.maxBanListSize);
				break;
			default:
				return null;
		}
		var _summaryContainer = styles.summaryContainer,
			_summaryText = styles.summaryText;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_summaryContainer = [styles.summaryContainer, { height: 35 }];
			_summaryText = [styles.summaryText, { fontSize: 26 }];
		}
		return (
			<View style={_summaryContainer}>
				<Text allowFontScaling={false} style={_summaryText}>
					{summary}
				</Text>
				<AppTouchableOpacity onPress={this.onUpgradePress.bind(this)}>
					<Text allowFontScaling={false} style={[_summaryText, styles.upgradeText]}>
						{this.props.lang.playersList.btnUpgrade}
					</Text>
				</AppTouchableOpacity>
			</View>
		);
	}

	renderEmptyListLink() {
		var _summaryText = styles.summaryText;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_summaryText = [styles.summaryText, { fontSize: 26 }];
		}
		return (
			<AppTouchableOpacity
				style={styles.emptyListLinkContainer}
				onPress={() => this.changeTab(playerList.PLAYERS_LIST)}
			>
				<Text allowFontScaling={false} style={[_summaryText, styles.upgradeText]}>
					{this.props.lang.playersList.emptyFriendBanLink}
				</Text>
			</AppTouchableOpacity>
		);
	}

	renderListDetails() {
		const emptySearchMsg = this.props.lang.playersList.emptySearchResult.replace("#searchTerm#", this.state.searchTerm);
		switch (this.state.activeList) {
			case playerList.FRIEND_LIST:
				return (
					<View style={styles.detailsContainer}>
						{this.state.friendList.length > 0 && (
							<View style={styles.tableContainer}>
								<FriendBanListTable tableKey={playerList.FRIEND_LIST} friendList={this.state.friendList} />
							</View>
						)}
						{this.state.friendList.length == 0 && this.renderEmptyListLink()}
						{this.renderListBottom(playerList.FRIEND_LIST)}
					</View>
				);
			case playerList.BAN_LIST:
				return (
					<View style={styles.detailsContainer}>
						{this.state.banList.length > 0 && (
							<View style={styles.tableContainer}>
								<FriendBanListTable tableKey={playerList.BAN_LIST} banList={this.state.banList} />
							</View>
						)}
						{this.state.banList.length == 0 && this.renderEmptyListLink()}
						{this.renderListBottom(playerList.BAN_LIST)}
					</View>
				);
			case playerList.PLAYERS_LIST:
				var _inputLabel = styles.inputLabel,
					_inputStyle = [styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }],
					_emptySearchList = styles.emptySearchList,
					_inputBackImage = { width: 250, height: 50 };
				if (this.props.resolution === RESOLUTION.HIGH) {
					_inputLabel = [styles.inputLabel, { fontSize: 26, width: 300 }];
					_inputStyle = [
						styles.inputStyle,
						{ fontSize: 26, width: 300, height: 60 },
						Platform.OS === "android" && { paddingTop: 13 },
					];
					_inputBackImage = { width: 300, height: 60 };
					_emptySearchList = [styles.emptySearchList, { fontSize: 26 }];
				}
				return (
					<View style={styles.detailsContainer}>
						<View style={[styles.inputContainer, { transform: [{ scale: this.state.contentScale }] }]}>
							<Text allowFontScaling={false} style={_inputLabel}>
								{this.props.lang.playersList.searchInput.title}
							</Text>
							<ImageBackground source={TEXT_INPUT} resizeMode="stretch" style={_inputBackImage}>
								{Platform.OS !== "web" && (
									<TextInput
										allowFontScaling={false}
										disableFullscreenUI={true}
										onChangeText={(text) => this.onSearchTermChange(text)}
										defaultValue={this.state.searchTerm}
										placeholder={this.props.lang.playersList.searchInput.placeholder}
										placeholderTextColor="#B2B2B2"
										autoCapitalize="none"
										autoCompleteType="off"
										autoCorrect={false}
										spellCheck={false}
										textContentType="oneTimeCode"
										keyboardType={Platform.OS === "ios" ? "default" : "visible-password"}
										underlineColorAndroid="transparent"
										blurOnSubmit={true}
										returnKeyType="search"
										style={_inputStyle}
									/>
								)}
								{Platform.OS === "web" && (
									<TextInput
										allowFontScaling={false}
										disableFullscreenUI={true}
										onChangeText={(text) => this.onSearchTermChange(text)}
										defaultValue={this.state.searchTerm}
										placeholder={this.props.lang.playersList.searchInput.placeholder}
										placeholderTextColor="#B2B2B2"
										autoCapitalize="none"
										autoCorrect={false}
										spellCheck={false}
										textContentType="oneTimeCode"
										underlineColorAndroid="transparent"
										blurOnSubmit={true}
										returnKeyType="search"
										style={[_inputStyle, { outlineWidth: 0 }]}
									/>
								)}
							</ImageBackground>
						</View>
						<View
							style={[
								styles.tableContainer,
								styles.playerTableContainer,
								{
									opacity: this.state.playersList.length != 0 && this.state.searchTerm.length >= 3 ? 1 : 0,
								},
							]}
						>
							<PlayersListTable
								playersList={this.state.playersList}
								searchTerm={this.state.searchTerm}
								onFriendActionPress={this.onFriendAction.bind(this)}
								onBanActionPress={this.onBanAction.bind(this)}
								selectFilteredPlayer={this.selectFilteredPlayer.bind(this)}
								onTableLayout={(maxVisibleRows) => {
									this.setState({ maxVisibleRows: maxVisibleRows });
								}}
							/>
						</View>
						{this.state.playersList.length == 0 && this.state.searchTerm.length >= 3 && (
							<View style={{ flex: 1 }}>
								<Text allowFontScaling={false} style={[_emptySearchList]}>
									{this.state.isSearchInProgress ? this.props.lang.playersList.searchInProgress : emptySearchMsg}
								</Text>
							</View>
						)}
					</View>
				);
		}
	}

	renderLeftColumn() {
		var _leftColumnContainer = styles.leftColumnContainer;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_leftColumnContainer = [styles.leftColumnContainer, { width: 220 }];
		}
		return (
			<View style={_leftColumnContainer}>
				{this.renderButton(playerList.FRIEND_LIST)}
				{this.renderButton(playerList.BAN_LIST)}
				{this.renderButton(playerList.PLAYERS_LIST)}
			</View>
		);
	}

	renderRightColumn() {
		return (
			<View style={styles.rightColumnContainer}>
				{this.renderTitle()}
				{this.renderListDetails()}
			</View>
		);
	}

	renderPlayerInfo() {
		var selectedPlayer = this.state.playersList.find((pl) => pl.selected);
		if (selectedPlayer == undefined) selectedPlayer = this.state.friendList.find((pl) => pl.selected);
		if (selectedPlayer == undefined) selectedPlayer = this.state.banList.find((pl) => pl.selected);
		if (selectedPlayer == undefined) return null;
		return (
			<PlayerInfo
				lang={this.props.lang}
				player={selectedPlayer}
				chatAction={() => {}}
				messageAction={() => {}}
				friendAction={() => this.onFriendAction(selectedPlayer)}
				banAction={() => this.onBanAction(selectedPlayer)}
				userDetails={this.props.userDetails}
				backAction={this.onBackPress.bind(this)}
			/>
		);
	}

	renderContent() {
		return (
			<View style={styles.container}>
				<View
					style={[
						styles.contentContainer,
						// { transform: [{ scale: this.state.contentScale }] },
					]}
				>
					{!this.state.showPlayerInfo && this.renderLeftColumn()}
					{!this.state.showPlayerInfo && this.renderRightColumn()}
					{this.state.showPlayerInfo && this.renderPlayerInfo()}
				</View>
			</View>
		);
	}

	renderMain() {
		return (
			<ImageBackground
				source={this.props.isComposeOpened ? this.props.MENU_PLAY_BACK.url : this.props.SETTINGS_BACK.url}
				style={styles.backgroundContainer}
			>
				{!this.props.isComposeOpened && this.renderContent()}
				{this.props.isComposeOpened && (
					<SafeAreaView
						style={{
							flex: 1,
							marginHorizontal: this.props.resolution === RESOLUTION.HIGH ? 65 : 45,
						}}
					>
						<ComposeMessage />
					</SafeAreaView>
				)}
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.miscShopOpened) {
			return <MiscShop />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120;
			const availableHeight = windowDim.height; /* - 100 */
			if (playersListDimensions.width > availableWidth || playersListDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / playersListDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / playersListDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}

	getPlayersList(listType) {
		try {
			var list = [],
				mapList = {};
			switch (listType) {
				case playerList.FRIEND_LIST:
					mapList = this.props.friends;
					break;
				case playerList.BAN_LIST:
					mapList = this.props.bannedUsers;
					break;
				case playerList.PLAYERS_LIST:
					mapList = this.props.searchedUsers;
					break;
			}
			mapObject(mapList, (k, v) => {
				if (listType !== playerList.PLAYERS_LIST) {
					list.push({ userID: k, username: v });
				} else {
					list.push({
						userID: k,
						username: v,
						isFriend: this.props.friends[k] !== undefined,
						isBanned: this.props.bannedUsers[k] !== undefined,
					});
				}
			});
			list = orderBy(list, [(player) => player.username.toLowerCase()], ["asc"]);
			return list;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 30,
		justifyContent: "center",
		paddingTop: 5,
		zIndex: 0,
	},
	contentContainer: {
		flex: 1,
		flexDirection: "row",
		marginHorizontal: 20,
	},
	leftColumnContainer: {
		width: 170,
		paddingTop: 27,
		alignItems: "center",
	},
	categoryImage: { width: 150, height: 60 },
	categoryImageHR: { width: 200, height: 80 },
	categoryButton: {
		width: 140,
		height: 46,
		marginLeft: 3,
		justifyContent: "center",
	},
	categoryText: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		color: "#085968",
		textAlign: "center",
	},
	rightColumnContainer: {
		flex: 1,
	},
	titleContainer: {
		width: "100%",
		height: 30,
	},
	titleStyle: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansprobold,
		fontSize: 22,
		textAlign: "center",
		color: "#FFFFFF",
	},
	detailsContainer: { flex: 1, marginBottom: 3 },
	tableContainer: {
		flex: 1,
		paddingBottom: 1,
		marginBottom: 5,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
	},
	playerTableContainer: { marginBottom: 5 },
	inputContainer: {
		flexDirection: "row",
		marginBottom: 15,
		paddingHorizontal: 5,
		alignSelf: "center",
	},
	inputLabel: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		width: 250,
		textAlign: "left",
		paddingRight: 10,
		color: "#fff",
		lineHeight: 50,
	},
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 250,
		height: 50,
		marginRight: 2,
		borderRadius: 6,
		// paddingTop: Platform.OS === "web" ? 0 : 13,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	summaryContainer: {
		height: 25,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	summaryText: {
		fontFamily: f_sourcesansprobold,
		fontSize: 18,
		color: "#FFFDF1",
		alignSelf: "center",
	},
	upgradeText: {
		alignSelf: "center",
		color: "#00CED9",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 10,
	},
	emptySearchList: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		color: "#00CED9",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 10,
		alignSelf: "center",
	},
	emptyListLinkContainer: { flex: 1, marginTop: 20 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isShopOpen: state.tab.isShopOpen,
		miscShopOpened: state.gameShop.miscShopOpened,
		deleteAccountFinalOpened: state.app.deleteAccountFinalOpened,
		friends: state.currentUser.friends,
		maxFriendListSize: state.currentUser.maxFriendListSize,
		bannedUsers: state.currentUser.bannedUsers,
		maxBanListSize: state.currentUser.maxBanListSize,
		searchedUsers: state.currentUser.searchedUsers,
		userDetails: state.currentUser.userDetails,
		resolution: state.currentUser.preferences.resolution,
		isComposeOpened: state.gameMessage.isComposeOpened,
		MENU_PLAY_BACK: state.images.MENU_PLAY_BACK,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	clearSearchedUsersList,
	closeComposeMessage,
	closePlayersList,
	goBackToMain,
	openMiscShop,
	openTab,
	startPlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayersList);
