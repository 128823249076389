import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { appFont } from "../../config/defaults";
import InputEmoji from "../../libraries/InputEmoji/InputEmoji";
import { handleError } from "../ErrorHandler";

class GameChatInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			msgChat: "",
			focused: false,
		};
	}

	//#region lifecycle methods
	shouldComponentUpdate(nextProps, nextState) {
		if (
			(nextProps.activeRoom !== this.props.activeRoom || (nextState.msgChat != "" && this.state.msgChat == "")) &&
			this.refs.gameChatInput
		) {
			this.refs.gameChatInput.focus();
		}
		if (this.state.focused != nextState.focused || this.state.focused == nextState.focused) return false;
		return true;
	}
	//#endregion

	//#region events
	onSubmitEditing() {
		try {
			if (typeof this.props.onSendMessage === "function") {
				this.props.onSendMessage();
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		return (
			<View style={{ width: this.props.inputWidth }}>
				<InputEmoji
					ref="gameChatInput"
					value={this.state.msgChat}
					disabled={this.props.disableChat}
					maxLength={512}
					onChange={(text) => {
						if (!this.props.disableChat) this.setState({ msgChat: text });
					}}
					onFocus={(ev) => {
						if (!this.props.disableChat) this.setState({ focused: true });
					}}
					onBlur={() => {
						if (!this.props.disableChat) this.setState({ focused: false });
					}}
					onKeyDown={(ev) => {
						if (this.props.disableChat) {
							ev.preventDefault();
							ev.stopPropagation();
						}
					}}
					cleanOnEnter
					onEnter={this.onSubmitEditing.bind(this)}
					placeholder=""
					fontFamily={appFont}
					isInGame={true}
					containerClass="in-game-emoji-container"
					pickerClass="four_players"
				/>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	textInput: {
		fontFamily: appFont,
		fontSize: 12,
		color: "#a17d4d",
		lineHeight: 30,
		paddingLeft: 7,
		paddingRight: 5,
	},
});

export default GameChatInput;
