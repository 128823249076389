import React, { Component } from "react";
import { ImageBackground, Text } from "react-native";
import { SWITCH_ACTIVE, SWITCH_INACTIVE } from "../../config/images";
import { handleError } from "../ErrorHandler";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

class SwitchCheckbox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isChecked: props.isChecked || false,
		};
	}

	//#region events
	onPressed() {
		try {
			const { group, textContent, onPress, isMultiChecked, isChecked } = this.props;
			if (!isMultiChecked) {
				if (!isChecked) {
					if (typeof onPress === "function") onPress(group, textContent, !isChecked);
				} else {
					if (typeof onPress === "function") onPress(group, textContent, isChecked);
				}
			} else {
				if (typeof onPress === "function") onPress(group, textContent, !isChecked);
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		return (
			<AppTouchableOpacity
				activeOpacity={1}
				onPress={this.onPressed.bind(this)}
				style={[this.props.buttonStyle, { opacity: this.props.disabled ? 0.5 : 1 }]}
			>
				<ImageBackground
					source={this.props.isChecked ? SWITCH_ACTIVE : SWITCH_INACTIVE}
					resizeMode="stretch"
					style={this.props.labelBackgroundStyle}
				>
					<Text allowFontScaling={false} style={this.props.labelStyle}>
						{this.props.textContent}
					</Text>
				</ImageBackground>
			</AppTouchableOpacity>
		);
	}
	//#endregion
}

export { SwitchCheckbox };
