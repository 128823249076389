export const NEW_COORDINATES = require("../assets/json/coordinates.json");
export const inShopLateMoveTime = 3000; //milliseconds
export const hexToColor = Object.freeze({
	"#333333": "black",
	"#23AAE1": "blue",
	"#FF9743": "brown",
	"#7DC248": "green",
	"#F6AECD": "pink",
	"#612D8F": "purple",
	"#C4202C": "red",
	"#FCD407": "yellow",
});
export const invertedColor = Object.freeze({
	_333333: "9e9fa2",
	_23AAE1: "f06a34",
	_FF9743: "2794ec",
	_7DC248: "b665f5",
	_F6AECD: "3ab983",
	_612D8F: "8bb567",
	_C4202C: "3dcbcb",
	_FCD407: "5f7aff",
});
export const pips = Object.freeze({
	A: "A",
	ACE: "A",
	ONE: "A",
	K: "K",
	KING: "K",
	Q: "Q",
	QUEEN: "Q",
	J: "J",
	JACK: "J",
	T: "T",
	TEN: "T",
	9: "9",
	NINE: "9",
	8: "8",
	EIGHT: "8",
	7: "7",
	SEVENT: "7",
	6: "6",
	SIX: "6",
	5: "5",
	FIVE: "5",
	4: "4",
	FOUR: "4",
	3: "3",
	THREE: "3",
	2: "2",
	TWO: "2",
});

export const gameBoardTheme = Object.freeze({
	CLASSIC: "CLASSIC",
	MODERN: "MODERN",
});

export const cardIDs = ["2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K", "A"];
export const cardColors = ["s", "c", "d", "h"];

const _possibleCards = [];
cardColors.forEach((col) => {
	cardIDs.forEach((id) => {
		_possibleCards.push(id + col);
	});
});
export const possibleCards = _possibleCards;
