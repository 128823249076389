import React, { Component } from "react";
import { Dimensions, Image, ImageBackground, ScrollView, StyleSheet, Text, View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprosemibold,
	processState,
	RESOLUTION,
	tabType,
} from "../../../../../../config/defaults";
import { defaultCardsetDimensions, gameDimensions } from "../../../../../../config/dimensions";
import { TOGGLE_ICON } from "../../../../../../config/images";
import {
	closeCardSortOrder,
	goBackToMain,
	openCardsetsShop,
	openTab,
	openVIPShop,
	selectDefaultCardset,
	selectDefaultCardSortOrder,
	selectDefaultCardSuitOrder,
	selectDefaultCardValueOrder,
	startPlay,
	updateCardSortOrder,
} from "../../../../../../redux/actions";
import Analytics from "../../../../../Analytics/Analytics";
import { AppTouchableOpacity, ColoredButton, NavigationHeader, Spinner } from "../../../../../common";
import { handleError } from "../../../../../ErrorHandler";
import VIPShop from "../../../../../GameShop/VIPShop";

class CardSortOrder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("CardsSortOrderPreferences");
	}
	//#endregion

	//#region events
	onUpdatePress() {
		try {
			const selectedSort = this.props.sortOrders.find((e) => e.selected);
			const selectedSuit = this.props.suitRankings.find((e) => e.selected);
			const selectedValue = this.props.valueRankings.find((e) => e.selected);
			this.props.updateCardSortOrder(selectedSort, selectedSuit, selectedValue);
		} catch (error) {
			handleError(error);
		}
	}

	onLinkPress() {
		try {
			this.props.goBackToMain();
			this.props.startPlay();
			this.props.openTab(tabType.SHOP);
			this.props.openVIPShop();
		} catch (error) {
			handleError(error);
		}
	}

	toggleList(prev = true, toggleType, source = []) {
		try {
			if (source.length > 0) {
				const curSelInd = source.findIndex((e) => e.selected);
				if (curSelInd >= 0) {
					var nextSelInd = curSelInd;
					if (prev) {
						nextSelInd = curSelInd - 1;
						nextSelInd = nextSelInd < 0 ? source.length - 1 : nextSelInd;
					} else {
						nextSelInd = curSelInd + 1;
						nextSelInd = nextSelInd == source.length ? 0 : nextSelInd;
					}
					switch (toggleType) {
						case "sort":
							this.props.selectDefaultCardSortOrder(nextSelInd);
							break;
						case "suit":
							this.props.selectDefaultCardSuitOrder(nextSelInd);
							break;
						case "value":
							this.props.selectDefaultCardValueOrder(nextSelInd);
							break;
					}
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateCardSortOrderState != processState.STARTED}
				backAction={this.props.closeCardSortOrder}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		try {
			var buttonWidth = gameDimensions.gameButtonsWidth,
				buttonHeight = 60,
				_buttonTextStyle = {};
			if (this.props.resolution === RESOLUTION.HIGH) {
				buttonWidth *= 1.5;
				buttonHeight *= 1.5;
				_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
			}
			return (
				<View style={styles.buttonContainer}>
					<ColoredButton
						width={buttonWidth}
						height={buttonHeight}
						onPressFunc={this.onUpdatePress.bind(this)}
						textContent={this.props.lang.update}
						accessibilityLabel={this.props.lang.update}
						color={buttonColor.GREEN}
						disabled={false}
						additionalTextStyle={_buttonTextStyle}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderInfo() {
		try {
			const regex = /_/gi;
			var main_text = this.props.lang.cardSortOrder.info;
			var main_text_i = main_text.indexOf("_");
			var main_text_j = main_text.lastIndexOf("_");
			if (main_text_i != -1 && main_text_j != -1) var linkMainText = main_text.substring(main_text_i, ++main_text_j);
			if (linkMainText != undefined) var mainTextLink = linkMainText.replace(regex, "");

			var _infoStyle = [styles.dataText, styles.infoText];
			if (this.props.resolution === RESOLUTION.HIGH) {
				_infoStyle = [styles.dataText, styles.infoText, { fontSize: 24, lineHeight: 30 }];
			}
			return (
				<View style={{ width: "100%" }}>
					{mainTextLink != "" && (
						<ParsedText
							style={_infoStyle}
							parse={[
								{
									pattern: new RegExp("_" + mainTextLink + "_"),
									style: [_infoStyle, styles.linkDecor],
									onPress: this.onLinkPress.bind(this),
									renderText: () => mainTextLink,
								},
							]}
						>
							{main_text}
						</ParsedText>
					)}
					{mainTextLink == "" && (
						<Text allowFontScaling={false} style={_infoStyle}>
							{main_text}
						</Text>
					)}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderToggleRow(toggleType) {
		var titleText = "",
			selected,
			selectedText = "",
			source = [];
		switch (toggleType) {
			case "sort":
				selected = this.props.sortOrders.find((el) => el.selected == true);
				titleText = this.props.lang.cardSortOrder.sortOrderTitle;
				source = this.props.sortOrders;
				break;
			case "suit":
				selected = this.props.suitRankings.find((el) => el.selected == true);
				titleText = this.props.lang.cardSortOrder.suitRankingTitle;
				source = this.props.suitRankings;
				break;
			case "value":
				selected = this.props.valueRankings.find((el) => el.selected == true);
				titleText = this.props.lang.cardSortOrder.valueRankingTitle;
				source = this.props.valueRankings;
				break;
		}
		selectedText = selected !== undefined ? selected.text : "";
		var _rowContainer = styles.rowContainer,
			_rowInnerContainer = styles.rowInnerContainer,
			_labelStyle = [styles.dataTitle, styles.dataText],
			_toggleButton = styles.toggleButton,
			_toggleIcon = styles.toggleIcon,
			_selectedText = styles.selectedText;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_rowContainer = [styles.rowContainer, { height: 60 }];
			_rowInnerContainer = [styles.rowInnerContainer, { width: 500 }];
			_toggleButton = [styles.toggleButton, { width: 60, height: 60, paddingTop: 2 }];
			_toggleIcon = [styles.toggleIcon, { width: 45, height: 45 }];
			_selectedText = [styles.selectedText, { fontSize: 26, lineHeight: 60 }];
			_rowInnerContainer = [styles.rowInnerContainer, { width: 500 }];
			_labelStyle = [styles.dataTitle, styles.dataText, { fontSize: 26, width: 225, lineHeight: 60, paddingTop: 0 }];
		}
		return (
			<View style={_rowContainer}>
				<Text allowFontScaling={false} numberOfLines={1} selectable={false} style={_labelStyle}>
					{titleText}
				</Text>
				<View style={_rowInnerContainer}>
					<AppTouchableOpacity style={_toggleButton} onPress={() => this.toggleList(true, toggleType, source)}>
						<Image source={TOGGLE_ICON} style={_toggleIcon} resizeMode="stretch" />
					</AppTouchableOpacity>
					<Text allowFontScaling={false} numberOfLines={1} style={_selectedText}>
						{selectedText}
					</Text>
					<AppTouchableOpacity style={_toggleButton} onPress={() => this.toggleList(false, toggleType, source)}>
						<Image
							source={TOGGLE_ICON}
							style={[_toggleIcon, { transform: [{ rotateY: "-180deg" }] }]}
							resizeMode="stretch"
						/>
					</AppTouchableOpacity>
				</View>
			</View>
		);
	}

	renderContent() {
		return (
			<ScrollView
				contentContainer={styles.contentContainer}
				style={[
					styles.innerContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				{this.renderToggleRow("sort")}
				{this.renderToggleRow("suit")}
				{this.renderToggleRow("value")}
				{this.renderInfo()}
			</ScrollView>
		);
	}

	render() {
		if (this.props.updateCardSortOrderState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);

		if (this.props.vipShopOpened) {
			return <VIPShop />;
		} else {
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<View style={styles.container}>
						{this.renderContent()}
						{this.renderUpdateButton()}
					</View>
					{this.renderHeader()}
				</ImageBackground>
			);
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - Math.round(50 - 50 / windowDim.scale);
			if (defaultCardsetDimensions.width > availableWidth || defaultCardsetDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / defaultCardsetDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / defaultCardsetDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1 },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	innerContainer: {
		flex: 1,
		marginHorizontal: 20,
		marginBottom: 70,
	},
	contentContainer: {
		justifyContent: "center",
	},
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataText: { fontFamily: f_sourcesansprosemibold, fontSize: 20 },
	dataTitle: {
		width: 150,
		textAlign: "left",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		paddingTop: 2,
		paddingRight: 10,
		lineHeight: 40,
	},
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: 26,
		marginTop: 20,
		// marginBottom: 20,
		fontSize: 18,
	},
	linkDecor: { color: "#00CDD8" },
	rowContainer: {
		height: 40,
		paddingLeft: 40,
		flexDirection: "row",
		justifyContent: "center",
	},
	rowInnerContainer: { width: 350, flexDirection: "row" },
	toggleButton: { width: 40, height: 40, justifyContent: "center" },
	toggleIcon: { width: 30, height: 30, alignSelf: "center" },
	selectedText: {
		flex: 1,
		lineHeight: 40,
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		textAlign: "center",
		alignSelf: "center",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		vipShopOpened: state.gameShop.vipShopOpened,
		updateCardSortOrderState: state.app.updateCardSortOrderState,
		sortOrders: state.currentUser.preferences.sortOrders,
		suitRankings: state.currentUser.preferences.suitRankings,
		valueRankings: state.currentUser.preferences.valueRankings,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeCardSortOrder,
	goBackToMain,
	openCardsetsShop,
	openTab,
	openVIPShop,
	selectDefaultCardset,
	selectDefaultCardSortOrder,
	selectDefaultCardSuitOrder,
	selectDefaultCardValueOrder,
	startPlay,
	updateCardSortOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardSortOrder);
