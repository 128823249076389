import * as helpers from "../../../helpers/commonHelpers";
import { RESET_CLIENT } from "../../../redux/actions/actionTypes";
import { CHECK_FETCH_IN_PROGRESS, GET_BOARD_COORDINATES, GET_BOARD_COORDINATES_SUCCESS } from "../actions/actionTypes";

const initialState = {
	boardCoordinates: {
		data: [],
		isFetching: false,
		error: false,
	},
	isFetchInProgress: true,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_BOARD_COORDINATES:
			return {
				...state,
				boardCoordinates: {
					...state.boardCoordinates,
					data: initialState.boardCoordinates.data,
					isFetching: true,
					error: false,
				},
				isFetchInProgress: true,
			};
		case GET_BOARD_COORDINATES_SUCCESS:
			return {
				...state,
				boardCoordinates: {
					...state.boardCoordinates,
					data: payload,
					isFetching: false,
					error: false,
				},
			};
		case CHECK_FETCH_IN_PROGRESS:
			var anyInProgress = false;
			helpers.mapObject(state, (key, value) => {
				if (typeof value.isFetching === "boolean" && value.isFetching) anyInProgress = true;
			});
			return { ...state, isFetchInProgress: anyInProgress };
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
