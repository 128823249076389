import React, { Component } from "react";
import { Dimensions, ImageBackground, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, f_sourcesansprolight } from "../../../../config/defaults";
import { deleteAccountDimensions, gameDimensions } from "../../../../config/dimensions";
import { MyWebSocket } from "../../../../connection";
import { closeDeleteAccountFinal, closeDeleteAccountReason, goBackToMain } from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader } from "../../../common";
import { handleError } from "../../../ErrorHandler";

class DeleteAccountFinal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			anythingElse: "",
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("DeleteAccountFinal");
	}
	//#endregion

	//#region events
	onBackPress() {
		this.props.closeDeleteAccountFinal();
		this.props.closeDeleteAccountReason();
	}

	onDeleteAccountPress() {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "deleteAccount",
		});
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderDeleteButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.onDeleteAccountPress.bind(this)}
					textContent={this.props.lang.deleteAccount.final.button}
					accessibilityLabel={this.props.lang.deleteAccount.final.button}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderContent() {
		return (
			<View style={styles.container}>
				<View style={[styles.contentContainer, { transform: [{ scale: this.state.contentScale }] }]}>
					<View style={styles.titleContainer}>
						<Text allowFontScaling={false} style={styles.titleStyle}>
							{this.props.lang.deleteAccount.final.title}
						</Text>
					</View>
					<TextInput
						allowFontScaling={false}
						disableFullscreenUI={true}
						onChangeText={(text) => this.setState({ anythingElse: text })}
						defaultValue={this.state.anythingElse}
						placeholder={this.props.lang.deleteAccount.final.placeholder}
						placeholderTextColor="#B2B2B2"
						multiline={true}
						numberOfLines={4}
						maxLength={255}
						underlineColorAndroid="transparent"
						style={styles.inputStyle}
						blurOnSubmit={true}
						returnKeyType="done"
					/>
					{this.renderDeleteButton()}
				</View>
			</View>
		);
	}

	render() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				{this.renderContent()}
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120;
			const availableHeight = windowDim.height - 60;
			if (deleteAccountDimensions.width > availableWidth || deleteAccountDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / deleteAccountDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / deleteAccountDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: { flex: 1, padding: 40, paddingBottom: 0 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	contentContainer: { flex: 1, paddingHorizontal: 40 },
	titleContainer: {
		width: "85%",
		height: 50,
		alignSelf: "center",
	},
	titleStyle: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansprobold,
		fontSize: 24,
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
		textAlign: "center",
	},
	inputStyle: {
		height: 180,
		lineHeight: 30,
		marginRight: 2,
		borderWidth: 1,
		borderColor: "#555",
		borderRadius: 5,
		padding: 5,
		textAlignVertical: "top",
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		marginBottom: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeDeleteAccountFinal,
	closeDeleteAccountReason,
	goBackToMain,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountFinal);
