//#region App actions
export const UPGRADE_REQUIRED = "upgrade_required";
export const UPDATE_APP_ORIENTATION = "update_app_orientation";
export const ADMOB_INITED = "admob_inited";
export const OPEN_PLAYER_OVERVIEW = "open_player_overview";
export const CLOSE_PLAYER_OVERVIEW = "close_player_overview";
export const FINISH_APP_LOAD = "finish_app_load";
export const SET_INTERNET_UNREACHABLE = "set_internet_unreachable";
export const SET_INTERNET_REACHABLE = "set_internet_reachable";
export const NETWORK_RECOVERED = "network_recovered";
export const HANDLE_WELCOME = "handle_welcome";
export const SET_LOAD_TIME = "set_load_time";
export const SET_LOGGED_IN_STATE = "set_logged_in_state";
export const START_PLAY = "start_play";
export const START_CHAT = "start_chat";
export const START_SHOP = "start_shop";
export const START_LOG_IN = "start_log_in";
export const LOGOUT = "logout";
export const GO_BACK_TO_MAIN = "go_back_to_main";
export const OPEN_UPGRADE_ACCOUNT = "open_upgrade_account";
export const START_UPGRADE_ACCOUNT = "start_upgrade_account";
export const UPGRADE_ACCOUNT_FAILED = "upgrade_account_failed";
export const UPGRADE_ACCOUNT_SUCCEEDED = "upgrade_account_succeeded";
export const OPEN_EMAIL_LOGIN = "open_email_login";
export const START_EMAIL_LOGIN = "start_email_login";
export const EMAIL_LOGIN_SUCCEEDED = "email_login_succeeded";
export const EMAIL_LOGIN_FAILED = "email_login_failed";
export const EMAIL_LOGIN_CANCELED = "email_login_canceled";
export const START_EMAIL_SIGNUP = "start_email_signup";
export const CONTINUE_EMAIL_SIGNUP = "continue_email_signup";
export const EMAIL_SIGNUP_SUCCEEDED = "email_signup_succeeded";
export const EMAIL_SIGNUP_FAILED = "email_signup_failed";
export const EMAIL_SIGNUP_CANCELED = "email_signup_canceled";
export const START_FACEBOOK_LOGIN = "start_facebook_login";
export const FACEBOOK_LOGIN_SUCCEEDED = "facebook_login_succeeded";
export const FACEBOOK_LOGIN_FAILED = "facebook_login_failed";
export const FACEBOOK_LOGIN_CANCELED = "facebook_login_canceled";
export const FINISH_CHOOSE_USERNAME = "finish_choose_username";
export const START_CHOOSING_USERNAME = "start_choosing_username";
export const USERNAME_CHOOSING_SUCCEEDED = "username_choosing_succeeded";
export const USERNAME_CHOOSING_FAILED = "username_choosing_failed";
export const USERNAME_CHOOSING_CANCELED = "username_choosing_failed";
export const OPEN_MY_PROFILE = "open_my_profile";
export const CLOSE_MY_PROFILE = "close_my_profile";
export const START_PROFILE_UPDATE = "start_profile_update";
export const FINISH_PROFILE_UPDATE = "finish_profile_update";
export const SET_APP_VISIBILITY_STATE = "set_app_visibility_state";
export const ACCEPT_TERMS_AND_CONDITIONS = "accept_terms_and_conditions";
export const OPEN_LOG_IN_TROUBLE = "open_login_trouble";
export const CLOSE_LOG_IN_TROUBLE = "close_login_trouble";
export const OPEN_SEND_EMAIL_TROUBLE = "open_send_email_trouble";
export const CLOSE_SEND_EMAIL_TROUBLE = "close_send_email_trouble";
export const SEND_MY_USERNAME = "send_my_username";
export const SEND_MY_USERNAME_SUCCEEDED = "send_my_username_succeeded";
export const SEND_MY_USERNAME_FAILED = "send_my_username_failed";
export const CLEAR_SEND_USERNAME_ERROR = "clear_send_username_error";
export const OPEN_RESET_PASSWORD = "open_reset_password";
export const CLOSE_RESET_PASSWORD = "close_reset_password";
export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCEEDED = "reset_password_succeeded";
export const RESET_PASSWORD_FAILED = "reset_password_failed";
export const CLEAR_RESET_PASSWORD_ERROR = "clear_reset_password_error";
export const CHANGE_PASSWORD = "change_password";
export const CHANGE_PASSWORD_FAILED = "change_password_failed";
export const OPEN_SETTINGS = "open_settings";
export const CLOSE_SETTINGS = "close_settings";
export const OPEN_UPDATE_USERNAME = "open_update_username";
export const CLOSE_UPDATE_USERNAME = "close_update_username";
export const UPDATE_USERNAME = "update_username";
export const UPDATE_USERNAME_SUCCEEDED = "update_username_succeeded";
export const UPDATE_USERNAME_FAILED = "update_username_failed";
export const CLEAR_UPDATE_USERNAME_ERROR = "clear_update_username_error";
export const OPEN_UPDATE_LOCATION = "open_update_location";
export const CLOSE_UPDATE_LOCATION = "close_update_location";
export const UPDATE_LOCATION = "update_location";
export const UPDATE_LOCATION_SUCCEEDED = "update_location_succeeded";
export const UPDATE_LOCATION_FAILED = "update_location_failed";
export const OPEN_UPDATE_LANGUAGE = "open_update_language";
export const CLOSE_UPDATE_LANGUAGE = "close_update_language";
export const UPDATE_LANGUAGE = "update_language";
export const UPDATE_LANGUAGE_SUCCEEDED = "update_language_succeeded";
export const UPDATE_LANGUAGE_FAILED = "update_language_failed";
export const CLEAR_UPDATE_LANGUAGE_ERROR = "clear_update_language_error";
export const OPEN_UPDATE_PERSONAL_DATA = "open_update_personal_data";
export const CLOSE_UPDATE_PERSONAL_DATA = "close_update_personal_data";
export const UPDATE_PERSONAL_DATA = "update_personal_data";
export const UPDATE_PERSONAL_DATA_SUCCEEDED = "update_personal_data_succeeded";
export const UPDATE_PERSONAL_DATA_FAILED = "update_personal_data_failed";
export const OPEN_UPDATE_AVATAR = "open_update_avatar";
export const CLOSE_UPDATE_AVATAR = "close_update_avatar";
export const UPDATE_AVATAR = "update_avatar";
export const UPDATE_AVATAR_SUCCEEDED = "update_avatar_succeeded";
export const UPDATE_AVATAR_FAILED = "update_avatar_failed";
export const DELETE_AVATAR = "delete_avatar";
export const DELETE_AVATAR_SUCCEEDED = "delete_avatar_succeeded";
export const DELETE_AVATAR_FAILED = "delete_avatar_failed";
export const CLEAR_UPDATE_AVATAR_ERROR = "clear_update_avatar_error";
export const OPEN_PREFERENCES = "open_preferences";
export const CLOSE_PREFERENCES = "close_preferences";
export const OPEN_SOUNDS_PREFERENCES = "open_sounds_preferences";
export const CLOSE_SOUNDS_PREFERENCES = "close_sounds_preferences";
export const UPDATE_SOUNDS_PREFERENCES = "update_sounds_preferences";
export const UPDATE_SOUNDS_PREFERENCES_SUCCEEDED = "update_sounds_preferences_succeeded";
export const UPDATE_SOUNDS_PREFERENCES_FAILED = "update_sounds_preferences_failed";
export const OPEN_GRAPHICAL_PREFERENCES = "open_graphical_preferences";
export const CLOSE_GRAPHICAL_PREFERENCES = "close_graphical_preferences";
export const OPEN_CARDS_PREFERENCES = "open_cards_preferences";
export const CLOSE_CARDS_PREFERENCES = "close_cards_preferences";
export const OPEN_BOARD_PREFERENCES = "open_board_preferences";
export const CLOSE_BOARD_PREFERENCES = "close_board_preferences";
export const OPEN_LANGUAGE_PREFERENCES = "open_language_preferences";
export const CLOSE_LANGUAGE_PREFERENCES = "close_language_preferences";
export const UPDATE_RESOLUTION = "update_resolution";
export const UPDATE_RESOLUTION_SUCCEEDED = "update_resolution_succeeded";
export const UPDATE_RESOLUTION_FAILED = "update_resolution_failed";
export const OPEN_NOTIFICATIONS_PREFERENCES = "open_notifications_preferences";
export const CLOSE_NOTIFICATIONS_PREFERENCES = "close_notifications_preferences";
export const OPEN_SCREEN_PREFERENCES = "open_screen_preferences";
export const CLOSE_SCREEN_PREFERENCES = "close_screen_preferences";
export const OPEN_SHOW_RUNNING_GAMES_IN_LIST = "open_show_running_games_in_list";
export const CLOSE_SHOW_RUNNING_GAMES_IN_LIST = "close_show_running_games_in_list";
export const UPDATE_SHOW_RUNNING_GAMES_IN_LIST = "update_show_running_games_in_list";
export const UPDATE_SHOW_RUNNING_GAMES_IN_LIST_SUCCEEDED = "update_show_running_games_in_list_succeeded";
export const UPDATE_SHOW_RUNNING_GAMES_IN_LIST_FAILED = "update_show_running_games_in_list_failed";
export const OPEN_OVERRIDE_CARDSET = "open_override_cardset";
export const CLOSE_OVERRIDE_CARDSET = "close_override_cardset";
export const UPDATE_OVERRIDE_CARDSET = "update_override_cardset";
export const UPDATE_OVERRIDE_CARDSET_SUCCEEDED = "update_override_cardset_succeeded";
export const UPDATE_OVERRIDE_CARDSET_FAILED = "update_override_cardset_failed";
export const OPEN_DEFAULT_CARDSET = "open_default_cardset";
export const CLOSE_DEFAULT_CARDSET = "close_default_cardset";
export const UPDATE_DEFAULT_CARDSET = "update_default_cardset";
export const UPDATE_DEFAULT_CARDSET_SUCCEEDED = "update_default_cardset_succeeded";
export const UPDATE_DEFAULT_CARDSET_FAILED = "update_default_cardset_failed";
export const OPEN_CARD_SORT_ORDER = "open_card_sort_order";
export const CLOSE_CARD_SORT_ORDER = "close_card_sort_order";
export const UPDATE_CARD_SORT_ORDER = "update_card_sort";
export const UPDATE_CARD_SORT_ORDER_SUCCEEDED = "update_card_sort_succeeded";
export const UPDATE_CARD_SORT_ORDER_FAILED = "update_card_sort_failed";
export const OPEN_FIX_BOARD_POSITIONS = "open_fix_board_positions";
export const CLOSE_FIX_BOARD_POSITIONS = "close_fix_board_positions";
export const UPDATE_FIXED_POSITIONS = "update_fixed_positions";
export const UPDATE_FIXED_POSITIONS_SUCCEEDED = "update_fixed_positions_succeeded";
export const UPDATE_FIXED_POSITIONS_FAILED = "update_fixed_positions_failed";
export const OPEN_FIX_BOARD_COLORS = "open_fix_board_colors";
export const CLOSE_FIX_BOARD_COLORS = "close_fix_board_colors";
export const OPEN_EMAIL_NOTIFICATIONS_SETTINGS = "open_email_notifications_settings";
export const CLOSE_EMAIL_NOTIFICATIONS_SETTINGS = "close_email_notifications_settings";
export const UPDATE_EMAIL_NOTIFICATIONS = "update_email_notifications";
export const UPDATE_EMAIL_NOTIFICATIONS_SUCCEEDED = "update_email_notifications_succeeded";
export const UPDATE_EMAIL_NOTIFICATIONS_FAILED = "update_email_notifications_failed";
export const OPEN_APP_NOTIFICATIONS_SETTINGS = "open_app_notifications_settings";
export const CLOSE_APP_NOTIFICATIONS_SETTINGS = "close_app_notifications_settings";
export const UPDATE_APP_NOTIFICATIONS = "update_app_notifications";
export const UPDATE_APP_NOTIFICATIONS_SUCCEEDED = "update_app_notifications_succeeded";
export const UPDATE_APP_NOTIFICATIONS_FAILED = "update_app_notifications_failed";
export const OPEN_APP_LANGUAGE_SETTINGS = "open_app_language_settings";
export const CLOSE_APP_LANGUAGE_SETTINGS = "close_app_language_settings";
export const UPDATE_APP_LANGUAGE = "update_app_language";
export const UPDATE_APP_LANGUAGE_SUCCEEDED = "update_app_language_succeeded";
export const UPDATE_APP_LANGUAGE_FAILED = "update_app_language_failed";
export const OPEN_CHAT_TRANSLATION_SETTINGS = "open_chat_translation_settings";
export const CLOSE_CHAT_TRANSLATION_SETTINGS = "close_chat_translation_settings";
export const UPDATE_CHAT_TRANSLATION = "update_chat_translation";
export const UPDATE_CHAT_TRANSLATION_SUCCEEDED = "update_chat_translation_succeeded";
export const UPDATE_CHAT_TRANSLATION_FAILED = "update_chat_translation_failed";
export const OPEN_DELETE_ACCOUNT = "open_delete_account";
export const CLOSE_DELETE_ACCOUNT = "close_delete_account";
export const OPEN_DELETE_ACCOUNT_REASON = "open_delete_account_reason";
export const CLOSE_DELETE_ACCOUNT_REASON = "close_delete_account_reason";
export const OPEN_DELETE_ACCOUNT_FINAL = "open_delete_account_final";
export const CLOSE_DELETE_ACCOUNT_FINAL = "close_delete_account_final";
export const OPEN_PLAYERS_LIST = "open_players_list";
export const CLOSE_PLAYERS_LIST = "close_players_list";
export const OPEN_SEARCH_PLAYERS = "open_search_players";
export const CLOSE_SEARCH_PLAYERS = "close_search_players";
export const OPEN_HELP = "open_help";
export const CLOSE_HELP = "close_help";
export const OPEN_TUTORIALS = "open_tutorials";
export const CLOSE_TUTORIALS = "close_tutorials";
export const OPEN_SILVER_HELP = "open_silver_help";
export const CLOSE_SILVER_HELP = "close_silver_help";
export const OPEN_GOLD_HELP = "open_gold_help";
export const CLOSE_GOLD_HELP = "close_gold_help";
export const OPEN_SHOP_HELP = "open_shop_help";
export const CLOSE_SHOP_HELP = "close_shop_help";
export const OPEN_VIP_HELP = "open_vip_help";
export const CLOSE_VIP_HELP = "close_vip_help";
export const OPEN_VIP_BENEFITS_HELP = "open_vip_benefits_help";
export const CLOSE_VIP_BENEFITS_HELP = "close_vip_benefits_help";
export const OPEN_GAME_ITEMS_MENU = "open_game_items_menu";
export const CLOSE_GAME_ITEMS_MENU = "close_game_items_menu";
export const OPEN_EARN_FREE_SILVER = "open_earn_free_silver";
export const CLOSE_EARN_FREE_SILVER = "close_earn_free_silver";
export const OPEN_LEADER_BOARDS_MENU = "open_leader_boards_menu";
export const CLOSE_LEADER_BOARDS_MENU = "close_leader_boards_menu";
export const OPEN_LEADER_BOARDS = "open_leader_boards";
export const CLOSE_LEADER_BOARDS = "close_leader_boards";
export const OPEN_INVITE_FRIENDS_MENU = "open_invite_friends_menu";
export const CLOSE_INVITE_FRIENDS_MENU = "close_invite_friends_menu";
export const OPEN_EMAIL_FRIEND = "open_email_friend";
export const CLOSE_EMAIL_FRIEND = "close_email_friend";
export const SHOW_EMAIL_FRIEND_PREVIEW = "show_email_friend_preview";
export const CLOSE_EMAIL_FRIEND_PREVIEW = "close_email_friend_preview";
export const OPEN_RATE_FOR_SILVER = "open_rate_for_silver";
export const CLOSE_RATE_FOR_SILVER = "close_rate_for_silver";
export const OPEN_RATE_DONE = "open_rate_done";
export const CLOSE_RATE_DONE = "close_rate_done";
export const RESET_CLIENT = "reset_client";
export const OPEN_GAME_MESSAGE = "open_game_message";
export const CLOSE_GAME_MESSAGE = "close_game_message";
//#endregion

//#region Language action types
export const CHANGE_LANGUAGE_DATA = "change_language_data";
//#endregion

//#region WebSocket's action types
export const SOCKET_CONNECTION_CLOSED = "socket_connection_closed";
export const SOCKET_CONNECTION_ESTABLISHED = "socket_connection_established";
export const CONNECT_TO_WEBSOCKET = "connect_to_websocket";
export const CONNECTION_SUCCESSFULL = "connection_successfull";
export const CONNECTION_FAILED = "connection_failed";
export const ADD_URL_TO_SOCKET_LIST = "add_url_to_socket_list";
export const REMOVE_URL_FROM_SOCKET_LIST = "remove_url_from_socket_list";
export const INCREASE_CONNECTION_TRIES = "increase_connection_tries";
export const RESET_CONNECTION_TRIES = "reset_connection_tries";
export const INCREASE_CLIENT_MESSAGES_COUNTER = "increase_cMessageID";
export const RESET_CLIENT_MESSAGES_COUNTER = "reset_cMessageID";
export const AUTHENTICATE_START = "authenticate_start";
export const AUTHENTICATE_SUCCEEDED = "authenticate_succeeded";
export const MESSAGE_SENT = "message_sent";
export const PROCESS_ACK_EVENT = "process_ack_event";
export const REMOVE_SAVED_EVENT = "remove_saved_event";
export const ERROR_RECEIVED_FROM_SOCKET = "error_received_from_socket";
export const CONNECTION_ERROR_DETECTED = "connection_error_detected";
export const CONNECT_TO_NEXT_URL = "connect_to_next_url";
export const CLEAR_WEBSOCKET = "clear_websocket";
export const RECONNECT_START = "reconnect_start";
export const RECONNECT_FINISHED = "reconnect_finished";
export const REQUEST_NEXT_EVENT_EXECUTION = "request_next_event_execution";
//#endregion

//#region User actions
export const UNLOCK_CREATE_GAME_FEATURES = "unloack_create_game_features";
export const UPDATE_USER_OVERVIEW = "update_user_overview";
export const SET_USER_AS_VIP = "set_user_as_vip";
export const SAVE_USER_DETAILS = "save_user_details";
export const PROCESS_PLAYER_MOD = "process_player_mod";
export const UPDATE_STACK = "update_stack";
export const SET_FEATURES = "set_features";
export const SAVE_EMAIL_USERNAME = "save_email_username";
export const SAVE_EMAIL_PASSWORD = "save_email_password";
export const SAVE_FB_UID = "save_fb_uid";
export const SAVE_FB_TOKEN = "save_fb_token";
export const SAVE_FACEBOOK_USER_DETAILS = "save_facebook_user_details";
export const REMOVE_EMAIL_CREDENTIALS = "remove_email_credentials";
export const REMOVE_FACEBOOK_CREDENTIALS = "remove_facebook_credentials";
export const HANDLE_PROFILE_SETTINGS = "handle_profile_settings";
export const PROFILE_SETTINGS_UPDATED = "profile_settings_updated";
export const HANDLE_SETTINGS = "handle_settings";
export const DEFAULT_CARDSET_UPDATED = "default_cardset_updated";
export const SELECT_DEFAULT_CARDSET = "select_default_cardset";
export const SELECT_DEFAULT_CARD_SUIT_ORDER = "select_default_card_suit_order";
export const SELECT_DEFAULT_CARD_VALUE_ORDER = "select_default_card_value_order";
export const SELECT_DEFAULT_CARD_SORT_ORDER = "select_default_card_sort_order";
export const SHOW_RUNNING_GAMES_IN_LIST_UPDATED = "show_running_games_in_list_updated";
export const OVERRIDE_CARDSET_UPDATED = "override_cardset_updated";
export const FIXED_POSITIONS_UPDATED = "fixed_positions_updated";
export const APP_LANGUAGE_UPDATED = "app_language_updated";
export const CHAT_TRANSLATION_UPDATED = "chat_translation_updated";
export const EMAIL_NOTIFICATIONS_UPDATED = "email_notifications_updated";
export const APP_NOTIFICATIONS_UPDATED = "app_notifications_updated";
export const CARDS_ORDER_UPDATED = "cards_order_updated";
export const SCREEN_RESOLUTION_UPDATED = "screen_resolution_updated";
export const UPDATE_FRIEND_LIST = "update_friend_list";
export const UPDATE_BAN_LIST = "update_ban_list";
export const UPDATE_SEARCHED_USERS_LIST = "update_searched_users_list";
export const CLEAR_SEARCHED_USERS_LIST = "clear_searched_users_list";
export const SOUNDS_PREFERENCES_UPDATED = "sounds_preferences_updated";
//#endregion

//#region NavigationMenu's action types
export const TOGGLE_NAV_MENU = "toggle_nav_menu";
//#endregion

//#region Navigation between tabs action types
export const OPEN_SHOP_TAB = "open_shop_tab";
export const CLOSE_SHOP_TAB = "close_shop_tab";
export const OPEN_CHAT_TAB = "open_chat_tab";
export const OPEN_GAMES_TAB = "open_games_tab";
export const OPEN_GAME_LOBBY = "open_game_lobby";
export const OPEN_CREATE_NEW_GAME = "open_create_new_game";
export const OPEN_GAME_ROOM = "open_game_room";
export const OPEN_STARTED_GAME = "open_started_game";
export const OPEN_GAME_END = "open_game_end";
export const SHOW_BANNER = "showBanner";
export const HIDE_BANNER = "hide_banner";
//#endregion

//#region Resize actions
export const REQUEST_GAME_DETAILS_TABLE_HEIGHT = "request_game_details_table_height";
export const DETECT_SMALL_RESOLUTION = "detect_small_resolution";
export const DETECT_ORIENTATION = "detect_orientation";
export const RESIZE_PAGE_TOP = "resize_page_top";
export const RESIZE_GLOBAL_CHAT = "resize_global_chat";
export const RESIZE_GAME_LOBBY = "resize_game_lobby";
export const RESIZE_GAME_SHOP = "resize_game_shop";
export const RESIZE_MAIN_CONTENT = "resize_main_content";
export const RESIZE_GAME_LIST_TABLE = "resize_game_list_table";
export const GAME_LIST_TABLE_HEADER_SIZE = "game_list_table_header_size";
export const GAME_LIST_TABLE_CONTENT_SIZE_CHANGED = "game_list_table_content_size_changed";
export const GAME_LOBBY_BUTTONS_RENDERED_IN_GAME_LIST = "game_lobby_buttons_rendered_in_game_list";
export const GAME_LOBBY_BUTTONS_UNMOUNTED_FROM_GAME_LIST = "game_lobby_buttons_unmounted_from_game_list";
export const GAME_LIST_FILTER_RENDERED = "game_list_filter_rendered";
export const GAME_LIST_FILTER_TOGGLE = "game_list_filter_toggle";
export const GAME_LIST_FILTER_UNMOUNT = "game_list_filter_unmount";
export const GAME_LIST_FILTER_SECTION_UNMOUNT = "game_list_filter_section_unmount";
export const RESIZE_GAME_LOBBY_BUTTONS = "resize_game_lobby_buttons";
export const RESIZE_GAME_DETAILS_TABLE = "resize_game_details_table";
export const GAME_DETAILS_TABLE_RENDERED = "game_details_table_rendered";
export const GAME_DETAILS_TABLE_HEADER_SIZE = "game_details_table_header_size";
export const GAME_DETAILS_TABLE_CONTENT_SIZE_CHANGED = "game_details_table_content_size_changed";
export const RESIZE_CREATE_NEW_GAME = "resize_create_new_game";
export const CREATE_GAME_BUTTONS_RENDERED = "create_game_buttons_rendered_in_slot_settings";
export const RESIZE_CREATE_GAME_BUTTONS = "resize_create_game_buttons";
export const RESIZE_NEW_GAMES_RULE_DETAILS = "resize_new_games_rule_details";
export const IS_FIVE_OR_MORE_PLAYERS_GAME_ENABLED = "is_five_or_more_players_game_enabled";
export const RESIZE_GAME_ROOM = "resize_game_room";
export const GAME_ROOM_BUTTONS_RENDERED = "game_room_buttons_rendered";
export const RESIZE_GAME_ROOM_BUTTONS = "resize_game_room_buttons";
export const RESIZE_GAME_ROOMS_RULE_DETAILS = "resize_game_rooms_rules_details";
//#endregion

//#region GameList actions
export const UPDATE_GAME_LIST = "update_gameList";
export const INSERT_NEW_GAME = "insert_new_game";
export const REMOVE_GAME = "remove_game";
export const GAME_LIST_HEADER_CELL_PRESSED = "game_list_header_cell_pressed";
export const SELECT_GAME_IN_LOBBY = "select_game_in_lobby";
export const MOUSEENTER_GAME_IN_LOBBY = "mouseenter_game_in_lobby";
export const MOUSELEAVE_GAME_IN_LOBBY = "mouseleave_game_in_lobby";
export const SET_FILTER_LABELS = "set_filter_labels";
export const UPDATE_PLAYER_AMOUNT_FILTER = "update_player_amount_filter";
export const SET_FILTER = "set_filter";
export const APPLY_FILTER = "apply_filter";
export const RESET_FILTER = "reset_filter";
export const ENABLE_JOIN_GAME_BUTTON = "enable_join_game_button";
export const MOUSE_ENTERED_GAME_BUTTON = "mouse_entered_game_button";
export const MOUSE_LEAVED_GAME_BUTTON = "mouse_leaved_game_button";
export const CLEAR_GAME_SELECTION = "clear_game_selection";
export const CLEAR_GAME_LIST_MODULE = "clear_game_list_module";
export const SUBSCRIBE_TO_GAME_LIST = "subscribe_to_game_list";
export const GAME_LIST_SUBSCRIBED_RECEIVED = "game_list_subscribed_received";
export const GAME_LIST_UNSUBSCRIBED_RECEIVED = "game_list_unsubscribed_received";
//#endregion

//#region GameDetails actions
export const RECEIVE_GAME_PLAYER_INFO = "receive_game_player_info";
export const SELECT_SLOT_IN_LOBBY = "select_slot_in_lobby";
export const MOUSE_ENTER_SLOT_IN_LOBBY = "mouse_enter_slot_in_lobby";
export const MOUSE_LEAVE_SLOT_IN_LOBBY = "mouse_leave_slot_in_lobby";
export const CLEAR_GAME_DETAILS_IN_LOBBY = "clear_game_details_in_lobby";
export const LOADING_GAME_DETAILS_IN_LOBBY = "loading_game_details_in_lobby";
export const LOADED_GAME_DETAILS_IN_LOBBY = "loaded_game_details_in_lobby";
export const LOADING_GAME_DETAILS_IN_LOBBY_FINISHED = "loading_game_details_in_lobby_finished";
//#endregion

//#region CreateNewGame actions
export const SET_AD_UNLOCK_OPTION = "set_ad_unlock_option";
export const SAVE_AVAILABLE_BOTS = "save_available_bots";
export const PREPARE_PUBLISH_GAME_JSON = "prepare_publish_game_json";
export const SET_USER_AS_GAME_CREATOR = "set_user_as_game_creator";
export const CHANGE_GAME_NAME = "change_game_name";
export const SELECT_NEW_GAMES_SLOT = "select_new_games_slot";
export const MOUSE_ENTER_NEW_GAMES_SLOT = "mouse_enter_new_games_slot";
export const MOUSE_LEAVE_NEW_GAMES_SLOT = "mouse_leave_new_games_slot";
export const UPDATE_NEW_GAMES_SLOTS = "update_new_games_slots";
export const PREPARE_NEW_GAMES_RULES_LIST = "prepare_new_games_rules_list";
export const UPDATE_PLAYER_AMOUNT_OPTIONS = "update_player_amount_options";
export const UPDATE_SWITCHBOX_RULE = "update_switchbox_rule";
export const UPDATE_REQUIRED_CHIPS = "update_required_chips";
export const UPDATE_NEW_GAMES_PLAYER_AMOUNT = "update_new_games_player_amount";
export const UPDATE_NEW_GAMES_PLAYER_LEVEL = "update_new_games_player_level";
export const UPDATE_NEW_GAMES_CARDSET = "update_new_games_cardset";
export const UPDATE_NEW_GAMES_THEME = "update_new_games_theme";
export const UPDATE_NEW_GAMES_PLAYERS = "update_new_games_players";
export const CHECK_IF_CAN_START_GAME = "check_if_can_start_game";
export const RESET_CREATE_NEW_GAME = "reset_create_new_game";
export const UPDATE_NATIVE_SWITCH_RULE = "update_native_switch_rule";
export const UPDATE_MIN_RANKING = "update_min_ranking";
export const UPDATE_MAX_RANKING = "update_max_ranking";
//#endregion

//#region GameRoom actions
export const INIT_GAME_ROOMS_PLAYERS = "init_game_rooms_players";
export const GAME_PUBLISHED_RECEIVED = "game_published_received";
export const GAME_JOINED_RECEIVED = "game_joined_received";
export const RESET_GAME_ROOM = "reset_game_room";
export const GAME_NAME_UPDATED = "game_name_updated";
export const PREPARE_GAME_ROOMS_RULES_LIST = "prepare_game_rooms_rules_list";
export const SELECT_GAME_ROOMS_SLOT = "select_game_rooms_slot";
export const UPDATE_GAME_ROOMS_SLOT = "update_game_rooms_slot";
export const MOUSE_ENTER_GAME_ROOMS_SLOT = "mouse_enter_game_rooms_slot";
export const MOUSE_LEAVE_GAME_ROOMS_SLOT = "mouse_leave_game_rooms_slot";
export const RECEIVE_GAME_CHAT_MESSAGE = "receive_game_chat_message";
export const GAME_PLAYER_JOINED = "game_player_joined";
export const GAME_PLAYER_LEFT = "game_player_left";
export const DISABLE_CHANGE_SLOT = "disable_change_slot";
export const CLEAR_SLOT_SELECTION = "clear_slot_selection";
//#endregion

//#region CustomModal actions
export const OPEN_MODAL_DIALOG = "open_modal_dialog";
export const CLOSE_MODAL_DIALOG = "close_modal_dialog";
export const ENABLE_RECONNECT = "enable_reconnect";
export const DISABLE_RECONNECT = "disable_reconnect";
//#endregion

//#region GlobalChat actions
export const CHAT_ROOM_USER_UPDATED = "chat_room_user_updated";
export const SET_ONLINE_PLAYERS_AMOUNT = "set_online_players_amount";
export const UPDATE_CHATROOM_LIST = "update_chatroom_list";
export const REMOVE_CHATROOM = "remove_chatroom";
export const SELECT_CHATROOM_TAB = "select_chat_room_tab";
export const CHANGE_CHATROOM_TABS_SOUND = "change_chat_room_tabs_sound";
export const CLOSE_CHATROOM_TAB = "close_chat_room_tab";
export const SAVE_CHATROOM_TABS_ORDER = "save_chat_room_tabs_order";
export const CALC_GLOBAL_NEW_MESSAGES = "recalc_global_new_messages";
export const SET_CHATROOM_TAB_WIDTH = "set_chatroom_tab_width";
export const ADD_JOINED_ROOM = "add_joined_room";
export const OPEN_JOINED_ROOM = "open_joined_room";
export const REMOVE_USER_FROM_CHATROOM = "remove_user_from_chatroom";
export const RECEIVE_GLOBAL_CHAT_MESSAGE = "receive_global_chat_message";
export const CLEAR_GLOBAL_CHAT = "clear_global_chat";
//#endregion

//#region GameShop actions
export const SAVE_AVAILABLE_PURCHASE = "save_available_purchase";
export const START_VALIDATE_PURCHASE = "start_validate_purchase";
export const PURCHASE_VALIDATED = "purchase_validated";
export const PURCHASE_NOT_VALIDATED = "purchase_not_validated";
export const OPEN_SILVER_SHOP = "open_silver_shop";
export const CLOSE_SILVER_SHOP = "close_silver_shop";
export const OPEN_GOLD_SHOP = "open_gold_shop";
export const UPDATE_GOLD_ITEMS_PRICES = "update_gold_items_prices";
export const CLOSE_GOLD_SHOP = "close_gold_shop";
export const OPEN_VIP_SHOP = "open_vip_shop";
export const CLOSE_VIP_SHOP = "close_vip_shop";
export const OPEN_CHIPS_SHOP = "open_chips_shop";
export const CLOSE_CHIPS_SHOP = "close_chips_shop";
export const OPEN_MULTIPLIERS_SHOP = "open_multipliers_shop";
export const CLOSE_MULTIPLIERS_SHOP = "close_multipliers_shop";
export const OPEN_CARDSETS_SHOP = "open_cardsets_shop";
export const CLOSE_CARDSETS_SHOP = "close_cardsets_shop";
export const OPEN_MISC_SHOP = "open_misc_shop";
export const CLOSE_MISC_SHOP = "close_misc_shop";
export const BUY_ITEM = "buy_item";
export const BUY_ITEM_SUCCEEDED = "buy_item_succeeded";
export const ADD_CREDIT_SUCCEEDED = "add_credit_succeeded";
export const PURCHASE_STARTED = "add_credit_started";
export const CLEAR_STARTED_PURCHASE = "clear_started_purchase";
export const BUY_ITEM_FAILED = "buy_item_failed";
export const RECEIVE_SHOP_INVENTORY = "receive_shop_inventory";
//#endregion
