import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor, RESOLUTION } from "../../../../../config/defaults";
import { gameDimensions } from "../../../../../config/dimensions";
import {
	closeNotificationsPreferences,
	goBackToMain,
	openAppNotificationsSettings,
	openEmailNotificationsSettings,
} from "../../../../../redux/actions";
import { ColoredButton, NavigationHeader } from "../../../../common";
import AppNotifications from "./AppNotifications";
import EmailNotifications from "./EmailNotifications";

class NotificationsPreferences extends Component {
	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeNotificationsPreferences}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "email":
				textContent = this.props.lang.notificationsPreferences.btnEmail;
				onPressFunc = () => {
					this.props.openEmailNotificationsSettings();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "app":
				textContent = this.props.lang.notificationsPreferences.btnApp;
				onPressFunc = () => {
					this.props.openAppNotificationsSettings();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
		}
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				additionalTextStyle={_buttonTextStyle}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderButton("email")}
					{this.renderButton("app")}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.emailNotificationsSettingsOpened) {
			return <EmailNotifications />;
		} else if (this.props.appNotificationsSettingsOpened) {
			return <AppNotifications />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		minHeight: "100%",
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		emailNotificationsSettingsOpened: state.app.emailNotificationsSettingsOpened,
		appNotificationsSettingsOpened: state.app.appNotificationsSettingsOpened,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeNotificationsPreferences,
	goBackToMain,
	openAppNotificationsSettings,
	openEmailNotificationsSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPreferences);
