import { orderBy } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { globalChatMessageLimitPerRoom } from "../../config/defaults";
import {
	ADD_JOINED_ROOM,
	CALC_GLOBAL_NEW_MESSAGES,
	CHANGE_CHATROOM_TABS_SOUND,
	CHAT_ROOM_USER_UPDATED,
	CLEAR_GLOBAL_CHAT,
	CLOSE_CHATROOM_TAB,
	OPEN_JOINED_ROOM,
	RECEIVE_GLOBAL_CHAT_MESSAGE,
	REMOVE_CHATROOM,
	REMOVE_USER_FROM_CHATROOM,
	RESET_CLIENT,
	SAVE_CHATROOM_TABS_ORDER,
	SELECT_CHATROOM_TAB,
	SET_CHATROOM_TAB_WIDTH,
	SET_ONLINE_PLAYERS_AMOUNT,
	UPDATE_CHATROOM_LIST,
} from "../actions/actionTypes";

const initialState = {
	chatRoomList: [],
	joinedChatRooms: [],
	globalUnreadMessageCounter: 0,
	onlinePlayersAmount: 0,
	activeRoom: null,
	rightClickedPlayer: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case CHAT_ROOM_USER_UPDATED:
			if (
				typeof payload === "undefined" ||
				typeof payload.chatRoomID === "undefined" ||
				!payload.user ||
				!payload.user.userID
			)
				return state;
			// const { joinedChatRooms, activeRoom } = state;
			var newActiveRoom = state.activeRoom,
				newJoinedRooms = state.joinedChatRooms;
			if (state.activeRoom.chatRoomID == payload.chatRoomID && Array.isArray(state.activeRoom.users)) {
				newActiveRoom = cloneDeep(state.activeRoom);
				var _user = newActiveRoom.users.find((u) => u.userID === payload.user.userID);
				if (_user) {
					_user.city = payload.user.city;
					_user.country = payload.user.country;
					_user.isVip = payload.user.isVip;
					_user.language = payload.user.language;
					_user.ranking = payload.user.level;
					_user.score = payload.user.score;
				}
			}
			var joinedIndex = newJoinedRooms.findIndex((ar) => ar.chatRoomID == payload.chatRoomID);
			if (joinedIndex != -1 && Array.isArray(state.joinedChatRooms[joinedIndex].users)) {
				newJoinedRooms = cloneDeep(state.joinedChatRooms);
				var _user = newJoinedRooms[joinedIndex].users.find((u) => u.userID === payload.user.userID);
				if (_user) {
					_user.city = payload.user.city;
					_user.country = payload.user.country;
					_user.isVip = payload.user.isVip;
					_user.language = payload.user.language;
					_user.ranking = payload.user.level;
					_user.score = payload.user.score;
				}
			}
			return {
				...state,
				activeRoom: newActiveRoom,
				joinedChatRooms: newJoinedRooms,
			};
		case SET_ONLINE_PLAYERS_AMOUNT:
			if (typeof payload === "undefined") return state;
			const nr = Number(payload);
			if (isNaN(nr)) return state;
			return { ...state, onlinePlayersAmount: nr };
		case UPDATE_CHATROOM_LIST:
			if (
				typeof payload === "undefined" ||
				!Array.isArray(payload.chatRooms)
				/* !Array.isArray(payload.newRooms) ||
        !Array.isArray(payload.updRooms) ||
        !Array.isArray(payload.delRooms) */
			)
				return state;
			var newChatRoomList = [...state.chatRoomList],
				newActiveRoom = state.activeRoom,
				nJoinedRooms = state.joinedChatRooms;
			payload.chatRooms.forEach((room) => {
				var exists = newChatRoomList.find((el) => el.chatRoomID == room.chatRoomID);
				if (exists == undefined) {
					newChatRoomList.push(room);
				} else {
					exists.userCnt = room.userCnt;
					if (newActiveRoom != null && newActiveRoom.chatRoomID == room.chatRoomID) {
						newActiveRoom = { ...state.activeRoom };
						newActiveRoom.userCnt = room.userCnt;
					}
					var existingJoinedIndex = state.joinedChatRooms.findIndex((r) => r.chatRoomID == room.chatRoomID);
					if (existingJoinedIndex >= 0) {
						nJoinedRooms = [...state.joinedChatRooms];
						nJoinedRooms[existingJoinedIndex].userCnt = room.userCnt;
					}
				}
			});
			/* payload.newRooms.forEach((room) => {
        var exists = newChatRoomList.findIndex(
          (el) => el.chatRoomID == room.chatRoomID
        );
        if (exists == -1) newChatRoomList.push(room);
      });
      payload.updRooms.forEach((room) => {
        var exists = newChatRoomList.findIndex(
          (el) => el.chatRoomID == room.chatRoomID
        );
        if (exists != -1) newChatRoomList.splice(exists, 1, room);
      });
      payload.delRooms.forEach((room) => {
        var exists = newChatRoomList.findIndex(
          (el) => el.chatRoomID == room.chatRoomID
        );
        if (exists != -1) newChatRoomList.splice(exists, 1);
      }); */
			nJoinedRooms = orderBy(nJoinedRooms, ["chatRoomID"], ["asc"]);
			return {
				...state,
				chatRoomList: newChatRoomList,
				joinedChatRooms: nJoinedRooms,
				activeRoom: newActiveRoom,
			};
		case REMOVE_CHATROOM:
			if (typeof payload === "undefined" || typeof payload.chatRoomID === "undefined") return state;

			var removableRoomIndex = state.chatRoomList.findIndex((el) => el.chatRoomID == payload.chatRoomID);
			if (removableRoomIndex == -1) return state;

			var newChatRoomList = [...state.chatRoomList];
			newChatRoomList.splice(removableRoomIndex, 1);
			return { ...state, chatRoomList: newChatRoomList };
		case CLOSE_CHATROOM_TAB:
			if (typeof payload === "undefined" || typeof payload.chatRoomID === "undefined") return state;
			const { joinedChatRooms, activeRoom } = state;
			var roomToDeleteIndex = joinedChatRooms.findIndex((r) => r.chatRoomID == payload.chatRoomID);
			if (roomToDeleteIndex == -1) return state;
			var nActiveRoom =
				activeRoom != null && joinedChatRooms[roomToDeleteIndex].chatRoomID == activeRoom.chatRoomID
					? null
					: activeRoom;
			var newRooms = cloneDeep(joinedChatRooms);
			newRooms.splice(roomToDeleteIndex, 1);
			newRooms = orderBy(newRooms, ["chatRoomID"], ["asc"]);
			return {
				...state,
				activeRoom: nActiveRoom,
				joinedChatRooms: newRooms,
			};
		case SELECT_CHATROOM_TAB:
			if (typeof payload === "undefined" || typeof payload.chatRoomID === "undefined") return state;
			var nJoinedRooms = cloneDeep(state.joinedChatRooms);
			nJoinedRooms.map((r) => (r.selected = false));
			var selRoom = nJoinedRooms.find((rs) => rs.chatRoomID == payload.chatRoomID);
			selRoom.selected = true;
			selRoom.unreadMessageCounter = 0;
			return {
				...state,
				activeRoom: selRoom,
				joinedChatRooms: nJoinedRooms,
			};
		case CHANGE_CHATROOM_TABS_SOUND:
			if (typeof payload === "undefined" || typeof payload.chatRoomID === "undefined") return state;
			var nJoinedRooms = cloneDeep(state.joinedChatRooms);
			var room = nJoinedRooms.find((rs) => rs.chatRoomID == payload.chatRoomID);
			room.muted = !room.muted;
			var activeRoomClone =
				state.activeRoom !== null && room.chatRoomID == state.activeRoom.chatRoomID
					? cloneDeep(state.activeRoom)
					: state.activeRoom;
			activeRoomClone.muted =
				state.activeRoom !== null && room.chatRoomID == state.activeRoom.chatRoomID
					? !activeRoomClone.muted
					: activeRoomClone.muted;
			return {
				...state,
				activeRoom: activeRoomClone,
				joinedChatRooms: nJoinedRooms,
			};
		case SAVE_CHATROOM_TABS_ORDER:
			if (typeof payload === "undefined") return state;
			const { itemOrder } = payload;
			if (!Array.isArray(itemOrder)) return state;
			var newJoinedRooms = [];
			itemOrder.forEach((item) => {
				var room = state.joinedChatRooms[item.key];
				if (room != undefined) newJoinedRooms.splice(item.order, 0, cloneDeep(room));
			});
			return { ...state, joinedChatRooms: newJoinedRooms };
		case SET_CHATROOM_TAB_WIDTH:
			if (typeof payload === "undefined") return state;
			const { rroom, width } = payload;
			if (typeof rroom === "undefined" || typeof width === "undefined") return state;
			var uroom = state.joinedChatRooms.find((r) => r.chatRoomID == rroom.chatRoomID);
			if (uroom == undefined) return state;
			uroom = cloneDeep(uroom);
			const uroomIndex = state.joinedChatRooms.findIndex((r) => uroom.chatRoomID == r.chatRoomID);
			uroom.selectedWidth = width.selectedWidth;
			uroom.unreadWidth = width.unreadWidth;
			uroom.readWidth = width.readWidth;
			var newRooms = cloneDeep(state.joinedChatRooms);
			newRooms.splice(uroomIndex, 1, uroom);
			return { ...state, joinedChatRooms: newRooms };
		case ADD_JOINED_ROOM:
			if (
				typeof payload === "undefined" ||
				typeof payload.crjMsg === "undefined" ||
				typeof payload.userDetails === "undefined" ||
				typeof payload.crjMsg.iJoined !== "boolean" ||
				typeof payload.crjMsg.chatRoomID === "undefined"
			)
				return state;
			const { iJoined, chatRoomID, chatRoom } = payload.crjMsg;
			const { userDetails } = payload;
			var newJoinedChatRooms = cloneDeep(state.joinedChatRooms);
			if (iJoined) {
				//current user joined the room
				newJoinedChatRooms.map((cr) => (cr.selected = false));
				const currentUser = {
					userID: userDetails.userID,
					username: userDetails.username,
					country: userDetails.country,
					ranking: userDetails.ranking,
					points: userDetails.points,
					score: userDetails.score,
					gamesPlayed: userDetails.gamesPlayed,
					isVip: userDetails.isVip,
					relation: "NORMAL",
				};
				var newRoom = {};
				newRoom.chatRoomID = chatRoomID;
				newRoom.name = chatRoom.name;
				newRoom.muted = false;
				newRoom.selected = true;
				newRoom.users = [];
				newRoom.chatMessages = [];
				newRoom.unreadMessageCounter = 0;
				/* if (typeof users === "undefined") {
          //current user is the creator of this room
          newRoom.isRoomCreator = true;
          newRoom.users.push(currentUser);
        } else { */
				//current user joined this room
				newRoom.isRoomCreator = false;
				newRoom.userCnt = chatRoom.userCnt;
				if (Array.isArray(chatRoom.users)) {
					chatRoom.users.forEach((u) => {
						if (u.userID == userDetails.userID) {
							newRoom.users.push(currentUser);
						} else {
							u.ranking = u.level || 0;
							newRoom.users.push(u);
						}
					});
				}
				// }
				if (newJoinedChatRooms.findIndex((r) => r.chatRoomID == chatRoomID) == -1) {
					newJoinedChatRooms.push(newRoom);
					// sortObjectArray(newJoinedChatRooms, "chatRoomID", true);
					newJoinedChatRooms = orderBy(newJoinedChatRooms, ["chatRoomID"], ["asc"]);
					return {
						...state,
						activeRoom: newRoom,
						joinedChatRooms: newJoinedChatRooms,
					};
				}
				return state;
			} else {
				if (typeof payload.crjMsg.user === "undefined") return state;
				// other user joined this room which is already joined by the current user
				var newActiveRoom =
					state.activeRoom !== null && state.activeRoom.chatRoomID == chatRoomID
						? cloneDeep(state.activeRoom)
						: state.activeRoom;
				const roomToUpdate = newJoinedChatRooms.find((r) => r.chatRoomID == chatRoomID);
				var checkUser;
				if (newActiveRoom != undefined && newActiveRoom != null) {
					const { users } = newActiveRoom;
					checkUser = users.findIndex((ar) => ar.userID == payload.crjMsg.user.userID);
					if (checkUser == -1) {
						payload.crjMsg.user.ranking = payload.crjMsg.user.level || 0;
						users.push(payload.crjMsg.user);
					}
					if (typeof payload.crjMsg.userCntInRoom !== "undefined") {
						newActiveRoom.userCnt = payload.crjMsg.userCntInRoom;
					}
				}
				if (roomToUpdate != undefined && roomToUpdate != null) {
					const jrUsers = roomToUpdate.users;
					checkUser = jrUsers.findIndex((ar) => ar.userID == payload.crjMsg.user.userID);
					if (checkUser == -1) {
						payload.crjMsg.user.ranking = payload.crjMsg.user.level || 0;
						jrUsers.push(payload.crjMsg.user);
					}
					if (typeof payload.crjMsg.userCntInRoom !== "undefined") {
						roomToUpdate.userCnt = payload.crjMsg.userCntInRoom;
					}
				}
				newJoinedChatRooms = orderBy(newJoinedChatRooms, ["chatRoomID"], ["asc"]);
				return {
					...state,
					activeRoom: newActiveRoom,
					joinedChatRooms: newJoinedChatRooms,
				};
			}
		case OPEN_JOINED_ROOM:
			if (typeof payload === "undefined" || typeof payload.chatRoomID === "undefined") return state;
			var newJoinedChatRoomList = cloneDeep(state.joinedChatRooms);
			var roomToOpen = newJoinedChatRoomList.find((r) => r.chatRoomID == payload.chatRoomID);
			if (roomToOpen == undefined) return state;
			newJoinedChatRoomList.map((obj) => (obj.selected = false));
			roomToOpen.selected = true;
			newJoinedChatRoomList = orderBy(newJoinedChatRoomList, ["chatRoomID"], ["asc"]);
			return {
				...state,
				activeRoom: roomToOpen,
				joinedChatRooms: newJoinedChatRoomList,
			};
		case REMOVE_USER_FROM_CHATROOM:
			if (
				typeof payload === "undefined" ||
				typeof payload.crlMsg === "undefined" ||
				typeof payload.uDetails === "undefined" ||
				typeof payload.crlMsg.chatRoomID === "undefined"
			)
				return state;
			const { uDetails, crlMsg } = payload;
			var newJoinedChatRooms = cloneDeep(state.joinedChatRooms);
			var roomToUpdate = newJoinedChatRooms.find((r) => r.chatRoomID == crlMsg.chatRoomID);
			var newActiveRoom = state.activeRoom;
			if (crlMsg.userID != uDetails.userID) {
				var userToRemoveInd = -1;
				if (roomToUpdate != undefined) userToRemoveInd = roomToUpdate.users.findIndex((u) => u.userID == crlMsg.userID);
				if (userToRemoveInd != -1) {
					roomToUpdate.users.splice(userToRemoveInd, 1);
					if (newActiveRoom != null && newActiveRoom.chatRoomID == crlMsg.chatRoomID) {
						newActiveRoom = cloneDeep(state.activeRoom);
						newActiveRoom.users.splice(userToRemoveInd, 1);
						if (typeof payload.crlMsg.userCntInRoom !== "undefined") {
							newActiveRoom.userCnt = payload.crlMsg.userCntInRoom;
						}
					}
					if (typeof payload.crlMsg.userCntInRoom !== "undefined") {
						roomToUpdate.userCnt = payload.crlMsg.userCntInRoom;
					}
				}
			}
			return {
				...state,
				activeRoom: newActiveRoom,
				joinedChatRooms: newJoinedChatRooms,
			};
		case RECEIVE_GLOBAL_CHAT_MESSAGE:
			if (typeof payload === "undefined" || typeof payload.chatRoomID === "undefined") return state;

			var receiverRoomIndex = state.joinedChatRooms.findIndex((r) => r.chatRoomID == payload.chatRoomID);
			if (receiverRoomIndex < 0) return state;
			var receiverRoom = cloneDeep(state.joinedChatRooms[receiverRoomIndex]);

			const isActive =
				state.activeRoom != null && state.activeRoom.chatRoomID == payload.chatRoomID && payload.isTabChatButtonActive;
			const isActiveInGame =
				state.activeRoom != null &&
				state.activeRoom.chatRoomID == payload.chatRoomID &&
				!payload.isTabChatButtonActive &&
				(payload.isGameStarted || payload.isGameRoomOpen);
			var newMessage = {
				chatRoomID: payload.chatRoomID,
				userID: payload.userID,
				ranking: 0,
				name: "",
				color: "#000000",
				country: "",
				timestamp: payload.timestamp,
				content: payload.originalText,
				originalContent: payload.originalText,
				translatedContent: payload.translatedText,
				translated: payload.isTranslated && !payload.hadTranslationError,
			};

			var user = receiverRoom.users.find((u) => u.userID == payload.userID);
			newMessage.ranking = user == undefined || user.ranking == undefined ? 0 : user.ranking;
			newMessage.name = user == undefined || user.username == undefined ? "" : user.username;
			newMessage.country = user == undefined || user.country == undefined ? "" : user.country;
			receiverRoom.unreadMessageCounter = isActive || isActiveInGame ? 0 : ++receiverRoom.unreadMessageCounter;

			receiverRoom.chatMessages.push(newMessage);
			if (typeof globalChatMessageLimitPerRoom === "number") {
				if (receiverRoom.chatMessages.length > globalChatMessageLimitPerRoom) {
					receiverRoom.chatMessages.shift();
					receiverRoom.unreadMessageCounter--;
				}
			}

			var counter = 0;
			state.joinedChatRooms.forEach((room) => (counter += room.unreadMessageCounter));
			state.joinedChatRooms.splice(receiverRoomIndex, 1, receiverRoom);
			if (isActive || isActiveInGame) state.activeRoom.chatMessages = receiverRoom.chatMessages;
			return {
				...state,
				activeRoom: { ...state.activeRoom },
				// state.activeRoom !== null && (isActive || isActiveInGame)
				//   ? { ...state.activeRoom, chatMessages: receiverRoom.chatMessages }
				//   : state.activeRoom,
				//  ? receiverRoom : state.activeRoom,
				joinedChatRooms: isActiveInGame ? [...state.joinedChatRooms] : state.joinedChatRooms,
				globalUnreadMessageCounter: counter,
			};
		case CALC_GLOBAL_NEW_MESSAGES:
			var counter = 0;
			state.joinedChatRooms.forEach((room) => (counter += room.unreadMessageCounter));
			if (counter == state.globalUnreadMessageCounter) return state;
			return { ...state, globalUnreadMessageCounter: counter };
		case CLEAR_GLOBAL_CHAT:
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
