import React, { Component } from "react";
import { Dimensions, Image, ImageBackground, Platform, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import {
	f_sourcesansprobold,
	f_sourcesansprobolditalic,
	f_sourcesansprosemibolditalic,
	isWebOrTablet,
	tabType,
} from "../../config/defaults";
import { enableHiddenFeatures } from "../../config/permissions";
import { getRankingImageURL } from "../../helpers/commonHelpers";
import {
	closePlayerOverview,
	goBackToMain,
	openMultipliersShop,
	openTab,
	openVIPShop,
	startPlay,
	subscribeToGameList,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { AppTouchableOpacity, NavigationHeader, Spinner } from "../common";
import { handleError } from "../ErrorHandler";
import GameShopStack from "../GameShop/GameShopStack";
import ProgressBar from "./ProgressBar";

class PlayerOverview extends Component {
	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("PlayerOverview");
	}
	//#endregion

	//#region events
	handleVIPButtonPress() {
		try {
			this.props.goBackToMain();
			this.props.startPlay();
			this.props.openTab(tabType.SHOP);
			this.props.openVIPShop();
		} catch (error) {
			handleError(error);
		}
	}

	handleBuyMultipliers() {
		try {
			this.props.goBackToMain();
			this.props.startPlay();
			this.props.openTab(tabType.SHOP);
			this.props.openMultipliersShop();
		} catch (error) {
			handleError(error);
		}
	}

	handleListMultipliers() {
		try {
			//TODO:
		} catch (error) {
			handleError(error);
		}
	}

	onBackPress() {
		if (this.props.isLobbyOpen && !this.props.isTournamentTabOpened) this.props.subscribeToGameList();
		this.props.closePlayerOverview();
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			>
				<GameShopStack
				// inShopGoldPress={() => this.handleStackPress(SHOP_CATEGORY.GOLD)} //TODO:
				// inShopSilverPress={() => this.handleStackPress(SHOP_CATEGORY.SILVER)} //TODO:
				// inShopChipPress={() => this.handleStackPress(SHOP_CATEGORY.CHIPS)} //TODO:
				/>
			</NavigationHeader>
		);
	}

	renderButtons() {
		try {
			const vipTitle = this.props.userOverview.isVip
				? this.props.lang.playerOverview.btnRenewVIP
				: this.props.lang.playerOverview.btnBecomeVIP;
			const _enableMultipList = this.props.userOverview.multiplier > 1 && enableHiddenFeatures;
			return (
				<View key="buttonContainer" style={styles.buttonContainer}>
					<AppTouchableOpacity
						key="vipAction"
						activeOpacity={0.8}
						onPress={this.handleVIPButtonPress.bind(this)}
						style={styles.button}
					>
						<Text allowFontScaling={false} style={styles.buttonTextStyle}>
							{vipTitle}
						</Text>
					</AppTouchableOpacity>
					{_enableMultipList && (
						<AppTouchableOpacity
							key="listMultipliers"
							activeOpacity={0.8}
							onPress={this.handleListMultipliers.bind(this)}
							style={[styles.button, { opacity: _enableMultipList ? 1 : 0 }]}
							disabled={!_enableMultipList}
						>
							<Text allowFontScaling={false} style={styles.buttonTextStyle}>
								{this.props.lang.playerOverview.btnListMultipliers}
							</Text>
						</AppTouchableOpacity>
					)}
					<AppTouchableOpacity
						key="buyMultipliers"
						activeOpacity={0.8}
						onPress={this.handleBuyMultipliers.bind(this)}
						style={styles.button}
					>
						<Text allowFontScaling={false} style={styles.buttonTextStyle}>
							{this.props.lang.playerOverview.btnBuyMultiplier}
						</Text>
					</AppTouchableOpacity>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderVipUntil() {
		const { isVip, isVipUntil } = this.props.userOverview;
		const dateVipUntil = new Date(isVipUntil * 1000);
		const contentText = isVip ? dateVipUntil.toLocaleDateString() : "N/A";
		return (
			<View key="vipUntil" style={styles.dataLine}>
				<View style={styles.leftInnerColumn}>
					<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
						{this.props.lang.playerOverview.vipUntilTitle}
					</Text>
				</View>
				<View style={styles.rightInnerColumn}>
					<Text allowFontScaling={false} style={[styles.dataText, styles.rightAlignedText]}>
						{contentText}
					</Text>
				</View>
			</View>
		);
	}

	renderLeftColumn() {
		const { timeJoined } = this.props.userOverview;
		try {
			var rankDim = 30;
			const winDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
			if (winDim.scale > 1) {
				if (winDim.scale < 2.5) {
					rankDim = 50;
				} else {
					rankDim = 150;
				}
			}
			const rankingURL =
				typeof this.props.userOverview.currLevel !== "number"
					? undefined
					: getRankingImageURL(this.props.userOverview.currLevel, rankDim);
			return (
				<View key="leftColumn" style={styles.columnContainer}>
					<View key="gamesWon" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.gamesWonTitle}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.rightAlignedText]}>
								{this.props.userOverview.gamesWon.toLocaleString()}
							</Text>
						</View>
					</View>
					<View key="gamesLost" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.gamesLostTitle}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text allowFontScaling={false} numberOfLines={1} style={[styles.dataText, styles.rightAlignedText]}>
								{this.props.userOverview.gamesLost.toLocaleString()}
							</Text>
						</View>
					</View>
					<View key="currLevel" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.levelTitle}
							</Text>
						</View>
						<View style={[styles.rightInnerColumn, { justifyContent: "flex-end" }]}>
							{rankingURL != undefined && <Image style={[styles.roundIcon]} source={rankingURL} resizeMode="contain" />}
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.dataText, styles.rightAlignedText, { flex: undefined }]}
							>
								{this.props.userOverview.currLevel.toLocaleString()}
							</Text>
						</View>
					</View>
					<View key="score" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.scoreTitle}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text allowFontScaling={false} numberOfLines={1} style={[styles.dataText, styles.rightAlignedText]}>
								{this.props.userOverview.currScore.toLocaleString()}
							</Text>
						</View>
					</View>
					<View key="memberSince" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.memberSinceTitle}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text allowFontScaling={false} numberOfLines={1} style={[styles.dataText, styles.rightAlignedText]}>
								{timeJoined > 0 ? new Date(timeJoined * 1000).toLocaleDateString() : "N/A"}
							</Text>
						</View>
					</View>
					{this.renderVipUntil()}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderRightColumn() {
		try {
			return (
				<View key="rightColumn" style={[styles.columnContainer, { justifyContent: "flex-start" }]}>
					<View key="globalRank" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.glRankTitle}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text allowFontScaling={false} numberOfLines={1} style={[styles.dataText, styles.rightAlignedText]}>
								{this.props.userOverview.globalPos.toLocaleString()}
							</Text>
						</View>
					</View>
					<View key="yearlyRank" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.yRankTitle}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text allowFontScaling={false} numberOfLines={1} style={[styles.dataText, styles.rightAlignedText]}>
								{this.props.userOverview.yearlyPos.toLocaleString()}
							</Text>
						</View>
					</View>
					<View key="monthlyRank" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.mRankTitle}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text allowFontScaling={false} numberOfLines={1} style={[styles.dataText, styles.rightAlignedText]}>
								{this.props.userOverview.monthlyPos.toLocaleString()}
							</Text>
						</View>
					</View>
					<View key="weeklyRank" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.wRankTitle}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text allowFontScaling={false} numberOfLines={1} style={[styles.dataText, styles.rightAlignedText]}>
								{this.props.userOverview.weeklyPos.toLocaleString()}
							</Text>
						</View>
					</View>
					<View key="multiplier" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playerOverview.multiplierTitle}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text allowFontScaling={false} numberOfLines={1} style={[styles.dataText, styles.rightAlignedText]}>
								{this.props.userOverview.multiplier.toLocaleString()}
							</Text>
						</View>
					</View>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderProgressBar() {
		return (
			<View key="progressContainer" style={styles.progressContainer}>
				<Text
					allowFontScaling={false}
					numberOfLines={1}
					style={[styles.dataText, styles.dataTitle, styles.progressText]}
				>
					{this.props.userOverview.startScore.toLocaleString()}
				</Text>
				<ProgressBar
					min={Number(this.props.userOverview.startScore)}
					current={Number(this.props.userOverview.currScore)}
					max={Number(this.props.userOverview.nextScore)}
				/>
				<Text
					allowFontScaling={false}
					numberOfLines={1}
					style={[styles.dataText, styles.dataTitle, styles.progressText]}
				>
					{this.props.userOverview.nextScore.toLocaleString()}
				</Text>
			</View>
		);
	}

	render() {
		return (
			<ImageBackground source={this.props.MENU_PLAY_BACK.url} style={{ width: "100%", height: "100%" }}>
				<View style={styles.mainContainer}>
					<SafeAreaView style={{ flex: 1 }}>
						<View key="innerContainer" style={styles.innerContainer}>
							{this.props.loadingUserOverview && <Spinner size="large" />}
							{!this.props.loadingUserOverview && (
								<View key="infoContainer" style={styles.infoContainer}>
									<View key="infoContainer" style={styles.infoInnerContainer}>
										{this.renderLeftColumn()}
										{this.renderRightColumn()}
									</View>
									{this.renderProgressBar()}
								</View>
							)}
							{!this.props.loadingUserOverview && this.renderButtons()}
						</View>
					</SafeAreaView>
					{this.renderHeader()}
				</View>
			</ImageBackground>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		zIndex: 9995,
	},
	innerContainer: {
		flex: 1,
		justifyContent: "center",
		paddingTop: 60,
		paddingBottom: 15,
		paddingHorizontal: 15,
	},
	infoContainer: {
		flex: 1,
		justifyContent: "space-between",
		marginTop: isWebOrTablet ? 10 : 5,
		marginBottom: isWebOrTablet ? 20 : 10,
		borderWidth: 3,
		borderColor: "#095260",
		borderRadius: 10,
		overflow: "hidden",
		shadowColor: "#065C63",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowRadius: 6,
		shadowOpacity: 0.8,
		elevation: 5,
		backgroundColor: "rgb(57, 232, 242)",
	},
	infoInnerContainer: { flexDirection: "row" },
	progressContainer: {
		width: "100%",
		height: isWebOrTablet ? 40 : 30,
		flexDirection: "row",
		borderTopWidth: 1,
		borderTopColor: "#47e7ee",
		backgroundColor: "rgba(57, 232, 242,0.8)",
	},
	progressText: {
		flex: undefined,
		marginHorizontal: 20,
		fontFamily: f_sourcesansprobold,
	},
	buttonContainer: {
		width: "100%",
		height: isWebOrTablet ? 80 : 50,
		flexDirection: "row",
		justifyContent: "space-around",
	},
	button: {
		width: 200,
		height: isWebOrTablet ? 60 : 50,
		alignSelf: "center",
		borderWidth: 2,
		borderRadius: 10,
		borderColor: "#24cdef",
		backgroundColor: "#0a5f6e",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		shadowColor: "#06525a",
		shadowOffset: {
			width: 2,
			height: 4,
		},
		shadowRadius: 4,
		shadowOpacity: 0.8,
		elevation: 5,
	},
	buttonTextStyle: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		color: "#FFFFFF",
	},
	columnContainer: { flex: 2, justifyContent: "space-around" },
	dataLine: {
		flexDirection: "row",
		borderBottomWidth: 1,
		borderBottomColor: "#47e7ee",
	},
	leftInnerColumn: {
		flex: 2,
		paddingHorizontal: 15,
		flexDirection: "row",
		backgroundColor: "rgba(57, 232, 242,0.8)",
	},
	rightInnerColumn: {
		flex: 2,
		paddingHorizontal: 15,
		flexDirection: "row",
		backgroundColor: "rgba(36, 204, 216, 0.8)",
	},
	dataTitle: { fontFamily: f_sourcesansprobolditalic },
	dataText: {
		flex: 1,
		fontSize: isWebOrTablet ? 24 : 20,
		fontFamily: f_sourcesansprosemibolditalic,
		textAlign: "left",
		alignSelf: "center",
		color: "#07525f",
	},
	rightAlignedText: { textAlign: "right" },
	centeredText: { textAlign: "center" },
	roundIcon: {
		width: isWebOrTablet ? 25 : 20,
		height: isWebOrTablet ? 25 : 20,
		marginRight: 5,
		alignSelf: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		userOverview: state.currentUser.userOverview,
		loadingUserOverview: state.app.loadingUserOverview,
		resolution: state.currentUser.preferences.resolution,
		isLeaderBoardsMenuOpened: state.app.isLeaderBoardsMenuOpened,
		isShopOpen: state.tab.isShopOpen,
		isLobbyOpen: state.tab.isLobbyOpen,
		isTournamentTabOpened: state.app.isTournamentTabOpened,
		MENU_PLAY_BACK: state.images.MENU_PLAY_BACK,
	};
};

const mapDispatchToProps = {
	closePlayerOverview,
	goBackToMain,
	openMultipliersShop,
	openTab,
	openVIPShop,
	startPlay,
	subscribeToGameList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerOverview);
