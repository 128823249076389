import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import { f_sourcesansprosemibold, isWebOrTablet } from "../../config/defaults";
import { handleError } from "../ErrorHandler";

class ProgressBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			progressWidth: 0,
			textWidth: 0,
			isInnerText: false,
		};
	}
	//#region events
	measureProgressLayout(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			this.setState({ progressWidth: event.nativeEvent.layout.width });
		} catch (error) {
			handleError(error);
		}
	}

	measureTextLayout(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const textWidth = event.nativeEvent.layout.width;
			const renderInner = textWidth <= this.state.progressWidth;
			this.setState({ textWidth: textWidth, isInnerText: renderInner });
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		var progressWidthProc = 0;
		if (
			!isNaN(this.props.min) &&
			!isNaN(this.props.current) &&
			!isNaN(this.props.max) &&
			this.props.min >= 0 &&
			this.props.current >= 0 &&
			this.props.max >= 0
		) {
			const required = Math.abs(this.props.max - this.props.min);
			const aquired = Math.abs(this.props.current - this.props.min);
			if (required > 0) progressWidthProc = Math.round((aquired / required) * 100);
		}
		progressWidthProc += "%";
		const progressTextContent = this.props.current.toLocaleString() + " (" + progressWidthProc + ")";
		return (
			<View style={styles.container}>
				<View style={styles.progressBarContainer}>
					<View
						onLayout={(event) => this.measureProgressLayout(event)}
						style={[styles.progressBar, { width: progressWidthProc }]}
					>
						{this.state.isInnerText && (
							<Text allowFontScaling={false} numberOfLines={1} style={styles.currentText}>
								{progressTextContent}
							</Text>
						)}
					</View>
					{!this.state.isInnerText && (
						<Text
							onLayout={(event) => this.measureTextLayout(event)}
							allowFontScaling={false}
							numberOfLines={1}
							style={[styles.currentText, styles.leftAlignedText, { marginLeft: this.state.progressWidth }]}
						>
							{progressTextContent}
						</Text>
					)}
				</View>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "transparent",
		padding: 5,
	},
	progressBarContainer: {
		height: isWebOrTablet ? 30 : 25,
		width: "100%",
		backgroundColor: "#16a6c1",
		borderColor: "#0C697A",
		borderWidth: 2,
		borderRadius: 10,
		flexDirection: "row",
	},
	progressBar: {
		position: "absolute",
		height: "105%",
		top: -1,
		left: -2,
		right: 0,
		bottom: 0,
		zIndex: -1,
		backgroundColor: "#0C697A",
		borderRadius: isWebOrTablet ? 8 : 8,
	},
	currentText: {
		fontSize: isWebOrTablet ? 24 : 20,
		fontFamily: f_sourcesansprosemibold,
		color: "rgb(57, 232, 242)",
		lineHeight: isWebOrTablet ? 27 : 23,
		paddingHorizontal: 10,
		textAlign: "right",
	},
	leftAlignedText: {
		textAlign: "left",
		color: "#07525f",
	},
});

export default ProgressBar;
