import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansproitalic,
	f_sourcesansprosemibolditalic,
	processState,
	RESOLUTION,
} from "../../../../../config/defaults";
import { gameDimensions, updatePersonalDataDimensions } from "../../../../../config/dimensions";
import { closeScreenPreferences, goBackToMain, updateResolution } from "../../../../../redux/actions";
import Analytics from "../../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner, SwitchBox } from "../../../../common";
import { handleError } from "../../../../ErrorHandler";

class ScreenResolution extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			resolution: {
				0: this.props.resolution == RESOLUTION.LOW,
				1: this.props.resolution == RESOLUTION.MEDIUM,
				2: this.props.resolution == RESOLUTION.HIGH,
			},
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("ScreenResolutionPreferences");
	}
	//#endregion

	//#region lifecycle methods
	componentDidUpdate(prevProps) {
		if (prevProps.resolution !== this.props.resolution) {
			this.setState({
				resolution: {
					0: this.props.resolution == RESOLUTION.LOW,
					1: this.props.resolution == RESOLUTION.MEDIUM,
					2: this.props.resolution == RESOLUTION.HIGH,
				},
			});
		}
	}
	//#endregion

	//#region events
	onUpdatePress() {
		try {
			var resolution = "";
			if (this.state.resolution[0] == true) {
				resolution = RESOLUTION.LOW;
			} else if (this.state.resolution[1] == true) {
				resolution = RESOLUTION.MEDIUM;
			} else {
				resolution = RESOLUTION.HIGH;
			}
			this.props.updateResolution(resolution);
		} catch (error) {
			handleError(error);
		}
	}

	onResolutionCheck(filterGroup, val, isChecked) {
		try {
			switch (val) {
				case this.props.lang.screenPreferences.resolution.LOW:
					this.setState({
						resolution: { 0: isChecked, 1: !isChecked, 2: !isChecked },
					});
					break;
				case this.props.lang.screenPreferences.resolution.MEDIUM:
					this.setState({
						resolution: { 0: !isChecked, 1: isChecked, 2: !isChecked },
					});
					break;
				case this.props.lang.screenPreferences.resolution.HIGH:
					this.setState({
						resolution: { 0: !isChecked, 1: !isChecked, 2: isChecked },
					});
					break;
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateResolutionState != processState.STARTED}
				backAction={this.props.closeScreenPreferences}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer} key="submit">
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderResolutionField() {
		const resolutionLables = [
			this.props.lang.screenPreferences.resolution.LOW,
			this.props.lang.screenPreferences.resolution.MEDIUM,
			this.props.lang.screenPreferences.resolution.HIGH,
		];
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_switchBoxStyle = styles.switchBox;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_switchBoxStyle = [styles.switchBox, { width: 400 }];
		}
		return (
			<View style={styles.dataInnerContainer} key="resolution">
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.screenPreferences.switchTitle}
				</Text>
				<SwitchBox
					isMultiChecked={false}
					group="gender"
					onCheck={this.onResolutionCheck.bind(this)}
					labels={resolutionLables}
					checks={this.state.resolution}
					style={styles.switchBox}
					labelStyle={{
						fontFamily:
							this.props.resolution === RESOLUTION.HIGH ? f_sourcesansprosemibolditalic : f_sourcesansproitalic,
						fontSize: this.props.resolution === RESOLUTION.HIGH ? 26 : 18,
						textAlign: "center",
						lineHeight: this.props.resolution === RESOLUTION.HIGH ? 45 : 30,
						alignSelf: "center",
						marginTop: 0,
						marginBottom: 0,
						color: "#084955",
						textShadowColor: "rgba(0,0,0,0.25)",
						textShadowOffset: { width: 0, height: 1 },
						textShadowRadius: 10,
					}}
					labelBackground={{
						marginTop: this.props.resolution === RESOLUTION.HIGH ? -2 : 0,
						width: this.props.resolution === RESOLUTION.HIGH ? 120 : 80,
						height: this.props.resolution === RESOLUTION.HIGH ? 58 : 40,
					}}
					buttonStyle={{
						width: this.props.resolution === RESOLUTION.HIGH ? 120 : 80,
						height: this.props.resolution === RESOLUTION.HIGH ? 60 : 40,
						justifyContent: "center",
					}}
					width={this.props.resolution === RESOLUTION.HIGH ? 360 : 240}
					height={this.props.resolution === RESOLUTION.HIGH ? 45 : 30}
					containerHeight={50}
				/>
			</View>
		);
	}

	renderInfo() {
		var _infoStyle = [styles.dataText, styles.infoText];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_infoStyle = [styles.dataText, styles.infoText, { fontSize: 24, lineHeight: 30 }];
		}
		return (
			<View
				style={{
					width: "100%",
					paddingHorizontal: this.props.resolution === RESOLUTION.LOW ? 20 : 40,
				}}
			>
				<Text allowFontScaling={false} style={_infoStyle}>
					{this.props.lang.screenPreferences.info}
				</Text>
			</View>
		);
	}

	renderContent() {
		return (
			<View
				style={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				{this.renderResolutionField()}
				{this.renderInfo()}
				{this.renderUpdateButton()}
			</View>
		);
	}

	render() {
		const processInProgress = this.props.updateResolutionState == processState.STARTED;
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				{processInProgress && <Spinner size="large" />}
				{!processInProgress && <View style={styles.container}>{this.renderContent()}</View>}
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (
				updatePersonalDataDimensions.width > availableWidth ||
				updatePersonalDataDimensions.height > availableHeight
			) {
				const _scaleX = Math.round((availableWidth / updatePersonalDataDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / updatePersonalDataDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: { flex: 1, padding: 20, paddingTop: 55 },
	contentContainer: {
		flex: 1,
		// paddingHorizontal: 20,
		// paddingTop: 40,
		paddingBottom: 70,
	},
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		// marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: Platform.OS === "web" ? 0 : 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: 26,
		marginTop: 20,
		marginBottom: 20,
		fontSize: 18,
	},
	switchBox: {
		width: 300,
		height: 50,
		justifyContent: "center",
		marginTop: 5,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		updateResolutionState: state.app.updateResolutionState,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeScreenPreferences,
	goBackToMain,
	updateResolution,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenResolution);
