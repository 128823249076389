import Modal from "modal-enhanced-react-native-web";
import React, { Component } from "react";
import { Dimensions, Platform, StyleSheet, Text, View } from "react-native";
import ReactNativeModal from "react-native-modal";
import { connect } from "react-redux";
import { Box, GameButton } from "../../components/common";
import { appFont } from "../../config/defaults";
import { SMALL_BUTTON } from "../../config/images";

class GamePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPosButtonHovered: false,
			isNegButtonHovered: false,
		};
	}

	//#region events
	onPosButtonPress() {
		if (typeof this.props.posButAction == "function") this.props.posButAction();
	}

	onNegButtonPress() {
		if (typeof this.props.negButAction == "function") this.props.negButAction();
	}
	//#endregion

	//#region render methods
	renderPosButton() {
		const { posButText } = this.props;
		if (typeof posButText !== "string" || posButText == "") return null;
		return (
			<GameButton
				disabled={false}
				textContent={posButText}
				textContentStyle={[styles.buttonTextStyle, styles.textOutline]}
				onPress={this.onPosButtonPress.bind(this)}
				onMouseEnter={() => this.setState({ isPosButtonHovered: true })}
				onMouseLeave={() => this.setState({ isPosButtonHovered: false })}
				myStyle={styles.buttonStyle}
				backgroundImage={{
					uri: SMALL_BUTTON,
					resizeMode: "stretch",
					style: [styles.buttonBackImgStyle, { opacity: this.state.isNegButtonHovered ? 0.8 : 1 }],
				}}
			/>
		);
	}

	renderNegButton() {
		const { negButText } = this.props;
		if (typeof negButText !== "string" || negButText == "") return null;
		return (
			<GameButton
				textContent={negButText}
				textContentStyle={[styles.buttonTextStyle, styles.textOutline]}
				onPress={this.onNegButtonPress.bind(this)}
				onMouseEnter={() => this.setState({ isNegButtonHovered: true })}
				onMouseLeave={() => this.setState({ isNegButtonHovered: false })}
				myStyle={styles.buttonStyle}
				backgroundImage={{
					uri: SMALL_BUTTON,
					resizeMode: "stretch",
					style: [styles.buttonBackImgStyle, { opacity: this.state.isNegButtonHovered ? 0.8 : 1 }],
				}}
			/>
		);
	}

	renderButton() {
		const { negButText } = this.props;
		var butContStyle = [styles.buttonContainerStyle];
		if (negButText == "") butContStyle = [styles.buttonContainerStyle, { justifyContent: "center" }];
		return (
			<View style={butContStyle}>
				{this.renderPosButton()}
				{this.renderNegButton()}
			</View>
		);
	}

	renderHelpContent() {
		const { cardHelp, ruleHelp } = this.props.lang.gameHelp;

		//replace #1# with teamMate's name in the third rule
		var rule3Text = ruleHelp.rule3;
		var teamMateName = ruleHelp.notTeam;
		if (this.props.teams == true) {
			const teamMateSlot = (this.props.sitSlotID + this.props.playerCnt / 2) % this.props.playerCnt;
			const teamMate = this.props.players["slot_" + teamMateSlot];

			if (teamMate != undefined && typeof teamMate.username === "string") {
				teamMateName = teamMate.username;
			}
		}
		rule3Text = rule3Text.replace("#1#", teamMateName);

		return (
			<View style={styles.helpContainer}>
				<View style={styles.helpColumn}>
					<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpText, styles.helpTitle]}>
						{cardHelp.mainTitle}
					</Text>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpDetTitle, styles.helpText]}>
							{cardHelp.ace.title}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{cardHelp.ace.detail}
						</Text>
					</View>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpDetTitle, styles.helpText]}>
							{cardHelp.king.title}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{cardHelp.king.detail}
						</Text>
					</View>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpDetTitle, styles.helpText]}>
							{cardHelp.jack.title}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{cardHelp.jack.detail}
						</Text>
					</View>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpDetTitle, styles.helpText]}>
							{cardHelp.seven.title}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{cardHelp.seven.detail}
						</Text>
					</View>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpDetTitle, styles.helpText]}>
							{cardHelp.four.title}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{cardHelp.four.detail}
						</Text>
					</View>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpDetTitle, styles.helpText]}>
							{cardHelp.queen.title}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{cardHelp.queen.detail}
						</Text>
					</View>
				</View>
				<View style={styles.helpColumn}>
					<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpText, styles.helpTitle]}>
						{ruleHelp.mainTitle}
					</Text>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpText, styles.helpDot]}>
							{"·"}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{ruleHelp.rule1}
						</Text>
					</View>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpText, styles.helpDot]}>
							{"·"}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{ruleHelp.rule2}
						</Text>
					</View>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpText, styles.helpDot]}>
							{"·"}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{rule3Text}
						</Text>
					</View>
					<View style={styles.helpDetRow}>
						<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpText, styles.helpDot]}>
							{"·"}
						</Text>
						<Text allowFontScaling={false} style={[styles.helpDetails, styles.helpText]}>
							{ruleHelp.rule4}
						</Text>
					</View>
					<Text allowFontScaling={false} numberOfLines={1} style={[styles.helpText, styles.helpTitle]}>
						{ruleHelp.goodBye}
					</Text>
				</View>
			</View>
		);
	}

	renderModalContent() {
		return (
			<Box style={styles.boxStyle}>
				{this.renderHelpContent()}
				{this.renderButton()}
			</Box>
		);
	}

	renderModal() {
		const dimensions = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		const deviceWidth = dimensions.width;
		const deviceHeight = dimensions.height;
		if (Platform.OS === "web") {
			return (
				<Modal
					animationIn={"zoomIn"}
					animationInTiming={500}
					animationOut={"zoomOut"}
					animationOutTiming={500}
					backdropTransitionOutTiming={300}
					isVisible={this.props.visibleModal}
					deviceHeight={deviceHeight}
					deviceWidth={deviceWidth}
					onBackButtonPress={this.onPosButtonPress.bind(this)}
					onBackdropPress={this.onPosButtonPress.bind(this)}
				>
					{this.renderModalContent()}
				</Modal>
			);
		}
		return (
			<ReactNativeModal
				animationIn={"zoomIn"}
				animationInTiming={500}
				animationOut={"zoomOut"}
				animationOutTiming={500}
				backdropTransitionOutTiming={300}
				coverScreen={true}
				isVisible={this.props.visibleModal}
				deviceHeight={deviceHeight}
				deviceWidth={deviceWidth}
				useNativeDriver={true}
				onBackButtonPress={this.onPosButtonPress.bind(this)}
				onBackdropPress={this.onPosButtonPress.bind(this)}
			>
				{this.renderModalContent()}
			</ReactNativeModal>
		);
	}

	render() {
		return this.renderModal();
	}
	//#endregion
}

const styles = StyleSheet.create({
	boxStyle: {
		width: 490,
		padding: 10,
		minHeight: 50,
		alignSelf: "center",
		alignItems: "center",
	},
	helpContainer: {
		width: "100%",
		borderWidth: 0,
		flexDirection: "row",
	},
	helpColumn: {
		flex: 2,
		borderWidth: 0,
		borderColor: "red",
		paddingHorizontal: 5,
	},
	helpTitle: {
		width: "100%",
		textAlign: "center",
		marginTop: 5,
		marginBottom: 5,
		fontSize: 20,
		color: "#555",
	},
	helpDetRow: { width: "100%", flexDirection: "row" },
	helpDetTitle: { width: 60 },
	helpDetails: { flex: 1 },
	helpDot: {
		width: 15,
		fontStyle: "normal",
		fontSize: Platform.OS === "web" ? 42 : Platform.OS === "ios" ? 40 : 30,
		textAlign: "center",
		lineHeight: Platform.OS === "web" ? 18 : 28,
		color: "#555",
	},
	helpText: {
		fontFamily: appFont,
		fontSize: 16,
		fontStyle: "italic",
		color: "#555",
	},
	buttonContainerStyle: {
		width: "100%",
		flexDirection: "row",
		marginTop: 20,
		justifyContent: "space-between",
	},
	buttonStyle: {
		width: 100,
		height: 50,
		backgroundColor: "transparent",
		borderWidth: 0,
		opacity: 1,
	},
	buttonBackImgStyle: { width: 100, height: 50 },
	buttonTextStyle: {
		width: "100%",
		fontFamily: appFont,
		fontSize: 16,
		textAlign: "center",
		paddingTop: Platform.OS === "ios" ? 19 : 15,
		paddingBottom: 15,
		color: "#FFFFFF",
		textShadowColor: "#397423",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: Platform.OS !== "web" ? 0.001 : 0,
	},
	textOutline: { position: "absolute", zIndex: 0 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		teams: state.startedGame.teams,
		sitSlotID: state.startedGame.sitSlotID,
		playerCnt: state.startedGame.playerCnt,
		players: state.startedGame.players,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GamePopup);
