import React from "react";
import { StyleSheet, View } from "react-native";

const Separator = () => {
	return <View style={styles.separator} />;
};

const styles = StyleSheet.create({
	separator: {
		width: "100%",
		height: 5,
		backgroundColor: "#F9FAFA",
		marginBottom: 5,
	},
});

export { Separator };
