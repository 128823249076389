import React, { Component } from "react";
import { Dimensions, ScrollView, StyleSheet, Text, View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansproregular,
	f_sourcesansprosemibold,
} from "../../../../config/defaults";
import { emailTroubleDimensions, gameDimensions } from "../../../../config/dimensions";
import { closeRateForSilver } from "../../../../redux/actions";
import { ColoredButton } from "../../../common";
import { handleError } from "../../../ErrorHandler";

class RateDone extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
		};
	}

	//#region render methods
	renderEarnMoreButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.props.closeRateForSilver}
					textContent={this.props.lang.gameShop.rateSomething.rateDone.earnMore}
					accessibilityLabel={this.props.lang.gameShop.rateSomething.rateDone.earnMore}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderContent() {
		try {
			var main_text = this.props.lang.gameShop.rateSomething.rateDone.content.replace("#silverAmount#", 500);

			const regex = /_/gi;
			var main_text_i = main_text.indexOf("_");
			var main_text_j = main_text.lastIndexOf("_");
			if (main_text_i != -1 && main_text_j != -1) var linkMainText = main_text.substring(main_text_i, ++main_text_j);
			if (linkMainText != undefined) var mainTextLink = linkMainText.replace(regex, "");
			return (
				<View style={{ flex: 1 }}>
					<View style={styles.titleContainer}>
						<Text allowFontScaling={false} style={styles.titleStyle}>
							{this.props.lang.gameShop.rateSomething.rateDone.title}
						</Text>
					</View>
					<ScrollView
						style={[
							styles.sentContentContainer,
							{
								height: Dimensions.get("window").height - 40,
							},
						]}
					>
						{mainTextLink != "" && (
							<ParsedText
								style={styles.contentText}
								parse={[
									{
										pattern: new RegExp("_" + mainTextLink + "_"),
										style: styles.linkText,
										onPress: () => {},
										renderText: () => mainTextLink,
									},
								]}
							>
								{main_text}
							</ParsedText>
						)}
						{mainTextLink == "" && (
							<Text allowFontScaling={false} style={[styles.contentText]}>
								{main_text}
							</Text>
						)}
					</ScrollView>
					{this.renderEarnMoreButton()}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	render() {
		return <View style={styles.container}>{this.renderContent()}</View>;
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (emailTroubleDimensions.width > availableWidth || emailTroubleDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / emailTroubleDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / emailTroubleDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 10,
		marginHorizontal: 30,
	},
	buttonContainer: { alignSelf: "flex-end", marginRight: 20 },
	titleContainer: { width: "85%", height: 50, alignSelf: "center" },
	titleStyle: {
		width: "100%",
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 28,
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
		textAlign: "center",
	},
	sentContentContainer: { paddingHorizontal: 50, marginVertical: 5 },
	contentText: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansproregular,
		fontSize: 18,
		color: "#FFFFFF",
		marginTop: 20,
	},
	linkText: {
		color: "#00CDD8",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
		fontFamily: f_sourcesansprosemibold,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
	};
};

const mapDispatchToProps = { closeRateForSilver };

export default connect(mapStateToProps, mapDispatchToProps)(RateDone);
