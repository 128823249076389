import { cloneDeep } from "lodash";
import { surveys } from "../../config/questionnaire";
import { GO_BACK_TO_MAIN, OPEN_SHOP_TAB, RESET_CLIENT } from "../actions/actionTypes";
import { CLOSE_QUIESTIONNAIRE, OPEN_QUIESTIONNAIRE, PREPARE_SURVEY, SAVE_ANSWER } from "../actionTypes";

const initialState = {
	visibleSurvey: false,
	survey: [],
	surveyAnswers: [],
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case OPEN_QUIESTIONNAIRE:
			return { ...state, visibleSurvey: true };
		case PREPARE_SURVEY:
			if (typeof payload.surveyID === "undefined" || typeof surveys[payload.surveyID] === "undefined") return state;
			let survey = [];
			surveys[payload.surveyID].forEach((q) => {
				if (typeof payload.lang.questionnaire[q] !== "undefined") {
					survey.push(payload.lang.questionnaire[q]);
					survey[survey.length - 1].questionID = q;
				}
			});
			return { ...state, survey: survey };
		case SAVE_ANSWER:
			if (
				typeof payload === "undefined" ||
				typeof payload.questionID === "undefined" ||
				typeof payload.answerID === "undefined"
			)
				return state;
			let newAnswers = cloneDeep(state.surveyAnswers);
			var foundQuestion = newAnswers.find((e) => e.questionID == payload.questionID);
			if (foundQuestion) {
				foundQuestion.answerID = payload.answerID;
			} else {
				newAnswers.push({
					questionID: payload.questionID,
					answerID: payload.answerID,
				});
			}
			return { ...state, surveyAnswers: newAnswers };
		case CLOSE_QUIESTIONNAIRE:
		case GO_BACK_TO_MAIN:
		case OPEN_SHOP_TAB:
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
