import cloneDeep from "lodash/cloneDeep";
import { defaultCardsetID, maxExistingUserRating } from "../../config/defaults";
import { playAgainstComputerForChips, useClassicTheme } from "../../config/permissions";
import * as helpers from "../../helpers/commonHelpers";
import { gameBoardTheme } from "../../Keezers/config/defaults";
import {
	CHANGE_GAME_NAME,
	CHECK_IF_CAN_START_GAME,
	MOUSE_ENTER_NEW_GAMES_SLOT,
	MOUSE_LEAVE_NEW_GAMES_SLOT,
	PREPARE_NEW_GAMES_RULES_LIST,
	PREPARE_PUBLISH_GAME_JSON,
	RESET_CLIENT,
	RESET_CREATE_NEW_GAME,
	SAVE_AVAILABLE_BOTS,
	SELECT_NEW_GAMES_SLOT,
	SET_AD_UNLOCK_OPTION,
	SET_USER_AS_GAME_CREATOR,
	UNLOCK_CREATE_GAME_FEATURES,
	UPDATE_NATIVE_SWITCH_RULE,
	UPDATE_NEW_GAMES_CARDSET,
	UPDATE_NEW_GAMES_PLAYERS,
	UPDATE_NEW_GAMES_PLAYER_AMOUNT,
	UPDATE_NEW_GAMES_PLAYER_LEVEL,
	UPDATE_NEW_GAMES_SLOTS,
	UPDATE_NEW_GAMES_THEME,
	UPDATE_PLAYER_AMOUNT_OPTIONS,
	UPDATE_REQUIRED_CHIPS,
	UPDATE_SWITCHBOX_RULE,
} from "../actions/actionTypes";

const initialState = {
	loadingNewGame: true,
	isGameCreator: false,
	userTeamChoice: true,
	selectedSlotID: -1,
	canStartGame: false,
	newGamesRulesList: [],
	players: [],
	playerAmount_options: [],
	availableBots: [],
	publishGameJSON: {
		sMessageID: 0,
		type: "publishGame",
		gameID: 0,
		name: "",
		minRating: 0,
		maxRating: maxExistingUserRating,
		playerCnt: 4,
		teamsEnabled: true,
		fourRule: true,
		sevenRule: false,
		jackRule: true,
		localCountryOnly: false,
		localLanguageOnly: false,
		friendsOnly: false,
		chipAmount: 0,
		slots: [],
		cardset: defaultCardsetID,
		gameTheme: useClassicTheme === true ? gameBoardTheme.CLASSIC : gameBoardTheme.MODERN,
		enableFairDealing: true,
		isPrivateGame: false,
	},
	adUnlock: {
		adUnlockOptionEnabled: false,
		adProvider: null,
		adIDRemote: null,
		adID: "0",
		adKey: null,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SET_AD_UNLOCK_OPTION:
			if (
				typeof payload === "undefined" ||
				typeof payload.data === "undefined" ||
				typeof payload.data.generic === "undefined"
			)
				return state;
			return {
				...state,
				adUnlock: {
					...state.adUnlock,
					adUnlockOptionEnabled: payload.data.generic.adUnlockOptionEnabled,
					adProvider: payload.data.generic.adProvider,
					adIDRemote: payload.data.generic.adIDRemote,
					adID: payload.data.generic.adID,
					adKey: payload.data.generic.adKey,
				},
			};
		case SAVE_AVAILABLE_BOTS:
			if (typeof payload === "undefined" || !Array.isArray(payload.bots) || payload.bots.length == 0) return state;
			return {
				...state,
				availableBots: payload.bots[0].type ? payload.bots : state.availableBots,
			};
		case PREPARE_PUBLISH_GAME_JSON:
			if (
				!payload ||
				// payload.type != "gameCreated" ||
				payload.type != "gameDetails" ||
				typeof payload.userDetails === "undefined"
			)
				return state;

			var firstSlot = [];
			firstSlot.push({
				userID: payload.userDetails.userID,
				slotID: 0,
				username: payload.userDetails.username,
				country: payload.userDetails.country,
				wins: payload.userDetails.wins,
				losses: payload.userDetails.losses,
				disconnects: payload.userDetails.disconnects,
				ranking: payload.userDetails.ranking,
				points: payload.userDetails.points,
				gamesPlayed: payload.userDetails.gamesPlayed,
				teamName: "",
				isHovered: false,
				isSelected: false,
				isVip: payload.userDetails.isVip,
				curDate: Date.now(),
				state: "OCCUPIED",
			});
			return {
				...state,
				loadingNewGame: false,
				players: firstSlot,
				publishGameJSON: {
					...state.publishGameJSON,
					gameID: payload.data.generic.gameID || state.gameID,
					name: payload.data.generic.name || state.name,
					// players: payload.defaultPlayerCnt || state.players,
					// teams:
					// payload.defaultPlayerCnt % 2 == 0 && payload.defaultPlayerCnt != 2,
					/* fourRule:
            typeof payload.fourRuleOn === "boolean"
              ? payload.fourRuleOn
              : state.fourRule,
          sevenRule:
            typeof payload.sevenRuleOn === "boolean"
              ? payload.sevenRuleOn
              : state.sevenRule,
          jackRule:
            typeof payload.jackRuleOne === "boolean"
              ? payload.jackRuleOne
              : state.jackRule, */
					cardset: payload.data.specific.defaultCardset || state.cardset,
				},
			};
		case UPDATE_NEW_GAMES_SLOTS:
			var updatedSlots = {};
			updatedSlots = helpers.getNewGamesUpdatedSlots(
				state.publishGameJSON.slots,
				state.players,
				state.publishGameJSON.playerCnt,
				state.publishGameJSON.teamsEnabled,
				payload.open_lang,
				payload.team_lang
			);
			if (updatedSlots === undefined) return state;
			var openSlot = updatedSlots.slots.findIndex((obj) => obj.state == "OPEN");
			return {
				...state,
				canStartGame: openSlot == -1,
				players: updatedSlots.players,
				publishGameJSON: {
					...state.publishGameJSON,
					slots: updatedSlots.slots,
				},
			};
		case CHECK_IF_CAN_START_GAME:
			var selSlot = state.publishGameJSON.slots.findIndex((obj) => obj.state == "OPEN");
			return { ...state, canStartGame: selSlot == -1 };
		case UPDATE_NEW_GAMES_PLAYERS:
			const { selectedSlotID, selectedPlayerItem, lang } = payload;
			if (
				typeof selectedSlotID === "undefined" ||
				typeof selectedPlayerItem === "undefined" ||
				selectedSlotID < 1 ||
				selectedSlotID > state.publishGameJSON.slots.length
			)
				return state;
			var newPlayers = [...state.players];
			var selPlayer = newPlayers.find((obj) => obj.slotID == selectedSlotID);
			var newSlotsToPublish = [...state.publishGameJSON.slots];
			var selSlot = newSlotsToPublish.find((obj) => obj.slotID == selectedSlotID);
			var plao = [...state.playerAmount_options];
			var newRulesList = [...state.newGamesRulesList];
			switch (selectedPlayerItem.slotType) {
				case "COMPUTER":
					if (
						state.availableBots[selectedPlayerItem.botID] == undefined ||
						state.availableBots[selectedPlayerItem.botID].type == undefined
					)
						return state;

					//current slot and player
					selPlayer.username = selectedPlayerItem.label;
					selPlayer.ranking = selectedPlayerItem.strength;
					selPlayer.botImage = selectedPlayerItem.botImage;
					selSlot.state = selectedPlayerItem.state;
					selSlot.type = state.availableBots[selectedPlayerItem.botID].type;

					var occupiedSlots = newSlotsToPublish.filter((e) => e.state != "OPEN");
					var computersNR = occupiedSlots.length;
					if (computersNR != 0) {
						//other slots and players
						occupiedSlots.forEach((sl, ind) => {
							var otherPlayer = newPlayers.find((e) => e.slotID == occupiedSlots[ind].slotID);
							otherPlayer.username = selectedPlayerItem.label;
							otherPlayer.ranking = selectedPlayerItem.strength;
							otherPlayer.botImage = selectedPlayerItem.botImage;
							occupiedSlots[ind].state = selectedPlayerItem.state;
							occupiedSlots[ind].type = state.availableBots[selectedPlayerItem.botID].type;
						});
					}
					break;
				case "HUMAN":
					if (selectedPlayerItem.label == lang.openSlot) {
						selPlayer.username = lang.openSlot;
						// selSlot.botID = selectedPlayerItem.botID;
						selSlot.state = selectedPlayerItem.state;
						// selSlot.strength = selectedPlayerItem.strength;
						selPlayer.ranking = selectedPlayerItem.strength;
						// selSlot.type = selectedPlayerItem.slotType;
						if (selSlot.type) delete selSlot.type;
						if (selPlayer.botImage) delete selPlayer.botImage;
					}
					if (selectedPlayerItem.label == lang.closed && state.publishGameJSON.playerCnt > 2) {
						newPlayers.splice(selectedSlotID, 1);
						newSlotsToPublish.splice(selectedSlotID - 1, 1);
						for (var i = 0; i < newPlayers.length; i++) {
							newPlayers[i].slotID = i;
						}
						for (var i = 0; i < newSlotsToPublish.length; i++) {
							newSlotsToPublish[i].slotID = i + 1;
						}
						//teams rule
						var searchedRule = newRulesList.find((obj) => obj.ruleName == "enableTeams");
						var ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "enableTeams");
						var modifiedRule = cloneDeep(searchedRule);
						if (typeof modifiedRule !== "undefined") {
							modifiedRule.checkedState = state.userTeamChoice && newPlayers.length % 2 == 0 && newPlayers.length != 2;
							modifiedRule.enabled = newPlayers.length % 2 == 0 && newPlayers.length != 2;
							newRulesList.splice(ruleIndex, 1, modifiedRule);
						}
						//playerAmount rule
						searchedRule = newRulesList.find((obj) => obj.ruleName == "amountOfPlayers");
						ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "amountOfPlayers");
						modifiedRule = cloneDeep(searchedRule);
						if (typeof modifiedRule !== "undefined") {
							modifiedRule.activeOptionId = maxEnabledPlayerAmount;
							newRulesList.splice(ruleIndex, 1, modifiedRule);
							var selectedOption = plao.find((obj) => obj.selected);
							selectedOption.selected = false;
							var optionToSelect = plao.find((obj) => obj.label == newPlayers.length.toString());
							optionToSelect.selected = true;
						}
					}
					break;
			}
			var openSlot = newSlotsToPublish.findIndex((obj) => obj.state == "OPEN");
			return {
				...state,
				canStartGame: openSlot == -1,
				newGamesRulesList: newRulesList,
				playerAmount_options: plao,
				publishGameJSON: {
					...state.publishGameJSON,
					slots: newSlotsToPublish,
					playerCnt: newPlayers.length,
					teamsEnabled: newPlayers.length % 2 == 0 && newPlayers.length != 2,
				},
				players: newPlayers,
			};
		case PREPARE_NEW_GAMES_RULES_LIST:
			if (typeof payload.gameDetailsData == undefined) return state;
			const { gameOptions, miniHelp, yes, no, min, max, cardsets } = payload.lang;
			const { enableFiveOrMorePlayersGame, features } = payload;
			const { specific, generic } = features;
			var newPAO = [
				{
					value: "pa_2",
					label: "2",
					enabled: features.GAME_PLAY_CAN_PLAY_WITH_2_PLAYERS,
					visible: true,
				},
				{
					value: "pa_3",
					label: "3",
					enabled: features.GAME_PLAY_CAN_PLAY_WITH_3_PLAYERS,
					visible: true,
				},
				{
					value: "pa_4",
					label: "4",
					enabled: features.GAME_PLAY_CAN_PLAY_WITH_4_PLAYERS,
					visible: true,
				},
				{
					value: "pa_5",
					label: "5",
					enabled: features.GAME_PLAY_CAN_PLAY_WITH_5_PLAYERS,
					visible: enableFiveOrMorePlayersGame && features.GAME_PLAY_CAN_PLAY_WITH_5_PLAYERS,
				},
				{
					value: "pa_6",
					label: "6",
					enabled: features.GAME_PLAY_CAN_PLAY_WITH_6_PLAYERS,
					visible: enableFiveOrMorePlayersGame && features.GAME_PLAY_CAN_PLAY_WITH_6_PLAYERS,
				},
				{
					value: "pa_7",
					label: "7",
					enabled: features.GAME_PLAY_CAN_PLAY_WITH_7_PLAYERS,
					visible: enableFiveOrMorePlayersGame && features.GAME_PLAY_CAN_PLAY_WITH_7_PLAYERS,
				},
				{
					value: "pa_8",
					label: "8",
					enabled: features.GAME_PLAY_CAN_PLAY_WITH_8_PLAYERS,
					visible: enableFiveOrMorePlayersGame && features.GAME_PLAY_CAN_PLAY_WITH_8_PLAYERS,
				},
			];
			var _availableCardsets = {};
			if (payload.gameDetailsData != undefined) {
				payload.gameDetailsData.specific.availableCardsets.forEach((cs) => {
					_availableCardsets[cs] = {
						cardsetID: cs,
						name: cardsets[cs].name,
					};
				});
			}
			var _gameThemes = {};
			helpers.mapObject(gameBoardTheme, (key, val) => {
				_gameThemes[key] = {
					gameThemeID: key,
					name: val[0].toUpperCase() + val.slice(1).toLowerCase(),
				};
			});
			var rulesList = [
				{
					ruleID: 1,
					ruleName: "gameName",
					displayHelpText: false,
					label: gameOptions.gameName,
					helpText: miniHelp.gameName,
					placeHolder: gameOptions.gameName,
					content: state.publishGameJSON.name,
					enabled: generic.canSetName,
				},
				{
					ruleID: 2,
					ruleName: "onlyFriends",
					displayHelpText: false,
					checkedState: state.publishGameJSON.friendsOnly,
					label: gameOptions.onlyFriends,
					helpText: miniHelp.onlyFriends,
					enabled: generic.canSetFriendsMode,
				},
				{
					ruleID: 3,
					ruleName: "localLanguagesOnly",
					displayHelpText: false,
					checkedState: state.publishGameJSON.localLanguageOnly,
					label: gameOptions.localLanguagesOnly,
					helpText: miniHelp.localLanguagesOnly,
					enabled: generic.canSetLanguageMode,
				},
				{
					ruleID: 4,
					ruleName: "localCountryOnly",
					displayHelpText: false,
					checkedState: state.publishGameJSON.localCountryOnly,
					label: gameOptions.localCountryOnly,
					helpText: miniHelp.localCountryOnly,
					enabled: generic.canSetCountryMode,
				},
				{
					ruleID: 5,
					ruleName: "playForChips",
					displayHelpText: false,
					checkedState: false,
					label: gameOptions.playForChips,
					helpText: miniHelp.playForChips,
					enabled: generic.canSetChips,
				},
				{
					ruleID: 6,
					ruleName: "playForChipsAmount",
					displayHelpText: false,
					checkedState: false,
					label: gameOptions.playForChipsAmount,
					helpText: miniHelp.playForChips,
					value: state.publishGameJSON.chipAmount,
					enabled: generic.canSetChips,
				},
				{
					ruleID: 7,
					ruleName: "privateGame",
					displayHelpText: false,
					checkedState: state.publishGameJSON.isPrivateGame,
					label: gameOptions.privateGame,
					helpText: miniHelp.privateGame,
					enabled: specific.canSetPrivateGame,
				},
				{
					ruleID: 8,
					ruleName: "fairDeal",
					displayHelpText: false,
					checkedState: state.publishGameJSON.enableFairDealing,
					label: gameOptions.fairDeal,
					helpText: miniHelp.fairDeal,
					enabled: specific.canSetFairDealing,
				},
				{
					ruleID: 9,
					ruleName: "enableTeams",
					displayHelpText: false,
					checkedState: state.publishGameJSON.teamsEnabled,
					label: gameOptions.enableTeams,
					helpText: miniHelp.enableTeams,
					enabled:
						specific.canSetTeams && state.publishGameJSON.playerCnt != 2 && state.publishGameJSON.playerCnt % 2 == 0,
				},
				{
					ruleID: 10,
					ruleName: "minLevel",
					displayHelpText: false,
					label: gameOptions.minRating,
					helpText: miniHelp.minRating,
					value: state.publishGameJSON.minRating,
					minVal: 0,
					maxVal: state.players[0].ranking == undefined ? 1 : state.players[0].ranking,
					enabled: generic.canSetPlayerLevel,
				},
				{
					ruleID: 11,
					ruleName: "maxLevel",
					displayHelpText: false,
					label: gameOptions.maxRating,
					helpText: miniHelp.maxRating,
					value: state.publishGameJSON.maxRating,
					minVal: state.players[0].ranking == undefined ? 1 : state.players[0].ranking,
					maxVal: maxExistingUserRating,
					enabled: generic.canSetPlayerLevel,
				},
				{
					ruleID: 12,
					ruleName: "cardset",
					displayHelpText: false,
					label: gameOptions.cardset,
					helpText: miniHelp.cardset,
					activeOptionId: payload.gameDetailsData.specific.defaultCardset,
					cardsets: _availableCardsets,
					enabled: specific.canSetCardsets,
				},
				{
					ruleID: 13,
					ruleName: "gameTheme",
					displayHelpText: false,
					label: gameOptions.gameTheme,
					helpText: miniHelp.gameTheme,
					activeOptionId: useClassicTheme === true ? gameBoardTheme.CLASSIC : gameBoardTheme.MODERN,
					themes: _gameThemes,
					enabled: specific.canSetGameTheme || payload.isVip,
				},
			];
			return {
				...state,
				newGamesRulesList: rulesList,
				playerAmount_options: newPAO,
			};
		case UNLOCK_CREATE_GAME_FEATURES:
			var newRulesList = cloneDeep(state.newGamesRulesList);
			newRulesList.forEach((r) => (r.enabled = true));
			return { ...state, newGamesRulesList: newRulesList };
		case UPDATE_PLAYER_AMOUNT_OPTIONS:
			if (state.newGamesRulesList.length == 0) return state;
			var plao = [...state.playerAmount_options];
			plao.map((obj) => (obj.visible = payload.enableFiveOrMorePlayersGame || Number(obj.label) <= 4));
			if (plao == state.playerAmount_options) return state;

			var maxEnabledPlayerAmount = 1;
			plao.forEach((el) => {
				const nrpa = Number(el.label);
				if (el.enabled && el.visible && nrpa != maxEnabledPlayerAmount) maxEnabledPlayerAmount = nrpa;
			});

			if (
				!payload.enableFiveOrMorePlayersGame &&
				state.publishGameJSON.playerCnt > 4 &&
				state.publishGameJSON.playerCnt != maxEnabledPlayerAmount
			) {
				var newRulesList = [...state.newGamesRulesList];
				var newPublishGameJSON = { ...state.publishGameJSON };
				newPublishGameJSON.playerCnt = maxEnabledPlayerAmount;
				newPublishGameJSON.teamsEnabled = maxEnabledPlayerAmount % 2 == 0 && maxEnabledPlayerAmount != 2;
				//teams rule
				var searchedRule = newRulesList.find((obj) => obj.ruleName == "enableTeams");
				var ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "enableTeams");
				var modifiedRule = cloneDeep(searchedRule);
				if (typeof modifiedRule !== "undefined") {
					modifiedRule.checkedState =
						state.userTeamChoice && newPublishGameJSON.playerCnt % 2 == 0 && newPublishGameJSON.playerCnt != 2;
					modifiedRule.enabled = newPublishGameJSON.playerCnt % 2 == 0 && newPublishGameJSON.playerCnt != 2;
					newRulesList.splice(ruleIndex, 1, modifiedRule);
				}
				//playerAmount rule
				searchedRule = newRulesList.find((obj) => obj.ruleName == "amountOfPlayers");
				ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "amountOfPlayers");
				modifiedRule = cloneDeep(searchedRule);
				if (typeof modifiedRule !== "undefined") {
					modifiedRule.activeOptionId = maxEnabledPlayerAmount;
					newRulesList.splice(ruleIndex, 1, modifiedRule);
					var selectedOption = plao.find((obj) => obj.selected);
					selectedOption.selected = false;
					var optionToSelect = plao.find((obj) => obj.label == maxEnabledPlayerAmount.toString());
					optionToSelect.selected = true;
				}
				var openSlot = newPublishGameJSON.slots.findIndex((obj) => obj.state == "OPEN");
				return {
					...state,
					canStartGame: openSlot == -1,
					playerAmount_options: plao,
					newGamesRulesList: newRulesList,
					publishGameJSON: newPublishGameJSON,
				};
			}
			return { ...state, playerAmount_options: plao };
		case UPDATE_SWITCHBOX_RULE:
			if (
				typeof payload.group === "undefined" ||
				typeof payload.val === "undefined" ||
				typeof payload.isChecked === "undefined"
			)
				return state;
			const { group, val, isChecked } = payload;
			var newRulesList = [...state.newGamesRulesList];
			var searchedRule = newRulesList.find((obj) => obj.ruleName == group);
			var ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == group);
			if (searchedRule == undefined) return state;
			var modifiedRule = cloneDeep(searchedRule);
			modifiedRule.checkedState = isChecked;
			newRulesList.splice(ruleIndex, 1, modifiedRule);
			var newPublishGameJSON = { ...state.publishGameJSON };
			switch (group) {
				case "enableTeams":
					modifiedRule.checkedState = newPublishGameJSON.teamsEnabled;
					break;
				case "localLanguagesOnly":
					newPublishGameJSON.localLanguageOnly = modifiedRule.checkedState[0];
					break;
				case "onlyFriends":
					newPublishGameJSON.friendsOnly = modifiedRule.checkedState[0];
					break;
				case "fairDeal":
					newPublishGameJSON.enableFairDealing = modifiedRule.checkedState[0];
					break;
				case "privateGame":
					newPublishGameJSON.isPrivateGame = modifiedRule.checkedState[0];
					break;
			}
			return {
				...state,
				newGamesRulesList: newRulesList,
				publishGameJSON: newPublishGameJSON,
			};
		case UPDATE_REQUIRED_CHIPS:
			var newRulesList = [...state.newGamesRulesList];
			var searchedRule = newRulesList.find(
				(obj) => obj.ruleName == "chipsRequired" || obj.ruleName == "playForChipsAmount"
			);
			var ruleIndex = newRulesList.findIndex(
				(obj) => obj.ruleName == "chipsRequired" || obj.ruleName == "playForChipsAmount"
			);
			var modifiedRule = cloneDeep(searchedRule);
			modifiedRule.value = payload.newValue;
			newRulesList.splice(ruleIndex, 1, modifiedRule);
			var newPublishGameJSON = { ...state.publishGameJSON };
			newPublishGameJSON.chipAmount = payload.newValue;
			if (payload.newValue > 0 && typeof playAgainstComputerForChips === "boolean" && !playAgainstComputerForChips) {
				var newPlayers = [...state.players];
				newPlayers.forEach((obj) => {
					if (
						typeof obj.userID === "string" &&
						obj.userID.startsWith("generated") &&
						typeof obj.ranking === "string" &&
						obj.ranking !== ""
					) {
						obj.ranking = "";
						obj.username = payload.lang.openSlot;
					}
				});
				newPublishGameJSON.slots.forEach((obj) => {
					if (obj.type == "COMPUTER" && obj.state == "OCCUPIED") {
						obj.type = "HUMAN";
						obj.state = "OPEN";
						// obj.strength = "";
						// obj.botID = 0;
					}
				});
			}
			var openSlot = newPublishGameJSON.slots.findIndex((obj) => obj.state == "OPEN");
			return {
				...state,
				canStartGame: openSlot == -1,
				players: typeof newPlayers !== "undefined" ? newPlayers : state.players,
				newGamesRulesList: newRulesList,
				publishGameJSON: newPublishGameJSON,
			};
		case UPDATE_NEW_GAMES_PLAYER_AMOUNT:
			if (typeof payload !== "number" || payload < 2 || payload > 8 || payload == state.publishGameJSON.players)
				return state;
			var newPublishGameJSON = { ...state.publishGameJSON };
			newPublishGameJSON.playerCnt = payload;
			newPublishGameJSON.teamsEnabled = payload % 2 == 0 && payload != 2 && state.userTeamChoice;
			//teams rule
			var newRulesList = [...state.newGamesRulesList];
			var searchedRule = newRulesList.find((obj) => obj.ruleName == "enableTeams");
			var ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "enableTeams");
			var modifiedRule = cloneDeep(searchedRule);
			if (typeof modifiedRule !== "undefined") {
				modifiedRule.checkedState =
					state.userTeamChoice && newPublishGameJSON.playerCnt % 2 == 0 && newPublishGameJSON.playerCnt != 2;
				modifiedRule.enabled = newPublishGameJSON.playerCnt % 2 == 0 && newPublishGameJSON.playerCnt != 2;
				newRulesList.splice(ruleIndex, 1, modifiedRule);
			}
			//playerAmount rule
			searchedRule = newRulesList.find((obj) => obj.ruleName == "amountOfPlayers");
			ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "amountOfPlayers");
			modifiedRule = cloneDeep(searchedRule);
			if (modifiedRule !== undefined) {
				modifiedRule.activeOptionId = payload;
				newRulesList.splice(ruleIndex, 1, modifiedRule);
			}
			var openSlot = newPublishGameJSON.slots.findIndex((obj) => obj.state == "OPEN");
			return {
				...state,
				canStartGame: openSlot == -1,
				newGamesRulesList: newRulesList,
				publishGameJSON: newPublishGameJSON,
			};
		case UPDATE_NEW_GAMES_PLAYER_LEVEL:
			var newPublishGameJSON = { ...state.publishGameJSON };
			var { rule, value } = payload;
			if (typeof value !== "number" || (rule != "minLevel" && rule != "maxLevel")) return state;

			//needed switch statement because minLevel and maxLevel fields are renamed to minRating and maxRating
			switch (rule) {
				case "minLevel":
					newPublishGameJSON.minRating = value;
					break;
				case "maxLevel":
					newPublishGameJSON.maxRating = value;
					break;
				default:
					newPublishGameJSON[rule] = value;
					break;
			}
			var newRulesList = [...state.newGamesRulesList];
			var searchedRule = newRulesList.find((obj) => obj.ruleName == rule);
			var ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == rule);
			var modifiedRule = cloneDeep(searchedRule);
			modifiedRule.value = value;
			newRulesList.splice(ruleIndex, 1, modifiedRule);
			return {
				...state,
				newGamesRulesList: newRulesList,
				publishGameJSON: newPublishGameJSON,
			};
		case UPDATE_NEW_GAMES_CARDSET:
			if (typeof payload === "undefined") return { ...state };
			var newRulesList = [...state.newGamesRulesList];
			var searchedRule = newRulesList.find((obj) => obj.ruleName == "cardset");
			var ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "cardset");
			var modifiedRule = cloneDeep(searchedRule);
			modifiedRule.activeOptionId = payload;
			newRulesList.splice(ruleIndex, 1, modifiedRule);
			return {
				...state,
				newGamesRulesList: newRulesList,
				publishGameJSON: { ...state.publishGameJSON, cardset: payload },
			};
		case UPDATE_NEW_GAMES_THEME:
			if (typeof payload === "undefined") return { ...state };
			var newRulesList = [...state.newGamesRulesList];
			var searchedRule = newRulesList.find((obj) => obj.ruleName == "gameTheme");
			var ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "gameTheme");
			var modifiedRule = cloneDeep(searchedRule);
			modifiedRule.activeOptionId = payload;
			newRulesList.splice(ruleIndex, 1, modifiedRule);
			return {
				...state,
				newGamesRulesList: newRulesList,
				publishGameJSON: { ...state.publishGameJSON, gameTheme: payload },
			};
		case SELECT_NEW_GAMES_SLOT:
			var newSelSlotID = initialState.selectedSlotID;
			var playerList = [...state.players];
			playerList.map((obj) => (obj.isSelected = false));
			var selectedPlayer = playerList.find((obj) => obj.slotID == payload);
			selectedPlayer.isSelected = true;
			newSelSlotID = selectedPlayer.slotID;
			return { ...state, selectedSlotID: newSelSlotID, players: playerList };
		case MOUSE_ENTER_NEW_GAMES_SLOT:
			var playerList = [...state.players];
			playerList.map((obj) => (obj.isHovered = false));
			var hoveredSlot = playerList.find((obj) => obj.slotID == payload);
			hoveredSlot.isHovered = true;
			return { ...state, players: playerList };
		case MOUSE_LEAVE_NEW_GAMES_SLOT:
			var playerList = [...state.players];
			var hoveredGame = playerList.find((obj) => obj.slotID == payload);
			hoveredGame.isHovered = false;
			return { ...state, players: playerList };
		case SET_USER_AS_GAME_CREATOR:
			return { ...state, isGameCreator: true };
		case CHANGE_GAME_NAME:
			var newPublishGameJSON = { ...state.publishGameJSON, name: payload };
			var newRulesList = [...state.newGamesRulesList];
			var searchedRule = newRulesList.find((obj) => obj.ruleName == "gameName");
			var ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "gameName");
			var modifiedRule = cloneDeep(searchedRule);
			modifiedRule.content = payload;
			newRulesList.splice(ruleIndex, 1, modifiedRule);
			return {
				...state,
				newGamesRulesList: newRulesList,
				publishGameJSON: newPublishGameJSON,
			};
		case UPDATE_NATIVE_SWITCH_RULE:
			if (typeof payload.group === "undefined" || typeof payload.val === "undefined") return state;
			var newRulesList = [...state.newGamesRulesList];
			var searchedRule = newRulesList.find((obj) => obj.ruleName == payload.group);
			var ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == payload.group);
			var modifiedRule = cloneDeep(searchedRule);
			modifiedRule.checkedState = payload.val;
			newRulesList.splice(ruleIndex, 1, modifiedRule);
			var newPublishGameJSON = { ...state.publishGameJSON };
			var userTeamChoice = state.userTeamChoice;
			switch (payload.group) {
				case "enableTeams":
					newPublishGameJSON.teamsEnabled =
						payload.userChoice == true ? modifiedRule.checkedState : state.userTeamChoice;
					userTeamChoice = payload.userChoice == true ? modifiedRule.checkedState : userTeamChoice;
					break;
				case "localLanguagesOnly":
					newPublishGameJSON.localLanguageOnly = modifiedRule.checkedState;
					break;
				case "localCountryOnly":
					newPublishGameJSON.localCountryOnly = modifiedRule.checkedState;
					break;
				case "playForChips":
					searchedRule = newRulesList.find((obj) => obj.ruleName == "playForChipsAmount");
					ruleIndex = newRulesList.findIndex((obj) => obj.ruleName == "playForChipsAmount");
					modifiedRule = cloneDeep(searchedRule);
					modifiedRule.checkedState = payload.val;
					modifiedRule.value = !payload.val ? 0 : payload.amount;
					newPublishGameJSON.chipAmount = !payload.val ? 0 : payload.amount;
					newRulesList.splice(ruleIndex, 1, modifiedRule);
					break;
				case "onlyFriends":
					newPublishGameJSON.friendsOnly = modifiedRule.checkedState;
					break;
				case "fairDeal":
					newPublishGameJSON.enableFairDealing = modifiedRule.checkedState;
					break;
				case "privateGame":
					newPublishGameJSON.isPrivateGame = modifiedRule.checkedState;
					break;
			}
			return {
				...state,
				userTeamChoice: userTeamChoice,
				newGamesRulesList: newRulesList,
				publishGameJSON: newPublishGameJSON,
			};
		case RESET_CREATE_NEW_GAME:
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
