import { Platform } from "react-native";
import { updateShowRunningGamesInListSucceeded, updateSoundsPreferencesSucceeded } from ".";
import { modalType, storeKeys } from "../../config/defaults";
import { BUY_SUCCEEDED_SOUND, SAVE_SOUND } from "../../config/sounds";
import { MyWebSocket } from "../../connection";
import { storeData, clearItem } from "../../libraries/AsyncStorage";
import SecureStorage from "../../libraries/ReactSecureStorage";
import EncryptedStorage from "../../libraries/ReactNativeEncryptedStorage";
import { setAttribute, setUserID } from "../../libraries/Crashlytics/Crashlytics";
import { store } from "../store";
import {
	APP_LANGUAGE_UPDATED,
	APP_NOTIFICATIONS_UPDATED,
	CARDS_ORDER_UPDATED,
	CHAT_TRANSLATION_UPDATED,
	CLEAR_SEARCHED_USERS_LIST,
	DEFAULT_CARDSET_UPDATED,
	EMAIL_NOTIFICATIONS_UPDATED,
	FIXED_POSITIONS_UPDATED,
	HANDLE_PROFILE_SETTINGS,
	HANDLE_SETTINGS,
	OVERRIDE_CARDSET_UPDATED,
	PROCESS_PLAYER_MOD,
	PROFILE_SETTINGS_UPDATED,
	REMOVE_EMAIL_CREDENTIALS,
	REMOVE_FACEBOOK_CREDENTIALS,
	SAVE_EMAIL_PASSWORD,
	SAVE_EMAIL_USERNAME,
	SAVE_FACEBOOK_USER_DETAILS,
	SAVE_FB_TOKEN,
	SAVE_FB_UID,
	SAVE_USER_DETAILS,
	SCREEN_RESOLUTION_UPDATED,
	SELECT_DEFAULT_CARDSET,
	SELECT_DEFAULT_CARD_SORT_ORDER,
	SELECT_DEFAULT_CARD_SUIT_ORDER,
	SELECT_DEFAULT_CARD_VALUE_ORDER,
	SET_FEATURES,
	SET_USER_AS_VIP,
	SHOW_RUNNING_GAMES_IN_LIST_UPDATED,
	SOUNDS_PREFERENCES_UPDATED,
	UNLOCK_CREATE_GAME_FEATURES,
	UPDATE_BAN_LIST,
	UPDATE_FRIEND_LIST,
	UPDATE_SEARCHED_USERS_LIST,
	UPDATE_STACK,
	UPDATE_USER_OVERVIEW,
} from "./actionTypes";
import {
	closeUpdateAvatar,
	closeUpdateLocation,
	closeUpdatePersonalData,
	deleteAvatarSucceeded,
	setLoggedInState,
	updateAppLanguageSucceeded,
	updateAppNotificationsSucceeded,
	updateAvatarSucceeded,
	updateCardSortOrderSucceeded,
	updateChatTranslationSucceeded,
	updateDefaultCardsetSucceeded,
	updateEmailNotificationsSucceeded,
	updateFixedPositionsSucceeded,
	updateLanguageSucceeded,
	updateLocationSucceeded,
	updateOverrideCardsetSucceeded,
	updatePersonalDataSucceeded,
	updateResolutionSucceeded,
	updateUsernameSucceeded,
} from "./AppActions";
import { clearGameDetailsInLobby } from "./GameDetailsDataActions";
import { clearGameListModule } from "./GameListDataActions";
import { clearGlobalChat } from "./GlobalChatActions";
import { changeAppLanguage } from "./LanguageActions";
import { openModalDialog } from "./ModalActions";
import { removeSavedEvent } from "./SocketActions";
import { DEBUG_ENABLED } from "../../config/permissions";

export const updateUserOverview = (msg) => {
	return { type: UPDATE_USER_OVERVIEW, payload: msg };
};

export const saveUserDetails = (myJSON) => {
	return (dispatch) => {
		//save userID to crashlytics
		if (typeof myJSON.userID !== "undefined" && Platform.OS !== "web") {
			const uID = myJSON.userID.toString();
			setAttribute("userID", uID);
			setUserID(uID);
		}
		dispatch(_saveUserDetails(myJSON));
		dispatch(setFeatures(myJSON.features));
	};
};

export const processPlayerMod = (response) => {
	return { type: PROCESS_PLAYER_MOD, payload: response };
};

export const updateStack = (msg) => {
	if (msg && msg.soundToPlay === "SILVER_ADD") BUY_SUCCEEDED_SOUND.silver.play();
	return { type: UPDATE_STACK, payload: msg };
};

export const setFeatures = (features) => {
	return (dispatch) => {
		dispatch(_setFeatures(features));
	};
};

export const unlockCreateGameFeatures = () => {
	return { type: UNLOCK_CREATE_GAME_FEATURES };
};

export const displayPermissionDenied = (secPermissionID, permissionID = "") => {
	return (dispatch) => {
		var message;
		const lang = store.getState().language.currentLanguage;
		if (typeof permissionID === "string" && permissionID !== "") {
			const { disabledFeatureText } = store.getState().currentUser.features;
			if (
				disabledFeatureText &&
				typeof disabledFeatureText[permissionID] === "string" &&
				disabledFeatureText[permissionID] != ""
			)
				message = { main_text: disabledFeatureText[permissionID] };
		}

		if (
			(message == undefined || message == null || message.main_text == "") &&
			typeof secPermissionID === "string" &&
			secPermissionID != ""
		) {
			message =
				lang.disabledFeatureText !== undefined && typeof lang.disabledFeatureText[secPermissionID] !== "undefined"
					? lang.disabledFeatureText[secPermissionID]
					: lang.disabledFeatureText.permissionDenied;
		}

		if (message != undefined && message != null && typeof message.main_text === "string") {
			dispatch(openModalDialog(modalType.ERROR, message, lang.ok || ""));
		}
	};
};

export const saveEmailUsername = (username) => {
	return { type: SAVE_EMAIL_USERNAME, payload: username };
};

export const saveEmailPassword = (pwd) => {
	return { type: SAVE_EMAIL_PASSWORD, payload: pwd };
};

export const removeEmailCredentials = () => {
	return async (dispatch) => {
		storeData(storeKeys.EMAIL_IS_LOGGED_IN, "false");
		clearItem(storeKeys.EMAIL_USERNAME);
		// storeData(storeKeys.EMAIL_USERNAME, "");
		// storeData(storeKeys.EMAIL_PWD, "");
		try {
			if (Platform.OS === "web") {
				const pswd = SecureStorage.getItem(storeKeys.EMAIL_PWD);
				if (typeof pswd !== "undefined" && pswd !== "") {
					SecureStorage.removeItem(storeKeys.EMAIL_PWD);
				}
				// SecureStorage.setItem(storeKeys.EMAIL_PWD, "");
			} else {
				const pswd = await EncryptedStorage.getItem(storeKeys.EMAIL_PWD);
				if (typeof pswd !== "undefined" && pswd !== "") {
					await EncryptedStorage.removeItem(storeKeys.EMAIL_PWD);
				}
				// await EncryptedStorage.setItem(storeKeys.EMAIL_PWD, "");
			}
		} catch (error) {
			if (DEBUG_ENABLED) {
				console.log(error);
				console.log(error.message);
			}
		}
		dispatch({ type: REMOVE_EMAIL_CREDENTIALS });
	};
};

export const saveFacebookUID = (uid) => {
	return { type: SAVE_FB_UID, payload: uid };
};

export const saveFacebookToken = (token) => {
	return { type: SAVE_FB_TOKEN, payload: token };
};

export const saveFacebookUserDetails = (data) => {
	return { type: SAVE_FACEBOOK_USER_DETAILS, payload: data };
};

export const removeFacebookCredentials = () => {
	storeData(storeKeys.FB_IS_LOGGED_IN, "false");
	storeData(storeKeys.FB_TOKEN, "");
	storeData(storeKeys.FB_UID, "");
	storeData(storeKeys.FB_USER_DETAILS, "");
	return { type: REMOVE_FACEBOOK_CREDENTIALS };
};

export const handleProfileSettings = (msg) => {
	return { type: HANDLE_PROFILE_SETTINGS, payload: msg };
};

export const handleProfileSettingsUpdated = (msg) => {
	return (dispatch) => {
		const lang = store.getState().language.currentLanguage;
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined) {
			switch (responseTo.type) {
				case "updateUsername":
					if (typeof responseTo.username === "string") {
						dispatch(updateUsernameSucceeded());
						dispatch(saveEmailUsername(responseTo.username));
						storeData(storeKeys.EMAIL_USERNAME, responseTo.username);
					}
					break;
				case "updateLanguage":
					if (typeof responseTo.language === "string") {
						dispatch(updateLanguageSucceeded());
					}
					break;
				case "updateLocation":
					dispatch(updateLocationSucceeded());
					dispatch(closeUpdateLocation());
					/* dispatch(
            openModalDialog(
              modalType.DIALOG,
              lang.locationUpdate.locationUpdated,
              lang.ok
            )
          ); */
					SAVE_SOUND.play();
					break;
				case "updatePersonalData":
					dispatch(updatePersonalDataSucceeded());
					dispatch(closeUpdatePersonalData());
					/* dispatch(
            openModalDialog(
              modalType.DIALOG,
              lang.personalDataUpdate.personalDataUpdated,
              lang.ok
            )
          ); */
					SAVE_SOUND.play();
					break;
				case "updateAvatar":
					dispatch(updateAvatarSucceeded());
					dispatch(closeUpdateAvatar());
					/* dispatch(
            openModalDialog(
              modalType.DIALOG,
              lang.avatarUpdate.avatarUpdated,
              lang.ok
            )
          ); */
					SAVE_SOUND.play();
					break;
				case "deleteAvatar":
					dispatch(deleteAvatarSucceeded());
					dispatch(closeUpdateAvatar());
					SAVE_SOUND.play();
					break;
			}
			dispatch({ type: PROFILE_SETTINGS_UPDATED, payload: msg });
		}
	};
};

export const requestSettings = () => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "getSettings",
		});
	};
};

export const handleSettings = (msg) => {
	const cardsetURL = store.getState().app.welcome.webResources.cardsets;
	return { type: HANDLE_SETTINGS, payload: { ...msg, cardsetURL } };
};

export const defaultCardsetUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateDefaultCardsetSucceeded());
		SAVE_SOUND.play();
		dispatch({
			type: DEFAULT_CARDSET_UPDATED,
			payload: { defaultCardset: responseTo.defaultCardset },
		});
	};
};

export const selectDefaultCardset = (ind) => {
	return { type: SELECT_DEFAULT_CARDSET, payload: ind };
};

export const selectDefaultCardSuitOrder = (ind) => {
	return { type: SELECT_DEFAULT_CARD_SUIT_ORDER, payload: ind };
};

export const selectDefaultCardValueOrder = (ind) => {
	return { type: SELECT_DEFAULT_CARD_VALUE_ORDER, payload: ind };
};

export const selectDefaultCardSortOrder = (ind) => {
	return { type: SELECT_DEFAULT_CARD_SORT_ORDER, payload: ind };
};

export const overrideCardsetUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateOverrideCardsetSucceeded());
		SAVE_SOUND.play();
		dispatch({
			type: OVERRIDE_CARDSET_UPDATED,
			payload: { overrideCardset: responseTo.overrideCardset },
		});
	};
};

export const showRunningGamesInListUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateShowRunningGamesInListSucceeded());
		SAVE_SOUND.play();
		dispatch({
			type: SHOW_RUNNING_GAMES_IN_LIST_UPDATED,
			payload: { showRunningGamesInList: responseTo.showRunningGamesInList },
		});
	};
};

export const fixedPositionsUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateFixedPositionsSucceeded());
		SAVE_SOUND.play();
		dispatch({ type: FIXED_POSITIONS_UPDATED, payload: { responseTo } });
	};
};

export const appLanguageUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateAppLanguageSucceeded());
		SAVE_SOUND.play();
		dispatch({
			type: APP_LANGUAGE_UPDATED,
			payload: { newClientLanguage: responseTo.clientLanguage },
		});
		dispatch(changeAppLanguage(responseTo.clientLanguage));
	};
};

export const chatTranslationUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateChatTranslationSucceeded());
		SAVE_SOUND.play();
		dispatch({
			type: CHAT_TRANSLATION_UPDATED,
			payload: { useChatTranslation: responseTo.useChatTranslation },
		});
	};
};

export const emailNotificationsUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateEmailNotificationsSucceeded());
		SAVE_SOUND.play();
		dispatch({
			type: EMAIL_NOTIFICATIONS_UPDATED,
			payload: {
				emailOnNewsLetter: responseTo.emailOnNewsLetter,
				emailOnMessage: responseTo.emailOnMessage,
			},
		});
	};
};

export const soundsPreferencesUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateSoundsPreferencesSucceeded());
		SAVE_SOUND.play();
		dispatch({
			type: SOUNDS_PREFERENCES_UPDATED,
			payload: {
				playMenuButtonSound: responseTo.playMenuButtonSound,
				playMiscButtonSound: responseTo.playMiscButtonSound,
				playGameChatSound: responseTo.playGameChatSound,
				playGlobalChatSound: responseTo.playGlobalChatSound,
				useLoudToActSound: responseTo.useLoudToActSound,
			},
		});
	};
};

export const appNotificationsUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateAppNotificationsSucceeded());
		SAVE_SOUND.play();
		dispatch({
			type: APP_NOTIFICATIONS_UPDATED,
			payload: {
				appNotifyOnNewFriendGame: responseTo.appNotifyOnNewFriendGame,
				appNotifyOnFriendOnline: responseTo.appNotifyOnFriendOnline,
				appNotifyOnTournaments: responseTo.appNotifyOnTournaments,
				appNotifyOnPrivateChat: responseTo.appNotifyOnPrivateChat,
				appNotifyOnChat: responseTo.appNotifyOnChat,
				appNotifyOnGroupChat: responseTo.appNotifyOnGroupChat,
				appNotifyOnGroupOnline: responseTo.appNotifyOnGroupOnline,
				appNotifyOnMessage: responseTo.appNotifyOnMessage,
			},
		});
	};
};

export const updateFriendList = (msg, action = "ALL") => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (
			responseTo != undefined &&
			(responseTo.type == "addToFriendList" ||
				responseTo.type == "removeFromFriendList" ||
				responseTo.type == "getFriendList")
		) {
			dispatch({
				type: UPDATE_FRIEND_LIST,
				payload: { response: msg, action, userID: responseTo.userID },
			});
			dispatch(removeSavedEvent(msg.cMessageID));
		}
	};
};

export const updateBanList = (msg, action) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (
			responseTo != undefined &&
			(responseTo.type == "addToBanList" || responseTo.type == "removeFromBanList" || responseTo.type == "getBanList")
		) {
			dispatch({
				type: UPDATE_BAN_LIST,
				payload: { response: msg, userID: responseTo.userID, action },
			});
			dispatch(removeSavedEvent(msg.cMessageID));
		}
	};
};

export const updateSearchedUsersList = (msg) => {
	return { type: UPDATE_SEARCHED_USERS_LIST, payload: msg };
};

export const clearSearchedUsersList = () => {
	return { type: CLEAR_SEARCHED_USERS_LIST };
};

export const logOff = () => {
	return (dispatch) => {
		dispatch(setLoggedInState(false));
		dispatch(removeEmailCredentials());
		dispatch(removeFacebookCredentials());
		//clear global chat
		dispatch(clearGlobalChat());
		//clear game lobby
		dispatch(clearGameListModule());
		dispatch(clearGameDetailsInLobby());
	};
};

export const requestPersonalData = () => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "getProfileSettings",
		});
	};
};

export const cardsOrderUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateCardSortOrderSucceeded());
		SAVE_SOUND.play();
		dispatch({
			type: CARDS_ORDER_UPDATED,
			payload: {
				selectedSort: responseTo.cardSortOrder,
				selectedSuit: responseTo.cardSuitOrder,
				selectedValue: responseTo.cardValueOrder,
			},
		});
	};
};

export const screenResolutionUpdated = (responseTo) => {
	return (dispatch) => {
		dispatch(updateResolutionSucceeded());
		SAVE_SOUND.play();
		if (responseTo != undefined) {
			dispatch({
				type: SCREEN_RESOLUTION_UPDATED,
				payload: { resolution: responseTo.resolution },
			});
		}
	};
};

export const setUserAsVIP = () => {
	return { type: SET_USER_AS_VIP };
};

const _saveUserDetails = (myJSON) => {
	return { type: SAVE_USER_DETAILS, payload: myJSON };
};

const _setFeatures = (features) => {
	return { type: SET_FEATURES, payload: { features } };
};
