import React, { Component } from "react";
import { Image, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { appFont, FEATURE_PERMISSION, isWebOrTablet, RESOLUTION, tabType } from "../config/defaults";
import { gameDimensions } from "../config/dimensions";
import {
	TAB_CHAT,
	TAB_INBOX,
	TAB_LEADERBOARD,
	TAB_PLAY,
	TAB_SHOP,
	TAB_TOURNAMENT,
	UNREAD_MESSAGE,
} from "../config/images";
import {
	closeTournamentTab,
	displayPermissionDenied,
	openGameMessage,
	openLeaderBoardsMenu,
	openMessageInbox,
	openTab,
	openTournamentTab,
	subscribeToGameList,
	unsubscribeFromGameList,
} from "../redux/actions";
import { AppTouchableOpacity } from "./common";
import { handleError } from "./ErrorHandler";

class MainNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navImageDim: 0,
		};
	}

	//#region events
	onNavButtonsLayout(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const { height } = event.nativeEvent.layout;
			var navImageDim = Math.round(height / 5);
			const minDim = isWebOrTablet ? 50 : 48;
			navImageDim = navImageDim > 80 ? 80 : navImageDim;
			navImageDim = navImageDim < minDim ? minDim : navImageDim;
			this.setState({ navImageDim });
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderMailButtonsChildren() {
		if (this.props.messagingPermission != FEATURE_PERMISSION.ENABLED) return null;
		return (
			<View
				style={[
					styles.unreadGlobalMsgContainer,
					styles.unreadGlobalMsgSize,
					{
						opacity: this.props.unreadMessageCounter > 0 ? 1 : 0,
					},
				]}
			>
				<Image source={UNREAD_MESSAGE} style={[styles.unreadGlobalMsgIcon, styles.unreadGlobalMsgSize]} />
				<Text
					allowFontScaling={false}
					selectable={false}
					numberOfLines={1}
					ellipsizeMode="tail"
					style={[styles.unreadGlobalMsgText]}
				>
					{this.props.unreadMessageCounter > 0 ? this.props.unreadMessageCounter.toString() : " "}
				</Text>
			</View>
		);
	}

	renderChatButtonsChildren() {
		if (this.props.chatPermission != FEATURE_PERMISSION.ENABLED) return null;
		return (
			<View
				style={[
					styles.unreadGlobalMsgContainer,
					styles.unreadGlobalMsgSize,
					{
						opacity: this.props.globalUnreadMessageCounter > 0 ? 1 : 0,
					},
				]}
			>
				<Image source={UNREAD_MESSAGE} style={[styles.unreadGlobalMsgIcon, styles.unreadGlobalMsgSize]} />
				<Text
					allowFontScaling={false}
					selectable={false}
					numberOfLines={1}
					ellipsizeMode="tail"
					style={[styles.unreadGlobalMsgText]}
				>
					{this.props.globalUnreadMessageCounter > 0 ? this.props.globalUnreadMessageCounter.toString() : " "}
				</Text>
			</View>
		);
	}

	renderButton(btnType) {
		var isEnabled = true,
			isHidden = false,
			onPressFunc = () => {},
			imageSource = "",
			additionalChilds = null,
			_accLabel;
		const imageStyle = this.state.navImageDim == null ? {} : { height: this.state.navImageDim };

		switch (btnType) {
			case "tournaments":
				if (
					/* (this.props.username != "Sziduska" ||
            this.props.username != "test_4" ||
            this.props.username != "dr.zoidberg") && */
					this.props.tournamentsPermission == FEATURE_PERMISSION.HIDDEN
				)
					return null;
				isEnabled = this.props.tournamentsPermission == FEATURE_PERMISSION.ENABLED /* ||
          this.props.username == "Sziduska" ||
          this.props.username == "test_4" ||
          this.props.username == "dr.zoidberg" */;
				// isHidden = true;
				/* this.props.username != "Sziduska" &&
          this.props.username != "test_4" &&
          this.props.username != "dr.zoidberg" */
				_accLabel = "open tournaments";
				onPressFunc = () => {
					try {
						if (isEnabled) {
							this.props.openTournamentTab();
							if (this.props.isTabChatButtonActive) this.props.unsubscribeFromGameList();
						} else {
							this.props.displayPermissionDenied("GENERIC_TOURNAMENTS");
						}
					} catch (error) {
						handleError(error);
					}
				};
				imageSource = TAB_TOURNAMENT;
				break;
			case "chat":
				if (this.props.chatPermission == FEATURE_PERMISSION.HIDDEN) return null;
				isEnabled = this.props.chatPermission == FEATURE_PERMISSION.ENABLED;
				_accLabel = "open global chat";
				onPressFunc = () => {
					try {
						if (isEnabled && this.props.isTournamentTabOpened) {
							this.props.closeTournamentTab();
							this.props.subscribeToGameList();
							this.props.requestingChat();
						}
						if (this.props.isTabGamesButtonActive) {
							if (isEnabled) {
								if (typeof this.props.requestingChat === "function") this.props.requestingChat();
								this.props.openTab(tabType.CHAT);
							} else {
								this.props.displayPermissionDenied("GENERIC_CHAT");
							}
						}
					} catch (error) {
						handleError(error);
					}
				};
				imageSource = TAB_CHAT;
				additionalChilds = this.renderChatButtonsChildren();
				break;
			case "lobby":
				_accLabel = "open game lobby";
				onPressFunc = () => {
					try {
						if (!this.props.isTabGamesButtonActive || this.props.isTournamentTabOpened) {
							if (!this.props.isTournamentTabOpened) this.props.unsubscribeFromGameList();
							this.props.openTab(tabType.GAMES);
						}
						if (this.props.isTournamentTabOpened) this.props.closeTournamentTab();
					} catch (error) {
						handleError(error);
					}
				};
				imageSource = TAB_PLAY;
				break;
			case "shop":
				if (this.props.shopPermission == FEATURE_PERMISSION.HIDDEN) return null;
				isEnabled = this.props.shopPermission == FEATURE_PERMISSION.ENABLED;
				_accLabel = "open game shop";
				onPressFunc = () => {
					try {
						if (isEnabled) {
							this.props.openTab(tabType.SHOP, "bigButton");
						} else {
							this.props.displayPermissionDenied("GENERIC_SHOP");
						}
					} catch (error) {
						handleError(error);
					}
				};
				imageSource = TAB_SHOP;
				break;
			case "messaging":
				if (this.props.messagingPermission == FEATURE_PERMISSION.HIDDEN) return null;
				isEnabled = this.props.messagingPermission == FEATURE_PERMISSION.ENABLED;
				isHidden = false;
				_accLabel = "open messages";
				onPressFunc = () => {
					try {
						if (isEnabled) {
							this.props.openGameMessage();
							this.props.openMessageInbox();
						} else {
							this.props.displayPermissionDenied("GENERIC_MESSAGING");
						}
					} catch (error) {
						handleError(error);
					}
				};
				imageSource = TAB_INBOX;
				additionalChilds = this.renderMailButtonsChildren();
				break;
			case "leaderboard":
				if (
					this.props.resolution == RESOLUTION.LOW ||
					!isWebOrTablet ||
					this.props.leaderboardPermission == FEATURE_PERMISSION.HIDDEN
				)
					return null;
				isEnabled = this.props.leaderboardPermission == FEATURE_PERMISSION.ENABLED;
				_accLabel = "open leaderboards";
				onPressFunc = () => {
					if (isEnabled) {
						this.props.openLeaderBoardsMenu();
					} else {
						this.props.displayPermissionDenied("GENERIC_LEADERBOARD");
					}
				};
				imageSource = TAB_LEADERBOARD;
				break;
			default:
				return null;
		}

		return (
			<AppTouchableOpacity
				activeOpacity={isEnabled ? 1 : 0.5}
				disabled={!isEnabled || isHidden}
				onPress={onPressFunc}
				style={[styles.navButton, { opacity: isHidden ? 0 : isEnabled ? 1 : 0.5 }]}
				accessibilityLabel={_accLabel}
			>
				<Image
					source={imageSource}
					style={[
						styles.navButtonImage,
						imageStyle,
						!isEnabled || isHidden ? { width: 0, height: 0 } : { minHeight: isWebOrTablet ? 50 : 48 },
					]}
					resizeMode="stretch"
				/>
				{additionalChilds}
			</AppTouchableOpacity>
		);
	}

	render() {
		return (
			<ScrollView
				showsVerticalScrollIndicator={false}
				style={[{ flex: 1, maxWidth: 80 }, styles.navButtonsContainer]}
				contentContainerStyle={{
					paddingTop:
						this.props.isTabGamesButtonActive || this.props.isTournamentTabOpened
							? isWebOrTablet
								? gameDimensions.boxHeaderHeight - 3
								: 3
							: 5,
				}}
				onLayout={(event) => this.onNavButtonsLayout(event)}
			>
				{this.renderButton("chat")}
				{this.renderButton("lobby")}
				{this.renderButton("tournaments")}
				{this.renderButton("shop")}
				{this.renderButton("leaderboard")}
				{this.renderButton("messaging")}
			</ScrollView>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	navButtonsContainer: { width: 80 },
	navButton: {
		alignSelf: "center",
		overflow: "visible",
		marginBottom: isWebOrTablet ? 3 : 0,
	},
	navButtonImage: {
		width: Platform.OS === "web" ? 72 : 68,
		maxHeight: 68,
	},
	unreadGlobalMsgContainer: {
		position: "absolute",
		top: 0,
		right: 0,
		zIndex: 1,
		paddingTop: 1,
		paddingBottom: 1,
	},
	unreadGlobalMsgSize: {
		width: 22,
		height: 22,
	},
	unreadGlobalMsgIcon: {
		position: "absolute",
		top: -5,
		right: -2,
		zIndex: -1,
	},
	unreadGlobalMsgText: {
		fontFamily: appFont,
		fontSize: 10,
		color: "#fff",
		textAlign: "center",
		alignSelf: "center",
		marginTop: -1,
		marginRight: -3,
	},
});

const mapStateToProps = (state) => {
	return {
		isTabGamesButtonActive: state.tab.isTabGamesButtonActive,
		isTabChatButtonActive: state.tab.isTabChatButtonActive,
		globalUnreadMessageCounter: state.globalChat.globalUnreadMessageCounter,
		tournamentsPermission: state.currentUser.features.generic.tournaments,
		shopPermission: state.currentUser.features.generic.shop,
		messagingPermission: state.currentUser.features.generic.messaging,
		chatPermission: state.currentUser.features.generic.chat,
		resolution: state.currentUser.preferences.resolution,
		leaderboardPermission: state.currentUser.features.generic.leaderBoard,
		username: state.currentUser.userDetails.username,
		unreadMessageCounter: state.gameMessage.unreadMessageCounter,
		isTournamentTabOpened: state.app.isTournamentTabOpened,
	};
};

const mapDispatchToProps = {
	closeTournamentTab,
	displayPermissionDenied,
	openGameMessage,
	openLeaderBoardsMenu,
	openMessageInbox,
	openTab,
	openTournamentTab,
	subscribeToGameList,
	unsubscribeFromGameList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);
