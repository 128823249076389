import React, { Component } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansprobolditalic, f_sourcesansprosemibolditalic, isWebOrTablet } from "../../config/defaults";
import { DEFAULT_IMG, GRAY_BUTTON, PAGES, YELLOW_BUTTON } from "../../config/images";
import { openGameLobby, resetGameEnd } from "../../redux/actions/";
import Analytics from "../Analytics/Analytics";
import { AppTouchableOpacity } from "../common";
import { handleError } from "../ErrorHandler";

class DidYouKnow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLeftButtonHovered: false,
			isRightButtonHovered: false,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("DidYouKnow");
	}
	//#endregion

	//#region events
	onMouseEnterLeftButton() {
		this.setState({ isLeftButtonHovered: true });
	}

	onMouseLeaveLeftButton() {
		this.setState({ isLeftButtonHovered: false });
	}

	onLeftButtonPress() {
		try {
			//TODO:
		} catch (error) {
			handleError(error);
		}
	}

	onMouseEnterRightButton() {
		this.setState({ isRightButtonHovered: true });
	}

	onMouseLeaveRightButton() {
		this.setState({ isRightButtonHovered: false });
	}

	onRightButtonPress() {
		try {
			this.props.resetGameEnd();
			this.props.openGameLobby();
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderLeftButton() {
		const { didYouKnowScreenID, didYouKnowLink } = this.props.gameEnd;
		const requestLink = typeof didYouKnowLink === "string" && didYouKnowLink;
		const opacity = !requestLink ? 0 : this.state.isLeftButtonHovered ? 0.8 : 1;
		const langEntry = this.props.lang.didYouKnow.screens[didYouKnowScreenID];
		const _leftButtonText = langEntry && langEntry.leftButton ? langEntry.leftButton : "";
		return (
			<View style={{ flex: 2 }}>
				<AppTouchableOpacity
					activeOpacity={0.5}
					disabled={!requestLink}
					style={[styles.leftButtonDim]}
					onMouseEnter={this.onMouseEnterLeftButton.bind(this)}
					onMouseLeave={this.onMouseLeaveLeftButton.bind(this)}
					onPress={this.onLeftButtonPress.bind(this)}
				>
					<Image
						source={YELLOW_BUTTON}
						style={[styles.leftButtonDim, styles.buttonBackImage, { opacity }]}
						resizeMode="stretch"
					/>
					{requestLink && (
						<Text allowFontScaling={false} numberOfLines={1} style={styles.buttonText}>
							{_leftButtonText}
						</Text>
					)}
				</AppTouchableOpacity>
			</View>
		);
	}

	renderRightButton() {
		const { didYouKnowScreenID } = this.props.gameEnd;
		const opacity = this.state.isRightButtonHovered ? 0.8 : 1;
		const langEntry = this.props.lang.didYouKnow.screens[didYouKnowScreenID];
		const _rightButtonText = langEntry && langEntry.rightButton ? langEntry.rightButton : "";
		return (
			<View style={{ flex: 2 }}>
				<AppTouchableOpacity
					activeOpacity={0.5}
					style={[styles.rightButtonDim]}
					onMouseEnter={this.onMouseEnterRightButton.bind(this)}
					onMouseLeave={this.onMouseLeaveRightButton.bind(this)}
					onPress={this.onRightButtonPress.bind(this)}
				>
					<Image
						source={GRAY_BUTTON}
						style={[styles.rightButtonDim, styles.buttonBackImage, { opacity }]}
						resizeMode="stretch"
					/>
					<Text allowFontScaling={false} numberOfLines={1} style={styles.buttonText}>
						{_rightButtonText}
					</Text>
				</AppTouchableOpacity>
			</View>
		);
	}

	render() {
		const { didYouKnowScreenID } = this.props.gameEnd;
		const langEntry = this.props.lang.didYouKnow.screens[didYouKnowScreenID];
		const _titleText = langEntry && langEntry.title ? langEntry.title : "";
		const pageImage = PAGES[didYouKnowScreenID] ? PAGES[didYouKnowScreenID] : DEFAULT_IMG;

		return (
			<View style={styles.container}>
				<View style={styles.innerContainer}>
					<Image
						source={pageImage} //TODO: get pages back from the gameEnd event
						style={styles.mainBackImage}
						resizeMode="stretch"
					/>
					<View style={styles.buttonContainer}>
						{this.renderLeftButton()}
						{this.renderRightButton()}
					</View>
					<View style={styles.titleContainer}>
						<Text allowFontScaling={false} style={styles.titleText} numberOfLines={3}>
							{_titleText}
						</Text>
					</View>
				</View>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		height: "100%",
		marginRight: -6,
	},
	innerContainer: {
		width: "100%",
		height: "100%",
		flexDirection: "column-reverse",
	},
	titleContainer: {
		width: "100%",
		height: "33%",
		justifyContent: "center",
	},
	mainBackImage: {
		width: "100%",
		height: "100%",
		position: "absolute",
		zIndex: -1,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		height: "16%",
		bottom: 0,
	},
	leftButtonDim: { width: 200, height: 50, alignSelf: "center" },
	rightButtonDim: { width: 200, height: 50, alignSelf: "center" },
	buttonBackImage: {
		position: "absolute",
		zIndex: -1,
	},
	buttonText: {
		fontSize: 18,
		fontFamily: f_sourcesansprobolditalic,
		textAlign: "center",
		lineHeight: 42,
		color: "#000000",
	},
	titleText: {
		width: "98%",
		alignSelf: "center",
		fontSize: isWebOrTablet ? 26 : 20,
		fontFamily: f_sourcesansprosemibolditalic,
		textAlign: "center",
		lineHeight: isWebOrTablet ? 32 : 26,
		color: "#ffec79",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		gameEnd: state.startedGame.gameEnd,
	};
};

const mapDispatchToProps = { openGameLobby, resetGameEnd };

export default connect(mapStateToProps, mapDispatchToProps)(DidYouKnow);
