import React, { Component } from "react";
import { Dimensions, ImageBackground, ScrollView, StyleSheet, Text, View } from "react-native";
import { f_sourcesansprobold, f_sourcesansprosemibolditalic, isWebOrTablet, RESOLUTION } from "../../config/defaults";
import { ITEM_PRICE_GOLD, ITEM_PRICE_SILVER } from "../../config/images";
import { AppTouchableOpacity, HeaderText } from "../common";
import { handleError } from "../ErrorHandler";

class GameShopItemDetails extends Component {
	render() {
		try {
			if (this.props.item == undefined || this.props.item == null) return null;

			const { item } = this.props;

			/* const nPriceCurrency = item.currency !== undefined ? item.currency : "";
      const currency_lang =
        nPriceCurrency != "" &&
        genLang.currencies[nPriceCurrency] != "undefined"
          ? genLang.currencies[nPriceCurrency]
          : nPriceCurrency;
      const currencyPrice = !isNaN(item.currencyPrice)
        ? item.currencyPrice.toLocaleString()
        : 0;
      const priceVal = currency_lang + " " + currencyPrice; */
			var priceVal = item.realPrice;
			if (typeof item.realPrice === "string") {
				const numberPrice = Number.parseFloat(item.realPrice);
				const splitted = item.realPrice.split(" ");
				priceVal = splitted.length == 2 ? numberPrice.toLocaleString() + " " + splitted[1] : priceVal;
			}
			const goldPrice = !isNaN(item.goldPrice) ? item.goldPrice.toLocaleString() : 0;
			const silverPrice = !isNaN(item.silverPrice) ? item.silverPrice.toLocaleString() : 0;
			const buyEnabled = item.canBeBought;

			return (
				<View style={[styles.fullFlex, { paddingHorizontal: 60 }]}>
					<View style={styles.titleContainer}>
						<Text
							allowFontScaling={false}
							style={[styles.titleStyle, this.props.resolution === RESOLUTION.HIGH && { fontSize: 30 }]}
						>
							{item.title}
						</Text>
					</View>
					<View
						style={[
							styles.contentContainer,
							styles.scrollViewContainer,
							{ height: Dimensions.get("window").height - 50 },
						]}
					>
						<ScrollView style={{ flex: 1 }}>
							<Text
								allowFontScaling={false}
								style={[styles.contentText, this.props.resolution === RESOLUTION.HIGH && { fontSize: 24 }]}
							>
								{item.explanation}
							</Text>
						</ScrollView>
					</View>
					<View
						style={[
							styles.buttonContainer,
							{
								justifyContent: !item.canBeBoughtWithRealMoney ? "space-between" : "center",
							},
						]}
					>
						{item.canBeBoughtWithRealMoney == true && !item.canBeBoughtWithGold && !item.canBeBoughtWithSilver && (
							<AppTouchableOpacity
								activeOpacity={0.8}
								onPress={this.props.onCurrencyPricePress}
								disabled={!buyEnabled}
								style={styles.itemPriceButton}
							>
								{!buyEnabled && (
									<ImageBackground
										source={ITEM_PRICE_SILVER}
										style={[styles.fullFlex, { filter: "grayscale(1)" }]}
										resizeMode={"stretch"}
									>
										<HeaderText textContent={priceVal} textContentStyle={[styles.itemPriceText, styles.currency]} />
									</ImageBackground>
								)}
								{buyEnabled && (
									<ImageBackground source={ITEM_PRICE_SILVER} style={styles.fullFlex} resizeMode={"stretch"}>
										<HeaderText textContent={priceVal} textContentStyle={[styles.itemPriceText, styles.currency]} />
									</ImageBackground>
								)}
							</AppTouchableOpacity>
						)}
						{!item.canBeBoughtWithRealMoney && item.canBeBoughtWithSilver == true && (
							<AppTouchableOpacity
								activeOpacity={0.8}
								onPress={this.props.onSilverPricePress}
								disabled={!buyEnabled}
								style={styles.itemPriceButton}
							>
								{!buyEnabled && (
									<ImageBackground
										source={ITEM_PRICE_SILVER}
										style={[styles.fullFlex, { filter: "grayscale(1)" }]}
										resizeMode={"stretch"}
									>
										<HeaderText
											textContent={silverPrice}
											textContentStyle={[styles.itemPriceText, styles.silverPrice]}
										/>
									</ImageBackground>
								)}
								{buyEnabled && (
									<ImageBackground source={ITEM_PRICE_SILVER} style={styles.fullFlex} resizeMode={"stretch"}>
										<HeaderText
											textContent={silverPrice}
											textContentStyle={[styles.itemPriceText, styles.silverPrice]}
										/>
									</ImageBackground>
								)}
							</AppTouchableOpacity>
						)}
						{!item.canBeBoughtWithRealMoney && item.canBeBoughtWithGold == true && (
							<AppTouchableOpacity
								activeOpacity={0.8}
								onPress={this.props.onGoldPricePress}
								disabled={!buyEnabled}
								style={styles.itemPriceButton}
							>
								{!buyEnabled && (
									<ImageBackground
										source={ITEM_PRICE_GOLD}
										style={[styles.fullFlex, { filter: "grayscale(1)" }]}
										resizeMode={"stretch"}
									>
										<HeaderText textContent={goldPrice} textContentStyle={[styles.itemPriceText, styles.goldPrice]} />
									</ImageBackground>
								)}
								{buyEnabled && (
									<ImageBackground source={ITEM_PRICE_GOLD} style={styles.fullFlex} resizeMode={"stretch"}>
										<HeaderText textContent={goldPrice} textContentStyle={[styles.itemPriceText, styles.goldPrice]} />
									</ImageBackground>
								)}
							</AppTouchableOpacity>
						)}
					</View>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}
}

const styles = StyleSheet.create({
	fullFlex: { flex: 1 },
	titleContainer: { height: 50 },
	titleStyle: {
		width: "100%",
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 28,
		textAlign: "center",
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
	},
	scrollViewContainer: {
		flex: 1,
		borderWidth: isWebOrTablet ? 3 : 2,
		borderColor: "#278082",
		borderRadius: isWebOrTablet ? 25 : 15,
		backgroundColor: "rgba(24, 63, 63, 0.8)",
		paddingHorizontal: isWebOrTablet ? 20 : 10,
		paddingVertical: isWebOrTablet ? 10 : 5,
	},
	contentContainer: { marginTop: 5, marginBottom: 15 },
	contentText: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansprobold,
		fontSize: 18,
		color: "#ebf1f1",
	},
	buttonContainer: {
		height: 60,
		flexDirection: "row",
		paddingHorizontal: 30,
	},
	itemPriceButton: { width: 160, height: 60 },
	itemPriceText: {
		alignSelf: "center",
		lineHeight: 50,
		fontFamily: f_sourcesansprosemibolditalic,
		fontSize: 24,
		color: "#FFFFFF",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 5,
	},
	currency: { textShadowColor: "#6B6B6B" },
	silverPrice: { textShadowColor: "#6B6B6B" },
	goldPrice: { textShadowColor: "#826000" },
});

export default GameShopItemDetails;
