import React, { Component } from "react";
import { ImageBackground, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, f_sourcesansprobolditalic, RESOLUTION } from "../../config/defaults";
import { acceptTermsAndConditions } from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { Info, Link } from "../common";

class AcceptConditions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPrivacyOpen: false,
			isTermsOpen: false,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("TermsAndConditions");
	}
	//#endregion

	//#region events
	openPrivacyPolicy() {
		this.setState({ isPrivacyOpen: true, isTermsOpen: false });
	}

	openTermsAndConditions() {
		this.setState({ isPrivacyOpen: false, isTermsOpen: true });
	}

	goBackPress() {
		this.setState({ isPrivacyOpen: false, isTermsOpen: false });
	}
	//#endregion

	//#region render methods
	renderLinks() {
		return (
			<View style={styles.linkContainer}>
				<View style={styles.linkInnerContainer}>
					<Link
						onPress={this.openPrivacyPolicy.bind(this)}
						text={this.props.lang.acceptTerms.privacyLink}
						textStyle={styles.linkText}
						silentPress={true}
					/>
				</View>
				<View style={styles.linkInnerContainer}>
					<Link
						onPress={this.openTermsAndConditions.bind(this)}
						text={this.props.lang.acceptTerms.termsLink}
						textStyle={styles.linkText}
						silentPress={true}
					/>
				</View>
			</View>
		);
	}

	render() {
		var _buttonTextStyle = { paddingTop: 13 };
		if (this.props.resolution === RESOLUTION.HIGH) {
			styles.linkText = [styles.linkText, { fontSize: 36 }];
			styles.titleText = [styles.titleText, { fontSize: 46 }];
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		if (this.state.isPrivacyOpen)
			return (
				<ImageBackground source={this.props.DEFAULT_BACK.url} style={{ flex: 1 }}>
					<Info
						onMainPress={this.goBackPress.bind(this)}
						mainButtonText={this.props.lang.acceptTerms.backButton}
						titleText={this.props.lang.privacyPolicy.title}
						titleTextStyle={styles.titleText}
						contents={this.props.lang.privacyPolicy.content}
						buttonTextStyle={_buttonTextStyle}
						mainButtonColor={buttonColor.GREY}
						isPrivacyOpen={true}
						resolution={this.props.resolution}
					/>
				</ImageBackground>
			);
		if (this.state.isTermsOpen)
			return (
				<ImageBackground source={this.props.DEFAULT_BACK.url} style={{ flex: 1 }}>
					<Info
						onMainPress={this.goBackPress.bind(this)}
						mainButtonText={this.props.lang.acceptTerms.backButton}
						titleText={this.props.lang.termsAndCond.title}
						titleTextStyle={styles.titleText}
						contents={this.props.lang.termsAndCond.content}
						buttonTextStyle={_buttonTextStyle}
						mainButtonColor={buttonColor.GREY}
						resolution={this.props.resolution}
					/>
				</ImageBackground>
			);
		return (
			<ImageBackground source={this.props.DEFAULT_BACK.url} style={{ flex: 1 }}>
				<Info
					onMainPress={this.props.acceptTermsAndConditions}
					mainButtonText={this.props.lang.acceptTerms.acceptButton}
					buttonTextStyle={_buttonTextStyle}
					titleText={this.props.lang.acceptTerms.title}
					titleTextStyle={styles.titleText}
					contents={this.props.lang.acceptTerms.content}
					mainButtonColor={buttonColor.GREEN}
					linksRow={true}
					resolution={this.props.resolution}
				>
					{this.renderLinks()}
				</Info>
			</ImageBackground>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	linkContainer: {
		flexDirection: "row",
		width: "100%",
		height: 50,
		marginVertical: 5,
	},
	linkInnerContainer: { flex: 2 },
	linkText: {
		fontFamily: f_sourcesansprobolditalic,
		fontSize: 24,
		color: "#00CED9",
		lineHeight: 50,
		textAlign: "center",
		textDecorationLine: "none",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 10,
	},
	titleText: {
		fontFamily: f_sourcesansprobold,
		fontSize: 28,
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		DEFAULT_BACK: state.images.DEFAULT_BACK,
	};
};

const mapDispatchToProps = { acceptTermsAndConditions };

export default connect(mapStateToProps, mapDispatchToProps)(AcceptConditions);
