import React, { Component } from "react";
import { StyleSheet, Text } from "react-native";
import { Menu, MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import { appFont } from "../../config/defaults";
import { handleError } from "../ErrorHandler";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

class PlayerContextMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			muteHovered: false,
			friendAddHovered: false,
			friendRemoveHovered: false,
			banAddHovered: false,
			banRemoveHovered: false,
			privateRoomHovered: false,
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		const menuName = this.refs.plContMenu._name;
		this.refs.plContextTrigger.props.ctx.menuActions.openMenu(menuName);
	}
	//#endregion

	//#region events
	onOptionSelect(option) {
		try {
			if (typeof this.props.onOptionSelect === "function")
				this.props.onOptionSelect(this.props.refItem, this.props.refSection, option);
		} catch (error) {
			handleError(error);
		}
	}

	onCloseMenu() {
		if (typeof this.props.onCloseMenu === "function") this.props.onCloseMenu(this.props.refItem, this.props.refSection);
	}

	onMouseEnterOption(option) {
		var newState = {
			muteHovered: false,
			friendAddHovered: false,
			friendRemoveHovered: false,
			banAddHovered: false,
			banRemoveHovered: false,
			privateRoomHovered: false,
		};
		switch (option) {
			case "mute":
				newState.muteHovered = true;
				break;
			case "banAdd":
				newState.banAddHovered = true;
				break;
			case "banRemove":
				newState.banRemoveHovered = true;
				break;
			case "friendAdd":
				newState.friendAddHovered = true;
				break;
			case "friendRemove":
				newState.friendRemoveHovered = true;
				break;
			case "privateRoom":
				newState.privateRoomHovered = true;
				break;
			default:
				break;
		}
		this.setState(newState);
	}

	onMouseLeaveOption() {
		this.setState({
			muteHovered: false,
			friendAddHovered: false,
			friendRemoveHovered: false,
			banAddHovered: false,
			banRemoveHovered: false,
			privateRoomHovered: false,
		});
	}
	//#endregion

	//#region render methods
	renderPrivateRoomOption() {
		return (
			<AppTouchableOpacity
				key="t_privateRoom"
				onMouseEnter={() => this.onMouseEnterOption("privateRoom")}
				onMouseLeave={this.onMouseLeaveOption.bind(this)}
			>
				<MenuOption
					key="privateRoom"
					onSelect={() => this.onOptionSelect("privateRoom")}
					style={this.state.privateRoomHovered ? styles.hovered : styles.unHovered}
				>
					<Text allowFontScaling={false} style={styles.opUserName}>
						{this.props.lang.optPrivateMessage}
					</Text>
				</MenuOption>
			</AppTouchableOpacity>
		);
	}

	renderMuteOption() {
		return (
			<AppTouchableOpacity
				key="t_mute"
				onMouseEnter={() => this.onMouseEnterOption("mute")}
				onMouseLeave={this.onMouseLeaveOption.bind(this)}
			>
				<MenuOption
					key="mute"
					onSelect={() => this.onOptionSelect("mute")}
					style={this.state.muteHovered ? styles.hovered : styles.unHovered}
				>
					<Text allowFontScaling={false} style={styles.opUserName}>
						{this.props.lang.optMute}
					</Text>
				</MenuOption>
			</AppTouchableOpacity>
		);
	}

	renderMenuOptions() {
		try {
			const { refSection } = this.props;
			const { banAddHovered, banRemoveHovered, friendAddHovered, friendRemoveHovered } = this.state;
			var options = [];
			switch (refSection.title) {
				case this.props.lang.globalChat.otherPlayers:
					options.push(this.renderMuteOption());
					options.push(
						<AppTouchableOpacity
							key="t_banAdd"
							onMouseEnter={() => this.onMouseEnterOption("banAdd")}
							onMouseLeave={this.onMouseLeaveOption.bind(this)}
						>
							<MenuOption
								key="banAdd"
								onSelect={() => this.onOptionSelect("banAdd")}
								style={banAddHovered ? styles.hovered : styles.unHovered}
							>
								<Text allowFontScaling={false} style={styles.opUserName}>
									{this.props.lang.optBanAdd}
								</Text>
							</MenuOption>
						</AppTouchableOpacity>
					);
					options.push(
						<AppTouchableOpacity
							key="t_friendAdd"
							onMouseEnter={() => this.onMouseEnterOption("friendAdd")}
							onMouseLeave={this.onMouseLeaveOption.bind(this)}
						>
							<MenuOption
								key="friendAdd"
								onSelect={() => this.onOptionSelect("friendAdd")}
								style={friendAddHovered ? styles.hovered : styles.unHovered}
							>
								<Text allowFontScaling={false} style={styles.opUserName}>
									{this.props.lang.optFriendAdd}
								</Text>
							</MenuOption>
						</AppTouchableOpacity>
					);
					options.push(this.renderPrivateRoomOption());
					break;
				case this.props.lang.globalChat.friends:
					options.push(this.renderMuteOption());
					options.push(
						<AppTouchableOpacity
							key="t_friendRemove"
							onMouseEnter={() => this.onMouseEnterOption("friendRemove")}
							onMouseLeave={this.onMouseLeaveOption.bind(this)}
						>
							<MenuOption
								key="friendRemove"
								onSelect={() => this.onOptionSelect("friendRemove")}
								style={friendRemoveHovered ? styles.hovered : styles.unHovered}
							>
								<Text allowFontScaling={false} style={styles.opUserName}>
									{this.props.lang.optFriendRemove}
								</Text>
							</MenuOption>
						</AppTouchableOpacity>
					);
					options.push(this.renderPrivateRoomOption());
					break;
				case this.props.lang.globalChat.bannedPlayers:
					options.push(
						<AppTouchableOpacity
							key="t_banRemove"
							onMouseEnter={() => this.onMouseEnterOption("banRemove")}
							onMouseLeave={this.onMouseLeaveOption.bind(this)}
						>
							<MenuOption
								key="banRemove"
								onSelect={() => this.onOptionSelect("banRemove")}
								style={banRemoveHovered ? styles.hovered : styles.unHovered}
							>
								<Text allowFontScaling={false} style={styles.opUserName}>
									{this.props.lang.optBanRemove}
								</Text>
							</MenuOption>
						</AppTouchableOpacity>
					);
					break;
				default:
					break;
			}
			return options;
		} catch (error) {
			handleError(error);
		}
	}

	render() {
		return (
			<Menu key="plContMenu" ref="plContMenu" onClose={() => this.onCloseMenu()}>
				<MenuTrigger key="plContextTrigger" ref="plContextTrigger" />
				<MenuOptions
					key="optionsContainer"
					customStyles={{
						optionsContainer: styles.menuStyle,
					}}
				>
					{this.renderMenuOptions()}
				</MenuOptions>
			</Menu>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	menuStyle: {
		shadowColor: "#fff",
		shadowRadius: 0,
		position: "absolute",
		padding: 1,
		marginLeft: 75,
		zIndex: 555,
		width: 125,
		borderColor: "#ccc",
		borderWidth: 1,
		borderRadius: 5,
	},
	opUserName: {
		color: "#333",
		fontFamily: appFont,
		fontSize: 12,
		height: 18,
		paddingTop: 2,
		paddingBottom: 4,
	},
	hovered: {
		backgroundColor: "#DEF",
	},
	unHovered: {
		backgroundColor: "#FFF",
	},
});

export { PlayerContextMenu };
