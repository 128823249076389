import React, { Component } from "react";
import { Dimensions, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor } from "../../../config/defaults";
import { gameDimensions } from "../../../config/dimensions";
import {
	openInviteFriendsMenu,
	openRateForSilver,
	openUpdatePersonalData,
	requestPersonalData,
} from "../../../redux/actions";
import { ColoredButton } from "../../common";
import UpdatePersonalData from "../../Main/Settings/Profile/UpdatePersonalData";
//TODO: temporary disabled import GameRewardedAd from "./../../Admob/RewardedAd";
import InviteFriendsMenu from "./InviteFriendsMenu";
import SliderRate from "./Rate/SliderRate";

class EarnFreeSilver extends Component {
	//#render methods
	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "rate":
				textContent = this.props.lang.gameShop.earnFreeSilver.rate;
				onPressFunc = () => {
					this.props.openRateForSilver();
				};
				_buttonColor = buttonColor.LIGHT_BLUE;
				break;
			case "invite":
				textContent = this.props.lang.gameShop.earnFreeSilver.invite;
				onPressFunc = () => {
					this.props.openInviteFriendsMenu();
				};
				_buttonColor = buttonColor.GREEN;
				break;
			case "complete":
				textContent = this.props.lang.gameShop.earnFreeSilver.complete;
				onPressFunc = () => {
					this.props.requestPersonalData();
					this.props.openUpdatePersonalData();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
		}
		return (
			<ColoredButton
				width={gameDimensions.gameButtonsWidth}
				height={40}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				additionalTextStyle={styles.buttonText}
				color={_buttonColor}
				disabled={false}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ScrollView
				style={styles.container}
				contentContainerStyle={[styles.containerContent, { minHeight: Dimensions.get("window").height - 60 }]}
				centerContent={true}
			>
				{/*TODO: temporary disabled <GameRewardedAd
          textContent={this.props.lang.gameShop.earnFreeSilver.watchAd}
        /> */}
				{this.renderButton("rate")}
				{this.renderButton("invite")}
				{this.renderButton("complete")}
			</ScrollView>
		);
	}

	render() {
		if (this.props.updatePersonalDataOpened) {
			return <UpdatePersonalData openedFrom="shop" />;
		} else if (this.props.inviteFriendsMenuOpened) {
			return <InviteFriendsMenu />;
		} else if (this.props.rateForSilverOpened) {
			return <SliderRate />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		paddingHorizontal: 20,
		justifyContent: "center",
		alignItems: "center",
	},
	buttonText: {
		fontSize: 16,
		paddingTop: 8,
		paddingBottom: 8,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		updatePersonalDataOpened: state.app.updatePersonalDataOpened,
		inviteFriendsMenuOpened: state.app.inviteFriendsMenuOpened,
		rateForSilverOpened: state.app.rateForSilverOpened,
	};
};

const mapDispatchToProps = {
	openInviteFriendsMenu,
	openRateForSilver,
	openUpdatePersonalData,
	requestPersonalData,
};

export default connect(mapStateToProps, mapDispatchToProps)(EarnFreeSilver);
