import {
	CHANGE_GAME_ROOM_CHAT_TEXT,
	CHANGE_GLOBAL_CHAT_TEXT,
	CHANGE_STARTED_GAME_CHAT_TEXT,
	CLEAR_GAME_ROOM_TEXT,
	CLEAR_GLOBAL_CHAT_TEXT,
	CLEAR_STARTED_GAME_CHAT_TEXT,
	UPDATE_CREATED_GAME_ACTIVE_CHATROOM,
	UPDATE_IN_GAME_ACTIVE_CHATROOM,
} from "../actionTypes";

export const changeGlobalChatText = (text) => {
	return { type: CHANGE_GLOBAL_CHAT_TEXT, payload: { text } };
};

export const clearGlobalChatText = () => {
	return { type: CLEAR_GLOBAL_CHAT_TEXT };
};

export const changeGameRoomChatText = (text) => {
	return { type: CHANGE_GAME_ROOM_CHAT_TEXT, payload: { text } };
};

export const clearGameRoomChatText = () => {
	return { type: CLEAR_GAME_ROOM_TEXT };
};

export const updateCreatedGameActiveChatRoom = (activeRoomID) => {
	return {
		type: UPDATE_CREATED_GAME_ACTIVE_CHATROOM,
		payload: { activeRoomID },
	};
};

export const changeStartedGameChatText = (text) => {
	return { type: CHANGE_STARTED_GAME_CHAT_TEXT, payload: { text } };
};

export const clearStartedGameChatText = () => {
	return { type: CLEAR_STARTED_GAME_CHAT_TEXT };
};

export const updateInGameActiveChatRoom = (activeRoomID) => {
	return { type: UPDATE_IN_GAME_ACTIVE_CHATROOM, payload: { activeRoomID } };
};
