import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansproitalic,
	f_sourcesansprolight,
	f_sourcesansprosemibolditalic,
	isWebOrTablet,
	processState,
	RESOLUTION,
} from "../../../../config/defaults";
import { gameDimensions, updatePersonalDataDimensions } from "../../../../config/dimensions";
import { TEXT_INPUT } from "../../../../config/images";
import CheckBox from "../../../../libraries/CheckBox";
import { closeUpdatePersonalData, goBackToMain, updatePersonalData } from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { ColoredButton, DateInput, NavigationHeader, Spinner, SwitchBox } from "../../../common";
import { handleError } from "../../../ErrorHandler";

class UpdatePersonalData extends Component {
	constructor(props) {
		super(props);
		const { dateOfBirth } = this.props.profileSettings;
		const minDate = new Date("1900-01-01");
		var _born = minDate;
		if (typeof dateOfBirth === "string") {
			const dateParts = dateOfBirth.split("-");
			var day = Number.parseInt(dateParts[0]);
			day = day < 10 ? "0" + day : day.toString();
			var month = Number.parseInt(dateParts[1]);
			month = month < 10 ? "0" + month : month.toString();
			const year = Number.parseInt(dateParts[2]).toString();
			_born = new Date(year + "-" + month + "-" + day);
		}
		this.state = {
			contentScale: this.getContentScale(),
			firstName: this.props.profileSettings.firstName,
			lastName: this.props.profileSettings.lastName,
			dateOfBirth: _born,
			gender: {
				0: this.props.profileSettings.gender == "MALE",
				1: this.props.profileSettings.gender == "FEMALE",
				2: this.props.profileSettings.gender == "NOT_SET",
			},
			isFirstNameVisible: this.props.profileSettings.isFirstNameVisible,
			isLastNameVisible: this.props.profileSettings.isLastNameVisible,
			isAgeVisible: this.props.profileSettings.isAgeVisible,
			isGenderVisible: this.props.profileSettings.isGenderVisible,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("UpdatePersonalData");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.profileSettings != this.props.profileSettings) {
			const { dateOfBirth } = this.props.profileSettings;
			const minDate = new Date("1900-01-01");
			var _born = minDate;
			if (typeof dateOfBirth === "string") {
				const dateParts = dateOfBirth.split("-");
				var day = Number.parseInt(dateParts[0]);
				day = day < 10 ? "0" + day : day.toString();
				var month = Number.parseInt(dateParts[1]);
				month = month < 10 ? "0" + month : month.toString();
				const year = Number.parseInt(dateParts[2]).toString();
				_born = new Date(year + "-" + month + "-" + day);
			}
			this.setState({
				firstName: this.props.profileSettings.firstName,
				lastName: this.props.profileSettings.lastName,
				dateOfBirth: _born,
				gender: {
					0: this.props.profileSettings.gender == "MALE",
					1: this.props.profileSettings.gender == "FEMALE",
					2: this.props.profileSettings.gender == "NOT_SET",
				},
			});
		}
	}
	//#endregion

	//#region events
	onUpdatePress() {
		try {
			var gender = "";
			if (this.state.gender[0] == true) {
				gender = "MALE";
			} else if (this.state.gender[1] == true) {
				gender = "FEMALE";
			} else {
				gender = "NOT_SET";
			}
			this.props.updatePersonalData({
				birthday: {
					d: this.state.dateOfBirth.getDate(),
					m: this.state.dateOfBirth.getMonth() + 1,
					y: this.state.dateOfBirth.getFullYear(),
				},
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				gender: gender,
				isFirstNameVisible: this.state.isFirstNameVisible,
				isLastNameVisible: this.state.isLastNameVisible,
				isAgeVisible: this.state.isAgeVisible,
				isGenderVisible: this.state.isGenderVisible,
			});
		} catch (error) {
			handleError(error);
		}
	}

	onFirstNameChange(text) {
		this.setState({ firstName: text });
	}

	onLastNameChange(text) {
		this.setState({ lastName: text });
	}

	onDateOfBirthChange(date) {
		this.setState({ dateOfBirth: date });
	}

	onGenderCheck(filterGroup, val, isChecked) {
		try {
			switch (val) {
				case this.props.lang.personalDataUpdate.genders.male:
					this.setState({
						gender: { 0: isChecked, 1: !isChecked, 2: !isChecked },
					});
					break;
				case this.props.lang.personalDataUpdate.genders.female:
					this.setState({
						gender: { 0: !isChecked, 1: isChecked, 2: !isChecked },
					});
					break;
				case this.props.lang.personalDataUpdate.genders.unknown:
					this.setState({
						gender: { 0: !isChecked, 1: !isChecked, 2: isChecked },
					});
					break;
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updatePersonalDataState != processState.STARTED}
				backAction={this.props.closeUpdatePersonalData}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		const _containerStyle =
			Platform.OS === "web"
				? styles.webButtonContainer
				: [
						styles.buttonContainer,
						{
							paddingRight: this.props.resolution === RESOLUTION.LOW ? 0 : 150,
						},
				  ];
		return (
			<View style={_containerStyle} key="submit">
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={false}
					additionalTextStyle={_buttonTextStyle}
				/>
			</View>
		);
	}

	renderFirstNameField() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = [styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26, width: 400 }, Platform.OS === "android" && { paddingTop: 13 }];
		}
		return (
			<View style={styles.dataInnerContainer} key="firstName">
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.personalDataUpdate.firstName.title}
				</Text>
				<ImageBackground
					source={TEXT_INPUT}
					resizeMode="stretch"
					style={{
						width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300,
						height: 50,
					}}
				>
					<TextInput
						allowFontScaling={false}
						disableFullscreenUI={true}
						onChangeText={(text) => this.onFirstNameChange(text)}
						value={this.state.firstName}
						editable={true}
						selectTextOnFocus={true}
						underlineColorAndroid="transparent"
						style={[_inputStyle, Platform.OS === "web" ? { outlineWidth: 0 } : {}]}
						placeholder={this.props.lang.personalDataUpdate.firstName.placeholder}
						placeholderTextColor="#B2B2B2"
					/>
				</ImageBackground>
				{this.renderPublicCheck("fName")}
			</View>
		);
	}

	renderLastNameField() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = [styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26, width: 400 }, Platform.OS === "android" && { paddingTop: 13 }];
		}
		return (
			<View style={[styles.dataInnerContainer]} key="lastName">
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.personalDataUpdate.lastName.title}
				</Text>
				<ImageBackground
					source={TEXT_INPUT}
					resizeMode="stretch"
					style={{
						width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300,
						height: 50,
					}}
				>
					<TextInput
						allowFontScaling={false}
						disableFullscreenUI={true}
						onChangeText={(text) => this.onLastNameChange(text)}
						value={this.state.lastName}
						editable={true}
						selectTextOnFocus={true}
						underlineColorAndroid="transparent"
						style={[_inputStyle, Platform.OS === "web" ? { outlineWidth: 0 } : {}]}
						placeholder={this.props.lang.personalDataUpdate.lastName.placeholder}
						placeholderTextColor="#B2B2B2"
					/>
				</ImageBackground>
				{this.renderPublicCheck("lName")}
			</View>
		);
	}

	renderDateOfBirth() {
		var _inputLabel = [styles.dataTitle, styles.dataText];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
		}
		return (
			<View style={[styles.dataInnerContainer]} key="born">
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.personalDataUpdate.dateOfBirth}
				</Text>
				<DateInput
					selectedDate={this.state.dateOfBirth}
					onChange={this.onDateOfBirthChange.bind(this)}
					resolution={this.props.resolution}
					posButText={this.props.lang.ok}
					negButText={this.props.lang.cancel}
				/>
				{this.renderPublicCheck("age")}
			</View>
		);
	}

	renderGenderField() {
		const genderLables = [
			this.props.lang.personalDataUpdate.genders.male,
			this.props.lang.personalDataUpdate.genders.female,
			this.props.lang.personalDataUpdate.genders.unknown,
		];
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_switchBoxStyle = styles.switchBox;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_switchBoxStyle = [styles.switchBox, { width: 400 }];
		}
		return (
			<View style={[styles.dataInnerContainer, { position: "relative", zIndex: -1 }]} key="gender">
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.personalDataUpdate.genderTitle}
				</Text>
				<SwitchBox
					isMultiChecked={false}
					group="gender"
					onCheck={this.onGenderCheck.bind(this)}
					labels={genderLables}
					checks={this.state.gender}
					style={_switchBoxStyle}
					labelStyle={{
						fontFamily:
							this.props.resolution === RESOLUTION.HIGH ? f_sourcesansprosemibolditalic : f_sourcesansproitalic,
						fontSize: this.props.resolution === RESOLUTION.HIGH ? 26 : 18,
						textAlign: "center",
						lineHeight: this.props.resolution === RESOLUTION.HIGH ? 45 : 30,
						alignSelf: "center",
						marginTop: 0,
						marginBottom: 0,
						color: "#084955",
						textShadowColor: "rgba(0,0,0,0.25)",
						textShadowOffset: { width: 0, height: 1 },
						textShadowRadius: 10,
					}}
					labelBackground={{
						marginTop: this.props.resolution === RESOLUTION.HIGH ? -2 : 0,
						width: this.props.resolution === RESOLUTION.HIGH ? 120 : 80,
						height: this.props.resolution === RESOLUTION.HIGH ? 60 : 40,
					}}
					buttonStyle={{
						width: this.props.resolution === RESOLUTION.HIGH ? 120 : 80,
						height: this.props.resolution === RESOLUTION.HIGH ? 60 : 40,
						justifyContent: "center",
					}}
					width={this.props.resolution === RESOLUTION.HIGH ? 360 : 240}
					height={this.props.resolution === RESOLUTION.HIGH ? 45 : 30}
					containerHeight={50}
				/>
				{this.renderPublicCheck("gender")}
			</View>
		);
	}

	renderPublicCheck(type) {
		var _innerContainerStyle = styles.dataInnerContainer,
			_inputLabel = [styles.checkBoxTitle, styles.dataText],
			_checkBoxStyle = [
				Platform.OS === "web" ? styles.checkBox : { transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }] },
			],
			_onChangeFunc = () => {},
			_checkBoxVal = false;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { height: 80 }];
			_inputLabel =
				Platform.OS === "web"
					? [styles.checkBoxTitle, styles.dataText, { fontSize: 26 }]
					: [styles.checkBoxTitle, styles.dataText, { fontSize: 26 }];
			_checkBoxStyle = [
				Platform.OS === "web"
					? [styles.checkBox, { width: 30, height: 30 }]
					: { transform: [{ scale: Platform.OS === "ios" ? 1 : 1.75 }] },
			];
		}
		switch (type) {
			case "fName":
				_checkBoxVal = this.state.isFirstNameVisible;
				_onChangeFunc = () => {
					this.setState({
						isFirstNameVisible: !this.state.isFirstNameVisible,
					});
				};
				break;
			case "lName":
				_checkBoxVal = this.state.isLastNameVisible;
				_onChangeFunc = () => {
					this.setState({
						isLastNameVisible: !this.state.isLastNameVisible,
					});
				};
				break;
			case "age":
				_checkBoxVal = this.state.isAgeVisible;
				_onChangeFunc = () => {
					this.setState({
						isAgeVisible: !this.state.isAgeVisible,
					});
				};
				break;
			case "gender":
				_checkBoxVal = this.state.isGenderVisible;
				_onChangeFunc = () => {
					this.setState({
						isGenderVisible: !this.state.isGenderVisible,
					});
				};
				break;
		}
		return (
			<View style={[_innerContainerStyle, { alignItems: "center" }]}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.personalDataUpdate.visible}
				</Text>
				<CheckBox value={_checkBoxVal} onValueChange={_onChangeFunc} style={_checkBoxStyle} />
			</View>
		);
	}

	renderContent() {
		return (
			<ScrollView
				style={{ flex: 1 }}
				centerContent={true}
				contentContainerStyle={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				{this.renderFirstNameField()}
				{this.renderLastNameField()}
				{this.renderDateOfBirth()}
				{this.renderGenderField()}
				{this.renderUpdateButton()}
			</ScrollView>
		);
	}

	render() {
		const backImgSrc =
			this.props.openedFrom != "shop" || !isWebOrTablet ? this.props.SETTINGS_BACK.url : { uri: "https://" };
		if (this.props.updatePersonalDataState == processState.STARTED)
			return (
				<ImageBackground source={backImgSrc} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		return (
			<ImageBackground source={backImgSrc} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{(this.props.openedFrom != "shop" || !isWebOrTablet) && this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (
				updatePersonalDataDimensions.width > availableWidth ||
				updatePersonalDataDimensions.height > availableHeight
			) {
				const _scaleX = Math.round((availableWidth / updatePersonalDataDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / updatePersonalDataDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: { flex: 1, padding: 20, paddingTop: 55, paddingBottom: 0 },
	contentContainer: { minHeight: "100%", paddingHorizontal: 20 },
	buttonContainer: {
		alignSelf: "flex-end",
		marginTop: 5,
		marginBottom: 5,
	},
	webButtonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	buttonStyle: { backgroundColor: "transparent", borderWidth: 0 },
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: Platform.OS === "web" ? 0 : 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 300,
		height: 50,
		marginRight: 2,
		borderRadius: 6,
		// paddingTop: Platform.OS === "web" ? 0 : 13,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	switchBox: {
		width: 300,
		height: 50,
		justifyContent: "center",
		marginTop: 5,
	},
	checkBoxTitle: {
		// width: 440,
		marginLeft: 20,
		textAlign: "right",
		paddingTop: Platform.OS === "web" ? 0 : 2,
		paddingRight: 20,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	checkBox: { width: 20, height: 20, alignSelf: "center" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		profileSettings: state.currentUser.profileSettings,
		updatePersonalDataState: state.app.updatePersonalDataState,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeUpdatePersonalData,
	goBackToMain,
	updatePersonalData,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePersonalData);
