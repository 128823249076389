import { cloneDeep } from "lodash";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { Component } from "react";
import { ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, f_sourcesansprosemibold, RESOLUTION } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { mapObject } from "../../helpers/commonHelpers";
import CheckBox from "../../libraries/CheckBox";
// import Picker from "react-mobile-picker";
import Picker from "../../libraries/WheelPicker";
import { closeQuestionnaire, saveAnswer } from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { ColoredButton, RadioGroup } from "../common";
import { handleError } from "../ErrorHandler";

class Questionnaire extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentQuestionIndex: 0,
			answers: {
				questionID:
					this.props.survey.length > 0 && this.props.survey[0].questionID ? this.props.survey[0].questionID : "",
				answers: [],
			},
			completed: false,
			radioGroupWidth: 0,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("Questionnaire");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.survey.length != this.props.survey.length && prevProps.survey.length == 0) {
			this.setState({
				answers: {
					questionID: this.props.survey[0] && this.props.survey[0].questionID ? this.props.survey[0].questionID : "",
					answers: [],
				},
				completed: false,
			});
		}
	}
	//#endregion

	//#region events
	getAnswer(question) {
		var answer = [];
		if (question) {
			const foundQuestion = this.props.surveyAnswers.find((e) => e.questionID == question.questionID);
			if (foundQuestion) {
				answer = Array.isArray(foundQuestion.answerID)
					? foundQuestion.answerID
					: typeof foundQuestion.answerID === "object"
					? foundQuestion.answerID
					: [foundQuestion.answerID];
			}
		}
		return answer;
	}

	previousPress() {
		try {
			this.props.saveAnswer(this.state.answers.questionID, this.state.answers.answers);
			const prevIndex =
				this.state.currentQuestionIndex > 0 ? this.state.currentQuestionIndex - 1 : this.state.currentQuestionIndex;
			const prevQuestion = this.props.survey[prevIndex];
			const oldAnswer = this.getAnswer(prevQuestion);
			if (this.state.completed) {
				this.setState({
					currentQuestionIndex: prevIndex,
					answers: {
						questionID: prevQuestion ? prevQuestion.questionID : "",
						answers: oldAnswer,
					},
					completed: false,
				});
				if (typeof this.props.unCompleteSurvey === "function") this.props.unCompleteSurvey();
			} else {
				this.setState({
					currentQuestionIndex: prevIndex,
					answers: {
						questionID: prevQuestion ? prevQuestion.questionID : "",
						answers: oldAnswer,
					},
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	nextPress() {
		try {
			if (this.state.completed) {
				/*TODO: MyWebSocket.shared.sendMsg({
          type: "questionnaireAnswer",
          sMessageID: 0,
          questionID: this.state.answers.questionID,
          answers: this.state.answers.answers,
        }); */
				this.props.closeQuestionnaire();
				let answersText = "";
				this.props.surveyAnswers.forEach((a) => {
					answersText = answersText + JSON.stringify(a) + "\n\n";
				});
				alert("Your answers are:\n" + answersText);
			} else {
				this.props.saveAnswer(this.state.answers.questionID, this.state.answers.answers);
				const nextIndex = this.state.currentQuestionIndex + 1;
				const nextQuestion = this.props.survey[nextIndex];
				if (nextIndex > this.props.survey.length - 1) {
					const oldAnswer = this.getAnswer(nextQuestion);
					this.setState({
						currentQuestionIndex: nextIndex,
						answers: {
							questionID: nextQuestion ? nextQuestion.questionID : "",
							answers: oldAnswer,
						},
						completed: true,
					});
					if (typeof this.props.completeSurvey === "function") this.props.completeSurvey();
				} else {
					const oldAnswer = this.getAnswer(nextQuestion);
					this.setState({
						currentQuestionIndex: nextIndex,
						answers: {
							questionID: nextQuestion ? nextQuestion.questionID : "",
							answers: oldAnswer,
						},
					});
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onCheckBoxChange(checked, answerID) {
		try {
			let answers = cloneDeep(this.state.answers.answers);
			if (checked === true) {
				answers.push(answerID);
			} else {
				const answerIndex = answers.findIndex((a) => a === answerID);
				if (answerIndex != -1) answers.splice(answerIndex, 1);
			}
			this.setState({
				answers: { ...this.state.answers, answers: answers },
			});
		} catch (error) {
			handleError(error);
		}
	}

	onPickerSelect(name, selectedItem) {
		this.setState({
			answers: { ...this.state.answers, answers: [selectedItem] },
		});
	}
	//#endregion

	//#region render methods
	renderRightButton() {
		const { currentQuestionIndex } = this.state;
		const { questionID, answers } = this.state.answers;
		var isDisabled =
			currentQuestionIndex >= 0 &&
			currentQuestionIndex < this.props.survey.length &&
			(questionID !== this.props.survey[currentQuestionIndex].questionID || answers.length == 0);
		if (
			answers.length != 0 &&
			this.props.survey[currentQuestionIndex].questionType === "MIXED_RADIO" &&
			!this.state.completed &&
			typeof answers === "object" &&
			typeof answers.otherText === "string"
		) {
			isDisabled = isDisabled || answers.otherText.length == 0;
		}
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				onPressFunc={this.nextPress.bind(this)}
				textContent={this.state.completed ? this.props.lang.finish : this.props.lang.continue}
				accessibilityLabel={this.state.completed ? this.props.lang.finish : this.props.lang.continue}
				color={buttonColor.GREEN}
				disabled={isDisabled}
				additionalTextStyle={_buttonTextStyle}
			/>
		);
	}

	renderLeftButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				onPressFunc={this.previousPress.bind(this)}
				textContent={this.props.lang.previous}
				accessibilityLabel={this.props.lang.previous}
				color={buttonColor.GREEN}
				additionalTextStyle={_buttonTextStyle}
			/>
		);
	}

	renderButtons() {
		return (
			<View
				style={[
					styles.buttonContainer,
					this.state.currentQuestionIndex > 0 ? { justifyContent: "space-between" } : { justifyContent: "flex-end" },
					this.props.resolution !== RESOLUTION.HIGH && { marginBottom: 5 },
				]}
			>
				{this.state.currentQuestionIndex > 0 && this.renderLeftButton()}
				{this.renderRightButton()}
			</View>
		);
	}

	renderTextArea(placeHolder) {
		return (
			<TextInput
				allowFontScaling={false}
				disableFullscreenUI={true}
				onChangeText={(text) => {
					this.setState({
						answers: {
							...this.state.answers,
							answers: text === "" ? [] : [text],
						},
					});
				}}
				value={this.state.answers.answers[0]}
				placeholder={placeHolder}
				placeholderTextColor="#B2B2B2"
				editable={true}
				selectTextOnFocus={false}
				multiline={true}
				maxLength={8096}
				underlineColorAndroid="transparent"
				style={[
					styles.answerText,
					styles.textAreaStyle,
					{ fontSize: this.props.resolution === RESOLUTION.HIGH ? 22 : 18 },
				]}
				allowFontScaling={false}
				returnKeyType="done"
			/>
		);
	}

	renderRadioGroup(options) {
		let data = [];
		let _selectedLabel;
		mapObject(options, (k, v, i) => {
			if (
				k == this.state.answers.answers[0] ||
				(typeof this.state.answers === "object" && this.state.answers.questionID && k == this.state.answers.questionID)
			) {
				_selectedLabel = typeof v === "string" ? v : v.label;
			}
			if (
				!_selectedLabel &&
				typeof v === "object" &&
				v &&
				v.enableTextBox === "true" &&
				typeof v.label === "string" &&
				typeof this.state.answers.answers === "object" &&
				typeof this.state.answers.answers.otherText === "string"
			) {
				_selectedLabel = v.label;
			}
			data.push({
				value: k,
				label: typeof v === "string" ? v : v.label,
				enabled: true,
				visible: true,
				textLayout: {
					fontFamily: f_sourcesansprosemibold,
					fontSize: this.props.resolution === RESOLUTION.HIGH ? 22 : 18,
					color: "#B2B2B2",
					marginLeft: 10,
				},
				buttonLayout: { marginBottom: 10 },
				enableTextBox: typeof v === "object" && v && v.enableTextBox === "true",
			});
		});
		return (
			<RadioGroup
				ref="radioGroup"
				radioButtons={data}
				flexDirection={"column"}
				mother="questionnaire"
				selectedLabel={_selectedLabel}
				onPress={() => {
					try {
						const selOption = this.refs.radioGroup.state.radioButtons.find((o) => o.selected === true);
						this.setState({
							answers: {
								...this.state.answers,
								answers: selOption.enableTextBox
									? {
											otherText:
												this.state.answers.answers && typeof this.state.answers.answers.otherText === "string"
													? this.state.answers.answers.otherText
													: "",
									  }
									: selOption.value,
							},
						});
					} catch (error) {
						handleError(error);
					}
				}}
				onInnerContainerLayout={(ev) => {
					this.setState({ radioGroupWidth: ev.nativeEvent.layout.width });
				}}
			/>
		);
	}

	renderCheckBoxes(options) {
		let checkboxes = [];
		mapObject(options, (answerID, answerText) => {
			const checked = this.state.answers.answers.find((a) => a === answerID) ? true : false;
			checkboxes.push(
				<View key={answerID} style={styles.checkBoxContainer}>
					<CheckBox
						value={checked}
						onValueChange={(newVal) => {
							this.onCheckBoxChange(newVal, answerID);
						}}
						style={styles.checkBoxStyle}
					/>
					<Text
						allowFontScaling={false}
						style={[
							styles.answerText,
							styles.checkBoxText,
							{ fontSize: this.props.resolution === RESOLUTION.HIGH ? 22 : 18 },
						]}
					>
						{answerText}
					</Text>
				</View>
			);
		});
		return checkboxes;
	}

	renderWheelPicker(options) {
		var _options = { data: [] },
			_values = { data: "" };
		mapObject(options, (key, val) => {
			_options.data.push(val);
		});
		const selectedIndex = _options.data.findIndex((i) => i == this.state.answers.answers[0]);
		if (selectedIndex != -1) _values.data = _options.data[selectedIndex];
		return <Picker optionGroups={_options} valueGroups={_values} onChange={this.onPickerSelect.bind(this)} />;
	}

	renderSlider(question) {
		var _value = this.state.answers.answers.length == 0 ? 0 : Number(this.state.answers.answers);
		_value = isNaN(_value) ? 0 : _value;
		const _valueText = _value + "%";
		return (
			<View style={{ marginTop: 30 }}>
				<Text
					allowFontScaling={false}
					style={[
						styles.answerText,
						styles.sliderLabel,
						{
							// fontSize: this.props.resolution === RESOLUTION.HIGH ? 22 : 18,
							width: "100%",
							textAlign: "center",
							alignSelf: "flex-end",
						},
					]}
				>
					{_valueText}
				</Text>
				<View style={{ flexDirection: "row", justifyContent: "center" }}>
					<Text
						allowFontScaling={false}
						style={[
							styles.answerText,
							styles.sliderLabel,
							{
								lineHeight: 40,
								// fontSize: this.props.resolution === RESOLUTION.HIGH ? 22 : 18,
							},
						]}
					>
						{"" + 0 + question.leftLabel}
					</Text>
					<Slider
						className="questionnaire"
						min={0}
						max={100}
						step={1}
						value={_value}
						trackStyle={[{ backgroundColor: "#0C697A" }]}
						railStyle={{ backgroundColor: "#009688" }}
						handleStyle={[{ backgroundColor: "#0B5969", borderColor: "#0B5969" }]}
						onChange={(val) => {
							this.setState({
								answers: { ...this.state.answers, answers: [val] },
							});
						}}
					/>
					<Text
						allowFontScaling={false}
						style={[
							styles.answerText,
							styles.sliderLabel,
							{
								lineHeight: 40,
								// fontSize: this.props.resolution === RESOLUTION.HIGH ? 22 : 18,
							},
						]}
					>
						{"" + 100 + question.rightLabel}
					</Text>
				</View>
			</View>
		);
	}

	renderTextBox(placeHolder, withRadioButton) {
		return (
			<TextInput
				allowFontScaling={false}
				disableFullscreenUI={true}
				onChangeText={(text) => {
					if (!withRadioButton) {
						this.setState({
							answers: {
								...this.state.answers,
								answers: text === "" ? [] : [text],
							},
						});
					} else {
						this.setState({
							answers: {
								...this.state.answers,
								answers: { otherText: text },
							},
						});
					}
				}}
				value={
					withRadioButton && this.state.answers.answers && typeof this.state.answers.answers.otherText === "string"
						? this.state.answers.answers.otherText
						: this.state.answers.answers[0]
				}
				placeholder={placeHolder}
				placeholderTextColor="#B2B2B2"
				editable={true}
				selectTextOnFocus={false}
				maxLength={512}
				underlineColorAndroid="transparent"
				style={[
					styles.answerText,
					styles.textBoxStyle,
					withRadioButton && { width: this.state.radioGroupWidth },
					{ fontSize: this.props.resolution === RESOLUTION.HIGH ? 22 : 18 },
				]}
				allowFontScaling={false}
				returnKeyType="done"
			/>
		);
	}

	renderAnswers(question) {
		switch (question.questionType) {
			case "TEXTBOX":
				return this.renderTextBox(question.placeholderText);
			case "MIXED_RADIO":
				return (
					<View>
						{this.renderRadioGroup(question.options)}
						{this.props.survey[this.state.currentQuestionIndex].questionType === "MIXED_RADIO" &&
							typeof this.state.answers.answers === "object" &&
							typeof this.state.answers.answers.otherText === "string" && (
								<View style={styles.radioTextBoxContainer}>{this.renderTextBox("", true)}</View>
							)}
					</View>
				);
			case "TEXTAREA":
				return this.renderTextArea(question.placeholderText);
			case "RADIOGROUP":
				return this.renderRadioGroup(question.options);
			case "CHECKBOXGROUP":
				return this.renderCheckBoxes(question.options);
			case "WHEELPICKER":
				return this.renderWheelPicker(question.options);
			case "SLIDER":
				return this.renderSlider(question);
			default:
				return null;
		}
	}

	renderSurveyContent() {
		if (this.state.currentQuestionIndex < 0 || this.state.currentQuestionIndex > this.props.survey.length - 1)
			return null;
		const currentQuestion = this.props.survey[this.state.currentQuestionIndex];
		return (
			<View style={{ flex: 1 }}>
				<Text
					allowFontScaling={false}
					style={[
						styles.questionText,
						styles.centeredText,
						{
							marginBottom: this.props.resolution === RESOLUTION.HIGH ? 25 : 10,
							fontSize: this.props.resolution === RESOLUTION.HIGH ? 28 : 24,
						},
					]}
				>
					{currentQuestion.questionText}
				</Text>
				{this.renderAnswers(currentQuestion)}
			</View>
		);
	}

	renderCompletedContent() {
		var main_text = this.props.lang.questionnaire.finish.main;
		main_text = main_text.replace("#1#", "100");

		return (
			<View style={[styles.contentContainer]}>
				<View style={{ flex: 1 }}>
					<Text
						allowFontScaling={false}
						style={[
							styles.questionText,
							styles.centeredText,
							{
								marginBottom: this.props.resolution === RESOLUTION.HIGH ? 25 : 10,
								fontSize: this.props.resolution === RESOLUTION.HIGH ? 28 : 24,
							},
						]}
					>
						{this.props.lang.questionnaire.finish.title}
					</Text>
					<Text
						allowFontScaling={false}
						style={[
							styles.answerText,
							{
								fontSize: this.props.resolution === RESOLUTION.HIGH ? 22 : 18,
								marginTop: 30,
							},
						]}
					>
						{main_text}
					</Text>
				</View>
				{this.renderButtons()}
			</View>
		);
	}

	renderContent() {
		return (
			<View style={[styles.contentContainer]}>
				<ScrollView
					style={{
						marginBottom: this.props.resolution === RESOLUTION.HIGH ? 30 : 10,
					}}
					contentContainerStyle={{ flex: 1 }}
					showsVerticalScrollIndicator={false}
				>
					{this.renderSurveyContent()}
				</ScrollView>
				{this.renderButtons()}
			</View>
		);
	}

	renderMain() {
		return (
			<View style={[styles.container]}>
				{this.state.completed && this.renderCompletedContent()}
				{!this.state.completed && this.renderContent()}
			</View>
		);
	}

	render() {
		return <View style={{ flex: 1 }}>{this.renderMain()}</View>;
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { flex: 1, marginTop: 10 },
	contentContainer: { flex: 1, paddingHorizontal: 60 },
	buttonContainer: {
		flexDirection: "row",
		paddingHorizontal: 80,
		marginBottom: 20,
	},
	questionText: {
		fontFamily: f_sourcesansprobold,
		fontSize: 28,
		marginBottom: 25,
		color: "#F1F1F1",
	},
	answerText: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: 22,
		color: "#B2B2B2",
		marginBottom: 10,
	},
	centeredText: {
		textAlign: "center",
	},
	textAreaStyle: {
		flex: 1,
		fontFamily: f_sourcesansprosemibold,
		fontSize: 22,
		marginHorizontal: 30,
		borderWidth: 3,
		borderColor: "#4b0337",
		borderRadius: 9,
		padding: 8,
		textAlignVertical: "top",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		outlineWidth: 0,
		backgroundColor: "#5d0344",
	},
	radioTextBoxContainer: {
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 10,
		marginBottom: 5,
	},
	textBoxStyle: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: 22,
		marginHorizontal: 30,
		borderWidth: 3,
		borderColor: "#4b0337",
		borderRadius: 9,
		padding: 8,
		textAlignVertical: "center",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		outlineWidth: 0,
		backgroundColor: "#5d0344",
	},
	checkBoxContainer: {
		flexDirection: "row",
		paddingHorizontal: 40,
		marginBottom: 10,
		alignItems: "center",
	},
	checkBoxStyle: {
		width: 30,
		height: 30,
		backgroundColor: "blue",
		alignSelf: "center",
	},
	checkBoxText: {
		marginLeft: 10,
		marginBottom: 0,
	},
	sliderLabel: { fontSize: 14, marginBottom: 0 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		survey: state.questionnaire.survey,
		surveyAnswers: state.questionnaire.surveyAnswers,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = { closeQuestionnaire, saveAnswer };

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
