import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprolight,
	processState,
	RESOLUTION,
} from "../../../../config/defaults";
import { gameDimensions, updateLocationDimensions } from "../../../../config/dimensions";
import { TEXT_INPUT } from "../../../../config/images";
import CheckBox from "../../../../libraries/CheckBox";
import { closeUpdateLocation, goBackToMain, updateLocation } from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { ColoredButton, CountryListDropDown, NavigationHeader, Spinner } from "../../../common";
import { handleError } from "../../../ErrorHandler";

class UpdateLocation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			city: this.props.profileSettings.city,
			country: this.props.profileSettings.country,
			isCountryVisible: this.props.profileSettings.isCountryVisible,
			isCityVisible: this.props.profileSettings.isCityVisible,
			isCountryListExpanded: false,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("UpdateProfileLocation");
	}
	//#endregion

	//#region events
	onUpdatePress() {
		this.props.updateLocation({
			country: this.state.country,
			city: this.state.city,
			isCountryVisible: this.state.isCountryVisible,
			isCityVisible: this.state.isCityVisible,
		});
	}

	onCityChange(city) {
		this.setState({ city });
	}

	onCountryChange(countryID) {
		this.setState({ country: countryID });
	}

	toggleCountryList(show) {
		this.setState({ isCountryListExpanded: show });
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateLocationState != processState.STARTED}
				backAction={this.props.closeUpdateLocation}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={false}
					additionalTextStyle={_buttonTextStyle}
				/>
			</View>
		);
	}

	renderCountryField() {
		var _innerContainerStyle = styles.dataInnerContainer,
			_inputLabel = [styles.dataTitle, styles.dataText];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { height: 80 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
		}
		return (
			<View
				style={[
					_innerContainerStyle,
					Platform.OS === "web"
						? { position: "absolute", top: 0, zIndex: 5, alignSelf: "center" }
						: Platform.OS === "ios"
						? { zIndex: 5 }
						: {},
				]}
			>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.locationUpdate.country}
				</Text>
				<CountryListDropDown
					lang={this.props.lang}
					initialSelected={this.state.country.toLowerCase()}
					onChange={this.onCountryChange.bind(this)}
					onToggle={this.toggleCountryList.bind(this)}
					resolution={this.props.resolution}
				/>
			</View>
		);
	}

	renderCityField() {
		var _innerContainerStyle = styles.dataInnerContainer,
			_inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = [styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { height: 80 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26, width: 400 }, Platform.OS === "android" && { paddingTop: 13 }];
		}
		return (
			<View
				style={[
					_innerContainerStyle,
					Platform.OS === "web"
						? {
								marginTop: this.props.resolution === RESOLUTION.HIGH ? 100 : 60,
						  }
						: {},
				]}
			>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.locationUpdate.city.title}
				</Text>
				<ImageBackground
					source={TEXT_INPUT}
					resizeMode="stretch"
					style={{
						width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300,
						height: !this.state.isCountryListExpanded ? 50 : 0,
					}}
				>
					<TextInput
						allowFontScaling={false}
						disableFullscreenUI={true}
						onChangeText={(text) => this.onCityChange(text)}
						value={this.state.city}
						editable={true}
						selectTextOnFocus={true}
						underlineColorAndroid="transparent"
						style={[
							_inputStyle,
							{
								height: !this.state.isCountryListExpanded ? 50 : 0,
								paddingTop: !this.state.isCountryListExpanded
									? Platform.OS === "web"
										? 0
										: Platform.OS === "ios"
										? 0
										: 13
									: 0,
							},
							Platform.OS === "web" ? { outlineWidth: 0 } : {},
						]}
						placeholder={this.props.lang.locationUpdate.city.placeholder}
						placeholderTextColor="#B2B2B2"
					/>
				</ImageBackground>
			</View>
		);
	}

	renderCityPublicCheck() {
		var _innerContainerStyle = styles.dataInnerContainer,
			_inputLabel = [styles.dataTitle, styles.dataText],
			_checkBoxStyle = [
				Platform.OS === "web" ? styles.checkBox : { transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }] },
			];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { height: 80 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_checkBoxStyle = [
				Platform.OS === "web"
					? [styles.checkBox, { width: 30, height: 30 }]
					: { transform: [{ scale: Platform.OS === "ios" ? 1 : 1.75 }] },
			];
		}
		return (
			<View style={[_innerContainerStyle, { alignItems: "center" }]}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.locationUpdate.pubCity}
				</Text>
				<View style={[styles.checkboxContainer, { width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300 }]}>
					{!this.state.isCountryListExpanded && (
						<CheckBox
							value={this.state.isCityVisible}
							onValueChange={() => this.setState({ isCityVisible: !this.state.isCityVisible })}
							style={_checkBoxStyle}
						/>
					)}
				</View>
			</View>
		);
	}

	renderCountryPublicCheck() {
		var _innerContainerStyle = styles.dataInnerContainer,
			_inputLabel = [styles.dataTitle, styles.dataText],
			_checkBoxStyle = [
				Platform.OS === "web" ? styles.checkBox : { transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }] },
			];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { height: 80 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_checkBoxStyle = [
				Platform.OS === "web"
					? [styles.checkBox, { width: 30, height: 30 }]
					: { transform: [{ scale: Platform.OS === "ios" ? 1 : 1.75 }] },
			];
		}
		return (
			<View style={[_innerContainerStyle, { alignItems: "center" }]}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.locationUpdate.pubCountry}
				</Text>
				<View style={[styles.checkboxContainer, { width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300 }]}>
					{!this.state.isCountryListExpanded && (
						<CheckBox
							value={this.state.isCountryVisible}
							onValueChange={() =>
								this.setState({
									isCountryVisible: !this.state.isCountryVisible,
								})
							}
							style={_checkBoxStyle}
							disabled={true}
						/>
					)}
				</View>
			</View>
		);
	}

	renderContent() {
		return (
			<View
				style={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				{this.renderCountryField()}
				{this.renderCityField()}
				{this.renderCountryPublicCheck()}
				{this.renderCityPublicCheck()}
				{this.renderUpdateButton()}
			</View>
		);
	}

	render() {
		if (this.props.updateLocationState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (updateLocationDimensions.width > availableWidth || updateLocationDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / updateLocationDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / updateLocationDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	contentContainer: { flex: 1, paddingHorizontal: 20 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	checkboxContainer: {
		width: 300,
		height: 40,
		flexDirection: "row",
		paddingTop: 5,
	},
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 20,
		borderRadius: 5,
		width: 300,
		height: 50,
		marginRight: 2,
		// paddingTop: Platform.OS === "web" ? 0 : 13,
		paddingHorizontal: 8,
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	checkBox: { width: 20, height: 20, alignSelf: "center" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		profileSettings: state.currentUser.profileSettings,
		updateLocationState: state.app.updateLocationState,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeUpdateLocation,
	goBackToMain,
	updateLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLocation);
