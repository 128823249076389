import { cloneDeep } from "lodash";
import { Platform } from "react-native";
import {
	showRunningGamesInListUpdated,
	soundsPreferencesUpdated,
	subcribeToGlobalChat,
	updateShowRunningGamesInListFailed,
	updateSoundsPreferencesFailed,
	upgradeAccountSucceeded,
} from ".";
import { handleError } from "../../components/ErrorHandler";
import { ACC_URL, DEV_URL, PRD_URL, TST_URL, WS_URL } from "../../config/connection";
import { modalType, processState, reconnectWaitTime, SETTINGS_SCREEN, storeKeys, tabType } from "../../config/defaults";
import { DEBUG_ENABLED, LOGGING_ENABLED } from "../../config/permissions";
import { mySounds } from "../../config/sounds";
import { MyWebSocket } from "../../connection";
import DebugLogger from "../../controller/DebugLogger";
import { SPECTATED_GAME_CLOSED } from "../../Keezers/redux/actions/actionTypes";
import { getBoardCoordinates } from "../../Keezers/redux/actions/FetchDataActions";
import {
	finishCountdown,
	finishLateMoveInShop,
	gameInfoReceived,
	gameStartedReceived,
	requestReopenGame,
	timeout,
} from "../../Keezers/redux/actions/KeezersActions";
import { storeData } from "../../libraries/AsyncStorage";
import SecureStorage from "../../libraries/ReactSecureStorage";
import EncryptedStorage from "../../libraries/ReactNativeEncryptedStorage";
import { store } from "../../redux/store";
import {
	ADD_URL_TO_SOCKET_LIST,
	AUTHENTICATE_START,
	AUTHENTICATE_SUCCEEDED,
	CLEAR_WEBSOCKET,
	CONNECTION_ERROR_DETECTED,
	CONNECTION_FAILED,
	CONNECTION_SUCCESSFULL,
	CONNECT_TO_NEXT_URL,
	CONNECT_TO_WEBSOCKET,
	INCREASE_CLIENT_MESSAGES_COUNTER,
	INCREASE_CONNECTION_TRIES,
	MESSAGE_SENT,
	PROCESS_ACK_EVENT,
	PURCHASE_NOT_VALIDATED,
	RECONNECT_FINISHED,
	RECONNECT_START,
	REMOVE_SAVED_EVENT,
	REMOVE_URL_FROM_SOCKET_LIST,
	RESET_CLIENT_MESSAGES_COUNTER,
	RESET_CONNECTION_TRIES,
	SOCKET_CONNECTION_CLOSED,
	SOCKET_CONNECTION_ESTABLISHED,
	UPGRADE_REQUIRED,
} from "./actionTypes";
import {
	avatarDeletedSuccessfully,
	avatarUpdatedSuccessfully,
	changePasswordFailed,
	deleteAvatarFailed,
	emailLoginFailed,
	emailLoginSucceeded,
	emailSignupFailed,
	emailSignupSucceeded,
	facebookLoginSucceeded,
	goBackToMain,
	languageUpdatedSuccessfully,
	locationUpdatedSuccessfully,
	networkRecovered,
	offerTutorial,
	personalDataUpdatedSuccessFully,
	resetClient,
	resetPasswordFailed,
	resetPasswordSucceeded,
	sendMyUsernameFailed,
	sendMyUsernameSucceeded,
	setLoggedInState,
	startUpgrade,
	updateAppLanguageFailed,
	updateAppNotificationsFailed,
	updateAvatarFailed,
	updateCardSortOrderFailed,
	updateChatTranslationFailed,
	updateDefaultCardsetFailed,
	updateEmailNotificationsFailed,
	updateFixedPositionsFailed,
	updateLanguageFailed,
	updateLocationFailed,
	updateOverrideCardsetFailed,
	updatePersonalDataFailed,
	updateResolutionFailed,
	updateUsernameFailed,
	upgradeAccountFailed,
	usernameUpdatedSuccessFully,
} from "./AppActions";
import { preparePublishGameJSON, resetCreateNewGame, saveAvailableBots } from "./CreateNewGameActions";
import { addLog } from "./DebugActions";
import { setFilterLabels, subscribeToGameList, updatePlayerAmountFilter } from "./GameListDataActions";
import { gameJoinedReceived, gamePublishedReceived, leaveGame } from "./GameRoomActions";
import { buyItemFailed, clearInShopLateMoveTimeout, openChipsShop, openGoldShop } from "./GameShopActions";
import { addJoinedRoom, closeChatRoomTab, removeUserFromChatRoom } from "./GlobalChatActions";
import { closeModalDialog, openModalDialog } from "./ModalActions";
import { openGameLobby, openTab } from "./NavigationActions";
import {
	appLanguageUpdated,
	appNotificationsUpdated,
	cardsOrderUpdated,
	chatTranslationUpdated,
	defaultCardsetUpdated,
	emailNotificationsUpdated,
	fixedPositionsUpdated,
	logOff,
	overrideCardsetUpdated,
	processPlayerMod,
	requestSettings,
	saveEmailPassword,
	saveEmailUsername,
	saveUserDetails,
	screenResolutionUpdated,
	setFeatures,
	updateBanList,
	updateFriendList,
} from "./UserActions";

export const initConnection = (environment = "", socket = "") => {
	return (dispatch) => {
		if (typeof WS_URL === "string" && WS_URL !== "") {
			if (store.getState().socket.wsURLs.length <= 0) {
				var socketURL = WS_URL;
				switch (environment.toUpperCase()) {
					case "DEV":
						socketURL = DEV_URL;
						break;
					case "ACC":
						socketURL = ACC_URL;
						break;
					case "PRD":
						socketURL = PRD_URL;
						break;
					case "TST":
						socketURL = TST_URL;
						break;
					default:
						socketURL = WS_URL;
						break;
				}
				if (socket != "") {
					socketURL = "wss://" + socket + "/keezers/";
				}
				dispatch(addURLToSocketList(socketURL));
			}
		}
	};
};

export const addURLToSocketList = (url, _connectionID) => {
	return (dispatch) => {
		dispatch({ type: ADD_URL_TO_SOCKET_LIST, payload: { url, _connectionID } });
	};
};

export const removeURLFromSocketList = (url) => {
	return { type: REMOVE_URL_FROM_SOCKET_LIST, payload: { url } };
};

export const connectToWebsocket = (eventHandlers, _connectionID) => {
	return (dispatch) => {
		const { wsURLs, connectionID } = store.getState().socket;
		var url = wsURLs[connectionID];
		if (!isNaN(_connectionID)) {
			url = wsURLs[_connectionID];
		}
		dispatch({
			type: CONNECT_TO_WEBSOCKET,
			payload: { url, eventHandlers, _connectionID },
		});
	};
};

export const socketConnectionEstablished = () => {
	return { type: SOCKET_CONNECTION_ESTABLISHED };
};

export const socketConnectionClosed = () => {
	return { type: SOCKET_CONNECTION_CLOSED };
};

export const connectionSuccessfull = () => {
	return { type: CONNECTION_SUCCESSFULL };
};

export const connectionFailed = () => {
	return { type: CONNECTION_FAILED };
};

export const increaseConnectionTries = () => {
	return { type: INCREASE_CONNECTION_TRIES };
};

export const resetConnectionTries = () => {
	return { type: RESET_CONNECTION_TRIES };
};

export const sendMessage = (data, screen, moveToSave, shopData) => {
	return (dispatch) => {
		if (MyWebSocket.shared.ws !== null) {
			if (DEBUG_ENABLED) {
				const _data = cloneDeep(data);
				if (data.type == "login" || data.type == "registerGuest") {
					_data.password = "************************************************";
				}
				console.log(
					`!!! sendMessage entered with data = ${JSON.stringify(
						_data
					)}, screen = ${screen}, moveToSave = ${moveToSave}, shopData = ${shopData} in SocketActions.js !!!`
				);
				console.log("SENT: ", JSON.stringify(_data));
				if (LOGGING_ENABLED) {
					dispatch(addLog({ title: "SENT:", content: _data }));
				}
			}
			dispatch(sentMessage(data, screen, moveToSave, shopData));
			MyWebSocket.shared.ws.send(JSON.stringify(data));
			if (data.type == "connect" && data.resumeReason) delete data.resumeReason;
		}
	};
};

export const increase_cMessageID = () => {
	return {
		type: INCREASE_CLIENT_MESSAGES_COUNTER,
	};
};

export const startAuthenticate = () => {
	return { type: AUTHENTICATE_START };
};

export const authenticateSucceeded = (userDetailsMessage, isAuthenticateRefreshing) => {
	return async (dispatch) => {
		const { modal, socket, tab, startedGame, app, gameShop } = store.getState();
		const lang = store.getState().language.currentLanguage;

		//check if this is a response to any of the login messages
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == userDetailsMessage.cMessageID);
		userDetailsMessage.isGuestUser = false;
		if (responseTo != undefined) {
			if (app.emailLoginStarted) {
				dispatch(emailLoginSucceeded(responseTo));
			} else if (app.emailSignupStarted) {
				storeData(storeKeys.EMAIL_USERNAME, responseTo.username);
				if (Platform.OS === "web") {
					SecureStorage.setItem(storeKeys.EMAIL_PWD, responseTo.password);
				} else {
					await EncryptedStorage.setItem(storeKeys.EMAIL_PWD, responseTo.password);
				}
				// storeData(storeKeys.EMAIL_PWD, responseTo.password);
				storeData(storeKeys.EMAIL_IS_LOGGED_IN, "true");

				dispatch(saveEmailUsername(responseTo.username));
				dispatch(saveEmailPassword(responseTo.password));
				dispatch(emailSignupSucceeded());
				// offer tutorial
				dispatch(offerTutorial());
			} else if (app.upgradeAccountStarted) {
				storeData(storeKeys.EMAIL_USERNAME, responseTo.username);
				if (Platform.OS === "web") {
					SecureStorage.setItem(storeKeys.EMAIL_PWD, responseTo.password);
				} else {
					await EncryptedStorage.setItem(storeKeys.EMAIL_PWD, responseTo.password);
				}
				// storeData(storeKeys.EMAIL_PWD, responseTo.password);
				storeData(storeKeys.EMAIL_IS_LOGGED_IN, "true");

				dispatch(saveEmailUsername(responseTo.username));
				dispatch(saveEmailPassword(responseTo.password));
				dispatch(upgradeAccountSucceeded());
				// offer tutorial
				dispatch(offerTutorial());
			} else if (app.facebookLoginStarted) {
				dispatch(facebookLoginSucceeded());
			} else if (responseTo.type == "guestLogin") {
				userDetailsMessage.isGuestUser = true;
				// offer tutorial
				dispatch(offerTutorial());
			} else if (responseTo.type == "changePassword") {
				if (Platform.OS === "web") {
					SecureStorage.setItem(storeKeys.EMAIL_PWD, responseTo.password);
				} else {
					await EncryptedStorage.setItem(storeKeys.EMAIL_PWD, responseTo.password);
				}
				// storeData(storeKeys.EMAIL_PWD, responseTo.password);
				dispatch(saveEmailPassword(responseTo.password));
				dispatch(goBackToMain());
			}
			dispatch(removeSavedEvent(responseTo.cMessageID));
		}

		dispatch({ type: AUTHENTICATE_SUCCEEDED, payload: userDetailsMessage });
		dispatch(saveUserDetails(userDetailsMessage));
		var linkID = "MyProfile_";
		if (!isAuthenticateRefreshing) {
			dispatch(getBoardCoordinates());
			if (modal.visibleModal) dispatch(closeModalDialog());
			dispatch(setFilterLabels([lang.yes, lang.no], [lang.normal, lang.mellow]));
			dispatch(updatePlayerAmountFilter());

			if (socket.isReconnecting == false) {
				if (app.isPlayStarted) {
					if (tab.isShopOpen) MyWebSocket.shared.requestShopDetails();
					const { isSubscribeInProgress } = store.getState().gameListData;
					if (tab.isLobbyOpen && !isSubscribeInProgress) dispatch(subscribeToGameList());
					if (tab.isGlobalChatOpen) {
						dispatch(subcribeToGlobalChat());
						dispatch(openTab(tabType.CHAT));
					}
					if (tab.isCreateNewGameOpen || tab.isGameRoomOpen) dispatch(openGameLobby());
				} else {
					dispatch(goBackToMain());
				}
			}

			//checking if player is disconnected from a started game accidentally
			if (typeof userDetailsMessage.openGameID === "string" && userDetailsMessage.openGameID !== "0") {
				if (startedGame.gameID != -1 && tab.isGameStarted && startedGame.gameID == userDetailsMessage.openGameID) {
					dispatch(requestReopenGame());
				} else {
					// dispatch(startPlay());
					// dispatch(openStartedGame());
				}

				var rejoinGameMsg = {
					type: "rejoinGame",
					sMessageID: 0,
					gameID: userDetailsMessage.openGameID,
				};
				if (app.recoveringNetwork === true) {
					rejoinGameMsg.reason = "reconnect";
					dispatch(networkRecovered());
				} else {
					rejoinGameMsg.reason = "reload";
				}
				MyWebSocket.shared.sendMsg(rejoinGameMsg);
			}
		}
	};
};

export const processGameCreated = (msgJSON) => {
	return (dispatch) => {
		try {
			if (store.getState().tab.isCreateNewGameOpen) {
				const features = {
					specific:
						msgJSON.data && msgJSON.data.specific && msgJSON.data.specific.features
							? msgJSON.data.specific.features
							: {},
					generic:
						msgJSON.data && msgJSON.data.generic && msgJSON.data.generic.features ? msgJSON.data.generic.features : {},
					disabledFeatureText:
						msgJSON.data && msgJSON.data.generic && msgJSON.data.generic.disabledFeatureText
							? msgJSON.data.generic.disabledFeatureText
							: {},
				};
				dispatch(setFeatures(features));
				if (msgJSON.data && msgJSON.data.generic) {
					dispatch(saveAvailableBots(msgJSON.data.generic.availableBots));
				}
				dispatch(preparePublishGameJSON(msgJSON));
			}
		} catch (error) {
			handleError(error);
		}
	};
};

export const processGamePublished = (msgJSON) => {
	return (dispatch) => {
		dispatch(gamePublishedReceived(msgJSON));
	};
};

export const processGameJoined = (msgJSON) => {
	return (dispatch) => {
		dispatch(gameJoinedReceived(msgJSON));
	};
};

export const processGameStarted = (msgJSON) => {
	return (dispatch) => {
		dispatch(gameStartedReceived(msgJSON));
	};
};

export const processGameInfo = (msgJSON) => {
	return (dispatch) => {
		dispatch(gameInfoReceived(msgJSON));
	};
};

export const processChatRoomJoined = (msg) => {
	return (dispatch) => {
		dispatch(addJoinedRoom(msg));
	};
};

export const processChatRoomLeft = (msg) => {
	return (dispatch) => {
		if (msg.iLeft) {
			var rooms = store.getState().globalChat.joinedChatRooms;
			var room = rooms.find((r) => r.chatRoomID == msg.chatRoomID);
			if (room != undefined) dispatch(closeChatRoomTab(room));
		} else {
			dispatch(removeUserFromChatRoom(msg));
		}
	};
};

export const processChatRoomCreated = (msg) => {
	return (dispatch) => {
		switch (msg.roomType) {
			case "NORMAL":
			case "PRIVATE":
				dispatch(addJoinedRoom(msg));
				break;
			case "LOCALIZED":
			default:
				break;
		}
	};
};

export const processAckEvent = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && (responseTo.type == "playerMod" || responseTo.type == "gamePlayerKick")) {
			responseTo = cloneDeep(responseTo);
			dispatch(processPlayerMod(responseTo));
		}
		dispatch(_processAckEvent(msg));
	};
};

export const handleSuccessEvent = (msg) => {
	return (dispatch) => {
		switch (msg.page) {
			case "sendMessage":
				if (
					typeof mySounds.messageSend !== "undefined" &&
					mySounds.messageSend != null &&
					mySounds.messageSend.isLoaded() == true
				) {
					mySounds.messageSend.play();
				}
				break;
			case "leaveGame":
				const { tab } = store.getState();
				if (tab.isCreateNewGameOpen) {
					dispatch(openGameLobby());
					dispatch(resetCreateNewGame());
				} else {
					if (!tab.isGameEndOpened) {
						if (
							typeof mySounds.playerLeavesSound !== "undefined" &&
							mySounds.playerLeavesSound != null &&
							mySounds.playerLeavesSound.isLoaded() == true
						) {
							mySounds.playerLeavesSound.play();
						}
					}
				}
				var responseTo = store
					.getState()
					.socket.sentMsg.find((m) => m.type == "leaveGame" && m.cMessageID == msg.cMessageID);
				if (responseTo != undefined) dispatch(removeSavedEvent(responseTo.cMessageID));
				break;
			case "recoverUsername":
				dispatch(sendMyUsernameSucceeded(msg));
				break;
			case "recoverPassword":
				dispatch(resetPasswordSucceeded(msg));
				break;
			case "addToBanList":
				dispatch(updateBanList(msg, "ADD"));
				break;
			case "removeFromBanList":
				dispatch(updateBanList(msg, "REMOVE"));
				break;
			case "addToFriendList":
				dispatch(updateFriendList(msg, "ADD"));
				break;
			case "removeFromFriendList":
				dispatch(updateFriendList(msg, "REMOVE"));
				break;
			case "updateUsername":
				dispatch(usernameUpdatedSuccessFully(msg));
				break;
			case "updatePersonalData":
				dispatch(personalDataUpdatedSuccessFully(msg));
				break;
			case "updateLocation":
				dispatch(locationUpdatedSuccessfully(msg));
				break;
			case "updateLanguage":
				dispatch(languageUpdatedSuccessfully(msg));
				break;
			case "updateAvatar":
				dispatch(avatarUpdatedSuccessfully(msg));
				break;
			case "deleteAvatar":
				dispatch(avatarDeletedSuccessfully(msg));
				break;
			case "updateSettings":
				var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
				if (responseTo != undefined) {
					switch (responseTo.clientScreen) {
						case SETTINGS_SCREEN.UPDATE_SOUNDS_PREFERENCES:
							dispatch(soundsPreferencesUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_APP_LANGUAGE:
							dispatch(appLanguageUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_CHAT_TRANSLATION:
							dispatch(chatTranslationUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_EMAIL_NOTIFICATIONS:
							dispatch(emailNotificationsUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_APP_NOTIFICATIONS:
							dispatch(appNotificationsUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_CARDS_ORDER:
							dispatch(cardsOrderUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_SCREEN_RESOLUTION:
							dispatch(screenResolutionUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_DEFAULT_CARDSET:
							dispatch(defaultCardsetUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_OVERRIDE_CARDSET:
							dispatch(overrideCardsetUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_FIXED_POSITIONS:
							dispatch(fixedPositionsUpdated(responseTo));
							break;
						case SETTINGS_SCREEN.UPDATE_SHOW_RUNNING_GAMES_IN_LIST:
							dispatch(showRunningGamesInListUpdated(responseTo));
							break;
					}
					dispatch(removeSavedEvent(responseTo.cMessageID));
					dispatch(requestSettings());
				}
				break;
		}
	};
};

export const processErrorEvent = (msg) => {
	return (dispatch) => {
		var notifObj = null;
		var linkInText = false;
		var _shopCatLink = "";
		var isFullscreenLink = false;
		const lang = store.getState().language.currentLanguage;
		dispatch(finishReconnect());
		if (msg.error !== undefined) {
			notifObj =
				lang.serverError !== undefined && typeof lang.serverError[msg.error] !== "undefined"
					? lang.serverError[msg.error]
					: { main_text: msg.error.split("_").join(" ") };
			if (msg.error == "CANNOT_UPDATE_BANLIST_MAX_REACHED") {
				linkInText = true;
				_shopCatLink = "cat_misc";
			}
			if (msg.error == "CANNOT_UPDATE_FRIENDLIST_MAX_REACHED") {
				linkInText = true;
				_shopCatLink = "cat_misc";
			}
			if (msg.error == "CANNOT_BUY_ITEM_NOT_ENOUGH_SILVER") {
				linkInText = true;
				_shopCatLink = "cat_silver";
			}
			if (msg.error == "CANNOT_BUY_ITEM_NOT_ENOUGH_GOLD") {
				linkInText = true;
				_shopCatLink = "cat_gold";
			}
		} else {
			notifObj = { main_text: msg.title, extra_text: msg.text };
		}

		const { app } = store.getState();
		if (app.emailUsernameState == processState.STARTED) {
			dispatch(sendMyUsernameFailed(msg.text));
		}
		if (app.resetPasswordState == processState.STARTED) {
			dispatch(resetPasswordFailed(msg.text));
		}
		if (app.changePasswordState == processState.STARTED) {
			dispatch(changePasswordFailed(msg));
		}
		if (app.updateUsernameState == processState.STARTED) {
			dispatch(updateUsernameFailed(msg.text));
		}

		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (
			msg.isFieldError === false &&
			app.resetPasswordState !== processState.STARTED &&
			app.changePasswordState !== processState.STARTED &&
			app.emailUsernameState !== processState.STARTED &&
			app.updateUsernameState !== processState.STARTED &&
			app.upgradeAccountState !== processState.STARTED
		) {
			if (responseTo == undefined || (responseTo.type !== "rejoinGame" && responseTo.type !== "spectateGame")) {
				if (msg.exceptionType === "cannotRegisterTournamentNotEnoughChips") {
					dispatch(
						openModalDialog(
							modalType.ERROR,
							notifObj,
							lang.ok,
							() => {
								return;
							},
							lang.goToShop,
							() => {
								dispatch(openTab(tabType.SHOP));
								dispatch(openChipsShop());
							}
						)
					);
				} else if (msg.exceptionType === "cannotBuyItemNotEnoughGold") {
					dispatch(
						openModalDialog(
							modalType.ERROR,
							notifObj,
							lang.ok || "",
							() => {
								const { isSignupContinued, emailLoginStarted, upgradeAccountStarted } = store.getState().app;
								if (isSignupContinued) {
									dispatch(emailSignupFailed(msg));
								}
								if (emailLoginStarted) {
									dispatch(emailLoginFailed(msg));
								}
								if (upgradeAccountStarted) {
									dispatch(upgradeAccountFailed(msg));
								}
							},
							lang.buyGold,
							() => {
								dispatch(openTab(tabType.SHOP));
								dispatch(openGoldShop());
							},
							linkInText,
							isFullscreenLink,
							_shopCatLink
						)
					);
				} else {
					dispatch(
						openModalDialog(
							modalType.ERROR,
							notifObj,
							lang.ok || "",
							() => {
								const { isSignupContinued, emailLoginStarted, upgradeAccountStarted } = store.getState().app;
								if (isSignupContinued) {
									dispatch(emailSignupFailed(msg));
								}
								if (emailLoginStarted) {
									dispatch(emailLoginFailed(msg));
								}
								if (upgradeAccountStarted) {
									dispatch(upgradeAccountFailed(msg));
								}
							},
							"",
							null,
							linkInText,
							isFullscreenLink,
							_shopCatLink
						)
					);
				}
			}
		} else {
			const { isSignupContinued, emailLoginStarted, upgradeAccountStarted } = store.getState().app;
			if (isSignupContinued) {
				dispatch(emailSignupFailed(msg));
			}
			if (emailLoginStarted) {
				const responseTo = store
					.getState()
					.socket.sentMsg.find((m) => m.type == "login" || m.cMessageID == msg.cMessageID);
				if (responseTo) {
					if (responseTo.sendFromForm === false) {
						dispatch(openModalDialog(modalType.ERROR, notifObj));
					}
					dispatch(removeSavedEvent(msg.cMessageID, responseTo.type));
				}
				dispatch(emailLoginFailed(msg));
			}
			if (upgradeAccountStarted) {
				const responseTo = store
					.getState()
					.socket.sentMsg.find((m) => m.type == "registerGuest" || m.cMessageID == msg.cMessageID);
				if (responseTo) {
					// dispatch(openModalDialog(modalType.ERROR, notifObj));
					dispatch(removeSavedEvent(msg.cMessageID, responseTo.type));
				}
				dispatch(upgradeAccountFailed(msg));
			}
		}

		if (responseTo != undefined) {
			switch (responseTo.type) {
				case "addCredit":
					if (Platform.OS !== "web") {
						dispatch({
							type: PURCHASE_NOT_VALIDATED,
							payload: { purchaseToken: responseTo.token },
						});
					}
					break;
				case "spectateGame":
					dispatch(removeSavedEvent(responseTo.cMessageID));
					break;
				case "rejoinGame":
					dispatch(leaveGame(false));
					break;
				case "publishGame":
					dispatch(leaveGame());
					break;
				case "joinGame":
					dispatch(openGameLobby());
					break;
				// case "login":
				case "facebookLogin":
					dispatch(goBackToMain());
					dispatch(logOff());
					break;
				case "updateUsername":
					dispatch(updateUsernameFailed(msg.text));
					break;
				case "updateLanguage":
					dispatch(updateLanguageFailed(msg.text));
					break;
				case "updateLocation":
					dispatch(updateLocationFailed());
					break;
				case "updatePersonalData":
					dispatch(updatePersonalDataFailed());
					break;
				case "updateAvatar":
					dispatch(updateAvatarFailed(msg.text));
					break;
				case "deleteAvatar":
					dispatch(deleteAvatarFailed(msg.text));
					break;
				case "updateSettings":
					switch (responseTo.clientScreen) {
						case SETTINGS_SCREEN.UPDATE_SOUNDS_PREFERENCES:
							dispatch(updateSoundsPreferencesFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_APP_LANGUAGE:
							dispatch(updateAppLanguageFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_CHAT_TRANSLATION:
							dispatch(updateChatTranslationFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_EMAIL_NOTIFICATIONS:
							dispatch(updateEmailNotificationsFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_APP_NOTIFICATIONS:
							dispatch(updateAppNotificationsFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_CARDS_ORDER:
							dispatch(updateCardSortOrderFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_SCREEN_RESOLUTION:
							dispatch(updateResolutionFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_DEFAULT_CARDSET:
							dispatch(updateDefaultCardsetFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_OVERRIDE_CARDSET:
							dispatch(updateOverrideCardsetFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_FIXED_POSITIONS:
							dispatch(updateFixedPositionsFailed());
							break;
						case SETTINGS_SCREEN.UPDATE_SHOW_RUNNING_GAMES_IN_LIST:
							dispatch(updateShowRunningGamesInListFailed());
							break;
					}
					if (!msg.disconnectClient) dispatch(requestSettings());
					break;
				case "purchaseShopItemWithSilver":
				case "purchaseShopItemWithGold":
					dispatch(buyItemFailed(responseTo.shopData));
					break;
			}

			dispatch(removeSavedEvent(msg.cMessageID));
		}

		if (typeof msg.disconnectClient === "boolean" && msg.disconnectClient === true) {
			if (store.getState().tab.isGameStarted != true || store.getState().startedGame.isSpectator) {
				dispatch(finishCountdown());
				if (timeout) clearTimeout(timeout);
				dispatch(finishLateMoveInShop());
				clearInShopLateMoveTimeout();
				const { countdownSound } = mySounds;
				if (typeof countdownSound !== "undefined" && countdownSound != null && countdownSound.isLoaded() == true) {
					countdownSound.stop();
				}
				dispatch(setLoggedInState(false));
				dispatch(resetClient());
			}
			if (msg.details && typeof msg.details.clientVersion === "string" && typeof msg.details.minVersion === "string") {
				const _minVersion = Number(msg.details.minVersion);
				const _clientVersion = Number(msg.details.clientVersion);
				if (!isNaN(_minVersion) && !isNaN(_clientVersion) && _clientVersion < _minVersion) {
					const _msgText =
						typeof msg.text === "string" && msg.text !== ""
							? { main_text: msg.text }
							: lang.messages.updateAppWarningMessage;
					dispatch({ type: UPGRADE_REQUIRED });
					dispatch(openModalDialog(modalType.ERROR, _msgText, lang.updNow, startUpgrade));
				}
			}
		}
	};
};

export const removeSavedEvent = (cMessageID, mType) => {
	return { type: REMOVE_SAVED_EVENT, payload: { cMessageID, mType } };
};

export const processGameClosedEvent = (msg) => {
	return (dispatch) => {
		const { isGameRoomOpen, isGameStarted } = store.getState().tab;
		const { isSpectator, gameID } = store.getState().startedGame;
		const { grIsGameCreator, gameRoomsGameId } = store.getState().gameRoom;
		const lang = store.getState().language.currentLanguage;
		const notifObj = { main_text: msg.title, extra_text: msg.text };
		if (isGameRoomOpen && !grIsGameCreator && gameRoomsGameId == msg.gameID) {
			dispatch(
				openModalDialog(modalType.ERROR, notifObj, lang.ok, () => {
					if (!grIsGameCreator) dispatch(leaveGame());
				})
			);
		} else if (isGameStarted && isSpectator && gameID == msg.gameID) {
			dispatch({ type: SPECTATED_GAME_CLOSED });
		} else {
			dispatch(openModalDialog(modalType.ERROR, notifObj, lang.ok));
		}
	};
};

export const detectConnectionError = () => {
	return { type: CONNECTION_ERROR_DETECTED };
};

export const connectToNextURL = () => {
	return (dispatch) => {
		setTimeout(() => {
			DebugLogger.shared.resetLoggerID();
			dispatch(resetCMessageID());
			dispatch(_connectToNextURL());
			// dispatch(clearWebsocket());
			if (DEBUG_ENABLED) {
				console.log("Attempting to connect to the next URL...");
				if (LOGGING_ENABLED) {
					dispatch(addLog({ title: "Attempting to connect/reconnect...", content: "" }));
				}
			}
		}, reconnectWaitTime);
	};
};

export const resetCMessageID = () => {
	DebugLogger.shared.resetLoggerID();
	return { type: RESET_CLIENT_MESSAGES_COUNTER };
};

export const clearWebsocket = () => {
	return { type: CLEAR_WEBSOCKET };
};

export const startReconnect = () => {
	return { type: RECONNECT_START };
};

export const finishReconnect = () => {
	return { type: RECONNECT_FINISHED };
};

const sentMessage = (data, screen = "", moveToSave, shopData) => {
	if (screen != "") {
		data.clientScreen = screen;
	}
	if (moveToSave != undefined) data.moveToSave = moveToSave;
	if (shopData != undefined) data.shopData = shopData;
	return {
		type: MESSAGE_SENT,
		payload: data,
	};
};

const _processAckEvent = (msg) => {
	return { type: PROCESS_ACK_EVENT, payload: msg };
};

const _connectToNextURL = () => {
	return { type: CONNECT_TO_NEXT_URL };
};
