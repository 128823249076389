import React, { Component } from "react";
import { Animated, Easing, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { handleError } from "../../components/ErrorHandler";
import { appFont, isWebOrTablet, RESOLUTION } from "../../config/defaults";
import { gameStartedDimensions } from "../../config/dimensions";
import { MyWebSocket } from "../../connection";
import { store } from "../../redux/store";
import { playCard, selectPawn } from "../redux/actions";

class PlayerCard extends Component {
	constructor(props) {
		super(props);
		// const _imgAnimVal = this.props.card.dealed ? 1 : 0;
		const _topAnimVal = this.props.card.selected
			? this.props.cardTop >= 0
				? this.props.cardTop * 0.95
				: this.props.cardTop * 1.05
			: this.props.cardTop;
		const _rotAnim = new Animated.Value(this.props.cardRotation);
		this.state = {
			// imgAnim: new Animated.Value(_imgAnimVal),
			topAnim: new Animated.Value(_topAnimVal),
			leftAnim: new Animated.Value(this.props.cardLeft),
			rotationAnim: _rotAnim,
			spin:
				Platform.OS === "web"
					? _rotAnim.interpolate({
							duration: 500,
							inputRange: [this.props.cardRotation, this.props.cardRotation],
							outputRange: [this.props.cardRotation + "deg", this.props.cardRotation + "deg"],
					  })
					: _rotAnim.interpolate({
							inputRange: [this.props.cardRotation, this.props.cardRotation],
							outputRange: [this.props.cardRotation + "deg", this.props.cardRotation + "deg"],
					  }),
			btnPlayCardHintAnim: new Animated.Value(1),
			playerCardHintAnim: new Animated.Value(this.props.cardScale),
		};
		this.btnPlayCardHintInterval = null;
		this.playerCardHintInterval = null;
		// this.justCreated = true;
	}

	//#region lifecycle methods
	componentDidMount() {
		if (this.props.card && this.props.card.requestCardHint) {
			this.hintPlayerCard();
		}

		if ((this.props.card && this.props.card.selected) || this.props.canPlayerAct) {
			this.hintPlayCardButton();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		//card hint animation
		if (this.props.card.requestCardHint != nextProps.card.requestCardHint) {
			if (nextProps.card.requestCardHint == true) {
				this.hintPlayerCard();
			} else {
				this.resetPlayerCardHintAnimation();
			}
		}

		if (
			this.props.cardTop !== nextProps.cardTop ||
			this.props.cardLeft !== nextProps.cardLeft ||
			this.props.cardRotation !== nextProps.cardRotation
		) {
			if (Platform.OS === "web") {
				if (this.props.cardRotation <= nextProps.cardRotation) {
					this.setState({
						spin:
							Platform.OS === "web"
								? this.state.rotationAnim.interpolate({
										duration: 500,
										useNativeDriver: Platform.OS !== "web",
										easing: Easing.inOut(Easing.ease),
										inputRange: [this.props.cardRotation, nextProps.cardRotation],
										outputRange: [this.props.cardRotation + "deg", nextProps.cardRotation + "deg"],
								  })
								: this.state.rotationAnim.interpolate({
										useNativeDriver: Platform.OS !== "web",
										easing: Easing.inOut(Easing.ease),
										inputRange: [this.props.cardRotation, nextProps.cardRotation],
										outputRange: [this.props.cardRotation + "deg", nextProps.cardRotation + "deg"],
								  }),
					});
				} else {
					this.setState({
						spin:
							Platform.OS === "web"
								? this.state.rotationAnim.interpolate({
										duration: 500,
										inputRange: [nextProps.cardRotation, this.props.cardRotation],
										outputRange: [nextProps.cardRotation + "deg", this.props.cardRotation + "deg"],
								  })
								: this.state.rotationAnim.interpolate({
										inputRange: [nextProps.cardRotation, this.props.cardRotation],
										outputRange: [nextProps.cardRotation + "deg", this.props.cardRotation + "deg"],
								  }),
					});
				}
				Animated.parallel(
					[
						Animated.timing(this.state.topAnim, {
							toValue: nextProps.cardTop,
							duration: Platform.OS === "web" ? 500 : 750,
							useNativeDriver: false, //Platform.OS !== "web",
						}),
						Animated.timing(this.state.leftAnim, {
							toValue: nextProps.cardLeft,
							duration: Platform.OS === "web" ? 500 : 750,
							useNativeDriver: false, // Platform.OS !== "web",
						}),
						Animated.timing(this.state.rotationAnim, {
							toValue: nextProps.cardRotation,
							duration: Platform.OS === "web" ? 500 : 750,
							useNativeDriver: false, // Platform.OS !== "web",
						}),
					],
					{
						useNativeDriver: false, // Platform.OS !== "web",
						easing: Easing.inOut(Easing.ease),
					}
				).start();
			}
		}

		//finish the PlayCardButton's hint animation
		if (
			this.props.card.selected !== nextProps.card.selected ||
			(this.props.canPlayerAct == true && nextProps.canPlayerAct == false)
		) {
			this.resetPlayCardButtonHintAnimation();
		}

		//start the PlayCardButton's hint animation
		if (
			(this.props.card.selected == false && nextProps.card.selected == true) ||
			(this.props.canPlayerAct == false && nextProps.canPlayerAct == true)
		) {
			this.hintPlayCardButton();
		}

		//request re-render
		return true;
	}

	componentWillUnmount() {
		if (this.btnPlayCardHintInterval) clearInterval(this.btnPlayCardHintInterval);
		if (this.playerCardHintInterval) clearInterval(this.playerCardHintInterval);
	}
	//#endregion

	//#region events
	resetPlayerCardHintAnimation(willUnmount = false) {
		try {
			if (this.playerCardHintInterval !== null) {
				clearInterval(this.playerCardHintInterval);
				this.playerCardHintInterval = null;
				if (!willUnmount) {
					this.state.playerCardHintAnim.setValue(this.props.cardScale);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	hintPlayerCard(willUnmount = false) {
		try {
			this.resetPlayerCardHintAnimation(willUnmount);
			this.resetPlayCardButtonHintAnimation(willUnmount);
			if (this.playerCardHintInterval == null && !willUnmount) {
				this.playerCardHintInterval = setInterval(() => {
					Animated.loop(
						Animated.sequence([
							Animated.timing(this.state.playerCardHintAnim, {
								toValue: this.props.cardScale * 1.15,
								duration: 300,
								useNativeDriver: Platform.OS !== "web",
							}),
							Animated.timing(this.state.playerCardHintAnim, {
								toValue: this.props.cardScale,
								duration: 300,
								useNativeDriver: Platform.OS !== "web",
							}),
						]),
						{
							iterations: 3,
							useNativeDriver: Platform.OS !== "web",
						}
					).start();
				}, 5000);
			}
		} catch (error) {
			handleError(error);
		}
	}

	resetPlayCardButtonHintAnimation(willUnmount = false) {
		try {
			if (this.btnPlayCardHintInterval !== null) {
				clearInterval(this.btnPlayCardHintInterval);
				this.btnPlayCardHintInterval = null;
				if (!willUnmount) {
					this.state.btnPlayCardHintAnim.setValue(1);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	hintPlayCardButton() {
		try {
			this.resetPlayerCardHintAnimation();
			this.resetPlayCardButtonHintAnimation();
			this.btnPlayCardHintInterval = setInterval(() => {
				Animated.loop(
					Animated.sequence([
						Animated.timing(this.state.btnPlayCardHintAnim, {
							toValue: 1.15,
							duration: 300,
							useNativeDriver: Platform.OS !== "web",
						}),
						Animated.timing(this.state.btnPlayCardHintAnim, {
							toValue: 1,
							duration: 300,
							useNativeDriver: Platform.OS !== "web",
						}),
					]),
					{
						iterations: 3,
						useNativeDriver: Platform.OS !== "web",
					}
				).start();
			}, 5000);
		} catch (error) {
			handleError(error);
		}
	}

	onCardPress() {
		try {
			const { card, onPress, canPlayerAct, myIndex } = this.props;
			if (card.selected && canPlayerAct) {
				this.onPlayCardPress();
			} else {
				if (typeof onPress === "function") onPress(card, myIndex);
				this.resetPlayerCardHintAnimation();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onPlayCardPress() {
		try {
			const { startedGame } = store.getState();
			if (startedGame.selectedMoveIdToAct != -1) {
				var msgGamePlayerAct = {
					sMessageID: 0,
					type: "playerAct",
					gameID: this.props.gameID,
					moveID: startedGame.selectedMoveIdToAct,
					actID: startedGame.gamePlayerToAct.actID,
					card: this.props.card.id,
					toLate: false,
				};
				const _selectedMove = startedGame.gamePlayerToAct.availableMoves.find(
					(m) => m.moveID == startedGame.selectedMoveIdToAct
				);
				const moveToSave = {
					saveMove: true,
					selectedMove: {
						..._selectedMove,
						cards: [this.props.card.id],
						cardIndex: this.props.myIndex,
					},
				};
				if (
					startedGame.waitForSecond_7 == false ||
					(startedGame.waitForSecond_7 == true &&
						startedGame.gamePlayerToAct != null &&
						startedGame.gamePlayerToAct.waitForSecond_7 != undefined)
				) {
					var selPositions = startedGame.gameBoard.boardPositions.filter((bp) => bp.selected == true),
						selCurPos = selPositions.find((p) => p.selected == true && !p.targeted),
						selNewPos = selPositions.find((p) => p.selected == true && p.targeted == true);
					if (moveToSave.selectedMove.type == "DOUBLE") {
						if (
							moveToSave.selectedMove.secondMove.curPos == selCurPos.id &&
							moveToSave.selectedMove.secondMove.newPos == selNewPos.id
						) {
							MyWebSocket.shared.sendMsg(msgGamePlayerAct, "", moveToSave);
							this.props.playCard(msgGamePlayerAct, moveToSave);
						} else {
							this.props.selectPawn(selNewPos);
						}
					} else {
						MyWebSocket.shared.sendMsg(msgGamePlayerAct, "", moveToSave);
						this.props.playCard(msgGamePlayerAct, moveToSave);
					}
				}
				// MyWebSocket.shared.sendMsg(msgGamePlayerAct, "", moveToSave);
				// this.props.playCard(msgGamePlayerAct, moveToSave);
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		if (!this.props.card.dealed) return null;
		const { card } = this.props;
		// var cardsetSize = "l";
		// switch (this.props.resolution) {
		//   case RESOLUTION.LOW:
		//     cardsetSize = "s";
		//     break;
		//   case RESOLUTION.MEDIUM:
		//     cardsetSize = "m";
		//     break;
		//   case RESOLUTION.HIGH:
		//     cardsetSize = "l";
		//     break;
		// }
		// const cardImgURL =
		//   "https://" +
		//   this.props.cardsetURL +
		//   "/" +
		//   this.props.cardset +
		//   "/" +
		//   cardsetSize +
		//   "/" +
		//   card.id +
		//   ".png";
		const _playerCardText = this.props.canPlayerAct
			? this.props.lang.btnPlayCard
			: card && card.id && card.id.startsWith("7") && this.props.selectedPawns.length > 0
			? this.props.lang.btnPickTarget
			: this.props.lang.btnPickPawn;
		return (
			<Animated.View
				key={card.id}
				style={[
					styles.cardButton,
					{ zIndex: card.playable ? (card.selected ? 3 : 2) : -1 },
					{
						top: Platform.OS === "web" ? this.state.topAnim : this.props.cardTop,
						left: Platform.OS === "web" ? this.state.leftAnim : this.props.cardLeft,
						transform: [
							{ scale: this.state.playerCardHintAnim },
							{
								rotate: Platform.OS === "web" ? this.state.spin : this.props.cardRotation + "deg",
							},
						],
						width: gameStartedDimensions.playingCardWidth + 3,
						height: gameStartedDimensions.playingCardHeight,
					},
				]}
			>
				<TouchableOpacity
					key={card.id}
					onPress={this.onCardPress.bind(this)}
					activeOpacity={1}
					disabled={!card.dealed || !card.playable}
					touchSoundDisabled={true}
				>
					<View
						style={{
							position: "absolute",
							top: card.filtered || card.selected ? -2 : -1,
							left: card.filtered || card.selected ? -2 : -1,
							zIndex: -1,
							borderRadius: isWebOrTablet || Platform.OS === "ios" ? 9 : 11,
							width: card.filtered || card.selected ? 74 : 72,
							height: card.filtered || card.selected ? 98 : 96,
							backgroundColor: card.playable /*&& !this.justCreated*/
								? card.filtered
									? card.selected
										? this.props.canPlayerAct
											? "green"
											: "black" //"green"
										: "#555"
									: card.selected
									? "black"
									: "#ccc"
								: "transparent",
						}}
					/>
					<Image
						source={{ uri: this.props.preloadedCard.cardImgURL }}
						// onLoadEnd={() => (this.justCreated = false)}
						resizeMode="stretch"
						style={[
							styles.cardImg,
							{
								// opacity: card.dealed ? 1 : 0, //this.state.imgAnim,
								borderRadius: isWebOrTablet || Platform.OS === "ios" ? 8 : 5,
							},
						]}
					/>
					<Animated.View
						style={[
							styles.playerCardButton,
							{
								backgroundColor: this.props.canPlayerAct ? "green" : "black",
								opacity: card.selected
									? 1
									: // this.props.canPlayerAct
									  //   ? 1
									  //   : 0.7
									  0,
								transform: [{ scale: this.state.btnPlayCardHintAnim }],
							},
						]}
					>
						<TouchableOpacity
							activeOpacity={0.7}
							onPress={this.onPlayCardPress.bind(this)}
							disabled={!card.dealed || !card.playable || !card.selected || !this.props.canPlayerAct}
							style={{ width: "100%", height: "100%" }}
							touchSoundDisabled={true}
						>
							<Text
								allowFontScaling={false}
								style={[styles.playerCardText, this.props.resolution == RESOLUTION.LOW && { fontSize: 15 }]}
							>
								{_playerCardText}
							</Text>
						</TouchableOpacity>
					</Animated.View>
					<View
						style={[
							styles.cardImg,
							styles.disabledView,
							{
								opacity: !card.playable && card.dealed ? 0.4 : 0,
								borderRadius: isWebOrTablet || Platform.OS === "ios" ? 8 : 9,
							},
						]}
					/>
				</TouchableOpacity>
			</Animated.View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	cardButton: {
		position: "absolute",
		borderColor: "#ccc",
		borderRadius: 9,
	},
	cardImg: {
		width: 70,
		height: 94,
	},
	playerCardButton: {
		// minWidth: "90%",
		minWidth: "96%",
		minHeight: 20,
		position: "absolute",
		top: "40%",
		// left: "2%",
		left: "-1%",
		zIndex: 5,
		backgroundColor: "green",
		opacity: 0.9,
		// borderRadius: 9,
	},
	playerCardText: {
		width: "100%",
		height: 20,
		fontFamily: appFont,
		fontSize: 12,
		textAlign: "center",
		lineHeight: 20,
		color: "#fff",
	},
	disabledView: {
		position: "absolute",
		top: 0,
		left: 0,
		opacity: 0.4,
		backgroundColor: "#000",
	},
});

const mapStateToProps = (state) => {
	var selectedPawns = state.startedGame.gameBoard.boardPositions.filter((bp) => bp.selected == true && bp.filled);
	return {
		lang: state.language.currentLanguage,
		gameID: state.startedGame.gameID,
		canPlayerAct: state.startedGame.canPlayerAct,
		cardset: state.startedGame.cardset,
		cardsetURL: state.app.welcome.webResources.cardsets,
		resolution: state.currentUser.preferences.resolution,
		selectedPawns: selectedPawns,
	};
};

const mapDispatchToProps = { playCard, selectPawn };

export default connect(mapStateToProps, mapDispatchToProps)(PlayerCard);
