import React, { Component } from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { isWebOrTablet, RESOLUTION } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import {
	subscribeToTournamentsList,
	unSubscribeFromTournament,
	unsubscribeFromTournamentsList,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { HeaderText } from "../common";
import TournamentDetailsButtons from "./TournamentDetailsButtons";
import TournamentDetailsTable from "./TournamentDetailsTable";

class TournamentDetails extends Component {
	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("TournamentDetails");
		this.props.unsubscribeFromTournamentsList();
	}

	componentWillUnmount() {
		const { tournamentID } = this.props.selectedTournamentDetails;

		this.props.unSubscribeFromTournament(tournamentID);
		this.props.subscribeToTournamentsList();
	}
	//#endregion

	//#region render methods
	render() {
		return (
			<SafeAreaView style={{ flex: 1 }}>
				<View
					style={[
						styles.container,
						isWebOrTablet
							? {
									marginTop: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
							  }
							: { marginTop: 10 },
					]}
				>
					{isWebOrTablet && (
						<HeaderText
							textContent={this.props.selectedTournamentDetails.name}
							style={{ height: gameDimensions.boxHeaderHeight }}
						/>
					)}
					<View style={styles.tableStyle}>
						<TournamentDetailsTable />
					</View>
					{/* <TournamentDetailsButtons /> */}
				</View>
			</SafeAreaView>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		margin: 3,
		paddingLeft: 60,
		paddingRight: 60,
		paddingBottom: 10,
	},
	tableStyle: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedTournamentDetails: state.tournaments.selectedTournamentDetails,
	};
};

const mapDispatchToProps = {
	subscribeToTournamentsList,
	unSubscribeFromTournament,
	unsubscribeFromTournamentsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentDetails);
