import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { Dimensions, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { f_sourcesansprobold, f_sourcesansproregular, isWebOrTablet, RESOLUTION } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { handleError } from "../ErrorHandler";
import { ColoredButton } from "./ColoredButton";

class Info extends Component {
	constructor(props) {
		super(props);
		this.state = { scrollViewHeight: 15 };
	}

	//#region events
	measureScrollView(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const { height } = event.nativeEvent.layout;
			this.setState({ scrollViewHeight: height });
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderArticle(item, index) {
		if (this.props.resolution === RESOLUTION.HIGH) {
			styles.articleText = [styles.articleText, { fontSize: 36, lineHeight: 40 }];
		}
		if (typeof item.content !== "string") return null;
		return (
			<View
				key={"article_" + index}
				style={[styles.articleContainer, { paddingLeft: this.props.isPrivacyOpen ? 0 : 20 }]}
			>
				<Text allowFontScaling={false} numberOfLines={1} style={styles.articleText}>
					{item.content}
				</Text>
			</View>
		);
	}

	renderParagraph(item, index) {
		if (this.props.resolution === RESOLUTION.HIGH) {
			styles.paragraphText = [styles.paragraphText, { fontSize: 30, lineHeight: 34 }];
		}
		if (typeof item.content !== "string") return null;
		return (
			<View key={"para_" + index} style={styles.paragraphContainer}>
				<Text allowFontScaling={false} style={styles.paragraphText}>
					{item.content}
				</Text>
			</View>
		);
	}

	renderParagraph_Ul(item, index) {
		if (this.props.resolution === RESOLUTION.HIGH) {
			styles.paragraph_Ul_Text = [styles.paragraph_Ul_Text, { fontSize: 30, lineHeight: 34 }];
			styles.dotStyle = [
				styles.dotStyle,
				{
					width: 22,
					fontSize: Platform.OS === "web" ? 64 : 64,
					lineHeight: Platform.OS === "web" ? 32 : 30,
				},
			];
		}
		var paragraph_Ul = [];
		if (typeof item.title === "string") {
			paragraph_Ul.push(this.renderParagraph({ content: item.title }, index));
		}

		if (Array.isArray(item.items) && item.items.length > 0) {
			item.items.forEach((it, ind) => {
				if (typeof it === "string") {
					paragraph_Ul.push(
						<View key={"ul_item_" + ind} style={styles.paragraph_Ul_Container}>
							<Text allowFontScaling={false} numberOfLines={1} style={[styles.paragraph_Ul_Text, styles.dotStyle]}>
								{"·"}
							</Text>
							<Text allowFontScaling={false} style={[{ flex: 1 }, styles.paragraph_Ul_Text]}>
								{it}
							</Text>
						</View>
					);
				}
			});
		}

		return (
			<View key={"para_ul_" + index} style={styles.paragraphContainer}>
				{paragraph_Ul}
			</View>
		);
	}

	renderOl_In_Ol(item, index) {
		if (this.props.resolution === RESOLUTION.HIGH) {
			styles.paragraph_Ul_Text = [styles.paragraph_Ul_Text, { fontSize: 30, lineHeight: 34 }];
		}
		var ol_in_ol = [];
		if (
			item.mainOrderType &&
			item.secondaryOrderType &&
			((item.mainOrderType == "1" && item.secondaryOrderType == "a") ||
				(item.mainOrderType == "a" && item.secondaryOrderType == "1"))
		) {
			const mainOrderType = item.mainOrderType;
			const secondaryOrderType = item.secondaryOrderType;
			if (Array.isArray(item.items) && item.items.length > 0) {
				var counter = 0;
				item.items.forEach((element, ind) => {
					if (element.type && (element.content || element.items)) {
						if (element.type == "main") {
							counter++;
							const orderText =
								mainOrderType == "1"
									? counter.toString() + "."
									: String.fromCharCode("a".charCodeAt(0) + counter) + ".";
							ol_in_ol.push(
								<View key={"main_ol_item_" + ind} style={styles.paragraph_Ul_Container}>
									<Text
										allowFontScaling={false}
										numberOfLines={1}
										style={[styles.paragraph_Ul_Text, { textAlign: "right", width: 30, marginRight: 3 }]}
									>
										{orderText}
									</Text>
									<Text allowFontScaling={false} style={[{ flex: 1, textAlign: "justify" }, styles.paragraph_Ul_Text]}>
										{element.content}
									</Text>
								</View>
							);
						} else if (element.type == "secondary") {
							if (Array.isArray(element.items) && element.items.length > 0) {
								element.items.forEach((subIt, subInd) => {
									const orderText =
										secondaryOrderType == "1"
											? (subInd + 1).toString() + "."
											: String.fromCharCode("a".charCodeAt(0) + subInd) + ".";
									ol_in_ol.push(
										<View key={"sub_ol_item_" + subInd} style={styles.subordered_list_Container}>
											<Text
												allowFontScaling={false}
												numberOfLines={1}
												style={[styles.paragraph_Ul_Text, { textAlign: "right", width: 30, marginRight: 3 }]}
											>
												{orderText}
											</Text>
											<Text
												allowFontScaling={false}
												style={[{ flex: 1, textAlign: "justify" }, styles.paragraph_Ul_Text]}
											>
												{subIt}
											</Text>
										</View>
									);
								});
							}
						} else if (element.type == "unordered") {
							if (Array.isArray(element.items) && element.items.length > 0) {
								element.items.forEach((subIt, subInd) => {
									ol_in_ol.push(
										<View key={"sub_ul_item_" + subInd} style={styles.subordered_list_Container}>
											<Text
												allowFontScaling={false}
												numberOfLines={1}
												style={[styles.paragraph_Ul_Text, styles.dotStyle]}
											>
												{"·"}
											</Text>
											<Text allowFontScaling={false} style={[{ flex: 1 }, styles.paragraph_Ul_Text]}>
												{subIt}
											</Text>
										</View>
									);
								});
							}
						}
					}
				});
			}
		}
		return (
			<View key={"ol_in_ol_" + index} style={styles.paragraphContainer}>
				{ol_in_ol}
			</View>
		);
	}

	renderInnerContent() {
		try {
			var items = [];
			if (typeof this.props.contents === "string") {
				if (this.props.resolution === RESOLUTION.HIGH)
					styles.contentText = [styles.contentText, { fontSize: 30, lineHeight: 34 }];
				items.push(
					<Text key="text1" allowFontScaling={false} style={styles.contentText}>
						{this.props.contents}
					</Text>
				);
			} else if (Array.isArray(this.props.contents) && this.props.contents.length > 0) {
				this.props.contents.forEach((item, ind) => {
					if (item.type) {
						switch (item.type) {
							case "article":
								items.push(this.renderArticle(item, ind));
								break;
							case "paragraph":
								items.push(this.renderParagraph(item, ind));
								break;
							case "paragraph_ul":
								items.push(this.renderParagraph_Ul(item, ind));
								break;
							case "ol_in_ol":
								items.push(this.renderOl_In_Ol(item, ind));
								break;
						}
					}
				});
				items.push(null);
			}
			return items;
		} catch (error) {
			handleError(error);
		}
	}

	renderContent() {
		if (Platform.OS === "web") {
			const _height = this.props.linksRow
				? Dimensions.get("window").height - 120
				: Dimensions.get("window").height - 80;
			return (
				<ScrollView
					nestedScrollEnabled={true}
					showsVerticalScrollIndicator={false}
					style={[styles.contentContainer, { height: _height }]}
					onLayout={(event) => this.measureScrollView(event)}
				>
					<CustomScroll
						allowOuterScroll={true}
						heightRelativeToParent={this.state.scrollViewHeight - 5 + "px"}
						minScrollHandleHeight={15}
					>
						{this.renderInnerContent()}
					</CustomScroll>
				</ScrollView>
			);
		}

		const _height = this.props.linksRow ? Dimensions.get("screen").height - 120 : Dimensions.get("screen").height - 80;
		return <ScrollView style={[styles.contentContainer, { height: _height }]}>{this.renderInnerContent()}</ScrollView>;
	}

	render() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60;
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
		}
		return (
			<View style={[styles.container, this.props.style]}>
				<View style={styles.titleContainer}>
					<Text allowFontScaling={false} style={[styles.titleStyle, this.props.titleTextStyle]}>
						{this.props.titleText}
					</Text>
				</View>
				{this.renderContent()}
				{this.props.linksRow && this.props.children}
				<View style={styles.buttonContainer}>
					<ColoredButton
						width={buttonWidth}
						height={buttonHeight}
						onPressFunc={this.props.onMainPress}
						textContent={this.props.mainButtonText}
						accessibilityLabel={this.props.mainButtonText}
						color={this.props.mainButtonColor}
						additionalTextStyle={this.props.buttonTextStyle}
						disabled={false}
						isInMenu={true}
					/>
				</View>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		margin: gameDimensions.fullWidthMargin,
		padding: gameDimensions.fullWidthMargin,
	},
	titleContainer: { width: "100%", height: 50 },
	titleStyle: {
		width: "100%",
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 30,
		textAlign: "center",
	},
	contentContainer: {
		paddingHorizontal: 20,
		marginVertical: 10,
		borderWidth: isWebOrTablet ? 3 : 2,
		borderColor: "#278082",
		borderRadius: isWebOrTablet ? 25 : 15,
		backgroundColor: "rgba(0, 65, 91,0.8)",
	},
	contentText: {
		width: "100%",
		fontFamily: f_sourcesansproregular,
		fontSize: 18,
		lineHeight: 22,
		color: "#FFFFFF",
	},
	buttonContainer: { width: "100%", alignItems: "center" },
	articleContainer: { flex: 1, marginVertical: 10, paddingLeft: 20 },
	articleText: {
		flex: 1,
		fontFamily: f_sourcesansprobold,
		fontSize: 22,
		lineHeight: 26,
		color: "#FFFFFF",
	},
	paragraphContainer: { flex: 1, paddingRight: Platform.OS === "web" ? 20 : 0 },
	paragraphText: {
		flex: 1,
		fontFamily: f_sourcesansproregular,
		textAlign: "justify",
		fontSize: 18,
		lineHeight: 22,
		color: "#FFFFFF",
	},
	paragraph_Ul_Container: { flex: 1, flexDirection: "row", paddingLeft: 40 },
	subordered_list_Container: { flex: 1, flexDirection: "row", paddingLeft: 80 },
	dotStyle: {
		width: 15,
		fontStyle: "normal",
		fontSize: Platform.OS === "web" ? 42 : 30,
		textAlign: "center",
		lineHeight: Platform.OS === "web" ? 18 : 28,
	},
	paragraph_Ul_Text: {
		fontFamily: f_sourcesansproregular,
		fontSize: 18,
		lineHeight: 22,
		color: "#FFFFFF",
	},
});

export { Info };
