import React, { Component } from "react";
import { FlatList, Image, StyleSheet, Text, View } from "react-native";
import { AppTouchableOpacity, Box } from "../../../components/common";
import { handleError } from "../../../components/ErrorHandler";
import { appFont } from "../../../config/defaults";
import { ARROW } from "../../../config/images";
import { mapObject } from "../../../helpers/commonHelpers";

class CardsetDropDown extends Component {
	constructor(props) {
		super(props);
		const { initialSelected } = this.props;
		const _dataSource = this.getDataSource();
		var item = _dataSource.find((obj) => obj.cardsetID == initialSelected);
		this.state = {
			dataSource: _dataSource,
			show: false,
			hoveredItem: null,
			selectedItem: item || { cardsetID: -1, name: "" },
		};
	}

	//#region events
	onDropButtonPress() {
		this.setState({ show: !this.state.show });
	}

	onDropButtonBlur() {
		this.setState({ show: false });
	}

	onMouseEnterItem(item) {
		this.setState({ hoveredItem: item });
	}

	onMouseLeaveItem() {
		this.setState({ hoveredItem: null });
	}

	onItemSelect(item) {
		if (this.state.selectedItem != item) {
			this.setState({ selectedItem: item, show: false });
			if (typeof this.props.onChange === "function") this.props.onChange(item.cardsetID);
		}
	}
	//#endregion

	//#region render methods
	renderListRow(item) {
		const { hoveredItem, dataSource, selectedItem } = this.state;
		const listWidth = dataSource.length > 10 ? 125 : 110;
		const buttonStyle =
			hoveredItem == item || selectedItem == item ? { backgroundColor: "#ddd" } : { backgroundColor: "#fff" };
		const mouseEnter = () => this.onMouseEnterItem(item);
		const mouseLeave = () => this.onMouseLeaveItem();
		const selectItem = () => this.onItemSelect(item);
		return (
			<AppTouchableOpacity
				activeOpacity={1}
				style={[styles.itemButtonStyle, buttonStyle, { width: listWidth }]}
				onMouseEnter={mouseEnter}
				onMouseLeave={mouseLeave}
				onPress={selectItem}
			>
				<Text
					allowFontScaling={false}
					selectable={false}
					numberOfLines={1}
					ellipsizeMode="tail"
					style={[styles.normalText, { paddingLeft: 6, paddingRight: 6 }]}
				>
					{item.name}
				</Text>
			</AppTouchableOpacity>
		);
	}

	renderDropDownList() {
		if (this.state.show) {
			const listWidth = this.state.dataSource.length > 10 ? 125 : 110;
			return (
				<Box style={[styles.boxStyle, { width: listWidth }]}>
					<FlatList
						nestedScrollEnabled={true}
						keyExtractor={(item, index) => "cs_" + index.toString()}
						data={this.state.dataSource}
						extraData={this.props}
						renderItem={({ item }) => this.renderListRow(item)}
						getItemLayout={(item, index) => ({
							length: 16.8,
							offset: 16.8 * index,
							index,
						})}
						style={{ backgroundColor: "#fff", position: "relative", zIndex: 1 }}
					/>
				</Box>
			);
		}
		return null;
	}

	render() {
		const { show, selectedItem } = this.state;
		return (
			<View style={styles.mainContainer}>
				<AppTouchableOpacity
					activeOpacity={1}
					onPress={this.onDropButtonPress.bind(this)}
					style={{ flexDirection: "row", width: 125, marginLeft: 10 }}
					onBlur={this.onDropButtonBlur.bind(this)}
				>
					<Text
						allowFontScaling={false}
						selectable={false}
						numberOfLines={1}
						ellipsizeMode="tail"
						style={[styles.normalText, styles.selectedTextContainer]}
					>
						{selectedItem.name}
					</Text>
					<Image source={ARROW} style={!show ? [styles.dropIcon, styles.mirror] : styles.dropIcon} />
				</AppTouchableOpacity>
				{this.renderDropDownList()}
			</View>
		);
	}
	//#endregion

	//#region helpers
	getDataSource() {
		try {
			const { cardsets } = this.props;
			var dataSource = [];
			if (typeof cardsets !== "undefined") {
				mapObject(cardsets, (key, value) => {
					if (typeof value !== "undefined" && typeof value.cardsetID === "number" && typeof value.name === "string")
						dataSource.push(value);
				});
			}
			return dataSource;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: "row",
	},
	dropIcon: {
		width: 10,
		height: 7,
		marginTop: "auto",
		marginBottom: "auto",
		alignSelf: "flex-end",
	},
	mirror: {
		transform: [{ rotate: "180deg" }],
	},
	boxStyle: {
		position: "absolute",
		bottom: 30,
		left: 10,
		minHeight: 30,
		maxHeight: 190,
		borderRadius: 0,
		backgroundColor: "#fff",
	},
	itemButtonStyle: {
		backgroundColor: "#fff",
	},
	normalText: {
		fontFamily: appFont,
		fontSize: 14,
		marginTop: 2,
	},
	selectedTextContainer: {
		width: 105,
		fontSize: 13,
		borderRadius: 5,
		borderWidth: 1,
		borderColor: "#999",
		marginRight: 5,
		paddingTop: 2,
		paddingLeft: 3,
		paddingRight: 3,
		overflow: "hidden",
		height: 20,
	},
});

export { CardsetDropDown };
