import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor, RESOLUTION } from "../../../../../config/defaults";
import { gameDimensions } from "../../../../../config/dimensions";
import {
	closeLanguagePreferences,
	goBackToMain,
	openAppLanguageSettings,
	openChatTranslationSettings,
} from "../../../../../redux/actions";
import { ColoredButton, NavigationHeader } from "../../../../common";
import AppLanguage from "./AppLanguage";
import ChatTranslation from "./ChatTranslation";

class LanguagePreferences extends Component {
	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeLanguagePreferences}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "appLang":
				textContent = this.props.lang.languagePreferences.btnAppLang;
				onPressFunc = () => {
					this.props.openAppLanguageSettings();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "translate":
				textContent = this.props.lang.languagePreferences.btnChatTranslate;
				onPressFunc = () => {
					this.props.openChatTranslationSettings();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
		}
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				additionalTextStyle={_buttonTextStyle}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderButton("appLang")}
					{this.renderButton("translate")}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.chatTranslationSettingsOpened) {
			return <ChatTranslation />;
		} else if (this.props.appLanguageSettingsOpened) {
			return <AppLanguage />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		minHeight: "100%",
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		chatTranslationSettingsOpened: state.app.chatTranslationSettingsOpened,
		appLanguageSettingsOpened: state.app.appLanguageSettingsOpened,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeLanguagePreferences,
	goBackToMain,
	openAppLanguageSettings,
	openChatTranslationSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePreferences);
