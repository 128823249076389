import React, { Component } from "react";
import { Image, Platform, StyleSheet, Text } from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { appFont, isTablet, isWebOrTablet, viewSide } from "../../config/defaults";
import {
	BACK_ICON,
	CHAT_ICON,
	CREATE_ICON,
	EXIT_BUTTON,
	MUTED_BUTTON,
	SWITCH_ICON,
	VOLUME_BUTTON,
} from "../../config/images";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// gameNameEditable: false,
			gameNameFocusable: false,
			// newName:
			//   typeof this.props.textContent === "string"
			//     ? this.props.textContent
			//     : "",
			fullWidth: 0,
		};
	}

	//#region lifecycle methods
	componentDidUpdate(prevProps) {
		// if (prevProps.textContent !== this.props.textContent) {
		//   this.setState({
		//     newName:
		//       typeof this.props.textContent === "string"
		//         ? this.props.textContent
		//         : "",
		//   });
		// }
	}
	//#endregion

	//#region events
	measureView(event) {
		if (Platform.OS !== "web") event.persist();
		this.setState({ fullWidth: event.nativeEvent.layout.width });
	}

	// editGameNamePress() {
	//   this.setState({ gameNameEditable: true });
	//   this.setState({ gameNameFocusable: true });
	//   this.refs.gameNameInput.focus();
	// }

	chatIconPress() {
		const { onChatIconPress } = this.props;
		if (typeof onChatIconPress === "function") onChatIconPress();
	}

	soundIconPress() {
		const { onSoundIconPress } = this.props;
		if (typeof onSoundIconPress === "function") onSoundIconPress();
	}

	backIconPress() {
		const { onBackIconPress } = this.props;
		if (typeof onBackIconPress === "function") onBackIconPress();
	}

	switchIconPress() {
		const { onSwitchIconPress } = this.props;
		if (typeof onSwitchIconPress === "function") onSwitchIconPress();
	}

	createIconPress() {
		const { onCreateIconPress } = this.props;
		if (typeof onCreateIconPress === "function") onCreateIconPress();
	}

	crossIconPress() {
		const { onCrossIconPress } = this.props;
		if (typeof onCrossIconPress === "function") onCrossIconPress();
	}

	// onSubmitGameName() {
	//   const { newName } = this.state;
	//   const { onSubmit, textContent, notifAction, notifMsgs } = this.props;
	//   this.setState({ gameNameEditable: false, gameNameFocusable: false });
	//   if (
	//     newName.length >= gameNameMinLength &&
	//     newName.length <= gameNameMaxLength
	//   ) {
	//     if (typeof onSubmit === "function") onSubmit(newName);
	//   } else {
	//     var gameNameLengthWarningMessage = "";
	//     if (
	//       typeof notifMsgs !== "undefined" &&
	//       typeof notifMsgs.gameNameLengthWarningMessage !== "undefined"
	//     ) {
	//       var notificationObj = notifMsgs.gameNameLengthWarningMessage;
	//       if (typeof notificationObj !== "undefined")
	//         gameNameLengthWarningMessage = notificationObj.main_text;
	//     }
	//     var notification = {
	//       displayTime: 5000,
	//       deleteHistory: false,
	//       rotate: false,
	//       clickable: false,
	//       playSound: true,
	//       url: "#",
	//       priority: "high",
	//       announcement: gameNameLengthWarningMessage,
	//       isNotification: true,
	//     };
	//     if (typeof notifAction === "function") notifAction(notification);
	//     this.setState({ newName: textContent });
	//   }
	// }
	//#endregion

	//#region render methods
	renderBackIcon() {
		if (this.props.enableBackIcon == true) {
			return (
				<AppTouchableOpacity
					onPress={this.backIconPress.bind(this)}
					activeOpacity={1}
					style={[styles.leftFirstIcon, styles.iconLayout]}
				>
					<Image source={BACK_ICON} resizeMode="stretch" style={styles.iconImageSize} />
				</AppTouchableOpacity>
			);
		}
		return null;
	}

	renderCreateIcon() {
		if (this.props.enableCreateIcon == true) {
			const buttonStyle = this.props.enableBackIcon
				? [styles.leftSecondIcon, styles.iconLayout]
				: [styles.leftFirstIcon, styles.iconLayout];
			return (
				<AppTouchableOpacity
					onPress={this.createIconPress.bind(this)}
					activeOpacity={this.props.createIconOpacity}
					style={[buttonStyle, { opacity: this.props.createIconOpacity }]}
				>
					<Image source={CREATE_ICON} resizeMode="stretch" style={styles.iconImageSize} />
				</AppTouchableOpacity>
			);
		}
		return null;
	}

	renderSwitchIcon() {
		if (this.props.enableSwitchIcon == true) {
			return (
				<AppTouchableOpacity
					onPress={this.switchIconPress.bind(this)}
					activeOpacity={1}
					style={[styles.rightSecondIcon, styles.iconLayout]}
				>
					<Image source={SWITCH_ICON} resizeMode="stretch" style={styles.iconImageSize} />
				</AppTouchableOpacity>
			);
		}
		return null;
	}

	renderChatIcon() {
		if (this.props.enableChatIcon == true) {
			return (
				<AppTouchableOpacity
					onPress={this.chatIconPress.bind(this)}
					activeOpacity={1}
					style={[styles.leftFirstIcon, styles.iconLayout]}
				>
					<Image source={CHAT_ICON} resizeMode="stretch" style={styles.iconImageSize} />
				</AppTouchableOpacity>
			);
		}
		return null;
	}

	renderSoundIcon() {
		if (this.props.enableSoundIcon == true) {
			return (
				<AppTouchableOpacity
					onPress={this.soundIconPress.bind(this)}
					activeOpacity={1}
					style={[styles.rightFirstIcon, styles.iconLayout, styles.soundButton]}
				>
					<Image
						source={this.props.isSoundMuted ? MUTED_BUTTON : VOLUME_BUTTON}
						resizeMode="stretch"
						style={styles.soundIcon}
					/>
				</AppTouchableOpacity>
			);
		}
		return null;
	}

	renderCrossIcon() {
		if (this.props.enableCrossIcon == true) {
			return (
				<AppTouchableOpacity
					onPress={this.crossIconPress.bind(this)}
					activeOpacity={1}
					style={[styles.rightSecondIcon, styles.iconLayout, styles.closeButton]}
				>
					<Image source={EXIT_BUTTON} resizeMode="cover" style={styles.closeIcon} />
				</AppTouchableOpacity>
			);
		}
		return null;
	}

	renderHeader() {
		const {
			// enableEditName,
			textContent,
			style,
			position,
			enableBackIcon,
			enableCreateIcon,
			enableSwitchIcon,
		} = this.props;
		// const { gameNameEditable, newName } = this.state;

		let gradientColors = [];
		let sideContainerStyle = {};
		let sideTextStyle = {};
		switch (position) {
			case viewSide.RIGHT:
				gradientColors = ["#FCC62D", "#FCD745"];
				sideContainerStyle = styles.rightContainer;
				sideTextStyle = styles.rightTextStyle;
				break;
			case viewSide.BOTTOM:
				gradientColors = ["#CBE47E", "#B7D960"];
				sideContainerStyle = styles.bottomContainer;
				sideTextStyle = styles.bottomTextStyle;
				break;
			case viewSide.LEFT:
			default:
				gradientColors = ["#00DBE4", "#00BCCB"];
				sideContainerStyle = styles.leftContainer;
				sideTextStyle = styles.leftTextStyle;
				break;
		}
		if (Platform.OS === "web") styles.textStyle = [styles.textStyle, { cursor: "default" }];

		styles.textStyle = [styles.textStyle, { width: "100%" }];
		if (enableCreateIcon == true && enableBackIcon == true && enableSwitchIcon == true)
			styles.textStyle = [styles.textStyle, { paddingLeft: 81, paddingRight: 41 }];

		// if (enableEditName == true) {
		//   if (Platform.OS === "web" && gameNameEditable)
		//     styles.textStyle = [
		//       styles.textStyle,
		//       { cursor: "text", outlineColor: "transparent" },
		//     ];
		//   return (
		//     <LinearGradient
		//       start={{ x: 0, y: 0 }}
		//       end={{ x: 1, y: 0 }}
		//       colors={gradientColors}
		//       style={[styles.container, sideContainerStyle, style]}
		//     >
		//       {this.renderBackIcon()}
		//       {this.renderCreateIcon()}
		//       {this.renderSwitchIcon()}
		//       {this.renderChatIcon()}
		//       <TextInput
		//         allowFontScaling={false}
		//         ref="gameNameInput"
		//         disableFullscreenUI={true}
		//         onBlur={this.onSubmitGameName.bind(this)}
		//         onSubmitEditing={() => {
		//           if (this.refs.gameNameInput) this.refs.gameNameInput.blur();
		//         }}
		//         value={newName || textContent}
		//         onChangeText={(text) => this.setState({ newName: text })}
		//         editable={gameNameEditable}
		//         selectTextOnFocus={true}
		//         maxLength={gameNameMaxLength}
		//         underlineColorAndroid="transparent"
		//         style={[styles.textStyle, sideTextStyle]}
		//       />
		//       <AppTouchableOpacity
		//         onPress={this.editGameNamePress.bind(this)}
		//         activeOpacity={1}
		//         style={[styles.rightSecondIcon, styles.iconLayout]}
		//       >
		//         <Image
		//           source={EDIT_GAME_NAME}
		//           resizeMode="stretch"
		//           style={styles.iconImageSize}
		//         />
		//       </AppTouchableOpacity>
		//     </LinearGradient>
		//   );
		// }
		return (
			<LinearGradient
				start={{ x: 0, y: 0 }}
				end={{ x: 1, y: 0 }}
				colors={gradientColors}
				style={[styles.container, sideContainerStyle, style]}
			>
				<Text allowFontScaling={false} selectable={false} style={[styles.textStyle, sideTextStyle]}>
					{textContent}
				</Text>
				{this.renderBackIcon()}
				{this.renderCreateIcon()}
				{this.renderSwitchIcon()}
				{this.renderChatIcon()}
				{this.renderCrossIcon()}
				{this.renderSoundIcon()}
			</LinearGradient>
		);
	}

	render() {
		return this.renderHeader();
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { borderTopLeftRadius: 10, borderTopRightRadius: 10 },
	leftContainer: { backgroundColor: "#00BCCB" },
	rightContainer: { backgroundColor: "#FCD745" },
	bottomContainer: { backgroundColor: "#B7D960" },
	textStyle: {
		color: "#FFFFFF",
		alignSelf: "center",
		textAlign: "center",
		fontFamily: appFont,
		fontSize: isTablet ? 30 : 25,
		paddingTop: isTablet ? 8 : Platform.OS === "ios" ? 6 : 2,
		paddingBottom: 0,
		borderWidth: 0,
		textShadowColor: "#FFFFFF",
		textShadowOffset: { width: -2, height: -2 },
		textShadowRadius: Platform.OS !== "web" ? 1 : 0,
	},
	leftTextStyle: { textShadowColor: "#00A9B6" },
	rightTextStyle: { textShadowColor: "#D6B133" },
	bottomTextStyle: { textShadowColor: "#A6BE62" },
	iconImageSize: { width: 30, height: 30 },
	iconLayout: {
		width: 30,
		height: 30,
		position: "absolute",
		top: isTablet ? 10 : 2,
	},
	rightFirstIcon: { right: 30 },
	rightSecondIcon: { right: 5 },
	leftFirstIcon: { left: 5 },
	leftSecondIcon: { left: 45 },
	closeButton: {
		width: isWebOrTablet ? 40 : 30,
		height: isWebOrTablet ? 40 : 30,
		top: isWebOrTablet ? 5 : 2,
		justifyContent: "flex-start",
		alignSelf: "center",
	},
	closeIcon: {
		width: isWebOrTablet ? 40 : 30,
		height: isWebOrTablet ? 40 : 30,
		alignSelf: "flex-end",
	},
	soundButton: {
		width: isWebOrTablet ? 40 : 30,
		height: isWebOrTablet ? 40 : 30,
		top: isWebOrTablet ? 5 : 2,
		right: isWebOrTablet ? 60 : 42,
		justifyContent: "flex-start",
		alignSelf: "center",
	},
	soundIcon: {
		width: isWebOrTablet ? 40 : 30,
		height: isWebOrTablet ? 40 : 30,
		alignSelf: "flex-end",
	},
});

export { Header };
