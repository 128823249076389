import { TOGGLE_SYSTEM_SOUND } from "../actionTypes";

const initialState = {
	isMuted: false,
	scoreIncreaseSound: null,
	announceHighSound: null,
	announceLowSound: null,
	announceMediumSound: null,
	cardsDeal4Sound: null,
	cardsDeal5Sound: null,
	cardsThrowSound: null,
	cardsShuffleSound: null,
	cardsThrowAwaySound: null,
	chatGameSound: null,
	chatGlobalSound: null,
	chatJoinFriendSound: null,
	completeSound: null,
	countdownSound: null,
	disconnectSound: null,
	errorSound: null,
	gameChatSound: null,
	gameLostSound: null,
	gameWonSound: null,
	gameStartSound: null,
	kickSound: null,
	levelUpSound: null,
	moveBackwardsSound: null,
	moveJackSound: null,
	moveSmashSound: null,
	moveStartSound: null,
	moveNormalSound: null,
	myTurnSound: null,
	myTurnInactiveSound: null,
	notificationSound: null,
	playerJoinsSound: null,
	playerLeavesSound: null,
	reconnectSound: null,
	goldSound: null,
	silverSound: null,
	addCreditSound: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		// case REGISTER_SOUNDS:
		//   if (
		//     typeof payload === undefined ||
		//     typeof payload.internSoundSetURL === "undefined"
		//   )
		//     return state;
		//   const soundURL =
		//     payload.internSoundSetURL == ""
		//       ? CONTENT_URL + SOUND_SET + "01/"
		//       : CONTENT_URL + payload.internSoundSetURL;
		//   var newState = cloneDeep(state);
		//   mapObject(sounds_conf, (key, val) => {
		//     const errorMethod = (error) => {
		//       if (error) {
		//         if (DEBUG_ENABLED)
		//           console.log("Can't play sound. ", soundURL + val);
		//         handleError(new Error("Can't play sound. " + soundURL + val));
		//       }
		//     };
		//     if (state[key] == undefined || state[key] == null)
		//       newState[key] = new Sound(soundURL + val, null, errorMethod);
		//   });
		//   return newState;
		case TOGGLE_SYSTEM_SOUND:
			return { ...state, isMuted: !state.isMuted };
		default:
			return state;
	}
};
