import { mySounds } from "../../config/sounds";
import { EventQueue } from "../../controller";
import { store } from "../../redux/store";
import {
	GAME_END_RECEIVED,
	GAME_INFO_RECEIVED,
	GAME_STARTED_RECEIVED,
	RESET_GAME_END,
	RESET_STARTED_GAME,
	SHOW_DID_YOU_KNOW,
} from "../actionTypes";
import { openGameEnd, openStartedGame } from "./NavigationActions";
import { setFeatures } from "./UserActions";

export const gameStartedReceived = (msg) => {
	return (dispatch) => {
		EventQueue.shared.startQueuedEvent(msg);
		dispatch(_gameStartedReceived(msg));
		dispatch(setFeatures(msg.features));
		if (!store.getState().tab.isGameStarted) dispatch(openStartedGame());
	};
};

export const gameInfoReceived = (msg) => {
	return (dispatch) => {
		if (!store.getState().tab.isGameStarted && msg.reason === "NEW_ROUND") {
			dispatch(gameStartedReceived(msg));
		} else {
			EventQueue.shared.startQueuedEvent(msg);
			dispatch(_gameInfoReceived(msg));
			dispatch(setFeatures(msg.features));
		}
	};
};

export const gameEndReceived = (msg) => {
	return (dispatch) => {
		const { completeSound } = mySounds;
		if (typeof completeSound !== "undefined" && completeSound != null && completeSound.isLoaded() == true) {
			completeSound.stop();
		}
		dispatch({ type: GAME_END_RECEIVED, payload: msg });
		dispatch(openGameEnd());
	};
};

export const resetGameEnd = () => {
	return (dispatch) => {
		dispatch({ type: RESET_GAME_END });
	};
};

export const showDidYouKnow = () => {
	return (dispatch) => {
		dispatch({ type: SHOW_DID_YOU_KNOW });
	};
};
export const resetStartedGame = () => {
	return { type: RESET_STARTED_GAME };
};

const _gameStartedReceived = (msg) => {
	return { type: GAME_STARTED_RECEIVED, payload: msg };
};

const _gameInfoReceived = (msg) => {
	return { type: GAME_INFO_RECEIVED, payload: msg };
};
