import { modalType } from "../../config/defaults";
import { enableReconnectButton } from "../../config/permissions";
import {
	CLOSE_MODAL_DIALOG,
	CONNECTION_ERROR_DETECTED,
	DISABLE_RECONNECT,
	ENABLE_RECONNECT,
	OPEN_MODAL_DIALOG,
} from "../actions/actionTypes";

const initialState = {
	visibleModal: false,
	modType: modalType.DIALOG,
	message: {
		main_text: "",
		extra_text: "",
	},
	isConnectionError: false,
	enableReconnect: enableReconnectButton,
	posButText: "OK",
	posButAction: null,
	negButText: "",
	negButAction: null,
	linkInText: false,
	isFullscreenLink: false,
	isMaintenance: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case OPEN_MODAL_DIALOG:
			if (typeof payload === "undefined") return state;
			const {
				modType,
				message,
				posButText,
				posButAction,
				negButText,
				negButAction,
				linkInText,
				isFullscreenLink,
				lang,
			} = payload;

			var _type = modType,
				_posButText = posButText,
				_negButText = negButText,
				_linkInText = linkInText,
				_isFullscreenLink = isFullscreenLink;
			if (_type != modalType.DIALOG && _type != modalType.ERROR && _type != modalType.RECONNECT)
				_type = modalType.DIALOG;
			if (typeof _posButText !== "string") _posButText = "";
			if (typeof _negButText !== "string") _negButText = "";
			if (typeof _linkInText !== "boolean") _linkInText = false;
			if (typeof _isFullscreenLink !== "boolean") _isFullscreenLink = false;
			if (_posButText == "" && _negButText == "") _posButText = lang.ok || "OK";
			var _message = {
				main_text:
					typeof message === "undefined" || message === null || typeof message.main_text === "undefined"
						? ""
						: message.main_text,
				extra_text:
					typeof message === "undefined" || message === null || typeof message.extra_text === "undefined"
						? ""
						: message.extra_text,
			};

			return {
				...state,
				visibleModal: true,
				modType: _type,
				message: _message,
				enableReconnect: _type == modalType.RECONNECT ? enableReconnectButton : state.enableReconnect,
				posButText: _posButText,
				posButAction: typeof posButAction === "function" ? posButAction : null,
				negButText: _negButText,
				negButAction: typeof negButAction === "function" ? negButAction : null,
				linkInText: _linkInText,
				isFullscreenLink: _isFullscreenLink,
				isMaintenance:
					typeof message === "undefined" || message === null || typeof message.isMaintenance !== "boolean"
						? false
						: message.isMaintenance,
			};
		case CLOSE_MODAL_DIALOG:
			return { ...initialState, visibleModal: false };
		case CONNECTION_ERROR_DETECTED:
			return { ...state, isConnectionError: true };
		case ENABLE_RECONNECT:
			return { ...state, enableReconnect: true };
		case DISABLE_RECONNECT:
			return { ...state, enableReconnect: false };
		default:
			return state;
	}
};
