import React, { Component } from "react";
import { FlatList, Image, StyleSheet, View } from "react-native";
import { AppTouchableOpacity, Box } from "../../../components/common";
import { handleError } from "../../../components/ErrorHandler";
import { ARROW } from "../../../config/images";
import { getRankingImageURL } from "../../../helpers/commonHelpers";

class RankingImageDropDown extends Component {
	constructor(props) {
		super(props);
		const { initialSelected } = this.props;
		this.state = {
			dataSource: this.getDataSource(),
			show: false,
			hoveredItem: null,
			selectedItem: typeof initialSelected === "number" ? initialSelected : null,
		};
	}

	//#region events
	onDropButtonPress() {
		try {
			const { enabled, ruleName, onPermissionDenied } = this.props;
			if (typeof enabled === "boolean" && enabled == false) {
				var permissionID = "";
				switch (ruleName) {
					case "playerLevel":
						permissionID = "GAME_CREATE_CAN_SET_PLAYER_LEVEL";
				}
				if (typeof onPermissionDenied === "function") onPermissionDenied(permissionID);
			} else {
				this.setState({ show: !this.state.show });
			}
		} catch (error) {
			handleError(error);
		}
	}

	onDropButtonBlur() {
		this.setState({ show: false });
	}

	onMouseEnterItem(item) {
		this.setState({ hoveredItem: item });
	}

	onMouseLeaveItem() {
		this.setState({ hoveredItem: null });
	}

	onItemSelect(item) {
		if (this.state.selectedItem != item) {
			this.setState({ selectedItem: item, show: false });
			if (typeof this.props.onChange === "function") this.props.onChange(this.props.myRef, item);
		}
	}
	//#endregion

	//#region render methods
	renderListRow(item) {
		const { hoveredItem, dataSource, selectedItem } = this.state;
		const URL = getRankingImageURL(item, 24);
		const listWidth = dataSource.length > 6 ? 50 : 35;
		const buttonStyle =
			hoveredItem == item || selectedItem == item ? { backgroundColor: "#ddd" } : { backgroundColor: "#fff" };
		const mouseEnter = () => this.onMouseEnterItem(item);
		const mouseLeave = () => this.onMouseLeaveItem();
		const selectItem = () => this.onItemSelect(item);
		return (
			<AppTouchableOpacity
				activeOpacity={1}
				style={[styles.itemButtonStyle, buttonStyle, { width: listWidth }]}
				onMouseEnter={mouseEnter}
				onMouseLeave={mouseLeave}
				onPress={selectItem}
			>
				<Image
					source={URL}
					style={{ width: 20, height: 20 }}
					onMouseEnter={mouseEnter}
					onMouseLeave={mouseLeave}
					onPress={selectItem}
				/>
			</AppTouchableOpacity>
		);
	}

	renderDropDownList() {
		if (this.state.show) {
			const listWidth = this.state.dataSource.length > 6 ? 50 : 35;
			return (
				<Box style={[styles.boxStyle, { width: listWidth }]}>
					<FlatList
						nestedScrollEnabled={true}
						keyExtractor={(item, index) => this.props.myRef + index.toString()}
						data={this.state.dataSource}
						extraData={this.props}
						renderItem={({ item }) => this.renderListRow(item)}
						getItemLayout={(item, index) => ({
							length: 24,
							offset: 24 * index,
							index,
						})}
						style={{ backgroundColor: "#fff", position: "relative", zIndex: 1 }}
					/>
				</Box>
			);
		}
		return null;
	}

	render() {
		const { myRef } = this.props;
		const { show, selectedItem } = this.state;
		const selectedURL = getRankingImageURL(selectedItem, 24);
		return (
			<View style={styles.mainContainer} ref={myRef}>
				<AppTouchableOpacity
					activeOpacity={1}
					onPress={this.onDropButtonPress.bind(this)}
					style={{ flexDirection: "row", width: 35, marginRight: 10 }}
					onBlur={this.onDropButtonBlur.bind(this)}
				>
					<Image ref={"selected"} source={selectedURL} style={styles.selectedImage} resizeMode="stretch" />
					<Image source={ARROW} style={!show ? [styles.dropIcon, styles.mirror] : styles.dropIcon} />
				</AppTouchableOpacity>
				{this.renderDropDownList()}
			</View>
		);
	}
	//#endregion

	//#region helpers
	getDataSource() {
		try {
			const { minValue, maxValue } = this.props;
			var dataSource = [];
			if (typeof minValue === "number" && typeof maxValue === "number" && minValue <= maxValue) {
				for (var i = minValue; i <= maxValue; i++) {
					dataSource.push(i);
				}
			}
			return dataSource;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: "row",
	},
	dropIcon: {
		width: 10,
		height: 7,
		marginTop: "auto",
		marginBottom: "auto",
		alignSelf: "flex-end",
	},
	mirror: {
		transform: [{ rotate: "180deg" }],
	},
	boxStyle: {
		position: "absolute",
		top: 25,
		left: -5,
		minHeight: 30,
		maxHeight: 145,
		borderRadius: 0,
		backgroundColor: "#fff",
	},
	itemButtonStyle: {
		backgroundColor: "#fff",
		paddingTop: 2,
		paddingBottom: 2,
		paddingLeft: 5,
	},
	selectedImage: {
		width: 17,
		height: 17,
		marginLeft: 5,
		marginRight: 5,
		marginTop: 2,
	},
});

export { RankingImageDropDown };
