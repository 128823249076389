import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor } from "../../../../../../config/defaults";
import { gameDimensions } from "../../../../../../config/dimensions";
import {
	closeBoardPreferences,
	goBackToMain,
	openFixBoardColors,
	openFixBoardPositions,
} from "../../../../../../redux/actions";
import { ColoredButton, NavigationHeader } from "../../../../../common";
import FixBoardColors from "./FixBoardColors";
import FixBoardPositions from "./FixBoardPositions";

class BoardPreferences extends Component {
	//#render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeBoardPreferences}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "position":
				textContent = this.props.lang.boardPreferences.btnPosition;
				onPressFunc = () => {
					this.props.openFixBoardPositions();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "color":
				textContent = this.props.lang.boardPreferences.btnColor;
				onPressFunc = () => {
					this.props.openFixBoardColors();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
		}
		return (
			<ColoredButton
				width={gameDimensions.gameButtonsWidth}
				height={60}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderButton("position")}
					{/*TODO: temporary hidden {this.renderButton("color")} */}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.fixBoardPositionsOpened) {
			return <FixBoardPositions />;
		} else if (this.props.fixBoardColorsOpened) {
			return <FixBoardColors />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		minHeight: "100%",
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		fixBoardPositionsOpened: state.app.fixBoardPositionsOpened,
		fixBoardColorsOpened: state.app.fixBoardColorsOpened,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeBoardPreferences,
	goBackToMain,
	openFixBoardPositions,
	openFixBoardColors,
};

export default connect(mapStateToProps, mapDispatchToProps)(BoardPreferences);
