import React from "react";
import { gameBoardTheme } from "../config/defaults";
import ClassicMiniCardsContainer from "./ClassicMiniCardsContainer";
import ModernMiniCardsContainer from "./ModernMiniCardsContainer/ModernMiniCardsContainer";

const MiniCardsContainer = (props) => {
	switch (props.gameTheme) {
		case gameBoardTheme.MODERN:
			return <ModernMiniCardsContainer {...props} />;
		default:
			return <ClassicMiniCardsContainer {...props} />;
	}
};

export default MiniCardsContainer;
