import * as helpers from "../../helpers/commonHelpers";
import {
	CLEAR_GAME_DETAILS_IN_LOBBY,
	LOADING_GAME_DETAILS_IN_LOBBY,
	LOADING_GAME_DETAILS_IN_LOBBY_FINISHED,
	MOUSE_ENTER_SLOT_IN_LOBBY,
	MOUSE_LEAVE_SLOT_IN_LOBBY,
	RECEIVE_GAME_PLAYER_INFO,
	RESET_CLIENT,
	SELECT_SLOT_IN_LOBBY,
} from "../actions/actionTypes";

const initialState = {
	selectedGame: {
		gameID: -1,
		selectedSlotID: -1,
		gamePlayersList: [],
		loading: false,
		loadingGamePlayersList: false,
		state: "PUBLISHED",
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case LOADING_GAME_DETAILS_IN_LOBBY:
			return {
				...state,
				selectedGame: {
					...state.selectedGame,
					loading: true,
					loadingGamePlayersList: true,
					state: "PUBLISHED",
				},
			};
		case RECEIVE_GAME_PLAYER_INFO:
			const { msg, selLobbyGameID, isLobbyOpen, gameList, freeSlotLang, teamPrefixLang } = payload;
			if (
				typeof isLobbyOpen !== "boolean" ||
				!isLobbyOpen ||
				typeof selLobbyGameID === "undefined" ||
				typeof msg.data === "undefined" ||
				msg.data === null ||
				typeof msg.data.generic === "undefined" ||
				msg.data.generic === null ||
				typeof msg.data.generic.state === "undefined" ||
				typeof msg.data.generic.gameID === "undefined" ||
				msg.data.generic.gameID != selLobbyGameID ||
				!Array.isArray(gameList) ||
				gameList.length == 0
			)
				return state;

			var selGame = gameList.find((obj) => obj.gameID == msg.data.generic.gameID);
			if (selGame == undefined) return state;

			var playersList = helpers.getSelectedGamesPlayersList(selGame, msg, freeSlotLang, teamPrefixLang);
			if (playersList == "undefined") return state;
			playersList.map((player) => {
				player.isSelected = false;
				player.isHovered = false;
			});
			var newState = {
				...state,
				selectedGame: {
					...state.selectedGame,
					gameID: selLobbyGameID,
					gamePlayersList: playersList,
					loading: false,
					state: msg.data.generic.state != state.selectedGame.state ? msg.data.generic.state : state.selectedGame.state,
				},
			};
			return newState;
		case LOADING_GAME_DETAILS_IN_LOBBY_FINISHED:
			return {
				...state,
				selectedGame: { ...state.selectedGame, loadingGamePlayersList: false },
			};
		case SELECT_SLOT_IN_LOBBY:
			var newSelSlotID = initialState.selectedSlotID;
			var playerList = [...state.selectedGame.gamePlayersList];
			playerList.map((obj) => (obj.isSelected = false));
			var selectedPlayer = playerList.find((obj) => obj.slotID == payload);
			selectedPlayer.isSelected = true;
			newSelSlotID = selectedPlayer.slotID;
			return {
				...state,
				selectedGame: {
					...state.selectedGame,
					gamePlayersList: playerList,
					selectedSlotID: newSelSlotID,
				},
			};
		case MOUSE_ENTER_SLOT_IN_LOBBY:
			var playerList = [...state.selectedGame.gamePlayersList];
			playerList.map((obj) => (obj.isHovered = false));
			var hoveredSlot = playerList.find((obj) => obj.slotID == payload);
			hoveredSlot.isHovered = true;
			return {
				...state,
				selectedGame: { ...state.selectedGame, gamePlayersList: playerList },
			};
		case MOUSE_LEAVE_SLOT_IN_LOBBY:
			var playerList = [...state.selectedGame.gamePlayersList];
			var hoveredGame = playerList.find((obj) => obj.slotID == payload);
			hoveredGame.isHovered = false;
			return {
				...state,
				selectedGame: { ...state.selectedGame, gamePlayersList: playerList },
			};
		case CLEAR_GAME_DETAILS_IN_LOBBY:
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
