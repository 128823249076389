import { Platform } from "react-native";
import * as RNLocalize from "react-native-localize";
import { BUILD_VERSION } from "../../config/connection";
import { debugLogLimit, processState } from "../../config/defaults";
import Orientation from "../../libraries/Orientation/Orientation";
import {
	ACCEPT_TERMS_AND_CONDITIONS,
	ADMOB_INITED,
	CHANGE_PASSWORD,
	CHANGE_PASSWORD_FAILED,
	CLEAR_RESET_PASSWORD_ERROR,
	CLEAR_SEND_USERNAME_ERROR,
	CLEAR_UPDATE_AVATAR_ERROR,
	CLEAR_UPDATE_LANGUAGE_ERROR,
	CLEAR_UPDATE_USERNAME_ERROR,
	CLOSE_APP_LANGUAGE_SETTINGS,
	CLOSE_APP_NOTIFICATIONS_SETTINGS,
	CLOSE_BOARD_PREFERENCES,
	CLOSE_CARDS_PREFERENCES,
	CLOSE_CARD_SORT_ORDER,
	CLOSE_CHAT_TRANSLATION_SETTINGS,
	CLOSE_DEFAULT_CARDSET,
	CLOSE_DELETE_ACCOUNT,
	CLOSE_DELETE_ACCOUNT_FINAL,
	CLOSE_DELETE_ACCOUNT_REASON,
	CLOSE_EARN_FREE_SILVER,
	CLOSE_EMAIL_FRIEND,
	CLOSE_EMAIL_FRIEND_PREVIEW,
	CLOSE_EMAIL_NOTIFICATIONS_SETTINGS,
	CLOSE_FIX_BOARD_COLORS,
	CLOSE_FIX_BOARD_POSITIONS,
	CLOSE_GAME_ITEMS_MENU,
	CLOSE_GAME_MESSAGE,
	CLOSE_GOLD_HELP,
	CLOSE_GRAPHICAL_PREFERENCES,
	CLOSE_HELP,
	CLOSE_INVITE_FRIENDS_MENU,
	CLOSE_LANGUAGE_PREFERENCES,
	CLOSE_LEADER_BOARDS,
	CLOSE_LEADER_BOARDS_MENU,
	CLOSE_LOG_IN_TROUBLE,
	CLOSE_MY_PROFILE,
	CLOSE_NOTIFICATIONS_PREFERENCES,
	CLOSE_OVERRIDE_CARDSET,
	CLOSE_PLAYERS_LIST,
	CLOSE_PLAYER_OVERVIEW,
	CLOSE_PREFERENCES,
	CLOSE_RATE_DONE,
	CLOSE_RATE_FOR_SILVER,
	CLOSE_RESET_PASSWORD,
	CLOSE_SCREEN_PREFERENCES,
	CLOSE_SEARCH_PLAYERS,
	CLOSE_SEND_EMAIL_TROUBLE,
	CLOSE_SETTINGS,
	CLOSE_SHOP_HELP,
	CLOSE_SHOW_RUNNING_GAMES_IN_LIST,
	CLOSE_SILVER_HELP,
	CLOSE_SOUNDS_PREFERENCES,
	CLOSE_TUTORIALS,
	CLOSE_UPDATE_AVATAR,
	CLOSE_UPDATE_LANGUAGE,
	CLOSE_UPDATE_LOCATION,
	CLOSE_UPDATE_PERSONAL_DATA,
	CLOSE_UPDATE_USERNAME,
	CLOSE_VIP_BENEFITS_HELP,
	CLOSE_VIP_HELP,
	CONNECTION_FAILED,
	CONTINUE_EMAIL_SIGNUP,
	DELETE_AVATAR,
	DELETE_AVATAR_FAILED,
	DELETE_AVATAR_SUCCEEDED,
	EMAIL_LOGIN_CANCELED,
	EMAIL_LOGIN_FAILED,
	EMAIL_LOGIN_SUCCEEDED,
	EMAIL_SIGNUP_CANCELED,
	EMAIL_SIGNUP_FAILED,
	EMAIL_SIGNUP_SUCCEEDED,
	FACEBOOK_LOGIN_CANCELED,
	FACEBOOK_LOGIN_FAILED,
	FACEBOOK_LOGIN_SUCCEEDED,
	FINISH_APP_LOAD,
	FINISH_CHOOSE_USERNAME,
	FINISH_PROFILE_UPDATE,
	GO_BACK_TO_MAIN,
	HANDLE_WELCOME,
	NETWORK_RECOVERED,
	OPEN_APP_LANGUAGE_SETTINGS,
	OPEN_APP_NOTIFICATIONS_SETTINGS,
	OPEN_BOARD_PREFERENCES,
	OPEN_CARDSETS_SHOP,
	OPEN_CARDS_PREFERENCES,
	OPEN_CARD_SORT_ORDER,
	OPEN_CHAT_TRANSLATION_SETTINGS,
	OPEN_CHIPS_SHOP,
	OPEN_DEFAULT_CARDSET,
	OPEN_DELETE_ACCOUNT,
	OPEN_DELETE_ACCOUNT_FINAL,
	OPEN_DELETE_ACCOUNT_REASON,
	OPEN_EARN_FREE_SILVER,
	OPEN_EMAIL_FRIEND,
	OPEN_EMAIL_LOGIN,
	OPEN_EMAIL_NOTIFICATIONS_SETTINGS,
	OPEN_FIX_BOARD_COLORS,
	OPEN_FIX_BOARD_POSITIONS,
	OPEN_GAME_ITEMS_MENU,
	OPEN_GAME_MESSAGE,
	OPEN_GOLD_HELP,
	OPEN_GOLD_SHOP,
	OPEN_GRAPHICAL_PREFERENCES,
	OPEN_HELP,
	OPEN_INVITE_FRIENDS_MENU,
	OPEN_LANGUAGE_PREFERENCES,
	OPEN_LEADER_BOARDS,
	OPEN_LEADER_BOARDS_MENU,
	OPEN_LOG_IN_TROUBLE,
	OPEN_MISC_SHOP,
	OPEN_MULTIPLIERS_SHOP,
	OPEN_MY_PROFILE,
	OPEN_NOTIFICATIONS_PREFERENCES,
	OPEN_OVERRIDE_CARDSET,
	OPEN_PLAYERS_LIST,
	OPEN_PLAYER_OVERVIEW,
	OPEN_PREFERENCES,
	OPEN_RATE_DONE,
	OPEN_RATE_FOR_SILVER,
	OPEN_RESET_PASSWORD,
	OPEN_SCREEN_PREFERENCES,
	OPEN_SEARCH_PLAYERS,
	OPEN_SEND_EMAIL_TROUBLE,
	OPEN_SETTINGS,
	OPEN_SHOP_HELP,
	OPEN_SHOP_TAB,
	OPEN_SHOW_RUNNING_GAMES_IN_LIST,
	OPEN_SILVER_HELP,
	OPEN_SILVER_SHOP,
	OPEN_SOUNDS_PREFERENCES,
	OPEN_TUTORIALS,
	OPEN_UPDATE_AVATAR,
	OPEN_UPDATE_LANGUAGE,
	OPEN_UPDATE_LOCATION,
	OPEN_UPDATE_PERSONAL_DATA,
	OPEN_UPDATE_USERNAME,
	OPEN_UPGRADE_ACCOUNT,
	OPEN_USE_QUICK_MOVE,
	OPEN_VIP_BENEFITS_HELP,
	OPEN_VIP_HELP,
	OPEN_VIP_SHOP,
	RECONNECT_START,
	RESET_CLIENT,
	RESET_PASSWORD,
	RESET_PASSWORD_FAILED,
	RESET_PASSWORD_SUCCEEDED,
	SEND_MY_USERNAME,
	SEND_MY_USERNAME_FAILED,
	SEND_MY_USERNAME_SUCCEEDED,
	SET_APP_VISIBILITY_STATE,
	SET_INTERNET_REACHABLE,
	SET_INTERNET_UNREACHABLE,
	SET_LOAD_TIME,
	SET_LOGGED_IN_STATE,
	SHOW_EMAIL_FRIEND_PREVIEW,
	SOCKET_CONNECTION_CLOSED,
	START_CHAT,
	START_EMAIL_LOGIN,
	START_EMAIL_SIGNUP,
	START_FACEBOOK_LOGIN,
	START_LOG_IN,
	START_PLAY,
	START_PROFILE_UPDATE,
	START_SHOP,
	START_UPGRADE_ACCOUNT,
	UPDATE_APP_LANGUAGE,
	UPDATE_APP_LANGUAGE_FAILED,
	UPDATE_APP_LANGUAGE_SUCCEEDED,
	UPDATE_APP_NOTIFICATIONS,
	UPDATE_APP_NOTIFICATIONS_FAILED,
	UPDATE_APP_NOTIFICATIONS_SUCCEEDED,
	UPDATE_APP_ORIENTATION,
	UPDATE_AVATAR,
	UPDATE_AVATAR_FAILED,
	UPDATE_AVATAR_SUCCEEDED,
	UPDATE_CARD_SORT_ORDER,
	UPDATE_CARD_SORT_ORDER_FAILED,
	UPDATE_CARD_SORT_ORDER_SUCCEEDED,
	UPDATE_CHAT_TRANSLATION,
	UPDATE_CHAT_TRANSLATION_FAILED,
	UPDATE_CHAT_TRANSLATION_SUCCEEDED,
	UPDATE_DEFAULT_CARDSET,
	UPDATE_DEFAULT_CARDSET_FAILED,
	UPDATE_DEFAULT_CARDSET_SUCCEEDED,
	UPDATE_EMAIL_NOTIFICATIONS,
	UPDATE_EMAIL_NOTIFICATIONS_FAILED,
	UPDATE_EMAIL_NOTIFICATIONS_SUCCEEDED,
	UPDATE_FIXED_POSITIONS,
	UPDATE_FIXED_POSITIONS_FAILED,
	UPDATE_FIXED_POSITIONS_SUCCEEDED,
	UPDATE_LANGUAGE,
	UPDATE_LANGUAGE_FAILED,
	UPDATE_LANGUAGE_SUCCEEDED,
	UPDATE_LOCATION,
	UPDATE_LOCATION_FAILED,
	UPDATE_LOCATION_SUCCEEDED,
	UPDATE_OVERRIDE_CARDSET,
	UPDATE_OVERRIDE_CARDSET_FAILED,
	UPDATE_OVERRIDE_CARDSET_SUCCEEDED,
	UPDATE_PERSONAL_DATA,
	UPDATE_PERSONAL_DATA_FAILED,
	UPDATE_PERSONAL_DATA_SUCCEEDED,
	UPDATE_RESOLUTION,
	UPDATE_RESOLUTION_FAILED,
	UPDATE_RESOLUTION_SUCCEEDED,
	UPDATE_SHOW_RUNNING_GAMES_IN_LIST,
	UPDATE_SHOW_RUNNING_GAMES_IN_LIST_FAILED,
	UPDATE_SHOW_RUNNING_GAMES_IN_LIST_SUCCEEDED,
	UPDATE_SOUNDS_PREFERENCES,
	UPDATE_SOUNDS_PREFERENCES_FAILED,
	UPDATE_SOUNDS_PREFERENCES_SUCCEEDED,
	UPDATE_USERNAME,
	UPDATE_USERNAME_FAILED,
	UPDATE_USERNAME_SUCCEEDED,
	UPDATE_USER_OVERVIEW,
	UPDATE_USE_QUICK_MOVE,
	UPDATE_USE_QUICK_MOVE_FAILED,
	UPDATE_USE_QUICK_MOVE_SUCCEEDED,
	UPGRADE_ACCOUNT_FAILED,
	UPGRADE_ACCOUNT_SUCCEEDED,
	UPGRADE_REQUIRED,
} from "../actions/actionTypes";
import { TOURNAMENT_ACTIONS } from "../actionTypes";
const initOrientation = Platform.OS === "web" ? "" : Orientation.getInitialOrientation();

const initialState = {
	isWelcomeReceived: false,
	isUpgradable: false,
	appOrientation:
		initOrientation != "LANDSCAPE-LEFT" && initOrientation != "LANDSCAPE-RIGHT" ? "LANDSCAPE-LEFT" : initOrientation,
	admobInited: false,
	loadTimeMS: 0,
	pageLoadTimestamp: Date.now(),
	currencies: [],
	menuSoundOn: false,
	appLoaded: false,
	termsAccepted: false,
	isPlayStarted: false,
	isPlayRequested: false,
	isChatRequested: false,
	isShopRequested: false,
	isLoggedIn: false,
	isEmailLoggedIn: false,
	isFBLoggedIn: false,
	isLoginStarted: false,
	upgradeAccountOpened: false,
	upgradeAccountStarted: false,
	upgradeAccountState: processState.INITIALIZED,
	upgradeAccountError: { username: "", pwd: "", email: "" },
	emailLoginOpened: false,
	emailLoginStarted: false,
	emailLoginState: processState.INITIALIZED,
	emailLoginError: { username: "", pwd: "" },
	emailSignupStarted: false,
	isSignupContinued: false,
	signupState: processState.INITIALIZED,
	signupError: { username: "", pwd: "", email: "" },
	facebookLoginStarted: false,
	myProfileOpened: false,
	updateProfileOpened: false,
	welcome: {
		country: "",
		versioning: {
			warn: 0,
			minimal: 1,
			latest: BUILD_VERSION,
		},
		webResources: {
			avatars: "",
			botAvatars: "",
			cardsets: "",
			shop: "",
		},
		supportedClientCurrencies: [],
		features: {
			specific: {},
			generic: {},
		},
		sessionID: -1,
		enableDebugLogging: false,
		debugLogSize: debugLogLimit,
		enableAcks: false,
		tutorialMode: "ASK",
		tutorialFile: "tut1.mp4",
	},
	isBackground: false,
	troubleLoginStarted: false,
	troubleEmailSendOpened: false,
	emailUsernameSucceeded: false,
	emailUsernameState: processState.INITIALIZED,
	emailUsernameError: "",
	resetPasswordOpened: false,
	resetPasswordSucceeded: false,
	resetPasswordState: processState.INITIALIZED,
	resetPasswordError: "",
	changePasswordSucceeded: false,
	changePasswordState: processState.INITIALIZED,
	changePasswordError: {
		code: "",
		password: "",
		confirmPassword: "",
	},
	isSettingsOpened: false,
	isHelpOpened: false,
	preferencesOpened: false,
	soundPreferencesOpened: false,
	updateSoundsPreferencesState: processState.INITIALIZED,
	updateSoundsPreferencesSucceeded: false,
	graphicalPreferencesOpened: false,
	cardsPreferencesOpened: false,
	showRunningGamesInListOpened: false,
	showRunningGamesInListState: processState.INITIALIZED,
	showRunningGamesInListSucceeded: false,
	overrideCardsetOpened: false,
	updateOverrideCardsetState: processState.INITIALIZED,
	updateOverrideCardsetSucceeded: false,
	defaultCardsetOpened: false,
	updateDefaultCardsetState: processState.INITIALIZED,
	updateDefaultCardsetSucceeded: false,
	cardSortOrderOpened: false,
	updateCardSortOrderState: processState.INITIALIZED,
	updateCardSortOrderSucceeded: false,
	boardPreferencesOpened: false,
	fixBoardPositionsOpened: false,
	updateFixedPositionsState: processState.INITIALIZED,
	updateFixedPositionsSucceeded: false,
	fixBoardColorsOpened: false,
	languagePreferencesOpened: false,
	appLanguageSettingsOpened: false,
	updateAppLanguageState: processState.INITIALIZED,
	updateAppLanguageSucceeded: false,
	chatTranslationSettingsOpened: false,
	updateChatTranslationState: processState.INITIALIZED,
	updateChatTranslationSucceeded: false,
	notificationsPreferencesOpened: false,
	emailNotificationsSettingsOpened: false,
	updateEmailNotificationsState: processState.INITIALIZED,
	updateEmailNotificationsSucceeded: false,
	appNotificationsSettingsOpened: false,
	updateAppNotificationsState: processState.INITIALIZED,
	updateAppNotificationsSucceeded: false,
	screenPreferencesOpened: false,
	updateResolutionState: processState.INITIALIZED,
	updateResolutionSucceeded: false,
	updateUsernameOpened: false,
	updateUsernameSucceeded: false,
	updateUsernameState: processState.INITIALIZED,
	updateUsernameError: "",
	updateLocationOpened: false,
	updateLocationState: processState.INITIALIZED,
	updateLocationSucceeded: false,
	updateLanguageOpened: false,
	updateLanguageState: processState.INITIALIZED,
	updateLanguageSucceeded: false,
	updateLanguageError: "",
	updatePersonalDataOpened: false,
	updatePersonalDataState: processState.INITIALIZED,
	updatePersonalDataSucceeded: false,
	updateAvatarOpened: false,
	updateAvatarSucceeded: false,
	deleteAvatarSucceeded: false,
	updateAvatarState: processState.INITIALIZED,
	deleteAvatarState: processState.INITIALIZED,
	updateAvatarError: "",
	deleteAccountOpened: false,
	deleteAccountReasonOpened: false,
	deleteAccountFinalOpened: false,
	playersListOpened: false,
	searchPlayersOpened: false,
	tutorialsOpened: false,
	silverHelpOpened: false,
	goldHelpOpened: false,
	shopHelpOpened: false,
	vipHelpOpened: false,
	vipBenefitsHelpOpened: false,
	gameItemsMenuOpened: false,
	earnFreeSilverOpened: false,
	inviteFriendsMenuOpened: false,
	emailFriendOpened: false,
	emailFriendPreviewOpened: false,
	rateForSilverOpened: false,
	rateDoneOpened: false,
	isLeaderBoardsMenuOpened: false,
	isGameMessageOpened: false,
	isTournamentTabOpened: false,
	leaderBoardOpened: false,
	isInternetUnreachable: true,
	recoveringNetwork: false,
	isPlayerOverviewOpened: false,
	loadingUserOverview: true,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case UPGRADE_REQUIRED:
			return { ...state, isUpgradable: true };
		case UPDATE_APP_ORIENTATION:
			if (
				!payload ||
				typeof payload.orientation === "undefined" ||
				(payload.orientation != "LANDSCAPE-LEFT" && payload.orientation != "LANDSCAPE-RIGHT")
			)
				return state;

			return { ...state, appOrientation: payload.orientation };
		case ADMOB_INITED:
			return { ...state, admobInited: true };
		case FINISH_APP_LOAD:
			return { ...state, appLoaded: true };
		case SET_INTERNET_REACHABLE:
			return {
				...state,
				isInternetUnreachable: false,
				recoveringNetwork: false,
			};
		case SET_INTERNET_UNREACHABLE:
			return { ...state, isInternetUnreachable: true, recoveringNetwork: true };
		case NETWORK_RECOVERED:
			return { ...state, recoveringNetwork: false };
		case ACCEPT_TERMS_AND_CONDITIONS:
			return { ...state, termsAccepted: true };
		case RECONNECT_START:
		case CONNECTION_FAILED:
		case SOCKET_CONNECTION_CLOSED:
			return { ...state, isWelcomeReceived: false };
		case HANDLE_WELCOME:
			if (typeof payload !== "object" || payload === null) return state;

			return {
				...state,
				isWelcomeReceived: true,
				currencies: RNLocalize.getCurrencies(),
				menuSoundOn: payload.menuSoundOn || initialState.menuSoundOn,
				welcome: {
					...state.welcome,
					country: payload.country || "",
					versioning: payload.versioning || {
						warn: 0,
						minimal: 1,
						latest: BUILD_VERSION,
					},
					webResources: {
						avatars: payload.webResources ? payload.webResources.avatars || "" : "",
						botAvatars: payload.webResources ? payload.webResources.botAvatars || "" : "",
						cardsets: payload.webResources ? payload.webResources.cardsets || "" : "",
						shop: payload.webResources ? payload.webResources.shop || "" : "",
					},
					supportedClientCurrencies: Array.isArray(payload.supportedClientCurrencies)
						? payload.supportedClientCurrencies
						: [],
					features: {
						specific:
							payload.features && payload.features.specific
								? payload.features.specific
								: state.welcome.features.specific,
						generic:
							payload.features && payload.features.generic ? payload.features.generic : state.welcome.features.generic,
					},
					sessionID: typeof payload.sessionID === "string" ? payload.sessionID : state.welcome.sessionID,
					enableDebugLogging: payload.enableDebugLogging,
					debugLogSize: typeof payload.debugLogSize === "number" ? payload.debugLogSize : state.welcome.debugLogSize,
					enableAcks: payload.enableAcks,
					tutorialMode: payload.tutorialMode || state.tutorialMode,
					tutorialFile: payload.tutorialFile || state.tutorialFile,
				},
			};
		case SET_LOAD_TIME:
			if (typeof payload !== "number" || state.loadTimeMS != 0) return state;
			return { ...state, loadTimeMS: Date.now() - payload };
		case SET_LOGGED_IN_STATE:
			if (typeof payload !== "boolean") return state;
			if (payload) {
				return {
					...state,
					isLoggedIn: payload,
					emailLoginOpened: false,
					isLoginStarted: false,
					emailLoginStarted: false,
					emailLoginState: initialState.emailLoginState,
					emailLoginError: initialState.emailLoginError,
					emailSignupStarted: false,
					facebookLoginStarted: false,
				};
			} else {
				return {
					...initialState,
					isPlayStarted: state.isPlayStarted,
					isPlayRequested: state.isPlayRequested,
					isChatRequested: state.isChatRequested,
					isShopRequested: state.isShopRequested,
					isTournamentTabOpened: state.isTournamentTabOpened,
					isGameMessageOpened: state.isGameMessageOpened,
					isLeaderBoardsMenuOpened: state.isLeaderBoardsMenuOpened,
					leaderBoardOpened: state.leaderBoardOpened,
					loadTimeMS: state.loadTimeMS,
					appLoaded: state.appLoaded,
					termsAccepted: state.termsAccepted,
					welcome: state.welcome,
					isWelcomeReceived: state.isWelcomeReceived,
				};
			}
		case START_PLAY:
			return {
				...state,
				isPlayStarted: true,
				isPlayRequested: true,
				isChatRequested: false,
				isShopRequested: false,
			};
		case START_CHAT:
			return {
				...state,
				isPlayStarted: true,
				isChatRequested: true,
				isPlayRequested: false,
				isShopRequested: false,
			};
		case START_SHOP:
			return {
				...state,
				isPlayStarted: true,
				isChatRequested: false,
				isPlayRequested: false,
				isShopRequested: true,
			};
		case START_LOG_IN:
			return { ...state, isLoginStarted: true };
		case OPEN_UPGRADE_ACCOUNT:
			return { ...state, upgradeAccountOpened: true };
		case START_UPGRADE_ACCOUNT:
			return {
				...state,
				upgradeAccountStarted: true,
				upgradeAccountState: processState.STARTED,
				upgradeAccountError: initialState.upgradeAccountError,
			};
		case UPGRADE_ACCOUNT_FAILED:
			if (
				typeof payload === undefined ||
				typeof payload.isFieldError === undefined ||
				!payload.isFieldError ||
				typeof payload.field !== "string"
			) {
				return {
					...state,
					upgradeAccountStarted: false,
					upgradeAccountState: processState.FINISHED,
					upgradeAccountError: initialState.upgradeAccountError,
				};
			} else {
				const newErrors = { ...state.upgradeAccountError };
				switch (payload.field) {
					case "username":
						newErrors.username = payload.text;
						newErrors.pwd = "";
						newErrors.email = "";
						break;
					case "password":
						newErrors.username = "";
						newErrors.pwd = payload.text;
						newErrors.email = "";
						break;
					case "email":
						newErrors.username = "";
						newErrors.pwd = "";
						newErrors.email = payload.text;
						break;
				}
				return {
					...state,
					upgradeAccountStarted: false,
					upgradeAccountState: processState.FINISHED,
					upgradeAccountError: newErrors,
				};
			}
		case UPGRADE_ACCOUNT_SUCCEEDED:
			return {
				...state,
				isLoggedIn: true,
				isEmailLoggedIn: true,
				isLoginStarted: false,
				upgradeAccountOpened: false,
				upgradeAccountStarted: false,
				upgradeAccountState: processState.FINISHED,
				upgradeAccountError: initialState.upgradeAccountError,
			};
		case OPEN_EMAIL_LOGIN:
			return {
				...state,
				emailLoginOpened: true,
				isLoginStarted: false,
				emailSignupStarted: false,
				facebookLoginStarted: false,
			};
		case START_EMAIL_LOGIN:
			return {
				...state,
				isLoginStarted: true,
				emailLoginStarted: true,
				emailLoginState: processState.STARTED,
				emailLoginError: initialState.emailLoginError,
				emailSignupStarted: false,
				facebookLoginStarted: false,
			};
		case EMAIL_LOGIN_SUCCEEDED:
			return {
				...state,
				isLoggedIn: true,
				isEmailLoggedIn: true,
				emailLoginOpened: false,
				isLoginStarted: false,
				emailLoginStarted: false,
				emailLoginState: processState.FINISHED,
				emailLoginError: initialState.emailLoginError,
			};
		case EMAIL_LOGIN_FAILED:
			if (
				typeof payload === undefined ||
				typeof payload.isFieldError === undefined ||
				!payload.isFieldError ||
				typeof payload.field !== "string"
			) {
				return {
					...state,
					isLoginStarted: false,
					emailLoginStarted: false,
					emailLoginState: processState.FINISHED,
					emailLoginError: initialState.emailLoginError,
				};
			} else {
				const newErrors = { ...state.emailLoginError };
				switch (payload.field) {
					case "username":
						newErrors.username = payload.text;
						newErrors.pwd = "";
						break;
					case "password":
						newErrors.username = "";
						newErrors.pwd = payload.text;
						break;
				}
				return {
					...state,
					isLoginStarted: false,
					emailLoginStarted: false,
					emailLoginState: processState.FINISHED,
					emailLoginError: newErrors,
				};
			}
		case EMAIL_LOGIN_CANCELED:
			return {
				...state,
				isLoginStarted: false,
				emailLoginStarted: false,
				emailLoginState: initialState.emailLoginState,
				emailLoginError: initialState.emailLoginError,
				emailLoginOpened: false,
			};
		case START_EMAIL_SIGNUP:
			return {
				...state,
				emailLoginStarted: false,
				emailLoginState: initialState.emailLoginState,
				emailLoginError: initialState.emailLoginError,
				emailSignupStarted: true,
				facebookLoginStarted: false,
				isSignupContinued: false,
				signupState: initialState.signupState,
				signupError: initialState.signupError,
			};
		case CONTINUE_EMAIL_SIGNUP:
			return {
				...state,
				isSignupContinued: true,
				isLoginStarted: true,
				signupState: processState.STARTED,
			};
		case EMAIL_SIGNUP_SUCCEEDED:
			return {
				...state,
				isLoggedIn: true,
				isEmailLoggedIn: true,
				isLoginStarted: false,
				emailSignupStarted: false,
				isSignupContinued: false,
				signupState: processState.FINISHED,
				signupError: initialState.signupError,
			};
		case EMAIL_SIGNUP_FAILED:
			if (
				typeof payload === undefined ||
				typeof payload.isFieldError === undefined ||
				!payload.isFieldError ||
				typeof payload.field !== "string"
			) {
				return {
					...state,
					isSignupContinued: false,
					isLoginStarted: false,
					signupState: processState.FINISHED,
					signupError: initialState.signupError,
				};
			} else {
				const newErrors = { ...state.signupError };
				switch (payload.field) {
					case "username":
						newErrors.username = payload.text;
						newErrors.pwd = "";
						newErrors.email = "";
						break;
					case "password":
						newErrors.username = "";
						newErrors.pwd = payload.text;
						newErrors.email = "";
						break;
					case "email":
						newErrors.username = "";
						newErrors.pwd = "";
						newErrors.email = payload.text;
						break;
				}
				return {
					...state,
					isSignupContinued: false,
					isLoginStarted: false,
					signupState: processState.FINISHED,
					signupError: newErrors,
				};
			}
		case EMAIL_SIGNUP_CANCELED:
			return {
				...state,
				emailSignupStarted: false,
				isSignupContinued: false,
				isLoginStarted: false,
				signupState: initialState.signupState,
				signupError: initialState.signupError,
			};
		case START_FACEBOOK_LOGIN:
			return {
				...state,
				emailLoginStarted: false,
				emailSignupStarted: false,
				facebookLoginStarted: true,
			};
		case FACEBOOK_LOGIN_SUCCEEDED:
			return { ...state, isFBLoggedIn: true };
		case FINISH_CHOOSE_USERNAME:
			return {
				...state,
				isLoggedIn: true,
				isLoginStarted: false,
				facebookLoginStarted: false,
			};
		case FACEBOOK_LOGIN_FAILED:
		case FACEBOOK_LOGIN_CANCELED:
			return { ...state, facebookLoginStarted: false, isFBLoggedIn: false };
		case OPEN_MY_PROFILE:
			return { ...state, myProfileOpened: true };
		case CLOSE_MY_PROFILE:
			return { ...state, myProfileOpened: false };
		case START_PROFILE_UPDATE:
			return { ...state, updateProfileOpened: true };
		case FINISH_PROFILE_UPDATE:
			return { ...state, updateProfileOpened: false };
		case SET_APP_VISIBILITY_STATE:
			if (typeof payload !== "boolean") return state;
			return { ...state, isBackground: payload };
		case OPEN_LOG_IN_TROUBLE:
			return { ...state, troubleLoginStarted: true };
		case CLOSE_LOG_IN_TROUBLE:
			return {
				...state,
				troubleLoginStarted: false,
				troubleEmailSendOpened: initialState.troubleEmailSendOpened,
				emailUsernameSucceeded: initialState.emailUsernameSucceeded,
				emailUsernameState: initialState.emailUsernameState,
				emailUsernameError: initialState.emailUsernameError,
				resetPasswordOpened: initialState.resetPasswordOpened,
				resetPasswordSucceeded: initialState.resetPasswordSucceeded,
				resetPasswordState: initialState.resetPasswordState,
				resetPasswordError: initialState.resetPasswordError,
				changePasswordSucceeded: initialState.changePasswordSucceeded,
				changePasswordState: initialState.changePasswordState,
				changePasswordError: initialState.changePasswordError,
			};
		case OPEN_SEND_EMAIL_TROUBLE:
			return {
				...state,
				troubleEmailSendOpened: true,
				emailUsernameSucceeded: initialState.emailUsernameSucceeded,
				emailUsernameState: initialState.emailUsernameState,
				emailUsernameError: initialState.emailUsernameError,
			};
		case CLOSE_SEND_EMAIL_TROUBLE:
			return {
				...state,
				troubleEmailSendOpened: false,
				emailUsernameSucceeded: initialState.emailUsernameSucceeded,
				emailUsernameState: initialState.emailUsernameState,
				emailUsernameError: initialState.emailUsernameError,
			};
		case SEND_MY_USERNAME:
			return {
				...state,
				emailUsernameState: processState.STARTED,
				emailUsernameError: initialState.emailUsernameError,
				emailUsernameSucceeded: initialState.emailUsernameSucceeded,
			};
		case SEND_MY_USERNAME_SUCCEEDED:
			return {
				...state,
				emailUsernameSucceeded: true,
				emailUsernameError: initialState.emailUsernameError,
				emailUsernameState: processState.FINISHED,
			};
		case SEND_MY_USERNAME_FAILED:
			return {
				...state,
				emailUsernameSucceeded: false,
				emailUsernameState: processState.FINISHED,
				emailUsernameError: payload || state.emailUsernameError,
			};
		case CLEAR_SEND_USERNAME_ERROR:
			return {
				...state,
				emailUsernameError: initialState.emailUsernameError,
				emailUsernameState: initialState.emailUsernameState,
				emailUsernameSucceeded: initialState.emailUsernameSucceeded,
			};
		case OPEN_RESET_PASSWORD:
			return {
				...state,
				resetPasswordOpened: true,
				resetPasswordSucceeded: initialState.resetPasswordSucceeded,
				resetPasswordState: initialState.resetPasswordState,
				resetPasswordError: initialState.resetPasswordError,
				changePasswordSucceeded: initialState.changePasswordSucceeded,
				changePasswordState: initialState.changePasswordState,
				changePasswordError: initialState.changePasswordError,
			};
		case CLOSE_RESET_PASSWORD:
			return {
				...state,
				resetPasswordOpened: false,
				resetPasswordSucceeded: initialState.resetPasswordSucceeded,
				resetPasswordState: initialState.resetPasswordState,
				resetPasswordError: initialState.resetPasswordError,
				changePasswordSucceeded: initialState.changePasswordSucceeded,
				changePasswordState: initialState.changePasswordState,
				changePasswordError: initialState.changePasswordError,
			};
		case RESET_PASSWORD:
			return {
				...state,
				resetPasswordState: processState.STARTED,
				resetPasswordError: initialState.resetPasswordError,
				resetPasswordSucceeded: initialState.resetPasswordSucceeded,
				changePasswordSucceeded: initialState.changePasswordSucceeded,
				changePasswordState: initialState.changePasswordState,
				changePasswordError: initialState.changePasswordError,
			};
		case RESET_PASSWORD_SUCCEEDED:
			return {
				...state,
				resetPasswordSucceeded: true,
				resetPasswordError: initialState.resetPasswordError,
				resetPasswordState: processState.FINISHED,
			};
		case RESET_PASSWORD_FAILED:
			return {
				...state,
				resetPasswordSucceeded: false,
				resetPasswordState: processState.FINISHED,
				resetPasswordError: payload || state.resetPasswordError,
			};
		case CLEAR_RESET_PASSWORD_ERROR:
			return {
				...state,
				resetPasswordError: initialState.resetPasswordError,
				resetPasswordState: initialState.resetPasswordState,
				resetPasswordSucceeded: initialState.resetPasswordSucceeded,
			};
		case CHANGE_PASSWORD:
			return {
				...state,
				changePasswordState: processState.STARTED,
				changePasswordSucceeded: initialState.changePasswordSucceeded,
				changePasswordError: initialState.changePasswordError,
			};
		case CHANGE_PASSWORD_FAILED:
			if (typeof payload === undefined || typeof payload.text !== "string") return state;

			if (payload.field === "code") {
				return {
					...state,
					changePasswordSucceeded: false,
					changePasswordState: processState.FINISHED,
					changePasswordError: {
						...state.changePasswordError,
						code: payload.text,
					},
				};
			}
			return {
				...state,
				changePasswordSucceeded: false,
				changePasswordState: processState.FINISHED,
				changePasswordError: {
					...state.changePasswordError,
					password: payload.text,
					confirmPassword: payload.text,
				},
			};
		case OPEN_SETTINGS:
			return { ...state, isSettingsOpened: true };
		case CLOSE_SETTINGS:
			return {
				...state,
				isSettingsOpened: false,
				updateUsernameSucceeded: initialState.updateUsernameSucceeded,
				updateUsernameError: initialState.updateUsernameError,
				updateUsernameState: initialState.updateUsernameState,
				updateLocationState: initialState.updateLocationState,
				updateLocationSucceeded: initialState.updateLocationSucceeded,
				updateLanguageError: initialState.updateLanguageError,
				updateLanguageState: initialState.updateLanguageState,
				updateLanguageSucceeded: initialState.updateLanguageSucceeded,
				updatePersonalDataState: initialState.updatePersonalDataState,
				updatePersonalDataSucceeded: initialState.updatePersonalDataSucceeded,
				updateAvatarSucceeded: initialState.updateAvatarSucceeded,
				deleteAvatarSucceeded: initialState.deleteAvatarSucceeded,
				updateAvatarState: initialState.updateAvatarState,
				deleteAvatarState: initialState.deleteAvatarState,
				updateAvatarError: initialState.updateAvatarError,
			};
		case OPEN_UPDATE_USERNAME:
			return { ...state, updateUsernameOpened: true };
		case CLOSE_UPDATE_USERNAME:
			return {
				...state,
				updateUsernameOpened: false,
				updateUsernameSucceeded: initialState.updateUsernameSucceeded,
				updateUsernameState: initialState.updateUsernameState,
				updateUsernameError: initialState.updateUsernameError,
			};
		case UPDATE_USERNAME:
			return {
				...state,
				updateUsernameState: processState.STARTED,
				updateUsernameSucceeded: initialState.updateUsernameSucceeded,
				updateUsernameError: initialState.updateUsernameError,
			};
		case UPDATE_USERNAME_SUCCEEDED:
			return {
				...state,
				updateUsernameState: processState.FINISHED,
				updateUsernameSucceeded: true,
				updateUsernameError: initialState.updateUsernameError,
			};
		case UPDATE_USERNAME_FAILED:
			return {
				...state,
				updateUsernameState: processState.FINISHED,
				updateUsernameSucceeded: false,
				updateUsernameError: payload || state.updateUsernameError,
			};
		case CLEAR_UPDATE_USERNAME_ERROR:
			return {
				...state,
				updateUsernameError: initialState.updateUsernameError,
				updateUsernameState: initialState.updateUsernameState,
				updateUsernameSucceeded: initialState.updateUsernameSucceeded,
			};
		case OPEN_UPDATE_LOCATION:
			return { ...state, updateLocationOpened: true };
		case CLOSE_UPDATE_LOCATION:
			return {
				...state,
				updateLocationOpened: false,
				updateLocationState: initialState.updateLocationState,
				updateLocationSucceeded: initialState.updateLocationSucceeded,
			};
		case UPDATE_LOCATION:
			return {
				...state,
				updateLocationState: processState.STARTED,
				updateLocationSucceeded: initialState.updateLocationSucceeded,
			};
		case UPDATE_LOCATION_SUCCEEDED:
			return {
				...state,
				updateLocationState: processState.FINISHED,
				updateLocationSucceeded: true,
			};
		case UPDATE_LOCATION_FAILED:
			return {
				...state,
				updateLocationState: processState.FINISHED,
				updateLocationSucceeded: false,
			};
		case OPEN_UPDATE_LANGUAGE:
			return { ...state, updateLanguageOpened: true };
		case CLOSE_UPDATE_LANGUAGE:
			return {
				...state,
				updateLanguageOpened: false,
				updateLanguageError: initialState.updateLanguageError,
				updateLanguageState: initialState.updateLanguageState,
				updateLanguageSucceeded: initialState.updateLanguageSucceeded,
			};
		case UPDATE_LANGUAGE:
			return {
				...state,
				updateLanguageState: processState.STARTED,
				updateLanguageSucceeded: initialState.updateLanguageSucceeded,
				updateLanguageError: initialState.updateLanguageError,
			};
		case UPDATE_LANGUAGE_SUCCEEDED:
			return {
				...state,
				updateLanguageState: processState.FINISHED,
				updateLanguageSucceeded: true,
				updateLanguageError: initialState.updateLanguageError,
			};
		case UPDATE_LANGUAGE_FAILED:
			return {
				...state,
				updateLanguageState: processState.FINISHED,
				updateLanguageSucceeded: false,
				updateLanguageError: payload || state.updateLanguageError,
			};
		case CLEAR_UPDATE_LANGUAGE_ERROR:
			return {
				...state,
				updateLanguageError: initialState.updateLanguageError,
				updateLanguageState: initialState.updateLanguageState,
				updateLanguageSucceeded: initialState.updateLanguageSucceeded,
			};
		case OPEN_UPDATE_PERSONAL_DATA:
			return { ...state, updatePersonalDataOpened: true };
		case CLOSE_UPDATE_PERSONAL_DATA:
			return {
				...state,
				updatePersonalDataOpened: false,
				updatePersonalDataState: initialState.updatePersonalDataState,
				updatePersonalDataSucceeded: initialState.updatePersonalDataSucceeded,
			};
		case UPDATE_PERSONAL_DATA:
			return {
				...state,
				updatePersonalDataState: processState.STARTED,
				updatePersonalDataSucceeded: initialState.updatePersonalDataSucceeded,
			};
		case UPDATE_PERSONAL_DATA_SUCCEEDED:
			return {
				...state,
				updatePersonalDataState: processState.FINISHED,
				updatePersonalDataSucceeded: true,
			};
		case UPDATE_PERSONAL_DATA_FAILED:
			return {
				...state,
				updatePersonalDataState: processState.FINISHED,
				updatePersonalDataSucceeded: false,
			};
		case OPEN_UPDATE_AVATAR:
			return { ...state, updateAvatarOpened: true };
		case CLOSE_UPDATE_AVATAR:
			return {
				...state,
				updateAvatarOpened: false,
				updateAvatarSucceeded: initialState.updateAvatarSucceeded,
				deleteAvatarSucceeded: initialState.deleteAvatarSucceeded,
				updateAvatarState: initialState.updateAvatarState,
				deleteAvatarState: initialState.deleteAvatarState,
				updateAvatarError: initialState.updateAvatarError,
			};
		case UPDATE_AVATAR:
			return {
				...state,
				updateAvatarState: processState.STARTED,
				updateAvatarSucceeded: initialState.updateAvatarSucceeded,
				updateAvatarError: initialState.updateAvatarError,
			};
		case UPDATE_AVATAR_SUCCEEDED:
			return {
				...state,
				updateAvatarState: processState.FINISHED,
				updateAvatarSucceeded: true,
				updateAvatarError: initialState.updateAvatarError,
			};
		case UPDATE_AVATAR_FAILED:
			return {
				...state,
				updateAvatarState: processState.FINISHED,
				updateAvatarSucceeded: false,
				updateAvatarError: payload || state.updateAvatarError,
			};
		case DELETE_AVATAR:
			return {
				...state,
				deleteAvatarState: processState.STARTED,
				deleteAvatarSucceeded: initialState.deleteAvatarSucceeded,
				updateAvatarError: initialState.updateAvatarError,
			};
		case DELETE_AVATAR_SUCCEEDED:
			return {
				...state,
				deleteAvatarState: processState.FINISHED,
				deleteAvatarSucceeded: true,
				updateAvatarError: initialState.updateAvatarError,
			};
		case DELETE_AVATAR_FAILED:
			return {
				...state,
				deleteAvatarState: processState.FINISHED,
				deleteAvatarSucceeded: false,
				updateAvatarError: payload || state.updateAvatarError,
			};
		case CLEAR_UPDATE_AVATAR_ERROR:
			return {
				...state,
				updateAvatarState: initialState.updateAvatarState,
				deleteAvatarState: initialState.deleteAvatarState,
				updateAvatarSucceeded: initialState.updateAvatarSucceeded,
				deleteAvatarSucceeded: initialState.deleteAvatarSucceeded,
				updateAvatarError: initialState.updateAvatarError,
			};
		case OPEN_PREFERENCES:
			return { ...state, preferencesOpened: true };
		case CLOSE_PREFERENCES:
			return {
				...state,
				preferencesOpened: false,
				updateSoundsPreferencesState: initialState.updateSoundsPreferencesState,
				updateSoundsPreferencesSucceeded: initialState.updateSoundsPreferencesSucceeded,
				updateAppNotificationsState: initialState.updateAppNotificationsState,
				updateAppNotificationsSucceeded: initialState.updateAppNotificationsSucceeded,
				updateEmailNotificationsState: initialState.updateEmailNotificationsState,
				updateEmailNotificationsSucceeded: initialState.updateEmailNotificationsSucceeded,
				updateChatTranslationState: initialState.updateChatTranslationState,
				updateChatTranslationSucceeded: initialState.updateChatTranslationSucceeded,
				updateAppLanguageState: initialState.updateAppLanguageState,
				updateAppLanguageSucceeded: initialState.updateAppLanguageSucceeded,
			};
		case OPEN_SOUNDS_PREFERENCES:
			return { ...state, soundPreferencesOpened: true };
		case CLOSE_SOUNDS_PREFERENCES:
			return {
				...state,
				soundPreferencesOpened: false,
				updateSoundsPreferencesState: initialState.updateSoundsPreferencesState,
				updateSoundsPreferencesSucceeded: initialState.updateSoundsPreferencesSucceeded,
			};
		case UPDATE_SOUNDS_PREFERENCES:
			return {
				...state,
				updateSoundsPreferencesState: processState.STARTED,
				updateSoundsPreferencesSucceeded: initialState.updateSoundsPreferencesSucceeded,
			};
		case UPDATE_SOUNDS_PREFERENCES_SUCCEEDED:
			return {
				...state,
				updateSoundsPreferencesState: processState.FINISHED,
				updateSoundsPreferencesSucceeded: true,
			};
		case UPDATE_SOUNDS_PREFERENCES_FAILED:
			return {
				...state,
				updateSoundsPreferencesState: processState.FINISHED,
				updateSoundsPreferencesSucceeded: false,
			};
		case OPEN_GRAPHICAL_PREFERENCES:
			return { ...state, graphicalPreferencesOpened: true };
		case CLOSE_GRAPHICAL_PREFERENCES:
			return {
				...state,
				graphicalPreferencesOpened: false,
				updateResolutionState: initialState.updateResolutionState,
				updateResolutionSucceeded: initialState.updateResolutionSucceeded,
				updateFixedPositionsState: initialState.updateFixedPositionsState,
				updateFixedPositionsSucceeded: initialState.updateFixedPositionsSucceeded,
				showRunningGamesInListState: initialState.showRunningGamesInListState,
				showRunningGamesInListSucceeded: initialState.showRunningGamesInListSucceeded,
				updateOverrideCardsetState: initialState.updateOverrideCardsetState,
				updateOverrideCardsetSucceeded: initialState.updateOverrideCardsetSucceeded,
				updateDefaultCardsetState: initialState.updateDefaultCardsetState,
				updateDefaultCardsetSucceeded: initialState.updateDefaultCardsetSucceeded,
				updateCardSortOrderState: initialState.updateCardSortOrderState,
				updateCardSortOrderSucceeded: initialState.updateCardSortOrderSucceeded,
			};
		case OPEN_CARDS_PREFERENCES:
			return { ...state, cardsPreferencesOpened: true };
		case CLOSE_CARDS_PREFERENCES:
			return { ...state, cardsPreferencesOpened: false };
		case OPEN_SHOW_RUNNING_GAMES_IN_LIST:
			return { ...state, showRunningGamesInListOpened: true };
		case CLOSE_SHOW_RUNNING_GAMES_IN_LIST:
			return {
				...state,
				showRunningGamesInListOpened: false,
				showRunningGamesInListState: initialState.showRunningGamesInListState,
				showRunningGamesInListSucceeded: initialState.showRunningGamesInListSucceeded,
			};
		case UPDATE_SHOW_RUNNING_GAMES_IN_LIST:
			return {
				...state,
				showRunningGamesInListState: processState.STARTED,
				showRunningGamesInListSucceeded: initialState.showRunningGamesInListSucceeded,
			};
		case UPDATE_SHOW_RUNNING_GAMES_IN_LIST_SUCCEEDED:
			return {
				...state,
				showRunningGamesInListState: processState.FINISHED,
				showRunningGamesInListSucceeded: true,
			};
		case UPDATE_SHOW_RUNNING_GAMES_IN_LIST_FAILED:
			return {
				...state,
				showRunningGamesInListState: processState.FINISHED,
				showRunningGamesInListSucceeded: false,
			};
		case OPEN_OVERRIDE_CARDSET:
			return { ...state, overrideCardsetOpened: true };
		case CLOSE_OVERRIDE_CARDSET:
			return {
				...state,
				overrideCardsetOpened: false,
				updateOverrideCardsetState: initialState.updateOverrideCardsetState,
				updateOverrideCardsetSucceeded: initialState.updateOverrideCardsetSucceeded,
			};
		case UPDATE_OVERRIDE_CARDSET:
			return {
				...state,
				updateOverrideCardsetState: processState.STARTED,
				updateOverrideCardsetSucceeded: initialState.updateOverrideCardsetSucceeded,
			};
		case UPDATE_OVERRIDE_CARDSET_SUCCEEDED:
			return {
				...state,
				updateOverrideCardsetState: processState.FINISHED,
				updateOverrideCardsetSucceeded: true,
			};
		case UPDATE_OVERRIDE_CARDSET_FAILED:
			return {
				...state,
				updateOverrideCardsetState: processState.FINISHED,
				updateOverrideCardsetSucceeded: false,
			};
		case OPEN_DEFAULT_CARDSET:
			return { ...state, defaultCardsetOpened: true };
		case CLOSE_DEFAULT_CARDSET:
			return {
				...state,
				defaultCardsetOpened: false,
				updateDefaultCardsetState: initialState.updateDefaultCardsetState,
				updateDefaultCardsetSucceeded: initialState.updateDefaultCardsetSucceeded,
			};
		case UPDATE_DEFAULT_CARDSET:
			return {
				...state,
				updateDefaultCardsetState: processState.STARTED,
				updateDefaultCardsetSucceeded: initialState.updateDefaultCardsetSucceeded,
			};
		case UPDATE_DEFAULT_CARDSET_SUCCEEDED:
			return {
				...state,
				updateDefaultCardsetState: processState.FINISHED,
				updateDefaultCardsetSucceeded: true,
			};
		case UPDATE_DEFAULT_CARDSET_FAILED:
			return {
				...state,
				updateDefaultCardsetState: processState.FINISHED,
				updateDefaultCardsetSucceeded: false,
			};
		case OPEN_CARD_SORT_ORDER:
			return { ...state, cardSortOrderOpened: true };
		case CLOSE_CARD_SORT_ORDER:
			return {
				...state,
				cardSortOrderOpened: false,
				updateCardSortOrderState: initialState.updateCardSortOrderState,
				updateCardSortOrderSucceeded: initialState.updateCardSortOrderSucceeded,
			};
		case UPDATE_CARD_SORT_ORDER:
			return {
				...state,
				updateCardSortOrderState: processState.STARTED,
				updateCardSortOrderSucceeded: initialState.updateCardSortOrderSucceeded,
			};
		case UPDATE_CARD_SORT_ORDER_SUCCEEDED:
			return {
				...state,
				updateCardSortOrderState: processState.FINISHED,
				updateCardSortOrderSucceeded: true,
			};
		case UPDATE_CARD_SORT_ORDER_FAILED:
			return {
				...state,
				updateCardSortOrderState: processState.FINISHED,
				updateCardSortOrderSucceeded: false,
			};
		case OPEN_BOARD_PREFERENCES:
			return { ...state, boardPreferencesOpened: true };
		case CLOSE_BOARD_PREFERENCES:
			return { ...state, boardPreferencesOpened: false };
		case OPEN_FIX_BOARD_POSITIONS:
			return { ...state, fixBoardPositionsOpened: true };
		case CLOSE_FIX_BOARD_POSITIONS:
			return {
				...state,
				fixBoardPositionsOpened: false,
				updateFixedPositionsState: initialState.updateFixedPositionsState,
				updateFixedPositionsSucceeded: initialState.updateFixedPositionsSucceeded,
			};
		case UPDATE_FIXED_POSITIONS:
			return {
				...state,
				updateFixedPositionsState: processState.STARTED,
				updateFixedPositionsSucceeded: initialState.updateFixedPositionsSucceeded,
			};
		case UPDATE_FIXED_POSITIONS_SUCCEEDED:
			return {
				...state,
				updateFixedPositionsState: processState.FINISHED,
				updateFixedPositionsSucceeded: true,
			};
		case UPDATE_FIXED_POSITIONS_FAILED:
			return {
				...state,
				updateFixedPositionsState: processState.FINISHED,
				updateFixedPositionsSucceeded: false,
			};
		case OPEN_FIX_BOARD_COLORS:
			return { ...state, fixBoardColorsOpened: true };
		case CLOSE_FIX_BOARD_COLORS:
			return { ...state, fixBoardColorsOpened: false };
		case OPEN_LANGUAGE_PREFERENCES:
			return { ...state, languagePreferencesOpened: true };
		case CLOSE_LANGUAGE_PREFERENCES:
			return { ...state, languagePreferencesOpened: false };
		case OPEN_APP_LANGUAGE_SETTINGS:
			return { ...state, appLanguageSettingsOpened: true };
		case CLOSE_APP_LANGUAGE_SETTINGS:
			return {
				...state,
				appLanguageSettingsOpened: false,
				updateAppLanguageState: initialState.updateAppLanguageState,
				updateAppLanguageSucceeded: initialState.updateAppLanguageSucceeded,
			};
		case UPDATE_APP_LANGUAGE:
			return {
				...state,
				updateAppLanguageState: processState.STARTED,
				updateAppLanguageSucceeded: initialState.updateAppLanguageSucceeded,
			};
		case UPDATE_APP_LANGUAGE_SUCCEEDED:
			return {
				...state,
				updateAppLanguageState: processState.FINISHED,
				updateAppLanguageSucceeded: true,
			};
		case UPDATE_APP_LANGUAGE_FAILED:
			return {
				...state,
				updateAppLanguageState: processState.FINISHED,
				updateAppLanguageSucceeded: false,
			};
		case OPEN_CHAT_TRANSLATION_SETTINGS:
			return { ...state, chatTranslationSettingsOpened: true };
		case CLOSE_CHAT_TRANSLATION_SETTINGS:
			return {
				...state,
				chatTranslationSettingsOpened: false,
				updateChatTranslationState: initialState.updateChatTranslationState,
				updateChatTranslationSucceeded: initialState.updateChatTranslationSucceeded,
			};
		case UPDATE_CHAT_TRANSLATION:
			return {
				...state,
				updateChatTranslationState: processState.STARTED,
				updateChatTranslationSucceeded: initialState.updateChatTranslationSucceeded,
			};
		case UPDATE_CHAT_TRANSLATION_SUCCEEDED:
			return {
				...state,
				updateChatTranslationState: processState.FINISHED,
				updateChatTranslationSucceeded: true,
			};
		case UPDATE_CHAT_TRANSLATION_FAILED:
			return {
				...state,
				updateChatTranslationState: processState.FINISHED,
				updateChatTranslationSucceeded: false,
			};
		case OPEN_NOTIFICATIONS_PREFERENCES:
			return { ...state, notificationsPreferencesOpened: true };
		case CLOSE_NOTIFICATIONS_PREFERENCES:
			return { ...state, notificationsPreferencesOpened: false };
		case OPEN_EMAIL_NOTIFICATIONS_SETTINGS:
			return { ...state, emailNotificationsSettingsOpened: true };
		case CLOSE_EMAIL_NOTIFICATIONS_SETTINGS:
			return {
				...state,
				emailNotificationsSettingsOpened: false,
				updateEmailNotificationsState: initialState.updateEmailNotificationsState,
				updateEmailNotificationsSucceeded: initialState.updateEmailNotificationsSucceeded,
			};
		case UPDATE_EMAIL_NOTIFICATIONS:
			return {
				...state,
				updateEmailNotificationsState: processState.STARTED,
				updateEmailNotificationsSucceeded: initialState.updateEmailNotificationsSucceeded,
			};
		case UPDATE_EMAIL_NOTIFICATIONS_SUCCEEDED:
			return {
				...state,
				updateEmailNotificationsState: processState.FINISHED,
				updateEmailNotificationsSucceeded: true,
			};
		case UPDATE_EMAIL_NOTIFICATIONS_FAILED:
			return {
				...state,
				updateEmailNotificationsState: processState.FINISHED,
				updateEmailNotificationsSucceeded: false,
			};
		case OPEN_APP_NOTIFICATIONS_SETTINGS:
			return { ...state, appNotificationsSettingsOpened: true };
		case CLOSE_APP_NOTIFICATIONS_SETTINGS:
			return {
				...state,
				appNotificationsSettingsOpened: false,
				updateAppNotificationsState: initialState.updateAppNotificationsState,
				updateAppNotificationsSucceeded: initialState.updateAppNotificationsSucceeded,
			};
		case UPDATE_APP_NOTIFICATIONS:
			return {
				...state,
				updateAppNotificationsState: processState.STARTED,
				updateAppNotificationsSucceeded: initialState.updateAppNotificationsSucceeded,
			};
		case UPDATE_APP_NOTIFICATIONS_SUCCEEDED:
			return {
				...state,
				updateAppNotificationsState: processState.FINISHED,
				updateAppNotificationsSucceeded: true,
			};
		case UPDATE_APP_NOTIFICATIONS_FAILED:
			return {
				...state,
				updateAppNotificationsState: processState.FINISHED,
				updateAppNotificationsSucceeded: false,
			};
		case OPEN_SCREEN_PREFERENCES:
			return { ...state, screenPreferencesOpened: true };
		case CLOSE_SCREEN_PREFERENCES:
			return {
				...state,
				screenPreferencesOpened: false,
				updateResolutionState: initialState.updateResolutionState,
				updateResolutionSucceeded: initialState.updateResolutionSucceeded,
			};
		case UPDATE_RESOLUTION:
			return {
				...state,
				updateResolutionState: processState.STARTED,
				updateResolutionSucceeded: initialState.updateResolutionSucceeded,
			};
		case UPDATE_RESOLUTION_SUCCEEDED:
			return {
				...state,
				updateResolutionState: processState.FINISHED,
				updateResolutionSucceeded: true,
			};
		case UPDATE_RESOLUTION_FAILED:
			return {
				...state,
				updateResolutionState: processState.FINISHED,
				updateResolutionSucceeded: false,
			};
		case OPEN_DELETE_ACCOUNT:
			return { ...state, deleteAccountOpened: true };
		case CLOSE_DELETE_ACCOUNT:
			return { ...state, deleteAccountOpened: false };
		case OPEN_DELETE_ACCOUNT_REASON:
			return { ...state, deleteAccountReasonOpened: true };
		case CLOSE_DELETE_ACCOUNT_REASON:
			return {
				...state,
				deleteAccountReasonOpened: false,
				deleteAccountFinalOpened: initialState.deleteAccountFinalOpened,
			};
		case OPEN_DELETE_ACCOUNT_FINAL:
			return { ...state, deleteAccountFinalOpened: true };
		case CLOSE_DELETE_ACCOUNT_FINAL:
			return { ...state, deleteAccountFinalOpened: false };
		case OPEN_PLAYERS_LIST:
			return { ...state, playersListOpened: true };
		case CLOSE_PLAYERS_LIST:
			return { ...state, playersListOpened: false };
		case OPEN_SEARCH_PLAYERS:
			return { ...state, searchPlayersOpened: true };
		case CLOSE_SEARCH_PLAYERS:
			return { ...state, searchPlayersOpened: false };
		case OPEN_HELP:
			return { ...state, isHelpOpened: true };
		case CLOSE_HELP:
			return {
				...state,
				isHelpOpened: false,
				tutorialsOpened: initialState.tutorialsOpened,
				silverHelpOpened: initialState.silverHelpOpened,
				goldHelpOpened: initialState.goldHelpOpened,
				shopHelpOpened: initialState.shopHelpOpened,
				vipHelpOpened: initialState.vipHelpOpened,
				vipBenefitsHelpOpened: initialState.vipBenefitsHelpOpened,
			};
		case OPEN_TUTORIALS:
			return { ...state, tutorialsOpened: true };
		case CLOSE_TUTORIALS:
			return { ...state, tutorialsOpened: false };
		case OPEN_SILVER_HELP:
			return { ...state, silverHelpOpened: true };
		case CLOSE_SILVER_HELP:
			return { ...state, silverHelpOpened: false };
		case OPEN_GOLD_HELP:
			return { ...state, goldHelpOpened: true };
		case CLOSE_GOLD_HELP:
			return { ...state, goldHelpOpened: false };
		case OPEN_SHOP_HELP:
			return { ...state, shopHelpOpened: true };
		case CLOSE_SHOP_HELP:
			return { ...state, shopHelpOpened: false };
		case OPEN_VIP_HELP:
			return { ...state, vipHelpOpened: true };
		case CLOSE_VIP_HELP:
			return { ...state, vipHelpOpened: false, vipBenefitsHelpOpened: false };
		case OPEN_VIP_BENEFITS_HELP:
			return { ...state, vipBenefitsHelpOpened: true };
		case CLOSE_VIP_BENEFITS_HELP:
			return { ...state, vipBenefitsHelpOpened: false };
		case OPEN_GAME_ITEMS_MENU:
			return { ...state, gameItemsMenuOpened: true };
		case CLOSE_GAME_ITEMS_MENU:
			return { ...state, gameItemsMenuOpened: false };
		case OPEN_EARN_FREE_SILVER:
			return { ...state, earnFreeSilverOpened: true };
		case CLOSE_EARN_FREE_SILVER:
		case OPEN_CARDSETS_SHOP:
		case OPEN_CHIPS_SHOP:
		case OPEN_GOLD_SHOP:
		case OPEN_MISC_SHOP:
		case OPEN_MULTIPLIERS_SHOP:
		case OPEN_SILVER_SHOP:
		case OPEN_VIP_SHOP:
		case OPEN_SHOP_TAB:
			return {
				...state,
				earnFreeSilverOpened: false,
				inviteFriendsMenuOpened: false,
				emailFriendOpened: false,
				emailFriendPreviewOpened: false,
				rateForSilverOpened: false,
				rateDoneOpened: false,
			};
		case OPEN_INVITE_FRIENDS_MENU:
			return {
				...state,
				inviteFriendsMenuOpened: true,
				emailFriendPreviewOpened: false,
			};
		case CLOSE_INVITE_FRIENDS_MENU:
			return {
				...state,
				inviteFriendsMenuOpened: false,
				emailFriendOpened: false,
				emailFriendPreviewOpened: false,
			};
		case OPEN_EMAIL_FRIEND:
			return {
				...state,
				emailFriendOpened: true,
				emailFriendPreviewOpened: false,
			};
		case CLOSE_EMAIL_FRIEND:
			return {
				...state,
				emailFriendOpened: false,
				emailFriendPreviewOpened: false,
			};
		case SHOW_EMAIL_FRIEND_PREVIEW:
			return { ...state, emailFriendPreviewOpened: true };
		case CLOSE_EMAIL_FRIEND_PREVIEW:
			return { ...state, emailFriendPreviewOpened: false };
		case OPEN_RATE_FOR_SILVER:
			return { ...state, rateForSilverOpened: true };
		case CLOSE_RATE_FOR_SILVER:
			return { ...state, rateForSilverOpened: false, rateDoneOpened: false };
		case OPEN_RATE_DONE:
			return { ...state, rateDoneOpened: true };
		case CLOSE_RATE_DONE:
			return { ...state, rateDoneOpened: false };
		case OPEN_LEADER_BOARDS_MENU:
			return {
				...state,
				isLeaderBoardsMenuOpened: true,
				isTournamentTabOpened: false,
			};
		case CLOSE_LEADER_BOARDS_MENU:
			return { ...state, isLeaderBoardsMenuOpened: false };
		case OPEN_GAME_MESSAGE:
			return {
				...state,
				isGameMessageOpened: true,
				isTournamentTabOpened: false,
			};
		case CLOSE_GAME_MESSAGE:
			return { ...state, isGameMessageOpened: false };
		case TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_TAB:
			return { ...state, isTournamentTabOpened: true };
		case TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_TAB:
			return { ...state, isTournamentTabOpened: false };
		case OPEN_PLAYER_OVERVIEW:
			return {
				...state,
				isPlayerOverviewOpened: true,
				loadingUserOverview: true,
			};
		case UPDATE_USER_OVERVIEW:
			if (!payload || payload.type != "userOverview") return state;
			return { ...state, loadingUserOverview: false };
		case CLOSE_PLAYER_OVERVIEW:
			return {
				...state,
				isPlayerOverviewOpened: false,
				loadingUserOverview: true,
			};
		case OPEN_LEADER_BOARDS:
			return { ...state, leaderBoardOpened: true };
		case CLOSE_LEADER_BOARDS:
			return { ...state, leaderBoardOpened: false };
		case GO_BACK_TO_MAIN:
		case RESET_CLIENT:
			return {
				...state,
				isWelcomeReceived: type === RESET_CLIENT ? initialState.isWelcomeReceived : state.isWelcomeReceived,
				isPlayStarted: initialState.isPlayStarted,
				isLoginStarted: initialState.isLoginStarted,
				upgradeAccountOpened: initialState.upgradeAccountOpened,
				upgradeAccountStarted: initialState.upgradeAccountStarted,
				upgradeAccountState: initialState.upgradeAccountState,
				upgradeAccountError: initialState.upgradeAccountError,
				emailLoginOpened: initialState.emailLoginOpened,
				emailLoginStarted: initialState.emailLoginStarted,
				emailLoginState: initialState.emailLoginState,
				emailLoginError: initialState.emailLoginError,
				emailSignupStarted: initialState.emailSignupStarted,
				isSignupContinued: initialState.isSignupContinued,
				signupState: initialState.signupState,
				signupError: initialState.signupError,
				facebookLoginStarted: initialState.facebookLoginStarted,
				myProfileOpened: initialState.myProfileOpened,
				updateProfileOpened: initialState.updateProfileOpened,
				troubleLoginStarted: initialState.troubleLoginStarted,
				troubleEmailSendOpened: initialState.troubleEmailSendOpened,
				emailUsernameSucceeded: initialState.emailUsernameSucceeded,
				emailUsernameState: initialState.emailUsernameState,
				emailUsernameError: initialState.emailUsernameError,
				resetPasswordOpened: initialState.resetPasswordOpened,
				resetPasswordSucceeded: initialState.resetPasswordSucceeded,
				changePasswordSucceeded: initialState.changePasswordSucceeded,
				resetPasswordState: initialState.resetPasswordState,
				resetPasswordError: initialState.resetPasswordError,
				changePasswordState: initialState.changePasswordState,
				changePasswordError: initialState.changePasswordError,
				isSettingsOpened: initialState.isSettingsOpened,
				isHelpOpened: initialState.isHelpOpened,
				preferencesOpened: initialState.preferencesOpened,
				soundPreferencesOpened: initialState.soundPreferencesOpened,
				updateSoundsPreferencesState: initialState.updateSoundsPreferencesState,
				updateSoundsPreferencesSucceeded: initialState.updateSoundsPreferencesSucceeded,
				graphicalPreferencesOpened: initialState.graphicalPreferencesOpened,
				cardsPreferencesOpened: initialState.cardsPreferencesOpened,
				showRunningGamesInListOpened: initialState.showRunningGamesInListOpened,
				showRunningGamesInListState: initialState.showRunningGamesInListState,
				showRunningGamesInListSucceeded: initialState.showRunningGamesInListSucceeded,
				overrideCardsetOpened: initialState.overrideCardsetOpened,
				updateOverrideCardsetState: initialState.updateOverrideCardsetState,
				updateOverrideCardsetSucceeded: initialState.updateOverrideCardsetSucceeded,
				defaultCardsetOpened: initialState.defaultCardsetOpened,
				updateDefaultCardsetState: initialState.updateDefaultCardsetState,
				updateDefaultCardsetSucceeded: initialState.updateDefaultCardsetSucceeded,
				cardSortOrderOpened: initialState.cardSortOrderOpened,
				updateCardSortOrderState: initialState.updateCardSortOrderState,
				updateCardSortOrderSucceeded: initialState.updateCardSortOrderSucceeded,
				boardPreferencesOpened: initialState.boardPreferencesOpened,
				fixBoardPositionsOpened: initialState.fixBoardPositionsOpened,
				updateFixedPositionsState: initialState.updateFixedPositionsState,
				updateFixedPositionsSucceeded: initialState.updateFixedPositionsSucceeded,
				fixBoardColorsOpened: initialState.fixBoardColorsOpened,
				languagePreferencesOpened: initialState.languagePreferencesOpened,
				appLanguageSettingsOpened: initialState.appLanguageSettingsOpened,
				updateAppLanguageState: initialState.updateAppLanguageState,
				updateAppLanguageSucceeded: initialState.updateAppLanguageSucceeded,
				chatTranslationSettingsOpened: initialState.chatTranslationSettingsOpened,
				updateChatTranslationState: initialState.updateChatTranslationState,
				updateChatTranslationSucceeded: initialState.updateChatTranslationSucceeded,
				notificationsPreferencesOpened: initialState.notificationsPreferencesOpened,
				emailNotificationsSettingsOpened: initialState.emailNotificationsSettingsOpened,
				updateEmailNotificationsState: initialState.updateEmailNotificationsState,
				updateEmailNotificationsSucceeded: initialState.updateEmailNotificationsSucceeded,
				appNotificationsSettingsOpened: initialState.appNotificationsSettingsOpened,
				updateAppNotificationsState: initialState.updateAppNotificationsState,
				updateAppNotificationsSucceeded: initialState.updateAppNotificationsSucceeded,
				screenPreferencesOpened: initialState.screenPreferencesOpened,
				updateResolutionState: initialState.updateResolutionState,
				updateResolutionSucceeded: initialState.updateResolutionSucceeded,
				updateUsernameOpened: initialState.updateUsernameOpened,
				updateUsernameSucceeded: initialState.updateUsernameSucceeded,
				updateUsernameState: initialState.updateUsernameState,
				updateUsernameError: initialState.updateUsernameError,
				updateLocationOpened: initialState.updateLocationOpened,
				updateLocationState: initialState.updateLocationState,
				updateLocationSucceeded: initialState.updateLocationSucceeded,
				updateLanguageOpened: initialState.updateLanguageOpened,
				updateLanguageError: initialState.updateLanguageError,
				updateLanguageState: initialState.updateLanguageState,
				updateLanguageSucceeded: initialState.updateLanguageSucceeded,
				updatePersonalDataOpened: initialState.updatePersonalDataOpened,
				updatePersonalDataState: initialState.updatePersonalDataState,
				updatePersonalDataSucceeded: initialState.updatePersonalDataSucceeded,
				updateAvatarOpened: initialState.updateAvatarOpened,
				updateAvatarSucceeded: initialState.updateAvatarSucceeded,
				deleteAvatarSucceeded: initialState.deleteAvatarSucceeded,
				updateAvatarState: initialState.updateAvatarState,
				deleteAvatarState: initialState.deleteAvatarState,
				updateAvatarError: initialState.updateAvatarError,
				deleteAccountOpened: initialState.deleteAccountOpened,
				deleteAccountReasonOpened: initialState.deleteAccountReasonOpened,
				deleteAccountFinalOpened: initialState.deleteAccountFinalOpened,
				playersListOpened: initialState.playersListOpened,
				searchPlayersOpened: initialState.searchPlayersOpened,
				tutorialsOpened: initialState.tutorialsOpened,
				silverHelpOpened: initialState.silverHelpOpened,
				goldHelpOpened: initialState.goldHelpOpened,
				shopHelpOpened: initialState.shopHelpOpened,
				vipHelpOpened: initialState.vipHelpOpened,
				vipBenefitsHelpOpened: initialState.vipBenefitsHelpOpened,
				gameItemsMenuOpened: initialState.gameItemsMenuOpened,
				earnFreeSilverOpened: initialState.earnFreeSilverOpened,
				inviteFriendsMenuOpened: initialState.inviteFriendsMenuOpened,
				emailFriendOpened: initialState.emailFriendOpened,
				emailFriendPreviewOpened: initialState.emailFriendPreviewOpened,
				rateForSilverOpened: initialState.rateForSilverOpened,
				rateDoneOpened: initialState.rateDoneOpened,
				isLeaderBoardsMenuOpened: initialState.isLeaderBoardsMenuOpened,
				isGameMessageOpened: initialState.isGameMessageOpened,
				isTournamentTabOpened: initialState.isTournamentTabOpened,
				leaderBoardOpened: initialState.leaderBoardOpened,
				isPlayerOverviewOpened: initialState.isPlayerOverviewOpened,
				loadingUserOverview: initialState.loadingUserOverview,
			};
		default:
			return state;
	}
};
