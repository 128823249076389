import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { appFont, f_sourcesansprobold } from "../../config/defaults";
import { SMALL_BUTTON } from "../../config/images";
import stringify from "../../helpers/emoji_stringify";
// import InputEmoji from "react-input-emoji";
import InputEmoji from "../../libraries/InputEmoji/InputEmoji";
import { handleError } from "../ErrorHandler";
import { GameButton } from "./GameButton";

class ChatMessageSender extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messageText: "",
			inputWidth: "100%",
			focused: false,
		};
	}

	//#region lifecycle methods
	shouldComponentUpdate(nextProps, nextState) {
		/* if (
      (this.props.isEnabled !== nextProps.isEnabled && nextProps.isEnabled) ||
      (this.state.messageText != "" && nextState.messageText == "") ||
      this.props.chatRoomID !== nextProps.chatRoomID
    ) {
      this.refs.chatInput.focus();
    } */
		if (this.props.isEnabled !== nextProps.isEnabled && nextProps.isEnabled) {
			return true;
		}
		if (this.state.focused != nextState.focused || this.state.focused == nextState.focused) return false;
		return true;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			(prevProps.isEnabled !== this.props.isEnabled && this.props.isEnabled) ||
			(prevState.messageText != "" && prevState.messageText == "") ||
			prevProps.chatRoomID !== this.props.chatRoomID
		) {
			this.refs.chatInput.focus();
		}
	}
	//#endregion

	//#region events
	measureContainer(event) {
		try {
			this.setState({ inputWidth: event.nativeEvent.layout.width - 140 });
		} catch (error) {
			handleError(error);
		}
	}

	onChatButtonPress() {
		try {
			if (this.props.isEnabled === true) {
				this.setState({ messageText: "" });
				this.refs.chatInput.submit();
				this.refs.chatInput.focus();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onSubmitMessage() {
		try {
			const { messageText } = this.state;
			const { isEnabled, chatButtonPress } = this.props;
			const msg = this.refs.chatInput.value;
			if (isEnabled === true && msg != "") {
				if (typeof chatButtonPress === "function") {
					const msgToSend = stringify(msg);
					chatButtonPress(msgToSend);
					this.refs.chatInput.focus();
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		try {
			const { isEnabled } = this.props;
			const opacity = isEnabled ? 1 : 0.5;
			const buttonCursor = isEnabled ? { cursor: "pointer" } : { cursor: "default" };
			return (
				<View
					style={[styles.container, { opacity }, this.props.style]}
					onLayout={(event) => this.measureContainer(event)}
				>
					<InputEmoji
						ref="chatInput"
						pickerClass={this.props.isInGameRoom ? "game-room-emoji-picker" : ""}
						isInGlobalChat={true}
						value={this.state.messageText}
						disabled={!this.props.isEnabled}
						maxLength={512}
						onChange={(text) => {
							this.setState({ messageText: text });
						}}
						cleanOnEnter
						onFocus={() => {
							this.setState({ focused: true });
						}}
						onBlur={() => {
							this.setState({ focused: false });
						}}
						onEnter={() => this.onSubmitMessage()}
						placeholder=""
						fontFamily={f_sourcesansprobold}
					/>
					<GameButton
						disabled={!this.props.isEnabled}
						textContent={this.props.lang.chat}
						textContentStyle={[styles.textStyle, styles.textOutline]}
						onPress={this.onChatButtonPress.bind(this)}
						myStyle={[styles.chatButton, buttonCursor]}
						backgroundImage={{
							uri: SMALL_BUTTON,
							resizeMode: "stretch",
							style: [styles.buttonBackImgStyle],
						}}
						silentPress={this.props.silentPress}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		width: "100%",
		height: 60,
		backgroundColor: "#ecedee",
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
	},
	textStyle: {
		width: "100%",
		height: 60,
		fontFamily: appFont,
		fontSize: 14,
		paddingTop: 13,
		paddingBottom: 13,
		textAlign: "center",
		color: "#FFFFFF",
		textShadowColor: "#397423",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 0,
	},
	textOutline: {
		position: "absolute",
		zIndex: 0,
	},
	textInputStyle: {
		alignSelf: "flex-start",
		width: "100%",
		height: 40,
		margin: 10,
		paddingTop: 5,
		paddingBottom: 4,
		paddingLeft: 8,
		paddingRight: 8,
		textAlign: "left",
		fontFamily: appFont,
		fontSize: 16,
		overflow: "hidden",
		borderWidth: 1,
		borderColor: "#ecedee",
		borderRadius: 10,
		shadowColor: "#383838",
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 2,
	},
	chatButton: {
		alignSelf: "flex-end",
		width: 100,
		height: 40,
		backgroundColor: "transparent",
		borderWidth: 0,
	},
	buttonBackImgStyle: {
		width: 100,
		height: 40,
		margin: 10,
	},
});

export { ChatMessageSender };
