import React, { Component } from "react";
import { FlatList, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { isWebOrTablet, playerList, RESOLUTION } from "../../../../config/defaults";
import { MINUS_ICON } from "../../../../config/images";
import { enableHiddenFeatures } from "../../../../config/permissions";
import { MyWebSocket } from "../../../../connection";
import { TableHeader, TableHeaderCell, TableRow, TableRowCell } from "../../../common";
import { handleError } from "../../../ErrorHandler";

class FriendBanListTable extends Component {
	constructor(props) {
		super(props);
		this.state = { leftColumnWidth: 50, maxVisibleRows: 1 };
	}

	//#region events
	tableRendered(event) {
		try {
			const tableHeight = event.nativeEvent.layout.height;
			// const maxVisibleRows = isWebOrTablet
			//   ? this.props.resolution === RESOLUTION.HIGH
			//     ? Math.floor(tableHeight / 41)
			//     : Math.floor(tableHeight / 31)
			//   : Math.floor(tableHeight / 20);
			const maxVisibleRows =
				this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web"
					? Math.round(tableHeight / 41)
					: Math.round(tableHeight / 31);
			this.setState({ maxVisibleRows });
		} catch (error) {
			handleError(error);
		}
	}

	onRemovePress(item) {
		try {
			switch (this.props.tableKey) {
				case playerList.FRIEND_LIST:
					//remove from friendlist
					if (typeof item.userID === "string") {
						MyWebSocket.shared.sendMsg({
							sMessageID: 0,
							type: "removeFromFriendList",
							userID: item.userID,
						});
					}
					break;
				case playerList.BAN_LIST:
					//remove from banList
					if (typeof item.userID === "string") {
						MyWebSocket.shared.sendMsg({
							sMessageID: 0,
							type: "removeFromBanList",
							userID: item.userID,
						});
					}
					break;
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		const source = this.props.tableKey == playerList.FRIEND_LIST ? this.props.friendList : this.props.banList;
		return (
			<TableHeader>
				<TableHeaderCell
					key={"player_plListHeader"}
					cellContent={this.props.lang.playersList.playerHeader}
					containerStyle={{ flex: 1 }}
					textStyle={[{ flex: 1, textAlign: "center" }, this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 }]}
					onLayout={(event) => {
						this.setState({
							leftColumnWidth: Math.round(event.nativeEvent.layout.width),
						});
					}}
				/>
				<TableHeaderCell
					key={"action_plListHeader"}
					cellContent={this.props.lang.playersList.actionHeader}
					containerStyle={{ width: isWebOrTablet ? 80 : 50 }}
					textStyle={[{ flex: 1, textAlign: "center" }, this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 }]}
				/>
				{Platform.OS === "web" && this.state.maxVisibleRows < source.length && (
					<TableHeaderCell
						key={"scrollerHeader"}
						cellContent={""}
						containerStyle={{ width: 17 }}
						textStyle={{ flex: 1, textAlign: "center" }}
					/>
				)}
			</TableHeader>
		);
	}

	renderRow(item) {
		const source = this.props.tableKey == playerList.FRIEND_LIST ? this.props.friendList : this.props.banList;
		return (
			<TableRow
				rowId={"row_" + item.userID}
				disabled={!enableHiddenFeatures}
				style={this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? { height: 40 } : {}}
			>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					selectedBackgroundColor={"#F6A022"}
					cellWidth={this.state.leftColumnWidth}
					key={"player_" + item.userID}
					cellId={"player_" + item.userID}
					content={item.username}
					cellType={"text"}
					style={[
						{ color: "#FFFDF1" },
						Platform.OS !== "web" && { fontSize: 20 },
						this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
					]}
				/>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					selectedBackgroundColor={"#F6A022"}
					cellWidth={isWebOrTablet ? 80 : 50}
					key={"action_" + item.userID}
					cellId={"action_" + item.userID}
					cellType={"img_button"}
					onButtonImagePress={() => this.onRemovePress(item)}
					imgURL={MINUS_ICON}
					imgDim={{
						width: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 30 : 18,
						height: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 30 : 18,
						marginTop: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 2 : undefined,
					}}
				/>
				{Platform.OS === "web" && this.state.maxVisibleRows < source.length && (
					<TableRowCell
						lang={this.props.lang}
						backgroundColor={"transparent"}
						cellWidth={25}
						key={"scroller_" + item.userID}
						cellId={"scroller_" + item.userID}
						cellType={"text"}
						content={" "}
						style={{ paddingLeft: 0, paddingRight: 0 }}
					/>
				)}
			</TableRow>
		);
	}

	renderTable() {
		return (
			<FlatList
				keyExtractor={(player) => player.userID.toString()}
				data={this.props.tableKey == playerList.FRIEND_LIST ? this.props.friendList : this.props.banList}
				extraData={this.props.tableKey == playerList.FRIEND_LIST ? this.props.friendList : this.props.banList}
				renderItem={({ item }) => this.renderRow(item)}
				getItemLayout={(data, index) => ({
					length: 20,
					offset: 20 * index,
					index,
				})}
				style={{ height: "100%" }}
				onLayout={(event) => this.tableRendered(event)}
			/>
		);
	}

	render() {
		return (
			<View style={styles.tableStyle}>
				{this.renderHeader()}
				{this.renderTable()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	tableStyle: { flex: 1, height: "100%" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FriendBanListTable);
