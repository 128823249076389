import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprosemibold, processState, RESOLUTION } from "../../../../../config/defaults";
import { gameDimensions, overrrideCardsetDimensions } from "../../../../../config/dimensions";
import CheckBox from "../../../../../libraries/CheckBox";
import { closeShowRunningGamesInList, goBackToMain, updateShowRunningGamesInList } from "../../../../../redux/actions";
import Analytics from "../../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../../../../common";
import { handleError } from "../../../../ErrorHandler";

class ShowRunningGames extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			isShowRunningGamesChecked: this.props.showRunningGamesInList,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("GameListPreferences");
	}
	//#endregion

	//#region events
	onUpdatePress() {
		this.props.updateShowRunningGamesInList(this.state.isShowRunningGamesChecked);
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.showRunningGamesInListState != processState.STARTED}
				backAction={this.props.closeShowRunningGamesInList}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={this.props.showRunningGamesInList == this.state.isShowRunningGamesChecked}
					additionalTextStyle={_buttonTextStyle}
				/>
			</View>
		);
	}

	renderShowRunningGamesCheck() {
		var _innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center" }],
			_inputLabel = [styles.dataTitle, styles.dataText],
			_checkBoxStyle = [
				Platform.OS === "web" ? styles.checkBox : { transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }] },
			];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center", height: 80 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { width: 300, fontSize: 26 }];
			_checkBoxStyle = [
				Platform.OS === "web"
					? [styles.checkBox, { width: 30, height: 30 }]
					: { transform: [{ scale: Platform.OS === "ios" ? 1 : 1.75 }] },
			];
		}
		return (
			<View style={_innerContainerStyle}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.showRunningGames.checkBoxTitle}
				</Text>
				<View style={styles.checkboxContainer}>
					<CheckBox
						value={this.state.isShowRunningGamesChecked}
						onValueChange={() =>
							this.setState({
								isShowRunningGamesChecked: !this.state.isShowRunningGamesChecked,
							})
						}
						style={_checkBoxStyle}
					/>
				</View>
			</View>
		);
	}

	renderShowRunningGamesInfo() {
		try {
			var _infoStyle = [styles.dataText, styles.infoText];
			if (this.props.resolution === RESOLUTION.HIGH) {
				_infoStyle = [styles.dataText, styles.infoText, { fontSize: 24, lineHeight: 30 }];
			}
			return (
				<View style={{ width: "100%" }}>
					<Text allowFontScaling={false} style={_infoStyle}>
						{this.props.lang.showRunningGames.info}
					</Text>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderContent() {
		return (
			<View
				style={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				{this.renderShowRunningGamesCheck()}
				{this.renderShowRunningGamesInfo()}
				{this.renderUpdateButton()}
			</View>
		);
	}

	render() {
		if (this.props.showRunningGamesInListState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);

		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (overrrideCardsetDimensions.width > availableWidth || overrrideCardsetDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / overrrideCardsetDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / overrrideCardsetDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	contentContainer: { flex: 1, paddingHorizontal: 20 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		// marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprosemibold, fontSize: 20 },
	checkboxContainer: {
		width: 300,
		height: 40,
		flexDirection: "row",
		paddingTop: 5,
	},
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: 26,
		marginTop: 20,
		marginBottom: 20,
		fontSize: 18,
	},
	checkBox: { width: 20, height: 20, alignSelf: "center" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		showRunningGamesInList: state.currentUser.preferences.showRunningGamesInList,
		showRunningGamesInListState: state.app.showRunningGamesInListState,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeShowRunningGamesInList,
	goBackToMain,
	updateShowRunningGamesInList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowRunningGames);
