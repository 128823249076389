import React from "react";
import { StyleSheet, Text } from "react-native";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

const Link = (props) => {
	const { onPress, buttonStyle, textStyle, text, silentPress } = props;
	return (
		<AppTouchableOpacity onPress={onPress} style={buttonStyle} silentPress={silentPress}>
			<Text allowFontScaling={false} style={[styles.txtStyle, textStyle]}>
				{text}
			</Text>
		</AppTouchableOpacity>
	);
};

const styles = StyleSheet.create({
	txtStyle: {
		textDecorationLine: "underline",
		textDecorationColor: "rgb(0,0,238)",
		color: "rgb(85,26,139)",
	},
});

export { Link };
