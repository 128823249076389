import { cloneDeep, sortBy } from "lodash";
import { leaderboardCategory, leaderboard_Type } from "../../config/defaults";
import { mapObject } from "../../helpers/commonHelpers";
import { CLOSE_LEADER_BOARDS, GO_BACK_TO_MAIN, OPEN_LEADER_BOARDS, RESET_CLIENT } from "../actions/actionTypes";
import { SAVE_LB_DATA, SELECT_LB_CATEGORY, SELECT_LB_GROUP } from "../actionTypes";

const initialState = {
	lbTypeSelected: leaderboard_Type.GLOBAL,
	lbCategorySelected: null,
	global: {
		ALWAYS: [],
		YEAR: [],
		MONTH: [],
		WEEK: [],
		DAY: [],
	},
	friends: {
		ALWAYS: [],
		YEAR: [],
		MONTH: [],
		WEEK: [],
		DAY: [],
	},
	groups: [
		{
			groupID: 1,
			groupName: "",
			selected: true,
			data: {
				ALWAYS: [],
				YEAR: [],
				MONTH: [],
				WEEK: [],
				DAY: [],
			},
		},
		{
			groupID: 2,
			groupName: "",
			selected: false,
			data: {
				ALWAYS: [],
				YEAR: [],
				MONTH: [],
				WEEK: [],
				DAY: [],
			},
		},
	],
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SELECT_LB_CATEGORY:
			switch (payload) {
				case leaderboardCategory.ALWAYS:
				case leaderboardCategory.YEAR:
				case leaderboardCategory.MONTH:
				case leaderboardCategory.WEEK:
				case leaderboardCategory.DAY:
					return { ...state, lbCategorySelected: payload };
				default:
					return state;
			}
		case SELECT_LB_GROUP:
			if (payload == undefined || payload.groupID == undefined) return state;
			var groupsClone = cloneDeep(state.groups);
			const foundIndex = groupsClone.findIndex((g) => g.groupID == payload.groupID);
			if (foundIndex == -1) return state;
			groupsClone.forEach((g) => (g.selected = false));
			groupsClone[foundIndex].selected = true;

			return { ...state, groups: groupsClone };
		case OPEN_LEADER_BOARDS:
			switch (payload.type) {
				case leaderboard_Type.GLOBAL:
				case leaderboard_Type.FRIENDS:
					return { ...state, lbTypeSelected: payload.type };
				case leaderboard_Type.GROUPS:
					var newGroups = cloneDeep(state.groups);
					newGroups[0].groupName = payload.lang[leaderboard_Type.GLOBAL];
					newGroups[1].groupName = payload.lang[leaderboard_Type.FRIENDS];
					return { ...state, lbTypeSelected: payload.type, groups: newGroups };
				default:
					return state;
			}
		case CLOSE_LEADER_BOARDS:
		case GO_BACK_TO_MAIN:
			return {
				...state,
				lbTypeSelected: initialState.lbTypeSelected,
				lbCategorySelected: initialState.lbCategorySelected,
			};
		case SAVE_LB_DATA:
			if (payload == undefined || typeof payload.positions === "undefined") return state;

			var newList = [];
			mapObject(payload.positions, (k, v) => {
				newList.push(v);
			});
			sortBy(newList, (item) => item.position);
			switch (state.lbTypeSelected) {
				case leaderboard_Type.GLOBAL:
					return {
						...state,
						global: {
							...state.global,
							ALWAYS: newList,
							YEAR: newList,
							MONTH: newList,
							WEEK: newList,
							DAY: newList,
						},
					};
				case leaderboard_Type.FRIENDS:
					return {
						...state,
						friends: {
							...state.friends,
							ALWAYS: newList,
							YEAR: newList,
							MONTH: newList,
							WEEK: newList,
							DAY: newList,
						},
					};
				case leaderboard_Type.GROUPS:
					const newGroups = cloneDeep(state.groups);
					const selGroup = newGroups.find((gr) => gr.selected == true);
					if (selGroup == undefined || selGroup.groupID == undefined) return state;
					selGroup.data.ALWAYS = newList;
					selGroup.data.YEAR = newList;
					selGroup.data.MONTH = newList;
					selGroup.data.WEEK = newList;
					selGroup.data.DAY = newList;
					return { ...state, groups: newGroups };
				default:
					return state;
			}
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
