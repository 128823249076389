import React, { Component } from "react";
import { Dimensions, ImageBackground, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprosemibold } from "../../../../config/defaults";
import { deleteAccountDimensions, gameDimensions } from "../../../../config/dimensions";
import { closeDeleteAccount, goBackToMain, openDeleteAccountReason } from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader } from "../../../common";
import { handleError } from "../../../ErrorHandler";
import DeleteAccountReason from "./DeleteAccountReason";

class DeleteAccount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("DeleteAccount");
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeDeleteAccount}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderProceedButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.props.openDeleteAccountReason}
					textContent={this.props.lang.deleteAccount.main.button}
					accessibilityLabel={this.props.lang.deleteAccount.main.button}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderInfo() {
		return (
			<View style={{ width: "100%" }}>
				<Text allowFontScaling={false} style={[styles.dataText, styles.infoText]}>
					{this.props.lang.deleteAccount.main.content}
				</Text>
			</View>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				{this.renderHeader()}
				<View style={styles.container}>
					<View style={[styles.contentContainer, { transform: [{ scale: this.state.contentScale }] }]}>
						{this.renderInfo()}
						{this.renderProceedButton()}
					</View>
				</View>
			</ImageBackground>
		);
	}

	render() {
		if (this.props.deleteAccountReasonOpened) {
			return <DeleteAccountReason />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120;
			const availableHeight = windowDim.height - 100;
			if (deleteAccountDimensions.width > availableWidth || deleteAccountDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / deleteAccountDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / deleteAccountDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	contentContainer: { flex: 1, paddingLeft: 20 },
	dataText: { fontFamily: f_sourcesansprosemibold, fontSize: 20 },
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: 26,
		marginTop: 20,
		marginBottom: 20,
		fontSize: 18,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		deleteAccountReasonOpened: state.app.deleteAccountReasonOpened,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeDeleteAccount,
	goBackToMain,
	openDeleteAccountReason,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccount);
