import { RESOLUTION } from "../../config/defaults";
import { mySounds } from "../../config/sounds";
import { MyWebSocket } from "../../connection";
import { store } from "../../redux/store";
import {
	ADD_JOINED_ROOM,
	CALC_GLOBAL_NEW_MESSAGES,
	CHANGE_CHATROOM_TABS_SOUND,
	CHAT_ROOM_USER_UPDATED,
	CLEAR_GLOBAL_CHAT,
	CLOSE_CHATROOM_TAB,
	OPEN_JOINED_ROOM,
	RECEIVE_GLOBAL_CHAT_MESSAGE,
	REMOVE_CHATROOM,
	REMOVE_USER_FROM_CHATROOM,
	SAVE_CHATROOM_TABS_ORDER,
	SELECT_CHATROOM_TAB,
	SET_CHATROOM_TAB_WIDTH,
	SET_ONLINE_PLAYERS_AMOUNT,
	UPDATE_CHATROOM_LIST,
} from "./actionTypes";
import { finishReconnect } from "./SocketActions";

export const chatRoomUserUpdated = (msg) => {
	return { type: CHAT_ROOM_USER_UPDATED, payload: msg };
};

export const subcribeToGlobalChat = () => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			type: "chatListSubscribe",
			sMessageID: 0,
		});
	};
};

export const unsubcribeFromGlobalChat = () => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			type: "chatListUnsubscribe",
			sMessageID: 0,
		});
	};
};

export const startChatRoomJoin = (chatRoomID) => {
	return (dispatch) => {
		if (!isNaN(chatRoomID)) {
			MyWebSocket.shared.sendMsg({
				type: "joinChatRoom",
				sMessageID: 0,
				chatRoomID: chatRoomID,
			});
		}
	};
};

export const startChatRoomLeave = (chatRoomID) => {
	return (dispatch) => {
		if (!isNaN(chatRoomID)) {
			MyWebSocket.shared.sendMsg({
				type: "leaveChatRoom",
				sMessageID: 0,
				chatRoomID: chatRoomID,
			});
		}
	};
};

export const sendGlobalChatMessage = (message, chatRoomID) => {
	return (dispatch) => {
		if (typeof message === "string" && message.length > 0 && message.length < 255 && !isNaN(chatRoomID)) {
			MyWebSocket.shared.sendMsg({
				type: "chat",
				sMessageID: 0,
				chatRoomID: chatRoomID,
				content: message,
			});
		}
	};
};

export const setOnlinePlayersAmount = (amount) => {
	return (dispatch) => {
		dispatch(_setOnlinePlayersAmount(amount));
	};
};

export const updateChatRoomList = (msg) => {
	return (dispatch) => {
		dispatch(_updateChatRoomList(msg));

		if (store.getState().socket.isReconnecting == true) dispatch(finishReconnect());
		dispatch(calcGlobalNewMessages());
	};
};

export const removeChatRoom = (msg) => {
	return (dispatch) => {
		dispatch(_removeChatRoom(msg));
		if (typeof msg.chatRoomID !== "undefined") {
			var joinedRooms = store.getState().globalChat.joinedChatRooms;
			var actRoom = joinedRooms.find((r) => r.chatRoomID == msg.chatRoomID);
			if (actRoom != undefined) dispatch(closeChatRoomTab(actRoom));
			if (joinedRooms.length > 0) {
				var fri = joinedRooms.findIndex((r) => r.chatRoomID != msg.chatRoomID);
				if (fri != -1) dispatch(selectChatRoomTab(joinedRooms[fri]));
			}
		}
		dispatch(calcGlobalNewMessages());
	};
};

export const selectChatRoomTab = (room) => {
	return (dispatch) => {
		dispatch(_selectChatRoomTab(room));
		dispatch(calcGlobalNewMessages());
	};
};

export const changeChatRoomTabsSound = (room) => {
	return { type: CHANGE_CHATROOM_TABS_SOUND, payload: room };
};

export const closeChatRoomTab = (room) => {
	return (dispatch) => {
		dispatch(_closeChatRoomTab(room));
		dispatch(calcGlobalNewMessages());
	};
};

export const saveChatRoomTabsOrder = (newOrder) => {
	return { type: SAVE_CHATROOM_TABS_ORDER, payload: newOrder };
};

export const calcGlobalNewMessages = () => {
	return { type: CALC_GLOBAL_NEW_MESSAGES };
};

export const setChatRoomTabWidth = (room, width) => {
	return {
		type: SET_CHATROOM_TAB_WIDTH,
		payload: { rroom: room, width: width },
	};
};

export const addJoinedRoom = (msg) => {
	return {
		type: ADD_JOINED_ROOM,
		payload: {
			crjMsg: msg,
			userDetails: store.getState().currentUser.userDetails,
		},
	};
};

export const removeUserFromChatRoom = (msg) => {
	return {
		type: REMOVE_USER_FROM_CHATROOM,
		payload: {
			crlMsg: msg,
			uDetails: store.getState().currentUser.userDetails,
		},
	};
};

export const receiveGlobalChatMessage = (msg) => {
	return (dispatch) => {
		dispatch(_receiveGlobalChatMessage(msg));
		dispatch(calcGlobalNewMessages());
	};
};

export const openJoinedRoom = (room) => {
	return (dispatch) => {
		dispatch(_openJoinedRoom(room));
		dispatch(calcGlobalNewMessages());
	};
};

export const clearGlobalChat = () => {
	return (dispatch) => {
		dispatch(_clearGlobalChat());
		//TODO: sendChatDebugMessage
	};
};

const _setOnlinePlayersAmount = (amount) => {
	return { type: SET_ONLINE_PLAYERS_AMOUNT, payload: amount };
};

const _updateChatRoomList = (msgNewRoomList) => {
	return { type: UPDATE_CHATROOM_LIST, payload: msgNewRoomList };
};

const _removeChatRoom = (msgRemoveRoom) => {
	return { type: REMOVE_CHATROOM, payload: msgRemoveRoom };
};

const _selectChatRoomTab = (room) => {
	return { type: SELECT_CHATROOM_TAB, payload: room };
};

const _closeChatRoomTab = (room) => {
	return { type: CLOSE_CHATROOM_TAB, payload: room };
};

const _receiveGlobalChatMessage = (msg) => {
	const { tab, currentUser, globalChat } = store.getState();
	const _resolution = currentUser.preferences.resolution;
	msg.mySound = mySounds.chatGlobalSound;
	msg.isTabChatButtonActive = tab.isTabChatButtonActive;
	msg.isGameStarted = tab.isGameStarted;
	msg.isGameRoomOpen = tab.isGameRoomOpen;
	msg.isGameStarted = tab.isGameStarted;
	msg.playSound = !tab.isGameStarted || _resolution !== RESOLUTION.LOW;
	if (typeof msg != "undefined") {
		var receiverRoomIndex = globalChat.joinedChatRooms.findIndex((r) => r.chatRoomID == msg.chatRoomID);
		if (receiverRoomIndex >= 0) {
			var receiverRoom = globalChat.joinedChatRooms[receiverRoomIndex];
			if (!receiverRoom.muted && msg.playSound == true) {
				if (
					currentUser.preferences.playGlobalChatSound == true &&
					typeof msg.mySound !== "undefined" &&
					msg.mySound != null &&
					msg.mySound.isLoaded() == true
				)
					msg.mySound.play();
			}
		}
	}
	return { type: RECEIVE_GLOBAL_CHAT_MESSAGE, payload: msg };
};

const _openJoinedRoom = (room) => {
	return { type: OPEN_JOINED_ROOM, payload: room };
};

const _clearGlobalChat = () => {
	return { type: CLEAR_GLOBAL_CHAT };
};
