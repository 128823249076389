import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor, RESOLUTION } from "../../../config/defaults";
import { gameDimensions } from "../../../config/dimensions";
import {
	closeLogInTrouble,
	goBackToMain,
	openResetPassword,
	openSendEmailTrouble,
	startEmailSignup,
} from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { ColoredButton, NavigationHeader } from "../../common";
import ResetPassword from "./ResetPassword";
import SendEmailTrouble from "./SendEmailTrouble";

class LogInTrouble extends Component {
	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("LoginTrouble");
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeLogInTrouble}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "email":
				textContent = this.props.lang.loginTrouble.email;
				onPressFunc = () => this.props.openSendEmailTrouble();
				_buttonColor = buttonColor.YELLOW;
				break;
			case "pwd":
				textContent = this.props.lang.loginTrouble.pwd;
				onPressFunc = () => this.props.openResetPassword();
				_buttonColor = buttonColor.PURPLE;
				break;
			case "register":
				textContent = this.props.lang.loginTrouble.register;
				onPressFunc = () => {
					this.props.goBackToMain();
					this.props.startEmailSignup();
				};
				_buttonColor = buttonColor.LIGHT_BLUE;
				break;
		}
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				additionalTextStyle={_buttonTextStyle}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.DEFAULT_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderButton("email")}
					{this.renderButton("pwd")}
					{this.renderButton("register")}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.troubleEmailSendOpened) {
			return <SendEmailTrouble />;
		} else if (this.props.resetPasswordOpened) {
			return <ResetPassword />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		minHeight: "100%",
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resetPasswordOpened: state.app.resetPasswordOpened,
		troubleEmailSendOpened: state.app.troubleEmailSendOpened,
		resolution: state.currentUser.preferences.resolution,
		DEFAULT_BACK: state.images.DEFAULT_BACK,
	};
};

const mapDispatchToProps = {
	closeLogInTrouble,
	goBackToMain,
	openResetPassword,
	openSendEmailTrouble,
	startEmailSignup,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogInTrouble);
