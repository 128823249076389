import React from "react";
import { StyleSheet } from "react-native";
import { buttonColor, f_sourcesansprobold } from "../../config/defaults";
import {
	BLUE_BTN,
	BLUE_LONG_BTN,
	GREEN_BTN,
	GREEN_LONG_BTN,
	GREY_BTN,
	LIGHT_BLUE_BTN,
	PURPLE_BTN,
	PURPLE_LONG_BTN,
	RED_BTN,
	RED_LONG_BTN,
	YELLOW_BTN,
	YELLOW_LONG_BTN,
} from "../../config/images";
import { handleError } from "../ErrorHandler";
import { GameButton } from "./GameButton";

const ColoredButton = ({
	width,
	height,
	onPressFunc,
	onLayout,
	textContent,
	additionalTextStyle,
	color,
	disabled,
	isLong,
	permissionDenied,
	silentPress,
	isInMenu,
	accessibilityLabel,
}) => {
	try {
		const buttonDimensions = { width: width, height: height };

		var shadowColor,
			bckImageSource,
			textColor = "#FFFFFF";
		switch (color) {
			case buttonColor.BLUE:
				textColor = "#E7F5FA";
				shadowColor = "#51595B";
				bckImageSource = isLong ? BLUE_LONG_BTN : BLUE_BTN;
				break;
			case buttonColor.GREY:
				shadowColor = "#0A4458";
				bckImageSource = GREY_BTN;
				break;
			case buttonColor.LIGHT_BLUE:
				shadowColor = "#0A4458";
				bckImageSource = LIGHT_BLUE_BTN;
				break;
			case buttonColor.PURPLE:
				shadowColor = "#5825A7";
				bckImageSource = isLong ? PURPLE_LONG_BTN : PURPLE_BTN;
				break;
			case buttonColor.RED:
				shadowColor = "#6C2D0C";
				bckImageSource = isLong ? RED_LONG_BTN : RED_BTN;
				break;
			case buttonColor.YELLOW:
				shadowColor = "#5F580B";
				bckImageSource = isLong ? YELLOW_LONG_BTN : YELLOW_BTN;
				break;
			case buttonColor.GREEN:
			default:
				shadowColor = "#297422";
				bckImageSource = isLong ? GREEN_LONG_BTN : GREEN_BTN;
				break;
		}

		return (
			<GameButton
				onPress={onPressFunc}
				silentPress={silentPress}
				isInMenu={isInMenu}
				onLayout={onLayout}
				disabled={disabled}
				myStyle={[styles.buttonStyle, buttonDimensions]}
				accessibilityLabel={accessibilityLabel}
				textContent={textContent}
				textContentStyle={[
					styles.buttonTextStyle,
					buttonDimensions,
					{ textShadowColor: shadowColor, color: textColor },
					additionalTextStyle,
				]}
				backgroundImage={{
					uri: bckImageSource,
					resizeMode: "stretch",
					style: [
						buttonDimensions,
						styles.buttonBackImage,
						{
							opacity: (disabled && !isInMenu) || permissionDenied ? 0.5 : 1,
						},
					],
				}}
			/>
		);
	} catch (error) {
		handleError(error);
	}
};

const styles = StyleSheet.create({
	buttonStyle: { backgroundColor: "transparent", borderWidth: 0 },
	buttonBackImage: { marginTop: 3, marginBottom: 3 },
	buttonTextStyle: {
		flex: 1,
		fontSize: 18,
		fontFamily: f_sourcesansprobold,
		textAlign: "center",
		paddingTop: 15,
		paddingBottom: 15,
		textShadowColor: "#297422",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 3,
	},
});

export { ColoredButton };
