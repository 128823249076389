import { cloneDeep } from "lodash";
import { handleError } from "../components/ErrorHandler";
import { debugLogLimit, storeKeys } from "../config/defaults";
import { DEBUG_ENABLED } from "../config/permissions";
import { mapObject } from "../helpers/commonHelpers";
import { getData, mergeObjects, storeData } from "../libraries/AsyncStorage";

class DebugLogger {
	static shared = DebugLogger.shared ? DebugLogger.shared : new DebugLogger();

	constructor() {
		this.loggerID = 0;
		this.sessionID = -1;
		this.enableDebugLogging = false;
		this.gameID = -1;
		this.savedLogIDs = [];
		this.debugLogSize = debugLogLimit;
		this.storedForReset = [];
		this.connectivityLogID = 0;
		this.savedConnectivityIDs = [];
	}

	resetConnectivityID = () => {
		this.connectivityLogID = 0;
	};

	increaseConnectivityID = () => {
		this.connectivityLogID += 1;
	};

	saveConnectivityLog = async (dataToLog) => {
		try {
			const sessId = await getData(storeKeys.LOGGED_SESSION_ID);
			var log_to_merge = {};
			var sesID = "sID_" + sessId;
			log_to_merge[sesID] = {};
			log_to_merge[sesID].log = {};
			log_to_merge[sesID].sessionID = sessId;
			const logID = "log_" + this.connectivityLogID;
			log_to_merge[sesID].log[logID] = {
				sessionID: sessId,
				data: cloneDeep(dataToLog),
				timestamp: Date.now(),
			};

			if (dataToLog) {
				await mergeObjects(storeKeys.STORED_CONN_LOG, log_to_merge).then(() => {
					if (this.savedConnectivityIDs.length > this.debugLogSize) {
						getData(storeKeys.STORED_CONN_LOG).then((_storedLog) => {
							if (_storedLog) {
								const jsonLog = JSON.parse(_storedLog);
								const deleteCount = this.savedConnectivityIDs.length - this.debugLogSize;
								var logsToDelete = [];
								for (var i = 0; i < deleteCount; i++) {
									logsToDelete.push(this.savedConnectivityIDs[i]);
								}
								mapObject(jsonLog[sesID].log, (key, val) => {
									if (typeof val === "object") {
										const allKeys = Object.keys(val);
										allKeys.forEach((k) => {
											if (logsToDelete.includes(k)) {
												const logInd = this.savedConnectivityIDs.indexOf(k);
												if (logInd >= 0) this.savedConnectivityIDs.splice(logInd, 1);
											}
											if (!this.savedConnectivityIDs.includes(k)) delete val[k];
										});
									}
								});
								// if (DEBUG_ENABLED) {
								// 	console.log("saved connectivity logs: ", this.savedConnectivityIDs);
								// 	console.log("MODIFIED: ", jsonLog);
								// }
								storeData(storeKeys.STORED_CONN_LOG, JSON.stringify(jsonLog));
								// getData(storeKeys.STORED_CONN_LOG).then((_saved) => {
								//   //eslint-disable-next-line
								//   if (_saved && DEBUG_ENABLED && __DEV__) {
								//     console.log(
								//       "saved connectivity log ID's: ",
								//       this.savedConnectivityIDs
								//     );
								//     console.log("STORED_CONN_LOG: ", JSON.parse(_saved));
								//   }
								// });
							}
						});
					}
					if (!this.savedConnectivityIDs.includes(logID)) {
						this.savedConnectivityIDs.push(logID);
						this.increaseConnectivityID();
					}
				});
			}

			const storedLog = await getData(storeKeys.STORED_CONN_LOG);
			//eslint-disable-next-line
			if (DEBUG_ENABLED && __DEV__) console.log("MERGED CONNECTIVITY LOG: ", JSON.parse(storedLog));
		} catch (error) {
			handleError(error);
		}
	};

	resetLoggerID = () => {
		this.loggerID = 0;
		this.savedLogIDs = [];
	};

	increaseLoggerID = () => {
		this.loggerID += 1;
	};

	saveLog = async (dataToLog, isInGame = false, storeForReset = false) => {
		try {
			if (this.enableDebugLogging && this.debugLogSize > 0) {
				// const savedSessionID = await getData(storeKeys.LOGGED_SESSION_ID);
				// if (savedSessionID == this.sessionID) {
				var log_to_merge = {};
				var sesID = "sID_" + this.sessionID;
				log_to_merge[sesID] = {};
				log_to_merge[sesID].log = {};
				const logID = "log_" + this.loggerID;
				if (isInGame) {
					log_to_merge[sesID].sessionID = this.sessionID;
					log_to_merge[sesID].log["game_" + this.gameID] = {};
					log_to_merge[sesID].log["game_" + this.gameID][logID] = {
						logData: dataToLog,
						timestamp: Date.now(),
					};
				} else {
					log_to_merge[sesID].log[logID] = {
						sessionID: this.sessionID,
						data: dataToLog,
						timestamp: Date.now(),
					};
				}
				if (dataToLog) {
					if (storeForReset) this.storedForReset.push(log_to_merge);
					await mergeObjects(storeKeys.STORED_LOG, log_to_merge).then(() => {
						if (this.savedLogIDs.length > this.debugLogSize) {
							getData(storeKeys.STORED_LOG).then((_storedLog) => {
								if (_storedLog) {
									const jsonLog = JSON.parse(_storedLog);
									const deleteCount = this.savedLogIDs.length - this.debugLogSize;
									var logsToDelete = [];
									for (var i = 0; i < deleteCount; i++) {
										logsToDelete.push(this.savedLogIDs[i]);
									}
									mapObject(jsonLog[sesID].log, (key, val) => {
										if (typeof val === "object") {
											const allKeys = Object.keys(val);
											allKeys.forEach((k) => {
												if (logsToDelete.includes(k)) {
													const logInd = this.savedLogIDs.indexOf(k);
													if (logInd >= 0) this.savedLogIDs.splice(logInd, 1);
												}
												if (!this.savedLogIDs.includes(k)) delete val[k];
											});
										}
									});
									// console.log("saved logs: ", this.savedLogIDs);
									// if (DEBUG_ENABLED) console.log("MODIFIED: ", jsonLog);
									storeData(storeKeys.STORED_LOG, JSON.stringify(jsonLog));
									getData(storeKeys.STORED_LOG).then((_saved) => {
										//eslint-disable-next-line
										if (_saved && DEBUG_ENABLED && __DEV__) {
											console.log("saved log ID's: ", this.savedLogIDs);
											console.log("STORED: ", JSON.parse(_saved));
										}
									});
								}
							});
						}
						if (!this.savedLogIDs.includes(logID)) {
							this.savedLogIDs.push(logID);
							this.increaseLoggerID();
						}
					});
				}

				const storedLog = await getData(storeKeys.STORED_LOG);
				//eslint-disable-next-line
				if (DEBUG_ENABLED && __DEV__) console.log("MERGED: ", JSON.parse(storedLog));
				// } else {
				//   handleError(
				//     new Error(
				//       "Error logging data, savedSessionID: " +
				//         savedSessionID +
				//         ", localSessionID: " +
				//         this.sessionID
				//     )
				//   );
				//   const storedLog = await getData(storeKeys.STORED_LOG);
				//   //eslint-disable-next-line
				//   if (DEBUG_ENABLED && __DEV__)
				//     console.log("MERGED: ", JSON.parse(storedLog));
				// }
			}
		} catch (error) {
			handleError(error);
		}
	};
}

export default DebugLogger;
