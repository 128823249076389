import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { closeDebugMenu, goBackToMain, openLogger } from "../../redux/actions";
import { ColoredButton, NavigationHeader } from "../common";
import { handleError } from "../ErrorHandler";
import Logger from "./Logger";

class DebugMenu extends Component {
	//#render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeDebugMenu}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "logger":
				textContent = "Logger";
				onPressFunc = () => {
					this.props.openLogger();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "exception":
				textContent = "Cause Exception";
				onPressFunc = () => {
					try {
						let test = 0;
						test = "";
					} catch (error) {
						handleError(error);
					}
				};
				_buttonColor = buttonColor.RED;
		}
		return (
			<ColoredButton
				width={gameDimensions.gameButtonsWidth}
				height={60}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderButton("logger")}
					{this.renderButton("exception")}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.isLoggerOpened) {
			return <Logger />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		minHeight: "100%",
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isLoggerOpened: state.debug.isLoggerOpened,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeDebugMenu,
	goBackToMain,
	openLogger,
};

export default connect(mapStateToProps, mapDispatchToProps)(DebugMenu);
