import { cloneDeep, findLastIndex, orderBy } from "lodash";
import { Image, Platform } from "react-native";
import { RESOLUTION } from "../../../config/defaults";
import { DEBUG_ENABLED, useClassicTheme } from "../../../config/permissions";
import DebugLogger from "../../../controller/DebugLogger";
import * as helpers from "../../../helpers/commonHelpers";
import {
	RESET_CLIENT,
	SOCKET_CONNECTION_CLOSED,
	UPDATE_BAN_LIST,
	UPDATE_FRIEND_LIST,
} from "../../../redux/actions/actionTypes";
import { gameBoardTheme, pips, possibleCards } from "../../config/defaults";
import {
	CLEAR_PLAYED_CARDS,
	CLEAR_START_POSITIONS_DIRECTION,
	CLEAR_TO_LATE_TIME,
	CLOSE_PANEL_MENU,
	DEAL_CARD,
	DISABLE_THROW_AWAY,
	ENABLE_THROW_AWAY,
	FILTER_AVAILABLE_MOVES,
	FINISH_COUNTDOWN,
	FINISH_LATE_MOVE_IN_SHOP,
	FOCUS_IN_GAME_CHAT_INPUT,
	FOCUS_OUT_GAME_CHAT_INPUT,
	GAME_BOARD_RENDERED,
	GAME_END_RECEIVED,
	GAME_INFO_RECEIVED,
	GAME_PLAYER_ACTED_RECEIVED,
	GAME_PLAYER_CONNECTED,
	GAME_PLAYER_DISCONNECTED,
	GAME_PLAYER_LEFT_GAME,
	GAME_PLAYER_TO_ACT_RECEIVED,
	GAME_REOPENED,
	GAME_STARTED_RECEIVED,
	INCREASE_MOVE_COUNT,
	MOVE_BOARD_PAWNS,
	MOVE_CARD_TO_PLAYED_CARDS,
	MOVE_PAWNS,
	MOVE_THROWN_CARD_TO_PLAYED_CARDS,
	NEW_GAME_STARTED,
	OPEN_PANEL_MENU,
	PLAY_CARD,
	PROCESS_IMAGE_STORED,
	REQUEST_REOPEN_GAME,
	RESET_GAME_END,
	RESET_GAME_PLAYER_ACTED,
	RESET_GAME_PLAYER_TO_ACT,
	RESET_LAST_MOVE_EFFECT,
	RESET_MOVE_COUNT,
	RESET_PLAYABLE_CARDS,
	RESET_PLAYABLE_POSITIONS,
	RESET_STARTED_GAME,
	RESET_WARNING_TIMER,
	ROLLBACK_MOVE,
	SAVE_START_POSITIONS_DIRECTION,
	SELECT_CARD,
	SELECT_PAWN,
	SELECT_TARGETED_POSITION,
	SET_BOARD_COORDINATES,
	SET_BOARD_POSITIONS_BASE_COLOR,
	SET_MOVE_COUNT,
	SET_NEXT_SLOT_TO_ACT,
	SET_PLAYABLE_CARDS,
	SET_PLAYABLE_PAWNS,
	SET_PLAYER_PANELS_COLOR,
	SET_TO_LATE_TIME,
	SET_WARNING_TIMER,
	SHOW_DID_YOU_KNOW,
	SPECTATED_GAME_CLOSED,
	SPECTATE_GAME_STARTED,
	SPECTATE_GAME_STOPPED,
	START_COUNTDOWN,
	START_LATE_MOVE_IN_SHOP,
	STORE_CREATED_SCREENSHOT,
	SWAP_PLAYER_PANELS,
	THROW_AWAY,
	UPDATE_PAWNS,
} from "../actions/actionTypes";

const cardValues = {
	A: ["AA"],
	N: ["N2", "N3", "N4", "N5", "N6", "N7", "N8", "N9", "NT"],
	F: ["FJ", "FQ", "FK"],
};

const initialState = {
	gameID: -1,
	gameTheme: useClassicTheme === true ? gameBoardTheme.CLASSIC : gameBoardTheme.MODERN,
	isSpectator: false,
	isSpectatedGameClosed: false,
	gameScreenshotURL: "",
	shareableFBImageURL: null,
	// shareableFBQuote: "#keezers",
	shareableFBHashtag: "#keezers",
	allowFacebookSharing: false,
	faceBookShareID: "",
	faceBookShareHash: "",
	boardLoaded: false,
	openGame: 0,
	name: "",
	playerCnt: 2,
	teams: false,
	fourRule: true,
	sevenRule: false,
	jackRule: true,
	playerSlot: 0,
	slotToAct: -1,
	nextSlotIDToAct: -1,
	dealer: -1,
	teamMate: -1,
	players: {},
	cards: [],
	cardset: "DEFAULT",
	preloadedCards: {},
	positions: [],
	cardCounts: [],
	eventWaitTime: 0,
	initialPosition: 0,
	sitSlotID: 0,
	isShuffled: false,
	features: [],
	chipsRequired: 0,
	reason: "",
	gameChatName: "",
	gamePlayerToAct: null,
	isPlayerToAct: false,
	moveToRollback: null,
	gamePlayerActed: null,
	isPartialWin: false,
	gameEnd: {
		isGameEnded: false,
		isDidYouKnowDisplayed: false,
		res: null,
		secondsBeforeClose: -1,
		requestDidYouKnow: false, //TODO:
		didYouKnowScreenID: "WATCH_VIDEO", //TODO:
		didYouKnowLink: null,
	},
	selectedMoveIdToAct: -1,
	canPlayerAct: false,
	waitForSecond_7: false,
	isThrowAwayAction: false,
	isPlayerWarned: false,
	playableCardList: [],
	playablePawnList: [],
	filteredMoves: [],
	targetedPositions: [],
	moveCnt: 0,
	isCountDownStarted: false,
	toLateTime: "",
	isInShopLateMove: false,
	gameBoard: {
		startPositions: [],
		boardPositions: [],
		playerPanels: [],
		playerPanelScale: 1,
		playedCards: [],
		playersCardsProp: {},
		playedCardsGlobal: {},
		potOfChipsCoord: {},
	},
	disconnectedFromGame: false,
	isNewGameStarted: false,
	cardSortOrder: "",
	cardSuitOrder: "",
	cardValueOrder: "",
	focusChatInput: false,
	expandPanelMenu: false,
	expandedPanelID: -1,
	useLastMoveTracking: true,
	useQuickMove: false,
	useLoudToActSound: false,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case SPECTATED_GAME_CLOSED:
			return { ...state, isSpectatedGameClosed: true };
		case SPECTATE_GAME_STARTED:
			return { ...state, isSpectator: true };
		case SPECTATE_GAME_STOPPED:
			return { ...state, isSpectator: false };
		case PROCESS_IMAGE_STORED:
			return state;
		case STORE_CREATED_SCREENSHOT:
			if (typeof payload !== "string" || payload == "") return state;
			return { ...state, gameScreenshotURL: payload };
		case UPDATE_FRIEND_LIST:
			if (payload === undefined || payload.action === undefined || payload.userID === undefined) return state;

			var searchedSlot = -1;
			helpers.mapObject(state.players, (k, v) => {
				if (v.userID == payload.userID) searchedSlot = v.slotID;
			});
			if (searchedSlot == -1) return state;

			var newPlayer = { ...state.players["slot_" + searchedSlot] };
			newPlayer.isOnMyFriendList = payload.action === "ADD";
			var newPlayers = {
				...state.players,
				["slot_" + searchedSlot]: newPlayer,
			};
			return { ...state, players: newPlayers };
		case UPDATE_BAN_LIST:
			if (payload === undefined || payload.action === undefined || payload.userID === undefined) return state;

			var searchedSlot = -1;
			helpers.mapObject(state.players, (k, v) => {
				if (v.userID == payload.userID) searchedSlot = v.slotID;
			});
			if (searchedSlot == -1) return state;

			var newPlayer = { ...state.players["slot_" + searchedSlot] };
			newPlayer.isOnMyBanList = payload.action === "ADD";
			var newPlayers = {
				...state.players,
				["slot_" + searchedSlot]: newPlayer,
			};
			return { ...state, players: newPlayers };
		case MOVE_BOARD_PAWNS:
			if (
				typeof payload === "undefined" ||
				typeof payload.curSlotID !== "number" ||
				typeof payload.newSlotID !== "number"
			)
				return state;
			var pawnNrPerPlayer = 16;
			var fPosNrPerPlayer = 4;
			var totalBPos = state.playerCnt * pawnNrPerPlayer;
			var totalFPos = state.playerCnt * fPosNrPerPlayer;
			var savedBPos = {};
			var savedFPos = {};

			// saving old positions
			state.gameBoard.boardPositions.forEach((pos) => {
				if (pos && pos.id && pos.id.startsWith("b")) {
					savedBPos[pos.id] = { ...pos };
				}
				if (pos && pos.id && pos.id.startsWith("f")) {
					savedFPos[pos.id] = { ...pos };
				}
			});

			var newPositions = cloneDeep(state.gameBoard.boardPositions);
			var newSlotID = payload.newSlotID;
			var curSlotID = payload.curSlotID;

			//swapping board positions
			state.gameBoard.boardPositions.forEach((pos, i) => {
				const ind = Number(pos.id.substr(1));
				if (pos && pos.id && pos.id.startsWith("b")) {
					//determining the newInd
					var newInd = ind;
					if (newSlotID < curSlotID) {
						newInd = ind + pawnNrPerPlayer * (curSlotID - newSlotID);
						if (newInd > totalBPos - 1) newInd -= totalBPos;
					} else {
						newInd = ind - pawnNrPerPlayer * (newSlotID - curSlotID);
						if (newInd < 0) newInd += totalBPos;
					}

					const newID = "b" + newInd;
					const newPos = newPositions[i];
					const savedPos = savedBPos[newID];

					if (savedPos.filled == true) newPos.color = savedPos.color;
					newPos.filled = savedPos.filled;
					newPos.filtered = savedPos.filtered;
					newPos.isBasePos = savedPos.isBasePos;
					newPos.playable = savedPos.playable;
					newPos.requestHint = savedPos.requestHint;
					newPos.requestSwapAnimation = savedPos.requestSwapAnimation;
					newPos.animatedMove = savedPos.animatedMove;
					newPos.lastSwithed = savedPos.lastSwithed;
					newPos.moveEnd = savedPos.moveEnd;
					newPos.moveStarter = savedPos.moveStarter;
					newPos.implicatedInMove = savedPos.implicatedInMove;
					newPos.selected = savedPos.selected;
					newPos.targeted = savedPos.targeted;
					newPos.smashable = savedPos.smashable;
					newPos.id = newID;
				}

				//swapping final positions
				if (pos && pos.id && pos.id.startsWith("f")) {
					//determining the newInd
					var newInd = ind;
					if (newSlotID < curSlotID) {
						newInd = ind + fPosNrPerPlayer * (curSlotID - newSlotID);
						if (newInd > totalFPos - 1) newInd -= totalFPos;
					} else {
						newInd = ind - fPosNrPerPlayer * (newSlotID - curSlotID);
						if (newInd < 0) newInd += totalFPos;
					}

					const newID = "f" + newInd;
					const newPos = newPositions[i];
					const savedPos = savedFPos[newID];

					newPos.color = newPos.baseColor;
					newPos.filled = savedPos.filled;
					newPos.filtered = savedPos.filtered;
					newPos.isBasePos = savedPos.isBasePos;
					newPos.playable = savedPos.playable;
					newPos.requestHint = savedPos.requestHint;
					newPos.requestSwapAnimation = savedPos.requestSwapAnimation;
					newPos.animatedMove = savedPos.animatedMove;
					newPos.lastSwithed = savedPos.lastSwithed;
					newPos.moveEnd = savedPos.moveEnd;
					newPos.moveStarter = savedPos.moveStarter;
					newPos.implicatedInMove = savedPos.implicatedInMove;
					newPos.selected = savedPos.selected;
					newPos.targeted = savedPos.targeted;
					newPos.smashable = savedPos.smashable;
					newPos.id = newID;
				}
			});

			//update new positions colors
			newPositions.forEach((pos) => {
				if (pos && pos.id && pos.filled == true && pos.id.startsWith("b")) {
					var pawnsNewPP, pawnsCurrentPP, newColor;
					var oldColor = cloneDeep(pos.color);
					pawnsCurrentPP = state.gameBoard.playerPanels.find(
						(pp) => pp.color && pp.color.normal && oldColor && oldColor.normal && pp.color.normal == oldColor.normal
					);

					if (pawnsCurrentPP && pawnsCurrentPP.slotID != undefined) {
						var newSlot_ID = pawnsCurrentPP.slotID;
						if (newSlotID < curSlotID) {
							newSlot_ID -= curSlotID - newSlotID;
						} else {
							newSlot_ID += newSlotID - curSlotID;
						}
						if (newSlot_ID > state.playerCnt - 1) {
							newSlot_ID -= state.playerCnt;
						} else if (newSlot_ID < 0) {
							newSlot_ID += state.playerCnt;
						}

						pawnsNewPP = state.gameBoard.playerPanels.find((pp) => pp.slotID == newSlot_ID);
						newColor =
							pawnsNewPP != undefined && pawnsNewPP.color ? pawnsNewPP.color : { normal: "#", strong: "#", text: "#" };
						pos.color = newColor;
					}
				}
			});
			return {
				...state,
				gameBoard: { ...state.gameBoard, boardPositions: newPositions },
			};
		case SWAP_PLAYER_PANELS:
			if (
				typeof payload === "undefined" ||
				typeof payload.curSlotID !== "number" ||
				typeof payload.newSlotID !== "number"
			)
				return state;

			var newSlotID = payload.newSlotID;
			var curSlotID = payload.curSlotID;
			var newSlotToAct = state.slotToAct;
			var newDealerSlot = state.dealer;

			if (newSlotID < curSlotID) {
				newDealerSlot -= curSlotID - newSlotID;
				newSlotToAct -= curSlotID - newSlotID;
			} else {
				newDealerSlot += newSlotID - curSlotID;
				newSlotToAct += newSlotID - curSlotID;
			}
			if (newDealerSlot > state.playerCnt - 1) {
				newDealerSlot -= state.playerCnt;
			} else if (newDealerSlot < 0) {
				newDealerSlot += state.playerCnt;
			}
			if (newSlotToAct > state.playerCnt - 1) {
				newSlotToAct -= state.playerCnt;
			} else if (newSlotToAct < 0) {
				newSlotToAct += state.playerCnt;
			}

			// player data, card counts
			var newPlayers = {};
			var _cardCounts = [];
			for (var i = 0; i < state.playerCnt; i++) {
				var newInd = i;
				if (newSlotID < curSlotID) {
					newInd = i + curSlotID - newSlotID;
					if (newInd > state.playerCnt - 1) newInd -= state.playerCnt;
				} else {
					newInd = i - newSlotID + curSlotID;
					if (newInd < 0) newInd += state.playerCnt;
				}
				newPlayers["slot_" + i] = cloneDeep(state.players["slot_" + newInd]);
				newPlayers["slot_" + i].slotID = i;
				var newCardCount = state.cardCounts.find((cc) => cc.slotID == i.toString());
				const copyFrom = state.cardCounts.find((cc) => cc.slotID == newInd.toString());
				if (newCardCount && copyFrom) {
					newCardCount = cloneDeep(newCardCount);
					newCardCount.count = copyFrom.count;
					_cardCounts.push(newCardCount);
				}
			}

			if (payload.rotateBoard === true) {
				//saving startPositions
				var savedSPos = {};
				helpers.mapObject(state.players, (k, v) => {
					const spList = state.gameBoard.startPositions.filter((sp) => sp.slotID == v.slotID);
					if (spList.length > 0) {
						spList.forEach((sp) => {
							savedSPos[sp.pos] = { ...sp };
						});
					}
				});
				//swapping startPositions
				var sPosNrPerPlayer = 4;
				var totalSPos = state.playerCnt * sPosNrPerPlayer;
				var newPositions = cloneDeep(state.gameBoard.startPositions);
				state.gameBoard.startPositions.forEach((sp, i) => {
					const ind = Number(sp.pos.substr(1));
					//determining the newInd
					var newInd = ind;
					if (newSlotID < curSlotID) {
						newInd = ind + sPosNrPerPlayer * (curSlotID - newSlotID);
						if (newInd > totalSPos - 1) newInd -= totalSPos;
					} else {
						newInd = ind - sPosNrPerPlayer * (newSlotID - curSlotID);
						if (newInd < 0) newInd += totalSPos;
					}

					const newID = "s" + newInd;
					var newPos = newPositions[i];
					const savedPos = savedSPos[newID];

					newPos.filled = savedPos.filled;
					newPos.filtered = savedPos.filtered;
					newPos.playable = savedPos.playable;
					newPos.requestHint = savedPos.requestHint;
					newPos.requestSwapAnimation = savedPos.requestSwapAnimation;
					newPos.animatedMove = savedPos.animatedMove;
					newPos.lastSwithed = savedPos.lastSwithed;
					newPos.moveEnd = savedPos.moveEnd;
					newPos.moveStarter = savedPos.moveStarter;
					newPos.implicatedInMove = savedPos.implicatedInMove;
					newPos.selected = savedPos.selected;
					newPos.targeted = savedPos.targeted;
					newPos.smashable = savedPos.smashable;
					newPos.pos = savedPos.pos;
				});
			}

			return {
				...state,
				slotToAct: newSlotToAct,
				players: newPlayers,
				cardCounts: _cardCounts,
				sitSlotID: newSlotID,
				dealer: newDealerSlot,
				gameBoard: {
					...state.gameBoard,
					startPositions: Array.isArray(newPositions) ? newPositions : state.gameBoard.startPositions,
				},
			};
		case OPEN_PANEL_MENU:
			if (typeof payload === undefined || typeof payload.panelID !== "number" || payload.panelID >= state.playerCnt)
				return state;

			return {
				...state,
				expandPanelMenu: true,
				expandedPanelID: payload.panelID,
			};
		case CLOSE_PANEL_MENU:
			if (state.expandPanelMenu == false) return state;
			return {
				...state,
				expandPanelMenu: false,
				// expandedPanelID: -1,
			};
		case GAME_PLAYER_LEFT_GAME:
			if (
				typeof payload !== "object" ||
				payload === null ||
				typeof payload.slotID === "undefined" ||
				typeof payload.gameID === "undefined" ||
				payload.gameID !== state.gameID
			)
				return state;

			var _slotID = payload.slotID;
			if (state.sitSlotID !== state.playerSlot) {
				_slotID += state.sitSlotID - state.playerSlot;
				/* if (state.sitSlotID > state.playerSlot) {
          _slotID += state.sitSlotID - state.playerSlot;
        } else {
          _slotID += state.playerSlot - state.sitSlotID;
        } */
				if (_slotID > state.playerCnt - 1) {
					_slotID -= state.playerCnt;
				} else if (_slotID < 0) {
					_slotID += state.playerCnt;
				}
			}
			var propID = "slot_" + _slotID;
			var leftPlayer = state.players[propID];
			if (typeof leftPlayer === "undefined") return state;

			leftPlayer.connectedState = "LEFT";
			var newPlayers = { ...state.players };
			newPlayers[propID] = leftPlayer;
			return { ...state, players: newPlayers };
		case GAME_PLAYER_CONNECTED:
			if (
				typeof payload !== "object" ||
				payload === null ||
				typeof payload.slotID === "undefined" ||
				typeof payload.gameID === "undefined" ||
				payload.gameID !== state.gameID
			)
				return state;

			var _slotID = payload.slotID;
			if (state.sitSlotID !== state.playerSlot) {
				_slotID += state.sitSlotID - state.playerSlot;
				/* if (state.sitSlotID > state.playerSlot) {
          _slotID += state.sitSlotID - state.playerSlot;
        } else {
          _slotID += state.playerSlot - state.sitSlotID;
        } */
				if (_slotID > state.playerCnt - 1) {
					_slotID -= state.playerCnt;
				} else if (_slotID < 0) {
					_slotID += state.playerCnt;
				}
			}
			var propID = "slot_" + _slotID;
			var connPlayer = state.players[propID];
			if (typeof connPlayer === "undefined") return state;

			connPlayer.connectedState = "CONNECTED";
			var newPlayers = { ...state.players };
			newPlayers[propID] = connPlayer;
			return { ...state, players: newPlayers };
		case GAME_PLAYER_DISCONNECTED:
			if (
				typeof payload !== "object" ||
				payload === null ||
				typeof payload.slotID === "undefined" ||
				typeof payload.gameID === "undefined" ||
				payload.gameID !== state.gameID
			)
				return state;

			var _slotID = payload.slotID;
			if (state.sitSlotID !== state.playerSlot) {
				_slotID += state.sitSlotID - state.playerSlot;
				/* if (state.sitSlotID > state.playerSlot) {
          _slotID += state.sitSlotID - state.playerSlot;
        } else {
          _slotID += state.playerSlot - state.sitSlotID;
        } */
				if (_slotID > state.playerCnt - 1) {
					_slotID -= state.playerCnt;
				} else if (_slotID < 0) {
					_slotID += state.playerCnt;
				}
			}
			var propID = "slot_" + _slotID;
			var discPlayer = state.players[propID];
			if (typeof discPlayer === "undefined") return state;

			discPlayer.connectedState = "DISCONNECTED";
			var newPlayers = { ...state.players };
			newPlayers[propID] = discPlayer;
			return { ...state, players: newPlayers };
		case FOCUS_IN_GAME_CHAT_INPUT:
			if (Platform.OS === "web") return state;
			return { ...state, focusChatInput: true };
		case FOCUS_OUT_GAME_CHAT_INPUT:
			if (Platform.OS === "web") return state;
			return { ...state, focusChatInput: false };
		case REQUEST_REOPEN_GAME:
			return { ...state, disconnectedFromGame: true };
		case NEW_GAME_STARTED:
			return { ...state, isNewGameStarted: true };
		case GAME_REOPENED:
			return { ...state, disconnectedFromGame: false };
		case GAME_STARTED_RECEIVED:
			if (typeof payload === "undefined") return state;
			var newCards = [];
			if (Array.isArray(payload.data.specific.cards)) {
				var cardSuitOrder = payload.data.specific.cardSuitOrder.split("");
				var cardValueOrder = payload.data.specific.cardValueOrder;
				var asc_or_desc = cardValueOrder.split("_").pop();
				var valOrder = cardValueOrder.split("_").shift().split("");
				var sortedCardVal = [];
				valOrder.forEach((ord) => {
					var filtCardVal = cloneDeep(cardValues[ord]);
					if (asc_or_desc == "DESC") {
						filtCardVal = filtCardVal.reverse();
					}
					filtCardVal.forEach((fcv) => sortedCardVal.push(fcv));
				});
				payload.data.specific.cards.forEach((c) => {
					if (payload.data.specific.features && payload.data.specific.features.cardSorting == true) {
						const suitOrderIndex = cardSuitOrder.indexOf(c[c.length - 1].toUpperCase());
						var valOrderIndex = sortedCardVal.findIndex((scv) => scv[scv.length - 1] == c["0"].toUpperCase());
						valOrderIndex = valOrderIndex != -1 ? valOrderIndex : 0;
						newCards.push({
							id: c,
							playable: true,
							filtered: false,
							selected: false,
							dealed: false,
							requestCardHint: false,
							cardSuit: suitOrderIndex,
							cardVal: valOrderIndex,
						});
					} else {
						newCards.push({
							id: c,
							playable: true,
							filtered: false,
							selected: false,
							dealed: false,
							requestCardHint: false,
						});
					}
				});
				if (payload.data.specific.features && payload.data.specific.features.cardSorting == true) {
					switch (payload.data.specific.cardSortOrder) {
						case "SUITS_VALUES":
							var orderedSV = orderBy(newCards, ["cardSuit", "cardVal"], ["asc", "asc"]);
							newCards = orderedSV;
							break;
						case "VALUES_SUITS":
							var orderedVS = orderBy(newCards, ["cardVal", "cardSuit"], ["asc", "asc"]);
							newCards = orderedVS;
							break;
						case "RANDOM":
						default:
							break;
					}
				}
			}
			var _startPositions = [];
			if (typeof payload.data.specific.positions === "object") {
				helpers.mapObject(payload.data.specific.positions, (k, v) =>
					_startPositions.push({ pos: k.toLowerCase(), slotID: v })
				);
				_startPositions.sort(function (a, b) {
					var idA = Number(a.pos.substr(1, a.pos.length));
					var idB = Number(b.pos.substr(1, b.pos.length));
					return idA > idB ? 1 : idB > idA ? -1 : 0;
				});
			}
			var _cardCounts = [];
			if (typeof payload.data.specific.cardCnts === "object") {
				helpers.mapObject(payload.data.specific.cardCnts, (k, v) => _cardCounts.push({ slotID: k, count: v }));
			}
			var _playerSlot = 0;
			if (Array.isArray(payload.data.generic.slots)) {
				const currentPlayer = payload.data.generic.slots.find(
					(slot) => slot.player != undefined && slot.player.name == payload.currentPlayersUsername
				);
				_playerSlot = currentPlayer != undefined ? currentPlayer.slotID : _playerSlot;
			}
			var newDealerSlot = payload.data.specific.dealerSlot != undefined ? payload.data.specific.dealerSlot : -1;
			var newActiveSlot = payload.data.specific.activeSlot != undefined ? payload.data.specific.activeSlot : -1;
			var _players = helpers.getStartedGamesPlayersList(payload);

			const _cardset =
				typeof payload.data.specific.cardset === "string" ? payload.data.specific.cardset : state.cardset;
			let _cardsetSize =
				typeof payload.data.specific.cardsetSize === "string" ? payload.data.specific.cardsetSize : state.cardsetSize;
			let _currCardsetSize = "l";
			switch (payload.resolution) {
				case RESOLUTION.LOW:
					_currCardsetSize = "s";
					break;
				case RESOLUTION.MEDIUM:
					_currCardsetSize = "m";
					break;
				case RESOLUTION.HIGH:
					_currCardsetSize = "l";
					break;
			}
			let _preloadedCards = { ...state.preloadedCards };
			if (typeof payload.cardsetURL === "string") {
				possibleCards.forEach((id) => {
					if (!_preloadedCards[_cardset]) _preloadedCards[_cardset] = {};
					if (!_preloadedCards[_cardset][_currCardsetSize]) _preloadedCards[_cardset][_currCardsetSize] = {};
					if (
						_preloadedCards[_cardset][_currCardsetSize][id.toString()] == undefined ||
						!_preloadedCards[_cardset][_currCardsetSize][id.toString()].isLoaded
					) {
						_preloadedCards[_cardset][_currCardsetSize][id.toString()] = {
							cardset: _cardset,
							size: _currCardsetSize,
							id: id.toString(),
						};
						_preloadedCards[_cardset][_currCardsetSize][id.toString()] = getPreloadedCard(
							_preloadedCards[_cardset][_currCardsetSize][id.toString()],
							payload.cardsetURL
						);
					}
				});
			}

			return {
				...state,
				useLoudToActSound: payload.data.specific.useLoudToActSound,
				useQuickMove: payload.data.specific.useQuickMove,
				useLastMoveTracking: payload.data.specific.useLastMoveTracking,
				cardSortOrder: payload.data.specific.cardSortOrder || "",
				cardSuitOrder: payload.data.specific.cardSuitOrder || "",
				cardValueOrder: payload.data.specific.cardValueOrder || "",
				gameChatName: payload.data.generic.gameChatName || "",
				gameID: payload.data.generic.gameID || -1,
				openGame: payload.data.generic.gameID || -1,
				name: payload.data.generic.name || "",
				playerCnt: payload.data.generic.playerCnt || 2,
				teams: payload.data.specific.teamsEnabled || false,
				fourRule: payload.fourRule || true,
				sevenRule: payload.sevenRule || false,
				jackRule: payload.jackRule || true,
				playerSlot: _playerSlot,
				dealer: newDealerSlot,
				teamMate: payload.teamMate || -1,
				players: _players,
				cards: newCards,
				cardset: _cardset,
				preloadedCards: _preloadedCards,
				positions: _startPositions,
				cardCounts: _cardCounts,
				eventWaitTime: payload.eventWaitTime || 0,
				initialPosition: payload.data.specific.sitSlotID || 0,
				sitSlotID: payload.reason === "RESET" ? state.sitSlotID : payload.data.specific.sitSlotID || 0,
				isShuffled: false,
				features: payload.data.specific.features || [],
				chipsRequired: payload.chipsRequired || 0,
				reason: payload.reason || "",
				slotToAct: newActiveSlot,
			};
		case SET_BOARD_COORDINATES:
			if (!Array.isArray(payload) || payload.length == 0) return state;
			const bCoord = payload.find((obj) => obj.amountOfPlayers == state.playerCnt);
			var boardPosCoord = !Array.isArray(bCoord.coordinates) ? state.gameBoard.boardPositions : bCoord.coordinates,
				playedCardsCoord =
					typeof bCoord.playedCardsCoordinates === "undefined"
						? state.gameBoard.playedCardsGlobal
						: bCoord.playedCardsCoordinates,
				playerPanelsCoord = !Array.isArray(bCoord.playerPanelsCoordinates)
					? state.gameBoard.playerPanels
					: bCoord.playerPanelsCoordinates,
				playerPanelScale =
					typeof bCoord.playerPanelScale !== "number" ? state.gameBoard.playerPanelScale : bCoord.playerPanelScale,
				potOfChipsCoord =
					typeof bCoord.potOfChipsCoordinates === "undefined"
						? state.gameBoard.potOfChipsCoord
						: bCoord.potOfChipsCoordinates,
				playersCardsProp =
					typeof bCoord.playersCards === "undefined" ? state.gameBoard.playersCardsProp : bCoord.playersCards;
			var spCounter = 0;
			var newStartPositions = [];
			for (var i = 0; i < state.playerCnt; i++) {
				for (var j = 0; j < 4; j++) {
					newStartPositions.push({ pos: "s" + spCounter++, slotID: i });
				}
			}
			return {
				...state,
				gameBoard: {
					...state.gameBoard,
					startPositions: newStartPositions,
					boardPositions: boardPosCoord,
					playedCardsGlobal: playedCardsCoord,
					playersCardsProp: playersCardsProp,
					playerPanels: playerPanelsCoord,
					playerPanelScale: playerPanelScale,
					potOfChipsCoord: potOfChipsCoord,
				},
			};
		case SET_PLAYER_PANELS_COLOR:
			if (typeof payload === "undefined") return state;

			var newPlayerPanels = cloneDeep(state.gameBoard.playerPanels);
			newPlayerPanels.forEach((pp) => {
				pp.color = payload[pp.slotID.toString()];
			});
			return {
				...state,
				gameBoard: { ...state.gameBoard, playerPanels: newPlayerPanels },
			};
		case SET_BOARD_POSITIONS_BASE_COLOR:
			var newBoardPositions = cloneDeep(state.gameBoard.boardPositions);
			for (var i = 0; i < state.playerCnt; i++) {
				var basePos, basePosToColor, playerPanel, color;
				if (i != 0) {
					basePos = i * 16;
				} else {
					basePos = 0;
				}
				playerPanel = state.gameBoard.playerPanels.find((pp) => pp.slotID == i);
				color = playerPanel != undefined ? playerPanel.color : { normal: "000000", strong: "000000", text: "000000" };
				basePosToColor = newBoardPositions.find((p) => p.id == "b" + basePos);
				if (basePosToColor != undefined) {
					basePosToColor.color = color;
					basePosToColor.isBasePos = true;
					basePosToColor.baseColor = playerPanel.color;
				}

				var startPos = state.gameBoard.startPositions.filter((p) => p.slotID == i);
				startPos.forEach((sp) => {
					const posToColor = newBoardPositions.find((bp) => bp.id == "f" + sp.pos.substr(1));
					if (posToColor != undefined) {
						posToColor.color = color;
						posToColor.baseColor = color;
						posToColor.slotID = i;
					}
				});
			}
			return {
				...state,
				gameBoard: { ...state.gameBoard, boardPositions: newBoardPositions },
			};
		case SAVE_START_POSITIONS_DIRECTION:
			if (typeof payload !== "boolean") return state;
			return { ...state, verticalStartPositions: payload };
		case CLEAR_START_POSITIONS_DIRECTION:
			var newState = cloneDeep(state);
			delete newState.verticalStartPositions;
			return newState;
		case RESET_LAST_MOVE_EFFECT:
			if (
				// (state.gamePlayerToAct !== undefined &&
				//   state.gamePlayerToAct !== null &&
				//   state.gamePlayerToAct.waitForSecond_7 == true) ||
				!state.useLastMoveTracking ||
				(state.gamePlayerActed !== undefined &&
					state.gamePlayerActed !== null &&
					state.gamePlayerActed.slotID != state.playerSlot &&
					state.gamePlayerActed.otherPlayersSecondMove == true &&
					state.moveCnt == 1)
			)
				return state;
			var newBoardPositions = [...state.gameBoard.boardPositions],
				newStartPositions = [...state.gameBoard.startPositions],
				modified = false;
			newBoardPositions.forEach((bp) => {
				if (bp.lastSwithed == true || bp.moveEnd == true || bp.moveStarter == true || bp.implicatedInMove == true) {
					if (!modified) modified = true;
					if (bp.lastSwithed == true) bp.lastSwithed = false;
					if (bp.moveEnd == true) bp.moveEnd = false;
					if (bp.moveStarter == true) bp.moveStarter = false;
					if (bp.implicatedInMove == true) bp.implicatedInMove = false;
				}
			});
			newStartPositions.forEach((sp) => {
				if (sp.lastSwithed == true || sp.moveEnd == true || sp.moveStarter == true || sp.implicatedInMove == true) {
					if (!modified) modified = true;
					if (sp.lastSwithed == true) sp.lastSwithed = false;
					if (sp.moveEnd == true) sp.moveEnd = false;
					if (sp.moveStarter == true) sp.moveStarter = false;
					if (sp.implicatedInMove == true) sp.implicatedInMove = false;
				}
			});
			if (!modified) return state;
			return {
				...state,
				gameBoard: {
					...state.gameBoard,
					boardPositions: newBoardPositions,
					startPositions: newStartPositions,
				},
			};
		case RESET_PLAYABLE_POSITIONS:
			var newBoardPositions = [...state.gameBoard.boardPositions],
				newStartPositions = [...state.gameBoard.startPositions];
			newBoardPositions.forEach((bp) => {
				bp.playable = false;
				bp.filtered = false;
				bp.selected = false;
				bp.smashable = false;
				bp.targeted = false;
				bp.requestHint = false;
			});
			newStartPositions.forEach((sp) => {
				sp.playable = false;
				sp.filtered = false;
				sp.selected = false;
				sp.smashable = false;
				sp.targeted = false;
				sp.requestHint = false;
			});
			return {
				...state,
				gameBoard: {
					...state.gameBoard,
					boardPositions: newBoardPositions,
					startPositions: newStartPositions,
				},
			};
		case RESET_PLAYABLE_CARDS:
			if (state.cards.length == 0) return state;
			var newCards = cloneDeep(state.cards);
			newCards.forEach((c) => {
				c.playable = false;
				c.filtered = false;
				c.selected = false;
				c.requestCardHint = false;
			});
			return { ...state, cards: newCards };
		case UPDATE_PAWNS:
			var newBoardPositions = [...state.gameBoard.boardPositions],
				newStartPositions = [...state.gameBoard.startPositions];
			newBoardPositions.forEach((bp) => {
				const pos = state.positions.find((p) => p.pos == bp.id);
				if (pos != undefined) {
					if (state.sitSlotID !== state.playerSlot) {
						var playerPanel = state.gameBoard.playerPanels.find((pp) => {
							var searchedSlotID = pos.slotID;
							if (state.sitSlotID !== state.playerSlot) {
								searchedSlotID += state.sitSlotID - state.playerSlot;
								/* if (state.sitSlotID > state.playerSlot) {
                  searchedSlotID += state.sitSlotID - state.playerSlot;
                } else {
                  searchedSlotID += state.playerSlot - state.sitSlotID;
                } */
								if (searchedSlotID > state.playerCnt - 1) {
									searchedSlotID -= state.playerCnt;
								} else if (searchedSlotID < 0) {
									searchedSlotID += state.playerCnt;
								}
							}
							return pp.slotID == searchedSlotID;
						});
					} else {
						var playerPanel = state.gameBoard.playerPanels.find((pp) => pp.slotID == pos.slotID);
					}
					const color = playerPanel != undefined ? playerPanel.color : { normal: "#", strong: "#", text: "#" };
					bp.color = color;
					bp.filled = true;
					const _tmp = cloneDeep(bp);
					bp = _tmp;
				} else {
					bp.filled = false;
					delete bp.color;
				}
			});
			newStartPositions.forEach((sp) => {
				const pos = state.positions.find((p) => p.pos == sp.pos);
				sp.filled = pos != undefined;
				if (pos != undefined) {
					const _tmp = cloneDeep(sp);
					sp = _tmp;
				}
			});
			return {
				...state,
				gameBoard: {
					...state.gameBoard,
					boardPositions: newBoardPositions,
					startPositions: newStartPositions,
				},
			};
		case ENABLE_THROW_AWAY:
			return { ...state, isThrowAwayAction: true };
		case DISABLE_THROW_AWAY:
			return { ...state, isThrowAwayAction: false };
		case SET_PLAYABLE_CARDS:
			if (state.gamePlayerToAct === null || typeof state.gamePlayerToAct.availableMoves === "undefined") return state;
			var newCards = cloneDeep(state.cards),
				playableCards = [],
				_cards = [];
			if (state.gamePlayerToAct.waitForSecond_7 != undefined && state.gamePlayerToAct.waitForSecond_7 == true) {
				_cards.push(newCards[state.gamePlayerToAct.cardIndex]);
			} else {
				state.gamePlayerToAct.availableMoves.forEach((move) => {
					const filtered = newCards.filter((c) => c && c.id && c.id.startsWith(pips[move.pip]));
					filtered.forEach((c) => _cards.push(c));
				});
			}
			if (_cards.length > 0) {
				_cards.forEach((card) => {
					card.playable = true;
					card.filtered = true;
					playableCards.push(card);
				});
			}

			//determine which card should be hinted (requestCardHint prop)
			var selectedCardIndex = newCards.indexOf((c) => c.selected);
			var commonCardCondition =
				selectedCardIndex == -1 &&
				playableCards.length > 1 &&
				state.isPlayerToAct == true &&
				state.canPlayerAct == false;
			newCards.forEach((card) => {
				card.requestCardHint =
					commonCardCondition &&
					playableCards[0].id == card.id &&
					card.selected == false &&
					card.dealed == true &&
					card.playable == true &&
					card.filtered == true;
			});
			return { ...state, cards: newCards, playableCardList: playableCards };
		case SET_PLAYABLE_PAWNS:
			if (state.gamePlayerToAct === null || typeof state.gamePlayerToAct.availableMoves === "undefined") return state;

			var newStartPositions = [...state.gameBoard.startPositions],
				newBoardPositions = [...state.gameBoard.boardPositions],
				playablePawns = [];
			state.gamePlayerToAct.availableMoves.forEach((move, index) => {
				var _move = move;
				if (move.type == "DOUBLE") {
					_move =
						typeof state.gamePlayerToAct.waitForSecond_7 === "undefined" ||
						state.gamePlayerToAct.waitForSecond_7 !== true
							? move.firstMove
							: move.secondMove;
				}
				const curPosID = typeof _move.curPos === "string" ? _move.curPos.toLowerCase() : _move.curPos;
				const _sp = newStartPositions.find((sp) => sp.pos == curPosID);
				const _bp = newBoardPositions.find((bp) => bp.id == curPosID);

				if (_sp != undefined) {
					const found = playablePawns.find((p) => p.id == curPosID);
					if (found == undefined) {
						playablePawns.push(_sp);
					}
					_sp.playable = true;
					_sp.filtered = true;
				}

				if (_bp != undefined) {
					const found = playablePawns.find((p) => p.id == curPosID);
					if (found == undefined) {
						playablePawns.push(_bp);
					}
					_bp.playable = true;
					_bp.filtered = true;
				}
			});

			//determine which pawn should be hinted (requestHint prop)
			var selectedPosIndex = newBoardPositions.indexOf((c) => c.selected == true);
			if (selectedPosIndex == -1) {
				selectedPosIndex = newStartPositions.indexOf((c) => c.selected == true);
			}
			var commonPosCondition =
				selectedPosIndex == -1 &&
				playablePawns.length > 1 &&
				state.isPlayerToAct == true &&
				state.canPlayerAct == false;

			newBoardPositions.forEach((bp) => {
				bp.requestHint =
					commonPosCondition &&
					playablePawns[0].id &&
					playablePawns[0].id == bp.id &&
					bp.selected == false &&
					bp.filled == true &&
					bp.filtered == true &&
					bp.playable == true;
			});
			newStartPositions.forEach((sp) => {
				sp.requestHint =
					commonPosCondition &&
					playablePawns[0].pos &&
					playablePawns[0].pos == sp.pos &&
					sp.selected == false &&
					sp.filled == true &&
					sp.filtered == true &&
					sp.playable == true;
			});
			return {
				...state,
				playablePawnList: playablePawns,
				gameBoard: {
					...state.gameBoard,
					startPositions: newStartPositions,
					boardPositions: newBoardPositions,
				},
			};
		case DEAL_CARD:
			if (typeof payload !== "number") return state;
			var newCards = cloneDeep(state.cards);
			if (newCards[payload]) newCards[payload].dealed = true;
			return { ...state, cards: newCards };
		case SELECT_CARD:
			if (typeof payload === "undefined") return state;

			var _SELC_log = {
				SELC_message: "SELECT_CARD reducer",
				gameID: state.gameID,
				selCardDet: {},
			};
			var newCards = cloneDeep(state.cards);
			_SELC_log.selCardDet.selectedCard = cloneDeep(newCards[payload.cardIndex]);
			_SELC_log.selCardDet.cardsBeforeSelect = cloneDeep(newCards);
			newCards.forEach((c, ind) => {
				c.selected = c.id == payload.id && ind == payload.cardIndex;
			});
			_SELC_log.selCardDet.cardsAfterSelect = cloneDeep(newCards);
			setTimeout(async () => {
				await DebugLogger.shared.saveLog(_SELC_log, true);
			}, 5);
			return { ...state, cards: newCards };
		case SELECT_PAWN:
			if (typeof payload === "undefined") return state;

			var _SELP_log = {
				SELP_message: "SELECT_PAWN reducer",
				gameID: state.gameID,
				selPawnDet: {},
			};
			var newStartPositions = [...state.gameBoard.startPositions],
				newBoardPositions = [...state.gameBoard.boardPositions];
			if (payload.pos) {
				newBoardPositions.forEach((bp) => {
					bp.selected = false;
				});
				newStartPositions.forEach((sp, ind) => {
					if (sp.pos == payload.pos) {
						_SELP_log.selPawnDet.beforeSelect = cloneDeep(newStartPositions[ind]);
						sp.selected = true;
						_SELP_log.selPawnDet.afterSelect = cloneDeep(newStartPositions[ind]);
					} else {
						sp.selected = false;
					}
				});
			} else {
				newStartPositions.forEach((sp) => {
					sp.selected = false;
				});
				newBoardPositions.forEach((bp, ind) => {
					if (bp.id == payload.id) {
						_SELP_log.selPawnDet.beforeSelect = cloneDeep(newBoardPositions[ind]);
						bp.selected = true;
						_SELP_log.selPawnDet.afterSelect = cloneDeep(newBoardPositions[ind]);
					} else {
						bp.selected = false;
					}
				});
			}
			setTimeout(async () => {
				await DebugLogger.shared.saveLog(_SELP_log, true);
			}, 5);
			return {
				...state,
				gameBoard: {
					...state.gameBoard,
					startPositions: newStartPositions,
					boardPositions: newBoardPositions,
				},
			};
		case SELECT_TARGETED_POSITION:
			if (
				typeof payload === "undefined" ||
				state.gamePlayerToAct === null ||
				typeof state.gamePlayerToAct.availableMoves === "undefined"
			)
				return state;
			var _SELTP_log = {
				SELTP_message: "SELECT_TARGETED_POSITION reducer",
				gameID: state.gameID,
				selTargetedPosDet: {},
			};
			var newBoardPositions = cloneDeep(state.gameBoard.boardPositions);
			var selPos = newBoardPositions.find((bp) => bp.id == payload.id);
			if (selPos != undefined) selPos.selected = true;
			var selMoveId = helpers.getSelectedMoveIdToAct(
				state.targetedPositions,
				state.cards,
				newBoardPositions,
				state.gameBoard.startPositions,
				state.filteredMoves
			);
			var canPlayerAct = helpers.checkPlayerCanAct(
				selMoveId,
				state.cards,
				newBoardPositions,
				state.gameBoard.startPositions,
				state.filteredMoves
			);
			var waitingForSecond7 = false;
			var _selMove = state.gamePlayerToAct.availableMoves.find((move) => move.moveID == selMoveId);
			if (_selMove != undefined) {
				if (_selMove.type == "DOUBLE") {
					var selPositions = [];
					selPositions = state.gameBoard.boardPositions.filter((bp) => bp.selected);
					state.gameBoard.startPositions.forEach((sp) => {
						if (sp.selected == true) selPositions.push(sp);
					});
					const selectedPawn = selPositions.find((sp) => !sp.targeted);
					if (state.waitForSecond_7 == true) {
						// check if secondMove is selected, if that's the case we don't have to wait for the second 7-move
						if (
							selectedPawn.id == _selMove.secondMove.curPos.toLowerCase() ||
							selectedPawn.pos == _selMove.secondMove.curPos.toLowerCase()
						) {
							const _targeted = state.targetedPositions.find((np) => np.id == _selMove.secondMove.newPos.toLowerCase());
							if (_targeted != undefined) {
								waitingForSecond7 = true;
							}
						}
						// check if firstMove is selected, if that's the case we will wait for the second 7-move
						if (
							selectedPawn.id == _selMove.firstMove.curPos.toLowerCase() ||
							selectedPawn.pos == _selMove.firstMove.curPos.toLowerCase()
						) {
							const _targeted = state.targetedPositions.find((np) => np.id == _selMove.firstMove.newPos.toLowerCase());
							if (_targeted != undefined) {
								waitingForSecond7 = true;
							}
						}
					} else {
						// check if firstMove is selected, if that's the case we will wait for the second 7-move
						if (
							selectedPawn.id == _selMove.firstMove.curPos.toLowerCase() ||
							selectedPawn.pos == _selMove.firstMove.curPos.toLowerCase()
						) {
							const _targeted = state.targetedPositions.find((np) => np.id == _selMove.firstMove.newPos.toLowerCase());
							if (_targeted != undefined) {
								waitingForSecond7 = true;
							}
						}
					}
				} else {
					waitingForSecond7 = state.waitForSecond_7;
				}
			} else {
				waitingForSecond7 = state.waitForSecond_7;
			}
			_SELTP_log.selTargetedPosDet.selectedPos = selPos;
			_SELTP_log.selTargetedPosDet.selMoveId = selMoveId;
			_SELTP_log.selTargetedPosDet._selMove = _selMove;
			_SELTP_log.selTargetedPosDet.waitingForSecond7 = waitingForSecond7;
			_SELTP_log.selTargetedPosDet.canPlayerAct = canPlayerAct;
			setTimeout(async () => {
				await DebugLogger.shared.saveLog(_SELTP_log, true);
			}, 5);
			return {
				...state,
				selectedMoveIdToAct: selMoveId,
				canPlayerAct: canPlayerAct,
				waitForSecond_7: waitingForSecond7,
				gameBoard: {
					...state.gameBoard,
					boardPositions: newBoardPositions,
				},
			};
		case GAME_PLAYER_TO_ACT_RECEIVED:
			if (typeof payload === "undefined") return state;
			var newSlotToAct = payload.slotID;
			if (state.sitSlotID !== state.playerSlot) {
				newSlotToAct += state.sitSlotID - state.playerSlot;
				/* if (state.sitSlotID > state.playerSlot) {
          newSlotToAct += state.sitSlotID - state.playerSlot;
        } else {
          newSlotToAct += state.playerSlot - state.sitSlotID;
        } */
				if (newSlotToAct > state.playerCnt - 1) {
					newSlotToAct -= state.playerCnt;
				} else if (newSlotToAct < 0) {
					newSlotToAct += state.playerCnt;
				}
			} else {
				newSlotToAct =
					payload.slotID != undefined && payload.slotID != state.slotToAct ? payload.slotID : state.slotToAct;
			}
			return {
				...state,
				gamePlayerToAct: payload,
				isPlayerToAct: payload.isPlayerToAct || initialState.isPlayerToAct,
				slotToAct: newSlotToAct,
				nextSlotIDToAct: payload.nextSlotIDToAct,
				waitForSecond_7: payload.waitForSecond_7 != undefined ? payload.waitForSecond_7 : state.waitForSecond_7,
			};
		case SET_NEXT_SLOT_TO_ACT:
			return {
				...state,
				slotToAct:
					/* state.nextSlotIDToAct != undefined
            ? state.nextSlotIDToAct
            : */ state.slotToAct,
			};
		case RESET_GAME_PLAYER_TO_ACT:
			return {
				...state,
				gamePlayerToAct: initialState.gamePlayerToAct,
				waitForSecond_7: false,
				isPlayerToAct: initialState.isPlayerToAct,
			};
		case GAME_PLAYER_ACTED_RECEIVED:
			if (typeof payload === "undefined") return state;
			return {
				...state,
				gamePlayerActed: payload,
				moveToRollback: null,
				isPartialWin: payload.isPartialWin || false,
				waitForSecond_7: payload.waitForSecond_7 == undefined ? state.waitForSecond_7 : payload.waitForSecond_7,
			};
		case RESET_GAME_PLAYER_ACTED:
			return {
				...state,
				gamePlayerActed: initialState.gamePlayerActed,
				isPartialWin: initialState.isPartialWin,
			};
		case GAME_END_RECEIVED:
			if (typeof payload === "undefined") return state;
			const _seconds = payload.secondsBeforeClose ? Number(payload.secondsBeforeClose) : -1;
			var seconds = !isNaN(_seconds) ? _seconds : -1;
			// var _shareableFBQuote = state.shareableFBQuote;
			// if (
			//   payload &&
			//   payload.specificDetails &&
			//   Array.isArray(payload.specificDetails.facebookShareImageText) &&
			//   payload.specificDetails.facebookShareImageText.length > 0
			// ) {
			//   const _myArray = payload.specificDetails.facebookShareImageText;
			//   _shareableFBQuote = "";
			//   _myArray.forEach((txt, ind) => {
			//     _shareableFBQuote += txt;
			//     if (ind != _myArray.length - 1) {
			//       _shareableFBQuote += "\n";
			//     }
			//   });
			// }
			return {
				...state,
				isPlayerToAct: false,
				// shareableFBQuote: _shareableFBQuote,
				// allowFacebookSharing:
				//   payload &&
				//   payload.specificDetails &&
				//   typeof payload.specificDetails.allowFacebookSharing === "boolean"
				//     ? payload.specificDetails.allowFacebookSharing
				//     : state.allowFacebookSharing,
				/*TODO: temporary disabled allowFacebookSharing:
          payload && typeof payload.faceBookSharingEnabled === "boolean"
            ? payload.faceBookSharingEnabled
            : state.allowFacebookSharing, */
				// shareableFBImageURL:
				//   payload &&
				//   payload.specificDetails &&
				//   typeof payload.specificDetails.imageUrl === "string"
				//     ? payload.specificDetails.imageUrl
				//     : state.shareableFBImageURL,
				shareableFBImageURL:
					payload && typeof payload.faceBookImageUrl === "string"
						? payload.faceBookImageUrl
						: state.shareableFBImageURL,
				// shareableFBHashtag:
				//   payload &&
				//   payload.specificDetails &&
				//   typeof payload.specificDetails.facebookShareHashTag === "string"
				//     ? "#" + payload.specificDetails.facebookShareHashTag
				//     : state.shareableFBHashtag,
				faceBookShareID:
					payload && typeof payload.faceBookShareID === "string" ? payload.faceBookShareID : state.faceBookShareID,
				faceBookShareHash:
					payload && typeof payload.faceBookShareHash === "string"
						? payload.faceBookShareHash
						: state.faceBookShareHash,
				gameEnd: {
					...state.gameEnd,
					secondsBeforeClose: seconds,
					res: payload,
					isGameEnded: true,
					requestDidYouKnow:
						payload && typeof payload.didYouKnowLink === "boolean"
							? payload.showDidYouKnow
							: state.gameEnd.requestDidYouKnow,
					didYouKnowScreenID:
						payload && typeof payload.didYouKnow === "string" ? payload.didYouKnow : state.gameEnd.didYouKnowScreenID,
					didYouKnowLink:
						payload && typeof payload.didYouKnowLink === "string"
							? payload.didYouKnowLink
							: state.gameEnd.didYouKnowLink,
				},
			};
		case RESET_GAME_END:
			return { ...state, gameEnd: initialState.gameEnd };
		case SHOW_DID_YOU_KNOW:
			return {
				...state,
				gameEnd: {
					...state.gameEnd,
					isGameEnded: false,
					isDidYouKnowDisplayed: true,
				},
			};
		case FILTER_AVAILABLE_MOVES:
			var filterLog = {
				filter_message: "FILTER_AVAILABLE_MOVES reducer",
				gameID: state.gameID,
				filterDet: {
					params: {
						isPawnSelected: payload.isPawnSelected,
						availableMovesError:
							state.gamePlayerToAct === null || !Array.isArray(state.gamePlayerToAct.availableMoves)
								? "there are no moves to filter"
								: undefined,
					},
					oldFiltMoves:
						state.gamePlayerToAct === null || !Array.isArray(state.gamePlayerToAct.availableMoves)
							? "there are no previously filtered move"
							: cloneDeep(state.gamePlayerToAct.availableMoves),
					oldCards: cloneDeep(state.cards),
				},
			};
			if (
				typeof payload === "undefined" ||
				typeof payload.isPawnSelected !== "boolean" ||
				state.gamePlayerToAct === null ||
				!Array.isArray(state.gamePlayerToAct.availableMoves)
			) {
				setTimeout(async () => {
					await DebugLogger.shared.saveLog(filterLog, true);
				}, 5);
				return state;
			}
			const { isPawnSelected } = payload;
			var newFilteredMoves = [],
				newStartPositions = [...state.gameBoard.startPositions],
				newBoardPositions = [...state.gameBoard.boardPositions],
				newCards = [...state.cards];
			var _res = helpers.filterAvailableMovesFunc(
				isPawnSelected,
				state.gamePlayerToAct,
				newCards,
				newBoardPositions,
				newStartPositions,
				newFilteredMoves,
				state.waitForSecond_7,
				filterLog
			);
			if (_res == undefined) {
				filterLog.filterDet.resultError = "result is undefined";
				setTimeout(async () => {
					await DebugLogger.shared.saveLog(filterLog, true);
				}, 5);
				return state;
			}
			filterLog.filterDet.newFiltMoves = _res.newFilteredMoves;
			filterLog.filterDet.newCards = _res.newCards;

			state.gamePlayerToAct.availableMoves.forEach((move) => {
				var _move = move;
				if (move.type == "DOUBLE") {
					_move =
						typeof state.gamePlayerToAct.waitForSecond_7 === "undefined" ||
						state.gamePlayerToAct.waitForSecond_7 !== true
							? move.firstMove
							: move.secondMove;
				}
				const curPosID = typeof _move.curPos === "string" ? _move.curPos.toLowerCase() : _move.curPos;
				const _bp = _res.newBoardPositions.find((bp) => bp.id == curPosID);

				if (_bp != undefined && !_bp.playable) _bp.playable = true;
			});

			var waitingForSecond7 = false;
			var _selMove = state.gamePlayerToAct.availableMoves.find((move) => move.moveID == _res.selectedMoveIdToAct);
			if (_selMove != undefined) {
				if (_selMove.type == "DOUBLE") {
					var selPositions = [];
					selPositions = _res.newBoardPositions.filter((bp) => bp.selected);
					newStartPositions.forEach((sp) => {
						if (sp.selected == true) selPositions.push(sp);
					});
					const selectedPawn = selPositions.find((sp) => !sp.targeted);
					if (state.waitForSecond_7 == true) {
						// check if secondMove is selected, if that's the case we don't have to wait for the second 7-move
						if (
							selectedPawn.id == _selMove.secondMove.curPos.toLowerCase() ||
							selectedPawn.pos == _selMove.secondMove.curPos.toLowerCase()
						) {
							const _targeted = _res.targetedPositions.find((np) => np.id == _selMove.secondMove.newPos.toLowerCase());
							if (_targeted != undefined) {
								waitingForSecond7 = false;
							} else {
								waitingForSecond7 = state.waitForSecond_7;
							}
						}
					} else {
						// check if firstMove is selected, if that's the case we will wait for the second 7-move
						if (
							selectedPawn.id == _selMove.firstMove.curPos.toLowerCase() ||
							selectedPawn.pos == _selMove.firstMove.curPos.toLowerCase()
						) {
							const _targeted = _res.targetedPositions.find((np) => np.id == _selMove.firstMove.newPos.toLowerCase());
							if (_targeted != undefined) {
								waitingForSecond7 = true;
							} else {
								waitingForSecond7 = state.waitForSecond_7;
							}
						}
					}
				} else {
					waitingForSecond7 = false;
				}
			} else {
				waitingForSecond7 = state.waitForSecond_7;
			}

			//determine which pawn should be hinted (requestHint prop)
			const ffMove = _res.newFilteredMoves[0];
			var selectedPosIndex = _res.newBoardPositions.indexOf((c) => c.selected == true);
			if (selectedPosIndex == -1) {
				selectedPosIndex = _res.newStartPositions.indexOf((c) => c.selected == true);
			}
			var commonPosCondition =
				ffMove != undefined &&
				selectedPosIndex == -1 &&
				state.playablePawnList.length > 1 &&
				state.isPlayerToAct == true &&
				_res.canPlayerAct == false;

			_res.newBoardPositions.forEach((bp) => {
				bp.requestHint =
					commonPosCondition &&
					ffMove.curPos.toLowerCase() == bp.id &&
					state.playablePawnList[0].id &&
					state.playablePawnList[0].id == bp.id &&
					bp.selected == false &&
					bp.filled == true &&
					bp.filtered == true &&
					bp.playable == true;
			});
			_res.newStartPositions.forEach((sp) => {
				sp.requestHint =
					commonPosCondition &&
					ffMove.curPos.toLowerCase() == sp.pos &&
					state.playablePawnList[0].pos &&
					state.playablePawnList[0].pos == sp.pos &&
					sp.selected == false &&
					sp.filled == true &&
					sp.filtered == true &&
					sp.playable == true;
			});

			//determine which card should be hinted (requestCardHint prop)
			var selectedCardIndex = _res.newCards.indexOf((c) => c.selected);
			var commonCardCondition =
				ffMove != undefined &&
				selectedCardIndex == -1 &&
				state.playableCardList.length > 1 &&
				state.isPlayerToAct == true &&
				_res.canPlayerAct == false;
			_res.newCards.forEach((card) => {
				var cardPip;
				if (ffMove != undefined) cardPip = pips[ffMove.pip];
				cardPip = cardPip == undefined ? "" : cardPip;
				card.requestCardHint =
					card &&
					commonCardCondition &&
					card.id &&
					card.id.startsWith(cardPip) &&
					card.selected == false &&
					card.dealed == true &&
					card.playable == true &&
					card.filtered == true;
			});

			setTimeout(async () => {
				await DebugLogger.shared.saveLog(filterLog, true);
			}, 5);

			return {
				...state,
				cards: _res.newCards,
				filteredMoves: _res.newFilteredMoves,
				targetedPositions: _res.targetedPositions,
				selectedMoveIdToAct: _res.selectedMoveIdToAct,
				canPlayerAct: _res.canPlayerAct,
				gameBoard: {
					...state.gameBoard,
					startPositions: _res.newStartPositions,
					boardPositions: _res.newBoardPositions,
				},
			};
		case PLAY_CARD:
		case THROW_AWAY:
			return {
				...state,
				playableCardList: initialState.playableCardList,
				playablePawnList: initialState.playablePawnList,
				filteredMoves: initialState.filteredMoves,
				selectedMoveIdToAct: initialState.selectedMoveIdToAct,
				canPlayerAct: initialState.canPlayerAct,
			};
		case GAME_INFO_RECEIVED:
			if (typeof payload === "undefined") return state;
			var newCards = [];
			if (Array.isArray(payload.data.specific.cards)) {
				var cardSuitOrder = payload.data.specific.cardSuitOrder.split("");
				var cardValueOrder = payload.data.specific.cardValueOrder;
				var asc_or_desc = cardValueOrder.split("_").pop();
				var valOrder = cardValueOrder.split("_").shift().split("");
				var sortedCardVal = [];
				valOrder.forEach((ord) => {
					var filtCardVal = cloneDeep(cardValues[ord]);
					if (asc_or_desc == "DESC") {
						filtCardVal = filtCardVal.reverse();
					}
					filtCardVal.forEach((fcv) => sortedCardVal.push(fcv));
				});
				payload.data.specific.cards.forEach((c) => {
					if (payload.data.specific.features && payload.data.specific.features.cardSorting == true) {
						const suitOrderIndex = cardSuitOrder.indexOf(c[c.length - 1].toUpperCase());
						var valOrderIndex = sortedCardVal.findIndex((scv) => scv[scv.length - 1] == c["0"].toUpperCase());
						valOrderIndex = valOrderIndex != -1 ? valOrderIndex : 0;
						newCards.push({
							id: c,
							playable: true,
							filtered: false,
							selected: false,
							dealed: false,
							requestCardHint: false,
							cardSuit: suitOrderIndex,
							cardVal: valOrderIndex,
						});
					} else {
						newCards.push({
							id: c,
							playable: true,
							filtered: false,
							selected: false,
							dealed: false,
							requestCardHint: false,
						});
					}
				});
				if (payload.data.specific.features && payload.data.specific.features.cardSorting == true) {
					switch (payload.data.specific.cardSortOrder) {
						case "SUITS_VALUES":
							var orderedSV = orderBy(newCards, ["cardSuit", "cardVal"], ["asc", "asc"]);
							newCards = orderedSV;
							break;
						case "VALUES_SUITS":
							var orderedVS = orderBy(newCards, ["cardVal", "cardSuit"], ["asc", "asc"]);
							newCards = orderedVS;
							break;
						case "RANDOM":
						default:
							break;
					}
				}
			}

			var _positions = [];
			if (typeof payload.data.specific.positions === "object") {
				helpers.mapObject(payload.data.specific.positions, (k, v) =>
					_positions.push({ pos: k.toLowerCase(), slotID: v })
				);
				_positions.sort(function (a, b) {
					var idA = Number(a.pos.substr(1, a.pos.length));
					var idB = Number(b.pos.substr(1, b.pos.length));
					return idA > idB ? 1 : idB > idA ? -1 : 0;
				});
			}

			var _cardCounts = [];
			if (typeof payload.data.specific.cardCnts === "object") {
				helpers.mapObject(payload.data.specific.cardCnts, (k, v) => _cardCounts.push({ slotID: k, count: v }));
			}

			var _newPlayers = helpers.getStartedGamesPlayersList(payload);
			var _players = { ...state.players };
			helpers.mapObject(_players, (k, v) => {
				var searchedSlotID = v.slotID;
				if (state.sitSlotID !== state.playerSlot) {
					searchedSlotID += state.sitSlotID - state.playerSlot;
				}
				if (searchedSlotID > state.playerCnt - 1) {
					searchedSlotID -= state.playerCnt;
				} else if (searchedSlotID < 0) {
					searchedSlotID += state.playerCnt;
				}
				const newData = _newPlayers["slot_" + searchedSlotID];
				if (newData) {
					newData.slotID = v.slotID;
					v = newData;
				}
			});

			const _isShuffled = payload.reason && payload.reason === "NEW_ROUND";
			var newDealerSlot = payload.data.specific.dealerSlot != undefined ? payload.data.specific.dealerSlot : -1;
			if (state.sitSlotID !== state.playerSlot) {
				newDealerSlot += state.sitSlotID - state.playerSlot;
				/* if (state.sitSlotID > state.playerSlot) {
          newDealerSlot += state.playerSlot - state.sitSlotID;
        } else {
          newDealerSlot += state.sitSlotID - state.playerSlot;
        } */
				if (newDealerSlot < 0) {
					newDealerSlot += payload.data.generic.playerCnt;
				} else if (newDealerSlot > payload.data.generic.playerCnt - 1) {
					newDealerSlot -= payload.data.generic.playerCnt;
				}
			}
			return {
				...state,
				useLoudToActSound: payload.data.specific.useLoudToActSound,
				useQuickMove: payload.data.specific.useQuickMove,
				useLastMoveTracking: payload.data.specific.useLastMoveTracking,
				cardSortOrder: payload.data.specific.cardSortOrder || "",
				cardSuitOrder: payload.data.specific.cardSuitOrder || "",
				cardValueOrder: payload.data.specific.cardValueOrder || "",
				gameChatName: payload.data.generic.gameChatName || "",
				dealer: _isShuffled ? newDealerSlot : state.dealer,
				// payload.data.specific.dealerSlot != undefined
				//   ? payload.data.specific.dealerSlot
				//   : -1,
				teamMate: payload.teamMate || -1,
				players: _players,
				cards: newCards,
				positions: _positions,
				cardCounts: _cardCounts,
				eventWaitTime: payload.eventWaitTime || 0,
				// playerSlot: _playerSlot,
				initialPosition: payload.data.specific.sitSlotID || 0,
				// sitSlotID: payload.data.specific.sitSlotID || 0,
				isShuffled: _isShuffled,
				features: payload.data.specific.features || [],
				reason: payload.reason || "",
				slotToAct: -1,
			};
		case START_COUNTDOWN:
			return { ...state, isCountDownStarted: true };
		case FINISH_COUNTDOWN:
			return { ...state, isCountDownStarted: false };
		case SET_TO_LATE_TIME:
			if (typeof payload === "undefined") return state;
			return { ...state, toLateTime: payload };
		case CLEAR_TO_LATE_TIME:
			return { ...state, toLateTime: initialState.toLateTime };
		case SET_WARNING_TIMER:
			return { ...state, isPlayerWarned: true };
		case RESET_WARNING_TIMER:
			return { ...state, isPlayerWarned: false };
		case MOVE_CARD_TO_PLAYED_CARDS:
			if (typeof payload === "undefined" || typeof payload.slotID === "undefined" || typeof payload.cardID !== "string")
				return state;

			const { slotID, cardID } = payload;
			var cardToPlayIndex = -1,
				randomDeg = Math.floor(Math.random() * 70 - 35),
				randomPosition = Math.floor(Math.random() * 20 - 10);
			var newCards = state.cards,
				newCardCounts = cloneDeep(state.cardCounts),
				// newPlayedCards = cloneDeep(state.gameBoard.playedCards);
				newPlayedCards = [...state.gameBoard.playedCards];
			if (slotID == state.playerSlot) {
				newCards = cloneDeep(state.cards);
				cardToPlayIndex = newCards.findIndex((c) => c.id == cardID);
				if (cardToPlayIndex !== -1) {
					newCards.splice(cardToPlayIndex, 1);
				}
			}

			var newSlotToAct = payload.slotID;
			if (state.sitSlotID !== state.playerSlot) {
				newSlotToAct += state.sitSlotID - state.playerSlot;
				/* if (state.sitSlotID > state.playerSlot) {
          newSlotToAct += state.sitSlotID - state.playerSlot;
        } else {
          newSlotToAct += state.playerSlot - state.sitSlotID;
        } */
				if (newSlotToAct > state.playerCnt - 1) {
					newSlotToAct -= state.playerCnt;
				} else if (newSlotToAct < 0) {
					newSlotToAct += state.playerCnt;
				}
			}
			newCardCounts.forEach((cc) => {
				if (cc.slotID == newSlotToAct) cc.count--;
			});
			newPlayedCards.push({
				id: cardID,
				isThrown: false,
				rotation: randomDeg,
				top: randomPosition,
				left: randomPosition,
			});
			//if there are more then 10 cards then remove the first card
			if (newPlayedCards.length > 10) {
				newPlayedCards.shift();
			}
			return {
				...state,
				cards: slotID == state.playerSlot ? newCards : state.cards,
				cardCounts: newCardCounts,
				gameBoard: { ...state.gameBoard, playedCards: newPlayedCards },
			};
		case MOVE_THROWN_CARD_TO_PLAYED_CARDS:
			if (typeof payload === "undefined" || typeof payload.slotID === "undefined" || !Array.isArray(payload.cards))
				return state;

			var cards = payload.cards,
				_slotID = payload.slotID,
				randomDeg = helpers.getRandomInt(0, 8),
				topPosition = 0,
				leftPosition = 40; // (cards.length - 1) * 10 + (5 - cards.length) * 10;
			randomDeg *= -1;
			randomDeg -= cards.length * 15 - 15;

			var newCards = cloneDeep(state.cards),
				newCardCounts = cloneDeep(state.cardCounts),
				// newPlayedCards = cloneDeep(state.gameBoard.playedCards);
				newPlayedCards = [...state.gameBoard.playedCards];

			cards.forEach((_card, i) => {
				if (i > 0) randomDeg += 15;
				if (_slotID == state.playerSlot) {
					cardToPlayIndex = newCards.findIndex((c) => c.id == _card);
					if (cardToPlayIndex !== -1) {
						newCards.splice(cardToPlayIndex, 1);
					}
				}
				var _topPos = topPosition,
					_leftPos = leftPosition;
				switch (i) {
					case 0:
						_topPos = topPosition - 12;
						_leftPos = leftPosition - 60;
						break;
					case 1:
						_topPos = topPosition - 12;
						_leftPos = leftPosition - 42;
						break;
					case 2:
						_topPos = topPosition - 10;
						_leftPos = leftPosition - 26;
						break;
					case 3:
						_topPos = topPosition - 6;
						_leftPos = leftPosition - 12;
						break;
				}

				var newSlotToAct = payload.slotID;
				if (state.sitSlotID !== state.playerSlot) {
					newSlotToAct += state.sitSlotID - state.playerSlot;
					/* if (state.sitSlotID > state.playerSlot) {
            newSlotToAct += state.sitSlotID - state.playerSlot;
          } else {
            newSlotToAct += state.playerSlot - state.sitSlotID;
          } */
					if (newSlotToAct > state.playerCnt - 1) {
						newSlotToAct -= state.playerCnt;
					} else if (newSlotToAct < 0) {
						newSlotToAct += state.playerCnt;
					}
				}
				newCardCounts.forEach((cc) => {
					if (cc.slotID == newSlotToAct) cc.count--;
				});
				newPlayedCards.push({
					id: _card,
					isThrown: payload.cards.length <= 5, //false,
					rotation: randomDeg,
					top: _topPos,
					left: _leftPos,
				});
			});

			//if there are more then 10 cards then remove the first card
			while (newPlayedCards.length > 10) {
				newPlayedCards.shift();
			}
			return {
				...state,
				cards: _slotID == state.playerSlot ? newCards : state.cards,
				cardCounts: newCardCounts,
				gameBoard: { ...state.gameBoard, playedCards: newPlayedCards },
			};
		case MOVE_PAWNS:
			if (typeof payload === "undefined" || typeof payload.move === "undefined") return state;

			var newStartPositions = [...state.gameBoard.startPositions],
				newBoardPositions = [...state.gameBoard.boardPositions],
				move = payload.move;
			var pawnsTransitionDelay = 0;
			var pawnsTransitionTime = 0;
			if (payload.slotID == state.playerSlot) {
				pawnsTransitionDelay = 0;
				pawnsTransitionTime = 300;
			} else {
				pawnsTransitionDelay = 500;
				pawnsTransitionTime = 300;
			}
			var _MP_log = {
				MP_message: "MOVE_PAWNS reducer",
				gameID: state.gameID,
				moveType: move.type,
				movePawnsDet: {},
			};
			switch (payload.move.type) {
				case "DOUBLE":
				case "NORMAL":
				case "START":
					if (move.type == "DOUBLE") {
						move = state.waitForSecond_7 == false ? payload.move.secondMove : payload.move.firstMove;
						if (payload.slotID != state.playerSlot) {
							move = state.moveCnt == 0 ? payload.move.firstMove : payload.move.secondMove;
						}
						if (
							state.gamePlayerActed !== null &&
							state.gamePlayerActed.type != "localMove" &&
							payload.slotID == state.playerSlot
						) {
							move = state.gamePlayerActed.waitForSecond_7 == true ? payload.move.secondMove : payload.move.firstMove;
						}
					}
					_MP_log.moveToProcess = move;
					var showNewPawnDelay = move.curPos.toLowerCase().startsWith("s") ? 0 : pawnsTransitionDelay;
					var playerPanel = state.gameBoard.playerPanels.find((pp) => pp.slotID == payload.slotID);
					var color = playerPanel != undefined ? playerPanel.color : { normal: "#", strong: "#", text: "#" };
					var curPos, newPos;
					if (move.curPos.toLowerCase().startsWith("s")) {
						curPos = newStartPositions.find((sp) => sp.pos == move.curPos.toLowerCase());
					} else {
						curPos = newBoardPositions.find((bp) => bp.id == move.curPos.toLowerCase());
					}
					if (move.newPos.toLowerCase().startsWith("s")) {
						newPos = newStartPositions.find((sp) => sp.pos == move.newPos.toLowerCase());
					} else {
						newPos = newBoardPositions.find((bp) => bp.id == move.newPos.toLowerCase());
					}
					_MP_log.movePawnsDet.curPosBeforeUpdate = cloneDeep(curPos);
					_MP_log.movePawnsDet.newPosBeforeUpdate = cloneDeep(newPos);
					if (typeof curPos !== "undefined") {
						color = cloneDeep(curPos.color);
						curPos.filled = false;
						curPos.requestSwapAnimation = false;
						curPos.moveStarter = state.useLastMoveTracking;
						// curPos.requestMoveAnimation = true;
						curPos.animatedMove = {
							pawnsTransitionTime: 500,
							pawnsTransitionDelay: pawnsTransitionDelay,
							requestFinish: false,
							playSound: false,
						};
						_MP_log.movePawnsDet.curPosAfterUpdate = cloneDeep(curPos);
					}
					if (typeof newPos !== "undefined") {
						if (move.isSmash && typeof move.returnPos === "string" && move.returnPos.toLowerCase().startsWith("s")) {
							var returnPos = newStartPositions.find((sp) => sp.pos == move.returnPos.toLowerCase());
							if (typeof returnPos !== "undefined") {
								_MP_log.movePawnsDet.returnPosBeforeUpdate = cloneDeep(returnPos);
								returnPos.filled = true;
								returnPos.requestSwapAnimation = false;
								returnPos.lastSwithed = state.useLastMoveTracking;
								// returnPos.requestMoveAnimation = true;
								returnPos.animatedMove = {
									pawnsTransitionTime: 500,
									pawnsTransitionDelay: pawnsTransitionDelay + 300,
									requestFinish: true,
									playSound:
										newPos.color && newPos.color.normal && color && color.normal && newPos.color.normal == color.normal, // false,
									isLocalPlayer: state.gamePlayerActed && state.gamePlayerActed.slotID == state.playerSlot,
									isDoubleMove: state.gamePlayerActed.move.type === "DOUBLE",
									isSmashMove: move.isSmash === true,
								};
								_MP_log.movePawnsDet.returnPosAfterUpdate = cloneDeep(returnPos);
							}
						}
						newPos.filled = true;
						newPos.color = color;
						newPos.requestSwapAnimation = false;
						newPos.lastSwithed = state.useLastMoveTracking && move.isSmash === true;
						newPos.moveEnd = state.useLastMoveTracking;
						// newPos.requestMoveAnimation = true;
						newPos.animatedMove = {};
						newPos.animatedMove = {
							pawnsTransitionTime: 500,
							pawnsTransitionDelay: move.isSmash === false ? showNewPawnDelay + 300 : showNewPawnDelay,
							requestFinish: move.isSmash === false,
							playSound: true,
							isLocalPlayer: state.gamePlayerActed && state.gamePlayerActed.slotID == state.playerSlot,
							isDoubleMove:
								state.gamePlayerActed && state.gamePlayerActed.move && state.gamePlayerActed.move.type === "DOUBLE",
							isSevenMove:
								state.gamePlayerActed &&
								Array.isArray(state.gamePlayerActed.cards) &&
								state.gamePlayerActed.cards[0].startsWith("7"),
							isSmashMove:
								move.isSmash == true &&
								typeof move.returnPos === "string" &&
								move.returnPos.toLowerCase().startsWith("s"),
							isBackMove:
								state.gamePlayerActed &&
								Array.isArray(state.gamePlayerActed.cards) &&
								state.gamePlayerActed.cards[0].startsWith("4"),
							isStartMove:
								state.gamePlayerActed && state.gamePlayerActed.move && state.gamePlayerActed.move.type === "START",
							isCompletedMove: false,
							// move.newPos.toLowerCase().startsWith("f") &&
							// state.teams &&
							// payload.isPartialWin == true,
							// isCompleted(state.gamePlayerActed.slotID, newBoardPositions),
							// isCompleted(newPos.slotID, newBoardPositions),
						};
						_MP_log.movePawnsDet.newPosAfterUpdate = cloneDeep(newPos);
					}

					//determine the implicated positions
					if (state.useLastMoveTracking == true) {
						if (move.curPos.toLowerCase().startsWith("b") || move.curPos.toLowerCase().startsWith("f")) {
							const cp_type = move.curPos.toLowerCase()[0];
							const np_type = move.newPos.toLowerCase()[0];
							var cp_Id = Number(move.curPos.substr(1)),
								np_Id = Number(move.newPos.substr(1)),
								implPos = [];
							if (!isNaN(cp_Id) && !isNaN(np_Id)) {
								if ((cp_type == "b" && np_type == "b") || (cp_type == "f" && np_type == "f")) {
									var steps = 0,
										cardValue = (cardValue =
											state.gamePlayerActed &&
											Array.isArray(state.gamePlayerActed.cards) &&
											typeof state.gamePlayerActed.cards[0] === "string" &&
											state.gamePlayerActed.cards.length > 0
												? state.gamePlayerActed.cards[0][0]
												: 0);
									switch (cardValue) {
										case "T":
											steps = 10;
											break;
										case "Q":
											steps = 12;
											break;
										case "A":
											steps = 1;
											break;
										default:
											steps = Number(cardValue);
											break;
									}
									steps = isNaN(steps) ? 0 : steps;
									if (payload.move.type == "DOUBLE") {
										steps = Math.abs(cp_Id - np_Id);
										steps = steps > Number(cardValue) ? state.playerCnt * 16 - steps : steps;
									}
									if (cp_Id <= np_Id) {
										if (Math.abs(cp_Id - np_Id) == steps) {
											implPos = newBoardPositions.filter(
												(bp) =>
													cp_type == bp.id[0] && Number(bp.id.substr(1)) > cp_Id && Number(bp.id.substr(1)) < np_Id
											);
										} else {
											implPos = [];
											var i = 1;
											do {
												var newInd = cp_Id - i;
												newInd = newInd < 0 ? newInd + state.playerCnt * 16 : newInd;
												newInd = cp_type + newInd;
												const newImpl = newBoardPositions.find((bp) => bp.id == newInd);
												if (newImpl && newImpl.id) implPos.push(newImpl);
												i++;
											} while (i <= steps);
										}
									} else {
										if (Math.abs(cp_Id - np_Id) == steps) {
											implPos = newBoardPositions.filter(
												(bp) =>
													cp_type == bp.id[0] && Number(bp.id.substr(1)) < cp_Id && Number(bp.id.substr(1)) > np_Id
											);
										} else {
											implPos = [];
											var i = 1;
											do {
												var newInd = cp_Id + i;
												newInd = newInd >= state.playerCnt * 16 ? newInd - state.playerCnt * 16 : newInd;
												newInd = cp_type + newInd;
												const newImpl = newBoardPositions.find((bp) => bp.id == newInd);
												if (newImpl && newImpl.id) implPos.push(newImpl);
												i++;
											} while (i <= steps);
										}
									}
								} else if (cp_type == "b" && np_type == "f") {
									var minB = -1,
										maxB = -1,
										minF = -1;
									const curPosColor = curPos.color;
									var actingPlayerSlot = state.gameBoard.playerPanels.find(
										(pp) =>
											pp.color &&
											pp.color.normal &&
											curPosColor &&
											curPosColor.normal &&
											pp.color.normal == curPosColor.normal
									);
									actingPlayerSlot =
										actingPlayerSlot && actingPlayerSlot.slotID != undefined
											? actingPlayerSlot.slotID
											: state.gamePlayerActed.slotID;
									if (actingPlayerSlot == 0) {
										maxB = state.playerCnt * 16 - 1;
									} else {
										maxB = actingPlayerSlot * 16 - 1;
									}

									minF = actingPlayerSlot * 4;
									if (state.sitSlotID !== state.playerSlot) {
										var newInd = minF;
										var nMaxB = maxB;
										var totalFPos = state.playerCnt * 4;
										var totalBPos = state.playerCnt * 16;
										if (state.sitSlotID < state.playerSlot) {
											newInd = minF + 4 * (state.playerSlot - state.sitSlotID);
											if (newInd > totalFPos - 1) newInd -= totalFPos;
											nMaxB = maxB + 16 * (state.playerSlot - state.sitSlotID);
											if (nMaxB > totalBPos - 1) nMaxB -= totalBPos;
										} else {
											newInd = minF - 4 * (state.sitSlotID - state.playerSlot);
											if (newInd < 0) newInd += totalFPos;
											nMaxB = maxB - 16 * (state.sitSlotID - state.playerSlot);
											if (nMaxB < 0) nMaxB += totalBPos;
										}
										minF = newInd;
										maxB = nMaxB;
									}
									minB = cp_Id < maxB ? cp_Id + 1 : -1;

									implPos = newBoardPositions.filter(
										(bp) =>
											(minB >= 0 &&
												maxB >= 0 &&
												cp_type == bp.id[0] &&
												Number(bp.id.substr(1)) >= minB &&
												Number(bp.id.substr(1)) <= maxB) ||
											(minF >= 0 &&
												np_type == bp.id[0] &&
												Number(bp.id.substr(1)) >= minF &&
												Number(bp.id.substr(1)) < np_Id)
									);
								}
							}
							implPos.forEach((impl) => (impl.implicatedInMove = true));
						}
					}
					break;
				case "SWITCH":
				case "SWAP":
					_MP_log.moveToProcess = move;
					var curPos, newPos, curPosColor, newPosColor;
					if (move.curPos.toLowerCase().startsWith("s")) {
						curPos = newStartPositions.find((sp) => sp.pos == move.curPos.toLowerCase());
					} else {
						curPos = newBoardPositions.find((bp) => bp.id == move.curPos.toLowerCase());
					}

					if (move.newPos.toLowerCase().startsWith("s")) {
						newPos = newStartPositions.find((sp) => sp.pos == move.newPos.toLowerCase());
					} else {
						newPos = newBoardPositions.find((bp) => bp.id == move.newPos.toLowerCase());
					}
					_MP_log.movePawnsDet.curPosBeforeUpdate = cloneDeep(curPos);
					_MP_log.movePawnsDet.newPosBeforeUpdate = cloneDeep(newPos);

					if (curPos != undefined && newPos != undefined) {
						curPosColor = cloneDeep(curPos.color);
						newPosColor = cloneDeep(newPos.color);
						curPos.color = newPosColor;
						newPos.color = curPosColor;
						curPos.requestSwapAnimation = true;
						curPos.lastSwithed = state.useLastMoveTracking;
						// curPos.requestMoveAnimation = false;
						curPos.animatedMove = {
							pawnsTransitionTime: pawnsTransitionTime,
							pawnsTransitionDelay: pawnsTransitionDelay,
							requestFinish: false,
							playSound: true,
						};
						newPos.requestSwapAnimation = true;
						newPos.lastSwithed = state.useLastMoveTracking;
						newPos.moveEnd = state.useLastMoveTracking;
						// newPos.requestMoveAnimation = false;
						newPos.animatedMove = {
							pawnsTransitionTime: pawnsTransitionTime + 300,
							pawnsTransitionDelay: 0,
							requestFinish: true,
							playSound: false,
						};
					}
					_MP_log.movePawnsDet.curPosAfterUpdate = cloneDeep(curPos);
					_MP_log.movePawnsDet.newPosAfterUpdate = cloneDeep(newPos);
					break;
				default:
					break;
			}
			setTimeout(async () => {
				await DebugLogger.shared.saveLog(_MP_log, true);
			}, 5);
			return {
				...state,
				gameBoard: {
					...state.gameBoard,
					startPositions: newStartPositions,
					boardPositions: newBoardPositions,
				},
			};
		case ROLLBACK_MOVE:
			if (typeof payload === "undefined" || typeof payload.moveToRollback === "undefined") return state;

			var newStartPositions = [...state.gameBoard.startPositions],
				newBoardPositions = [...state.gameBoard.boardPositions],
				move = payload.moveToRollback;
			switch (move.type) {
				case "DOUBLE_FIRST":
				case "DOUBLE_SECOND":
				case "NORMAL":
				case "START":
					const playerPanel = state.gameBoard.playerPanels.find((pp) => pp.slotID == state.sitSlotID);
					const color = playerPanel != undefined ? playerPanel.color : { normal: "#", strong: "#", text: "#" };
					var curPos, newPos;
					if (move.curPos.toLowerCase().startsWith("s")) {
						curPos = newStartPositions.find((sp) => sp.pos == move.curPos.toLowerCase());
					} else {
						curPos = newBoardPositions.find((bp) => bp.id == move.curPos.toLowerCase());
					}
					if (move.newPos.toLowerCase().startsWith("s")) {
						newPos = newStartPositions.find((sp) => sp.pos == move.newPos.toLowerCase());
					} else {
						newPos = newBoardPositions.find((bp) => bp.id == move.newPos.toLowerCase());
					}
					if (typeof curPos !== "undefined") {
						curPos.filled = true;
						curPos.color = color;
					}
					if (typeof newPos !== "undefined") {
						if (move.isSmash) {
							if (typeof move.returnPos === "string" && move.returnPos.toLowerCase().startsWith("s")) {
								var returnPos = newStartPositions.find((sp) => sp.pos == move.returnPos.toLowerCase());
								if (typeof returnPos !== "undefined") {
									returnPos.filled = false;
									newPos.color = cloneDeep(returnPos.color);
									newPos.filled = true;
								}
							}
						} else {
							newPos.filled = false;
						}
					}
					break;
				case "SWITCH":
				case "SWAP":
					var curPos, newPos, curPosColor, newPosColor;
					if (move.curPos.toLowerCase().startsWith("s")) {
						curPos = newStartPositions.find((sp) => sp.pos == move.curPos.toLowerCase());
					} else {
						curPos = newBoardPositions.find((bp) => bp.id == move.curPos.toLowerCase());
					}

					if (move.newPos.toLowerCase().startsWith("s")) {
						newPos = newStartPositions.find((sp) => sp.pos == move.newPos.toLowerCase());
					} else {
						newPos = newBoardPositions.find((bp) => bp.id == move.newPos.toLowerCase());
					}

					if (curPos != undefined && newPos != undefined) {
						curPosColor = cloneDeep(newPos.color);
						newPosColor = cloneDeep(curPos.color);
						curPos.color = curPosColor;
						newPos.color = newPosColor;
					}
					break;
				default:
					break;
			}
			var newCards = state.cards,
				newCardCounts = state.cardCounts,
				newPlayedCards = state.gameBoard.playedCards;
			if (move.type != "DOUBLE_FIRST") {
				newCards = cloneDeep(state.cards);
				newCardCounts = cloneDeep(state.cardCounts);
				if (move.type != "THROW") {
					var cardToRollbackIndex = findLastIndex(newPlayedCards, (c) => c.id == move.card);
					if (cardToRollbackIndex > -1) {
						newPlayedCards = cloneDeep(state.gameBoard.playedCards);
						newPlayedCards.splice(cardToRollbackIndex, 1);
					}

					newCardCounts.forEach((cc) => {
						if (cc.slotID == state.sitSlotID) cc.count++;
					});
					newCards.push({
						id: move.card,
						playable: false,
						filtered: false,
						selected: false,
						dealed: true,
					});
				} else {
					// rollback the thrown cards
					if (Array.isArray(move.cards) && move.cards.length > 0) {
						newPlayedCards = cloneDeep(state.gameBoard.playedCards);
						move.cards.reverse().forEach((card) => {
							var cardToRollbackIndex = findLastIndex(newPlayedCards, (c) => c.id == card);
							if (cardToRollbackIndex > -1) {
								newPlayedCards.splice(cardToRollbackIndex, 1);
							}

							newCardCounts.forEach((cc) => {
								if (cc.slotID == state.sitSlotID) cc.count++;
							});
							newCards.push({
								id: card,
								playable: false,
								filtered: false,
								selected: false,
								dealed: true,
							});
						});
					}
				}
			}

			return {
				...state,
				moveToRollback: payload.moveToRollback,
				cards: newCards,
				cardCounts: newCardCounts,
				gameBoard: {
					...state.gameBoard,
					startPositions: newStartPositions,
					boardPositions: newBoardPositions,
					playedCards: newPlayedCards,
				},
			};
		case SET_MOVE_COUNT:
			if (typeof payload !== "number") return state;
			return { ...state, moveCnt: payload };
		case INCREASE_MOVE_COUNT:
			return { ...state, moveCnt: state.moveCnt + 1 };
		case RESET_MOVE_COUNT:
			return { ...state, moveCnt: initialState.moveCnt };
		case CLEAR_PLAYED_CARDS:
			return {
				...state,
				gameBoard: {
					...state.gameBoard,
					playedCards: initialState.gameBoard.playedCards,
				},
			};
		case START_LATE_MOVE_IN_SHOP:
			return { ...state, isInShopLateMove: true };
		case FINISH_LATE_MOVE_IN_SHOP:
			return { ...state, isInShopLateMove: false };
		case GAME_BOARD_RENDERED:
			return { ...state, boardLoaded: true };
		case SOCKET_CONNECTION_CLOSED:
			return { ...state, isNewGameStarted: false };
		case RESET_STARTED_GAME:
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};

const getPreloadedCard = (_card, _cardsetURL) => {
	const _cardImgURL = "https://" + _cardsetURL + _card.cardset + "/" + _card.size + "/" + _card.id + ".png";
	_card.cardImgURL = _cardImgURL;
	Image.getSize(
		_cardImgURL,
		(width, height) => {
			_card.itemImgDim = { width, height };
			_card.isLoaded = true;
			// if (DEBUG_ENABLED) console.log("CARD PRELOADED: ", _card);
		},
		(error) => {
			// handleError(new Error("Error loading image: " + iconImgSrc));
			_card.isLoaded = false;
			// if (DEBUG_ENABLED) console.log("CARD NOT PRELOADED: ", _card);
		}
	);
	return _card;
};
