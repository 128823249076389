import React, { Component } from "react";
import { FlatList, Image, Platform, StyleSheet, Text, View } from "react-native";
import { f_sourcesansprobold, f_sourcesansprolight, RESOLUTION } from "../../config/defaults";
import { ARROW, TEXT_INPUT } from "../../config/images";
import { mapObject } from "../../helpers/commonHelpers";
import { handleError } from "../ErrorHandler";
import { AppTouchableOpacity } from "./AppTouchableOpacity";
import { Box } from "./Box";

class CountryListDropDown extends Component {
	constructor(props) {
		super(props);
		const { initialSelected } = this.props;
		const _dataSource = this.getDataSource();
		var item = _dataSource.find((obj) => obj.countryID == initialSelected);
		this.state = {
			dataSource: _dataSource,
			show: false,
			selectedItem: item || null,
		};
	}

	//#region events
	onDropButtonPress() {
		try {
			this.setState({ show: !this.state.show });
			this.props.onToggle(!this.state.show);
		} catch (error) {
			handleError(error);
		}
	}

	onDropButtonBlur() {
		try {
			// this.setState({ show: false });
			this.props.onToggle(false);
		} catch (error) {
			handleError(error);
		}
	}

	onItemSelect(item) {
		try {
			if (this.state.selectedItem != item) {
				this.setState({ selectedItem: item });
				if (typeof this.props.onChange === "function") this.props.onChange(item.countryID);
			}
			this.setState({ show: false });
			this.props.onToggle(false);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderListRow(item) {
		try {
			const { dataSource, selectedItem } = this.state;
			const listWidth =
				dataSource.length > 10
					? this.props.resolution === RESOLUTION.HIGH
						? 400
						: 300
					: this.props.resolution === RESOLUTION.HIGH
					? 390
					: 290;
			const buttonStyle = selectedItem == item ? { backgroundColor: "#ddd" } : { backgroundColor: "#fff" };
			const selectItem = () => this.onItemSelect(item);
			var _textStyle = [styles.normalText, { paddingLeft: 6, paddingRight: 6 }];
			if (this.props.resolution === RESOLUTION.HIGH) {
				_textStyle = [styles.normalText, { fontSize: 26, paddingLeft: 6, paddingRight: 6 }];
			}
			return (
				<AppTouchableOpacity
					activeOpacity={1}
					style={[styles.itemButtonStyle, buttonStyle, { width: listWidth }]}
					onPress={selectItem}
				>
					<Text allowFontScaling={false} selectable={false} numberOfLines={1} ellipsizeMode="tail" style={_textStyle}>
						{item.name}
					</Text>
				</AppTouchableOpacity>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderDropDownList() {
		try {
			if (this.state.show) {
				const listWidth =
					this.state.dataSource.length > 10
						? this.props.resolution === RESOLUTION.HIGH
							? 400
							: 300
						: this.props.resolution === RESOLUTION.HIGH
						? 390
						: 290;
				return (
					<Box
						style={[
							styles.boxStyle,
							{
								maxHeight: this.props.resolution === RESOLUTION.HIGH ? 300 : 170,
								width: listWidth,
							},
						]}
					>
						<FlatList
							nestedScrollEnabled={true}
							keyExtractor={(item, index) => "cl_" + index.toString()}
							data={this.state.dataSource}
							// extraData={this.state.dataSource}
							initialNumToRender={10}
							renderItem={({ item }) => this.renderListRow(item)}
							getItemLayout={(item, index) => ({
								length: 40,
								offset: 40 * index,
								index,
							})}
							style={{
								backgroundColor: "#fff",
								position: "relative",
								zIndex: 1,
							}}
						/>
					</Box>
				);
			}
			return null;
		} catch (error) {
			handleError(error);
		}
	}

	render() {
		try {
			const { show, selectedItem } = this.state;
			var _textStyle = [styles.normalText, styles.selectedTextContainer];
			if (this.props.resolution === RESOLUTION.HIGH) {
				_textStyle = [
					styles.normalText,
					styles.selectedTextContainer,
					{ width: 360, fontSize: 26, paddingTop: 0, marginTop: 1 },
				];
			}
			return (
				<View style={styles.mainContainer}>
					<AppTouchableOpacity
						activeOpacity={1}
						onPress={this.onDropButtonPress.bind(this)}
						style={{
							flexDirection: "row",
							width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300,
						}}
						onBlur={this.onDropButtonBlur.bind(this)}
					>
						<Image
							source={TEXT_INPUT}
							resizeMode="stretch"
							style={{
								width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300,
								height: 50,
								position: "absolute",
								zIndex: -1,
							}}
						/>
						<Text allowFontScaling={false} selectable={false} numberOfLines={1} ellipsizeMode="tail" style={_textStyle}>
							{selectedItem !== null ? selectedItem.name : ""}
						</Text>
						<Image source={ARROW} style={!show ? [styles.dropIcon, styles.mirror] : styles.dropIcon} />
					</AppTouchableOpacity>
					{this.renderDropDownList()}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region helpers
	getDataSource() {
		try {
			const { countryList } = this.props.lang;
			var dataSource = [];
			mapObject(countryList, (key, value) => {
				var newItem = {};
				newItem.countryID = key;
				newItem.name = value;
				dataSource.push(newItem);
			});
			return dataSource;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: "row",
	},
	dropIcon: {
		width: 15,
		height: 10,
		marginLeft: 5,
		alignSelf: "center",
	},
	mirror: {
		transform: [{ rotate: "180deg" }],
	},
	boxStyle: {
		position: "absolute",
		top: 45,
		left: 0,
		minHeight: 40,
		maxHeight: 170,
		backgroundColor: "#fff",
	},
	itemButtonStyle: {
		backgroundColor: "#fff",
	},
	normalText: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		marginTop: Platform.OS === "web" ? 0 : 2,
		color: "#000",
	},
	selectedTextContainer: {
		fontFamily: f_sourcesansprolight,
		width: 260,
		fontSize: 20,
		borderRadius: 5,
		marginRight: 5,
		paddingTop: Platform.OS === "web" ? 0 : 2,
		paddingLeft: 7,
		paddingRight: 7,
		height: 50,
		lineHeight: 50,
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
});

export { CountryListDropDown };
