import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import {
	createGameHangWaitTime,
	f_sourcesansproregular,
	gameDetailsTableKey,
	isWebOrTablet,
	RESOLUTION,
} from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { GameRuleDetails } from "../../Keezers/components/common";
import {
	cancelNewGame,
	changeGameName,
	checkIfCanStartGame,
	displayNotification,
	displayPermissionDenied,
	goBackToMain,
	openModalDialog,
	renderedCreateGameButtons,
	resizeCreateGameButtons,
	updateNewGamesCardset,
	updateNewGamesPlayerLevel,
	updateNewGamesTheme,
	updatePlayerAmount,
	updateRequiredChips,
	updateSwitchBoxRule,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { HeaderText, NavigationHeader, Spinner } from "../common";
import { handleError } from "../ErrorHandler";
import GameShopStack from "../GameShop/GameShopStack";
import CreateGameButtons from "./CreateGameButtons";
import CreateNewGameTable from "./CreateNewGameTable";

class CreateNewGame extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isHelpExpanded: this.props.resolution !== RESOLUTION.LOW && isWebOrTablet,
			helpHeight: 0,
			isPlayerCntError: false,
			playerCntErrorMsg: "",
			isGameCreateHanging: false,
		};
		this.hangTimeout = null;
	}

	//#region lifecycle methods
	async componentDidMount() {
		this.hangTimeout = setTimeout(() => {
			if (this.props.publishGameJSON.gameID <= 0) {
				this.setState({ isGameCreateHanging: true });
			}
		}, createGameHangWaitTime);
		await Analytics.logScreenView("CreateNewGame");
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.publishGameJSON !== this.props.publishGameJSON &&
			typeof this.props.publishGameJSON.playerCnt === "number" &&
			typeof prevProps.publishGameJSON.playerCnt === "number" &&
			this.props.publishGameJSON.playerCnt != prevProps.publishGameJSON.playerCnt
		) {
			const permID = "playerAmount_" + this.props.publishGameJSON.playerCnt;
			const permission = this.checkPermission(permID);
			var errorMsg = "";
			if (typeof permission.rightID === "string" && permission.rightID !== "") {
				const { disabledFeatureText } = this.props;
				if (
					disabledFeatureText &&
					typeof disabledFeatureText[permission.rightID] === "string" &&
					disabledFeatureText[permission.rightID] != ""
				)
					errorMsg = disabledFeatureText[permission.rightID];
			}
			this.setState({
				isPlayerCntError: !permission.enabled,
				playerCntErrorMsg: errorMsg,
			});
		}
	}

	componentWillUnmount() {
		if (this.hangTimeout !== null) {
			clearTimeout(this.hangTimeout);
			this.hangTimeout = null;
		}
	}
	//#endregion

	//#region events
	onSwitchBoxCheck(group, isChecked, amount) {
		try {
			const permission = this.checkPermission(group);
			if (permission.enabled == true) {
				this.props.updateSwitchBoxRule(group, isChecked, null, amount, true);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onMainLayout() {
		try {
			this.props.checkIfCanStartGame();
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={() => this.props.cancelNewGame(this.state.isGameCreateHanging)}
				closeAction={() => this.props.cancelNewGame(this.state.isGameCreateHanging)}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.btnReturnToLobby}
				resolution={this.props.resolution}
			>
				<GameShopStack />
			</NavigationHeader>
		);
	}

	renderSpinner() {
		if (this.props.loadingNewGame) {
			return <Spinner size="large" />;
		}
		return null;
	}

	renderGameRuleDetails() {
		if (!this.props.loadingNewGame && this.props.newGamesRulesList.length != 0) {
			return (
				<GameRuleDetails
					requiredChipsAction={this.props.updateRequiredChips}
					switchBoxAction={this.onSwitchBoxCheck.bind(this)}
					playerAmountAction={this.props.updatePlayerAmount}
					playerAmount_options={this.props.playerAmount_options}
					playerLevelAction={this.props.updateNewGamesPlayerLevel}
					cardsetChangeAction={this.props.updateNewGamesCardset}
					gameThemeChangeAction={this.props.updateNewGamesTheme}
					checkPermission={this.checkPermission.bind(this)}
					onPermissionDenied={this.props.displayPermissionDenied}
					rulesList={this.props.newGamesRulesList}
					tableKey={gameDetailsTableKey.newGame}
					scrollViewHeight={this.props.slotSettingsHeight}
					onGameNameSubmit={this.props.changeGameName}
					notifAction={this.props.openModalDialog}
					notifMsgs={this.props.lang.messages}
					notifButtonText={this.props.lang.ok}
				/>
			);
		}
		return null;
	}

	renderContent() {
		if (!this.props.loadingNewGame) {
			return (
				<View style={[styles.gameDetailsContainer, { flexDirection: "column-reverse" }]}>
					{this.state.isHelpExpanded == true && (
						<View
							style={{
								flex: 1,
								borderTopWidth: 1,
								borderTopColor: "#0C6A7A",
								backgroundColor: "rgba(10, 95, 110,0.5)",
							}}
							onLayout={(event) => this.setState({ helpHeight: event.nativeEvent.layout.height })}
						>
							<Text
								allowFontScaling={false}
								style={{
									fontFamily: f_sourcesansproregular,
									fontSize: 18,
									color: "#FFFDF1",
									paddingHorizontal: 5,
									paddingTop: 3,
								}}
							>
								{this.props.lang.createNewGame.tips_tricks_text}
							</Text>
							{/* TODO: tips, tricks and help area */}
						</View>
					)}
					<CreateNewGameTable
						isGameCreator={true}
						tableKey={gameDetailsTableKey.newGame}
						isHelpExpanded={this.state.isHelpExpanded}
						hideHelp={() => this.setState({ isHelpExpanded: false })}
						helpHeight={this.state.helpHeight}
					/>
					{this.state.isPlayerCntError && (
						<View
							style={{
								width: "100%",
								position: "absolute",
								bottom: this.state.isHelpExpanded ? this.state.helpHeight : 0,
								left: 0,
								backgroundColor: "#b1dce1",
								borderWidth: 2,
								borderColor: "#aab4b5",
							}}
						>
							<Text
								allowFontScaling={false}
								style={{
									width: "100%",
									textAlign: "center",
									fontFamily: f_sourcesansproregular,
									fontSize: 24,
									color: "#820b0b",
									paddingHorizontal: 5,
									paddingVertical: 3,
								}}
							>
								{this.state.playerCntErrorMsg}
							</Text>
						</View>
					)}
				</View>
			);
		}
		return null;
	}

	renderGameDetails() {
		return (
			<View style={[styles.halfWidth, { marginLeft: 5 }]}>
				<View
					style={[
						{
							height: this.props.otherSettingsHeight,
							marginTop: this.props.resolution !== RESOLUTION.LOW ? 0 : 3,
						},
					]}
				>
					{this.props.resolution !== RESOLUTION.LOW && (
						<HeaderText
							textContent={this.props.lang.gameLobby.gameDetails.gameDetailsHeader}
							style={{ height: gameDimensions.boxHeaderHeight }}
						/>
					)}
					{this.renderSpinner()}
					{this.renderContent()}
				</View>
				<CreateGameButtons
					isPublishEnabled={!this.state.isPlayerCntError}
					style={{
						width: "100%",
						marginTop: this.props.resolution !== RESOLUTION.LOW ? 0 : -3,
					}}
				/>
			</View>
		);
	}

	renderSettings() {
		return (
			<View style={styles.halfWidth}>
				<View
					style={{
						flex: 1,
						height: this.props.slotSettingsHeight,
						padding: 3,
					}}
				>
					{this.renderSpinner()}
					{this.renderGameRuleDetails()}
				</View>
			</View>
		);
	}

	render() {
		const { isCreateNewGameOpen, isTabGamesButtonActive, isTabShopButtonActive } = this.props;
		if (!isCreateNewGameOpen || !isTabGamesButtonActive || isTabShopButtonActive) return null;

		return (
			<View style={styles.container} onLayout={this.onMainLayout.bind(this)}>
				{this.renderHeader()}
				<View style={styles.detailsContainer}>
					{this.renderSettings()}
					{this.renderGameDetails()}
				</View>
			</View>
		);
	}
	//#endregion

	//#region helpers
	checkPermission(filterGroup) {
		try {
			var result = { rightID: "", enabled: true };
			switch (filterGroup) {
				case "playerAmount_2":
					result.enabled = this.props.canPlayWith2Players;
					result.rightID = "canPlayWith2Players";
					break;
				case "playerAmount_3":
					result.enabled = this.props.canPlayWith3Players;
					result.rightID = "canPlayWith3Players";
					break;
				case "playerAmount_4":
					result.enabled = this.props.canPlayWith4Players;
					result.rightID = "canPlayWith4Players";
					break;
				case "playerAmount_5":
					result.enabled = this.props.canPlayWith5Players;
					result.rightID = "canPlayWith5Players";
					break;
				case "playerAmount_6":
					result.enabled = this.props.canPlayWith6Players;
					result.rightID = "canPlayWith6Players";
					break;
				case "playerAmount_7":
					result.enabled = this.props.canPlayWith7Players;
					result.rightID = "canPlayWith7Players";
					break;
				case "playerAmount_8":
					result.enabled = this.props.canPlayWith8Players;
					result.rightID = "canPlayWith8Players";
					break;
				case "gameName":
					result.enabled = this.props.canSetName;
					result.rightID = "canSetName";
					result.secRightID = "GAME_CREATE_CAN_SET_NAME";
					break;
				case "onlyFriends":
					result.enabled = this.props.canSetFriendsMode;
					result.rightID = "canSetFriendsMode";
					result.secRightID = "GAME_CREATE_CAN_SET_ONLY_FRIENDS";
					break;
				case "localLanguagesOnly":
					result.enabled = this.props.canSetLanguageMode;
					result.rightID = "canSetLanguageMode";
					result.secRightID = "GAME_CREATE_CAN_SET_LANGUAGE";
					break;
				case "localCountryOnly":
					result.enabled = this.props.canSetCountryMode;
					result.rightID = "canSetCountryMode";
					result.secRightID = "GAME_CREATE_CAN_SET_COUNTRY";
					break;
				case "playForChips":
				case "playForChipsAmount":
					result.enabled = this.props.canSetChips;
					result.rightID = "canSetChips";
					result.secRightID = "GAME_CREATE_CAN_SET_CHIPS";
					break;
				case "enableTeams":
					const { playerCnt } = this.props.publishGameJSON;
					const isPlayableInTeams = playerCnt != 2 && playerCnt % 2 == 0;
					result.enabled = this.props.canSetTeams && isPlayableInTeams;
					if (!this.props.canSetTeams) {
						result.rightID = "canSetTeams";
						result.secRightID = "GAME_CREATE_CAN_SET_TEAMS";
					} else if (!isPlayableInTeams) {
						result.rightID = "canSetTeams";
						result.secRightID = "GAME_CREATE_NOT_FOR_TEAMS";
					}
					break;
				case "minLevel":
				case "maxLevel":
					result.enabled = this.props.canSetPlayerLevel;
					result.rightID = "canSetPlayerLevel";
					result.secRightID = "GAME_CREATE_CAN_SET_PLAYER_LEVEL";
					break;
				case "cardset":
					result.enabled = this.props.canSetCardsets;
					result.rightID = "canSetCardsets";
					result.secRightID = "GAME_CREATE_CAN_SET_CARDSETS";
					break;
				case "gameTheme":
					result.enabled = this.props.canSetGameTheme;
					result.rightID = "canSetGameTheme";
					result.secRightID = "GAME_CREATE_CAN_SET_GAME_THEME";
					break;
				case "fairDeal":
					result.enabled = this.props.canSetFairDealing;
					result.rightID = "canSetFairDealing";
					result.secRightID = "GAME_CREATE_CAN_SET_FAIR_DEAL";
					break;
				case "privateGame":
					result.enabled = this.props.canSetPrivateGame;
					result.rightID = "canSetPrivateGame";
					result.secRightID = "GAME_CREATE_CAN_SET_PRIVATE_GAME";
					break;
			}
			return result;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { flex: 1 },
	detailsContainer: {
		flex: 1,
		flexDirection: "row",
		paddingHorizontal: 3,
		marginTop: 50,
		marginBottom: 3,
	},
	halfWidth: { flex: 2 },
	gameDetailsContainer: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
		overflow: "hidden",
	},
});

const mapStateToProps = (state) => {
	const { features } = state.currentUser;
	return {
		lang: state.language.currentLanguage,
		isCreateNewGameOpen: state.tab.isCreateNewGameOpen,
		isTabGamesButtonActive: state.tab.isTabGamesButtonActive,
		isTabShopButtonActive: state.tab.isTabShopButtonActive,
		loadingNewGame: state.createNewGame.loadingNewGame,
		publishGameJSON: state.createNewGame.publishGameJSON,
		newGamesRulesList: state.createNewGame.newGamesRulesList,
		playerAmount_options: state.createNewGame.playerAmount_options,
		slotSettingsWidth: state.views.slotSettingsWidth,
		slotSettingsHeight: state.views.slotSettingsHeight,
		slotSettingsLeftMargin: state.views.slotSettingsLeftMargin,
		otherSettingsWidth: state.views.otherSettingsWidth,
		otherSettingsHeight: state.views.otherSettingsHeight,
		otherSettingsLeftMargin: state.views.otherSettingsLeftMargin,
		isSmallResolution: state.views.isSmallResolution,
		isLandscape: state.views.isLandscape,
		createNewGameRulesHeight: state.views.createNewGameRulesHeight,
		canSetName: state.currentUser.features.generic.canSetName,
		canSetFriendsMode: state.currentUser.features.generic.canSetFriendsMode,
		canSetChips: state.currentUser.features.generic.canSetChips,
		canSetPlayerLevel: state.currentUser.features.generic.canSetPlayerLevel,
		canSetLanguageMode: state.currentUser.features.generic.canSetLanguageMode,
		canSetCountryMode: state.currentUser.features.generic.canSetCountryMode,
		canSetTeams: state.currentUser.features.specific.canSetTeams,
		canSetCardsets: state.currentUser.features.specific.canSetCardsets,
		canSetGameTheme: state.currentUser.features.specific.canSetGameTheme,
		canSetFairDealing: state.currentUser.features.specific.canSetFairDealing,
		canSetPrivateGame: state.currentUser.features.specific.canSetPrivateGame,
		canPlayWith2Players: state.currentUser.features.generic.canPlayWith2Players,
		canPlayWith3Players: state.currentUser.features.generic.canPlayWith3Players,
		canPlayWith4Players: state.currentUser.features.generic.canPlayWith4Players,
		canPlayWith5Players: state.currentUser.features.generic.canPlayWith5Players,
		canPlayWith6Players: state.currentUser.features.generic.canPlayWith6Players,
		canPlayWith7Players: state.currentUser.features.generic.canPlayWith7Players,
		canPlayWith8Players: state.currentUser.features.generic.canPlayWith8Players,
		disabledFeatureText: state.currentUser.features.disabledFeatureText,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {
	cancelNewGame,
	changeGameName,
	checkIfCanStartGame,
	displayNotification,
	displayPermissionDenied,
	goBackToMain,
	openModalDialog,
	renderedCreateGameButtons,
	resizeCreateGameButtons,
	updateNewGamesCardset,
	updateNewGamesPlayerLevel,
	updateNewGamesTheme,
	updatePlayerAmount,
	updateRequiredChips,
	updateSwitchBoxRule,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewGame);
