//#region App
export const MAINTENANCE_ICON = require("../assets/img/maintenance_icon.png").default;
export const GAME_LOADING = require("../assets/img/splash.jpg").default;
export const DEFAULT_BACK = require("../assets/img/app_background/default.jpg").default;
export const MENU_PLAY_BACK = require("../assets/img/app_background/menu_play.jpg").default;
export const SETTINGS_BACK = require("../assets/img/app_background/settings.jpg").default;
export const HELP_BACK = require("../assets/img/app_background/help.jpg").default;
// export const SHOP_BACK = require("../assets/img/app_background/shop.jpg").default
export const SHOP_BACK = require("../assets/img/app_background/rate.jpg").default;
export const RATE_BACK = require("../assets/img/app_background/rate.jpg").default;
export const SURVEY_BACK = require("../assets/img/app_background/survey.jpg").default;
export const BACKGROUND = require("../assets/img/app_background/background.jpg").default;
export const UNLOCK_BTN = require("../assets/img/button_bg/unlock.png").default;
export const BLUE_BTN = require("../assets/img/button_bg/blue.png").default;
export const GREEN_BTN = require("../assets/img/button_bg/green.png").default;
export const LIGHT_BLUE_BTN = require("../assets/img/button_bg/light_blue.png").default;
export const PURPLE_BTN = require("../assets/img/button_bg/purple.png").default;
export const RED_BTN = require("../assets/img/button_bg/red.png").default;
export const YELLOW_BTN = require("../assets/img/button_bg/yellow.png").default;
export const GREY_BTN = require("../assets/img/button_bg/grey.png").default;
export const YELLOW_LONG_BTN = require("../assets/img/button_bg/yellow_long.png").default;
export const PURPLE_LONG_BTN = require("../assets/img/button_bg/purple_long.png").default;
export const BLUE_LONG_BTN = require("../assets/img/button_bg/blue_long.png").default;
export const GREEN_LONG_BTN = require("../assets/img/button_bg/green_long.png").default;
export const RED_LONG_BTN = require("../assets/img/button_bg/red_long.png").default;
export const DEFAULT_BUTTON = require("../assets/img/button-rectangular_green-l-old-style.png").default;
export const SMALL_BUTTON = require("../assets/img/button-rectangular_green-s-old-style.png").default;
export const AJAX_BUSY = require("../assets/img/ajaxLoaderBig3.gif").default;
export const ARROW = require("../assets/img/pijltje.png").default;
export const TOGGLE_ICON = require("../assets/img/toggle_icon.png").default;
export const SORT_ASC = require("../assets/img/sort_asc_white.png").default;
export const SORT_DESC = require("../assets/img/sort_desc_white.png").default;
export const SWITCH_BACKGROUND = require("../assets/img/switch/switch_back.png").default;
export const SWITCH_ACTIVE = require("../assets/img/switch/option_active.png").default;
export const SWITCH_INACTIVE = require("../assets/img/switch/option_inactive.png").default;
export const COMP_SUPER_EASY = require("../assets/img/computerSuperEasy.png").default;
export const COMP_EASY = require("../assets/img/computerEasy.png").default;
export const COMP_MEDIUM = require("../assets/img/computerMedium.png").default;
export const COMP_HARD = require("../assets/img/computerHard.png").default;
export const COMP_VERY_HARD = require("../assets/img/computerVeryHard.png").default;
export const EDIT_GAME_NAME = require("../assets/img/editGameName.png").default;
export const CHAT_ICON = require("../assets/img/chatIcon.png").default;
export const MAIL_ICON = require("../assets/img/mailIcon.png").default;
export const BACK_ICON = require("../assets/img/backIcon.png").default;
export const SWITCH_ICON = require("../assets/img/chatSwitch.png").default;
export const CREATE_ICON = require("../assets/img/createChatroom.png").default;
export const MINUS_ICON = require("../assets/img/minus_icon.png").default;
export const PLUS_ICON = require("../assets/img/plus_icon.png").default;
export const PREVIOUS_ICON = require("../assets/img/previous.png").default;
export const NEXT_ICON = require("../assets/img/next.png").default;
export const UNREAD_MESSAGE = require("../assets/img/button-round_red.png").default;
export const CLOSE_CROSS = require("../assets/img/kruisje.png").default;
export const TEXT_INPUT = require("../assets/img/textbox/textbox.png").default;
export const TEXT_INPUT_ERROR = require("../assets/img/textbox/textbox_issue.png").default;
export const MENU_BACK_ICON = require("../assets/img/main_header/backIcon.png").default;
export const MENU_NEGATIVE_BACK_ICON = require("../assets/img/main_header/negative_back_icon.png").default;
export const BACK_ICON_DISABLED = require("../assets/img/main_header/backIcon_disabled.png").default;
export const MENU_CLOSE_ICON = require("../assets/img/main_header/closeIcon.png").default;
export const HEADER_CHIP_ICON = require("../assets/img/main_header/chipsIcon.png").default;
export const HEADER_CHIP_ICON_RED = require("../assets/img/main_header/red-chip.png").default;
export const HEADER_CHIP_ICON_DISABLED = require("../assets/img/main_header/chipIcon_disabled.png").default;
export const HEADER_SILVER_ICON = require("../assets/img/main_header/silverCoin.png").default;
export const HEADER_GOLD_ICON = require("../assets/img/main_header/goldCoin.png").default;
export const DEFAULT_IMG = require("../assets/img/default_pic.png").default;
//#endregion

//#region TabArea
export const TAB_CHAT = require("../assets/img/tab_button/chat_button.png").default;
export const TAB_LEADERBOARD = require("../assets/img/tab_button/leaderboard_button.png").default;
export const TAB_INBOX = require("../assets/img/tab_button/inbox_button.png").default;
export const TAB_PLAY = require("../assets/img/tab_button/play_button.png").default;
export const TAB_SHOP = require("../assets/img/tab_button/shop_button_new.png").default;
export const TAB_TOURNAMENT = require("../assets/img/tab_button/tournament_button.png").default;
//#endregion

//#region Chat
export const CHAT_MUTED = require("../assets/img/chatMute.png").default;
export const CHAT_UNMUTED = require("../assets/img/chatUnmute.png").default;
//#endregion

//#region shop images
export const ITEM_TITLE_BACK = require("../assets/img/game_shop/title_back.png").default;
export const ITEM_PRICE_SILVER = require("../assets/img/game_shop/silver_button.png").default;
export const ITEM_PRICE_GOLD = require("../assets/img/game_shop/gold_button.png").default;
export const ITEM_DEFAULT_IMAGE = require("../assets/img/game_shop/default_item.png").default;
export const SILVER_PRICE_TYLED = {
	border: {
		bottom: require("../assets/img/game_shop/silver_button/bottom_border.png").default,
		top: require("../assets/img/game_shop/silver_button/top_border.png").default,
		left: require("../assets/img/game_shop/silver_button/left_border.png").default,
		right: require("../assets/img/game_shop/silver_button/right_border.png").default,
	},
	corner: {
		bottom_left: require("../assets/img/game_shop/silver_button/bottom_left_corner.png").default,
		bottom_right: require("../assets/img/game_shop/silver_button/bottom_right_corner.png").default,
		top_left: require("../assets/img/game_shop/silver_button/top_left_corner.png").default,
		top_right: require("../assets/img/game_shop/silver_button/top_right_corner.png").default,
	},
	center: require("../assets/img/game_shop/silver_button/center.png").default,
};
export const GOLD_PRICE_TYLED = {
	border: {
		bottom: require("../assets/img/game_shop/gold_button/bottom_border.png").default,
		top: require("../assets/img/game_shop/gold_button/top_border.png").default,
		left: require("../assets/img/game_shop/gold_button/left_border.png").default,
		right: require("../assets/img/game_shop/gold_button/right_border.png").default,
	},
	corner: {
		bottom_left: require("../assets/img/game_shop/gold_button/bottom_left_corner.png").default,
		bottom_right: require("../assets/img/game_shop/gold_button/bottom_right_corner.png").default,
		top_left: require("../assets/img/game_shop/gold_button/top_left_corner.png").default,
		top_right: require("../assets/img/game_shop/gold_button/top_right_corner.png").default,
	},
	center: require("../assets/img/game_shop/gold_button/center.png").default,
};
//#endregion

//#region Started Game
export const chatImages = {
	activeButton: {
		fullButton: require("../assets/img/gamechat/activeChatButton/activeChatboxButton.png").default,
	},
	inactiveButton: {
		fullButton: require("../assets/img/gamechat/inactiveChatButton/inactiveChatboxButton.png").default,
	},
	closeButton: require("../assets/img/gamechat/24x24_close_button/Close_button.png").default,
	moreButton: require("../assets/img/gamechat/moreButton.png").default,
	sendButton: require("../assets/img/gamechat/sendButton.png").default,
	inputText: require("../assets/img/gamechat/typeTextPanel.png").default,
	chatMain: require("../assets/img/gamechat/backgroundChatTextPanel.png").default,
};
//#endregion

//#region DidYouKnow pages
export const GRAY_BUTTON = require("../assets/img/didyouknow/grayButton.png").default;
export const YELLOW_BUTTON = require("../assets/img/didyouknow/yellowButton.png").default;
export const PAGES = {
	COMPLETE_PROFILE: require("../assets/img/didyouknow/earnstuff/complete_profile_page.png").default,
	INVITE_FRIEND: require("../assets/img/didyouknow/earnstuff/invite_friend_page.png").default,
	QUESTIONNAIRE: require("../assets/img/didyouknow/earnstuff/questionaire_page.png").default,
	RATE_SOMETHING: require("../assets/img/didyouknow/earnstuff/rate_something_page.png").default,
	RATE_APP: require("../assets/img/didyouknow/earnstuff/rate_this_app_page.png").default,
	WATCH_VIDEO: require("../assets/img/didyouknow/earnstuff/watch_video_app_page.png").default,
	CHIP_TRAY: require("../assets/img/didyouknow/buystuff/chip_tray_page.png").default,
	CHIPS: require("../assets/img/didyouknow/buystuff/chips_page.png").default,
	CARDSETS: require("../assets/img/didyouknow/buystuff/extra_cardsets_page.png").default,
	GOLD_BUY: require("../assets/img/didyouknow/buystuff/gold_buy_page.png").default,
	BAN_LIST_UPDATE: require("../assets/img/didyouknow/buystuff/list_update_bans_page.png").default,
	FRIEND_LIST_UPADTE: require("../assets/img/didyouknow/buystuff/list_update_friends_page.png").default,
	MULTIPLIERS: require("../assets/img/didyouknow/buystuff/score_multipliers_page.png").default,
	SILVER_INVENTORY_UPGRADE: require("../assets/img/didyouknow/buystuff/silver_inventory_upgrade_page.png").default,
	SMILEYSET: require("../assets/img/didyouknow/buystuff/smileyset_page.png").default,
	VIP_BLACK: require("../assets/img/didyouknow/buystuff/VIP_black.png").default,
	VIP_BLUE: require("../assets/img/didyouknow/buystuff/VIP_blue.png").default,
	VIP_GREEN: require("../assets/img/didyouknow/buystuff/VIP_green.png").default,
	VIP_VIOLET: require("../assets/img/didyouknow/buystuff/VIP_violet.png").default,
};
//#endregion

//#region Navigation Menu
export const NAV_MENU_BACK = require("../assets/img/navmenu/navigation_menu_background.png").default;
export const OPEN_MENU_BUTTON = require("../assets/img/navmenu/menu_button.png").default;
export const CLOSE_MENU_BUTTON = require("../assets/img/navmenu/close_menu_button.png").default;
export const HELP_BUTTON = require("../assets/img/navmenu/info_button.png").default;
export const VOLUME_BUTTON = require("../assets/img/navmenu/volume_button.png").default;
export const MUTED_BUTTON = require("../assets/img/navmenu/muted_button.png").default;
export const SETTINGS_BUTTON = require("../assets/img/navmenu/settings_button.png").default;
export const EXIT_BUTTON = require("../assets/img/navmenu/exit_game_button.png").default;
export const SELECTION_HIGHLIGHT =
	require("../assets/img/navmenu/background_of_selection_highlight_button.png").default;
export const RELOAD_BUTTON = require("../assets/img/navmenu/reload_button.png").default;
export const SHOP_BUTTON = require("../assets/img/navmenu/shop_button.png").default;
//#endregion

//#region GameEnd screen
export const LOST_CROWN = require("../assets/img/gameend/lose_crown.png").default;
export const WON_CROWN = require("../assets/img/gameend/won_crown.png").default;
export const WIN_BACKGROUND = require("../assets/img/gameend/Win_Background.png").default;
export const LOSE_BACKGROUND = require("../assets/img/gameend/Lose_Background.png").default;
export const EXIT_BUTTON_BACK = require("../assets/img/gameend/Exit_button_background.png").default;
export const EXIT_BUTTON_TEXT = require("../assets/img/gameend/Exit_button_text.png").default;
export const RESULTS_TABLE = require("../assets/img/gameend/Table.png").default;
export const BUY_BOOSTERS_BUTTON = require("../assets/img/gameend/Buy_boosters_button.png").default;
export const SILVER_ICON = require("../assets/img/gameend/Silver_icon.png").default;
//#endregion

//#region LeaderBoards
export const ACTIVE_CATEGORY = require("../assets/img/leaderboard/active_category.png").default;
export const INACTIVE_CATEGORY = require("../assets/img/leaderboard/inactive_category.png").default;
export const ACTIVE_GROUP = require("../assets/img/leaderboard/active_group.png").default;
export const INACTIVE_GROUP = require("../assets/img/leaderboard/inactive_group.png").default;
//#endregion

//#region GameRoom
export const lobbyChat = {
	tabArea: {
		normal: {
			back: {
				border: {
					bottom: require("../assets/img/lobbychat/tab_area/back/bottom_border.png").default,
					top: require("../assets/img/lobbychat/tab_area/back/top_border.png").default,
					left: require("../assets/img/lobbychat/tab_area/back/left_border.png").default,
					right: require("../assets/img/lobbychat/tab_area/back/right_border.png").default,
				},
				corner: {
					bottom_left: require("../assets/img/lobbychat/tab_area/back/bottom_left_corner.png").default,
					bottom_right: require("../assets/img/lobbychat/tab_area/back/bottom_right_corner.png").default,
					top_left: require("../assets/img/lobbychat/tab_area/back/top_left_corner.png").default,
					top_right: require("../assets/img/lobbychat/tab_area/back/top_right_corner.png").default,
				},
				center: require("../assets/img/lobbychat/tab_area/back/center.png").default,
			},
			activeTab: {
				border: {
					top: require("../assets/img/lobbychat/tab_area/active/top_border.png").default,
					bottom: require("../assets/img/lobbychat/tab_area/active/bottom_border.png").default,
					left: require("../assets/img/lobbychat/tab_area/active/left_border.png").default,
					right: require("../assets/img/lobbychat/tab_area/active/right_border.png").default,
				},
				corner: {
					bottom_left: require("../assets/img/lobbychat/tab_area/active/bottom_left_corner.png").default,
					bottom_right: require("../assets/img/lobbychat/tab_area/active/bottom_right_corner.png").default,
					top_left: require("../assets/img/lobbychat/tab_area/active/top_left_corner.png").default,
					top_right: require("../assets/img/lobbychat/tab_area/active/top_right_corner.png").default,
				},
				center: require("../assets/img/lobbychat/tab_area/active/center.png").default,
			},
			inactiveTab: {
				border: {
					top: require("../assets/img/lobbychat/tab_area/inactive/top_border.png").default,
					bottom: require("../assets/img/lobbychat/tab_area/inactive/bottom_border.png").default,
					left: require("../assets/img/lobbychat/tab_area/inactive/left_border.png").default,
					right: require("../assets/img/lobbychat/tab_area/inactive/right_border.png").default,
				},
				corner: {
					bottom_left: require("../assets/img/lobbychat/tab_area/inactive/bottom_left_corner.png").default,
					bottom_right: require("../assets/img/lobbychat/tab_area/inactive/bottom_right_corner.png").default,
					top_left: require("../assets/img/lobbychat/tab_area/inactive/top_left_corner.png").default,
					top_right: require("../assets/img/lobbychat/tab_area/inactive/top_right_corner.png").default,
				},
				center: require("../assets/img/lobbychat/tab_area/inactive/center.png").default,
			},
			previous: {
				arrow: require("../assets/img/lobbychat/tab_area/left/arrow.png").default,
				border: {
					top: require("../assets/img/lobbychat/tab_area/left/top_border.png").default,
					bottom: require("../assets/img/lobbychat/tab_area/left/bottom_border.png").default,
					left: require("../assets/img/lobbychat/tab_area/left/left_border.png").default,
					right: require("../assets/img/lobbychat/tab_area/left/right_border.png").default,
				},
				corner: {
					bottom_left: require("../assets/img/lobbychat/tab_area/left/bottom_left_corner.png").default,
					bottom_right: require("../assets/img/lobbychat/tab_area/left/bottom_right_corner.png").default,
					top_left: require("../assets/img/lobbychat/tab_area/left/top_left_corner.png").default,
					top_right: require("../assets/img/lobbychat/tab_area/left/top_right_corner.png").default,
				},
				center: require("../assets/img/lobbychat/tab_area/left/center.png").default,
			},
			next: {
				arrow: require("../assets/img/lobbychat/tab_area/right/arrow.png").default,
				border: {
					top: require("../assets/img/lobbychat/tab_area/right/top_border.png").default,
					bottom: require("../assets/img/lobbychat/tab_area/right/bottom_border.png").default,
					left: require("../assets/img/lobbychat/tab_area/right/left_border.png").default,
					right: require("../assets/img/lobbychat/tab_area/right/right_border.png").default,
				},
				corner: {
					bottom_left: require("../assets/img/lobbychat/tab_area/right/bottom_left_corner.png").default,
					bottom_right: require("../assets/img/lobbychat/tab_area/right/bottom_right_corner.png").default,
					top_left: require("../assets/img/lobbychat/tab_area/right/top_left_corner.png").default,
					top_right: require("../assets/img/lobbychat/tab_area/right/top_right_corner.png").default,
				},
				center: require("../assets/img/lobbychat/tab_area/right/center.png").default,
			},
		},
	},
	background: {
		border: {
			bottom: require("../assets/img/lobbychat/background/bottom_border.png").default,
			top: require("../assets/img/lobbychat/background/top_border.png").default,
			left: require("../assets/img/lobbychat/background/left_border.png").default,
			right: require("../assets/img/lobbychat/background/right_border.png").default,
		},
		corner: {
			bottom_left: require("../assets/img/lobbychat/background/bottom_left_corner.png").default,
			bottom_right: require("../assets/img/lobbychat/background/bottom_right_corner.png").default,
			top_left: require("../assets/img/lobbychat/background/top_left_corner.png").default,
			top_right: require("../assets/img/lobbychat/background/top_right_corner.png").default,
		},
		center: require("../assets/img/lobbychat/background/center.png").default,
	},
};
//#endregion
