import React from "react";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import { f_sourcesansprosemibolditalic, isTablet } from "../../config/defaults";
import { dataTableHeaderMinHeight } from "../../config/dimensions";
import { MINUS_ICON, PLUS_ICON, SORT_ASC, SORT_DESC } from "../../config/images";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

const TableHeaderCell = (props) => {
	const {
		containerStyle,
		textStyle,
		arrowContainerStyle,
		onPress,
		isLowResolution,
		isSelected,
		isAscending,
		isSortable,
		cellContent,
		enableIncreaseDecrease,
		enableAdd,
		enableRemove,
		addAction,
		removeAction,
		onLayout,
	} = props;

	const renderSortArrow = () => {
		if (isSortable) {
			if (isSelected) {
				if (isAscending) {
					return (
						<View style={[styles.arrowContainer, arrowContainerStyle || {}]}>
							<Image source={SORT_ASC} style={styles.sortArrow} />
						</View>
					);
				}
				return (
					<View style={[styles.arrowContainer, arrowContainerStyle || {}]}>
						<Image source={SORT_DESC} style={styles.sortArrow} />
					</View>
				);
			}
		}
		return null;
	};

	const renderAddButton = () => {
		if (enableIncreaseDecrease) {
			return (
				<AppTouchableOpacity
					disabled={!enableAdd}
					onPress={addAction}
					style={[
						styles.addRemoveIcon,
						styles.addRemoveButton,
						{
							opacity: enableAdd ? 1 : 0.5,
							width: 30,
							height: 30,
							// width: isTablet ? (!isLowResolution ? 30 : 22) : 20,
							// height: isTablet ? (!isLowResolution ? 30 : 22) : 20,
							alignSelf: !isLowResolution ? "center" : "flex-end",
						},
					]}
					accessibilityState={{ disabled: !enableAdd }}
					accessibilityLabel={"increase the player amount"}
				>
					<Image
						source={PLUS_ICON}
						style={[
							styles.addRemoveIcon,
							{
								width: 30,
								height: 30,
								// width: isTablet ? (!isLowResolution ? 30 : 22) : 20,
								// height: isTablet ? (!isLowResolution ? 30 : 22) : 20,
								alignSelf: !isLowResolution ? "center" : "flex-end",
							},
						]}
						resizeMode="contain"
					/>
				</AppTouchableOpacity>
			);
		}
		return null;
	};

	const renderRemoveButton = () => {
		if (enableIncreaseDecrease) {
			return (
				<AppTouchableOpacity
					disabled={!enableRemove}
					onPress={removeAction}
					style={[
						styles.addRemoveIcon,
						styles.addRemoveButton,
						{
							opacity: enableRemove ? 1 : 0.5,
							width: 30,
							height: 30,
							// width: isTablet ? (!isLowResolution ? 30 : 22) : 20,
							// height: isTablet ? (!isLowResolution ? 30 : 22) : 20,
							alignSelf: !isLowResolution ? "center" : "flex-end",
						},
					]}
					accessibilityState={{ disabled: !enableAdd }}
					accessibilityLabel={"decrease the player amount"}
				>
					<Image
						source={MINUS_ICON}
						style={[
							styles.addRemoveIcon,
							{
								width: 30,
								height: 30,
								// width: isTablet ? (!isLowResolution ? 30 : 22) : 20,
								// height: isTablet ? (!isLowResolution ? 30 : 22) : 20,
								alignSelf: !isLowResolution ? "center" : "flex-end",
							},
						]}
						resizeMode="contain"
					/>
				</AppTouchableOpacity>
			);
		}
		return null;
	};

	let contentStyle = [[styles.text, textStyle || {}, isLowResolution && { alignSelf: "flex-end" }]];
	contentStyle =
		Platform.OS === "web"
			? isSortable
				? [...contentStyle, { cursor: "pointer" }]
				: [...contentStyle, { cursor: "default" }]
			: contentStyle;

	if (typeof onPress === "function") {
		return (
			<AppTouchableOpacity
				onPress={onPress}
				style={[styles.container, containerStyle || {}]}
				activeOpacity={0.8}
				accessibilityLabel={"sort by " + cellContent + (isAscending ? "ascending" : "descending")}
			>
				{renderSortArrow()}
				<Text allowFontScaling={false} selectable={false} style={contentStyle}>
					{cellContent}
				</Text>
			</AppTouchableOpacity>
		);
	}
	return (
		<View style={[styles.container, containerStyle || {}]} onLayout={onLayout}>
			{renderSortArrow()}
			{/* {enableIncreaseDecrease && (
        <View style={[styles.arrowContainer, { justifyContent: "space-between" }]}>
          {renderRemoveButton()}
          {renderAddButton()}
        </View>
      )} */}
			{enableIncreaseDecrease && renderRemoveButton()}
			<Text allowFontScaling={false} selectable={false} style={contentStyle}>
				{cellContent}
			</Text>
			{enableIncreaseDecrease && renderAddButton()}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
		alignContent: "center",
		minHeight: dataTableHeaderMinHeight,
		borderRightWidth: /* Platform.OS === "web" ? 0 : */ 1,
		borderRightColor: /* Platform.OS === "web" ? "transparent" : */ "#22C1E4",
	},
	text: {
		color: "#FFFDF1",
		fontFamily: f_sourcesansprosemibolditalic,
		fontSize: isTablet ? 20 : 14,
		// marginTop: Platform.OS === "web" ? "auto" : undefined,
		// marginBottom: Platform.OS === "web" ? "auto" : undefined,
		alignSelf: "center",
	},
	arrowContainer: {
		width: "100%",
		height: "100%",
		flexDirection: "row",
		justifyContent: "flex-end",
		position: "absolute",
		backgroundColor: "transparent",
		zIndex: 0,
	},
	sortArrow: {
		width: 19,
		height: 19,
		marginTop: "auto",
		marginBottom: "auto",
	},
	addRemoveIcon: {
		width: isTablet ? 30 : 20,
		height: isTablet ? 30 : 20,
	},
	addRemoveButton: {
		marginLeft: 10,
		marginRight: 10,
		paddingTop: 2,
		alignSelf: "center",
	},
});

export { TableHeaderCell };
