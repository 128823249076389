import Analytics from "../../components/Analytics/Analytics";
import { MyWebSocket } from "../../connection";
import DebugLogger from "../../controller/DebugLogger";
import { store } from "../../redux/store";
import {
	CHANGE_GAME_NAME,
	CHECK_IF_CAN_START_GAME,
	MOUSE_ENTER_NEW_GAMES_SLOT,
	MOUSE_LEAVE_NEW_GAMES_SLOT,
	PREPARE_NEW_GAMES_RULES_LIST,
	PREPARE_PUBLISH_GAME_JSON,
	RESET_CREATE_NEW_GAME,
	SAVE_AVAILABLE_BOTS,
	SELECT_NEW_GAMES_SLOT,
	SET_AD_UNLOCK_OPTION,
	SET_USER_AS_GAME_CREATOR,
	UPDATE_NATIVE_SWITCH_RULE,
	UPDATE_NEW_GAMES_CARDSET,
	UPDATE_NEW_GAMES_PLAYERS,
	UPDATE_NEW_GAMES_PLAYER_AMOUNT,
	UPDATE_NEW_GAMES_PLAYER_LEVEL,
	UPDATE_NEW_GAMES_SLOTS,
	UPDATE_NEW_GAMES_THEME,
	UPDATE_PLAYER_AMOUNT_OPTIONS,
	UPDATE_REQUIRED_CHIPS,
	UPDATE_SWITCHBOX_RULE,
} from "./actionTypes";
import { initPlayersInGameRoom } from "./GameRoomActions";
import { openGameLobby, openGameRoom, openStartedGame } from "./NavigationActions";

export const cancelNewGame = (isGameCreateHanging) => {
	return async (dispatch) => {
		const { publishGameJSON } = store.getState().createNewGame;
		if (publishGameJSON.gameID > 0) {
			MyWebSocket.shared.sendMsg({
				type: "leaveGame",
				sMessageID: 0,
				gameID: publishGameJSON.gameID,
			});
			DebugLogger.shared.storedForReset = [];
			await Analytics.logEvent("finishedGame");
		} else {
			if (store.getState().tab.isCreateNewGameOpen && isGameCreateHanging) {
				dispatch(openGameLobby());
				dispatch(resetCreateNewGame());
			}
		}
	};
};

export const publishGame = (gameID, gameName, players, slots, cardset) => {
	return (dispatch) => {
		dispatch(openGameRoom(gameID, gameName, cardset));
		dispatch(initPlayersInGameRoom(players, slots));
		if (store.getState().createNewGame.canStartGame == true) dispatch(openStartedGame());
	};
};

export const saveAvailableBots = (bots) => {
	return { type: SAVE_AVAILABLE_BOTS, payload: { bots } };
};

export const preparePublishGameJSON = (msg) => {
	return (dispatch) => {
		dispatch(
			_preparePublishGameJSON({
				...msg,
				userDetails: store.getState().currentUser.userDetails,
			})
		);
		dispatch(setUserAsGameCreator());
		dispatch(updateNewGamesSlots());
		dispatch(prepareNewGamesRulesList(msg));
		dispatch(setAdUnlockOption(msg));
	};
};

export const setAdUnlockOption = (msg) => {
	return { type: SET_AD_UNLOCK_OPTION, payload: msg };
};

export const updateNewGamesSlots = () => {
	const lang = store.getState().language.currentLanguage;
	const open_lang = lang.openSlot;
	const team_lang = lang.teamPrefix;
	return (dispatch) => {
		dispatch({
			type: UPDATE_NEW_GAMES_SLOTS,
			payload: { open_lang, team_lang },
		});
	};
};

export const setUserAsGameCreator = () => {
	return { type: SET_USER_AS_GAME_CREATOR };
};

export const changeGameName = (newName) => {
	return (dispatch) => {
		dispatch(_changeGameName(newName));
	};
};

export const selectNewGamesSlot = (slotID) => {
	return { type: SELECT_NEW_GAMES_SLOT, payload: slotID };
};

export const mouseEnterNewGamesSlot = (slotID) => {
	return { type: MOUSE_ENTER_NEW_GAMES_SLOT, payload: slotID };
};

export const mouseLeaveNewGamesSlot = (slotID) => {
	return { type: MOUSE_LEAVE_NEW_GAMES_SLOT, payload: slotID };
};

export const prepareNewGamesRulesList = (msg) => {
	const enableFiveOrMorePlayersGame = store.getState().views.enableFiveOrMorePlayersGame;
	const features = store.getState().currentUser.features;
	const lang = store.getState().language.currentLanguage;
	const isVip = store.getState().currentUser.userDetails.isVip;
	return {
		type: PREPARE_NEW_GAMES_RULES_LIST,
		payload: {
			lang,
			enableFiveOrMorePlayersGame,
			features,
			gameDetailsData: msg.data,
			isVip,
		},
	};
};

export const updatePlayerAmountOptions = (enableFiveOrMorePlayersGame) => {
	return (dispatch) => {
		dispatch(_updatePlayerAmountOptions(enableFiveOrMorePlayersGame));
		dispatch(updateNewGamesSlots());
	};
};

export const updateSwitchBoxRule = (group, val, isChecked, amount, userChoice) => {
	return (dispatch) => {
		dispatch(_updateNativeSwitchRule(group, val, amount, userChoice));
		if (group == "enableTeams") dispatch(updateNewGamesSlots());
	};
};

export const updateRequiredChips = (newValue) => {
	return (dispatch) => {
		dispatch(_updateRequiredChips(newValue));
	};
};

export const updatePlayerAmount = (newValue) => {
	return (dispatch) => {
		dispatch(_updatePlayerAmount(newValue));
		dispatch(updateNewGamesSlots());
	};
};

export const updateNewGamesPlayerLevel = (rule, value) => {
	return { type: UPDATE_NEW_GAMES_PLAYER_LEVEL, payload: { rule, value } };
};

export const updateNewGamesCardset = (carsetID) => {
	return { type: UPDATE_NEW_GAMES_CARDSET, payload: carsetID };
};

export const updateNewGamesTheme = (gameThemeID) => {
	return { type: UPDATE_NEW_GAMES_THEME, payload: gameThemeID };
};

export const updateNewGamesPlayers = (selectedSlotID, selectedPlayerItem, currentPlayerCnt) => {
	return (dispatch) => {
		const lang = store.getState().language.currentLanguage;
		if (selectedPlayerItem.label == lang.closed) {
			dispatch(updatePlayerAmount(currentPlayerCnt - 1));
		} else {
			dispatch(_updateNewGamesPlayers(selectedSlotID, selectedPlayerItem));
		}
	};
};

export const checkIfCanStartGame = () => {
	return { type: CHECK_IF_CAN_START_GAME };
};

export const resetCreateNewGame = () => {
	return { type: RESET_CREATE_NEW_GAME };
};

const _updateNewGamesPlayers = (selectedSlotID, selectedPlayerItem) => {
	const lang = store.getState().language.currentLanguage;
	var l = {
		openSlot: lang.openSlot,
		closed: lang.closed,
		computerLabel: lang.computerLabel,
		easy: "EASY",
		very_easy: "VERY_EASY",
		medium: "MEDIUM",
		hard: "HARD",
		very_hard: "VERY_HARD",
	};
	return {
		type: UPDATE_NEW_GAMES_PLAYERS,
		payload: { selectedSlotID, selectedPlayerItem, lang: l },
	};
};

const _updatePlayerAmount = (newValue) => {
	return { type: UPDATE_NEW_GAMES_PLAYER_AMOUNT, payload: newValue };
};

const _updateSwitchBoxRule = (group, val, isChecked) => {
	return {
		type: UPDATE_SWITCHBOX_RULE,
		payload: { group, val, isChecked },
	};
};

const _preparePublishGameJSON = (msg) => {
	return { type: PREPARE_PUBLISH_GAME_JSON, payload: msg };
};

const _changeGameName = (newName) => {
	return { type: CHANGE_GAME_NAME, payload: newName };
};

const _updatePlayerAmountOptions = (enableFiveOrMorePlayersGame) => {
	return {
		type: UPDATE_PLAYER_AMOUNT_OPTIONS,
		payload: { enableFiveOrMorePlayersGame },
	};
};

const _updateRequiredChips = (newValue) => {
	const lang = store.getState().language.currentLanguage;
	return { type: UPDATE_REQUIRED_CHIPS, payload: { newValue, lang } };
};

const _updateNativeSwitchRule = (group, val, amount, userChoice) => {
	return {
		type: UPDATE_NATIVE_SWITCH_RULE,
		payload: { group, val, amount: amount || 0, userChoice },
	};
};
