import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, processState, RESOLUTION } from "../../../../config/defaults";
import { gameDimensions, updateLanguageDimensions } from "../../../../config/dimensions";
import { languages } from "../../../../languages/gen_lang";
import { clearUpdateLanguageError, closeUpdateLanguage, goBackToMain, updateLanguage } from "../../../../redux/actions";
import { ColoredButton, NavigationHeader, Spinner } from "../../../common";
import { LanguageDropDown } from "../../../common/LanguageDropDown";
import { handleError } from "../../../ErrorHandler";
import Analytics from "./../../../Analytics/Analytics";

class UpdateLanguage extends Component {
	constructor(props) {
		super(props);
		const _lang = this.props.profileSettings.language.toLowerCase();
		this.state = {
			contentScale: this.getContentScale(),
			language: _lang,
			newLangName: languages[_lang] || "",
			isFormValid: true,
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		Analytics.logScreenView("UpdateProfileLanguage");
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.updateLanguageState != this.props.updateLanguageState &&
			!prevProps.updateLanguageSucceeded &&
			!this.props.updateLanguageSucceeded
		) {
			this.setState({ language: prevState.language });
		}
		if (prevProps.updateLanguageError != this.props.updateLanguageError) {
			this.setState({ isFormValid: false });
		}
	}
	//#endregion

	//#region events
	onUpdatePress() {
		this.props.updateLanguage(this.state.language);
	}

	onLanguageChange(langID, name) {
		this.setState({ language: langID, newLangName: name });
		this.props.clearUpdateLanguageError();
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateLanguageState != processState.STARTED}
				backAction={this.props.closeUpdateLanguage}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={!this.state.isFormValid}
					additionalTextStyle={_buttonTextStyle}
				/>
			</View>
		);
	}

	renderInfo() {
		var _infoStyle = [styles.dataText, styles.infoText];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_infoStyle = [styles.dataText, styles.infoText, { fontSize: 24 }];
		}
		return (
			<View style={Platform.OS === "web" ? { width: "100%", marginTop: 80 } : { width: "100%" }}>
				<Text allowFontScaling={false} style={_infoStyle} selectable={false}>
					{this.props.lang.languageUpdate.info}
				</Text>
			</View>
		);
	}

	renderLanguageField() {
		var _innerContainerStyle = styles.dataInnerContainer,
			_inputLabel = [styles.dataTitle, styles.dataText];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { height: 80 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
		}
		return (
			<View
				key="inputContainer"
				style={[
					_innerContainerStyle,
					Platform.OS === "web"
						? { position: "absolute", top: 0, zIndex: 5, alignSelf: "center" }
						: Platform.OS === "ios"
						? { zIndex: 5 }
						: {},
				]}
			>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.languageUpdate.langTitle}
				</Text>
				<LanguageDropDown
					initialSelected={this.state.language}
					onChange={this.onLanguageChange.bind(this)}
					resolution={this.props.resolution}
				/>
			</View>
		);
	}

	renderLanguageError() {
		const errorMsg =
			this.props.updateLanguageError != "" ? this.props.updateLanguageError : this.props.lang.languageUpdate.error;
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataErrorContainer}>
				<Text allowFontScaling={false} style={_errorStyle}>
					{errorMsg}
				</Text>
			</View>
		);
	}

	renderGoBactToMain() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = { paddingTop: 13 };
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.props.goBackToMain}
					textContent={this.props.lang.backToMain}
					accessibilityLabel={this.props.lang.backToMain}
					color={buttonColor.GREY}
					additionalTextStyle={_buttonTextStyle}
					disabled={false}
				/>
			</View>
		);
	}

	renderContent() {
		if (!this.props.updateLanguageSucceeded) {
			return (
				<View
					style={[
						styles.contentContainer,
						// { transform: [{ scale: this.state.contentScale }] },
					]}
				>
					{this.renderLanguageField()}
					{this.renderInfo()}
					{((this.props.updateLanguageState == processState.FINISHED && !this.props.updateLanguageSucceeded) ||
						!this.state.isFormValid) &&
						this.renderLanguageError()}
					{this.renderUpdateButton()}
				</View>
			);
		} else {
			const { languageUpdated } = this.props.lang.languageUpdate;
			const _text = languageUpdated.text.replace("#1#", this.state.newLangName);
			var _titleStyle = styles.titleStyle,
				_infoStyle = styles.contentText;
			if (this.props.resolution === RESOLUTION.HIGH) {
				_titleStyle = [styles.titleStyle, { fontSize: 32 }];
				_infoStyle = [styles.contentText, { fontSize: 26 }];
			}
			return (
				<View style={styles.contentContainer}>
					<View style={styles.titleContainer}>
						<Text allowFontScaling={false} style={_titleStyle}>
							{languageUpdated.title}
						</Text>
					</View>
					<ScrollView
						style={[
							styles.sentContentContainer,
							{
								height: Dimensions.get("window").height - 100,
							},
						]}
					>
						<Text allowFontScaling={false} style={_infoStyle}>
							{_text}
						</Text>
					</ScrollView>
					{this.renderGoBactToMain()}
				</View>
			);
		}
	}

	render() {
		if (this.props.updateLanguageState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (updateLanguageDimensions.width > availableWidth || updateLanguageDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / updateLanguageDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / updateLanguageDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: { flex: 1, padding: 20, paddingTop: 55 },
	contentContainer: { flex: 1, paddingHorizontal: 20 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: 26,
		marginTop: 20,
		marginBottom: 20,
		fontSize: 18,
	},
	dataErrorContainer: {
		width: "100%",
		height: 40,
		marginTop: 3,
		marginBottom: 3,
		paddingLeft: 10,
		justifyContent: "flex-end",
		alignItems: "flex-end",
		alignSelf: "flex-end",
	},
	fieldError: {
		width: 500,
		height: "100%",
		color: "#FE1F01",
		textAlign: "right",
	},
	titleContainer: {
		width: "85%",
		height: 50,
		alignSelf: "center",
	},
	titleStyle: {
		width: "100%",
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 24,
		textAlign: "center",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	sentContentContainer: {
		paddingHorizontal: 50,
		marginVertical: 5,
	},
	contentText: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansprobold,
		fontSize: 18,
		marginTop: 20,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		profileSettings: state.currentUser.profileSettings,
		updateLanguageSucceeded: state.app.updateLanguageSucceeded,
		updateLanguageState: state.app.updateLanguageState,
		updateLanguageError: state.app.updateLanguageError,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	clearUpdateLanguageError,
	closeUpdateLanguage,
	goBackToMain,
	updateLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLanguage);
