import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { RESOLUTION } from "../../config/defaults";
import { PlayedCard } from "./common";

class PlayedCardsArea extends Component {
	//#region render methods
	render() {
		var _playedCards = [];
		var cardsetSize = "l";
		switch (this.props.resolution) {
			case RESOLUTION.LOW:
				cardsetSize = "s";
				break;
			case RESOLUTION.MEDIUM:
				cardsetSize = "m";
				break;
			case RESOLUTION.HIGH:
				cardsetSize = "l";
				break;
		}
		this.props.playedCards.forEach((c, ind) =>
			_playedCards.push(
				<PlayedCard
					key={"playedCard_" + c.id + "_" + ind}
					cardsetURL={this.props.cardsetURL}
					cardset={this.props.cardset}
					cardsetSize={cardsetSize}
					playedCardsGlobal={this.props.playedCardsGlobal}
					card={c}
					preloadedCard={this.props.preloadedCards[this.props.cardset][cardsetSize][c.id]}
				/>
			)
		);
		return <View key="playedCardsContainer">{_playedCards}</View>;
		//#endregion
	}
}

const mapStateToProps = (state) => {
	return {
		playedCardsGlobal: state.startedGame.gameBoard.playedCardsGlobal,
		playedCards: state.startedGame.gameBoard.playedCards,
		cardset: state.startedGame.cardset,
		cardsetURL: state.app.welcome.webResources.cardsets,
		preloadedCards: state.startedGame.preloadedCards,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlayedCardsArea);
