import React, { Component } from "react";
import { Dimensions, FlatList, Platform, SafeAreaView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import {
	f_sourcesansproregular,
	f_sourcesansprosemibolditalic,
	isWebOrTablet,
	RESOLUTION,
} from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import * as helpers from "../../helpers/commonHelpers";
import Analytics from "../Analytics/Analytics";
import { HeaderText, TableRow, TableRowCell } from "../common";
import { handleError } from "../ErrorHandler";

class TournamentLadderStat extends Component {
	teamsInGameListRef = React.createRef();
	teamsDroppedListRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = {
			inGameExpandedTeamID: -1,
			droppedOutExpandedTeamID: -1,
		};

		this.teamsInGameColumns = ["teamName"];
		this.teamsDroppedColumns = ["teamPosition", "teamName", "chipsWon"];
		this.teamMemberColumns = ["country", "ranking", "username"];
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("TournamentLadderStat");
	}
	//#endregion

	//#region events
	onTeamSelect(teamID, index, isInProgress = false) {
		try {
			if (isInProgress) {
				if (this.state.inGameExpandedTeamID == teamID) {
					this.setState({ inGameExpandedTeamID: -1 });
				} else {
					this.setState({ inGameExpandedTeamID: teamID });
				}
				if (this.teamsInGameListRef && this.teamsInGameListRef.current)
					this.teamsInGameListRef.current.scrollToIndex({
						index,
						viewPosition: 0,
					});
			} else {
				if (this.state.droppedOutExpandedTeamID == teamID) {
					this.setState({ droppedOutExpandedTeamID: -1 });
				} else {
					this.setState({ droppedOutExpandedTeamID: teamID });
				}
				if (this.teamsDroppedListRef && this.teamsDroppedListRef.current)
					this.teamsDroppedListRef.current.scrollToIndex({
						index,
						viewPosition: 0,
					});
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderMembersRow(item, index, isInProgress = false) {
		const rowId = "row_" + item.userID;
		let cells = [];

		if ((item.isDroppedOut && !isInProgress) || (!item.isDroppedOut && isInProgress)) {
			let isCurrentUser = item.userID == this.props.currentUserID;
			this.teamMemberColumns.forEach((key) => {
				if (typeof item[key] !== "undefined") {
					let cellId = key + "_" + item.userID;
					let cellKey = "cell_" + cellId;

					var _content = item[key].toLocaleString(),
						_cellWidth = "100%",
						_cellType = "img",
						imgDim = {},
						laurelDim = {},
						_isVip = false,
						showToolTip = false,
						tooltipText = "";
					switch (key) {
						case "country":
							_cellWidth = isWebOrTablet ? 80 : 40;
							var countryURL = {};
							if (typeof item[key] === "string" && item[key] != "")
								countryURL = {
									uri: helpers.getCountryFlagImageURL(item[key].toLowerCase(), 40),
								};
							imgDim = { width: 30, height: 30 };
							showToolTip = Platform.OS === "web";
							const countryCode = typeof item[key] === "string" ? item[key].toLowerCase() : undefined;
							tooltipText =
								countryCode && typeof this.props.lang.countryList[countryCode] === "string"
									? this.props.lang.countryList[countryCode]
									: undefined;
							break;
						case "ranking":
							_cellWidth = isWebOrTablet ? 80 : 40;
							var rankingURL = {};
							_isVip = item.isVip;
							var rankDim = 30;
							const winDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
							if (winDim.scale > 1) {
								if (winDim.scale < 2.5) {
									rankDim = 50;
								} else {
									rankDim = 150;
								}
							}
							rankingURL = helpers.getRankingImageURL(item[key], rankDim);
							imgDim = { width: 30, height: 30 };
							laurelDim = { width: 54, height: 54 };
							break;
						case "username":
							_cellType = "text";
							break;
					}
					cells.push(
						<TableRowCell
							lang={this.props.lang}
							isSelected={isCurrentUser}
							backgroundColor={"transparent"}
							selectedBackgroundColor={"#F6A022"}
							style={styles.cellTextStyle}
							cellWidth={_cellWidth}
							key={cellKey}
							cellId={cellId}
							content={_content}
							cellType={_cellType}
							imgURL={countryURL || rankingURL}
							isVip={_isVip}
							laurelDim={laurelDim}
							imgDim={imgDim}
							cellContainerStyle={[{ height: 40 }, key == "username" && { flex: 1 }]}
							showToolTip={showToolTip}
							tooltipText={tooltipText}
						/>
					);
				}
			});
		}

		return (
			<TableRow disabled={true} rowId={rowId}>
				{cells}
			</TableRow>
		);
	}

	renderMembersTable(members, isInProgress = false) {
		return (
			<FlatList
				accessibilityLabel={"Members of the selected team"}
				keyExtractor={(member) => member.userID.toString()}
				data={members}
				extraData={members}
				renderItem={({ item, index }) => this.renderMembersRow(item, index, isInProgress)}
				getItemLayout={(data, index) => ({
					length: 42,
					offset: 42 * index,
					index,
				})}
			/>
		);
	}

	renderTeamRow(item, index, isInProgress = false) {
		let cells = [];
		const rowId = "row_" + item.teamID;
		let _isSelected = false;
		if (isInProgress) {
			_isSelected = item.teamID == this.state.inGameExpandedTeamID;
		} else {
			_isSelected = item.teamID == this.state.droppedOutExpandedTeamID;
		}
		const findCurrentUser = item.teamMembers.find((us) => us.userID == this.props.currentUserID);
		const _isActive = findCurrentUser && findCurrentUser.userID;
		const columns = isInProgress == true ? this.teamsInGameColumns : this.teamsDroppedColumns;
		const _isDroppedButPlaying = !isInProgress && !item.isFinished;
		const _isFinished = !isInProgress && item.isFinished;
		const _isFinishedNoChips = _isFinished && item.chipsWon == 0;
		const _isFinishedWithChips = _isFinished && item.chipsWon > 0;

		columns.forEach((key) => {
			if (typeof item[key] !== "undefined") {
				let cellId = key + "_" + item.teamID;
				let cellKey = "cell_" + cellId;

				var _content = item[key].toLocaleString(),
					_cellWidth = "100%",
					_cellType = "text",
					_withDropDown = false;
				switch (key) {
					case "teamPosition":
						_cellWidth = 50;
						break;
					case "teamName":
						_cellType = "mixed";
						_withDropDown = true;
						break;
					case "chipsWon":
						_cellWidth = 80;
						break;
				}
				cells.push(
					<TableRowCell
						lang={this.props.lang}
						isSelected={_isActive || _isSelected}
						backgroundColor={"transparent"}
						selectedBackgroundColor={_isActive && !_isSelected ? "#F6A022" : "rgba(10, 95, 110,0.5)"}
						style={[
							styles.cellTextStyle,
							Platform.OS !== "web" && { flex: 1 },
							_isDroppedButPlaying && {
								fontFamily: f_sourcesansprosemibolditalic,
								color: "#adff2f",
							},
							_isFinishedNoChips && {
								fontFamily: f_sourcesansproregular,
								color: "#c9c9c9",
							},
							_isFinishedWithChips && {
								color: _isActive && !_isSelected ? "#fafad2" : "#ffd700",
							},
							key == "chipsWon" && { textAlign: "right", paddingRight: 10 },
						]}
						cellWidth={_cellWidth}
						key={cellKey}
						cellId={cellId}
						content={_content}
						cellType={_cellType}
						cellContainerStyle={[
							{ height: 40 },
							key == "teamName" && { flex: 1, flexDirection: "row" },
							(key == "chipsWon" || (key == "teamName" && isInProgress)) && {
								borderRightWidth: 0,
							},
						]}
						withDropDown={_withDropDown}
						arrowStyle={_isSelected ? styles.arrowIcon : [styles.arrowIcon, styles.mirror]}
						arrowContainer={styles.arrowContainer}
					/>
				);
			}
		});

		const rowSelectFunc = () => this.onTeamSelect(item.teamID, index, isInProgress);
		return (
			<>
				<TableRow rowId={rowId} onPress={rowSelectFunc}>
					{cells}
				</TableRow>
				{_isSelected && (
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							minHeight: 40,
						}}
					>
						<View
							style={{
								width: 50,
								borderBottomWidth: 1,
								borderColor: "#22C1E4",
							}}
						/>
						<View style={{ flex: 1, borderLeftWidth: 1, borderColor: "#22C1E4" }}>
							{this.renderMembersTable(item.teamMembers, isInProgress)}
						</View>
						<View
							style={{
								width: 80,
								borderBottomWidth: 1,
								borderColor: "#22C1E4",
							}}
						/>
					</View>
				)}
			</>
		);
	}

	renderTeamTable(isInProgress = false, dataTable) {
		if (
			!isInProgress &&
			this.props.selectedTournament.teamsInPlay.length == 0 &&
			this.props.selectedTournament.teamsDropped.length == 0
		) {
			return (
				<FlatList
					ref={this.teamsDroppedListRef}
					accessibilityLabel={"Tournament statistics"}
					keyExtractor={(team) => team.teamID.toString()}
					data={this.props.selectedTournament.teams}
					extraData={this.props.selectedTournament.teams}
					renderItem={({ item, index }) => this.renderTeamRow(item, index, isInProgress)}
					getItemLayout={(data, index) => ({
						length: Array.isArray(data.teamMembers) ? 42 + (data.teamMembers.length - 1) * 42 : 42,
						offset: Array.isArray(data.teamMembers) ? 42 * index + (data.teamMembers.length - 1) * 42 : 42 * index,
						index,
					})}
					nestedScrollEnabled={true}
				/>
			);
		}

		return (
			<FlatList
				ref={isInProgress ? this.teamsInGameListRef : this.teamsDroppedListRef}
				accessibilityLabel={"Tournament statistics"}
				keyExtractor={(team) => team.toString()}
				data={dataTable}
				extraData={dataTable}
				renderItem={({ item, index }) => {
					const found = this.props.selectedTournament.teams.find((team) => team.teamID == item);
					if (found) return this.renderTeamRow(found, index, isInProgress);
					return null;
				}}
				getItemLayout={(data, index) => {
					const found = this.props.selectedTournament.teams.find((team) => team.teamID == data);
					if (found && Array.isArray(found.teamMembers) && found.teamMembers.length > 0) {
						return {
							length: Array.isArray(found.teamMembers) ? 42 + (found.teamMembers.length - 1) * 42 : 42,
							offset: Array.isArray(found.teamMembers) ? 42 * index + (found.teamMembers.length - 1) * 42 : 42 * index,
							index,
						};
					}
					return { length: 42, offset: 42 * index, index };
				}}
				nestedScrollEnabled={true}
			/>
		);
	}

	renderColumn(isInProgress = false) {
		let dataTable = isInProgress
			? this.props.selectedTournament.teamsInPlay
			: this.props.selectedTournament.teamsDropped;
		dataTable = Array.isArray(dataTable) ? dataTable : [];
		if (
			!isInProgress &&
			this.props.selectedTournament.teamsInPlay.length == 0 &&
			this.props.selectedTournament.teamsDropped.length == 0
		) {
			dataTable = this.props.selectedTournament.teams;
		}
		if (dataTable.length > 0) {
			const _headerContent = isInProgress
				? this.props.lang.ladderStat.inTournament
				: this.props.lang.ladderStat.finished;
			return (
				<View style={styles.columnContainer}>
					<HeaderText textContent={_headerContent} style={{ height: gameDimensions.boxHeaderHeight }} />
					<View style={styles.tableStyle}>{this.renderTeamTable(isInProgress, dataTable)}</View>
				</View>
			);
		}
		return null;
	}

	render() {
		return (
			<SafeAreaView style={{ flex: 1 }}>
				<View
					style={[
						styles.container,
						isWebOrTablet && {
							marginTop: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
						},
					]}
				>
					{this.renderColumn(true)}
					{this.renderColumn(false)}
				</View>
			</SafeAreaView>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "row",
		margin: 3,
		paddingLeft: isWebOrTablet ? 20 : 35,
		paddingRight: isWebOrTablet ? 20 : 35,
		paddingBottom: 10,
	},
	columnContainer: { flex: 2, marginLeft: 5, marginRight: 5 },
	tableStyle: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
	},
	cellTextStyle: {
		textAlign: "center",
		color: "#FFFFFF",
		lineHeight: 40,
		height: 40,
		fontSize: 20,
	},
	arrowContainer: {
		height: "100%",
		justifyContent: "center",
		paddingRight: 10,
	},
	arrowIcon: {
		width: 15,
		height: 10,
		marginLeft: 5,
	},
	mirror: {
		transform: [{ rotate: "180deg" }],
	},
});

const mapStateToProps = (state) => {
	var found = state.tournaments.tournamentsList.find(
		(tour) => tour.tournamentID === state.tournaments.selectedTournamentID
	);
	if (!found) {
		found = { teams: [] };
	}
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedTournament: found,
		currentUserID: state.currentUser.userDetails.userID,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentLadderStat);
