import { MyWebSocket } from "../../connection";
import { store } from "../../redux/store";
import {
	CLEAR_GAME_DETAILS_IN_LOBBY,
	LOADING_GAME_DETAILS_IN_LOBBY,
	LOADING_GAME_DETAILS_IN_LOBBY_FINISHED,
	MOUSE_ENTER_SLOT_IN_LOBBY,
	MOUSE_LEAVE_SLOT_IN_LOBBY,
	RECEIVE_GAME_PLAYER_INFO,
	SELECT_SLOT_IN_LOBBY,
} from "./actionTypes";
import { removeSavedEvent } from "./SocketActions";

export const receiveGamePlayerInfo = (msg) => {
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "getGameDetails") {
			dispatch(_receiveGamePlayerInfo(msg));
			MyWebSocket.shared.sendProcessedMessage(msg.sMessageID);
			dispatch(removeSavedEvent(msg.cMessageID));
		}
	};
};

export const selectSlotInLobby = (selSlotID) => {
	return { type: SELECT_SLOT_IN_LOBBY, payload: selSlotID };
};

export const mouseEnterSlotInLobby = (selSlotID) => {
	return { type: MOUSE_ENTER_SLOT_IN_LOBBY, payload: selSlotID };
};

export const mouseLeaveSlotInLobby = (selSlotID) => {
	return { type: MOUSE_LEAVE_SLOT_IN_LOBBY, payload: selSlotID };
};

export const clearGameDetailsInLobby = () => {
	return { type: CLEAR_GAME_DETAILS_IN_LOBBY };
};

export const loadingGameDetailsInLobby = () => {
	return { type: LOADING_GAME_DETAILS_IN_LOBBY };
};

export const gameDetailsLoadingFinished = () => {
	return { type: LOADING_GAME_DETAILS_IN_LOBBY_FINISHED };
};

const _receiveGamePlayerInfo = (msg) => {
	let selLobbyGameID = store.getState().gameListData.selectedGameID;
	let isLobbyOpen = store.getState().tab.isLobbyOpen;
	let gameList = store.getState().gameListData.gameListFiltered;
	let freeSlotLang = store.getState().language.currentLanguage.openSlot;
	let teamPrefixLang = store.getState().language.currentLanguage.teamPrefix;
	return {
		type: RECEIVE_GAME_PLAYER_INFO,
		payload: {
			msg,
			selLobbyGameID,
			isLobbyOpen,
			gameList,
			freeSlotLang,
			teamPrefixLang,
		},
	};
};
