import { orderBy } from "lodash";
import React, { Component } from "react";
import { ImageBackground, Platform, SafeAreaView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansprobold, f_sourcesansprolight, RESOLUTION } from "../../../config/defaults";
import { TEXT_INPUT } from "../../../config/images";
import { MyWebSocket } from "../../../connection";
import { mapObject } from "../../../helpers/commonHelpers";
import { clearSearchedUsersList, closeComposeMessage, closeSearchPlayers, goBackToMain } from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { NavigationHeader } from "../../common";
import { handleError } from "../../ErrorHandler";
import ComposeMessage from "../../GameMessage/ComposeMessage";
import PlayersListTable from "./PlayersList/PlayersListTable";

export class SearchPlayersScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchTerm: "",
			isSearchInProgress: false,
			playersList: [],
			maxVisibleRows: 1,
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		Analytics.logScreenView("FriendBanListSettings");
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.friends != this.props.friends ||
			prevProps.bannedUsers != this.props.bannedUsers ||
			prevProps.searchedUsers != this.props.searchedUsers
		) {
			// var searchedPl = this.state.playersList.find((pl) => pl.selected == true);
			var newPlList = this.getPlayersList();
			// if (searchedPl && searchedPl.userID) {
			//   const found = newPlList.find((fr) => fr.userID == searchedPl.userID);
			//   if (found && found.userID) found.selected = true;
			// }
			this.setState({ isSearchInProgress: false, playersList: newPlList });
		}
	}
	//#endregion

	//#region events
	onBackPress() {
		try {
			if (this.props.isComposeOpened) {
				this.props.closeComposeMessage();
			} else {
				this.props.clearSearchedUsersList();
				this.props.closeSearchPlayers();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onSearchTermChange(text) {
		try {
			if (text != this.state.searchTerm) {
				this.setState({
					searchTerm: text,
					isSearchInProgress: true,
					playersList: [],
				});
				//if searchTerm contains at least 3 characters, search the
				this.searchPlayers(text);
			}
		} catch (error) {
			handleError(error);
		}
	}

	searchPlayers(text) {
		try {
			if (text.length >= 3) {
				MyWebSocket.shared.sendMsg({
					sMessageID: 0,
					type: "searchUsers",
					username: text,
					maxVisibleRows: this.state.maxVisibleRows,
				});
			} else {
				this.props.clearSearchedUsersList();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onFriendAction(item) {
		try {
			if (item.isFriend == true) {
				//remove from friendlist
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "removeFromFriendList",
						userID: item.userID,
					});
				}
			} else {
				//add to friendlist
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "addToFriendList",
						userID: item.userID,
					});
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onBanAction(item) {
		try {
			if (item.isBanned == true) {
				//remove from banList
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "removeFromBanList",
						userID: item.userID,
					});
				}
			} else {
				//add to banList
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "addToBanList",
						userID: item.userID,
					});
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderContent() {
		const emptySearchMsg = this.props.lang.playersList.emptySearchResult.replace("#searchTerm#", this.state.searchTerm);
		var _inputLabel = styles.inputLabel,
			_inputStyle = [styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }],
			_emptySearchList = styles.emptySearchList,
			_inputBackImage = { width: 250, height: 50 };
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.inputLabel, { fontSize: 26, width: 300 }];
			_inputStyle = [
				styles.inputStyle,
				{ fontSize: 26, width: 300, height: 60 },
				Platform.OS === "android" && { paddingTop: 13 },
			];
			_inputBackImage = { width: 300, height: 60 };
			_emptySearchList = [styles.emptySearchList, { fontSize: 26 }];
		}
		return (
			<View style={[styles.container, { marginTop: this.props.resolution === RESOLUTION.HIGH ? 70 : 60 }]}>
				<View style={styles.detailsContainer}>
					<View style={styles.inputContainer}>
						<Text allowFontScaling={false} style={_inputLabel}>
							{this.props.lang.playersList.searchInput.title}
						</Text>
						<ImageBackground source={TEXT_INPUT} resizeMode="stretch" style={_inputBackImage}>
							{Platform.OS !== "web" && (
								<TextInput
									allowFontScaling={false}
									disableFullscreenUI={true}
									onChangeText={(text) => this.onSearchTermChange(text)}
									defaultValue={this.state.searchTerm}
									placeholder={this.props.lang.playersList.searchInput.placeholder}
									placeholderTextColor="#B2B2B2"
									autoCapitalize="none"
									autoCompleteType="off"
									autoCorrect={false}
									spellCheck={false}
									textContentType="oneTimeCode"
									keyboardType={Platform.OS === "ios" ? "default" : "visible-password"}
									underlineColorAndroid="transparent"
									blurOnSubmit={true}
									returnKeyType="search"
									style={_inputStyle}
								/>
							)}
							{Platform.OS === "web" && (
								<TextInput
									allowFontScaling={false}
									disableFullscreenUI={true}
									onChangeText={(text) => this.onSearchTermChange(text)}
									defaultValue={this.state.searchTerm}
									placeholder={this.props.lang.playersList.searchInput.placeholder}
									placeholderTextColor="#B2B2B2"
									autoCapitalize="none"
									autoCorrect={false}
									spellCheck={false}
									textContentType="oneTimeCode"
									underlineColorAndroid="transparent"
									blurOnSubmit={true}
									returnKeyType="search"
									style={[_inputStyle, { outlineWidth: 0 }]}
								/>
							)}
						</ImageBackground>
					</View>
					<View
						style={[
							styles.tableContainer,
							styles.playerTableContainer,
							{
								opacity: this.state.playersList.length != 0 && this.state.searchTerm.length >= 3 ? 1 : 0,
							},
						]}
					>
						<PlayersListTable
							playersList={this.state.playersList}
							searchTerm={this.state.searchTerm}
							onFriendActionPress={this.onFriendAction.bind(this)}
							onBanActionPress={this.onBanAction.bind(this)}
							// selectFilteredPlayer={this.selectFilteredPlayer.bind(this)}
							onTableLayout={(maxVisibleRows) => {
								this.setState({ maxVisibleRows: maxVisibleRows });
							}}
						/>
					</View>
					{this.state.playersList.length == 0 && this.state.searchTerm.length >= 3 && (
						<View style={{ flex: 1 }}>
							<Text allowFontScaling={false} style={[_emptySearchList]}>
								{this.state.isSearchInProgress ? this.props.lang.playersList.searchInProgress : emptySearchMsg}
							</Text>
						</View>
					)}
				</View>
			</View>
		);
	}

	render() {
		return (
			<ImageBackground
				source={this.props.isComposeOpened ? this.props.MENU_PLAY_BACK.url : this.props.SETTINGS_BACK.url}
				style={styles.backgroundContainer}
			>
				{!this.props.isComposeOpened && this.renderContent()}
				{this.props.isComposeOpened && (
					<SafeAreaView
						style={{
							flex: 1,
							marginHorizontal: this.props.resolution === RESOLUTION.HIGH ? 65 : 45,
						}}
					>
						<ComposeMessage />
					</SafeAreaView>
				)}
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getPlayersList() {
		try {
			var list = [];
			mapObject(this.props.searchedUsers, (k, v) => {
				list.push({
					userID: k,
					username: v,
					isFriend: this.props.friends[k] !== undefined,
					isBanned: this.props.bannedUsers[k] !== undefined,
				});
			});
			list = orderBy(list, [(player) => player.username.toLowerCase()], ["asc"]);
			return list;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: { flex: 1, justifyContent: "center" },
	detailsContainer: { flex: 1, marginBottom: 5, marginHorizontal: 10 },
	tableContainer: {
		flex: 1,
		paddingBottom: 1,
		marginBottom: 5,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
	},
	playerTableContainer: { marginBottom: 5 },
	inputContainer: {
		flexDirection: "row",
		marginBottom: 15,
		paddingHorizontal: 5,
		alignSelf: "center",
	},
	inputLabel: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		width: 250,
		textAlign: "left",
		paddingRight: 10,
		color: "#fff",
		lineHeight: 50,
	},
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 250,
		height: 50,
		marginRight: 2,
		borderRadius: 6,
		// paddingTop: Platform.OS === "web" ? 0 : 13,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	emptySearchList: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		color: "#00CED9",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 10,
		alignSelf: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		isComposeOpened: state.gameMessage.isComposeOpened,
		searchedUsers: state.currentUser.searchedUsers,
		friends: state.currentUser.friends,
		bannedUsers: state.currentUser.bannedUsers,
		MENU_PLAY_BACK: state.images.MENU_PLAY_BACK,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	clearSearchedUsersList,
	closeComposeMessage,
	closeSearchPlayers,
	goBackToMain,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPlayersScreen);
