import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { appFont, gameButton, RESOLUTION } from "../../config/defaults";
import { GREEN_BTN, YELLOW_BTN } from "../../config/images";
import { MyWebSocket } from "../../connection";
import {
	clearGameSelection,
	displayPermissionDenied,
	mouseEnteredGameButton,
	mouseLeavedGameButton,
	openCreateNewGame,
	openGameRoom,
	resizeGameLobbyButtons,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { GameButton } from "../common";
import { handleError } from "../ErrorHandler";

class GameLobbyButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnSpectateHovered: false,
		};
	}

	//#region events
	onMouseEnterSpectate() {
		this.setState({ btnSpectateHovered: true });
	}

	onMouseLeaveSpectate() {
		this.setState({ btnSpectateHovered: false });
	}

	async onSpectatePress() {
		try {
			const spectateGameMsg = {
				type: "spectateGame",
				sMessageID: 0,
				gameID: this.props.selectedGameID,
			};
			MyWebSocket.shared.sendMsg(spectateGameMsg);
			await Analytics.logEvent("spectateGame");
		} catch (error) {
			handleError(error);
		}
	}

	async onJoinGamePress() {
		try {
			const { selectedGameID, gameListOrig } = this.props;
			const selGameIndex = gameListOrig.findIndex((obj) => obj.gameID == selectedGameID);
			const { gamePlayersList, selectedSlotID } = this.props.selectedGame;
			var firstOpenSlotID = gamePlayersList.findIndex((obj) => obj.username == this.props.lang.openSlot);
			var slotIDToJoin = selectedSlotID;

			if (slotIDToJoin > -1) {
				var selSlot = gamePlayersList.find((obj) => obj.slotID == slotIDToJoin);
				if (selSlot.username != this.props.lang.openSlot) slotIDToJoin = firstOpenSlotID;
			} else {
				slotIDToJoin = firstOpenSlotID;
			}

			const gameName = selGameIndex == -1 ? "" : gameListOrig[selGameIndex].name;
			const cardset = selGameIndex == -1 ? "" : gameListOrig[selGameIndex].cardset;

			if (slotIDToJoin != -1) {
				var msgGameJoin = {
					type: "joinGame",
					sMessageID: 0,
					gameID: selectedGameID,
					slotID: slotIDToJoin,
					swap: false,
				};
				MyWebSocket.shared.sendMsg(msgGameJoin);
				this.props.openGameRoom(selectedGameID, gameName, cardset);
				this.props.clearGameSelection();
				await Analytics.logEvent("joinGame");
			}
		} catch (error) {
			handleError(error);
		}
	}

	async onCreateNewGamePress() {
		try {
			if (this.props.canCreateGame) {
				this.props.openCreateNewGame();
				this.props.mouseLeavedGameButton(gameButton.CREATE_GAME);
				this.props.clearGameSelection();
				MyWebSocket.shared.sendMsg({ type: "createGame", sMessageID: 0 });
				await Analytics.logEvent("createGame");
			} else {
				this.props.displayPermissionDenied("GENERIC_GAME_CREATE");
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		const { btnCreateNewGame, btnJoinGame, btnSpectate } = this.props.lang.gameLobby;
		const { defButtonStyle, btnTextStyle, containerStyle, disabledButtonBck } = styles;
		const {
			mouseEnteredGameButton,
			mouseLeavedGameButton,
			joinGameButton,
			createNewGameButton,
			onLayout,
			style,
			selectedGame,
			selectedGameID,
		} = this.props;
		const rightOpacity = this.props.canCreateGame ? 1 : 0.5;
		const enabledLeftBackImage = {
			uri: YELLOW_BTN,
			style: [
				styles.backImageStyle,
				this.props.resolution == RESOLUTION.HIGH && {
					maxWidth: 250,
					height: 60,
				},
			],
		};
		const enabledRightBackImage = {
			uri: GREEN_BTN,
			style: [
				styles.backImageStyle,
				{ opacity: rightOpacity },
				this.props.resolution == RESOLUTION.HIGH && {
					maxWidth: 250,
					height: 60,
				},
			],
		};
		const disabledLeftBackImage = {
			...enabledLeftBackImage,
			style: [...enabledLeftBackImage.style, disabledButtonBck],
		};
		const disabledRightBackImage = {
			...enabledRightBackImage,
			style: [...enabledRightBackImage.style, disabledButtonBck, { opacity: rightOpacity }],
		};
		const hoveredLeftBackImage = {
			...enabledLeftBackImage,
			style: [...enabledLeftBackImage.style, { opacity: 0.8 }],
		};
		const hoveredRightBackImage = {
			...enabledRightBackImage,
			style: [...enabledRightBackImage.style, { opacity: this.props.canCreateGame ? 0.8 : 0.5 }],
		};
		const enabledButtonStyle = [
			defButtonStyle,
			{
				cursor: "pointer",
				height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
			},
		];
		const disabledButtonStyle = [
			defButtonStyle,
			{
				cursor: "default",
				height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
			},
		];
		var _btnTextStyle = styles.btnTextStyle;
		if (this.props.resolution == RESOLUTION.HIGH) {
			_btnTextStyle = [styles.btnTextStyle, { fontSize: 22, paddingTop: 13, paddingBottom: 13 }];
		}
		return (
			<View onLayout={onLayout} style={[containerStyle, style || {}]}>
				{(selectedGameID == -1 || (selectedGame && selectedGame.state !== "RUNNING")) && (
					<GameButton
						onPress={this.onJoinGamePress.bind(this)}
						onMouseEnter={() => mouseEnteredGameButton(gameButton.JOIN_GAME)}
						onMouseLeave={() => mouseLeavedGameButton(gameButton.JOIN_GAME)}
						disabled={joinGameButton.enabled ? false : true}
						myStyle={
							Platform.OS === "web"
								? joinGameButton.enabled
									? enabledButtonStyle
									: disabledButtonStyle
								: [
										defButtonStyle,
										{
											height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
										},
								  ]
						}
						textContent={btnJoinGame}
						textContentStyle={[_btnTextStyle, { textShadowColor: "#5f580b" }]}
						backgroundImage={
							joinGameButton.enabled
								? joinGameButton.hovered
									? hoveredLeftBackImage
									: enabledLeftBackImage
								: disabledLeftBackImage
						}
					/>
				)}
				{selectedGameID != -1 && selectedGame && selectedGame.state === "RUNNING" && (
					<GameButton
						onPress={this.onSpectatePress.bind(this)}
						onMouseEnter={this.onMouseEnterSpectate.bind(this)}
						onMouseLeave={this.onMouseLeaveSpectate.bind(this)}
						disabled={false}
						myStyle={
							Platform.OS === "web"
								? enabledButtonStyle
								: [
										defButtonStyle,
										{
											height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
										},
								  ]
						}
						textContent={btnSpectate}
						textContentStyle={[_btnTextStyle, { textShadowColor: "#5f580b" }]}
						backgroundImage={this.state.btnSpectateHovered ? hoveredLeftBackImage : enabledLeftBackImage}
					/>
				)}
				<GameButton
					onPress={this.onCreateNewGamePress.bind(this)}
					onMouseEnter={() => mouseEnteredGameButton(gameButton.CREATE_GAME)}
					onMouseLeave={() => mouseLeavedGameButton(gameButton.CREATE_GAME)}
					disabled={createNewGameButton.enabled ? false : true}
					myStyle={
						Platform.OS === "web"
							? createNewGameButton.enabled
								? enabledButtonStyle
								: disabledButtonStyle
							: [
									defButtonStyle,
									{
										height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
									},
							  ]
					}
					textContent={btnCreateNewGame}
					textContentStyle={_btnTextStyle}
					backgroundImage={
						createNewGameButton.enabled
							? createNewGameButton.hovered
								? hoveredRightBackImage
								: enabledRightBackImage
							: disabledRightBackImage
					}
				/>
			</View>
		);
	}
	//#endregion

	//#region helpers
	checkPermission(playerAmount) {
		try {
			var result = { rightID: "", enabled: true };
			var permissionID = "GAME_PLAY_CAN_PLAY_WITH_" + playerAmount + "_PLAYERS";
			if (typeof this.props[permissionID] === "boolean") {
				result.rightID = permissionID;
				result.enabled = this.props[permissionID];
			}
			return result;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	containerStyle: {
		flexDirection: "row",
		justifyContent: "space-around",
		marginTop: 3,
		paddingTop: 5,
		paddingBottom: 5,
	},
	defButtonStyle: {
		flex: 2,
		height: 40,
		backgroundColor: "transparent",
		borderWidth: 0,
	},
	btnTextStyle: {
		width: "100%",
		fontFamily: appFont,
		fontSize: 14,
		paddingTop: 10,
		paddingBottom: 10,
		textAlign: "center",
		color: "#FFFFFF",
		textShadowColor: "#5f580b",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: Platform.OS !== "web" ? 0.001 : 0,
	},
	disabledButtonBck: {
		/*  opacity: 0  */
	},
	backImageStyle: { flex: 2, maxWidth: 200, height: 40 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		joinGameButton: state.gameListData.joinGameButton,
		createNewGameButton: state.gameListData.createNewGameButton,
		gameListOrig: state.gameListData.gameListOrig,
		selectedGameID: state.gameListData.selectedGameID,
		selectedGame: state.gameDetailsData.selectedGame,
		gameLobbyButtonsWidth: state.views.gameLobbyButtonsWidth,
		canCreateGame: state.currentUser.features.generic.canCreateGame,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {
	clearGameSelection,
	displayPermissionDenied,
	mouseEnteredGameButton,
	mouseLeavedGameButton,
	openCreateNewGame,
	openGameRoom,
	resizeGameLobbyButtons,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameLobbyButtons);
