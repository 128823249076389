import React, { Component } from "react";
import { FlatList, SafeAreaView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { isWebOrTablet, RESOLUTION } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import Analytics from "../Analytics/Analytics";
import { HeaderText, TableHeader, TableHeaderCell, TableRow, TableRowCell } from "../common";

class TournamentPayout extends Component {
	constructor(props) {
		super(props);
		this.columns = ["position", "chipsCount"];
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("TournamentPayout");
	}
	//#endregion

	//#region render methods
	renderRow(item, index) {
		const rowId = "row_" + item.position;
		let cells = [];

		this.columns.forEach((key) => {
			if (typeof item[key] !== "undefined") {
				let cellId = key + "_" + item.position;
				let cellKey = "cell_" + cellId;

				var _content = item[key],
					_cellWidth = "100%";
				switch (key) {
					case "position":
						_cellWidth = 175;
						break;
					case "chipsCount":
						_content = _content.toLocaleString();
						break;
				}
				cells.push(
					<TableRowCell
						lang={this.props.lang}
						isSelected={false}
						backgroundColor={"transparent"}
						selectedBackgroundColor={"#F6A022"}
						style={styles.cellTextStyle}
						cellWidth={_cellWidth}
						key={cellKey}
						cellId={cellId}
						content={_content}
						cellType={"text"}
						cellContainerStyle={[{ height: 40 }, key == "chipsCount" && { flex: 1, borderRightWidth: 0 }]}
					/>
				);
			}
		});
		return (
			<TableRow rowId={rowId} disabled={true}>
				{cells}
			</TableRow>
		);
	}

	renderTable() {
		return (
			<FlatList
				accessibilityLabel={"Tournaments payout"}
				keyExtractor={(payout) => payout.position.toString()}
				data={this.props.selectedTournament.payout}
				extraData={this.props.selectedTournament.payout}
				renderItem={({ item, index }) => this.renderRow(item, index)}
				getItemLayout={(data, index) => ({
					length: 42,
					offset: 42 * index,
					index,
				})}
			/>
		);
	}

	renderHeader() {
		const headerCells = [];

		this.columns.forEach((key) => {
			const headerKey = key + "_Header";
			var _cellWidth = "100%",
				_content = this.props.lang.tournamentPayout[key];
			switch (key) {
				case "position":
					_cellWidth = 175;
					break;
				case "chipsCount":
					break;
			}

			headerCells.push(
				<TableHeaderCell
					key={headerKey}
					cellContent={_content}
					containerStyle={[styles.headerContainer, { width: _cellWidth }, key == "chipsCount" && { flex: 1 }]}
					textStyle={[styles.headerText, { width: _cellWidth }, key == "chipsCount" && { flex: 1 }]}
					isSortable={false}
					isSelected={false}
					isAscending={false}
				/>
			);
		});

		return <TableHeader>{headerCells}</TableHeader>;
	}

	render() {
		const headerContent = this.props.lang.tournamentPayout.buyIn.replace(
			"#1#",
			this.props.selectedTournament.buyIn > 0
				? this.props.selectedTournament.buyIn.toLocaleString()
				: this.props.lang.tournamentList.freeBuyIn
		);
		return (
			<SafeAreaView style={{ flex: 1 }}>
				<View
					style={[
						styles.container,
						isWebOrTablet && {
							marginTop: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
						},
					]}
				>
					<HeaderText
						textContent={headerContent}
						style={{
							height: gameDimensions.boxHeaderHeight,
						}}
						textContentStyle={{ marginHorizontal: 5 }}
						iconContainer={{
							height: gameDimensions.boxHeaderHeight,
							justifyContent: "center",
						}}
						withChipsIcon={this.props.selectedTournament.buyIn > 0}
					/>
					<View style={styles.tableStyle}>
						{this.renderHeader()}
						{this.renderTable()}
					</View>
				</View>
			</SafeAreaView>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		margin: 3,
		paddingLeft: 60,
		paddingRight: 60,
		paddingBottom: 10,
	},
	tableStyle: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
	},
	cellTextStyle: {
		textAlign: "center",
		color: "#FFFFFF",
		lineHeight: 40,
		height: 40,
		fontSize: 20,
	},
	headerContainer: {
		height: 40,
		justifyContent: "center",
		backgroundColor: "transparent",
	},
	headerText: {
		fontSize: 20,
		height: 40,
		lineHeight: 40,
		textAlign: "center",
		backgroundColor: "transparent",
	},
});

const mapStateToProps = (state) => {
	var found = state.tournaments.tournamentsList.find(
		(tour) => tour.tournamentID === state.tournaments.selectedTournamentID
	);
	if (!found) {
		found = { teams: [] };
	}
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedTournament: found,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentPayout);
