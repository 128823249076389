import React, { Component } from "react";
import { Dimensions, FlatList, Platform, SafeAreaView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { isWebOrTablet, RESOLUTION } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import * as helpers from "../../helpers/commonHelpers";
import { selectTeam } from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { HeaderText, TableRow, TableRowCell } from "../common";
import { handleError } from "../ErrorHandler";

class TournamentScoreStat extends Component {
	teamListRef = React.createRef();
	constructor(props) {
		super(props);
		this.teamColumns = ["teamPosition", "teamName", "teamPointsFor", "teamPointsAgainst", "teamWins", "teamLosses"];
		this.teamMemberColumns = ["country", "ranking", "username"];
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("TournamentScoreStat");
	}
	//#endregion

	//#region events
	onTeamSelect(teamID, index) {
		try {
			this.props.selectTeam(teamID);
			if (this.teamListRef && this.teamListRef.current)
				this.teamListRef.current.scrollToIndex({ index, viewPosition: 0 });
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderMembersRow(item, index) {
		const rowId = "row_" + item.userID;
		let cells = [];
		let isCurrentUser = item.userID == this.props.currentUserID;

		this.teamMemberColumns.forEach((key) => {
			if (typeof item[key] !== "undefined") {
				let cellId = key + "_" + item.userID;
				let cellKey = "cell_" + cellId;

				var _content = item[key].toLocaleString(),
					_cellWidth = "100%",
					_cellType = "img",
					imgDim = {},
					laurelDim = {},
					_isVip = false,
					showToolTip = false,
					tooltipText = "";
				switch (key) {
					case "country":
						_cellWidth = 80;
						var countryURL = {};
						if (typeof item[key] === "string" && item[key] != "")
							countryURL = {
								uri: helpers.getCountryFlagImageURL(item[key].toLowerCase(), 40),
							};
						imgDim = { width: 30, height: 30 };
						showToolTip = Platform.OS === "web";
						const countryCode = typeof item[key] === "string" ? item[key].toLowerCase() : undefined;
						tooltipText =
							countryCode && typeof this.props.lang.countryList[countryCode] === "string"
								? this.props.lang.countryList[countryCode]
								: undefined;
						break;
					case "ranking":
						_cellWidth = 80;
						var rankingURL = {};
						_isVip = item.isVip;
						var rankDim = 30;
						const winDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
						if (winDim.scale > 1) {
							if (winDim.scale < 2.5) {
								rankDim = 50;
							} else {
								rankDim = 150;
							}
						}
						rankingURL = helpers.getRankingImageURL(item[key], rankDim);
						imgDim = { width: 30, height: 30 };
						laurelDim = { width: 54, height: 54 };
						break;
					case "username":
						_cellType = "text";
						break;
				}
				cells.push(
					<TableRowCell
						lang={this.props.lang}
						isSelected={isCurrentUser}
						backgroundColor={"transparent"}
						selectedBackgroundColor={"#F6A022"}
						style={[styles.cellTextStyle]}
						cellWidth={_cellWidth}
						key={cellKey}
						cellId={cellId}
						content={_content}
						cellType={_cellType}
						imgURL={countryURL || rankingURL}
						isVip={_isVip}
						laurelDim={laurelDim}
						imgDim={imgDim}
						cellContainerStyle={[{ height: 40 }, key == "username" && { flex: 1 }]}
						showToolTip={showToolTip}
						tooltipText={tooltipText}
					/>
				);
			}
		});

		return (
			<TableRow disabled={true} rowId={rowId}>
				{cells}
			</TableRow>
		);
	}

	renderMembersTable(members) {
		return (
			<FlatList
				accessibilityLabel={"Members of the selected team"}
				keyExtractor={(member) => member.userID.toString()}
				data={members}
				extraData={members}
				renderItem={({ item, index }) => this.renderMembersRow(item, index)}
				getItemLayout={(data, index) => ({
					length: 42,
					offset: 42 * index,
					index,
				})}
			/>
		);
	}

	renderTeamRow(item, index) {
		const rowId = "row_" + item.teamID;
		let cells = [];
		let _isSelected = item.teamID == this.props.selectedTeamID;
		const findCurrentUser = item.teamMembers.find((us) => us.userID == this.props.currentUserID);
		const _isActive = findCurrentUser && findCurrentUser.userID;

		this.teamColumns.forEach((key) => {
			if (typeof item[key] !== "undefined") {
				let cellId = key + "_" + item.teamID;
				let cellKey = "cell_" + cellId;

				var _content = item[key].toLocaleString(),
					_cellWidth = "100%",
					_cellType = "text",
					_withDropDown = false;
				switch (key) {
					case "teamPosition":
						_cellWidth = 50;
						break;
					case "teamName":
						_cellType = "mixed";
						_withDropDown = true;
						break;
					case "teamPointsFor":
					case "teamPointsAgainst":
					case "teamWins":
					case "teamLosses":
						_cellWidth = 80;
						break;
				}
				cells.push(
					<TableRowCell
						lang={this.props.lang}
						isSelected={_isActive || _isSelected}
						backgroundColor={"transparent"}
						selectedBackgroundColor={_isActive && !_isSelected ? "#F6A022" : "rgba(10, 95, 110,0.5)"}
						style={[
							styles.cellTextStyle,
							Platform.OS !== "web" && { flex: 1 },
							key == "teamPosition" && index == 0 && styles.firstRowFirstItem,
							key == "teamLosses" && index == 0 && styles.firstRowLastItem,
						]}
						cellWidth={_cellWidth}
						key={cellKey}
						cellId={cellId}
						content={_content}
						cellType={_cellType}
						cellContainerStyle={[
							{ height: 40 },
							key == "teamName" && { flex: 1, flexDirection: "row" },
							key == "teamPosition" && index == 0 && styles.firstRowFirstItem,
							key == "teamLosses" && index == 0 && styles.firstRowLastItem,
							key == "teamLosses" && { borderRightWidth: 0 },
						]}
						withDropDown={_withDropDown}
						arrowStyle={_isSelected ? styles.arrowIcon : [styles.arrowIcon, styles.mirror]}
						arrowContainer={styles.arrowContainer}
					/>
				);
			}
		});

		const rowSelectFunc = () => this.onTeamSelect(item.teamID, index);
		return (
			<>
				<TableRow rowId={rowId} onPress={rowSelectFunc}>
					{cells}
				</TableRow>
				{_isSelected && (
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							minHeight: 40,
						}}
					>
						<View
							style={{
								width: 50,
								borderBottomWidth: 1,
								borderColor: "#22C1E4",
							}}
						/>
						<View style={{ flex: 1, borderLeftWidth: 1, borderColor: "#22C1E4" }}>
							{this.renderMembersTable(item.teamMembers)}
						</View>
						<View
							style={{
								width: 80,
								borderBottomWidth: 1,
								borderColor: "#22C1E4",
							}}
						/>
					</View>
				)}
			</>
		);
	}

	renderTeamTable() {
		return (
			<FlatList
				ref={this.teamListRef}
				accessibilityLabel={"Tournament statistics"}
				keyExtractor={(team) => team.teamID.toString()}
				data={this.props.selectedTournament.teams}
				extraData={this.props.selectedTournament.teams}
				renderItem={({ item, index }) => this.renderTeamRow(item, index)}
				getItemLayout={(data, index) => ({
					length: Array.isArray(data.teamMembers) ? 42 + (data.teamMembers.length - 1 * 42) : 42,
					offset: Array.isArray(data.teamMembers) ? 42 * index + (data.teamMembers.length - 1 * 42) : 42 * index,
					index,
				})}
				nestedScrollEnabled={true}
			/>
		);
	}

	render() {
		return (
			<SafeAreaView style={{ flex: 1 }}>
				<View
					style={[
						styles.container,
						isWebOrTablet
							? {
									marginTop: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
							  }
							: { marginTop: 10 },
					]}
				>
					{isWebOrTablet && (
						<HeaderText
							textContent={this.props.selectedTournament.tournamentName + " stats:"}
							style={{ height: gameDimensions.boxHeaderHeight }}
						/>
					)}
					<View style={styles.tableStyle}>{this.renderTeamTable()}</View>
				</View>
			</SafeAreaView>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		margin: 3,
		paddingLeft: 60,
		paddingRight: 60,
		paddingBottom: 10,
	},
	tableStyle: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
	},
	cellTextStyle: {
		textAlign: "center",
		color: "#FFFFFF",
		lineHeight: 40,
		height: 40,
		fontSize: 20,
	},
	firstRowFirstItem: { borderTopLeftRadius: 6 },
	firstRowLastItem: { borderTopRightRadius: 6 },
	arrowContainer: {
		height: "100%",
		justifyContent: "center",
		paddingRight: 10,
	},
	arrowIcon: {
		width: 15,
		height: 10,
		marginLeft: 5,
	},
	mirror: {
		transform: [{ rotate: "180deg" }],
	},
});

const mapStateToProps = (state) => {
	var found = state.tournaments.tournamentsList.find(
		(tour) => tour.tournamentID === state.tournaments.selectedTournamentID
	);
	if (!found) {
		found = { teams: [] };
	}
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedTeamID: state.tournaments.selectedTeamID,
		selectedTournament: found,
		currentUserID: state.currentUser.userDetails.userID,
	};
};

const mapDispatchToProps = { selectTeam };

export default connect(mapStateToProps, mapDispatchToProps)(TournamentScoreStat);
