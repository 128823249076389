import React, { Component } from "react";
import { Dimensions, Platform, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor } from "../../../config/defaults";
import { gameDimensions } from "../../../config/dimensions";
import { openEmailFriend } from "../../../redux/actions";
import { ColoredButton } from "../../common";
import EmailFriend from "./EmailFriend";

class InviteFriendsMenu extends Component {
	//#region render methods
	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "facebook":
				textContent = this.props.lang.gameShop.inviteFriendsMenu.facebook;
				onPressFunc = () => {
					//TODO:
				};
				_buttonColor = buttonColor.BLUE;
				break;
			case "whatsapp":
				textContent = this.props.lang.gameShop.inviteFriendsMenu.whatsapp;
				onPressFunc = () => {
					//TODO:
				};
				_buttonColor = buttonColor.GREEN;
				break;
			case "email":
				textContent = this.props.lang.gameShop.inviteFriendsMenu.email;
				onPressFunc = () => {
					this.props.openEmailFriend();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "sms":
				textContent = this.props.lang.gameShop.inviteFriendsMenu.sms;
				onPressFunc = () => {
					//TODO:
				};
				_buttonColor = buttonColor.PURPLE;
				break;
		}
		return (
			<ColoredButton
				width={gameDimensions.gameButtonsWidth}
				height={40}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				additionalTextStyle={styles.buttonText}
				color={_buttonColor}
				disabled={false}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ScrollView
				style={styles.container}
				contentContainerStyle={[styles.containerContent, { minHeight: Dimensions.get("window").height - 60 }]}
				centerContent={true}
			>
				{this.renderButton("facebook")}
				{this.renderButton("whatsapp")}
				{this.renderButton("email")}
				{Platform.OS !== "web" && this.renderButton("sms")}
			</ScrollView>
		);
	}

	render() {
		if (this.props.emailFriendOpened) {
			return <EmailFriend />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		paddingHorizontal: 20,
		justifyContent: "center",
		alignItems: "center",
	},
	buttonText: {
		fontSize: 16,
		paddingTop: 8,
		paddingBottom: 8,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		emailFriendOpened: state.app.emailFriendOpened,
	};
};

const mapDispatchToProps = { openEmailFriend };

export default connect(mapStateToProps, mapDispatchToProps)(InviteFriendsMenu);
