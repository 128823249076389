import React, { Component } from "react";
import { Dimensions, ImageBackground, Linking, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { CONNECT_MSG, WS_URL } from "../../config/connection";
import { APP_STATE, buttonColor, FEATURE_PERMISSION, RESOLUTION, storeKeys } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { MyWebSocket } from "../../connection";
import { EventQueue } from "../../controller";
import DebugLogger from "../../controller/DebugLogger";
import {
	closePanelMenu,
	gameDetailsStartedRecevied,
	gameEndReceived,
	gamePlayerActedReceived,
	gamePlayerConnected,
	gamePlayerDisconnected,
	gamePlayerLeftStartedGame,
	gamePlayerToActReceived,
	processLocalMove,
	processStoredImage,
	swapPlayerPanels,
} from "../../Keezers/redux/actions";
import { getData, clearItem } from "../../libraries/AsyncStorage";
import SecureStorage from "../../libraries/ReactSecureStorage";
import {
	addCreditSucceeded,
	addLog,
	authenticateSucceeded,
	buyItemSucceeded,
	chatRoomUserUpdated,
	clearGameDetailsInLobby,
	clearGameListModule,
	clearGlobalChat,
	closeModalDialog,
	connectToNextURL,
	connectToWebsocket,
	detectConnectionError,
	displayNotification,
	displayPermissionDenied,
	emailLoginSucceeded,
	facebookLoginSucceeded,
	finishAppLoad,
	gameListSubscribedReceived,
	gameListUnsubscribedReceived,
	gamePlayerJoined,
	getLanguage,
	goBackToMain,
	handleProfileSettings,
	handleProfileSettingsUpdated,
	handleSettings,
	handleSuccessEvent,
	handleUpgradeRequired,
	handleWelcome,
	increaseConnectionTries,
	increase_cMessageID,
	initConnection,
	insertNewGame,
	leaveGame,
	logOff,
	openEmailLogin,
	openHelp,
	openLeaderBoardsMenu,
	openModalDialog,
	openQuestionnaire,
	openSettings,
	openShop,
	openTab,
	openUpgradeAccount,
	preloadScreenBackgrounds,
	processAckEvent,
	processAnnouncement,
	processChatRoomCreated,
	processChatRoomJoined,
	processChatRoomLeft,
	processErrorEvent,
	processGameClosedEvent,
	processGameCreated,
	processGameInfo,
	processGameJoined,
	processGamePublished,
	processGameStarted,
	receiveGameChatMessage,
	receiveGamePlayerInfo,
	receiveGlobalChatMessage,
	receiveNewMessage,
	receiveShopInventory,
	removeChatRoom,
	removeEmailCredentials,
	removeFacebookCredentials,
	removeGame,
	removeURLFromSocketList,
	resetCMessageID,
	resetConnectionTries,
	resizeAll,
	saveEmailPassword,
	saveEmailUsername,
	saveFacebookToken,
	saveFacebookUID,
	saveFacebookUserDetails,
	saveLeaderBoardsData,
	saveMessageList,
	sendMessage,
	// setInternetReachable,
	// setInternetUnreachable,
	setLoadTime,
	setLoggedInState,
	setOnlinePlayersAmount,
	showBanner,
	socketConnectionClosed,
	socketConnectionEstablished,
	startAuthenticate,
	startChat,
	startEmailLogin,
	startEmailSignup,
	startPlay,
	startReconnect,
	updateBanList,
	updateChatRoomList,
	updateFriendList,
	updateGameName,
	updateGameRoomsSlot,
	updateGameRoomWithPlayerLeft,
	updateMessageDetails,
	updateSearchedUsersList,
	updateStack,
	updateUserOverview,
	insertTournaments,
	removeTournaments,
	saveTournamentDetails,
	updateTournamentDetails,
} from "../../redux/actions";
import { store } from "../../redux/store";
import Analytics from "../Analytics/Analytics";
import { ColoredButton, Spinner } from "../common";
import CustomModal from "../CustomModal";
import Play from "../Play";
import PlayerOverview from "../PlayerOverview/PlayerOverview";
import { f_sourcesansprobold } from "./../../config/defaults";
import AcceptConditions from "./AcceptConditions";
import EmailLoginForm from "./EmailLoginForm";
import EmailSignupForm from "./EmailSignupForm";
import Help from "./Help/Help";
import LeaderBoardsMenu from "./LeaderBoard/LeaderBoardsMenu";
import Settings from "./Settings/Settings";
import UpgradeAccount from "./UpgradeAccount";

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			playBtnHovered: false,
			retrieveProgressHovered: false,
			btnChatHovered: false,
			btnProfileHovered: false,
			preloading: true,
			showSpinner: true,
			environment: WS_URL,
			socketURL: "",
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		this.props.preloadScreenBackgrounds();
		//TODO: remove next lines when socket is OK
		// this.props.handleWelcome(test_welcome);
		// this.props.authenticateSucceeded(new_UserDetails);
		// this.props.emailLoginSucceeded();
		//TODO: END process when socket is NOT OK

		Analytics.logScreenView("MainMenu");

		const password = await getData(storeKeys.EMAIL_PWD);
		if (typeof password !== "undefined" && password !== "") {
			await clearItem(storeKeys.EMAIL_PWD);
		}

		this.preloadImages();
		this.props.getLanguage();

		this.dimensionsSubscription = Dimensions.addEventListener("change", this.props.resizeAll);

		var eventHandlers = {
			onopen: MyWebSocket.shared.socketOnOpenHandler.bind(MyWebSocket.shared),
			onmessage: MyWebSocket.shared.socketOnMessageHandler.bind(MyWebSocket.shared),
			onerror: MyWebSocket.shared.socketOnErrorHandler.bind(MyWebSocket.shared),
			close: MyWebSocket.shared.socketOnCloseHandler.bind(MyWebSocket.shared),
		};
		MyWebSocket.shared.socketConnectionEstablished = this.props.socketConnectionEstablished;
		MyWebSocket.shared.socketConnectionClosed = this.props.socketConnectionClosed;
		MyWebSocket.shared.increaseConnectionTries = this.props.increaseConnectionTries;
		MyWebSocket.shared.resetConnectionTries = this.props.resetConnectionTries;
		MyWebSocket.shared.increaseClientMessageCounter = this.props.increase_cMessageID;
		MyWebSocket.shared.resetCMessageID = this.props.resetCMessageID;
		MyWebSocket.shared.sendMessageAction = this.props.sendMessage;
		MyWebSocket.shared.startAuthenticate = this.props.startAuthenticate;
		MyWebSocket.shared.authenticateSucceeded = this.props.authenticateSucceeded;
		MyWebSocket.shared.openTab = this.props.openTab;
		MyWebSocket.shared.insertNewGame = this.props.insertNewGame;
		MyWebSocket.shared.removeGame = this.props.removeGame;
		MyWebSocket.shared.receiveGamePlayerInfo = this.props.receiveGamePlayerInfo;
		MyWebSocket.shared.processGameCreated = this.props.processGameCreated;
		MyWebSocket.shared.processGamePublished = this.props.processGamePublished;
		MyWebSocket.shared.processGameNameUpdated = this.props.updateGameName;
		MyWebSocket.shared.processGameSlotUpdated = this.props.updateGameRoomsSlot;
		MyWebSocket.shared.processGameChat = this.props.receiveGameChatMessage;
		MyWebSocket.shared.processGamePlayerJoined = this.props.gamePlayerJoined;
		MyWebSocket.shared.processGameJoined = this.props.processGameJoined;
		MyWebSocket.shared.updateGameRoomWithPlayerLeft = this.props.updateGameRoomWithPlayerLeft;
		MyWebSocket.shared.processGameStarted = this.props.processGameStarted;
		MyWebSocket.shared.processGameInfo = this.props.processGameInfo;
		MyWebSocket.shared.processGamePlayerToAct = this.props.gamePlayerToActReceived;
		MyWebSocket.shared.processLocalMove = this.props.processLocalMove;
		MyWebSocket.shared.processGamePlayerActed = this.props.gamePlayerActedReceived;
		MyWebSocket.shared.processGameEnd = this.props.gameEndReceived;
		MyWebSocket.shared.setOnlinePlayersAmount = this.props.setOnlinePlayersAmount;
		MyWebSocket.shared.processChatRoomListUpdate = this.props.updateChatRoomList;
		MyWebSocket.shared.processChatRoomListRemove = this.props.removeChatRoom;
		MyWebSocket.shared.processChatRoomJoined = this.props.processChatRoomJoined;
		MyWebSocket.shared.processChatRoomLeft = this.props.processChatRoomLeft;
		MyWebSocket.shared.processChatRoomCreated = this.props.processChatRoomCreated;
		MyWebSocket.shared.chatRoomUserUpdated = this.props.chatRoomUserUpdated;
		MyWebSocket.shared.receiveGlobalChatMessage = this.props.receiveGlobalChatMessage;
		MyWebSocket.shared.clearGlobalChat = this.props.clearGlobalChat;
		MyWebSocket.shared.processAckEvent = this.props.processAckEvent;
		MyWebSocket.shared.updateStack = this.props.updateStack;
		MyWebSocket.shared.openModalDialog = this.props.openModalDialog;
		MyWebSocket.shared.displayNotification = this.props.displayNotification;
		MyWebSocket.shared.processGameClosedEvent = this.props.processGameClosedEvent;
		MyWebSocket.shared.processAnnouncement = this.props.processAnnouncement;
		MyWebSocket.shared.processErrorEvent = this.props.processErrorEvent;
		MyWebSocket.shared.clearGameListModule = this.props.clearGameListModule;
		MyWebSocket.shared.clearGameDetailsInLobby = this.props.clearGameDetailsInLobby;
		MyWebSocket.shared.detectConnectionError = this.props.detectConnectionError;
		MyWebSocket.shared.handleWelcome = this.props.handleWelcome;
		MyWebSocket.shared.handleUpgradeRequired = this.props.handleUpgradeRequired;
		MyWebSocket.shared.startReconnect = this.props.startReconnect;
		MyWebSocket.shared.isUpgradable = this.props.isUpgradable;
		MyWebSocket.shared.connectToNextURL = () => {
			if (this.props.sessionID !== -1) {
				CONNECT_MSG.recoverFromCrash = false;
				CONNECT_MSG.clientState = APP_STATE.RECONNECT;
			}
			if (this.props.websocket !== null) {
				this.props.websocket.close();
			}
			if (this.props.authenticated == true) {
				this.props.setLoggedInState(false);
			}
			if (!this.props.isUpgradable) {
				this.props.connectToNextURL();
				setTimeout(() => {
					this.props.connectToWebsocket(eventHandlers);
				}, 1000);
			}
		};
		MyWebSocket.shared.processGameListSubscribed = this.props.gameListSubscribedReceived;
		MyWebSocket.shared.processGameListUnsubscribed = this.props.gameListUnsubscribedReceived;
		MyWebSocket.shared.saveLeaderBoardsData = this.props.saveLeaderBoardsData;
		MyWebSocket.shared.handleProfileSettings = this.props.handleProfileSettings;
		MyWebSocket.shared.handleProfileSettingsUpdated = this.props.handleProfileSettingsUpdated;
		MyWebSocket.shared.handleSettings = this.props.handleSettings;
		MyWebSocket.shared.updateFriendList = this.props.updateFriendList;
		MyWebSocket.shared.updateBanList = this.props.updateBanList;
		MyWebSocket.shared.updateSearchedUsersList = this.props.updateSearchedUsersList;
		MyWebSocket.shared.logOff = this.props.logOff;
		MyWebSocket.shared.closeModalDialog = this.props.closeModalDialog;
		MyWebSocket.shared.addLog = this.props.addLog;
		MyWebSocket.shared.receiveShopInventory = this.props.receiveShopInventory;
		MyWebSocket.shared.removeURLFromSocketList = this.props.removeURLFromSocketList;
		MyWebSocket.shared.gameDetailsStartedRecevied = this.props.gameDetailsStartedRecevied;
		MyWebSocket.shared.handleSuccessEvent = this.props.handleSuccessEvent;
		MyWebSocket.shared.leaveGame = this.props.leaveGame;
		MyWebSocket.shared.goBackToMain = this.props.goBackToMain;
		MyWebSocket.shared.gamePlayerDisconnected = this.props.gamePlayerDisconnected;
		MyWebSocket.shared.gamePlayerConnected = this.props.gamePlayerConnected;
		MyWebSocket.shared.gamePlayerLeftStartedGame = this.props.gamePlayerLeftStartedGame;
		MyWebSocket.shared.buyItemSucceeded = this.props.buyItemSucceeded;
		MyWebSocket.shared.addCreditSucceeded = this.props.addCreditSucceeded;
		MyWebSocket.shared.updateUserOverview = this.props.updateUserOverview;
		MyWebSocket.shared.enableAcks = this.props.enableAcks;
		MyWebSocket.shared.insertTournaments = this.props.insertTournaments;
		MyWebSocket.shared.removeTournaments = this.props.removeTournaments;
		MyWebSocket.shared.saveTournamentDetails = this.props.saveTournamentDetails;
		MyWebSocket.shared.updateTournamentDetails = this.props.updateTournamentDetails;
		EventQueue.shared.swapPlayerPanels = this.props.swapPlayerPanels;
		DebugLogger.shared.sessionID = this.props.sessionID;
		DebugLogger.shared.enableDebugLogging = this.props.enableDebugLogging;
		DebugLogger.shared.debugLogSize = this.props.debugLogSize;
		EventQueue.shared.openShop = this.props.openShop;
		EventQueue.shared.processStoredImage = this.props.processStoredImage;
		EventQueue.shared.saveMessageList = this.props.saveMessageList;
		EventQueue.shared.receiveNewMessage = this.props.receiveNewMessage;
		EventQueue.shared.updateMessageDetails = this.props.updateMessageDetails;

		// window.addEventListener("online", this.handleConnectionChange);
		// window.addEventListener("offline", this.handleConnectionChange);

		Linking.getInitialURL().then((link) => {
			if (link !== null) {
				var sURLVariables = link.split("?").pop().split("&");
				var environment = "",
					socketURL = "";
				for (var i = 0; i < sURLVariables.length; i++) {
					var sParameterName = sURLVariables[i].split("=");
					if (sParameterName[0].toLowerCase() == "env") {
						environment = sParameterName[1].split("#!").shift();
					}
					if (sParameterName[0].toLowerCase() == "ws") {
						socketURL = sParameterName[1].split("#!").shift();
					}
				}
				this.setState({ environment, socketURL });
				if (this.props.websocket === null) {
					this.props.initConnection(environment, socketURL);
					this.props.connectToWebsocket(eventHandlers);
				} else {
					if (MyWebSocket.shared.ws !== this.props.websocket) MyWebSocket.shared.ws = this.props.websocket;
				}
			} else {
				if (this.props.websocket === null) {
					this.props.initConnection(this.state.environment, this.state.socketURL);
					this.props.connectToWebsocket(eventHandlers);
				} else {
					if (MyWebSocket.shared.ws !== this.props.websocket) MyWebSocket.shared.ws = this.props.websocket;
				}
			}
		});

		(function (global, _MyWebSocket) {
			if (typeof global === "undefined") {
				throw new Error("window is undefined");
			}

			var _hash = "!";
			var noBackPlease = function () {
				global.location.href += "#";

				// making sure we have the fruit available for juice (^__^)
				global.setTimeout(function () {
					global.location.href += "!";
				}, 50);
			};

			global.onhashchange = function () {
				if (global.location.hash !== _hash) {
					global.location.hash = _hash;
				}
			};

			global.onload = function () {
				noBackPlease();

				// disables backspace on page except on input fields and textarea..
				document.body.onkeydown = function (e) {
					var elm = e.target.nodeName.toLowerCase();
					var content_editable = e.target.getAttribute("contenteditable");
					if (
						e.which === 8 &&
						elm !== "input" &&
						elm !== "textarea" &&
						content_editable !== null &&
						content_editable !== "" &&
						elm !== "div"
					) {
						e.preventDefault();
					}
					// stopping event bubbling up the DOM tree..
					e.stopPropagation();
					if (store.getState().startedGame.expandPanelMenu == true) {
						store.dispatch(closePanelMenu());
					}
				};
			};

			global.onbeforeunload = function () {
				if (_MyWebSocket && _MyWebSocket.ws && _MyWebSocket.ws.readyState == 1) {
					//send exit event
					var disconnectMsg = {
						sMessageID: 0,
						type: "exit",
						reason: "PAGE_UNLOAD",
					};
					_MyWebSocket.sendMsg(disconnectMsg);
				}
				const conn_log = {
					conn_message: "Page will be unloaded",
					currentConnection: this.props.websocket,
					connectionTries: this.connectionTriesCounter,
				};
				DebugLogger.shared.saveConnectivityLog(conn_log);
			};

			//disabling browser back button
			global.history.pushState(null, null, global.location.href);
			global.onpopstate = function () {
				global.history.go(1);
			};
		})(window, MyWebSocket.shared);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isUpgradable != this.props.isUpgradable) {
			MyWebSocket.shared.isUpgradable = this.props.isUpgradable;
		}
		if (prevProps.sessionID != this.props.sessionID) {
			DebugLogger.shared.sessionID = this.props.sessionID;
		}
		if (prevProps.enableDebugLogging != this.props.enableDebugLogging) {
			DebugLogger.shared.enableDebugLogging = this.props.enableDebugLogging;
		}
		if (prevProps.debugLogSize != this.props.debugLogSize) {
			DebugLogger.shared.debugLogSize = this.props.debugLogSize;
		}
		if (prevProps.enableAcks != this.props.enableAcks) {
			MyWebSocket.shared.enableAcks = this.props.enableAcks;
		}

		if (prevProps.loggableMessages != this.props.loggableMessages) {
			this.props.loggableMessages.forEach((lm, index) => {
				if (index > prevProps.loggableMessages.length - 1) this.props.addLog(lm);
			});
		}
		if (prevProps.cMessageID != this.props.cMessageID) {
			MyWebSocket.shared.cMessageID = this.props.cMessageID;
		}
		if (prevProps.connectionTriesCounter != this.props.connectionTriesCounter) {
			MyWebSocket.shared.connectionTriesCounter = this.props.connectionTriesCounter;
		}
		// TODO: put this back when socket is OK
		if (prevProps.socketConnected == false && this.props.socketConnected == true && this.props.appLoaded) {
			// this.props.initConnection(this.state.environment, this.state.socketURL);
			this.props.finishAppLoad();
			if (MyWebSocket.shared.ws !== this.props.websocket) MyWebSocket.shared.ws = this.props.websocket;
		}

		if (this.props.websocket === null && prevProps.websocket !== null) {
			// var eventHandlers = {
			//   onopen: MyWebSocket.shared.socketOnOpenHandler.bind(MyWebSocket.shared),
			//   onmessage: MyWebSocket.shared.socketOnMessageHandler.bind(
			//     MyWebSocket.shared
			//   ),
			//   onerror: MyWebSocket.shared.socketOnErrorHandler.bind(
			//     MyWebSocket.shared
			//   ),
			//   close: MyWebSocket.shared.socketOnCloseHandler.bind(MyWebSocket.shared),
			// };
			// this.props.connectToWebsocket(eventHandlers);
		} else {
			if (MyWebSocket.shared.ws !== this.props.websocket) MyWebSocket.shared.ws = this.props.websocket;
		}

		if (prevProps.connected == true && this.props.connected == false) {
			this.setState({ showSpinner: false });
		}
		if (prevProps.connected == false && this.props.connected == true && this.props.termsAccepted == true) {
			this.setState({ showSpinner: false });
			if (
				!this.props.emailSignupStarted &&
				!this.props.facebookLoginStarted &&
				!this.props.emailLoginStarted &&
				!this.props.emailLoginOpened
			) {
				//check if user is logged in as guest
				if (this.props.isGuestUser) {
					MyWebSocket.shared.sendLogin();
				} else {
					//check if user is already logged in with email
					getData(storeKeys.EMAIL_IS_LOGGED_IN).then((storedData) => {
						if (storedData == "true") {
							getData(storeKeys.EMAIL_USERNAME).then((uName) => {
								if (uName !== null) {
									this.props.saveEmailUsername(uName);
									const pwd = SecureStorage.getItem(storeKeys.EMAIL_PWD);
									if (pwd !== null) {
										this.props.saveEmailPassword(pwd);
										this.props.startEmailLogin();
										MyWebSocket.shared.sendLogin("email", false);
									}
									/* getData(storeKeys.EMAIL_PWD).then((pwd) => {
										if (pwd !== null) {
											this.props.saveEmailPassword(pwd);
											this.props.startEmailLogin();
											MyWebSocket.shared.sendLogin("email", false);
										}
									}); */
								}
							});
						}
					});
				}
			}
		}
	}

	componentWillUnmount() {
		this.dimensionsSubscription?.remove();
		// window.removeEventListener("online", this.handleConnectionChange);
		// window.removeEventListener("offline", this.handleConnectionChange);
	}
	//#endregion

	//#region events
	// handleConnectionChange = (event) => {
	//   if (event.type == "online") {
	//     if (this.props.appLoaded) store.dispatch(setInternetReachable());
	//   } else {
	//     if (this.props.appLoaded) store.dispatch(setInternetUnreachable());
	//   }
	// };

	onMainLayout() {
		this.props.setLoadTime(this.props.appStartTime);
		this.props.finishAppLoad();
	}
	//#endregion

	//#region render methods
	renderSpinner() {
		if (this.state.showSpinner) return <Spinner size="large" />;
		return null;
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "",
			_permissionDenied = false,
			_disabled = false;
		switch (btnType) {
			case "play":
				textContent = this.props.lang.play;
				onPressFunc = () => {
					this.props.startPlay();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "guestPlay":
				textContent = this.props.lang.guestPlay;
				_disabled = this.props.emailLoginStarted || this.props.facebookLoginStarted;
				onPressFunc = () => {
					if (this.props.guestLoginPermission == FEATURE_PERMISSION.ENABLED) {
						MyWebSocket.shared.sendLogin("guest");
					} else {
						this.props.displayPermissionDenied("GUEST_LOGIN");
					}
				};
				_buttonColor = buttonColor.YELLOW;
				switch (this.props.guestLoginPermission) {
					case FEATURE_PERMISSION.DISABLED:
						_permissionDenied = true;
						break;
					case FEATURE_PERMISSION.ENABLED:
						_permissionDenied = false;
						break;
				}
				break;
			case "upgradeAcc":
				textContent = this.props.lang.upgradeAcc;
				onPressFunc = () => this.props.openUpgradeAccount();
				_buttonColor = buttonColor.BLUE;
				break;
			case "login":
				textContent = this.props.lang.login;
				onPressFunc = () => {
					this.props.openEmailLogin();
				};
				_buttonColor = buttonColor.GREEN;
				break;
			case "register":
				textContent = this.props.lang.register;
				onPressFunc = () => {
					this.props.startEmailSignup();
				};
				_buttonColor = buttonColor.RED;
				break;
			case "chat":
				textContent = this.props.lang.chat;
				onPressFunc = () => {
					this.props.startChat();
				};
				break;
			case "leaderboards":
				textContent = this.props.lang.leaderboards;
				onPressFunc = () => {
					if (this.props.leaderboardPermission == FEATURE_PERMISSION.ENABLED) {
						this.props.openLeaderBoardsMenu();
					} else {
						this.props.displayPermissionDenied("GENERIC_LEADERBOARD");
					}
				};
				_buttonColor = buttonColor.PURPLE;
				switch (this.props.leaderboardPermission) {
					case FEATURE_PERMISSION.DISABLED:
						_permissionDenied = true;
						break;
					case FEATURE_PERMISSION.ENABLED:
						_permissionDenied = false;
						break;
				}
				break;
			case "settings":
				textContent = this.props.lang.settings;
				onPressFunc = () => {
					this.props.openSettings();
				};
				_buttonColor = buttonColor.LIGHT_BLUE;
				break;
			case "help":
				textContent = this.props.lang.help;
				onPressFunc = () => {
					this.props.openHelp();
				};
				_buttonColor = buttonColor.GREEN;
				break;
			case "logout":
				textContent = this.props.lang.logout;
				onPressFunc = () => {
					MyWebSocket.shared.sendMsg({ type: "logout", sMessageID: 0 });
					this.props.logOff();
				};
				_buttonColor = buttonColor.RED;
				break;
		}
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}

		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				onPressFunc={onPressFunc}
				accessibilityLabel={textContent}
				textContent={textContent}
				color={_buttonColor}
				disabled={_disabled}
				permissionDenied={_permissionDenied}
				additionalTextStyle={_buttonTextStyle}
				isInMenu={true}
			/>
		);
	}

	renderConnectingScreen() {
		return (
			<ImageBackground source={this.props.DEFAULT_BACK.url} style={{ flex: 1 }}>
				<View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
					<Text
						allowFontScaling={false}
						numberOfLines={1}
						style={{
							fontFamily: f_sourcesansprobold,
							fontSize: 46,
							color: "#F6A022",
						}}
					>
						Connecting...
					</Text>
				</View>
			</ImageBackground>
		);
	}

	renderMainForm() {
		// if (!this.props.connected) return null;
		if (this.props.termsAccepted) {
			return (
				<ScrollView style={styles.fullFlex} contentContainerStyle={styles.boxContentStyle} centerContent={true}>
					{(this.props.isLoggedIn || this.props.authenticated) && this.renderButton("play")}
					{!this.props.isLoggedIn &&
						!this.props.authenticated &&
						this.props.guestLoginPermission != FEATURE_PERMISSION.HIDDEN &&
						this.renderButton("guestPlay")}
					{!this.props.isLoggedIn && !this.props.authenticated && this.renderButton("login")}
					{!this.props.isLoggedIn && !this.props.authenticated && this.renderButton("register")}
					{!this.props.isLoggedIn && this.props.authenticated && this.props.userID < -1 && this.renderButton("chat")}
					{(this.props.isLoggedIn || this.props.authenticated) &&
						this.props.leaderboardPermission != FEATURE_PERMISSION.HIDDEN &&
						this.renderButton("leaderboards")}
					{this.props.authenticated && this.props.isGuestUser && this.renderButton("upgradeAcc")}
					{(this.props.isLoggedIn || this.props.authenticated) &&
						!this.props.isGuestUser &&
						this.renderButton("settings")}
					{/*TODO: temporary hidden {(this.props.isLoggedIn || this.props.authenticated) &&
            this.renderButton("help")} */}
					{((!this.props.isLoggedIn && this.props.authenticated) ||
						(this.props.isLoggedIn && !this.props.isFBLoggedIn)) &&
						this.renderButton("logout")}
				</ScrollView>
			);
		} else {
			if (!this.props.connected) return null;
			return <AcceptConditions />;
		}
	}

	renderContent() {
		// if (!this.props.connected) return null;
		if (this.props.upgradeAccountOpened) {
			return <UpgradeAccount />;
		} else if (this.props.emailLoginOpened) {
			return <EmailLoginForm />;
		} else if (this.props.emailSignupStarted) {
			return <EmailSignupForm />;
		} else if (this.props.isSettingsOpened) {
			return <Settings />;
		} else if (this.props.isHelpOpened) {
			return <Help />;
		} else if (this.props.isLeaderBoardsMenuOpened) {
			return <LeaderBoardsMenu />;
		} else {
			return this.renderMainForm();
		}
	}

	renderModal() {
		if (!this.props.visibleModal) return null;
		return <CustomModal />;
	}

	renderPlayerOverview() {
		if (!this.props.isPlayerOverviewOpened) return null;
		return <PlayerOverview />;
	}

	render() {
		if (this.state.preloading) return this.renderSpinner();
		if (this.props.isPlayStarted) return <Play />;

		return (
			<ImageBackground source={this.props.MENU_PLAY_BACK.url} style={{ flex: 1 }}>
				<View style={styles.container} onLayout={this.onMainLayout.bind(this)}>
					{this.renderSpinner()}
					{!this.props.isWelcomeReceived && this.renderConnectingScreen()}
					{this.props.isWelcomeReceived && this.renderContent()}
					{this.props.isWelcomeReceived && this.renderPlayerOverview()}
					{this.renderModal()}
				</View>
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	preloadImages() {
		/* ALL_NETWORK_IMAGES.forEach((img, index) => {
      Image.prefetch(img.uri).then(() => {
        if (index == ALL_NETWORK_IMAGES.length - 1)
          this.setState({ preloading: false, showSpinner:false });
      });
    }); */
		this.setState({ preloading: false, showSpinner: false });
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { flex: 1, height: "100%", justifyContent: "center" },
	fullFlex: { flex: 1 },
	boxContentStyle: {
		flex: 1,
		margin: gameDimensions.fullWidthMargin,
		paddingBottom: gameDimensions.fullWidthMargin,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		enableBanner: state.tab.enableBanner,
		cMessageID: state.socket.cMessageID,
		connectionTriesCounter: state.socket.connectionTriesCounter,
		socketConnected: state.socket.socketConnected,
		visibleModal: state.modal.visibleModal,
		appLoaded: state.app.appLoaded,
		isLoggedIn: state.app.isLoggedIn,
		termsAccepted: state.app.termsAccepted,
		isPlayStarted: state.app.isPlayStarted,
		isLoginStarted: state.app.isLoginStarted,
		isUpgradable: state.app.isUpgradable,
		upgradeAccountOpened: state.app.upgradeAccountOpened,
		emailLoginOpened: state.app.emailLoginOpened,
		emailSignupStarted: state.app.emailSignupStarted,
		emailLoginStarted: state.app.emailLoginStarted,
		facebookLoginStarted: state.app.facebookLoginStarted,
		isFBLoggedIn: state.app.isFBLoggedIn,
		isSettingsOpened: state.app.isSettingsOpened,
		isHelpOpened: state.app.isHelpOpened,
		isLeaderBoardsMenuOpened: state.app.isLeaderBoardsMenuOpened,
		isPlayerOverviewOpened: state.app.isPlayerOverviewOpened,
		connected: state.socket.connected,
		websocket: state.socket.websocket,
		authenticated: state.currentUser.authenticated,
		isGuestUser: state.currentUser.isGuestUser,
		userID: state.currentUser.userDetails.userID,
		guestLoginPermission: state.app.welcome.features.generic.guestLogin,
		leaderboardPermission: state.currentUser.features.generic.leaderBoard,
		resolution: state.currentUser.preferences.resolution,
		sessionID: state.app.welcome.sessionID,
		enableDebugLogging: state.app.welcome.enableDebugLogging,
		debugLogSize: state.app.welcome.debugLogSize,
		enableAcks: state.app.welcome.enableAcks,
		isWelcomeReceived: state.app.isWelcomeReceived,
		MENU_PLAY_BACK: state.images.MENU_PLAY_BACK,
		DEFAULT_BACK: state.images.DEFAULT_BACK,
	};
};

const mapDispatchToProps = {
	addCreditSucceeded,
	addLog,
	authenticateSucceeded,
	buyItemSucceeded,
	clearGameDetailsInLobby,
	clearGameListModule,
	clearGlobalChat,
	closeModalDialog,
	closePanelMenu,
	connectToNextURL,
	connectToWebsocket,
	detectConnectionError,
	displayNotification,
	displayPermissionDenied,
	emailLoginSucceeded,
	facebookLoginSucceeded,
	finishAppLoad,
	gameListSubscribedReceived,
	gameListUnsubscribedReceived,
	gamePlayerActedReceived,
	gamePlayerConnected,
	gamePlayerDisconnected,
	gamePlayerJoined,
	gamePlayerLeftStartedGame,
	getLanguage,
	gamePlayerToActReceived,
	processLocalMove,
	processStoredImage,
	swapPlayerPanels,
	goBackToMain,
	handleProfileSettings,
	handleSettings,
	handleProfileSettingsUpdated,
	handleWelcome,
	handleUpgradeRequired,
	increaseConnectionTries,
	increase_cMessageID,
	initConnection,
	insertNewGame,
	leaveGame,
	logOff,
	openEmailLogin,
	openHelp,
	openLeaderBoardsMenu,
	openModalDialog,
	openQuestionnaire,
	openSettings,
	openShop,
	openTab,
	openUpgradeAccount,
	processAckEvent,
	processAnnouncement,
	processChatRoomCreated,
	processChatRoomJoined,
	processChatRoomLeft,
	chatRoomUserUpdated,
	processErrorEvent,
	processGameClosedEvent,
	processGameCreated,
	gameDetailsStartedRecevied,
	gameEndReceived,
	processGameInfo,
	processGameJoined,
	processGamePublished,
	processGameStarted,
	receiveGameChatMessage,
	receiveGamePlayerInfo,
	receiveGlobalChatMessage,
	receiveNewMessage,
	receiveShopInventory,
	removeChatRoom,
	removeEmailCredentials,
	removeFacebookCredentials,
	removeGame,
	removeURLFromSocketList,
	resetCMessageID,
	resetConnectionTries,
	resizeAll,
	saveEmailPassword,
	saveEmailUsername,
	saveFacebookToken,
	saveFacebookUID,
	saveFacebookUserDetails,
	saveLeaderBoardsData,
	saveMessageList,
	sendMessage,
	setLoadTime,
	setLoggedInState,
	setOnlinePlayersAmount,
	showBanner,
	socketConnectionClosed,
	socketConnectionEstablished,
	startAuthenticate,
	startChat,
	startEmailLogin,
	startEmailSignup,
	startPlay,
	startReconnect,
	updateBanList,
	updateChatRoomList,
	updateFriendList,
	updateGameName,
	updateGameRoomsSlot,
	updateGameRoomWithPlayerLeft,
	updateMessageDetails,
	updateSearchedUsersList,
	handleSuccessEvent,
	updateStack,
	preloadScreenBackgrounds,
	updateUserOverview,
	insertTournaments,
	removeTournaments,
	saveTournamentDetails,
	updateTournamentDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
