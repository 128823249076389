import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor, leaderboard_Type } from "../../../config/defaults";
import { gameDimensions } from "../../../config/dimensions";
import { closeLeaderBoardsMenu, openLeaderBoards } from "../../../redux/actions";
import { ColoredButton, NavigationHeader } from "../../common";
import GameShopStack from "../../GameShop/GameShopStack";
import LeaderBoards from "./LeaderBoards";

class LeaderBoardsMenu extends Component {
	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeLeaderBoardsMenu}
				closeAction={this.props.closeLeaderBoardsMenu}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			>
				<GameShopStack />
			</NavigationHeader>
		);
	}

	renderButton(btnType) {
		var _buttonColor = "",
			textContent = this.props.lang.leaderBoardsMenu[btnType];
		switch (btnType) {
			case leaderboard_Type.GLOBAL:
				_buttonColor = buttonColor.YELLOW;
				break;
			case leaderboard_Type.FRIENDS:
				_buttonColor = buttonColor.PURPLE;
				break;
			case leaderboard_Type.GROUPS:
				_buttonColor = buttonColor.LIGHT_BLUE;
				break;
		}
		return (
			<ColoredButton
				width={gameDimensions.gameButtonsWidth}
				height={60}
				onPressFunc={() => {
					this.props.openLeaderBoards(btnType);
				}}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.MENU_PLAY_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderButton(leaderboard_Type.GLOBAL)}
					{this.renderButton(leaderboard_Type.FRIENDS)}
					{/* {this.renderButton(leaderboard_Type.GROUPS)} */}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.isPlayerOverviewOpened) return null;
		if (this.props.leaderBoardOpened) {
			return <LeaderBoards />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		minHeight: "100%",
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		leaderBoardOpened: state.app.leaderBoardOpened,
		lbCategorySelected: state.leaderboard.lbCategorySelected,
		resolution: state.currentUser.preferences.resolution,
		isPlayerOverviewOpened: state.app.isPlayerOverviewOpened,
		MENU_PLAY_BACK: state.images.MENU_PLAY_BACK,
	};
};

const mapDispatchToProps = { closeLeaderBoardsMenu, openLeaderBoards };

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoardsMenu);
