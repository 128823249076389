import KeepAwake from "@sayem314/react-native-keep-awake";
import React, { Component } from "react";
import { ImageBackground, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { RESOLUTION } from "../config/defaults";
import { EventQueue } from "../controller";
import GameBoard from "../Keezers/components/GameBoard";
import GamePopup from "../Keezers/components/GamePopup";
//TODO: add this if socket not working
// import { MyWebSocket } from "../connection";
// import {
//   gameDetails2Running_Started,
//   issueDeal,
//   issueToAct,
// } from "../Keezers/config/testEvents";
//TODO: end add this if socket not working
import {
	//TODO: add this if socket not working
	// authenticateSucceeded,
	// processGameStarted,
	//TODO: end add this if socket not working
	resizeAll,
} from "../redux/actions";
//TODO: temporary disabled import Banner from "./Admob/Banner";
import { AppBackImage, Spinner } from "./common";
import CustomModal from "./CustomModal";
import CreateNewGame from "./Game/CreateNewGame";
import GameEnd from "./Game/GameEnd";
import GameLobby from "./Game/GameLobby";
import GameRoom from "./Game/GameRoom";
import GameMessage from "./GameMessage/GameMessage";
import GameShopTablet from "./GameShop/GameShopTablet";
import GlobalChat from "./GlobalChat/GlobalChat";
import LeaderBoardsMenu from "./Main/LeaderBoard/LeaderBoardsMenu";
import Settings from "./Main/Settings/Settings";
import NavigationMenu from "./NavigationMenu";
import PlayerOverview from "./PlayerOverview/PlayerOverview";
import TournamentLobby from "./Tournament/TournamentLobby";

class Play extends Component {
	constructor(props) {
		super(props);
		this.state = { gameHelpVisible: false };
	}

	//#region lifecycle methods
	componentDidMount() {
		EventQueue.shared.closeGameHelp = () => {
			this.setState({ gameHelpVisible: false });
		};
		//TODO: remove if socket is OK
		// setTimeout(() => {
		//   MyWebSocket.shared.socketOnMessageHandler({
		//     data: JSON.stringify(gameDetails2Running_Started),
		//   });
		//   setTimeout(() => {
		//     MyWebSocket.shared.socketOnMessageHandler({
		//       data: JSON.stringify(issueDeal),
		//     });
		//     MyWebSocket.shared.socketOnMessageHandler({
		//       data: JSON.stringify(issueToAct),
		//     });
		//   }, 500);
		// }, 500);
		//TODO: end remove comment
	}
	//#endregion

	//#region events
	measureView(event) {
		this.props.resizeAll(event.nativeEvent.layout);
	}
	//#endregion

	//#region render methods
	renderGlobalChat() {
		if (!this.props.isTabChatButtonActive || !this.props.isGlobalChatOpen) return null;
		return <GlobalChat />;
	}

	renderGameLobby() {
		if (this.props.isGameStarted || !this.props.isLobbyOpen || !this.props.isTabGamesButtonActive) return null;
		return <GameLobby />;
	}

	renderCreateNewGame() {
		if (this.props.isGameStarted || !this.props.isCreateNewGameOpen || !this.props.isTabGamesButtonActive) return null;
		return <CreateNewGame />;
	}

	renderGameRoom() {
		if (this.props.isGameStarted || !this.props.isGameRoomOpen || !this.props.isTabGamesButtonActive) return null;
		return (
			<>
				<KeepAwake />
				<GameRoom />
			</>
		);
	}

	renderStartedGame() {
		if (!this.props.isGameStarted || !this.props.isTabGamesButtonActive) return null;
		return (
			<AppBackImage>
				<KeepAwake />
				<GameBoard />
			</AppBackImage>
		);
	}

	renderLeaderBoard() {
		if (
			this.props.resolution == RESOLUTION.LOW ||
			!this.props.isLeaderBoardsMenuOpened ||
			this.props.isPlayerOverviewOpened
		)
			return null;
		return <LeaderBoardsMenu />;
	}

	renderGameMessages() {
		if (!this.props.isGameMessageOpened) return null;
		return <GameMessage />;
	}

	renderTournaments() {
		if (!this.props.isTournamentTabOpened) return null;
		return <TournamentLobby />;
	}

	renderGameShop() {
		if (!this.props.isShopOpen || !this.props.isTabShopButtonActive) return null;
		return <GameShopTablet />;
	}

	renderSettings() {
		if (!this.props.isSettingsOpened) return null;
		return (
			<View style={{ width: "100%", height: "100%", top: 0, left: 0 }}>
				<Settings />
			</View>
		);
	}

	renderGameHelp() {
		if (!this.state.gameHelpVisible) return null;
		return (
			<GamePopup
				posButAction={() => this.setState({ gameHelpVisible: false })}
				negButAction
				posButText="OK"
				negButText=""
				visibleModal={this.state.gameHelpVisible}
			/>
		);
	}

	renderNavigationMenu() {
		if (
			!this.props.isGameStarted ||
			!this.props.isTabGamesButtonActive ||
			this.props.isSettingsOpened ||
			this.props.isGameEnded ||
			this.props.isShopOpen
		)
			return null;

		return (
			<NavigationMenu
				openHelp={() => this.setState({ gameHelpVisible: true })}
				closeHelp={() => this.setState({ gameHelpVisible: false })}
			/>
		);
	}

	renderModal() {
		if (!this.props.visibleModal) return null;
		return <CustomModal />;
	}

	renderPlayerOverview() {
		if (!this.props.isPlayerOverviewOpened) return null;
		return <PlayerOverview />;
	}

	renderInnerContent() {
		if (
			(!this.props.isGameStarted && this.props.isTabShopButtonActive) ||
			this.props.isGameEndOpened ||
			this.props.isLeaderBoardsMenuOpened ||
			this.props.isPlayerOverviewOpened ||
			this.props.isGameMessageOpened ||
			this.props.isTournamentTabOpened
		)
			return null;
		return (
			<View style={{ flex: 1 }}>
				{this.renderGlobalChat()}
				{this.renderGameLobby()}
				{this.renderCreateNewGame()}
				{this.renderGameRoom()}
				{this.renderStartedGame()}
			</View>
		);
	}

	renderBanner() {
		return null;
		//TODO: temporary disabled return <Banner />;
	}

	renderGameEnd() {
		if (!this.props.isGameEndOpened || !this.props.isTabGamesButtonActive || this.props.isShopOpen) return null;
		return <GameEnd />;
	}

	renderContent() {
		if (this.props.loading) {
			return <Spinner size="large" />;
		} else {
			return (
				<View style={[styles.container]} onLayout={(event) => this.measureView(event)}>
					{this.renderBanner()}
					{this.renderLeaderBoard()}
					{this.renderGameMessages()}
					{this.renderTournaments()}
					{this.renderInnerContent()}
					{this.renderGameShop()}
					{this.renderGameEnd()}
					{this.renderNavigationMenu()}
					{this.renderSettings()}
					{this.renderGameHelp()}
					{this.renderPlayerOverview()}
					{this.renderModal()}
				</View>
			);
		}
	}

	renderMain() {
		if (this.props.isFetchInProgress) {
			return (
				<View style={styles.container} onLayout={(event) => this.measureView(event)}>
					<Spinner size="large" />
					{this.renderModal()}
				</View>
			);
		}
		// MyWebSocket.shared.cMessageID = store.getState().socket.cMessageID;
		return this.renderContent();
	}

	render() {
		if ((this.props.isGameStarted || this.props.isGameEndOpened) && this.props.isTabGamesButtonActive)
			return (
				<View style={{ flex: 1 }}>
					{this.renderMain()}
					{!this.props.isWelcomeReceived && <View key="inactiveLayer" style={styles.disconnectedLayer} />}
				</View>
			);
		return (
			<ImageBackground source={this.props.MENU_PLAY_BACK.url} style={{ flex: 1 }}>
				{this.renderMain()}
				{!this.props.isWelcomeReceived && <View key="inactiveLayer" style={styles.disconnectedLayer} />}
			</ImageBackground>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundImage: { flex: 1 },
	container: {
		flex: 1,
		height: "100%",
		overflow: "hidden",
	},
	disconnectedLayer: {
		width: "100%",
		height: "100%",
		overflow: "hidden",
		position: "absolute",
		zIndex: 9999,
		backgroundColor: "#000",
		opacity: 0.5,
	},
});

const mapStateToProps = (state) => {
	return {
		loading: state.socket.loading,
		isLobbyOpen: state.tab.isLobbyOpen,
		isCreateNewGameOpen: state.tab.isCreateNewGameOpen,
		isGameRoomOpen: state.tab.isGameRoomOpen,
		isGameStarted: state.tab.isGameStarted,
		isGameEndOpened: state.tab.isGameEndOpened,
		isGlobalChatOpen: state.tab.isGlobalChatOpen,
		isShopOpen: state.tab.isShopOpen,
		isTabChatButtonActive: state.tab.isTabChatButtonActive,
		isTabGamesButtonActive: state.tab.isTabGamesButtonActive,
		isTabShopButtonActive: state.tab.isTabShopButtonActive,
		visibleModal: state.modal.visibleModal,
		isFetchInProgress: state.fetchData.isFetchInProgress,
		isSettingsOpened: state.app.isSettingsOpened,
		isGameEnded: state.startedGame.gameEnd.isGameEnded,
		isLeaderBoardsMenuOpened: state.app.isLeaderBoardsMenuOpened,
		isPlayerOverviewOpened: state.app.isPlayerOverviewOpened,
		resolution: state.currentUser.preferences.resolution,
		isGameMessageOpened: state.app.isGameMessageOpened,
		isTournamentTabOpened: state.app.isTournamentTabOpened,
		isWelcomeReceived: state.app.isWelcomeReceived,
		MENU_PLAY_BACK: state.images.MENU_PLAY_BACK,
	};
};

const mapDispatchToProps = {
	resizeAll,
	//TODO: remove if socket is OK
	// authenticateSucceeded,
	// processGameStarted,
	//TODO: end remove comment
};

export default connect(mapStateToProps, mapDispatchToProps)(Play);
