import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { ImageBackground, Platform, SafeAreaView, ScrollView, StyleSheet, Switch, Text, View } from "react-native";
import { connect } from "react-redux";
import {
	f_sourcesansprosemibold,
	f_sourcesansprosemibolditalic,
	isWebOrTablet,
	leaderboard_Type,
	RESOLUTION,
} from "../../../config/defaults";
import { ACTIVE_CATEGORY, INACTIVE_CATEGORY } from "../../../config/images";
import { allowPlayerInfo } from "../../../config/permissions";
import { MyWebSocket } from "../../../connection";
import { mapObject } from "../../../helpers/commonHelpers";
import {
	closeLeaderBoards,
	closeLeaderBoardsMenu,
	goBackToMain,
	requestLeaderBoard,
	selectLBCategory,
	subscribeToGameList,
} from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { AppTouchableOpacity, NavigationHeader, PlayerInfo } from "../../common";
import { handleError } from "../../ErrorHandler";
import GameShopStack from "../../GameShop/GameShopStack";
import GroupList from "./GroupList";
import LeaderBoardTable from "./LeaderBoardTable";

class LeaderBoards extends Component {
	constructor(props) {
		super(props);
		const { leaderBoardCategories } = this.props.lang.leaderBoardsMenu;
		var categories = [];
		mapObject(leaderBoardCategories, (key, val, ind) => {
			categories.push({
				catID: key,
				name: val,
				selected: ind == 0,
			});
		});
		this.state = {
			isRelative: true,
			categories: categories,
			maxVisibleRows: 1,
			showPlayerInfo: false,
			selectedPlayer: null,
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		if (this.props.isWelcomeReceived && this.props.authenticated) this.requestFriendBanLists();
		Analytics.logScreenView("Leaderboards");
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.lbCategorySelected != this.props.lbCategorySelected ||
			prevState.isRelative != this.state.isRelative
		) {
			this.props.requestLeaderBoard(this.state.maxVisibleRows, this.state.isRelative);
		}
		if (this.props.groups != prevProps.groups) {
			const prevSelected = prevProps.groups.findIndex((gr) => gr.selected == true);
			const curSelected = this.props.groups.findIndex((gr) => gr.selected == true);
			if (prevSelected != curSelected) {
				this.props.requestLeaderBoard(this.state.maxVisibleRows, this.state.isRelative);
			}
		}

		if (prevState.selectedPlayer !== null && this.state.selectedPlayer === null) {
			var curSelected;
			if (this.props.lbTypeSelected == leaderboard_Type.GROUPS) {
				curSelected = this.props.groups.find((gr) => gr.selected == true);
			}
			if (this.props.lbTypeSelected == leaderboard_Type.FRIENDS || (curSelected && curSelected.groupID == 2)) {
				this.props.requestLeaderBoard(this.state.maxVisibleRows, this.state.isRelative);
			}
		}
	}
	//#endregion

	//#region events
	requestFriendBanLists() {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "getFriendList",
		});
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "getBanList",
		});
	}

	onFriendAction(item) {
		try {
			if (item.isFriend == true) {
				//remove from friendlist
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "removeFromFriendList",
						userID: item.userID,
					});
				}
			} else {
				//add to friendlist
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "addToFriendList",
						userID: item.userID,
					});
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onBanAction(item) {
		try {
			if (item.isBanned == true) {
				//remove from banList
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "removeFromBanList",
						userID: item.userID,
					});
				}
			} else {
				//add to banList
				if (typeof item.userID === "string") {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "addToBanList",
						userID: item.userID,
					});
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onBackPress() {
		if (this.state.showPlayerInfo) {
			this.setState({ showPlayerInfo: false, selectedPlayer: null });
		} else {
			this.props.closeLeaderBoards();
			if (this.props.resolution !== RESOLUTION.LOW) {
				this.props.closeLeaderBoardsMenu();
				this.props.subscribeToGameList();
			}
		}
	}

	selectPlayer(player) {
		if (allowPlayerInfo == true) {
			var _player = player != undefined && player.userID != undefined ? player : null;
			this.setState({ showPlayerInfo: true, selectedPlayer: _player });
		}
	}

	onCategorySelect(category) {
		try {
			var foundIndex = this.state.categories.findIndex((c) => c.catID == category.catID);
			if (foundIndex >= 0) {
				var clone = cloneDeep(this.state.categories);
				clone.forEach((c) => (c.selected = false));
				clone[foundIndex].selected = true;
				this.setState({ categories: clone });
				this.props.selectLBCategory(category.catID);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onSwitchPress() {
		try {
			this.setState({ isRelative: !this.state.isRelative });
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			>
				<GameShopStack />
			</NavigationHeader>
		);
	}

	renderCategories() {
		var categories = [];
		var categoryImageStyle = styles.categoryImage,
			categoryTextStyle = styles.categoryText,
			categoryButtonStyle = styles.categoryButton;
		if (this.props.resolution === RESOLUTION.HIGH) {
			categoryImageStyle = styles.categoryImageHR;
			categoryTextStyle = [styles.categoryText, { fontSize: 28, height: 53 }];
			categoryButtonStyle = [styles.categoryButton, { width: 170, height: 70, paddingRight: 3, paddingBottom: 0 }];
		}
		this.state.categories.forEach((cat) => {
			categories.push(
				<ImageBackground
					key={cat.catID}
					source={cat.selected ? ACTIVE_CATEGORY : INACTIVE_CATEGORY}
					resizeMode="stretch"
					style={categoryImageStyle}
				>
					<AppTouchableOpacity activeOpacity={1} onPress={() => this.onCategorySelect(cat)} style={categoryButtonStyle}>
						<Text allowFontScaling={false} numberOfLines={1} style={categoryTextStyle}>
							{cat.name}
						</Text>
					</AppTouchableOpacity>
				</ImageBackground>
			);
		});
		return <View style={styles.categoryContainer}>{categories}</View>;
	}

	renderSwitch() {
		if (this.props.lbTypeSelected == leaderboard_Type.FRIENDS) return null;
		const selGroupIndex = this.props.groups.findIndex((gr) => gr.selected == true);
		const _disabled = this.props.lbTypeSelected == leaderboard_Type.GROUPS && selGroupIndex == 1;
		var switchStyle = styles.switchButton,
			switchContStyle = [styles.switchContainer, { opacity: _disabled ? 0 : 1 }],
			_switchTitle = styles.switchTitle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			switchStyle = [
				styles.switchButton,
				{
					transform: [{ scale: 1.75 }],
					marginTop: Platform.OS === "web" ? 10 : 5,
				},
			];
			switchContStyle = [styles.switchContainer, { opacity: _disabled ? 0 : 1, marginBottom: 20 }];
			_switchTitle = [styles.switchTitle, { fontSize: 22 }];
		}
		return (
			<View style={switchContStyle}>
				<Text allowFontScaling={false} style={_switchTitle}>
					{this.props.lang.leaderBoardsMenu.switchTitle}
				</Text>
				{Platform.OS !== "web" && (
					<Switch
						disabled={_disabled}
						value={this.state.isRelative}
						trackColor={{ false: "grey", true: "#00e600" }}
						onValueChange={this.onSwitchPress.bind(this)}
						style={switchStyle}
						ios_backgroundColor={"grey"}
					/>
				)}
				{Platform.OS === "web" && (
					<Switch
						disabled={_disabled}
						value={this.state.isRelative}
						activeTrackColor={this.state.isRelative ? "#00e600" : "grey"}
						onValueChange={this.onSwitchPress.bind(this)}
						style={switchStyle}
					/>
				)}
			</View>
		);
	}

	renderLeftColumn() {
		var _leftColumnContainer = styles.leftColumnContainer,
			_leftContentContainerStyle = styles.leftContentContainerStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_leftColumnContainer = [styles.leftColumnContainer, { maxWidth: 200 }];
		}
		if (!isWebOrTablet) {
			_leftContentContainerStyle = [styles.leftContentContainerStyle, { paddingTop: 0 }];
		}
		return (
			<ScrollView
				horizontal={false}
				style={_leftColumnContainer}
				contentContainerStyle={_leftContentContainerStyle}
				showsVerticalScrollIndicator={false}
			>
				{this.renderSwitch()}
				{this.renderCategories()}
			</ScrollView>
		);
	}

	renderRightColumn() {
		return (
			<View style={styles.rightColumnContainer}>
				{this.props.lbTypeSelected == leaderboard_Type.GROUPS && <GroupList />}
				<View style={styles.tableContainer}>
					<LeaderBoardTable
						isRelative={this.state.isRelative}
						selectPlayer={this.selectPlayer.bind(this)}
						onTableLayout={(maxVisibleRows) => {
							this.setState({ maxVisibleRows: maxVisibleRows });
							if (this.props.lbCategorySelected == null) {
								this.props.selectLBCategory(this.state.categories[0].catID);
							}
						}}
					/>
				</View>
			</View>
		);
	}

	renderPlayerInfo() {
		if (this.state.selectedPlayer == null) return null;

		return (
			<PlayerInfo
				lang={this.props.lang}
				player={this.state.selectedPlayer}
				chatAction={() => {}}
				messageAction={() => {}}
				friendAction={() => this.onFriendAction(this.state.selectedPlayer)}
				banAction={() => this.onBanAction(this.state.selectedPlayer)}
				userDetails={this.props.userDetails}
				backAction={this.onBackPress.bind(this)}
			/>
		);
	}

	renderContent() {
		if (this.props.isPlayerOverviewOpened) return null;
		return (
			<View style={styles.container}>
				<View style={[styles.contentContainer, this.props.resolution === RESOLUTION.HIGH && { marginTop: 10 }]}>
					{!this.state.showPlayerInfo && this.renderLeftColumn()}
					{!this.state.showPlayerInfo && this.renderRightColumn()}
					{this.state.showPlayerInfo && this.renderPlayerInfo()}
				</View>
			</View>
		);
	}

	render() {
		return (
			<ImageBackground source={this.props.MENU_PLAY_BACK.url} style={styles.backgroundContainer}>
				<SafeAreaView style={{ flex: 1 }}>{this.renderContent()}</SafeAreaView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1 },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	contentContainer: {
		flex: 1,
		flexDirection: "row",
		marginHorizontal: 5,
		marginBottom: 5,
	},
	leftColumnContainer: {
		maxWidth: 150,
	},
	leftContentContainerStyle: {
		paddingTop: isWebOrTablet ? 30 : 25,
		paddingBottom: 10,
	},
	rightColumnContainer: {
		flex: 1,
		justifyContent: "center",
		paddingBottom: 7,
		paddingRight: 12,
	},
	tableContainer: {
		flex: 1,
		paddingBottom: 1,
		paddingRight: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
	},
	categoryContainer: { marginLeft: 10 },
	categoryImage: { width: 120, height: 60 },
	categoryImageHR: { width: 170, height: 70 },
	categoryButton: {
		width: 112,
		height: 50,
		marginLeft: 3,
		paddingBottom: 3,
		justifyContent: "center",
	},
	categoryText: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: 20,
		color: "#085968",
		textAlign: "center",
	},
	switchContainer: { marginBottom: 5 },
	switchTitle: {
		fontFamily: f_sourcesansprosemibolditalic,
		fontSize: 14,
		color: "#FFFFFF",
		alignSelf: "center",
	},
	switchButton: {
		transform: [{ scale: Platform.OS === "ios" ? 0.9 : 1.25 }],
		marginVertical: 5,
		alignSelf: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		lbTypeSelected: state.leaderboard.lbTypeSelected,
		lbCategorySelected: state.leaderboard.lbCategorySelected,
		groups: state.leaderboard.groups,
		resolution: state.currentUser.preferences.resolution,
		userDetails: state.currentUser.userDetails,
		isPlayerOverviewOpened: state.app.isPlayerOverviewOpened,
		isWelcomeReceived: state.app.isWelcomeReceived,
		authenticated: state.currentUser.authenticated,
		MENU_PLAY_BACK: state.images.MENU_PLAY_BACK,
	};
};

const mapDispatchToProps = {
	closeLeaderBoards,
	closeLeaderBoardsMenu,
	goBackToMain,
	requestLeaderBoard,
	selectLBCategory,
	subscribeToGameList,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoards);
