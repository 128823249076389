import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { gameDetailsTableKey, RESOLUTION } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { MyWebSocket } from "../../connection";
import { GameRuleDetails } from "../../Keezers/components/common";
import { leaveGame, openModalDialog, sendGlobalChatMessage } from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { ChatMessageSender, HeaderText, NavigationHeader, Spinner } from "../common";
import { handleError } from "../ErrorHandler";
import GameShopStack from "../GameShop/GameShopStack";
import CreateNewGameTable from "./CreateNewGameTable";
import GameRoomButtons from "./GameRoomButtons";
import GameRoomChat from "./GameRoomChat";

class GameRoom extends Component {
	constructor(props) {
		super(props);
		this.activeRoomID = -1;
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("GameCreated");
	}
	//#endregion

	//#region events
	sendGameChatMessage(msgText) {
		try {
			if (typeof msgText === "string" && msgText != "") {
				if (this.activeRoomID == -1) {
					const msgGameChat = {
						type: "gameChat",
						sMessageID: 0,
						gameID: this.props.gameRoomsGameId,
						content: msgText,
					};
					MyWebSocket.shared.sendMsg(msgGameChat);
				} else {
					this.props.sendGlobalChatMessage(msgText, this.activeRoomID);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onClosePress() {
		// this.props.openModalDialog(
		//   modalType.DIALOG,
		//   this.props.lang.messages.gamePlayerLeaveWarningMessage,
		//   this.props.lang.yes,
		//   () => {
		try {
			const { msgGamePlayerLeave } = this.props;
			if (msgGamePlayerLeave.gameID && msgGamePlayerLeave.gameID > 0) {
				MyWebSocket.shared.sendMsg(msgGamePlayerLeave);
				this.props.leaveGame();
			}
		} catch (error) {
			handleError(error);
		}
		//   },
		//   this.props.lang.no,
		//   null
		// );
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={() => this.onClosePress()}
				closeAction={() => this.onClosePress()}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.btnReturnToLobby}
				resolution={this.props.resolution}
			>
				<GameShopStack />
			</NavigationHeader>
		);
	}

	renderSpinner() {
		if (this.props.loadingGameRoom) {
			return <Spinner size="large" />;
		}
		return null;
	}

	renderContent() {
		if (!this.props.loadingGameRoom) {
			return (
				<View style={[styles.gameDetailsContainer, { flexDirection: "column-reverse" }]}>
					<GameRuleDetails rulesList={this.props.gameRoomsRulesList} tableKey={gameDetailsTableKey.joinedGame} />
					<CreateNewGameTable isGameCreator={this.props.grIsGameCreator} tableKey={gameDetailsTableKey.joinedGame} />
				</View>
			);
		}
		return null;
	}

	renderGameDetails() {
		return (
			<View style={[styles.halfWidth, { marginLeft: 5 }]}>
				<View
					style={[
						{
							height:
								this.props.resolution !== RESOLUTION.LOW
									? this.props.grSlotSettingsHeight + 13
									: this.props.grSlotSettingsHeight + 8,
							marginTop: this.props.resolution !== RESOLUTION.LOW ? -5 : 0,
						},
					]}
				>
					{this.props.resolution !== RESOLUTION.LOW && (
						<HeaderText
							textContent={this.props.lang.gameLobby.gameDetails.gameDetailsHeader}
							style={{ height: gameDimensions.boxHeaderHeight }}
						/>
					)}
					{this.renderSpinner()}
					{this.renderContent()}
				</View>
				<GameRoomButtons style={{ width: "100%" }} />
			</View>
		);
	}

	renderGameChat() {
		const isGameChatNameDefined = typeof this.props.gameChatName === "string" && this.props.gameChatName != "";
		return (
			<View style={styles.halfWidth}>
				{isGameChatNameDefined && (
					<HeaderText
						textContent={this.props.gameChatName}
						style={{ height: gameDimensions.boxHeaderHeight, marginTop: -5 }}
					/>
				)}
				<GameRoomChat
					setActiveRoom={(activeRoomID) => {
						this.activeRoomID = activeRoomID;
					}}
				/>
				{this.renderChatInput()}
			</View>
		);
	}

	renderChatInput() {
		return (
			<View
				style={{
					width: this.props.chatMainWidth,
					marginLeft: this.props.chatMainLeftMargin,
					zIndex: 999,
				}}
			>
				<ChatMessageSender
					isInGameRoom={true}
					lang={this.props.lang}
					isEnabled={!this.props.loadingGameRoom}
					chatButtonPress={this.sendGameChatMessage.bind(this)}
					style={{
						backgroundColor: "transparent",
						width: this.props.chatMainWidth,
					}}
				/>
			</View>
		);
	}

	render() {
		const { isGameRoomOpen, isTabGamesButtonActive, isTabShopButtonActive } = this.props;
		if (!isGameRoomOpen || !isTabGamesButtonActive || isTabShopButtonActive) return null;

		return (
			<View style={styles.container}>
				{this.renderHeader()}
				<View style={styles.detailsContainer}>
					{this.renderGameChat()}
					{this.renderGameDetails()}
				</View>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { flex: 1 },
	detailsContainer: {
		flex: 1,
		flexDirection: "row",
		paddingHorizontal: 3,
		marginTop: 55,
		marginBottom: 3,
	},
	halfWidth: { flex: 2 },
	gameDetailsContainer: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
		overflow: "hidden",
	},
});

const mapStateToProps = (state) => {
	const { features } = state.currentUser;
	return {
		lang: state.language.currentLanguage,
		isGameRoomOpen: state.tab.isGameRoomOpen,
		isTabGamesButtonActive: state.tab.isTabGamesButtonActive,
		isTabShopButtonActive: state.tab.isTabShopButtonActive,
		loadingGameRoom: state.gameRoom.loadingGameRoom,
		grIsGameCreator: state.gameRoom.grIsGameCreator,
		gameChatName: state.gameRoom.gameChatName,
		gameName: state.gameRoom.gameName,
		gameRoomsGameId: state.gameRoom.gameRoomsGameId,
		gameRoomsRulesList: state.gameRoom.gameRoomsRulesList,
		msgGamePlayerLeave: state.gameRoom.msgGamePlayerLeave,
		grSlotSettingsHeight: state.views.grSlotSettingsHeight,
		GAME_CREATE_CAN_SET_NAME: features.GAME_CREATE_CAN_SET_NAME,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {
	leaveGame,
	openModalDialog,
	sendGlobalChatMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameRoom);
