import React, { Component } from "react";
import { Animated, Image, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import {
	CLOSE_MENU_BUTTON,
	EXIT_BUTTON,
	HELP_BUTTON,
	MUTED_BUTTON,
	NAV_MENU_BACK,
	OPEN_MENU_BUTTON,
	RELOAD_BUTTON,
	SHOP_BUTTON,
	VOLUME_BUTTON,
} from "../config/images";
import { MyWebSocket } from "../connection";
import { EventQueue } from "../controller";
import DebugLogger from "../controller/DebugLogger";
import { finishCountdown } from "../Keezers/redux/actions";
import DeviceInfo from "../libraries/DeviceInfo";
import {
	leaveGame,
	openGameLobby,
	openModalDialog,
	resetCreateNewGame,
	resetGameEnd,
	resetGameRoom,
	// openSettings,
	// toggleNavMenu,
	toggleSystemSound,
} from "../redux/actions";
import Analytics from "./Analytics/Analytics";
import { AppTouchableOpacity } from "./common";
import { handleError } from "./ErrorHandler";

class NavigationMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			animNavMenuBackScale: new Animated.Value(0),
			animNavMenuBackTranslateX: new Animated.Value(-225),
			animNavMenuBackTranslateY: new Animated.Value(225),
			animNavMenuBackOpacity: new Animated.Value(0),
			animOpacity: new Animated.Value(0),
			isMenuOpen: false,
			openCloseInProgress: false,
			enableReload: true,
		};

		this.toggleTimeout = null;
		this.reloadTimeout = null;
	}

	//#region lifecycle methods
	componentDidMount() {
		this.setAnimatedState();
	}

	componentWillUnmount() {
		if (this.toggleTimeout) clearTimeout(this.toggleTimeout);
		if (this.reloadTimeout) clearTimeout(this.reloadTimeout);
		// if (this.state.isMenuOpen == true) this.toggleNavMenu();
	}
	//#endregion

	//#region events
	toggleNavMenu() {
		try {
			this.setState({ isMenuOpen: !this.state.isMenuOpen });
		} catch (error) {
			handleError(error);
		}
	}

	animateNavMenuBack() {
		try {
			this.setState({ openCloseInProgress: true });
			if (this.state.isMenuOpen) {
				Animated.parallel(
					[
						Animated.timing(this.state.animNavMenuBackScale, {
							toValue: 0,
							duration: 300,
							useNativeDriver: Platform.OS !== "web",
						}),
						Animated.timing(this.state.animNavMenuBackTranslateX, {
							toValue: -225,
							duration: 200,
							useNativeDriver: Platform.OS !== "web",
						}),
						Animated.timing(this.state.animNavMenuBackTranslateY, {
							toValue: 225,
							duration: 200,
							useNativeDriver: Platform.OS !== "web",
						}),
						Animated.timing(this.state.animNavMenuBackOpacity, {
							toValue: 0,
							duration: 300,
							useNativeDriver: Platform.OS !== "web",
						}),
						Animated.timing(this.state.animOpacity, {
							toValue: 0,
							duration: 50,
							useNativeDriver: Platform.OS !== "web",
						}),
					],
					{ useNativeDriver: Platform.OS !== "web" }
				).start((data) => {
					if (data.finished) this.setState({ openCloseInProgress: false });
				});
				if (this.toggleTimeout) {
					clearTimeout(this.toggleTimeout);
					this.toggleTimeout = null;
				}
				this.toggleTimeout = setTimeout(() => {
					this.toggleNavMenu();
				}, 300);
			} else {
				if (this.toggleTimeout) {
					clearTimeout(this.toggleTimeout);
					this.toggleTimeout = null;
				}
				this.toggleTimeout = setTimeout(() => {
					this.toggleNavMenu();
				}, 300);
				Animated.parallel(
					[
						Animated.timing(this.state.animNavMenuBackScale, {
							toValue: 1,
							duration: 150,
							useNativeDriver: Platform.OS !== "web",
						}),
						Animated.timing(this.state.animNavMenuBackTranslateX, {
							toValue: 0,
							duration: 300,
							useNativeDriver: Platform.OS !== "web",
						}),
						Animated.timing(this.state.animNavMenuBackTranslateY, {
							toValue: 0,
							duration: 300,
							useNativeDriver: Platform.OS !== "web",
						}),
						Animated.timing(this.state.animNavMenuBackOpacity, {
							toValue: 1,
							duration: 300,
							useNativeDriver: Platform.OS !== "web",
						}),
						Animated.timing(this.state.animOpacity, {
							toValue: 1,
							delay: 300,
							duration: 50,
							useNativeDriver: Platform.OS !== "web",
						}),
					],
					{ useNativeDriver: Platform.OS !== "web" }
				).start((data) => {
					if (data.finished) this.setState({ openCloseInProgress: false });
				});
			}
		} catch (error) {
			handleError(error);
		}
	}

	async onExitPress() {
		// this.props.openModalDialog(
		//   modalType.DIALOG,
		//   this.props.lang.messages.gamePlayerLeaveWarningMessage,
		//   this.props.lang.yes,
		//   () => {
		try {
			if (this.props.gameID > 0) {
				if (!this.props.isSpectator) {
					this.props.leaveGame();
					const msgGamePlayerLeave = {
						type: "leaveGame",
						sMessageID: 0,
						gameID: this.props.gameID,
					};
					MyWebSocket.shared.sendMsg(msgGamePlayerLeave);
					DebugLogger.shared.storedForReset = [];
					await Analytics.logEvent("finishedGame");
				} else {
					if (!this.props.isSpectatedGameClosed) {
						const msgStopSpectatingGame = {
							type: "stopSpectatingGame",
							sMessageID: 0,
							gameID: this.props.gameID,
						};
						MyWebSocket.shared.sendMsg(msgStopSpectatingGame);
					}
					DebugLogger.shared.storedForReset = [];
					await Analytics.logEvent("stopSpectatingGame");
					EventQueue.shared.resetEventsQueue();
					this.props.finishCountdown();
					this.props.resetGameEnd();
					this.props.resetGameRoom();
					this.props.resetCreateNewGame();
					this.props.openGameLobby();
				}
			}
		} catch (error) {
			handleError(error);
		}
		//   },
		//   this.props.lang.no,
		//   null
		// );
	}

	onReloadGamePress() {
		try {
			var msgGameChat = {
				content: "/reset",
				type: "gameChat",
				sMessageID: 0,
				gameID: this.props.gameID,
			};
			MyWebSocket.shared.sendMsg(msgGameChat);
			this.setState({ enableReload: false });

			if (this.reloadTimeout) {
				clearTimeout(this.reloadTimeout);
				this.reloadTimeout = null;
			}
			this.reloadTimeout = setTimeout(() => {
				this.setState({ enableReload: true });
			}, 20000);
		} catch (error) {
			handleError(error);
		}
	}

	onHelpPress() {
		if (typeof this.props.openHelp === "function") this.props.openHelp();
	}

	onShopOpenPress() {
		try {
			DebugLogger.shared.saveLog({ OSB_message: "openShop button pressed" }, true);
			var newMsg = {
				sMessageID: -1,
				type: "openInGameShop",
			};
			EventQueue.shared.processServerEvent(newMsg);
			if (typeof this.props.closeHelp === "function") this.props.closeHelp();
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderTopRight() {
		var hasNotch = false;
		if (Platform.OS === "ios") {
			hasNotch = DeviceInfo.hasNotch();
		}
		return (
			<View
				style={[
					styles.topRightContainer,
					Platform.OS === "ios" &&
						hasNotch && {
							right: this.props.appOrientation === "LANDSCAPE-RIGHT" ? 35 : 0,
						},
				]}
			>
				<Animated.View
					style={[
						{
							width: Platform.OS !== "web" ? (this.state.isMenuOpen ? 225 : 70) : this.state.isMenuOpen ? 225 : 0,
							height: Platform.OS !== "web" ? (this.state.isMenuOpen ? 225 : 70) : this.state.isMenuOpen ? 225 : 0,
							transform: [{ rotate: "-180deg" }],
						},
					]}
				>
					<Animated.Image
						source={NAV_MENU_BACK}
						style={[
							styles.backMainImage,
							{
								opacity: this.state.animNavMenuBackOpacity,
								transform: [
									{ scale: this.state.animNavMenuBackScale },
									{ translateX: this.state.animNavMenuBackTranslateX },
									{ translateY: this.state.animNavMenuBackTranslateY },
								],
								width: this.state.isMenuOpen ? 225 : 0,
								height: this.state.isMenuOpen ? 225 : 0,
							},
						]}
						resizeMode="stretch"
					/>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen}
						style={[this.state.isMenuOpen ? styles.buttonDim : {}, styles.firstButton]}
						onPress={this.onExitPress.bind(this)}
						accessibilityLabel={"leave game"}
					>
						<Animated.Image
							source={EXIT_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
									transform: [{ rotate: "-180deg" }],
								},
							]}
						/>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen}
						style={[this.state.isMenuOpen ? styles.buttonDim : {}, styles.secondButton]}
						onPress={this.onShopOpenPress.bind(this)}
						accessibilityLabel={"open game shop"}
					>
						<Animated.Image
							source={SHOP_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
									transform: [{ rotate: "-180deg" }],
								},
							]}
						/>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen || !this.state.enableReload}
						onPress={this.onReloadGamePress.bind(this)}
						// disabled={this.props.isGuestUser}
						style={[
							this.state.isMenuOpen ? styles.buttonDim : {},
							styles.thirdButton,
							// { opacity: this.props.isGuestUser ? 0.4 : 1 },
							{ opacity: !this.state.enableReload ? 0.4 : 1 },
						]}
						accessibilityLabel={"reload game"}
					>
						<Animated.Image
							source={RELOAD_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
									transform: [{ rotate: "-180deg" }],
								},
							]}
						/>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen}
						style={[this.state.isMenuOpen ? styles.buttonDim : {}, styles.fourthButton]}
						onPress={this.props.toggleSystemSound}
						accessibilityLabel={this.props.isMuted ? "unmute" : "mute"}
					>
						<Animated.Image
							source={this.props.isMuted ? MUTED_BUTTON : VOLUME_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
									transform: [{ rotate: "-180deg" }],
								},
							]}
						/>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen}
						onPress={this.onHelpPress.bind(this)}
						style={[this.state.isMenuOpen ? styles.buttonDim : {}, styles.lastButton]}
						accessibilityLabel={"open help"}
					>
						<Animated.Image
							source={HELP_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
									transform: [{ rotate: "-180deg" }],
								},
							]}
						/>
					</AppTouchableOpacity>
				</Animated.View>
				{this.state.isMenuOpen && (
					<AppTouchableOpacity
						activeOpacity={0.8}
						disabled={this.state.openCloseInProgress}
						onPress={this.animateNavMenuBack.bind(this)}
						style={[styles.toggleMenuButton_TR, styles.buttonDim]}
						accessibilityLabel={"close game menu"}
					>
						<Image source={CLOSE_MENU_BUTTON} resizeMode="stretch" style={[styles.buttonDim]} />
					</AppTouchableOpacity>
				)}
				{!this.state.isMenuOpen && (
					<AppTouchableOpacity
						activeOpacity={0.8}
						disabled={this.state.openCloseInProgress}
						onPress={this.animateNavMenuBack.bind(this)}
						style={[styles.toggleMenuButton_TR, styles.buttonDim]}
						accessibilityLabel={"open game menu"}
					>
						<Image source={OPEN_MENU_BUTTON} resizeMode="stretch" style={[styles.buttonDim]} />
					</AppTouchableOpacity>
				)}
			</View>
		);
	}

	renderBottomLeft() {
		var hasNotch = false;
		if (Platform.OS === "ios") {
			hasNotch = DeviceInfo.hasNotch();
		}
		return (
			<View
				style={[
					styles.bottomLeftContainer,
					Platform.OS === "ios" &&
						hasNotch && {
							left: this.props.appOrientation === "LANDSCAPE-RIGHT" ? 0 : 35,
						},
				]}
			>
				<Animated.View
					style={[
						{
							width: Platform.OS !== "web" ? (this.state.isMenuOpen ? 225 : 70) : this.state.isMenuOpen ? 225 : 0,
							height: Platform.OS !== "web" ? (this.state.isMenuOpen ? 225 : 70) : this.state.isMenuOpen ? 225 : 0,
						},
					]}
				>
					<Animated.Image
						source={NAV_MENU_BACK}
						style={[
							styles.backMainImage,
							{
								opacity: this.state.animNavMenuBackOpacity,
								transform: [
									{ scale: this.state.animNavMenuBackScale },
									{ translateX: this.state.animNavMenuBackTranslateX },
									{ translateY: this.state.animNavMenuBackTranslateY },
								],
								width: this.state.isMenuOpen ? 225 : 0,
								height: this.state.isMenuOpen ? 225 : 0,
							},
						]}
						resizeMode="stretch"
					/>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen}
						onPress={this.onHelpPress.bind(this)}
						style={[this.state.isMenuOpen ? styles.buttonDim : {}, styles.firstButton]}
						accessibilityLabel={"open help"}
					>
						<Animated.Image
							source={HELP_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
								},
							]}
						/>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen}
						style={[this.state.isMenuOpen ? styles.buttonDim : {}, styles.secondButton]}
						onPress={this.props.toggleSystemSound}
						accessibilityLabel={this.props.isMuted ? "unmute" : "mute"}
					>
						<Animated.Image
							source={this.props.isMuted ? MUTED_BUTTON : VOLUME_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
								},
							]}
						/>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen || !this.state.enableReload}
						onPress={this.onReloadGamePress.bind(this)}
						// disabled={this.props.isGuestUser}
						style={[
							this.state.isMenuOpen ? styles.buttonDim : {},
							styles.thirdButton,
							// { opacity: this.props.isGuestUser ? 0.4 : 1 },
							{ opacity: !this.state.enableReload ? 0.4 : 1 },
						]}
						accessibilityLabel={"reload game"}
					>
						<Animated.Image
							source={RELOAD_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
								},
							]}
						/>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen}
						style={[this.state.isMenuOpen ? styles.buttonDim : {}, styles.fourthButton]}
						onPress={this.onShopOpenPress.bind(this)}
						accessibilityLabel={"open game shop"}
					>
						<Animated.Image
							source={SHOP_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
								},
							]}
						/>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						activeOpacity={0.4}
						disabled={this.state.openCloseInProgress || !this.state.isMenuOpen}
						style={[this.state.isMenuOpen ? styles.buttonDim : {}, styles.lastButton]}
						onPress={this.onExitPress.bind(this)}
						accessibilityLabel={"leave game"}
					>
						<Animated.Image
							source={EXIT_BUTTON}
							resizeMode="stretch"
							style={[
								this.state.isMenuOpen ? styles.buttonDim : {},
								{
									opacity: this.state.animOpacity,
								},
							]}
						/>
					</AppTouchableOpacity>
				</Animated.View>
				{this.state.isMenuOpen && (
					<AppTouchableOpacity
						activeOpacity={0.8}
						disabled={this.state.openCloseInProgress}
						onPress={this.animateNavMenuBack.bind(this)}
						style={[styles.toggleMenuButton_BL, styles.buttonDim]}
						accessibilityLabel={"close game menu"}
					>
						<Image source={CLOSE_MENU_BUTTON} resizeMode="stretch" style={[styles.buttonDim]} />
					</AppTouchableOpacity>
				)}
				{!this.state.isMenuOpen && (
					<AppTouchableOpacity
						activeOpacity={0.8}
						disabled={this.state.openCloseInProgress}
						onPress={this.animateNavMenuBack.bind(this)}
						style={[styles.toggleMenuButton_BL, styles.buttonDim]}
						accessibilityLabel={"open game menu"}
					>
						<Image source={OPEN_MENU_BUTTON} resizeMode="stretch" style={[styles.buttonDim]} />
					</AppTouchableOpacity>
				)}
			</View>
		);
	}

	render() {
		if (!this.props.boardLoaded) return null;

		switch (this.props.playerCnt) {
			case 4:
			case 5:
			case 6:
			case 7:
				return this.renderTopRight();
			case 2:
			case 3:
			case 8:
			default:
				return this.renderBottomLeft();
		}
	}
	//#endregion

	//#region helpers
	setAnimatedState() {
		try {
			const _backScale = this.state.isMenuOpen ? 1 : 0.3;
			const _opacityAnim = this.state.isMenuOpen ? 1 : 0;
			const _translateXAnim = this.state.isMenuOpen ? 0 : -225;
			const _translateYAnim = this.state.isMenuOpen ? 0 : 225;
			this.state.animNavMenuBackScale.setValue(_backScale);
			this.state.animNavMenuBackTranslateX.setValue(_translateXAnim);
			this.state.animNavMenuBackTranslateY.setValue(_translateYAnim);
			this.state.animNavMenuBackOpacity.setValue(_opacityAnim);
			this.state.animOpacity.setValue(_opacityAnim);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	bottomLeftContainer: {
		position: "absolute",
		left: 0,
		bottom: 0,
		zIndex: 900,
	},
	topRightContainer: {
		position: "absolute",
		right: 0,
		top: 0,
		zIndex: 900,
	},
	backMainImage: {
		width: 225,
		height: 225,
		position: "absolute",
		bottom: -3,
		left: -1,
		zIndex: -1,
	},
	buttonDim: {
		width: 45,
		height: 45,
	},
	toggleMenuButton_BL: {
		position: "absolute",
		bottom: 20,
		left: 20,
		borderRadius: 50,
	},
	toggleMenuButton_TR: {
		position: "absolute",
		top: 20,
		right: 20,
		borderRadius: 50,
	},
	firstButton: { position: "absolute", top: 37, left: 2, zIndex: 5 },
	secondButton: { position: "absolute", top: 55, left: 52 },
	thirdButton: { position: "absolute", top: 86, left: 95 },
	fourthButton: { position: "absolute", top: 130, left: 125 },
	lastButton: { position: "absolute", top: 178, left: 144 },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isMenuOpen: state.navMenu.isMenuOpen,
		gameID: state.startedGame.gameID,
		isSpectator: state.startedGame.isSpectator,
		isSpectatedGameClosed: state.startedGame.isSpectatedGameClosed,
		isMuted: state.sounds.isMuted,
		boardLoaded: state.startedGame.boardLoaded,
		playerCnt: state.startedGame.playerCnt,
		// isGuestUser: state.currentUser.isGuestUser,
		appOrientation: state.app.appOrientation,
	};
};

const mapDispatchToProps = {
	finishCountdown,
	resetGameEnd,
	resetGameRoom,
	resetCreateNewGame,
	openGameLobby,
	leaveGame,
	openModalDialog,
	// openSettings,
	// toggleNavMenu,
	toggleSystemSound,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
