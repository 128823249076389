import React, { Component } from "react";
import { Image, Platform, SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { appFont, isWebOrTablet } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { HEADER_CHIP_ICON_RED, HEADER_CHIP_ICON_DISABLED } from "../../config/images";
// import { GameRuleDetails } from "../../Keezers/components/common";
import {
	closeCreateTeam,
	closePoolOverview,
	closeTournamentDetails,
	closeTournamentLadderStat,
	closeTournamentPayout,
	closeTournamentScoreStat,
	closeTournamentTab,
	deselectTournament,
	goBackToMain,
	subscribeToGameList,
	subscribeToTournamentsList,
	unsubscribeFromTournamentsList,
	setTournamentScreenActive,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { HeaderText, NavigationHeader } from "../common";
import { handleError } from "../ErrorHandler";
import GameShopStack from "../GameShop/GameShopStack";
import MainNavigation from "../MainNavigation";
import CreateTeamScreen from "./CreateTeam/CreateTeamScreen";
import TournamentDetails from "./TournamentDetails";
import TournamentLadderStat from "./TournamentLadderStat";
import TournamentListTable from "./TournamentListTable";
import TournamentLobbyButtons from "./TournamentLobbyButtons";
import TournamentPayout from "./TournamentPayout";
import TournamentPoolOverview from "./TournamentPoolOverview";
import TournamentScoreStat from "./TournamentScoreStat";

class TournamentLobby extends Component {
	//#region lifecycle methods
	async componentDidMount() {
		this.props.setTournamentScreenActive(true);
		this.props.subscribeToTournamentsList();
		await Analytics.logEvent("openTournamentLobby");
		await Analytics.logScreenView("TournamentLobby");
	}

	componentWillUnmount() {
		this.props.setTournamentScreenActive(false);
		this.props.unsubscribeFromTournamentsList();
		this.props.deselectTournament();
	}
	//#endregion

	//#region events
	onBackPress() {
		try {
			if (this.props.isTourPayoutOpen) {
				this.props.closeTournamentPayout();
			} else if (this.props.isTourStatsOpen) {
				this.props.closeTournamentScoreStat();
			} else if (this.props.isTourLadderStatsOpen) {
				this.props.closeTournamentLadderStat();
			} else if (this.props.isPoolOverviewOpen) {
				this.props.closePoolOverview();
			} else if (this.props.isTournamentDetailsOpen) {
				if (this.props.createTeamOpened && this.props.newTeam) {
					this.props.closeCreateTeam();
				} else {
					this.props.closeTournamentDetails();
				}
			} else {
				this.props.closeTournamentTab();
				if (this.props.isLobbyOpen) {
					this.props.subscribeToGameList();
				}
			}
		} catch (err) {
			handleError(err);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.onBackPress.bind(this)}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			>
				{((!this.props.createTeamOpened &&
					!this.props.isTourStatsOpen &&
					!this.props.isTourLadderStatsOpen &&
					!this.props.isTournamentDetailsOpen &&
					!this.props.isTourPayoutOpen &&
					!this.props.isPoolOverviewOpen) ||
					isWebOrTablet) && <GameShopStack />}
			</NavigationHeader>
		);
	}

	renderTournamentList() {
		return (
			<View style={styles.halfWidth}>
				<HeaderText textContent={"Open tournaments"} style={{ height: gameDimensions.boxHeaderHeight }} />
				<TournamentListTable />
			</View>
		);
	}

	renderSettingsRow(type, teamName, teamIndex) {
		const { chipsRequired, totalBuyIn, registeredPlayerCnt, maxPlayerCnt, totalRounds, payoutMode } =
			this.props.selectedTournamentDetails;
		const {
			buyIn,
			prizePool,
			registeredPlayers,
			freePlaces,
			teams,
			noTeams,
			rounds,
			_payoutMode,
			conservative,
			topHeavy,
		} = this.props.lang.tournamentList.tournamentListColumns;
		var normalTextStyle = {
			lineHeight: isWebOrTablet ? 40 : 30,
			height: isWebOrTablet ? 40 : 30,
			fontFamily: appFont,
			fontSize: isWebOrTablet ? 20 : 16,
			color: "#222",
			textAlign: "left",
			textAlignVertical: "center",
		};
		let _label;
		let _content;
		switch (type) {
			case "buyIn":
				_label = buyIn;
				_content = chipsRequired.toString();
				break;
			case "prizePool":
				_label = prizePool;
				_content = totalBuyIn.toString();
				break;
			case "registeredPlayers":
				_label = registeredPlayers;
				_content = registeredPlayerCnt.toString();
				break;
			case "freePlaces":
				_label = freePlaces;
				_content = (maxPlayerCnt - registeredPlayerCnt).toString();
				break;
			case "teams":
				_label = teams;
				_content = "";
				break;
			case "team":
				_label = teamName;
				_content = "";
				break;
			case "noTeams":
				_label = noTeams;
				_content = "";
				break;
			case "rounds":
				_label = rounds;
				_content = totalRounds;
				break;
			case "payoutMode":
				_label = _payoutMode;
				_content = payoutMode === "CONSERVATIVE" ? conservative : topHeavy;
				break;
		}

		return (
			<View key={type === "team" ? teamIndex : type.toString()} style={{ flex: 1, flexDirection: "row" }}>
				<View
					style={{
						width: isWebOrTablet ? 220 : 150,
						height: isWebOrTablet ? 40 : 30,
						paddingLeft: 5,
					}}
				>
					<Text allowFontScaling={false} numberOfLines={1} style={normalTextStyle}>
						{_label}
					</Text>
				</View>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						height: isWebOrTablet ? 40 : 30,
						paddingLeft: 5,
					}}
				>
					{(type == "buyIn" || type == "prizePool") && (
						<Image
							source={type == "buyIn" && chipsRequired <= 0 ? HEADER_CHIP_ICON_DISABLED : HEADER_CHIP_ICON_RED}
							style={{
								width: 30,
								height: 30,
								marginTop: 8,
								marginRight: 5,
								alignSelf: "center",
							}}
						/>
					)}
					<Text allowFontScaling={false} numberOfLines={1} style={normalTextStyle}>
						{_content}
					</Text>
				</View>
			</View>
		);
	}

	renderTournamentSettings() {
		const tournamentDetails = this.props.selectedTournamentDetails;
		return (
			<View style={[styles.halfWidth, { marginLeft: 5 }]}>
				<View style={{ flex: 1 }}>
					<HeaderText textContent={"Tournament settings"} style={{ height: gameDimensions.boxHeaderHeight }} />
					<View style={styles.settingsContainer}>
						{tournamentDetails && (
							<ScrollView
								showsHorizontalScrollIndicator={false}
								nestedScrollEnabled={true}
								style={{ flex: 1, margin: 5 }}
							>
								{this.renderSettingsRow("buyIn")}
								{this.renderSettingsRow("prizePool")}
								{this.renderSettingsRow("registeredPlayers")}
								{this.renderSettingsRow("freePlaces")}
								{this.renderSettingsRow("rounds")}
								{this.renderSettingsRow("payoutMode")}
								{this.renderSettingsRow("teams")}
								{tournamentDetails.teams.length > 0 &&
									tournamentDetails.teams.map((team, index) => this.renderSettingsRow("team", team.name, index))}
								{tournamentDetails.teams.length === 0 && this.renderSettingsRow("noTeams")}
							</ScrollView>
						)}
					</View>
					<TournamentLobbyButtons />
				</View>
			</View>
		);
	}

	renderLobby() {
		return (
			<SafeAreaView style={{ flex: 1 }}>
				<View style={styles.detailsContainer}>
					<MainNavigation
						requestingChat={() => {
							this.requestingChat = true;
						}}
					/>
					<View style={styles.detailsInnerContainer}>
						{this.renderTournamentList()}
						{this.renderTournamentSettings()}
					</View>
				</View>
			</SafeAreaView>
		);
	}

	renderTournamentDetails() {
		return <TournamentDetails />;
	}

	renderCreateTeam() {
		return <CreateTeamScreen />;
	}

	renderTournamentScoreStat() {
		return <TournamentScoreStat />;
	}

	renderTournamentLadderStat() {
		return <TournamentLadderStat />;
	}

	renderTournamentPayout() {
		return <TournamentPayout />;
	}

	renderTournamentPoolOverview() {
		return <TournamentPoolOverview />;
	}

	renderContent() {
		if (this.props.isTourStatsOpen) {
			return this.renderTournamentScoreStat();
		} else if (this.props.isTourLadderStatsOpen) {
			return this.renderTournamentLadderStat();
		} else if (this.props.isTourPayoutOpen) {
			return this.renderTournamentPayout();
		} else if (this.props.createTeamOpened && this.props.newTeam) {
			return this.renderCreateTeam();
		} else if (this.props.isPoolOverviewOpen) {
			return this.renderTournamentPoolOverview();
		} else if (this.props.isTournamentDetailsOpen) {
			return this.renderTournamentDetails();
		} else {
			return this.renderLobby();
		}
	}

	render() {
		const { isTournamentTabOpened, isTabShopButtonActive } = this.props;
		if (!isTournamentTabOpened || isTabShopButtonActive) return null;
		return (
			<View style={styles.container}>
				{/* {Platform.OS === "ios" && this.renderHeader()} */}
				{this.renderContent()}
				{this.renderHeader()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { flex: 1 },
	detailsContainer: {
		flex: 1,
		flexDirection: "row",
		paddingHorizontal: 3,
		marginTop: 50,
		marginBottom: 3,
		marginRight: 5,
	},
	detailsInnerContainer: { flex: 1, flexDirection: "row" },
	halfWidth: { flex: 2 },
	settingsContainer: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		isTabShopButtonActive: state.tab.isTabShopButtonActive,
		isLobbyOpen: state.tab.isLobbyOpen,
		isTournamentTabOpened: state.app.isTournamentTabOpened,
		createTeamOpened: state.tournaments.createTeamOpened,
		newTeam: state.tournaments.newTeam,
		isTourStatsOpen: state.tournaments.isTourStatsOpen,
		isTourPayoutOpen: state.tournaments.isTourPayoutOpen,
		isTourLadderStatsOpen: state.tournaments.isTourLadderStatsOpen,
		isTournamentDetailsOpen: state.tournaments.isTournamentDetailsOpen,
		isPoolOverviewOpen: state.tournaments.isPoolOverviewOpen,
		selectedTournamentDetails: state.tournaments.selectedTournamentDetails,
	};
};

const mapDispatchToProps = {
	closeCreateTeam,
	closePoolOverview,
	closeTournamentDetails,
	closeTournamentLadderStat,
	closeTournamentPayout,
	closeTournamentScoreStat,
	closeTournamentTab,
	deselectTournament,
	goBackToMain,
	subscribeToGameList,
	subscribeToTournamentsList,
	unsubscribeFromTournamentsList,
	setTournamentScreenActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentLobby);
