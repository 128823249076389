import Analytics from "../../components/Analytics/Analytics";
import { modalType } from "../../config/defaults";
import { mySounds } from "../../config/sounds";
import { MyWebSocket } from "../../connection";
import { EventQueue } from "../../controller";
import { finishCountdown, newGameStarted } from "../../Keezers/redux/actions";
import { store } from "../../redux/store";
import {
	CLEAR_SLOT_SELECTION,
	DISABLE_CHANGE_SLOT,
	GAME_JOINED_RECEIVED,
	GAME_NAME_UPDATED,
	GAME_PLAYER_JOINED,
	GAME_PLAYER_LEFT,
	GAME_PUBLISHED_RECEIVED,
	INIT_GAME_ROOMS_PLAYERS,
	MOUSE_ENTER_GAME_ROOMS_SLOT,
	MOUSE_LEAVE_GAME_ROOMS_SLOT,
	PREPARE_GAME_ROOMS_RULES_LIST,
	RECEIVE_GAME_CHAT_MESSAGE,
	RESET_GAME_ROOM,
	SELECT_GAME_ROOMS_SLOT,
	UPDATE_GAME_ROOMS_SLOT,
} from "./actionTypes";
import { resetCreateNewGame } from "./CreateNewGameActions";
import { removeGame } from "./GameListDataActions";
import { openModalDialog } from "./ModalActions";
import { openGameLobby, openGameRoom } from "./NavigationActions";
import { displayNotification } from "./NotificationBarActions";
import { removeSavedEvent } from "./SocketActions";

export const initPlayersInGameRoom = (players, slots) => {
	return { type: INIT_GAME_ROOMS_PLAYERS, payload: { players, slots } };
};

export const leaveGame = (_openLobby = true) => {
	return (dispatch) => {
		const { isGameStarted, isGameEndOpened } = store.getState().tab;
		if (isGameStarted || isGameEndOpened) {
			EventQueue.shared.resetEventsQueue();
			dispatch(finishCountdown());
		}
		dispatch(resetGameRoom());
		dispatch(resetCreateNewGame());
		if (_openLobby == true) dispatch(openGameLobby());
	};
};

export const gamePublishedReceived = (msg) => {
	return async (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);
		if (responseTo != undefined && responseTo.type == "publishGame") {
			dispatch(newGameStarted());
			if (store.getState().createNewGame.canStartGame) {
				const msgStartGame = {
					type: "startGame",
					sMessageID: 0,
					gameID: msg.data.generic.gameID,
				};
				MyWebSocket.shared.sendMsg(msgStartGame);
				await Analytics.logEvent("startGame");
			} else {
				dispatch(_gamePublishedReceived(msg));
				dispatch(prepareGameRoomsRulesList());
			}
			dispatch(removeSavedEvent(msg.cMessageID));
		}
	};
};

export const gameJoinedReceived = (msg) => {
	const { playerJoinsSound } = mySounds;
	if (typeof playerJoinsSound !== "undefined" && playerJoinsSound != null && playerJoinsSound.isLoaded() == true)
		playerJoinsSound.play();
	return (dispatch) => {
		var responseTo = store.getState().socket.sentMsg.find((m) => m.cMessageID == msg.cMessageID);

		if (!msg.hasError) {
			const { tab } = store.getState();
			if (!tab.isGameRoomOpen)
				dispatch(openGameRoom(msg.data.generic.gameID, msg.data.generic.name, msg.data.specific.cardset));
			if (responseTo != undefined && responseTo.type == "joinGame") {
				dispatch(newGameStarted());
				dispatch(_gameJoinedReceived(msg));
			} else {
				dispatch(_gamePlayerJoined(msg));
			}
			dispatch(prepareGameRoomsRulesList(msg));
		} else {
			var notificationText = "";
			const lang = store.getState().language.currentLanguage;
			if (typeof msg.reason !== "undefined" && msg.reason !== null) {
				notificationText = msg.reason;
			} else {
				if (typeof lang.messages !== "undefined" && typeof lang.messages.undefinedErrorMessage !== "undefined") {
					var notificationObj = lang.messages.undefinedErrorMessage;
					if (typeof notificationObj !== "undefined") notificationText = notificationObj.main_text;
				}
			}
			var notification = {
				displayTime: 5000,
				deleteHistory: false,
				rotate: false,
				clickable: false,
				playSound: true,
				url: "#",
				priority: "high",
				announcement: notificationText,
			};
			dispatch(displayNotification(notification));
		}

		if (responseTo != undefined && responseTo.type == "joinGame") {
			dispatch(removeSavedEvent(msg.cMessageID));
		}
	};
};

export const prepareGameRoomsRulesList = (msg) => {
	const lang = store.getState().language.currentLanguage;
	return { type: PREPARE_GAME_ROOMS_RULES_LIST, payload: { lang, msg } };
};

export const selectGameRoomsSlot = (slotID) => {
	return { type: SELECT_GAME_ROOMS_SLOT, payload: slotID };
};

export const mouseEnterGameRoomsSlot = (slotID) => {
	return { type: MOUSE_ENTER_GAME_ROOMS_SLOT, payload: slotID };
};

export const mouseLeaveGameRoomsSlot = (slotID) => {
	return { type: MOUSE_LEAVE_GAME_ROOMS_SLOT, payload: slotID };
};

export const resetGameRoom = () => {
	return { type: RESET_GAME_ROOM };
};

export const updateGameName = (msg) => {
	return { type: GAME_NAME_UPDATED, payload: msg };
};

export const updateGameRoomsSlot = (newDataMsg) => {
	return (dispatch) => {
		dispatch(_updateGameRoomsSlot(newDataMsg));
	};
};

export const _updateGameRoomsSlot = (newDataMsg) => {
	const lang = store.getState().language.currentLanguage;
	return {
		type: UPDATE_GAME_ROOMS_SLOT,
		payload: { msg: newDataMsg, lang: lang },
	};
};

export const receiveGameChatMessage = (msg) => {
	const { tab, startedGame, currentUser } = store.getState();
	msg.mySound = mySounds.chatGameSound;
	if (tab.isGameRoomOpen && !tab.isGameStarted) msg.mySound = mySounds.gameChatSound;
	if (tab.isGameStarted && startedGame.players.length != 0) msg.startedGamePlayers = startedGame.players;

	if (
		currentUser.preferences.playGameChatSound == true &&
		typeof msg.mySound !== "undefined" &&
		msg.mySound != null &&
		msg.mySound.isLoaded() == true
	)
		msg.mySound.play();

	msg.gameTheme = startedGame.gameTheme;
	return { type: RECEIVE_GAME_CHAT_MESSAGE, payload: msg };
};

export const gamePlayerJoined = (msgGPJ) => {
	const { playerJoinsSound } = mySounds;
	if (typeof playerJoinsSound !== "undefined" && playerJoinsSound != null && playerJoinsSound.isLoaded() == true)
		playerJoinsSound.play();
	return (dispatch) => {
		dispatch(_gamePlayerJoined(msgGPJ));
	};
};

export const updateGameRoomWithPlayerLeft = (msg) => {
	const { grIsGameCreator } = store.getState().gameRoom;
	const { kickSound, playerLeavesSound } = mySounds;
	const { userDetails } = store.getState().currentUser;
	const lang = store.getState().language.currentLanguage;
	return (dispatch) => {
		if (grIsGameCreator) {
			if (typeof playerLeavesSound !== "undefined" && playerLeavesSound != null && playerLeavesSound.isLoaded() == true)
				playerLeavesSound.play();
			dispatch(_gamePlayerLeft(msg));
		} else {
			var notifTxt = { main_text: "", extra_text: "" };
			switch (msg.data.specific.reason) {
				case "KICK":
					if (
						typeof kickSound !== "undefined" &&
						kickSound != null &&
						kickSound.isLoaded() == true &&
						userDetails.userID == msg.userID
					)
						kickSound.play();

					if (typeof lang.messages !== "undefined" && typeof lang.messages.playerIsKickedMessage !== "undefined") {
						var notificationObj = lang.messages.playerIsKickedMessage;
						if (typeof notificationObj !== "undefined") notifTxt = notificationObj.main_text;
					}

					dispatch(leaveGame());
					break;
				case "BAN":
					if (
						typeof kickSound !== "undefined" &&
						kickSound != null &&
						kickSound.isLoaded() == true &&
						userDetails.userID == msg.userID
					)
						kickSound.play();

					if (typeof lang.messages !== "undefined" && typeof lang.messages.playerIsBannedMessage !== "undefined") {
						var notificationObj = lang.messages.playerIsBannedMessage;
						if (typeof notificationObj !== "undefined") notifTxt = notificationObj.main_text;
					}

					dispatch(leaveGame());
					dispatch(removeGame(msg.data.generic.gameID, msg.sMessageID));
					break;
				case "CHOICE":
				default:
					if (
						typeof playerLeavesSound !== "undefined" &&
						playerLeavesSound != null &&
						playerLeavesSound.isLoaded() == true
					)
						playerLeavesSound.play();
					dispatch(_gamePlayerLeft(msg));
					break;
			}
			// if (notifTxt !== "") {
			//   var notification = {
			//     displayTime: 5000,
			//     deleteHistory: false,
			//     rotate: false,
			//     clickable: false,
			//     playSound: true,
			//     url: "#",
			//     priority: "high",
			//     announcement: notifTxt,
			//     isNotification: true,
			//   };
			//   dispatch(displayNotification(notification));
			// }
			if (notifTxt.main_text != "") {
				dispatch(openModalDialog(modalType.DIALOG, notifTxt, lang.ok));
			}
		}
	};
};

export const disableChangeSlot = () => {
	return { type: DISABLE_CHANGE_SLOT };
};

export const clearSlotSelection = () => {
	return { type: CLEAR_SLOT_SELECTION };
};

const _gamePublishedReceived = (msg) => {
	const lang = store.getState().language.currentLanguage;
	return {
		type: GAME_PUBLISHED_RECEIVED,
		payload: { msg, lang, isJoined: false },
	};
};

const _gameJoinedReceived = (msg) => {
	const lang = store.getState().language.currentLanguage;
	return {
		type: GAME_JOINED_RECEIVED,
		payload: { msg, lang, isJoined: true },
	};
};

const _gamePlayerJoined = (msg) => {
	const { language, gameRoom } = store.getState();
	const lang = language.currentLanguage;
	return {
		type: GAME_PLAYER_JOINED,
		payload: { msg, lang, isJoined: !gameRoom.grIsGameCreator },
	};
};

const _gamePlayerLeft = (msg) => {
	const { language, gameRoom } = store.getState();
	const lang = language.currentLanguage;
	return {
		type: GAME_PLAYER_LEFT,
		payload: { msg, lang, isJoined: !gameRoom.grIsGameCreator },
	};
};
