import {
	DISPLAY_NOTIFICATION,
	INCREASE_CURRENT_ANNOUNCEMENTS_DISPLAYED_NUMBER,
	INCREASE_CURRENT_MESSAGE_INDEX,
	PROCESS_ANNOUNCEMENT,
	REMOVE_CURRENT_ANNOUNCEMENT_FROM_QUEUE,
	REMOVE_CURRENT_DISPLAYED_MESSAGE,
	RESET_CURRENT_MESSAGE_INDEX,
	ROTATE_NOTIFICATIONS,
} from "../actionTypes";
import { store } from "../store";

export const displayNotification = (notifObj) => {
	return (dispatch) => {
		if (store.getState().notifications.messagesQueueList.length > 0) {
			//there are notifications in queue and already at least one of them is displaying
			dispatch(_displayNotification(notifObj)).then(() => {
				dispatch(rotateNotifications());
			});
		} else {
			dispatch(processAnnouncement(notifObj)).then(() => {
				if (store.getState().notifications.messagesQueueList.length == 1) {
					dispatch(rotateNotifications());
				}
			});
		}
	};
};

export const processAnnouncement = (msgJSON) => {
	return (dispatch) => {
		dispatch(_processAnnouncement(msgJSON));
		dispatch(rotateNotifications());
		return Promise.resolve();
	};
};

export const rotateNotifications = () => {
	return { type: ROTATE_NOTIFICATIONS };
};

export const removeCurrentAnnouncementFromQueue = () => {
	return (dispatch) => {
		dispatch(_removeCurrentAnnouncementFromQueue());
		return Promise.resolve();
	};
};

export const increaseCurrentMessageIndex = () => {
	return (dispatch) => {
		dispatch(_increaseCurrentMessageIndex());
		return Promise.resolve();
	};
};

export const resetCurrentMessageIndex = () => {
	return { type: RESET_CURRENT_MESSAGE_INDEX };
};

export const removeCurrentDisplayedMessage = () => {
	return (dispatch) => {
		dispatch(_removeCurrentDisplayedMessage());
		return Promise.resolve();
	};
};

export const increaseCurrentAnnoucementsDispalyedNumber = () => {
	return (dispatch) => {
		dispatch(_increaseCurrentAnnouncementsDisplayedNumber());
	};
};

const _displayNotification = (notifObj) => {
	return (dispatch) => {
		dispatch({ type: DISPLAY_NOTIFICATION, payload: notifObj });
		return Promise.resolve();
	};
};

const _processAnnouncement = (msgJSON) => {
	return { type: PROCESS_ANNOUNCEMENT, payload: msgJSON };
};

const _removeCurrentAnnouncementFromQueue = () => {
	return { type: REMOVE_CURRENT_ANNOUNCEMENT_FROM_QUEUE };
};

const _increaseCurrentMessageIndex = () => {
	return { type: INCREASE_CURRENT_MESSAGE_INDEX };
};

const _removeCurrentDisplayedMessage = () => {
	return { type: REMOVE_CURRENT_DISPLAYED_MESSAGE };
};

const _increaseCurrentAnnouncementsDisplayedNumber = () => {
	return { type: INCREASE_CURRENT_ANNOUNCEMENTS_DISPLAYED_NUMBER };
};
