import React, { Component } from "react";
import { Platform, StyleSheet, TouchableWithoutFeedback, View } from "react-native";
import { connect } from "react-redux";
import { RESOLUTION } from "../../config/defaults";
import { panelMenuEnabled } from "../../config/permissions";
import DebugLogger from "../../controller/DebugLogger";
import { mapObject } from "../../helpers/commonHelpers";
import { focusInGameChatInput } from "../../Keezers/redux/actions";
import { store } from "../../redux/store";
import { ChatMessages } from "../common";
import { handleError } from "../ErrorHandler";

class GameChatMessages extends Component {
	constructor(props) {
		super(props);
		var players = [];
		const { startedGame } = store.getState();
		mapObject(startedGame.players, (k, v) => players.push(v));
		this.state = {
			players: players,
		};
		this.focusInTimeout = null;
	}

	//#region lifecycle methods
	componentWillUnmount() {
		if (this.focusInTimeout) {
			clearTimeout(this.focusInTimeout);
			this.focusInTimeout = null;
		}
	}
	//#endregion

	//#region events
	focusOnInput() {
		try {
			if (Platform.OS !== "web") {
				if (Platform.OS === "android" && Platform.Version >= 30) {
					// Keyboard.dismiss();
					if (this.focusInTimeout) {
						clearTimeout(this.focusInTimeout);
						this.focusInTimeout = null;
					}
					this.focusInTimeout = setTimeout(() => {
						this.props.focusInGameChatInput();
					}, 500);
				} else {
					this.props.focusInGameChatInput();
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		if (
			typeof this.props.activeRoomID === "undefined"
			// || this.props.activeRoomID !== -1
		)
			return null;
		var messages = [],
			players = [];
		if (this.props.activeRoomID == -1) {
			messages = this.props.gameChatMessages;
			players = this.state.players;
		} else {
			const room = this.props.joinedChatRooms.find((r) => r.chatRoomID == this.props.activeRoomID);
			if (room == undefined || !Array.isArray(room.chatMessages) || !Array.isArray(room.users)) return null;

			messages = room.chatMessages;
			players = room.users;
			// var _msg = {
			//   content: "/getPositions",
			//   type: "gameChat",
			//   sMessageID: 0,
			//   gameID: this.props.gameID,
			// };
			// MyWebSocket.shared.sendMsg(_msg);
		}
		const isLowRes = this.props.resolution == RESOLUTION.LOW;
		const topPos = isLowRes ? 26 : 28;
		const isMenuExpanded =
			panelMenuEnabled == true && this.props.expandPanelMenu == true && this.props.expandedPanelID >= 0;
		return (
			<View>
				{Platform.OS !== "web" && (
					<TouchableWithoutFeedback
						onPress={this.focusOnInput.bind(this)}
						onLayout={(event) => {
							event.persist();
							DebugLogger.shared.saveLog(
								{
									_btnFocus_trigger: "Button under chat messages rendered in GameChatMessages",
									layout: event.nativeEvent.layout,
								},
								true
							);
						}}
					>
						<View
							style={{
								position: "absolute",
								width: "100%",
								height: this.props.containerHeight + 50,
								top: 0,
							}}
						/>
					</TouchableWithoutFeedback>
				)}
				<ChatMessages
					focusFunc={this.focusOnInput.bind(this)}
					players={players}
					renderLoc={"startedGame"}
					isGlobalMessage={this.props.activeRoomID !== -1}
					messages={messages}
					contentHeight={this.props.containerHeight}
					marginTop={0}
					topPos={topPos}
					resolution={this.props.resolution}
					useColoredGameChat={this.props.useColoredGameChat}
					containerOpacity={isMenuExpanded ? 0 : 1}
				/>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
	return {
		gameChatMessages: state.gameRoom.gameChatMessages,
		resolution: state.currentUser.preferences.resolution,
		useColoredGameChat: state.currentUser.preferences.useColoredGameChat,
		joinedChatRooms: state.globalChat.joinedChatRooms,
		expandPanelMenu: state.startedGame.expandPanelMenu,
		expandedPanelID: state.startedGame.expandedPanelID,
	};
};

const mapDispatchToProps = { focusInGameChatInput };

export default connect(mapStateToProps, mapDispatchToProps)(GameChatMessages);
