import { NEW_COORDINATES } from "../../config/defaults";
import { CHECK_FETCH_IN_PROGRESS, GET_BOARD_COORDINATES, GET_BOARD_COORDINATES_SUCCESS } from "./actionTypes";

export const getBoardCoordinates = () => {
	return (dispatch) => {
		dispatch(_getBoardCoordinates());
		dispatch(_getBoardCoordinatesSuccess(NEW_COORDINATES));
	};
};

const _getBoardCoordinates = () => {
	return { type: GET_BOARD_COORDINATES };
};

const _getBoardCoordinatesSuccess = (json) => {
	return (dispatch) => {
		dispatch({ type: GET_BOARD_COORDINATES_SUCCESS, payload: json });
		dispatch(_checkFetchInProgress());
	};
};

const _checkFetchInProgress = () => {
	return { type: CHECK_FETCH_IN_PROGRESS };
};
