import { modalType } from "../../config/defaults";
import { mySounds } from "../../config/sounds";
import { store } from "../store";
import { CLOSE_MODAL_DIALOG, DISABLE_RECONNECT, ENABLE_RECONNECT, OPEN_MODAL_DIALOG } from "./actionTypes";

export const openModalDialog = (
	modType,
	message,
	posButText,
	posButAction,
	negButText,
	negButAction,
	linkInText,
	isFullscreenLink,
	shopCatLink = "",
	shopSubCatLink = "",
	shopItemLink = ""
) => {
	return (dispatch) => {
		dispatch(enableReconnect());

		dispatch(
			_openModalDialog(
				modType,
				message,
				posButText,
				posButAction,
				negButText,
				negButAction,
				linkInText,
				isFullscreenLink
			)
		);
		switch (modType) {
			case modalType.ERROR:
				const { errorSound } = mySounds;
				if (typeof errorSound !== "undefined" && errorSound != null && errorSound.isLoaded() == true) {
					errorSound.play();
				}
				break;
		}
	};
};

export const closeModalDialog = () => {
	return { type: CLOSE_MODAL_DIALOG };
};

export const enableReconnect = () => {
	return { type: ENABLE_RECONNECT };
};

export const disableReconnect = () => {
	return { type: DISABLE_RECONNECT };
};

const _openModalDialog = (
	modType,
	message,
	posButText,
	posButAction,
	negButText,
	negButAction,
	linkInText,
	isFullscreenLink
) => {
	const lang = store.getState().language.currentLanguage;
	return {
		type: OPEN_MODAL_DIALOG,
		payload: {
			modType,
			message,
			posButText,
			posButAction,
			negButText,
			negButAction,
			linkInText,
			isFullscreenLink,
			lang,
		},
	};
};
