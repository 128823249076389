import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { FlatList, Platform, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansprobold, f_sourcesansprosemibold, isWebOrTablet, RESOLUTION } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import Analytics from "../Analytics/Analytics";
import { HeaderText } from "../common";
import { handleError } from "../ErrorHandler";

class TournamentPoolOverview extends Component {
	poolsListRef = React.createRef();
	constructor(props) {
		super(props);
		this.state = { nrCols: 4, pools: this.getPools(4) };
		this.poolWidth = isWebOrTablet ? 280 : 210;
		this.poolHeight = isWebOrTablet ? 190 : 150;
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("TournamentPoolOverview");
	}
	//#endregion

	//#region events
	onPoolsListLayout(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const width = event.nativeEvent.layout.width;
			var nrCols = Math.floor(width / this.poolWidth);
			nrCols = nrCols < 1 ? 1 : nrCols;
			if (nrCols != this.state.nrCols) {
				this.setState({ nrCols: nrCols, pools: this.getPools(nrCols) });
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderTeams(teamIDs) {
		var _teams = [];
		if (Array.isArray(teamIDs)) {
			teamIDs.forEach((_teamID) => {
				const team = this.props.selectedTournament.teams.find((t) => t.teamID == _teamID);
				if (team && typeof team.teamName === "string") {
					_teams.push(
						<View key={team.teamName} style={styles.teamInnerContainer}>
							<Text style={styles.teamNameText}>{team.teamName}</Text>
						</View>
					);
				}
			});
		}
		return (
			<View key="teamContainer" style={styles.teamContainer}>
				{_teams}
			</View>
		);
	}

	renderPoolContent(item, index) {
		var pool = null;
		if (!item.empty) {
			pool = (
				<View key="poolContainer" style={styles.poolContainer}>
					<View key="poolNameContainer" style={styles.poolNameContainer}>
						<Text style={styles.poolNameText}>{item.poolName}</Text>
					</View>
					{this.renderTeams(item.teamIDs)}
				</View>
			);
		}
		return pool;
	}

	renderPools(item, index) {
		return (
			<View
				style={{
					width: this.poolWidth,
					height: this.poolHeight,
					borderRadius: 6,
					marginRight: 5,
					margin: 5,
					backgroundColor: !item.empty ? "rgba(0,64,78,0.5)" : "transparent",
				}}
			>
				{this.renderPoolContent(item, index)}
			</View>
		);
	}

	renderPoolsList() {
		return (
			<FlatList
				key={"cols_" + this.state.nrCols}
				ref={this.poolsListRef}
				nestedScrollEnabled={true}
				keyExtractor={(item, index) => "pool_" + index.toString()}
				data={this.state.pools}
				extraData={this.state.pools}
				renderItem={({ item, index }) => this.renderPools(item, index)}
				numColumns={this.state.nrCols}
				showsVerticalScrollIndicator={Platform.OS !== "web"}
				columnWrapperStyle={this.state.nrCols > 1 ? { justifyContent: "center" } : null}
				onContentSizeChange={() => {
					if (
						this.poolsListRef &&
						this.poolsListRef.current &&
						Array.isArray(this.state.pools) &&
						this.state.pools.length > 0
					)
						this.poolsListRef.current.scrollToIndex({
							animated: false,
							index: 0,
							viewPosition: 0,
						});
				}}
				getItemLayout={(item, index) => ({
					length: 255,
					offset: 255 * index,
					index,
				})}
				style={styles.poolsListStyle}
				contentContainerStyle={styles.poolsListContentContainer}
				onLayout={this.onPoolsListLayout.bind(this)}
			/>
		);
	}

	render() {
		const _header = this.props.selectedTournament.tournamentName + " Overview";
		return (
			<SafeAreaView style={{ flex: 1 }}>
				<View
					style={[
						styles.container,
						isWebOrTablet
							? {
									marginTop: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
							  }
							: { marginTop: 10 },
					]}
				>
					{isWebOrTablet && <HeaderText textContent={_header} style={{ height: gameDimensions.boxHeaderHeight }} />}
					<View style={styles.tableStyle}>{this.renderPoolsList()}</View>
				</View>
			</SafeAreaView>
		);
	}
	//#endregion

	//#region helpers
	getPools(numColumns = 0) {
		try {
			var list = cloneDeep(this.props.selectedTournament.pools);
			if (list.length == 0 || numColumns == 0) return list;
			const numberOfFullRows = Math.floor(list.length / numColumns);
			var numberOfElementsLastRow = list.length - numberOfFullRows * numColumns;

			while (numberOfElementsLastRow !== numColumns && numberOfElementsLastRow !== 0) {
				list.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
				numberOfElementsLastRow++;
			}
			return list;
		} catch (err) {
			handleError(err);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		margin: 3,
		paddingLeft: isWebOrTablet ? 10 : 50,
		paddingRight: isWebOrTablet ? 10 : 50,
		paddingBottom: 10,
	},
	tableStyle: {
		flex: 1,
		paddingVertical: 5,
		paddingHorizontal: 10,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
	},
	poolsListStyle: { flex: 1 },
	poolsListContentContainer: {
		minHeight: "100%",
		justifyContent: "center",
	},
	poolContainer: { flex: 1, paddingVertical: 5, paddingHorizontal: 10 },
	poolNameContainer: { height: isWebOrTablet ? 40 : 30 },
	poolNameText: {
		fontFamily: f_sourcesansprobold,
		fontSize: isWebOrTablet ? 24 : 20,
		lineHeight: isWebOrTablet ? 30 : 25,
		color: "#fff",
	},
	teamContainer: { height: isWebOrTablet ? 160 : 120 },
	teamInnerContainer: {
		paddingHorizontal: 3,
		backgroundColor: "#fff",
		borderRadius: 6,
		marginBottom: isWebOrTablet ? 8 : 5,
	},
	teamNameText: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: isWebOrTablet ? 18 : 14,
		lineHeight: isWebOrTablet ? 26 : 22,
		color: "#333",
	},
});

const mapStateToProps = (state) => {
	var found = state.tournaments.tournamentsList.find(
		(tour) => tour.tournamentID === state.tournaments.selectedTournamentID
	);
	if (!found) {
		found = { teams: [] };
	}
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedTournament: found,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TournamentPoolOverview);
