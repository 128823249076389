import React, { Component } from "react";
import { Dimensions, Image, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import Analytics from "../../components/Analytics/Analytics";
import { handleError } from "../../components/ErrorHandler";
// import GameChat from "../../components/Game/GameChat";
import { GAME_LOADING } from "../../config/images";
import { mySounds } from "../../config/sounds";
import { resizeAll } from "../../redux/actions";
import { store } from "../../redux/store";
import Keezers2Players from "./Keezers2Players";
import Keezers3Players from "./Keezers3Players";
import Keezers4Players from "./Keezers4Players";
import Keezers5Players from "./Keezers5Players";
import Keezers6Players from "./Keezers6Players";
import Keezers7Players from "./Keezers7Players";
import Keezers8Players from "./Keezers8Players";

class GameBoard extends Component {
	constructor(props) {
		super(props);
		this.state = { imageDisplayed: false };
		this.props.resizeAll(Dimensions.get("window"));
		this._gameStartSound = mySounds.gameStartSound;
	}

	componentDidMount() {
		Analytics.logScreenView("GameBoard");
	}

	//#region events
	handleLoadEnd() {
		try {
			if (Platform.OS !== "web") {
				this.startBoardRendering();
			} else {
				Image.getSize(
					GAME_LOADING,
					(w, h) => {
						this.startBoardRendering();
					},
					(err) => {
						this.startBoardRendering();
					}
				);
			}
		} catch (err) {
			handleError(new Error("Error in GameBoard handleLoadEnd: " + JSON.stringify(err)));
			//anyway do your job
			this.startBoardRendering();
		}
	}

	startBoardRendering() {
		try {
			const isSoundInited =
				typeof this._gameStartSound !== "undefined" &&
				this._gameStartSound != null &&
				this._gameStartSound.isLoaded() == true;

			if (isSoundInited) {
				this._gameStartSound.setVolume(0.2);
				this._gameStartSound.play();
			}

			const soundDuration = isSoundInited ? this._gameStartSound.getDuration() * 1000 : 250;
			const { app, tab } = store.getState();
			const { isBackground, isSettingsOpened, isHelpOpened } = app;
			const { isShopOpen } = tab;
			if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
				setTimeout(() => {
					this.setState({ imageDisplayed: true });
				}, soundDuration);
			} else {
				this.setState({ imageDisplayed: true });
			}
		} catch (err) {
			handleError(new Error("Error in GameBoard startBoardRendering: " + JSON.stringify(err)));
			//anyway do your job
			this.setState({ imageDisplayed: true });
		}
	}
	//#endregion

	//#region render methods
	renderGameBoard() {
		if (store.getState().startedGame.gameID <= 0) return null;
		switch (this.props.playerCnt) {
			case 2:
				return <Keezers2Players />;
			case 3:
				return <Keezers3Players />;
			case 4:
				return <Keezers4Players />;
			case 5:
				return <Keezers5Players />;
			case 6:
				return <Keezers6Players />;
			case 7:
				return <Keezers7Players />;
			case 8:
				return <Keezers8Players />;
			default:
				return null;
		}
	}

	render() {
		if (
			!this.props.isGameStarted ||
			// this.props.isTabShopButtonActive ||
			this.props.isGameEndOpened ||
			Object.keys(this.props.playersCardsProp).length == 0
		)
			return (
				<View style={styles.gameLoadingContainer}>
					<Image source={GAME_LOADING} resizeMode="contain" style={{ width: "100%", height: "100%" }} />
				</View>
			);
		return (
			<View style={{ flex: 1 }}>
				{/* {this.renderGameBoard()}
        {this.props.boardLoaded === false && (
          <View style={styles.gameLoadingContainer}>
            <Image
              source={GAME_LOADING}
              resizeMode="contain"
              style={{ width: "100%", height: "100%" }}
            />
          </View>
        )} */}
				{(this.state.imageDisplayed || this.props.boardLoaded) && this.renderGameBoard()}
				{!this.state.imageDisplayed && !this.props.boardLoaded && (
					<View style={styles.gameLoadingContainer}>
						<Image
							source={GAME_LOADING}
							resizeMode="contain"
							style={{ width: "100%", height: "100%" }}
							onLoadEnd={this.handleLoadEnd.bind(this)}
						/>
					</View>
				)}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	gameLoadingContainer: {
		width: "100%",
		height: "100%",
		backgroundColor: "#00C4D2",
		alignItems: "center",
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: 999,
	},
});

const mapStateToProps = (state) => {
	return {
		isGameStarted: state.tab.isGameStarted,
		isGameEndOpened: state.tab.isGameEndOpened,
		// isTabShopButtonActive: state.tab.isTabShopButtonActive,
		playerCnt: state.startedGame.playerCnt,
		playersCardsProp: state.startedGame.gameBoard.playersCardsProp,
		boardLoaded: state.startedGame.boardLoaded,
	};
};

const mapDispatchToProps = { resizeAll };

export default connect(mapStateToProps, mapDispatchToProps)(GameBoard);
