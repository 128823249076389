import { Dimensions, PixelRatio, Platform } from "react-native";
import * as RNLocalize from "react-native-localize";
import Analytics from "../components/Analytics/Analytics";
import { handleError } from "../components/ErrorHandler";
import { AUTHENTICATE_BASE_MSG, CONNECT_MSG } from "../config/connection";
import { DEFAULT_CURRENCY, modalType, storeKeys } from "../config/defaults";
import { DEBUG_ENABLED, LOGGING_ENABLED, sendAckAndProcessed } from "../config/permissions";
import { EventQueue } from "../controller";
import DebugLogger from "../controller/DebugLogger";
import { create_UUID } from "../helpers/commonHelpers";
import { getData, storeData } from "../libraries/AsyncStorage";
import UUIDGenerator from "../libraries/UUIDGenerator";
import { store } from "../redux/store";
import { clearTournamentList } from "../redux/actions";
const parser = Platform.OS !== "web" ? null : require("ua-parser-js");

class MyWebSocket {
	static shared = MyWebSocket.shared ? MyWebSocket.shared : new MyWebSocket();

	constructor() {
		this.isUpgradable = false;
		this.ws = null;
		this.socketConnectionClosed = null;
		this.socketConnectionEstablished = null;
		this.isAuthenticateRefreshing = false;
		this.authenticateRefreshProcess = null;
		this.increaseConnectionTries = null;
		this.resetConnectionTries = null;
		this.cMessageID = 0;
		this.connectionTriesCounter = 0;
		this.sendMessageAction = null;
		this.increaseClientMessageCounter = null;
		this.resetCMessageID = null;
		this.startAuthenticate = null;
		this.authenticateSucceeded = null;
		this.openTab = null;
		this.insertNewGame = null;
		this.removeGame = null;
		this.receiveGamePlayerInfo = null;
		this.processGameCreated = null;
		this.processGameJoined = null;
		this.processGamePublished = null;
		this.processGameNameUpdated = null;
		this.processGameSlotUpdated = null;
		this.processGameChat = null;
		this.processGamePlayerJoined = null;
		this.updateGameRoomWithPlayerLeft = null;
		this.processGameStarted = null;
		this.processGameInfo = null;
		this.processGamePlayerToAct = null;
		this.processGamePlayerActed = null;
		this.processLocalMove = null;
		this.processGameEnd = null;
		this.setOnlinePlayersAmount = null;
		this.processChatRoomListUpdate = null;
		this.processChatRoomListRemove = null;
		this.processChatRoomJoined = null;
		this.processChatRoomLeft = null;
		this.processChatRoomCreated = null;
		this.chatRoomUserUpdated = null;
		this.receiveGlobalChatMessage = null;
		this.clearGlobalChat = null;
		this.processAckEvent = null;
		this.openModalDialog = null;
		this.displayNotification = null;
		this.processGameClosedEvent = null;
		this.processAnnouncement = null;
		this.processErrorEvent = null;
		this.clearGameListModule = null;
		this.clearGameDetailsInLobby = null;
		this.detectConnectionError = null;
		this.handleWelcome = null;
		this.handleUpgradeRequired = null;
		this.startReconnect = null;
		this.connectToNextURL = null;
		this.processGameListSubscribed = null;
		this.processGameListUnsubscribed = null;
		this.saveLeaderBoardsData = null;
		this.handleProfileSettings = null;
		this.handleProfileSettingsUpdated = null;
		this.handleSettings = null;
		this.updateFriendList = null;
		this.updateBanList = null;
		this.updateSearchedUsersList = null;
		this.logOff = null;
		this.closeModalDialog = null;
		this.addLog = null;
		this.receiveShopInventory = null;
		this.removeURLFromSocketList = null;
		this.gameDetailsStartedRecevied = null;
		this.handleSuccessEvent = null;
		this.leaveGame = null;
		this.goBackToMain = null;
		this.gamePlayerDisconnected = null;
		this.gamePlayerConnected = null;
		this.gamePlayerLeftStartedGame = null;
		this.buyItemSucceeded = null;
		this.addCreditSucceeded = null;
		this.updateUserOverview = null;
		this.enableAcks = false;
		this.insertTournaments = () => {};
		this.removeTournaments = () => {};
		this.saveTournamentDetails = () => {};
		this.updateTournamentDetails = () => {};
	}

	logData = ({ title, content }) => {
		if (typeof this.addlog === "function") this.addLog({ title, content });
	};

	sendMsg = (message, screen, moveToSave, shopData) => {
		try {
			if (this.ws === null || this.ws.readyState !== 1 || typeof message === "undefined" || message == "") return;

			//prevent sending startGame twice
			if (message.type == "startGame") {
				const sentMsgList = store.getState().socket.sentMsg;
				const foundEvent = sentMsgList.find((ev) => ev.type == "startGame" && ev.gameID == message.gameID);
				if (foundEvent) return;
			}

			if (typeof message === "string") message = JSON.parse(message);
			this.increaseClientMessageCounter();
			message.cMessageID = store.getState().socket.cMessageID;
			if (message.type == "connect") {
				message.sMessageID = 0;
				getData(storeKeys.REFERRER_SENT)
					.then((data) => {
						if (!data) {
							storeData(storeKeys.REFERRER_SENT, "true");
						} else {
							if (Object.keys(message).indexOf("referrer") != -1) delete message.referrer;
						}
						this.sendMessageAction(message, screen, moveToSave, shopData);
					})
					.catch((err) => {
						if (Object.keys(message).indexOf("referrer") != -1) delete message.referrer;
						this.sendMessageAction(message, screen, moveToSave, shopData);
					});
			} else {
				this.sendMessageAction(message, screen, moveToSave, shopData);
			}
		} catch (error) {
			handleError(error);
		}
	};

	sendAckMessage = (sMessageID) => {
		if (DEBUG_ENABLED) {
			console.log(`!!! entered sendAckMessage with sMessageID = ${sMessageID} inside MyWebsocket.js !!!`);
		}

		try {
			if (typeof sMessageID === "undefined") return null;
			if (typeof this.enableAcks !== "boolean" || (typeof this.enableAcks === "boolean" && !this.enableAcks))
				return null;

			var clientAckMsg = {
				sMessageID: sMessageID,
				type: "ack",
			};
			clientAckMsg.localTimestamp = Date.now();
			this.sendMsg(clientAckMsg);

			DebugLogger.shared.saveLog(
				{
					ackLog: `Sending ack message with sMessageID = ${sMessageID}`,
				},
				true
			);
		} catch (error) {
			handleError(error);
		}
	};

	sendProcessedMessage = (sMessageID, games) => {
		try {
			if (typeof sMessageID === "undefined") return null;
			if (
				typeof sendAckAndProcessed !== "boolean" ||
				(typeof sendAckAndProcessed === "boolean" && !sendAckAndProcessed)
			)
				return null;

			var clientProcessedMsg = {
				sMessageID: sMessageID,
				type: "processed",
			};
			if (typeof games !== "undefined" && Array.isArray(games)) {
				clientProcessedMsg.games = [];
				games.map((game) => {
					var newItem = {};
					if (typeof game.gameID !== "undefined") {
						newItem.gameID = game.gameID;
						if (typeof game.isHiddenByFilter !== "undefined") {
							newItem.isHiddenByFilter = game.isHiddenByFilter;
						}
						if (typeof game.isUpdatedInGameList !== "undefined") {
							newItem.isUpdatedInGameList = game.isUpdatedInGameList;
						}
						if (typeof game.isNewlyInserted !== "undefined") {
							newItem.isNewlyInserted = game.isNewlyInserted;
						}
					}
					if (typeof newItem.gameID !== "undefined") {
						clientProcessedMsg.games.push(newItem);
					}
				});
			}
			clientProcessedMsg.localTimestamp = Date.now();
			this.sendMsg(clientProcessedMsg);
		} catch (error) {
			handleError(error);
		}
	};

	sendAuthenticateRefresh = () => {
		this.isAuthenticateRefreshing = true;
		this.sendMsg({ type: "authenticateRefresh" });
	};

	requestShopDetails = async (_reason, _linkID) => {
		try {
			const { welcome } = store.getState().app;
			const { supportedClientCurrencies } = welcome;
			const currencies = RNLocalize.getCurrencies();
			const intersection = supportedClientCurrencies.filter((x) => currencies.includes(x));
			var _currency = currencies.length > 0 ? currencies[0] : DEFAULT_CURRENCY;
			if (Platform.OS === "android") {
				const RNIap = require("react-native-iap").default;
				await RNIap.endConnection().catch((err) => {
					if (DEBUG_ENABLED) console.log("ERROR IN MYWEBSOCKET ANDROID RNIap.endConnection()", err);
				});
				await RNIap.initConnection().catch((err) => {
					if (DEBUG_ENABLED) console.log("ERROR IN MYWEBSOCKET ANDROID RNIap.initConnection()", err);
				});
				var _items = await RNIap.getProducts(["gold.s"]).catch((err) => {
					if (DEBUG_ENABLED) console.log("ERROR IN MYWEBSOCKET ANDROID RNIap.getProducts(): ", err);
				});
				_currency =
					Array.isArray(_items) && _items.length > 0 && _items[0] && typeof _items[0].currency === "string"
						? _items[0].currency
						: _currency;
			}
			if (Platform.OS === "ios") {
				const RNIap = require("react-native-iap").default;
				await RNIap.endConnection().catch((err) => {
					if (DEBUG_ENABLED) console.log("ERROR IN MYWEBSOCKET IOS RNIap.endConnection()", err);
				});
				await RNIap.initConnection().catch((err) => {
					if (DEBUG_ENABLED) console.log("ERROR IN MYWEBSOCKET IOS RNIap.initConnection()", err);
				});
				var _items = await RNIap.getProducts(["keezers.gold.s"]).catch((err) => {
					if (DEBUG_ENABLED) console.log("ERROR IN MYWEBSOCKET IOS RNIap.getProducts(): ", err);
				});
				_currency =
					Array.isArray(_items) && _items.length > 0 && _items[0] && typeof _items[0].currency === "string"
						? _items[0].currency
						: _currency;
			}
			MyWebSocket.shared.sendMsg({
				sMessageID: 0,
				type: "getShopInventory",
				currency: _currency,
				// clientCurrency: intersection.length > 0 ? intersection[0] : _currency,
			});
		} catch (error) {
			handleError(error);
		}
	};

	openNewWindowAndRedirect = (url) => {
		try {
			var newWindow = window.open(url, "_blank", "toolbar=no, top=0, left=0");
			const lang = store.getState().language.currentLanguage;
			if (newWindow && newWindow != null) {
				newWindow.opener = null;
				newWindow.location = url;
			} else {
				if (this.openModalDialog != null)
					this.openModalDialog(modalType.DIALOG, lang.messages.popupBlockerWarningMessage, lang.continue, () => {
						setTimeout(() => {
							this.openNewWindowAndRedirect(url);
						}, 100);
					});
			}
		} catch (error) {
			handleError(error);
		}
	};

	showReconnectNotification = async () => {
		try {
			if (this.isUpgradable) return;
			const { wsURLs, connectionID } = store.getState().socket;
			var url = wsURLs[connectionID];
			const lang = store.getState().language.currentLanguage;
			var notifObj = {
				main_text: lang.reconnect_disabled,
				isMaintenance: false,
			};
			if (typeof url === "string" && url.indexOf("wss://") == 0 && url.indexOf("/keezers/") != -1) {
				url = url.replace("wss://", "https://");
				url = url.replace("/keezers/", "/status");

				// url = "https://gateway-01.goatama.net/status";
				await fetch(url)
					.then((response) => {
						if (response.ok) return response.json();
						throw response;
					})
					.then((data) => {
						if (data && data.isInMaintenance === true) {
							if (data.maintenanceText) {
								notifObj.isMaintenance = true;
								const txt = data.maintenanceText[lang.languageID.toUpperCase()];
								if (typeof txt === "string") notifObj.main_text = txt;
							}
						}
					})
					.catch((err) => {
						const fetchError = new Error("Error fetching data from: " + url + "; " + JSON.stringify(err));
						handleError(fetchError);
					})
					.finally(() => {
						this.openModalDialog(modalType.RECONNECT, notifObj, "");
					});
			} else {
				this.openModalDialog(modalType.RECONNECT, notifObj, "");
			}
		} catch (error) {
			handleError(error);
		}
	};

	socketOnOpenHandler = (event) => {
		try {
			const { wsURLs, connectionID, isReconnecting } = store.getState().socket;

			if (DEBUG_ENABLED) {
				console.log("CONNECTED TO: ", wsURLs[connectionID]);
				if (LOGGING_ENABLED) {
					this.addLog({
						title: "CONNECTED TO:",
						content: wsURLs[connectionID],
					});
				}
			}

			this.socketConnectionEstablished();
			if (Platform.OS === "web") {
				if (typeof window.localStorage.open_pages !== "undefined") {
					const openedPages = JSON.parse(localStorage.open_pages);
					CONNECT_MSG.debug.openedPagesCount = Object.keys(openedPages).length;
				}
			}

			const { visibleModal, modType } = store.getState().modal;
			if (visibleModal && modType == modalType.RECONNECT) this.closeModalDialog();
			if (isReconnecting == true) {
				AUTHENTICATE_BASE_MSG.reason = "reconnect";
			} else {
				if (typeof AUTHENTICATE_BASE_MSG.reason !== "undefined") {
					delete AUTHENTICATE_BASE_MSG.reason;
				}
			}

			switch (Platform.OS) {
				case "ios":
				case "android":
					CONNECT_MSG.clientType = Platform.OS.toUpperCase();
					break;
				default:
					CONNECT_MSG.clientType = "BROWSER";
					break;
			}

			const screenDim = Dimensions.get("screen");
			const dpi = Platform.OS === "web" ? window.devicePixelRatio : PixelRatio.get();
			const resolutionWidth = Math.round(screenDim.width * dpi);
			const resolutionHeight = Math.round(screenDim.height * dpi);
			CONNECT_MSG.clientResolution = resolutionWidth + "x" + resolutionHeight;

			CONNECT_MSG.loadTimeMs = store.getState().app.loadTimeMS;
			CONNECT_MSG.pageLoadTimestamp = store.getState().app.pageLoadTimestamp;
			CONNECT_MSG.platformData = {};

			if (Platform.OS === "web") {
				CONNECT_MSG.platformData.loadedFromUrl = window.location.href;
				var _parser = new parser.UAParser();
				var webInfo = _parser.getResult();
				CONNECT_MSG.deviceDetails = {
					os: webInfo.os,
					device: webInfo.device,
					browser: webInfo.browser,
					// cpu: webInfo.cpu,
					// engine: webInfo.engine,
				};
				getData(storeKeys.DEVICE_ID).then((data) => {
					if (data === null || data == "TEST_ID") {
						const devID = create_UUID();
						storeData(storeKeys.DEVICE_ID, devID);
						CONNECT_MSG.deviceIdentifier = devID;
						CONNECT_MSG.appIdentifier = devID;
					} else {
						CONNECT_MSG.deviceIdentifier = data;
						CONNECT_MSG.appIdentifier = data;
					}

					this.sendMsg(CONNECT_MSG);
				});
			} else {
				if (typeof CONNECT_MSG.appIdentifier !== "string" || CONNECT_MSG.appIdentifier == "") {
					getData(storeKeys.UUID)
						.then((data) => {
							if (data === null || data === "") {
								UUIDGenerator.getRandomUUID()
									.then((uuid) => {
										storeData(storeKeys.UUID, uuid);
										CONNECT_MSG.appIdentifier = uuid;
										this.sendMsg(CONNECT_MSG);
									})
									.catch((error) => {
										handleError(new Error("Failed to generate the appIdentifier"));
									});
							} else {
								CONNECT_MSG.appIdentifier = data;
								this.sendMsg(CONNECT_MSG);
							}
						})
						.catch((error) => {
							new Error("Failed to read the appIdentifier from AsyncStorage");
						});
				} else {
					this.sendMsg(CONNECT_MSG);
				}
			}

			this.resetConnectionTries();
			this.connectionTriesCounter = 0;
		} catch (error) {
			handleError(error);
		}
	};

	socketOnMessageHandler = (event) => {
		try {
			const msg = JSON.parse(event.data);
			if (DEBUG_ENABLED) {
				console.log("RESPONSE: ", event.data);
				if (LOGGING_ENABLED) {
					this.addLog({ title: "RESPONSE:", content: event.data });
				}
			}
			EventQueue.shared.receiveEvent(msg);
		} catch (error) {
			handleError(error);
		}
	};

	socketOnErrorHandler = async (event) => {
		if (DEBUG_ENABLED) {
			console.log("websocket error", event);
			if (LOGGING_ENABLED) {
				this.addLog({ title: "websocket error", content: event });
			}
		}

		const connError_log = {
			connError_message: "Websocket connection error",
			wsURL: this.ws.url,
			readyState: this.ws.readyState,
			connectionTries: this.connectionTriesCounter,
			startReconnect: undefined,
		};

		if (this.connectionTriesCounter >= 1 && this.ws && this.ws.readyState == 3) {
			//CLIENT disconnects again
			//reconnect with notification
			connError_log.startReconnect = true;
			DebugLogger.shared.resetLoggerID();
			this.resetCMessageID();
			this.increaseConnectionTries();
			this.startReconnect();
			this.connectToNextURL();
			await this.showReconnectNotification();
		}

		if (this.ws && this.ws.readyState == 3) {
			DebugLogger.shared.saveConnectivityLog(connError_log);
		}
	};

	socketOnCloseHandler = async (event) => {
		try {
			const { isReconnecting } = store.getState().socket;
			const { tournamentScreenIsActive } = store.getState().tournaments;

			const connClose_log = {
				connClose_message: "Websocket connection closed",
				wsURL: this.ws.url,
				readyState: this.ws.readyState,
				connectionTries: this.connectionTriesCounter,
				isReconnecting,
			};
			DebugLogger.shared.saveConnectivityLog(connClose_log);

			const _sCC_log = {
				sCC_message: "Resetting the event's queue",
				reason: "socket connection closed",
			};
			DebugLogger.shared.saveLog(_sCC_log, true);
			EventQueue.shared.resetEventsQueue();

			if (DEBUG_ENABLED) {
				console.log("websocket closed", event);
				if (LOGGING_ENABLED) {
					this.addLog({ title: "websocket closed", content: event });
				}
			}

			DebugLogger.shared.resetLoggerID();
			this.resetCMessageID();
			AUTHENTICATE_BASE_MSG.cMessageID = 0;
			if (typeof this.authenticateRefreshProcess !== "undefined") {
				clearInterval(this.authenticateRefreshProcess);
				this.authenticateRefreshProcess = null;
			}

			//clear global chat
			// this.clearGlobalChat();
			//clear game lobby
			this.clearGameListModule();
			this.clearGameDetailsInLobby();
			// clear tournaments list if on screen
			if (tournamentScreenIsActive) {
				store.dispatch(clearTournamentList());
			}

			if (this.connectionTriesCounter < 1 && !store.getState().socket.isReconnecting) {
				// CLIENT disconnects the first time
				//trying to reconnect without any notification
				this.increaseConnectionTries();
				this.startReconnect();
				this.connectToNextURL();
			}
			const { isCreateNewGameOpen, isGameRoomOpen, isGameStarted } = store.getState().tab;
			if (isCreateNewGameOpen || isGameRoomOpen || (isGameStarted && store.getState().startedGame.isSpectator)) {
				this.leaveGame();
				// this.goBackToMain();
			}
			this.socketConnectionClosed();
		} catch (error) {
			handleError(error);
		}
	};

	sendLogin = async (type, isAutomated = false) => {
		try {
			const { currentUser } = store.getState();
			var loginMsg = { type: "login", sMessageID: 0 };
			switch (type) {
				case "email":
					loginMsg.username = currentUser.credentials.email.username;
					loginMsg.password = currentUser.credentials.email.pwd;
					loginMsg.sendFromForm = isAutomated;
					await Analytics.onSignIn({ uName: loginMsg.username }, false);
					break;
				case "facebook":
					loginMsg.type = "facebookLogin";
					loginMsg.accessToken = currentUser.credentials.facebook.token;
					loginMsg.userID = currentUser.credentials.facebook.UID;
					loginMsg.birthday = currentUser.fb_userDetails.birthday;
					loginMsg.email = currentUser.fb_userDetails.email;
					loginMsg.firstName = currentUser.fb_userDetails.first_name;
					loginMsg.lastName = currentUser.fb_userDetails.last_name;
					loginMsg.gender = currentUser.fb_userDetails.gender;
					loginMsg.friendsUsingThisApp = [];
					currentUser.fb_userDetails.friends.map((f) => loginMsg.friendsUsingThisApp.push(f.id));
					break;
				default:
					loginMsg.type = "guestLogin";
					await Analytics.onSignIn({ uName: "guest" }, true);
					break;
			}
			this.sendMsg(loginMsg);
		} catch (error) {
			handleError(error);
		}
	};
}

export { MyWebSocket };
