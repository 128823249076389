import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { appFont } from "../../config/defaults";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

class KickBanPlayer extends Component {
	constructor(props) {
		super(props);
	}

	//#region events
	onKickPress() {
		const { kickAction } = this.props;
		if (typeof kickAction === "function") kickAction();
	}

	onBanPress() {
		const { banAction } = this.props;
		if (typeof banAction === "function") banAction();
	}
	//#endregion

	//#region render methods
	render() {
		const { username, containerStyle, usernameStyle } = this.props;
		return (
			<View style={[styles.container, containerStyle]}>
				<Text allowFontScaling={false} selectable={false} style={[usernameStyle || {}]}>
					{username}
				</Text>
				<AppTouchableOpacity activeOpacity={0.8} onPress={this.onKickPress.bind(this)} style={styles.buttonStyle}>
					<Text allowFontScaling={false} selectable={false} style={styles.buttonTextStyle}>
						{this.props.lang.kickButton}
					</Text>
				</AppTouchableOpacity>
				<AppTouchableOpacity activeOpacity={0.8} onPress={this.onBanPress.bind(this)} style={styles.buttonStyle}>
					<Text allowFontScaling={false} selectable={false} style={styles.buttonTextStyle}>
						{this.props.lang.banButton}
					</Text>
				</AppTouchableOpacity>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flexDirection: "row",
	},
	buttonStyle: {
		height: 17,
		marginLeft: 5,
		marginTop: "auto",
		marginBottom: "auto",
		borderWidth: 0,
		borderRadius: 5,
		backgroundColor: "#777",
	},
	buttonTextStyle: {
		fontFamily: appFont,
		fontSize: 13,
		color: "#fff",
		width: "100%",
		paddingLeft: 3,
		paddingRight: 3,
		paddingTop: 1,
	},
});

export { KickBanPlayer };
