import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprolight,
	f_sourcesansprosemibold,
	isWebOrTablet,
	processState,
	RESOLUTION,
} from "../../../../config/defaults";
import { gameDimensions, updateUsernameDimensions } from "../../../../config/dimensions";
import { TEXT_INPUT, TEXT_INPUT_ERROR } from "../../../../config/images";
import { clearUpdateUsernameError, closeUpdateUsername, goBackToMain, updateUsername } from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../../../common";
import { handleError } from "../../../ErrorHandler";

class UpdateUsername extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			username: this.props.profileSettings.username,
			usernameValid: true,
			isFormValid: true,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("UpdateUsername");
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.updateUsernameState != this.props.updateUsernameState &&
			!prevProps.updateUsernameSucceeded &&
			!this.props.updateUsernameSucceeded
		) {
			this.setState({ userValid: false, isFormValid: false });
		}
	}
	//#endregion

	//#region events
	onUsernameChange(text) {
		this.setState({ username: text });
	}
	onUpdatePress() {
		this.props.updateUsername(this.state.username);
	}
	onUsernameFocus() {
		// this.props.clearUpdateUsernameError();
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateUsernameState != processState.STARTED}
				backAction={this.props.closeUpdateUsername}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUsernameField() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = [styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26 }, Platform.OS === "android" && { paddingTop: 13 }];
		}
		return (
			<View style={styles.dataInnerContainer}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.usernameUpdate.username.title}
				</Text>
				<ImageBackground
					source={this.state.usernameValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					{Platform.OS !== "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onChangeText={(text) => this.onUsernameChange(text)}
							onFocus={this.onUsernameFocus.bind(this)}
							value={this.state.username}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							autoCompleteType="off"
							autoCorrect={false}
							spellCheck={false}
							textContentType="oneTimeCode"
							keyboardType={Platform.OS === "ios" ? "default" : "visible-password"}
							underlineColorAndroid="transparent"
							style={_inputStyle}
							placeholder={this.props.lang.usernameUpdate.username.placeholder}
							placeholderTextColor="#B2B2B2"
						/>
					)}
					{Platform.OS === "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onChangeText={(text) => this.onUsernameChange(text)}
							onFocus={this.onUsernameFocus.bind(this)}
							value={this.state.username}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							autoCorrect={false}
							spellCheck={false}
							textContentType="oneTimeCode"
							underlineColorAndroid="transparent"
							style={[_inputStyle, { outlineWidth: 0 }]}
							placeholder={this.props.lang.usernameUpdate.username.placeholder}
							placeholderTextColor="#B2B2B2"
						/>
					)}
				</ImageBackground>
			</View>
		);
	}

	renderUsernameError() {
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataErrorContainer}>
				<Text allowFontScaling={false} style={_errorStyle}>
					{this.props.updateUsernameError}
				</Text>
			</View>
		);
	}

	renderInfo() {
		var _infoStyle = [styles.dataTitle, styles.infoText, { width: "100%" }];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_infoStyle = [styles.dataTitle, styles.infoText, { fontSize: 24, width: "100%" }];
		}
		return (
			<View style={{ width: "100%" }}>
				<Text allowFontScaling={false} style={_infoStyle}>
					{this.props.lang.usernameUpdate.info}
				</Text>
			</View>
		);
	}

	renderUpdateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					additionalTextStyle={_buttonTextStyle}
				/>
			</View>
		);
	}

	renderGoBactToMain() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = { paddingTop: 13 };
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.props.goBackToMain}
					textContent={this.props.lang.backToMain}
					accessibilityLabel={this.props.lang.backToMain}
					color={buttonColor.GREY}
					additionalTextStyle={_buttonTextStyle}
					disabled={false}
				/>
			</View>
		);
	}

	renderContent() {
		if (!this.props.updateUsernameSucceeded) {
			return (
				<View
					style={[
						styles.contentContainer,
						// { transform: [{ scale: this.state.contentScale }] },
					]}
				>
					<View style={isWebOrTablet ? { flex: 1, justifyContent: "center" } : {}}>
						{this.renderUsernameField()}
						{this.renderInfo()}
						{((this.props.updateUsernameState == processState.FINISHED && !this.props.updateUsernameSucceeded) ||
							!this.state.usernameValid) &&
							this.renderUsernameError()}
					</View>
					{this.renderUpdateButton()}
				</View>
			);
		} else {
			const _text = this.props.lang.usernameUpdate.usernameUpdated.text.replace("#1#", this.state.username);
			var _titleStyle = styles.titleStyle,
				_infoStyle = styles.contentText;
			if (this.props.resolution === RESOLUTION.HIGH) {
				_titleStyle = [styles.titleStyle, { fontSize: 32 }];
				_infoStyle = [styles.contentText, { fontSize: 26 }];
			}
			return (
				<View style={styles.emailContainer}>
					<View style={styles.titleContainer}>
						<Text allowFontScaling={false} style={_titleStyle}>
							{this.props.lang.usernameUpdate.usernameUpdated.title}
						</Text>
					</View>
					<ScrollView
						style={[
							styles.sentContentContainer,
							{
								height: Dimensions.get("window").height - 100,
							},
						]}
					>
						<Text allowFontScaling={false} style={_infoStyle}>
							{_text}
						</Text>
					</ScrollView>
					{this.renderGoBactToMain()}
				</View>
			);
		}
	}

	render() {
		if (this.props.updateUsernameState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (updateUsernameDimensions.width > availableWidth || updateUsernameDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / updateUsernameDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / updateUsernameDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: { flex: 1, padding: 20, paddingBottom: 5 },
	contentContainer: { flex: 1, padding: 20 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		// width: "100%",
		height: 60,
		flexDirection: "row",
		marginTop: 25,
		// marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		// justifyContent: "center",
		alignItems: "center",
		alignSelf: isWebOrTablet ? "center" : "flex-end",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	infoText: {
		lineHeight: 26,
		marginTop: 10,
		marginBottom: 10,
		paddingLeft: 10,
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		color: "#FFFDF1",
	},
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 300,
		height: 50,
		marginRight: 2,
		borderRadius: 6,
		// paddingTop: Platform.OS === "web" ? 0 : 13,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataErrorContainer: {
		width: "100%",
		height: 40,
		marginTop: 3,
		marginBottom: 3,
		paddingLeft: 10,
		justifyContent: "flex-start",
		alignItems: "flex-start",
		// alignSelf: "flex-start",
		alignSelf: isWebOrTablet ? "center" : "flex-start",
	},
	fieldError: {
		width: isWebOrTablet ? "100%" : 500,
		height: "100%",
		color: "#FE1F01",
		textAlign: "left",
	},
	emailContainer: { flex: 1, paddingHorizontal: 20 },
	titleContainer: {
		width: "85%",
		height: 50,
		alignSelf: "center",
	},
	titleStyle: {
		width: "100%",
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 24,
		textAlign: "center",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	sentContentContainer: {
		paddingHorizontal: 50,
		marginVertical: 5,
	},
	contentText: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansprobold,
		fontSize: 18,
		marginTop: 20,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		profileSettings: state.currentUser.profileSettings,
		updateUsernameSucceeded: state.app.updateUsernameSucceeded,
		updateUsernameState: state.app.updateUsernameState,
		updateUsernameError: state.app.updateUsernameError,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	clearUpdateUsernameError,
	closeUpdateUsername,
	goBackToMain,
	updateUsername,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUsername);
