import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { toArray } from "react-emoji-render";
import {
	Dimensions,
	FlatList,
	Image,
	Platform,
	ScrollView,
	StyleSheet,
	Text,
	TouchableWithoutFeedback,
	View,
} from "react-native";
import { defaultClientLanguage } from "../../config/connection";
import { appFont, isTablet, RESOLUTION } from "../../config/defaults";
import { getCountryFlagImageURL, getRankingImageURL } from "../../helpers/commonHelpers";
import parse from "../../helpers/emoji_parse";
import { handleError } from "../ErrorHandler";

class ChatMessages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fullWidth: 0,
			flatListHeight: 15,
			initNumToRender: 10,
			scrollViewHeight: 15,
			glChMessageWidth: 0,
			glChInnerContainerWidth: 0,
			scrollViewMsgWidth: 0,
			scrollViewInnerContainerWidth: 0,
		};

		//constants for global chat
		this.discountFromWidth = isTablet ? 340 : 240;
		this.winDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		this.rankDim = isTablet ? 30 : 14;
		if (isTablet && this.winDim.scale >= 1) {
			if (this.winDim.scale < 1.25) {
				this.rankDim = 40;
			} else if (this.winDim.scale > 1.25 && this.winDim.scale <= 1.5) {
				this.rankDim = 30;
			} else if (this.winDim.scale < 3) {
				this.rankDim = 50;
			} else {
				this.rankDim = 150;
			}
		}
	}

	componentDidMount() {
		if (
			this.props.renderLoc == "gameRoom" ||
			this.props.renderLoc == "globalChat" ||
			this.props.renderLoc == "startedGame"
		) {
			this.scrollToEndOfList();
		}
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.renderLoc == "startedGame" &&
			this.props.messages.length > 0 &&
			prevProps.messages.length > 0 &&
			this.props.messages[this.props.messages.length - 1] != prevProps.messages[prevProps.length - 1]
		) {
			this.scrollToEndOfList();
		}
	}

	//#region events
	measureContainer(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const _initNumToRender = Math.round(event.nativeEvent.layout.height / 31);
			const _fullWidth = event.nativeEvent.layout.width;
			const messageWidth = _fullWidth - this.discountFromWidth;
			const innerContainerWidth = _fullWidth - 25;
			const _messageWidth = _fullWidth - 120;
			const _innerContainerWidth = _fullWidth - 10;
			this.setState({
				flatListHeight: event.nativeEvent.layout.height,
				fullWidth: _fullWidth,
				initNumToRender: _initNumToRender,
				glChMessageWidth: messageWidth,
				glChInnerContainerWidth: innerContainerWidth,
				scrollViewMsgWidth: _messageWidth,
				scrollViewInnerContainerWidth: _innerContainerWidth,
			});
		} catch (error) {
			handleError(error);
		}
	}

	measureScrollView(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const { width, height } = event.nativeEvent.layout;
			const _messageWidth = width - 120;
			const _innerContainerWidth = width - 10;
			this.setState({
				scrollViewHeight: height,
				fullWidth: width,
				scrollViewMsgWidth: _messageWidth,
				scrollViewInnerContainerWidth: _innerContainerWidth,
			});
		} catch (error) {
			handleError(error);
		}
	}

	scrollToEndOfList() {
		try {
			const myRef = "flatlist_" + this.props.renderLoc;
			if (this.refs[myRef] && this.props.messages.length > 0) {
				this.refs[myRef].scrollToEnd({ animated: false });
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderRow(item) {
		try {
			const { players, renderLoc, isGlobalMessage } = this.props;
			var msgContent = "";
			switch (renderLoc) {
				case "gameRoom":
				case "startedGame":
					msgContent =
						item.isTranslated == true && item.hadTranslationError == false
							? isGlobalMessage
								? item.translatedContent
								: item.translatedText
							: isGlobalMessage
							? item.originalContent
							: item.originalText;
					break;
				default:
					msgContent = item.translated == true ? item.translatedContent : item.originalContent;
					break;
			}
			msgContent = parse(msgContent);
			const contentArray = toArray(msgContent);
			const emojis = contentArray.filter((el) => typeof el !== "string");
			const texts = contentArray.filter((el) => typeof el === "string");
			var onlyEmoji = texts.length == 0 && emojis.length == 1 ? true : false;
			const useOnlyEmojiChar = texts.length == 0 && emojis.length > 0;
			onlyEmoji = false;

			var userName = "";
			if (item.name == null) {
				const player = players.find((obj) => obj.userID == item.userID);
				if (player != undefined) userName = player.username;
			} else {
				userName = item.name;
			}
			userName += ":";
			switch (renderLoc) {
				case "globalChat":
					const rankingImgURL = getRankingImageURL(item.ranking, this.rankDim);
					const countryImgURL =
						typeof item.country !== "undefined" && item.country != ""
							? getCountryFlagImageURL(item.country.toUpperCase())
							: "https://";
					const clientLang = this.props.clientLanguage ? this.props.clientLanguage : defaultClientLanguage;
					// const time = new Date(item.timestamp).toLocaleTimeString(clientLang); //milliseconds
					//seconds
					const dateFromSeconds = new Date(item.timestamp * 1000);
					const time = dateFromSeconds.toLocaleTimeString(clientLang);
					return (
						<View
							style={[
								styles.innerContainer,
								{
									width: this.state.glChInnerContainerWidth,
									paddingLeft: 5,
									paddingBottom: 2,
								},
							]}
						>
							<Image
								source={rankingImgURL}
								resizeMode="contain"
								style={{
									width: isTablet ? 20 : 14,
									height: isTablet ? 20 : 14,
									marginLeft: 4,
									marginRight: 3,
									marginBottom: isTablet ? 9 : 6,
								}}
							/>
							<Image
								source={{ uri: countryImgURL }}
								style={{
									width: isTablet ? 20 : 14,
									height: isTablet ? 20 : 14,
									marginRight: 3,
									marginBottom: isTablet ? 9 : 6,
								}}
							/>
							<View style={styles.userName}>
								<Text allowFontScaling={false} style={styles.textStyle}>
									{userName}
								</Text>
							</View>
							<View style={{ marginTop: -3 }}>
								<Text
									allowFontScaling={false}
									style={[
										styles.textStyle,
										{
											width: this.state.glChMessageWidth,
											marginRight: 0,
											lineHeight: onlyEmoji ? (isTablet ? 46 : 32) : isTablet ? 20 : 16,
											fontSize: onlyEmoji ? (isTablet ? 36 : 24) : isTablet ? 18 : 12,
											paddingTop: 3,
										},
										Platform.OS === "web" ? { overflow: "hidden", wordBreak: "break-word" } : {},
									]}
								>
									{msgContent}
								</Text>
							</View>
							<View style={{ width: isTablet ? 120 : 80 }}>
								<Text allowFontScaling={false} style={[styles.textStyle, { alignSelf: "flex-end" }]}>
									{time}
								</Text>
							</View>
						</View>
					);
				case "startedGame":
					var innerContainerWidth = this.state.fullWidth - 10;
					const textColor =
						typeof item.slotID !== "undefined" && this.props.useColoredGameChat
							? { color: "#" + item.color }
							: { color: "#a17d4d" };
					var resStyle = {
						fontSize: 12,
						lineHeight: 15,
					};
					var onlyEmojiStyle = onlyEmoji
						? {
								fontSize: 24,
								lineHeight: 34,
						  }
						: resStyle;
					switch (this.props.resolution) {
						case RESOLUTION.LOW:
							resStyle = {
								fontSize: 22,
								lineHeight: 26,
							};
							onlyEmojiStyle = onlyEmoji
								? {
										fontSize: 44,
										lineHeight: 56,
								  }
								: resStyle;
							break;
						case RESOLUTION.MEDIUM:
							resStyle = {
								fontSize: 16,
								lineHeight: 18,
							};
							onlyEmojiStyle = onlyEmoji
								? {
										fontSize: 32,
										lineHeight: 40,
								  }
								: resStyle;
							break;
					}
					return (
						<TouchableWithoutFeedback onPress={this.props.focusFunc}>
							<View style={[styles.innerContainer, { width: innerContainerWidth }]}>
								{(!this.props.useColoredGameChat ||
									this.props.resolution !== RESOLUTION.LOW ||
									(this.props.resolution === RESOLUTION.LOW && useOnlyEmojiChar == true) ||
									this.props.isGlobalMessage ||
									item.isSpectatorMessage) && (
									<Text
										allowFontScaling={false}
										style={[
											styles.textStyle,
											styles.gameChatUserName,
											textColor,
											resStyle,
											// item.isSpectatorMessage && { fontFamily: f_sourcesansprobolditalic },
										]}
									>
										{this.props.resolution === RESOLUTION.LOW && useOnlyEmojiChar == true
											? item.isSpectatorMessage
												? userName.substring(0, 4) + ": "
												: String.fromCodePoint(187)
											: this.props.resolution === RESOLUTION.LOW
											? userName.substring(0, 4) + ": "
											: userName}
									</Text>
								)}
								<Text
									allowFontScaling={false}
									style={[
										styles.textStyle,
										styles.gameChatTextStyle,
										textColor,
										onlyEmojiStyle,
										// item.isSpectatorMessage && !useOnlyEmojiChar { fontFamily: f_sourcesansprobolditalic, },
									]}
								>
									{msgContent}
								</Text>
							</View>
						</TouchableWithoutFeedback>
					);
				case "gameRoom":
				default:
					var _txtStyle = { lineHeight: isTablet ? 24 : 16 };
					return (
						<View style={[styles.innerContainer, { width: this.state.scrollViewInnerContainerWidth }]}>
							<Text allowFontScaling={false} style={[styles.textStyle, styles.userName, _txtStyle]}>
								{userName}
							</Text>
							<Text
								allowFontScaling={false}
								style={[
									styles.textStyle,
									{
										flexShrink: 1,
										width: this.state.scrollViewMsgWidth,
										lineHeight: onlyEmoji ? (isTablet ? 40 : 32) : isTablet ? 24 : 16,
										fontSize: onlyEmoji ? (isTablet ? 36 : 24) : isTablet ? 18 : 12,
									},
								]}
							>
								{msgContent}
							</Text>
						</View>
					);
			}
		} catch (error) {
			handleError(error);
		}
	}

	renderWebMessages() {
		try {
			var msgList = [];
			this.props.messages.forEach((item, index) => {
				var msgContent =
					item.isTranslated == true && item.hadTranslationError == false
						? this.props.isGlobalMessage
							? item.translatedContent
							: item.translatedText
						: this.props.isGlobalMessage
						? item.originalContent
						: item.originalText;
				if (typeof msgContent === "string") {
					msgContent = parse(msgContent);
					const contentArray = toArray(msgContent);
					const emojis = contentArray.filter((el) => typeof el !== "string");
					const texts = contentArray.filter((el) => typeof el === "string");
					var onlyEmoji = texts.length == 0 && emojis.length == 1 ? true : false;
					const useOnlyEmojiChar = texts.length == 0 && emojis.length > 0;
					onlyEmoji = false;

					const { players, renderLoc } = this.props;
					var userName = "";
					if (item.name == null) {
						const player = players.find((obj) => obj.userID == item.userID);
						if (player != undefined) userName = player.username;
					} else {
						userName = item.name;
					}
					userName += ":";
					var innerContainerWidth = renderLoc == "startedGame" ? this.state.fullWidth - 5 : this.state.fullWidth - 40;
					const textColor =
						typeof item.slotID !== "undefined" && this.props.useColoredGameChat
							? { color: "#" + item.color }
							: { color: "#a17d4d" };

					var resStyle = {
						fontSize: 12,
						lineHeight: 15,
						// fontSize: item.isSpectatorMessage ? 13 : 12,
						// lineHeight: item.isSpectatorMessage ? 16 : 15,
					};
					var onlyEmojiStyle = onlyEmoji
						? {
								fontSize: 24,
								lineHeight: 34,
						  }
						: resStyle;
					switch (this.props.resolution) {
						case RESOLUTION.LOW:
							resStyle = {
								fontSize: 22,
								lineHeight: 26,
							};
							onlyEmojiStyle = onlyEmoji
								? {
										fontSize: 44,
										lineHeight: 56,
								  }
								: resStyle;
							break;
						case RESOLUTION.MEDIUM:
							resStyle = {
								fontSize: 16,
								lineHeight: 18,
							};
							onlyEmojiStyle = onlyEmoji
								? {
										fontSize: 32,
										lineHeight: 40,
								  }
								: resStyle;
							break;
					}
					msgList.push(
						<View
							key={"message_" + index}
							style={[
								styles.innerContainer,
								{ width: innerContainerWidth },
								this.props.resolution === RESOLUTION.LOW && renderLoc == "startedGame" && { paddingLeft: 5 },
							]}
						>
							{(!this.props.useColoredGameChat ||
								this.props.resolution !== RESOLUTION.LOW ||
								(renderLoc == "startedGame" && this.props.resolution === RESOLUTION.LOW && useOnlyEmojiChar == true) ||
								this.props.isGlobalMessage ||
								item.isSpectatorMessage) && (
								<Text
									allowFontScaling={false}
									style={[
										styles.textStyle,
										styles.gameChatUserName,
										textColor,
										resStyle,
										item.isSpectatorMessage &&
											{
												// fontFamily: f_sourcesansprobolditalic,
											},
									]}
								>
									{renderLoc == "startedGame" && this.props.resolution === RESOLUTION.LOW && useOnlyEmojiChar == true
										? item.isSpectatorMessage
											? userName.substring(0, 4) + ": "
											: String.fromCodePoint(187)
										: this.props.resolution === RESOLUTION.LOW
										? userName.substring(0, 4) + ": "
										: userName}
								</Text>
							)}
							<Text
								allowFontScaling={false}
								style={[
									styles.textStyle,
									styles.gameChatTextStyle,
									textColor,
									onlyEmojiStyle,
									{ overflow: "hidden", wordBreak: "break-word" },
									Platform.OS === "web" && { userSelect: "text" },
									item.isSpectatorMessage &&
										!useOnlyEmojiChar &&
										{
											// fontFamily: f_sourcesansprobolditalic,
										},
								]}
							>
								{msgContent}
							</Text>
						</View>
					);
				}
			});
			return msgList;
		} catch (error) {
			handleError(error);
		}
	}

	renderWebGameRoomMessages() {
		var result = [];
		this.props.messages.forEach((item) => result.push(this.renderRow(item)));
		return result;
	}

	render() {
		try {
			const { renderLoc, messages, contentHeight, marginTop } = this.props;
			const myRef = "flatlist_" + renderLoc;
			if (renderLoc != "startedGame") {
				if (Platform.OS === "web" && renderLoc == "gameRoom") {
					return (
						<ScrollView
							ref={myRef}
							key="webMessages"
							style={[
								styles.mainContainer,
								{
									padding: 0,
									margin: 0,
									height: contentHeight + 20,
								},
							]}
							contentContainerStyle={{ flex: 1, paddingBottom: 10 }}
							nestedScrollEnabled={true}
							showsVerticalScrollIndicator={false}
							onLayout={(event) => this.measureScrollView(event)}
							getItemLayout={(item, index) => ({
								length: 200,
								offset: 200 * index,
								index,
							})}
						>
							<CustomScroll
								allowOuterScroll={true}
								heightRelativeToParent={contentHeight + "px"}
								minScrollHandleHeight={15}
								scrollTo={this.props.messages.length * 220}
							>
								{this.renderWebGameRoomMessages()}
							</CustomScroll>
						</ScrollView>
					);
				}
				return (
					<FlatList
						ref={myRef}
						onLayout={(event) => this.measureContainer(event)}
						// onContentSizeChange={() => this.scrollToEndOfList()}
						nestedScrollEnabled={true}
						keyExtractor={(item, index) => renderLoc + "_" + index.toString()}
						data={messages}
						extraData={this.props}
						overScrollMode="never"
						initialNumToRender={this.state.initNumToRender}
						renderItem={({ item }) => this.renderRow(item)}
						getItemLayout={(item, index) => ({
							length: 65,
							offset: 65 * index,
							index,
						})}
						style={[
							styles.mainContainer,
							{
								paddingLeft: 0,
								height: contentHeight,
								marginBottom: renderLoc == "gameRoom" ? 5 : 10,
								paddingTop: renderLoc == "gameRoom" ? 0 : 10,
								marginTop: renderLoc == "gameRoom" ? 3 : 0,
							},
						]}
						ListFooterComponent={<View style={{ height: Platform.OS === "web" ? 0 : 5 }} />}
					/>
				);
			} else {
				if (Platform.OS !== "web") {
					return (
						<FlatList
							ref={myRef}
							onLayout={(event) => this.measureContainer(event)}
							nestedScrollEnabled={true}
							keyExtractor={(item, index) => renderLoc + "_" + index.toString()}
							data={messages}
							extraData={this.props.messages}
							renderItem={({ item }) => this.renderRow(item)}
							onContentSizeChange={() => this.scrollToEndOfList()}
							style={[
								styles.mainContainer,
								{
									maxHeight: contentHeight,
									padding: 0,
									margin: 0,
									marginLeft: this.props.resolution === RESOLUTION.LOW ? 0 : 10,
									marginRight: 5,
									paddingTop: typeof marginTop === "number" ? marginTop : 25,
									top: typeof this.props.topPos === "number" ? this.props.topPos : undefined,
									opacity: this.props.containerOpacity,
								},
							]}
						/>
					);
				} else {
					return (
						<ScrollView
							ref={myRef}
							key="webMessages"
							style={[
								styles.mainContainer,
								{
									height: contentHeight,
									padding: 0,
									margin: 0,
									marginRight: 5,
									marginTop: typeof marginTop === "number" ? marginTop : 25,
									top: typeof this.props.topPos === "number" ? this.props.topPos : undefined,
									opacity: this.props.containerOpacity,
								},
							]}
							nestedScrollEnabled={true}
							showsVerticalScrollIndicator={false}
							onLayout={(event) => this.measureScrollView(event)}
							getItemLayout={(item, index) => ({
								length: 200,
								offset: 200 * index,
								index,
							})}
						>
							<CustomScroll
								allowOuterScroll={true}
								heightRelativeToParent={this.state.scrollViewHeight - 5 + "px"}
								minScrollHandleHeight={15}
								scrollTo={this.props.messages.length * 200}
							>
								{this.renderWebMessages()}
							</CustomScroll>
						</ScrollView>
					);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: { padding: 10, paddingBottom: 0 },
	innerContainer: { flexDirection: "row", paddingLeft: 10 },
	textStyle: {
		color: "black",
		textAlign: "left",
		fontFamily: appFont,
		fontSize: isTablet ? 18 : 12,
		lineHeight: isTablet ? 20 : 16,
		marginRight: 5,
	},
	gameChatTextStyle: { color: "#fff", marginRight: 0, flex: 1 },
	userName: { width: isTablet ? 150 : 100 },
	gameChatUserName: { color: "lightblue" },
});

export { ChatMessages };
