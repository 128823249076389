import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import {
	f_sourcesansprobold,
	f_sourcesansprobolditalic,
	f_sourcesansprosemibold,
	RESOLUTION,
} from "../../config/defaults";
import { openComposeMessage } from "../../redux/actions";
import { AppTouchableOpacity, Spinner } from "../common";
import { handleError } from "../ErrorHandler";

class MessageDetails extends Component {
	constructor(props) {
		super(props);
		this.state = { scrollViewHeight: 50 };
	}

	//#region events
	measureScrollView(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const { height } = event.nativeEvent.layout;
			this.setState({ scrollViewHeight: height });
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderButton(type) {
		var _buttonTextStyle = styles.buttonTextStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_buttonTextStyle = [styles.buttonTextStyle, { fontSize: 26 }];
		}
		var buttonText = "",
			onPressFunc = () => {};
		switch (type) {
			case "reply":
				onPressFunc = () => this.props.openComposeMessage(true);
				buttonText = this.props.lang.gameMessage.details.btnReply;
				break;
			case "forward":
				onPressFunc = () => this.props.openComposeMessage(false, true);
				buttonText = this.props.lang.gameMessage.details.btnForward;
				break;
		}
		return (
			<AppTouchableOpacity activeOpacity={1} onPress={onPressFunc} style={styles.buttonStyle}>
				<Text allowFontScaling={false} style={_buttonTextStyle}>
					{buttonText}
				</Text>
			</AppTouchableOpacity>
		);
	}
	renderButtons() {
		return (
			<View style={styles.buttonContainer}>
				{this.renderButton("forward")}
				{this.renderButton("reply")}
			</View>
		);
	}

	renderMessageContent(_textStyle) {
		if (Platform.OS === "web") {
			return (
				<ScrollView
					nestedScrollEnabled={true}
					showsVerticalScrollIndicator={false}
					style={{ flex: 1 }}
					contentContainerStyle={{ paddingTop: 10, paddingHorizontal: 8 }}
					onLayout={(event) => this.measureScrollView(event)}
				>
					<CustomScroll
						allowOuterScroll={true}
						heightRelativeToParent={this.state.scrollViewHeight - 25 + "px"}
						minScrollHandleHeight={40}
					>
						<Text allowFontScaling={false} style={_textStyle}>
							{this.props.selectedMessage.content}
						</Text>
					</CustomScroll>
				</ScrollView>
			);
		}
		return (
			<ScrollView
				style={{
					flex: 1,
					marginTop: 14,
					marginBottom: 3,
					marginHorizontal: 8,
				}}
			>
				<Text allowFontScaling={false} style={_textStyle}>
					{this.props.selectedMessage.content}
				</Text>
			</ScrollView>
		);
	}

	renderContentField(type) {
		var _contentTextStyle = [styles.contentTextStyle],
			fieldContainerStyle = {},
			_content = "";

		if (this.props.resolution === RESOLUTION.HIGH) {
			_contentTextStyle = [styles.contentTextStyle, { fontSize: 26 }];
		}

		switch (type) {
			case "from":
				fieldContainerStyle = {
					height: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
				};
				_contentTextStyle = [..._contentTextStyle, { lineHeight: this.props.resolution === RESOLUTION.HIGH ? 60 : 50 }];
				_content = this.props.selectedMessage.fromUsername;
				break;
			case "title":
				fieldContainerStyle = {
					height: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
				};
				_contentTextStyle = [..._contentTextStyle, { lineHeight: this.props.resolution === RESOLUTION.HIGH ? 60 : 50 }];
				_content = this.props.selectedMessage.subject;
				break;
			case "message":
				fieldContainerStyle = { flex: 1 };
				_contentTextStyle = [..._contentTextStyle, { flex: 1, paddingLeft: 0, paddingHorizontal: 0 }];
				_content = this.props.selectedMessage.content;
				break;
		}

		return (
			<View style={fieldContainerStyle}>
				{type == "message" && this.renderMessageContent(_contentTextStyle)}
				{type != "message" && (
					<Text allowFontScaling={false} style={_contentTextStyle}>
						{_content}
					</Text>
				)}
			</View>
		);
	}

	renderLabel(type) {
		var _labelStyle = styles.labelStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_labelStyle = [styles.labelStyle, { fontSize: 26, lineHeight: 60 }];
		}
		var content = "";
		switch (type) {
			case "from":
				content = this.props.lang.gameMessage.details.lblFrom;
				break;
			case "title":
				content = this.props.lang.gameMessage.details.lblTitle;
				break;
			case "message":
				content = this.props.lang.gameMessage.details.lblMessage;
				break;
		}
		return (
			<View
				key={"lbl_" + type}
				style={[
					styles.leftColumn,
					{
						width: this.props.resolution === RESOLUTION.HIGH ? 130 : 100,
					},
				]}
			>
				<Text style={_labelStyle}>{content}</Text>
			</View>
		);
	}

	renderRow(type) {
		var containerStyles = styles.rowContainer;
		if (type == "message") containerStyles = [styles.rowContainer, { flex: 1 }];
		return (
			<View style={containerStyles}>
				{this.renderLabel(type)}
				<View style={styles.rightColumn}>{this.renderContentField(type)}</View>
			</View>
		);
	}

	renderContent() {
		if (this.props.selectedMessage == null) return <Spinner size="large" />;
		return (
			<>
				<View style={styles.contentContainer}>
					{this.renderRow("from")}
					{this.renderRow("title")}
					{this.renderRow("message")}
				</View>
				{this.renderButtons()}
			</>
		);
	}

	render() {
		return (
			<View style={[styles.container, this.props.resolution === RESOLUTION.HIGH && styles.containerHR]}>
				{this.renderContent()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "flex-end",
		marginHorizontal: 5,
		marginTop: 10,
	},
	containerHR: { marginHorizontal: 10 },
	buttonContainer: {
		flexDirection: "row",
		alignSelf: "flex-end",
		alignItems: "flex-end",
		marginTop: 10,
		marginBottom: 15,
	},
	buttonStyle: {
		width: 200,
		height: 60,
		marginLeft: 20,
		alignSelf: "center",
		borderWidth: 2,
		borderRadius: 10,
		borderColor: "#24cdef",
		backgroundColor: "#0a5f6e",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		shadowColor: "#06525a",
		shadowOffset: {
			width: 2,
			height: 4,
		},
		shadowRadius: 4,
		shadowOpacity: 0.8,
		elevation: 5,
	},
	buttonTextStyle: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		color: "#FFFFFF",
	},
	contentContainer: {
		flex: 1,
		borderWidth: 3,
		borderColor: "#095260",
		borderRadius: 10,
		overflow: "hidden",
		shadowColor: "#065C63",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowRadius: 6,
		shadowOpacity: 0.8,
		elevation: 5,
		backgroundColor: "rgb(57, 232, 242)",
	},
	leftColumn: { marginRight: 20 },
	rightColumn: {
		flex: 1,
		paddingRight: 10,
		backgroundColor: "rgba(36, 204, 216, 0.8)",
	},
	rowContainer: {
		flexDirection: "row",
		borderBottomWidth: 1,
		borderBottomColor: "#47e7ee",
		justifyContent: "center",
	},
	labelStyle: {
		fontFamily: f_sourcesansprobolditalic,
		fontSize: 20,
		color: "#07525f",
		lineHeight: 50,
		textAlign: "right",
	},
	contentTextStyle: {
		width: "100%",
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		marginRight: 2,
		paddingHorizontal: 8,
		textAlign: "left",
		backgroundColor: "transparent",
		color: "#07525f",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedMessage: state.gameMessage.selectedMessage,
	};
};

const mapDispatchToProps = { openComposeMessage };

export default connect(mapStateToProps, mapDispatchToProps)(MessageDetails);
