import React, { Component } from "react";
import { ImageBackground, Platform, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor, RESOLUTION } from "../../../../../config/defaults";
import { gameDimensions } from "../../../../../config/dimensions";
import {
	closeGraphicalPreferences,
	goBackToMain,
	openBoardPreferences,
	openCardsPreferences,
	openFixBoardPositions,
	openScreenPreferences,
	openShowRunningGamesInList,
} from "../../../../../redux/actions";
import Analytics from "../../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader } from "../../../../common";
import BoardPreferences from "./Board/BoardPreferences";
import CardsPreferences from "./Cards/CardsPreferences";
import ScreenResolution from "./ScreenResolution";
import ShowRunningGames from "./ShowRunningGames";

class GraphicalPreferences extends Component {
	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("GraphicalPreferencesMenu");
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeGraphicalPreferences}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "";
		switch (btnType) {
			case "cards":
				textContent = this.props.lang.preferencesPage.cards;
				onPressFunc = () => {
					this.props.openCardsPreferences();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "board":
				textContent = this.props.lang.preferencesPage.board;
				onPressFunc = () => {
					this.props.openBoardPreferences();
					this.props.openFixBoardPositions();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
			case "screen":
				textContent = this.props.lang.preferencesPage.screen;
				onPressFunc = () => {
					this.props.openScreenPreferences();
				};
				_buttonColor = buttonColor.BLUE;
				break;
			case "gameList":
				textContent = this.props.lang.preferencesPage.gameList;
				onPressFunc = () => {
					this.props.openShowRunningGamesInList();
				};
				_buttonColor = buttonColor.GREEN;
				break;
		}

		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				disabled={false}
				additionalTextStyle={_buttonTextStyle}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{Platform.OS === "ios" && this.renderHeader()}
					{this.renderButton("cards")}
					{this.renderButton("board")}
					{this.renderButton("screen")}
					{this.renderButton("gameList")}
				</ScrollView>
				{Platform.OS !== "ios" && this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.cardsPreferencesOpened) {
			return <CardsPreferences />;
		} else if (this.props.boardPreferencesOpened) {
			return <BoardPreferences />;
		} else if (this.props.screenPreferencesOpened) {
			return <ScreenResolution />;
		} else if (this.props.showRunningGamesInListOpened) {
			return <ShowRunningGames />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		paddingHorizontal: Platform.OS === "ios" ? 0 : 55,
	},
	containerContent: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		cardsPreferencesOpened: state.app.cardsPreferencesOpened,
		boardPreferencesOpened: state.app.boardPreferencesOpened,
		screenPreferencesOpened: state.app.screenPreferencesOpened,
		showRunningGamesInListOpened: state.app.showRunningGamesInListOpened,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeGraphicalPreferences,
	goBackToMain,
	openBoardPreferences,
	openCardsPreferences,
	openFixBoardPositions,
	openScreenPreferences,
	openShowRunningGamesInList,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphicalPreferences);
