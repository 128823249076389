import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, ScrollView, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, f_sourcesansprobold, processState, RESOLUTION } from "../../../../../config/defaults";
import { emailNotifSettingsDimensions, gameDimensions } from "../../../../../config/dimensions";
import CheckBox from "../../../../../libraries/CheckBox";
import { closeAppNotificationsSettings, goBackToMain, updateAppNotifications } from "../../../../../redux/actions";
import Analytics from "../../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../../../../common";
import { handleError } from "../../../../ErrorHandler";

class AppNotifications extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			newFriendGameEnabled: this.props.appNotifyOnNewFriendGame,
			friendEnabled: this.props.appNotifyOnFriendOnline,
			tournamentEnabled: this.props.appNotifyOnTournaments,
			privateChatEnabled: this.props.appNotifyOnPrivateChat,
			chatEnabled: this.props.appNotifyOnChat,
			groupChatEnabled: this.props.appNotifyOnGroupChat,
			groupOnlineEnabled: this.props.appNotifyOnGroupOnline,
			messageEnabled: this.props.appNotifyOnMessage,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("AppNotificationsPreferences");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.appNotifyOnNewFriendGame !== this.props.appNotifyOnNewFriendGame) {
			this.setState({
				newFriendGameEnabled: this.props.appNotifyOnNewFriendGame,
			});
		}
		if (prevProps.appNotifyOnFriendOnline !== this.props.appNotifyOnFriendOnline) {
			this.setState({ friendEnabled: this.props.appNotifyOnFriendOnline });
		}
		if (prevProps.appNotifyOnTournaments !== this.props.appNotifyOnTournaments) {
			this.setState({ tournamentEnabled: this.props.appNotifyOnTournaments });
		}
		if (prevProps.appNotifyOnPrivateChat !== this.props.appNotifyOnPrivateChat) {
			this.setState({ privateChatEnabled: this.props.appNotifyOnPrivateChat });
		}
		if (prevProps.appNotifyOnChat !== this.props.appNotifyOnChat) {
			this.setState({ chatEnabled: this.props.appNotifyOnChat });
		}
		if (prevProps.appNotifyOnGroupChat !== this.props.appNotifyOnGroupChat) {
			this.setState({ groupChatEnabled: this.props.appNotifyOnGroupChat });
		}
		if (prevProps.appNotifyOnGroupOnline !== this.props.appNotifyOnGroupOnline) {
			this.setState({ groupOnlineEnabled: this.props.appNotifyOnGroupOnline });
		}
		if (prevProps.appNotifyOnMessage !== this.props.appNotifyOnMessage) {
			this.setState({ messageEnabled: this.props.appNotifyOnMessage });
		}
	}
	//#endregion

	//#region events
	onSavePress() {
		try {
			const data = {
				appNotifyOnNewFriendGame: this.state.newFriendGameEnabled,
				appNotifyOnFriendOnline: this.state.friendEnabled,
				appNotifyOnTournaments: this.state.tournamentEnabled,
				appNotifyOnPrivateChat: this.state.privateChatEnabled,
				appNotifyOnChat: this.state.chatEnabled,
				appNotifyOnGroupChat: this.state.groupChatEnabled,
				appNotifyOnGroupOnline: this.state.groupOnlineEnabled,
				appNotifyOnMessage: this.state.messageEnabled,
			};
			this.props.updateAppNotifications(data);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateAppNotificationsState != processState.STARTED}
				backAction={this.props.closeAppNotificationsSettings}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderSaveButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onSavePress.bind(this)}
					textContent={this.props.lang.save}
					accessibilityLabel={this.props.lang.save}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderCheck(type) {
		var title = "",
			changeFunc = () => {},
			value;

		switch (type) {
			case "newFriendGame":
				title = this.props.lang.appNotifications.newFriendGame;
				value = this.state.newFriendGameEnabled;
				changeFunc = () => {
					this.setState({
						newFriendGameEnabled: !this.state.newFriendGameEnabled,
					});
				};
				break;
			case "friend":
				title = this.props.lang.appNotifications.friend;
				value = this.state.friendEnabled;
				changeFunc = () => {
					this.setState({
						friendEnabled: !this.state.friendEnabled,
					});
				};
				break;
			case "tournament":
				title = this.props.lang.appNotifications.tournament;
				value = this.state.tournamentEnabled;
				changeFunc = () => {
					this.setState({ tournamentEnabled: !this.state.tournamentEnabled });
				};
				break;
			case "privateChat":
				title = this.props.lang.appNotifications.privateChat;
				value = this.state.privateChatEnabled;
				changeFunc = () => {
					this.setState({ privateChatEnabled: !this.state.privateChatEnabled });
				};
				break;
			case "chat":
				title = this.props.lang.appNotifications.chat;
				value = this.state.chatEnabled;
				changeFunc = () => {
					this.setState({ chatEnabled: !this.state.chatEnabled });
				};
				break;
			case "groupChat":
				title = this.props.lang.appNotifications.groupChat;
				value = this.state.groupChatEnabled;
				changeFunc = () => {
					this.setState({ groupChatEnabled: !this.state.groupChatEnabled });
				};
				break;
			case "groupOnline":
				title = this.props.lang.appNotifications.groupOnline;
				value = this.state.groupOnlineEnabled;
				changeFunc = () => {
					this.setState({ groupOnlineEnabled: !this.state.groupOnlineEnabled });
				};
				break;
			case "message":
				title = this.props.lang.appNotifications.message;
				value = this.state.messageEnabled;
				changeFunc = () => {
					this.setState({ messageEnabled: !this.state.messageEnabled });
				};
				break;
		}
		var _innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center" }],
			_inputLabel = [styles.dataTitle, styles.dataText],
			_checkBoxStyle = [
				Platform.OS === "web" ? styles.checkBox : { transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }] },
			];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center", height: 70 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_checkBoxStyle = [
				Platform.OS === "web"
					? [styles.checkBox, { width: 30, height: 30 }]
					: { transform: [{ scale: Platform.OS === "ios" ? 1 : 1.75 }] },
			];
		}
		return (
			<View style={_innerContainerStyle}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{title}
				</Text>
				<View style={styles.checkboxContainer}>
					<CheckBox value={value} onValueChange={changeFunc} style={_checkBoxStyle} />
				</View>
			</View>
		);
	}

	renderContent() {
		return (
			<View
				style={{
					flex: 1,
					paddingHorizontal: 20,
					paddingBottom: 5,
					// transform: [{ scale: this.state.contentScale }],
				}}
			>
				<ScrollView
					style={{ flex: 1, marginBottom: 70 }}
					contentContainerStyle={styles.containerContent}
					centerContent={true}
				>
					{this.renderCheck("newFriendGame")}
					{this.renderCheck("friend")}
					{this.renderCheck("tournament")}
					{this.renderCheck("privateChat")}
					{this.renderCheck("chat")}
					{this.renderCheck("groupChat")}
					{this.renderCheck("groupOnline")}
					{this.renderCheck("message")}
				</ScrollView>
				{this.renderSaveButton()}
			</View>
		);
	}

	render() {
		if (this.props.updateAppNotificationsState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);

		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("screen");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height; //- 50;
			if (
				emailNotifSettingsDimensions.width > availableWidth ||
				emailNotifSettingsDimensions.height > availableHeight
			) {
				const _scaleX = Math.round((availableWidth / emailNotifSettingsDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / emailNotifSettingsDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	containerContent: {
		justifyContent: "center",
	},
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 2,
		marginBottom: 2,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 400,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	checkboxContainer: {
		width: 100,
		height: 40,
		flexDirection: "row",
		paddingTop: 5,
	},
	checkBox: { width: 20, height: 20, alignSelf: "center" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		appNotifyOnNewFriendGame: state.currentUser.preferences.appNotifyOnNewFriendGame,
		appNotifyOnFriendOnline: state.currentUser.preferences.appNotifyOnFriendOnline,
		appNotifyOnTournaments: state.currentUser.preferences.appNotifyOnTournaments,
		appNotifyOnPrivateChat: state.currentUser.preferences.appNotifyOnPrivateChat,
		appNotifyOnChat: state.currentUser.preferences.appNotifyOnChat,
		appNotifyOnGroupChat: state.currentUser.preferences.appNotifyOnGroupChat,
		appNotifyOnGroupOnline: state.currentUser.preferences.appNotifyOnGroupOnline,
		appNotifyOnMessage: state.currentUser.preferences.appNotifyOnMessage,
		updateAppNotificationsState: state.app.updateAppNotificationsState,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeAppNotificationsSettings,
	goBackToMain,
	updateAppNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNotifications);
