import React, { Component } from "react";
import { Dimensions, FlatList, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { isTablet, isWebOrTablet, RESOLUTION } from "../../config/defaults";
import { gameDetailsColumns, gameDimensions } from "../../config/dimensions";
import { MyWebSocket } from "../../connection";
import * as helpers from "../../helpers/commonHelpers";
import {
	clearGameDetailsInLobby,
	clearGameSelection,
	displayPermissionDenied,
	gameDetailsLoadingFinished,
	gameDetailsTableContentSizeChanged,
	gameDetailsTableHeaderSize,
	gameDetailsTableRendered,
	mouseEnterSlotInLobby,
	mouseLeaveSlotInLobby,
	openGameRoom,
	requestAdditionalGameDetailsTableHeight,
	selectSlotInLobby,
} from "../../redux/actions";
import { store } from "../../redux/store";
import { Spinner, TableHeader, TableHeaderCell, TableRow, TableRowCell } from "../common";
import { handleError } from "../ErrorHandler";

class GameDetailsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columnNames: this.getColumNames(),
			maxVisibleRows: 1,
		};
		this.requestedHeight = [Number("0")];
	}

	//#region lifecycle methods
	componentDidMount() {
		this.props.gameDetailsTableRendered(this.props.tableKey);
	}

	shouldComponentUpdate(nextProps) {
		if (
			this.props.gameDetailsTable.additionalHeight != nextProps.gameDetailsTable.additionalHeight &&
			nextProps.gameDetailsTable.additionalHeight == 0
		) {
			this.requestedHeight = [Number("0")];
		}
		return true;
	}

	componentWillUnmount() {
		this.props.clearGameSelection();
		this.props.clearGameDetailsInLobby();
	}
	//#endregion

	//#region events
	tableRendered(event) {
		try {
			const tableHeight = event.nativeEvent.layout.height;
			const maxVisibleRows = isWebOrTablet ? Math.floor(tableHeight / 31) : Math.floor(tableHeight / 20);
			const { gamePlayersList } = this.props.selectedGame;
			if (gamePlayersList.length > maxVisibleRows && this.props.resolution == RESOLUTION.LOW) {
				const additionalRowNr = gamePlayersList.length - maxVisibleRows;
				var additionalHeight = isWebOrTablet ? additionalRowNr * 31 : additionalRowNr * 20;

				const { gameDetailsHeight } = store.getState().views;
				const tableHeaderHeight = isTablet ? 22 : 16;
				const tmpHeight =
					gameDimensions.boxHeaderHeight +
					this.props.gameDetailsTable.contentHeight +
					tableHeaderHeight +
					this.props.gameDetailsTable.additionalHeight +
					additionalHeight;
				if (tmpHeight > gameDetailsHeight) {
					additionalHeight =
						gameDetailsHeight -
						gameDimensions.boxHeaderHeight -
						this.props.gameDetailsTable.contentHeight -
						tableHeaderHeight -
						this.props.gameDetailsTable.additionalHeight;
				}

				if (this.requestedHeight.indexOf(additionalHeight) == -1) {
					this.requestedHeight.push(additionalHeight);
					this.props.requestAdditionalGameDetailsTableHeight(additionalHeight);
				}
				this.setState({ maxVisibleRows });
			}
		} catch (error) {
			handleError(error);
		}
	}

	onHeaderLayout(event) {
		try {
			const { gameDetailsTableHeaderSize } = this.props;
			if (Platform.OS !== "web") event.persist();
			gameDetailsTableHeaderSize(event.nativeEvent.layout);
		} catch (error) {
			handleError(error);
		}
	}

	onRowSelect(slotID) {
		this.props.selectSlotInLobby(slotID);
	}

	onRowDoubleTap(slotID) {
		try {
			this.props.selectSlotInLobby(slotID);
			setTimeout(() => {
				const { selectedGameID, gameListOrig } = this.props;
				const selGameIndex = gameListOrig.findIndex((obj) => obj.gameID == selectedGameID);
				var permission = this.checkPermission(gameListOrig[selGameIndex].playerCnt);

				if (!permission.enabled) {
					this.props.displayPermissionDenied(permission.rightID);
				} else {
					const { gamePlayersList, selectedSlotID } = this.props.selectedGame;
					if (selectedSlotID != -1) {
						const gameName = selGameIndex == -1 ? "" : gameListOrig[selGameIndex].name;
						const cardset = selGameIndex == -1 ? "" : gameListOrig[selGameIndex].cardset;

						var firstOpenSlotID = gamePlayersList.findIndex((obj) => obj.username == this.props.lang.openSlot);
						var slotIDToJoin = selectedSlotID;
						if (slotIDToJoin > -1) {
							var selSlot = gamePlayersList.find((obj) => obj.slotID == slotIDToJoin);
							if (selSlot.username != this.props.lang.openSlot) slotIDToJoin = firstOpenSlotID;
						} else {
							slotIDToJoin = firstOpenSlotID;
						}

						if (slotIDToJoin != -1) {
							var msgGameJoin = {
								type: "joinGame",
								sMessageID: 0,
								gameID: selectedGameID,
								slotID: slotIDToJoin,
								swap: false,
							};
							MyWebSocket.shared.sendMsg(msgGameJoin);
							this.props.openGameRoom(selectedGameID, gameName, cardset);
							this.props.clearGameSelection();
						}
					}
				}
			}, 100);
		} catch (error) {
			handleError(error);
		}
	}

	onMouseEnter(slotID) {
		this.props.mouseEnterSlotInLobby(slotID);
	}

	onMouseLeave(slotID) {
		this.props.mouseLeaveSlotInLobby(slotID);
	}

	onRawLayout(index) {
		try {
			if (index == this.props.selectedGame.gamePlayersList.length - 1) {
				//set loadingGamePlayersList to false in redux state
				this.props.gameDetailsLoadingFinished();
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderRow(item, index) {
		const rowId = "row_" + item.slotID;
		const { gameDetailsTableColumnsDimensions } = this.props;
		let cells = [];
		helpers.mapObject(this.state.columnNames, (key) => {
			if (typeof item[key] !== "undefined" || key == "avatar") {
				let cellId = key + "_" + item.slotID;
				let cellKey = "cell_" + cellId;
				let textStyle =
					gameDetailsColumns[key].cellType !== "img"
						? {
								textAlign: "center",
								color: "#FFFFFF",
								lineHeight: 40,
								height: 40,
								fontSize: 20,
						  }
						: {};
				textStyle = [textStyle, isWebOrTablet && (key == "slotID" || key == "teamName") && { paddingHorizontal: 0 }];

				var imgDim = {},
					laurelDim = {},
					_isVip = false,
					showToolTip = false,
					tooltipText = "";
				switch (key) {
					case "username":
						let freeSlotLang = this.props.lang.openSlot;
						if (item[key] == freeSlotLang) textStyle = [textStyle, styles.openSlotText];
						break;
					case "country":
						var countryURL = {};
						if (typeof item[key] === "string" && item[key] != "")
							countryURL = {
								uri: helpers.getCountryFlagImageURL(item[key], 40),
							};
						imgDim = { width: 30, height: 30 };
						showToolTip = Platform.OS === "web";
						const countryCode = typeof item[key] === "string" ? item[key].toLowerCase() : undefined;
						tooltipText =
							countryCode && typeof this.props.lang.countryList[countryCode] === "string"
								? this.props.lang.countryList[countryCode]
								: undefined;
						break;
					case "ranking":
						var rankingURL = {};
						_isVip = item.isVip;
						var rankDim = 30;
						const winDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
						if (winDim.scale > 1) {
							if (winDim.scale < 2.5) {
								rankDim = 50;
							} else {
								rankDim = 150;
							}
						}
						// if (["EASY", "MEDIUM", "HARD", "RANDOM"].includes(item[key])) {
						//   rankingURL = helpers.getRankingImageURL("", rankDim);
						// } else {
						//   rankingURL = helpers.getRankingImageURL(item[key], rankDim);
						// }
						rankingURL = helpers.getRankingImageURL(item[key], rankDim);
						imgDim = { width: 30, height: 30 };
						laurelDim = { width: 54, height: 54 };
						if (["EASY", "VERY_EASY", "MEDIUM", "HARD", "VERY_HARD"].includes(item[key])) {
							showToolTip = Platform.OS === "web";
							tooltipText =
								typeof this.props.lang[item[key].toLowerCase()] === "string"
									? this.props.lang[item[key].toLowerCase()]
									: undefined;
						}
						break;
					case "avatar":
						if (item.userID && !item.userID.startsWith("generated")) {
							const _tmpURL = "https://" + this.props.avatars + item.userID + "/" + item.curDate;
							var avatarURL = item.userID.startsWith("generated") ? { uri: "https://" } : { uri: _tmpURL };
							imgDim = { width: 40, height: 40 };
						}
						if (item.botImage && item.botImage != "" && item.botImage.endsWith(".png")) {
							var avatarURL = {
								uri: "https://" + this.props.botAvatars + item.botImage,
							};
							imgDim = { width: 40, height: 40 };
						}
						break;
				}

				const _cell = (
					<TableRowCell
						lang={this.props.lang}
						isSelected={item.isSelected}
						isHovered={item.isHovered}
						backgroundColor={"transparent"}
						selectedBackgroundColor={"#F6A022"}
						style={textStyle}
						cellWidth={gameDetailsTableColumnsDimensions[key]}
						key={cellKey}
						cellId={cellId}
						content={key == "slotID" ? item[key] + 1 : item[key]}
						cellType={gameDetailsColumns[key].cellType || "text"}
						imgURL={avatarURL || countryURL || rankingURL}
						isVip={_isVip}
						laurelDim={laurelDim}
						imgDim={imgDim}
						cellContainerStyle={{ height: 40 }}
						showToolTip={showToolTip}
						tooltipText={tooltipText}
					/>
				);
				/*  if (key === "teamName") {
          if (this.props.resolution !== RESOLUTION.LOW && isWebOrTablet)
            cells.push(_cell);
        } else {
          cells.push(_cell);
        } */
				cells.push(_cell);
			}
		});

		const rowSelectFunc = () => this.onRowSelect(item.slotID);
		const rowDoubleTapFunc = () => this.onRowDoubleTap(item.slotID);
		const rowMouseEnterFunc = () => this.onMouseEnter(item.slotID);
		const rowMouseLeaveFunc = () => this.onMouseLeave(item.slotID);
		const rowOnLayoutFunc = () => this.onRawLayout(index);
		return (
			<TableRow
				rowId={rowId}
				onPress={rowSelectFunc}
				onDoubleTap={rowDoubleTapFunc}
				onMouseEnter={rowMouseEnterFunc}
				onMouseLeave={rowMouseLeaveFunc}
				onLayout={rowOnLayoutFunc}
			>
				{cells}
			</TableRow>
		);
	}

	renderHeader() {
		const { isGameCreator, tableKey } = this.props;
		if (typeof isGameCreator !== "boolean") return null;

		const columnDimensions = this.getColumnDimensions();
		const headerCells = [];

		helpers.mapObject(this.state.columnNames, (key, value) => {
			let cWidth = 0;
			if (typeof columnDimensions[key] === "number") cWidth = columnDimensions[key];
			const textStyle = {
				textAlign: "center",
				backgroundColor: "transparent",
				alignSelf: isTablet && this.props.resolution == RESOLUTION.LOW ? "flex-end" : "center",
			};
			const containerStyle = {
				width: cWidth,
				justifyContent: "center",
				backgroundColor: "transparent",
			};

			let headerKey = key + (tableKey || "") + "Header";

			const _headerCell = (
				<TableHeaderCell
					key={headerKey}
					cellContent={value}
					containerStyle={[
						containerStyle,
						this.props.resolution == RESOLUTION.LOW && {
							minHeight: isTablet ? 22 : 16,
							height: isTablet ? 22 : 16,
						},
					]}
					textStyle={textStyle}
					isSortable={false}
					isSelected={false}
					isAscending={false}
				/>
			);
			if (key === "teamName") {
				if (this.props.resolution !== RESOLUTION.LOW && isWebOrTablet) headerCells.push(_headerCell);
			} else {
				headerCells.push(_headerCell);
			}
		});

		return <TableHeader onLayout={(event) => this.onHeaderLayout(event)}>{headerCells}</TableHeader>;
	}

	renderTable() {
		if (this.props.selectedGame.loading) {
			return <Spinner size="large" />;
		}
		const { isGameCreator, selectedGame, gameDetailsTableContentSizeChanged, gameDetailsTable } = this.props;
		if (typeof isGameCreator !== "boolean") return null;

		return (
			<FlatList
				accessibilityLabel={"Game's details"}
				keyExtractor={(player) => player.slotID.toString()}
				data={selectedGame.gamePlayersList || []}
				extraData={this.props}
				renderItem={({ item, index }) => this.renderRow(item, index)}
				getItemLayout={(data, index) => ({
					length: 42,
					offset: 42 * index,
					index,
				})}
				onContentSizeChange={(width, height) => {
					gameDetailsTableContentSizeChanged({ width, height });
				}}
				style={{
					height:
						this.props.resolution != RESOLUTION.LOW
							? this.props.gameDetailsTable.contentHeight
							: this.props.gameDetailsTable.contentHeight + this.props.gameDetailsTable.additionalHeight,
				}}
				onLayout={(event) => this.tableRendered(event)}
			/>
		);
	}

	render() {
		const _tableStyle = {
			height:
				this.props.resolution != RESOLUTION.LOW
					? this.props.gameDetailsTable.contentHeight + 14
					: this.props.gameDetailsTable.contentHeight + this.props.gameDetailsTable.additionalHeight + 14,
		};
		return (
			<View style={_tableStyle}>
				{/* {this.renderHeader()} */}
				{this.renderTable()}
			</View>
		);
	}
	//#endregion

	//#region helpers
	getColumNames() {
		try {
			let columnNames = {};
			const { gameDetailsTableColumns } = this.props.lang;
			helpers.mapObject(gameDetailsColumns, (key) => {
				if (key !== "teamName" && key !== "slotID") columnNames[key] = gameDetailsTableColumns[key] || "";
			});
			return columnNames;
		} catch (error) {
			handleError(error);
		}
	}

	getColumnDimensions() {
		try {
			const { gameDetailsTableColumnsDimensions } = this.props;
			let dims = {};
			helpers.mapObject(gameDetailsTableColumnsDimensions, (key) => {
				dims[key] = gameDetailsTableColumnsDimensions[key];
			});
			return dims;
		} catch (error) {
			handleError(error);
		}
	}

	checkPermission(playerAmount) {
		try {
			var result = { rightID: "", enabled: true };
			var permissionID = "GAME_PLAY_CAN_PLAY_WITH_" + playerAmount + "_PLAYERS";
			if (typeof this.props[permissionID] === "boolean") {
				result.rightID = permissionID;
				result.enabled = this.props[permissionID];
			}
			return result;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	openSlotText: {
		color: "#000000",
	},
});

const mapStateToProps = (state) => {
	const { features } = state.currentUser;
	return {
		lang: state.language.currentLanguage,
		avatars: state.app.welcome.webResources.avatars,
		botAvatars: state.app.welcome.webResources.botAvatars,
		gameDetailsTable: state.views.gameDetailsTable,
		gameDetailsTableColumnsDimensions: state.views.gameDetailsTableColumnsDimensions,
		selectedGame: state.gameDetailsData.selectedGame,
		selectedGameID: state.gameListData.selectedGameID,
		gameListOrig: state.gameListData.gameListOrig,
		resolution: state.currentUser.preferences.resolution,
		GAME_PLAY_CAN_PLAY_WITH_2_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_2_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_3_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_3_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_4_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_4_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_5_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_5_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_6_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_6_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_7_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_7_PLAYERS,
		GAME_PLAY_CAN_PLAY_WITH_8_PLAYERS: features.GAME_PLAY_CAN_PLAY_WITH_8_PLAYERS,
	};
};

const mapDispatchToProps = {
	clearGameDetailsInLobby,
	clearGameSelection,
	displayPermissionDenied,
	gameDetailsLoadingFinished,
	gameDetailsTableContentSizeChanged,
	gameDetailsTableHeaderSize,
	gameDetailsTableRendered,
	mouseEnterSlotInLobby,
	mouseLeaveSlotInLobby,
	openGameRoom,
	requestAdditionalGameDetailsTableHeight,
	selectSlotInLobby,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameDetailsTable);
