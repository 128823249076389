import { RESET_STARTED_GAME } from "../../Keezers/redux/actions/actionTypes";
import { RESET_CLIENT, RESET_GAME_ROOM } from "../actions/actionTypes";
import {
	CHANGE_GAME_ROOM_CHAT_TEXT,
	CHANGE_GLOBAL_CHAT_TEXT,
	CHANGE_STARTED_GAME_CHAT_TEXT,
	CLEAR_GAME_ROOM_TEXT,
	CLEAR_GLOBAL_CHAT_TEXT,
	CLEAR_STARTED_GAME_CHAT_TEXT,
	UPDATE_CREATED_GAME_ACTIVE_CHATROOM,
	UPDATE_IN_GAME_ACTIVE_CHATROOM,
} from "../actionTypes";

const initialState = {
	activeInGameChatRoomID: -1,
	msgStartedGameChat: "",
	msgGlobalChat: "",
	activeChatRoomID: -1,
	msgGameRoomChat: "",
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case CHANGE_GLOBAL_CHAT_TEXT:
			if (!payload || typeof payload.text !== "string") return state;
			return { ...state, msgGlobalChat: payload.text };
		case CLEAR_GLOBAL_CHAT_TEXT:
			return { ...state, msgGlobalChat: "" };
		case CHANGE_GAME_ROOM_CHAT_TEXT:
			if (!payload || typeof payload.text !== "string") return state;
			return { ...state, msgGameRoomChat: payload.text };
		case CLEAR_GAME_ROOM_TEXT:
			return { ...state, msgGameRoomChat: "" };
		case UPDATE_CREATED_GAME_ACTIVE_CHATROOM:
			if (typeof payload === "undefined" || typeof payload.activeRoomID === "undefined") return state;
			return { ...state, activeChatRoomID: payload.activeRoomID };
		case CHANGE_STARTED_GAME_CHAT_TEXT:
			if (!payload || typeof payload.text !== "string") return state;
			return { ...state, msgStartedGameChat: payload.text };
		case CLEAR_STARTED_GAME_CHAT_TEXT:
			return { ...state, msgStartedGameChat: "" };
		case UPDATE_IN_GAME_ACTIVE_CHATROOM:
			if (typeof payload === "undefined" || typeof payload.activeRoomID === "undefined") return state;
			return { ...state, activeInGameChatRoomID: payload.activeRoomID };
		case RESET_STARTED_GAME:
		case RESET_GAME_ROOM:
			return {
				...state,
				activeInGameChatRoomID: -1,
				msgStartedGameChat: "",
				msgGlobalChat: "",
				activeChatRoomID: -1,
				msgGameRoomChat: "",
			};
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
