import { MyWebSocket } from "../../connection";
import { TOURNAMENT_ACTIONS } from "../actionTypes";

export const setTournamentScreenActive = (status) => {
	return {
		type: TOURNAMENT_ACTIONS.SET_TOURNAMENT_SCREEN_ACTIVE,
		payload: status,
	};
};

export const clearTournamentList = () => {
	return {
		type: TOURNAMENT_ACTIONS.CLEAR_TOURNAMENT_LIST,
	};
};

export const subscribeToTournamentsList = () => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "tournamentListSubscribe",
		});
	};
};

export const unsubscribeFromTournamentsList = () => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "tournamentListUnsubscribe",
		});
	};
};

export const insertTournaments = (msg) => {
	return (dispatch) => {
		if (typeof msg === "object" && msg.tournaments) {
			dispatch({
				type: TOURNAMENT_ACTIONS.INSERT_TOURNAMENT,
				payload: { tournaments: msg.tournaments },
			});
		}
	};
};

export const removeTournaments = (msg) => {
	return (dispatch) => {
		if (typeof msg === "object" && msg.tournaments) {
			dispatch({
				type: TOURNAMENT_ACTIONS.REMOVE_TOURNAMENT,
				payload: { tournaments: msg.tournaments },
			});
		}
	};
};

export const selectTournament = (tournamentID) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SELECT_TOURNAMENT,
			payload: { tournamentID },
		});
	};
};

export const deselectTournament = () => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.DESELECT_TOURNAMENT,
		});
	};
};

export const updateTournamentDetails = (msg) => {
	return () => {
		let message = {
			sMessageID: 0,
			type: "getTournamentDetails",
			tournamentID: msg.tournamentID,
		};
		MyWebSocket.shared.sendMsg(message);
	};
};

export const saveTournamentDetails = (tournamentDetails) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SAVE_TOURNAMENT_DETAILS,
			payload: tournamentDetails,
		});
	};
};

export const registerForTournament = (tournamentID) => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "registerTournament",
			tournamentID: tournamentID,
			teamID: 0,
		});
	};
};

export const unRegisterFromTournament = (tournamentID) => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "unregisterTournament",
			tournamentID: tournamentID,
			teamID: 0,
		});
	};
};

export const subscribeToTournament = (tournamentID) => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "tournamentSubscribe",
			tournamentID: tournamentID,
		});
	};
};

export const unSubscribeFromTournament = (tournamentID) => {
	return (dispatch) => {
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "tournamentUnsubscribe",
			tournamentID: tournamentID,
		});
	};
};

export const openTournamentDetails = () => {
	return (dispatch, getState) => {
		var currentUserID = getState().currentUser.userDetails.userID;
		dispatch({
			type: TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_DETAILS,
			payload: { currentUserID },
		});
	};
};

export const closeTournamentDetails = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_DETAILS });
	};
};

export const selectTeam = (teamID) => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.SELECT_TEAM, payload: { teamID } });
	};
};

export const deselectTeam = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.DESELECT_TEAM });
	};
};

export const openCreateTeam = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.OPEN_CREATE_TEAM });
	};
};

export const closeCreateTeam = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.CLOSE_CREATE_TEAM });
	};
};

export const saveNewTeam = () => {
	return (dispatch, getState) => {
		const { userDetails } = getState().currentUser;
		const _userDetails = {
			userID: userDetails.userID,
			username: userDetails.username,
			country: userDetails.country,
			ranking: userDetails.ranking,
		};
		dispatch({
			type: TOURNAMENT_ACTIONS.SAVE_NEW_TEAM,
			payload: { userDetails: _userDetails },
		});
		dispatch(closeCreateTeam());
	};
};

export const selectNewTeamType = (newType) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SELECT_NEW_TEAM_TYPE,
			payload: { newType },
		});
	};
};

export const saveNewTeamName = (newName) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SAVE_NEW_TEAM_NAME,
			payload: { newName },
		});
	};
};

export const saveNewTeamPinCode = (newPinCode) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SAVE_NEW_TEAM_PIN_CODE,
			payload: { newPinCode },
		});
	};
};

export const saveNewTeamMinLevel = (newMinLevel) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SAVE_NEW_TEAM_MIN_LEVEL,
			payload: { newMinLevel },
		});
	};
};

export const saveNewTeamMaxLevel = (newMaxLevel) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SAVE_NEW_TEAM_MAX_LEVEL,
			payload: { newMaxLevel },
		});
	};
};

export const openTournamentStat = () => {
	return (dispatch, getState) => {
		var found = getState().tournaments.tournamentsList.find(
			(tour) => tour.tournamentID === getState().tournaments.selectedTournamentID
		);
		if (found && typeof found.isLadder === "boolean") {
			if (found.isLadder) {
				if (!found.isPoolLadder) {
					dispatch(deselectTeam());
					dispatch(openTournamentLadderStat());
				}
			} else {
				dispatch(deselectTeam());
				dispatch(openTournamentScoreStat());
			}
		}
	};
};

export const openTournamentScoreStat = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_SCORE_STAT });
	};
};

export const closeTournamentScoreStat = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_SCORE_STAT });
	};
};

export const openTournamentPayout = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_PAYOUT });
	};
};

export const closeTournamentPayout = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_PAYOUT });
	};
};

export const openTournamentLadderStat = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.OPEN_TOURNAMENT_LADDER_STAT });
		dispatch(updateTournamentLadderStat());
	};
};

export const closeTournamentLadderStat = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.CLOSE_TOURNAMENT_LADDER_STAT });
	};
};

export const updateTournamentLadderStat = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.UPDATE_TOURNAMENT_LADDER_STAT });
	};
};

export const openPoolOverview = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.OPEN_POOL_OVERVIEW });
	};
};

export const closePoolOverview = () => {
	return (dispatch) => {
		dispatch({ type: TOURNAMENT_ACTIONS.CLOSE_POOL_OVERVIEW });
	};
};

export const saveNewTournamentName = (newName) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SAVE_NEW_TOURNAMENT_NAME,
			payload: { newName },
		});
	};
};

export const selectNewTournamentType = (newType) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SELECT_NEW_TOURNAMENT_TYPE,
			payload: { newType },
		});
	};
};

export const selectNewTournamentTeamcount = (teamCount) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SELECT_NEW_TOURNAMENT_TEAMCOUNT,
			payload: { teamCount },
		});
	};
};

export const selectNewTournamentBuyIn = (buyIn) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SELECT_NEW_TOURNAMENT_BUYIN,
			payload: { buyIn },
		});
	};
};

export const selectNewTournamentStartsAt = (startsAt) => {
	return (dispatch) => {
		dispatch({
			type: TOURNAMENT_ACTIONS.SELECT_NEW_TOURNAMENT_STARTS_AT,
			payload: { startsAt },
		});
	};
};
