import React, { Component } from "react";
import { Image, ImageBackground, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { modalType, RESOLUTION, SHOP_CATEGORY, tabType } from "../../config/defaults";
import {
	closeEmailFriend,
	closeEmailFriendPreview,
	closeGoldHelp,
	closeInviteFriendsMenu,
	closeQuestionnaire,
	closeRateForSilver,
	closeShop,
	closeSilverHelp,
	closeUpdatePersonalData,
	goBackToMain,
	openCardsetsShop,
	openChipsShop,
	openEarnFreeSilver,
	openGameItemsMenu,
	openGamesTab,
	openGoldHelp,
	openGoldShop,
	openMiscShop,
	openModalDialog,
	openMultipliersShop,
	openSilverHelp,
	openSilverShop,
	openTab,
	openVIPShop,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { AppTouchableOpacity, NavigationHeader } from "../common";
import { handleError } from "../ErrorHandler";
import Questionnaire from "../Questionnaire/Questionnaire";
import CardsetsShop from "./CardsetsShop";
import ChipsShop from "./ChipsShop";
import EarnFreeSilver from "./EarnFreeSilver/EarnFreeSilver";
import GameShopStack from "./GameShopStack";
import GoldShop from "./GoldShop";
import MiscShop from "./MiscShop";
import MultipliersShop from "./MultipliersShop";
import SilverShop from "./SilverShop";
import VIPShop from "./VIPShop";

class GameShopTablet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navImageDim: null,
			isSurveyCompleted: false,
			itemDetailsOpened: false,
			multiplierActive: false,
			cardsetsActive: false,
			miscActive: false,
			earnFreeActive: false,
			silverActive: false,
			goldActive: false,
			vipActive: false,
			chipsActive: false,
		};
		this.selectedCat;
		this.selectedCatType;
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logEvent("openShop");
		await Analytics.logScreenView("GameShop");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.visibleSurvey !== this.props.visibleSurvey) {
			this.setState({ isSurveyCompleted: false });
			this.props.openVIPShop();
		}

		if (
			(prevProps.categories.length == 0 && this.props.categories.length > 0) ||
			prevProps.categories !== this.props.categories
		) {
			const multiplierActive = this.props.multipliersShopOpened;
			const cardsetsActive = this.props.cardsetsShopOpened;
			const miscActive = this.props.miscShopOpened;
			const earnFreeActive = this.props.earnFreeSilverOpened;
			const silverActive = this.props.silverShopOpened;
			const goldActive = this.props.goldShopOpened;
			const chipsActive = this.props.chipsShopOpened;

			if (goldActive) {
				const _item = this.props.categories.find((cat) => cat.type == SHOP_CATEGORY.GOLD);
				if (_item) this.openShopCategory(SHOP_CATEGORY.GOLD, _item);
			} else if (silverActive) {
				const _item = this.props.categories.find((cat) => cat.type == SHOP_CATEGORY.SILVER);
				if (_item) this.openShopCategory(SHOP_CATEGORY.SILVER, _item);
			} else if (chipsActive) {
				const _item = this.props.categories.find((cat) => cat.type == SHOP_CATEGORY.CHIPS);
				if (_item) this.openShopCategory(SHOP_CATEGORY.CHIPS, _item);
			} else if (multiplierActive) {
				const _item = this.props.categories.find((cat) => cat.type == SHOP_CATEGORY.MULTIPLIERS);
				if (_item) this.openShopCategory(SHOP_CATEGORY.MULTIPLIERS, _item);
			} else if (miscActive) {
				const _cat = this.props.categories.find((cat) => cat.type == SHOP_CATEGORY.MISC);
				switch (this.props.miscShopType) {
					case SHOP_CATEGORY.BAN_LIST:
						if (_cat && Array.isArray(_cat.subCategories)) {
							const _item = _cat.subCategories.find((cat) => cat.type == SHOP_CATEGORY.BAN_LIST);
							if (_item) {
								this.openShopCategory(SHOP_CATEGORY.BAN_LIST, _item);
							} else {
								if (_cat) this.openShopCategory(SHOP_CATEGORY.MISC, _cat);
							}
						} else {
							if (_cat) this.openShopCategory(SHOP_CATEGORY.MISC, _cat);
						}
						break;
					case SHOP_CATEGORY.FRIEND_LIST:
						if (_cat && Array.isArray(_cat.subCategories)) {
							const _item = _cat.subCategories.find((cat) => cat.type == SHOP_CATEGORY.FRIEND_LIST);
							if (_item) {
								this.openShopCategory(SHOP_CATEGORY.FRIEND_LIST, _item);
							} else {
								if (_cat) this.openShopCategory(SHOP_CATEGORY.MISC, _cat);
							}
						} else {
							if (_cat) this.openShopCategory(SHOP_CATEGORY.MISC, _cat);
						}
						break;
					default:
						if (_cat) this.openShopCategory(SHOP_CATEGORY.MISC, _cat);
						break;
				}
			} else if (cardsetsActive) {
				const _cat = this.props.categories.find((cat) => cat.type == SHOP_CATEGORY.MISC);
				if (_cat && Array.isArray(_cat.subCategories)) {
					const _item = _cat.subCategories.find((cat) => cat.type == SHOP_CATEGORY.CARDPACK);
					if (_item) this.openShopCategory(SHOP_CATEGORY.CARDPACK, _item);
				}
			} else if (earnFreeActive) {
				const _cat = this.props.categories.find((cat) => cat.type == SHOP_CATEGORY.MISC);
				if (_cat && Array.isArray(_cat.subCategories)) {
					const _item = _cat.subCategories.find((cat) => cat.type == SHOP_CATEGORY.EARN_FREE);
					if (_item) this.openShopCategory(SHOP_CATEGORY.EARN_FREE, _item);
				}
			} else {
				const _item = this.props.categories.find((cat) => cat.type == SHOP_CATEGORY.VIP);
				if (_item) {
					if (this.props.vipShopOpened) {
						this.openShopCategory(SHOP_CATEGORY.VIP, _item);
					} else {
						setTimeout(() => {
							this.openShopCategory(SHOP_CATEGORY.VIP, _item);
						}, 500);
					}
				}
			}
		}
	}
	//#endregion

	//#region events
	onNavButtonsLayout(event) {
		try {
			const { height } = event.nativeEvent.layout;
			var navImageDim = Math.round(height /* - 27 */ / 9);
			navImageDim = navImageDim > 80 ? 80 : navImageDim;
			this.setState({ navImageDim: navImageDim });
		} catch (error) {
			handleError(error);
		}
	}

	closeSurvey(close) {
		try {
			if (!this.state.isSurveyCompleted) {
				this.props.openModalDialog(
					modalType.DIALOG,
					this.props.lang.messages.abortSurveyWarningMessage,
					this.props.lang.yes,
					() => {
						this.props.closeQuestionnaire();
						if (close) this.props.goBackToMain();
					},
					this.props.lang.no,
					null
				);
			} else {
				this.props.closeQuestionnaire();
				if (close) this.props.goBackToMain();
			}
		} catch (error) {
			handleError(error);
		}
	}

	closeSliderRate(close) {
		try {
			if (!this.props.rateDoneOpened) {
				this.props.openModalDialog(
					modalType.DIALOG,
					this.props.lang.messages.abortRatingMessage,
					this.props.lang.yes,
					() => {
						this.props.closeRateForSilver();
						if (close) this.props.goBackToMain();
					},
					this.props.lang.no,
					null
				);
			} else {
				this.props.closeRateForSilver();
				if (close) this.props.goBackToMain();
			}
		} catch (error) {
			handleError(error);
		}
	}

	openShopCategory(catType, cat) {
		try {
			var silverActive = false,
				goldActive = false,
				cardsetsActive = false,
				multiplierActive = false,
				miscActive = false,
				earnFreeActive = false,
				vipActive = false,
				chipsActive = false;
			this.selectedCat = cat;
			this.selectedCatType = catType;
			if (this.props.inviteFriendsMenuOpened) {
				if (this.props.emailFriendOpened) {
					if (this.props.emailFriendPreviewOpened) {
						this.props.closeEmailFriendPreview();
					} else {
						this.props.closeEmailFriend();
					}
				} else {
					this.props.closeInviteFriendsMenu();
				}
			} else if (this.props.visibleSurvey) {
				this.closeSurvey(false);
			} else if (this.props.rateForSilverOpened) {
				this.closeSliderRate(false);
			} else if (this.props.silverHelpOpened) {
				this.props.closeSilverHelp();
			} else if (this.props.goldHelpOpened) {
				this.props.closeGoldHelp();
			} else if (this.props.updatePersonalDataOpened) {
				this.props.closeUpdatePersonalData();
			} else if (this.state.itemDetailsOpened) {
				this.setState({ itemDetailsOpened: false });
			}
			switch (catType) {
				case SHOP_CATEGORY.CARDPACK:
					cardsetsActive = true;
					this.props.openCardsetsShop();
					break;
				case SHOP_CATEGORY.BAN_LIST:
					miscActive = true;
					this.props.openMiscShop();
					break;
				case SHOP_CATEGORY.FRIEND_LIST:
					miscActive = true;
					this.props.openMiscShop();
					break;
				case SHOP_CATEGORY.CHIPS:
					chipsActive = true;
					this.props.openChipsShop();
					break;
				case SHOP_CATEGORY.EARN_FREE:
					earnFreeActive = true;
					this.props.openEarnFreeSilver();
					break;
				case SHOP_CATEGORY.GOLD:
					goldActive = true;
					this.props.openGoldShop();
					break;
				case SHOP_CATEGORY.MISC:
					miscActive = true;
					this.props.openMiscShop();
					break;
				case SHOP_CATEGORY.MULTIPLIERS:
					multiplierActive = true;
					this.props.openMultipliersShop();
					break;
				case SHOP_CATEGORY.SILVER:
					silverActive = true;
					this.props.openSilverShop();
					break;
				case SHOP_CATEGORY.VIP:
					vipActive = true;
					this.props.openVIPShop();
					break;
			}
			this.setState({
				multiplierActive,
				miscActive,
				cardsetsActive,
				chipsActive,
				goldActive,
				silverActive,
				vipActive,
				earnFreeActive,
			});
		} catch (error) {
			handleError(error);
		}
	}

	onBackPress() {
		try {
			if (this.props.inviteFriendsMenuOpened) {
				if (this.props.emailFriendOpened) {
					if (this.props.emailFriendPreviewOpened) {
						this.props.closeEmailFriendPreview();
					} else {
						this.props.closeEmailFriend();
					}
				} else {
					this.props.closeInviteFriendsMenu();
				}
			} else if (this.props.visibleSurvey) {
				this.closeSurvey(false);
			} else if (this.props.rateForSilverOpened) {
				this.closeSliderRate(false);
			} else if (this.props.silverHelpOpened) {
				this.props.closeSilverHelp();
			} else if (this.props.goldHelpOpened) {
				this.props.closeGoldHelp();
			} else if (this.props.updatePersonalDataOpened) {
				this.props.closeUpdatePersonalData();
			} else if (this.state.itemDetailsOpened) {
				this.setState({ itemDetailsOpened: false });
			} else {
				this.props.closeShop();
				if (this.props.isTabGamesButtonActive) this.props.openGamesTab();
				if (this.props.isTabChatButtonActive) this.props.openTab(tabType.CHAT);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onClosePress() {
		try {
			if (this.props.visibleSurvey) {
				this.closeSurvey(true);
			} else if (this.props.rateForSilverOpened) {
				this.closeSliderRate(true);
			} else {
				this.props.goBackToMain();
			}
		} catch (error) {
			handleError(error);
		}
	}

	handleStackPress(catType) {
		try {
			const _item = this.props.categories.find((cat) => cat.type == catType);
			if (_item) this.openShopCategory(catType, _item);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		const isBackNegative =
			(this.props.visibleSurvey && !this.state.isSurveyCompleted) ||
			(this.props.rateForSilverOpened && !this.props.rateDoneOpened)
				? true
				: false;
		return (
			<NavigationHeader
				backAction={this.onBackPress.bind(this)}
				closeAction={this.onClosePress.bind(this)}
				isBackNegative={isBackNegative}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			>
				<GameShopStack
					inShopGoldPress={() => this.handleStackPress(SHOP_CATEGORY.GOLD)}
					inShopSilverPress={() => this.handleStackPress(SHOP_CATEGORY.SILVER)}
					inShopChipPress={() => this.handleStackPress(SHOP_CATEGORY.CHIPS)}
				/>
			</NavigationHeader>
		);
	}

	renderNavButton(item, isCat) {
		if (!item || !item.isSelectable) return null;
		return (
			<AppTouchableOpacity
				key={isCat ? "cat_" + item.categoryID : "subCat_" + item.subCategoryID}
				onPress={() => this.openShopCategory(item.type, item)}
				style={[styles.navButton]}
				disabled={!item.isSelectable}
				accessibilityState={{ disabled: !item.isSelectable }}
				accessibilityLabel={"open " + item.title + " shop"}
			>
				<Image
					source={{ uri: item.iconImgSrc }}
					style={[styles.navButtonImage, { height: this.state.navImageDim }]}
					resizeMode="contain"
				/>
			</AppTouchableOpacity>
		);
	}

	renderNavigationButtons() {
		var buttons = [];
		this.props.categories.forEach((cat) => {
			if (cat.useSubCategories == true && cat.isSelectable == false) {
				cat.subCategories.forEach((sc) => {
					if (sc.type !== SHOP_CATEGORY.EARN_FREE) buttons.push(this.renderNavButton(sc, false));
				});
			} else {
				buttons.push(this.renderNavButton(cat, true));
			}
		});

		return (
			<View style={styles.navButtonsContainer} onLayout={(event) => this.onNavButtonsLayout(event)}>
				{buttons}
			</View>
		);
	}

	renderNavigationMenu() {
		return <View style={styles.navigationContainer}>{this.renderNavigationButtons()}</View>;
	}

	renderContent() {
		if (this.props.visibleSurvey) {
			return (
				<Questionnaire
					completeSurvey={() => this.setState({ isSurveyCompleted: true })}
					unCompleteSurvey={() => this.setState({ isSurveyCompleted: false })}
				/>
			);
		} else if (this.state.multiplierActive) {
			return (
				<MultipliersShop
					mainCat={this.selectedCat}
					itemDetailsOpened={this.state.itemDetailsOpened}
					toggleItemDetails={(showItemDetails) => this.setState({ itemDetailsOpened: showItemDetails })}
				/>
			);
		} else if (this.state.cardsetsActive) {
			return (
				<CardsetsShop
					mainCat={this.selectedCat}
					itemDetailsOpened={this.state.itemDetailsOpened}
					toggleItemDetails={(showItemDetails) => this.setState({ itemDetailsOpened: showItemDetails })}
				/>
			);
		} else if (this.state.miscActive) {
			return (
				<MiscShop
					mainCat={this.selectedCat}
					selectedSubCat={this.selectedCatType}
					itemDetailsOpened={this.state.itemDetailsOpened}
					toggleItemDetails={(showItemDetails) => this.setState({ itemDetailsOpened: showItemDetails })}
				/>
			);
		} else if (this.state.earnFreeActive) {
			return <EarnFreeSilver />;
		} /* else if (this.state.silverActive) {
			return (
				<SilverShop
					mainCat={this.selectedCat}
					itemDetailsOpened={this.state.itemDetailsOpened}
					toggleItemDetails={(showItemDetails) =>
						this.setState({ itemDetailsOpened: showItemDetails })
					}
				/>
			);
		} */ else if (this.state.goldActive) {
			return (
				<GoldShop
					mainCat={this.selectedCat}
					itemDetailsOpened={this.state.itemDetailsOpened}
					toggleItemDetails={(showItemDetails) => this.setState({ itemDetailsOpened: showItemDetails })}
				/>
			);
		} else if (this.state.vipActive) {
			return (
				<VIPShop
					mainCat={this.selectedCat}
					itemDetailsOpened={this.state.itemDetailsOpened}
					toggleItemDetails={(showItemDetails) => this.setState({ itemDetailsOpened: showItemDetails })}
				/>
			);
		} else if (this.state.chipsActive) {
			return (
				<ChipsShop
					mainCat={this.selectedCat}
					itemDetailsOpened={this.state.itemDetailsOpened}
					toggleItemDetails={(showItemDetails) => this.setState({ itemDetailsOpened: showItemDetails })}
				/>
			);
		} else {
			return null;
		}
	}

	render() {
		var backImgSrc = this.props.SHOP_BACK.url;
		if (this.props.silverHelpOpened || this.props.goldHelpOpened) backImgSrc = this.props.HELP_BACK.url;
		if (this.props.visibleSurvey) backImgSrc = this.props.SURVEY_BACK.url;
		if (this.props.updatePersonalDataOpened) backImgSrc = this.props.SETTINGS_BACK.url;
		return (
			<ImageBackground
				source={backImgSrc}
				style={
					this.props.isGameStarted
						? {
								width: "100%",
								height: "100%",
								position: "absolute",
								zIndex: 8888,
						  }
						: { flex: 1 }
				}
			>
				<View style={styles.container}>
					<View
						style={[
							styles.detailsContainer,
							{
								marginTop: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
							},
						]}
					>
						{this.renderNavigationMenu()}
						<View style={styles.detailsInnerContainer}>{this.renderContent()}</View>
					</View>
				</View>
				{!this.props.isPlayerOverviewOpened && this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { flex: 1 },
	detailsContainer: {
		flex: 1,
		flexDirection: "row",
		paddingHorizontal: 3,
		marginTop: 50,
		marginBottom: 3,
	},
	detailsInnerContainer: { flex: 1, flexDirection: "row" },
	navigationContainer: { /* width: 100, */ marginTop: 15 },
	navButtonsContainer: {
		flex: 1,
		height: "100%",
		// width: 100,
	},
	navButton: {
		alignSelf: "center",
		marginBottom: 3,
	},
	navButtonImage: {
		width: 80,
		height: 80,
		maxHeight: 80,
		maxWidth: 80,
		minHeight: 40,
		minWidth: 40,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		categories: state.gameShop.categories,
		multipliersShopOpened: state.gameShop.multipliersShopOpened,
		cardsetsShopOpened: state.gameShop.cardsetsShopOpened,
		miscShopOpened: state.gameShop.miscShopOpened,
		miscShopType: state.gameShop.miscShopType,
		silverShopOpened: state.gameShop.silverShopOpened,
		goldShopOpened: state.gameShop.goldShopOpened,
		vipShopOpened: state.gameShop.vipShopOpened,
		chipsShopOpened: state.gameShop.chipsShopOpened,
		earnFreeSilverOpened: state.app.earnFreeSilverOpened,
		visibleSurvey: state.questionnaire.visibleSurvey,
		silverHelpOpened: state.app.silverHelpOpened,
		goldHelpOpened: state.app.goldHelpOpened,
		updatePersonalDataOpened: state.app.updatePersonalDataOpened,
		inviteFriendsMenuOpened: state.app.inviteFriendsMenuOpened,
		emailFriendOpened: state.app.emailFriendOpened,
		emailFriendPreviewOpened: state.app.emailFriendPreviewOpened,
		rateForSilverOpened: state.app.rateForSilverOpened,
		rateDoneOpened: state.app.rateDoneOpened,
		resolution: state.currentUser.preferences.resolution,
		isTabChatButtonActive: state.tab.isTabChatButtonActive,
		isTabGamesButtonActive: state.tab.isTabGamesButtonActive,
		isPlayerOverviewOpened: state.app.isPlayerOverviewOpened,
		isGameStarted: state.tab.isGameStarted,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
		SHOP_BACK: state.images.SHOP_BACK,
		HELP_BACK: state.images.HELP_BACK,
		SURVEY_BACK: state.images.SURVEY_BACK,
	};
};

const mapDispatchToProps = {
	closeEmailFriend,
	closeEmailFriendPreview,
	closeGoldHelp,
	closeInviteFriendsMenu,
	closeQuestionnaire,
	closeRateForSilver,
	closeShop,
	closeSilverHelp,
	closeUpdatePersonalData,
	goBackToMain,
	openCardsetsShop,
	openChipsShop,
	openEarnFreeSilver,
	openGameItemsMenu,
	openGamesTab,
	openGoldHelp,
	openGoldShop,
	openMiscShop,
	openModalDialog,
	openMultipliersShop,
	openSilverHelp,
	openSilverShop,
	openTab,
	openVIPShop,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameShopTablet);
