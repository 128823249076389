import React from "react";
import { gameBoardTheme } from "../../config/defaults";
import { ClassicPlayerPanel } from "./ClassicPlayerPanel";
import { ModernPlayerPanel } from "./ModernPlayerPanel";

const PlayerPanel = (props) => {
	switch (props.gameTheme) {
		case gameBoardTheme.MODERN:
			return <ModernPlayerPanel {...props} />;
		default:
			return <ClassicPlayerPanel {...props} />;
	}
};

export { PlayerPanel };
