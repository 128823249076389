import { cloneDeep, orderBy, sortBy } from "lodash";
import { Image, Platform } from "react-native";
import { DEFAULT_CURRENCY, isWebOrTablet, SHOP_CATEGORY } from "../../config/defaults";
import {
	ADD_CREDIT_SUCCEEDED,
	BUY_ITEM,
	BUY_ITEM_FAILED,
	BUY_ITEM_SUCCEEDED,
	CLEAR_STARTED_PURCHASE,
	CLOSE_CARDSETS_SHOP,
	CLOSE_CHIPS_SHOP,
	CLOSE_GAME_ITEMS_MENU,
	CLOSE_GOLD_SHOP,
	CLOSE_MISC_SHOP,
	CLOSE_MULTIPLIERS_SHOP,
	CLOSE_SHOP_TAB,
	// CLOSE_SILVER_SHOP,
	CLOSE_VIP_SHOP,
	GO_BACK_TO_MAIN,
	OPEN_CARDSETS_SHOP,
	OPEN_CHIPS_SHOP,
	OPEN_EARN_FREE_SILVER,
	OPEN_GOLD_SHOP,
	OPEN_MISC_SHOP,
	OPEN_MULTIPLIERS_SHOP,
	OPEN_SHOP_TAB,
	// OPEN_SILVER_SHOP,
	OPEN_VIP_SHOP,
	PURCHASE_NOT_VALIDATED,
	PURCHASE_STARTED,
	PURCHASE_VALIDATED,
	RECEIVE_SHOP_INVENTORY,
	RESET_CLIENT,
	SAVE_AVAILABLE_PURCHASE,
	START_VALIDATE_PURCHASE,
	UPDATE_GOLD_ITEMS_PRICES,
} from "../actions/actionTypes";
import { DEBUG_ENABLED } from "../../config/permissions";

const initialState = {
	categories: [],
	silverShopOpened: false,
	silverShopInventory: [],
	goldShopOpened: false,
	goldShopInventory: [],
	vipShopOpened: false,
	vipShopInventory: [],
	chipsShopOpened: false,
	chipsInventory: [],
	multipliersShopOpened: false,
	multipliersInventory: [],
	cardsetsShopOpened: false,
	cardsetsInventory: [],
	miscShopOpened: false,
	miscShopType: SHOP_CATEGORY.MISC,
	miscInventory: [],
	banInventory: [],
	friendInventory: [],
	availablePurchases: [],
	itemInPurchase: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case PURCHASE_STARTED:
			if (
				typeof payload === "undefined" ||
				typeof payload.details === "undefined" ||
				(typeof payload.details.androidProductID === "undefined" && typeof payload.details.iosProductID === "undefined")
			)
				return state;
			return { ...state, itemInPurchase: payload };
		case CLEAR_STARTED_PURCHASE:
			if (state.itemInPurchase == null) return state;
			return { ...state, itemInPurchase: null };
		case SAVE_AVAILABLE_PURCHASE:
			if (!payload || typeof payload.purchaseToken === "undefined") return state;
			var found = state.availablePurchases.find((p) => p.purchaseToken == payload.purchaseToken);
			if (found) return state;
			var newAvPurch = cloneDeep(state.availablePurchases);
			newAvPurch.push(payload);
			//eslint-disable-next-line
			if (__DEV__) console.log("SAVED PURCHASES IN STORE: ", newAvPurch);
			return { ...state, availablePurchases: newAvPurch };
		case START_VALIDATE_PURCHASE:
			if (!payload || typeof payload.purchaseToken === "undefined") return state;
			var foundIndex = state.availablePurchases.findIndex((p) => p.purchaseToken == payload.purchaseToken);
			if (foundIndex == -1) return state;
			var newAvPurch = cloneDeep(state.availablePurchases);
			newAvPurch[foundIndex].validationStarted = true;
			return { ...state, availablePurchases: newAvPurch };
		case PURCHASE_VALIDATED:
			if (!payload || typeof payload.purchaseToken === "undefined") return state;
			var foundIndex = state.availablePurchases.findIndex((p) => p.purchaseToken == payload.purchaseToken);
			if (foundIndex == -1) return state;
			var newAvPurch = cloneDeep(state.availablePurchases);
			newAvPurch[foundIndex].validationStarted = false;
			newAvPurch[foundIndex].validated = true;
			return { ...state, availablePurchases: newAvPurch };
		case PURCHASE_NOT_VALIDATED:
			if (!payload || typeof payload.purchaseToken === "undefined") return state;
			var foundIndex = state.availablePurchases.findIndex((p) => p.purchaseToken == payload.purchaseToken);
			if (foundIndex == -1) return state;
			var newAvPurch = cloneDeep(state.availablePurchases);
			newAvPurch[foundIndex].validationStarted = false;
			newAvPurch[foundIndex].validated = false;
			return { ...state, availablePurchases: newAvPurch };
		case GO_BACK_TO_MAIN:
		case CLOSE_SHOP_TAB:
			return {
				...state,
				categories: [],
				silverShopOpened: false,
				goldShopOpened: false,
				vipShopOpened: false,
				chipsShopOpened: false,
				multipliersShopOpened: false,
				cardsetsShopOpened: false,
				miscShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			};
		case OPEN_SHOP_TAB:
		case OPEN_EARN_FREE_SILVER:
			return {
				...state,
				silverShopOpened: false,
				goldShopOpened: false,
				vipShopOpened: false,
				chipsShopOpened: false,
				multipliersShopOpened: false,
				cardsetsShopOpened: false,
				miscShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			};
		/* case OPEN_SILVER_SHOP:
			return {
				...state,
				silverShopOpened: true,
				goldShopOpened: false,
				vipShopOpened: false,
				chipsShopOpened: false,
				multipliersShopOpened: false,
				cardsetsShopOpened: false,
				miscShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			}; 
		case CLOSE_SILVER_SHOP:
			return { ...state, silverShopOpened: false }; */
		case OPEN_GOLD_SHOP:
			return {
				...state,
				goldShopOpened: true,
				silverShopOpened: false,
				vipShopOpened: false,
				chipsShopOpened: false,
				multipliersShopOpened: false,
				cardsetsShopOpened: false,
				miscShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			};
		case CLOSE_GOLD_SHOP:
			return { ...state, goldShopOpened: false };
		case UPDATE_GOLD_ITEMS_PRICES:
			// if (DEBUG_ENABLED) console.log("UPDATE_GOLD_ITEMS_PRICES called redux");
			if (!payload || !Array.isArray(payload.data)) return state;
			//update prices, canBeBought properties
			var newGSInventory = state.goldShopInventory;
			newGSInventory = cloneDeep(state.goldShopInventory);
			payload.data.forEach((prod) => {
				var searchedInd = -1;
				switch (Platform.OS) {
					case "android":
						searchedInd = state.goldShopInventory.findIndex(
							(it) => it.details && it.details.androidProductID && it.details.androidProductID == prod.productId
						);
						break;
					case "ios":
						searchedInd = state.goldShopInventory.findIndex(
							(it) => it.details && it.details.iosProductID && it.details.iosProductID == prod.productId
						);
						break;
				}
				// if (DEBUG_ENABLED) console.log(`searchedInd ${searchedInd}`);
				if (searchedInd != -1) {
					var searched = state.goldShopInventory[searchedInd];
					// if (DEBUG_ENABLED) console.log(`prod ${JSON.stringify(prod)}`);
					searched.productType = prod.productType;
					searched.currency = prod.oneTimePurchaseOfferDetails.priceCurrencyCode;
					searched.realPrice = prod.oneTimePurchaseOfferDetails.formattedPrice;
					searched.canBeBought = true;
					searched.isAvailable = true;
					newGSInventory.splice(searchedInd, 1, searched);
				}
			});
			return { ...state, goldShopInventory: newGSInventory };
		case OPEN_VIP_SHOP:
			return {
				...state,
				vipShopOpened: true,
				silverShopOpened: false,
				goldShopOpened: false,
				chipsShopOpened: false,
				multipliersShopOpened: false,
				cardsetsShopOpened: false,
				miscShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			};
		case CLOSE_VIP_SHOP:
			return { ...state, vipShopOpened: false };
		case OPEN_CHIPS_SHOP:
			return {
				...state,
				chipsShopOpened: true,
				silverShopOpened: false,
				goldShopOpened: false,
				vipShopOpened: false,
				multipliersShopOpened: false,
				cardsetsShopOpened: false,
				miscShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			};
		case CLOSE_CHIPS_SHOP:
			return { ...state, chipsShopOpened: false };
		case OPEN_MULTIPLIERS_SHOP:
			return {
				...state,
				multipliersShopOpened: true,
				silverShopOpened: false,
				goldShopOpened: false,
				vipShopOpened: false,
				chipsShopOpened: false,
				cardsetsShopOpened: false,
				miscShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			};
		case CLOSE_MULTIPLIERS_SHOP:
			return { ...state, multipliersShopOpened: false };
		case OPEN_CARDSETS_SHOP:
			return {
				...state,
				cardsetsShopOpened: true,
				silverShopOpened: false,
				goldShopOpened: false,
				vipShopOpened: false,
				chipsShopOpened: false,
				multipliersShopOpened: false,
				miscShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			};
		case CLOSE_CARDSETS_SHOP:
			return { ...state, cardsetsShopOpened: false };
		case OPEN_MISC_SHOP:
			var _miscType = SHOP_CATEGORY.MISC;
			switch (payload) {
				case SHOP_CATEGORY.BAN_LIST:
				case SHOP_CATEGORY.FRIEND_LIST:
					_miscType = payload;
					break;
			}
			return {
				...state,
				miscShopOpened: true,
				miscShopType: _miscType,
				silverShopOpened: false,
				goldShopOpened: false,
				vipShopOpened: false,
				chipsShopOpened: false,
				multipliersShopOpened: false,
				cardsetsShopOpened: false,
			};
		case CLOSE_MISC_SHOP:
			return {
				...state,
				miscShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			};
		case CLOSE_GAME_ITEMS_MENU:
			return {
				...state,
				cardsetsShopOpened: false,
				miscShopOpened: false,
				multipliersShopOpened: false,
				miscShopType: SHOP_CATEGORY.MISC,
			};
		case BUY_ITEM:
			if (payload.item == undefined) return state;

			var parent = state.categories.find((cat) => cat.categoryID == payload.item.categoryID);
			var parentType = parent && parent != null ? parent.type : "";
			var _vipShopInventory = state.vipShopInventory,
				_goldShopInventory = state.goldShopInventory,
				_silverShopInventory = state.silverShopInventory,
				_chipsInventory = state.chipsInventory,
				_multipliersInventory = state.multipliersInventory,
				_cardsetsInventory = state.cardsetsInventory,
				_miscInventory = state.miscInventory,
				_banInventory = state.banInventory,
				_friendInventory = state.friendInventory;
			switch (parentType) {
				case SHOP_CATEGORY.VIP:
					var searched = _vipShopInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_vipShopInventory = [...state.vipShopInventory];
						const _item = _vipShopInventory[searched];
						_item.canBeBought = false;
					}
					break;
				case SHOP_CATEGORY.MISC:
					const subCat = Array.isArray(parent.subCategories)
						? parent.subCategories.find((sc) => sc.subCategoryID == payload.item.subCategoryID)
						: undefined;
					if (!parent.useSubCategories || subCat == undefined || subCat.type == undefined) {
						var searched = _miscInventory.findIndex((it) => it.itemID == payload.item.itemID);
						if (searched != -1) {
							_miscInventory = [...state.miscInventory];
							const _item = _miscInventory[searched];
							_item.canBeBought = false;
						}
					} else {
						switch (subCat.type) {
							case SHOP_CATEGORY.CARDPACK:
								var searched = _cardsetsInventory.findIndex((it) => it.itemID == payload.item.itemID);
								if (searched != -1) {
									_cardsetsInventory = [...state.cardsetsInventory];
									const _item = _cardsetsInventory[searched];
									_item.canBeBought = false;
								}
								break;
							case SHOP_CATEGORY.BAN_LIST:
								var searched = _banInventory.findIndex((it) => it.itemID == payload.item.itemID);
								if (searched != -1) {
									_banInventory = [...state.banInventory];
									const _item = _banInventory[searched];
									_item.canBeBought = false;
								}
								break;
							case SHOP_CATEGORY.FRIEND_LIST:
								var searched = _friendInventory.findIndex((it) => it.itemID == payload.item.itemID);
								if (searched != -1) {
									_friendInventory = [...state.friendInventory];
									const _item = _friendInventory[searched];
									_item.canBeBought = false;
								}
								break;
						}
					}
					break;
				case SHOP_CATEGORY.MULTIPLIERS:
					var searched = _multipliersInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_multipliersInventory = [...state.multipliersInventory];
						const _item = _multipliersInventory[searched];
						_item.canBeBought = false;
					}
					break;
				case SHOP_CATEGORY.CHIPS:
					var searched = _chipsInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_chipsInventory = [...state.chipsInventory];
						const _item = _chipsInventory[searched];
						_item.canBeBought = false;
					}
					break;
				case SHOP_CATEGORY.SILVER:
					var searched = _silverShopInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_silverShopInventory = [...state.silverShopInventory];
						const _item = _silverShopInventory[searched];
						_item.canBeBought = false;
					}
					break;
				case SHOP_CATEGORY.GOLD:
					var searched = _goldShopInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_goldShopInventory = [...state.goldShopInventory];
						const _item = _goldShopInventory[searched];
						_item.canBeBought = false;
					}
					break;
			}

			return {
				...state,
				vipShopInventory: _vipShopInventory,
				goldShopInventory: _goldShopInventory,
				silverShopInventory: _silverShopInventory,
				chipsInventory: _chipsInventory,
				multipliersInventory: _multipliersInventory,
				cardsetsInventory: _cardsetsInventory,
				miscInventory: _miscInventory,
				banInventory: _banInventory,
				friendInventory: _friendInventory,
			};
		case BUY_ITEM_SUCCEEDED:
			if (payload.item == undefined || payload.item == null || payload.item.itemID == undefined) return state;

			var parent = state.categories.find((cat) => cat.categoryID == payload.item.categoryID);
			var parentType = parent && parent != null ? parent.type : "";
			var _vipShopInventory = state.vipShopInventory,
				_goldShopInventory = state.goldShopInventory,
				_silverShopInventory = state.silverShopInventory,
				_chipsInventory = state.chipsInventory,
				_multipliersInventory = state.multipliersInventory,
				_cardsetsInventory = state.cardsetsInventory,
				_miscInventory = state.miscInventory,
				_banInventory = state.banInventory,
				_friendInventory = state.friendInventory;
			switch (parentType) {
				case SHOP_CATEGORY.VIP:
					var searched = _vipShopInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_vipShopInventory = [...state.vipShopInventory];
						const _item = _vipShopInventory[searched];
						_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
					}
					break;
				case SHOP_CATEGORY.MISC:
					const subCat = Array.isArray(parent.subCategories)
						? parent.subCategories.find((sc) => sc.subCategoryID == payload.item.subCategoryID)
						: undefined;
					if (!parent.useSubCategories || subCat == undefined || subCat.type == undefined) {
						var searched = _miscInventory.findIndex((it) => it.itemID == payload.item.itemID);
						if (searched != -1) {
							_miscInventory = [...state.miscInventory];
							const _item = _miscInventory[searched];
							_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
						}
					} else {
						switch (subCat.type) {
							case SHOP_CATEGORY.CARDPACK:
								var searched = _cardsetsInventory.findIndex((it) => it.itemID == payload.item.itemID);
								if (searched != -1) {
									_cardsetsInventory = [...state.cardsetsInventory];
									const _item = _cardsetsInventory[searched];
									_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
								}
								break;
							case SHOP_CATEGORY.BAN_LIST:
								var searched = _banInventory.findIndex((it) => it.itemID == payload.item.itemID);
								if (searched != -1) {
									_banInventory = [...state.banInventory];
									const _item = _banInventory[searched];
									_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
								}
								break;
							case SHOP_CATEGORY.FRIEND_LIST:
								var searched = _friendInventory.findIndex((it) => it.itemID == payload.item.itemID);
								if (searched != -1) {
									_friendInventory = [...state.friendInventory];
									const _item = _friendInventory[searched];
									_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
								}
								break;
						}
					}
					break;
				case SHOP_CATEGORY.MULTIPLIERS:
					var searched = _multipliersInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_multipliersInventory = [...state.multipliersInventory];
						const _item = _multipliersInventory[searched];
						_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
					}
					break;
				case SHOP_CATEGORY.CHIPS:
					var searched = _chipsInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_chipsInventory = [...state.chipsInventory];
						const _item = _chipsInventory[searched];
						_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
					}
					break;
				case SHOP_CATEGORY.SILVER:
					var searched = _silverShopInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_silverShopInventory = [...state.silverShopInventory];
						const _item = _silverShopInventory[searched];
						_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
					}
					break;
				case SHOP_CATEGORY.GOLD:
					var searched = _goldShopInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_goldShopInventory = [...state.goldShopInventory];
						const _item = _goldShopInventory[searched];
						_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
					}
					break;
			}

			return {
				...state,
				vipShopInventory: _vipShopInventory,
				goldShopInventory: _goldShopInventory,
				silverShopInventory: _silverShopInventory,
				chipsInventory: _chipsInventory,
				multipliersInventory: _multipliersInventory,
				cardsetsInventory: _cardsetsInventory,
				miscInventory: _miscInventory,
				banInventory: _banInventory,
				friendInventory: _friendInventory,
			};
		case ADD_CREDIT_SUCCEEDED:
			if (!payload || payload.itemID == undefined) return state;

			var _vipShopInventory = state.vipShopInventory,
				_goldShopInventory = state.goldShopInventory,
				_silverShopInventory = state.silverShopInventory,
				_chipsInventory = state.chipsInventory,
				_multipliersInventory = state.multipliersInventory,
				_cardsetsInventory = state.cardsetsInventory,
				_miscInventory = state.miscInventory,
				_banInventory = state.banInventory,
				_friendInventory = state.friendInventory;

			var searched = _goldShopInventory.findIndex((it) => it.itemID == payload.itemID);
			if (searched != -1) {
				_goldShopInventory = [...state.goldShopInventory];
				const _item = _goldShopInventory[searched];
				_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
			} else {
				searched = _vipShopInventory.findIndex((it) => it.itemID == payload.itemID);
				if (searched != -1) {
					_vipShopInventory = [...state.vipShopInventory];
					const _item = _vipShopInventory[searched];
					_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
				} else {
					searched = _silverShopInventory.findIndex((it) => it.itemID == payload.itemID);
					if (searched != -1) {
						_silverShopInventory = [...state.silverShopInventory];
						const _item = _silverShopInventory[searched];
						_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
					} else {
						searched = _chipsInventory.findIndex((it) => it.itemID == payload.itemID);
						if (searched != -1) {
							_chipsInventory = [...state.chipsInventory];
							const _item = _chipsInventory[searched];
							_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
						} else {
							searched = _multipliersInventory.findIndex((it) => it.itemID == payload.itemID);
							if (searched != -1) {
								_multipliersInventory = [...state.multipliersInventory];
								const _item = _multipliersInventory[searched];
								_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
							} else {
								searched = _cardsetsInventory.findIndex((it) => it.itemID == payload.itemID);
								if (searched != -1) {
									_cardsetsInventory = [...state.cardsetsInventory];
									const _item = _cardsetsInventory[searched];
									_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
								} else {
									searched = _miscInventory.findIndex((it) => it.itemID == payload.itemID);
									if (searched != -1) {
										_miscInventory = [...state.miscInventory];
										const _item = _miscInventory[searched];
										_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
									} else {
										searched = _banInventory.findIndex((it) => it.itemID == payload.itemID);
										if (searched != -1) {
											_banInventory = [...state.banInventory];
											const _item = _banInventory[searched];
											_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
										} else {
											searched = _friendInventory.findIndex((it) => it.itemID == payload.itemID);
											if (searched != -1) {
												_friendInventory = [...state.friendInventory];
												const _item = _friendInventory[searched];
												_item.canBeBought = !_item.canBeBoughtOnce && _item.isAvailable;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			return {
				...state,
				vipShopInventory: _vipShopInventory,
				goldShopInventory: _goldShopInventory,
				silverShopInventory: _silverShopInventory,
				chipsInventory: _chipsInventory,
				multipliersInventory: _multipliersInventory,
				cardsetsInventory: _cardsetsInventory,
				miscInventory: _miscInventory,
				banInventory: _banInventory,
				friendInventory: _friendInventory,
			};
		case BUY_ITEM_FAILED:
			if (payload.item == undefined || payload.item == null || payload.item.itemID == undefined) return state;

			var parent = state.categories.find((cat) => cat.categoryID == payload.item.categoryID);
			var parentType = parent && parent != null ? parent.type : "";
			var _vipShopInventory = state.vipShopInventory,
				_goldShopInventory = state.goldShopInventory,
				_silverShopInventory = state.silverShopInventory,
				_chipsInventory = state.chipsInventory,
				_multipliersInventory = state.multipliersInventory,
				_cardsetsInventory = state.cardsetsInventory,
				_miscInventory = state.miscInventory,
				_banInventory = state.banInventory,
				_friendInventory = state.friendInventory;
			switch (parentType) {
				case SHOP_CATEGORY.VIP:
					var searched = _vipShopInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_vipShopInventory = [...state.vipShopInventory];
						const _item = _vipShopInventory[searched];
						_item.canBeBought = _item.isAvailable;
					}
					break;
				case SHOP_CATEGORY.MISC:
					const subCat = Array.isArray(parent.subCategories)
						? parent.subCategories.find((sc) => sc.subCategoryID == payload.item.subCategoryID)
						: undefined;
					if (!parent.useSubCategories || subCat == undefined || subCat.type == undefined) {
						var searched = _miscInventory.findIndex((it) => it.itemID == payload.item.itemID);
						if (searched != -1) {
							_miscInventory = [...state.miscInventory];
							const _item = _miscInventory[searched];
							_item.canBeBought = _item.isAvailable;
						}
					} else {
						switch (subCat.type) {
							case SHOP_CATEGORY.CARDPACK:
								var searched = _cardsetsInventory.findIndex((it) => it.itemID == payload.item.itemID);
								if (searched != -1) {
									_cardsetsInventory = [...state.cardsetsInventory];
									const _item = _cardsetsInventory[searched];
									_item.canBeBought = _item.isAvailable;
								}
								break;
							case SHOP_CATEGORY.BAN_LIST:
								var searched = _banInventory.findIndex((it) => it.itemID == payload.item.itemID);
								if (searched != -1) {
									_banInventory = [...state.banInventory];
									const _item = _banInventory[searched];
									_item.canBeBought = _item.isAvailable;
								}
								break;
							case SHOP_CATEGORY.FRIEND_LIST:
								var searched = _friendInventory.findIndex((it) => it.itemID == payload.item.itemID);
								if (searched != -1) {
									_friendInventory = [...state.friendInventory];
									const _item = _friendInventory[searched];
									_item.canBeBought = _item.isAvailable;
								}
								break;
						}
					}
					break;
				case SHOP_CATEGORY.MULTIPLIERS:
					var searched = _multipliersInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_multipliersInventory = [...state.multipliersInventory];
						const _item = _multipliersInventory[searched];
						_item.canBeBought = _item.isAvailable;
					}
					break;
				case SHOP_CATEGORY.CHIPS:
					var searched = _chipsInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_chipsInventory = [...state.chipsInventory];
						const _item = _chipsInventory[searched];
						_item.canBeBought = _item.isAvailable;
					}
					break;
				case SHOP_CATEGORY.SILVER:
					var searched = _silverShopInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_silverShopInventory = [...state.silverShopInventory];
						const _item = _silverShopInventory[searched];
						_item.canBeBought = _item.isAvailable;
					}
					break;
				case SHOP_CATEGORY.GOLD:
					var searched = _goldShopInventory.findIndex((it) => it.itemID == payload.item.itemID);
					if (searched != -1) {
						_goldShopInventory = [...state.goldShopInventory];
						const _item = _goldShopInventory[searched];
						_item.canBeBought = _item.isAvailable;
					}
					break;
			}

			return {
				...state,
				vipShopInventory: _vipShopInventory,
				goldShopInventory: _goldShopInventory,
				silverShopInventory: _silverShopInventory,
				chipsInventory: _chipsInventory,
				multipliersInventory: _multipliersInventory,
				cardsetsInventory: _cardsetsInventory,
				miscInventory: _miscInventory,
				banInventory: _banInventory,
				friendInventory: _friendInventory,
			};
		case RECEIVE_SHOP_INVENTORY:
			if (
				payload == undefined ||
				!Array.isArray(payload.categories) ||
				!Array.isArray(payload.items) ||
				payload.categories.length <= 0 ||
				payload.items.length <= 0
			)
				return state;

			// separate the items
			var _vipShopInventory = [],
				_goldShopInventory = [],
				_silverShopInventory = [],
				_chipsInventory = [],
				_multipliersInventory = [],
				_cardsetsInventory = [],
				_miscInventory = [],
				_banInventory = [],
				_friendInventory = [],
				_categories = [];

			var shopURL = payload.shopURL;
			payload.categories.forEach((cat) => {
				if (cat.type !== "SILVER") {
					cat.isSelectable = cat.isVisible;
					if (cat.useSubCategories == true) {
						cat.isSelectable = cat.isVisible && !isWebOrTablet;
						cat.subCategories = [];
						if (Array.isArray(payload.subCategories)) {
							payload.subCategories.forEach((sc) => {
								if (sc.categoryID === cat.categoryID) {
									sc.isSelectable = true;
									cat.subCategories.push(getPreloadedCategoryIcon(sc, shopURL, true));
								}
							});
						}

						if (cat.subCategories.length == 0) {
							cat.useSubCategories = false;
							delete cat.subCategories;
						} else {
							cat.subCategories = orderBy(cat.subCategories, ["sequence"], ["asc"]);
						}
					}
					_categories.push(getPreloadedCategoryIcon(cat, shopURL, false));
				}
			});
			_categories = orderBy(_categories, ["sequence"], ["asc"]);

			payload.items.forEach((item, index) => {
				const itemParent = _categories.find((cat) => cat.categoryID == item.categoryID);
				var prel_item = getPreloadedShopItem(item, index, shopURL);
				if (itemParent) {
					switch (itemParent.type) {
						case SHOP_CATEGORY.VIP:
							_vipShopInventory.push(prel_item);
							break;
						case SHOP_CATEGORY.MULTIPLIERS:
							_multipliersInventory.push(prel_item);
							break;
						case SHOP_CATEGORY.CHIPS:
							_chipsInventory.push(prel_item);
							break;
						/* case SHOP_CATEGORY.SILVER:
							_silverShopInventory.push(prel_item);
							break; */
						case SHOP_CATEGORY.GOLD:
							_goldShopInventory.push(prel_item);
							break;
						case SHOP_CATEGORY.MISC:
							const subCat = Array.isArray(itemParent.subCategories)
								? itemParent.subCategories.find((sc) => sc.subCategoryID == item.subCategoryID)
								: undefined;
							if (!itemParent.useSubCategories || subCat == undefined || subCat.type == undefined) {
								_miscInventory.push(prel_item);
								break;
							} else {
								switch (subCat.type) {
									case SHOP_CATEGORY.CARDPACK:
										_cardsetsInventory.push(prel_item);
										break;
									case SHOP_CATEGORY.BAN_LIST:
										_banInventory.push(prel_item);
										break;
									case SHOP_CATEGORY.FRIEND_LIST:
										_friendInventory.push(prel_item);
										break;
								}
							}
							break;
					}
				}
			});

			_vipShopInventory = sortBy(_vipShopInventory, (e) => e.sequence);
			_goldShopInventory = sortBy(_goldShopInventory, (e) => e.sequence);
			_silverShopInventory = sortBy(_silverShopInventory, (e) => e.sequence);
			_chipsInventory = sortBy(_chipsInventory, (e) => e.sequence);
			_multipliersInventory = sortBy(_multipliersInventory, (e) => e.sequence);
			_cardsetsInventory = sortBy(_cardsetsInventory, (e) => e.sequence);
			_miscInventory = sortBy(_miscInventory, (e) => e.sequence);
			_banInventory = sortBy(_banInventory, (e) => e.sequence);
			_friendInventory = sortBy(_friendInventory, (e) => e.sequence);

			return {
				...state,
				categories: _categories,
				vipShopInventory: _vipShopInventory,
				goldShopInventory: _goldShopInventory,
				silverShopInventory: _silverShopInventory,
				chipsInventory: _chipsInventory,
				multipliersInventory: _multipliersInventory,
				cardsetsInventory: _cardsetsInventory,
				miscInventory: _miscInventory,
				banInventory: _banInventory,
				friendInventory: _friendInventory,
			};
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};

const getPreloadedCategoryIcon = (cat, shopURL, isSubCat) => {
	const iconImgSrc = isSubCat
		? "https://" + shopURL + "subcategories/" + cat.image
		: "https://" + shopURL + "categories/" + cat.image;
	cat.iconImgSrc = iconImgSrc;
	Image.getSize(
		iconImgSrc,
		(width, height) => (cat.itemImgDim = { width, height }),
		(error) => {
			// handleError(new Error("Error loading image: " + iconImgSrc));
		}
	);
	return cat;
};

const getPreloadedShopItem = (item, index, shopURL) => {
	const itemImgSrc = "https://" + shopURL + "item/" + item.image;
	const itemBackURL = "https://" + shopURL + "background/" + item.background;

	item.itemImgSrc = { uri: itemImgSrc };
	item.imgBack = { uri: itemBackURL };
	item.currency = item.currency || DEFAULT_CURRENCY;
	item.origIndex = index;
	item.itemImgDim = { width: 0, height: 0 };
	item.canBeBought = item.isAvailable;

	var popBestSrc;
	if (item.isMostPopulair == true && item.isBestValue == false)
		popBestSrc = "https://" + shopURL + "item/mostPopulair.png";
	if (item.isBestValue == true && item.isMostPopulair == false)
		popBestSrc = "https://" + shopURL + "item/bestValue.png";
	if (typeof popBestSrc === "string") item.popBestSrc = { uri: popBestSrc };

	Image.getSize(
		itemImgSrc,
		(width, height) => (item.itemImgDim = { width, height }),
		(error) => {
			// handleError(new Error("Error loading image: " + itemImgSrc));
		}
	);
	return item;
};
