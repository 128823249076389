import emojiData from "emoji-datasource";
import { handleError } from "../components/ErrorHandler";

const parse = (text) => {
	try {
		var tmp = text.split("["),
			emojis = [];
		tmp.forEach((t) => {
			emojis.push(t.split("]").shift());
		});
		emojis.forEach((txt) => {
			var found = emojiData.find((e) => txt == e.unified);
			if (found != undefined && found.unified != undefined) {
				const emoji = String.fromCodePoint(...found.unified.split("-").map((u) => "0x" + u));
				var reg = new RegExp("\\[" + txt + "\\]", "g");
				if (text) text = text.replace(reg, emoji);
			}
		});
		/* _.each(emojiData, (value, key) => {
      var reg = new RegExp("\\[" + value.unified + "\\]", "g");
      const emoji = String.fromCodePoint(
        ...value.unified.split("-").map((u) => "0x" + u)
      );
      if (text) text = text.replace(reg, emoji);
    }); */
		return text;
	} catch (error) {
		handleError(error);
	}
};

export default parse;
