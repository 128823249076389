import { Howl, Howler } from "howler";

export default class Sound {
	static setCategory() {}

	constructor(url, error, name) {
		this.name = name;
		this.url = url;
		this.sound = new Howl({ src: [url], onloaderror: error });
		this._isLoaded = true;

		this.play = this.play.bind(this);
		this.stop = this.stop.bind(this);
		this.getVolume = this.getVolume.bind(this);
		this.setVolume = this.setVolume.bind(this);
		this.getDuration = this.getDuration.bind(this);
		this.isPlaying = this.isPlaying.bind(this);
		this.isLoaded = this.isLoaded.bind(this);
	}

	play() {
		this.sound.play();
		return this;
	}

	stop() {
		this.sound.stop();
		return this;
	}

	getVolume() {
		return this.sound.volume();
	}

	setVolume(value) {
		this.sound.volume(value);
		return this;
	}

	getDuration() {
		return this.sound.duration();
	}

	isPlaying() {
		return this.sound.playing();
	}

	isLoaded() {
		return this._isLoaded;
	}

	static setSystemVolume(value) {
		switch (value) {
			case 0:
				Howler.mute(true);
				break;
			case 1:
				Howler.mute(false);
				break;
			default:
				Howler.volume(value);
				break;
		}
	}
}
