import { cloneDeep, difference } from "lodash";
import { Platform } from "react-native";
import { batch } from "react-redux";
/* import {
  gameStarted_2Player,
  gameStarted_3Player,
  gameStarted_4Player,
  gameStarted_5Player,
  gameStarted_6Player,
  gameStarted_7Player,
  gameStarted_8Player,
} from "../../config/testEvents"; */
import Analytics from "../../../components/Analytics/Analytics";
import { handleError } from "../../../components/ErrorHandler";
import { mySounds } from "../../../config/sounds";
import { MyWebSocket } from "../../../connection";
import { EventQueue } from "../../../controller";
import DebugLogger from "../../../controller/DebugLogger";
import { mapObject } from "../../../helpers/commonHelpers";
import { startPlay } from "../../../redux/actions";
import { clearInShopLateMoveTimeout } from "../../../redux/actions/GameShopActions";
import { openStartedGame } from "../../../redux/actions/NavigationActions";
import { removeSavedEvent } from "../../../redux/actions/SocketActions";
import { setFeatures, updateStack } from "../../../redux/actions/UserActions";
import { store } from "../../../redux/store";
import { pips } from "../../config/defaults";
import {
	CLEAR_PLAYED_CARDS,
	CLEAR_START_POSITIONS_DIRECTION,
	CLEAR_TO_LATE_TIME,
	CLOSE_PANEL_MENU,
	DEAL_CARD,
	DISABLE_THROW_AWAY,
	ENABLE_THROW_AWAY,
	FILTER_AVAILABLE_MOVES,
	FINISH_COUNTDOWN,
	FINISH_LATE_MOVE_IN_SHOP,
	FOCUS_IN_GAME_CHAT_INPUT,
	FOCUS_OUT_GAME_CHAT_INPUT,
	GAME_BOARD_RENDERED,
	GAME_END_RECEIVED,
	GAME_INFO_RECEIVED,
	GAME_PLAYER_ACTED_RECEIVED,
	GAME_PLAYER_CONNECTED,
	GAME_PLAYER_DISCONNECTED,
	GAME_PLAYER_LEFT_GAME,
	GAME_PLAYER_TO_ACT_RECEIVED,
	GAME_REOPENED,
	GAME_STARTED_RECEIVED,
	INCREASE_MOVE_COUNT,
	MOVE_BOARD_PAWNS,
	MOVE_CARD_TO_PLAYED_CARDS,
	MOVE_PAWNS,
	MOVE_THROWN_CARD_TO_PLAYED_CARDS,
	NEW_GAME_STARTED,
	OPEN_PANEL_MENU,
	PLAY_CARD,
	PROCESS_IMAGE_STORED,
	REQUEST_REOPEN_GAME,
	RESET_GAME_END,
	RESET_GAME_PLAYER_ACTED,
	RESET_GAME_PLAYER_TO_ACT,
	RESET_LAST_MOVE_EFFECT,
	RESET_MOVE_COUNT,
	RESET_PLAYABLE_CARDS,
	RESET_PLAYABLE_POSITIONS,
	RESET_STARTED_GAME,
	RESET_WARNING_TIMER,
	ROLLBACK_MOVE,
	SAVE_START_POSITIONS_DIRECTION,
	SELECT_CARD,
	SELECT_PAWN,
	SELECT_TARGETED_POSITION,
	SET_BOARD_COORDINATES,
	SET_BOARD_POSITIONS_BASE_COLOR,
	SET_MOVE_COUNT,
	SET_NEXT_SLOT_TO_ACT,
	SET_PLAYABLE_CARDS,
	SET_PLAYABLE_PAWNS,
	SET_PLAYER_PANELS_COLOR,
	SET_TO_LATE_TIME,
	SET_WARNING_TIMER,
	SHOW_DID_YOU_KNOW,
	SPECTATE_GAME_STARTED,
	SPECTATE_GAME_STOPPED,
	START_COUNTDOWN,
	START_LATE_MOVE_IN_SHOP,
	STORE_CREATED_SCREENSHOT,
	SWAP_PLAYER_PANELS,
	THROW_AWAY,
	UPDATE_MESSAGES_COLORS,
	UPDATE_PAWNS,
} from "./actionTypes";
import { DEBUG_ENABLED } from "../../../config/permissions";

export const spectateGameStarted = () => {
	return { type: SPECTATE_GAME_STARTED };
};

export const spectateGameStopped = () => {
	return { type: SPECTATE_GAME_STOPPED };
};

export const processStoredImage = (msg) => {
	return { type: PROCESS_IMAGE_STORED, payload: msg };
};

export const storeCreatedScreenshot = (uri) => {
	return { type: STORE_CREATED_SCREENSHOT, payload: uri };
};

let swapTimeout = null;

export function getSwapTimeout() {
	return swapTimeout;
}
export function clearSwapTimeout() {
	clearTimeout(swapTimeout);
	swapTimeout = null;
}

export const swapPlayerPanels = (curSlotID, newSlotID, rotateBoard = false, isAutomated = false) => {
	return (dispatch) => {
		setTimeout(async () => {
			await DebugLogger.shared.saveLog(
				{
					swap_init_message: "swapPlayerPanels initiated",
					currentSlotID: curSlotID,
					targetSlotID: newSlotID,
					playerRequested: !isAutomated,
				},
				true
			);
		}, 50);
		if (rotateBoard == true) dispatch(moveBoardPawns(curSlotID, newSlotID));
		dispatch({
			type: SWAP_PLAYER_PANELS,
			payload: { curSlotID, newSlotID, rotateBoard },
		});
		const { players, gameBoard, gameTheme } = store.getState().startedGame;
		dispatch({
			type: UPDATE_MESSAGES_COLORS,
			payload: { players: players, panels: gameBoard.playerPanels, gameTheme },
		});

		//finishing swapping event
		if (EventQueue.shared.isSwapPlayerPanelEventInProgress) {
			const { app, tab } = store.getState();
			const { isBackground, isSettingsOpened, isHelpOpened } = app;
			const { isShopOpen } = tab;
			if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
				swapTimeout = setTimeout(() => {
					const { currentEvent, requestNextEventExecution } = EventQueue.shared;
					EventQueue.shared.isSwapPlayerPanelEventInProgress = false;
					if (currentEvent !== null) {
						clearSwapTimeout();
						const { sMessageID, delay } = currentEvent;
						requestNextEventExecution(sMessageID, true, delay);
					}
				}, 1000);
			} else {
				const { currentEvent, requestNextEventExecution } = EventQueue.shared;
				if (currentEvent !== null) {
					EventQueue.shared.isSwapPlayerPanelEventInProgress = false;
					const { sMessageID, delay } = currentEvent;
					requestNextEventExecution(sMessageID, true, delay);
				}
			}
		}
	};
};

export const moveBoardPawns = (curSlotID, newSlotID) => {
	return (dispatch) => {
		dispatch({ type: MOVE_BOARD_PAWNS, payload: { curSlotID, newSlotID } });
	};
};

export const openPanelMenu = (panelID) => {
	return (dispatch) => {
		dispatch(closePanelMenu);
		dispatch({ type: OPEN_PANEL_MENU, payload: { panelID } });
	};
};

export const closePanelMenu = () => {
	return { type: CLOSE_PANEL_MENU };
};

export const gamePlayerLeftStartedGame = (msg) => {
	return { type: GAME_PLAYER_LEFT_GAME, payload: msg };
};

export const gamePlayerConnected = (msg) => {
	const { startedGame } = store.getState();
	const { reconnectSound } = mySounds;
	if (
		startedGame.boardLoaded == true &&
		typeof reconnectSound !== "undefined" &&
		reconnectSound != null &&
		reconnectSound.isLoaded() == true
	) {
		reconnectSound.play();
	}
	return { type: GAME_PLAYER_CONNECTED, payload: msg };
};

export const gamePlayerDisconnected = (msg) => {
	const { startedGame } = store.getState();
	const { disconnectSound } = mySounds;
	if (
		startedGame.boardLoaded == true &&
		typeof disconnectSound !== "undefined" &&
		disconnectSound != null &&
		disconnectSound.isLoaded() == true
	) {
		disconnectSound.play();
	}
	return { type: GAME_PLAYER_DISCONNECTED, payload: msg };
};

export const focusInGameChatInput = () => {
	DebugLogger.shared.saveLog(
		{
			_focus_trigger: "Focus in GameChat input requested",
		},
		true
	);
	return { type: FOCUS_IN_GAME_CHAT_INPUT };
};

export const focusOutGameChatInput = () => {
	return { type: FOCUS_OUT_GAME_CHAT_INPUT };
};

export const gameBoardRendered = () => {
	return { type: GAME_BOARD_RENDERED };
};

export const gameStartedReceived = (msg) => {
	return (dispatch) => {
		if (!store.getState().tab.isGameStarted) dispatch(openStartedGame());

		// const { tab } = store.getState();
		// if (tab.isShopOpen) {
		const { gameStartSound } = mySounds;
		if (typeof gameStartSound !== "undefined" && gameStartSound != null && gameStartSound.isLoaded() == true) {
			gameStartSound.setVolume(0.2);
			gameStartSound.play();
		}
		// }
	};
};

export const requestReopenGame = () => {
	return { type: REQUEST_REOPEN_GAME };
};

export const gameReopened = () => {
	return { type: GAME_REOPENED };
};

export const newGameStarted = () => {
	return { type: NEW_GAME_STARTED };
};

export const gameDetailsStartedRecevied = (msg, isDisc = false) => {
	return async (dispatch) => {
		try {
			DebugLogger.shared.gameID = msg.data.generic.gameID;
			var _GDS_log = {
				GDS_message: isDisc ? "gameDetails received after disconnected and reconnected" : "gameDetails received",
				actions: {},
				reason: msg.reason,
				sMessageID: msg.sMessageID,
				gameID: msg.data.generic.gameID,
			};
			var _counter = 0;

			if (msg.reason == "GAME_STARTED" || msg.reason === "RESET") {
				_GDS_log.actions["_" + _counter] = "Queued event started to execute";
				_counter++;
				EventQueue.shared.startQueuedEvent(msg);
				if (msg.reason === "RESET") {
					const { startedGame } = store.getState();
					//collect debugging data and send it in a clientException event
					var _pawns = startedGame.gameBoard.startPositions.filter((sp) => sp.filled == true);
					_pawns = _pawns.concat(startedGame.gameBoard.boardPositions.filter((bp) => bp.filled == true));
					var _players = cloneDeep(startedGame.players);
					mapObject(_players, (k, v) => {
						const search = startedGame.gameBoard.playerPanels.find((pp) => pp.slotID == v.slotID);
						if (search && search.color) v.color = search.color;
					});
					var exceptionMsg = {
						type: "clientException",
						sMessageID: 0,
						inThisSession: true,
						exception: "RESET_GAME_REQUESTED",
						stackTrace: [],
						details: {
							players: _players,
							gamePlayerToAct: startedGame.gamePlayerToAct,
							gamePlayerActed: startedGame.gamePlayerActed,
							slotToAct: startedGame.slotToAct,
							playerSlot: startedGame.playerSlot,
							sitSlotID: startedGame.sitSlotID,
							pawns: _pawns,
							cards: startedGame.cards,
						},
					};
					if (Platform.OS !== "web") exceptionMsg.details.scaleLog = DebugLogger.shared.storedForReset;
					MyWebSocket.shared.sendMsg(exceptionMsg);
				}
				if (msg.reason === "GAME_STARTED" && !store.getState().startedGame.disconnectedFromGame) {
					dispatch(newGameStarted());
				}
			}

			const { tab, app } = store.getState();
			var isAlreadyOpened = false;
			if (!app.isPlayStarted) dispatch(startPlay());
			if (!tab.isGameStarted) {
				_GDS_log.actions["_" + _counter] = "Opening the GameBoard component";
				_counter++;
				dispatch(openStartedGame());
			} else {
				isAlreadyOpened = true;
			}

			//finish move if there is any move in progress
			// dispatch(resetPlayableCards());
			dispatch(disableThrowAway());
			dispatch(finishCountdown());
			if (timeout) clearTimeout(timeout);
			dispatch(finishLateMoveInShop());
			clearInShopLateMoveTimeout();
			const { countdownSound } = mySounds;
			if (typeof countdownSound !== "undefined" && countdownSound != null && countdownSound.isLoaded() == true) {
				countdownSound.stop();
			}
			// end finish move

			dispatch(_gameStartedReceived(msg));
			dispatch(setBoardCoordinates());
			dispatch(setFeatures(msg.features));
			dispatch(setPlayerPanelsColor(msg.data.specific.colors));
			dispatch(setBoardPositionsBaseColor());
			_GDS_log.actions["_" + _counter] = "Disabling all positions";
			_counter++;
			dispatch(resetPlayablePositions());
			const { sitSlotID, playerSlot } = store.getState().startedGame;
			if (sitSlotID != playerSlot) {
				_GDS_log.actions["_" + _counter] = "Swapping player panels";
				_counter++;
				dispatch(swapPlayerPanels(playerSlot, sitSlotID, true, true));
			}
			dispatch(updatePawns());

			if (!store.getState().startedGame.isNewGameStarted) {
				if (store.getState().startedGame.disconnectedFromGame == true) {
					dispatch(gameReopened());
					if (
						isAlreadyOpened ||
						(msg.data &&
							msg.data.specific &&
							Array.isArray(msg.data.specific.cards) &&
							msg.data.specific.cards.length != 0)
					) {
						dispatch(dealCards());
					} else {
						const { currentEvent } = EventQueue.shared;
						if (currentEvent !== null) {
							_GDS_log.actions["_" + _counter] = "Requesting next event's execution";
							_counter++;
							const { sMessageID, delay } = currentEvent;
							EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
						}
					}
				} else {
					if (
						isAlreadyOpened &&
						msg.data &&
						msg.data.specific &&
						Array.isArray(msg.data.specific.cards) &&
						msg.data.specific.cards.length != 0
					) {
						dispatch(dealCards());
					} else {
						const { currentEvent } = EventQueue.shared;
						if (currentEvent !== null) {
							_GDS_log.actions["_" + _counter] = "Requesting next event's execution";
							_counter++;
							const { sMessageID, delay } = currentEvent;
							EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
						}
					}
				}
			} else {
				const { isSettingsOpened, isHelpOpened } = app;
				const { isShopOpen } = tab;
				if (isSettingsOpened || isHelpOpened || isShopOpen || msg.reason === "RESET") {
					if (
						msg.data &&
						msg.data.specific &&
						Array.isArray(msg.data.specific.cards) &&
						msg.data.specific.cards.length != 0
					) {
						dispatch(dealCards());
					} else {
						_GDS_log.actions["_" + _counter] = "Requesting next event's execution";
						_counter++;
						const { currentEvent } = EventQueue.shared;
						if (currentEvent !== null) {
							EventQueue.shared.isDealInProgress = false;
							const { sMessageID, delay } = currentEvent;
							EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
						} else {
							EventQueue.shared.isDealInProgress = false;
							EventQueue.shared.requestNextEventExecution(0, true, 0);
						}
					}
				} else {
					var responseTo = store
						.getState()
						.socket.sentMsg.find(
							(m) =>
								(m.type == "rejoinGame" && m.cMessageID == msg.cMessageID) ||
								(m.type == "spectateGame" && msg.data && msg.data.generic && m.gameID == msg.data.generic.gameID)
						);
					if (responseTo != undefined) {
						if (responseTo.type == "spectateGame") {
							dispatch(spectateGameStarted());
						}
						dispatch(removeSavedEvent(responseTo.cMessageID));
					}
					if (
						!msg.data ||
						!msg.data.specific ||
						!Array.isArray(msg.data.specific.cards) ||
						msg.data.specific.cards.length == 0
					) {
						// const { currentEvent } = EventQueue.shared;
						// if (currentEvent !== null) {
						//   _GDS_log.actions["_" + _counter] =
						//     "Requesting next event's execution";
						//   _counter++;
						//   const { sMessageID, delay } = currentEvent;
						//   EventQueue.shared.requestNextEventExecution(
						//     sMessageID,
						//     true,
						//     delay
						//   );
						// }
					}
				}
			}
			await DebugLogger.shared.saveLog(_GDS_log, true);
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const gameInfoReceived = (msg) => {
	return async (dispatch) => {
		try {
			const { tab, app, startedGame } = store.getState();
			if (
				/* !store.getState().tab.isGameStarted &&
        (msg.reason === "DEAL" || msg.reason === "NEW_ROUND") */
				startedGame.disconnectedFromGame == true /* ||
        !app.isPlayStarted ||
        !tab.isGameStarted */
			) {
				dispatch(gameDetailsStartedRecevied(msg, startedGame.disconnectedFromGame));
			} else {
				var _GI_log = {
					GI_message: "gameDetails received",
					actions: {},
					reason: msg.reason,
					sMessageID: msg.sMessageID,
					gameID: msg.data.generic.gameID,
				};
				var _counter = 0;

				_GI_log.actions["_" + _counter] = "Queued event started to execute";
				_counter++;
				EventQueue.shared.startQueuedEvent(msg);

				//finish move if there is any move in progress
				// dispatch(resetPlayableCards());
				dispatch(disableThrowAway());
				dispatch(finishCountdown());
				if (timeout) clearTimeout(timeout);
				dispatch(finishLateMoveInShop());
				clearInShopLateMoveTimeout();
				const { countdownSound } = mySounds;
				if (typeof countdownSound !== "undefined" && countdownSound != null && countdownSound.isLoaded() == true) {
					countdownSound.stop();
				}
				// end finish move
				_GI_log.actions["_" + _counter] = "Disabling all positions";
				_counter++;
				dispatch(resetPlayablePositions());
				_GI_log.actions["_" + _counter] = "Clearing played cards";
				_counter++;
				dispatch(clearPlayedCards());
				dispatch(_gameInfoReceived(msg));
				dispatch(setFeatures(msg.features));
				_GI_log.actions["_" + _counter] = "Updating pawns based on event's data";
				_counter++;
				dispatch(updatePawns());

				// const { isBackground, isSettingsOpened, isHelpOpened } = app;
				// const { isShopOpen } = tab;
				// if (isBackground || isSettingsOpened || isHelpOpened || isShopOpen) {
				dispatch(dealCards());
				// }
				await DebugLogger.shared.saveLog(_GI_log, true);
			}
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const gamePlayerToActReceived = (msg) => {
	if (DEBUG_ENABLED) {
		console.log(`!!! gamePlayerToActReceived called with msg = ${JSON.stringify(msg)} !!!`);
	}

	return async (dispatch) => {
		try {
			var _GPTA_log = {
				GPTA_message: "gamePlayerToAct received",
				sMessageID: msg.sMessageID,
				gameID: msg.gameID,
				isPlayerToAct: msg.isPlayerToAct,
				slotToAct: msg.slotID,
				actions: {},
			};
			var _counter = 0;
			batch(() => {
				if (store.getState().startedGame.gameID == msg.gameID) {
					if (typeof msg.isPlayerToAct !== "undefined" && msg.isPlayerToAct == true) {
						_GPTA_log.actions["_" + _counter] = "Finished previous move if there were any";
						_counter++;
						//finish move if there is any move in progress
						_GPTA_log.actions["_" + _counter] = "Player's all cards disabled";
						_counter++;
						dispatch(resetPlayableCards());
						_GPTA_log.actions["_" + _counter] = "All positions are disabled";
						_counter++;
						dispatch(resetPlayablePositions());
						_GPTA_log.actions["_" + _counter] = "Throw Away button is disabled";
						_counter++;
						dispatch(disableThrowAway());
						//stop previous timer
						if (typeof msg.waitForSecond_7 === "undefined" || msg.waitForSecond_7 == false) {
							dispatch(finishCountdown());
							if (timeout) clearTimeout(timeout);
							dispatch(finishLateMoveInShop());
							clearInShopLateMoveTimeout();
							const { countdownSound } = mySounds;
							if (
								typeof countdownSound !== "undefined" &&
								countdownSound != null &&
								countdownSound.isLoaded() == true
							) {
								countdownSound.stop();
							}
							//start a new timer
							if (typeof msg.isPlayerToAct !== "undefined" && msg.isPlayerToAct == true) {
								dispatch(startCountdown(msg));
							}
						}
					}

					_GPTA_log.actions["_" + _counter] = "Queued event started to execute";
					_counter++;
					EventQueue.shared.startQueuedEvent(msg);
					_GPTA_log.actions["_" + _counter] = "Clearing previous gamePlayerToAct event if there is any";
					_counter++;
					dispatch(resetGamePlayerToAct());
					dispatch(_gamePlayerToActReceived(msg));

					const { app, tab } = store.getState();
					const { myTurnSound, myTurnInactiveSound } = mySounds;
					if (typeof msg.isPlayerToAct !== "undefined" && msg.isPlayerToAct == true) {
						//current player's gamePlayerToAct
						if (msg.availableMoves[0].type != "THROW") {
							dispatch(setPlayableCards());
							dispatch(setPlayablePawns());
						} else {
							_GPTA_log.actions["_" + _counter] = "Enabled the Throw Away button";
							_counter++;
							dispatch(enableThrowAway());
						}

						/* if (tab.isShopOpen) {
            dispatch(startShopLateMoveTimeout());
          } */

						if (msg.availableMoves.length == 1) {
							_GPTA_log.actions["_" + _counter] = "There is detected only 1 possible move";
							_counter++;
							if (msg.availableMoves[0].type != "THROW") {
								const { startedGame } = store.getState();
								var _cardIndex;
								if (msg.waitForSecond_7 != undefined && msg.waitForSecond_7 == true) {
									_cardIndex = msg.cardIndex;
								} else {
									_cardIndex = startedGame.cards.findIndex(
										// (c) => c.id == msg.availableMoves[0].card
										(c) => c && c.id && c.id.startsWith(pips[msg.availableMoves[0].pip])
									);
								}
								if (_cardIndex != undefined) {
									_GPTA_log.actions["_" + _counter] = {
										message: "There is only 1 playable card",
									};
									_GPTA_log.actions["_" + _counter].playableCard = startedGame.cards[_cardIndex];
									_counter++;
									dispatch(selectCard(startedGame.cards[_cardIndex], _cardIndex));
								}
							}
						} else {
							_GPTA_log.actions["_" + _counter] = "There is detected not only 1 possible move";
							_counter++;
							const { startedGame } = store.getState();
							var _playableCards = [],
								_playablePawns = [];
							startedGame.cards.forEach((c) => {
								if (c.filtered && c.playable) _playableCards.push(c);
							});
							startedGame.gameBoard.boardPositions.forEach((bp) => {
								if (bp.filtered && bp.playable) _playablePawns.push(bp);
							});
							startedGame.gameBoard.startPositions.forEach((sp) => {
								if (sp.filtered && sp.playable) _playablePawns.push(sp);
							});
							var cardCounts = {};
							_playableCards.forEach(function (x) {
								cardCounts[x.id] = (cardCounts[x.id] || 0) + 1;
							});
							if (_playableCards.length == 1 || Object.keys(cardCounts).length == 1) {
								_GPTA_log.actions["_" + _counter] = {
									message: "There is only 1 playable card",
								};
								_GPTA_log.actions["_" + _counter].playableCard = _playableCards;
								_counter++;
								if (msg.waitForSecond_7 != undefined && msg.waitForSecond_7 == true) {
									_GPTA_log.actions["_" + _counter] = "Double move's second move is detected";
									_counter++;
									dispatch(selectCard(_playableCards[0], msg.cardIndex));
								} else {
									dispatch(selectCard(_playableCards[0], 0));
								}
							}
							if (_playablePawns.length == 1) {
								_GPTA_log.actions["_" + _counter] = {
									message: "There is only 1 playable pawn",
								};
								_GPTA_log.actions["_" + _counter].playablePawn = _playablePawns[0];
								_counter++;
								dispatch(selectPawn(_playablePawns[0]));
								dispatch(resetLastMoveEffect());
							}
						}
						if (store.getState().startedGame.waitForSecond_7 == false) {
							if (!app.isBackground && !app.isSettingsOpened && !app.isHelpOpened && !tab.isShopOpen) {
								if (store.getState().startedGame.useLoudToActSound) {
									myTurnInactiveSound.play();
								} else {
									myTurnSound.play();
								}
								const { currentEvent } = EventQueue.shared;
								if (currentEvent !== null) {
									_GPTA_log.actions["_" + _counter] = "Requested next event's execution";
									_counter++;
									const { sMessageID, delay } = currentEvent;
									EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
								}
							} else {
								// myTurnInactiveSound.play();
								const { currentEvent } = EventQueue.shared;
								if (currentEvent !== null) {
									_GPTA_log.actions["_" + _counter] = "Requested next event's execution";
									_counter++;
									const { sMessageID, delay } = currentEvent;
									EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
								}
							}
						} else {
							const { currentEvent } = EventQueue.shared;
							if (currentEvent !== null) {
								_GPTA_log.actions["_" + _counter] = "Requested next event's execution";
								_counter++;
								const { sMessageID, delay } = currentEvent;
								EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
							}
						}
					} else {
						//other player's gamePlayerToAct
						const { currentEvent } = EventQueue.shared;
						if (currentEvent !== null) {
							_GPTA_log.actions["_" + _counter] = "Requested next event's execution";
							_counter++;
							const { sMessageID, delay } = currentEvent;
							EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
						}
					}
				}
			});
			const { startedGame, app, tab } = store.getState();
			if (msg.isPlayerToAct === true) {
				if (startedGame.gamePlayerToAct !== null && startedGame.useQuickMove === true) {
					if (startedGame.selectedMoveIdToAct != -1 && startedGame.canPlayerAct === true) {
						//do automatic move quickMove
						const card = startedGame.cards.find((c) => c.selected);
						if (card != undefined) {
							const cardIndex = startedGame.cards.findIndex((c) => c.selected);
							const _selectedMove = startedGame.gamePlayerToAct.availableMoves.find(
								(m) => m.moveID == startedGame.selectedMoveIdToAct
							);
							if (_selectedMove) {
								if (_selectedMove.type == "DOUBLE") {
									var selTargetedPositions = startedGame.gameBoard.boardPositions.filter(
										(bp) => bp.selected && bp.targeted && !bp.filled
									);
									if (selTargetedPositions.length == 1) {
										dispatch(selectPawn(selTargetedPositions[0]));
									}

									//log the automatic move quickMove
									_GPTA_log.actions["_" + _counter] = {
										description: "There is a quick DOUBLE move detected and automatically will be played",
										gameID: startedGame.gameID,
										moveID: startedGame.selectedMoveIdToAct,
										actID: startedGame.gamePlayerToAct.actID,
										selTargetedPositions,
									};
									_counter++;
								} else {
									const moveToSave = {
										saveMove: true,
										selectedMove: {
											..._selectedMove,
											cards: [card.id],
											cardIndex,
										},
									};
									var msgGamePlayerActQuickMove = {
										sMessageID: 0,
										type: "playerAct",
										gameID: startedGame.gameID,
										moveID: startedGame.selectedMoveIdToAct,
										actID: startedGame.gamePlayerToAct.actID,
										card: card.id,
										toLate: false,
									};
									MyWebSocket.shared.sendMsg(msgGamePlayerActQuickMove, "", moveToSave);
									dispatch(playCard(msgGamePlayerActQuickMove, moveToSave));

									//log the automatic move quickMove
									_GPTA_log.actions["_" + _counter] = {
										description: "There is a quickMove detected and automatically will be played",
										gameID: startedGame.gameID,
										moveID: startedGame.selectedMoveIdToAct,
										actID: startedGame.gamePlayerToAct.actID,
										moveToSave,
									};
									_counter++;
								}
							} else {
								if (app.isBackground || app.isSettingsOpened || app.isHelpOpened || tab.isShopOpen) {
									const { myTurnInactiveSound } = mySounds;
									myTurnInactiveSound.play();
								}
							}
						} else {
							if (app.isBackground || app.isSettingsOpened || app.isHelpOpened || tab.isShopOpen) {
								const { myTurnInactiveSound } = mySounds;
								myTurnInactiveSound.play();
							}
						}
					} else if (startedGame.isThrowAwayAction) {
						//do automatic quick THROW move
						var _msgPlayerActQuickThrow = {
							sMessageID: 0,
							type: "playerAct",
							gameID: startedGame.gameID,
							moveID: 0,
							actID: startedGame.gamePlayerToAct.actID,
							card: "*",
							toLate: false,
						};
						var selectedMove = startedGame.gamePlayerToAct.availableMoves[0];
						selectedMove.cards = [];
						startedGame.cards.forEach((card) => selectedMove.cards.push(card.id));
						const moveToSave = {
							saveMove: true,
							selectedMove: selectedMove,
						};
						MyWebSocket.shared.sendMsg(_msgPlayerActQuickThrow, "", moveToSave);
						dispatch(throwAway(_msgPlayerActQuickThrow, moveToSave));

						//log the automatic move quickMove
						_GPTA_log.actions["_" + _counter] = {
							description: "There is a quickThrowMove detected and automatically will be played",
							gameID: startedGame.gameID,
							moveID: 0,
							actID: startedGame.gamePlayerToAct.actID,
							moveToSave,
						};
						_counter++;
					} else {
						if (app.isBackground || app.isSettingsOpened || app.isHelpOpened || tab.isShopOpen) {
							const { myTurnInactiveSound } = mySounds;
							myTurnInactiveSound.play();
						}
					}
				} else {
					if (app.isBackground || app.isSettingsOpened || app.isHelpOpened || tab.isShopOpen) {
						const { myTurnInactiveSound } = mySounds;
						myTurnInactiveSound.play();
					}
				}
			}
			await DebugLogger.shared.saveLog(_GPTA_log, true);
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const setNextSlotToAct = () => {
	return { type: SET_NEXT_SLOT_TO_ACT };
};

export const resetGamePlayerToAct = () => {
	return (dispatch) => {
		dispatch({ type: RESET_GAME_PLAYER_TO_ACT });
	};
};

export const gamePlayerActedReceived = (msg) => {
	if (DEBUG_ENABLED) {
		console.log(`!!! gamePlayerActedReceived called with msg = ${JSON.stringify(msg)} !!!`);
	}

	return async (dispatch) => {
		try {
			var _GA_log = {
				GA_message: "gamePlayerActed received",
				sMessageID: msg.sMessageID,
				gameID: msg.gameID,
				actions: {},
			};
			var _counter = 0;
			_GA_log.actions["_" + _counter] = "Queued event started to execute";
			_counter++;
			const { socket, startedGame } = store.getState();
			EventQueue.shared.startQueuedEvent(msg);
			if (startedGame.gameID == msg.gameID) {
				//finish move if there is any move in progress
				dispatch(resetPlayableCards());
				// dispatch(resetPlayablePositions());
				dispatch(disableThrowAway());
				dispatch(finishCountdown());
				dispatch(finishLateMoveInShop());
				if (timeout) clearTimeout(timeout);
				clearInShopLateMoveTimeout();
				const { countdownSound } = mySounds;
				if (typeof countdownSound !== "undefined" && countdownSound != null && countdownSound.isLoaded() == true) {
					countdownSound.stop();
				}
				_GA_log.actions["_" + _counter] =
					"Finished previous move if there were any; all player's cards are now disabled";
				_counter++;
				// end finish move

				if (
					typeof msg.slotID !== "undefined" &&
					typeof msg.move !== "undefined" &&
					typeof msg.move.type !== "undefined"
				) {
					switch (msg.move.type) {
						case "DOUBLE":
						case "NORMAL":
						case "START":
						case "SWITCH":
						case "SWAP":
							_GA_log.moveType = msg.move.type;
							if (msg.slotID == startedGame.playerSlot) {
								// current player's move
								_GA_log.actions["_" + _counter] = "Started to execute current player's move";
								_counter++;
								// check if the move is equal to the saved move
								var responseTo = socket.sentMsg.find(
									(m) => m.gameID == msg.gameID && m.moveID == msg.move.moveID && m.card == msg.cards[0]
								);
								if (responseTo != undefined && responseTo.type == "playerAct") {
									//NOT LATE MOVE
									const { selectedMove } = responseTo.moveToSave;
									if (selectedMove.type == "DOUBLE") {
										// the saved DOUBLE move was locally fully processed
										const isSameType = selectedMove.type == msg.move.type;
										const savedFirstMove = selectedMove.firstMove;
										const savedSecondMove = selectedMove.secondMove;
										const serverFirstMove = msg.move.firstMove;
										const serverSecondMove = msg.move.secondMove;
										if (isSameType == true) {
											// check if server processed the saved DOUBLE-move
											if (
												savedFirstMove.curPos == serverFirstMove.curPos &&
												savedFirstMove.newPos == serverFirstMove.newPos &&
												savedFirstMove.isSmash == serverFirstMove.isSmash &&
												savedFirstMove.returnPos == serverFirstMove.returnPos &&
												savedSecondMove.curPos == serverSecondMove.curPos &&
												savedSecondMove.newPos == serverSecondMove.newPos &&
												savedSecondMove.isSmash == serverSecondMove.isSmash &&
												savedSecondMove.returnPos == serverSecondMove.returnPos
											) {
												//server processed the saved move; request the next event's execution
												_GA_log.actions["_" + _counter] = "Corresponding move was locally fully processed";
												_counter++;
												dispatch(setNextSlotToAct());
												const { currentEvent } = EventQueue.shared;
												if (currentEvent !== null) {
													_GA_log.actions["_" + _counter] = "Requested next event's execution";
													_counter++;
													const { sMessageID, delay } = currentEvent;
													EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
													dispatch(resetGamePlayerActed());
												}
											} else {
												//server processed other move than the saved one
												_GA_log.actions["_" + _counter] = "NOT the corresponding move was locally fully processed";
												_counter++;
												// rollback fully the DOUBLE-move
												// rollback the firstMove
												const firstToRollback = {
													...savedFirstMove,
													type: "DOUBLE_FIRST",
												};
												_GA_log.actions["_" + _counter] = "Rollbacking  1st move: " + JSON.stringify(firstToRollback);
												_counter++;
												dispatch(rollbackMove(firstToRollback));
												// rollback the secondMove
												const secondToRollback = {
													...savedSecondMove,
													type: "DOUBLE_SECOND",
													card: selectedMove.cards[0],
												};
												_GA_log.actions["_" + _counter] = "Rollbacking 2nd move: " + JSON.stringify(secondToRollback);
												_counter++;
												dispatch(rollbackMove(secondToRollback));
												dispatch({
													type: GAME_PLAYER_ACTED_RECEIVED,
													payload: msg,
												});
												if (msg.move.type == "DOUBLE") {
													// if the server-processed a DOUBLE-move, then process a DOUBLE-move
													if (msg.waitForSecond_7 == undefined) {
														//first move
														_GA_log.actions["_" + _counter] = "Starting 1st move";
														_counter++;
														_GA_log.actions["_" + _counter] =
															"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
														_counter++;
														dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
														mySounds.cardsThrowSound.play();
														var _msg = cloneDeep(msg);
														_msg.waitForSecond_7 = true;
														MyWebSocket.shared.socketOnMessageHandler({
															data: JSON.stringify(_msg),
														});
														dispatch(movePawns(msg));
														dispatch(resetGamePlayerToAct());
													}
													if (msg.waitForSecond_7 != undefined && msg.waitForSecond_7 == true) {
														//second move
														_GA_log.actions["_" + _counter] = "Starting 2nd move";
														_counter++;
														dispatch(movePawns(msg));
													}
												} else {
													// if the server-processed a usual move, then process a usual move
													dispatch(movePawns(msg));
													_GA_log.actions["_" + _counter] =
														"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
													_counter++;
													dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
													mySounds.cardsThrowSound.play();
												}
											}
										} else {
											_GA_log.actions["_" + _counter] = "NOT the corresponding move was locally fully processed";
											_counter++;
											// rollback fully the DOUBLE-move
											// rollback the firstMove
											const firstToRollback = {
												...savedFirstMove,
												type: "DOUBLE_FIRST",
											};
											_GA_log.actions["_" + _counter] = "Rollbacking  1st move: " + JSON.stringify(firstToRollback);
											_counter++;
											dispatch(rollbackMove(firstToRollback));
											// rollback the secondMove
											const secondToRollback = {
												...savedSecondMove,
												type: "DOUBLE_SECOND",
												card: selectedMove.cards[0],
											};
											_GA_log.actions["_" + _counter] = "Rollbacking 2nd move: " + JSON.stringify(secondToRollback);
											_counter++;

											dispatch({
												type: GAME_PLAYER_ACTED_RECEIVED,
												payload: msg,
											});
											dispatch(rollbackMove(secondToRollback));
											if (msg.move.type == "DOUBLE") {
												// if the server-processed a DOUBLE-move, then process a DOUBLE-move
												if (msg.waitForSecond_7 == undefined) {
													//first move
													_GA_log.actions["_" + _counter] = "Starting 1st move";
													_counter++;
													_GA_log.actions["_" + _counter] =
														"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
													_counter++;
													dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
													mySounds.cardsThrowSound.play();
													var _msg = cloneDeep(msg);
													_msg.waitForSecond_7 = true;
													MyWebSocket.shared.socketOnMessageHandler({
														data: JSON.stringify(_msg),
													});
													dispatch(movePawns(msg));
													dispatch(resetGamePlayerToAct());
												}
												if (msg.waitForSecond_7 != undefined && msg.waitForSecond_7 == true) {
													//second move
													_GA_log.actions["_" + _counter] = "Starting 2nd move";
													_counter++;
													dispatch(movePawns(msg));
												}
											} else {
												// if the server-processed a usual move, then process a usual move
												dispatch(movePawns(msg));
												_GA_log.actions["_" + _counter] =
													"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
												_counter++;
												dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
												mySounds.cardsThrowSound.play();
											}
										}
									} else {
										// usual move
										if (selectedMove.curPos == msg.move.curPos && selectedMove.newPos == msg.move.newPos) {
											// the saved move was processed by the server; request the next event's execution if there are any
											dispatch(setNextSlotToAct());
											const { currentEvent } = EventQueue.shared;
											if (currentEvent !== null) {
												_GA_log.actions["_" + _counter] =
													"Corresponding usual move was locally processed; request next event's execution";
												_counter++;
												const { sMessageID, delay } = currentEvent;
												EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
												dispatch(resetGamePlayerActed());
											}
										} else {
											// not the saved move was processed by the server
											// rollback that move and process this one
											_GA_log.actions["_" + _counter] =
												"NOT the correspondinig usual move was locally processed rollback is required";
											_counter++;
											selectedMove.card = responseTo.card;
											_GA_log.actions["_" + _counter] = "Rollbacking move " + JSON.stringify(selectedMove);
											_counter++;
											dispatch(rollbackMove(selectedMove));
											dispatch({
												type: GAME_PLAYER_ACTED_RECEIVED,
												payload: msg,
											});
											if (msg.move.type == "DOUBLE") {
												// if the server-processed a DOUBLE-move, then process a DOUBLE-move
												if (msg.waitForSecond_7 == undefined) {
													//first move
													_GA_log.actions["_" + _counter] = "Starting 1st move";
													_counter++;
													_GA_log.actions["_" + _counter] =
														"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
													_counter++;
													dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
													mySounds.cardsThrowSound.play();
													var _msg = cloneDeep(msg);
													_msg.waitForSecond_7 = true;
													MyWebSocket.shared.socketOnMessageHandler({
														data: JSON.stringify(_msg),
													});
													dispatch(movePawns(msg));
													dispatch(resetGamePlayerToAct());
												}
												if (msg.waitForSecond_7 != undefined && msg.waitForSecond_7 == true) {
													//second move
													_GA_log.actions["_" + _counter] = "Starting 2nd move";
													_counter++;
													dispatch(movePawns(msg));
												}
											} else {
												dispatch(movePawns(msg));
												_GA_log.actions["_" + _counter] =
													"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
												_counter++;
												dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
												mySounds.cardsThrowSound.play();
											}
										}
									}
									if (msg.isPartialWin == true) {
										const { completeSound } = mySounds;
										completeSound.play();
									}
									dispatch(removeSavedEvent(responseTo.cMessageID));
								} else {
									//LATE MOVE
									_GA_log.actions["_" + _counter] = "Started to execute late move";
									_counter++;
									// check if there is any DOUBLE move started
									const { startedGame } = store.getState();
									if (startedGame.waitForSecond_7 == true && startedGame.gamePlayerToAct !== null) {
										// there is a DOUBLE MOVE IN PROGRESS
										_GA_log.actions["_" + _counter] = "There is a DOUBLE move started";
										_counter++;
										// define the firstMove which is in progress
										var firstMove = null;
										const { gamePlayerToAct } = startedGame;
										if (
											gamePlayerToAct !== null &&
											Array.isArray(gamePlayerToAct.availableMoves) &&
											gamePlayerToAct.availableMoves.length > 0
										) {
											firstMove = gamePlayerToAct.availableMoves[0].firstMove;
										}
										// rollback the firstMove
										const moveToRollback = {
											...firstMove,
											type: "DOUBLE_FIRST",
										};
										_GA_log.actions["_" + _counter] = "Rollbacking that 1st move" + JSON.stringify(firstMove);
										_counter++;
										dispatch(rollbackMove(moveToRollback));
									}
									dispatch({
										type: GAME_PLAYER_ACTED_RECEIVED,
										payload: msg,
									});
									// check if the new move is a DOUBLE-move
									if (msg.move.type == "DOUBLE") {
										// the new move is a DOUBLE-move
										// process the current player's DOUBLE MOVE
										if (msg.waitForSecond_7 == undefined) {
											//first move
											_GA_log.actions["_" + _counter] = "Starting 1st move";
											_counter++;
											_GA_log.actions["_" + _counter] =
												"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
											_counter++;
											dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
											mySounds.cardsThrowSound.play();
											var _msg = cloneDeep(msg);
											_msg.waitForSecond_7 = true;
											MyWebSocket.shared.socketOnMessageHandler({
												data: JSON.stringify(_msg),
											});
											dispatch(movePawns(msg));
											dispatch(resetGamePlayerToAct());
											const finalNr = Number(
												msg.move.firstMove.newPos.substr(msg.move.firstMove.newPos.startsWith("F"), 1)
											);
											if (
												msg.isPartialWin == true &&
												msg.move &&
												msg.move.firstMove &&
												typeof msg.move.firstMove.newPos === "string" &&
												msg.move.firstMove.newPos.startsWith("F") &&
												!isNaN(finalNr) &&
												finalNr % 4 == 0
											) {
												const { completeSound } = mySounds;
												completeSound.play();
											}
										}
										if (msg.waitForSecond_7 != undefined && msg.waitForSecond_7 == true) {
											//second move
											_GA_log.actions["_" + _counter] = "Starting 2nd move";
											_counter++;
											dispatch(movePawns(msg));
											const finalNr = Number(
												msg.move.secondMove.newPos.substr(msg.move.secondMove.newPos.startsWith("F"), 1)
											);
											if (
												msg.isPartialWin == true &&
												msg.move &&
												msg.move.secondMove &&
												typeof msg.move.secondMove.newPos === "string" &&
												msg.move.secondMove.newPos.startsWith("F") &&
												!isNaN(finalNr) &&
												finalNr % 4 == 0
											) {
												const { completeSound } = mySounds;
												completeSound.play();
											}
										}
									} else {
										// the new move is not a DOUBLE-move
										dispatch(movePawns(msg));
										_GA_log.actions["_" + _counter] =
											"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
										_counter++;
										dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
										mySounds.cardsThrowSound.play();
										if (msg.isPartialWin == true) {
											const { completeSound } = mySounds;
											completeSound.play();
										}
									}
								}
							} else {
								// other player's move
								_GA_log.actions["_" + _counter] = "Started to execute other player's move";
								_counter++;
								dispatch({ type: GAME_PLAYER_ACTED_RECEIVED, payload: msg });

								const { app, tab } = store.getState();
								const { isBackground, isSettingsOpened, isHelpOpened } = app;
								const { isShopOpen } = tab;

								if (msg.move.type == "DOUBLE") {
									// the new move is a DOUBLE-move
									// process the other player's DOUBLE MOVE
									_GA_log.actions["_" + _counter] = "Current movecount is: " + store.getState().startedGame.moveCnt;
									_counter++;
									if (store.getState().startedGame.moveCnt > 1) {
										_GA_log.actions["_" + _counter] =
											"Resetting the movecount; current is: " + store.getState().startedGame.moveCnt;
										_counter++;
										dispatch(resetMoveCount());
									}
									if (store.getState().startedGame.moveCnt == 0) {
										//first move
										_GA_log.actions["_" + _counter] = "Starting 1st move";
										_counter++;
										dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
										_GA_log.actions["_" + _counter] =
											"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
										_counter++;
										mySounds.cardsThrowSound.play();

										if (!isBackground && !isSettingsOpened && !isHelpOpened) {
											_GA_log.actions["_" + _counter] = {
												GA_message: isShopOpen ? "current player is in shop" : "current player is active",
												isBackground: isBackground,
												isSettingsOpened: isSettingsOpened,
												isHelpOpened: isHelpOpened,
												isShopOpen: isShopOpen,
											};
											_counter++;
											setTimeout(() => {
												msg.otherPlayersSecondMove = true;
												const _msg = cloneDeep(msg);
												if (
													_msg.isPartialWin == true &&
													_msg.move &&
													_msg.move.firstMove &&
													typeof _msg.move.firstMove.newPos === "string" &&
													_msg.move.firstMove.newPos.startsWith("F")
												) {
													_msg.isPartialWin = false;
												}
												//first move
												MyWebSocket.shared.socketOnMessageHandler({
													data: JSON.stringify(_msg),
												});
												dispatch(movePawns(msg));
											}, mySounds.cardsThrowSound.getDuration() * 1000);
										} else {
											_GA_log.actions["_" + _counter] = {
												GA_message: isShopOpen ? "current player is in shop" : "current player is active",
												isBackground: isBackground,
												isSettingsOpened: isSettingsOpened,
												isHelpOpened: isHelpOpened,
												isShopOpen: isShopOpen,
											};
											_counter++;
											msg.otherPlayersSecondMove = true;
											const _msg = cloneDeep(msg);
											if (
												_msg.isPartialWin == true &&
												_msg.move &&
												_msg.move.firstMove &&
												typeof _msg.move.firstMove.newPos === "string" &&
												_msg.move.firstMove.newPos.startsWith("F")
											) {
												_msg.isPartialWin = false;
											}
											//first move
											dispatch(movePawns(msg));
											if (isBackground || isShopOpen || isSettingsOpened || isHelpOpened) {
												_GA_log.actions["_" + _counter] = {
													GA_message: "Finished 1st move in action, requesting next event's execution",
												};
												_counter++;
												dispatch(increaseMoveCount());
												dispatch(setNextSlotToAct());
												if (isBackground) {
													const { currentEvent } = EventQueue.shared;
													if (currentEvent !== null) {
														const { sMessageID, delay } = currentEvent;
														EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
													}
												}
											}
											MyWebSocket.shared.socketOnMessageHandler({
												data: JSON.stringify(_msg),
											});
										}
									}
									if (store.getState().startedGame.moveCnt != 0) {
										//second move
										_GA_log.actions["_" + _counter] = "Starting 2nd move";
										_counter++;
										if (isBackground || isShopOpen || isSettingsOpened || isHelpOpened) {
											_GA_log.actions["_" + _counter] = {
												GA_message: "Finished 2nd move in action, requesting next event's execution",
											};
											_counter++;
											dispatch(increaseMoveCount());
											dispatch(setNextSlotToAct());
											if (isBackground) {
												const { currentEvent } = EventQueue.shared;
												if (currentEvent !== null) {
													const { sMessageID, delay } = currentEvent;
													EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
												}
											}
										}
										dispatch(movePawns(msg));
									}
								} else {
									// the new move is not a DOUBLE-move
									_GA_log.actions["_" + _counter] =
										"Card " + msg.cards[0] + " moved to played cards from the " + msg.slotID + " slot";
									_counter++;
									dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
									mySounds.cardsThrowSound.play();
									const { app, tab } = store.getState();
									const { isBackground, isSettingsOpened, isHelpOpened } = app;
									const { isShopOpen } = tab;
									if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
										setTimeout(() => {
											dispatch(movePawns(msg));
										}, mySounds.cardsThrowSound.getDuration() * 1000);
									} else {
										dispatch(movePawns(msg));
									}
								}
								if (msg.isPartialWin == true) {
									const { completeSound, moveNormalSound } = mySounds;
									var soundDuration = 800;
									soundDuration += moveNormalSound.getDuration() * 1000;
									soundDuration += mySounds.cardsThrowSound.getDuration() * 1000;
									const { app, tab } = store.getState();
									const { isSettingsOpened, isHelpOpened, isBackground } = app;
									const { isShopOpen } = tab;
									if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
										setTimeout(() => completeSound.play(), soundDuration + 100);
									} else {
										completeSound.play();
									}
								}
							}
							break;
						case "THROW":
							if (msg.slotID != startedGame.playerSlot) {
								_GA_log.actions["_" + _counter] = "Started to execute other player's THROW move";
								_counter++;
								// other player's THROW move
								dispatch({ type: GAME_PLAYER_ACTED_RECEIVED, payload: msg });
								_GA_log.actions["_" + _counter] =
									"Moved thrown cards: " + JSON.stringify(msg.cards) + " from PlayerCardsArea to PlayedCardsArea";
								_counter++;
								dispatch(moveThrownCardsToPlayedCards(msg.slotID, msg.cards));
								mySounds.cardsThrowAwaySound.play();
								const { app, tab } = store.getState();
								const { isBackground, isSettingsOpened, isHelpOpened } = app;
								const { isShopOpen } = tab;
								if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
									setTimeout(() => {
										dispatch(setNextSlotToAct());
										const { currentEvent } = EventQueue.shared;
										if (currentEvent !== null) {
											_GA_log.actions["_" + _counter] = "Requesting next event's execution";
											_counter++;
											const { sMessageID, delay } = currentEvent;
											EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
											dispatch(resetGamePlayerActed());
										}
									}, mySounds.cardsThrowAwaySound.getDuration() * 1000);
								} else {
									dispatch(setNextSlotToAct());
									const { currentEvent } = EventQueue.shared;
									if (currentEvent !== null) {
										_GA_log.actions["_" + _counter] = "Requesting next event's execution";
										_counter++;
										const { sMessageID, delay } = currentEvent;
										EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
										dispatch(resetGamePlayerActed());
									}
								}
							} else {
								_GA_log.actions["_" + _counter] = "Started to execute current player's THROW move";
								_counter++;
								// current player's THROW move
								var responseTo = socket.sentMsg.find(
									(m) => m.type == "playerAct" && m.gameID == msg.gameID && m.moveID == msg.move.moveID
								);
								if (responseTo != undefined) {
									if (responseTo.moveToSave != undefined && responseTo.moveToSave.selectedMove != undefined) {
										const { selectedMove } = responseTo.moveToSave;
										const _diff = difference(selectedMove.cards, msg.cards);
										if (_diff.length > 0) {
											// not the saved move was processed by the server
											// rollback that move and process this one
											_GA_log.actions["_" + _counter] =
												"Not the corresponding move was executed previously; that move is now rollbacked";
											_counter++;
											dispatch(rollbackMove(selectedMove));
											dispatch({
												type: GAME_PLAYER_ACTED_RECEIVED,
												payload: msg,
											});
											_GA_log.actions["_" + _counter] =
												"Moved thrown cards: " + JSON.stringify(msg.cards) + " from PlayerCardsArea to PlayedCardsArea";
											_counter++;
											dispatch(moveThrownCardsToPlayedCards(msg.slotID, msg.cards));
											mySounds.cardsThrowAwaySound.play();
											const { app, tab } = store.getState();
											const { isBackground, isSettingsOpened, isHelpOpened } = app;
											const { isShopOpen } = tab;
											if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
												setTimeout(() => {
													dispatch(setNextSlotToAct());
													const { currentEvent } = EventQueue.shared;
													if (currentEvent !== null) {
														_GA_log.actions["_" + _counter] = "Requesting next event's execution";
														_counter++;
														const { sMessageID, delay } = currentEvent;
														EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
														dispatch(resetGamePlayerActed());
													}
												}, mySounds.cardsThrowAwaySound.getDuration() * 1000);
											} else {
												dispatch(setNextSlotToAct());
												const { currentEvent } = EventQueue.shared;
												if (currentEvent !== null) {
													_GA_log.actions["_" + _counter] = "Requesting next event's execution";
													_counter++;
													const { sMessageID, delay } = currentEvent;
													EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
													dispatch(resetGamePlayerActed());
												}
											}
										} else {
											const { app, tab } = store.getState();
											const { isBackground, isSettingsOpened, isHelpOpened } = app;
											const { isShopOpen } = tab;
											if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
												setTimeout(() => {
													dispatch(setNextSlotToAct());
													const { currentEvent } = EventQueue.shared;
													if (currentEvent !== null) {
														_GA_log.actions["_" + _counter] = "Requesting next event's execution";
														_counter++;
														const { sMessageID, delay } = currentEvent;
														EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
														dispatch(resetGamePlayerActed());
													}
												}, (mySounds.cardsThrowAwaySound.getDuration() * 1000) / 2);
											} else {
												dispatch(setNextSlotToAct());
												const { currentEvent } = EventQueue.shared;
												if (currentEvent !== null) {
													_GA_log.actions["_" + _counter] = "Requesting next event's execution";
													_counter++;
													const { sMessageID, delay } = currentEvent;
													EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
													dispatch(resetGamePlayerActed());
												}
											}
										}
									} else {
										dispatch(setNextSlotToAct());
										const { currentEvent } = EventQueue.shared;
										if (currentEvent !== null) {
											_GA_log.actions["_" + _counter] = "Requesting next event's execution";
											_counter++;
											const { sMessageID, delay } = currentEvent;
											EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
											dispatch(resetGamePlayerActed());
										}
									}
									dispatch(removeSavedEvent(responseTo.cMessageID));
								} else {
									_GA_log.actions["_" + _counter] = "Started to execute late THROW move";
									_counter++;
									dispatch(setNextSlotToAct());
									dispatch({ type: GAME_PLAYER_ACTED_RECEIVED, payload: msg });
									_GA_log.actions["_" + _counter] =
										"Moved thrown cards: " + JSON.stringify(msg.cards) + " from PlayerCardsArea to PlayedCardsArea";
									_counter++;
									dispatch(moveThrownCardsToPlayedCards(msg.slotID, msg.cards));
									mySounds.cardsThrowAwaySound.play();
									const { app, tab } = store.getState();
									const { isBackground, isSettingsOpened, isHelpOpened } = app;
									const { isShopOpen } = tab;
									if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
										setTimeout(() => {
											const { currentEvent } = EventQueue.shared;
											if (currentEvent !== null) {
												_GA_log.actions["_" + _counter] = "Requesting next event's execution";
												_counter++;
												const { sMessageID, delay } = currentEvent;
												EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
												dispatch(resetGamePlayerActed());
											}
										}, mySounds.cardsThrowAwaySound.getDuration() * 1000);
									} else {
										const { currentEvent } = EventQueue.shared;
										if (currentEvent !== null) {
											_GA_log.actions["_" + _counter] = "Requesting next event's execution";
											_counter++;
											const { sMessageID, delay } = currentEvent;
											EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
											dispatch(resetGamePlayerActed());
										}
									}
								}
							}
							break;
						default:
							_GA_log.actions["_" + _counter] = "Unknow move.type; requested next event's execution";
							_counter++;
							dispatch(setNextSlotToAct());
							const { currentEvent } = EventQueue.shared;
							if (currentEvent !== null) {
								const { sMessageID, delay } = currentEvent;
								EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
								dispatch(resetGamePlayerActed());
							}
							break;
					}
				} else {
					dispatch(setNextSlotToAct());
					const { currentEvent } = EventQueue.shared;
					if (currentEvent !== null) {
						const { sMessageID, delay } = currentEvent;
						EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
						dispatch(resetGamePlayerActed());
					}
				}
			} else {
				const { currentEvent } = EventQueue.shared;
				if (currentEvent !== null) {
					const { sMessageID, delay } = currentEvent;
					EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
					dispatch(resetGamePlayerActed());
				}
			}
			await DebugLogger.shared.saveLog(_GA_log, true);
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const rollbackMove = (moveToRollback) => {
	return { type: ROLLBACK_MOVE, payload: { moveToRollback } };
};

export const processLocalMove = (msg) => {
	return (dispatch) => {
		try {
			const { startedGame } = store.getState();
			EventQueue.shared.startQueuedEvent(msg);
			if (startedGame.gameID == msg.gameID && msg.slotID == startedGame.playerSlot) {
				if (msg.move.type !== "THROW") {
					//finish move if there is any move in progress
					dispatch(resetPlayableCards());
					dispatch(resetPlayablePositions());
					dispatch(disableThrowAway());
					if (startedGame.waitForSecond_7 == false) {
						dispatch(finishCountdown());
						if (timeout) clearTimeout(timeout);
						dispatch(finishLateMoveInShop());
						clearInShopLateMoveTimeout();
						if (
							typeof mySounds.countdownSound !== "undefined" &&
							mySounds.countdownSound != null &&
							mySounds.countdownSound.isLoaded() == true
						) {
							mySounds.countdownSound.stop();
						}
					}
					// end finish move
					dispatch({ type: GAME_PLAYER_ACTED_RECEIVED, payload: msg });
					dispatch(movePawns(msg));
					if (msg.waitForSecond_7 == false) {
						dispatch(moveCardToPlayedCards(msg.slotID, msg.cards[0]));
						mySounds.cardsThrowSound.play();
					}
				} else {
					var _cards = [];
					startedGame.cards.forEach((card) => _cards.push(card.id));
					dispatch(moveThrownCardsToPlayedCards(msg.slotID, _cards));
					mySounds.cardsThrowAwaySound.play();
					const { app, tab } = store.getState();
					const { isBackground, isSettingsOpened, isHelpOpened } = app;
					const { isShopOpen } = tab;
					if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
						setTimeout(() => {
							const { currentEvent } = EventQueue.shared;
							if (currentEvent !== null) {
								const { sMessageID, delay } = currentEvent;
								EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
								dispatch(resetGamePlayerActed());
							}
						}, mySounds.cardsThrowAwaySound.getDuration() * 1000);
					} else {
						const { currentEvent } = EventQueue.shared;
						if (currentEvent !== null) {
							const { sMessageID, delay } = currentEvent;
							EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
							dispatch(resetGamePlayerActed());
						}
					}
				}
			} else {
				const { currentEvent } = EventQueue.shared;
				if (currentEvent !== null) {
					const { sMessageID, delay } = currentEvent;
					EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
				}
			}
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const resetGamePlayerActed = () => {
	return (dispatch) => {
		dispatch({ type: RESET_GAME_PLAYER_ACTED });
	};
};

export const gameEndReceived = (msg) => {
	return async (dispatch) => {
		try {
			const _GE_log = {
				GE_message: "gameEnd received",
				sMessageID: msg.sMessageID,
				gameID: msg.gameID,
			};
			await DebugLogger.shared.saveLog(_GE_log, true);
			const { countdownSound, completeSound } = mySounds;
			if (typeof countdownSound !== "undefined" && countdownSound != null && countdownSound.isLoaded() == true) {
				countdownSound.stop();
			}
			if (typeof completeSound !== "undefined" && completeSound != null && completeSound.isLoaded() == true) {
				completeSound.stop();
			}
			dispatch(resetPlayableCards());
			dispatch(resetPlayablePositions());
			dispatch(clearToLateTime());
			if (timeout) clearTimeout(timeout);
			dispatch(finishLateMoveInShop());
			clearInShopLateMoveTimeout();
			dispatch({ type: GAME_END_RECEIVED, payload: msg });
			if (!store.getState().startedGame.isSpectator) {
				var _finalScore = 0;
				if (
					msg &&
					typeof msg.baseScore === "number" &&
					typeof msg.durationBonus === "number" &&
					typeof msg.multiplyer === "number"
				) {
					_finalScore = (msg.baseScore + msg.durationBonus) * msg.multiplyer;
				}
				const stackMsg = {
					silver: msg.finalSilver,
					chips: msg.finalChips,
					score: _finalScore,
					finalLevel: msg.finalLevel,
				};
				dispatch(updateStack(stackMsg));
				dispatch(playGameEndSounds(msg));
				if (msg.levelUp == true) await Analytics.logEvent("levelUp");
			}
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const resetGameEnd = () => {
	return (dispatch) => {
		dispatch({ type: RESET_GAME_END });
	};
};

export const showDidYouKnow = () => {
	return (dispatch) => {
		dispatch({ type: SHOW_DID_YOU_KNOW });
	};
};

export const moveCardToPlayedCards = (slotID, cardID) => {
	return (dispatch) => {
		dispatch({ type: MOVE_CARD_TO_PLAYED_CARDS, payload: { slotID, cardID } });
	};
};

export const moveThrownCardsToPlayedCards = (slotID, cards) => {
	return (dispatch) => {
		dispatch(resetLastMoveEffect());
		dispatch({
			type: MOVE_THROWN_CARD_TO_PLAYED_CARDS,
			payload: { slotID, cards },
		});
	};
};

export const movePawns = (msg) => {
	return async (dispatch) => {
		try {
			if (Array.isArray(msg.cards) && msg.cards.length == 1) {
				dispatch(resetLastMoveEffect());
				dispatch({
					type: MOVE_PAWNS,
					payload: { move: msg.move, slotID: msg.slotID },
				});

				const { tab, sounds, startedGame, app } = store.getState();
				if (tab.isShopOpen || app.isSettingsOpened || app.isHelpOpened) {
					const { moveSmashSound, moveBackwardsSound, moveStartSound, moveNormalSound, completeSound, moveJackSound } =
						mySounds;
					var soundDuration = 0;
					switch (msg.move.type) {
						case "DOUBLE":
						case "NORMAL":
						case "START":
							if (msg.move.isSmash) {
								moveSmashSound.play();
								soundDuration = moveSmashSound.getDuration() * 1000;
							} else {
								if (msg.cards[0].startsWith("4")) {
									moveBackwardsSound.play();
									soundDuration = moveBackwardsSound.getDuration() * 1000;
								} else {
									if (msg.move.type === "START") {
										moveStartSound.play();
										soundDuration = moveStartSound.getDuration() * 1000;
									} else {
										moveNormalSound.play();
										soundDuration = moveNormalSound.getDuration() * 1000;
									}

									//check if it is completed
									var _move = msg.move;
									if (msg.move.type == "DOUBLE") {
										_move = startedGame.waitForSecond_7 == false ? msg.move.secondMove : msg.move.firstMove;
									}
									if (startedGame.teams && _move.newPos.toLowerCase().startsWith("f")) {
										// if (isCompleted(msg.slotID)) {
										/* if (msg.isPartialWin == true) {
                      soundDuration =
                        soundDuration +
                        completeSound.getDuration() * 1000 +
                        100;
                      const { app, tab } = store.getState();
                      const { isSettingsOpened, isHelpOpened } = app;
                      const { isShopOpen } = tab;
                      if (!isSettingsOpened && !isHelpOpened && !isShopOpen) {
                        setTimeout(function () {
                          completeSound.play();
                        }, soundDuration + 100);
                      } else {
                        completeSound.play();
                      }
                    } */
									}
								}
							}
							break;
						case "SWITCH":
						case "SWAP":
							soundDuration = moveJackSound.getDuration() * 1000;
							moveJackSound.play();
							break;
					}
					const { app, tab } = store.getState();
					const { isSettingsOpened, isHelpOpened } = app;
					const { isShopOpen } = tab;
					if (!isSettingsOpened && !isHelpOpened && !isShopOpen) {
						setTimeout(async () => {
							const { currentEvent } = EventQueue.shared;
							if (currentEvent !== null) {
								const { sMessageID, delay } = currentEvent;
								await DebugLogger.shared.saveLog(
									{
										MPA_message: "Finished move in 'movePawns' action, requesting next event's execution",
									},
									true
								);
								EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
							}
							dispatch(resetGamePlayerActed());
						}, soundDuration);
					} else {
						const { currentEvent } = EventQueue.shared;
						if (currentEvent !== null) {
							await DebugLogger.shared.saveLog(
								{
									MPA_message: "Finished move in 'movePawns' action, requesting next event's execution",
								},
								true
							);
							const { sMessageID, delay } = currentEvent;
							EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
						}
						dispatch(resetGamePlayerActed());
					}
				}
			} else {
				const { currentEvent } = EventQueue.shared;
				if (currentEvent !== null) {
					const { sMessageID, delay } = currentEvent;
					EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
				}
				dispatch(resetGamePlayerActed());
			}
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export function isCompleted(slotID, _boardPositions) {
	if (!Array.isArray(_boardPositions)) {
		const { gameBoard } = store.getState().startedGame;
		var boardPositions = gameBoard.boardPositions;
	} else {
		var boardPositions = _boardPositions;
	}
	const filledFinalPositions = boardPositions.filter(
		(bp) => bp.id.startsWith("f") && bp.slotID == slotID && bp.filled == true
	);

	if (filledFinalPositions.length == 4) return true;
	return false;
}

export const dealCards = () => {
	return (dispatch) => {
		try {
			const { isShuffled } = store.getState().startedGame;
			const { cardsShuffleSound } = mySounds;
			var shuffleTime = isShuffled ? cardsShuffleSound.getDuration() * 1000 + 100 : 100;
			if (isShuffled) cardsShuffleSound.play();
			const { app, tab } = store.getState();
			const { isBackground, isSettingsOpened, isHelpOpened } = app;
			const { isShopOpen } = tab;
			if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
				setTimeout(() => {
					const i = 0;
					dispatch(loop(i));
				}, shuffleTime);
			} else {
				//loop
				const i = 0;
				dispatch(loop(i));
			}
		} catch (error) {
			handleError(error);
			dispatch(resetPlayableCards());
			dispatch(endCardDeal());
		}
	};
};

var dealSoundDuration = 0;
function loop(i) {
	return async (dispatch) => {
		const { cards, isShuffled } = store.getState().startedGame;
		const { cardsDeal4Sound, cardsDeal5Sound } = mySounds;
		if (i < cards.length) {
			if (i == 0) {
				await DebugLogger.shared.saveLog({ deal_loop_message: "Dealing cards started" }, true);
				switch (cards.length) {
					case 4:
						if (
							typeof cardsDeal4Sound !== "undefined" &&
							cardsDeal4Sound != null &&
							cardsDeal4Sound.isLoaded() == true
						) {
							cardsDeal4Sound.play();
							dealSoundDuration = cardsDeal4Sound.getDuration() * 1000;
						}
						break;
					case 5:
						if (
							typeof cardsDeal5Sound !== "undefined" &&
							cardsDeal5Sound != null &&
							cardsDeal5Sound.isLoaded() == true
						) {
							cardsDeal5Sound.play();
							dealSoundDuration = cardsDeal5Sound.getDuration() * 1000;
						}
						break;
					default:
						break;
				}
				// if (DEBUG_ENABLED) {
				// console.log("STARTED DEALING AT: ", Date.now());
				// console.log("dealSoundDuration: ", dealSoundDuration);
				// }
			}
			const loopDelay = Platform.OS === "web" ? 350 : 250;
			dispatch({ type: DEAL_CARD, payload: i });
			i++;
			const { app, tab } = store.getState();
			const { isBackground, isSettingsOpened, isHelpOpened } = app;
			const { isShopOpen } = tab;
			if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
				setTimeout(() => {
					dispatch(loop(i));
				}, loopDelay);
			} else {
				dispatch(loop(i));
			}
		} else {
			const eventDuration = isShuffled ? /* dealSoundDuration + */ 350 : 50;
			const { app, tab } = store.getState();
			const { isBackground, isSettingsOpened, isHelpOpened } = app;
			const { isShopOpen } = tab;
			await DebugLogger.shared.saveLog(
				{
					deal_loop_message: "Player's all cards disabled",
				},
				true
			);
			dispatch(resetPlayableCards());
			await DebugLogger.shared.saveLog({ deal_loop_message: "Dealing cards - ended" }, true);
			if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
				setTimeout(() => {
					dispatch(endCardDeal());
				}, eventDuration);
			} else {
				dispatch(endCardDeal());
			}
		}
	};
}

const endCardDeal = () => {
	return (dispatch) => {
		DebugLogger.shared.saveLog(
			{
				endCardDeal_message: "Dealing cards - ended - Next event should be requested",
				currentlyExecuting: EventQueue.shared.currentEvent
					? cloneDeep(EventQueue.shared.currentEvent).sMessageID
					: EventQueue.shared.currentEvent,
				isDealInProgress: cloneDeep(EventQueue.shared.isDealInProgress),
				isEventInProgress: cloneDeep(EventQueue.shared.isEventInProgress),
			},
			true
		);
		// if (DEBUG_ENABLED) {
		// console.log("ENDED CARD_DEAL AT: ", Date.now());
		// }
		const { currentEvent } = EventQueue.shared;
		if (currentEvent !== null) {
			EventQueue.shared.isDealInProgress = false;
			const { sMessageID, delay } = currentEvent;
			EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
		} else {
			EventQueue.shared.isDealInProgress = false;
			EventQueue.shared.requestNextEventExecution(0, true, 0);
		}
		// const { app, tab } = store.getState();
		// const { isBackground, isSettingsOpened, isHelpOpened } = app;
		// const { isShopOpen } = tab;
		// if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
		//   setTimeout(() => {
		//     DebugLogger.shared.saveLog(
		//       {
		//         endCardDeal_message:
		//           "Dealing cards - ended - Next event should be requested",
		//         currentlyExecuting: EventQueue.shared.currentEvent
		//           ? cloneDeep(EventQueue.shared.currentEvent).sMessageID
		//           : EventQueue.shared.currentEvent,
		//         isDealInProgress: cloneDeep(EventQueue.shared.isDealInProgress),
		//         isEventInProgress: cloneDeep(EventQueue.shared.isEventInProgress),
		//       },
		//       true
		//     );
		//     const { currentEvent } = EventQueue.shared;
		//     if (currentEvent !== null) {
		//       EventQueue.shared.isDealInProgress = false;
		//       const { sMessageID, delay } = currentEvent;
		//       EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
		//     } else {
		//       EventQueue.shared.isDealInProgress = false;
		//       EventQueue.shared.requestNextEventExecution(0, true, 0);
		//     }
		//   }, 750);
		// } else {
		//   DebugLogger.shared.saveLog(
		//     {
		//       endCardDeal_message:
		//         "Dealing cards - ended - Next event should be requested",
		//       currentlyExecuting: EventQueue.shared.currentEvent
		//         ? cloneDeep(EventQueue.shared.currentEvent).sMessageID
		//         : EventQueue.shared.currentEvent,
		//       isDealInProgress: cloneDeep(EventQueue.shared.isDealInProgress),
		//       isEventInProgress: cloneDeep(EventQueue.shared.isEventInProgress),
		//     },
		//     true
		//   );
		//   const { currentEvent } = EventQueue.shared;
		//   if (currentEvent !== null) {
		//     EventQueue.shared.isDealInProgress = false;
		//     const { sMessageID, delay } = currentEvent;
		//     EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
		//   } else {
		//     EventQueue.shared.isDealInProgress = false;
		//     EventQueue.shared.requestNextEventExecution(0, true, 0);
		//   }
		// }
	};
};

export const resetLastMoveEffect = () => {
	return { type: RESET_LAST_MOVE_EFFECT };
};

export const resetPlayablePositions = () => {
	return (dispatch) => {
		dispatch({ type: RESET_PLAYABLE_POSITIONS });
	};
};

export const resetPlayableCards = () => {
	return (dispatch) => {
		dispatch({ type: RESET_PLAYABLE_CARDS });
	};
};

export const setPlayableCards = () => {
	return { type: SET_PLAYABLE_CARDS };
};

export const setPlayablePawns = () => {
	return { type: SET_PLAYABLE_PAWNS };
};

export const enableThrowAway = () => {
	return { type: ENABLE_THROW_AWAY };
};

export const disableThrowAway = () => {
	return { type: DISABLE_THROW_AWAY };
};

export const selectCard = (card, cardIndex) => {
	return (dispatch) => {
		batch(() => {
			dispatch(resetLastMoveEffect());
			dispatch({ type: SELECT_CARD, payload: { ...card, cardIndex } });
			dispatch(filterAvailableMoves(false));
		});
	};
};

export const selectPawn = (pawn) => {
	return (dispatch) => {
		try {
			var _newlog = {
				selPawnMess: "selectPawn initiated",
				positionParam: cloneDeep(pawn),
			};
			if (!pawn.targeted) {
				if (!pawn.selected) {
					_newlog.selPawn_det_1 = "not a targeted position";
					batch(() => {
						dispatch({ type: SELECT_PAWN, payload: pawn });
						dispatch(filterAvailableMoves(true));
					});
				}
			} else {
				_newlog.selPawn_det_1 = "targeted position";
				const { startedGame } = store.getState();
				const { gameBoard } = startedGame;
				var selPositions = gameBoard.boardPositions.filter((bp) => bp.selected && !bp.targeted && bp.filled);
				var _plPawns = [];
				if (
					typeof startedGame.gamePlayerToAct === "undefined" ||
					startedGame.gamePlayerToAct === null ||
					!Array.isArray(startedGame.gamePlayerToAct.availableMoves)
				) {
					return;
				}
				startedGame.gamePlayerToAct.availableMoves.forEach((move) => {
					var _move = move;
					if (move.type == "DOUBLE") {
						_move =
							typeof startedGame.gamePlayerToAct.waitForSecond_7 === "undefined" ||
							startedGame.gamePlayerToAct.waitForSecond_7 !== true
								? move.firstMove
								: move.secondMove;
					}
					const curPosID = typeof _move.curPos === "string" ? _move.curPos.toLowerCase() : _move.curPos;
					const _bp = gameBoard.boardPositions.find((bp) => bp.id == curPosID);

					if (_bp != undefined) {
						const found = _plPawns.find((p) => p.id == curPosID);
						if (found == undefined) {
							_plPawns.push(_bp.id);
						}
					}
				});
				if (
					pawn.filled == true &&
					selPositions.length > 0 &&
					pawn.color &&
					pawn.color.normal &&
					selPositions[0].color &&
					selPositions[0].color.normal &&
					pawn.color.normal == selPositions[0].color.normal &&
					_plPawns.includes(pawn.id)
				) {
					dispatch(resetPlayableCards());
					dispatch(setPlayableCards());
					dispatch({ type: SELECT_PAWN, payload: pawn });
					dispatch(filterAvailableMoves(true));
					dispatch(selectPawn(pawn));
				} else {
					dispatch({ type: SELECT_TARGETED_POSITION, payload: pawn });
					const { startedGame } = store.getState();
					if (store.getState().startedGame.canPlayerAct) {
						const card = startedGame.cards.find((c) => c.selected);
						if (card != undefined) {
							const cardIndex = startedGame.cards.findIndex((c) => c.selected);
							const _selectedMove = startedGame.gamePlayerToAct.availableMoves.find(
								(m) => m.moveID == startedGame.selectedMoveIdToAct
							);
							const moveToSave = {
								saveMove: true,
								selectedMove: { ..._selectedMove, cards: [card.id], cardIndex },
							};
							var msgGamePlayerAct = {
								sMessageID: 0,
								type: "playerAct",
								gameID: startedGame.gameID,
								moveID: startedGame.selectedMoveIdToAct,
								actID: startedGame.gamePlayerToAct.actID,
								card: card.id,
								toLate: false,
							};

							if (
								startedGame.waitForSecond_7 == false ||
								(startedGame.waitForSecond_7 == true &&
									startedGame.gamePlayerToAct != null &&
									startedGame.gamePlayerToAct.waitForSecond_7 != undefined)
							) {
								MyWebSocket.shared.sendMsg(msgGamePlayerAct, "", moveToSave);
							}
							/* if (startedGame.waitForSecond_7 == false) {
            MyWebSocket.shared.sendMsg(msgGamePlayerAct, "", moveToSave);
          } */
							dispatch(playCard(msgGamePlayerAct, moveToSave));
						}
					}
				}
			}
			setTimeout(async () => {
				await DebugLogger.shared.saveLog(_newlog, true);
			}, 5);
		} catch (error) {
			handleError(error);
		}
	};
};

export const playCard = (msgPlayerAct, moveToSave) => {
	return (dispatch) => {
		try {
			var _plCard_log = { plCardMess: "playCard initiated" };
			//execute the move immediately
			if (msgPlayerAct != undefined && moveToSave != undefined) {
				const { startedGame } = store.getState();
				var selPositions = startedGame.gameBoard.boardPositions.filter((bp) => bp.selected == true),
					selCurPos = selPositions.find((p) => p.selected == true && !p.targeted),
					selNewPos = selPositions.find((p) => p.selected == true && p.targeted == true);
				var msg = {
					slotID: startedGame.playerSlot,
					cards: [msgPlayerAct.card],
					move: moveToSave.selectedMove,
					gameID: msgPlayerAct.gameID,
					processInQueue: true,
					delay: 0,
					type: "localMove",
					sMessageID: 0,
					waitForSecond_7:
						startedGame.waitForSecond_7 == true &&
						startedGame.gamePlayerToAct != null &&
						startedGame.gamePlayerToAct.waitForSecond_7 == undefined, //startedGame.waitForSecond_7,
				};
				MyWebSocket.shared.socketOnMessageHandler({
					data: JSON.stringify(msg),
				});
				_plCard_log.selectedCard = msgPlayerAct.card;

				if (
					startedGame.waitForSecond_7 == true &&
					startedGame.gamePlayerToAct != null &&
					startedGame.gamePlayerToAct.waitForSecond_7 == undefined
				) {
					// SECOND MOVE START
					const { startedGame } = store.getState();
					var msg = cloneDeep(startedGame.gamePlayerToAct);
					var remainingMoves = [];
					startedGame.gamePlayerToAct.availableMoves.forEach((move) => {
						if (move.type == "DOUBLE") {
							const { firstMove, secondMove } = move;
							if (firstMove.curPos.toLowerCase() == selCurPos.id && firstMove.newPos.toLowerCase() == selNewPos.id) {
								remainingMoves.push(move);
							}
						}
					});
					if (remainingMoves.length > 0) {
						msg.availableMoves = remainingMoves;
						// const _toLateTime = Number(startedGame.toLateTime);
						/* msg.timeToAct =
          startedGame.toLateTime == "" || isNaN(_toLateTime) || _toLateTime <= 0
            ? 0
            : _toLateTime - 1; */
						msg.waitForSecond_7 = true;
						msg.card = moveToSave.selectedMove.cards[0];
						_plCard_log.selectedCard = moveToSave.selectedMove.cards[0];
						msg.cardIndex = moveToSave.selectedMove.cardIndex;
						// show the next available moves
						MyWebSocket.shared.socketOnMessageHandler({
							data: JSON.stringify(msg),
						});
					}
					dispatch({ type: PLAY_CARD });
				} else {
					//FINISH FIRST MOVE or other move
					dispatch(finishMove(true));
					dispatch({ type: PLAY_CARD });
				}
			}
			setTimeout(async () => {
				await DebugLogger.shared.saveLog(_plCard_log, true);
			}, 100);
		} catch (error) {
			handleError(error);
		}
	};
};

export const finishMove = (isLocalMove = false) => {
	return (dispatch) => {
		try {
			dispatch(resetPlayableCards());
			dispatch(resetPlayablePositions());
			const { startedGame } = store.getState();
			if (
				startedGame.waitForSecond_7 == false ||
				(startedGame.waitForSecond_7 == true &&
					startedGame.gamePlayerToAct != null &&
					startedGame.gamePlayerToAct.waitForSecond_7 == undefined)
			) {
				dispatch(resetGamePlayerToAct());
			}
			dispatch(disableThrowAway());
			dispatch(finishCountdown());
			if (timeout) clearTimeout(timeout);
			dispatch(finishLateMoveInShop());
			clearInShopLateMoveTimeout();
			const { countdownSound } = mySounds;
			if (typeof countdownSound !== "undefined" && countdownSound != null && countdownSound.isLoaded() == true) {
				countdownSound.stop();
			}
			if (!isLocalMove) {
				const { currentEvent } = EventQueue.shared;
				if (currentEvent !== null) {
					const { sMessageID, delay } = currentEvent;
					EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
				}
			}
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const throwAway = (msgPlayerAct, moveToSave) => {
	return (dispatch) => {
		setTimeout(async () => {
			await DebugLogger.shared.saveLog({ throw_a_message: "throwAway initiated" }, true);
		}, 50);
		dispatch({ type: THROW_AWAY });
		dispatch(finishMove());
		//execute the move immediately
		if (msgPlayerAct != undefined && moveToSave != undefined) {
			var msg = {
				slotID: store.getState().startedGame.playerSlot,
				cards: [msgPlayerAct.card],
				move: moveToSave.selectedMove,
				gameID: msgPlayerAct.gameID,
				processInQueue: true,
				delay: 0,
				type: "localMove",
				sMessageID: 0,
			};
			MyWebSocket.shared.socketOnMessageHandler({ data: JSON.stringify(msg) });
		}
	};
};

export const sendGameMoveExpired = () => {
	return (dispatch) => {
		try {
			const { startedGame } = store.getState();
			if (startedGame.gamePlayerToAct && typeof startedGame.gamePlayerToAct.actID === "number") {
				var msgToLate = {
					type: "playerActExpired",
					sMessageID: 0,
					gameID: startedGame.gameID,
					actID: startedGame.gamePlayerToAct.actID,
				};
				/* if (startedGame.isInShopLateMove) {
          msgToLate.reason = "inShop";
        } else {
          msgToLate.reason = "toLate";
        } */
				MyWebSocket.shared.sendMsg(msgToLate);
			}
			dispatch(finishMove());
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const updatePawns = () => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PAWNS });
	};
};

export const setBoardCoordinates = () => {
	const coord = store.getState().fetchData.boardCoordinates.data;
	return { type: SET_BOARD_COORDINATES, payload: coord };
};

export const saveStartPositionsDirections = (verticalStartPositions) => {
	const { isGameStarted } = store.getState().tab;
	return (dispatch) => {
		if (isGameStarted) {
			dispatch({
				type: SAVE_START_POSITIONS_DIRECTION,
				payload: verticalStartPositions,
			});
		} else {
			dispatch({ type: CLEAR_START_POSITIONS_DIRECTION });
		}
	};
};

export const resetStartedGame = () => {
	return { type: RESET_STARTED_GAME };
};

export const filterAvailableMoves = (isPawnSelected) => {
	return (dispatch) => {
		dispatch({ type: FILTER_AVAILABLE_MOVES, payload: { isPawnSelected } });
	};
};

export const setToLateTime = (time) => {
	return { type: SET_TO_LATE_TIME, payload: time };
};

export const clearToLateTime = () => {
	return { type: CLEAR_TO_LATE_TIME };
};

export var timeout = null;
var countDownStartedTime = Date.now();
var _countDownLog;
export const startCountdown = (msg) => {
	return (dispatch) => {
		try {
			timeout = null;
			if (timeout) clearTimeout(timeout);
			dispatch(_startCountdownTimer(msg.timeToAct, msg.startWarningTimer, 1000, msg.receivedAt));
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const finishCountdown = () => {
	return (dispatch) => {
		try {
			if (_countDownLog) {
				setTimeout(async () => {
					await DebugLogger.shared.saveLog(_countDownLog, true);
					_countDownLog = undefined;
				}, 50);
			}
			if (timeout) clearTimeout(timeout);
			const { countdownSound } = mySounds;
			if (typeof countdownSound !== "undefined" && countdownSound != null && countdownSound.isLoaded() == true) {
				countdownSound.stop();
			}
			dispatch({ type: FINISH_COUNTDOWN });
			dispatch(clearToLateTime());
			dispatch(resetWarningTimer());
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const setWarningTimer = () => {
	return { type: SET_WARNING_TIMER };
};

export const resetWarningTimer = () => {
	return { type: RESET_WARNING_TIMER };
};

export const setMoveCount = (newValue) => {
	return { type: SET_MOVE_COUNT, payload: newValue };
};

export const increaseMoveCount = () => {
	return { type: INCREASE_MOVE_COUNT };
};

export const resetMoveCount = () => {
	return { type: RESET_MOVE_COUNT };
};

export const startLateMoveInShop = () => {
	return { type: START_LATE_MOVE_IN_SHOP };
};

export const finishLateMoveInShop = () => {
	return { type: FINISH_LATE_MOVE_IN_SHOP };
};
const setPlayerPanelsColor = (colors) => {
	return { type: SET_PLAYER_PANELS_COLOR, payload: colors };
};

const setBoardPositionsBaseColor = () => {
	return { type: SET_BOARD_POSITIONS_BASE_COLOR };
};

const _gameStartedReceived = (msg) => {
	const currentPlayersUsername = store.getState().currentUser.userDetails.username;
	const cardsetURL = store.getState().app.welcome.webResources.cardsets;
	const resolution = store.getState().currentUser.preferences.resolution;
	return {
		type: GAME_STARTED_RECEIVED,
		payload: { ...msg, currentPlayersUsername, cardsetURL, resolution },
	};
};

const _startCountdownTimer = (timen, warningTime, pause, receivedAt) => {
	return async (dispatch) => {
		try {
			countDownStartedTime = typeof receivedAt !== "undefined" ? receivedAt - 500 : Date.now();
			var ellapsedBeforeStart = Date.now() - countDownStartedTime;
			var x = ellapsedBeforeStart % 1000;
			ellapsedBeforeStart += x;
			var remainingTime = timen - Math.floor(ellapsedBeforeStart / 1000);
			if (!_countDownLog) {
				_countDownLog = { countdown_message_1: "Starting countdown" };
				_countDownLog.eventReceivedAt = receivedAt;
				_countDownLog.ellapsedBeforeStart = ellapsedBeforeStart;
				_countDownLog.remainingTime = remainingTime;
			}
			const { tab, startedGame, sounds } = store.getState();
			const { countdownSound } = mySounds;
			if (remainingTime <= 0) {
				const { startedGame, tab, sounds } = store.getState();
				if (_countDownLog) _countDownLog.message_4 = "Finished countdown";
				dispatch(finishCountdown());
				dispatch(finishLateMoveInShop());
				countdownSound.stop();
				if (
					!tab.isGameEndOpened &&
					typeof startedGame.gamePlayerToAct !== "undefined" &&
					startedGame.gamePlayerToAct !== null
				) {
					if (_countDownLog) _countDownLog.message_5 = "Sending GameMoveExpired event";
					dispatch(sendGameMoveExpired());
					dispatch(resetPlayableCards());
					dispatch(resetPlayablePositions());
				}
			} else {
				if (timeout) clearTimeout(timeout);
				timeout = setTimeout(function () {
					dispatch(_startCountdownTimer(timen, warningTime, pause, receivedAt));
				}, pause);
			}
			dispatch(setToLateTime(remainingTime));
			if (remainingTime == warningTime) {
				if (_countDownLog) _countDownLog.message_2 = "Displayed the time left to move";
				dispatch({ type: START_COUNTDOWN });
				countdownSound.setVolume(0);
				countdownSound.play();
			}
			if (remainingTime <= warningTime) {
				//if sound is not playing start again playing it
				if (!countdownSound.isPlaying()) countdownSound.play();

				//start the warning method
				dispatch(setWarningTimer());
				if (_countDownLog) _countDownLog.message_3 = "Start warning the player";

				const _currVolume = Platform.OS === "web" ? countdownSound.getVolume() : await countdownSound.getVolume();

				if (!sounds.isMuted && typeof countdownSound != "undefined" && countdownSound != null && _currVolume < 1) {
					switch (remainingTime) {
						case Math.round(warningTime * 0.9):
							countdownSound.setVolume(0.1);
							break;
						case Math.round(warningTime * 0.8):
							countdownSound.setVolume(0.2);
							break;
						case Math.round(warningTime * 0.7):
							countdownSound.setVolume(0.4);
							break;
						case Math.round(warningTime * 0.6):
							countdownSound.setVolume(0.6);
							break;
						case Math.round(warningTime * 0.5):
							countdownSound.setVolume(1);
							break;
					}
					if (
						sounds.isMuted /* ||
            (tab.isShopOpen && startedGame.isInShopLateMove != null) */
					)
						countdownSound.setVolume(0);
				}
			}
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};

export const clearPlayedCards = () => {
	return { type: CLEAR_PLAYED_CARDS };
};

const _gameInfoReceived = (msg) => {
	return { type: GAME_INFO_RECEIVED, payload: msg };
};

const _gamePlayerToActReceived = (msg) => {
	return { type: GAME_PLAYER_TO_ACT_RECEIVED, payload: msg };
};

const playGameEndSounds = (msg) => {
	return (dispatch) => {
		try {
			const { levelUpSound, gameWonSound, scoreIncreaseSound, gameLostSound } = mySounds;
			if (msg.playerWon) {
				if (typeof gameWonSound !== "undefined" && gameWonSound != null && gameWonSound.isLoaded() == true)
					gameWonSound.play();
				if (msg.levelUp) {
					const { app, tab } = store.getState();
					const { isBackground, isSettingsOpened, isHelpOpened } = app;
					const { isShopOpen } = tab;
					if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
						setTimeout(() => {
							if (typeof levelUpSound !== "undefined" && levelUpSound != null && levelUpSound.isLoaded() == true)
								levelUpSound.play();
							const { app, tab } = store.getState();
							const { isBackground, isSettingsOpened, isHelpOpened } = app;
							const { isShopOpen } = tab;
							if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
								setTimeout(() => {
									const { currentEvent } = EventQueue.shared;
									if (currentEvent !== null) {
										const { sMessageID, delay } = currentEvent;
										EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
									}
								}, levelUpSound.getDuration() * 1000);
							} else {
								const { currentEvent } = EventQueue.shared;
								if (currentEvent !== null) {
									const { sMessageID, delay } = currentEvent;
									EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
								}
							}
						}, gameWonSound.getDuration() * 1000);
					} else {
						if (typeof levelUpSound !== "undefined" && levelUpSound != null && levelUpSound.isLoaded() == true)
							levelUpSound.play();
						const { currentEvent } = EventQueue.shared;
						if (currentEvent !== null) {
							const { sMessageID, delay } = currentEvent;
							EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
						}
					}
				} else {
					if (msg.scoreUp) {
						const { app, tab } = store.getState();
						const { isBackground, isSettingsOpened, isHelpOpened } = app;
						const { isShopOpen } = tab;
						if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
							setTimeout(() => {
								if (
									typeof scoreIncreaseSound !== "undefined" &&
									scoreIncreaseSound != null &&
									scoreIncreaseSound.isLoaded() == true
								)
									scoreIncreaseSound.play();
								const { app, tab } = store.getState();
								const { isBackground, isSettingsOpened, isHelpOpened } = app;
								const { isShopOpen } = tab;
								if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
									setTimeout(function () {
										const { currentEvent } = EventQueue.shared;
										if (currentEvent !== null) {
											const { sMessageID, delay } = currentEvent;
											EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
										}
									}, scoreIncreaseSound.getDuration() * 1000);
								} else {
									const { currentEvent } = EventQueue.shared;
									if (currentEvent !== null) {
										const { sMessageID, delay } = currentEvent;
										EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
									}
								}
							}, gameWonSound.getDuration() * 1000);
						} else {
							if (
								typeof scoreIncreaseSound !== "undefined" &&
								scoreIncreaseSound != null &&
								scoreIncreaseSound.isLoaded() == true
							)
								scoreIncreaseSound.play();
							const { currentEvent } = EventQueue.shared;
							if (currentEvent !== null) {
								const { sMessageID, delay } = currentEvent;
								EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
							}
						}
					} else {
						const { app, tab } = store.getState();
						const { isBackground, isSettingsOpened, isHelpOpened } = app;
						const { isShopOpen } = tab;
						if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
							setTimeout(() => {
								const { currentEvent } = EventQueue.shared;
								if (currentEvent !== null) {
									const { sMessageID, delay } = currentEvent;
									EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
								}
							}, gameWonSound.getDuration() * 1000);
						} else {
							const { currentEvent } = EventQueue.shared;
							if (currentEvent !== null) {
								const { sMessageID, delay } = currentEvent;
								EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
							}
						}
					}
				}
			} else {
				if (typeof gameLostSound !== "undefined" && gameLostSound != null && gameLostSound.isLoaded() == true)
					gameLostSound.play();
				if (msg.levelUp) {
					const { app, tab } = store.getState();
					const { isBackground, isSettingsOpened, isHelpOpened } = app;
					const { isShopOpen } = tab;
					if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
						setTimeout(() => {
							if (typeof levelUpSound !== "undefined" && levelUpSound != null && levelUpSound.isLoaded() == true)
								levelUpSound.play();
							const { app, tab } = store.getState();
							const { isBackground, isSettingsOpened, isHelpOpened } = app;
							const { isShopOpen } = tab;
							if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
								setTimeout(() => {
									const { currentEvent } = EventQueue.shared;
									if (currentEvent !== null) {
										const { sMessageID, delay } = currentEvent;
										EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
									}
								}, levelUpSound.getDuration() * 1000);
							} else {
								const { currentEvent } = EventQueue.shared;
								if (currentEvent !== null) {
									const { sMessageID, delay } = currentEvent;
									EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
								}
							}
						}, gameLostSound.getDuration() * 1000);
					} else {
						if (typeof levelUpSound !== "undefined" && levelUpSound != null && levelUpSound.isLoaded() == true)
							levelUpSound.play();
						const { currentEvent } = EventQueue.shared;
						if (currentEvent !== null) {
							const { sMessageID, delay } = currentEvent;
							EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
						}
					}
				} else {
					if (msg.scoreUp) {
						const { app, tab } = store.getState();
						const { isBackground, isSettingsOpened, isHelpOpened } = app;
						const { isShopOpen } = tab;
						if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
							setTimeout(() => {
								if (
									typeof scoreIncreaseSound !== "undefined" &&
									scoreIncreaseSound != null &&
									scoreIncreaseSound.isLoaded() == true
								)
									scoreIncreaseSound.play();
								const { app, tab } = store.getState();
								const { isBackground, isSettingsOpened, isHelpOpened } = app;
								const { isShopOpen } = tab;
								if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
									setTimeout(() => {
										const { currentEvent } = EventQueue.shared;
										if (currentEvent !== null) {
											const { sMessageID, delay } = currentEvent;
											EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
										}
									}, scoreIncreaseSound.getDuration() * 1000);
								} else {
									const { currentEvent } = EventQueue.shared;
									if (currentEvent !== null) {
										const { sMessageID, delay } = currentEvent;
										EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
									}
								}
							}, gameLostSound.getDuration() * 1000);
						} else {
							if (
								typeof scoreIncreaseSound !== "undefined" &&
								scoreIncreaseSound != null &&
								scoreIncreaseSound.isLoaded() == true
							)
								scoreIncreaseSound.play();
							const { currentEvent } = EventQueue.shared;
							if (currentEvent !== null) {
								const { sMessageID, delay } = currentEvent;
								EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
							}
						}
					} else {
						const { app, tab } = store.getState();
						const { isBackground, isSettingsOpened, isHelpOpened } = app;
						const { isShopOpen } = tab;
						if (!isBackground && !isSettingsOpened && !isHelpOpened && !isShopOpen) {
							setTimeout(() => {
								const { currentEvent } = EventQueue.shared;
								if (currentEvent !== null) {
									const { sMessageID, delay } = currentEvent;
									EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
								}
							}, gameLostSound.getDuration() * 1000);
						} else {
							const { currentEvent } = EventQueue.shared;
							if (currentEvent !== null) {
								const { sMessageID, delay } = currentEvent;
								EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
							}
						}
					}
				}
			}
		} catch (error) {
			handleError(error);
			const { currentEvent } = EventQueue.shared;
			if (currentEvent !== null) {
				const { sMessageID, delay } = currentEvent;
				EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
			}
		}
	};
};
