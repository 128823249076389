import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { RESOLUTION } from "../../config/defaults";
import { MENU_BACK_ICON, MENU_CLOSE_ICON, MENU_NEGATIVE_BACK_ICON } from "../../config/images";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

const NavigationHeader = (props) => {
	const { isBackNegative, isBackEnabled } = props;
	var _iconLayout = styles.iconLayout;
	if (props.resolution === RESOLUTION.HIGH) {
		styles.iconImageSize = { width: 60, height: 60 };
	} else {
		styles.iconImageSize = { width: 40, height: 40 };
		_iconLayout = [_iconLayout, { width: 50 }];
	}
	return (
		<View
			style={[
				styles.headerStyle,
				{
					justifyContent: isBackEnabled == false ? "flex-end" : "space-between",
				},
			]}
		>
			{isBackEnabled !== false && (
				<AppTouchableOpacity
					onPress={props.backAction}
					activeOpacity={0.5}
					style={[
						styles.leftIcon,
						_iconLayout,
						{
							paddingTop: props.resolution === RESOLUTION.HIGH ? 20 : 10,
							paddingLeft: props.resolution === RESOLUTION.HIGH ? 0 : 10,
						},
					]}
					accessibilityLabel={props.backAccessibilityLabel}
				>
					<Image
						source={isBackNegative ? MENU_NEGATIVE_BACK_ICON : MENU_BACK_ICON}
						resizeMode="stretch"
						style={styles.iconImageSize}
					/>
				</AppTouchableOpacity>
			)}
			{props.children}
			<AppTouchableOpacity
				onPress={props.closeAction}
				activeOpacity={0.5}
				style={[
					styles.rightIcon,
					_iconLayout,
					{
						paddingTop: props.resolution === RESOLUTION.HIGH ? 20 : 10,
						paddingRight: props.resolution === RESOLUTION.HIGH ? 0 : 10,
					},
				]}
				accessibilityLabel={props.closeAccessibilityLabel}
			>
				<Image source={MENU_CLOSE_ICON} resizeMode="stretch" style={styles.iconImageSize} />
			</AppTouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	headerStyle: {
		flexDirection: "row",
		width: "100%",
		position: "absolute",
		top: 0,
		left: 0,
	},
	iconImageSize: { width: 40, height: 40 },
	iconLayout: {
		width: 80,
		height: 60,
		paddingTop: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	rightIcon: { paddingLeft: 10, right: 0 },
	leftIcon: { paddingRight: 10, left: 0 },
});

export { NavigationHeader };
