import cloneDeep from "lodash/cloneDeep";
import { Dimensions, PixelRatio, Platform } from "react-native";
import { handleError } from "../components/ErrorHandler";
import { CONTENT_URL, FLAG_IMG_SET, POKER_CHIPS_SET, RANKING_SET } from "../config/connection";
import { maxExistingUserRating } from "../config/defaults";
import { COMP_EASY, COMP_HARD, COMP_MEDIUM, COMP_SUPER_EASY, COMP_VERY_HARD } from "../config/images";
import { DEBUG_ENABLED } from "../config/permissions";
import { mySounds } from "../config/sounds";
import { pips } from "../Keezers/config/defaults";

export function getURLParameter(sParam) {
	if (Platform.OS !== "web") return false;
	sParam = typeof sParam !== "string" ? "" : sParam;
	var sPageURL = window.location.search.substring(1);
	var sURLVariables = sPageURL.split("?").pop().split("&");
	for (var i = 0; i < sURLVariables.length; i++) {
		var sParameterName = sURLVariables[i].split("=");
		if (sParameterName[0].toLowerCase() == sParam.toLowerCase()) {
			return sParameterName[1].split("#!").shift().toLowerCase();
		}
	}
	return false;
}

export function create_UUID() {
	var dt = new Date().getTime();
	var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (dt + Math.random() * 16) % 16 | 0;
		dt = Math.floor(dt / 16);
		return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
}

export function getScreenSizeInInch() {
	try {
		const screenDim = Dimensions.get("screen");
		const dpi = Platform.OS === "web" ? window.devicePixelRatio : PixelRatio.get();
		const realScreenWidth = Platform.OS === "web" ? screenDim.width : screenDim.width * dpi;
		const realScreenHeight = Platform.OS === "web" ? screenDim.height : screenDim.height * dpi;
		const pixels_in_inch = Platform.OS === "web" ? 96 * dpi : 150 * dpi;
		const screenSizeInPixels = Math.sqrt(Math.pow(realScreenWidth, 2) + Math.pow(realScreenHeight, 2));
		var screenSizeInInch = Math.round(screenSizeInPixels / pixels_in_inch);
		screenSizeInInch = screenSizeInInch == undefined ? 6 : screenSizeInInch;
		return screenSizeInInch;
	} catch (error) {
		handleError(error);
	}
}

export function emojiToUnicode(message) {
	try {
		var emojiRegexp =
			/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g;
		if (!message) return;
		try {
			var newMessage = message.match(emojiRegexp);
			for (var emoj in newMessage) {
				var emojmessage = newMessage[emoj];
				var index = message.indexOf(emojmessage);
				if (index === -1) continue;
				emojmessage = "\\u" + emojmessage.charCodeAt(0).toString(16) + "\\u" + emojmessage.charCodeAt(1).toString(16);
				message = message.substr(0, index) + emojmessage + message.substr(index + 2);
			}
			return message;
		} catch (err) {
			if (DEBUG_ENABLED) console.error("error in emojiToUnicode" + err.stack);
		}
	} catch (error) {
		handleError(error);
	}
}

export function emojiUnicode(emoji) {
	try {
		var comp;
		if (emoji.length === 1) {
			comp = emoji.charCodeAt(0);
		}
		comp = (emoji.charCodeAt(0) - 0xd800) * 0x400 + (emoji.charCodeAt(1) - 0xdc00) + 0x10000;
		if (comp < 0) {
			comp = emoji.charCodeAt(0);
		}
		return comp.toString("16");
	} catch (error) {
		handleError(error);
	}
}

export function mapObject(object, callback) {
	try {
		return Object.keys(object).map(function (key, index) {
			return callback(key, object[key], index, Object.keys(object).length);
		});
	} catch (error) {
		handleError(error);
	}
}

export function sortObjectArray(array, sortKey, isAscending) {
	try {
		const results = array.sort((rowDataA, rowDataB) => {
			const a = rowDataA[sortKey];
			const b = rowDataB[sortKey];
			if (typeof a === "string" && typeof b === "string") {
				// If strings, sort case-insensitive
				return a.toLowerCase().localeCompare(b.toLowerCase());
			}
			// If any other type of object, e.g. a number, just use the natural ordering
			if (a < b) return -1;
			if (a > b) return 1;
			return 0;
		});
		if (!isAscending) return results.reverse();
		return results;
	} catch (error) {
		handleError(error);
	}
}

export function filterObjectArray(array, filterKey, filterTerm) {
	try {
		const lowerCaseFilterTerm = filterTerm.toLowerCase();
		return array.filter(
			(rowData) => rowData[filterKey] && rowData[filterKey].toLowerCase().startsWith(lowerCaseFilterTerm)
		);
	} catch (error) {
		handleError(error);
	}
}

export function getUserDetailsFromAuthenticatedMessage(myJSON) {
	try {
		var userDetails = {};
		if (typeof myJSON !== "undefined" && myJSON.type == "userDetails") {
			userDetails = {
				username: typeof myJSON.username === "string" ? myJSON.username : "",
				userID: typeof myJSON.userID !== "undefined" ? myJSON.userID : "0",
				firstName: typeof myJSON.firstName === "string" ? myJSON.firstName : "",
				lastName: typeof myJSON.lastName === "string" ? myJSON.lastName : "",
				country: typeof myJSON.country === "string" ? myJSON.country : "",
				gender: typeof myJSON.gender === "string" ? myJSON.gender : "",
				born: typeof myJSON.born === "number" ? myJSON.born : 0,
				city: typeof myJSON.city === "string" ? myJSON.city : "",
				wins: typeof myJSON.wins === "number" ? myJSON.wins : 0,
				losses: typeof myJSON.losses === "number" ? myJSON.losses : 0,
				disconnects: typeof myJSON.disconnects === "number" ? myJSON.disconnects : 0,
				ranking: typeof myJSON.level === "number" ? myJSON.level : 0,
				points: typeof myJSON.points === "number" ? myJSON.points : 0,
				score: typeof myJSON.score === "number" ? myJSON.score : 0,
				gamesPlayed: typeof myJSON.gamesPlayed === "number" ? myJSON.gamesPlayed : 0,
				languages: typeof myJSON.languages === "string" ? myJSON.languages : "",
				friendList:
					typeof myJSON.friendList !== "undefined" && Array.isArray(myJSON.friendList) ? myJSON.friendList : [],
				banList: typeof myJSON.banList !== "undefined" && Array.isArray(myJSON.banList) ? myJSON.banList : [],
				colors: typeof myJSON.colors !== "undefined" && Array.isArray(myJSON.colors) ? myJSON.colors : [],
				emojis: typeof myJSON.emojis !== "undefined" ? myJSON.emojis : {},
				levelProgress: typeof myJSON.levelProgress === "number" ? myJSON.levelProgress : 0,
				stack: {
					gold: typeof myJSON.gold === "number" ? myJSON.gold : 0,
					silver: typeof myJSON.silver === "number" ? myJSON.silver : 0,
					chip: typeof myJSON.chips === "number" ? myJSON.chips : 0,
				},
				minScore: typeof myJSON.minScore === "number" ? myJSON.minScore : 0,
				maxScore: typeof myJSON.maxScore === "number" ? myJSON.maxScore : 0,
				email: typeof myJSON.maxScore === "string" ? myJSON.email : "",
				publicData: typeof myJSON.publicData !== "undefined" ? myJSON.publicData : {},
				vipUntilTimestamp: typeof myJSON.vipUntilTimestamp === "number" ? myJSON.vipUntilTimestamp : 0,
				displayVipRed: typeof myJSON.displayVipRed === "boolean" ? myJSON.displayVipRed : false,
				streamingMode: typeof myJSON.streamingMode === "boolean" ? myJSON.streamingMode : false,
				extraMultipliers:
					typeof myJSON.extraMultipliers !== "undefined" && Array.isArray(myJSON.extraMultipliers)
						? myJSON.extraMultipliers
						: [],
				isVip: typeof myJSON.isVip === "boolean" ? myJSON.isVip : false,
				/* openTournamentID:
          typeof myJSON.openTournamentID === "string"
            ? myJSON.openTournamentID
            : "", */
			};
		}
		return userDetails;
	} catch (error) {
		handleError(error);
	}
}

export function getCountryFlagImageURL(country, dimension) {
	try {
		if (typeof country === "string") {
			if (typeof dimension !== "number" || isNaN(Number(dimension))) dimension = 20;
			return CONTENT_URL + FLAG_IMG_SET + dimension + "/" + country.toLowerCase() + ".png";
		}
		return undefined;
	} catch (error) {
		handleError(error);
	}
}

export function getRankingImageURL(ranking, dimension) {
	try {
		var tmpRankingImgURL = _getRankingImageURL(ranking, dimension);
		if (tmpRankingImgURL !== undefined) {
			return { uri: tmpRankingImgURL };
		} else {
			ranking = typeof ranking === "string" ? ranking.toUpperCase() : "";
			if (ranking) {
				switch (ranking) {
					case "VERY_EASY":
					case "RANDOM_VERY_EASY":
						return COMP_SUPER_EASY;
					case "EASY":
					case "RANDOM_EASY":
						return COMP_EASY;
					case "MEDIUM":
					case "RANDOM_MEDIUM":
						return COMP_MEDIUM;
					case "HARD":
					case "RANDOM_HARD":
						return COMP_HARD;
					case "VERY_HARD":
					case "RANDOM_VERY_HARD":
						return COMP_VERY_HARD;
					default:
						return {};
				}
			}
			return {};
		}
	} catch (error) {
		handleError(error);
	}
}

export function getChipsRequiredURL() {
	try {
		return CONTENT_URL + POKER_CHIPS_SET + "pokerchip-green_24px.png";
	} catch (error) {
		handleError(error);
	}
}

export function getStateAfterSetFilter(state, payload) {
	try {
		var newState = { ...state };
		const { filterGroup, val, isChecked } = payload;
		if (newState.filter[filterGroup].active) {
			var valIndex = newState.filter[filterGroup].default.labels.indexOf(val);
			if (valIndex != -1) newState.filter[filterGroup].active[valIndex] = isChecked;
		}
		newState = cloneDeep(newState);
		return newState;
	} catch (error) {
		handleError(error);
	}
}

export function getDefaultFilters(filter) {
	try {
		if (typeof filter == "undefined") return {};
		var curFilters = { ...filter };
		mapObject(curFilters, (key, value) => {
			mapObject(value.active, (k, v) => {
				var newVal = false;
				if (value.default.checks[k] != false) newVal = true;
				value.active[k] = newVal;
			});
		});
		return curFilters;
	} catch (error) {
		handleError(error);
	}
}

export function getFilteredGameList(gameList, filter) {
	try {
		//if gameList is not an array or filter is undefined then return the gameList
		if (!Array.isArray(gameList) || typeof filter === "undefined") return gameList;

		var counts = [];
		var filteredList = [...gameList];
		var gamesToRemove = [];
		mapObject(filter, (key, value) => {
			// iterating on filters
			switch (key) {
				case "playerCnt_3":
					mapObject(value.active, (k, v) => {
						if (typeof v === "boolean" && v) counts.push(value.default.labels[k]);
					});
					break;
				case "playerCnt_4":
					mapObject(value.active, (k, v) => {
						if (typeof v === "boolean" && v) counts.push(value.default.labels[k]);
					});
					filteredList.map((game) => {
						if (game.playerCnt && counts.indexOf(game.playerCnt) == -1)
							if (gamesToRemove.indexOf(game) == -1) gamesToRemove.push(game);
					});
					break;
				case "chipsRequired":
					filteredList.map((game) => {
						if (typeof game[key] === "number") {
							var requiredTrue = false;
							var requiredFalse = false;
							if (value.active[0] == true) requiredTrue = true;
							if (value.active[1] == true) requiredFalse = true;
							if (requiredTrue != requiredFalse) {
								if (requiredTrue) {
									if (game[key] <= 0) if (gamesToRemove.indexOf(game) == -1) gamesToRemove.push(game);
								} else {
									if (game[key] > 0) if (gamesToRemove.indexOf(game) == -1) gamesToRemove.push(game);
								}
							}
						}
					});
					break;
				case "onlyLocalLanguage":
				case "onlyFriends":
					filteredList.map((game) => {
						if (typeof game[key] === "boolean") {
							var requiredTrue = false;
							var requiredFalse = false;
							if (value.active[0] == true) requiredTrue = true;
							if (value.active[1] == true) requiredFalse = true;
							if (requiredTrue != requiredFalse) {
								if (requiredTrue) {
									if (!game[key]) if (gamesToRemove.indexOf(game) == -1) gamesToRemove.push(game);
								} else {
									if (game[key]) if (gamesToRemove.indexOf(game) == -1) gamesToRemove.push(game);
								}
							}
						}
					});
					break;
				default:
					break;
			}
		});
		gamesToRemove.map((game) => {
			var ind = filteredList.indexOf(game);
			game.isSelected = false;
			game.isHovered = false;
			game.isHiddenByFilter = true;
			if (ind != -1) filteredList.splice(ind, 1);
		});
		return filteredList;
	} catch (error) {
		handleError(error);
	}
}

export function updateSlotTeams(playerList, teamsEnabled, playerAmount, teamPrefixLang) {
	try {
		if (
			typeof playerList !== "undefined" &&
			typeof teamsEnabled === "boolean" &&
			typeof playerAmount === "number" &&
			playerAmount >= 2
		) {
			if (playerAmount % 2 !== 0 || playerAmount === 2) teamsEnabled = false;
			let teamNr = 0;
			playerList.map((player) => {
				if (!teamsEnabled) {
					player.teamName = "";
				} else {
					if (teamNr >= playerAmount / 2) teamNr = 0;
					player.teamName = teamPrefixLang + " " + teamNr;
					teamNr++;
				}
			});
		}
		return playerList;
	} catch (error) {
		handleError(error);
	}
}

export function getSelectedGamesPlayersList(selectedGame, gamePlayersInfo, freeSlotLang, teamPrefixLang) {
	try {
		if (
			typeof selectedGame != "undefined" &&
			selectedGame != null &&
			typeof gamePlayersInfo != "undefined" &&
			gamePlayersInfo != null
		) {
			var players = [];

			var allPlayers = [];
			if (gamePlayersInfo.humanPlayers) gamePlayersInfo.humanPlayers.forEach((el) => allPlayers.push(el));
			if (gamePlayersInfo.computerPlayers) gamePlayersInfo.computerPlayers.forEach((el) => allPlayers.push(el));
			if (
				gamePlayersInfo.data &&
				gamePlayersInfo.data !== null &&
				gamePlayersInfo.data.generic &&
				gamePlayersInfo.data.generic.slots
			)
				gamePlayersInfo.data.generic.slots.forEach((el) => {
					const new_el = {
						slotID: el.slotID,
						state: el.state == "OCCUPIED" ? "OCCUPIED" : "OPEN",
						isVip: el && el.player && el.player.isVip,
						username: el.state == "OCCUPIED" ? el.player.name : freeSlotLang,
						ranking:
							el.player != undefined
								? el.player.level != undefined
									? el.player.level
									: el.player.strength != undefined
									? el.player.strength
									: ""
								: "",
						botImage:
							el.player && typeof el.player.imageName === "string" && el.player.strength != undefined
								? el.player.imageName
								: "",
						country: el.player && el.player.country ? el.player.country : "",
						userID: el.state == "OCCUPIED" ? el.player.userID : "",
					};
					allPlayers.push(new_el);
				});

			if (selectedGame.playerCnt) {
				for (var i = 0; i < selectedGame.playerCnt; i++) {
					var newPlayer = allPlayers.find((obj) => obj.slotID == i);
					if (typeof newPlayer != "undefined") {
						switch (newPlayer.state) {
							case "OCCUPIED":
								newPlayer.wins = "";
								newPlayer.losses = "";
								newPlayer.disconnects = "";
								newPlayer.points = 0;
								newPlayer.gamesPlayed = 0;
								newPlayer.curDate = Date.now();
								players.push(newPlayer);
								break;
							case "OPEN":
								newPlayer = {};
								newPlayer.userID = "generated_" + i;
								newPlayer.curDate = Date.now();
								newPlayer.slotID = i;
								newPlayer.username = freeSlotLang || "";
								newPlayer.country = "";
								newPlayer.wins = "";
								newPlayer.losses = "";
								newPlayer.disconnects = "";
								newPlayer.ranking = "";
								newPlayer.points = 0;
								newPlayer.gamesPlayed = 0;
								players.push(newPlayer);
								break;
						}
					}
					/* if (typeof newPlayer != "undefined") {
            if (typeof newPlayer.userID != "undefined") {
              players.push(newPlayer);
            } else if (typeof newPlayer.computerID != "undefined") {
              var tmp = {
                userID: newPlayer.computerID,
                slotID: newPlayer.slotID,
                username: newPlayer.name,
                country: "",
                wins: newPlayer.wins,
                losses: newPlayer.losses,
                disconnects: "",
                ranking: newPlayer.strength,
                points: 0,
                gamesPlayed: 0,
              };
              players.push(tmp);
            }
          } else {
            newPlayer = {};
            newPlayer.userID = "generated_" + i;
            newPlayer.slotID = i;
            newPlayer.username = freeSlotLang || "";
            newPlayer.country = "";
            newPlayer.wins = "";
            newPlayer.losses = "";
            newPlayer.disconnects = "";
            newPlayer.ranking = "";
            newPlayer.points = 0;
            newPlayer.gamesPlayed = 0;
            players.push(newPlayer);
          } */
				}
				players = updateSlotTeams(
					players,
					gamePlayersInfo.data.specific.teamsEnabled,
					// selectedGame.teams,
					selectedGame.playerCnt,
					teamPrefixLang
				);
				return players;
			} else {
				for (var i = 0; i < selectedGame.data.generic.playerCnt; i++) {
					var newPlayer = allPlayers.find((obj) => obj.slotID == i);
					if (newPlayer != undefined) {
						players.push(newPlayer);
						if (newPlayer.curDate == undefined) newPlayer.curDate = Date.now();
					} else {
						newPlayer = {};
						newPlayer.userID = "generated_" + i;
						newPlayer.curDate = Date.now();
						newPlayer.slotID = i;
						newPlayer.username = freeSlotLang || "";
						newPlayer.country = "";
						newPlayer.wins = "";
						newPlayer.losses = "";
						newPlayer.disconnects = "";
						newPlayer.ranking = "";
						newPlayer.points = 0;
						newPlayer.gamesPlayed = 0;
						players.push(newPlayer);
					}
				}
			}
			players = updateSlotTeams(
				players,
				selectedGame.data.specific.teamsEnabled,
				selectedGame.data.generic.playerCnt,
				teamPrefixLang
			);
			return players;
		}
		return undefined;
	} catch (error) {
		handleError(error);
	}
}

export function getNewGamesUpdatedSlots(
	existingSlots,
	existingPlayers,
	playerAmount,
	enabledTeams,
	open_lang,
	teamPrefixLang
) {
	try {
		if (typeof existingSlots === "undefined" || !Array.isArray(existingSlots) || typeof playerAmount !== "number")
			return undefined;

		var res = {
			players: [...existingPlayers],
			slots: [...existingSlots],
		};
		var existingSlotsNr = existingPlayers.length;
		open_lang = open_lang || "";

		//new slots
		if (existingSlotsNr < playerAmount) {
			for (var i = 0; i < playerAmount - existingSlotsNr; i++) {
				var newNr = existingSlotsNr + i;
				res.players.push({
					userID: "generated_" + newNr,
					slotID: newNr,
					username: open_lang,
					country: "",
					wins: 0,
					losses: 0,
					disconnects: 0,
					ranking: "",
					points: 0,
					gamesPlayed: 0,
					teamName: "",
					isHovered: false,
					isSelected: false,
				});
				var find = res.slots.findIndex((obj) => obj.slotID == newNr);
				if (find == -1 && newNr != 0) {
					res.slots.push({
						slotID: newNr,
						// type: "HUMAN",
						state: "OPEN",
						// strength: "",
						// botID: 0,
					});
				}
			}
		}

		//removing existing slots if it's necessary
		if (existingSlotsNr > playerAmount) {
			for (var i = existingSlotsNr; i > playerAmount; i--) {
				res.players.splice(i - 1, 1);
				res.slots.splice(i - 2, 1);
			}
		}

		res.players = updateSlotTeams(res.players, enabledTeams, playerAmount, teamPrefixLang);

		return res;
	} catch (error) {
		handleError(error);
	}
}

export function getStartedGamesPlayersList(gamePlayersInfo) {
	try {
		if (typeof gamePlayersInfo === "undefined" || gamePlayersInfo == null) return {};

		var allPlayers = [];
		if (gamePlayersInfo.humanPlayers) {
			gamePlayersInfo.humanPlayers.forEach((el) => allPlayers.push(el));
		}
		if (gamePlayersInfo.computerPlayers) gamePlayersInfo.computerPlayers.forEach((el) => allPlayers.push(el));
		if (
			gamePlayersInfo.data &&
			gamePlayersInfo.data != null &&
			gamePlayersInfo.data.generic &&
			gamePlayersInfo.data.generic.slots
		)
			gamePlayersInfo.data.generic.slots.forEach((el) => {
				const new_el = {
					userID: el.player.userID || 0,
					slotID: el.slotID,
					state: el.state == "OCCUPIED" ? "OCCUPIED" : "OPEN",
					isVip: el.player.isVip,
					username: el.player.name,
					score: el.player.score ? el.player.score : "-",
					isOnMyBanList: el.player.isOnMyBanList == true,
					isOnMyFriendList: el.player.isOnMyFriendList == true,
					ranking:
						el.player.level != undefined ? el.player.level : el.player.strength != undefined ? el.player.strength : "",
					country: el.player.country || "",
					slotType: el.player.type || "COMPUTER",
					botImage:
						el.player && typeof el.player.imageName === "string" && el.player.strength != undefined
							? el.player.imageName
							: "",
					age: el.player.age != undefined ? el.player.age : 0,
					firstName: el.player.firstName ? el.player.firstName : null,
					lastName: el.player.lastName ? el.player.lastName : null,
					gender: el.player.gender ? el.player.gender : null,
					city: el.player.city ? el.player.city : null,
				};
				switch (el.player.state) {
					case "CONNECTED":
						new_el.connectedState = el.player.state;
						break;
					case "LEFT":
						new_el.connectedState = el.player.state;
						break;
					case "DISCONNECTED":
					default:
						new_el.connectedState = "DISCONNECTED";
						break;
				}
				allPlayers.push(new_el);
			});

		var players = {};
		allPlayers.forEach((pl) => {
			players["slot_" + pl.slotID] = {};
			players["slot_" + pl.slotID].slotID = pl.slotID;
			if (pl.slotType == "HUMAN") {
				players["slot_" + pl.slotID].userID = pl.userID;
				players["slot_" + pl.slotID].username = pl.username;
				players["slot_" + pl.slotID].country = pl.country;
				players["slot_" + pl.slotID].wins = pl.wins;
				players["slot_" + pl.slotID].losses = pl.losses;
				players["slot_" + pl.slotID].disconnects = pl.disconnects;
				players["slot_" + pl.slotID].ranking = pl.ranking;
				players["slot_" + pl.slotID].points = pl.points;
				players["slot_" + pl.slotID].gamesPlayed = pl.gamesPlayed;
				players["slot_" + pl.slotID].teamName = pl.teamName;
				players["slot_" + pl.slotID].isHuman = true;
				players["slot_" + pl.slotID].laurelID = pl.laurelID;
				players["slot_" + pl.slotID].isVip = pl.isVip;
				players["slot_" + pl.slotID].crownID = pl.crownID;
				players["slot_" + pl.slotID].connectedState = pl.connectedState;
				players["slot_" + pl.slotID].score = pl.score;
				players["slot_" + pl.slotID].isOnMyBanList = pl.isOnMyBanList;
				players["slot_" + pl.slotID].isOnMyFriendList = pl.isOnMyFriendList;
				players["slot_" + pl.slotID].age = pl.age;
				players["slot_" + pl.slotID].firstName = pl.firstName;
				players["slot_" + pl.slotID].lastName = pl.lastName;
				players["slot_" + pl.slotID].gender = pl.gender;
				players["slot_" + pl.slotID].city = pl.city;
			} else if (pl.slotType == "COMPUTER") {
				players["slot_" + pl.slotID].computerID = pl.computerID;
				players["slot_" + pl.slotID].username = pl.username;
				players["slot_" + pl.slotID].nickName = pl.nickName;
				players["slot_" + pl.slotID].strength = pl.ranking;
				players["slot_" + pl.slotID].wins = pl.wins;
				players["slot_" + pl.slotID].losses = pl.losses;
				players["slot_" + pl.slotID].teamName = pl.teamName;
				players["slot_" + pl.slotID].isHuman = false;
				players["slot_" + pl.slotID].laurelID = 0;
				players["slot_" + pl.slotID].crownID = 0;
				players["slot_" + pl.slotID].connectedState = "CONNECTED";
				players["slot_" + pl.slotID].score = pl.score;
				players["slot_" + pl.slotID].isOnMyBanList = pl.isOnMyBanList;
				players["slot_" + pl.slotID].isOnMyFriendList = pl.isOnMyFriendList;
				players["slot_" + pl.slotID].botImage = pl.botImage;
			}
		});

		return players;
	} catch (error) {
		handleError(error);
	}
}

export function playAnnouncementSound(priority, isErrorMessage, isNotification) {
	try {
		if (typeof priority !== undefined) {
			const { announceHighSound, announceMediumSound, announceLowSound, errorSound, notificationSound } = mySounds;
			if (!isErrorMessage && !isNotification) {
				switch (priority) {
					case "high":
						if (
							typeof announceHighSound !== "undefined" &&
							announceHighSound != null &&
							announceHighSound.isLoaded() == true
						) {
							announceHighSound.play();
						}
						break;
					case "medium":
						if (
							typeof announceMediumSound !== "undefined" &&
							announceMediumSound != null &&
							announceMediumSound.isLoaded() == true
						) {
							announceMediumSound.play();
						}
						break;
					case "low":
					default:
						if (
							typeof announceLowSound !== "undefined" &&
							announceLowSound != null &&
							announceLowSound.isLoaded() == true
						) {
							announceLowSound.play();
						}
						break;
				}
			} else {
				if (isErrorMessage) {
					if (typeof errorSound !== "undefined" && errorSound != null && errorSound.isLoaded() == true) {
						errorSound.play();
					}
				}
				if (isNotification) {
					if (
						typeof notificationSound !== "undefined" &&
						notificationSound != null &&
						notificationSound.isLoaded() == true
					) {
						notificationSound.play();
					}
				}
			}
		}
	} catch (error) {
		handleError(error);
	}
}

function _getRankingImageURL(ranking, dimension) {
	try {
		if (typeof ranking === "number") {
			if (typeof dimension !== "number" || isNaN(Number(dimension))) dimension = 14;
			if (ranking < 10) {
				return CONTENT_URL + RANKING_SET + dimension + "/badge_level_0" + ranking + ".png";
			} else {
				if (ranking <= maxExistingUserRating) {
					return CONTENT_URL + RANKING_SET + dimension + "/badge_level_" + ranking + ".png";
				}
			}
		}
		return undefined;
	} catch (error) {
		handleError(error);
	}
}

export function getProfileData(userDetails) {
	try {
		if (typeof userDetails === "undefined") return {};
		var result = {};
		result.username = userDetails.username;
		result.VIPUntil = userDetails.vipUntilTimestamp;
		result.displayVipRed = userDetails.displayVipRed;
		result.ranking = userDetails.ranking;
		result.points = userDetails.points;
		result.nextLevel = userDetails.ranking + 1;
		result.pointsNeeded = userDetails.maxScore - userDetails.minScore;
		result.goldStack = userDetails.stack.gold;
		result.silverStack = userDetails.stack.silver;
		result.chipStack = userDetails.stack.chip;
		result.leaderboardRank = 0;
		return result;
	} catch (error) {
		handleError(error);
	}
}

export function formatDate(date) {
	try {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [month, day, year].join("-");
	} catch (error) {
		handleError(error);
	}
}

export function filterAvailableMovesFunc(
	isPawnSelected,
	gamePlayerToAct,
	newCards,
	newBoardPositions,
	newStartPositions,
	newFilteredMoves,
	waitForSecond_7 = false,
	filterLog
) {
	try {
		if (filterLog) {
			if (!Array.isArray(filterLog.filterFunc)) filterLog.filterFunc = [];
		}
		if (
			typeof gamePlayerToAct === "undefined" ||
			gamePlayerToAct === null ||
			!Array.isArray(gamePlayerToAct.availableMoves)
		) {
			return undefined;
		}
		//reset newFilteredMoves
		newFilteredMoves = [];
		//reset all targeted positions
		newBoardPositions.forEach((bp) => {
			if (bp.targeted) {
				if (filterLog) {
					filterLog.filterFunc.push({
						msg: "reset targeted position",
						position: cloneDeep(bp),
					});
				}
				bp.playable = false;
				bp.targeted = false;
				bp.selected = false;
			}
		});

		var selectedCard,
			selectedPawn,
			selPositions = [],
			newPositions = [];
		selectedCard = newCards.find((c) => c.selected == true);
		selPositions = newBoardPositions.filter((bp) => bp.selected);
		newStartPositions.forEach((sp) => {
			if (sp.selected) selPositions.push(sp);
		});
		selectedPawn = selPositions.find((sp) => !sp.targeted);
		if (filterLog) {
			filterLog.filterFunc.push({
				msg: "selected card",
				card: cloneDeep(selectedCard),
			});
			filterLog.filterFunc.push({
				msg: "selected pawn",
				pawn: cloneDeep(selectedPawn),
			});
		}

		if (selectedCard != undefined) {
			if (selectedPawn == undefined) {
				//card is selected, pawn is not selected
				if (filterLog) {
					filterLog.filterFunc.push({
						msg: "card is selected, pawn is not selected => resetting all positions 'filtered' flag to false",
					});
				}
				newBoardPositions.forEach((bp) => {
					bp.filtered = false;
				});
				newStartPositions.forEach((sp) => {
					sp.filtered = false;
				});

				//filter available moves by the selectedCard
				gamePlayerToAct.availableMoves.forEach((move) => {
					if (selectedCard.id && selectedCard.id.startsWith(pips[move.pip])) {
						if (move.type == "DOUBLE") {
							// check if the first or the second move should be processed
							if (gamePlayerToAct.waitForSecond_7 == undefined) {
								if (!waitForSecond_7) {
									//filter the first available moves
									newFilteredMoves.push({
										...move.firstMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								} else {
									newFilteredMoves.push({
										...move.secondMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
							} else {
								//filter the first available moves
								newFilteredMoves.push({
									...move.secondMove,
									moveID: move.moveID,
									pip: move.pip,
									type: move.type,
								});
							}
						} else {
							newFilteredMoves.push(move);
						}
					}
				});
				if (filterLog) {
					filterLog.filterFunc.push({
						msg: "filter available moves by the selectedCard",
						newFilteredMoves: cloneDeep(newFilteredMoves),
					});
				}

				//set and display playable pawns
				var pawnCounter = 0,
					singlePawn = null;
				newFilteredMoves.forEach((move) => {
					const curPosID = move.curPos.toLowerCase();
					const _sp = newStartPositions.find((sp) => sp.pos == curPosID);
					const _bp = newBoardPositions.find((bp) => bp.id == curPosID);

					if (_sp != undefined) {
						if (_sp.filtered == false) {
							if (filterLog) {
								filterLog.filterFunc.push({
									msg: "set and display playable pawns; 'filtered': true",
									pawn: cloneDeep(_sp),
								});
							}
							_sp.filtered = true;
							pawnCounter++;
							singlePawn = _sp;
						}
					}
					if (_bp != undefined) {
						if (_bp.filtered == false) {
							if (filterLog) {
								filterLog.filterFunc.push({
									msg: "set and display playable pawns; 'filtered': true",
									pawn: cloneDeep(_bp),
								});
							}
							_bp.filtered = true;
							pawnCounter++;
							singlePawn = _bp;
						}
					}
				});

				//if only one pawn is filtered, select it automatically
				if (pawnCounter == 1 && singlePawn != null) {
					singlePawn.selected = true;
					if (filterLog) {
						filterLog.filterFunc.push({
							msg: "if only one pawn is filtered, select it automatically; 'selected': true",
							pawn: cloneDeep(singlePawn),
						});
					}
					return filterAvailableMovesFunc(
						true,
						gamePlayerToAct,
						newCards,
						newBoardPositions,
						newStartPositions,
						newFilteredMoves,
						waitForSecond_7,
						filterLog
					);
				} else {
					const selMoveId = getSelectedMoveIdToAct(
						newPositions,
						newCards,
						newBoardPositions,
						newStartPositions,
						newFilteredMoves
					);
					const canPlayerAct = checkPlayerCanAct(
						selMoveId,
						newCards,
						newBoardPositions,
						newStartPositions,
						newFilteredMoves
					);
					if (filterLog) {
						filterLog.filterFunc.push({
							msg: "returning results",
							filteredMoves: newFilteredMoves,
							targetedPositions: newPositions,
							selectedMoveID: selMoveId,
							canPlayerAct: canPlayerAct,
						});
					}
					return {
						newCards: newCards,
						newBoardPositions: newBoardPositions,
						newStartPositions: newStartPositions,
						newFilteredMoves: newFilteredMoves,
						canPlayerAct: canPlayerAct,
						selectedMoveIdToAct: selMoveId,
						targetedPositions: newPositions,
					};
				}
			}
		} else {
			if (selectedPawn != undefined) {
				//card is not selected, pawn is selected
				if (filterLog) {
					filterLog.filterFunc.push({
						msg: "card is not selected, pawn is selected => resetting all cards 'filtered' flag to false",
					});
				}
				newCards.forEach((c) => {
					c.filtered = false;
				});

				//filter available moves by the selectedPawn
				gamePlayerToAct.availableMoves.forEach((move) => {
					if (move.type == "DOUBLE") {
						if (gamePlayerToAct.waitForSecond_7 == undefined) {
							if (!waitForSecond_7) {
								//filter first moves
								if (selectedPawn.id && move.firstMove.curPos.toLowerCase() == selectedPawn.id) {
									newFilteredMoves.push({
										...move.firstMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
								if (selectedPawn.pos && move.firstMove.curPos.toLowerCase() == selectedPawn.pos) {
									newFilteredMoves.push({
										...move.firstMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
							} else {
								//filter second moves
								if (selectedPawn.id && move.secondMove.curPos.toLowerCase() == selectedPawn.id) {
									newFilteredMoves.push({
										...move.secondMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
								if (selectedPawn.pos && move.secondMove.curPos.toLowerCase() == selectedPawn.pos) {
									newFilteredMoves.push({
										...move.secondMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
							}
						} else {
							if (selectedPawn.id && move.secondMove.curPos.toLowerCase() == selectedPawn.id) {
								newFilteredMoves.push({
									...move.secondMove,
									moveID: move.moveID,
									pip: move.pip,
									type: move.type,
								});
							}
							if (selectedPawn.pos && move.secondMove.curPos.toLowerCase() == selectedPawn.pos) {
								newFilteredMoves.push({
									...move.secondMove,
									moveID: move.moveID,
									pip: move.pip,
									type: move.type,
								});
							}
						}
					} else {
						if (selectedPawn.id && move.curPos.toLowerCase() == selectedPawn.id) {
							newFilteredMoves.push(move);
						}
						if (selectedPawn.pos && move.curPos.toLowerCase() == selectedPawn.pos) newFilteredMoves.push(move);
					}
				});
				if (filterLog) {
					filterLog.filterFunc.push({
						msg: "filter available moves by the selectedPawn",
						newFilteredMoves: cloneDeep(newFilteredMoves),
					});
				}

				//set and display playable cards and pawns
				var cardCounter = 0,
					singleCard = null;
				newFilteredMoves.forEach((move) => {
					const curPosID = move.curPos.toLowerCase();
					const _sp = newStartPositions.find((sp) => sp.pos == curPosID);
					const _bp = newBoardPositions.find((bp) => bp.id == curPosID);

					if (_sp != undefined) {
						if (filterLog) {
							filterLog.filterFunc.push({
								msg: "set and display playable pawns; 'filtered': true",
								pawn: cloneDeep(_sp),
							});
						}
						_sp.filtered = true;
					}
					if (_bp != undefined) {
						if (filterLog) {
							filterLog.filterFunc.push({
								msg: "set and display playable pawns; 'filtered': true",
								pawn: cloneDeep(_bp),
							});
						}
						_bp.filtered = true;
					}

					const _cards = newCards.filter((c) => c && c.id && c.id.startsWith(pips[move.pip]));
					if (_cards.length > 0) {
						_cards.forEach((card) => {
							if (filterLog) {
								filterLog.filterFunc.push({
									msg: "set and display playable card; 'filtered': true",
									pawn: cloneDeep(card),
								});
							}
							card.filtered = true;
							if (singleCard == null || singleCard.id != card.id) cardCounter++;
						});
						if (_cards.length == 1) singleCard = _cards[0];
					}
				});

				//if only one card is filtered, select it automatically
				if (cardCounter == 1 && singleCard != null) {
					if (filterLog) {
						filterLog.filterFunc.push({
							msg: "if only one card is filtered, select it automatically; 'selected': true",
							pawn: cloneDeep(singleCard),
						});
					}
					singleCard.selected = true;
					return filterAvailableMovesFunc(
						false,
						gamePlayerToAct,
						newCards,
						newBoardPositions,
						newStartPositions,
						newFilteredMoves,
						waitForSecond_7,
						filterLog
					);
				} else {
					const selMoveId = getSelectedMoveIdToAct(
						newPositions,
						newCards,
						newBoardPositions,
						newStartPositions,
						newFilteredMoves
					);
					const canPlayerAct = checkPlayerCanAct(
						selMoveId,
						newCards,
						newBoardPositions,
						newStartPositions,
						newFilteredMoves
					);

					if (filterLog) {
						filterLog.filterFunc.push({
							msg: "returning results",
							filteredMoves: newFilteredMoves,
							targetedPositions: newPositions,
							selectedMoveID: selMoveId,
							canPlayerAct: canPlayerAct,
						});
					}
					return {
						newCards: newCards,
						newBoardPositions: newBoardPositions,
						newStartPositions: newStartPositions,
						newFilteredMoves: newFilteredMoves,
						canPlayerAct: canPlayerAct,
						selectedMoveIdToAct: selMoveId,
						targetedPositions: newPositions,
					};
				}
			}
		}

		if (selectedPawn != undefined && selectedCard != undefined) {
			//card is selected, pawn is selected
			if (!isPawnSelected) {
				if (filterLog) {
					filterLog.filterFunc.push({
						msg: "card is selected, pawn is selected; filtering initiated with card selection => resetting all positions 'filtered' flag to false",
					});
				}
				newBoardPositions.forEach((bp) => {
					bp.filtered = false;
				});
				newStartPositions.forEach((sp) => {
					sp.filtered = false;
				});

				//filter available moves by the selectedCard
				gamePlayerToAct.availableMoves.forEach((move) => {
					if (selectedCard.id && selectedCard.id.startsWith(pips[move.pip])) {
						if (move.type == "DOUBLE") {
							// check if the first or the second move should be processed
							if (gamePlayerToAct.waitForSecond_7 == undefined) {
								if (!waitForSecond_7) {
									//filter the first available moves
									newFilteredMoves.push({
										...move.firstMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								} else {
									//filter second moves
									newFilteredMoves.push({
										...move.secondMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
							} else {
								//filter the first available moves
								newFilteredMoves.push({
									...move.secondMove,
									moveID: move.moveID,
									pip: move.pip,
									type: move.type,
								});
							}
						} else {
							newFilteredMoves.push(move);
						}
					}
				});
				if (filterLog) {
					filterLog.filterFunc.push({
						msg: "filter available moves by the selectedCard",
						newFilteredMoves: cloneDeep(newFilteredMoves),
					});
				}

				//set and display playable pawns
				//check if filteredMoves contains the selectedPawn
				var contains = false;
				newFilteredMoves.forEach((move) => {
					const curPosID = move.curPos.toLowerCase();
					const _sp = newStartPositions.find((sp) => sp.pos == curPosID);
					const _bp = newBoardPositions.find((bp) => bp.id == curPosID);

					if (_sp != undefined) {
						if (filterLog) {
							filterLog.filterFunc.push({
								msg: "set and display playable pawns; 'filtered': true",
								pawn: cloneDeep(_sp),
							});
						}
						_sp.filtered = true;
					}
					if (_bp != undefined) {
						if (filterLog) {
							filterLog.filterFunc.push({
								msg: "set and display playable pawns; 'filtered': true",
								pawn: cloneDeep(_bp),
							});
						}
						_bp.filtered = true;
					}
					if ((selectedPawn.id && curPosID == selectedPawn.id) || (selectedPawn.pos && curPosID == selectedPawn.pos))
						contains = true;
				});

				if (!contains) {
					selectedPawn.selected = false;
					selectedPawn.filtered = false;

					newCards.forEach((c) => {
						c.filtered = false;
					});
					newBoardPositions.forEach((bp) => {
						bp.filtered = false;
						bp.selected = false;
					});
					newStartPositions.forEach((sp) => {
						sp.filtered = false;
						sp.selected = false;
					});
					if (filterLog) {
						filterLog.filterFunc.push({
							msg: "filtered moves doesn't contains the selected pawn => 'filtered': false; 'selected':false",
							pawn: cloneDeep(selectedPawn),
						});
					}
					return filterAvailableMovesFunc(
						!isPawnSelected,
						gamePlayerToAct,
						newCards,
						newBoardPositions,
						newStartPositions,
						newFilteredMoves,
						waitForSecond_7,
						filterLog
					);
				}
			} else {
				if (filterLog) {
					filterLog.filterFunc.push({
						msg: "card is selected, pawn is selected; filtering initiated with position selection => resetting all cards 'filtered' flag to false",
					});
				}
				newCards.forEach((c) => {
					c.filtered = false;
				});

				//filter available moves by the selectedPawn
				gamePlayerToAct.availableMoves.forEach((move) => {
					if (move.type == "DOUBLE") {
						if (gamePlayerToAct.waitForSecond_7 == undefined) {
							if (!waitForSecond_7) {
								if (selectedPawn.id && move.firstMove.curPos.toLowerCase() == selectedPawn.id) {
									newFilteredMoves.push({
										...move.firstMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
								if (selectedPawn.pos && move.firstMove.curPos.toLowerCase() == selectedPawn.pos) {
									newFilteredMoves.push({
										...move.firstMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
							} else {
								if (selectedPawn.id && move.secondMove.curPos.toLowerCase() == selectedPawn.id) {
									newFilteredMoves.push({
										...move.secondMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
								if (selectedPawn.pos && move.secondMove.curPos.toLowerCase() == selectedPawn.pos) {
									newFilteredMoves.push({
										...move.secondMove,
										moveID: move.moveID,
										pip: move.pip,
										type: move.type,
									});
								}
							}
						} else {
							if (selectedPawn.id && move.secondMove.curPos.toLowerCase() == selectedPawn.id) {
								newFilteredMoves.push({
									...move.secondMove,
									moveID: move.moveID,
									pip: move.pip,
									type: move.type,
								});
							}
							if (selectedPawn.pos && move.secondMove.curPos.toLowerCase() == selectedPawn.pos) {
								newFilteredMoves.push({
									...move.secondMove,
									moveID: move.moveID,
									pip: move.pip,
									type: move.type,
								});
							}
						}
					} else {
						if (selectedPawn.id && move.curPos.toLowerCase() == selectedPawn.id) {
							newFilteredMoves.push(move);
						}
						if (selectedPawn.pos && move.curPos.toLowerCase() == selectedPawn.pos) newFilteredMoves.push(move);
					}
				});
				if (filterLog) {
					filterLog.filterFunc.push({
						msg: "filter available moves by the selectedPawn",
						newFilteredMoves: cloneDeep(newFilteredMoves),
					});
				}

				//set and display playable cards and pawns
				//check if filteredMoves contains the selectedCard
				var contains = false;
				newFilteredMoves.forEach((move) => {
					const curPosID = move.curPos.toLowerCase();
					const _sp = newStartPositions.find((sp) => sp.pos == curPosID);
					const _bp = newBoardPositions.find((bp) => bp.id == curPosID);
					if (_sp != undefined) {
						if (filterLog) {
							filterLog.filterFunc.push({
								msg: "set and display playable pawns; 'filtered': true",
								pawn: cloneDeep(_sp),
							});
						}
						_sp.filtered = true;
					}
					if (_bp != undefined) {
						if (filterLog) {
							filterLog.filterFunc.push({
								msg: "set and display playable pawns; 'filtered': true",
								pawn: cloneDeep(_bp),
							});
						}
						_bp.filtered = true;
					}

					const _cards = newCards.filter((c) => c && c.id && c.id.startsWith(pips[move.pip]));
					if (_cards.length > 0) {
						_cards.forEach((card) => {
							if (filterLog) {
								filterLog.filterFunc.push({
									msg: "set and display playable card; 'filtered': true",
									pawn: cloneDeep(card),
								});
							}
							card.filtered = true;
						});
					}

					if (selectedCard.id && selectedCard.id.startsWith(pips[move.pip])) contains = true;
				});

				if (!contains) {
					selectedCard.selected = false;
					newCards.forEach((c) => {
						c.filtered = false;
						c.selected = false;
					});
					if (filterLog) {
						filterLog.filterFunc.push({
							msg: "filtered moves doesn't contains the selected card => 'filtered': false; 'selected':false",
							pawn: cloneDeep(selectedCard),
						});
					}

					return filterAvailableMovesFunc(
						!isPawnSelected,
						gamePlayerToAct,
						newCards,
						newBoardPositions,
						newStartPositions,
						newFilteredMoves,
						waitForSecond_7,
						filterLog
					);
				}
			}

			//display new positions
			var filteredMovesToRemove = [];
			newFilteredMoves.forEach((move, index) => {
				if (selectedCard.id && selectedCard.id.startsWith(pips[move.pip])) {
					var curPosID = move.curPos.toLowerCase(),
						newPosID = move.newPos.toLowerCase();
					if ((selectedPawn.id && curPosID == selectedPawn.id) || (selectedPawn.pos && curPosID == selectedPawn.pos)) {
						var pos = newBoardPositions.find((bp) => bp.id == newPosID);
						pos = pos == undefined ? newStartPositions.find((sp) => sp.pos == newPosID) : pos;
						if (pos != undefined) {
							pos.targeted = true;
							if (newPositions.find((np) => np.id == pos.id) == undefined) newPositions.push(pos);
						}
					}
				}
			});

			const selMoveId = getSelectedMoveIdToAct(
				newPositions,
				newCards,
				newBoardPositions,
				newStartPositions,
				newFilteredMoves
			);
			const canPlayerAct = checkPlayerCanAct(
				selMoveId,
				newCards,
				newBoardPositions,
				newStartPositions,
				newFilteredMoves
			);

			if (filterLog) {
				filterLog.filterFunc.push({
					msg: "returning results",
					filteredMoves: newFilteredMoves,
					targetedPositions: newPositions,
					selectedMoveID: selMoveId,
					canPlayerAct: canPlayerAct,
				});
			}
			return {
				newCards: newCards,
				newBoardPositions: newBoardPositions,
				newStartPositions: newStartPositions,
				newFilteredMoves: newFilteredMoves,
				canPlayerAct: canPlayerAct,
				selectedMoveIdToAct: selMoveId,
				targetedPositions: newPositions,
			};
		}
	} catch (error) {
		handleError(error);
	}
}

export function getSelectedMoveIdToAct(newPositions, newCards, newBoardPositions, newStartPositions, newFilteredMoves) {
	try {
		var selectedCard,
			selectedPawn,
			selPositions = [],
			selectedNewPos = [];
		selectedCard = newCards.find((c) => c.selected == true);
		selPositions = newBoardPositions.filter((bp) => bp.selected);
		newStartPositions.forEach((sp) => {
			if (sp.selected) selPositions.push(sp);
		});
		selectedPawn = selPositions.find((sp) => !sp.targeted);
		selectedNewPos = newBoardPositions.find((bp) => bp.targeted && bp.selected);
		var res = -1;
		if (selectedCard != undefined && selectedPawn != undefined) {
			newFilteredMoves.forEach((move) => {
				if (
					// move.card == selectedCard.id &&
					selectedCard.id.startsWith(pips[move.pip]) &&
					((selectedPawn.id &&
						/* move.subMoves[move.subMoves.length - 1].curPos ==
              selectedPawn.id */
						move.curPos.toLowerCase() == selectedPawn.id) ||
						(selectedPawn.pos &&
							/* move.subMoves[move.subMoves.length - 1].curPos ==
                selectedPawn.pos */
							move.curPos.toLowerCase() == selectedPawn.pos))
				) {
					if (newPositions.length == 1) {
						if (
							/* move.subMoves[move.subMoves.length - 1].newPos ==
              newPositions[0].id */
							move.newPos.toLowerCase() == newPositions[0].id
						) {
							res = move.moveID;
							newPositions[0].selected = true;
							newPositions[0].playable = true;
						}
					} else {
						// if (move.card[0] == "7" || move.card[0] == "J") {
						if (pips[move.pip] == "7" || pips[move.pip] == "J") {
							newPositions.forEach((np) => {
								np.playable = true;
							});
						}
						if (selectedNewPos != undefined) {
							if (
								/* move.subMoves[move.subMoves.length - 1].newPos ==
                selectedNewPos.id */
								move.newPos.toLowerCase() == selectedNewPos.id
							) {
								res = move.moveID;
							}
						}
					}
				}
			});
		}
		return res;
	} catch (error) {
		handleError(error);
	}
}

export function checkPlayerCanAct(selMoveId, newCards, newBoardPositions, newStartPositions, newFilteredMoves) {
	try {
		var selectedCard,
			selectedPawn,
			selPositions = [];
		selectedCard = newCards.find((c) => c.selected == true);
		selPositions = newBoardPositions.filter((bp) => bp.selected);
		newStartPositions.forEach((sp) => {
			if (sp.selected) selPositions.push(sp);
		});
		selectedPawn = selPositions.find((sp) => !sp.targeted);

		if (
			selectedCard != null &&
			selectedPawn != null &&
			typeof newFilteredMoves != "undefined" &&
			newFilteredMoves.length > 0 &&
			selMoveId != -1
		) {
			return true;
		}
		return false;
	} catch (error) {
		handleError(error);
	}
}

export function getRandomInt(min, max) {
	try {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	} catch (error) {
		handleError(error);
	}
}

export function decideAvatarURL(playerObj, avatarURL, bot, botAvatarURL) {
	let url = "";
	if (playerObj.isHuman) {
		url = "https://" + avatarURL + playerObj.userID + "/" + Date.now();
	} else {
		if (
			playerObj.botImage &&
			playerObj.botImage != "" &&
			/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(playerObj.botImage)
		) {
			url = "https://" + botAvatarURL + playerObj.botImage;
		} else {
			url = "https://" + botAvatarURL + bot.image;
		}
	}
	return url;
}
