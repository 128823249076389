import { element, func, oneOfType } from "prop-types";
import React, { Component } from "react";
import { Animated, Platform, StyleSheet, View } from "react-native";
import { f_sourcesansprosemibold } from "../../config/defaults";
import createHoverMonitor from "./createHoverMonitor";

const hover = createHoverMonitor();

/**
 * Use:
 * <Hoverable>
 *   {(hover) => <View style={hover && styles.hovered} />}
 * </Hoverable>
 *
 * Example: https://imaginary-lycra.glitch.me/
 * Example source: https://glitch.com/edit/#!/imaginary-lycra
 */
class Hoverable extends Component {
	static displayName = "Hoverable";

	static propTypes = {
		children: oneOfType([func, element]).isRequired,
		onHoverIn: func,
		onHoverOut: func,
	};

	state = {
		isHovered: false,
		showTooltip: false,
		tooltipOpacityAnim: new Animated.Value(0),
	};

	//#region events
	_handleMouseEnter = (e) => {
		if (hover.isEnabled && !this.state.isHovered) {
			const { onHoverIn } = this.props;
			if (onHoverIn) {
				onHoverIn();
			}
			this.setState(() => ({ isHovered: true, showTooltip: true }));
			Animated.timing(this.state.tooltipOpacityAnim, {
				toValue: 1,
				duration: 5,
				useNativeDriver: Platform.OS !== "web",
			}).start();
		}
	};

	_handleMouseLeave = (e) => {
		if (this.state.isHovered) {
			const { onHoverOut } = this.props;
			if (onHoverOut) {
				onHoverOut();
			}
			this.setState(() => ({ isHovered: false }));
			Animated.timing(this.state.tooltipOpacityAnim, {
				toValue: 0,
				duration: 5,
				useNativeDriver: Platform.OS !== "web",
			}).start(() => this.setState(() => ({ showTooltip: false })));
		}
	};
	//#endregion

	//#region render methods
	renderContent(child) {
		return (
			<View style={[this.props.style, !this.props.noAbsPos && styles.mainContainer]}>
				{React.cloneElement(React.Children.only(child), {
					onMouseEnter: this._handleMouseEnter,
					onMouseMove: this._handleMouseEnter,
					onMouseLeave: this._handleMouseLeave,
				})}
				{/* {this.state.showTooltip == true && this.props.tooltipText && (
          <Animated.View
            style={[
              styles.tooltipContainer,
              {
                top: this.props.tooltipTextPos.top,
                bottom: this.props.tooltipTextPos.bottom,
                left: this.props.tooltipTextPos.left,
                right: this.props.tooltipTextPos.right,
                opacity: this.state.tooltipOpacityAnim,
              },
              this.props.tooltipContainer,
            ]}
          >
            <Text
              allowFontScaling={false}
              numberOfLines={1}
              style={[styles.tooltipText, this.props.textStyle]}
            >
              {this.props.tooltipText}
            </Text>
          </Animated.View>
        )} */}
			</View>
		);
	}

	render() {
		const child =
			typeof this.props.children === "function" ? this.props.children(this.state.isHovered) : this.props.children;
		const _tooltipText = this.props.tooltipText ? this.props.tooltipText : undefined;

		if (Platform.OS === "web") {
			return <div title={_tooltipText}>{this.renderContent(child)}</div>;
		}
		return this.renderContent(child);
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: { position: "absolute" },
	tooltipContainer: {
		position: "absolute",
		maxWidth: 300,
		zIndex: 777,
		// borderRadius: 3,
		// shadowColor: "#383838",
		// shadowOffset: {
		//   width: 1,
		//   height: 1,
		// },
		// shadowRadius: 2,
		// shadowOpacity: 1,
		// elevation: 5,
	},
	tooltipText: {
		fontSize: 8,
		fontFamily: f_sourcesansprosemibold,
		borderWidth: 0.5,
		backgroundColor: "#FFFFFF",
		padding: 1,
	},
});

export { Hoverable };
