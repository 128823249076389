import NetInfo from "@react-native-community/netinfo";
import { Platform } from "react-native";
import { CONNECTION_TYPE } from "../config/defaults";

export default class NetworkUtils {
	static async isOnline() {
		const response = await NetInfo.fetch();
		if (Platform.OS !== "web") return response.isConnected && response.isInternetReachable;
		return response.isConnected;
	}

	static async isNetworkAvailable() {
		const response = await NetInfo.fetch();
		return response.isConnected;
	}

	static async isInternetReachable() {
		const response = await NetInfo.fetch();
		if (Platform.OS !== "web") return response.isInternetReachable;
		return response.isConnected;
	}

	static async getConnectionType() {
		const response = await NetInfo.fetch();
		switch (response.type) {
			case "cellular":
				const cellular = await NetInfo.fetch("cellular");
				switch (cellular.details.cellularGeneration) {
					case "2g":
						return CONNECTION_TYPE.CELLULAR_2G;
					case "3g":
						return CONNECTION_TYPE.CELLULAR_3G;
					case "4g":
						return CONNECTION_TYPE.CELLULAR_4G;
					case "5g":
						return CONNECTION_TYPE.CELLULAR_5G;
					default:
						return CONNECTION_TYPE.UNKNOWN;
				}
			case "wifi":
				return CONNECTION_TYPE.WIFI;
			default:
				return CONNECTION_TYPE.UNKNOWN;
		}
	}
}
