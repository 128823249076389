import React, { Component } from "react";
import { FlatList, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { isWebOrTablet, playerList, RESOLUTION } from "../../../../config/defaults";
import { MAIL_ICON, MINUS_ICON, PLUS_ICON } from "../../../../config/images";
import { enableHiddenFeatures } from "../../../../config/permissions";
import { openComposeMessage } from "../../../../redux/actions";
import { TableHeader, TableHeaderCell, TableRow, TableRowCell } from "../../../common";
import { handleError } from "../../../ErrorHandler";

class PlayersListTable extends Component {
	constructor(props) {
		super(props);
		this.state = { leftColumnWidth: 60, maxVisibleRows: 1 };
	}

	//#region events
	tableRendered(event) {
		try {
			const tableHeight = event.nativeEvent.layout.height;
			// const maxVisibleRows = isWebOrTablet
			//   ? this.props.resolution === RESOLUTION.HIGH
			//     ? Math.floor(tableHeight / 41)
			//     : Math.floor(tableHeight / 31)
			//   : Math.floor(tableHeight / 20);
			const maxVisibleRows =
				this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web"
					? Math.round(tableHeight / 41)
					: Math.round(tableHeight / 31);
			this.setState({ maxVisibleRows });
			this.props.onTableLayout(maxVisibleRows);
		} catch (error) {
			handleError(error);
		}
	}

	onChatPress(item) {
		//TODO:
	}

	onMessagePress(item) {
		this.props.openComposeMessage(false, false, item);
	}

	selectPlayer(player) {
		if (typeof this.props.selectFilteredPlayer === "function")
			this.props.selectFilteredPlayer(player, playerList.PLAYERS_LIST);
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<TableHeader>
				<TableHeaderCell
					key={"player_plListHeader"}
					cellContent={this.props.lang.playersList.playerHeader}
					containerStyle={{ flex: 1 }}
					textStyle={[{ flex: 1, textAlign: "center" }, this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 }]}
					onLayout={(event) => {
						this.setState({
							leftColumnWidth: Math.round(event.nativeEvent.layout.width),
						});
					}}
				/>
				{/* <TableHeaderCell
          key={"chat_plListHeader"}
          cellContent={this.props.lang.playersList.chatHeader}
          containerStyle={{ width: 60 }}
          textStyle={[
            { flex: 1, textAlign: "center" },
            this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
          ]}
        /> */}
				<TableHeaderCell
					key={"msg_plListHeader"}
					cellContent={this.props.lang.playersList.messageHeader}
					containerStyle={{
						width: isWebOrTablet ? (this.props.resolution === RESOLUTION.HIGH ? 105 : 85) : 60,
					}}
					textStyle={[{ flex: 1, textAlign: "center" }, this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 }]}
				/>
				<TableHeaderCell
					key={"friendList_plListHeader"}
					cellContent={this.props.lang.playersList.friendHeader}
					containerStyle={{
						width: isWebOrTablet ? (this.props.resolution === RESOLUTION.HIGH ? 160 : 120) : 85,
					}}
					textStyle={[{ flex: 1, textAlign: "center" }, this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 }]}
				/>
				<TableHeaderCell
					key={"banList_plListHeader"}
					cellContent={this.props.lang.playersList.banHeader}
					containerStyle={{
						width: isWebOrTablet ? (this.props.resolution === RESOLUTION.HIGH ? 160 : 120) : 85,
					}}
					textStyle={[{ flex: 1, textAlign: "center" }, this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 }]}
				/>
				{Platform.OS === "web" && this.state.maxVisibleRows < this.props.playersList.length && (
					<TableHeaderCell
						key={"scrollerHeader"}
						cellContent={""}
						containerStyle={{ width: 17 }}
						textStyle={{ flex: 1, textAlign: "center" }}
					/>
				)}
			</TableHeader>
		);
	}

	renderRow(item) {
		const friendActionURL = item.isFriend ? MINUS_ICON : PLUS_ICON;
		const banActionURL = item.isBanned ? MINUS_ICON : PLUS_ICON;
		return (
			<TableRow
				rowId={"row_" + item.userID}
				onPress={() => this.selectPlayer(item)}
				onDoubleTap={() => this.selectPlayer(item)}
				disabled={!enableHiddenFeatures}
				style={this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? { height: 40 } : {}}
			>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					cellWidth={this.state.leftColumnWidth}
					key={"player_" + item.userID}
					cellId={"player_" + item.userID}
					content={item.username}
					cellType={"parsed_text"}
					// parsedStyle={{ backgroundColor: "#F6A022", paddingLeft: 0 }}
					parsedText={this.props.searchTerm}
					style={[
						{ color: "#FFFDF1" },
						Platform.OS !== "web" && { fontSize: 20 },
						this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
					]}
				/>
				{/* <TableRowCell
          lang={this.props.lang}
          backgroundColor={"transparent"}
          cellWidth={60}
          key={"chat_" + item.userID}
          cellId={"chat_" + item.userID}
          cellType={"img_button"}
          onButtonImagePress={() => this.onChatPress(item)}
          isButtonDisabled={false}
          imgURL={CHAT_ICON}
          imgDim={{
            width:
              this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web"
                ? 32
                : 26,
            height:
              this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web"
                ? 30
                : 24,
            marginTop:
              this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web"
                ? 2
                : undefined,
          }}
        /> */}
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					cellWidth={isWebOrTablet ? (this.props.resolution === RESOLUTION.HIGH ? 105 : 85) : 60}
					key={"msg_" + item.userID}
					cellId={"msg_" + item.userID}
					cellType={"img_button"}
					onButtonImagePress={() => this.onMessagePress(item)}
					isButtonDisabled={false}
					imgURL={MAIL_ICON}
					imgDim={{
						width: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 38 : 32,
						height: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 26 : 22,
					}}
				/>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					cellWidth={isWebOrTablet ? (this.props.resolution === RESOLUTION.HIGH ? 160 : 120) : 85}
					key={"friendList_" + item.userID}
					cellId={"friendList_" + item.userID}
					cellType={"img_button"}
					onButtonImagePress={() => this.props.onFriendActionPress(item)}
					imgURL={friendActionURL}
					isButtonDisabled={item.isBanned}
					imgDim={{
						width: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 30 : 18,
						height: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 30 : 18,
						marginTop: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 2 : undefined,
					}}
				/>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					cellWidth={isWebOrTablet ? (this.props.resolution === RESOLUTION.HIGH ? 160 : 120) : 85}
					key={"banList_" + item.userID}
					cellId={"banList_" + item.userID}
					cellType={"img_button"}
					onButtonImagePress={() => this.props.onBanActionPress(item)}
					isButtonDisabled={item.isFriend}
					imgURL={banActionURL}
					imgDim={{
						width: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 30 : 18,
						height: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 30 : 18,
						marginTop: this.props.resolution === RESOLUTION.HIGH || Platform.OS !== "web" ? 2 : undefined,
					}}
				/>
				{Platform.OS === "web" && this.state.maxVisibleRows < this.props.playersList.length && (
					<TableRowCell
						lang={this.props.lang}
						backgroundColor={"transparent"}
						cellWidth={25}
						key={"scroller_" + item.userID}
						cellId={"scroller_" + item.userID}
						cellType={"text"}
						content={" "}
						style={{ paddingLeft: 0, paddingRight: 0 }}
					/>
				)}
			</TableRow>
		);
	}

	renderTable() {
		return (
			<FlatList
				keyExtractor={(player) => player.userID.toString()}
				data={this.props.playersList}
				renderItem={({ item }) => this.renderRow(item)}
				getItemLayout={(data, index) => ({
					length: 20,
					offset: 20 * index,
					index,
				})}
				style={{ height: "100%" }}
				onLayout={(event) => this.tableRendered(event)}
			/>
		);
	}

	render() {
		return (
			<View style={styles.tableStyle}>
				{this.renderHeader()}
				{this.renderTable()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	tableStyle: { flex: 1, height: "100%" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = { openComposeMessage };

export default connect(mapStateToProps, mapDispatchToProps)(PlayersListTable);
