import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprolight,
	processState,
	RESOLUTION,
} from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { TEXT_INPUT, TEXT_INPUT_ERROR } from "../../config/images";
import { MyWebSocket } from "../../connection";
import CheckBox from "../../libraries/CheckBox";
import { goBackToMain, startUpgradeAccount } from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../common";
import { handleError } from "../ErrorHandler";

class UpgradeAccount extends Component {
	emailRef = React.createRef();
	pwdRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			isFormValid: true,
			username: "",
			usernameValid: true,
			usernameActive: false,
			email: "",
			emailValid: true,
			emailActive: false,
			pwd: "",
			pwdValid: true,
			pwdActive: false,
			isEnoughOld: false,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("UpgradeAccount");
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.upgradeAccountState == processState.STARTED &&
			this.props.upgradeAccountState == processState.FINISHED &&
			!prevProps.isEmailLoggedIn &&
			!this.props.isEmailLoggedIn
		) {
			const validUName = this.props.upgradeAccountError.username === "";
			const validPwd = this.props.upgradeAccountError.pwd === "";
			const validEmail = this.props.upgradeAccountError.email === "";
			const validForm = validUName && validPwd && validEmail && this.state.isEnoughOld;
			this.setState({
				usernameValid: validUName,
				emailValid: validEmail,
				pwdValid: validPwd,
				isFormValid: validForm,
			});
		}
	}
	//#endregion

	//#region events
	onUpgradePress() {
		try {
			// save credentials in AsyncStorage which will be removed if upgrade failed
			if (this.isFormValidated()) {
				const registerGuestMsg = {
					sMessageID: 0,
					type: "registerGuest",
					username: this.state.username,
					password: this.state.pwd,
					email: this.state.email,
				};
				MyWebSocket.shared.sendMsg(registerGuestMsg);
				this.props.startUpgradeAccount();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onEmailSubmit() {
		this.setState({ emailActive: false });
	}

	onEmailChange(text) {
		this.setState({ email: text });
	}

	onPwdSubmit() {
		this.setState({ pwdActive: false });
	}

	onPwdChange(text) {
		this.setState({ pwd: text });
	}

	onUsernameSubmit() {
		this.setState({ usernameActive: false });
	}

	onUsernameChange(text) {
		this.setState({ username: text });
	}

	onEnoughOldChange() {
		this.setState({ isEnoughOld: !this.state.isEnoughOld, isFormValid: true });
	}
	//#endregion

	//#region render methods
	renderUpgradeButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={[styles.buttonContainer, styles.fullWidth]}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onUpgradePress.bind(this)}
					textContent={this.props.lang.btnUpgradeAcc}
					accessibilityLabel={this.props.lang.btnUpgradeAcc}
					color={buttonColor.GREEN}
				/>
			</View>
		);
	}

	renderEmailField() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = styles.inputStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26, width: 300 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataInnerContainer}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.emailSignupForm.email.title}
				</Text>
				<ImageBackground
					source={this.state.emailValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					<TextInput
						allowFontScaling={false}
						disableFullscreenUI={true}
						onBlur={this.onEmailSubmit.bind(this)}
						onFocus={() => this.setState({ emailActive: true })}
						onSubmitEditing={this.onEmailSubmit.bind(this)}
						onChangeText={(text) => this.onEmailChange(text)}
						value={this.state.email}
						editable={true}
						selectTextOnFocus={true}
						autoCapitalize="none"
						autoCorrect={false}
						spellCheck={false}
						textContentType="oneTimeCode"
						underlineColorAndroid="transparent"
						style={_inputStyle}
						placeholder={this.props.lang.emailSignupForm.email.placeHolder}
						placeholderTextColor="#8a8a8a"
					/>
				</ImageBackground>
			</View>
		);
	}

	renderEmailError() {
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26, width: 600 }];
		}
		return (
			<View style={styles.dataErrorContainer}>
				{!this.state.emailValid && (
					<Text allowFontScaling={false} style={_errorStyle}>
						{this.props.upgradeAccountError.email}
					</Text>
				)}
			</View>
		);
	}

	renderUsername() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = styles.inputStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26, width: 300 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataInnerContainer}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.emailSignupForm.userName.title}
				</Text>
				<ImageBackground
					source={this.state.usernameValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					<TextInput
						allowFontScaling={false}
						disableFullscreenUI={true}
						onBlur={this.onUsernameSubmit.bind(this)}
						onFocus={() => this.setState({ usernameActive: true })}
						onSubmitEditing={this.onUsernameSubmit.bind(this)}
						onChangeText={(text) => this.onUsernameChange(text)}
						value={this.state.username}
						editable={true}
						selectTextOnFocus={true}
						autoCapitalize="none"
						autoCorrect={false}
						spellCheck={false}
						textContentType="oneTimeCode"
						underlineColorAndroid="transparent"
						style={_inputStyle}
						placeholder={this.props.lang.emailSignupForm.userName.placeHolder}
						placeholderTextColor="#8a8a8a"
					/>
				</ImageBackground>
			</View>
		);
	}

	renderUsernameError() {
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26, width: 600 }];
		}
		return (
			<View style={styles.dataErrorContainer}>
				{!this.state.usernameValid && (
					<Text allowFontScaling={false} style={_errorStyle}>
						{this.props.upgradeAccountError.username}
					</Text>
				)}
			</View>
		);
	}

	renderPwdField() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = styles.inputStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26, width: 300 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataInnerContainer}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.emailLoginForm.pwd.title}
				</Text>
				<ImageBackground
					source={this.state.pwdValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					<TextInput
						allowFontScaling={false}
						disableFullscreenUI={true}
						onBlur={this.onPwdSubmit.bind(this)}
						onFocus={() => this.setState({ pwdActive: true })}
						onSubmitEditing={this.onPwdSubmit.bind(this)}
						onChangeText={(text) => this.onPwdChange(text)}
						value={this.state.pwd}
						editable={true}
						selectTextOnFocus={true}
						autoCapitalize="none"
						underlineColorAndroid="transparent"
						style={_inputStyle}
						secureTextEntry={true}
						placeholder={this.props.lang.emailLoginForm.pwd.placeHolder}
						placeholderTextColor="#8a8a8a"
					/>
				</ImageBackground>
			</View>
		);
	}

	renderPwdError() {
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26, width: 600 }];
		}
		return (
			<View style={styles.dataErrorContainer}>
				{!this.state.pwdValid && (
					<Text allowFontScaling={false} style={_errorStyle}>
						{this.props.upgradeAccountError.pwd}
					</Text>
				)}
			</View>
		);
	}

	renderEnoughOldCheck() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_checkBoxStyle = styles.checkBox;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26, width: 300 }];
			_checkBoxStyle = [styles.checkBox, { width: 30, height: 30 }];
		}
		return (
			<View style={[styles.dataInnerContainer, { alignItems: "center" }]}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.emailSignupForm.enoughOld.title}
				</Text>
				<View style={styles.checkboxContainer}>
					<CheckBox
						value={this.state.isEnoughOld}
						onValueChange={this.onEnoughOldChange.bind(this)}
						style={_checkBoxStyle}
					/>
					{this.renderEnoughOldError()}
				</View>
			</View>
		);
	}

	renderEnoughOldError() {
		return (
			<View style={styles.enoughOldError}>
				{!this.state.isFormValid && !this.state.isEnoughOld && (
					<Text
						allowFontScaling={false}
						style={[
							styles.dataTitle,
							styles.dataText,
							styles.fieldError,
							{
								width: 300,
								textAlign: "left",
								paddingTop: this.props.resolution === RESOLUTION.HIGH ? 0 : 5,
								fontSize: this.props.resolution === RESOLUTION.HIGH ? 26 : 20,
							},
						]}
					>
						{this.props.lang.emailSignupForm.enoughOld.errorMsg}
					</Text>
				)}
			</View>
		);
	}

	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.upgradeAccountState != processState.STARTED}
				backAction={this.props.goBackToMain}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	render() {
		if (this.props.upgradeAccountState == processState.STARTED)
			return (
				<ImageBackground source={this.props.DEFAULT_BACK.url} style={{ flex: 1 }}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		return (
			<ImageBackground source={this.props.DEFAULT_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={{ flex: 1 }} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderUsername()}
					{this.renderUsernameError()}
					{this.renderPwdField()}
					{this.renderPwdError()}
					{this.renderEmailField()}
					{this.renderEmailError()}
					{this.renderEnoughOldCheck()}
					{this.renderUpgradeButton()}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	isFormValidated() {
		try {
			this.setState({
				isFormValid: this.state.isEnoughOld,
			});
			return this.state.isEnoughOld;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	fullWidth: { width: "100%" },
	container: {
		flex: 1,
	},
	containerContent: {
		minHeight: "100%",
		padding: 20,
		paddingTop: 80,
		justifyContent: "center",
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 250,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#fff",
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	buttonContainer: {
		marginTop: 10,
		marginBottom: 10,
		justifyContent: "flex-end",
		alignItems: "flex-end",
		paddingRight: 150,
	},
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 300,
		height: 50,
		marginRight: 2,
		borderRadius: 5,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		outlineWidth: 0,
	},
	dataErrorContainer: {
		width: "100%",
		minHeight: 40,
		marginTop: 0,
		marginBottom: 10,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	checkboxContainer: {
		width: 300,
		height: 40,
		flexDirection: "row",
		paddingTop: 5,
	},
	checkBox: {
		width: 20,
		height: 20,
		alignSelf: "center",
		backgroundColor: "blue",
	},
	enoughOldError: {
		width: 250,
		paddingLeft: 10,
	},
	fieldError: {
		width: 550,
		height: "100%",
		color: "#FE1F01",
		textAlign: "right",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		upgradeAccountState: state.app.upgradeAccountState,
		upgradeAccountError: state.app.upgradeAccountError,
		resolution: state.currentUser.preferences.resolution,
		DEFAULT_BACK: state.images.DEFAULT_BACK,
	};
};

const mapDispatchToProps = { goBackToMain, startUpgradeAccount };

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeAccount);
