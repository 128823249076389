import React, { Component } from "react";
import { Dimensions, Image, Platform, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { LAUREL_URL } from "../../config/connection";
import {
	FEATURE_PERMISSION,
	f_sourcesansprobolditalic,
	isTablet,
	isWebOrTablet,
	RESOLUTION,
	tabType,
} from "../../config/defaults";
import { HEADER_CHIP_ICON, HEADER_GOLD_ICON, HEADER_SILVER_ICON } from "../../config/images";
import { getRankingImageURL } from "../../helpers/commonHelpers";
import {
	closeChipsShop,
	closeGameItemsMenu,
	closeGoldShop,
	closePlayerOverview,
	closeSilverShop,
	closeVIPShop,
	goBackToMain,
	openChipsShop,
	openGoldShop,
	openPlayerOverview,
	openSilverShop,
	openTab,
	startPlay,
} from "../../redux/actions";
import { AppTouchableOpacity } from "../common";
import { handleError } from "../ErrorHandler";

class GameShopStack extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// goldStackWidth: 0,
			// silverStackWidth: 0,
			// chipStackWidth: 0,
			// scoreWidth: 0,
			shouldResize: false,
		};
	}

	//#region lifecycleMethods
	componentDidMount() {
		this.dimensionsSubscription = Dimensions.addEventListener("change", this.resizeItself);
	}
	componentWillUnmount() {
		this.dimensionsSubscription?.remove();
	}
	//#endregion

	//#region events
	// measureStackLayout(event, stackType) {
	//   try {
	//     if (Platform.OS !== "web") event.persist();
	//     switch (stackType) {
	//       case "gold":
	//         this.setState({
	//           goldStackWidth: event.nativeEvent.layout.width,
	//           shouldResize: false,
	//         });
	//         break;
	//       case "silver":
	//         this.setState({
	//           silverStackWidth: event.nativeEvent.layout.width,
	//           shouldResize: false,
	//         });
	//         break;
	//       case "chip":
	//         this.setState({
	//           chipStackWidth: event.nativeEvent.layout.width,
	//           shouldResize: false,
	//         });
	//         break;
	//     }
	//   } catch (error) {
	//     handleError(error);
	//   }
	// }

	resizeItself = () => {
		this.setState({ shouldResize: true });
	};

	handlePlayerScorePress() {
		this.props.openPlayerOverview();
	}

	handleStackPress(stackType) {
		try {
			if (stackType == "gold" || stackType == "silver" || stackType == "chip") {
				this.props.closePlayerOverview();
				if (!this.props.isShopOpen) this.props.goBackToMain();
				if (!this.props.isShopOpen) this.props.startPlay();
				if (!this.props.isShopOpen) this.props.openTab(tabType.SHOP);
				if (this.props.vipShopOpened) this.props.closeVIPShop();
				if (this.props.gameItemsMenuOpened) this.props.closeGameItemsMenu();
				switch (stackType) {
					case "gold":
						if (typeof this.props.inShopGoldPress === "function") {
							this.props.inShopGoldPress();
						} else {
							if (this.props.silverShopOpened) this.props.closeSilverShop();
							if (this.props.chipsShopOpened) this.props.closeChipsShop();
							if (!this.props.goldShopOpened) this.props.openGoldShop();
						}
						break;
					case "silver":
						if (typeof this.props.inShopSilverPress === "function") {
							this.props.inShopSilverPress();
						} else {
							if (this.props.goldShopOpened) this.props.closeGoldShop();
							if (this.props.chipsShopOpened) this.props.closeChipsShop();
							if (!this.props.silverShopOpened) this.props.openSilverShop();
						}
						break;
					case "chip":
						if (typeof this.props.inShopChipPress === "function") {
							this.props.inShopChipPress();
						} else {
							if (this.props.silverShopOpened) this.props.closeSilverShop();
							if (this.props.goldShopOpened) this.props.closeGoldShop();
							if (!this.props.chipsShopOpened) this.props.openChipsShop();
						}
						break;
					default:
						return;
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		try {
			const winDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
			const nWidth = winDim.width - 140;
			const maxButtonWidth = (nWidth - 80) / 4;
			const { stack, ranking, isVip } = this.props.userDetails;
			var rankingImgURL = getRankingImageURL(ranking, 24);
			const laurelImgSrc = isVip ? { uri: LAUREL_URL + "009.png" } : {};
			const stackPermission =
				this.props.shopPermission != FEATURE_PERMISSION.ENABLED ||
				this.props.isGameRoomOpen ||
				this.props.isCreateNewGameOpen ||
				this.props.createTeamOpened ||
				this.props.isTourStatsOpen ||
				this.props.isTourLadderStatsOpen ||
				this.props.isTournamentDetailsOpen ||
				this.props.isTourPayoutOpen ||
				this.props.isPoolOverviewOpen;

			var _stackContainer = [styles.stackContainer, { width: nWidth || 0 }],
				_amountText = styles.amountText,
				_coinImage = styles.coinImage,
				_rankingImageStyle = styles.rankingImage,
				_laurelImgStyle = styles.laurelImg;
			if (this.props.resolution === RESOLUTION.HIGH) {
				_stackContainer = [styles.stackContainer, { width: nWidth || 0, height: 50, paddingTop: 0 }];
				_amountText = [styles.amountText, { fontSize: isWebOrTablet ? 32 : 28, lineHeight: 50 }];
				_coinImage = [styles.coinImage, { width: 42, height: 42, marginTop: 12 }];
				var rankDim = 30;
				if (winDim.scale > 1) {
					if (winDim.scale < 2.5) {
						rankDim = 50;
					} else {
						rankDim = 150;
					}
				}
				rankingImgURL = getRankingImageURL(ranking, rankDim);
				_rankingImageStyle = [styles.rankingImage, { width: 30, height: 30 }];
				_laurelImgStyle = styles.laurelImgHR;
			}

			return (
				<View key="stackContainer" style={_stackContainer}>
					<AppTouchableOpacity
						key="shopGoldStack"
						activeOpacity={0.8}
						style={[styles.buttonStyle, Platform.OS != "web" && { minWidth: 50, minHeight: 50 }]}
						// onLayout={(event) => this.measureStackLayout(event, "gold")}
						onPress={() => this.handleStackPress("gold")}
						disabled={
							stackPermission /*||
              (Platform.OS === "ios" &&
                this.props.username !== "dr.zoidberg" &&
                this.props.username !== "Sziduska")*/
						}
						accessibilityLabel={stack.gold + " gold"}
					>
						<View style={[styles.innerContainer]}>
							<Image resizeMode="stretch" source={HEADER_GOLD_ICON} style={_coinImage} />
							<View style={[styles.amountContainer, { maxWidth: maxButtonWidth }]}>
								<Text allowFontScaling={false} numberOfLines={1} ellipsizeMode="tail" style={_amountText}>
									{stack.gold.toLocaleString()}
								</Text>
							</View>
						</View>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						key="shopSilverStack"
						activeOpacity={0.8}
						style={[styles.buttonStyle, Platform.OS != "web" && { minWidth: 50, minHeight: 50 }]}
						// onLayout={(event) => this.measureStackLayout(event, "silver")}
						onPress={() => this.handleStackPress("silver")}
						disabled={stackPermission}
						accessibilityLabel={stack.silver.toString() + " silver"}
					>
						<View style={styles.innerContainer}>
							<Image resizeMode="stretch" source={HEADER_SILVER_ICON} style={_coinImage} />
							<View style={[styles.amountContainer, { maxWidth: maxButtonWidth }]}>
								<Text allowFontScaling={false} numberOfLines={1} ellipsizeMode="tail" style={_amountText}>
									{stack.silver.toLocaleString()}
								</Text>
							</View>
						</View>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						key="shopChipStack"
						activeOpacity={0.8}
						style={[styles.buttonStyle, Platform.OS != "web" && { minWidth: 50, minHeight: 50 }]}
						// onLayout={(event) => this.measureStackLayout(event, "chip")}
						onPress={() => this.handleStackPress("chip")}
						disabled={stackPermission}
						accessibilityLabel={stack.chip + " chips"}
					>
						<View style={[styles.innerContainer]}>
							<Image resizeMode="stretch" source={HEADER_CHIP_ICON} style={_coinImage} />
							<View style={[styles.amountContainer, { maxWidth: maxButtonWidth }]}>
								<Text allowFontScaling={false} numberOfLines={1} ellipsizeMode="tail" style={_amountText}>
									{stack.chip.toLocaleString()}
								</Text>
							</View>
						</View>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						key="playerScore"
						activeOpacity={0.8}
						style={[styles.buttonStyle, Platform.OS != "web" && { minWidth: 50, minHeight: 50 }]}
						onPress={this.handlePlayerScorePress.bind(this)}
						disabled={
							this.props.isGameRoomOpen ||
							this.props.isCreateNewGameOpen ||
							this.props.createTeamOpened ||
							this.props.isTourStatsOpen ||
							this.props.isTourLadderStatsOpen ||
							this.props.isTournamentDetailsOpen ||
							this.props.isTourPayoutOpen ||
							this.props.isPoolOverviewOpen
						}
						accessibilityLabel={this.props.userDetails.score + " is your score"}
					>
						<View style={[styles.innerContainer]}>
							<Image
								key="ranking"
								resizeMode="contain"
								source={rankingImgURL}
								style={[
									_rankingImageStyle,
									{
										marginRight: isVip ? (Platform.OS === "web" ? 10 : 8) : 3,
									},
								]}
							/>
							<Image key="laurel" resizeMode={"stretch"} source={laurelImgSrc} style={_laurelImgStyle} />
							<View style={[styles.amountContainer, { maxWidth: maxButtonWidth }]}>
								<Text
									allowFontScaling={false}
									numberOfLines={1}
									ellipsizeMode="tail"
									style={[
										_amountText,
										{
											marginTop: this.props.resolution === RESOLUTION.HIGH ? 2 : 0,
										},
									]}
								>
									{this.props.userDetails.score.toLocaleString()}
								</Text>
							</View>
						</View>
					</AppTouchableOpacity>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	stackContainer: {
		flex: 1,
		height: 40,
		paddingTop: 3,
		flexDirection: "row",
		alignContent: "center",
		justifyContent: "space-evenly",
		borderBottomWidth: 1,
		borderBottomColor: "#A1FAFF",
	},
	buttonStyle: {
		// flex: 3,
	},
	innerContainer: {
		flexDirection: "row",
		justifyContent: "center",
		// paddingRight: 8,
	},
	amountContainer: {
		width: "auto",
		flexDirection: "row",
		overflow: "hidden",
	},
	amountText: {
		paddingHorizontal: 3,
		fontFamily: f_sourcesansprobolditalic,
		fontSize: isTablet ? 22 : 18,
		textAlign: "left",
		lineHeight: 40,
		alignSelf: "center",
		color: "#FFFFFF",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
	},
	coinImage: {
		width: 28,
		height: 28,
		alignSelf: "center",
		marginTop: 8,
	},
	laurelImg: {
		width: 42, //46,
		height: 42, // 46,
		position: "absolute",
		top: Platform.OS === "web" ? -1 : -0.5, //-3,
		left: Platform.OS === "web" ? -11 : -10, //-13,
	},
	laurelImgHR: {
		width: 56, // 62,
		height: 56, // 62,
		position: "absolute",
		top: -3, // -6,
		left: -13, // -16,
	},
	rankingImage: {
		width: Platform.OS === "web" ? 20 : 22,
		height: Platform.OS === "web" ? 20 : 22,
		alignSelf: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		resolution: state.currentUser.preferences.resolution,
		isSmallResolution: state.views.isSmallResolution,
		isLandscape: state.views.isLandscape,
		gameShopWidth: state.views.gameShopWidth,
		userDetails: state.currentUser.userDetails,
		shopPermission: state.currentUser.features.generic.shop,
		isShopOpen: state.tab.isShopOpen,
		isCreateNewGameOpen: state.tab.isCreateNewGameOpen,
		isGameRoomOpen: state.tab.isGameRoomOpen,
		goldShopOpened: state.gameShop.goldShopOpened,
		silverShopOpened: state.gameShop.silverShopOpened,
		chipsShopOpened: state.gameShop.chipsShopOpened,
		vipShopOpened: state.gameShop.vipShopOpened,
		gameItemsMenuOpened: state.app.gameItemsMenuOpened,
		createTeamOpened: state.tournaments.createTeamOpened,
		isTourStatsOpen: state.tournaments.isTourStatsOpen,
		isTourPayoutOpen: state.tournaments.isTourPayoutOpen,
		isTourLadderStatsOpen: state.tournaments.isTourLadderStatsOpen,
		isTournamentDetailsOpen: state.tournaments.isTournamentDetailsOpen,
		isPoolOverviewOpen: state.tournaments.isPoolOverviewOpen,
		// username: state.currentUser.userDetails.username,
	};
};

const mapDispatchToProps = {
	closeChipsShop,
	closeGameItemsMenu,
	closeGoldShop,
	closePlayerOverview,
	closeSilverShop,
	closeVIPShop,
	goBackToMain,
	openChipsShop,
	openGoldShop,
	openPlayerOverview,
	openSilverShop,
	openTab,
	startPlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameShopStack);
