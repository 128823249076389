import Analytics from "../../components/Analytics/Analytics";
import { mySounds } from "../../config/sounds";
import { MyWebSocket } from "../../connection";
import {
	CLEAR_QUERIED_USERS,
	CLOSE_COMPOSE_MESSAGE,
	CLOSE_MESSAGE_DETAILS,
	OPEN_COMPOSE_MESSAGE,
	OPEN_MESSAGE_DETAILS,
	OPEN_MESSAGE_INBOX,
	RECEIVE_NEW_MESSAGE,
	SAVE_MESSAGE_LIST,
	TOGGLE_CHECK_MESSAGE,
	UPDATE_MESSAGE_DETAILS,
} from "../actionTypes";
import { store } from "../store";

export const openMessageInbox = () => {
	getMessageList();
	Analytics.logEvent("openInbox");
	return { type: OPEN_MESSAGE_INBOX };
};

export const openComposeMessage = (isReply = false, isForwarded = false, selectedUser) => {
	if (isReply) {
		Analytics.logEvent("replyMessage");
	} else if (isForwarded) {
		Analytics.logEvent("forwardMessage");
	} else {
		Analytics.logEvent("composeMessage");
	}
	return {
		type: OPEN_COMPOSE_MESSAGE,
		payload: { isReply, isForwarded, selectedUser },
	};
};

export const closeComposeMessage = () => {
	getMessageList();
	return { type: CLOSE_COMPOSE_MESSAGE };
};

export const toggleCheckMessage = (messageID) => {
	return { type: TOGGLE_CHECK_MESSAGE, payload: { messageID } };
};

export const openMessageDetails = (selMessageID) => {
	Analytics.logEvent("readMessage");
	if (selMessageID != undefined) getMessage(selMessageID);
	return { type: OPEN_MESSAGE_DETAILS };
};

export const updateMessageDetails = (msg) => {
	return { type: UPDATE_MESSAGE_DETAILS, payload: msg };
};

export const closeMessageDetails = () => {
	getMessageList();
	return { type: CLOSE_MESSAGE_DETAILS };
};

export const saveMessageList = (msg) => {
	return { type: SAVE_MESSAGE_LIST, payload: msg };
};

export const receiveNewMessage = (msg) => {
	if (store.getState().gameMessage.isInboxOpened) getMessageList();
	if (
		typeof mySounds.messageReceived !== "undefined" &&
		mySounds.messageReceived != null &&
		mySounds.messageReceived.isLoaded() == true
	) {
		mySounds.messageReceived.play();
	}
	return { type: RECEIVE_NEW_MESSAGE, payload: msg };
};

export const clearQueriedUsers = () => {
	return { type: CLEAR_QUERIED_USERS };
};

const getMessageList = () => {
	MyWebSocket.shared.sendMsg({
		type: "getMessageList",
		sMessageID: 0,
	});
};

const getMessage = (messageID) => {
	MyWebSocket.shared.sendMsg({
		type: "getMessage",
		sMessageID: 0,
		messageID: messageID,
	});
};
