import { sortBy } from "lodash";
import React, { Component } from "react";
import { Dimensions, FlatList, Image, ImageBackground, Platform, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprolight,
	f_sourcesansprosemibold,
	processState,
	RESOLUTION,
} from "../../../../../config/defaults";
import { appLanguageDimensions, gameDimensions } from "../../../../../config/dimensions";
import { ARROW, TEXT_INPUT } from "../../../../../config/images";
import { mapObject } from "../../../../../helpers/commonHelpers";
import { closeAppLanguageSettings, goBackToMain, updateAppLanguage } from "../../../../../redux/actions";
import Analytics from "../../../../Analytics/Analytics";
import { AppTouchableOpacity, Box, ColoredButton, NavigationHeader, Spinner } from "../../../../common";
import { handleError } from "../../../../ErrorHandler";

class AppLanguage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			availableAppLanguages: this.getAvailableLanguages(),
			language: this.getSelectedLanguage(),
			isExpanded: false,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("AppLanguagePreferences");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.availableAppLanguages !== this.props.availableAppLanguages) {
			this.setState({
				availableAppLanguages: this.getAvailableLanguages(),
				language: this.getSelectedLanguage(),
			});
		}
	}
	//#endregion

	//#region events
	onUpdatePress() {
		this.props.updateAppLanguage(this.state.language.langID);
	}

	onItemSelect(item) {
		this.setState({ language: item, isExpanded: false });
	}

	onDropButtonPress() {
		this.setState({ isExpanded: !this.state.isExpanded });
	}

	onDropButtonBlur() {
		// this.setState({ isExpanded: false });
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateAppLanguageState != processState.STARTED}
				backAction={this.props.closeAppLanguageSettings}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={this.state.language === null || this.props.lang.languageID == this.state.language.langID}
				/>
			</View>
		);
	}

	renderListRow(item) {
		var buttonStyle = this.state.language == item ? { backgroundColor: "#ddd" } : { backgroundColor: "#fff" };
		var _textStyle = [styles.normalText, { paddingLeft: 6, paddingRight: 6 }];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_textStyle = [styles.normalText, { fontSize: 26, paddingLeft: 6, paddingRight: 6, width: 360 }];
			buttonStyle = { ...buttonStyle, width: 400 };
		}
		return (
			<AppTouchableOpacity
				activeOpacity={1}
				style={[styles.itemButtonStyle, buttonStyle]}
				onPress={() => this.onItemSelect(item)}
			>
				<Text allowFontScaling={false} selectable={false} numberOfLines={1} ellipsizeMode="tail" style={_textStyle}>
					{item.name}
				</Text>
			</AppTouchableOpacity>
		);
	}

	renderDropDownList() {
		if (this.state.isExpanded) {
			return (
				<Box
					style={[
						styles.boxStyle,
						{
							width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300,
							maxHeight: this.props.resolution === RESOLUTION.HIGH ? 300 : 170,
						},
					]}
				>
					<FlatList
						nestedScrollEnabled={true}
						keyExtractor={(item, index) => "cl_" + index.toString()}
						data={this.state.availableAppLanguages}
						extraData={this.props}
						renderItem={({ item }) => this.renderListRow(item)}
						getItemLayout={(item, index) => ({
							length: 40,
							offset: 40 * index,
							index,
						})}
						style={{ backgroundColor: "#fff", position: "relative", zIndex: 1 }}
					/>
				</Box>
			);
		}
		return null;
	}

	renderDropDown() {
		var _textStyle = [styles.normalText, styles.selectedTextContainer];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_textStyle = [styles.normalText, styles.selectedTextContainer, { fontSize: 26, width: 360 }];
		}
		return (
			<View style={{ flexDirection: "row" }}>
				<AppTouchableOpacity
					activeOpacity={1}
					onPress={this.onDropButtonPress.bind(this)}
					style={{
						flexDirection: "row",
						width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300,
					}}
					onBlur={this.onDropButtonBlur.bind(this)}
				>
					<Image
						source={TEXT_INPUT}
						resizeMode="stretch"
						style={{
							width: this.props.resolution === RESOLUTION.HIGH ? 400 : 300,
							height: 50,
							position: "absolute",
							zIndex: -1,
						}}
					/>
					<Text allowFontScaling={false} selectable={false} numberOfLines={1} ellipsizeMode="tail" style={_textStyle}>
						{this.state.language !== null ? this.state.language.name : ""}
					</Text>
					<Image source={ARROW} style={!this.state.isExpanded ? [styles.dropIcon, styles.mirror] : styles.dropIcon} />
				</AppTouchableOpacity>
				{this.renderDropDownList()}
			</View>
		);
	}

	renderLanguageField() {
		var _innerContainerStyle = styles.dataInnerContainer,
			_inputLabel = [styles.dataTitle, styles.dataText];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { height: 80 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
		}
		return (
			<View style={_innerContainerStyle}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.appLanguage.dropdownTitle}
				</Text>
				{this.renderDropDown()}
			</View>
		);
	}

	renderInfo() {
		var _infoStyle = [styles.dataText, styles.infoText];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_infoStyle = [styles.dataText, styles.infoText, { fontSize: 24, lineHeight: 30 }];
		}
		return (
			<View
				style={{
					width: "100%",
					paddingHorizontal: 40,
					marginTop: 20,
				}}
			>
				<Text allowFontScaling={false} style={_infoStyle}>
					{this.props.lang.appLanguage.info}
				</Text>
			</View>
		);
	}

	renderContent() {
		return (
			<View
				style={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				{this.renderLanguageField()}
				{this.renderInfo()}
				{this.renderUpdateButton()}
			</View>
		);
	}

	render() {
		if (this.props.updateAppLanguageState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);

		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 80;
			if (appLanguageDimensions.width > availableWidth || appLanguageDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / appLanguageDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / appLanguageDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}

	getAvailableLanguages() {
		try {
			var _languages = [];
			mapObject(this.props.availableAppLanguages, (key, val) => {
				_languages.push({
					langID: key.toLowerCase(),
					name: val,
				});
			});
			_languages = sortBy(_languages, (item) => item.name);
			return _languages;
		} catch (error) {
			handleError(error);
		}
	}

	getSelectedLanguage() {
		try {
			var selectedLangID = Object.keys(this.props.availableAppLanguages).find(
				(key) => key.toLowerCase() == this.props.lang.languageID
			);

			const selectedLang =
				selectedLangID == undefined
					? null
					: {
							langID: this.props.lang.languageID.toLowerCase(),
							name: this.props.availableAppLanguages[selectedLangID],
					  };
			return selectedLang;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	contentContainer: { flex: 1, paddingHorizontal: 20 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprosemibold, fontSize: 20 },
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: 26,
		marginTop: 20,
		marginBottom: 20,
		fontSize: 18,
	},
	dropIcon: {
		width: 15,
		height: 10,
		marginLeft: 5,
		alignSelf: "center",
	},
	mirror: {
		transform: [{ rotate: "180deg" }],
	},
	boxStyle: {
		position: "absolute",
		top: 45,
		left: 0,
		width: 300,
		minHeight: 40,
		maxHeight: 170,
		borderRadius: 0,
		backgroundColor: "#fff",
	},
	itemButtonStyle: {
		width: 300,
		backgroundColor: "#fff",
	},
	normalText: {
		fontFamily: f_sourcesansprobold,
		fontSize: 18,
		marginTop: Platform.OS === "web" ? 0 : 2,
		color: "#555",
	},
	selectedTextContainer: {
		fontFamily: f_sourcesansprolight,
		textAlign: "center",
		width: 260,
		fontSize: 20,
		borderRadius: 5,
		marginRight: 5,
		paddingTop: Platform.OS === "web" ? 0 : 2,
		paddingLeft: 7,
		paddingRight: 7,
		height: 50,
		lineHeight: 50,
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		availableAppLanguages: state.currentUser.preferences.availableAppLanguages,
		updateAppLanguageState: state.app.updateAppLanguageState,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeAppLanguageSettings,
	goBackToMain,
	updateAppLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLanguage);
