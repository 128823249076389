import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { Dimensions, ImageBackground, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprolight,
	f_sourcesansproregular,
	processState,
	RESOLUTION,
} from "../../../config/defaults";
import { emailTroubleDimensions, gameDimensions } from "../../../config/dimensions";
import { TEXT_INPUT, TEXT_INPUT_ERROR } from "../../../config/images";
import { validateConfirmPwd } from "../../../helpers/validationHelpers";
import { changePassword, closeLogInTrouble, closeResetPassword, goBackToMain } from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../../common";
import { handleError } from "../../ErrorHandler";

class SetNewPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			scrollViewHeight: 50,
			code: "",
			codeValid: true,
			codeActive: false,
			newPwd: "",
			newPwdValid: true,
			newPwdActive: false,
			confirmPwd: "",
			confirmPwdValid: true,
			confirmPwdActive: false,
			isFormValid: true,
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		Analytics.logScreenView("SetNewPassword");
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.changePasswordState != this.props.changePasswordState &&
			!prevProps.changePasswordSucceeded &&
			!this.props.changePasswordSucceeded
		) {
			const isCodeValid = this.props.changePasswordError.code == "";
			const isPwdValid = this.props.changePasswordError.password == "";
			const isConfPwdValid = this.props.changePasswordError.confirmPassword == "";
			const isValid = isCodeValid && isPwdValid && isConfPwdValid;
			this.setState({
				codeValid: isCodeValid,
				newPwdValid: isPwdValid,
				confirmPwdValid: isConfPwdValid,
				isFormValid: isValid,
			});
		}
	}
	//#endregion

	//#region events
	measureScrollView(event) {
		try {
			const { height } = event.nativeEvent.layout;
			this.setState({ scrollViewHeight: height });
		} catch (error) {
			handleError(error);
		}
	}

	onInputSubmit(type) {
		switch (type) {
			case "code":
				this.setState({ codeActive: false });
				break;
			case "pwd":
				this.setState({ newPwdActive: false });
				break;
			case "confirm":
				this.setState({ confirmPwdActive: false });
				break;
		}
	}
	onInputChange(type, text) {
		switch (type) {
			case "code":
				this.setState({ code: text });
				break;
			case "pwd":
				this.setState({ newPwd: text });
				break;
			case "confirm":
				this.setState({ confirmPwd: text });
				break;
		}
	}
	onInputFocus(type) {
		switch (type) {
			case "code":
				this.setState({ codeActive: true });
				break;
			case "pwd":
				this.setState({ newPwdActive: true });
				break;
			case "confirm":
				this.setState({ confirmPwdActive: true });
				break;
		}
	}
	onButtonPress() {
		if (this.isFormValidated()) {
			this.props.changePassword(this.props.username, this.state.code, this.state.newPwd);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.changePasswordState != processState.STARTED}
				backAction={this.props.closeLogInTrouble}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onButtonPress.bind(this)}
					textContent={this.props.lang.loginTrouble.reset}
					accessibilityLabel={this.props.lang.loginTrouble.reset}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderInputError(type) {
		var condition = true,
			textContent = "";
		switch (type) {
			case "code":
				condition = this.state.codeValid;
				textContent =
					this.state.code.length == 4
						? this.props.changePasswordError.code
						: this.props.lang.loginTrouble.code.errorMsg;
				break;
			case "pwd":
				condition = this.state.newPwdValid;
				textContent = this.props.changePasswordError.password;
				break;
			case "confirm":
				condition = this.state.confirmPwdValid;
				textContent = this.props.lang.emailSignupForm.confirmPwd.errorMsg;
				break;
		}
		if (condition) return null;
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [
				styles.dataTitle,
				styles.dataText,
				styles.fieldError,
				{ fontSize: 26, width: 600, lineHeight: 30 },
			];
		}
		return (
			<View style={styles.dataErrorContainer}>
				<Text allowFontScaling={false} style={_errorStyle}>
					{textContent}
				</Text>
			</View>
		);
	}

	renderInputField(type) {
		var titleContent = "",
			inputValue,
			placeHolder = "",
			validState = true;
		switch (type) {
			case "code":
				titleContent = this.props.lang.loginTrouble.code.title;
				placeHolder = this.props.lang.loginTrouble.code.placeHolder;
				inputValue = this.state.code;
				validState = this.state.codeValid;
				break;
			case "pwd":
				titleContent = this.props.lang.emailLoginForm.pwd.title;
				placeHolder = this.props.lang.emailLoginForm.pwd.placeHolder;
				inputValue = this.state.newPwd;
				validState = this.state.newPwdValid;
				break;
			case "confirm":
				titleContent = this.props.lang.emailSignupForm.confirmPwd.title;
				placeHolder = this.props.lang.emailSignupForm.confirmPwd.placeHolder;
				inputValue = this.state.confirmPwd;
				validState = this.state.confirmPwdValid;
				break;
		}
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = styles.inputStyle;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26, width: 300 }];
			_inputStyle = [styles.inputStyle, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataInnerContainer}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{titleContent}
				</Text>
				<ImageBackground
					source={validState ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					<TextInput
						allowFontScaling={false}
						disableFullscreenUI={true}
						onBlur={() => this.onInputSubmit(type)}
						onFocus={() => this.onInputFocus(type)}
						onSubmitEditing={() => this.onInputSubmit(type)}
						onChangeText={(text) => this.onInputChange(type, text)}
						value={inputValue}
						editable={true}
						selectTextOnFocus={true}
						autoCapitalize="none"
						textContentType="none"
						secureTextEntry={type != "code"}
						underlineColorAndroid="transparent"
						style={_inputStyle}
						placeholder={placeHolder}
						placeholderTextColor="#8a8a8a"
						maxLength={type == "code" ? 4 : 30}
					/>
				</ImageBackground>
			</View>
		);
	}

	renderForm() {
		return (
			<View style={styles.formContainer}>
				{this.renderInputField("code")}
				{this.renderInputError("code")}
				{this.renderInputField("pwd")}
				{this.renderInputError("pwd")}
				{this.renderInputField("confirm")}
				{this.renderInputError("confirm")}
			</View>
		);
	}

	renderContent() {
		var _titleStyle = styles.titleStyle,
			_infoStyle = styles.contentText;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_titleStyle = [styles.titleStyle, { fontSize: 32 }];
			_infoStyle = [styles.contentText, { fontSize: 26, lineHeight: undefined }];
		}
		return (
			<View style={styles.usernameContainer}>
				<View style={styles.titleContainer}>
					<Text allowFontScaling={false} style={_titleStyle}>
						{this.props.lang.loginTrouble.emailSent.title}
					</Text>
				</View>
				<ScrollView
					contentContainerStyle={styles.containerContent}
					centerContent={true}
					style={{
						flex: 1,
						marginVertical: 10,
						height: Dimensions.get("window").height - 40,
					}}
					nestedScrollEnabled={true}
					showsVerticalScrollIndicator={false}
					onLayout={(event) => this.measureScrollView(event)}
				>
					<CustomScroll
						allowOuterScroll={true}
						heightRelativeToParent={this.state.scrollViewHeight - 10 + "px"}
						minScrollHandleHeight={50}
					>
						<Text allowFontScaling={false} style={_infoStyle}>
							{this.props.lang.loginTrouble.emailSent.text}
						</Text>
						{this.renderForm()}
					</CustomScroll>
				</ScrollView>
				{this.renderButton()}
			</View>
		);
	}

	render() {
		if (this.props.changePasswordState == processState.STARTED)
			return (
				<ImageBackground source={this.props.DEFAULT_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		return (
			<ImageBackground source={this.props.DEFAULT_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120;
			const availableHeight = windowDim.height - 100;
			if (emailTroubleDimensions.width > availableWidth || emailTroubleDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / emailTroubleDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / emailTroubleDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}

	isFormValidated() {
		try {
			const isCodeValid = this.state.code.length == 4;
			const isConfirmPwdValid = validateConfirmPwd(this.state.newPwd, this.state.confirmPwd);
			const isFormValid = isCodeValid && isConfirmPwdValid;
			this.setState({
				codeValid: isCodeValid,
				confirmPwdValid: isConfirmPwdValid,
				isFormValid: isFormValid,
			});

			return isFormValid;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		padding: 20,
	},
	buttonContainer: {
		marginRight: 60,
		alignSelf: "flex-end",
		justifyContent: "flex-end",
		alignItems: "flex-end",
	},
	titleContainer: {
		width: "85%",
		height: 50,
		alignSelf: "center",
	},
	titleStyle: {
		width: "100%",
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 28,
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
		textAlign: "center",
	},
	usernameContainer: { flex: 1, paddingHorizontal: 20 },
	containerContent: {
		minHeight: "100%",
		paddingHorizontal: 10,
		justifyContent: "center",
	},
	contentText: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansproregular,
		fontSize: 18,
		color: "#FFFFFF",
		marginTop: 20,
	},
	formContainer: { flex: 1, marginRight: 5, marginTop: 30 },
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 300,
		height: 50,
		marginRight: 2,
		borderRadius: 5,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		outlineWidth: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#fff",
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	dataErrorContainer: {
		width: "100%",
		height: 40,
		marginTop: 3,
		marginBottom: 3,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	fieldError: {
		width: 500,
		height: "100%",
		color: "#FE1F01",
		textAlign: "right",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		changePasswordSucceeded: state.app.changePasswordSucceeded,
		changePasswordState: state.app.changePasswordState,
		changePasswordError: state.app.changePasswordError,
		resolution: state.currentUser.preferences.resolution,
		DEFAULT_BACK: state.images.DEFAULT_BACK,
	};
};

const mapDispatchToProps = {
	changePassword,
	closeLogInTrouble,
	closeResetPassword,
	goBackToMain,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPassword);
