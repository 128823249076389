import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { gameDetailsTableKey } from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { GameRuleDetails } from "../../Keezers/components/common";
import {
	clearGameSelection,
	goBackToMain,
	resetStartedGame,
	// subscribeToGameList,
	unsubscribeFromGameList,
} from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { HeaderText, NavigationHeader } from "../common";
import GameShopStack from "../GameShop/GameShopStack";
import MainNavigation from "../MainNavigation";
import GameDetailsTable from "./GameDetailsTable";
import GameListTable from "./GameListTable";
import GameLobbyButtons from "./GameLobbyButtons";

class GameLobby extends Component {
	constructor(props) {
		super(props);
		this.requestingChat = false;
	}

	//#region lifecycle methods
	async componentDidMount() {
		this.props.resetStartedGame();
		this.props.clearGameSelection();
		await Analytics.logEvent("openGameLobby");
		await Analytics.logScreenView("GameLobby");
	}

	componentWillUnmount() {
		if (!this.requestingChat) this.props.unsubscribeFromGameList();
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.goBackToMain}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			>
				<GameShopStack />
			</NavigationHeader>
		);
	}

	renderGameList() {
		return (
			<View style={styles.halfWidth}>
				<HeaderText
					textContent={this.props.lang.gameLobby.gameList.gameListHeader}
					style={{ height: gameDimensions.boxHeaderHeight }}
				/>
				<GameListTable />
			</View>
		);
	}

	renderGameDetails() {
		return (
			<View style={[styles.halfWidth, { marginLeft: 5 }]}>
				<View style={{ height: this.props.gameDetailsHeight }}>
					<HeaderText
						textContent={this.props.lang.gameLobby.gameDetails.gameDetailsHeader}
						style={{ height: gameDimensions.boxHeaderHeight }}
					/>
					<View style={styles.gameDetailsContainer}>
						<GameDetailsTable isGameCreator={false} tableKey={gameDetailsTableKey.selectedGame} />
						<GameRuleDetails
							rulesList={this.props.selectedGamesRulesList}
							tableKey={gameDetailsTableKey.selectedGame}
						/>
					</View>
				</View>
				<GameLobbyButtons style={{ width: "100%" }} />
			</View>
		);
	}

	render() {
		const { isLobbyOpen, isTabGamesButtonActive, isTabShopButtonActive } = this.props;
		if (!isLobbyOpen || !isTabGamesButtonActive || isTabShopButtonActive) return null;
		return (
			<View style={styles.container}>
				{this.renderHeader()}
				<View style={styles.detailsContainer}>
					<MainNavigation
						requestingChat={() => {
							this.requestingChat = true;
						}}
					/>
					<View style={styles.detailsInnerContainer}>
						{this.renderGameList()}
						{this.renderGameDetails()}
					</View>
				</View>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: { flex: 1 },
	detailsContainer: {
		flex: 1,
		flexDirection: "row",
		paddingHorizontal: 3,
		marginTop: 50,
		marginBottom: 3,
	},
	detailsInnerContainer: { flex: 1, flexDirection: "row" },
	halfWidth: { flex: 2 },
	gameDetailsContainer: {
		flex: 1,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isLobbyOpen: state.tab.isLobbyOpen,
		isTabGamesButtonActive: state.tab.isTabGamesButtonActive,
		isTabShopButtonActive: state.tab.isTabShopButtonActive,
		gameDetailsHeight: state.views.gameDetailsHeight,
		selectedGamesRulesList: state.gameListData.selectedGamesRulesList,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {
	clearGameSelection,
	goBackToMain,
	resetStartedGame,
	// subscribeToGameList,
	unsubscribeFromGameList,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameLobby);
