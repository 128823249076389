export const panelImage = {
	TL: {
		black: require("../assets/img/panels/black/PlayerpanelTL.png").default,
		blue: require("../assets/img/panels/blue/PlayerpanelTL.png").default,
		brown: require("../assets/img/panels/brown/PlayerpanelTL.png").default,
		green: require("../assets/img/panels/green/PlayerpanelTL.png").default,
		pink: require("../assets/img/panels/pink/PlayerpanelTL.png").default,
		purple: require("../assets/img/panels/purple/PlayerpanelTL.png").default,
		red: require("../assets/img/panels/red/PlayerpanelTL.png").default,
		yellow: require("../assets/img/panels/yellow/PlayerpanelTL.png").default,
	},
	TR: {
		black: require("../assets/img/panels/black/PlayerpanelTR.png").default,
		blue: require("../assets/img/panels/blue/PlayerpanelTR.png").default,
		brown: require("../assets/img/panels/brown/PlayerpanelTR.png").default,
		green: require("../assets/img/panels/green/PlayerpanelTR.png").default,
		pink: require("../assets/img/panels/pink/PlayerpanelTR.png").default,
		purple: require("../assets/img/panels/purple/PlayerpanelTR.png").default,
		red: require("../assets/img/panels/red/PlayerpanelTR.png").default,
		yellow: require("../assets/img/panels/yellow/PlayerpanelTR.png").default,
	},
	BL: {
		black: require("../assets/img/panels/black/PlayerpanelBL.png").default,
		blue: require("../assets/img/panels/blue/PlayerpanelBL.png").default,
		brown: require("../assets/img/panels/brown/PlayerpanelBL.png").default,
		green: require("../assets/img/panels/green/PlayerpanelBL.png").default,
		pink: require("../assets/img/panels/pink/PlayerpanelBL.png").default,
		purple: require("../assets/img/panels/purple/PlayerpanelBL.png").default,
		red: require("../assets/img/panels/red/PlayerpanelBL.png").default,
		yellow: require("../assets/img/panels/yellow/PlayerpanelBL.png").default,
	},
	BR: {
		black: require("../assets/img/panels/black/PlayerpanelBR.png").default,
		blue: require("../assets/img/panels/blue/PlayerpanelBR.png").default,
		brown: require("../assets/img/panels/brown/PlayerpanelBR.png").default,
		green: require("../assets/img/panels/green/PlayerpanelBR.png").default,
		pink: require("../assets/img/panels/pink/PlayerpanelBR.png").default,
		purple: require("../assets/img/panels/purple/PlayerpanelBR.png").default,
		red: require("../assets/img/panels/red/PlayerpanelBR.png").default,
		yellow: require("../assets/img/panels/yellow/PlayerpanelBR.png").default,
	},
};

export const panelIcons = {
	connected: require("../assets/img/panels/connected.png").default,
	connected_disabled: require("../assets/img/panels/connected_disabled.png").default,
	dealer: require("../assets/img/panels/dealer.png").default,
	dealer_disabled: require("../assets/img/panels/dealer_disabled.png").default,
	disabled: require("../assets/img/panels/disabled.png").default,
	disconnected: require("../assets/img/panels/disconnected.png").default,
	disconnected_disabled: require("../assets/img/panels/disconnected_disabled.png").default,
	player_left: require("../assets/img/panels/player_left.png").default,
	player_left_disabled: require("../assets/img/panels/player_left_disabled.png").default,
	chat_enabled: require("../assets/img/panels/chat_enabled.png").default,
	chat_disabled: require("../assets/img/panels/chat_disabled.png").default,
	ranking: {
		black: require("../assets/img/panels/black/black_rank.png").default,
		blue: require("../assets/img/panels/blue/blue_rank.png").default,
		brown: require("../assets/img/panels/brown/brown_rank.png").default,
		green: require("../assets/img/panels/green/green_rank.png").default,
		pink: require("../assets/img/panels/pink/pink_rank.png").default,
		purple: require("../assets/img/panels/purple/purple_rank.png").default,
		red: require("../assets/img/panels/red/red_rank.png").default,
		yellow: require("../assets/img/panels/yellow/yellow_rank.png").default,
	},
	ranking_vip: {
		black: require("../assets/img/panels/black/VIP_black.png").default,
		blue: require("../assets/img/panels/blue/VIP_blue.png").default,
		brown: require("../assets/img/panels/brown/VIP_brown.png").default,
		green: require("../assets/img/panels/green/VIP_green.png").default,
		pink: require("../assets/img/panels/pink/VIP_pink.png").default,
		purple: require("../assets/img/panels/purple/VIP_purple.png").default,
		red: require("../assets/img/panels/red/VIP_red.png").default,
		yellow: require("../assets/img/panels/yellow/VIP_yellow.png").default,
	},
	team_mate: {
		black: require("../assets/img/panels/black/team_mate_black.png").default,
		blue: require("../assets/img/panels/blue/team_mate_blue.png").default,
		brown: require("../assets/img/panels/brown/team_mate_brown.png").default,
		green: require("../assets/img/panels/green/team_mate_green.png").default,
		pink: require("../assets/img/panels/pink/team_mate_pink.png").default,
		purple: require("../assets/img/panels/purple/team_mate_purple.png").default,
		red: require("../assets/img/panels/red/team_mate_red.png").default,
		yellow: require("../assets/img/panels/yellow/team_mate_yellow.png").default,
	},
	mini_card: {
		black: require("../assets/img/panels/black/pp_icon_card.png").default,
		blue: require("../assets/img/panels/blue/pp_icon_card.png").default,
		brown: require("../assets/img/panels/brown/pp_icon_card.png").default,
		green: require("../assets/img/panels/green/pp_icon_card.png").default,
		pink: require("../assets/img/panels/pink/pp_icon_card.png").default,
		purple: require("../assets/img/panels/purple/pp_icon_card.png").default,
		red: require("../assets/img/panels/red/pp_icon_card.png").default,
		yellow: require("../assets/img/panels/yellow/pp_icon_card.png").default,
	},
	thinking: require("../assets/img/panels/thinking.png").default,
	thinking_disabled: require("../assets/img/panels/thinking_disabled.png").default,
};

export const gameChat = {
	tabArea: {
		low: {
			border: {
				bottom: require("../assets/img/gamechat/tab_area/normal/back/bottom_border.png").default,
				top: require("../assets/img/gamechat/tab_area/normal/back/top_border.png").default,
				left: require("../assets/img/gamechat/tab_area/normal/back/left_border.png").default,
				right: require("../assets/img/gamechat/tab_area/normal/back/right_border.png").default,
			},
			corner: {
				bottom_left: require("../assets/img/gamechat/tab_area/normal/back/bottom_left_corner.png").default,
				bottom_right: require("../assets/img/gamechat/tab_area/normal/back/bottom_right_corner.png").default,
				top_left: require("../assets/img/gamechat/tab_area/normal/back/top_left_corner.png").default,
				top_right: require("../assets/img/gamechat/tab_area/normal/back/top_right_corner.png").default,
			},
			center: require("../assets/img/gamechat/tab_area/normal/back/center_area.png").default,
		},
		normal: {
			back: {
				border: {
					bottom: require("../assets/img/gamechat/tab_area/normal/back/bottom_border.png").default,
					top: require("../assets/img/gamechat/tab_area/normal/back/top_border.png").default,
					left: require("../assets/img/gamechat/tab_area/normal/back/left_border.png").default,
					right: require("../assets/img/gamechat/tab_area/normal/back/right_border.png").default,
				},
				corner: {
					bottom_left: require("../assets/img/gamechat/tab_area/normal/back/bottom_left_corner.png").default,
					bottom_right: require("../assets/img/gamechat/tab_area/normal/back/bottom_right_corner.png").default,
					top_left: require("../assets/img/gamechat/tab_area/normal/back/top_left_corner.png").default,
					top_right: require("../assets/img/gamechat/tab_area/normal/back/top_right_corner.png").default,
				},
				center: require("../assets/img/gamechat/tab_area/normal/back/center_area.png").default,
			},
			activeTab: {
				border: {
					top: require("../assets/img/gamechat/tab_area/normal/active/top_border.png").default,
					left: require("../assets/img/gamechat/tab_area/normal/active/left_border.png").default,
					right: require("../assets/img/gamechat/tab_area/normal/active/right_border.png").default,
				},
				corner: {
					left: require("../assets/img/gamechat/tab_area/normal/active/top_left_corner.png").default,
					right: require("../assets/img/gamechat/tab_area/normal/active/top_right_corner.png").default,
				},
				center: require("../assets/img/gamechat/tab_area/normal/active/center_area.png").default,
			},
			inactiveTab: {
				border: {
					top: require("../assets/img/gamechat/tab_area/normal/inactive/top_border.png").default,
					left: require("../assets/img/gamechat/tab_area/normal/inactive/left_border.png").default,
					right: require("../assets/img/gamechat/tab_area/normal/inactive/right_border.png").default,
				},
				corner: {
					left: require("../assets/img/gamechat/tab_area/normal/inactive/top_left_corner.png").default,
					right: require("../assets/img/gamechat/tab_area/normal/inactive/top_right_corner.png").default,
				},
				center: require("../assets/img/gamechat/tab_area/normal/inactive/center_area.png").default,
			},
			previous: {
				arrow: require("../assets/img/gamechat/tab_area/normal/left/arrow.png").default,
				border: {
					bottom: require("../assets/img/gamechat/tab_area/normal/left/bottom_border.png").default,
					top: require("../assets/img/gamechat/tab_area/normal/left/top_border.png").default,
					left: require("../assets/img/gamechat/tab_area/normal/left/left_border.png").default,
					right: require("../assets/img/gamechat/tab_area/normal/left/right_border.png").default,
				},
				corner: {
					bottom_left: require("../assets/img/gamechat/tab_area/normal/left/bottom_left_corner.png").default,
					top_left: require("../assets/img/gamechat/tab_area/normal/left/top_left_corner.png").default,
					bottom_right: require("../assets/img/gamechat/tab_area/normal/left/bottom_right_corner.png").default,
					top_right: require("../assets/img/gamechat/tab_area/normal/left/top_right_corner.png").default,
				},
				center: require("../assets/img/gamechat/tab_area/normal/left/center.png").default,
			},
			next: {
				arrow: require("../assets/img/gamechat/tab_area/normal/right/arrow.png").default,
				border: {
					bottom: require("../assets/img/gamechat/tab_area/normal/right/bottom_border.png").default,
					top: require("../assets/img/gamechat/tab_area/normal/right/top_border.png").default,
					left: require("../assets/img/gamechat/tab_area/normal/right/left_border.png").default,
					right: require("../assets/img/gamechat/tab_area/normal/right/right_border.png").default,
				},
				corner: {
					bottom_left: require("../assets/img/gamechat/tab_area/normal/right/bottom_left_corner.png").default,
					top_left: require("../assets/img/gamechat/tab_area/normal/right/top_left_corner.png").default,
					bottom_right: require("../assets/img/gamechat/tab_area/normal/right/bottom_right_corner.png").default,
					top_right: require("../assets/img/gamechat/tab_area/normal/right/top_right_corner.png").default,
				},
				center: require("../assets/img/gamechat/tab_area/normal/right/center.png").default,
			},
		},
	},
	background: {
		border: {
			bottom: require("../assets/img/gamechat/background/bottom_border.png").default,
			top: require("../assets/img/gamechat/background/top_border.png").default,
			left: require("../assets/img/gamechat/background/left_border.png").default,
			right: require("../assets/img/gamechat/background/right_border.png").default,
		},
		corner: {
			bottom_left: require("../assets/img/gamechat/background/bottom_left_corner.png").default,
			bottom_right: require("../assets/img/gamechat/background/bottom_right_corner.png").default,
			top_left: require("../assets/img/gamechat/background/top_left_corner.png").default,
			top_right: require("../assets/img/gamechat/background/top_right_corner.png").default,
		},
		center: require("../assets/img/gamechat/background/center_chat_area.png").default,
	},
	input: {
		border: {
			bottom: require("../assets/img/gamechat/input/bottom_border.png").default,
			top: require("../assets/img/gamechat/input/top_border.png").default,
			left: require("../assets/img/gamechat/input/left_border.png").default,
			right: require("../assets/img/gamechat/input/right_border.png").default,
		},
		corner: {
			bottom_left: require("../assets/img/gamechat/input/bottom_left_corner.png").default,
			bottom_right: require("../assets/img/gamechat/input/bottom_right_corner.png").default,
			top_left: require("../assets/img/gamechat/input/top_left_corner.png").default,
			top_right: require("../assets/img/gamechat/input/top_right_corner.png").default,
		},
		center: require("../assets/img/gamechat/input/center_text_area.png").default,
		send: require("../assets/img/gamechat/input/send_back.png").default,
		smile: require("../assets/img/gamechat/input/smile_icon.png").default,
	},
	send_back: require("../assets/img/gamechat/send_back.png").default,
};

export const baseEmptyPos = {
	normal: {
		black: require("../assets/img/positions/board/normal/black_base.png").default,
		blue: require("../assets/img/positions/board/normal/blue_base.png").default,
		brown: require("../assets/img/positions/board/normal/brown_base.png").default,
		green: require("../assets/img/positions/board/normal/green_base.png").default,
		pink: require("../assets/img/positions/board/normal/pink_base.png").default,
		purple: require("../assets/img/positions/board/normal/purple_base.png").default,
		red: require("../assets/img/positions/board/normal/red_base.png").default,
		yellow: require("../assets/img/positions/board/normal/yellow_base.png").default,
		norm: require("../assets/img/positions/board/normal/normal_position.png").default,
	},
	disabled: {
		black: require("../assets/img/positions/board/disabled/black_base.png").default,
		blue: require("../assets/img/positions/board/disabled/blue_base.png").default,
		brown: require("../assets/img/positions/board/disabled/brown_base.png").default,
		green: require("../assets/img/positions/board/disabled/green_base.png").default,
		pink: require("../assets/img/positions/board/disabled/pink_base.png").default,
		purple: require("../assets/img/positions/board/disabled/purple_base.png").default,
		red: require("../assets/img/positions/board/disabled/red_base.png").default,
		yellow: require("../assets/img/positions/board/disabled/yellow_base.png").default,
		norm: require("../assets/img/positions/board/disabled/normal_position.png").default,
	},
	selectable: {
		black: require("../assets/img/positions/board/selectable/black_base.png").default,
		blue: require("../assets/img/positions/board/selectable/blue_base.png").default,
		brown: require("../assets/img/positions/board/selectable/brown_base.png").default,
		green: require("../assets/img/positions/board/selectable/green_base.png").default,
		pink: require("../assets/img/positions/board/selectable/pink_base.png").default,
		purple: require("../assets/img/positions/board/selectable/purple_base.png").default,
		red: require("../assets/img/positions/board/selectable/red_base.png").default,
		yellow: require("../assets/img/positions/board/selectable/yellow_base.png").default,
		norm: require("../assets/img/positions/board/selectable/normal_position.png").default,
	},
	moved: {
		black: require("../assets/img/positions/board/moved/black_base.png").default,
		blue: require("../assets/img/positions/board/moved/blue_base.png").default,
		brown: require("../assets/img/positions/board/moved/brown_base.png").default,
		green: require("../assets/img/positions/board/moved/green_base.png").default,
		pink: require("../assets/img/positions/board/moved/pink_base.png").default,
		purple: require("../assets/img/positions/board/moved/purple_base.png").default,
		red: require("../assets/img/positions/board/moved/red_base.png").default,
		yellow: require("../assets/img/positions/board/moved/yellow_base.png").default,
		norm: require("../assets/img/positions/board/moved/normal_position.png").default,
	},
	starter: {
		black: require("../assets/img/positions/board/starter/black_base.png").default,
		blue: require("../assets/img/positions/board/starter/blue_base.png").default,
		brown: require("../assets/img/positions/board/starter/brown_base.png").default,
		green: require("../assets/img/positions/board/starter/green_base.png").default,
		pink: require("../assets/img/positions/board/starter/pink_base.png").default,
		purple: require("../assets/img/positions/board/starter/purple_base.png").default,
		red: require("../assets/img/positions/board/starter/red_base.png").default,
		yellow: require("../assets/img/positions/board/starter/yellow_base.png").default,
		norm: require("../assets/img/positions/board/starter/normal_position.png").default,
	},
};

export const pawn_img = {
	normal: {
		black_b: {
			black: require("../assets/img/positions/pawn/black_b/normal/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/black_b/normal/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/black_b/normal/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/black_b/normal/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/black_b/normal/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/black_b/normal/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/black_b/normal/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/black_b/normal/yellow_pawn.png").default,
		},
		blue_b: {
			black: require("../assets/img/positions/pawn/blue_b/normal/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/blue_b/normal/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/blue_b/normal/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/blue_b/normal/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/blue_b/normal/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/blue_b/normal/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/blue_b/normal/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/blue_b/normal/yellow_pawn.png").default,
		},
		brown_b: {
			black: require("../assets/img/positions/pawn/brown_b/normal/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/brown_b/normal/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/brown_b/normal/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/brown_b/normal/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/brown_b/normal/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/brown_b/normal/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/brown_b/normal/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/brown_b/normal/yellow_pawn.png").default,
		},
		green_b: {
			black: require("../assets/img/positions/pawn/green_b/normal/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/green_b/normal/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/green_b/normal/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/green_b/normal/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/green_b/normal/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/green_b/normal/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/green_b/normal/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/green_b/normal/yellow_pawn.png").default,
		},
		pink_b: {
			black: require("../assets/img/positions/pawn/pink_b/normal/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/pink_b/normal/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/pink_b/normal/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/pink_b/normal/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/pink_b/normal/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/pink_b/normal/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/pink_b/normal/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/pink_b/normal/yellow_pawn.png").default,
		},
		purple_b: {
			black: require("../assets/img/positions/pawn/purple_b/normal/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/purple_b/normal/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/purple_b/normal/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/purple_b/normal/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/purple_b/normal/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/purple_b/normal/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/purple_b/normal/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/purple_b/normal/yellow_pawn.png").default,
		},
		red_b: {
			black: require("../assets/img/positions/pawn/red_b/normal/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/red_b/normal/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/red_b/normal/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/red_b/normal/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/red_b/normal/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/red_b/normal/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/red_b/normal/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/red_b/normal/yellow_pawn.png").default,
		},
		yellow_b: {
			black: require("../assets/img/positions/pawn/yellow_b/normal/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/yellow_b/normal/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/yellow_b/normal/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/yellow_b/normal/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/yellow_b/normal/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/yellow_b/normal/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/yellow_b/normal/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/yellow_b/normal/yellow_pawn.png").default,
		},
		norm_b: {
			black: require("../assets/img/positions/pawn/norm_b/normal/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/norm_b/normal/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/norm_b/normal/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/norm_b/normal/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/norm_b/normal/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/norm_b/normal/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/norm_b/normal/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/norm_b/normal/yellow_pawn.png").default,
		},
	},
	active: {
		black_b: {
			black: require("../assets/img/positions/pawn/black_b/active/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/black_b/active/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/black_b/active/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/black_b/active/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/black_b/active/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/black_b/active/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/black_b/active/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/black_b/active/yellow_pawn.png").default,
		},
		blue_b: {
			black: require("../assets/img/positions/pawn/blue_b/active/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/blue_b/active/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/blue_b/active/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/blue_b/active/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/blue_b/active/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/blue_b/active/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/blue_b/active/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/blue_b/active/yellow_pawn.png").default,
		},
		brown_b: {
			black: require("../assets/img/positions/pawn/brown_b/active/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/brown_b/active/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/brown_b/active/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/brown_b/active/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/brown_b/active/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/brown_b/active/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/brown_b/active/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/brown_b/active/yellow_pawn.png").default,
		},
		green_b: {
			black: require("../assets/img/positions/pawn/green_b/active/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/green_b/active/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/green_b/active/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/green_b/active/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/green_b/active/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/green_b/active/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/green_b/active/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/green_b/active/yellow_pawn.png").default,
		},
		pink_b: {
			black: require("../assets/img/positions/pawn/pink_b/active/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/pink_b/active/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/pink_b/active/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/pink_b/active/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/pink_b/active/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/pink_b/active/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/pink_b/active/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/pink_b/active/yellow_pawn.png").default,
		},
		purple_b: {
			black: require("../assets/img/positions/pawn/purple_b/active/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/purple_b/active/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/purple_b/active/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/purple_b/active/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/purple_b/active/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/purple_b/active/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/purple_b/active/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/purple_b/active/yellow_pawn.png").default,
		},
		red_b: {
			black: require("../assets/img/positions/pawn/red_b/active/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/red_b/active/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/red_b/active/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/red_b/active/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/red_b/active/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/red_b/active/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/red_b/active/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/red_b/active/yellow_pawn.png").default,
		},
		yellow_b: {
			black: require("../assets/img/positions/pawn/yellow_b/active/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/yellow_b/active/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/yellow_b/active/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/yellow_b/active/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/yellow_b/active/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/yellow_b/active/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/yellow_b/active/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/yellow_b/active/yellow_pawn.png").default,
		},
		norm_b: {
			black: require("../assets/img/positions/pawn/norm_b/active/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/norm_b/active/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/norm_b/active/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/norm_b/active/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/norm_b/active/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/norm_b/active/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/norm_b/active/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/norm_b/active/yellow_pawn.png").default,
		},
	},
	selectable: {
		black_b: {
			black: require("../assets/img/positions/pawn/black_b/selectable/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/black_b/selectable/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/black_b/selectable/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/black_b/selectable/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/black_b/selectable/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/black_b/selectable/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/black_b/selectable/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/black_b/selectable/yellow_pawn.png").default,
		},
		blue_b: {
			black: require("../assets/img/positions/pawn/blue_b/selectable/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/blue_b/selectable/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/blue_b/selectable/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/blue_b/selectable/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/blue_b/selectable/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/blue_b/selectable/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/blue_b/selectable/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/blue_b/selectable/yellow_pawn.png").default,
		},
		brown_b: {
			black: require("../assets/img/positions/pawn/brown_b/selectable/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/brown_b/selectable/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/brown_b/selectable/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/brown_b/selectable/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/brown_b/selectable/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/brown_b/selectable/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/brown_b/selectable/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/brown_b/selectable/yellow_pawn.png").default,
		},
		green_b: {
			black: require("../assets/img/positions/pawn/green_b/selectable/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/green_b/selectable/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/green_b/selectable/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/green_b/selectable/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/green_b/selectable/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/green_b/selectable/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/green_b/selectable/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/green_b/selectable/yellow_pawn.png").default,
		},
		pink_b: {
			black: require("../assets/img/positions/pawn/pink_b/selectable/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/pink_b/selectable/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/pink_b/selectable/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/pink_b/selectable/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/pink_b/selectable/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/pink_b/selectable/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/pink_b/selectable/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/pink_b/selectable/yellow_pawn.png").default,
		},
		purple_b: {
			black: require("../assets/img/positions/pawn/purple_b/selectable/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/purple_b/selectable/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/purple_b/selectable/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/purple_b/selectable/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/purple_b/selectable/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/purple_b/selectable/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/purple_b/selectable/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/purple_b/selectable/yellow_pawn.png").default,
		},
		red_b: {
			black: require("../assets/img/positions/pawn/red_b/selectable/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/red_b/selectable/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/red_b/selectable/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/red_b/selectable/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/red_b/selectable/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/red_b/selectable/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/red_b/selectable/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/red_b/selectable/yellow_pawn.png").default,
		},
		yellow_b: {
			black: require("../assets/img/positions/pawn/yellow_b/selectable/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/yellow_b/selectable/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/yellow_b/selectable/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/yellow_b/selectable/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/yellow_b/selectable/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/yellow_b/selectable/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/yellow_b/selectable/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/yellow_b/selectable/yellow_pawn.png").default,
		},
		norm_b: {
			black: require("../assets/img/positions/pawn/norm_b/selectable/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/norm_b/selectable/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/norm_b/selectable/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/norm_b/selectable/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/norm_b/selectable/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/norm_b/selectable/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/norm_b/selectable/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/norm_b/selectable/yellow_pawn.png").default,
		},
	},
	switched: {
		black_b: {
			black: require("../assets/img/positions/pawn/black_b/switched/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/black_b/switched/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/black_b/switched/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/black_b/switched/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/black_b/switched/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/black_b/switched/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/black_b/switched/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/black_b/switched/yellow_pawn.png").default,
		},
		blue_b: {
			black: require("../assets/img/positions/pawn/blue_b/switched/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/blue_b/switched/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/blue_b/switched/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/blue_b/switched/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/blue_b/switched/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/blue_b/switched/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/blue_b/switched/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/blue_b/switched/yellow_pawn.png").default,
		},
		brown_b: {
			black: require("../assets/img/positions/pawn/brown_b/switched/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/brown_b/switched/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/brown_b/switched/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/brown_b/switched/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/brown_b/switched/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/brown_b/switched/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/brown_b/switched/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/brown_b/switched/yellow_pawn.png").default,
		},
		green_b: {
			black: require("../assets/img/positions/pawn/green_b/switched/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/green_b/switched/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/green_b/switched/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/green_b/switched/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/green_b/switched/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/green_b/switched/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/green_b/switched/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/green_b/switched/yellow_pawn.png").default,
		},
		pink_b: {
			black: require("../assets/img/positions/pawn/pink_b/switched/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/pink_b/switched/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/pink_b/switched/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/pink_b/switched/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/pink_b/switched/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/pink_b/switched/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/pink_b/switched/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/pink_b/switched/yellow_pawn.png").default,
		},
		purple_b: {
			black: require("../assets/img/positions/pawn/purple_b/switched/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/purple_b/switched/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/purple_b/switched/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/purple_b/switched/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/purple_b/switched/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/purple_b/switched/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/purple_b/switched/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/purple_b/switched/yellow_pawn.png").default,
		},
		red_b: {
			black: require("../assets/img/positions/pawn/red_b/switched/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/red_b/switched/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/red_b/switched/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/red_b/switched/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/red_b/switched/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/red_b/switched/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/red_b/switched/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/red_b/switched/yellow_pawn.png").default,
		},
		yellow_b: {
			black: require("../assets/img/positions/pawn/yellow_b/switched/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/yellow_b/switched/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/yellow_b/switched/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/yellow_b/switched/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/yellow_b/switched/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/yellow_b/switched/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/yellow_b/switched/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/yellow_b/switched/yellow_pawn.png").default,
		},
		norm_b: {
			black: require("../assets/img/positions/pawn/norm_b/switched/black_pawn.png").default,
			blue: require("../assets/img/positions/pawn/norm_b/switched/blue_pawn.png").default,
			brown: require("../assets/img/positions/pawn/norm_b/switched/brown_pawn.png").default,
			green: require("../assets/img/positions/pawn/norm_b/switched/green_pawn.png").default,
			pink: require("../assets/img/positions/pawn/norm_b/switched/pink_pawn.png").default,
			purple: require("../assets/img/positions/pawn/norm_b/switched/purple_pawn.png").default,
			red: require("../assets/img/positions/pawn/norm_b/switched/red_pawn.png").default,
			yellow: require("../assets/img/positions/pawn/norm_b/switched/yellow_pawn.png").default,
		},
	},
};
