import { APP_STATE, START_REASON } from "./defaults";

export const BUILD_VERSION = 417;
export const ANDROID_VERSION = "1.0.417";
export const IOS_VERSION = "1.0.417";
const userID = "1";
const token_php = "<?php echo(hash('sha256','TAL1Dcau1mFrKQZg:" + userID + ":XFrVGI7Uh1LNvu5i'));?>";

export const APP_STORE_URL = {
	ios: "https://apps.apple.com/us/app/keezers/id1576688418",
	android: "https://play.google.com/store/apps/details?id=net.goatama.keezers&hl=nl&gl=US",
};
export const ACC_URL = "wss://gateway-acc.goatama.net/keezers/";
export const DEV_URL = "wss://gateway-dev.goatama.net/keezers/";
export const TST_URL = "wss://gateway-tst.goatama.net/keezers/";
export const PRD_URL = "wss://gateway-prd.goatama.net/keezers/";
export const WS_URL = PRD_URL;

// export const IMAGE_ASSETS_URL = "https://static-tst.keezers.net/client/img";
export const DYNAMIC_IMAGES_URL = "https://dynamic.goatama.net/images/";
export var CONTENT_URL = "https://static.goatama.net/keezers";

export const FLAG_IMG_SET = "/images/flags/round/";
export const SQUARE_FLAG_URL = CONTENT_URL + "/images/flags/square/64/";
export const RANKING_SET = "/images/badges/";
export const LAUREL_URL = CONTENT_URL + "/images/laurels/";
export const POKER_CHIPS_SET = "/images/chips/24/";
export const SOUND_SET = "/sounds/";
export const TUTORIAL_URL = CONTENT_URL + "/tutorial/";

export var AUTHENTICATE_BASE_MSG = {
	type: "authenticate",
	language: "en-EN",
	currency: "EUR",
	debug: false,
	streamingMode: false,
	token: token_php,
	userID: userID,
};
export var CONNECT_MSG = {
	type: "connect",
	clientVersion: BUILD_VERSION,
	deviceIdentifier: "",
	appIdentifier: "",
	clientState: APP_STATE.STARTED,
	startReason: START_REASON.NORMAL,
	recoverFromCrash: false,
	screenSizeInInch: 6,
	debug: {},
};

export const defaultClientLanguage = "EN";
export const defaultClientResolution = "HIGH";
