import Slider from "rc-slider";
import React, { Component } from "react";
import ReactAvatarEditor from "react-avatar-editor";
import { Dimensions, Image, ImageBackground, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { appFont, buttonColor, f_sourcesansprobold, processState, RESOLUTION } from "../../../../config/defaults";
import { gameDimensions, updateAvatarDimensions } from "../../../../config/dimensions";
import { DEFAULT_IMG, MINUS_ICON, PLUS_ICON } from "../../../../config/images";
import {
	addLog,
	clearUpdateAvatarError,
	closeUpdateAvatar,
	deleteAvatar,
	goBackToMain,
	updateAvatar,
} from "../../../../redux/actions";
import Analytics from "../../../Analytics/Analytics";
import { AppTouchableOpacity, ColoredButton, NavigationHeader, Spinner } from "../../../common";
import { handleError } from "../../../ErrorHandler";

class UpdateAvatar extends Component {
	constructor(props) {
		super(props);
		const src = "https://" + this.props.avatars + this.props.userDetails.userID + "/" + Date.now();
		this.state = {
			contentScale: this.getContentScale(),
			avatarSource: { uri: src },
			showAvatarSelector: false,
			base64OfImage: "",
			image: "",
			scale: 1,
		};
		this.onAvatarSelectorClose = this.onAvatarSelectorClose.bind(this);
		this.handlewNewImage = this.handlewNewImage.bind(this);
	}

	setEditorRef = (editor) => (this.editor = editor);

	//#region lifecycle methods
	componentDidMount() {
		Analytics.logScreenView("UpdateAvatar");
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			(prevProps.updateAvatarState != this.props.updateAvatarState &&
				// !prevProps.updateAvatarSucceeded &&
				!this.props.updateAvatarSucceeded) ||
			prevProps.avatars != this.props.avatars
		) {
			const newSource = {
				uri: "https://" + this.props.avatars + this.props.userDetails.userID + "/" + Date.now(),
			};
			this.setState({
				avatarSource: newSource,
				base64OfImage: "",
				image: "",
				scale: 1,
			});
		}

		if (prevProps.deleteAvatarState != this.props.deleteAvatarState && this.props.deleteAvatarSucceeded) {
			this.setState({
				avatarSource: { uri: DEFAULT_IMG },
				base64OfImage: "",
				image: "",
				scale: 1,
			});
		}
	}
	//#endregion

	//#region events
	onBackPress() {
		if (this.state.showAvatarSelector) {
			this.onAvatarSelectorClose();
		} else {
			this.props.closeUpdateAvatar();
		}
	}

	onUpdatePress() {
		this.props.updateAvatar(this.state.base64OfImage);
		this.onAvatarSelectorClose();
	}

	onAvatarSelectorClose() {
		this.setState({ showAvatarSelector: false });
	}

	selectNewImage() {
		const input = document.getElementById("imgInput");
		if (input) input.click();
	}

	handlewNewImage(event) {
		if (event.target && event.target.files && event.target.files.length) {
			this.setState({ image: event.target.files[0] });
		}
	}

	handleScale(newVal) {
		if (typeof newVal === "number") {
			const scale = parseFloat(newVal);
			this.setState({ scale });
		}
	}

	onCrop() {
		if (this.editor && this.editor.getImageScaledToCanvas()) {
			const base64data = this.editor
				.getImageScaledToCanvas()
				.toDataURL("image/png")
				.split("data:image/png;base64,")
				.pop();
			this.setState({ base64OfImage: base64data });
		}
	}

	changeAvatar() {
		this.setState({ showAvatarSelector: true });
	}

	removeAvatar() {
		this.props.clearUpdateAvatarError();
		this.props.deleteAvatar();
		const newSource = {
			uri: "https://" + this.props.avatars + this.props.userDetails.userID + "/" + Date.now(),
		};
		this.setState({
			avatarSource: newSource,
			base64OfImage: "",
			image: "",
			scale: 1,
		});
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={
					this.props.updateAvatarState != processState.STARTED && this.props.deleteAvatarState != processState.STARTED
				}
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderMainButtons() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={[styles.buttonContainer, { height: buttonHeight }]}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.removeAvatar.bind(this)}
					textContent={this.props.lang.delete}
					accessibilityLabel={this.props.lang.delete}
					color={buttonColor.RED}
					disabled={false}
					additionalTextStyle={_buttonTextStyle}
				/>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.changeAvatar.bind(this)}
					textContent={this.props.lang.avatarUpdate.selectNew}
					accessibilityLabel={this.props.lang.avatarUpdate.selectNew}
					// onPressFunc={this.onUpdatePress.bind(this)}
					// textContent={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={false}
					additionalTextStyle={_buttonTextStyle}
				/>
			</View>
		);
	}

	renderSelectAvatar() {
		var _titleStyle = styles.titleStyle,
			_innerContainer = styles.dataInnerContainer;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_titleStyle = [styles.titleStyle, { fontSize: 46 }];
			_innerContainer = [styles.dataInnerContainer, { height: 60 }];
		}
		return (
			<View style={_innerContainer}>
				<Text allowFontScaling={false} style={_titleStyle}>
					{this.props.lang.avatarUpdate.avatarInput.title}
				</Text>
			</View>
		);
	}

	renderAvatarError() {
		const errorMsg =
			this.props.updateAvatarError != "" ? this.props.updateAvatarError : this.props.lang.avatarUpdate.errorMsg;
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError],
			_dataErrorContainer = styles.dataErrorContainer;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_dataErrorContainer = [styles.dataErrorContainer, { height: 60 }];
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26 }];
		}
		return (
			<View style={_dataErrorContainer}>
				<Text allowFontScaling={false} style={_errorStyle}>
					{errorMsg}
				</Text>
			</View>
		);
	}

	renderAvatar() {
		var _iWidth = updateAvatarDimensions.image.width,
			_iHeight = updateAvatarDimensions.image.height;
		if (this.props.resolution === RESOLUTION.HIGH) {
			_iWidth *= 1.5;
			_iHeight *= 1.5;
		}
		return (
			<View key="avatarImageContainer" style={styles.avatarImageContainer}>
				<AppTouchableOpacity
					onPress={this.removeAvatar.bind(this)}
					style={[styles.addRemoveIcon, styles.addRemoveButton]}
				>
					<Image source={MINUS_ICON} style={styles.addRemoveIcon} resizeMode="contain" />
				</AppTouchableOpacity>
				<Image
					source={{ uri: this.state.avatarSource.uri, cache: "reload" }}
					style={{ width: _iWidth, height: _iHeight, alignSelf: "center" }}
				/>
				<AppTouchableOpacity
					onPress={this.changeAvatar.bind(this)}
					style={[styles.addRemoveIcon, styles.addRemoveButton]}
				>
					<Image source={PLUS_ICON} style={styles.addRemoveIcon} resizeMode="contain" />
				</AppTouchableOpacity>
			</View>
		);
	}

	renderInnerContent() {
		return (
			<View>
				{this.renderSelectAvatar()}
				{(this.props.updateAvatarState == processState.FINISHED && !this.props.updateAvatarSucceeded) ||
					(this.props.deleteAvatarState == processState.FINISHED &&
						!this.props.deleteAvatarSucceeded &&
						this.renderAvatarError())}
				{this.renderAvatar()}
				{this.renderMainButtons()}
			</View>
		);
	}

	renderSecondaryButtons() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={[styles.buttonContainer, { height: buttonHeight, width: "100%" }]}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.selectNewImage.bind(this)}
					textContent={this.props.lang.avatarUpdate.selectOther}
					accessibilityLabel={this.props.lang.avatarUpdate.selectOther}
					color={buttonColor.GREEN}
					disabled={this.state.base64OfImage == ""}
					additionalTextStyle={_buttonTextStyle}
				/>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={this.state.base64OfImage == ""}
					additionalTextStyle={_buttonTextStyle}
				/>
			</View>
		);
	}

	renderAvatarSelector() {
		return (
			<View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
				<View
					style={{
						width: 375,
						height: 375,
						marginBottom: this.props.resolution === RESOLUTION.HIGH ? 30 : 0,
						alignItems: "center",
					}}
				>
					<ReactAvatarEditor
						ref={this.setEditorRef}
						scale={parseFloat(this.state.scale)}
						width={300}
						height={300}
						borderRadius={5}
						rotate={parseFloat(0)}
						image={this.state.image}
						onLoadSuccess={this.onCrop.bind(this)}
						onMouseUp={this.onCrop.bind(this)}
						color={[0, 0, 0, 0.25]}
						style={styles.editorStyle}
					/>
					<Slider
						min={1}
						max={2}
						step={0.01}
						value={this.state.scale}
						trackStyle={[
							{
								backgroundColor: "#0C697A",
								height: this.props.resolution === RESOLUTION.HIGH ? 8 : undefined,
							},
						]}
						railStyle={{
							backgroundColor: "#009688",
							height: this.props.resolution === RESOLUTION.HIGH ? 8 : undefined,
						}}
						handleStyle={{
							backgroundColor: "#0B5969",
							borderColor: "#0B5969",
							width: this.props.resolution === RESOLUTION.HIGH ? 21 : undefined,
							height: this.props.resolution === RESOLUTION.HIGH ? 21 : undefined,
						}}
						style={{
							marginTop: this.props.resolution === RESOLUTION.HIGH ? 30 : 20,
							width: 300,
							height: this.props.resolution === RESOLUTION.HIGH ? 50 : undefined,
						}}
						disabled={this.state.image == ""}
						onChange={this.handleScale.bind(this)}
					/>
					<input id="imgInput" name="newImage" type="file" accept="image/*" onChange={this.handlewNewImage} />
					{this.state.image == "" && (
						<AppTouchableOpacity onPress={this.selectNewImage.bind(this)} style={styles.editButton}>
							<Text allowFontScaling={false} style={styles.editButtonText}>
								{this.props.lang.avatarUpdate.avatarInput.placeholder}
							</Text>
						</AppTouchableOpacity>
					)}
				</View>
				{this.renderSecondaryButtons()}
			</View>
		);
	}

	renderContent() {
		return (
			<View style={[styles.contentContainer, { transform: [{ scale: this.state.contentScale }] }]}>
				{!this.state.showAvatarSelector && this.renderInnerContent()}
				{this.state.showAvatarSelector && this.renderAvatarSelector()}
			</View>
		);
	}

	render() {
		if (this.props.updateAvatarState == processState.STARTED || this.props.deleteAvatarState == processState.STARTED) {
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		}

		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 100; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 80;
			if (updateAvatarDimensions.width > availableWidth || updateAvatarDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / updateAvatarDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / updateAvatarDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		paddingHorizontal: 50,
		paddingTop: 30,
		paddingBottom: 20,
	},
	contentContainer: { flex: 1, paddingHorizontal: 0, justifyContent: "center" },
	buttonContainer: {
		height: 60,
		flexDirection: "row",
		justifyContent: "space-evenly",
		paddingHorizontal: 30,
		marginTop: 30,
		marginBottom: 10,
	},
	dataInnerContainer: {
		height: 50,
		flexDirection: "row",
		// paddingLeft: 40,
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#fff",
	},
	dataText: { fontFamily: appFont, fontSize: 20 },
	titleStyle: {
		flex: 1,
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 28,
		textAlign: "center",
		color: "#feb122",
		textShadowColor: "#333",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 10,
	},
	avatarImageContainer: {
		flex: 1,
		justifyContent: "center",
		flexDirection: "row",
		marginTop: 10,
		marginBottom: 10,
	},
	dataErrorContainer: {
		width: "100%",
		height: 40,
		marginTop: 10,
		marginBottom: 5,
		paddingLeft: 10,
		justifyContent: "flex-end",
		alignItems: "flex-end",
		alignSelf: "flex-end",
	},
	fieldError: {
		width: "100%",
		height: "100%",
		color: "#FE1F01",
		textAlign: "center",
		lineHeight: 40,
		padding: 0,
	},
	addRemoveIcon: {
		width: 45,
		height: 45,
		opacity: 0,
	},
	addRemoveButton: {
		marginLeft: 10,
		marginRight: 10,
		paddingTop: 2,
		alignSelf: "center",
	},
	selectButtonContainer: { position: "absolute", right: 20, bottom: 0 },
	editorStyle: { backgroundColor: "transparent", borderRadius: 10 },
	editButton: {
		width: 350,
		height: 350,
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
	},
	editButtonText: {
		width: 350,
		height: 50,
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 28,
		textAlign: "center",
		color: "#feb122",
		textShadowColor: "#333",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		userDetails: state.currentUser.userDetails,
		updateAvatarState: state.app.updateAvatarState,
		updateAvatarSucceeded: state.app.updateAvatarSucceeded,
		updateAvatarError: state.app.updateAvatarError,
		avatars: state.app.welcome.webResources.avatars,
		deleteAvatarState: state.app.deleteAvatarState,
		deleteAvatarSucceeded: state.app.deleteAvatarSucceeded,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	addLog,
	clearUpdateAvatarError,
	closeUpdateAvatar,
	deleteAvatar,
	goBackToMain,
	updateAvatar,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAvatar);
