import React, { Component } from "react";
import { Dimensions, FlatList, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { isWebOrTablet, leaderboard_Type, RESOLUTION } from "../../../config/defaults";
import { gameDetailsColumns } from "../../../config/dimensions";
import { getCountryFlagImageURL, getRankingImageURL } from "../../../helpers/commonHelpers";
import { TableHeader, TableHeaderCell, TableRow, TableRowCell } from "../../common";
import { handleError } from "../../ErrorHandler";

class LeaderBoardTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			playersList: this.getCurrentList(),
			playerColumnWidth: 435,
			maxVisibleRows: 1,
		};
	}

	//#region lifecycle methods
	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.lbCategorySelected !== this.props.lbCategorySelected ||
			prevProps.global != this.props.global ||
			prevProps.friends != this.props.friends ||
			prevProps.groups != this.props.groups
		) {
			this.setState({ playersList: this.getCurrentList() });
		}

		if (prevState.playersList != this.state.playersList || prevProps.isRelative != this.props.isRelative) {
			this.onTableLayout();
		}
	}
	//#endregion

	//#region events
	scrollToUser() {
		try {
			var index = this.state.playersList.findIndex((p) => p.userID == this.props.userDetails.userID);
			index = index == -1 ? 0 : index;
			if (this.refs && this.refs.playersList)
				this.refs.playersList.scrollToIndex({
					animated: false,
					index: index,
					viewPosition: 0.5,
				});
		} catch (error) {
			handleError(error);
		}
	}

	scrollToTop() {
		try {
			if (this.refs && this.refs.playersList) this.refs.playersList.scrollToIndex({ animated: false, index: 0 });
		} catch (error) {
			handleError(error);
		}
	}

	onTableLayout() {
		try {
			if (this.state.playersList.length > 0) {
				if (this.props.isRelative) {
					// if is relative scroll to the current user's position, center it and highlight it
					this.scrollToUser();
				} else {
					// if is not relative scroll to the top
					this.scrollToTop();
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	tableRendered(event) {
		try {
			const tableHeight = event.nativeEvent.layout.height;
			const maxVisibleRows = isWebOrTablet
				? this.props.resolution === RESOLUTION.HIGH
					? Math.floor(tableHeight / 41)
					: Math.floor(tableHeight / 31)
				: Math.floor(tableHeight / 20);
			this.setState({ maxVisibleRows });
			this.props.onTableLayout(maxVisibleRows);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<TableHeader>
				<TableHeaderCell
					key={"positionHeader"}
					cellContent={this.props.lang.leaderBoardsMenu.commonHeaders.position}
					containerStyle={{ width: isWebOrTablet ? 100 : 80 }}
					textStyle={[
						{ flex: 1, textAlign: "center", paddingHorizontal: 3 },
						this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
					]}
				/>
				<TableHeaderCell
					key={"countryHeader"}
					cellContent={this.props.lang.leaderBoardsMenu.commonHeaders.country}
					containerStyle={{
						width: this.props.resolution === RESOLUTION.HIGH ? 100 : isWebOrTablet ? 80 : 60,
					}}
					textStyle={[
						{ flex: 1, textAlign: "center", paddingHorizontal: 3 },
						this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
					]}
				/>
				<TableHeaderCell
					key={"levelHeader"}
					cellContent={this.props.lang.leaderBoardsMenu.commonHeaders.level}
					containerStyle={{
						width: this.props.resolution === RESOLUTION.HIGH ? 80 : isWebOrTablet ? 60 : 40,
					}}
					textStyle={[{ flex: 1, textAlign: "center" }, this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 }]}
				/>
				<TableHeaderCell
					key={"playerHeader"}
					cellContent={this.props.lang.leaderBoardsMenu.commonHeaders.player}
					containerStyle={{ flex: 1 }}
					textStyle={[
						{ flex: 1, textAlign: "center", paddingHorizontal: 3 },
						this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
					]}
					onLayout={(event) => {
						this.setState({
							playerColumnWidth: Math.round(event.nativeEvent.layout.width),
						});
					}}
				/>
				<TableHeaderCell
					key={"scoreHeader"}
					cellContent={this.props.lang.leaderBoardsMenu.commonHeaders.score}
					containerStyle={{
						width: this.props.resolution === RESOLUTION.HIGH ? 135 : isWebOrTablet ? 125 : 100,
					}}
					textStyle={[
						{ flex: 1, textAlign: "center", paddingHorizontal: 3 },
						this.props.resolution === RESOLUTION.HIGH && { fontSize: 26 },
					]}
				/>
				{Platform.OS === "web" && this.state.maxVisibleRows < this.state.playersList.length && (
					<TableHeaderCell
						key={"scrollerHeader"}
						cellContent={""}
						containerStyle={{ width: 17 }}
						textStyle={{ flex: 1, textAlign: "center" }}
					/>
				)}
			</TableHeader>
		);
	}

	renderRow(item) {
		var _imgSize = this.props.resolution === RESOLUTION.HIGH ? 30 : gameDetailsColumns.ranking.imageSize;
		const winDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		if (this.props.resolution === RESOLUTION.HIGH && winDim.scale > 1) {
			if (winDim.scale < 2.5) {
				_imgSize = 50;
			} else {
				_imgSize = 150;
			}
		}
		const rankingURL = getRankingImageURL(item.level, _imgSize);
		const countryImgURL = getCountryFlagImageURL(item.country, this.props.resolution === RESOLUTION.HIGH ? 40 : 20);
		const isSelected = item.userID == this.props.userDetails.userID;

		return (
			<TableRow
				rowId={"row_" + item.userID}
				// onPress={() => this.props.selectPlayer(item)}
				// onDoubleTap={() => this.props.selectPlayer(item)}
				isPressable={false}
				style={this.props.resolution === RESOLUTION.HIGH ? { height: 40 } : {}}
			>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					selectedBackgroundColor={"#F6A022"}
					isSelected={isSelected}
					cellWidth={isWebOrTablet ? 100 : 80}
					key={"position_" + item.userID}
					cellId={"position_" + item.userID}
					cellType={"text"}
					content={" " + item.position.toLocaleString()}
					style={[
						{ textAlign: "center", paddingHorizontal: 3 },
						this.props.resolution === RESOLUTION.HIGH && {
							fontSize: 26,
							lineHeight: 40,
							height: 40,
						},
					]}
				/>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					selectedBackgroundColor={"#F6A022"}
					isSelected={isSelected}
					cellWidth={this.props.resolution === RESOLUTION.HIGH ? 100 : isWebOrTablet ? 80 : 60}
					key={"country_" + item.userID}
					cellId={"country_" + item.userID}
					cellType={"img"}
					imgURL={{ uri: countryImgURL }}
					imgDim={{
						width: this.props.resolution === RESOLUTION.HIGH ? 30 : isWebOrTablet ? 20 : 14,
						height: this.props.resolution === RESOLUTION.HIGH ? 30 : isWebOrTablet ? 20 : 14,
					}}
				/>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					selectedBackgroundColor={"#F6A022"}
					isSelected={isSelected}
					cellWidth={this.props.resolution === RESOLUTION.HIGH ? 80 : isWebOrTablet ? 60 : 40}
					key={"level_" + item.userID}
					cellId={"level_" + item.userID}
					cellType={"img"}
					imgURL={rankingURL}
					imgDim={this.props.resolution === RESOLUTION.HIGH ? { width: 30, height: 30 } : {}}
				/>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					selectedBackgroundColor={"#F6A022"}
					isSelected={isSelected}
					cellWidth={this.state.playerColumnWidth}
					key={"player_" + item.userID}
					cellId={"player_" + item.userID}
					cellType={"text"}
					content={item.username}
					style={[
						{ textAlign: "center", paddingHorizontal: 3 },
						this.props.resolution === RESOLUTION.HIGH && {
							fontSize: 26,
							lineHeight: 40,
							height: 40,
						},
					]}
				/>
				<TableRowCell
					lang={this.props.lang}
					backgroundColor={"transparent"}
					selectedBackgroundColor={"#F6A022"}
					isSelected={isSelected}
					cellWidth={this.props.resolution === RESOLUTION.HIGH ? 135 : isWebOrTablet ? 125 : 100}
					key={"score_" + item.userID}
					cellId={"score_" + item.userID}
					cellType={"text"}
					content={" " + item.score.toLocaleString()}
					style={[
						{ paddingLeft: 3, paddingRight: 10, textAlign: "right" },
						this.props.resolution === RESOLUTION.HIGH && {
							fontSize: 26,
							lineHeight: 40,
							height: 40,
						},
					]}
				/>
				{Platform.OS === "web" && this.state.maxVisibleRows < this.state.playersList.length && (
					<TableRowCell
						lang={this.props.lang}
						backgroundColor={"transparent"}
						selectedBackgroundColor={"#F6A022"}
						isSelected={isSelected}
						cellWidth={25}
						key={"scroller_" + item.userID}
						cellId={"scroller_" + item.userID}
						cellType={"text"}
						content={" "}
						style={{ paddingLeft: 0, paddingRight: 0 }}
					/>
				)}
			</TableRow>
		);
	}

	renderTable() {
		return (
			<FlatList
				ref="playersList"
				keyExtractor={(player) => player.userID.toString()}
				data={this.state.playersList}
				renderItem={({ item }) => this.renderRow(item)}
				getItemLayout={(data, index) => ({
					length: 20,
					offset: 20 * index,
					index,
				})}
				onContentSizeChange={() => this.onTableLayout()}
				style={{ height: "100%" }}
				onLayout={(event) => this.tableRendered(event)}
			/>
		);
	}

	render() {
		return (
			<View style={styles.tableStyle}>
				{this.renderHeader()}
				{this.renderTable()}
			</View>
		);
	}
	//#endregion

	//#region helpers
	getCurrentList() {
		try {
			const { lbCategorySelected, lbTypeSelected } = this.props;
			if (lbTypeSelected == leaderboard_Type.GROUPS) {
				const selGroup = this.props.groups.find((gr) => gr.selected == true);
				if (!selGroup || !selGroup.data || !selGroup.data[lbCategorySelected]) return [];
				return selGroup.data[lbCategorySelected];
			} else {
				if (this.props[lbTypeSelected] != undefined && this.props[lbTypeSelected][lbCategorySelected] != undefined)
					return this.props[lbTypeSelected][lbCategorySelected];
				return [];
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		userDetails: state.currentUser.userDetails,
		lbTypeSelected: state.leaderboard.lbTypeSelected,
		lbCategorySelected: state.leaderboard.lbCategorySelected,
		global: state.leaderboard.global,
		friends: state.leaderboard.friends,
		groups: state.leaderboard.groups,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {};

const styles = StyleSheet.create({
	tableStyle: { flex: 1, height: "100%" },
});

export default connect(mapStateToProps, mapDispatchToProps)(LeaderBoardTable);
