import React, { Component } from "react";
import { Image, Platform, StyleSheet, View } from "react-native";
import { isTablet } from "../../../config/defaults";

class PlayedCard extends Component {
	shouldComponentUpdate(nextProps) {
		return (
			this.props.cardsetID != nextProps.cardsetID ||
			this.props.cardsetSize != nextProps.cardsetSize ||
			this.props.playedCardsGlobal.top !== nextProps.playedCardsGlobal.top ||
			this.props.playedCardsGlobal.left !== nextProps.playedCardsGlobal.left ||
			this.props.playedCardsGlobal.scale !== nextProps.playedCardsGlobal.scale ||
			this.props.card.id !== nextProps.card.id ||
			this.props.card.isThrown !== nextProps.card.isThrown ||
			this.props.card.rotation !== nextProps.card.rotation ||
			this.props.card.top !== nextProps.card.top ||
			this.props.card.left !== nextProps.card.left
		);
	}

	render() {
		const { playedCardsGlobal, card } = this.props;
		const cardImgURL =
			"https://" +
			this.props.cardsetURL +
			"/" +
			this.props.cardset +
			"/" +
			this.props.cardsetSize +
			"/" +
			card.id +
			".png";
		return (
			<View
				key={card.id}
				style={{
					position: "absolute",
					top: playedCardsGlobal.top,
					left: playedCardsGlobal.left,
					transform: [{ scale: playedCardsGlobal.scale || 1 }],
				}}
			>
				<Image
					source={{ uri: this.props.preloadedCard.cardImgURL }}
					resizeMode={Platform.OS === "web" ? "stretch" : "cover"}
					style={[
						styles.cardDim,
						styles.cardBorder,
						{
							top: card.top || 0,
							left: card.left || 0,
							transform: [{ rotate: (card.rotation || 0) + "deg" }],
							borderWidth: card.isThrown ? 0 : 1,
							borderRadius: card.isThrown ? 8 : 9,
							borderColor: card.isThrown ? "transparent" : "#ccc",
						},
					]}
				/>
				{card.isThrown && (
					<View
						style={{
							position: "absolute",
							top: card.top,
							left: card.left,
							zIndex: 0,
							opacity: 0.5,
							backgroundColor: "#000",
							width: "100%",
							height: "100%",
							borderRadius: 8,
							transform: [{ rotate: (card.rotation || 0) + "deg" }],
						}}
					/>
				)}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	cardImg: { borderRadius: Platform.OS !== "web" && isTablet ? 8 : 5 },
	cardDim: {
		width: Platform.OS === "web" ? 74 : 70,
		height: Platform.OS === "web" ? 96 : 94,
	},
	cardBorder: {
		borderRadius: 9,
		borderColor: "#ccc",
		borderWidth: 1,
	},
});

export { PlayedCard };
