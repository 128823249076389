import React, { Component } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { appFont, RESOLUTION } from "../../config/defaults";
import { GREEN_BTN, YELLOW_BTN } from "../../config/images";
import { openCreateTeam, openTournamentStat } from "../../redux/actions";
import Analytics from "../Analytics/Analytics";
import { GameButton } from "../common";
import { handleError } from "../ErrorHandler";

class TournamentDetailsButtons extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnJoinHovered: false,
			btnCreateTeamHovered: false,
		};
	}

	//#region events
	onMouseEnterJoinButton() {
		this.setState({ btnJoinHovered: true });
	}

	onMouseLeaveJoinButton() {
		this.setState({ btnJoinHovered: false });
	}

	onMouseEnterCreateTeamButton() {
		this.setState({ btnCreateTeamHovered: true });
	}

	onMouseLeaveCreateTeamButton() {
		this.setState({ btnCreateTeamHovered: false });
	}

	async onCreateTeam() {
		try {
			await Analytics.logEvent("startCreateTeam");
			this.props.openCreateTeam();
		} catch (err) {
			handleError(err);
		}
	}

	async onJoinTeam() {
		try {
			await Analytics.logEvent("joinTeam");
			//TODO: don't forget to check if player is already in other team then the selected/created one
			//TODO: don't forget to check if there is place to join
			this.props.openTournamentStat(); //TODO: remove this line when joining is ready
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderRightButton() {
		const disabled = this.props.selectedTournament.withTeams && this.props.selectedTeamID == -1; /* ||
      !this.props.selectedTournament.canJoinTeam */
		const opacity = this.state.btnJoinHovered || disabled ? 0.8 : 1;
		const enabledBackImage = {
			uri: GREEN_BTN,
			style: [
				{
					flex: 2,
					maxWidth: 200,
					height: 40,
					opacity,
				},
				this.props.resolution == RESOLUTION.HIGH && {
					maxWidth: 250,
					height: 60,
				},
			],
		};
		return (
			<GameButton
				onPress={this.onJoinTeam.bind(this)}
				onMouseEnter={this.onMouseEnterJoinButton.bind(this)}
				onMouseLeave={this.onMouseLeaveJoinButton.bind(this)}
				disabled={disabled}
				myStyle={[
					styles.defButtonStyle,
					{
						opacity,
						height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
					},
				]}
				textContent={"Join team"}
				textContentStyle={[
					styles.btnTextStyle,
					{ opacity },
					this.props.resolution == RESOLUTION.HIGH && {
						fontSize: 22,
						paddingTop: 13,
						paddingBottom: 13,
					},
				]}
				backgroundImage={enabledBackImage}
			/>
		);
	}

	renderLeftButton() {
		const disabled = !this.props.selectedTournament.canCreateTeam;
		const opacity = disabled ? 0 : this.state.btnCreateTeamHovered ? 0.8 : 1;
		const enabledBackImage = {
			uri: YELLOW_BTN,
			style: [
				{
					flex: 2,
					maxWidth: 200,
					height: 40,
					opacity,
				},
				this.props.resolution == RESOLUTION.HIGH && {
					maxWidth: 250,
					height: 60,
				},
			],
		};
		return (
			<GameButton
				onPress={this.onCreateTeam.bind(this)}
				onMouseEnter={this.onMouseEnterCreateTeamButton.bind(this)}
				onMouseLeave={this.onMouseLeaveCreateTeamButton.bind(this)}
				disabled={disabled}
				myStyle={[
					styles.defButtonStyle,
					{
						opacity,
						height: this.props.resolution == RESOLUTION.HIGH ? 60 : 40,
					},
				]}
				textContent={"Create team"}
				textContentStyle={[
					styles.btnTextStyle,
					{ opacity, textShadowColor: "#5f580b" },
					this.props.resolution == RESOLUTION.HIGH && {
						fontSize: 22,
						paddingTop: 13,
						paddingBottom: 13,
					},
				]}
				backgroundImage={enabledBackImage}
			/>
		);
	}

	render() {
		return (
			<View style={[styles.containerStyle, this.props.style || {}]}>
				{this.renderLeftButton()}
				{this.renderRightButton()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	containerStyle: {
		flexDirection: "row",
		justifyContent: "space-around",
		marginTop: 3,
		padding: 5,
	},
	defButtonStyle: {
		flex: 2,
		height: 40,
		backgroundColor: "transparent",
		borderWidth: 0,
	},
	btnTextStyle: {
		width: "100%",
		fontFamily: appFont,
		fontSize: 14,
		paddingTop: 10,
		paddingBottom: 10,
		textAlign: "center",
		color: "#FFFFFF",
		textShadowColor: "#397423",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: Platform.OS !== "web" ? 0.001 : 0,
	},
});

const mapStateToProps = (state) => {
	var found = state.tournaments.tournamentsList.find(
		(tour) => tour.tournamentID === state.tournaments.selectedTournamentID
	);
	if (!found) found = { withTeams: true };

	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedTeamID: state.tournaments.selectedTeamID,
		selectedTournament: found,
		currentUserID: state.currentUser.userDetails.userID,
	};
};

const mapDispatchToProps = { openCreateTeam, openTournamentStat };

export default connect(mapStateToProps, mapDispatchToProps)(TournamentDetailsButtons);
