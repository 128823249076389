import { cloneDeep } from "lodash";
import { playAnnouncementSound } from "../../helpers/commonHelpers";
import { RESET_CLIENT } from "../actions/actionTypes";
import {
	DISPLAY_NOTIFICATION,
	INCREASE_CURRENT_ANNOUNCEMENTS_DISPLAYED_NUMBER,
	INCREASE_CURRENT_MESSAGE_INDEX,
	PROCESS_ANNOUNCEMENT,
	REMOVE_CURRENT_ANNOUNCEMENT_FROM_QUEUE,
	REMOVE_CURRENT_DISPLAYED_MESSAGE,
	RESET_CURRENT_MESSAGE_INDEX,
	ROTATE_NOTIFICATIONS,
} from "../actionTypes";

const initialState = {
	messagesQueueList: [],
	currentMessageIndex: 0,
	messageToDisplay: null,
	displayStartingTime: 0,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case DISPLAY_NOTIFICATION:
			if (typeof payload === "undefined") return state;
			const { displayStartingTime } = state;

			var nMessagesQueueList = cloneDeep(state.messagesQueueList);
			var nCurMsgIndex = state.currentMessageIndex;
			var interruptedMessage = nMessagesQueueList[nCurMsgIndex];
			var isQuickMessage = true;
			if (interruptedMessage.displayTime <= 10000) {
				isQuickMessage = false;
			}

			if (isQuickMessage) {
				nMessagesQueueList.splice(nCurMsgIndex, 0, payload);
			} else {
				var elapsedDisplayingTime = Date.now() - displayStartingTime;
				var remainingDisplayingTime = interruptedMessage.displayTime - elapsedDisplayingTime;
				if (remainingDisplayingTime < 5000) {
					//the interruptedMessage should be displayed again after the payload only in case if rotate:true
					if (interruptedMessage.rotate) {
						//payload should be inserted after the interruptedMessage
						nCurMsgIndex++;
						nMessagesQueueList.splice(nCurMsgIndex, 0, payload);
					} else {
						//interruptedMessage should be replaced with payload
						nMessagesQueueList.splice(nCurMsgIndex, 1, payload);
					}
				} else {
					//the interruptedMessage should be displayed again after the payload with the remainingDisplayingTime
					if (interruptedMessage.rotate) {
						var duplicateMessage = {
							displayTime: remainingDisplayingTime,
							deleteHistory: interruptedMessage.deleteHistory,
							rotate: false,
							clickable: interruptedMessage.clickable,
							playSound: false,
							url: interruptedMessage.url,
							priority: interruptedMessage.priority,
							announcement: interruptedMessage.announcement,
							displayedNr: 0,
						};
						//payload and the duplicateMessage should be inserted after the interruptedMessage
						nCurMsgIndex++;
						nMessagesQueueList.splice(nCurMsgIndex, 0, payload);
						nMessagesQueueList.splice(nCurMsgIndex + 1, 0, duplicateMessage);
					} else {
						if (typeof interruptedMessage.isErrorMessage === "undefined" || !interruptedMessage.isErrorMessage) {
							interruptedMessage.displayTime = remainingDisplayingTime;
							//payload should be inserted before the interruptedMessage
							nMessagesQueueList.splice(nCurMsgIndex, 0, payload);
						}
					}
				}
			}
			return {
				...state,
				messagesQueueList: nMessagesQueueList,
				currentMessageIndex: nCurMsgIndex,
			};
		case PROCESS_ANNOUNCEMENT:
			if (typeof payload === "undefined") return state;
			payload.displayedNr = 0;
			var nMessagesQueueList = cloneDeep(state.messagesQueueList);
			if (payload.rotate || nMessagesQueueList.length == 0) {
				nMessagesQueueList.push(payload);
			} else {
				var isInserted = false;
				for (var i = nMessagesQueueList.length - 1; i >= 0 && i >= state.currentMessageIndex && !isInserted; i--) {
					if (nMessagesQueueList[i].displayedNr !== undefined && nMessagesQueueList[i].displayedNr == 0) {
						nMessagesQueueList.splice(i + 1, 0, payload);
						isInserted = true;
					}
				}
				if (!isInserted) {
					nMessagesQueueList.splice(state.currentMessageIndex + 1, 0, payload);
				}
			}
			return { ...state, messagesQueueList: nMessagesQueueList };
		case ROTATE_NOTIFICATIONS:
			if (state.messagesQueueList.length <= 0) return state;
			var nMsgQueueList = cloneDeep(state.messagesQueueList);
			var nCurMsgIndex = state.currentMessageIndex;
			var msgToDisplay = cloneDeep(nMsgQueueList[nCurMsgIndex]);
			if (typeof payload !== "undefined" && typeof msgToDisplay.playSound === "boolean" && msgToDisplay.playSound) {
				playAnnouncementSound(msgToDisplay.priority, msgToDisplay.isErrorMessage, msgToDisplay.isNotification);
			}
			if (msgToDisplay.deleteHistory === true) {
				for (var i = nMsgQueueList.length - 1; i >= 0; i--) {
					if (nMsgQueueList[i].displayedNr >= 1) {
						nMsgQueueList.splice(i, 1);
						if (i < nCurMsgIndex) nCurMsgIndex--;
					}
				}
			}
			return {
				...state,
				messagesQueueList: nMsgQueueList,
				currentMessageIndex: nCurMsgIndex,
				messageToDisplay: msgToDisplay,
				displayStartingTime: Date.now(),
			};
		case REMOVE_CURRENT_ANNOUNCEMENT_FROM_QUEUE:
			var nMsgQueueList = cloneDeep(state.messagesQueueList);
			nMsgQueueList.splice(state.currentMessageIndex, 1);
			return {
				...state,
				messagesQueueList: nMsgQueueList,
			};
		case INCREASE_CURRENT_MESSAGE_INDEX:
			return { ...state, currentMessageIndex: state.currentMessageIndex + 1 };
		case INCREASE_CURRENT_ANNOUNCEMENTS_DISPLAYED_NUMBER:
			var nMsgQueueList = cloneDeep(state.messagesQueueList);
			const curMsg = nMsgQueueList[state.currentMessageIndex];
			if (curMsg) nMsgQueueList[state.currentMessageIndex].displayedNr++;
			return { ...state, messagesQueueList: nMsgQueueList };
		case RESET_CURRENT_MESSAGE_INDEX:
			return {
				...state,
				currentMessageIndex: initialState.currentMessageIndex,
			};
		case REMOVE_CURRENT_DISPLAYED_MESSAGE:
			return {
				...state,
				messageToDisplay: initialState.messageToDisplay,
			};
		case RESET_CLIENT:
			return initialState;
		default:
			return state;
	}
};
