import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, StyleSheet, Text, View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprosemibold,
	processState,
	RESOLUTION,
	tabType,
} from "../../../../../../config/defaults";
import { gameDimensions, overrrideCardsetDimensions } from "../../../../../../config/dimensions";
import CheckBox from "../../../../../../libraries/CheckBox";
import {
	closeOverrideCardset,
	goBackToMain,
	openTab,
	openVIPShop,
	startPlay,
	updateOverrideCardset,
} from "../../../../../../redux/actions";
import Analytics from "../../../../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../../../../../common";
import { handleError } from "../../../../../ErrorHandler";
import VIPShop from "../../../../../GameShop/VIPShop";

class OverrideCardset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			isOverrideChecked: this.props.overrideCardset,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("OverrideCardsetPreferences");
	}
	//#endregion

	//#region events
	onUpdatePress() {
		this.props.updateOverrideCardset(this.state.isOverrideChecked);
	}

	onLinkPress() {
		this.props.goBackToMain();
		this.props.startPlay();
		this.props.openTab(tabType.SHOP);
		this.props.openVIPShop();
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateOverrideCardsetState != processState.STARTED}
				backAction={this.props.closeOverrideCardset}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					onPressFunc={this.onUpdatePress.bind(this)}
					textContent={this.props.lang.update}
					accessibilityLabel={this.props.lang.update}
					color={buttonColor.GREEN}
					disabled={!this.props.isVip || this.props.overrideCardset == this.state.isOverrideChecked}
					additionalTextStyle={_buttonTextStyle}
				/>
			</View>
		);
	}

	renderOverrideCheck() {
		var _innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center" }],
			_inputLabel = [styles.dataTitle, styles.dataText],
			_checkBoxStyle = [
				Platform.OS === "web" ? styles.checkBox : { transform: [{ scale: Platform.OS === "ios" ? 0.85 : 1.25 }] },
			];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_innerContainerStyle = [styles.dataInnerContainer, { alignItems: "center", height: 80 }];
			_inputLabel = [styles.dataTitle, styles.dataText, { width: 300, fontSize: 26 }];
			_checkBoxStyle = [
				Platform.OS === "web"
					? [styles.checkBox, { width: 30, height: 30 }]
					: { transform: [{ scale: Platform.OS === "ios" ? 1 : 1.75 }] },
			];
		}
		return (
			<View style={_innerContainerStyle}>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.overrideCardset.checkBoxTitle}
				</Text>
				<View style={styles.checkboxContainer}>
					<CheckBox
						disabled={!this.props.isVip}
						value={this.state.isOverrideChecked}
						onValueChange={() =>
							this.setState({
								isOverrideChecked: !this.state.isOverrideChecked,
							})
						}
						style={_checkBoxStyle}
					/>
				</View>
			</View>
		);
	}

	renderOverrideInfo() {
		try {
			const regex = /_/gi;
			var main_text = this.props.lang.overrideCardset.info;
			var main_text_i = main_text.indexOf("_");
			var main_text_j = main_text.lastIndexOf("_");
			if (main_text_i != -1 && main_text_j != -1) var linkMainText = main_text.substring(main_text_i, ++main_text_j);
			if (linkMainText != undefined) var mainTextLink = linkMainText.replace(regex, "");

			var _infoStyle = [styles.dataText, styles.infoText];
			if (this.props.resolution === RESOLUTION.HIGH) {
				_infoStyle = [styles.dataText, styles.infoText, { fontSize: 24, lineHeight: 30 }];
			}
			return (
				<View style={{ width: "100%" }}>
					{mainTextLink != "" && (
						<ParsedText
							style={_infoStyle}
							parse={[
								{
									pattern: new RegExp("_" + mainTextLink + "_"),
									style: [_infoStyle, styles.linkDecor],
									onPress: this.onLinkPress.bind(this),
									renderText: () => mainTextLink,
								},
							]}
						>
							{main_text}
						</ParsedText>
					)}
					{mainTextLink == "" && (
						<Text allowFontScaling={false} style={_infoStyle}>
							{main_text}
						</Text>
					)}
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderContent() {
		return (
			<View
				style={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				{this.renderOverrideCheck()}
				{this.renderOverrideInfo()}
				{this.renderUpdateButton()}
			</View>
		);
	}

	render() {
		if (this.props.updateOverrideCardsetState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);

		if (this.props.vipShopOpened) {
			return <VIPShop />;
		} else {
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<View style={styles.container}>{this.renderContent()}</View>
					{this.renderHeader()}
				</ImageBackground>
			);
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (overrrideCardsetDimensions.width > availableWidth || overrrideCardsetDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / overrrideCardsetDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / overrrideCardsetDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	contentContainer: { flex: 1, paddingHorizontal: 20 },
	buttonContainer: {
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		// marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprosemibold, fontSize: 20 },
	checkboxContainer: {
		width: 300,
		height: 40,
		flexDirection: "row",
		paddingTop: 5,
	},
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: 26,
		marginTop: 20,
		marginBottom: 20,
		fontSize: 18,
	},
	linkDecor: { color: "#00CDD8" },
	checkBox: { width: 20, height: 20, alignSelf: "center" },
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		vipShopOpened: state.gameShop.vipShopOpened,
		isVip: state.currentUser.userDetails.isVip,
		overrideCardset: state.currentUser.preferences.overrideCardset,
		updateOverrideCardsetState: state.app.updateOverrideCardsetState,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeOverrideCardset,
	goBackToMain,
	openTab,
	openVIPShop,
	startPlay,
	updateOverrideCardset,
};

export default connect(mapStateToProps, mapDispatchToProps)(OverrideCardset);
