import React, { Component } from "react";
import { Image, StyleSheet, View } from "react-native";
import { handleError } from "../../components/ErrorHandler";
import { gameChat } from "../config/images";

class KeezChatMessageBox extends Component {
	//#render methods
	render() {
		try {
			const { border, corner, center } = gameChat.background;
			const _width = this.props.width + 6;
			const _height = typeof this.props.height === "number" ? this.props.height + 6 : 206;
			return (
				<View
					style={[
						styles.messageBoxContainer,
						{
							position: "absolute",
							top: -3,
							left: -3,
							zIndex: -1,
							width: typeof _width === "number" ? _width : 330,
							height: _height,
						},
					]}
				>
					<Image
						key="backTLC"
						source={corner.top_left}
						resizeMode={"repeat"}
						style={{
							width: 13,
							height: 12,
							position: "absolute",
							top: 0,
							left: 0,
						}}
					/>
					<Image
						key="backTB"
						source={border.top}
						resizeMode={"repeat"}
						style={{
							width: typeof _width === "number" ? _width - 24 : 250,
							height: 14,
							position: "absolute",
							top: 0,
							left: 12,
							zIndex: -1,
						}}
					/>
					<Image
						source={corner.top_right}
						key="backTRC"
						resizeMode={"repeat"}
						style={{
							width: 13,
							height: 12,
							position: "absolute",
							top: 0,
							right: 0,
						}}
					/>
					<Image
						key="backLB"
						source={border.left}
						resizeMode={"repeat"}
						style={{
							width: 13,
							height: _height - 22,
							position: "absolute",
							top: 11,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="backCenter"
						source={center}
						resizeMode={"repeat"}
						style={{
							width: typeof _width === "number" ? _width - 24 : 250,
							height: _height - 21,
							position: "absolute",
							top: 11,
							left: 12,
							zIndex: -1,
						}}
					/>
					<Image
						source={border.right}
						key="backRB"
						resizeMode={"repeat"}
						style={{
							width: 13,
							height: _height - 22,
							position: "absolute",
							top: 11,
							right: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="backBLC"
						source={corner.bottom_left}
						resizeMode={"repeat"}
						style={{
							width: 13,
							height: 13,
							position: "absolute",
							bottom: 0,
							left: 0,
							zIndex: -1,
						}}
					/>
					<Image
						key="backBB"
						source={border.bottom}
						resizeMode={"repeat"}
						style={{
							width: typeof _width === "number" ? _width - 24 : 250,
							height: 12,
							position: "absolute",
							bottom: 0,
							left: 12,
							zIndex: -1,
						}}
					/>
					<Image
						source={corner.bottom_right}
						key="backBRC"
						resizeMode={"repeat"}
						style={{
							width: 13,
							height: 13,
							position: "absolute",
							bottom: 0,
							right: 0,
							zIndex: -1,
						}}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	messageBoxContainer: {
		width: 330,
		height: 200,
	},
});

export default KeezChatMessageBox;
