import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { RESOLUTION } from "../../config/defaults";
import { dealCards, selectCard, throwAway } from "../redux/actions";
import PlayerCard from "./PlayerCard";
import ThrowAwayButton from "./ThrowAwayButton";
import TimerView from "./TimerView";

class PlayersCardsArea extends Component {
	//#region lifecycle methods
	/* componentDidUpdate(prevProps) {
    if (
      prevProps.cards != this.props.cards &&
      // prevProps.cards.length != this.props.cards.length &&
      this.props.cards.length > 0 &&
      !this.props.cards[0].dealed
    ) {
      this.props.dealCards();
    }
  } */
	//#endregion

	//#region render methods
	renderPlayersCards() {
		if (this.props.playersCardsProp.top == undefined) return null;

		var plCards = [];
		const { cards, playersCardsProp } = this.props;
		const cardsStyle = playersCardsProp[cards.length];
		var cardsetSize = "l";
		switch (this.props.resolution) {
			case RESOLUTION.LOW:
				cardsetSize = "s";
				break;
			case RESOLUTION.MEDIUM:
				cardsetSize = "m";
				break;
			case RESOLUTION.HIGH:
				cardsetSize = "l";
				break;
		}
		cards.forEach((c, ind) => {
			var _scale = playersCardsProp.scale,
				calcTop = (cardsStyle.top[ind] + playersCardsProp.top) * playersCardsProp.scale,
				calcLeft = (cardsStyle.left[ind] + playersCardsProp.left) * playersCardsProp.scale;

			/* if (this.props.playerCnt == 4) {
        if (Platform.OS !== "web" && !DeviceInfo.isTablet()) {
          _scale = this.props.plCardsScale;
          calcTop =
            (cardsStyle.top[ind] + playersCardsProp.top) *
              this.props.plCardsScale +
            playersCardsProp.top / this.props.initialScale / _scale / 6;
          calcLeft =
            (cardsStyle.left[ind] + playersCardsProp.left) *
              this.props.plCardsScale -
            ((playersCardsProp.left *
              playersCardsProp.leftInBoardPercentage *
              _scale *
              this.props.plCardsScale) /
              100) *
              this.props.plCardsScale;
        }
      } */

			plCards.push(
				<PlayerCard
					key={"playerCard_" + c.id + "_" + ind}
					myIndex={ind}
					card={c}
					selected={c.selected}
					filtered={c.filtered}
					cardScale={_scale}
					cardTop={calcTop}
					cardLeft={calcLeft}
					cardRotation={cardsStyle.rotations[ind]}
					onPress={this.props.selectCard}
					preloadedCard={this.props.preloadedCards[this.props.cardset][cardsetSize][c.id]}
				/>
			);
		});
		return plCards;
	}

	render() {
		return (
			<View key="playerCardsContainer">
				{this.renderPlayersCards()}
				<ThrowAwayButton />
				<TimerView />
			</View>
		);
	}
	//#endregion
}

const mapStateToProps = (state) => {
	return {
		playerCnt: state.startedGame.playerCnt,
		playersCardsProp: state.startedGame.gameBoard.playersCardsProp,
		cards: state.startedGame.cards,
		resolution: state.currentUser.preferences.resolution,
		preloadedCards: state.startedGame.preloadedCards,
		cardset: state.startedGame.cardset,
	};
};

const mapDispatchToProps = { dealCards, selectCard, throwAway };

export default connect(mapStateToProps, mapDispatchToProps)(PlayersCardsArea);
