import React, { Component } from "react";
import { Dimensions, FlatList, Platform, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { appFont, gameDetailsTableKey, isTablet, isWebOrTablet, modalType, RESOLUTION } from "../../config/defaults";
import { gameDetailsColumns } from "../../config/dimensions";
import { playAgainstComputerForChips } from "../../config/permissions";
import { MyWebSocket } from "../../connection";
import * as helpers from "../../helpers/commonHelpers";
import {
	clearSlotSelection,
	disableChangeSlot,
	displayNotification,
	displayPermissionDenied,
	gameDetailsTableContentSizeChanged,
	gameDetailsTableHeaderSize,
	gameDetailsTableRendered,
	mouseEnterGameRoomsSlot,
	mouseEnterNewGamesSlot,
	mouseLeaveGameRoomsSlot,
	mouseLeaveNewGamesSlot,
	openModalDialog,
	requestAdditionalGameDetailsTableHeight,
	selectGameRoomsSlot,
	selectNewGamesSlot,
	updateNewGamesPlayers,
	updatePlayerAmount,
} from "../../redux/actions";
import { store } from "../../redux/store";
import { AppTouchableOpacity, Box, TableHeader, TableHeaderCell, TableRow, TableRowCell } from "../common";
import { handleError } from "../ErrorHandler";

class CreateNewGameTable extends Component {
	constructor(props) {
		super(props);
		const _dataSource = this.getPlayerDropDownDataSource();
		this.state = {
			columnNames: this.getColumNames(),
			show: false,
			dropDownDataSource: _dataSource,
			selectedSlotID: null,
			hoveredDropDownItem: null,
			maxVisibleRows: 1,
			tableHeight: 0,
			// selectedDropDownItem: null,
		};
		this.requestedHeight = [Number("0")];
	}

	//#region lifecycle methods
	componentDidMount() {
		this.props.gameDetailsTableRendered(this.props.tableKey);
	}

	shouldComponentUpdate(nextProps) {
		if (
			this.props.gameDetailsTable.additionalHeight != nextProps.gameDetailsTable.additionalHeight &&
			nextProps.gameDetailsTable.additionalHeight == 0
		) {
			this.requestedHeight = [Number("0")];
		}
		return true;
	}
	//#endregion

	//#region events
	tableRendered(event) {
		try {
			const tableHeight = event.nativeEvent.layout.height;
			const maxVisibleRows = isWebOrTablet ? Math.floor(tableHeight / 40) : Math.floor(tableHeight / 40);
			if (this.props.tableKey == gameDetailsTableKey.joinedGame) {
				const { gameRoomPlayers } = this.props;
				if (gameRoomPlayers.length > maxVisibleRows && this.props.resolution == RESOLUTION.LOW) {
					const additionalRowNr = gameRoomPlayers.length - maxVisibleRows;
					var additionalHeight = isWebOrTablet ? additionalRowNr * 40 : additionalRowNr * 40;

					const { grSlotSettingsHeight } = store.getState().views;
					const gameDetailsHeight = grSlotSettingsHeight + 10;
					const tableHeaderHeight = 0; //isTablet ? 22 : 16;
					const tmpHeight =
						this.props.gameDetailsTable.contentHeight +
						tableHeaderHeight +
						this.props.gameDetailsTable.additionalHeight +
						additionalHeight;

					if (tmpHeight > gameDetailsHeight) {
						additionalHeight =
							gameDetailsHeight -
							this.props.gameDetailsTable.contentHeight -
							tableHeaderHeight -
							this.props.gameDetailsTable.additionalHeight;
					}

					if (this.requestedHeight.indexOf(additionalHeight) == -1) {
						this.requestedHeight.push(additionalHeight);
						this.props.requestAdditionalGameDetailsTableHeight(additionalHeight);
					}
				}
			}
			this.setState({ maxVisibleRows, tableHeight });
		} catch (error) {
			handleError(error);
		}
	}

	onKickBanPlayer(slotID, ban) {
		try {
			if (this.props.tableKey == gameDetailsTableKey.joinedGame) {
				if (typeof slotID === "number" && typeof ban === "boolean" && this.props.gameRoomsGameId != -1) {
					var msgGamePlayerKick = {
						type: "gamePlayerKick",
						gameID: this.props.gameRoomsGameId,
						slotID: slotID,
					};
					var selectedPlayer = this.props.gameRoomPlayers.find((obj) => obj.slotID == slotID);
					if (typeof selectedPlayer !== "undefined") {
						msgGamePlayerKick.userID = selectedPlayer.userID;
						if (ban) {
							this.props.openModalDialog(
								modalType.DIALOG,
								this.props.lang.messages.banPlayerWarningMessage,
								this.props.lang.yes,
								() => {
									msgGamePlayerKick.ban = true;
									MyWebSocket.shared.sendMsg(msgGamePlayerKick);
								},
								this.props.lang.no,
								null
							);
						} else {
							this.props.openModalDialog(
								modalType.DIALOG,
								this.props.lang.messages.kickPlayerWarningMessage,
								this.props.lang.yes,
								() => {
									msgGamePlayerKick.ban = false;
									MyWebSocket.shared.sendMsg(msgGamePlayerKick);
								},
								this.props.lang.no,
								null
							);
						}
					}
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	onHeaderLayout(event) {
		try {
			const { gameDetailsTableHeaderSize } = this.props;
			if (Platform.OS !== "web") event.persist();
			gameDetailsTableHeaderSize(event.nativeEvent.layout);
		} catch (error) {
			handleError(error);
		}
	}

	onRowSelect(slotID) {
		try {
			switch (this.props.tableKey) {
				case gameDetailsTableKey.newGame:
					this.props.selectNewGamesSlot(slotID);
					break;
				case gameDetailsTableKey.joinedGame:
					this.props.selectGameRoomsSlot(slotID);
					break;
			}
		} catch (error) {
			handleError(error);
		}
	}

	onRowDoubleTap(slotID) {
		try {
			/* this.onRowSelect(slotID);
      setTimeout(() => {
        if (
          this.props.canChangeSlot &&
          !this.props.grIsGameCreator &&
          this.props.grSelectedSlotID != -1 &&
          this.props.gameRoomsGameId != -1
        ) {
          this.props.disableChangeSlot();
          var msgGameJoin = {
            type: "joinGame",
            sMessageID: 0,
            gameID: this.props.gameRoomsGameId,
            slotID: this.props.grSelectedSlotID,
            swap: true,
          };
          MyWebSocket.shared.sendMsg(msgGameJoin);
          this.props.clearSlotSelection();
        }
      }, 100); */
		} catch (error) {
			handleError(error);
		}
	}

	onMouseEnter(slotID) {
		try {
			switch (this.props.tableKey) {
				case gameDetailsTableKey.newGame:
					this.props.mouseEnterNewGamesSlot(slotID);
					break;
				case gameDetailsTableKey.joinedGame:
					this.props.mouseEnterGameRoomsSlot(slotID);
					break;
			}
		} catch (error) {
			handleError(error);
		}
	}

	onMouseLeave(slotID) {
		try {
			switch (this.props.tableKey) {
				case gameDetailsTableKey.newGame:
					this.props.mouseLeaveNewGamesSlot(slotID);
					break;
				case gameDetailsTableKey.joinedGame:
					this.props.mouseLeaveGameRoomsSlot(slotID);
					break;
			}
		} catch (error) {
			handleError(error);
		}
	}

	onPlayerDropButtonPress(positions, nro, show, slotID) {
		try {
			const { tableKey, players, gameRoomPlayers, chipsRequired, publishGameJSON } = this.props;
			const playersSource = tableKey == gameDetailsTableKey.newGame ? players : gameRoomPlayers;
			const reqChipsAmount = tableKey == gameDetailsTableKey.newGame ? publishGameJSON.chipAmount : chipsRequired;
			var comp_counter = 0;
			var comp_ranking = "";
			playersSource.forEach((po) => {
				if (typeof po.ranking === "string" && po.ranking != "") {
					comp_counter++;
					// comp_ranking = po.ranking;
				}
			});
			var tmpDataSource = [...this.state.dropDownDataSource];
			tmpDataSource.forEach((obj) => {
				// if (this.props.GAME_CREATE_CAN_SET_CUSTOM_BOTS) {
				if (obj.slotType == "COMPUTER" && obj.label != this.props.lang.computerLabel) {
					obj.enabled = comp_counter == 0 || obj.strength.toLowerCase() == comp_ranking.toLowerCase();
					if (typeof playAgainstComputerForChips === "boolean" && !playAgainstComputerForChips) {
						obj.enabled = obj.enabled && reqChipsAmount == 0;
						if (reqChipsAmount > 0)
							obj.warningMsg = this.props.lang.messages.playAgainstComputerForChipsWarning.main_text;
					}

					if (comp_counter > 0 && obj.strength.toLowerCase() != comp_ranking.toLowerCase())
						obj.warningMsg = this.props.lang.messages.strengthDisabledWarningMessage.main_text.replace(
							"{0}",
							comp_ranking.toLowerCase()
						);

					if (obj.enabled) obj.warningMsg = "";
				}
				// } else {
				//   obj.enabled = false;
				//   obj.warningMsg = "";
				// }
			});
			if (typeof slotID === "number") this.onRowSelect(slotID);
			const { leftPosition, height, width } = positions;
			const { contentHeight } = this.props.gameDetailsTable;
			const tableHeaderHeight =
				this.props.tableKey == gameDetailsTableKey.newGame ? this.props.gameDetailsTable.tableHeaderHeight : 0;
			var newTop = (nro + 1) * height + tableHeaderHeight - 6;
			var newLeft = Math.round((width - 200) / 2) + leftPosition;
			if (newTop > contentHeight) {
				newTop = tableHeaderHeight;
			}
			newTop = isNaN(newTop) ? 0 : newTop;
			newLeft = isNaN(newLeft) ? 0 : newLeft;

			var newHeight = this.state.tableHeight + tableHeaderHeight;
			if (this.props.tableKey != gameDetailsTableKey.joinedGame) {
				newHeight = this.props.isHelpExpanded == true ? newHeight + this.props.helpHeight : newHeight;
				const dropDownListHeight = this.state.dropDownDataSource.length * 30;
				if (dropDownListHeight + 15 + newTop > newHeight) {
					newHeight -= newTop;
					newHeight -= 15;
					styles.boxStyle = [styles.boxStyle, { top: newTop, left: newLeft, maxHeight: newHeight }];
				} else {
					styles.boxStyle = [styles.boxStyle, { top: newTop, left: newLeft, maxHeight: isTablet ? 230 : 190 }];
				}
			} else {
				styles.boxStyle = [styles.boxStyle, { top: newTop, left: newLeft, maxHeight: isTablet ? 230 : 190 }];
			}
			this.setState({
				dropDownDataSource: tmpDataSource,
				show: show,
				selectedSlotID: nro,
			});
		} catch (error) {
			handleError(error);
		}
	}

	onMouseEnterItem(item) {
		this.setState({ hoveredDropDownItem: item });
	}

	onMouseLeaveItem() {
		this.setState({ hoveredDropDownItem: null });
	}

	onDropDownItemSelect(item) {
		try {
			const { selectedSlotID } = this.state;
			if (selectedSlotID !== null) {
				if (item.enabled) {
					const { tableKey, publishGameJSON, gameRoomPlayers } = this.props;
					// var selBotID = -1;
					var selSlot;
					switch (tableKey) {
						case gameDetailsTableKey.newGame:
							// selBotID = publishGameJSON.slots[selectedSlotID - 1].botID;
							selSlot = publishGameJSON.slots[selectedSlotID - 1];
							break;
						case gameDetailsTableKey.joinedGame:
							selSlot = gameRoomPlayers[selectedSlotID];
							/* if (typeof selSlot.username === "string") {
                const lowerCaseUName = selSlot.username.toLowerCase();
                if (
                  lowerCaseUName.includes(
                    this.props.lang.computerLabel.toLowerCase()
                  )
                ) {
                  if (
                    lowerCaseUName.includes(this.props.lang.easy.toLowerCase())
                  )
                    selBotID = 3;
                  if (
                    lowerCaseUName.includes(
                      this.props.lang.medium.toLowerCase()
                    )
                  )
                    selBotID = 2;
                  if (
                    lowerCaseUName.includes(this.props.lang.hard.toLowerCase())
                  )
                    selBotID = 1;
                }
                if (
                  lowerCaseUName.includes(
                    this.props.lang.openSlot.toLowerCase()
                  )
                )
                  selBotID = 0;
              } */
							break;
					}
					if (
						selSlot &&
						((selSlot.state && selSlot.state != item.state) || (selSlot.type && selSlot.type != item.type))
					) {
						var isComputer = false;
						switch (item.slotType) {
							case "HUMAN":
								/* if (item.state == "OPEN") {
                this.setState({ selectedDropDownItem: item, show:false });
              } */
								isComputer = false;
								break;
							case "COMPUTER":
								/* if (typeof item.botID === "number" && item.enabled) {
                this.setState({ selectedDropDownItem: item, show:false });
              } */
								isComputer = true;
								break;
						}
						if (tableKey === gameDetailsTableKey.newGame) {
							this.props.updateNewGamesPlayers(selectedSlotID, item, publishGameJSON.playerCnt);
						}
						if (tableKey === gameDetailsTableKey.joinedGame) {
							//TODO: START put the following back if server will be ready
							// var msgGameUpdateSlot = {
							//   type: "gameUpdateSlot",
							//   sMessageID: 0,
							//   gameID: this.props.gameRoomsGameId,
							//   slotID: selectedSlotID,
							//   botType: item.type,
							//   state: "OCCUPIED",
							//   // botID: item.botID,
							//   // isComputerPlayer: isComputer,
							// };
							// /* if (isComputer)
							//   msgGameUpdateSlot.computerPlayer = "CASE_BASED_ORIGINAL"; //TODO: define this somehow */
							// MyWebSocket.shared.sendMsg(msgGameUpdateSlot);
							//TODO: END put the following back if server will be ready
						}
						this.setState({ /* selectedDropDownItem: null, */ show: false });
					}
				} else {
					if (item.warningMsg != "") {
						var notification = {
							displayTime: 5000,
							deleteHistory: false,
							rotate: false,
							clickable: false,
							playSound: true,
							url: "#",
							priority: "high",
							announcement: item.warningMsg,
							isNotification: true,
						};
						this.props.displayNotification(notification);
					} else {
						// this.props.displayPermissionDenied(
						//   "GAME_CREATE_CAN_SET_CUSTOM_BOTS"
						// );
					}
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderDropDownListRow(item) {
		try {
			const myStyle =
				Platform.OS === "web"
					? (item.slotType == "COMPUTER" && !item.enabled) ||
					  (item.label == this.props.lang.closed && this.props.publishGameJSON.playerCnt == 2)
						? { cursor: "default" }
						: { cursor: "pointer" }
					: {};

			const backColor =
				(item.slotType == "COMPUTER" && !item.enabled) ||
				(item.label == this.props.lang.closed && this.props.publishGameJSON.playerCnt == 2) ||
				this.state.hoveredDropDownItem == item
					? "#ddd"
					: "#fff";
			const opacity =
				(item.slotType == "COMPUTER" && !item.enabled) ||
				(item.label == this.props.lang.closed && this.props.publishGameJSON.playerCnt == 2)
					? 0.5
					: 1;
			const mouseEnter = () => this.onMouseEnterItem(item);
			const mouseLeave = () => this.onMouseLeaveItem();
			const selectItem = () => this.onDropDownItemSelect(item);
			return (
				<AppTouchableOpacity
					activeOpacity={opacity}
					style={[{ backgroundColor: backColor, opacity }, myStyle]}
					onMouseEnter={mouseEnter}
					onMouseLeave={mouseLeave}
					onPress={selectItem}
				>
					<Text allowFontScaling={false} style={[styles.normalText, { backgroundColor: backColor }]}>
						{item.label}
					</Text>
				</AppTouchableOpacity>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderDropDownList() {
		// if (this.state.show) {
		return (
			<Box style={[styles.boxStyle, this.state.show == false && { width: 0, height: 0, opacity: 0 }]}>
				<FlatList
					nestedScrollEnabled={true}
					keyExtractor={(item, index) => "pdd_" + index.toString()}
					data={this.state.dropDownDataSource}
					extraData={this.props}
					renderItem={({ item }) => this.renderDropDownListRow(item)}
					// getItemLayout={(item, index) => ({
					//   length: 16.8,
					//   offset: 16.8 * index,
					//   index,
					// })}
					style={{ backgroundColor: "#fff", position: "relative", zIndex: 5 }}
				/>
			</Box>
		);
		// }
		// return null;
	}

	renderHeader() {
		try {
			if (typeof this.props.isGameCreator !== "boolean") return null;

			const { gameDetailsTableColumnsDimensions } = this.props;
			const headerCells = [];

			helpers.mapObject(this.state.columnNames, (key, value) => {
				let cWidth = 0;
				if (typeof gameDetailsTableColumnsDimensions[key] === "number") cWidth = gameDetailsTableColumnsDimensions[key];
				const enableIncreaseDecrease = key == "username" && this.props.tableKey == gameDetailsTableKey.newGame;
				const textStyle = {
					textAlign: "center",
					backgroundColor: "transparent",
				};

				const containerStyle = {
					width: cWidth,
					justifyContent: "center",
					backgroundColor: "transparent",
				};

				let headerKey = key + (this.props.tableKey || "") + "Header";

				const _headerCell = (
					<TableHeaderCell
						key={headerKey}
						cellContent={value}
						containerStyle={[
							containerStyle,
							this.props.resolution == RESOLUTION.LOW && {
								minHeight: isTablet ? 22 : this.props.tableKey == gameDetailsTableKey.newGame ? 18 : 16,
								height: isTablet ? 22 : this.props.tableKey == gameDetailsTableKey.newGame ? 18 : 16,
							},
							this.props.tableKey == gameDetailsTableKey.newGame && {
								minHeight: 30,
								height: 30,
							},
						]}
						textStyle={[
							textStyle,
							this.props.tableKey == gameDetailsTableKey.newGame && {
								fontSize: 18,
							},
						]}
						isLowResolution={
							this.props.resolution == RESOLUTION.LOW && this.props.tableKey !== gameDetailsTableKey.newGame
						}
						isSortable={false}
						isSelected={false}
						isAscending={false}
						enableIncreaseDecrease={enableIncreaseDecrease}
						enableAdd={this.props.publishGameJSON.playerCnt < 8}
						enableRemove={this.props.publishGameJSON.playerCnt > 2}
						addAction={() => {
							if (this.props.publishGameJSON.playerCnt < 8) {
								this.props.updatePlayerAmount(this.props.publishGameJSON.playerCnt + 1);
							}
							if (this.props.resolution == RESOLUTION.LOW && this.props.isHelpExpanded == true) {
								this.props.hideHelp();
							}
						}}
						removeAction={() => {
							if (this.props.publishGameJSON.playerCnt > 2) {
								this.props.updatePlayerAmount(this.props.publishGameJSON.playerCnt - 1);
							}
						}}
					/>
				);
				if (key === "teamName") {
					if (this.props.resolution !== RESOLUTION.LOW && isWebOrTablet) headerCells.push(_headerCell);
				} else {
					headerCells.push(_headerCell);
				}
			});

			return <TableHeader onLayout={(event) => this.onHeaderLayout(event)}>{headerCells}</TableHeader>;
		} catch (error) {
			handleError(error);
		}
	}

	renderTableRow(item) {
		try {
			const rowId = "row_" + item.slotID;
			const { gameDetailsTableColumnsDimensions } = this.props;
			let cells = [];
			helpers.mapObject(this.state.columnNames, (key) => {
				if (typeof item[key] !== "undefined" || key == "avatar") {
					let cellId = key + "_" + item.slotID;
					let cellKey = "cell_" + cellId;
					let textStyle =
						gameDetailsColumns[key].cellType !== "img"
							? {
									textAlign: "center",
									color: "#FFFFFF",
									lineHeight:
										(this.props.tableKey == gameDetailsTableKey.newGame && this.props.isGameCreator) ||
										this.props.tableKey == gameDetailsTableKey.joinedGame
											? 40
											: 40,
							  }
							: {};
					textStyle = [
						textStyle,
						{
							height:
								(this.props.tableKey == gameDetailsTableKey.newGame && this.props.isGameCreator) ||
								this.props.tableKey == gameDetailsTableKey.joinedGame
									? 40
									: 40,
						},
						isWebOrTablet &&
							(key == "slotID" || key == "teamName") && {
								paddingHorizontal: 0,
							},
					];

					var imgDim = {},
						laurelDim = {},
						isPlayerDropDown = false,
						isTogglePlayer = false,
						enableKickBan = false,
						contentTextColor = "#000000",
						showToolTip = false,
						tooltipText = "";
					switch (key) {
						case "username":
							textStyle = [...textStyle, { fontSize: 20 }];
							if (
								item[key] == this.props.lang.openSlot ||
								(typeof item.ranking === "string" &&
									(item.ranking == "" ||
										[
											"VERY_EASY",
											"EASY",
											"MEDIUM",
											"HARD",
											"VERY_HARD",
											"RANDOM", //if user isn't VIP
										].includes(item.ranking)))
							) {
								if (this.props.tableKey == gameDetailsTableKey.joinedGame) {
									// isPlayerDropDown = this.props.grIsGameCreator;
									isTogglePlayer = this.props.grIsGameCreator;
									if (this.props.grIsGameCreator) {
										contentTextColor = item.state === "OCCUPIED" ? "#FFFFFF" : "#00fffd";
									} else {
										textStyle = [
											textStyle,
											{
												color: item.state === "OCCUPIED" ? "#FFFFFF" : "#000000",
											},
										];
									}
								}
								if (this.props.tableKey == gameDetailsTableKey.newGame && this.props.isGameCreator) {
									// isPlayerDropDown = this.props.isGameCreator;
									isTogglePlayer = this.props.isGameCreator;
									contentTextColor = "#00fffd";
								}
							} else {
								if (
									this.props.tableKey == gameDetailsTableKey.joinedGame &&
									item.state == "OCCUPIED" &&
									typeof item.userID !== "undefined"
								)
									enableKickBan = this.props.grIsGameCreator && this.props.userDetails.userID != item.userID;
							}
							break;
						case "country":
							var countryURL = {};
							if (typeof item[key] === "string" && item[key] != "") {
								countryURL = {
									uri: helpers.getCountryFlagImageURL(item[key], 40),
								};
								imgDim = { width: 30, height: 30 };
							}
							showToolTip = Platform.OS === "web";
							const countryCode = typeof item[key] === "string" ? item[key].toLowerCase() : undefined;
							tooltipText =
								countryCode && typeof this.props.lang.countryList[countryCode] === "string"
									? this.props.lang.countryList[countryCode]
									: undefined;
							break;
						case "ranking":
							var _isVip = item.isVip;
							var rankingURL = {};
							var rankDim = 30;
							const winDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
							if (winDim.scale > 1) {
								if (winDim.scale < 2.5) {
									rankDim = 50;
								} else {
									rankDim = 150;
								}
							}
							rankingURL = helpers.getRankingImageURL(item[key], rankDim);
							imgDim = { width: 30, height: 30 };
							var laurelDim = { width: 54, height: 54 };
							if (["EASY", "VERY_EASY", "MEDIUM", "HARD", "VERY_HARD"].includes(item[key])) {
								showToolTip = Platform.OS === "web";
								tooltipText =
									typeof this.props.lang[item[key].toLowerCase()] === "string"
										? this.props.lang[item[key].toLowerCase()]
										: undefined;
							}
							break;
						case "avatar":
							if (item.userID && !item.userID.startsWith("generated")) {
								const _tmpURL = "https://" + this.props.avatars + item.userID + "/" + item.curDate;
								var avatarURL = item.userID.startsWith("generated") ? { uri: "https://" } : { uri: _tmpURL };
								imgDim = { width: 40, height: 40 };
							}
							if (item.botImage && item.botImage != "" && item.botImage.endsWith(".png")) {
								var avatarURL = {
									uri: "https://" + this.props.botAvatars + item.botImage,
								};
								imgDim = { width: 40, height: 40 };
							}
							break;
					}

					const _cell = (
						<TableRowCell
							lang={this.props.lang}
							isSelected={item.isSelected}
							isHovered={item.isHovered}
							backgroundColor={"transparent"}
							selectedBackgroundColor={"#F6A022"}
							style={textStyle}
							cellWidth={gameDetailsTableColumnsDimensions[key]}
							key={cellKey}
							cellId={cellId}
							content={key == "slotID" ? item[key] + 1 : item[key]}
							cellType={gameDetailsColumns[key].cellType || "text"}
							isPlayerDropDown={isPlayerDropDown}
							isTogglePlayer={isTogglePlayer}
							tableKey={this.props.tableKey}
							enableKickBan={false}
							kickAction={() => this.onKickBanPlayer(item.slotID, false)}
							banAction={() => this.onKickBanPlayer(item.slotID, true)}
							onDropButtonPress={
								key == "username" &&
								(item[key] == this.props.lang.openSlot || (typeof item.ranking === "string" && item.ranking == ""))
									? this.onPlayerDropButtonPress.bind(this)
									: null
							}
							onDropButtonBlur={() => {
								this.setState({ show: false });
							}}
							show={this.state.show}
							slotID={item.slotID}
							contentTextColor={contentTextColor}
							imgURL={avatarURL || countryURL || rankingURL}
							isVip={_isVip}
							laurelDim={laurelDim}
							imgDim={imgDim}
							cellContainerStyle={{ height: 40 }}
							showToolTip={showToolTip}
							tooltipText={tooltipText}
						/>
					);
					cells.push(_cell);
					/* if (key === "teamName") {
            if (this.props.resolution !== RESOLUTION.LOW && isWebOrTablet)
              cells.push(_cell);
          } else {
            cells.push(_cell);
          } */
				}
			});

			/* var rowSelectFunc, onDoubleTapFunc, rowMouseEnterFunc, rowMouseLeaveFunc;
      if (this.props.tableKey !== gameDetailsTableKey.joinedGame) {
        rowSelectFunc = () => this.onRowSelect(item.slotID);
        onDoubleTapFunc = () => this.onRowDoubleTap(item.slotID);
        rowMouseEnterFunc = () => this.onMouseEnter(item.slotID);
        rowMouseLeaveFunc = () => this.onMouseLeave(item.slotID);
      } */

			return (
				<TableRow
					rowId={rowId}
					// onPress={rowSelectFunc}
					// onDoubleTap={onDoubleTapFunc}
					// onMouseEnter={rowMouseEnterFunc}
					// onMouseLeave={rowMouseLeaveFunc}
					disabled={true}
				>
					{cells}
				</TableRow>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderTable() {
		const {
			isGameCreator,
			gameDetailsTableContentSizeChanged,
			gameDetailsTable,
			tableKey,
			grIsGameCreator,
			gameRoomPlayers,
			players,
		} = this.props;
		if (tableKey == gameDetailsTableKey.newGame && typeof isGameCreator !== "boolean") return null;
		if (tableKey == gameDetailsTableKey.joinedGame && typeof grIsGameCreator !== "boolean") return null;

		var datasource = [];
		switch (tableKey) {
			case gameDetailsTableKey.joinedGame:
				datasource = gameRoomPlayers;
				break;
			case gameDetailsTableKey.newGame:
				datasource = players;
				break;
		}

		return (
			<FlatList
				keyExtractor={(player) => tableKey + player.slotID.toString()}
				data={datasource}
				extraData={this.props}
				renderItem={({ item }) => this.renderTableRow(item)}
				getItemLayout={(data, index) => ({
					length: 42,
					offset: 42 * index,
					index,
				})}
				onContentSizeChange={(width, height) => {
					gameDetailsTableContentSizeChanged({ width, height });
				}}
				style={{
					// height: gameDetailsTable.contentHeight,
					height:
						this.props.isHelpExpanded || this.props.tableKey == gameDetailsTableKey.joinedGame
							? this.props.resolution != RESOLUTION.LOW
								? this.props.gameDetailsTable.contentHeight
								: this.props.gameDetailsTable.contentHeight + this.props.gameDetailsTable.additionalHeight
							: "100%",
					// width: gameDetailsTable.contentWidth,
				}}
				onLayout={(event) => this.tableRendered(event)}
			/>
		);
	}

	render() {
		const tableStyle = {
			height:
				this.props.isHelpExpanded || this.props.tableKey == gameDetailsTableKey.joinedGame
					? this.props.resolution != RESOLUTION.LOW
						? this.props.gameDetailsTable.contentHeight + 2
						: this.props.gameDetailsTable.contentHeight + this.props.gameDetailsTable.additionalHeight + 2
					: "100%",
			// width: this.props.gameDetailsTable.contentWidth || null,
		};
		return (
			<View style={tableStyle}>
				{this.props.tableKey == gameDetailsTableKey.newGame && this.renderHeader()}
				{this.renderTable()}
				{this.renderDropDownList()}
			</View>
		);
	}
	//#endregion

	//#region helpers
	getColumNames() {
		try {
			let columnNames = {};
			const { gameDetailsTableColumns } = this.props.lang;
			helpers.mapObject(gameDetailsColumns, (key) => {
				// if (key !== "avatar")
				columnNames[key] = gameDetailsTableColumns[key] || "";
			});
			return columnNames;
		} catch (error) {
			handleError(error);
		}
	}

	getPlayerDropDownDataSource() {
		try {
			var dataSource = [
				{
					slotType: "HUMAN",
					enabled: true,
					label:
						typeof this.props.lang !== "undefined" && typeof this.props.lang.openSlot !== "undefined"
							? this.props.lang.openSlot
							: "OPEN SLOT",
					state: "OPEN",
					botID: 0,
					strength: "",
					warningMsg: "",
				},
			];
			if (Array.isArray(this.props.availableBots) && this.props.availableBots.length > 0) {
				this.props.availableBots.forEach((bot, ind) => {
					if (bot.type) {
						dataSource.push({
							type: bot.type,
							slotType: "COMPUTER",
							enabled: true,
							label: bot.name,
							state: "OCCUPIED",
							botID: ind,
							strength: bot.strength,
							warningMsg: "",
							botImage: bot.image,
						});
					}
				});
			}
			/* if (
        typeof tableKey !== "undefined" &&
        tableKey == gameDetailsTableKey.newGame
      ) {
        dataSource.splice(1, 0, {
          slotType: "HUMAN",
          enabled: true,
          label:
            typeof lang !== "undefined" && typeof lang.closed !== "undefined"
              ? lang.closed
              : "CLOSED",
          state: "OCCUPIED",
          warningMsg: "",
        });
      } */
			return dataSource;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	boxStyle: {
		position: "absolute",
		top: 0,
		left: 0,
		width: isTablet ? 200 : 160,
		minHeight: 30,
		borderRadius: 0,
		backgroundColor: "transparent",
		zIndex: 5,
	},
	normalText: {
		fontFamily: appFont,
		fontSize: isTablet ? 20 : 14,
		paddingTop: isTablet ? 5 : 2,
		paddingBottom: isTablet ? 5 : 2,
		paddingLeft: 6,
		paddingRight: 6,
	},
});

const mapStateToProps = (state) => {
	const { features } = state.currentUser;
	return {
		lang: state.language.currentLanguage,
		avatars: state.app.welcome.webResources.avatars,
		botAvatars: state.app.welcome.webResources.botAvatars,
		gameDetailsTable: state.views.gameDetailsTable,
		gameDetailsTableColumnsDimensions: state.views.gameDetailsTableColumnsDimensions,
		isSmallResolution: state.views.isSmallResolution,
		publishGameJSON: state.createNewGame.publishGameJSON,
		players: state.createNewGame.players,
		isGameCreator: state.createNewGame.isGameCreator,
		availableBots: state.createNewGame.availableBots,
		gameRoomPlayers: state.gameRoom.gameRoomPlayers,
		grIsGameCreator: state.gameRoom.grIsGameCreator,
		gameRoomsGameId: state.gameRoom.gameRoomsGameId,
		grSelectedSlotID: state.gameRoom.grSelectedSlotID,
		canChangeSlot: state.gameRoom.canChangeSlot,
		chipsRequired: state.gameRoom.chipsRequired,
		userDetails: state.currentUser.userDetails,
		resolution: state.currentUser.preferences.resolution,
		// GAME_CREATE_CAN_SET_CUSTOM_BOTS: features.GAME_CREATE_CAN_SET_CUSTOM_BOTS,
	};
};

const mapDispatchToProps = {
	clearSlotSelection,
	disableChangeSlot,
	displayNotification,
	displayPermissionDenied,
	gameDetailsTableContentSizeChanged,
	gameDetailsTableHeaderSize,
	gameDetailsTableRendered,
	mouseEnterGameRoomsSlot,
	mouseEnterNewGamesSlot,
	mouseLeaveGameRoomsSlot,
	mouseLeaveNewGamesSlot,
	openModalDialog,
	requestAdditionalGameDetailsTableHeight,
	selectGameRoomsSlot,
	selectNewGamesSlot,
	updateNewGamesPlayers,
	updatePlayerAmount,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewGameTable);
