import React, { Component } from "react";
import { Dimensions, Image, ImageBackground, Platform, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import {
	f_sourcesansprobold,
	f_sourcesansproregular,
	f_sourcesansprosemibold,
	isTablet,
	isWebOrTablet,
	tabType,
} from "../../config/defaults";
import { gameDimensions } from "../../config/dimensions";
import { LOST_CROWN, WON_CROWN } from "../../config/images";
import { MyWebSocket } from "../../connection";
import { EventQueue } from "../../controller";
import { finishCountdown } from "../../Keezers/redux/actions";
import {
	openGameLobby,
	openMultipliersShop,
	openTab,
	resetCreateNewGame,
	resetGameEnd,
	resetGameRoom,
	showDidYouKnow,
} from "../../redux/actions/";
//removed because of crashlytics import GameRewardedAd from "../Admob/RewardedAd";
import Analytics from "../Analytics/Analytics";
import { AppTouchableOpacity /* , Spinner */ } from "../common";
import { handleError } from "../ErrorHandler";
import FBShareButton from "../Facebook/FBShareButton";
import {
	BUY_BOOSTERS_BUTTON,
	EXIT_BUTTON_BACK,
	LOSE_BACKGROUND,
	SILVER_ICON,
	WIN_BACKGROUND,
} from "./../../config/images";
import DidYouKnow from "./DidYouKnow";

class GameEnd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rowHeight: Platform.OS === "web" ? 36 : 24,
			isExitButtonHovered: false,
			isBuyBoostersButtonHovered: false,
			btnBackWidth: 0,
			btnBackHeight: 0,
			FBShareResult: null,
		};
	}

	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("GameEnd");
	}

	componentWillUnmount() {
		this.dimensionsSubscription?.remove();
	}
	//#endregion

	//#region events
	measureRowHeight(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			this.setState({ rowHeight: event.nativeEvent.layout.height });
		} catch (error) {
			handleError(error);
		}
	}

	onButtonBackLayout(event) {
		if (Platform.OS !== "web") event.persist();
		this.setState({
			btnBackWidth: event.nativeEvent.layout.width,
			btnBackHeight: event.nativeEvent.layout.height,
		});
	}

	async onExitPress() {
		try {
			if (this.props.allowFacebookSharing) {
				var shareResult = {
					type: "shareResult",
					sMessageID: 0,
					platform: "FACEBOOK",
					hash: this.props.faceBookShareHash,
					shareID: this.props.faceBookShareID,
				};
				if (this.state.FBShareResult === null) {
					await Analytics.logEvent("facebookShare_NOT_SHARED");
					shareResult.result = "NOT_SHARED";
					MyWebSocket.shared.sendMsg(shareResult);
				} else {
					switch (this.state.FBShareResult.result) {
						case "USER_ABORT":
							await Analytics.logEvent("facebookShare_ABORTED");
							shareResult.result = this.state.FBShareResult.result;
							break;
						case "SHARED":
							shareResult.result = this.state.FBShareResult.result;
							break;
						case "ERROR":
							await Analytics.logEvent("facebookShare_ERROR");
							shareResult.result = this.state.FBShareResult.result;
							shareResult.errorData = this.state.FBShareResult.errorData;
							break;
						default:
							await Analytics.logEvent("facebookShare_NOT_SHARED");
							shareResult.result = "NOT_SHARED";
							break;
					}
					if (this.state.FBShareResult.result !== "SHARED") MyWebSocket.shared.sendMsg(shareResult);
				}
			}
			EventQueue.shared.resetEventsQueue();
			this.props.finishCountdown();
			this.props.resetGameRoom();
			this.props.resetCreateNewGame();

			if (this.props.gameEnd.requestDidYouKnow && this.props.gameEnd.didYouKnowScreenID) {
				this.props.showDidYouKnow();
			} else {
				this.props.resetGameEnd();
				this.props.openGameLobby();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onBuyBooster() {
		try {
			this.props.openTab(tabType.SHOP);
			this.props.openMultipliersShop();
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderBuyBoostersButton(fontSize, lineHeight, paddingLeft, boosterPadding) {
		const opacity = this.state.isBuyBoostersButtonHovered ? 0.8 : 1;
		return (
			<AppTouchableOpacity
				activeOpacity={0.5}
				onPress={this.onBuyBooster.bind(this)}
				onMouseEnter={() => {
					this.setState({ isBuyBoostersButtonHovered: true });
				}}
				onMouseLeave={() => {
					this.setState({ isBuyBoostersButtonHovered: false });
				}}
				style={{
					width: "100%",
					height: "100%",
					paddingRight: boosterPadding,
					flexDirection: "row",
					justifyContent: "flex-end",
					opacity,
				}}
			>
				<Text
					allowFontScaling={false}
					selectable={false}
					numberOfLines={1}
					ellipsizeMode="tail"
					style={[
						styles.boldText,
						{
							flex: 1,
							fontSize,
							lineHeight,
							paddingLeft,
							color: "blue",
						},
					]}
				>
					{this.props.lang.gameEnd.multiplier}
				</Text>
				<Image
					source={BUY_BOOSTERS_BUTTON}
					style={{ width: "24%", height: "57%", alignSelf: "center" }}
					resizeMode={Platform.OS === "ios" ? "contain" : "center"}
				/>
			</AppTouchableOpacity>
		);
	}

	renderExitButton() {
		const opacity = this.state.isExitButtonHovered ? 0.8 : 1;
		return (
			<AppTouchableOpacity
				activeOpacity={opacity}
				onPress={this.onExitPress.bind(this)}
				disabled={false}
				onMouseEnter={() => {
					this.setState({ isExitButtonHovered: true });
				}}
				onMouseLeave={() => {
					this.setState({ isExitButtonHovered: false });
				}}
				style={[styles.buttonStyle, styles.buttonDimensions]}
			>
				<ImageBackground
					source={EXIT_BUTTON_BACK}
					resizeMode={Platform.OS === "ios" ? "contain" : "center"}
					style={{
						flex: 1,
						justifyContent: "center",
						alignContent: "center",
						paddingBottom: Math.round((this.state.btnBackHeight * 18) / 100),
						opacity: opacity,
					}}
					onLayout={(event) => {
						this.onButtonBackLayout(event);
					}}
				>
					<Text
						allowFontScaling={false}
						style={{
							color: "#ffffff",
							fontSize: Math.round((this.state.btnBackHeight * 48) / 100),
							fontFamily: f_sourcesansprosemibold,
							textAlign: "center",
							textShadowColor: "#c3943e",
							textShadowOffset: {
								width: 0,
								height: Platform.OS !== "web" ? 1 : 3,
							},
							textShadowRadius: 3,
						}}
					>
						{this.props.lang.gameEnd.btnExit}
					</Text>
				</ImageBackground>
			</AppTouchableOpacity>
		);
	}

	renderButtons() {
		// const displayRewardedAd = false;
		return (
			<View style={styles.buttonContainer}>
				{this.renderExitButton()}
				<FBShareButton
					backImageStyle={{ width: "43%", height: "85%" }}
					additionalButtonStyle={{ height: "94%" }}
					additionalTextStyle={{ width: "100%", height: "100%" }}
					onShareResult={(_result) => this.setState({ FBShareResult: _result })}
				/>
				{/* {Platform.OS !== "web" && displayRewardedAd == true && (
          <GameRewardedAd
            isUnlock={true}
            adRequestLocation="gameEnd"
            buttonStyle={{ flex: undefined, width: "40%", height: "98%" }}
            buttonImageStyle={{
              flex: undefined,
              width: "40%",
              maxWidth: undefined,
              height: "110%",
              marginTop: -3,
            }}
          />
        )} */}
			</View>
		);
	}

	renderResultsContent() {
		const { res } = this.props.gameEnd;
		const fontSize =
			Platform.OS === "web"
				? Math.round(this.state.rowHeight / 3.5)
				: isTablet
				? Math.round(this.state.rowHeight / 3)
				: Math.round(this.state.rowHeight / 2.5);
		const lineHeight = this.state.rowHeight;
		const paddingLeft = isWebOrTablet ? 10 : 3;
		const boosterPadding = Math.round(this.state.rowHeight / 4);
		const totalScore = res.baseScore + res.durationBonus;
		const _finalScore = totalScore * res.multiplyer;
		return (
			<View key="resultsTableContainer" style={styles.resultsTableContainer}>
				<View key="resultsTableBorder" style={styles.resultsTableBorder}>
					<View
						key="doubleCell_1"
						style={{
							width: "23%",
							height: lineHeight * 2,
							position: "absolute",
							right: 0,
							alignItems: "flex-start",
							borderColor: "#a5a097",
							borderLeftWidth: 1,
						}}
					>
						<Text
							allowFontScaling={false}
							style={[
								styles.boldText,
								{
									fontSize,
									paddingLeft: Math.round(paddingLeft * 0.5),
									lineHeight: lineHeight * 2,
									color: "gray",
								},
							]}
						>
							+
						</Text>
					</View>
					<View
						key="winScore"
						style={{ flex: 6, flexDirection: "row" }}
						onLayout={(event) => this.measureRowHeight(event)}
					>
						<View
							style={{
								flex: 1,
								height: lineHeight,
								borderColor: "#a5a097",
								borderBottomWidth: 1,
							}}
						>
							<Text
								allowFontScaling={false}
								selectable={false}
								numberOfLines={1}
								ellipsizeMode="tail"
								style={[styles.regularText, { fontSize, lineHeight, paddingLeft }]}
							>
								{this.props.lang.gameEnd.winScore}
							</Text>
						</View>
						<View style={{ width: "23%", height: lineHeight }}>
							<Text
								allowFontScaling={false}
								style={[
									styles.boldText,
									{
										fontSize,
										lineHeight: lineHeight,
										paddingRight: paddingLeft,
										width: "100%",
										textAlign: "right",
									},
								]}
							>
								{res.baseScore}
							</Text>
						</View>
					</View>
					<View key="duration" style={{ flex: 6, flexDirection: "row" }}>
						<View style={{ flex: 1, height: lineHeight }}>
							<Text
								allowFontScaling={false}
								selectable={false}
								numberOfLines={1}
								ellipsizeMode="tail"
								style={[styles.regularText, { fontSize, lineHeight, paddingLeft }]}
							>
								{this.props.lang.gameEnd.durationBonus}
							</Text>
						</View>
						<View style={{ width: "23%", height: lineHeight }}>
							<Text
								allowFontScaling={false}
								style={[
									styles.boldText,
									{
										fontSize,
										lineHeight: lineHeight,
										paddingRight: paddingLeft,
										width: "100%",
										textAlign: "right",
									},
								]}
							>
								{res.durationBonus}
							</Text>
						</View>
					</View>
					<View
						key="totalScore"
						style={{
							flex: 6,
							flexDirection: "row",
							borderTopColor: "#97958b",
							borderTopWidth: isWebOrTablet ? 4 : 2,
							borderBottomColor: "#a5a097",
							borderBottomWidth: 1,
							backgroundColor: "#dae1e1",
						}}
					>
						<View style={{ flex: 1, height: lineHeight }}>
							<Text
								allowFontScaling={false}
								selectable={false}
								numberOfLines={1}
								ellipsizeMode="tail"
								style={[styles.boldText, { fontSize, lineHeight, paddingLeft }]}
							>
								{this.props.lang.gameEnd.total}
							</Text>
						</View>
						<View style={{ width: "23%", height: lineHeight }}>
							<Text
								allowFontScaling={false}
								style={[
									styles.boldText,
									{
										fontSize,
										lineHeight: lineHeight,
										paddingRight: paddingLeft,
										width: "100%",
										textAlign: "right",
									},
								]}
							>
								{totalScore}
							</Text>
						</View>
					</View>
					<View
						key="multiplier"
						style={{
							flex: 6,
							flexDirection: "row",
							width: "100%",
						}}
					>
						<View
							style={{
								width: "77%",
								height: "100%",
								flexDirection: "row",
								alignItems: "center",
								borderRightColor: "#a5a097",
								borderRightWidth: 1,
							}}
						>
							{this.renderBuyBoostersButton(fontSize, lineHeight, paddingLeft, boosterPadding)}
						</View>
						<View style={{ width: "23%", height: lineHeight, flexDirection: "row" }}>
							<Text
								allowFontScaling={false}
								style={[
									styles.boldText,
									{
										fontSize,
										paddingLeft: Math.round(paddingLeft * 0.5),
										lineHeight: lineHeight,
										color: "gray",
										alignSelf: "flex-start",
										position: "absolute",
										zIndex: -1,
									},
								]}
							>
								x
							</Text>
							<Text
								allowFontScaling={false}
								style={[
									styles.boldText,
									{
										fontSize,
										lineHeight: lineHeight,
										paddingRight: paddingLeft,
										width: "100%",
										textAlign: "right",
										alignSelf: "flex-start",
									},
								]}
							>
								{res.multiplyer}
							</Text>
						</View>
					</View>
					<View
						key="scoreEarned"
						style={{
							flex: 6,
							flexDirection: "row",
							borderTopColor: "#97958b",
							borderTopWidth: isWebOrTablet ? 4 : 2,
							borderBottomColor: "#a5a097",
							borderBottomWidth: 1,
							backgroundColor: "#dae1e1",
						}}
					>
						<View style={{ flex: 1, height: lineHeight }}>
							<Text
								allowFontScaling={false}
								selectable={false}
								numberOfLines={1}
								ellipsizeMode="tail"
								style={[styles.boldText, { fontSize, lineHeight, paddingLeft }]}
							>
								{this.props.lang.gameEnd.finalScore}
							</Text>
						</View>
						<View style={{ width: "23%", height: lineHeight }}>
							<Text
								allowFontScaling={false}
								style={[
									styles.boldText,
									{
										fontSize,
										lineHeight: lineHeight,
										paddingRight: paddingLeft,
										width: "100%",
										textAlign: "right",
									},
								]}
							>
								{_finalScore}
							</Text>
						</View>
					</View>
					<View
						key="silverEarned"
						style={{
							flex: 6,
							flexDirection: "row",
							backgroundColor: "#dae1e1",
						}}
					>
						<View
							style={{
								width: "80%",
								height: "100%",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Text
								allowFontScaling={false}
								selectable={false}
								numberOfLines={1}
								ellipsizeMode="tail"
								style={[styles.boldText, { fontSize, lineHeight, paddingLeft }]}
							>
								{this.props.lang.gameEnd.earnedSilver}
							</Text>
							<Image
								source={SILVER_ICON}
								resizeMode={Platform.OS === "ios" ? "contain" : "center"}
								style={[styles.silverIcon, { right: boosterPadding }]}
							/>
						</View>
						<View
							style={{
								width: "23%",
								height: lineHeight,
								position: "absolute",
								right: 0,
								borderLeftColor: "#a5a097",
								borderLeftWidth: 1,
							}}
						>
							<Text
								allowFontScaling={false}
								style={[
									styles.boldText,
									{
										fontSize,
										lineHeight: lineHeight,
										paddingRight: paddingLeft,
										width: "100%",
										textAlign: "right",
									},
								]}
							>
								{res.silverEarned}
							</Text>
						</View>
					</View>
				</View>
				{this.renderButtons()}
			</View>
		);
	}

	renderGameResults() {
		const { res } = this.props.gameEnd;
		return (
			<View key="gameResultsContainer" style={styles.resultsContainer}>
				<View
					key="gameResultTitleContainer"
					style={{
						position: "absolute",
						top: 0,
						zIndex: 2,
						width: "110%",
						height: "15%",
						alignSelf: "center",
						borderRadius: 14,
						backgroundColor: res.playerWon ? "#00d4df" : "#db4627",
						paddingTop: isWebOrTablet ? 15 : 10,
						justifyContent: "center",
					}}
				>
					<Image
						source={res.playerWon ? WON_CROWN : LOST_CROWN}
						resizeMode="contain"
						style={{
							position: "absolute",
							top: "-80%",
							width: res.playerWon ? 394 : 401,
							height: "115%",
							alignSelf: "center",
						}}
					/>
					<Text
						allowFontScaling={false}
						numberOfLines={1}
						style={{
							alignSelf: "center",
							fontFamily: f_sourcesansprobold,
							fontSize: isWebOrTablet ? 40 : 28,
							color: "#f9fdfe",
							textShadowColor: res.playerWon ? "#009fa8" : "#a82e18",
							textShadowOffset: { width: 2, height: 2 },
							textShadowRadius: Platform.OS !== "web" ? 0.001 : 0,
						}}
					>
						{res.playerWon ? this.props.lang.gameEnd.titleWin : this.props.lang.gameEnd.titleLoss}
					</Text>
				</View>
				<View style={{ height: "80%", width: "80%", alignSelf: "center" }}>{this.renderResultsContent()}</View>
			</View>
		);
	}

	render() {
		if (!this.props.isGameEndOpened) return null;

		const { res } = this.props.gameEnd;
		const windowDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		return (
			<View style={styles.container}>
				{this.props.gameEnd.isGameEnded && (
					<Image
						source={res.playerWon ? WIN_BACKGROUND : LOSE_BACKGROUND}
						style={[
							styles.containerBackImage,
							{
								width: windowDim.width,
								height: windowDim.height + gameDimensions.fullWidthMargin,
							},
						]}
						resizeMode="cover"
					/>
				)}
				{this.props.gameEnd.isGameEnded && this.renderGameResults()}
				{this.props.gameEnd.isDidYouKnowDisplayed && <DidYouKnow />}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		height: "100%",
		justifyContent: "center",
	},
	containerBackImage: {
		position: "absolute",
		zIndex: -1,
	},
	resultsContainer: {
		width: "60%",
		height: "80%",
		paddingBottom: 10,
		alignSelf: "center",
		justifyContent: "flex-end",
		backgroundColor: "#e9e6e5",
		borderRadius: 14,
	},
	resultsTableContainer: {
		width: "100%",
		height: "100%",
	},
	resultsTableBorder: {
		flex: 1,
		width: "100%",
		height: "100%",
		alignSelf: "center",
		borderWidth: isWebOrTablet ? 3 : 2,
		borderColor: "#a5a097",
	},
	buttonContainer: {
		flexDirection: "row",
		height: "10%",
		width: "100%",
		alignSelf: "center",
		justifyContent: "space-around",
		alignContent: "center",
		marginTop: Platform.OS === "web" ? 20 : isTablet ? 15 : 10,
		marginBottom: 5,
	},
	buttonStyle: {
		justifyContent: "center",
		borderRadius: 5,
		backgroundColor: "transparent",
		borderWidth: 0,
	},
	buttonDimensions: {
		height: "98%",
		width: "40%",
	},
	boldText: {
		fontFamily: f_sourcesansprosemibold,
		height: "100%",
		color: "#000",
	},
	regularText: {
		fontFamily: f_sourcesansproregular,
		height: "100%",
		color: "#000",
	},
	silverIcon: {
		width: "9%",
		height: "57%",
		alignSelf: "center",
		position: "absolute",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isGameEndOpened: state.tab.isGameEndOpened,
		gameEnd: state.startedGame.gameEnd,
		username: state.currentUser.userDetails.username,
		allowFacebookSharing: state.startedGame.allowFacebookSharing,
		faceBookShareID: state.startedGame.faceBookShareID,
		faceBookShareHash: state.startedGame.faceBookShareHash,
	};
};

const mapDispatchToProps = {
	finishCountdown,
	resetGameEnd,
	resetGameRoom,
	resetCreateNewGame,
	openGameLobby,
	openMultipliersShop,
	openTab,
	showDidYouKnow,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameEnd);
