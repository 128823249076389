import React, { Component } from "react";
import { Dimensions, FlatList, Image, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { appFont } from "../../config/defaults";
import { UNREAD_MESSAGE } from "../../config/images";
import { selectChatRoomTab } from "../../redux/actions";
import { AppTouchableOpacity } from "../common";
import { handleError } from "../ErrorHandler";

class JoinedChatRooms extends Component {
	/* shouldComponentUpdate(nextProps) {
    if (
      nextProps.activeRoom != null &&
      this.props.activeRoom !== nextProps.activeRoom
    ) {
      var index = nextProps.joinedChatRooms.findIndex(
        (r) => r.chatRoomID == nextProps.activeRoom.chatRoomID
      );
      index = index == -1 ? 0 : index;
      this.refs.tabList.scrollToIndex({
        index: index,
        viewPosition: 0.5,
      });
    }
    if (
      this.props.activeRoom !== null &&
      nextProps.activeRoom !== null &&
      this.props.activeRoom.chatRoomID == nextProps.activeRoom.chatRoomID &&
      this.props.joinedChatRooms == nextProps.joinedChatRooms
    ) {
      return false;
    }
    return true;
  } */

	//#region events
	selectRoom(room, index) {
		try {
			const { selectChatRoomTab, activeRoom } = this.props;
			if (!room.selected && (activeRoom != null || activeRoom.chatRoomID != room.chatRoomID)) {
				selectChatRoomTab(room);
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderTab(room, index) {
		return (
			<AppTouchableOpacity
				style={[
					styles.tab,
					{
						backgroundColor: room.selected ? "#bbb" : "#eee",
						paddingLeft: room.unreadMessageCounter > 0 && !room.selected ? 2 : 0,
					},
				]}
				onPress={() => this.selectRoom(room, index)}
			>
				<View
					style={[
						styles.unreadMessageContainer,
						styles.unreadMessageSize,
						{
							display: room.unreadMessageCounter > 0 && !room.selected ? "flex" : "none",
						},
					]}
				>
					<Image source={UNREAD_MESSAGE} style={[styles.unreadMessageIcon, styles.unreadMessageSize]} />
					<Text
						allowFontScaling={false}
						selectable={false}
						numberOfLines={1}
						ellipsizeMode="tail"
						style={[styles.customFont, styles.unreadMessageText]}
					>
						{room.unreadMessageCounter.toString()}
					</Text>
				</View>
				<Text
					allowFontScaling={false}
					selectable={false}
					numberOfLines={1}
					ellipsizeMode="tail"
					style={[
						styles.customFont,
						styles.roomName,
						{
							paddingLeft: room.unreadMessageCounter > 0 && !room.selected ? 2 : 5,
						},
					]}
				>
					{room.name}
				</Text>
			</AppTouchableOpacity>
		);
	}

	render() {
		const _width = Dimensions.get("window").width - 135;
		return (
			<FlatList
				ref={"tabList"}
				horizontal={true}
				showsHorizontalScrollIndicator={true}
				keyExtractor={(item, index) => "chatRoomTab_" + item.chatRoomID.toString()}
				data={this.props.joinedChatRooms}
				extraData={this.props.joinedChatRooms}
				renderItem={({ item, index }) => this.renderTab(item, index)}
				getItemLayout={(item, index) => ({
					length: 150,
					offset: 150 * index,
					index,
				})}
				style={{
					width: _width,
					height: 55,
					paddingLeft: 5,
					position: "absolute",
					left: 60,
					top: 10,
					zIndex: 0,
				}}
			/>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	tab: {
		flexDirection: "row",
		// width: 150,
		height: 35,
		backgroundColor: "#eee",
		marginRight: 5,
		borderRadius: 5,
		borderWidth: 1,
		overflow: "hidden",
		zIndex: -1,
	},
	roomName: {
		flex: 1,
		paddingRight: 5,
		color: "#555",
		fontSize: 14,
		textAlign: "center",
		textAlignVertical: "center",
		height: 35,
		lineHeight: 35,
		overflow: "hidden",
	},
	customFont: {
		fontFamily: appFont,
	},
	unreadMessageContainer: {
		alignSelf: "flex-start",
		justifyContent: "center",
		paddingTop: 2,
		marginLeft: 0,
	},
	unreadMessageSize: {
		width: 16,
		height: 16,
	},
	unreadMessageIcon: {
		position: "absolute",
		zIndex: -1,
		marginTop: 2,
	},
	unreadMessageText: {
		fontSize: 10,
		color: "#fff",
		textAlign: "center",
		marginTop: 1,
		alignSelf: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		joinedChatRooms: state.globalChat.joinedChatRooms,
		activeRoom: state.globalChat.activeRoom,
	};
};

const mapDispatchToProps = { selectChatRoomTab };

export default connect(mapStateToProps, mapDispatchToProps)(JoinedChatRooms);
