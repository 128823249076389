import { Dimensions, Platform } from "react-native";
import { gameDetailsTableKey } from "../../config/defaults";
import {
	dataTableHeaderMinHeight,
	gameDetailsColumns,
	gameDimensions,
	gameListColumns,
	gameLobbyDimensions,
	gameRoomDimensions,
	globalChatDimensions,
	newGameDimensions,
	shopDimensions,
} from "../../config/dimensions";
import * as functions from "../../helpers/resizeHelpers";
import {
	CLEAR_GAME_DETAILS_IN_LOBBY,
	CLEAR_GAME_SELECTION,
	CREATE_GAME_BUTTONS_RENDERED,
	DETECT_ORIENTATION,
	DETECT_SMALL_RESOLUTION,
	GAME_DETAILS_TABLE_CONTENT_SIZE_CHANGED,
	GAME_DETAILS_TABLE_HEADER_SIZE,
	GAME_DETAILS_TABLE_RENDERED,
	GAME_LIST_FILTER_RENDERED,
	GAME_LIST_FILTER_SECTION_UNMOUNT,
	GAME_LIST_FILTER_TOGGLE,
	GAME_LIST_FILTER_UNMOUNT,
	GAME_LIST_TABLE_CONTENT_SIZE_CHANGED,
	GAME_LIST_TABLE_HEADER_SIZE,
	GAME_LOBBY_BUTTONS_RENDERED_IN_GAME_LIST,
	GAME_LOBBY_BUTTONS_UNMOUNTED_FROM_GAME_LIST,
	GAME_ROOM_BUTTONS_RENDERED,
	IS_FIVE_OR_MORE_PLAYERS_GAME_ENABLED,
	REQUEST_GAME_DETAILS_TABLE_HEIGHT,
	RESIZE_CREATE_GAME_BUTTONS,
	RESIZE_CREATE_NEW_GAME,
	RESIZE_GAME_DETAILS_TABLE,
	RESIZE_GAME_LIST_TABLE,
	RESIZE_GAME_LOBBY,
	RESIZE_GAME_LOBBY_BUTTONS,
	RESIZE_GAME_ROOM,
	RESIZE_GAME_ROOMS_RULE_DETAILS,
	RESIZE_GAME_ROOM_BUTTONS,
	RESIZE_GAME_SHOP,
	RESIZE_GLOBAL_CHAT,
	RESIZE_MAIN_CONTENT,
	RESIZE_NEW_GAMES_RULE_DETAILS,
	RESIZE_PAGE_TOP,
} from "../actions/actionTypes";

const initialState = {
	contentViewHeight: 0,
	pageTopWidth: 0,
	pageTopLeftMargin: 0,
	gameShopWidth: shopDimensions.shopDefaultWidth,
	gameShopHeight: shopDimensions.shopDefaultHeight,
	gameShopMenuLeft: 0,
	grChatBodyHeight: gameRoomDimensions.grChatDefaultHeight - 100,
	chatMainWidth: globalChatDimensions.chatMainDefaultWidth,
	chatMainHeight: globalChatDimensions.chatMainDefaultHeight,
	chatMainLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
	chatMainContentHeight: globalChatDimensions.chatMainDefaultHeight - gameDimensions.boxHeaderHeight - 80,
	chatListsWidth: globalChatDimensions.chatListsDefaultWidth,
	chatListsHeight: globalChatDimensions.chatListsDefaultHeight,
	chatListsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
	chatListsContentHeight: globalChatDimensions.chatListsDefaultHeight - gameDimensions.boxHeaderHeight - 10,
	chatRoomNameWidth: globalChatDimensions.chatRoomNameMainDefaultWidth,
	chatRoomsTabWidth:
		globalChatDimensions.chatMainDefaultWidth +
		gameDimensions.rightBoxDefaultLeftMargin +
		globalChatDimensions.chatListsDefaultWidth,
	gameListWidth: gameLobbyDimensions.gameListDefaultWidth,
	gameListHeight: gameLobbyDimensions.gameListDefaultHeight,
	gameListLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
	gameDetailsWidth: gameLobbyDimensions.gameDetailsDefaultWidth,
	gameDetailsHeight: gameLobbyDimensions.gameDetailsDefaultHeight,
	gameDetailsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
	gameListTableColumnsDimensions: {
		creatorCountry: gameListColumns.creatorCountry.maxWidth,
		chipsRequired: gameListColumns.chipsRequired.maxWidth,
		name: gameListColumns.name.secureWidth,
		playerCnt: gameListColumns.playerCnt.maxWidth,
		// freeSlots: gameListColumns.freeSlots.maxWidth,
	},
	gameListTable: {
		contentWidth: 0,
		contentHeight:
			gameLobbyDimensions.gameListDefaultHeight -
			gameDimensions.gameButtonsHeight -
			gameLobbyDimensions.gameListFilterContainerMinHeight -
			gameDimensions.boxHeaderHeight,
		tableHeaderHeight: dataTableHeaderMinHeight,
	},
	gameLobbyButtonsHeight: gameDimensions.gameButtonsHeight,
	gameLobbyButtonsWidth: gameDimensions.gameButtonsWidth,
	gameListFilterContainerHeight: gameLobbyDimensions.gameListFilterContainerMinHeight,
	isGameListFilterExpanded: false,
	gameDetailsTableColumnsDimensions: {
		// slotID: gameDetailsColumns.slotID.maxWidth,
		country: gameDetailsColumns.country.maxWidth,
		ranking: gameDetailsColumns.ranking.maxWidth,
		username: gameDetailsColumns.username.secureWidth,
		// isWebOrTablet
		//   ? gameDetailsColumns.username.secureWidth
		//   : gameDetailsColumns.username.secureWidth +
		//     gameDetailsColumns.teamName.maxWidth,
		avatar: gameDetailsColumns.avatar.maxWidth,
		// teamName: isWebOrTablet ? gameDetailsColumns.teamName.maxWidth : 0,
	},
	gameDetailsTable: {
		contentWidth: 0,
		contentHeight:
			gameLobbyDimensions.gameDetailsDefaultHeight -
			gameLobbyDimensions.gameDetailsFooterHeight -
			gameDimensions.boxHeaderHeight,
		tableHeaderHeight: dataTableHeaderMinHeight,
		tableKey: gameDetailsTableKey.selectedGame,
		additionalHeight: 0,
	},
	slotSettingsWidth: newGameDimensions.slotSettingsDefaultWidth,
	slotSettingsHeight: newGameDimensions.slotSettingsDefaultHeight,
	slotSettingsLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
	slotSettingsFooterHeight: 5,
	otherSettingsWidth: newGameDimensions.otherSettingsDefaultWidth,
	otherSettingsHeight: newGameDimensions.otherSettingsDefaultHeight - gameDimensions.gameButtonsHeight - 5,
	otherSettingsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
	createNewGameButtonsHeight: gameDimensions.gameButtonsHeight,
	createNewGameButtonsWidth: gameDimensions.gameButtonsWidth,
	createNewGameRulesHeight:
		newGameDimensions.otherSettingsDefaultHeight - gameDimensions.boxHeaderHeight - gameDimensions.gameButtonsHeight,
	grSlotSettingsWidth: gameRoomDimensions.grSlotSettingsDefaultWidth,
	grSlotSettingsHeight: gameRoomDimensions.grSlotSettingsDefaultHeight - gameRoomDimensions.chatInputHeight - 5,
	grSlotSettingsLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
	grOtherSettingsWidth: gameRoomDimensions.grOtherSettingsDefaultWidth,
	grOtherSettingsHeight: gameRoomDimensions.grOtherSettingsDefaultHeight - gameDimensions.gameButtonsHeight - 5,
	grOtherSettingsLeftMargin: gameDimensions.rightBoxDefaultLeftMargin,
	grChatWidth: gameRoomDimensions.grChatDefaultWidth,
	grChatHeight: gameRoomDimensions.grChatDefaultHeight,
	grChatLeftMargin: gameDimensions.leftBoxDefaultLeftMargin,
	gameRoomButtonsHeight: gameDimensions.gameButtonsHeight,
	gameRoomButtonsWidth: gameDimensions.gameButtonsWidth,
	gameRoomsRulesHeight:
		gameRoomDimensions.grOtherSettingsDefaultHeight - gameDimensions.boxHeaderHeight - gameDimensions.gameButtonsHeight,
	isSmallResolution: false,
	isLandscape: null,
	enableFiveOrMorePlayersGame: true,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case DETECT_SMALL_RESOLUTION:
			return { ...state, isSmallResolution: functions.isSmallResolution() };
		case DETECT_ORIENTATION:
			return { ...state, isLandscape: functions.isLandscape() };
		case IS_FIVE_OR_MORE_PLAYERS_GAME_ENABLED:
			var _enableMoreThanFive = true;
			/*TODO: put this back if it's necessary if (Platform.OS !== "web" || state.isSmallResolution)
        _enableMoreThanFive = false; */
			return { ...state, enableFiveOrMorePlayersGame: _enableMoreThanFive };
		case RESIZE_MAIN_CONTENT:
			return {
				...state,
				contentViewHeight: payload.height - gameDimensions.tabAreaDefaultHeight - 30,
			}; //TODO: when TabArea and NotificationBar resize is done, this must be modified too
		case RESIZE_PAGE_TOP:
			var dims = functions.getPageTopDimensions();
			return {
				...state,
				pageTopWidth: dims.width || 0,
				pageTopLeftMargin: dims.leftMargin || 0,
			};
		case RESIZE_GAME_SHOP:
			let windowDimensions = Dimensions.get("window"),
				shopWidth = 0,
				shopHeight = 0,
				calcHeight = windowDimensions.height - 40,
				shopMenuLeft = 0;
			if (Platform.OS !== "web" || state.isSmallResolution) {
				shopWidth = windowDimensions.width;
				shopHeight = calcHeight;
			} else {
				shopWidth = Math.min(windowDimensions.width - 12, shopDimensions.shopDefaultWidth);
				shopHeight = Math.min(calcHeight, shopDimensions.shopDefaultHeight);
				shopMenuLeft =
					Platform.OS === "web" && !state.isSmallResolution && windowDimensions.width <= shopWidth + 90 ? 0 : -45;
			}
			return {
				...state,
				gameShopWidth: shopWidth,
				gameShopHeight: shopHeight,
				gameShopMenuLeft: shopMenuLeft,
			};
		case RESIZE_GLOBAL_CHAT:
			var calcDimensions = functions.getGlobalChatDimensions(state.isSmallResolution);
			calcDimensions.chatListsContentHeight = calcDimensions.chatListsHeight - gameDimensions.boxHeaderHeight - 10;
			return {
				...state,
				chatMainWidth: calcDimensions.chatMainWidth,
				chatMainHeight: calcDimensions.chatMainHeight,
				chatMainLeftMargin: calcDimensions.chatMainLeftMargin,
				chatMainContentHeight: calcDimensions.chatMainContentHeight,
				chatListsWidth: calcDimensions.chatListsWidth,
				chatListsHeight: calcDimensions.chatListsHeight,
				chatListsLeftMargin: calcDimensions.chatListsLeftMargin,
				chatListsContentHeight: calcDimensions.chatListsContentHeight,
				chatRoomNameWidth: calcDimensions.chatRoomNameWidth,
				chatRoomsTabWidth: calcDimensions.chatRoomsTabWidth,
			};
		case RESIZE_GAME_LOBBY:
			var calcDimensions = functions.getGameLobbyDimensions(state.isSmallResolution, payload.resolution);
			return {
				...state,
				gameListWidth: calcDimensions.gameListWidth,
				gameListHeight: calcDimensions.gameListHeight,
				gameListLeftMargin: calcDimensions.gameListLeftMargin,
				gameDetailsWidth: calcDimensions.gameDetailsWidth,
				gameDetailsHeight: calcDimensions.gameDetailsHeight,
				gameDetailsLeftMargin: calcDimensions.gameDetailsLeftMargin,
			};
		case RESIZE_GAME_LIST_TABLE:
			if (state.gameListHeight == undefined) return state;
			var gameLobbyButtonsHeight = 0;
			var tableHeight =
				state.gameListHeight -
				gameLobbyButtonsHeight -
				gameDimensions.boxHeaderHeight -
				state.gameListFilterContainerHeight;
			var cols = functions.getGameListTableColumnsDimensions(state.gameListTable.contentWidth, state.gameListWidth);
			return {
				...state,
				gameListTable: { ...state.gameListTable, contentHeight: tableHeight },
				gameListTableColumnsDimensions: cols,
			};
		case GAME_LIST_TABLE_HEADER_SIZE:
			var newHeight = payload.height < dataTableHeaderMinHeight ? dataTableHeaderMinHeight : payload.height;
			return {
				...state,
				gameListTable: {
					...state.gameListTable,
					tableHeaderHeight: newHeight,
				},
			};
		case GAME_LIST_TABLE_CONTENT_SIZE_CHANGED:
			return {
				...state,
				gameListTable: {
					...state.gameListTable,
					contentWidth: payload.contentView.width,
					contentHeight: payload.contentView.height,
				},
			};
		case GAME_LOBBY_BUTTONS_RENDERED_IN_GAME_LIST:
			return { ...state, gameLobbyButtonsHeight: payload };
		case GAME_LOBBY_BUTTONS_UNMOUNTED_FROM_GAME_LIST:
			var newHeight = Platform.OS !== "web" || state.isSmallResolution ? 50 : 0;
			return { ...state, gameLobbyButtonsHeight: newHeight };
		case GAME_LIST_FILTER_RENDERED:
			var nh = Platform.OS === "web" && !state.isSmallResolution ? payload : payload + 10;
			return { ...state, gameListFilterContainerHeight: nh };
		case GAME_LIST_FILTER_UNMOUNT:
			return {
				...state,
				gameListFilterContainerHeight: initialState.gameListFilterContainerHeight,
			};
		case GAME_LIST_FILTER_SECTION_UNMOUNT:
			return {
				...state,
				isGameListFilterExpanded: initialState.isGameListFilterExpanded,
			};
		case GAME_LIST_FILTER_TOGGLE:
			return {
				...state,
				isGameListFilterExpanded: !state.isGameListFilterExpanded,
			};
		case RESIZE_GAME_LOBBY_BUTTONS:
			var newWidth = gameDimensions.gameButtonsWidth;
			var requestedWidth = payload.width / 2 - 2 * gameDimensions.fullWidthMargin;
			if (requestedWidth > 0 && requestedWidth != newWidth && requestedWidth < gameDimensions.gameButtonsWidth)
				newWidth = requestedWidth;
			return { ...state, gameLobbyButtonsWidth: newWidth };
		case GAME_DETAILS_TABLE_RENDERED:
			switch (payload) {
				case gameDetailsTableKey.selectedGame:
				case gameDetailsTableKey.newGame:
				case gameDetailsTableKey.joinedGame:
					return {
						...state,
						gameDetailsTable: { ...state.gameDetailsTable, tableKey: payload },
					};
				default:
					return state;
			}
		case RESIZE_GAME_DETAILS_TABLE:
			if (state.gameDetailsHeight == undefined) return state;
			var tableHeight =
				state.gameDetailsHeight - gameLobbyDimensions.gameDetailsFooterHeight - gameDimensions.boxHeaderHeight;
			var containerWidth = 0;
			switch (state.gameDetailsTable.tableKey) {
				case gameDetailsTableKey.newGame:
					containerWidth = state.slotSettingsWidth - 7;
					var footerHeight =
						/* (Platform.OS !== "web" || state.isSmallResolution) &&
            !state.isLandscape
              ? state.createNewGameButtonsHeight +
                state.createNewGameRulesHeight
              :  */ state.createNewGameButtonsHeight;
					/* tableHeight =
            Platform.OS !== "web"
              ? tableHeight
              : state.slotSettingsHeight -
                footerHeight -
                gameDimensions.boxHeaderHeight; */
					break;
				case gameDetailsTableKey.joinedGame:
					containerWidth = state.grSlotSettingsWidth - 7;
					var footerHeight =
						(Platform.OS !== "web" || state.isSmallResolution) && !state.isLandscape
							? state.gameRoomButtonsHeight /* + state.gameRoomsRulesHeight */
							: state.gameRoomButtonsHeight;
					tableHeight = Platform.OS !== "web" ? tableHeight : tableHeight /* state.grSlotSettingsHeight -
                footerHeight -
                gameDimensions.boxHeaderHeight */;
					break;
				case gameDetailsTableKey.selectedGame:
				default:
					containerWidth = state.gameDetailsWidth;
					break;
			}
			var cols = functions.getGameDetailsTableColDimensions(
				state.gameDetailsTable.contentWidth,
				containerWidth,
				state.gameDetailsTable.tableKey,
				payload.resolution
			);
			return {
				...state,
				gameDetailsTable: {
					...state.gameDetailsTable,
					contentHeight: tableHeight,
					contentWidth: containerWidth,
				},
				gameDetailsTableColumnsDimensions: cols,
			};
		case REQUEST_GAME_DETAILS_TABLE_HEIGHT:
			if (typeof payload !== "number") return state;
			return {
				...state,
				gameDetailsTable: {
					...state.gameDetailsTable,
					additionalHeight: payload,
				},
			};
		case CLEAR_GAME_DETAILS_IN_LOBBY:
		case CLEAR_GAME_SELECTION:
			return {
				...state,
				gameDetailsTable: {
					...state.gameDetailsTable,
					additionalHeight: initialState.gameDetailsTable.additionalHeight,
				},
			};
		case GAME_DETAILS_TABLE_HEADER_SIZE:
			var newHeight = payload.height < dataTableHeaderMinHeight ? dataTableHeaderMinHeight : payload.height;
			return {
				...state,
				gameDetailsTable: {
					...state.gameDetailsTable,
					tableHeaderHeight: newHeight,
				},
			};
		case GAME_DETAILS_TABLE_CONTENT_SIZE_CHANGED:
			var nContentHeight = 0;
			switch (state.gameDetailsTable.tableKey) {
				case gameDetailsTableKey.newGame:
					var footerHeight = state.createNewGameButtonsHeight;
					var nContentHeight = state.slotSettingsHeight - footerHeight - gameDimensions.boxHeaderHeight;
					break;
				case gameDetailsTableKey.joinedGame:
					var footerHeight = state.gameRoomButtonsHeight;
					var nContentHeight = state.grSlotSettingsHeight - footerHeight - gameDimensions.boxHeaderHeight;
					break;
				case gameDetailsTableKey.selectedGame:
				default:
					nContentHeight = payload.contentView.height;
					break;
			}
			return {
				...state,
				gameDetailsTable: {
					...state.gameDetailsTable,
					contentWidth: payload.contentView.width,
					contentHeight: nContentHeight,
				},
			};
		case RESIZE_CREATE_NEW_GAME:
			var calcDimensions = functions.getCreateNewGameDimensions(state.isSmallResolution, payload.resolution);
			return {
				...state,
				slotSettingsWidth: calcDimensions.slotSettingsWidth,
				slotSettingsHeight: calcDimensions.slotSettingsHeight,
				slotSettingsLeftMargin: calcDimensions.slotSettingsLeftMargin,
				otherSettingsWidth: calcDimensions.otherSettingsWidth,
				otherSettingsHeight: calcDimensions.otherSettingsHeight,
				otherSettingsLeftMargin: calcDimensions.otherSettingsLeftMargin,
			};
		case CREATE_GAME_BUTTONS_RENDERED:
			return { ...state, createNewGameButtonsHeight: payload };
		case RESIZE_CREATE_GAME_BUTTONS:
			var newWidth = gameDimensions.gameButtonsWidth;
			var requestedWidth = payload.width / 2 - 2 * gameDimensions.fullWidthMargin;
			if (requestedWidth > 0 && requestedWidth != newWidth && requestedWidth < gameDimensions.gameButtonsWidth)
				newWidth = requestedWidth;
			return { ...state, createNewGameButtonsWidth: newWidth };
		case RESIZE_NEW_GAMES_RULE_DETAILS:
			if (state.gameDetailsTable.tableKey !== gameDetailsTableKey.newGame) return state;
			var ngrHeight =
				/* Platform.OS !== "web" || state.isSmallResolution
          ? state.isLandscape
            ? state.slotSettingsHeight -
              gameDimensions.boxHeaderHeight -
              state.createNewGameButtonsHeight
            : state.slotSettingsHeight -
              gameDimensions.boxHeaderHeight -
              state.createNewGameButtonsHeight -
              state.gameDetailsTable.contentHeight
          : */ state.otherSettingsHeight - gameDimensions.boxHeaderHeight - state.createNewGameButtonsHeight;
			ngrHeight =
				newGameDimensions.mobileSettingsDefaultHeight < ngrHeight
					? newGameDimensions.mobileSettingsDefaultHeight
					: ngrHeight;
			return { ...state, createNewGameRulesHeight: ngrHeight };
		case RESIZE_GAME_ROOM:
			var calcDimensions = functions.getGameRoomDimensions(
				state.isSmallResolution,
				state.grChatHeight,
				payload.resolution
			);
			return {
				...state,
				grSlotSettingsWidth: calcDimensions.grSlotSettingsWidth,
				grSlotSettingsHeight: calcDimensions.grSlotSettingsHeight,
				grSlotSettingsLeftMargin: calcDimensions.grSlotSettingsLeftMargin,
				grOtherSettingsWidth: calcDimensions.grOtherSettingsWidth,
				grOtherSettingsHeight: calcDimensions.grOtherSettingsHeight,
				grOtherSettingsLeftMargin: calcDimensions.grOtherSettingsLeftMargin,
				grChatWidth: calcDimensions.grChatWidth,
				grChatHeight: calcDimensions.grChatHeight,
				grChatLeftMargin: calcDimensions.grChatLeftMargin,
				grChatBodyHeight: calcDimensions.grChatBodyHeight,
			};
		case GAME_ROOM_BUTTONS_RENDERED:
			return { ...state, gameRoomButtonsHeight: payload };
		case RESIZE_GAME_ROOM_BUTTONS:
			var newWidth = gameDimensions.gameButtonsWidth;
			var requestedWidth = payload.width / 2 - 2 * gameDimensions.fullWidthMargin;
			if (requestedWidth > 0 && requestedWidth != newWidth && requestedWidth < gameDimensions.gameButtonsWidth)
				newWidth = requestedWidth;
			return { ...state, gameRoomButtonsWidth: newWidth };
		case RESIZE_GAME_ROOMS_RULE_DETAILS:
			if (state.gameDetailsTable.tableKey !== gameDetailsTableKey.joinedGame) return state;
			var ngrHeight =
				/* Platform.OS !== "web" || state.isSmallResolution
          ? state.isLandscape
            ? state.grSlotSettingsHeight -
              gameDimensions.boxHeaderHeight -
              state.gameRoomButtonsHeight
            : state.grSlotSettingsHeight -
              gameDimensions.boxHeaderHeight -
              state.gameRoomButtonsHeight -
              state.gameDetailsTable.contentHeight
          : */ state.grOtherSettingsHeight - gameDimensions.boxHeaderHeight - state.gameRoomButtonsHeight;
			ngrHeight =
				gameRoomDimensions.grMobileSettingsDefaultHeight < ngrHeight
					? gameRoomDimensions.grMobileSettingsDefaultHeight
					: ngrHeight;
			return { ...state, gameRoomsRulesHeight: ngrHeight };
		default:
			return state;
	}
};
