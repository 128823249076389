import React, { Component } from "react";
import { Animated, Dimensions, Keyboard, Platform, View } from "react-native";
import { PanGestureHandler, PinchGestureHandler, State, TapGestureHandler } from "react-native-gesture-handler";
import { connect } from "react-redux";
import { gameDimensions, gameStartedDimensions } from "../../config/dimensions";
import { EventQueue } from "../../controller";
import DebugLogger from "../../controller/DebugLogger";
import { store } from "../../redux/store";
import {
	closePanelMenu,
	dealCards,
	focusOutGameChatInput,
	gameBoardRendered,
	resetGamePlayerActed,
	saveStartPositionsDirections,
	setNextSlotToAct,
} from "../redux/actions";
import BoardPositions from "./BoardPositions";
import KeezChat from "./KeezChat";
import PlayedCardsArea from "./PlayedCardsArea";
import PlayerPanelsContainer from "./PlayerPanelsContainer";
import PlayersCardsArea from "./PlayersCardsArea";

class Keezers7Players extends Component {
	panRef = React.createRef();
	pinchRef = React.createRef();
	doubleTapRef = React.createRef();

	constructor(props) {
		super(props);

		this.boardWidthOnScale_1 = 908;
		this.boardHeightOnScale_1 = 512;

		const sizes = this.getSizingValues();
		this.state = { initialScale: sizes.minScale, currentScale: sizes.minScale };

		const _init_log = {
			_init_log_message: "Scale initialized",
			initialScale: sizes.minScale,
			dimensions: Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen"),
		};
		DebugLogger.shared.saveLog(_init_log, true, true);

		if (Platform.OS !== "web") {
			/* Set initial pinching */
			this.setInitialPinch(sizes.minScale);

			/* Set initial pan move */
			this.setInitialPanMove();
		}
	}

	//#region lifecycle methods
	componentDidMount() {
		if (Platform.OS === "web") {
			/* Set initial pinching */
			this.setInitialPinch(this.state.initialScale);

			/* Set initial pan move */
			this.setInitialPanMove();

			document.body.addEventListener("mouseup", this.handleOnClick);
			document.body.addEventListener("touchend", this.handleOnClick);
		}
		this.dimensionsSubscription = Dimensions.addEventListener("change", this.resizeBoard);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.boardLoaded == false && this.props.boardLoaded == true) {
			this.props.dealCards();
		}
	}

	componentWillUnmount() {
		if (Platform.OS === "web") {
			this.props.saveStartPositionsDirections(this.state.verticalStartPositions);
			document.body.removeEventListener("mouseup", this.handleOnClick);
			document.body.removeEventListener("touchend", this.handleOnClick);
		}
		this.dimensionsSubscription?.remove();
	}
	//#endregion

	//#region events
	handleOnClick = (ev) => {
		if (store.getState().startedGame.expandPanelMenu == true) {
			this.props.closePanelMenu();
		}
	};

	onFinishedPawnMoveAction() {
		this.props.setNextSlotToAct();
		const { currentEvent } = EventQueue.shared;
		if (currentEvent !== null) {
			const { sMessageID, delay } = currentEvent;
			EventQueue.shared.requestNextEventExecution(sMessageID, true, delay);
		}
	}

	onZoomGesture(event) {
		if (event.nativeEvent.numberOfPointers === 2) {
			if (event.nativeEvent.oldState === State.ACTIVE) {
				const windowDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
				const availableHeight = windowDim.height - 45;

				if (windowDim.width < this.boardWidthOnScale_1 * this.lastScale) {
					const marginX = Math.abs((windowDim.width - this.boardWidthOnScale_1 * this.lastScale) / 2);

					if (this.lastOffset.x > marginX) this.lastOffset.x = marginX;
					if (this.lastOffset.x < -marginX) this.lastOffset.x = -marginX;
				}

				if (windowDim.height < this.state.boardHeightOnScale_1 * this.lastScale) {
					const marginY = Math.abs((availableHeight - this.state.boardHeightOnScale_1 * this.lastScale) / 2);

					if (this.lastOffset.y > marginY) this.lastOffset.y = marginY;
					if (this.lastOffset.y < -marginY) this.lastOffset.y = -marginY;
				}

				this.lastScale = Math.round(this.lastScale * event.nativeEvent.scale * 100) / 100;
				if (this.lastScale < this.state.initialScale) {
					this.lastScale = this.state.initialScale;
					this.lastOffset.x = 0;
					this.lastOffset.y = 0;
				}

				const { maxBoardScale } = gameStartedDimensions;
				if (this.lastScale > maxBoardScale) {
					this.lastScale = maxBoardScale;
				}

				if (event.nativeEvent.scale < 1) {
					this.lastOffset.x = 0;
					this.lastOffset.y = 0;
				}

				this.animTranslateX.setOffset(this.lastOffset.x);
				this.animTranslateY.setOffset(this.lastOffset.y);
				this.animTranslateX.setValue(0);
				this.animTranslateY.setValue(0);
				this.baseScale.setValue(this.lastScale);
				this.pinchScale.setValue(1);
				const _zoom_log = {
					_zoom_log_message: "Zoomed gameboard",
					fromScale: this.state.currentScale,
					toScale: this.lastScale,
					initialScale: this.state.initialScale,
					dimensions: Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen"),
				};
				DebugLogger.shared.saveLog(_zoom_log, true, true);
				this.setState({ currentScale: this.lastScale });
			}
		}
	}

	onMoveGesture(event) {
		if (event.nativeEvent.numberOfPointers == 1) {
			if (event.nativeEvent.oldState === State.BEGAN) {
				this.startOffset.x = this.lastOffset.x;
				this.startOffset.y = this.lastOffset.y;
			}

			if (event.nativeEvent.oldState === State.ACTIVE) {
				const windowDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
				const movedX = event.nativeEvent.translationX;
				const movedY = event.nativeEvent.translationY;
				const availableHeight = windowDim.height - 45;
				const availableWidth = windowDim.width - 4 * gameDimensions.fullWidthMargin;

				if (this.lastScale == this.state.initialScale) {
					if (windowDim.width < this.boardWidthOnScale_1 * this.lastScale) {
						this.lastOffset.x = this.startOffset.x + movedX;
						const marginX = Math.abs((availableWidth - this.boardWidthOnScale_1 * this.lastScale) / 2);

						if (this.lastOffset.x > marginX) this.lastOffset.x = marginX;
						if (this.lastOffset.x < -marginX) this.lastOffset.x = -marginX;
					}

					if (windowDim.height < this.state.boardHeightOnScale_1 * this.lastScale) {
						this.lastOffset.y = this.startOffset.y + movedY;
						const marginY = Math.abs((availableHeight - this.state.boardHeightOnScale_1 * this.lastScale) / 2);

						if (this.lastOffset.y > marginY) this.lastOffset.y = marginY;
						if (this.lastOffset.y < -marginY) this.lastOffset.y = -marginY;
					}
				} else {
					const leftOutside = (this.boardWidthOnScale_1 * this.lastScale - windowDim.width) / 2;
					const topOutside = (this.state.boardHeightOnScale_1 * this.lastScale - windowDim.height) / 2;
					const maxMovedX = (this.boardWidthOnScale_1 * this.lastScale - leftOutside) / this.lastScale - 25;
					const maxMovedY = (this.state.boardHeightOnScale_1 * this.lastScale - topOutside) / this.lastScale;

					this.lastOffset.x = this.startOffset.x + movedX;
					if (this.lastOffset.x > maxMovedX) {
						this.lastOffset.x = maxMovedX;
					}
					if (this.lastOffset.x < -maxMovedX) {
						this.lastOffset.x = -maxMovedX;
					}

					this.lastOffset.y = this.startOffset.y + movedY;
					if (this.lastOffset.y > maxMovedY) {
						this.lastOffset.y = maxMovedY;
					}
					if (this.lastOffset.y < -maxMovedY) {
						this.lastOffset.y = -maxMovedY;
					}
				}

				this.animTranslateX.setOffset(this.lastOffset.x);
				this.animTranslateY.setOffset(this.lastOffset.y);
				this.animTranslateX.setValue(0);
				this.animTranslateY.setValue(0);
			}
		}
	}

	onDoubleTapGesture(event) {
		if (event.nativeEvent.oldState === State.UNDETERMINED) {
			if (Platform.OS !== "web" && store.getState().startedGame.expandPanelMenu == true) {
				this.props.closePanelMenu();
			}
			if (Platform.OS === "android" && Platform.Version >= 30) {
				Keyboard.dismiss();
				this.props.focusOutGameChatInput();
			}
		}
		if (event.nativeEvent.state === State.ACTIVE) {
			const sizes = this.getSizingValues();
			const _dbt_log = {
				_dbt_log_message: "Reset scale",
				fromScale: this.state.currentScale,
				toScale: sizes.minScale,
				initialScale: this.state.initialScale,
				dimensions: Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen"),
			};
			DebugLogger.shared.saveLog(_dbt_log, true, true);

			this.setState({
				initialScale: sizes.minScale,
				currentScale: sizes.minScale,
			});

			/* Set initial pinching */
			this.setInitialPinch(sizes.minScale);

			/* Set initial pan move */
			this.setInitialPanMove();
		}
	}
	//#endregion

	//#region render methods
	render() {
		const _translateXAnim =
			Platform.OS === "web" ? this.animTranslateX : Animated.divide(this.animTranslateX, this.baseScale);
		const _translateYAnim =
			Platform.OS === "web" ? this.animTranslateY : Animated.divide(this.animTranslateY, this.baseScale);
		return (
			<TapGestureHandler
				ref={this.doubleTapRef}
				onHandlerStateChange={(event) => this.onDoubleTapGesture(event)}
				numberOfTaps={2}
				maxDelayMs={150}
			>
				<View style={{ flex: 1 }}>
					<PinchGestureHandler
						ref={this.pinchRef}
						onGestureEvent={this.onZoomEvent}
						onHandlerStateChange={(event) => this.onZoomGesture(event)}
					>
						<Animated.View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<PanGestureHandler
								ref={this.panRef}
								// waitFor={this.doubleTapRef}
								simultaneousHandlers={this.pinchRef}
								onGestureEvent={this.handleGesture}
								onHandlerStateChange={(event) => this.onMoveGesture(event)}
								minPointers={1}
								maxPointers={1}
								activeOffsetY={[-50, 50]}
								activeOffsetX={[-50, 50]}
								enabled={Platform.OS !== "web" && this.state.currentScale != this.state.initialScale}
							>
								<Animated.View
									style={{
										// borderWidth: 1,
										position: "relative",
										width: this.boardWidthOnScale_1,
										height: this.boardHeightOnScale_1,
										transform: [
											{
												scale: Platform.OS === "web" ? this.state.scale : this.scale,
											},
											{ translateY: _translateYAnim },
											{ translateX: _translateXAnim },
										],
										justifyContent: "center",
										alignItems: "center",
									}}
									onLayout={() => {
										if (!this.props.boardLoaded) this.props.gameBoardRendered();
									}}
								>
									<PlayedCardsArea />
									<KeezChat top={170} left={220} width={330} height={170} />
									<PlayersCardsArea />
									<BoardPositions
										onFinishedPawnMoveAction={() => {
											this.onFinishedPawnMoveAction();
										}}
									/>
									<PlayerPanelsContainer
										verticalStartPositions={this.state.verticalStartPositions}
										onFinishedPawnMoveAction={() => {
											this.onFinishedPawnMoveAction();
										}}
									/>
								</Animated.View>
							</PanGestureHandler>
						</Animated.View>
					</PinchGestureHandler>
				</View>
			</TapGestureHandler>
		);
	}
	//#endregion

	//#region helpers
	resizeBoard = () => {
		if (!this.props.isBackground) {
			const sizes = this.getSizingValues();
			this.setState({
				initialScale: sizes.minScale,
				currentScale: sizes.minScale,
			});

			/* Set initial pinching */
			this.setInitialPinch(sizes.minScale);

			/* Set initial pan move */
			this.setInitialPanMove();
		}
	};

	getSizingValues() {
		const windowDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
		const width = windowDim.width - 9;
		const height = windowDim.height - 9;
		var availableWidth = 0,
			availableHeight = 0,
			minScale = 1;
		if (width >= height) {
			availableWidth = width;
			availableHeight = Math.floor((availableWidth * 9) / 16);
		} else {
			availableHeight = Math.floor((width * 9) / 16);
			availableWidth = Math.floor((availableHeight * 16) / 9);
		}

		var scaleX = availableWidth / this.boardWidthOnScale_1,
			scaleY = availableHeight / this.boardHeightOnScale_1;
		scaleX = Math.floor(scaleX * 100) / 100;
		scaleY = Math.floor(scaleY * 100) / 100;
		minScale = Math.min(scaleX, scaleY);

		if (
			Math.round(minScale * this.boardWidthOnScale_1) <= width &&
			Math.round(minScale * this.boardHeightOnScale_1) <= height
		) {
			do {
				var tmpNewScale = minScale + 0.01;
				var scBW = Math.round(tmpNewScale * this.boardWidthOnScale_1);
				var scBH = Math.round(tmpNewScale * this.boardHeightOnScale_1);
				var oldScale = minScale;
				if (scBW <= width && scBH <= height && minScale != tmpNewScale) {
					minScale = tmpNewScale;
				}
			} while (scBW <= width && scBH <= height && oldScale != minScale);
		} else {
			do {
				var tmpNewScale = minScale - 0.01;
				var scBW = Math.round(tmpNewScale * this.boardWidthOnScale_1);
				var scBH = Math.round(tmpNewScale * this.boardHeightOnScale_1);
				var oldScale = minScale;
				if ((scBW >= width || scBH >= height) && minScale != tmpNewScale) {
					minScale = tmpNewScale;
				}
			} while ((scBW >= width || scBH >= height) && oldScale != minScale);
		}

		minScale = Math.floor(minScale * 100) / 100;

		return { minScale };
	}

	setInitialPinch(minScale = 0) {
		this.lastScale = minScale;
		this.baseScale = new Animated.Value(minScale);
		this.pinchScale = new Animated.Value(1);
		if (Platform.OS === "web") {
			this.setState({
				scale: Animated.multiply(this.baseScale, this.pinchScale),
			});
		} else {
			this.scale = Animated.multiply(this.baseScale, this.pinchScale);
		}
		this.onZoomEvent = Animated.event(
			[
				{
					nativeEvent: { scale: this.pinchScale },
				},
			],
			{ useNativeDriver: Platform.OS !== "web" }
		);
	}

	setInitialPanMove() {
		this.animTranslateX = new Animated.Value(0);
		this.animTranslateY = new Animated.Value(0);
		this.lastOffset = { x: 0, y: 0 };
		this.startOffset = { x: 0, y: 0 };
		this.handleGesture = Animated.event(
			[
				{
					nativeEvent: {
						translationX: this.animTranslateX,
						translationY: this.animTranslateY,
					},
				},
			],
			{ useNativeDriver: Platform.OS !== "web" }
		);
	}
	//#endregion
}

const mapStateToProps = (state) => {
	return {
		verticalStartPositions: state.startedGame.verticalStartPositions,
		boardLoaded: state.startedGame.boardLoaded,
		isBackground: state.app.isBackground,
	};
};

const mapDispatchToProps = {
	closePanelMenu,
	dealCards,
	focusOutGameChatInput,
	gameBoardRendered,
	resetGamePlayerActed,
	saveStartPositionsDirections,
	setNextSlotToAct,
};

export default connect(mapStateToProps, mapDispatchToProps)(Keezers7Players);
