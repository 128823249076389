import React, { Component } from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { appFont, modalType, RESOLUTION, tabType, viewSide } from "../../config/defaults";
import { gameDimensions, globalChatDimensions } from "../../config/dimensions";
import {
	changeChatRoomTabsSound,
	closeChatRoomTab,
	openModalDialog,
	openTab,
	selectChatRoomTab,
	startChatRoomLeave,
} from "../../redux/actions";
import { store } from "../../redux/store";
import { AppTouchableOpacity, Box, ChatMessages, Header } from "../common";
import { handleError } from "../ErrorHandler";

class GlobalChatMain extends Component {
	//#region events
	onCrossIconPress() {
		this.props.openModalDialog(
			modalType.DIALOG,
			this.props.lang.messages.chatRoomLeaveWarningMessage,
			this.props.lang.yes,
			() => {
				try {
					const { activeRoom } = this.props;
					const { joinedChatRooms } = store.getState().globalChat;
					if (activeRoom !== null && activeRoom.chatRoomID) {
						this.props.closeChatRoomTab(activeRoom);
						this.props.startChatRoomLeave(activeRoom.chatRoomID);
						if (joinedChatRooms.length > 0) {
							var fri = joinedChatRooms.findIndex((r) => r.chatRoomID != activeRoom.chatRoomID);
							this.props.selectChatRoomTab(joinedChatRooms[fri]);
						}
					}
				} catch (error) {
					handleError(error);
				}
			},
			this.props.lang.no,
			null
		);
	}

	onSoundIconPress() {
		if (this.props.activeRoom != null) this.props.changeChatRoomTabsSound(this.props.activeRoom);
	}
	//#endregion

	//#region render methods
	renderOpenGames() {
		if (Array.isArray(this.props.gameListOrig)) {
			const openGamesCnt = this.props.gameListOrig.filter((g) => g.gameState !== "RUNNING").length;
			if (openGamesCnt > 0) {
				return (
					<AppTouchableOpacity
						activeOpacity={1}
						style={styles.openGamesButton}
						onPress={() => this.props.openTab(tabType.GAMES)}
					>
						<Text allowFontScaling={false} style={styles.openGamesText}>
							{this.props.lang.globalChat.openGames}
						</Text>
						<Text allowFontScaling={false} style={[styles.openGamesText, styles.openGamesCounter]}>
							{openGamesCnt.toString()}
						</Text>
					</AppTouchableOpacity>
				);
			}
		}
		return (
			<View style={[styles.openGamesButton, styles.noOpenGames]}>
				<Text allowFontScaling={false} style={[styles.openGamesText]}>
					{this.props.lang.globalChat.openGames}
				</Text>
				<Text allowFontScaling={false} style={[styles.openGamesText, styles.openGamesCounter]}>
					{"0"}
				</Text>
			</View>
		);
	}

	renderChatBody() {
		if (this.props.activeRoom != null) {
			return (
				<ChatMessages
					players={this.props.activeRoom.users}
					renderLoc={"globalChat"}
					messages={this.props.activeRoom.chatMessages}
					contentHeight={this.props.chatMainContentHeight}
					clientLanguage={this.props.clientLanguage}
				/>
			);
		}
		return <View style={{ height: this.props.chatMainContentHeight }} />;
	}

	render() {
		let boxStyle =
			this.props.resolution !== RESOLUTION.LOW
				? [
						{ flex: 2, height: this.props.chatMainHeight },
						Platform.OS === "web" && {
							maxWidth: globalChatDimensions.chatMainDefaultWidth,
						},
				  ]
				: {
						width: this.props.chatMainWidth,
						height: this.props.chatMainHeight,
						marginLeft: this.props.chatMainLeftMargin,
				  };

		return (
			<Box style={boxStyle}>
				<Header
					textContent={this.props.activeRoom != null ? this.props.activeRoom.name : ""}
					position={viewSide.LEFT}
					style={{ height: gameDimensions.boxHeaderHeight }}
					enableCrossIcon={this.props.activeRoom !== null}
					onCrossIconPress={this.onCrossIconPress.bind(this)}
					enableSoundIcon={this.props.activeRoom !== null}
					onSoundIconPress={this.onSoundIconPress.bind(this)}
					isSoundMuted={this.props.activeRoom != null ? this.props.activeRoom.muted : false}
				/>
				{this.renderOpenGames()}
				{this.renderChatBody()}
			</Box>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	openGamesButton: {
		width: "100%",
		height: 20,
		flexDirection: "row",
		paddingLeft: 50,
		backgroundColor: "#888",
		paddingTop: 2,
	},
	noOpenGames: {
		backgroundColor: "#ccc",
	},
	openGamesText: {
		fontFamily: appFont,
		color: "#fff",
		fontSize: 16,
	},
	openGamesCounter: {
		marginLeft: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		chatMainWidth: state.views.chatMainWidth,
		chatMainHeight: state.views.chatMainHeight,
		chatMainLeftMargin: state.views.chatMainLeftMargin,
		chatMainContentHeight: state.views.chatMainContentHeight,
		activeRoom: state.globalChat.activeRoom,
		gameListOrig: state.gameListData.gameListOrig,
		clientLanguage: state.currentUser.preferences.clientLanguage,
		resolution: state.currentUser.preferences.resolution,
	};
};

const mapDispatchToProps = {
	changeChatRoomTabsSound,
	closeChatRoomTab,
	openModalDialog,
	openTab,
	selectChatRoomTab,
	startChatRoomLeave,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalChatMain);
