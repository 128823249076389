import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprobolditalic,
	f_sourcesansprolight,
	f_sourcesansproregular,
	processState,
} from "../../../config/defaults";
import { gameDimensions, loginFormDimensions } from "../../../config/dimensions";
import { TEXT_INPUT, TEXT_INPUT_ERROR } from "../../../config/images";
import { validateEmail, validateTextField } from "../../../helpers/validationHelpers";
import { closeEmailFriendPreview, showEmailFriendPreview } from "../../../redux/actions";
import { ColoredButton, Spinner } from "../../common";
import { handleError } from "../../ErrorHandler";
import InviteEmailSent from "./InviteEmailSent";

class EmailFriend extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			isFormValid: true,
			email: "",
			emailValid: true,
			emailActive: false,
			friendName: "",
			friendNameValid: true,
			friendNameActive: false,
			isSendInProgress: processState.INITIALIZED,
		};
	}

	//#region events
	onSendEmailPress() {
		// save credentials in AsyncStorage
		try {
			if (this.isFormValidated()) {
				//TODO:
				this.setState({ isSendInProgress: processState.STARTED });
				setTimeout(() => {
					this.setState({ isSendInProgress: processState.FINISHED });
				}, 1000);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onPreviewPress() {
		// save credentials in AsyncStorage
		try {
			if (this.isFormValidated()) {
				this.props.showEmailFriendPreview();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onEmailSubmit() {
		this.setState({ emailActive: false });
	}

	onEmailChange(text) {
		this.setState({ email: text, emailValid: true, isFormValid: true });
	}

	onFriendNameSubmit() {
		this.setState({ friendNameActive: false });
	}

	onFriendNameChange(text) {
		this.setState({
			friendName: text,
			friendNameValid: true,
			isFormValid: true,
		});
	}
	//#endregion

	//#region render methods
	renderSendEmailButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.onSendEmailPress.bind(this)}
					textContent={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.sendEmail}
					accessibilityLabel={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.sendEmail}
					color={buttonColor.GREEN}
					disabled={!this.state.isFormValid}
				/>
			</View>
		);
	}

	renderPreviewButton() {
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={gameDimensions.gameButtonsWidth}
					height={60}
					onPressFunc={this.onPreviewPress.bind(this)}
					textContent={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.preview}
					accessibilityLabel={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.preview}
					color={buttonColor.YELLOW}
					disabled={false}
				/>
			</View>
		);
	}

	renderEmailField() {
		return (
			<View style={styles.dataInnerContainer}>
				<Text allowFontScaling={false} style={[styles.dataTitle, styles.dataText]}>
					{this.props.lang.gameShop.inviteFriendsMenu.emailFriend.email.title}
				</Text>
				<ImageBackground
					source={this.state.emailValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					{Platform.OS !== "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onBlur={this.onEmailSubmit.bind(this)}
							onFocus={() => this.setState({ emailActive: true })}
							onSubmitEditing={this.onEmailSubmit.bind(this)}
							onChangeText={(text) => this.onEmailChange(text)}
							value={this.state.email}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							autoCompleteType="off"
							autoCorrect={false}
							spellCheck={false}
							textContentType="oneTimeCode"
							keyboardType="email-address"
							underlineColorAndroid="transparent"
							style={[styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }]}
							placeholder={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.email.placeHolder}
							placeholderTextColor="#8a8a8a"
						/>
					)}
					{Platform.OS === "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onBlur={this.onEmailSubmit.bind(this)}
							onFocus={() => this.setState({ emailActive: true })}
							onSubmitEditing={this.onEmailSubmit.bind(this)}
							onChangeText={(text) => this.onEmailChange(text)}
							value={this.state.email}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							autoCorrect={false}
							spellCheck={false}
							textContentType="oneTimeCode"
							underlineColorAndroid="transparent"
							style={[styles.inputStyle, { outlineWidth: 0 }]}
							placeholder={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.email.placeHolder}
							placeholderTextColor="#8a8a8a"
						/>
					)}
				</ImageBackground>
			</View>
		);
	}

	renderEmailError() {
		return (
			<View style={styles.dataErrorContainer}>
				{!this.state.emailValid && (
					<Text allowFontScaling={false} style={[styles.dataTitle, styles.dataText, styles.fieldError]}>
						{this.props.lang.gameShop.inviteFriendsMenu.emailFriend.email.errorMsg}
					</Text>
				)}
			</View>
		);
	}

	renderFriendNameField() {
		return (
			<View style={styles.dataInnerContainer}>
				<Text allowFontScaling={false} style={[styles.dataTitle, styles.dataText]}>
					{this.props.lang.gameShop.inviteFriendsMenu.emailFriend.friendName.title}
				</Text>
				<ImageBackground
					source={this.state.friendNameValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					{Platform.OS !== "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onBlur={this.onFriendNameSubmit.bind(this)}
							onFocus={() => this.setState({ friendNameActive: true })}
							onSubmitEditing={this.onFriendNameSubmit.bind(this)}
							onChangeText={(text) => this.onFriendNameChange(text)}
							value={this.state.friendName}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							autoCompleteType="off"
							textContentType="oneTimeCode"
							keyboardType={Platform.OS === "ios" ? "default" : "visible-password"}
							underlineColorAndroid="transparent"
							style={[styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }]}
							placeholder={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.friendName.placeHolder}
							placeholderTextColor="#8a8a8a"
						/>
					)}
					{Platform.OS === "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onBlur={this.onFriendNameSubmit.bind(this)}
							onFocus={() => this.setState({ friendNameActive: true })}
							onSubmitEditing={this.onFriendNameSubmit.bind(this)}
							onChangeText={(text) => this.onFriendNameChange(text)}
							value={this.state.friendName}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							textContentType="oneTimeCode"
							underlineColorAndroid="transparent"
							style={[styles.inputStyle, { outlineWidth: 0 }]}
							placeholder={this.props.lang.gameShop.inviteFriendsMenu.emailFriend.friendName.placeHolder}
							placeholderTextColor="#8a8a8a"
						/>
					)}
				</ImageBackground>
			</View>
		);
	}

	renderFriendNameError() {
		return (
			<View style={styles.dataErrorContainer}>
				{!this.state.friendNameValid && (
					<Text allowFontScaling={false} style={[styles.dataTitle, styles.dataText, styles.fieldError]}>
						{this.props.lang.gameShop.inviteFriendsMenu.emailFriend.friendName.errorMsg}
					</Text>
				)}
			</View>
		);
	}

	renderInnerContent() {
		if (!this.props.emailFriendPreviewOpened) {
			return (
				<View>
					{this.renderEmailField()}
					{this.renderEmailError()}
					{this.renderFriendNameField()}
					{this.renderFriendNameError()}
				</View>
			);
		}

		const { emailFriend } = this.props.lang.gameShop.inviteFriendsMenu;
		var text = emailFriend.previewText;
		text = text.replace("#friendName#", this.state.friendName);
		text = text.replace("#firstName#", this.props.userDetails.firstName);
		text = text.replace("#lastName#", this.props.userDetails.lastName);
		return (
			<ScrollView style={styles.previewContainer} contentContainerStyle={styles.containerContent}>
				<Text allowFontScaling={false} style={styles.previewText}>
					{text}
				</Text>
			</ScrollView>
		);
	}

	renderContent() {
		return (
			<View
				style={[
					styles.container,
					{
						transform: [{ scale: this.state.contentScale }],
					},
				]}
			>
				{this.renderInnerContent()}
				<View style={styles.mainButtonContainer}>
					{!this.props.emailFriendPreviewOpened && this.renderPreviewButton()}
					{this.renderSendEmailButton()}
				</View>
			</View>
		);
	}

	render() {
		switch (this.state.isSendInProgress) {
			case processState.STARTED:
				return <Spinner size="large" />;
			case processState.FINISHED:
				return (
					<InviteEmailSent
						emailAddress={this.state.email}
						onInviteMorePress={() => {
							this.setState({
								email: "",
								friendName: "",
								isSendInProgress: processState.INITIALIZED,
							});
							this.props.closeEmailFriendPreview();
						}}
					/>
				);
			default:
				return this.renderContent();
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			if (loginFormDimensions.width > availableWidth || loginFormDimensions.height > windowDim.height) {
				const _scaleX = Math.round((availableWidth / loginFormDimensions.width) * 100) / 100;
				const _scaleY = Math.round((windowDim.height / loginFormDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}

	isFormValidated() {
		try {
			const isEmailValid = validateEmail(this.state.email);
			const isFriendNameValid = validateTextField(this.state.friendName);
			const isFormValid = isEmailValid && isFriendNameValid;
			this.setState({
				emailValid: isEmailValid,
				friendNameValid: isFriendNameValid,
				isFormValid: isFormValid,
			});

			return isFormValid;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: { flex: 1, marginTop: 55, marginHorizontal: 30 },
	fullWidth: {
		width: "100%",
	},
	dataContainer: { marginTop: 10 },
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	mainButtonContainer: {
		flexDirection: "row",
		position: "absolute",
		right: 20,
		bottom: 0,
	},
	buttonContainer: { marginHorizontal: 10 },
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 300,
		height: 50,
		marginRight: 2,
		borderRadius: 5,
		// paddingTop: Platform.OS === "web" ? 0 : 13,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	linkText: {
		width: 200,
		paddingTop: 2,
		paddingRight: 10,
		fontFamily: f_sourcesansprobolditalic,
		fontSize: 22,
		color: "#00CED9",
		lineHeight: 40,
		textAlign: "left",
		textDecorationLine: "none",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 2, height: 2 },
		textShadowRadius: 10,
	},
	dataErrorContainer: {
		width: "100%",
		height: 40,
		marginTop: 3,
		marginBottom: 3,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	fieldError: {
		width: 500,
		height: "100%",
		color: "#FE1F01",
		textAlign: "right",
	},
	previewContainer: {
		paddingTop: 10,
		paddingHorizontal: 40,
		maxHeight: Dimensions.get("window").height - 140,
	},
	containerContent: {
		justifyContent: "center",
	},
	previewText: {
		fontFamily: f_sourcesansproregular,
		fontSize: 18,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		userDetails: state.currentUser.userDetails,
		emailFriendPreviewOpened: state.app.emailFriendPreviewOpened,
	};
};

const mapDispatchToProps = { closeEmailFriendPreview, showEmailFriendPreview };

export default connect(mapStateToProps, mapDispatchToProps)(EmailFriend);
