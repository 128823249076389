import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprolight,
	f_sourcesansproregular,
	isWebOrTablet,
	processState,
	RESOLUTION,
} from "../../../config/defaults";
import { emailTroubleDimensions, gameDimensions } from "../../../config/dimensions";
import { TEXT_INPUT, TEXT_INPUT_ERROR } from "../../../config/images";
import {
	clearSendUsernameError,
	closeLogInTrouble,
	closeSendEmailTrouble,
	goBackToMain,
	sendMyUsername,
} from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../../common";
import { handleError } from "../../ErrorHandler";

class SendEmailTrouble extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			email: "",
			emailValid: true,
			emailActive: false,
			isFormValid: true,
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		Analytics.logScreenView("EmailUsername");
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.emailUsernameState != this.props.emailUsernameState &&
			!prevProps.emailUsernameSucceeded &&
			!this.props.emailUsernameSucceeded
		) {
			this.setState({ emailValid: false, isFormValid: false });
		}
	}
	//#endregion

	//#region events
	onEmailSubmit() {
		this.setState({ emailActive: false });
	}
	onEmailChange(text) {
		this.setState({ email: text });
	}
	onEmailFocus() {
		this.setState({ emailActive: true });
	}
	onSendPress() {
		this.props.sendMyUsername(this.state.email);
	}
	onGoToLoginPress() {
		this.props.closeLogInTrouble();
		this.props.closeSendEmailTrouble();
	}
	onBackPress() {
		if (this.props.emailUsernameState != processState.INITIALIZED) {
			this.props.closeLogInTrouble();
		}
		this.props.closeSendEmailTrouble();
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.emailUsernameState != processState.STARTED}
				backAction={this.onBackPress.bind(this)}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderEmailField() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = [styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_inputStyle = [
				styles.inputStyle,
				{ fontSize: 26, marginTop: Platform.OS === "web" ? 0 : 3 },
				Platform.OS === "android" && { paddingTop: 13 },
			];
		}
		return (
			<View
				style={styles.dataInnerContainer}
				accessible
				accessibilityLabel={"input " + this.props.lang.loginTrouble.emailAddress}
			>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.loginTrouble.emailAddress}
				</Text>
				<ImageBackground
					source={this.state.emailValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					{Platform.OS !== "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onBlur={this.onEmailSubmit.bind(this)}
							onFocus={this.onEmailFocus.bind(this)}
							onSubmitEditing={this.onEmailSubmit.bind(this)}
							onChangeText={(text) => this.onEmailChange(text)}
							value={this.state.email}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							autoCompleteType="off"
							autoCorrect={false}
							spellCheck={false}
							textContentType="oneTimeCode"
							keyboardType="email-address"
							underlineColorAndroid="transparent"
							style={_inputStyle}
							placeholder={this.props.lang.loginTrouble.emailAddress}
							placeholderTextColor="#8a8a8a"
						/>
					)}
					{Platform.OS === "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onBlur={this.onEmailSubmit.bind(this)}
							onFocus={this.onEmailFocus.bind(this)}
							onSubmitEditing={this.onEmailSubmit.bind(this)}
							onChangeText={(text) => this.onEmailChange(text)}
							value={this.state.email}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							autoCorrect={false}
							spellCheck={false}
							textContentType="oneTimeCode"
							underlineColorAndroid="transparent"
							style={[_inputStyle, { outlineWidth: 0 }]}
							placeholder={this.props.lang.loginTrouble.emailAddress}
							placeholderTextColor="#8a8a8a"
						/>
					)}
				</ImageBackground>
			</View>
		);
	}

	renderEmailError() {
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataErrorContainer}>
				{((this.props.emailUsernameState == processState.FINISHED && !this.props.emailUsernameSucceeded) ||
					!this.state.emailValid) && (
					<Text allowFontScaling={false} style={_errorStyle}>
						{this.props.emailUsernameError}
					</Text>
				)}
			</View>
		);
	}

	renderSendButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.sendButtonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onSendPress.bind(this)}
					textContent={this.props.lang.loginTrouble.sendUsername}
					accessibilityLabel={this.props.lang.loginTrouble.sendUsername}
					color={buttonColor.GREEN}
				/>
			</View>
		);
	}

	renderLoginButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.loginButtonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onGoToLoginPress.bind(this)}
					textContent={this.props.lang.loginTrouble.loginButton}
					accessibilityLabel={this.props.lang.loginTrouble.loginButton}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderButton() {
		if (this.props.emailUsernameState == processState.FINISHED && this.props.emailUsernameSucceeded) {
			return this.renderLoginButton();
		} else {
			return this.renderSendButton();
		}
	}

	renderContent() {
		if (!this.props.emailUsernameSucceeded) {
			return (
				<View
					style={[
						styles.contentContainer,
						// { transform: [{ scale: this.state.contentScale }] },
					]}
				>
					<View style={isWebOrTablet ? { flex: 1, justifyContent: "center" } : { paddingTop: 40, paddingRight: 20 }}>
						{this.renderEmailField()}
						{this.renderEmailError()}
					</View>
					{this.renderButton()}
				</View>
			);
		} else {
			var _titleStyle = styles.titleStyle,
				_infoStyle = styles.contentText;
			if (this.props.resolution === RESOLUTION.HIGH) {
				_titleStyle = [styles.titleStyle, { fontSize: 32 }];
				_infoStyle = [styles.contentText, { fontSize: 26, lineHeight: undefined }];
			}
			return (
				<View style={styles.emailContainer}>
					<View style={styles.titleContainer}>
						<Text allowFontScaling={false} style={_titleStyle}>
							{this.props.lang.loginTrouble.emailSent.title}
						</Text>
					</View>
					<ScrollView
						style={[
							styles.sentContentContainer,
							{
								height: Dimensions.get("window").height - 40,
							},
						]}
					>
						<Text allowFontScaling={false} style={_infoStyle}>
							{this.props.lang.loginTrouble.emailSent.text}
						</Text>
					</ScrollView>
					{this.renderButton()}
				</View>
			);
		}
	}

	render() {
		if (this.props.emailUsernameState == processState.STARTED)
			return (
				<ImageBackground source={this.props.DEFAULT_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		return (
			<ImageBackground source={this.props.DEFAULT_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (emailTroubleDimensions.width > availableWidth || emailTroubleDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / emailTroubleDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / emailTroubleDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		padding: 20,
	},
	contentContainer: { flex: 1 },
	sendButtonContainer: {
		position: "absolute",
		right: 10,
		bottom: 0,
	},
	loginButtonContainer: {
		marginRight: 60,
		alignSelf: "flex-end",
		justifyContent: "flex-end",
		alignItems: "flex-end",
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
		alignSelf: isWebOrTablet ? "center" : "flex-end",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 300,
		height: 50,
		marginRight: 2,
		borderRadius: 5,
		// paddingTop: Platform.OS === "web" ? 0 : 13,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataErrorContainer: {
		width: "100%",
		height: 40,
		marginTop: 3,
		marginBottom: 3,
		paddingLeft: 10,
		justifyContent: "flex-end",
		alignItems: isWebOrTablet ? "center" : "flex-end",
		alignSelf: "flex-end",
	},
	fieldError: {
		width: 500,
		height: "100%",
		color: "#FE1F01",
		textAlign: "right",
	},
	emailContainer: { flex: 1, paddingHorizontal: isWebOrTablet ? 20 : 0 },
	titleContainer: {
		width: "85%",
		height: 50,
		alignSelf: "center",
	},
	titleStyle: {
		width: "100%",
		lineHeight: 50,
		fontFamily: f_sourcesansprobold,
		fontSize: 28,
		color: "#F6A022",
		textShadowColor: "rgba(0,0,0,0.5)",
		textShadowOffset: { width: 0, height: 3 },
		textShadowRadius: 10,
		textAlign: "center",
	},
	sentContentContainer: {
		paddingHorizontal: 50,
		marginVertical: 5,
	},
	contentText: {
		width: "100%",
		lineHeight: 30,
		fontFamily: f_sourcesansproregular,
		fontSize: 18,
		color: "#FFFFFF",
		marginTop: 20,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		emailUsernameSucceeded: state.app.emailUsernameSucceeded,
		emailUsernameState: state.app.emailUsernameState,
		emailUsernameError: state.app.emailUsernameError,
		resolution: state.currentUser.preferences.resolution,
		DEFAULT_BACK: state.images.DEFAULT_BACK,
	};
};

const mapDispatchToProps = {
	clearSendUsernameError,
	closeLogInTrouble,
	closeSendEmailTrouble,
	goBackToMain,
	sendMyUsername,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailTrouble);
