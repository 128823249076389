import { isTablet } from "./defaults";

export const silverShopDimensions = {
	width: 775,
	height: 360,
};

export const loginFormDimensions = {
	width: 650,
	height: 350,
};

export const emailTroubleDimensions = {
	width: 550,
	height: 350,
};

export const updateLocationDimensions = {
	width: 550,
	height: 350,
};

export const updateUsernameDimensions = {
	width: 550,
	height: 350,
};

export const updateLanguageDimensions = {
	width: 550,
	height: 350,
};

export const updatePersonalDataDimensions = {
	width: 550,
	height: 350,
};

export const updateAvatarDimensions = {
	width: 550,
	height: 350,
	image: {
		width: 150,
		height: 150,
	},
};

export const overrrideCardsetDimensions = {
	width: 550,
	height: 350,
};

export const defaultCardsetDimensions = {
	width: 550,
	height: 350,
};

export const fixBoardPositionsDimensions = {
	width: 550,
	height: 400,
};

export const fixBoardColorsDimensions = {
	width: 550,
	height: 350,
};

export const emailNotifSettingsDimensions = {
	width: 540,
	height: 350,
};

export const appNotifSettingsDimensions = {
	width: 540,
	height: 350,
};

export const appLanguageDimensions = {
	width: 540,
	height: 350,
};

export const chatTranslationDimensions = {
	width: 540,
	height: 350,
};

export const deleteAccountDimensions = {
	width: 550,
	height: 350,
};

export const playersListDimensions = {
	width: 600,
	height: 375,
};

export const dataTableHeaderMinHeight = isTablet ? 30 : 25;

export const gameDimensions = {
	fullWidthMargin: 3,
	headerHeight: 60,
	tabAreaDefaultHeight: 60,
	notificationBarDefaultHeight: 0,
	pageContentTopMargin: 5,
	pageContentBottomMargin: 5,
	gameButtonsHeight: 40,
	gameButtons_HR_Height: 60,
	boxHeaderHeight: isTablet ? 50 : 33,
	gameButtonsWidth: 200,
	leftBoxDefaultLeftMargin: 0,
	rightBoxDefaultLeftMargin: 5,
	navHeaderHeight: 60,
};

export const gameLobbyDimensions = {
	gameListDefaultWidth: 505,
	gameListMinWidth: 250,
	gameListDefaultHeight: 570,
	gameListMinHeight: 270,
	gameListTableMinHeight: 25,
	gameDetailsDefaultWidth: 600,
	gameDetailsMinWidth: 425,
	gameDetailsDefaultHeight: 400,
	gameDetailsMinHeight: 270,
	gameDetailsFooterHeight: isTablet ? 160 : 100,
	gameListFilterContainerMinHeight: 0,
	gameListFilterContainerMaxHeight: 185,
	gameListFilterSectionMinHeight: 0,
	gameListFilterSectionMaxHeight: 160,
};

export const gameListColumns = {
	creatorCountry: {
		flexible: false,
		minWidth: 40,
		maxWidth: 40,
		sortable: false,
		cellType: "img",
		style: {
			backgroundColor: "#FFFFFF",
			selectedColor: "#edeeef",
			justifyTextContent: "center",
			textColor: "#000000",
		},
	},
	chipsRequired: {
		flexible: false,
		minWidth: 40,
		maxWidth: 40,
		sortable: false,
		cellType: "img",
		style: {
			backgroundColor: "#FFFFFF",
			selectedColor: "#edeeef",
			justifyTextContent: "center",
			textColor: "#000000",
		},
	},
	name: {
		flexible: true,
		secureWidth: 265,
		sortable: true,
		cellType: "text",
		style: {
			backgroundColor: "#f7fbee",
			selectedColor: "#e5eadf",
			justifyTextContent: "flex-start",
			textAlign: "left",
			textColor: "#a5d043",
		},
	},
	playerCnt: {
		flexible: false,
		minWidth: 50,
		maxWidth: 50,
		sortable: true,
		cellType: "text",
		style: {
			backgroundColor: "#fdeeea",
			selectedColor: "#ebdedc",
			justifyTextContent: "center",
			textAlign: "center",
			textColor: "#ef6f46",
		},
	},
	/* freeSlots: {
    flexible: false,
    minWidth: 45,
    maxWidth: 50,
    sortable: true,
    cellType: "text",
    style: {
      backgroundColor: "#ecedee",
      selectedColor: "#dbdddf",
      justifyTextContent: "center",
      textAlign: "center",
      textColor: "#261c19",
    },
  }, */
};

export const gameListMobileColumns = {
	creatorCountry: {
		flexible: false,
		minWidth: 40,
		maxWidth: 40,
		sortable: false,
		cellType: "img",
		style: {
			backgroundColor: "#FFFFFF",
			selectedColor: "#edeeef",
			justifyTextContent: "center",
			textColor: "#000000",
		},
	},
	chipsRequired: {
		flexible: false,
		minWidth: 40,
		maxWidth: 40,
		sortable: false,
		cellType: "img",
		style: {
			backgroundColor: "#FFFFFF",
			selectedColor: "#edeeef",
			justifyTextContent: "center",
			textColor: "#000000",
		},
	},
	name: {
		flexible: true,
		secureWidth: 265,
		sortable: true,
		cellType: "text",
		style: {
			backgroundColor: "#f7fbee",
			selectedColor: "#e5eadf",
			justifyTextContent: "flex-start",
			textAlign: "left",
			textColor: "#a5d043",
		},
	},
	playerCnt: {
		flexible: false,
		minWidth: 50,
		maxWidth: 50,
		sortable: true,
		cellType: "text",
		style: {
			backgroundColor: "#fdeeea",
			selectedColor: "#ebdedc",
			justifyTextContent: "center",
			textAlign: "center",
			textColor: "#ef6f46",
		},
	},
	/* freeSlots: {
    flexible: false,
    minWidth: 45,
    maxWidth: 50,
    sortable: true,
    cellType: "text",
    style: {
      backgroundColor: "#ecedee",
      selectedColor: "#dbdddf",
      justifyTextContent: "center",
      textAlign: "center",
      textColor: "#261c19",
    },
  }, */
};

export const gameDetailsColumns = {
	/* slotID: {
    flexible: false,
    minWidth: isWebOrTablet ? 20 : 11,
    maxWidth: isWebOrTablet ? 25 : 15,
    cellType: "text",
    style: {
      backgroundColor: "#FFFFFF",
      selectedColor: "#edeeef",
      justifyTextContent: "flex-end",
      textAlign: "center",
      textColor: "#000000",
    },
  }, */
	country: {
		flexible: false,
		minWidth: 45,
		maxWidth: 45,
		cellType: "img",
		style: {
			backgroundColor: "#FFFFFF",
			selectedColor: "#edeeef",
			justifyTextContent: "center",
			textColor: "#000000",
		},
	},
	ranking: {
		flexible: false,
		minWidth: 45,
		maxWidth: 45,
		cellType: "img",
		imageSize: 30,
		style: {
			backgroundColor: "#FFFFFF",
			selectedColor: "#edeeef",
			justifyTextContent: "center",
			textColor: "#000000",
		},
	},
	avatar: {
		flexible: false,
		minWidth: 45,
		maxWidth: 45,
		cellType: "img",
		style: {
			backgroundColor: "#FFFFFF",
			selectedColor: "#edeeef",
			justifyTextContent: "center",
			textColor: "#000000",
		},
	},
	username: {
		flexible: true,
		secureWidth: 265,
		cellType: "mixed",
		style: {
			backgroundColor: "#FFFFFF",
			selectedColor: "#edeeef",
			justifyTextContent: "flex-start",
			textAlign: "left",
			textColor: "#000000",
		},
	},
	/* teamName: {
    flexible: false,
    minWidth: 50,
    maxWidth: 80,
    cellType: "text",
    style: {
      backgroundColor: "#FFFFFF",
      selectedColor: "#edeeef",
      justifyTextContent: "center",
      textAlign: "center",
      textColor: "#000000",
    },
  }, */
};

export const newGameDimensions = {
	slotSettingsDefaultWidth: 505,
	slotSettingsMinWidth: 310,
	slotSettingsDefaultHeight: 400,
	slotSettingsMinHeight: 345,
	mobileSettingsDefaultHeight: 290,
	otherSettingsDefaultWidth: 600,
	otherSettingsMinWidth: 310,
	otherSettingsDefaultHeight: 400,
	otherSettingsMinHeight: 345,
	otherSettingsBodyDefaultHeight: 105,
};

export const gameRoomDimensions = {
	grSlotSettingsDefaultWidth: 525,
	grSlotSettingsMinWidth: 310,
	grSlotSettingsDefaultHeight: 380,
	grSlotSettingsMinHeight: 320,
	grMobileSettingsDefaultHeight: 290,
	grOtherSettingsDefaultWidth: 600,
	grOtherSettingsMinWidth: 310,
	grOtherSettingsDefaultHeight: 380,
	grOtherSettingsMinHeight: 320,
	grOtherSettingsBodyDefaultHeight: 105,
	grChatDefaultWidth: 1140,
	grChatDefaultHeight: 170,
	grChatMinHeight: 155,
	chatInputHeight: 60,
};

export const globalChatDimensions = {
	chatMainDefaultWidth: 680,
	chatMainDefaultHeight: 500,
	chatInputHeight: 60,
	chatMainMinHeight: 180,
	chatListsDefaultWidth: 400,
	chatListsDefaultHeight: 500,
	chatListsMinHeight: 215,
	chatRoomNameMainDefaultWidth: 700,
};

export const shopDimensions = {
	shopDefaultWidth: 880,
	shopDefaultHeight: 625,
};

export const gameStartedDimensions = {
	playingCardWidth: 71.5,
	playingCardHeight: 95.5,
	playerPanelWidth: 52,
	playerPanelHeight: 41,
	maxBoardScale: 3,
	chatTabWidth: 85,
	moreButtonWidth: 35,
};
