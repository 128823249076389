import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import {
	Image,
	ImageBackground,
	ScrollView,
	StyleSheet,
	Switch,
	Text,
	TextInput,
	TouchableOpacity,
	View,
} from "react-native";
import { AppTouchableOpacity } from "../../../components/common";
import { handleError } from "../../../components/ErrorHandler";
import {
	appFont,
	f_sourcesansprolight,
	f_sourcesansprosemibold,
	gameDetailsTableKey,
	gameNameMaxLength,
	gameNameMinLength,
	modalType,
} from "../../../config/defaults";
import { TEXT_INPUT, TOGGLE_ICON } from "../../../config/images";
import { mapObject } from "../../../helpers/commonHelpers";
import CheckBox from "../../../libraries/CheckBox";

class GameRuleDetails extends Component {
	constructor(props) {
		super(props);
		const gameNameRule = this.props.rulesList.find((r) => r.ruleName == "gameName");
		this.state = {
			requiredChipsVal: "0",
			isRequiredChipsActive: false,
			gameNameContent: gameNameRule !== undefined ? gameNameRule.content : "",
			isGameNameActive: false,
			rowHeight: 27,
			dataWidth: 175,
			maxVisibleRows: 4,
		};
	}

	//#region events
	onGameNameSubmit() {
		try {
			const { gameNameContent } = this.state;
			const { onGameNameSubmit, notifAction, notifMsgs, notifButtonText } = this.props;
			if (gameNameContent.length >= gameNameMinLength && gameNameContent.length <= gameNameMaxLength) {
				if (typeof onGameNameSubmit === "function") onGameNameSubmit(gameNameContent);
			} else {
				var gameNameLengthWarningMessage = { main_text: "" };
				if (typeof notifMsgs !== "undefined" && typeof notifMsgs.gameNameLengthWarningMessage !== "undefined") {
					gameNameLengthWarningMessage.main_text = notifMsgs.gameNameLengthWarningMessage.main_text;
				}
				if (typeof notifAction === "function") {
					notifAction(modalType.ERROR, gameNameLengthWarningMessage, notifButtonText, null);
				}
				const gameNameRule = this.props.rulesList.find((r) => r.ruleName == "gameName");
				this.setState({
					gameNameContent: gameNameRule !== undefined ? gameNameRule.content : "",
				});
			}
			this.setState({ isGameNameActive: false });
		} catch (error) {
			handleError(error);
		}
	}

	onGameNameFocus() {
		this.setState({ isGameNameActive: true });
	}

	onRequiredChipsSubmit() {
		try {
			const { requiredChipsAction } = this.props;
			var newVal = Number(this.state.requiredChipsVal);
			if (isNaN(newVal)) newVal = 0;
			newVal = Math.abs(newVal);
			this.setState({ requiredChipsVal: newVal.toString() });
			this.setState({ isRequiredChipsActive: false });
			if (typeof requiredChipsAction === "function") {
				requiredChipsAction(newVal);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onRequiredChipsFocus() {
		this.setState({ isRequiredChipsActive: true });
	}

	onLevelChange(rule, value) {
		if (typeof this.props.playerLevelAction === "function") {
			this.props.playerLevelAction(rule, value);
		}
	}

	onCardsetChange(cardsetID) {
		if (typeof this.props.cardsetChangeAction === "function") {
			this.props.cardsetChangeAction(cardsetID);
		}
	}

	onGameThemeChange(gameThemeID) {
		if (typeof this.props.gameThemeChangeAction === "function") {
			this.props.gameThemeChangeAction(gameThemeID);
		}
	}

	toggleCardsetList(prev = true, cardsets, activeOptionId) {
		try {
			if (Object.keys(cardsets).length > 0) {
				var selected = undefined,
					curSelInd = -1;
				mapObject(cardsets, (k, v, ind) => {
					if (v.cardsetID == activeOptionId) {
						selected = v;
						curSelInd = ind;
					}
				});
				if (curSelInd >= 0) {
					var nextSelInd = curSelInd;
					if (prev) {
						nextSelInd = curSelInd - 1;
						nextSelInd = nextSelInd < 0 ? Object.keys(cardsets).length - 1 : nextSelInd;
					} else {
						nextSelInd = curSelInd + 1;
						nextSelInd = nextSelInd == Object.keys(cardsets).length ? 0 : nextSelInd;
					}
					const newCardsetKey = Object.keys(cardsets)[nextSelInd];
					const newCardsetID = cardsets[newCardsetKey].cardsetID;
					this.onCardsetChange(newCardsetID);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	toggleGameThemes(prev = true, themes, activeOptionId) {
		try {
			if (Object.keys(themes).length > 0) {
				var selected = undefined,
					curSelInd = -1;
				mapObject(themes, (k, v, ind) => {
					if (v.gameThemeID == activeOptionId) {
						selected = v;
						curSelInd = ind;
					}
				});
				if (curSelInd >= 0) {
					var nextSelInd = curSelInd;
					if (prev) {
						nextSelInd = curSelInd - 1;
						nextSelInd = nextSelInd < 0 ? Object.keys(themes).length - 1 : nextSelInd;
					} else {
						nextSelInd = curSelInd + 1;
						nextSelInd = nextSelInd == Object.keys(themes).length ? 0 : nextSelInd;
					}
					const newGameThemeKey = Object.keys(themes)[nextSelInd];
					const newGameThemeID = themes[newGameThemeKey].gameThemeID;
					this.onGameThemeChange(newGameThemeID);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}

	measureScrollView(event) {
		try {
			if (this.props.tableKey !== gameDetailsTableKey.newGame) return null;
			var _height = Math.round((event.nativeEvent.layout.height - 60) / 10);
			_height = _height > 45 ? 45 : _height;
			_height = _height < 30 ? 30 : _height;
			var _width = Math.round(event.nativeEvent.layout.width - 215);
			_width = _width > 300 ? 300 : _width;
			_width = _width < 130 ? 130 : _width;
			this.setState({ rowHeight: _height, dataWidth: _width });
		} catch (error) {
			handleError(error);
		}
	}

	measureContainer(event) {
		const fullHeight = event.nativeEvent.layout.height;
		const maxVisibleRows = Math.round(fullHeight / 30);
		this.setState({ maxVisibleRows });
	}
	//#endregion

	//#region render methods
	renderRuleContent(rule) {
		if (typeof rule.content === "string" && this.props.tableKey != gameDetailsTableKey.newGame) {
			return (
				<Text
					allowFontScaling={false}
					selectable={false}
					style={[styles.ruleContentContainer, styles.normalText, { marginLeft: 5 }]}
				>
					{rule.content || ""}
				</Text>
			);
		}

		//return the specific ruleContent
		const opacity = rule.enabled ? 1 : 0.5;
		switch (rule.ruleName) {
			case "gameName":
				if (this.props.tableKey != gameDetailsTableKey.newGame) return null;
				return (
					<ImageBackground
						source={TEXT_INPUT}
						resizeMode="stretch"
						style={[
							styles.inputImageStyle,
							styles.nameInputStyle,
							{
								height: this.state.rowHeight,
								width: this.state.dataWidth,
								marginTop: -10,
							},
						]}
					>
						<TextInput
							allowFontScaling={false}
							ref="gameNameInput"
							disableFullscreenUI={true}
							onBlur={this.onGameNameSubmit.bind(this)}
							onFocus={this.onGameNameFocus.bind(this)}
							onSubmitEditing={this.onGameNameSubmit.bind(this)}
							onChangeText={(text) => this.setState({ gameNameContent: text })}
							value={this.state.gameNameContent}
							placeholder={rule.placeholder || ""}
							editable={rule.enabled}
							disabled={!rule.enabled}
							selectTextOnFocus={true}
							maxLength={gameNameMaxLength}
							underlineColorAndroid="transparent"
							style={[
								styles.inputStyle,
								styles.nameInputStyle,
								{ height: this.state.rowHeight, width: this.state.dataWidth },
							]}
						/>
					</ImageBackground>
				);
			case "enableTeams":
			case "localLanguagesOnly":
			case "localCountryOnly":
			case "onlyFriends":
			case "playForChips":
			case "fairDeal":
			case "privateGame":
				if (this.props.tableKey == gameDetailsTableKey.newGame) {
					return (
						<View style={{ width: this.state.dataWidth + 15, opacity }}>
							<Switch
								disabled={!rule.enabled}
								value={rule.checkedState}
								activeTrackColor={rule.checkedState ? "#00e600" : "grey"}
								onValueChange={(val) => {
									this.props.switchBoxAction(rule.ruleName, val, Number(this.state.requiredChipsVal));
								}}
								style={{ alignSelf: "flex-end", marginTop: -10 }}
							/>
						</View>
					);
				}
				return (
					<View style={styles.ruleContentContainer}>
						<CheckBox value={rule.content} disabled={true} style={styles.checkboxStyle} accessible={false} />
					</View>
				);
			case "playForChipsAmount":
				if (this.props.tableKey == gameDetailsTableKey.newGame) {
					return (
						<View style={{ flexDirection: "row", height: this.state.rowHeight }}>
							{rule.checkedState && (
								<ImageBackground
									source={TEXT_INPUT}
									resizeMode="stretch"
									style={[styles.inputImageStyle, { height: this.state.rowHeight }]}
								>
									<TextInput
										allowFontScaling={false}
										disableFullscreenUI={true}
										group={rule.ruleName}
										ref="chipsRequiredInput"
										onBlur={this.onRequiredChipsSubmit.bind(this)}
										onFocus={this.onRequiredChipsFocus.bind(this)}
										onSubmitEditing={this.onRequiredChipsSubmit.bind(this)}
										onChangeText={(text) => this.setState({ requiredChipsVal: text })}
										value={this.state.requiredChipsVal}
										editable={rule.enabled}
										disabled={!rule.enabled}
										selectTextOnFocus={true}
										underlineColorAndroid="transparent"
										keyboardType={"numeric"}
										style={[styles.inputStyle, { height: this.state.rowHeight }]}
									/>
								</ImageBackground>
							)}
						</View>
					);
				}
				return this.null;
			case "cardset":
				if (this.props.tableKey == gameDetailsTableKey.newGame) {
					var selected = undefined;
					mapObject(rule.cardsets, (k, v) => {
						if (v.cardsetID == rule.activeOptionId) selected = v;
					});
					const cardsetName = selected !== undefined ? selected.name : "";
					return (
						<View style={[styles.toggleContainer, { width: this.state.dataWidth + 15 }]}>
							<AppTouchableOpacity
								disabled={selected == undefined || !rule.enabled}
								style={[styles.toggleButton, { opacity }]}
								onPress={() => this.toggleCardsetList(true, rule.cardsets, rule.activeOptionId)} //TODO:
								accessibilityState={{
									disabled: selected == undefined || !rule.enabled,
								}}
								accessibilityLabel={"previous cardset"}
							>
								<Image source={TOGGLE_ICON} style={{ width: 30, height: 30 }} resizeMode="stretch" />
							</AppTouchableOpacity>
							<Text allowFontScaling={false} style={[styles.toggleText, { lineHeight: this.state.rowHeight }]}>
								{cardsetName}
							</Text>
							<AppTouchableOpacity
								disabled={selected == undefined || !rule.enabled}
								style={[styles.toggleButton, { opacity }]}
								onPress={() => this.toggleCardsetList(false, rule.cardsets, rule.activeOptionId)} //TODO:
								accessibilityState={{
									disabled: selected == undefined || !rule.enabled,
								}}
								accessibilityLabel={"next cardset"}
							>
								<Image
									source={TOGGLE_ICON}
									style={{
										width: 30,
										height: 30,
										transform: [{ rotateY: "-180deg" }],
									}}
									resizeMode="stretch"
								/>
							</AppTouchableOpacity>
						</View>
					);
				}
				return (
					<Text allowFontScaling={false} selectable={false} style={styles.normalText}>
						{rule.content || ""}
					</Text>
				);
			case "gameTheme":
				if (this.props.tableKey == gameDetailsTableKey.newGame) {
					var selected = undefined;
					mapObject(rule.themes, (k, v) => {
						if (v.gameThemeID == rule.activeOptionId) selected = v;
					});
					const themeName = selected !== undefined ? selected.name : "";
					return (
						<View style={[styles.toggleContainer, { width: this.state.dataWidth + 15 }]}>
							<AppTouchableOpacity
								disabled={selected == undefined || !rule.enabled}
								style={[styles.toggleButton, { opacity }]}
								onPress={() => this.toggleGameThemes(true, rule.themes, rule.activeOptionId)}
								accessibilityState={{
									disabled: selected == undefined || !rule.enabled,
								}}
								accessibilityLabel={"previous game theme"}
							>
								<Image source={TOGGLE_ICON} style={{ width: 30, height: 30 }} resizeMode="stretch" />
							</AppTouchableOpacity>
							<Text allowFontScaling={false} style={[styles.toggleText, { lineHeight: this.state.rowHeight }]}>
								{themeName}
							</Text>
							<AppTouchableOpacity
								disabled={selected == undefined || !rule.enabled}
								style={[styles.toggleButton, { opacity }]}
								onPress={() => this.toggleGameThemes(false, rule.themes, rule.activeOptionId)}
								accessibilityState={{
									disabled: selected == undefined || !rule.enabled,
								}}
								accessibilityLabel={"next theme"}
							>
								<Image
									source={TOGGLE_ICON}
									style={{
										width: 30,
										height: 30,
										transform: [{ rotateY: "-180deg" }],
									}}
									resizeMode="stretch"
								/>
							</AppTouchableOpacity>
						</View>
					);
				}
				return (
					<Text allowFontScaling={false} selectable={false} style={styles.normalText}>
						{rule.content || ""}
					</Text>
				);
			case "minLevel":
			case "maxLevel":
				return (
					<View
						style={{
							flexDirection: "row",
							justifyContent: "flex-end",
							width: this.state.dataWidth,
						}}
					>
						<Text
							allowFontScaling={false}
							style={[
								styles.sliderLabel,
								{
									marginRight: 25,
									color: "#000000",
									lineHeight: this.state.rowHeight,
								},
							]}
						>
							{rule.value}
						</Text>
						<Text allowFontScaling={false} style={[styles.sliderLabel, { lineHeight: this.state.rowHeight }]}>
							{rule.minVal}
						</Text>
						<Slider
							ref={rule.ruleName}
							className="game-create"
							min={rule.minVal}
							max={rule.maxVal}
							step={1}
							value={rule.value}
							disabled={!rule.enabled}
							trackStyle={[{ backgroundColor: "#0C697A" }]}
							railStyle={{ backgroundColor: "#009688" }}
							handleStyle={[{ backgroundColor: "#0B5969", borderColor: "#0B5969" }]}
							onChange={(val) => {
								this.onLevelChange(rule.ruleName, val);
							}}
						/>
						<Text allowFontScaling={false} style={[styles.sliderLabel, { lineHeight: this.state.rowHeight }]}>
							{rule.maxVal}
						</Text>
					</View>
				);
			default:
				return null;
		}
	}

	renderRules(startIndex, endIndex, leftCol = true) {
		const { rulesList, tableKey } = this.props;

		if (!Array.isArray(rulesList)) return null;
		var normalTextSyle = [styles.normalText];
		var labelContainerStyle = [styles.labelContainer];
		var ruleMainContainerStyle = [styles.ruleMainContainer];
		var innerContainerStyle = [styles.innerContainer];
		switch (tableKey) {
			case gameDetailsTableKey.newGame:
				normalTextSyle = [...normalTextSyle, { width: 180, fontSize: 18, lineHeight: this.state.rowHeight }];
				labelContainerStyle = [
					...labelContainerStyle,
					{ width: 180, alignSelf: "center", height: this.state.rowHeight },
				];
				ruleMainContainerStyle = [
					...ruleMainContainerStyle,
					{ height: this.state.rowHeight, paddingLeft: 0, paddingTop: 5 },
				];
				innerContainerStyle = [...innerContainerStyle, { height: this.state.rowHeight }];
				break;
			case gameDetailsTableKey.joinedGame:
			case gameDetailsTableKey.selectedGame:
				normalTextSyle = [...normalTextSyle, { width: leftCol == true ? 170 : 140 }];
				labelContainerStyle = [...labelContainerStyle, { width: leftCol == true ? 170 : 140 }];
				break;
		}

		var rules = [];
		rulesList.forEach((rule, index) => {
			if (index >= startIndex && index <= endIndex && rule.ruleName !== "gameTheme") {
				if (rule.ruleName === "playForChipsAmount" && !rule.checkedState) {
					if (tableKey !== gameDetailsTableKey.newGame) {
						rules.push(
							<View style={ruleMainContainerStyle} key={"gameDetailsRule_" + index}>
								<View style={innerContainerStyle}></View>
							</View>
						);
					}
				} else {
					var labelColor =
						this.props.tableKey == gameDetailsTableKey.newGame ? (rule.enabled ? "#FFFDF1" : "#0C6A7A") : "#000000";
					const labelTextShadowColor =
						this.props.tableKey == gameDetailsTableKey.newGame ? "rgba(0,0,0,0.25)" : "transparent";
					const fontFamily = this.props.tableKey == gameDetailsTableKey.newGame ? f_sourcesansprosemibold : appFont;
					const fontSize = this.props.tableKey == gameDetailsTableKey.newGame ? 18 : 18;
					switch (rule.ruleName) {
						case "gameName":
						case "playForChipsAmount":
							labelColor =
								this.props.tableKey == gameDetailsTableKey.newGame
									? rule.enabled
										? "#FFFDF1"
										: labelColor
									: labelColor;
							break;
					}
					rules.push(
						<View style={ruleMainContainerStyle} key={"gameDetailsRule_" + index}>
							<TouchableOpacity
								activeOpacity={1}
								disabled={this.props.tableKey != gameDetailsTableKey.newGame}
								onPress={() => {
									const permission = this.props.checkPermission(rule.ruleName);
									if (!permission.enabled) this.props.onPermissionDenied(permission.secRightID, permission.rightID);
								}}
								style={innerContainerStyle}
								touchSoundDisabled={true}
							>
								<View style={labelContainerStyle}>
									<Text
										allowFontScaling={false}
										selectable={false}
										style={[
											normalTextSyle,
											{
												fontFamily,
												fontSize,
												color: labelColor,
												textShadowColor: labelTextShadowColor,
												paddingLeft: rule.ruleName == "playForChipsAmount" ? 25 : 0,
											},
										]}
									>
										{rule.label || ""}
									</Text>
								</View>
								{this.renderRuleContent(rule)}
							</TouchableOpacity>
						</View>
					);
				}
			}
		});

		return rules;
	}

	render() {
		const mainContainerStyle = [
			styles.mainContainer,
			this.props.containerStyle,
			this.props.scrollViewHeight ? { height: this.props.scrollViewHeight } : {},
		];
		if (this.props.tableKey !== gameDetailsTableKey.newGame) {
			var minCounter = 0,
				maxCounter = 0,
				leftMin = 0,
				leftMax = 4,
				rightMin = 5,
				rightMax = 9;
			if (this.props.rulesList.length > 0) {
				maxCounter = Math.floor(this.props.rulesList.length / 2);
				if (this.state.maxVisibleRows < maxCounter) {
					minCounter = maxCounter - this.state.maxVisibleRows;
					maxCounter = minCounter + this.state.maxVisibleRows - 1;
					while (this.props.rulesList.length - 1 - maxCounter > this.state.maxVisibleRows) {
						minCounter++;
						maxCounter++;
					}
					leftMin = minCounter;
					leftMax = maxCounter;
					rightMin = leftMax + 1;
					rightMax = this.props.rulesList.length - 1;
				}
			}
			return (
				<View style={[mainContainerStyle, { flexDirection: "row", paddingLeft: 0, overflow: "hidden" }]}>
					<View
						style={{
							flex: 2,
							paddingLeft: 5,
							borderRightColor: "#0C6A7A",
							borderRightWidth: 1,
						}}
						onLayout={(event) => this.measureContainer(event)}
					>
						{this.renderRules(leftMin, leftMax, true)}
					</View>
					<View style={{ flex: 2, paddingLeft: 5 }}>{this.renderRules(rightMin, rightMax, false)}</View>
				</View>
			);
		} else {
			return (
				<ScrollView
					style={[
						mainContainerStyle,
						{
							paddingTop: 0,
							paddingLeft: 5,
							backgroundColor: "transparent",
							borderTopWidth: 0,
							marginTop: 47,
						},
					]}
					showsVerticalScrollIndicator={false}
					nestedScrollEnabled={true}
					onLayout={(event) => this.measureScrollView(event)}
					contentContainerStyle={{ justifyContent: "center" }}
				>
					<CustomScroll
						allowOuterScroll={true}
						heightRelativeToParent={this.props.scrollViewHeight - 5 + "px"}
						minScrollHandleHeight={15}
					>
						{this.renderRules(0, this.props.rulesList.length)}
					</CustomScroll>
				</ScrollView>
			);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
		paddingLeft: 15,
		paddingTop: 7,
		borderTopWidth: 1,
		borderTopColor: "#0C6A7A",
		backgroundColor: "rgba(10, 95, 110,0.5)",
	},
	ruleMainContainer: { marginBottom: 3 },
	innerContainer: {
		flexDirection: "row",
		height: 25,
		paddingRight: 25,
		alignItems: "center",
		justifyContent: "space-between",
	},
	labelContainer: { width: 100, height: 25 },
	ruleContentContainer: { flex: 1, height: 25 },
	normalText: {
		fontFamily: appFont,
		fontSize: 18,
		textAlign: "left",
		textAlignVertical: "center",
		alignSelf: "center",
		height: 25,
		textShadowColor: "transparent",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 87,
		height: 27,
		borderRadius: 6,
		paddingTop: 0,
		paddingBottom: 0,
		paddingHorizontal: 3,
		textAlignVertical: "center",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	inputImageStyle: { width: 90, height: 27, marginLeft: 10, marginRight: 2 },
	nameInputStyle: { width: 175 },
	checkboxStyle: { width: 15, height: 15, marginLeft: 5, marginTop: 4 },
	sliderLabel: {
		width: 25,
		lineHeight: 30,
		fontFamily: f_sourcesansprosemibold,
		fontSize: 18,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		marginRight: -10,
		// marginLeft: -10,
	},
	toggleText: {
		fontFamily: f_sourcesansprosemibold,
		textAlign: "center",
		lineHeight: 27,
		fontSize: 18,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	toggleContainer: {
		width: 190,
		height: 50,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-end",
	},
	toggleButton: { width: 30, height: 30 },
});

export { GameRuleDetails };
