import React from "react";
import { gameBoardTheme } from "../config/defaults";
import ClassicPosition from "./ClassicPosition";
import ModernPosition from "./ModernPosition/ModernPosition";

const Position = (props) => {
	switch (props.gameTheme) {
		case gameBoardTheme.MODERN:
			return <ModernPosition {...props} />;
		default:
			return <ClassicPosition {...props} />;
	}
};

export default Position;
