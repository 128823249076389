import { combineReducers } from "redux";
import FetchDataReducer from "../../Keezers/redux/reducers/FetchDataReducer";
import StartedGameReducer from "../../Keezers/redux/reducers/KeezersReducer";
import AppReducer from "./AppReducer";
import CreateNewGameReducer from "./CreateNewGameReducer";
import DebugReducer from "./DebugReducer";
import GameDetailsDataReducer from "./GameDetailsDataReducer";
import GameListDataReducer from "./GameListDataReducer";
import GameMessageReducer from "./GameMessageReducer";
import GameRoomReducer from "./GameRoomReducer";
import GameShopReducer from "./GameShopReducer";
import GlobalChatReducer from "./GlobalChatReducer";
import ImageReducer from "./ImageReducer";
import LanguageReducer from "./LanguageReducer";
import LeaderboardReducer from "./LeaderboardReducer";
import ModalReducer from "./ModalReducer";
import NavigationMenuReducer from "./NavigationMenuReducer";
import NavigationReducer from "./NavigationReducer";
import NotificationBarReducer from "./NotificationBarReducer";
import QuestionnaireReducer from "./QuestionnaireReducer";
import ResizeReducer from "./ResizeReducer";
import SharedInputReducer from "./SharedInputReducer";
import SocketReducer from "./SocketReducer";
import SoundsReducer from "./SoundsReducer";
import TournamentReducer from "./TournamentReducer";
import UserReducer from "./UserReducer";

export default combineReducers({
	app: AppReducer,
	socket: SocketReducer,
	tab: NavigationReducer,
	views: ResizeReducer,
	currentUser: UserReducer,
	modal: ModalReducer,
	language: LanguageReducer,
	globalChat: GlobalChatReducer,
	gameListData: GameListDataReducer,
	gameDetailsData: GameDetailsDataReducer,
	createNewGame: CreateNewGameReducer,
	gameRoom: GameRoomReducer,
	startedGame: StartedGameReducer,
	gameShop: GameShopReducer,
	fetchData: FetchDataReducer,
	sounds: SoundsReducer,
	notifications: NotificationBarReducer,
	questionnaire: QuestionnaireReducer,
	navMenu: NavigationMenuReducer,
	leaderboard: LeaderboardReducer,
	debug: DebugReducer,
	gameMessage: GameMessageReducer,
	sharedInput: SharedInputReducer,
	tournaments: TournamentReducer,
	images: ImageReducer,
});
