import { Platform } from "react-native";
import { getURLParameter } from "../helpers/commonHelpers";

// export const DEBUG_ENABLED = true;
export const DEBUG_ENABLED = Platform.OS === "web" && getURLParameter("debug") == "true" ? true : false;
// export const LOGGING_ENABLED = true;
export const LOGGING_ENABLED = false;
export const sendAckAndProcessed = false; //TODO: enable this, when server is ready to receive it
export const playAgainstComputerForChips = false;
export const useMiniShopButton = false;
export const hideShop = false;
export const hideChat = false;
export const enableReconnectButton = true;
export const enableAllFeatures = false;
export const panelMenuEnabled = true;
export const enablePanelSwap = true;
export const allowPlayerInfo = getURLParameter("playerinfo") == "true" ? true : false;
export const enableHiddenFeatures = getURLParameter("hiddenFeatures") == "true" ? true : false;
export const useClassicTheme = Platform.OS === "web" && getURLParameter("useClassicTheme") == "false" ? false : true;
