export const surveys = {
	survey1: ["questionKey_2", "questionKey_1", "questionKey_5", "questionKey_7", "questionKey_9"],
	survey2: ["questionKey_5", "questionKey_7", "questionKey_10"],
	survey3: [
		"questionKey_3",
		"questionKey_10",
		"questionKey_9",
		"questionKey_1",
		// "questionKey_2",
		// "questionKey_4",
		// "questionKey_5",
	],
	surveyAll: [
		"questionKey_1",
		"questionKey_2",
		"questionKey_3",
		"questionKey_11",
		"questionKey_12",
		// "questionKey_9",
		// "questionKey_10",
	],
};
