import React, { Component } from "react";
import CustomScroll from "react-custom-scroll";
import { Platform, SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { buttonColor, isWebOrTablet, RESOLUTION, TOURNAMENT_TEAM_TYPE } from "../../../config/defaults";
import { gameDimensions } from "../../../config/dimensions";
import { saveNewTeam } from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { ColoredButton, HeaderText } from "../../common";
import { handleError } from "../../ErrorHandler";
import PinCodeInput from "./PinCodeInput";
import RankingSlider from "./RankingSlider";
import TeamNameInput from "./TeamNameInput";
import TeamTypeToggle from "./TeamTypeToggle";

class CreateTeamScreen extends Component {
	constructor(props) {
		super(props);
		this.state = { scrollViewHeight: 60 };
	}
	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("CreateTeamScreen");
	}
	//#endregion

	//#region events
	measureScrollView(event) {
		try {
			if (Platform.OS !== "web") event.persist();
			const { height } = event.nativeEvent.layout;
			this.setState({ scrollViewHeight: height });
		} catch (error) {
			handleError(error);
		}
	}

	onSavePress() {
		try {
			this.props.saveNewTeam();
		} catch (err) {
			handleError(err);
		}
	}
	//#endregion

	//#region render methods
	renderSaveButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.buttonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onSavePress.bind(this)}
					textContent={this.props.lang.save}
					accessibilityLabel={this.props.lang.save}
					color={buttonColor.GREEN}
					disabled={false}
				/>
			</View>
		);
	}

	renderSettings() {
		if (Platform.OS === "web") {
			return (
				<ScrollView
					nestedScrollEnabled={true}
					showsVerticalScrollIndicator={false}
					style={[styles.detailsContainer, { minHeight: 60 }]}
					onLayout={(event) => this.measureScrollView(event)}
				>
					<CustomScroll
						allowOuterScroll={true}
						heightRelativeToParent={this.state.scrollViewHeight - 5 + "px"}
						minScrollHandleHeight={60}
						className="create-team-details"
					>
						<TeamNameInput />
						<TeamTypeToggle />
						{this.props.newTeam.teamType === TOURNAMENT_TEAM_TYPE.PRIVATE && <PinCodeInput />}
						{this.props.newTeam.teamType === TOURNAMENT_TEAM_TYPE.LEVEL && <RankingSlider />}
					</CustomScroll>
				</ScrollView>
			);
		}
		return (
			<ScrollView style={styles.detailsContainer} contentContainerStyle={{ minHeight: 130, paddingVertical: 5 }}>
				<TeamNameInput />
				<TeamTypeToggle />
				{this.props.newTeam.teamType === TOURNAMENT_TEAM_TYPE.PRIVATE && <PinCodeInput />}
				{this.props.newTeam.teamType === TOURNAMENT_TEAM_TYPE.LEVEL && <RankingSlider />}
			</ScrollView>
		);
	}

	render() {
		return (
			<SafeAreaView style={{ flex: 1 }}>
				<View
					style={[
						styles.container,
						isWebOrTablet && {
							marginTop: this.props.resolution === RESOLUTION.HIGH ? 60 : 50,
						},
					]}
				>
					<HeaderText textContent={"Create team"} style={{ height: gameDimensions.boxHeaderHeight }} />
					{this.renderSettings()}
					{this.renderSaveButton()}
				</View>
			</SafeAreaView>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		margin: 3,
		paddingLeft: 60,
		paddingRight: 60,
		paddingBottom: 10,
	},
	detailsContainer: {
		flex: 1,
		marginTop: 5,
		backgroundColor: "rgba(10, 95, 110,0.5)",
		borderWidth: 2,
		borderColor: "#0C6A7A",
		borderRadius: 6,
		shadowColor: "#383838",
		shadowOffset: {
			width: 1,
			height: 1,
		},
		shadowRadius: 5,
		shadowOpacity: 1,
		elevation: 5,
		overflow: "hidden",
	},
	buttonContainer: {
		marginTop: 20,
		justifyContent: "flex-end",
		alignItems: "flex-end",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		newTeam: state.tournaments.newTeam,
	};
};

const mapDispatchToProps = { saveNewTeam };

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeamScreen);
