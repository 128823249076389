import React, { Component } from "react";
import { Animated, Image, Platform, View } from "react-native";
import { connect } from "react-redux";
import { Hoverable } from "../../components/common";
import { activeTurnEffectTime } from "../../config/defaults";
import { panelIcons } from "../config/images";

class PanelToAct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			actingPlayerAnim: new Animated.Value(0),
		};
		this.actingPlayerInterval = null;
		this.displayed = false;
	}

	//#region lifecycle methods
	componentDidMount() {
		if (this.props.slotToAct == this.props.slotID && this.actingPlayerInterval === null) {
			this.state.actingPlayerAnim.stopAnimation();
			this.actingPlayerInterval = setInterval(() => {
				if (this.displayed) {
					Animated.timing(this.state.actingPlayerAnim, {
						toValue: 0,
						duration: 5,
						useNativeDriver: Platform.OS !== "web",
					}).start();
					this.displayed = false;
				} else {
					Animated.timing(this.state.actingPlayerAnim, {
						toValue: 1,
						duration: 5,
						useNativeDriver: Platform.OS !== "web",
					}).start();
					this.displayed = true;
				}
			}, activeTurnEffectTime);
		}
	}

	shouldComponentUpdate(nextProps) {
		if (this.props.isGameEnded == false && nextProps.isGameEnded == true) {
			clearInterval(this.actingPlayerInterval);
			this.actingPlayerInterval = null;
			this.state.actingPlayerAnim.stopAnimation();
			if (this.props.slotToAct == this.props.slotID) {
				this.state.actingPlayerAnim.setValue(1);
				this.displayed = true;
			} else {
				this.state.actingPlayerAnim.setValue(0);
				this.displayed = false;
			}
		}
		return true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isBackground == true && this.props.isBackground == false) {
			if (this.props.slotToAct == this.props.slotID) {
				this.state.actingPlayerAnim.stopAnimation();
				this.actingPlayerInterval = setInterval(() => {
					if (this.displayed) {
						Animated.timing(this.state.actingPlayerAnim, {
							toValue: 0,
							duration: 5,
							useNativeDriver: Platform.OS !== "web",
						}).start();
						this.displayed = false;
					} else {
						Animated.timing(this.state.actingPlayerAnim, {
							toValue: 1,
							duration: 5,
							useNativeDriver: Platform.OS !== "web",
						}).start();
						this.displayed = true;
					}
				}, activeTurnEffectTime);
			}
		}

		if (
			prevProps.slotToAct !== this.props.slotToAct &&
			this.props.slotToAct == this.props.slotID &&
			this.props.isBackground == false &&
			this.actingPlayerInterval === null
		) {
			this.state.actingPlayerAnim.stopAnimation();
			this.actingPlayerInterval = setInterval(() => {
				if (this.displayed) {
					Animated.timing(this.state.actingPlayerAnim, {
						toValue: 0,
						duration: 5,
						useNativeDriver: Platform.OS !== "web",
					}).start();
					this.displayed = false;
				} else {
					Animated.timing(this.state.actingPlayerAnim, {
						toValue: 1,
						duration: 5,
						useNativeDriver: Platform.OS !== "web",
					}).start();
					this.displayed = true;
				}
			}, activeTurnEffectTime);
		}

		if (
			prevProps.slotToAct !== this.props.slotToAct &&
			this.props.slotID != this.props.slotToAct &&
			this.actingPlayerInterval !== null
		) {
			clearInterval(this.actingPlayerInterval);
			this.actingPlayerInterval = null;
			this.state.actingPlayerAnim.stopAnimation();
			this.state.actingPlayerAnim.setValue(0);
			this.displayed = false;
		}

		if (prevProps.isBackground == false && this.props.isBackground == true) {
			clearInterval(this.actingPlayerInterval);
			this.actingPlayerInterval = null;
			this.state.actingPlayerAnim.stopAnimation();
			this.state.actingPlayerAnim.setValue(0);
			this.displayed = false;
		}
	}

	componentWillUnmount() {
		if (this.actingPlayerInterval) clearInterval(this.actingPlayerInterval);
	}
	//#endregion

	//#region render methods
	render() {
		return (
			<Hoverable
				key="thinkingHover"
				tooltipText={
					this.props.slotToAct == this.props.slotID
						? this.props.lang.tooltips.thinkingOn
						: this.props.lang.tooltips.thinkingOff
				}
				tooltipTextPos={this.props.tooltipTextPos}
				style={[this.props.style, this.props.toActPos, this.props.hoverIndex && { zIndex: this.props.hoverIndex }]}
			>
				<View key="toAct" style={this.props.style}>
					<Animated.Image
						key="active"
						resizeMode="contain"
						source={panelIcons.thinking}
						style={{
							width: this.props.width, //11,
							height: this.props.height, //11,
							position: "absolute",
							opacity: this.state.actingPlayerAnim,
							zIndex: 1,
						}}
					/>
					<Image
						key="inactive"
						resizeMode="contain"
						source={panelIcons.thinking_disabled}
						style={{
							position: "absolute",
							width: this.props.width, //11,
							height: this.props.height, //11,
							zIndex: 0,
						}}
					/>
				</View>
			</Hoverable>
		);
	}
	//#endregion
}

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		isBackground: state.app.isBackground,
		slotToAct: state.startedGame.slotToAct,
		isGameEnded: state.startedGame.gameEnd.isGameEnded,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PanelToAct);
