import React, { Component } from "react";
import { Dimensions, FlatList, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { isWebOrTablet } from "../../config/defaults";
import * as helpers from "../../helpers/commonHelpers";
import { deselectTeam, openTournamentStat, selectTeam } from "../../redux/actions";
import { AppTouchableOpacity, TableHeader, TableHeaderCell, TableRow, TableRowCell } from "../common";
import { handleError } from "../ErrorHandler";

class TournamentDetailsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			maxVisibleRows: 1,
			expandedTeamID: -1,
		};
		this.columns = ["teamName", "teamType", "teamMembers"];
		this.teamMemberColumns = ["country", "ranking", "username"];
	}

	//#region events
	tableRendered(event) {
		try {
			const tableHeight = event.nativeEvent.layout.height;
			const maxVisibleRows = isWebOrTablet ? Math.floor(tableHeight / 31) : Math.floor(tableHeight / 20);
			this.setState({ maxVisibleRows });
		} catch (error) {
			handleError(error);
		}
	}

	onRowSelect(teamID) {
		try {
			var newTeamID = teamID;
			if (this.state.expandedTeamID == teamID) newTeamID = -1;
			this.props.selectTeam(teamID);
			this.setState({ expandedTeamID: newTeamID });
		} catch (error) {
			handleError(error);
		}
	}

	onRowDoubleTap(teamID) {
		try {
			this.props.openTournamentStat();
		} catch (error) {
			handleError(error);
		}
	}

	onTableBackPress() {
		this.props.deselectTeam();
		this.setState({ expandedTeamID: -1 });
	}
	//#endregion

	//#region render methods
	renderMembersRow(item, index) {
		const rowId = "row_" + item.userID;
		let cells = [];
		let isCurrentUser = item.userID == this.props.currentUserID;

		this.teamMemberColumns.forEach((key) => {
			if (typeof item[key] !== "undefined") {
				let cellId = key + "_" + item.userID;
				let cellKey = "cell_" + cellId;

				var _content = item[key].toLocaleString(),
					_cellWidth = "100%",
					_cellType = "img",
					imgDim = {},
					laurelDim = {},
					_isVip = false,
					showToolTip = false,
					tooltipText = "";
				switch (key) {
					case "country":
						_cellWidth = 80;
						var countryURL = {};
						if (typeof item[key] === "string" && item[key] != "")
							countryURL = {
								uri: helpers.getCountryFlagImageURL(item[key].toLowerCase(), 40),
							};
						imgDim = { width: 30, height: 30 };
						showToolTip = Platform.OS === "web";
						const countryCode = typeof item[key] === "string" ? item[key].toLowerCase() : undefined;
						tooltipText =
							countryCode && typeof this.props.lang.countryList[countryCode] === "string"
								? this.props.lang.countryList[countryCode]
								: undefined;
						break;
					case "ranking":
						_cellWidth = 80;
						var rankingURL = {};
						_isVip = item.isVip;
						var rankDim = 30;
						const winDim = Platform.OS === "web" ? Dimensions.get("window") : Dimensions.get("screen");
						if (winDim.scale > 1) {
							if (winDim.scale < 2.5) {
								rankDim = 50;
							} else {
								rankDim = 150;
							}
						}
						rankingURL = helpers.getRankingImageURL(item[key], rankDim);
						imgDim = { width: 30, height: 30 };
						laurelDim = { width: 54, height: 54 };
						break;
					case "username":
						_cellType = "text";
						break;
				}
				cells.push(
					<TableRowCell
						lang={this.props.lang}
						isSelected={isCurrentUser}
						backgroundColor={"transparent"}
						selectedBackgroundColor={"#F6A022"}
						style={[styles.cellTextStyle]}
						cellWidth={_cellWidth}
						key={cellKey}
						cellId={cellId}
						content={_content}
						cellType={_cellType}
						imgURL={countryURL || rankingURL}
						isVip={_isVip}
						laurelDim={laurelDim}
						imgDim={imgDim}
						cellContainerStyle={[{ height: 40 }, key == "username" && { flex: 1 }]}
						showToolTip={showToolTip}
						tooltipText={tooltipText}
					/>
				);
			}
		});

		return (
			<TableRow disabled={true} rowId={rowId}>
				{cells}
			</TableRow>
		);
	}

	renderMembersTable(members) {
		return (
			<FlatList
				accessibilityLabel={"Members of the selected team"}
				keyExtractor={(member) => member.userID.toString()}
				data={members}
				extraData={members}
				renderItem={({ item, index }) => this.renderMembersRow(item, index)}
				getItemLayout={(data, index) => ({
					length: 42,
					offset: 42 * index,
					index,
				})}
			/>
		);
	}

	renderRow(item, index) {
		const rowId = "row_" + item.teamID;
		let cells = [];
		let _isSelected = item.teamID == this.props.selectedTeamID;
		let _isExpanded = item.teamID == this.state.expandedTeamID;

		this.columns.forEach((key) => {
			if (typeof item[key] !== "undefined") {
				let cellId = key + "_" + item.teamID;
				let cellKey = "cell_" + cellId;

				var _content = item[key],
					_cellWidth = "100%",
					_cellType = "text",
					_withDropDown = false;
				switch (key) {
					case "teamName":
						_cellType = "mixed";
						_withDropDown = true;
						break;
					case "teamType":
						_cellWidth = 120;
						break;
					case "teamMembers":
						const occupied = Array.isArray(item.teamMembers) ? item.teamMembers.length : 0;
						const freePlaces = item.maxMembersCount - occupied;
						_content = freePlaces.toLocaleString() + " / " + item.maxMembersCount.toLocaleString();
						_cellWidth = 150;
						break;
				}
				cells.push(
					<TableRowCell
						lang={this.props.lang}
						isSelected={_isSelected}
						backgroundColor={"transparent"}
						selectedBackgroundColor={"#F6A022"}
						style={[
							styles.cellTextStyle,
							Platform.OS !== "web" && { flex: 1 },
							key == "teamType" && { textAlign: "left" },
						]}
						cellWidth={_cellWidth}
						key={cellKey}
						cellId={cellId}
						content={_content}
						cellType={_cellType}
						cellContainerStyle={[
							{ height: 40 },
							key == "teamName" && { flex: 1, flexDirection: "row" },
							key == "teamMembers" && { borderRightWidth: 0 },
						]}
						withDropDown={_withDropDown}
						arrowStyle={_isExpanded ? styles.arrowIcon : [styles.arrowIcon, styles.mirror]}
						arrowContainer={styles.arrowContainer}
					/>
				);
			}
		});

		const rowSelectFunc = () => this.onRowSelect(item.teamID);
		const rowDoubleTapFunc = () => this.onRowDoubleTap();
		return (
			<>
				<TableRow rowId={rowId} onPress={rowSelectFunc} onDoubleTap={rowDoubleTapFunc}>
					{cells}
				</TableRow>
				{_isExpanded && (
					<View
						style={{
							flex: 1,
							flexDirection: "row",
							minHeight: 40,
						}}
					>
						<View
							style={{
								width: 50,
								borderBottomWidth: 1,
								borderColor: "#22C1E4",
							}}
						/>
						<View style={{ flex: 1, borderLeftWidth: 1, borderColor: "#22C1E4" }}>
							{this.renderMembersTable(item.teamMembers)}
						</View>
						<View
							style={{
								width: 50,
								borderBottomWidth: 1,
								borderColor: "#22C1E4",
							}}
						/>
					</View>
				)}
			</>
		);
	}

	renderHeader() {
		const headerCells = [];
		this.columns.forEach((col) => {
			let _cellWidth = "100%";
			let headerKey = col + "_Header";
			switch (col) {
				case "teamName":
					break;
				case "teamType":
					_cellWidth = 120;
					break;
				case "teamMembers":
					_cellWidth = 150;
					break;
			}

			headerCells.push(
				<TableHeaderCell
					key={headerKey}
					cellContent={this.props.lang.tournamentDetailsColumns[col] || ""}
					containerStyle={[styles.headerContainer, { width: _cellWidth }, col == "teamName" && { flex: 1 }]}
					textStyle={[styles.headerText, { width: _cellWidth }, col == "teamName" && { flex: 1 }]}
					isSortable={false}
					isSelected={false}
					isAscending={false}
				/>
			);
		});

		return <TableHeader>{headerCells}</TableHeader>;
	}

	renderTable() {
		//TODO: if (this.props.selectedGame.loading) {
		//   return <Spinner size="large" />;
		// }

		return (
			<AppTouchableOpacity
				activeOpacity={1}
				style={{ flex: 1, paddingBottom: 2 }}
				onPress={this.onTableBackPress.bind(this)}
				// disabled={this.props.selectedTeamID == -1}
				disabled={true}
				accessibilityState={{
					disabled: this.props.selectedTeamID == -1,
				}}
				accessibilityLabel={"deselect team"}
			>
				<FlatList
					accessibilityLabel={"Tournaments details"}
					keyExtractor={(team) => team.teamID.toString()}
					data={this.props.selectedTournament.teams}
					extraData={this.props.selectedTournament.teams}
					renderItem={({ item, index }) => this.renderRow(item, index)}
					getItemLayout={(data, index) => ({
						length: 42,
						offset: 42 * index,
						index,
					})}
					onLayout={(event) => this.tableRendered(event)}
				/>
			</AppTouchableOpacity>
		);
	}

	render() {
		return (
			<View style={styles.tableStyle}>
				{this.renderHeader()}
				{/* {this.renderTable()} */}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	tableStyle: { flex: 1 },
	cellTextStyle: {
		textAlign: "center",
		color: "#FFFFFF",
		lineHeight: 40,
		height: 40,
		fontSize: 20,
	},
	headerContainer: {
		height: isWebOrTablet ? 40 : 20,
		justifyContent: "center",
		backgroundColor: "transparent",
	},
	headerText: {
		fontSize: isWebOrTablet ? 20 : 16,
		height: isWebOrTablet ? 40 : 20,
		lineHeight: isWebOrTablet ? 40 : 20,
		textAlign: "center",
		backgroundColor: "transparent",
	},
	arrowContainer: {
		height: "100%",
		justifyContent: "center",
		paddingRight: 10,
	},
	arrowIcon: {
		width: 15,
		height: 10,
		marginLeft: 5,
	},
	mirror: {
		transform: [{ rotate: "180deg" }],
	},
});

const mapStateToProps = (state) => {
	var found = state.tournaments.tournamentsList.find(
		(tour) => tour.tournamentID === state.tournaments.selectedTournamentID
	);
	if (!found) {
		found = { teams: [] };
	}
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		selectedTournamentID: state.tournaments.selectedTournamentID,
		selectedTeamID: state.tournaments.selectedTeamID,
		selectedTournament: found,
	};
};

const mapDispatchToProps = { deselectTeam, openTournamentStat, selectTeam };

export default connect(mapStateToProps, mapDispatchToProps)(TournamentDetailsTable);
