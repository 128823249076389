import { cloneDeep } from "lodash";
import { GO_BACK_TO_MAIN } from "../actions/actionTypes";
import { ADD_LOG, CLOSE_DEBUG_MENU, CLOSE_LOGGER, OPEN_DEBUG_MENU, OPEN_LOGGER } from "../actionTypes";

const initialState = {
	isDebugMenuOpened: false,
	isLoggerOpened: false,
	messages: [],
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case OPEN_LOGGER:
			return { ...state, isLoggerOpened: true };
		case CLOSE_LOGGER:
			return { ...state, isLoggerOpened: false };
		case ADD_LOG:
			if (typeof payload === "undefined" || (payload.title == undefined && payload.content == undefined)) return state;
			var newMessages = cloneDeep(state.messages);
			var _title, _content;
			switch (typeof payload.title) {
				case "object":
					_title = JSON.stringify(payload.title);
					break;
				case "undefined":
				case "function":
					_title = "";
					break;
				default:
					_title = payload.title.toString();
					break;
			}
			switch (typeof payload.content) {
				case "object":
					_content = JSON.stringify(payload.content);
					break;
				case "undefined":
				case "function":
					_content = "";
					break;
				default:
					_content = payload.content.toString();
					break;
			}

			newMessages.push({ title: _title, content: _content });
			if (newMessages.length > 100) {
				newMessages.shift();
			}
			return { ...state, messages: newMessages };

		case OPEN_DEBUG_MENU:
			return { ...state, isDebugMenuOpened: true };
		case CLOSE_DEBUG_MENU:
		case GO_BACK_TO_MAIN:
			return {
				...state,
				isDebugMenuOpened: initialState.isDebugMenuOpened,
				isLoggerOpened: initialState.isLoggerOpened,
			};
		default:
			return state;
	}
};
