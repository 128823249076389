import React, { Component } from "react";
import { Dimensions, Image, ImageBackground, StyleSheet, Text, View } from "react-native";
import ParsedText from "react-native-parsed-text";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprosemibold,
	processState,
	RESOLUTION,
	tabType,
} from "../../../../../../config/defaults";
import { defaultCardsetDimensions, gameDimensions } from "../../../../../../config/dimensions";
import { NEXT_ICON, PREVIOUS_ICON } from "../../../../../../config/images";
import {
	closeDefaultCardset,
	goBackToMain,
	openCardsetsShop,
	openTab,
	selectDefaultCardset,
	startPlay,
	updateDefaultCardset,
} from "../../../../../../redux/actions";
import Analytics from "../../../../../Analytics/Analytics";
import { AppTouchableOpacity, ColoredButton, NavigationHeader, Spinner } from "../../../../../common";
import { handleError } from "../../../../../ErrorHandler";
import CardsetsShop from "../../../../../GameShop/CardsetsShop";

class DefaultCardset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		Analytics.logScreenView("DefaultCardsetPreferences");
	}
	//#endregion

	//#region events
	onUpdatePress() {
		try {
			const selected = this.props.availableCardsets.find((e) => e.selected);
			if (selected !== undefined) {
				this.props.updateDefaultCardset(selected.name);
			}
		} catch (error) {
			handleError(error);
		}
	}

	onLinkPress() {
		try {
			this.props.goBackToMain();
			this.props.startPlay();
			this.props.openTab(tabType.SHOP);
			this.props.openCardsetsShop();
		} catch (error) {
			handleError(error);
		}
	}

	toggleCardsetList(prev = true) {
		try {
			if (this.props.availableCardsets.length > 0) {
				const curSelInd = this.props.availableCardsets.findIndex((e) => e.selected);
				if (curSelInd >= 0) {
					var nextSelInd = curSelInd;
					if (prev) {
						nextSelInd = curSelInd - 1;
						nextSelInd = nextSelInd < 0 ? this.props.availableCardsets.length - 1 : nextSelInd;
					} else {
						nextSelInd = curSelInd + 1;
						nextSelInd = nextSelInd == this.props.availableCardsets.length ? 0 : nextSelInd;
					}
					this.props.selectDefaultCardset(nextSelInd);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.updateDefaultCardsetState != processState.STARTED}
				backAction={this.props.closeDefaultCardset}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUpdateButton() {
		try {
			const selected = this.props.availableCardsets.find((e) => e.selected);
			var buttonWidth = gameDimensions.gameButtonsWidth,
				buttonHeight = 60,
				_buttonTextStyle = {};
			if (this.props.resolution === RESOLUTION.HIGH) {
				buttonWidth *= 1.5;
				buttonHeight *= 1.5;
				_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
			}
			return (
				<View style={styles.buttonContainer}>
					<ColoredButton
						width={buttonWidth}
						height={buttonHeight}
						onPressFunc={this.onUpdatePress.bind(this)}
						textContent={this.props.lang.update}
						accessibilityLabel={this.props.lang.update}
						color={buttonColor.GREEN}
						disabled={selected !== undefined && selected.cardset == this.props.defaultCardset}
						additionalTextStyle={_buttonTextStyle}
					/>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderCardsetInfo() {
		try {
			const regex = /_/gi;
			var main_text = this.props.lang.defaultCardset.info;
			var main_text_i = main_text.indexOf("_");
			var main_text_j = main_text.lastIndexOf("_");
			if (main_text_i != -1 && main_text_j != -1) var linkMainText = main_text.substring(main_text_i, ++main_text_j);
			if (linkMainText != undefined) var mainTextLink = linkMainText.replace(regex, "");
			var _infoStyle = [styles.dataText, styles.infoText],
				_labelStyle = [styles.dataTitle, styles.dataText],
				_dataInnerContainer = styles.dataInnerContainer;
			if (this.props.resolution === RESOLUTION.HIGH) {
				_infoStyle = [styles.dataText, styles.infoText, { fontSize: 24, lineHeight: 30 }];
				_labelStyle = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
				_dataInnerContainer = [styles.dataInnerContainer, { height: 60, paddingTop: 5 }];
			}
			return (
				<View key="info" style={styles.columnContainer}>
					<View style={_dataInnerContainer}>
						<Text allowFontScaling={false} style={_labelStyle}>
							{this.props.lang.defaultCardset.title}
						</Text>
					</View>
					<View style={styles.detailsContainer}>
						{mainTextLink != "" && (
							<ParsedText
								style={_infoStyle}
								parse={[
									{
										pattern: new RegExp("_" + mainTextLink + "_"),
										style: [_infoStyle, styles.linkDecor],
										onPress: this.onLinkPress.bind(this),
										renderText: () => mainTextLink,
									},
								]}
							>
								{main_text}
							</ParsedText>
						)}
						{mainTextLink == "" && (
							<Text allowFontScaling={false} style={_infoStyle}>
								{main_text}
							</Text>
						)}
					</View>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderCardsetDetails() {
		try {
			var selIndex = this.props.availableCardsets.findIndex((e) => e.selected);
			var selected = this.props.availableCardsets[selIndex];
			var _labelStyle = [styles.dataTitle, styles.dataText],
				_dataInnerContainer = styles.dataInnerContainer,
				_counterText = [styles.dataText, styles.counterText],
				_counterContainer = styles.counterContainer,
				_toggleButton = styles.toggleButton,
				_cardsetBackground = styles.cardsetBackground;
			if (this.props.resolution === RESOLUTION.HIGH) {
				_labelStyle = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
				_dataInnerContainer = [styles.dataInnerContainer, { height: 60, paddingTop: 5 }];
				_counterText = [styles.dataText, styles.counterText, { fontSize: 24 }];
				_counterContainer = [styles.counterContainer, { height: 45 }];
				_toggleButton = [styles.toggleButton, { width: 65, height: 65 }];
				_cardsetBackground = [styles.cardsetBackground, { width: 210, height: 255 }];
			}

			return (
				<View key="details" style={styles.columnContainer}>
					<View style={_dataInnerContainer}>
						<Text allowFontScaling={false} style={_labelStyle}>
							{selected.text}
						</Text>
					</View>
					<View style={styles.detailsContainer}>
						<View style={_counterContainer}>
							<Text allowFontScaling={false} style={_counterText}>
								{selIndex + 1}/{this.props.availableCardsets.length}
							</Text>
						</View>
						<View
							style={[
								styles.toggleContainer,
								{
									marginTop: this.props.resolution === RESOLUTION.LOW ? -25 : 0,
								},
							]}
						>
							<View style={styles.toggleInnerContainer}>
								<AppTouchableOpacity
									disabled={selected == undefined}
									style={[
										_toggleButton,
										{
											alignSelf: this.props.resolution === RESOLUTION.LOW ? "flex-start" : "center",
										},
									]}
									onPress={() => this.toggleCardsetList(true)}
								>
									<Image source={PREVIOUS_ICON} style={_toggleButton} />
								</AppTouchableOpacity>
							</View>
							<View style={styles.toggleInnerContainer}>
								<ImageBackground
									source={{ uri: selected.cardsetImgBackURL }}
									resizeMode="stretch"
									style={_cardsetBackground}
								>
									<Image source={{ uri: selected.cardsetImgURL }} resizeMode="cover" style={[styles.cardsetImage]} />
								</ImageBackground>
							</View>
							<View style={styles.toggleInnerContainer}>
								<AppTouchableOpacity
									disabled={selected == undefined}
									style={[
										_toggleButton,
										{
											alignSelf: this.props.resolution === RESOLUTION.LOW ? "flex-end" : "center",
										},
									]}
									onPress={() => this.toggleCardsetList(false)}
								>
									<Image source={NEXT_ICON} style={_toggleButton} />
								</AppTouchableOpacity>
							</View>
						</View>
					</View>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderContent() {
		return (
			<View
				style={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				<View style={styles.cardsetContainer}>
					{this.renderCardsetInfo()}
					{this.renderCardsetDetails()}
				</View>
				{this.renderUpdateButton()}
			</View>
		);
	}

	render() {
		if (this.props.updateDefaultCardsetState == processState.STARTED)
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);

		if (this.props.cardsetsShopOpened) {
			return <CardsetsShop />;
		} else {
			return (
				<ImageBackground source={this.props.SETTINGS_BACK.url} style={styles.backgroundContainer}>
					<View style={styles.container}>{this.renderContent()}</View>
					{this.renderHeader()}
				</ImageBackground>
			);
		}
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - Math.round(50 - 50 / windowDim.scale);
			if (defaultCardsetDimensions.width > availableWidth || defaultCardsetDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / defaultCardsetDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / defaultCardsetDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1 },
	container: {
		flex: 1,
		marginTop: 55,
		justifyContent: "center",
	},
	contentContainer: {
		flex: 1,
		marginHorizontal: 10,
	},
	buttonContainer: {
		// width: gameDimensions.gameButtonsWidth,
		// height: 60,
		alignSelf: "flex-end",
	},
	dataInnerContainer: {
		height: 40,
		justifyContent: "center",
	},
	dataTitle: {
		flex: 1,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataText: { fontFamily: f_sourcesansprosemibold, fontSize: 20 },
	infoText: {
		width: "100%",
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
		lineHeight: 26,
		marginTop: 10,
		marginBottom: 20,
		fontSize: 18,
	},
	linkDecor: { color: "#00CDD8" },
	cardsetContainer: {
		flex: 1,
		height: 280,
		flexDirection: "row",
	},
	columnContainer: { flex: 2, height: 280 },
	detailsContainer: {
		flex: 1,
		marginBottom: 5,
		justifyContent: "center",
	},
	counterContainer: { width: "100%", height: 30 },
	counterText: {
		textAlign: "center",
		lineHeight: 30,
		fontSize: 18,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	toggleContainer: { flex: 1, height: 180, flexDirection: "row" },
	toggleInnerContainer: {
		flex: 3,
		height: 180,
		justifyContent: "center",
	},
	toggleButton: {
		width: 45,
		height: 45,
		// alignSelf: "center",
	},
	cardsetBackground: {
		width: 140,
		height: 170,
		justifyContent: "center",
		alignSelf: "center",
		position: "absolute",
		// zIndex: -1,
	},
	cardsetImage: {
		width: 160,
		height: 110,
		alignSelf: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		cardsetsShopOpened: state.gameShop.cardsetsShopOpened,
		updateDefaultCardsetState: state.app.updateDefaultCardsetState,
		defaultCardset: state.currentUser.preferences.defaultCardset,
		availableCardsets: state.currentUser.preferences.availableCardsets,
		cardsets: state.app.welcome.webResources.cardsets,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeDefaultCardset,
	goBackToMain,
	openCardsetsShop,
	openTab,
	startPlay,
	selectDefaultCardset,
	updateDefaultCardset,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultCardset);
