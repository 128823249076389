import { cloneDeep } from "lodash";
import React, { Component } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { connect } from "react-redux";
import { appFont, gameDetailsTableKey, isTablet } from "../../config/defaults";
import { TOGGLE_ICON } from "../../config/images";
import { updateNewGamesPlayers } from "../../redux/actions";
import { store } from "../../redux/store";
import { handleError } from "../ErrorHandler";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

class PlayerTypeToggleCell extends Component {
	constructor(props) {
		super(props);
		const _dataSource = this.getPlayerDropDownDataSource();
		this.state = {
			dataSource: _dataSource,
		};
	}
	//#region lifecycle methods
	componentDidUpdate(prevProps, prevState) {
		if (this.props.tableKey == gameDetailsTableKey.newGame) {
			if (this.props.players !== prevProps.players) {
				const _dataSource = this.getPlayerDropDownDataSource(true);
				this.setState({ dataSource: _dataSource });
			}
		}
	}
	//#endregion

	//#region events
	toggleDataSource(prev = true) {
		try {
			const curSelInd = this.state.dataSource.findIndex((e) => e.selected);
			if (curSelInd >= 0) {
				var nextSelInd = curSelInd;
				if (prev) {
					nextSelInd = curSelInd - 1;
					nextSelInd = nextSelInd < 0 ? this.state.dataSource.length - 1 : nextSelInd;
				} else {
					nextSelInd = curSelInd + 1;
					nextSelInd = nextSelInd == this.state.dataSource.length ? 0 : nextSelInd;
				}
				var newDataSource = cloneDeep(this.state.dataSource);
				newDataSource.forEach((el) => (el.selected = false));
				newDataSource[nextSelInd].selected = true;
				this.setState({ dataSource: newDataSource });

				if (this.props.tableKey === gameDetailsTableKey.newGame) {
					this.props.updateNewGamesPlayers(
						this.props.slotID,
						newDataSource[nextSelInd],
						store.getState().createNewGame.publishGameJSON.playerCnt
					);
				}
			}
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion

	//#region render methods
	render() {
		var selected = this.state.dataSource.find((data) => data.selected == true);
		const _players =
			this.props.tableKey == gameDetailsTableKey.newGame ? this.props.players : this.props.gameRoomPlayers;
		const slotPlayer = _players[this.props.slotID];
		return (
			<View style={[styles.mainContainer, this.props.style]}>
				{this.state.dataSource.length > 1 && (
					<AppTouchableOpacity
						style={[styles.toggleButton, { opacity: 1 }]}
						onPress={() => this.toggleDataSource(true)}
						accessibilityLabel={"previous slot type"}
					>
						<Image source={TOGGLE_ICON} style={{ width: 40, height: 40 }} resizeMode="stretch" />
					</AppTouchableOpacity>
				)}
				<Text
					allowFontScaling={false}
					style={[styles.normalText, styles.selectedTextContainer, { color: this.props.textColor }]}
				>
					{selected == undefined ? slotPlayer.username : selected.label}
				</Text>
				{this.state.dataSource.length > 1 && (
					<AppTouchableOpacity
						style={[styles.toggleButton, { opacity: 1 }]}
						onPress={() => this.toggleDataSource(false)}
						accessibilityLabel={"next slot type"}
					>
						<Image
							source={TOGGLE_ICON}
							style={{
								width: 40,
								height: 40,
								transform: [{ rotateY: "-180deg" }],
							}}
							resizeMode="stretch"
						/>
					</AppTouchableOpacity>
				)}
			</View>
		);
	}
	//#endregion

	//#region helpers
	getPlayerDropDownDataSource() {
		try {
			const _players =
				this.props.tableKey == gameDetailsTableKey.newGame ? this.props.players : this.props.gameRoomPlayers;
			const slotPlayer = _players[this.props.slotID];
			var dataSource = [
				{
					slotType: "HUMAN",
					enabled: true,
					label: this.props.lang.openSlot,
					state: "OPEN",
					botID: 0,
					strength: "",
					warningMsg: "",
					selected: slotPlayer.username == this.props.lang.openSlot,
				},
			];

			if (
				this.props.tableKey == gameDetailsTableKey.newGame &&
				Array.isArray(this.props.availableBots) &&
				this.props.availableBots.length > 0
			) {
				this.props.availableBots.forEach((bot, ind) => {
					if (bot.type) {
						dataSource.push({
							type: bot.type,
							slotType: "COMPUTER",
							enabled: true,
							label: bot.name,
							state: "OCCUPIED",
							botID: ind,
							strength: bot.strength,
							warningMsg: "",
							selected: slotPlayer.ranking == bot.strength,
							botImage: bot.image,
						});
					}
				});
			}
			return dataSource;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: "row",
		justifyContent: "center",
	},
	toggleButton: {
		width: 40,
		height: 40,
		marginTop: "auto",
		marginBottom: "auto",
	},
	normalText: {
		alignSelf: "center",
		fontFamily: appFont,
		fontSize: isTablet ? 20 : 20,
		lineHeight: 40,
		flex: 1,
		textAlign: "center",
	},
	selectedTextContainer: {
		marginRight: 5,
		paddingTop: 0,
		paddingLeft: 3,
		paddingRight: 3,
		overflow: "hidden",
		height: 40,
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		availableBots: state.createNewGame.availableBots,
		players: state.createNewGame.players,
		gameRoomPlayers: state.gameRoom.gameRoomPlayers,
	};
};

const mapDispatchToProps = { updateNewGamesPlayers };

const PlayerTypeToggle = connect(mapStateToProps, mapDispatchToProps)(PlayerTypeToggleCell);
export { PlayerTypeToggle };
