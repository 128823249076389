import React, { Component } from "react";
import { Image, Platform, StyleSheet, Text, View } from "react-native";
import { appFont, isTablet, isWebOrTablet } from "../../config/defaults";
import { ARROW } from "../../config/images";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

class PlayerDropDownButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			topPosition: 0,
			leftPosition: isWebOrTablet ? 75 : 93,
			height: 0,
			width: 0,
			rowNr: Number(this.props.cellId.slice(-1)),
		};
	}

	//#region lifecycle methods
	componentDidUpdate(prevProps) {
		if (prevProps.show && !this.props.show) {
			this.setState({ show: false });
		}
	}
	//#endregion

	//#region events
	onLayout(event) {
		if (Platform.OS !== "web") event.persist();
		if (this.marker) {
			this.marker.measure((x, y, width, height, pageX, pageY) => {
				this.setState({
					topPosition: pageY,
					height: height == undefined ? event.nativeEvent.layout.height : height,
					width: width == undefined ? event.nativeEvent.layout.width : width,
				});
			});
		}
	}

	onDropButtonPress() {
		this.setState({ show: !this.state.show });
		if (typeof this.props.onButtonPress === "function") {
			this.props.onButtonPress(
				{
					topPosition: this.state.topPosition,
					leftPosition: this.state.leftPosition,
					height: this.state.height,
					width: this.state.width,
				},
				this.state.rowNr,
				!this.state.show,
				this.props.slotID
			);
		}
	}

	onDropButtonBlur() {
		this.setState({ show: false });
		const { onDropButtonBlur } = this.props;
		if (typeof onDropButtonBlur === "function") onDropButtonBlur();
	}
	//#endregion

	//#region render methods
	render() {
		const { show } = this.state;
		const { contentText, textColor } = this.props;
		return (
			<View
				style={[styles.mainContainer, this.props.style]}
				ref={(ref) => {
					this.marker = ref;
				}}
				onLayout={(event) => this.onLayout(event)}
			>
				<AppTouchableOpacity
					activeOpacity={1}
					onPress={this.onDropButtonPress.bind(this)}
					style={{ flexDirection: "row", flex: 1, justifyContent: "center" }}
					onBlur={this.onDropButtonBlur.bind(this)}
				>
					<Text
						allowFontScaling={false}
						style={[styles.normalText, styles.selectedTextContainer, { color: textColor }]}
					>
						{contentText}
					</Text>
					<Image source={ARROW} style={!show ? [styles.dropIcon, styles.mirror] : styles.dropIcon} />
				</AppTouchableOpacity>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: "row",
		justifyContent: "center",
	},
	dropIcon: {
		width: 10,
		height: 7,
		marginTop: "auto",
		marginBottom: "auto",
		alignSelf: "flex-end",
	},
	mirror: {
		transform: [{ rotate: "180deg" }],
	},
	normalText: {
		alignSelf: "center",
		fontFamily: appFont,
		fontSize: isTablet ? 20 : 20,
		lineHeight: 40,
		// marginTop: isTablet ? -2 : 2,
	},
	selectedTextContainer: {
		marginRight: 5,
		paddingTop: 0,
		paddingLeft: 3,
		paddingRight: 3,
		overflow: "hidden",
		height: 40,
	},
});

export { PlayerDropDownButton };
