import React, { Component } from "react";
import { ImageBackground, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { BACKGROUND } from "../../config/images";
import { gameBoardTheme } from "../../Keezers/config/defaults";

class MyAppBackImage extends Component {
	render() {
		var containerStyle = styles.image;
		switch (this.props.gameTheme) {
			case gameBoardTheme.MODERN:
				containerStyle = [styles.image, { filter: "invert(1)" }];
				break;
		}
		return (
			<ImageBackground source={BACKGROUND} style={containerStyle} resizeMode="repeat">
				<View style={containerStyle}>{this.props.children}</View>
			</ImageBackground>
		);
	}
}

const styles = StyleSheet.create({
	image: { flex: 1 },
});

const mapStateToProps = (state) => {
	return {
		gameTheme: state.startedGame.gameTheme,
	};
};

const mapDispatchToProps = {};

const AppBackImage = connect(mapStateToProps, mapDispatchToProps)(MyAppBackImage);

export { AppBackImage };
