import React, { Component } from "react";
import { TouchableOpacity, View } from "react-native";
import { connect } from "react-redux";
import { BUTTON_PRESS_SOUND } from "../../config/sounds";
import { handleError } from "../ErrorHandler";

class MyAppTouchableOpacity extends Component {
	onPressHandler() {
		try {
			if (!this.props.disabled) {
				if (!this.props.silentPress) {
					if (
						(!this.props.isInMenu && this.props.playMiscButtonSound == true) ||
						// (this.props.isInMenu && this.props.menuSoundOn)
						(this.props.isInMenu && this.props.playMenuButtonSound == true)
					) {
						if (
							typeof BUTTON_PRESS_SOUND !== "undefined" &&
							BUTTON_PRESS_SOUND != null &&
							BUTTON_PRESS_SOUND.isLoaded() == true
						)
							BUTTON_PRESS_SOUND.play();
					}
				}
				if (typeof this.props.onPress === "function") this.props.onPress();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onMouseEnterHandler() {
		try {
			if (!this.props.disabled) {
				if (typeof this.props.onMouseEnter === "function") this.props.onMouseEnter();
			}
		} catch (error) {
			handleError(error);
		}
	}

	onMouseLeaveHandler() {
		try {
			if (!this.props.disabled) {
				if (typeof this.props.onMouseLeave === "function") this.props.onMouseLeave();
			}
		} catch (error) {
			handleError(error);
		}
	}

	renderButton() {
		return (
			<TouchableOpacity
				{...this.props}
				onPress={this.onPressHandler.bind(this)}
				onMouseEnter={this.onMouseEnterHandler.bind(this)}
				onMouseLeave={this.onMouseLeaveHandler.bind(this)}
				touchSoundDisabled={true}
			>
				{this.props.children}
			</TouchableOpacity>
		);
	}

	render() {
		if (this.props.renderInView == true) {
			return (
				<View style={this.props.style} importantForAccessibility={this.props.importantForAccessibility}>
					{this.renderButton()}
				</View>
			);
		}
		return this.renderButton();
	}
}

const mapStateToProps = (state) => {
	return {
		// menuSoundOn: state.app.menuSoundOn,
		playMenuButtonSound: state.currentUser.preferences.playMenuButtonSound,
		playMiscButtonSound: state.currentUser.preferences.playMiscButtonSound,
	};
};

const mapDispatchToProps = {};

const AppTouchableOpacity = connect(mapStateToProps, mapDispatchToProps)(MyAppTouchableOpacity);

export { AppTouchableOpacity };
