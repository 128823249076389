import React, { Component } from "react";
import { ImageBackground, ScrollView, StyleSheet } from "react-native";
import { connect } from "react-redux";
import { buttonColor, FEATURE_PERMISSION, RESOLUTION } from "../../../config/defaults";
import { gameDimensions } from "../../../config/dimensions";
import { MyWebSocket } from "../../../connection";
import {
	closeSettings,
	displayPermissionDenied,
	openDebugMenu,
	openDeleteAccount,
	openMyProfilePage,
	openPlayersList,
	openPreferences,
	openSearchPlayers,
	requestPersonalData,
	requestSettings,
} from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { ColoredButton, NavigationHeader } from "../../common";
import DebugMenu from "../../Debug/DebugMenu";
import DeleteAccount from "./DeleteAccount/DeleteAccount";
import PlayersList from "./PlayersList/PlayersList";
import Preferences from "./Preferences/Preferences";
import MyProfile from "./Profile/MyProfile";
import SearchPlayersScreen from "./SearchPlayersScreen";

class Settings extends Component {
	//#region lifecycle methods
	async componentDidMount() {
		await Analytics.logScreenView("SettingsMenu");
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				backAction={this.props.closeSettings}
				closeAction={this.props.closeSettings}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderButton(btnType) {
		var onPressFunc = () => {},
			_buttonColor = "",
			textContent = "",
			_additionalTextStyle,
			_permissionDenied = false;
		switch (btnType) {
			case "profile":
				textContent = this.props.lang.settingsPage.profile;
				onPressFunc = () => {
					this.props.requestPersonalData();
					this.props.openMyProfilePage();
				};
				_buttonColor = buttonColor.YELLOW;
				break;
			case "preferences":
				textContent = this.props.lang.settingsPage.preferences;
				onPressFunc = () => {
					this.props.requestSettings();
					this.props.openPreferences();
				};
				_buttonColor = buttonColor.PURPLE;
				break;
			case "fbLists":
				textContent = this.props.lang.settingsPage.fbLists;
				onPressFunc = () => {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "getFriendList",
					});
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "getBanList",
					});
					this.props.openPlayersList();
				};
				_buttonColor = buttonColor.LIGHT_BLUE;
				break;
			case "searchPl":
				textContent = this.props.lang.settingsPage.searchPlayers;
				onPressFunc = () => {
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "getFriendList",
					});
					MyWebSocket.shared.sendMsg({
						sMessageID: 0,
						type: "getBanList",
					});
					this.props.openSearchPlayers();
				};
				_buttonColor = buttonColor.GREEN;
				break;
			case "delete":
				textContent = this.props.lang.settingsPage.deleteAcc;
				onPressFunc = () => {
					if (this.props.deleteAccountPermission == FEATURE_PERMISSION.ENABLED) {
						this.props.openDeleteAccount();
					} else {
						this.props.displayPermissionDenied("GENERIC_DELETE_ACCOUNT");
					}
				};
				_buttonColor = buttonColor.RED;
				switch (this.props.deleteAccountPermission) {
					case FEATURE_PERMISSION.DISABLED:
						_permissionDenied = true;
						break;
					case FEATURE_PERMISSION.ENABLED:
						_permissionDenied = false;
						break;
				}
				break;
			case "debug":
				textContent = "Debug";
				onPressFunc = () => {
					if (this.props.adminOptionsPermission == FEATURE_PERMISSION.ENABLED) {
						this.props.openDebugMenu();
					} else {
						this.props.displayPermissionDenied("GENERIC_ADMIN_OPTIONS");
					}
				};
				_additionalTextStyle = { paddingTop: 13 };
				_buttonColor = buttonColor.GREY;
				switch (this.props.adminOptionsPermission) {
					case FEATURE_PERMISSION.DISABLED:
						_permissionDenied = true;
						break;
					case FEATURE_PERMISSION.ENABLED:
						_permissionDenied = false;
						break;
				}
				break;
		}

		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<ColoredButton
				width={buttonWidth}
				height={buttonHeight}
				onPressFunc={onPressFunc}
				textContent={textContent}
				accessibilityLabel={textContent}
				color={_buttonColor}
				additionalTextStyle={[_additionalTextStyle, _buttonTextStyle]}
				disabled={false}
				permissionDenied={_permissionDenied}
				isInMenu={true}
			/>
		);
	}

	renderMain() {
		return (
			<ImageBackground source={this.props.SETTINGS_BACK.url} style={{ flex: 1 }}>
				<ScrollView style={styles.container} contentContainerStyle={styles.containerContent} centerContent={true}>
					{this.renderButton("profile")}
					{this.renderButton("preferences")}
					{this.renderButton("fbLists")}
					{this.renderButton("searchPl")}
					{this.props.deleteAccountPermission != FEATURE_PERMISSION.HIDDEN && this.renderButton("delete")}
					{this.props.isDebuggingUser &&
						this.props.adminOptionsPermission != FEATURE_PERMISSION.HIDDEN &&
						this.renderButton("debug")}
				</ScrollView>
				{this.renderHeader()}
			</ImageBackground>
		);
	}

	render() {
		if (this.props.myProfileOpened) {
			return <MyProfile />;
		} else if (this.props.preferencesOpened) {
			return <Preferences />;
		} else if (this.props.deleteAccountOpened) {
			return <DeleteAccount />;
		} else if (this.props.playersListOpened) {
			return <PlayersList />;
		} else if (this.props.searchPlayersOpened) {
			return <SearchPlayersScreen />;
		} else if (this.props.isDebugMenuOpened) {
			return <DebugMenu />;
		} else {
			return this.renderMain();
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	containerContent: {
		minHeight: "100%",
		padding: 20,
		justifyContent: "center",
		alignItems: "center",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		myProfileOpened: state.app.myProfileOpened,
		preferencesOpened: state.app.preferencesOpened,
		deleteAccountOpened: state.app.deleteAccountOpened,
		playersListOpened: state.app.playersListOpened,
		searchPlayersOpened: state.app.searchPlayersOpened,
		deleteAccountPermission: state.currentUser.features.generic.deleteAccount,
		isDebugMenuOpened: state.debug.isDebugMenuOpened,
		isDebuggingUser: state.currentUser.isDebuggingUser,
		adminOptionsPermission: state.currentUser.features.generic.adminOptions,
		resolution: state.currentUser.preferences.resolution,
		SETTINGS_BACK: state.images.SETTINGS_BACK,
	};
};

const mapDispatchToProps = {
	closeSettings,
	displayPermissionDenied,
	openDebugMenu,
	openDeleteAccount,
	openMyProfilePage,
	openPlayersList,
	openPreferences,
	openSearchPlayers,
	requestPersonalData,
	requestSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
