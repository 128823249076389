import React, { Component } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { f_sourcesansprobold, f_sourcesansprobolditalic, f_sourcesansprosemibolditalic } from "../../config/defaults";
import { CHAT_ICON, MAIL_ICON, MINUS_ICON, PLUS_ICON } from "../../config/images";
import { getCountryFlagImageURL, getRankingImageURL } from "../../helpers/commonHelpers";
import { handleError } from "../ErrorHandler";
import { AppTouchableOpacity } from "./AppTouchableOpacity";

class PlayerInfo extends Component {
	//#region render methods
	renderButtons() {
		try {
			const { banList, friendList } = this.props.userDetails;
			if (banList.indexOf(this.props.player.userID) != -1) {
				this.props.player.isBanned = true;
				this.props.player.isFriend = false;
			} else if (friendList.indexOf(this.props.player.userID) != -1) {
				this.props.player.isBanned = false;
				this.props.player.isFriend = true;
			} else {
				this.props.player.isBanned = false;
				this.props.player.isFriend = false;
			}
			const friendActionURL = this.props.player.isFriend ? MINUS_ICON : PLUS_ICON;
			const banActionURL = this.props.player.isBanned ? MINUS_ICON : PLUS_ICON;
			return (
				<View key="buttonContainer" style={styles.buttonContainer}>
					<AppTouchableOpacity
						key="friendAction"
						activeOpacity={0.8}
						onPress={() => this.props.friendAction(this.props.player)}
						style={styles.button}
						disabled={this.props.player.isBanned}
					>
						<Image
							style={[styles.buttonIcon, { opacity: this.props.player.isBanned ? 0.8 : 1 }]}
							source={friendActionURL}
						/>
						<Text
							allowFontScaling={false}
							style={[styles.buttonTextStyle, { opacity: this.props.player.isBanned ? 0.8 : 1 }]}
						>
							{this.props.lang.playersList.friendsTitle}
						</Text>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						key="banAction"
						activeOpacity={0.8}
						onPress={() => this.props.banAction(this.props.player)}
						style={styles.button}
						disabled={this.props.player.isFriend}
					>
						<Image
							style={[
								styles.buttonIcon,
								{
									opacity: this.props.player.isFriend ? 0.5 : 1,
									marginRight: 3,
								},
							]}
							source={banActionURL}
						/>
						<Text
							allowFontScaling={false}
							style={[styles.buttonTextStyle, { opacity: this.props.player.isFriend ? 0.5 : 1 }]}
						>
							{this.props.lang.playersList.banTitle}
						</Text>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						key="chatAction"
						activeOpacity={0.8}
						onPress={() => this.props.chatAction(this.props.player)}
						style={styles.button}
					>
						<Image style={styles.buttonIcon} source={CHAT_ICON} resizeMode="contain" />
						<Text allowFontScaling={false} style={[styles.buttonTextStyle, { marginLeft: 10 }]}>
							{this.props.lang.playersList.btnChat}
						</Text>
					</AppTouchableOpacity>
					<AppTouchableOpacity
						key="messageAction"
						activeOpacity={0.8}
						onPress={() => this.props.messageAction(this.props.player)}
						style={styles.button}
					>
						<Image style={styles.buttonIcon} source={MAIL_ICON} resizeMode="contain" />
						<Text allowFontScaling={false} style={[styles.buttonTextStyle, { marginLeft: 10 }]}>
							{this.props.lang.playersList.btnMail}
						</Text>
					</AppTouchableOpacity>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderLeftColumn() {
		try {
			const username_txt =
					typeof this.props.player.username === "undefined" || this.props.player.username == ""
						? this.props.lang.notPublic
						: this.props.player.username,
				country =
					typeof this.props.player.country === "undefined" || this.props.player.country == ""
						? undefined
						: this.props.lang.countryList[this.props.player.country.toLowerCase()],
				countryURL = country != undefined ? getCountryFlagImageURL(this.props.player.country.toUpperCase(), 40) : "",
				country_txt =
					typeof this.props.player.country === "undefined" || this.props.player.country == ""
						? this.props.lang.notPublic
						: country != undefined
						? country
						: this.props.player.country,
				city_txt =
					typeof this.props.player.city === "undefined" || this.props.player.city == ""
						? this.props.lang.notPublic
						: this.props.player.city,
				joined_txt =
					typeof this.props.player.joined === "undefined" || this.props.player.joined == 1900
						? this.props.lang.notPublic
						: this.props.player.joined,
				age_txt =
					typeof this.props.player.age === "undefined" || this.props.player.age == 0
						? this.props.lang.notPublic
						: this.props.player.age;

			return (
				<View key="leftColumn" style={styles.columnContainer}>
					<View key="username" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.username}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text
								allowFontScaling={false}
								style={[styles.dataText, username_txt == this.props.lang.notPublic && styles.notPublicText]}
							>
								{username_txt}
							</Text>
						</View>
					</View>
					<View key="country" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.country}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							{/* {countryURL != "" && (
                <Image
                  style={styles.roundIcon}
                  source={{ uri: countryURL }}
                  resizeMode="contain"
                />
              )} */}
							<Text
								allowFontScaling={false}
								numberOfLines={1}
								style={[styles.dataText, country_txt == this.props.lang.notPublic && styles.notPublicText]}
							>
								{country_txt}
							</Text>
						</View>
					</View>
					<View key="city" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.city}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text
								allowFontScaling={false}
								style={[styles.dataText, city_txt == this.props.lang.notPublic && styles.notPublicText]}
							>
								{city_txt}
							</Text>
						</View>
					</View>
					<View key="joined" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.joined}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text
								allowFontScaling={false}
								style={[styles.dataText, joined_txt == this.props.lang.notPublic && styles.notPublicText]}
							>
								{joined_txt}
							</Text>
						</View>
					</View>
					<View key="age" style={[styles.dataLine, { borderBottomWidth: 0 }]}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.age}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text
								allowFontScaling={false}
								style={[styles.dataText, age_txt == this.props.lang.notPublic && styles.notPublicText]}
							>
								{age_txt}
							</Text>
						</View>
					</View>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	renderRightColumn() {
		try {
			const ranking_txt =
					typeof this.props.player.ranking !== "number"
						? this.props.lang.notPublic
						: " " + this.props.player.ranking.toLocaleString(),
				rankingURL =
					typeof this.props.player.ranking !== "number" ? undefined : getRankingImageURL(this.props.player.ranking, 30),
				played_txt =
					typeof this.props.player.played !== "number"
						? this.props.lang.notPublic
						: " " + this.props.player.played.toLocaleString(),
				won_txt =
					typeof this.props.player.won !== "number"
						? this.props.lang.notPublic
						: " " + this.props.player.won.toLocaleString(),
				lost_txt =
					typeof this.props.player.lost !== "number"
						? this.props.lang.notPublic
						: " " + this.props.player.lost.toLocaleString(),
				glRank_txt =
					typeof this.props.player.globalRank !== "number"
						? this.props.lang.notPublic
						: " " + this.props.player.globalRank.toLocaleString(),
				score_txt =
					typeof this.props.player.score !== "number"
						? this.props.lang.notPublic
						: " " + this.props.player.score.toLocaleString();
			return (
				<View key="rightColumn" style={styles.columnContainer}>
					<View key="ranking" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.ranking}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							{rankingURL != undefined && <Image style={[styles.roundIcon]} source={rankingURL} resizeMode="contain" />}
							<Text
								allowFontScaling={false}
								style={[
									styles.dataText,
									styles.rightData,
									ranking_txt == this.props.lang.notPublic && styles.notPublicText,
								]}
							>
								{ranking_txt}
							</Text>
						</View>
					</View>
					{/* <View key="played" style={styles.dataLine}>
            <View style={styles.leftInnerColumn}>
              <Text
                allowFontScaling={false}
                style={[styles.dataText, styles.dataTitle]}
              >
                {this.props.lang.playersList.playerInfo.played}
              </Text>
            </View>
            <View style={styles.rightInnerColumn}>
              <Text
                allowFontScaling={false}
                style={[
                  styles.dataText,
                  styles.rightData,
                  played_txt == this.props.lang.notPublic && styles.notPublicText,
                ]}
              >
                {played_txt}
              </Text>
            </View>
          </View> */}
					<View key="won" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.won}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text
								allowFontScaling={false}
								style={[
									styles.dataText,
									styles.rightData,
									won_txt == this.props.lang.notPublic && styles.notPublicText,
								]}
							>
								{won_txt}
							</Text>
						</View>
					</View>
					<View key="lost" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.lost}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text
								allowFontScaling={false}
								style={[
									styles.dataText,
									styles.rightData,
									lost_txt == this.props.lang.notPublic && styles.notPublicText,
								]}
							>
								{lost_txt}
							</Text>
						</View>
					</View>
					<View key="glRank" style={styles.dataLine}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.globalRank}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text
								allowFontScaling={false}
								style={[
									styles.dataText,
									styles.rightData,
									glRank_txt == this.props.lang.notPublic && styles.notPublicText,
								]}
							>
								{glRank_txt}
							</Text>
						</View>
					</View>
					<View key="score" style={[styles.dataLine, { borderBottomWidth: 0 }]}>
						<View style={styles.leftInnerColumn}>
							<Text allowFontScaling={false} style={[styles.dataText, styles.dataTitle]}>
								{this.props.lang.playersList.playerInfo.score}
							</Text>
						</View>
						<View style={styles.rightInnerColumn}>
							<Text
								allowFontScaling={false}
								style={[
									styles.dataText,
									styles.rightData,
									score_txt == this.props.lang.notPublic && styles.notPublicText,
								]}
							>
								{score_txt}
							</Text>
						</View>
					</View>
				</View>
			);
		} catch (error) {
			handleError(error);
		}
	}

	render() {
		const { player } = this.props;
		if (player == undefined) {
			debugger;
			this.props.onBackAction();
			return null;
		}

		return (
			<View key="mainContainer" style={styles.mainContainer}>
				<View key="infoContainer" style={styles.infoContainer}>
					{this.renderLeftColumn()}
					{this.renderRightColumn()}
				</View>
				{this.renderButtons()}
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flex: 1,
		paddingBottom: 15,
		paddingHorizontal: 15,
	},
	infoContainer: {
		flex: 1,
		flexDirection: "row",
		marginTop: 10,
		marginBottom: 20,
		borderWidth: 3,
		borderColor: "#095260",
		borderRadius: 10,
		overflow: "hidden",
		shadowColor: "#065C63",
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowRadius: 6,
		shadowOpacity: 0.8,
		elevation: 5,
	},
	buttonContainer: {
		width: "100%",
		height: 80,
		flexDirection: "row",
		justifyContent: "space-around",
	},
	button: {
		width: 160,
		height: 60,
		alignSelf: "center",
		borderWidth: 2,
		borderRadius: 10,
		borderColor: "#24cdef",
		backgroundColor: "#0a5f6e",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		shadowColor: "#06525a",
		shadowOffset: {
			width: 2,
			height: 4,
		},
		shadowRadius: 4,
		shadowOpacity: 0.8,
		elevation: 5,
	},
	buttonTextStyle: {
		fontFamily: f_sourcesansprobold,
		fontSize: 20,
		marginLeft: 5,
		color: "#FFFFFF",
	},
	buttonIcon: { width: 40, height: 40, marginTop: 4 },
	columnContainer: { flex: 2, justifyContent: "space-around" },
	dataLine: {
		flex: 5,
		flexDirection: "row",
		borderBottomWidth: 1,
		borderBottomColor: "#47e7ee",
	},
	leftInnerColumn: {
		flex: 2,
		paddingHorizontal: 15,
		flexDirection: "row",
		backgroundColor: "rgba(57, 232, 242,0.8)",
	},
	rightInnerColumn: {
		flex: 2,
		paddingHorizontal: 15,
		flexDirection: "row",
		backgroundColor: "rgba(36, 204, 216, 0.8)",
	},
	dataTitle: { fontFamily: f_sourcesansprobolditalic },
	dataText: {
		fontSize: 24,
		fontFamily: f_sourcesansprosemibolditalic,
		textAlign: "left",
		alignSelf: "center",
		color: "#07525f",
	},
	notPublicText: { color: "#118b97" },
	rightData: { flex: 1, textAlign: "right" },
	roundIcon: {
		width: 25,
		height: 25,
		marginRight: 5,
		alignSelf: "center",
	},
});

export { PlayerInfo };
