import { Platform } from "react-native";
import { MyWebSocket } from "../../connection";
import { mapObject } from "../../helpers/commonHelpers";
import { isSmallResolution } from "../../helpers/resizeHelpers";
import { store } from "../../redux/store";
import {
	APPLY_FILTER,
	CLEAR_GAME_LIST_MODULE,
	CLEAR_GAME_SELECTION,
	ENABLE_JOIN_GAME_BUTTON,
	GAME_LIST_HEADER_CELL_PRESSED,
	GAME_LIST_SUBSCRIBED_RECEIVED,
	GAME_LIST_UNSUBSCRIBED_RECEIVED,
	INSERT_NEW_GAME,
	MOUSEENTER_GAME_IN_LOBBY,
	MOUSELEAVE_GAME_IN_LOBBY,
	MOUSE_ENTERED_GAME_BUTTON,
	MOUSE_LEAVED_GAME_BUTTON,
	REMOVE_GAME,
	RESET_FILTER,
	SELECT_GAME_IN_LOBBY,
	SET_FILTER,
	SET_FILTER_LABELS,
	SUBSCRIBE_TO_GAME_LIST,
	UPDATE_PLAYER_AMOUNT_FILTER,
} from "./actionTypes";
import { clearGameDetailsInLobby, loadingGameDetailsInLobby } from "./GameDetailsDataActions";

export const subscribeToGameList = () => {
	return (dispatch) => {
		const { playerCnt_3, playerCnt_4, chipsRequired, onlyLocalLanguage, onlyFriends } =
			store.getState().gameListData.filter;
		var oll = "BOTH",
			ofr = "BOTH",
			crq = "BOTH",
			//plc = [];
			plc = {};

		if (onlyLocalLanguage.active[0] != onlyLocalLanguage.active[1]) {
			if (onlyLocalLanguage.active[0] == true) oll = "ON";
			if (onlyLocalLanguage.active[1] == true) oll = "OFF";
		}
		if (onlyFriends.active[0] != onlyFriends.active[1]) {
			if (onlyFriends.active[0] == true) ofr = "ON";
			if (onlyFriends.active[1] == true) ofr = "OFF";
		}
		if (chipsRequired.active[0] != chipsRequired.active[1]) {
			if (chipsRequired.active[0] == true) crq = "ON";
			if (chipsRequired.active[1] == true) crq = "OFF";
		}
		mapObject(playerCnt_3.active, (k, v, i) => {
			plc[playerCnt_3.default.labels[i]] = v;
			//if (v === true) plc.push(playerCnt_3.default.labels[k]);
		});
		mapObject(playerCnt_4.active, (k, v, i) => {
			plc[playerCnt_4.default.labels[i]] = v;
			//if (v === true) plc.push(playerCnt_4.default.labels[k]);
		});
		const { showRunningGamesInList } = store.getState().currentUser.preferences;
		MyWebSocket.shared.sendMsg({
			sMessageID: 0,
			type: "gameListSubscribe",
			ruleFour: "BOTH",
			ruleSeven: "BOTH",
			ruleJack: "BOTH",
			onlyLocalCountry: "BOTH",
			onlyLocalLanguage: oll,
			onlyFriends: ofr,
			chipsRequired: crq,
			playerCnts: plc,
			subscribeToRunningGames: showRunningGamesInList,
		});
		dispatch({ type: SUBSCRIBE_TO_GAME_LIST });
	};
};

export const gameListSubscribedReceived = (msg) => {
	return (dispatch) => {
		dispatch({ type: GAME_LIST_SUBSCRIBED_RECEIVED, payload: msg });
	};
};

export const unsubscribeFromGameList = () => {
	return (dispatch) => {
		dispatch(clearGameSelection());
		MyWebSocket.shared.sendMsg({ sMessageID: 0, type: "gameListUnsubscribe" });
		dispatch(
			gameListUnsubscribedReceived({
				subscribeID: store.getState().gameListData.subscribeID,
			})
		);
	};
};

export const gameListUnsubscribedReceived = (msg) => {
	return (dispatch) => {
		dispatch({ type: GAME_LIST_UNSUBSCRIBED_RECEIVED, payload: msg });
	};
};

export const setFilterLabels = (yesNo, nm) => {
	return (dispatch) => {
		dispatch({ type: SET_FILTER_LABELS, payload: { yesNo, nm } });
	};
};

export const updatePlayerAmountFilter = () => {
	return (dispatch) => {
		dispatch(_updatePlayerAmountFilter());
		dispatch(applyFilter());
		dispatch(enableJoinGameButton());
		if (store.getState().gameListData.selectedGameID == -1) dispatch(clearGameDetailsInLobby());
	};
};

export const insertNewGame = (games, sMessageID, subscribeID) => {
	return (dispatch) => {
		const { tab } = store.getState();
		if (tab.isLobbyOpen || tab.isGlobalChatOpen) {
			dispatch(_insertNewGame(games, subscribeID));
			dispatch(_applyFilter());
			if (games.length == 1 && games[0].gameID == store.getState().gameListData.selectedGameID) {
				var msg = {
					sMessageID: 0,
					type: "getGameDetails",
					gameID: games[0].gameID,
				};
				MyWebSocket.shared.sendMsg(msg);
				dispatch(enableJoinGameButton());
			}
			var origGames = store.getState().gameListData.gameListOrig;
			games.forEach((game) => {
				var ind = origGames.find((g) => g.gameID == game.gameID);
				if (typeof ind != "undefined") game.isHiddenByFilter = ind.isHiddenByFilter;
			});
			MyWebSocket.shared.sendProcessedMessage(sMessageID, games);
		}
	};
};

export const pressGameListHeaderCell = (columnKey) => {
	return { type: GAME_LIST_HEADER_CELL_PRESSED, payload: columnKey };
};

export const selectGameInLobby = (selectedGameId) => {
	return (dispatch) => {
		dispatch(_selectGameInLobby(selectedGameId));
		dispatch(enableJoinGameButton());
		dispatch(loadingGameDetailsInLobby());
	};
};

export const mouseEnterGameInLobby = (selectedGameId) => {
	return { type: MOUSEENTER_GAME_IN_LOBBY, payload: selectedGameId };
};

export const mouseLeaveGameInLobby = (selectedGameId) => {
	return { type: MOUSELEAVE_GAME_IN_LOBBY, payload: selectedGameId };
};

export const mouseEnteredGameButton = (button) => {
	return { type: MOUSE_ENTERED_GAME_BUTTON, payload: button };
};

export const mouseLeavedGameButton = (button) => {
	return { type: MOUSE_LEAVED_GAME_BUTTON, payload: button };
};

export const removeGame = (gameID, sMessageID, subscribeID) => {
	return (dispatch) => {
		dispatch(_removeGame(gameID, subscribeID));
		dispatch(enableJoinGameButton());
		if (store.getState().gameListData.selectedGameID == -1) dispatch(clearGameDetailsInLobby());
		MyWebSocket.shared.sendProcessedMessage(sMessageID);
	};
};

export const filterData = (filterGroup, val, isChecked) => {
	return (dispatch) => {
		dispatch(setFilter(filterGroup, val, isChecked));
		dispatch(applyFilter());
		dispatch(enableJoinGameButton());
		if (store.getState().gameListData.selectedGameID == -1) dispatch(clearGameDetailsInLobby());
	};
};

export const resetFilters = () => {
	return (dispatch) => {
		dispatch(_resetFilters());
		if (Platform.OS !== "web" || isSmallResolution()) {
			dispatch(setFilter("playerCnt_4", 5, false));
			dispatch(setFilter("playerCnt_4", 6, false));
			dispatch(setFilter("playerCnt_4", 7, false));
			dispatch(setFilter("playerCnt_4", 8, false));
		}
		dispatch(applyFilter());
		dispatch(enableJoinGameButton());
		if (store.getState().gameListData.selectedGameID == -1) dispatch(clearGameDetailsInLobby());
	};
};

export const enableJoinGameButton = () => {
	return { type: ENABLE_JOIN_GAME_BUTTON };
};

export const clearGameSelection = () => {
	return (dispatch) => {
		dispatch(_clearGameSelection());
		dispatch(clearGameDetailsInLobby());
		dispatch(enableJoinGameButton());
	};
};

export const setFilter = (filterGroup, val, isChecked) => {
	return (dispatch) => {
		dispatch(_setFilter(filterGroup, val, isChecked));
	};
};

export const applyFilter = () => {
	return (dispatch) => {
		dispatch(_applyFilter());
	};
};

export const clearGameListModule = () => {
	return { type: CLEAR_GAME_LIST_MODULE };
};

const _clearGameSelection = () => {
	return { type: CLEAR_GAME_SELECTION };
};

const _selectGameInLobby = (selectedGameId) => {
	return {
		type: SELECT_GAME_IN_LOBBY,
		payload: {
			selectedGameId,
			lang: store.getState().language.currentLanguage,
		},
	};
};

const _resetFilters = () => {
	return (dispatch) => {
		dispatch({ type: RESET_FILTER });
		const { isSubscribeInProgress } = store.getState().gameListData;
		if (!isSubscribeInProgress) dispatch(subscribeToGameList());
	};
};

const _setFilter = (filterGroup, val, isChecked) => {
	const enableFiverOrMorePlayers = store.getState().views.enableFiveOrMorePlayersGame;
	return {
		type: SET_FILTER,
		payload: { filterGroup, val, isChecked, enableFiverOrMorePlayers },
	};
};

const _applyFilter = () => {
	return { type: APPLY_FILTER };
};

const _insertNewGame = (games, subscribeID) => {
	return { type: INSERT_NEW_GAME, payload: { games, subscribeID } };
};

const _removeGame = (gameID, subscribeID) => {
	return { type: REMOVE_GAME, payload: { gameID, subscribeID } };
};

const _updatePlayerAmountFilter = () => {
	const enableFiveOrMorePlayers = store.getState().views.enableFiveOrMorePlayersGame;
	return {
		type: UPDATE_PLAYER_AMOUNT_FILTER,
		payload: { enableFiveOrMorePlayers },
	};
};
