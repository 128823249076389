import React, { Component } from "react";
import { ImageBackground, Platform, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import { f_sourcesansprolight, f_sourcesansprosemibold, isWebOrTablet } from "../../../config/defaults";
import { TEXT_INPUT } from "../../../config/images";
import { saveNewTeamPinCode } from "../../../redux/actions";
import { handleError } from "../../ErrorHandler";

class PinCodeInput extends Component {
	//#region events
	onChangeText(newPinCode) {
		try {
			this.props.saveNewTeamPinCode(newPinCode);
		} catch (err) {
			handleError(err);
		}
	}
	//#endregion

	//#region render methods
	render() {
		return (
			<View style={styles.mainContainer}>
				<View style={styles.labelContainer}>
					<Text allowFontScaling={false} selectable={false} style={styles.labelText}>
						{"Pin code: "}
					</Text>
				</View>
				<View style={styles.inputContainer}>
					<ImageBackground
						source={TEXT_INPUT}
						resizeMode="cover"
						style={styles.inputImageStyle}
						imageStyle={{ borderRadius: 6 }}
					>
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onChangeText={this.onChangeText.bind(this)}
							value={this.props.pinCode.toString()}
							placeholder={"Pin code"}
							placeholderTextColor="#B2B2B2"
							maxLength={8}
							keyboardType="number-pad"
							underlineColorAndroid="transparent"
							autoCapitalize="none"
							autoCompleteType="off"
							autoCorrect={false}
							spellCheck={false}
							style={[styles.codeInputStyle, Platform.OS === "web" && { outlineWidth: 0, lineHeight: 50 }]}
						/>
					</ImageBackground>
				</View>
			</View>
		);
	}
	//#endregion
}

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: "row",
		height: 60,
		paddingTop: 5,
		paddingBottom: 5,
	},
	labelContainer: { flex: 2, height: 50 },
	labelText: {
		fontFamily: f_sourcesansprosemibold,
		fontSize: isWebOrTablet ? 20 : 18,
		textAlignVertical: "center",
		alignSelf: "flex-end",
		height: 50,
		lineHeight: 50,
		color: "#FFFDF1",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	inputContainer: {
		flex: 2,
		height: 50,
		flexDirection: "row",
		alignSelf: "flex-start",
	},
	inputImageStyle: {
		width: 275,
		height: 50,
		marginLeft: 10,
		marginRight: 2,
	},
	codeInputStyle: {
		width: "100%",
		fontFamily: f_sourcesansprolight,
		fontSize: isWebOrTablet ? 20 : 18,
		height: 50,
		paddingHorizontal: 8,
		textAlign: "left",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
});

const mapStateToProps = (state) => {
	const { newTeam } = state.tournaments;
	return {
		lang: state.language.currentLanguage,
		resolution: state.currentUser.preferences.resolution,
		pinCode: newTeam && typeof newTeam.pinCode !== "undefined" ? newTeam.pinCode : "",
	};
};

const mapDispatchToProps = { saveNewTeamPinCode };

export default connect(mapStateToProps, mapDispatchToProps)(PinCodeInput);
