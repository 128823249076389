import React, { Component } from "react";
import { Dimensions, ImageBackground, Platform, StyleSheet, Text, TextInput, View } from "react-native";
import { connect } from "react-redux";
import {
	buttonColor,
	f_sourcesansprobold,
	f_sourcesansprolight,
	isWebOrTablet,
	processState,
	RESOLUTION,
} from "../../../config/defaults";
import { emailTroubleDimensions, gameDimensions } from "../../../config/dimensions";
import { TEXT_INPUT, TEXT_INPUT_ERROR } from "../../../config/images";
import { clearResetPasswordError, closeResetPassword, goBackToMain, resetPassword } from "../../../redux/actions";
import Analytics from "../../Analytics/Analytics";
import { ColoredButton, NavigationHeader, Spinner } from "../../common";
import { handleError } from "../../ErrorHandler";
import SetNewPassword from "./SetNewPassword";

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentScale: this.getContentScale(),
			username: "",
			usernameValid: true,
			usernameActive: false,
			isFormValid: true,
		};
	}

	//#region lifecycle methods
	componentDidMount() {
		Analytics.logScreenView("ResetPassword");
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.resetPasswordState != this.props.resetPasswordState &&
			!prevProps.resetPasswordSucceeded &&
			!this.props.resetPasswordSucceeded
		) {
			this.setState({ usernameValid: false, isFormValid: false });
		}
	}
	//#endregion

	//#region events
	onUsernameSubmit() {
		this.setState({ usernameActive: false });
	}
	onUsernameChange(text) {
		this.setState({ username: text });
	}
	onUsernameFocus() {
		this.setState({ usernameActive: true });
	}
	onResetPress() {
		this.props.resetPassword(this.state.username);
	}
	//#endregion

	//#region render methods
	renderHeader() {
		return (
			<NavigationHeader
				isBackEnabled={this.props.resetPasswordState != processState.STARTED}
				backAction={this.props.closeResetPassword}
				closeAction={this.props.goBackToMain}
				backAccessibilityLabel={this.props.lang.acceptTerms.backButton}
				closeAccessibilityLabel={this.props.lang.backToMain}
				resolution={this.props.resolution}
			/>
		);
	}

	renderUsernameField() {
		var _inputLabel = [styles.dataTitle, styles.dataText],
			_inputStyle = [styles.inputStyle, Platform.OS === "android" && { paddingTop: 13 }];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_inputLabel = [styles.dataTitle, styles.dataText, { fontSize: 26 }];
			_inputStyle = [
				styles.inputStyle,
				{ fontSize: 26, marginTop: Platform.OS === "web" ? 0 : 3 },
				Platform.OS === "android" && { paddingTop: 13 },
			];
		}
		return (
			<View
				style={styles.dataInnerContainer}
				accessible
				accessibilityLabel={"input " + this.props.lang.loginTrouble.username}
			>
				<Text allowFontScaling={false} style={_inputLabel}>
					{this.props.lang.loginTrouble.username}
				</Text>
				<ImageBackground
					source={this.state.usernameValid ? TEXT_INPUT : TEXT_INPUT_ERROR}
					resizeMode="stretch"
					style={{ width: 300, height: 50 }}
				>
					{Platform.OS !== "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onBlur={this.onUsernameSubmit.bind(this)}
							onFocus={this.onUsernameFocus.bind(this)}
							onSubmitEditing={this.onUsernameSubmit.bind(this)}
							onChangeText={(text) => this.onUsernameChange(text)}
							value={this.state.username}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							autoCompleteType="off"
							autoCorrect={false}
							spellCheck={false}
							textContentType="oneTimeCode"
							keyboardType={Platform.OS === "ios" ? "default" : "visible-password"}
							underlineColorAndroid="transparent"
							style={_inputStyle}
							placeholder={this.props.lang.loginTrouble.username}
							placeholderTextColor="#8a8a8a"
						/>
					)}
					{Platform.OS === "web" && (
						<TextInput
							allowFontScaling={false}
							disableFullscreenUI={true}
							onBlur={this.onUsernameSubmit.bind(this)}
							onFocus={this.onUsernameFocus.bind(this)}
							onSubmitEditing={this.onUsernameSubmit.bind(this)}
							onChangeText={(text) => this.onUsernameChange(text)}
							value={this.state.username}
							editable={true}
							selectTextOnFocus={true}
							autoCapitalize="none"
							autoCorrect={false}
							spellCheck={false}
							textContentType="oneTimeCode"
							underlineColorAndroid="transparent"
							style={[_inputStyle, { outlineWidth: 0 }]}
							placeholder={this.props.lang.loginTrouble.username}
							placeholderTextColor="#8a8a8a"
						/>
					)}
				</ImageBackground>
			</View>
		);
	}

	renderPasswordError() {
		var _errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError];
		if (this.props.resolution === RESOLUTION.HIGH) {
			_errorStyle = [styles.dataTitle, styles.dataText, styles.fieldError, { fontSize: 26 }];
		}
		return (
			<View style={styles.dataErrorContainer}>
				{((this.props.resetPasswordState == processState.FINISHED && !this.props.resetPasswordSucceeded) ||
					!this.state.usernameValid) && (
					<Text allowFontScaling={false} style={_errorStyle}>
						{this.props.resetPasswordError}
					</Text>
				)}
			</View>
		);
	}

	renderResetButton() {
		var buttonWidth = gameDimensions.gameButtonsWidth,
			buttonHeight = 60,
			_buttonTextStyle = {};
		if (this.props.resolution === RESOLUTION.HIGH) {
			buttonWidth *= 1.5;
			buttonHeight *= 1.5;
			_buttonTextStyle = { fontSize: 28, paddingTop: 20 };
		}
		return (
			<View style={styles.resetButtonContainer}>
				<ColoredButton
					width={buttonWidth}
					height={buttonHeight}
					additionalTextStyle={_buttonTextStyle}
					onPressFunc={this.onResetPress.bind(this)}
					textContent={this.props.lang.loginTrouble.reset}
					accessibilityLabel={this.props.lang.loginTrouble.reset}
					color={buttonColor.GREEN}
				/>
			</View>
		);
	}

	renderContent() {
		return (
			<View
				style={[
					styles.contentContainer,
					// { transform: [{ scale: this.state.contentScale }] },
				]}
			>
				<View style={isWebOrTablet ? { flex: 1, justifyContent: "center" } : { paddingTop: 40, paddingRight: 20 }}>
					{this.renderUsernameField()}
					{this.renderPasswordError()}
				</View>
				{this.renderResetButton()}
			</View>
		);
	}

	render() {
		if (this.props.resetPasswordState == processState.STARTED)
			return (
				<ImageBackground source={this.props.DEFAULT_BACK.url} style={styles.backgroundContainer}>
					<Spinner size="large" />
					{this.renderHeader()}
				</ImageBackground>
			);
		if (this.props.resetPasswordSucceeded) {
			return <SetNewPassword username={this.state.username} />;
		}
		return (
			<ImageBackground source={this.props.DEFAULT_BACK.url} style={styles.backgroundContainer}>
				<View style={styles.container}>{this.renderContent()}</View>
				{this.renderHeader()}
			</ImageBackground>
		);
	}
	//#endregion

	//#region helpers
	getContentScale() {
		try {
			var scale = 1;
			const windowDim = Dimensions.get("window");
			const availableWidth = windowDim.width - 120; //right and left icon's positions and width + container padding
			const availableHeight = windowDim.height - 100;
			if (emailTroubleDimensions.width > availableWidth || emailTroubleDimensions.height > availableHeight) {
				const _scaleX = Math.round((availableWidth / emailTroubleDimensions.width) * 100) / 100;
				const _scaleY = Math.round((availableHeight / emailTroubleDimensions.height) * 100) / 100;
				scale = Math.min(_scaleX, _scaleY);
				scale = scale > 1 ? 1 : scale;
			}
			return scale;
		} catch (error) {
			handleError(error);
		}
	}
	//#endregion
}

const styles = StyleSheet.create({
	backgroundContainer: { flex: 1, justifyContent: "center" },
	container: {
		flex: 1,
		padding: 20,
	},
	contentContainer: { flex: 1 },
	resetButtonContainer: {
		position: "absolute",
		right: 10,
		bottom: 0,
	},
	dataInnerContainer: {
		height: 40,
		flexDirection: "row",
		marginTop: 3,
		marginBottom: 13,
		paddingLeft: 10,
		paddingRight: 10,
		justifyContent: "center",
		alignItems: "center",
		alignSelf: isWebOrTablet ? "center" : "flex-end",
	},
	dataTitle: {
		width: 200,
		textAlign: "left",
		paddingTop: 2,
		paddingRight: 10,
		color: "#FFFDF1",
	},
	dataText: { fontFamily: f_sourcesansprobold, fontSize: 20 },
	inputStyle: {
		fontFamily: f_sourcesansprolight,
		fontSize: 18,
		width: 300,
		height: 50,
		marginRight: 2,
		borderRadius: 5,
		// paddingTop: Platform.OS === "web" ? 0 : 13,
		paddingHorizontal: 8,
		textAlign: "center",
		textAlignVertical: "center",
		backgroundColor: "transparent",
		color: "#B2B2B2",
		textShadowColor: "rgba(0,0,0,0.25)",
		textShadowOffset: { width: 0, height: 1 },
		textShadowRadius: 10,
	},
	dataErrorContainer: {
		width: "100%",
		height: 40,
		marginTop: 3,
		marginBottom: 3,
		paddingLeft: 10,
		justifyContent: "flex-end",
		alignItems: isWebOrTablet ? "center" : "flex-end",
		alignSelf: "flex-end",
	},
	fieldError: {
		width: 500,
		height: "100%",
		color: "#FE1F01",
		textAlign: "right",
	},
});

const mapStateToProps = (state) => {
	return {
		lang: state.language.currentLanguage,
		resetPasswordSucceeded: state.app.resetPasswordSucceeded,
		resetPasswordState: state.app.resetPasswordState,
		resetPasswordError: state.app.resetPasswordError,
		resolution: state.currentUser.preferences.resolution,
		DEFAULT_BACK: state.images.DEFAULT_BACK,
	};
};

const mapDispatchToProps = {
	clearResetPasswordError,
	closeResetPassword,
	goBackToMain,
	resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
